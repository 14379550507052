import React, { memo, useState } from 'react';
import GridConfig from './grid-config';
import DataGrid from 'components/data-grid';

interface Props {
  userPatientProfileID: string;
}
export const GridPatientActivities = memo<Props>(({ userPatientProfileID }) => {
  const [gridConfig] = useState<any>(GridConfig(userPatientProfileID));
  return <DataGrid config={gridConfig} />;
});
