import React, { memo, useCallback } from 'react';
import { useI18n } from 'AurionCR/components';
import { useOpen } from 'AurionCR/components/hooks';
import { useAppDispatch } from 'store';
import { TMRefresh } from 'pages/TaskManager/components/store';
import { Button } from '@material-ui/core';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import { DialogRenewal } from 'components/dialog-renewal';

export const Renewal = memo(() => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { isOpen, handleOpen, handleClose } = useOpen();

  const onRefresh = useCallback(() => {
    dispatch(TMRefresh());
  }, [dispatch]);
  return (
    <>
      <Button
        variant={'contained'}
        color={'primary'}
        onClick={handleOpen}
        startIcon={<LibraryAddIcon />}
        style={{ whiteSpace: 'nowrap' }}
      >
        {t('prescription-renewal')}
      </Button>
      {isOpen && <DialogRenewal onClose={handleClose} onRefresh={onRefresh} />}
    </>
  );
});
