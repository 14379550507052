import { DataGridLightRenderColumnProps } from '../../models';
import React, { memo } from 'react';
import style from './index.module.scss';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

interface Props extends DataGridLightRenderColumnProps<any, any> {}

export const ColumnBoolean = memo<Props>(({ row, field }) => {
  const value = row[field];
  return (
    <div className={style.wrap} title={value}>
      <CheckCircleIcon color={value ? 'primary' : 'disabled'} />
    </div>
  );
});

export const renderColumnBoolean = (props: DataGridLightRenderColumnProps<any, any>) => {
  return <ColumnBoolean {...props} />;
};
