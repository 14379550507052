import React, { useCallback } from 'react';
import { UserPatientProfileDietDiary } from 'services/user-patient-profile-diet-diaries';
import { IDataGridColumnEditorCustom } from 'components/data-grid/model';
import { GridModel } from '../../models';
import { useDataGridTasks } from 'components/data-grid/hooks';
import { EditorEmployee } from 'components/data-grid-editors/editor-employee';

interface IEditor extends Omit<IDataGridColumnEditorCustom, 'field'> {
  field: keyof UserPatientProfileDietDiary;
}
interface Props {
  row: GridModel;
  editor: IEditor;
  onClose: () => void;
}
export const EditorReplyEmployee: React.FC<Props> = ({ row, editor, onClose }) => {
  const { onTasks } = useDataGridTasks();

  const onHandleSubmit = useCallback(
    (formData: { data: string }) => {
      onTasks([
        [
          'pathData',
          {
            row: { ...row },
            value: {
              isViewed: true,
              userEmployeeProfileID: formData.data,
              replyDate: new Date().toISOString(),
            },
          },
        ],
      ]);
      onClose();
    },
    [onTasks, onClose, row],
  );

  return (
    <EditorEmployee
      // @ts-ignore
      row={row}
      // @ts-ignore
      editor={editor}
      data={row.userEmployeeProfileID}
      onSubmit={onHandleSubmit}
      onClose={onClose}
    />
  );
};
