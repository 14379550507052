import { DateValue } from 'utils/dates';

export enum MODEL_TYPE {
  SMS = 'sms',
  CALL = 'call',
  PRESCRIPTION = 'prescription',
  DOCUMENT = 'document',
  SIGNED_DOCUMENT = 'signed-document',
  USER_DOCUMENT = 'user-document',
  SUBSCRIPTION = 'subscription',
  SESSION = 'session',
  MEDICAL_INFORMATION = 'medical-information',
  CLINICAL_MEETING = 'clinical-meeting',
  SUPPORT_MEETING = 'support-meeting',
  TASK = 'task',
  NOTE = 'note',
  ACTIVITIES = 'patient-activity',
}

export type FilterModel = {
  types: MODEL_TYPE[];
  fromYear: number;
  toYear: number;
};

interface BaseRow {
  id: string;
  type: MODEL_TYPE;
  date: DateValue;
  title: string | undefined | null;
  employee: string | undefined | null;
  download: string | null | undefined;
}
export interface BaseRowActivities extends BaseRow {
  activities: number;
}

export interface RowSms extends BaseRow {
  type: MODEL_TYPE.SMS;
}
export interface RowCall extends BaseRow {
  type: MODEL_TYPE.CALL;
}
export interface RowPrescription extends BaseRowActivities {
  type: MODEL_TYPE.PRESCRIPTION;
}
export interface RowDocument extends BaseRow {
  type: MODEL_TYPE.DOCUMENT;
}
export interface RowSignedDocument extends BaseRow {
  type: MODEL_TYPE.SIGNED_DOCUMENT;
}
export interface RowUserDocument extends BaseRow {
  type: MODEL_TYPE.USER_DOCUMENT;
}
export interface RowSubscription extends BaseRow {
  type: MODEL_TYPE.SUBSCRIPTION;
  activities: number;
}
export interface RowSession extends BaseRow {
  type: MODEL_TYPE.SESSION;
}
export interface RowMedicalInformation extends BaseRow {
  type: MODEL_TYPE.MEDICAL_INFORMATION;
}
export interface RowClinicalMeeting extends BaseRowActivities {
  type: MODEL_TYPE.CLINICAL_MEETING;
  approveMeeting: boolean;

  arriveToClinicDateTime: null | string;
  completeSessionDateTime: null | string;
}
export interface RowSupportMeeting extends BaseRowActivities {
  type: MODEL_TYPE.SUPPORT_MEETING;
  userPatientProfileSessionID: string | null | undefined;
}
export interface RowTask extends BaseRowActivities {
  type: MODEL_TYPE.TASK;
  done: boolean;
}

export interface RowNote extends BaseRow {
  type: MODEL_TYPE.NOTE;
}
export interface RowActivities extends BaseRow {
  type: MODEL_TYPE.ACTIVITIES;
}

export type Row =
  | RowSms
  | RowCall
  | RowPrescription
  | RowDocument
  | RowSignedDocument
  | RowUserDocument
  | RowSubscription
  | RowSession
  | RowMedicalInformation
  | RowClinicalMeeting
  | RowSupportMeeting
  | RowTask
  | RowActivities
  | RowNote;
