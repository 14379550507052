import React, { memo, FC, useMemo } from 'react';
import { IDataGridMadel } from '../../model';
import { Button } from '@material-ui/core';
import { useI18n } from 'AurionCR/components';
import { useExportToExcel } from '../../../hooks';
import { createGetProps } from '../requests';
import RefreshIcon from '@material-ui/icons/Refresh';
import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';
import { format } from 'date-fns';
import { APP_FORMAT_EXCEL_DATE } from 'configs/const';

const ControlsDefault: FC<{
  model: IDataGridMadel;
  onTasks: (tasks: any[]) => void;
  filters: any;
  pagination: any;
  sortable: any;
}> = ({ model, filters, onTasks, pagination, sortable }) => {
  const { t } = useI18n();
  const { exportToExcel } = useExportToExcel();
  const controls = model?.toolbar?.controlsDefault;
  const isDisableResetFilters = useMemo(() => {
    return controls?.resetFilters && model?.toolbar?.filters?.length
      ? !(model?.toolbar?.filters || []).some((value) => {
          const val = filters[value.field];
          return val && val !== value.default;
        })
      : false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls?.resetFilters, filters, model?.toolbar?.filters]);
  // handles
  const onResetFilters = () => {
    const filters = (model.toolbar.filters || []).reduce((acc: any, value) => {
      acc[value.field] = value.default || '';
      return acc;
    }, {});
    onTasks([['filters', filters], ['getData']]);
  };
  const onExport = () => {
    const params = { ...createGetProps({ model, pagination, filters, sortable }) };
    const title = t(model?.toolbar?.excelTitle || model?.toolbar?.title || '');
    params.params.Take = null;
    params.params.Skip = 0;
    exportToExcel({
      ...params,
      columns: model.columns,
      title: `${format(new Date(), APP_FORMAT_EXCEL_DATE)}-${title}`,
    }).catch((e) => {
      console.error('export', model, e);
    });
  };

  if (!controls?.refresh && !controls?.new && !controls?.resetFilters && !controls?.export)
    return null;

  return (
    <ul className="toolbar-controls">
      {controls.resetFilters && (
        <li>
          <Button
            className="btn-reset-filters"
            title={controls.resetFilters.title || ''}
            variant="contained"
            color="primary"
            // size="small"
            onClick={onResetFilters}
            disabled={isDisableResetFilters}
            startIcon={<RefreshIcon />}
          >
            {t(controls.resetFilters.title || '')}
          </Button>
        </li>
      )}
      {controls.new && (
        <li>
          <Button
            className="btn-new"
            // @ts-ignore
            title={controls.new.title || ''}
            variant="contained"
            color="secondary"
            // size="small"
            onClick={(_) => onTasks([['onHandle', { type: 'new' }]])}
            startIcon={<AddIcon />}
          >
            {t(controls.new.title || '')}
          </Button>
        </li>
      )}
      {controls.export && (
        <li>
          <Button
            className="btn-export"
            title={controls.export.title || ''}
            variant="contained"
            color="primary"
            // size="small"
            onClick={onExport}
            disabled={!pagination?.total}
            startIcon={<GetAppIcon />}
          >
            {t(controls.export.title || '')}
          </Button>
        </li>
      )}
      {controls.refresh && (
        <li>
          <Button
            className="btn-refresh"
            title={controls.refresh.title || ''}
            variant="contained"
            color="primary"
            // size="small"
            onClick={(_) => onTasks([['getData']])}
            startIcon={<RefreshIcon />}
          >
            {t(controls.refresh.title || '')}
          </Button>
        </li>
      )}
    </ul>
  );
};

export default memo(ControlsDefault);
