import React, { memo, useCallback } from 'react';
import { SupportMeeting } from 'services/support-meetings';
import { Notebook } from 'services/notebook';
import { NotebookPreviewInput } from '../preview-input';
import { NotebookRenewalInput } from '../renewal-input';
import { NotebookCreateInput } from '../create-input';
import { usePermissions } from 'hooks/use-permissions';
import { isMutationFulfilled, isMutationRejected } from 'utils/rtk-query';
import {
  useSupportMeetingNotebookCreateComplete,
  useSupportMeetingNotebookRenewalComplete,
} from '../../hooks';
import { useAppDispatch } from 'store';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { parseErrorData } from 'utils/service';

type Meeting = Pick<
  SupportMeeting,
  | 'id'
  | 'userPatientProfileID'
  | 'userPatientProfileSessionID'
  | 'supportMeetingTypeID'
  | 'meetingFromDateTime'
  | 'meetingToDateTime'
>;
type MeetingNotebook = Pick<Notebook, 'labelKey'>;

interface Props {
  meeting: Meeting;
  notebook: null | MeetingNotebook;
  isRenewal: boolean;
  disabled: boolean;

  onDone: () => void;
}

export const NotebookPrescriptionInput = memo<Props>(
  ({ meeting, notebook, isRenewal, disabled, onDone }) => {
    const { userPatientProfileID, userPatientProfileSessionID } = meeting;

    const dispatch = useAppDispatch();

    const [triggerRenewalComplete] = useSupportMeetingNotebookRenewalComplete(meeting.id);
    const [triggerCreateComplete] = useSupportMeetingNotebookCreateComplete(meeting.id);

    const onRenewalComplete = useCallback(async () => {
      const res = await triggerRenewalComplete(meeting.id);

      if (isMutationFulfilled(res)) {
        onDone();
      }

      if (isMutationRejected(res)) {
        dispatch(notifyRequestResult(parseErrorData(res.error), 'error'));
      }
    }, [triggerRenewalComplete, meeting, onDone, dispatch]);
    const onCreateComplete = useCallback(
      async (session: { id: string }) => {
        const res = await triggerCreateComplete({
          id: meeting.id,
          userPatientProfileSessionID: session.id,
          userPatientProfileID: meeting.userPatientProfileID,
        });

        if (isMutationFulfilled(res)) {
          onDone();
        }
        if (isMutationRejected(res)) {
          dispatch(notifyRequestResult(parseErrorData(res.error), 'error'));
        }
      },
      [triggerCreateComplete, meeting, onDone, dispatch],
    );

    const isAllowToCreateAndRenewPrescriptions = usePermissions(
      'isAllowToCreateAndRenewPrescriptions',
    );

    if (userPatientProfileSessionID && userPatientProfileID) {
      return (
        <NotebookPreviewInput
          userPatientProfileSessionID={userPatientProfileSessionID}
          userPatientProfileID={userPatientProfileID}
        >
          {notebook?.labelKey}
        </NotebookPreviewInput>
      );
    }

    if (!isRenewal) {
      return (
        <NotebookCreateInput meeting={meeting} onComplete={onCreateComplete} disabled={disabled} />
      );
    }

    if (!isAllowToCreateAndRenewPrescriptions) {
      return null;
    }

    return (
      <NotebookRenewalInput
        supportMeetingID={meeting.id}
        userPatientProfileID={userPatientProfileID}
        onComplete={onRenewalComplete}
        disabled={disabled}
      />
    );
  },
);
