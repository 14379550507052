import React from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { Button } from '@material-ui/core';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import { DialogPreviousDrugs } from '../dialog-previous-drugs';
import { useOpen } from 'AurionCR/components/hooks';
import { useTranslate } from 'hooks/use-translate';

interface Classes {
  root: string;
}

interface Props {
  className?: string;
  classes?: Partial<Classes>;
  userPatientProfileID: string;
  userPatientProfilePrescriptionID: string;
  onRefresh: () => void;
}

export const ControlsAddPrev: React.FC<Props> = ({
  className,
  userPatientProfileID,
  userPatientProfilePrescriptionID,
  onRefresh,
  classes,
}) => {
  const { t } = useTranslate();
  const { isOpen, handleOpen, handleClose } = useOpen();
  return (
    <>
      <Button
        color={'primary'}
        variant={'contained'}
        className={clsx(style.root, classes?.root, className)}
        startIcon={<SelectAllIcon />}
        onClick={handleOpen}
      >
        {t('choose-drugs-from-prev')}
      </Button>
      {isOpen && (
        <DialogPreviousDrugs
          userPatientProfileID={userPatientProfileID}
          userPatientProfilePrescriptionID={userPatientProfilePrescriptionID}
          onClose={handleClose}
          onRefresh={onRefresh}
        />
      )}
    </>
  );
};
