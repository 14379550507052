import React, { forwardRef, memo, useCallback } from 'react';
import {
  FormControl,
  FormControlProps,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  FormHelperText,
  FormLabelProps,
  FormControlLabelProps,
} from '@material-ui/core';

export interface AppRadioGroupOption<T> extends Omit<FormControlLabelProps, 'control'> {
  value: T;
}

interface Props<T, O extends AppRadioGroupOption<T>>
  extends Omit<RadioGroupProps, 'value' | 'onChange'> {
  error?: boolean;
  helperText?: React.ReactNode;
  FormControlProps?: Partial<Omit<FormControlProps, 'error'>>;
  label?: React.ReactNode;
  LabelProps?: FormLabelProps;
  options: O[];
  value: T;
  onChange: (value: this['value'], option: O) => void;
  disabled?: boolean;
}

const AppRadioGroupComponent = <T, O extends AppRadioGroupOption<T>>({
  error,
  helperText,
  FormControlProps,
  label,
  LabelProps,
  options,
  onChange,
  disabled,
  ...rest
}: Props<T, O>) => {
  const factoryChange = useCallback(
    (option: O) => {
      return () => onChange(option.value, option);
    },
    // eslint-disable-next-line
    [onChange],
  );
  return (
    <FormControl error={error} {...FormControlProps}>
      {label && <FormLabel {...LabelProps}>{label}</FormLabel>}
      <RadioGroup {...rest}>
        {options.map((option) => (
          <FormControlLabel
            key={String(option.value)}
            control={<Radio />}
            {...option}
            disabled={disabled || option.disabled}
            onChange={factoryChange(option)}
          />
        ))}
      </RadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export const AppRadioGroup = memo(
  forwardRef(AppRadioGroupComponent),
) as typeof AppRadioGroupComponent;
