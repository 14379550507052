import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseErrorData } from 'utils/service';
import { ClinicalMeeting, ServiceClinicalMeetings } from 'services/clinical-meetings';
import {
  apiRemarkForPatientCallStatuses,
  REMARK_REASON,
} from 'services/remark-for-patient-call-status';
import { AppAsyncThunkConfig } from 'store/store';
import {
  ClinicalMeetingActivity,
  ServiceClinicalMeetingActivities,
} from 'services/clinical-meeting-activities';
import { selectAuthUser } from 'store/auth';
import { dateFormat } from 'utils/dates';
import { APP_FORMAT_DATE, APP_FORMAT_TIME } from 'configs/const';
import { i18nAppTranslator } from 'modules/i18n';
import * as dynamic from 'utils/dynamic-helpers';

interface MeetingInformation
  extends Pick<
    ClinicalMeeting,
    | 'meetingFromDateTime'
    | 'meetingToDateTime'
    | 'arriveToClinicDateTime'
    | 'completeSessionDateTime'
    | 'approveMeeting'
    | 'remarks'
  > {
  clinicalMeetingType: Pick<Components.Schemas.ClinicalMeetingType, 'title'>;
  clinicalMeetingSubject: Pick<Components.Schemas.ClinicalMeetingSubject, 'title'>;
  userPatientProfile: Pick<Components.Schemas.UserPatientProfile, 'fullName'>;
  userEmployeeProfile: Pick<Components.Schemas.UserEmployeeProfile, 'fullName'> & { role: string };
  organizerUserEmployeeProfile: Pick<Components.Schemas.UserEmployeeProfile, 'fullName'>;
}

const actionClinicalMeetingActivitiesGetMeetingAfterChanges = createAsyncThunk<
  MeetingInformation,
  string,
  AppAsyncThunkConfig
>(`CLINICAL_MEETING_ACTIVITIES/getMeetingAfterChanges`, async (meetingID) => {
  try {
    const { data } = await ServiceClinicalMeetings.getDynamic<MeetingInformation>(meetingID, {
      select: dynamic.select(
        'new { clinicalMeetingType.title } as clinicalMeetingType',
        'new { clinicalMeetingSubject.title } as clinicalMeetingSubject',
        'new { userPatientProfile.fullName } as userPatientProfile',
        'new { userEmployeeProfile.fullName, userEmployeeProfile.userCrmProfilePermission.title as role } as userEmployeeProfile',
        'new { organizerUserEmployeeProfile.fullName } as organizerUserEmployeeProfile',
        'meetingFromDateTime',
        'meetingToDateTime',
      ),
    });

    return data;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

const actionClinicalMeetingActivitiesCrateLog = createAsyncThunk<
  void,
  Pick<ClinicalMeetingActivity, 'clinicalMeetingID' | 'remarks'> & { reason: REMARK_REASON },
  AppAsyncThunkConfig
>(`CLINICAL_MEETING_ACTIVITIES/createLog`, async (input, { getState, dispatch }) => {
  try {
    const { reason, ...rest } = input;

    const res = dispatch(
      apiRemarkForPatientCallStatuses.endpoints.getRemarkByReason.initiate(reason),
    );

    const remark = await res.unwrap();
    res.unsubscribe();

    const user = selectAuthUser(getState());

    const remarkForPatientCallStatusID = remark?.id;

    if (!user) {
      throw new Error('current employee is required');
    }

    if (!remarkForPatientCallStatusID) {
      throw new Error('remark status is required');
    }

    await ServiceClinicalMeetingActivities.post({
      ...rest,
      remarkForPatientCallStatusID,
      entryDate: new Date().toISOString(),
      userEmployeeProfileID: user.appUserID,
    });
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionClinicalMeetingActivitiesCrateLogCreated = createAsyncThunk<
  void,
  { clinicalMeetingID: string },
  AppAsyncThunkConfig
>(`CLINICAL_MEETING_ACTIVITIES/createLogCreated`, async ({ clinicalMeetingID }, { dispatch }) => {
  try {
    const meetingInfo = await dispatch(
      actionClinicalMeetingActivitiesGetMeetingAfterChanges(clinicalMeetingID),
    ).unwrap();

    const remarks = i18nAppTranslator.tp('activity-clinical-meeting-created', {
      patient: { fullName: meetingInfo.userPatientProfile.fullName || '' },
      employee: {
        fullName: meetingInfo.userEmployeeProfile.fullName || '',
        role: meetingInfo.userEmployeeProfile.role,
      },
      date: dateFormat(meetingInfo.meetingFromDateTime, APP_FORMAT_DATE),
      time: dateFormat(meetingInfo.meetingFromDateTime, APP_FORMAT_TIME),
    });

    await dispatch(
      actionClinicalMeetingActivitiesCrateLog({
        clinicalMeetingID,
        remarks,
        reason: REMARK_REASON.AUTO,
      }),
    );
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionClinicalMeetingActivitiesCrateLogCallToPatient = createAsyncThunk<
  void,
  { clinicalMeetingID: string },
  AppAsyncThunkConfig
>(`clinicalMeetingID/crateLogCallToPatient`, async ({ clinicalMeetingID }, { dispatch }) => {
  try {
    const remarks = i18nAppTranslator.tp('activity-clinical-meeting-call-to-patient');

    await dispatch(
      actionClinicalMeetingActivitiesCrateLog({
        clinicalMeetingID,
        remarks,
        reason: REMARK_REASON.AUTO,
      }),
    );
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionClinicalMeetingActivitiesCrateLogSendSms = createAsyncThunk<
  void,
  { clinicalMeetingID: string; message: string },
  AppAsyncThunkConfig
>(
  `CLINICAL_MEETING_ACTIVITIES/crateLogSendSms`,
  async ({ clinicalMeetingID, message }, { dispatch }) => {
    try {
      const remarks = i18nAppTranslator.tp('activity-clinical-meeting-send-sms', {
        message,
      });

      await dispatch(
        actionClinicalMeetingActivitiesCrateLog({
          clinicalMeetingID,
          remarks,
          reason: REMARK_REASON.AUTO,
        }),
      );
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
