import React, { useCallback } from 'react';
import { GridControlsDropDownTemplateProps } from 'models/grid';
import { GridModel } from '../../models';
import { ListItemText, MenuItem } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import DeleteIcon from '@material-ui/icons/Delete';
import { composeFunctions } from 'utils';
import vars from 'styles/_variabels.scss';

interface Props extends GridControlsDropDownTemplateProps<GridModel> {}
export const ControlDelete: React.FC<Props> = ({ id, data, onTask, handleClose }) => {
  const { tp } = useTranslate();

  const onClick = useCallback(() => onTask([['deleteData', data]]), [onTask, data]);

  return (
    <MenuItem
      style={{ color: vars.colorRed }}
      disabled={data.isManually === false}
      onClick={composeFunctions(onClick, handleClose)}
    >
      <DeleteIcon fontSize="small" />
      <ListItemText> {tp('delete')}</ListItemText>
    </MenuItem>
  );
};
