import React, { useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { useOpen } from 'AurionCR/components/hooks';
import { DialogHeading } from 'components/dialog-title';
import { SelectDrugs } from 'components/select-drugs';
import { DialogGenerateForm29 } from 'components/dialog-generate-form29';
import { useCurrentUser, useSourceDoctors } from 'components/hooks';
import { Controller, useForm } from 'react-hook-form';
import { SelectEmployee } from 'components/select-employee';
import * as yup from 'yup';
import { useEffectError, useFieldProps, useMountedRef } from 'hooks';
import { ButtonCancel, ButtonSave } from 'components/app-buttons';
import { yupResolver } from '@hookform/resolvers/yup';
import { apiUserPatientProfileDocuments } from 'services/user-patient-profile-documents';
import { getBase64FileSize } from 'utils/file-uploader';
import { isMutationFulfilled } from 'utils/rtk-query';
import { useAppDispatch } from 'store';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';

enum STEP {
  INIT = 1,
  FORM,
}

const schema = yup.object({
  drugID: yup.string().typeError('rule-string').required('rule-required').default(''),
  doctorID: yup.string().typeError('rule-string').required('rule-required').default(''),
});

const useCreateDocumentMutation =
  apiUserPatientProfileDocuments.useCreateUserPatientProfileDocumentGimel29Mutation;

type GenerateResult = {
  value: string;
  valueWatermark: string;
  drug: Pick<Components.Schemas.Drug, 'catalogName'>;
};

interface FlowProps {
  patientID: string;
  onClose: () => void;
  onDone: () => void;
}
const Flow: React.FC<FlowProps> = ({ patientID, onClose, onDone }) => {
  const { t } = useTranslate();
  const { appUserID } = useCurrentUser();
  const [step, setStep] = useState(STEP.INIT);
  const { control, errors, handleSubmit, watch } = useForm({
    defaultValues: schema.cast({}),
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

  const sourceDoctors = useSourceDoctors(true);

  const onNext = useCallback(() => {
    setStep(STEP.FORM);
  }, []);

  const [create, resultCreate] = useCreateDocumentMutation();
  useEffectError(resultCreate.error);

  const onCreateCopy = useCallback(
    async (input: GenerateResult) => {
      const fileName = [t('form-29'), input.drug.catalogName, t('copy')].join(' - ');

      return create({
        isForm29: true,
        isCopy: true,
        userPatientProfileID: patientID,
        userEmployeeProfileID: appUserID,
        fileName: fileName,
        documentURL: {
          value: input.valueWatermark,
          type: 'application/pdf',
          name: `form-gimel-29__free__${new Date().toISOString()}__copy.pdf`,
          size: getBase64FileSize(input.valueWatermark),
        },
        documentForPrintURL: null,
      });
    },
    [patientID, appUserID, t, create],
  );

  const [createCopy, resultCreateCopy] = useCreateDocumentMutation();
  useEffectError(resultCreateCopy.error);
  const onCreate = useCallback(
    async (input: GenerateResult) => {
      const fileName = [t('form-29'), input.drug.catalogName].join(' - ');

      return createCopy({
        isForm29: true,
        isCopy: false,
        userPatientProfileID: patientID,
        userEmployeeProfileID: appUserID,
        fileName: fileName,
        documentURL: {
          value: input.value,
          type: 'application/pdf',
          name: `form-gimel-29__free__${new Date().toISOString()}.pdf`,
          size: getBase64FileSize(input.value),
        },
        documentForPrintURL: null,
      });
    },
    [patientID, appUserID, t, createCopy],
  );

  const drugID = watch('drugID');
  const doctorID = watch('doctorID');

  const onGenerated = useCallback(
    async (input: GenerateResult) => {
      const results = await Promise.all([onCreate(input), onCreateCopy(input)]);

      const isSuccess = results.every(isMutationFulfilled);
      if (isSuccess) {
        onDone();
      }
    },
    [onCreateCopy, onCreate, onDone],
  );

  if (step === STEP.INIT) {
    return (
      <Dialog open={true} fullWidth={true} maxWidth={'xs'} onClose={onClose}>
        <DialogHeading title={t('select-drug')} onClose={onClose} />
        <DialogContent dividers>
          <Controller
            name={'doctorID'}
            control={control}
            render={(renderProps) => {
              const props = getFieldProps(renderProps);
              return (
                <SelectEmployee
                  {...props}
                  options={sourceDoctors.data}
                  loading={sourceDoctors.loading}
                  disableClearable
                />
              );
            }}
          />
          <Controller
            name={'drugID'}
            control={control}
            render={(renderProps) => {
              const props = getFieldProps(renderProps);
              return <SelectDrugs {...props} />;
            }}
          />
        </DialogContent>
        <DialogActions>
          <ButtonCancel isBack={false} onClick={onClose} />
          <ButtonSave isCreate={false} onClick={handleSubmit(onNext)}>
            {t('next')}
          </ButtonSave>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <DialogGenerateForm29
      onClose={onClose}
      onGenerated={onGenerated}
      drugID={drugID}
      patientID={patientID}
      doctorID={doctorID}
    />
  );
};

interface Props {
  patientID: string;
  onDone: () => void;
}
export const ButtonGenerateGimel29: React.FC<Props> = ({ patientID, onDone }) => {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();
  const { isOpen, handleOpen, handleClose } = useOpen();
  const mountedRef = useMountedRef();

  const onDoneWrapper = useCallback(() => {
    onDone();
    if (mountedRef.current) {
      handleClose();
    }

    dispatch(notifyRequestResult(t('success-generated'), 'success'));
  }, [onDone, handleClose, mountedRef, dispatch, t]);

  return (
    <>
      <Button color={'primary'} variant={'contained'} onClick={handleOpen}>
        {t('generate-gimel-29')}
      </Button>
      {isOpen && <Flow onClose={handleClose} patientID={patientID} onDone={onDoneWrapper} />}
    </>
  );
};
