import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IRoute, useI18n, useRouter } from 'AurionCR/components';
import { TabsNav } from './navigations';
import style from './index.module.scss';
import { LayoutContent } from 'components/layout-content';
import { Typography } from '@material-ui/core';

export const ContentLayout = memo(({ children }) => {
  const { t } = useI18n();
  const router = useRouter();
  // state
  const { map }: { map: { [key: string]: IRoute } } = useSelector((state) => (state as any).routes);
  const parent = useMemo(() => {
    const route = router.pathname.split('/');
    if (route.length > 3) {
      return map[route.slice(0, -1).join('/')] || null;
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, router.pathname]);
  // render

  return parent ? (
    <LayoutContent
      classes={{
        root: style.content,
        main: style.main,
      }}
      header={
        <Typography variant="h1" color="secondary">
          {t(parent?.title)}
        </Typography>
      }
    >
      <div className={style.wrapper}>
        <TabsNav routes={parent.routes || []} />
        <div className={style.rightSide}>{children}</div>
      </div>
    </LayoutContent>
  ) : (
    <main className={'root-page-content'}>{children}</main>
  );
});
