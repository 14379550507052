import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import React from 'react';
import style from './index.module.scss';
import { Box, Button, Drawer, DrawerProps, Paper, Typography } from '@material-ui/core';
import { Stack } from 'components/stack';
import { useTranslate } from 'hooks/use-translate';

interface Props extends DrawerProps {
  isOpen: boolean;
  onAccept: () => void;
  onClose: () => void;
}

export const PopupNotification: React.FC<Props> = ({ isOpen, onClose, onAccept, ...rest }) => {
  const { t } = useTranslate();

  return (
    <Drawer anchor={'top'} open={isOpen} classes={{ paper: style.paper }} {...rest}>
      <Paper elevation={1} className={style.wrap}>
        <Stack direction={'row'} spacing={1.6} mb={2}>
          <div>
            <NotificationsActiveOutlinedIcon
              color={'primary'}
              fontSize={'inherit'}
              style={{ fontSize: '6rem' }}
            />
          </div>
          <Box alignSelf={'center'} py={0.5}>
            <Typography variant={'h2'} color={'secondary'}>
              {t('popup-notification-title')}
            </Typography>
            <Typography color={'secondary'}>{t('popup-notification-message')}</Typography>
          </Box>
        </Stack>
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={1.6}>
          <Button variant={'contained'} color={'primary'} size={'small'} onClick={onAccept}>
            {t('allow')}
          </Button>
          <Button variant={'outlined'} color={'primary'} size={'small'} onClick={onClose}>
            {t('no-thanks')}
          </Button>
        </Stack>
      </Paper>
    </Drawer>
  );
};
