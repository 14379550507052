import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServiceLanguages } from 'services/languages';
import { parseErrorData } from 'utils/service';
import { selectLanguagesLanguageID } from './selectors';
import { AppAsyncThunkConfig } from 'store/store';

export const actionLanguagesFetch = createAsyncThunk(`TRANSLATE/languagesFetch`, async () => {
  try {
    const {
      data: { value },
    } = await ServiceLanguages.getAppLanguages();

    return value;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionLanguagesInit = createAsyncThunk<
  { languageID: string },
  void,
  AppAsyncThunkConfig
>(`TRANSLATE/languagesInit`, async (_, { dispatch, getState }) => {
  try {
    const languages = await dispatch(actionLanguagesFetch()).unwrap();

    if (languages.length === 0) {
      throw new Error('languages list is empty');
    }

    let languageID = selectLanguagesLanguageID(getState());

    if (!languageID) {
      languageID = languages[0].id;
    }

    if (!languageID) {
      throw new Error('languages: unexpected-behaviour');
    }

    return { languageID };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
