import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DialogHeading } from 'components/dialog-title';
import { Dialog, DialogActions, DialogContent, List, Typography } from '@material-ui/core';
import { apiClinicalMeetings } from 'services/clinical-meetings';
import { useEffectError, useMap } from 'hooks';
import { useTranslate } from 'hooks/use-translate';
import { ButtonCancel, ButtonSave } from 'components/app-buttons';
import { Loading } from 'AurionCR/components';
import { Meeting, MeetingEmployee, MeetingType, MeetingListItem } from 'components/meetings-list';
import { produce } from 'immer';
import { AppCheckbox } from 'components/app-checkbox';
import { DataGridEmpty } from 'components/data-grid-light';
import { apiSupportMeeting } from 'services/support-meetings';
import { isMutationFulfilled } from 'utils/rtk-query';
import { Alert } from '@material-ui/lab';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import style from './index.module.scss';

enum TYPE {
  CLINICAL = 'clinical',
  SUPPORT = 'support',
}

const factoryChangeMeeting = (item: Item) => {
  return (items: Item[]) => {
    const index = items.findIndex((meeting) => meeting.id === item.id);

    return produce(items, (draft) => {
      if (index === -1) {
        draft.push(item);
        return;
      }
      draft.splice(index, 1);
    });
  };
};

type Item = { id: string; type: TYPE };

type ListItem = Meeting & { employee: Partial<MeetingEmployee>; type: Partial<MeetingType> };

const useFetchClinicalMeetingsQuery =
  apiClinicalMeetings.useGetClinicalMeetingsFutureUnhandledQuery;
const useCancelClinicalMeetingMutation = apiClinicalMeetings.useCancelClinicalMeetingAutoMutation;

const useFetchSupportMeetingsQuery = apiSupportMeeting.useGetSupportMeetingsFutureUnhandledQuery;
const useCancelSupportMeetingMutation = apiSupportMeeting.useCancelSupportMeetingAutoMutation;

const useDeactivatePatientMutation = apiUserPatientProfile.useDeactivatePatientMutation;

interface MeetingsListProps {
  title: React.ReactNode;
  list: ListItem[];
  disabled: boolean;
  getSelected: (item: ListItem) => boolean;
  onSelect: (item: ListItem) => void;
  isLoading: boolean;
}
const MeetingsList = memo<MeetingsListProps>(
  ({ list, disabled, getSelected, onSelect, isLoading, title }) => {
    const factorySelect = (item: ListItem) => {
      return () => onSelect(item);
    };
    const isEmpty = list.length === 0;
    return (
      <div className={style.meetings}>
        <Typography component={'h4'} color={'secondary'} style={{ fontWeight: 600 }}>
          {title}
        </Typography>
        {isEmpty ? (
          <DataGridEmpty />
        ) : (
          <List className={style.list}>
            {list.map((meeting) => {
              return (
                <MeetingListItem
                  selected={getSelected(meeting)}
                  meeting={meeting}
                  employee={meeting.employee}
                  type={meeting.type}
                  disabled={disabled}
                  onSelect={factorySelect(meeting)}
                  multiple={true}
                />
              );
            })}
          </List>
        )}
        {isLoading && <Loading />}
      </div>
    );
  },
);

interface Props {
  userPatientProfileID: string;
  onClose: () => void;
  onDone: () => void;
}
export const DialogPatientDeactivate = memo<Props>(({ userPatientProfileID, onClose, onDone }) => {
  const { tp } = useTranslate();
  const refSubmitCount = useRef(0);

  const [deactivatePatient, resultDeactivate] = useDeactivatePatientMutation();
  useEffectError(resultDeactivate.error);

  const [items, setItems] = useState<Array<Item>>([]);
  const mapSelected = useMap(items, 'id');
  const getSelected = (meeting: { id: string }) => {
    return !!mapSelected[meeting.id];
  };

  const resultClinical = useFetchClinicalMeetingsQuery(
    { userPatientProfileID },
    { refetchOnMountOrArgChange: true },
  );
  useEffectError(resultClinical.error);
  const clinicalMeetings = useMemo(() => {
    return (resultClinical.data || []).map((meeting) => ({ ...meeting, type: meeting.subject }));
  }, [resultClinical.data]);
  const onSelectClinical = (meeting: { id: string }) => {
    setItems(factoryChangeMeeting({ id: meeting.id, type: TYPE.CLINICAL }));
  };
  const [cancelClinical, resultCancelClinical] = useCancelClinicalMeetingMutation();
  useEffectError(resultCancelClinical.error);

  const resultSupport = useFetchSupportMeetingsQuery(
    { userPatientProfileID },
    { refetchOnMountOrArgChange: true },
  );
  useEffectError(resultSupport.error);
  const supportMeetings = useMemo(() => {
    return resultSupport.data || [];
  }, [resultSupport.data]);
  const onSelectSupport = (meeting: { id: string }) => {
    setItems(factoryChangeMeeting({ id: meeting.id, type: TYPE.SUPPORT }));
  };
  const [cancelSupport, resultCancelSupport] = useCancelSupportMeetingMutation();
  useEffectError(resultCancelSupport.error);

  const options = useMemo(() => {
    return [...clinicalMeetings, ...supportMeetings];
  }, [clinicalMeetings, supportMeetings]);

  const onChangeAll = (value: boolean) => {
    if (value) {
      return onSelectAll();
    }
    return setItems([]);
  };
  const onSelectAll = () => {
    setItems([
      ...clinicalMeetings.map(({ id }) => ({ id, type: TYPE.CLINICAL })),
      ...supportMeetings.map(({ id }) => ({ id, type: TYPE.SUPPORT })),
    ]);
  };

  const isAnySelected = items.length > 0;
  const isAllSelected = items.length === options.length;

  const isLoading =
    resultClinical.isFetching ||
    resultSupport.isLoading ||
    resultCancelClinical.isLoading ||
    resultCancelSupport.isLoading ||
    resultDeactivate.isLoading;

  const onSubmit = useCallback(async () => {
    refSubmitCount.current++;

    const res = await Promise.all(
      items.map((item) => {
        if (item.type === TYPE.CLINICAL) {
          return cancelClinical(item.id);
        }
        return cancelSupport(item.id);
      }),
    );

    if (res.length) {
      resultClinical.refetch();
      resultSupport.refetch();
    }

    const isAllSuccess = res.every((r) => isMutationFulfilled(r));

    if (!isAllSuccess) {
      return;
    }

    const resDeactivate = await deactivatePatient(userPatientProfileID);

    if (isMutationFulfilled(resDeactivate)) {
      onDone();
      // onSend({ id: resDeactivate.data.taskID });
    }
  }, [
    cancelClinical,
    cancelSupport,
    deactivatePatient,
    items,
    onDone,
    // onSend,
    userPatientProfileID,
    resultClinical,
    resultSupport,
  ]);

  const onCloseWrap = () => {
    if (refSubmitCount.current !== 0) {
      return onClose();
    }
    return onSubmit();
  };

  // auto continue if there are no any meetings
  useEffect(() => {
    if (refSubmitCount.current > 0) return;

    if (!resultClinical.isSuccess) return;
    if (!resultSupport.isSuccess) return;

    if (resultClinical.isFetching) return;
    if (resultSupport.isFetching) return;

    if (resultClinical.data?.length > 0) return;
    if (resultSupport.data?.length > 0) return;

    onSubmit();
  }, [resultClinical, resultSupport, onSubmit]);

  if (supportMeetings.length === 0 && clinicalMeetings.length === 0) return null;

  return (
    <Dialog open={true} fullWidth={true} maxWidth={'md'}>
      <DialogHeading isLoading={isLoading} title={tp('deactivate-patient')} onClose={onCloseWrap} />
      <DialogContent className={style.dialogContent}>
        <Alert severity={'info'} className={style.alert}>
          {tp('deactivate-patient-meetings-helper-text')}
        </Alert>
        <AppCheckbox
          value={isAnySelected && isAllSelected}
          indeterminate={isAnySelected && !isAllSelected}
          label={tp('select-all')}
          onChange={onChangeAll}
        />
        <div className={style.column}>
          <MeetingsList
            title={tp('clinical-meetings')}
            isLoading={resultClinical.isLoading}
            list={clinicalMeetings}
            onSelect={onSelectClinical}
            getSelected={getSelected}
            disabled={isLoading}
          />

          <MeetingsList
            title={tp('support-meetings')}
            isLoading={resultSupport.isLoading}
            list={supportMeetings}
            onSelect={onSelectSupport}
            getSelected={getSelected}
            disabled={isLoading}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <ButtonCancel isBack={false} onClick={onCloseWrap}>
          {tp('skip')}
        </ButtonCancel>
        <ButtonSave disabled={isLoading} isCreate={false} onClick={onSubmit}>
          {tp('submit')}
        </ButtonSave>
      </DialogActions>
    </Dialog>
  );
});
