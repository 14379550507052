import React, { useMemo } from 'react';
import DataGrid from 'components/data-grid';
import { GridConfig } from './grid-config';
import style from './index.module.scss';

interface Props {
  date: Date;
}
export const MeetingsView: React.FC<Props> = ({ date }) => {
  const config = useMemo<any>(() => {
    return GridConfig({ date });
  }, [date]);

  return (
    <div className={style.root}>
      <DataGrid config={config} />
    </div>
  );
};
