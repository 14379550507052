const API_ENDPOINT = 'NotebookHelpers';

export const API_NOTEBOOKS_HELPERS = {
  GET_ALL_DYNAMIC: (userPatientProfileID: string) =>
    `${API_ENDPOINT}/GetPatientMedicalInformationsDynamic?UserPatientProfileID=${userPatientProfileID}`,
};

export interface NotebookHelperMedicalInformation {
  id: string;
  rowIndex: number;
  answer: string;
  internalSystemValue: string;
  rank: number;
  externalSystemValue: string;
  question: string;
  entryDate: string;
  notebookTitle: string;
  employeeName: string;
}
