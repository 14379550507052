import { iTMActionTypes, iTaskManager, iTMCalendarSupportMeeting } from './@type';
import { createDefaultMeetingTimes } from 'utils/app-helpers';
import { SupportMeetingActivity } from 'services/support-meeting-activities';
import { ClinicalMeeting } from 'services/clinical-meetings';

export const createTimes = () => {
  return createDefaultMeetingTimes();
};
export const TMReset = () => {
  return { type: iTMActionTypes.TM_RESET } as const;
};
export const TMMerge = (payload: Partial<iTaskManager>) => {
  return { type: iTMActionTypes.TM_MERGE, payload } as const;
};
export const TMViewMerge = (
  payload:
    | (Partial<iTaskManager['calendar']> & { view: iTaskManager['view'] })
    | (Partial<iTaskManager['list']> & { view: iTaskManager['view'] }),
) => {
  return { type: iTMActionTypes.TM_VIEW_MERGE, payload } as const;
};
export const TMFilterMerge = (
  payload:
    | (Partial<iTaskManager['calendar']['filters']> & { view: iTaskManager['view'] })
    | (Partial<iTaskManager['list']['filters']> & { view: iTaskManager['view'] }),
) => {
  return { type: iTMActionTypes.TM_FILTER_MERGE, payload } as const;
};
// saga
export const TMInit = () => {
  return { type: iTMActionTypes.TM_INIT } as const;
};
export const TMRefresh = (refreshAnyway = false) => {
  return { type: iTMActionTypes.TM_REFRESH, payload: refreshAnyway } as const;
};
export const actionTMRefreshActivities = () => {
  return { type: iTMActionTypes.TM_REFRESH_ACTIVITIES } as const;
};
export const TMDeleteMeeting = (payload: { id: string }) => {
  return { type: iTMActionTypes.TM_DELETE_MEETING, payload } as const;
};
export const TMNotebookPath = (payload: { id: string; userPatientProfileSessionID: string }) => {
  return { type: iTMActionTypes.TM_NOTEBOOK_PATH, payload } as const;
};
export const TMChangeView = (payload: iTaskManager['view']) => {
  return { type: iTMActionTypes.TM_CHANGE_VIEW, payload } as const;
};
export const TMMoveAll = (payload: { date: Date } & Partial<SupportMeetingActivity>) => {
  return { type: iTMActionTypes.TM_MOVE_ALL, payload } as const;
};
export const TMMoveItem = (
  payload: { date: Date; data: iTMCalendarSupportMeeting } & Partial<SupportMeetingActivity>,
) => {
  return { type: iTMActionTypes.TM_MOVE_ITEM, payload } as const;
};

export const TMItemData = (payload: { id: string }) => {
  return { type: iTMActionTypes.TM_LOAD_ITEM_DATA, payload } as const;
};

export const actionTMSendMessageBulk = (payload: { message: string }) => {
  return { type: iTMActionTypes.TM_SEND_MESSAGES_BULK, payload } as const;
};
export const actionTMExportToExcel = () => {
  return { type: iTMActionTypes.EXPORT_TO_EXCEL } as const;
};

export const actionTMSelectItem = (payload: { itemID: string; value: boolean }) => {
  return { type: iTMActionTypes.TM_SELECT, payload } as const;
};

export const actionTMSetClinicalMeetings = (
  payload: Pick<ClinicalMeeting, 'id' | 'userPatientProfileID'>[],
) => {
  return { type: iTMActionTypes.SET_CLINICAL_MEETINGS, payload } as const;
};

export type iTMAction = ReturnType<
  | typeof TMReset
  | typeof TMMerge
  | typeof TMViewMerge
  | typeof TMFilterMerge
  | typeof TMInit
  | typeof TMRefresh
  | typeof actionTMRefreshActivities
  | typeof TMDeleteMeeting
  | typeof TMNotebookPath
  | typeof TMChangeView
  | typeof TMMoveAll
  | typeof TMMoveItem
  | typeof TMItemData
  | typeof actionTMSendMessageBulk
  | typeof actionTMExportToExcel
  | typeof actionTMSelectItem
  | typeof actionTMSetClinicalMeetings
>;
