import React from 'react';
import { BulkActionComponentProps } from '../../models';
import { DialogConfirm } from 'components/dialog-confirm';
import { useTranslate } from 'hooks/use-translate';
import { apiUserPatientProfileDietDiaries } from 'services/user-patient-profile-diet-diaries';
import { useEffectError } from 'hooks';
import { isMutationFulfilled } from 'utils/rtk-query';

const endpoint = apiUserPatientProfileDietDiaries.endpoints.deleteUserPatientProfileDietDiaryBulk;
const useDeleteMutation = endpoint.useMutation;

export const BulkActionDelete: React.FC<BulkActionComponentProps> = ({
  onClose,
  onRefresh,
  selected,
}) => {
  const { tp } = useTranslate();
  const [performDelete, resultDelete] = useDeleteMutation();
  useEffectError(resultDelete.error);

  const isLoading = resultDelete.isLoading;
  const onConfirm = async () => {
    const res = await performDelete({ ids: selected.map(({ id }) => id) });

    if (isMutationFulfilled(res)) {
      onRefresh();
      onClose();
    }
  };
  const onCancel = onClose;

  return (
    <DialogConfirm
      message={tp('confirm-delete-bulk', { count: selected.length })}
      onConfirm={onConfirm}
      onCancel={onCancel}
      isLoading={isLoading}
    />
  );
};
