import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DialogSendMessage,
  schemaEmailModel,
  SendMessageForm,
} from 'components/dialog-send-message';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffectError, useMountedRef } from 'hooks';
import { apiUserPatientPrescriptions } from 'services/user-patient-profile-prescriptions';
import { apiEmailMessages } from 'services/email-messages';
import { useAppDispatch } from 'store';
import { useTranslate } from 'hooks/use-translate';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { CLINIC_EMAIL } from 'configs/const';
import { isMutationRejected } from 'utils/rtk-query';
import { DialogAttachments } from './components/dialog-attachments';
import { dateFormat } from 'utils/dates';
import * as yup from 'yup';
import { InferType } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ButtonBase, Divider, Typography } from '@material-ui/core';
import { apiUserPatientProfilePrescriptionActivities } from 'services/user-patient-profile-prescription-activities';

enum STEP {
  ATTACHES = 1,
  FORM = 2,
}

const useFetchData = apiUserPatientPrescriptions.useGetMedicalPrescriptionDetailsForUserCopyQuery;
const useSendMessageMutation = apiEmailMessages.useSendEmailToPatientMutation;

const schemaFile = yup.object({
  id: yup.string().required(),
  title: yup.string().required(),
  url: yup.string().required(),
});
type ModelFile = InferType<typeof schemaFile>;

const schema = schemaEmailModel.shape({
  attaches: yup.array().of(schemaFile).required().min(1),
});
type Model = InferType<typeof schema>;

interface Props {
  userPatientProfilePrescriptionID: string;
  onClose: () => void;
  onDone: () => void;
}

export const MedicalPrescriptionDialogSendCopyToPatient: React.FC<Props> = ({
  userPatientProfilePrescriptionID,
  onClose,
  onDone,
}) => {
  const [isWasForm, setItWasForm] = useState(false);
  const [step, setStep] = useState(STEP.ATTACHES);
  const { t, tp } = useTranslate();
  const dispatch = useAppDispatch();

  const { data: prescription, isFetching, error } = useFetchData(userPatientProfilePrescriptionID);
  useEffectError(error);

  const defaultValues = useMemo<Model>(() => {
    return schema.cast({
      toEmails: [],
      subject: t('email-patient-subject'),
      replyToEmail: t('email-reply-to'),
      fromDisplayName: t('email-display-name'),
      html: t('email-patient-html'),
      attaches: [],
    });
  }, [t]);

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });
  const { reset, setValue, watch } = formMethods;

  const attaches = watch('attaches');

  useEffect(() => {
    if (!prescription) return;

    reset(
      schema.cast({
        ...defaultValues,
        toEmails: [prescription.patient.email, CLINIC_EMAIL].filter(Boolean) as string[],
      }),
    );
  }, [prescription, reset, defaultValues]);

  const [triggerSend, resultSend] = useSendMessageMutation();
  useEffectError(resultSend.error);

  const mountedRef = useMountedRef();
  const onSubmit = useCallback(
    async (formData: Model) => {
      if (!prescription) return;

      const { attaches, ...rest } = formData;

      const result = await triggerSend({
        ...rest,
        userPatientProfileID: prescription.userPatientProfileID,
        attachments: attaches.map((file) => file.url),
      });

      if (isMutationRejected(result)) {
        return;
      }

      dispatch(notifyRequestResult(t('success-send-and-complete'), 'success'));
      await dispatch(
        apiUserPatientProfilePrescriptionActivities.endpoints.createUserPatientProfilePrescriptionActivity.initiate(
          {
            userPatientProfilePrescriptionID,
            remarks: tp('activity-patient-prescription-send-copy-to-patient'),
          },
        ),
      );

      if (mountedRef.current) {
        onDone();
        onClose();
      }
    },
    [
      triggerSend,
      mountedRef,
      onDone,
      onClose,
      dispatch,
      t,
      tp,
      prescription,
      userPatientProfilePrescriptionID,
    ],
  );

  const isLoading = resultSend.isLoading;

  const title = useMemo(() => {
    if (isFetching) {
      return t('loading');
    }
    if (!prescription) {
      return t('unknown');
    }
    return [
      prescription.patient.fullName,
      prescription.patient.idNumber,
      dateFormat(prescription.entryDate),
    ]
      .filter(Boolean)
      .join(' | ');
  }, [prescription, t, isFetching]);

  const files = useMemo(() => {
    return [
      ...(prescription
        ? [
            { id: 'prescription', title: t('prescription'), url: prescription.prescriptionURL },
            ...prescription.documents.map((doc) => {
              return {
                id: doc.id,
                title: doc.fileName,
                url: doc.documentURL,
              };
            }),
          ]
        : []),
    ];
  }, [prescription, t]);

  const onAttach = useCallback(
    (files: ModelFile[]) => {
      setValue('attaches', files);
      setStep(STEP.FORM);
      setItWasForm(true);
    },
    [setValue],
  );

  const onReAttach = useCallback(() => {
    setStep(STEP.ATTACHES);
  }, []);
  const onCloseAttach = useCallback(() => {
    if (isWasForm) {
      return setStep(STEP.FORM);
    }
    onClose();
  }, [onClose, isWasForm]);

  if (step === STEP.ATTACHES) {
    return (
      <DialogAttachments
        value={attaches}
        title={title}
        options={files}
        onClose={onCloseAttach}
        onChange={onAttach}
      />
    );
  }

  return (
    <DialogSendMessage
      title={title}
      DialogProps={{
        maxWidth: 'lg',
      }}
      onClose={onClose}
      onSubmit={formMethods.handleSubmit(onSubmit)}
      isLoading={isLoading || isFetching}
    >
      <FormProvider {...formMethods}>
        <SendMessageForm isLoading={isLoading || isFetching} />
        <Divider />
        <Typography
          variant={'subtitle1'}
          component={ButtonBase}
          color={'primary'}
          style={{ fontWeight: 'bold', marginTop: '1rem' }}
          onClick={onReAttach}
          disabled={isLoading || isFetching}
        >
          {tp('attachments')} ({attaches.length})
        </Typography>
      </FormProvider>
    </DialogSendMessage>
  );
};
