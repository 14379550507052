import { DateValue } from 'utils/dates';
const API_ENDPOINT = 'ClinicalMeetings';

export const API_CLINICAL_MEETINGS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<ClinicalMeeting, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<ClinicalMeeting, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export class ClinicalMeeting
  implements
    Pick<
      Components.Schemas.ClinicalMeeting,
      | 'id'
      | 'isActive'
      | 'clinicalMeetingTypeID'
      | 'clinicalMeetingSubjectID'
      | 'userPatientProfileID'
      | 'userEmployeeProfileID'
      | 'meetingFromDateTime'
      | 'meetingToDateTime'
      | 'arriveToClinicDateTime'
      | 'completeSessionDateTime'
      | 'organizerUserEmployeeProfileID'
      | 'userPatientProfileSessionID'
      | 'approveMeeting'
      | 'remarks'
    >
{
  id!: string;
  isActive = true;
  clinicalMeetingTypeID = '';
  clinicalMeetingSubjectID = '';
  userPatientProfileID = '';
  userEmployeeProfileID = '';
  organizerUserEmployeeProfileID = '';

  meetingFromDateTime = '';
  meetingToDateTime = '';
  arriveToClinicDateTime?: string | null = null;
  completeSessionDateTime?: string | null = null;
  userPatientProfileSessionID?: string | null = undefined;

  approveMeeting = false;
  remarks = '';
}

export class ClinicalMeetingCreate extends ClinicalMeeting {
  date: string | Date | undefined = new Date().toISOString();
}

export type iEmployeeMeetingDateTime = [DateValue, DateValue] | null | undefined;
export class ClinicalMeetingCreateModel extends ClinicalMeetingCreate {
  doctorID!: string;

  meetingDateTimeDoctor?: iEmployeeMeetingDateTime = undefined;

  meetingDateTimeUserEmployeeProfile?: iEmployeeMeetingDateTime = undefined;
}

export interface ClinicalMeetingGetActivitiesInput {
  userPatientProfileID: string;
  dateRange: DateValue[];
}

export interface ClinicalMeetingGetActivitiesItem
  extends Pick<
    Components.Schemas.ClinicalMeeting,
    | 'id'
    | 'meetingFromDateTime'
    | 'meetingToDateTime'
    | 'approveMeeting'
    | 'arriveToClinicDateTime'
    | 'completeSessionDateTime'
  > {
  employee: Components.Schemas.UserEmployeeProfile['fullName'];
  clinicalMeetingTypeTitle: Components.Schemas.ClinicalMeetingType['title'];
  clinicalMeetingSubjectTitle: Components.Schemas.ClinicalMeetingSubject['title'];
  activities: number;
}

export interface ClinicalMeetingSendSmsToPatient
  extends Pick<
    ClinicalMeeting,
    'meetingFromDateTime' | 'meetingToDateTime' | 'userEmployeeProfileID' | 'userPatientProfileID'
  > {
  patient: Pick<Components.Schemas.UserPatientProfile, 'firstName' | 'lastName' | 'mobilePhone'>;
  meetingEmployee: Pick<Components.Schemas.UserEmployeeProfile, 'firstName' | 'lastName'>;
  clinicalMeetingType: Pick<Components.Schemas.ClinicalMeetingType, 'meetingTypeKey'>;
}

export type CheckClinicMeetingType = {
  clinicalMeetingType: Pick<Components.Schemas.ClinicalMeetingType, 'meetingTypeKey'>;
};

export interface ClinicalMeetingExcel
  extends Pick<
    Components.Schemas.ClinicalMeeting,
    | 'id'
    | 'meetingFromDateTime'
    | 'meetingToDateTime'
    | 'approveMeeting'
    | 'remarks'
    | 'completeSessionDateTime'
    | 'arriveToClinicDateTime'
  > {
  clinicalMeetingType: Pick<Components.Schemas.ClinicalMeetingType, 'title'>;
  clinicalMeetingSubject: Pick<Components.Schemas.ClinicalMeetingSubject, 'title'>;
  userPatientProfile: Pick<Components.Schemas.UserPatientProfile, 'fullName'>;
  userEmployeeProfile: Pick<Components.Schemas.UserEmployeeProfile, 'fullName'>;
  activities: number;
  subscription: { endDate: string; title: string } | null;
}

export interface MeetingsWithDoctorOrDietitianItem {
  id: string;
  userEmployeeProfileID: string;
  userPatientProfileID: string;
}

export interface MeetingForEndOfTreatmentConsultation {
  meetingFromDateTime: DateValue;
  meetingToDateTime: DateValue;
  id: string;
  employee: Pick<Components.Schemas.UserEmployeeProfile, 'fullName'>;
  subject: Pick<Components.Schemas.ClinicalMeetingSubject, 'title' | 'icon' | 'color'>;
}

export interface CancelClinicalMeetingInput {
  id: string;
  remarks: string;
  statusID: string;
  clinicalMeetingSubjectID: string;
}

export interface GetPatientTodayClinicalMeetingsInput {
  userPatientProfileID: string;
  userEmployeeProfilePermissionID: string;
}
export interface CreatePatientTodayClinicalMeeting {
  userPatientProfileID: string;
}

export interface PatientTodayClinicalMeeting
  extends Pick<
    ClinicalMeeting,
    | 'id'
    | 'meetingFromDateTime'
    | 'meetingToDateTime'
    | 'clinicalMeetingTypeID'
    | 'clinicalMeetingSubjectID'
    | 'userEmployeeProfileID'
    | 'remarks'
  > {
  employee: Required<Components.Schemas.ClinicalMeeting>['userEmployeeProfile']['fullName'];
}

export interface AttacheNotebookToClinicalMeetingInput {
  id: string;
  userPatientProfileSessionID: string;
  completeSessionDateTime?: string;
  userEmployeeProfileID?: string;
}

export interface GetMeetingExtendedInformationOutput
  extends Pick<
    Components.Schemas.ClinicalMeeting,
    'userEmployeeProfile' | 'clinicalMeetingSubject'
  > {
  id: string;
  userEmployeeProfile: Pick<Components.Schemas.UserEmployeeProfile, 'userCrmProfilePermission'>;
  clinicalMeetingSubject: Pick<
    Components.Schemas.ClinicalMeetingSubject,
    'isEndOfTreatmentMeeting'
  >;
}

export interface TryDeactivatePatientMeetingData
  extends Pick<ClinicalMeeting, 'id' | 'userEmployeeProfileID' | 'userPatientProfileID'> {
  patient: Pick<Components.Schemas.UserPatientProfile, 'isActive'>;
  employee: Pick<Components.Schemas.UserEmployeeProfile, 'fullName'>;
}

export interface LatestClinicalMeetingWithDoctorItem {
  id: string;
  meetingFromDateTime: DateValue;
  meetingToDateTime: DateValue;
}
