import React, { useMemo } from 'react';
import { SessionForNotebookGeneratorPreview } from 'services/user-patient-profile-session';
import { useTranslate } from 'hooks/use-translate';
import Masonry from 'react-responsive-masonry';
import {
  PreviewClinicalDrugs,
  PreviewSessionEffects,
  PreviewSessionItems,
} from 'components/preview-session-effects';
import { Divider, Paper, PaperProps, Typography, useTheme } from '@material-ui/core';
import { Stack, StackProps } from 'components/stack';
import { convertToDate } from 'utils/dates';
import { format } from 'date-fns';
import { APP_FORMAT_DATE_TIME } from 'configs/const';

interface RowTitleProps {
  row: Pick<SessionForNotebookGeneratorPreview, 'notebook' | 'userEmployeeProfile' | 'createdDate'>;
  middleSlot?: React.ReactNode;
}
const RowTitle: React.FC<RowTitleProps> = ({ row, middleSlot }) => {
  const title = useMemo(() => {
    return [row.notebook.labelKey, row.userEmployeeProfile.fullName].filter(Boolean).join(' | ');
  }, [row]);
  const date = useMemo(() => format(convertToDate(row.createdDate), APP_FORMAT_DATE_TIME), [row]);
  return (
    <Stack mb={1} alignItems={'center'}>
      <Typography variant={'h6'} color={'secondary'} style={{ flexGrow: 1, fontWeight: 700 }}>
        {title}
      </Typography>
      {middleSlot}
      <Typography variant={'body2'} color={'textSecondary'}>
        {date}
      </Typography>
    </Stack>
  );
};

interface RowProps {
  row: SessionForNotebookGeneratorPreview;
}

const Row: React.FC<RowProps> = ({ row }) => {
  const { t } = useTranslate();
  const { spacing } = useTheme();
  const sessionItems = useMemo(() => {
    return row.sessionItems.map((item) => {
      return {
        label: String(item.labelKey),
        value: item.entryValueString,
      };
    });
  }, [row.sessionItems]);
  const list = useMemo(() => {
    return [
      {
        title: t('session-items'),
        items: sessionItems,
        component: PreviewSessionItems,
      },
      {
        title: t('clinical-drugs'),
        items: row.clinicalDrugs,
        component: PreviewClinicalDrugs,
      },
      {
        title: t('icd10s'),
        items: row.icd10PreviewItems,
        remarks: row.icd10Remarks,
        component: PreviewSessionEffects,
      },
      {
        title: t('side-effects'),
        items: row.sideEffectPreviewItems,
        remarks: row.sideEffectRemarks,
        component: PreviewSessionEffects,
      },
      {
        title: t('surgeries'),
        items: row.surgeryPreviewItems,
        remarks: row.surgeryRemarks,
        component: PreviewSessionEffects,
      },
      {
        title: t('sensitivities'),
        items: row.sensitivityPreviewItems,
        remarks: row.sensitivityRemarks,
        component: PreviewSessionEffects,
      },
      {
        title: t('sensitivity-drugs'),
        items: row.sensitivityDrugPreviewItems,
        remarks: row.sensitivityDrugRemarks,
        component: PreviewSessionEffects,
      },
      {
        title: t('routine-drugs'),
        items: row.regularDrugPreviewItems,
        remarks: row.routineDrugRemarks,
        component: PreviewSessionEffects,
      },
      {
        title: t('past-drugs-for-obesity'),
        items: row.pastDrugPreviewItems,
        remarks: row.pastDrugForObesityRemarks,
        component: PreviewSessionEffects,
      },
    ];
  }, [row, sessionItems, t]);

  return (
    <Masonry columnsCount={2} gutter={`${spacing(1)}px`}>
      {list.map((item) => {
        const Component = item.component;
        //@ts-ignore
        return <Component title={item.title} items={item.items} remarks={item.remarks} />;
      })}
    </Masonry>
  );
};

interface SessionInformationCardProps extends PaperProps {
  data: SessionForNotebookGeneratorPreview;
  middleSlot?: React.ReactNode;
}
export const SessionInformationCard: React.FC<SessionInformationCardProps> = ({
  data,
  middleSlot,
  ...rest
}) => {
  return (
    <Paper
      elevation={3}
      key={data.id}
      {...rest}
      style={{
        padding: '1rem',
        ...rest?.style,
      }}
    >
      <RowTitle row={data} middleSlot={middleSlot} />
      <Divider style={{ marginBottom: '1rem', marginLeft: '-1rem', marginRight: '-1rem' }} />
      <Row row={data} />
    </Paper>
  );
};

interface Props extends StackProps {
  rows: SessionForNotebookGeneratorPreview[];
}
export const SessionInformationList: React.FC<Props> = ({ rows, ...rest }) => {
  return (
    <Stack spacing={2} direction={'column'} {...rest}>
      {rows.map((row, index) => {
        return <SessionInformationCard key={row.id} data={row} />;
      })}
    </Stack>
  );
};
