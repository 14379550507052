import { Badge, Box, Dialog, IconButton, Tooltip } from '@material-ui/core';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import {
  ServiceUserPatientProfile,
  apiUserPatientProfile,
  getPatientColor,
} from 'services/user-patient-profile';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import InfoIcon from '@material-ui/icons/Info';
import clsx from 'clsx';
import { DialogHeading } from 'components/dialog-title';
import { ShortRemark } from 'components/short-remark';
import { Stack } from 'components/stack';
import { TabItem, TabsWrapper } from 'components/tabs-wrapper';
import { APP_ROUTES, PATIENT_CARD_MAIN_TABS } from 'configs/routes';
import { useEffectError, useFetchDynamic } from 'hooks';
import { usePermissions } from 'hooks/use-permissions';
import { useTranslate } from 'hooks/use-translate';
import { useValueQuery } from 'hooks/use-value-query';
import { useAppDispatch } from 'store';
import { actionSetSearchedPatient } from 'store/searched-patients';
import CopyPathToClipboard from 'AurionCR/components/copy-path-to-clipboard';
import { useCurrentUser } from '../../../hooks';
import { patientEmitter } from '../../emitter';
import { usePatientSubscription, usePatientWeights } from '../../hooks';
import { ButtonAddNotebook } from '../button-add-notebook';
import { ButtonBackDoor } from '../button-backdoor';
import { ButtonCall } from '../button-call';
import { ButtonFillHealthSurvey } from '../button-fill-health-survey';
import { ButtonSendSms } from '../button-send-sms';
import { ButtonTask } from '../button-task';
import { TabAllActivities } from '../tab-all-activities';
import { TabAppointments } from '../tab-appointments';
import { TabCommercial } from '../tab-commercial';
import { TabDocuments } from '../tab-documents';
import { TabGeneral } from '../tab-general';
import { TabMedicalInformation } from '../tab-medical-information';
import { TabMessages } from '../tab-messages';
import { TabWeights } from '../tab-weights';
import style from './index.module.scss';
import { TemplatePatientFullName } from 'components/templates/template-patient-full-name';
import { BadgeEndOfSubscription } from '../badge-end-of-subscription';
import { PATIENT_CARD_TASK_QUERY_NAME } from '../../helpers';
import { makeFullName } from 'utils/app-helpers';
import { hexToRgb } from '../../../helpers';

const QUERY_NAME = 'main-tab';

interface Props {
  itemID: string;
  onClose: () => void;
}

export const Editor = memo<Props>(({ onClose, itemID }) => {
  const { tp, t } = useTranslate();
  const { $isAdmin } = useCurrentUser();
  const dispatch = useAppDispatch();
  const {
    data: initData,
    isLoading,
    error,
    refetch,
  } = apiUserPatientProfile.useGetPatientForGeneralFormQuery(itemID);
  useEffectError(error);

  const queryTask = useValueQuery<string>({
    name: PATIENT_CARD_TASK_QUERY_NAME,
    destroyOnUnmount: true,
  });

  const { data: subscription, isInactiveSubscription } = usePatientSubscription(itemID);
  const showDietitian = Boolean(subscription && subscription.subscription.visitDietitian);

  const { firstName, lastName, idNumber, shortRemark, mobilePhone } = initData || {};

  const formTitleWrap = useMemo(() => {
    if (!initData) {
      return t('loading');
    }

    const fullName = makeFullName(initData);
    const userInfo = [fullName, idNumber].filter(Boolean).join(' | ');

    return (
      <Stack spacing={1} mr={1}>
        <TemplatePatientFullName patient={{ ...initData, fullName }} variant={'h4'}>
          {userInfo}
        </TemplatePatientFullName>
        {shortRemark && <ShortRemark priority={'high'}>{shortRemark}</ShortRemark>}
        <BadgeEndOfSubscription userPatientProfileID={itemID} />
      </Stack>
    );
  }, [shortRemark, idNumber, t, itemID, initData]);

  const [triggerGetTasksCount, countResult] = useFetchDynamic(
    ServiceUserPatientProfile.getCountNotDoneTasks,
  );
  useEffect(() => {
    triggerGetTasksCount(itemID);
  }, [triggerGetTasksCount, itemID]);
  useEffect(() => {
    const refresh = () => triggerGetTasksCount(itemID);
    patientEmitter.on('refresh-tasks', refresh);
    return () => {
      patientEmitter.off('refresh-tasks', refresh);
    };
  }, [triggerGetTasksCount, itemID]);

  const { refresh } = usePatientWeights(itemID);

  const onRefreshNotebooks = useCallback(() => {
    patientEmitter.emit('refresh-weight', { patientID: itemID });
    patientEmitter.emit('refresh-notebooks', { patientID: itemID });
    refresh();
  }, [itemID, refresh]);

  const onRefreshSms = useCallback(() => {
    patientEmitter.emit('refresh-sms', { patientID: itemID });
  }, [itemID]);
  const onRefreshTasks = useCallback(() => {
    patientEmitter.emit('refresh-tasks', { patientID: itemID });
  }, [itemID]);

  const notDoneTasks = countResult.data?.count;

  const isAllowToEditCommercials = usePermissions('isAllowToEditCommercials');

  const { value: tab, onChange: onChangeTab } = useValueQuery<PATIENT_CARD_MAIN_TABS>({
    name: QUERY_NAME,
    init: PATIENT_CARD_MAIN_TABS.ALL_ACTIVITIES,
    destroyOnUnmount: true,
  });

  const userPatientProfileSubscriptionID = subscription?.id ?? undefined;

  const tabs = useMemo<TabItem<PATIENT_CARD_MAIN_TABS>[]>(
    () => [
      {
        value: PATIENT_CARD_MAIN_TABS.ALL_ACTIVITIES,
        keepInMemory: false,
        title: (
          <Badge badgeContent={notDoneTasks} color={'error'}>
            <Box p={'0 1rem 0 0'}> {tp('all-activities')}</Box>
          </Badge>
        ),
        element: <TabAllActivities notDoneTasks={notDoneTasks} userPatientProfileID={itemID} />,
      },
      {
        value: PATIENT_CARD_MAIN_TABS.GENERAL,
        keepInMemory: false,
        title: (
          <Badge
            badgeContent={
              isInactiveSubscription && (
                <Tooltip title={tp('patient-subscription-is-outdated-tooltip')}>
                  <InfoIcon color={'error'} />
                </Tooltip>
              )
            }
            color={'default'}
            invisible={false}
          >
            <Box p={'0 1.2rem 0 0'}> {tp('general-information')}</Box>
          </Badge>
        ),
        element: (
          <TabGeneral
            userPatientProfileID={itemID}
            showDietitian={showDietitian}
            isLoading={isLoading}
          />
        ),
      },
      {
        value: PATIENT_CARD_MAIN_TABS.APPOINTMENTS,
        title: tp('appointments'),
        element: <TabAppointments userPatientProfileID={itemID} />,
      },
      {
        value: PATIENT_CARD_MAIN_TABS.WEIGHTS,
        title: tp('weights'),
        element: <TabWeights userPatientProfileID={itemID} />,
      },
      {
        value: PATIENT_CARD_MAIN_TABS.MEDICAL_INFORMATION,
        title: tp('medical-information'),
        element: <TabMedicalInformation userPatientProfileID={itemID} />,
      },
      ...(isAllowToEditCommercials
        ? [
            {
              value: PATIENT_CARD_MAIN_TABS.COMMERCIAL,
              title: tp('commercial'),
              element: <TabCommercial userPatientProfileID={itemID} />,
            },
          ]
        : []),

      {
        value: PATIENT_CARD_MAIN_TABS.DOCUMENTS,
        title: tp('documents'),
        element: (
          <TabDocuments
            userPatientProfileID={itemID}
            userPatientProfileSubscriptionID={userPatientProfileSubscriptionID}
          />
        ),
      },
      {
        value: PATIENT_CARD_MAIN_TABS.MESSAGES,
        title: tp('communications'),
        element: <TabMessages userPatientProfileID={itemID} />,
      },
    ],
    [
      tp,
      itemID,
      showDietitian,
      isLoading,
      isAllowToEditCommercials,
      notDoneTasks,
      isInactiveSubscription,
      userPatientProfileSubscriptionID,
    ],
  );

  useEffect(() => {
    if (initData) {
      const fullName = [initData?.firstName, initData?.lastName].filter(Boolean).join(' ');
      const searchedPatient = {
        appIdentityUserID: initData.appIdentityUserID,
        fullName,
        id: initData.id,
        idNumber: initData.idNumber,
        mobilePhone: initData.mobilePhone,
        rowIndex: initData.rowIndex,
        shortRemark: initData.shortRemark,
        date: new Date(),
        isSearched: true,
        isActive: initData.isActive,
        onHold: initData.onHold,
      };
      dispatch(actionSetSearchedPatient(searchedPatient));
    }
  }, [initData, dispatch]);

  useEffect(() => {
    const onRefreshGeneral = ({ patientID }: { patientID: string }) => {
      if (patientID !== itemID) return;
      refetch();
    };

    patientEmitter.on('refresh-general-form', onRefreshGeneral);
    return () => {
      patientEmitter.off('refresh-general-form', onRefreshGeneral);
    };
  }, [itemID, refetch]);

  const patient = useMemo(() => ({ firstName, lastName }), [firstName, lastName]);

  const patientColor = useMemo(() => {
    const color = getPatientColor(initData ?? {});
    if (!color) return undefined;
    const { r, g, b } = hexToRgb(color);
    return `rgba(${r},${g},${b}, 0.2)`;
  }, [initData]);

  return (
    <Dialog fullScreen open={true} fullWidth maxWidth={false} onClose={onClose}>
      <DialogHeading
        classes={{
          root: clsx(style.titleRoot),
          close: style.titleClose,
        }}
        style={{ backgroundColor: patientColor }}
        title={formTitleWrap}
        onClose={onClose}
        isLoading={isLoading}
      >
        <Stack className={style.titleControls} spacing={1}>
          <div>
            <CopyPathToClipboard text={APP_ROUTES.PATIENT_LIST({ patient: itemID }).href}>
              <IconButton color={'primary'}>
                <FileCopyIcon />
              </IconButton>
            </CopyPathToClipboard>
          </div>
          <ButtonSendSms
            patient={patient}
            userPatientProfileID={itemID}
            toPhoneNumber={mobilePhone}
            onDone={onRefreshSms}
            meeting={null}
          />
          <ButtonFillHealthSurvey
            patient={initData}
            userPatientProfileID={itemID}
            toPhoneNumber={mobilePhone}
          />
          {$isAdmin && <ButtonBackDoor userPatientProfileID={itemID} />}
          <ButtonAddNotebook userPatientProfileID={itemID} onDone={onRefreshNotebooks} />
          <ButtonCall taskID={queryTask.value} userPatientProfileID={itemID} />
          <ButtonTask userPatientProfileID={itemID} onDone={onRefreshTasks} />
        </Stack>
      </DialogHeading>
      <TabsWrapper
        TabsProps={{
          orientation: 'vertical',
          TabIndicatorProps: {
            style: {
              backgroundColor: 'transparent',
            },
          },
        }}
        classes={{
          root: style.tabs,
          tab: style.tab,
          header: style.header,
          content: style.content,
        }}
        tabs={tabs}
        value={tab}
        onChange={onChangeTab}
      />
    </Dialog>
  );
});
