import React, { useCallback, useEffect } from 'react';
import { createFilterEquals, mergeFilters } from 'utils/dynamic-helpers';
import WarningIcon from '@material-ui/icons/Warning';
import {
  iUserPatientProfilePrescription,
  ServicePatientPrescription,
} from 'services/user-patient-profile-prescriptions';
import { useEffectError, useFetchDynamic, useMutationDynamic } from 'hooks';
import { Button, Collapse, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { useTranslate } from 'hooks/use-translate';
import { Alert } from '@material-ui/lab';

interface Props {
  userPatientProfileID: string;
  onCreated: (data: iUserPatientProfilePrescription) => void;
  onCompletePrevious?: (data: Pick<iUserPatientProfilePrescription, 'id'>) => void;
  onCancel?: () => void;
}

export const MedicalPrescriptionDialogNew: React.FC<Props> = ({
  userPatientProfileID,
  onCreated,
  onCompletePrevious,
  onCancel,
}) => {
  const { t, tp } = useTranslate();

  const fetchIncompletePrescriptions = useCallback((userPatientProfileID: string) => {
    return ServicePatientPrescription.getAllDynamic({
      select: 'id,entryDate',
      count: true,
      take: 1,
      filter: mergeFilters(
        createFilterEquals('userPatientProfileID', userPatientProfileID),
        'isCompleted==false',
      ).join('&&'),
    });
  }, []);

  const [triggerGet, { data, isLoading: isLoadingGet }] = useFetchDynamic(
    fetchIncompletePrescriptions,
  );

  const [createItem, { isLoading: isCreatingNew, error: errorCreatingNew }] = useMutationDynamic(
    ServicePatientPrescription.createNew,
  );
  useEffectError(errorCreatingNew);

  useEffect(() => {
    const init = async () => {
      await triggerGet(userPatientProfileID);
    };
    init();
  }, [triggerGet, userPatientProfileID]);

  const previous = data?.value[0];
  const onComplete = useCallback(() => {
    if (!previous) return;
    onCompletePrevious && onCompletePrevious(previous);
  }, [previous, onCompletePrevious]);

  const onCreate = useCallback(async () => {
    const result = await createItem({
      userPatientProfileID,
      remarks: t('prescription-validity'),
    });
    if (result.data) {
      onCreated(result.data);
    }
  }, [createItem, userPatientProfileID, onCreated, t]);

  const isLoading = isCreatingNew || isLoadingGet;

  return (
    <Dialog open={true} maxWidth={'xs'} fullWidth onClose={onCancel}>
      <DialogHeading isLoading={isLoading} title={tp('medical-prescription-new')} />
      <DialogContent>
        <Collapse in={!isLoadingGet}>
          <Alert severity={previous ? 'error' : 'success'}>
            {tp(previous ? 'medical-prescription-new-complete' : 'medical-prescription-new-create')}
          </Alert>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color={'primary'}>
          {t('cancel')}
        </Button>
        {!isLoadingGet && (
          <>
            {previous ? (
              <>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  disabled={isLoading}
                  onClick={onComplete}
                >
                  {t('complete-previous')}
                </Button>
                <Button
                  color={'secondary'}
                  variant={'contained'}
                  disabled={isLoading}
                  onClick={onCreate}
                  startIcon={<WarningIcon />}
                >
                  {t('start-new')}
                </Button>
              </>
            ) : (
              <Button
                color={'primary'}
                variant={'contained'}
                disabled={isLoading}
                onClick={onCreate}
              >
                {t('create-new')}
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
