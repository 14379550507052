import { API_SMS_CONTENTS, SMSContents } from 'services/sms-contents';
import { useCurrentUser } from 'components/hooks';
import { useAppSelector } from 'store';
import { useMemo } from 'react';
import { generateDynamicQuery } from 'utils/service';
import { mergeFilters } from 'utils/dynamic-helpers';
import { useSource } from 'AurionCR/components';
import { selectLanguageID } from 'store/languages';

export type MessageTemplate = Pick<SMSContents, 'id' | 'title' | 'message' | 'smsKey'>;

export const useSourceMessageTemplates = () => {
  const { userEmployeeProfilePermission } = useCurrentUser();
  const languageID = useAppSelector(selectLanguageID);
  const userEmployeeProfilePermissionID = userEmployeeProfilePermission?.id;

  const query = useMemo(
    () =>
      generateDynamicQuery({
        select: ['id', 'title', 'message', 'smsKey'].join(','),
        orderBy: ['title asc'].join(','),
        filter: mergeFilters(
          `languageID=="${languageID}"`,
          userEmployeeProfilePermissionID &&
            `userEmployeeProfilePermissionSmsContents.any(k =>k.userEmployeeProfilePermissionID=="${userEmployeeProfilePermissionID}")`,
        ).join('&&'),
      }),
    [languageID, userEmployeeProfilePermissionID],
  );

  return useSource<MessageTemplate>(`${API_SMS_CONTENTS.GET_ALL_DYNAMIC}?${query}`);
};
