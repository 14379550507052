import React, { memo, useMemo } from 'react';
import { GridOnTasks } from 'models/grid';

export interface GridTasksContextValue<T = any> {
  onTasks: GridOnTasks<T>;
}
export const GridTasksContext = React.createContext<GridTasksContextValue | null>(null);

interface Props {
  onTasks: GridOnTasks;
  children: React.ReactNode;
}
export const GridTasksProvider = memo<Props>(({ onTasks, children }) => {
  const value = useMemo(() => {
    return { onTasks };
  }, [onTasks]);

  return <GridTasksContext.Provider value={value}>{children}</GridTasksContext.Provider>;
});
