import React from 'react';
import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlProps,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
} from '@material-ui/core';

interface Props extends Omit<CheckboxProps, 'value' | 'checked' | 'onChange'> {
  value?: boolean;
  label?: React.ReactNode;
  LabelProps?: Partial<FormControlLabelProps>;
  FormControlProps?: Partial<FormControlProps>;
  error?: boolean;
  helperText?: React.ReactNode;
  onChange?: (value: boolean) => void;
}

export const AppCheckbox: React.FC<Props> = ({
  value,
  onChange,
  helperText,
  label,
  LabelProps,
  FormControlProps,
  error,
  readOnly,
  ...rest
}) => {
  const Control = (
    <Checkbox
      readOnly={readOnly}
      {...rest}
      checked={Boolean(value)}
      onChange={readOnly ? undefined : (e, v) => onChange && onChange(v)}
    />
  );

  return (
    <FormControl error={error} {...FormControlProps}>
      {label ? <FormControlLabel control={Control} label={label} {...LabelProps} /> : Control}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
