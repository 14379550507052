import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

interface WebNotificationContextValue {
  isInit: boolean;
  isSupport: boolean;
  permission: NotificationPermission | undefined;
  subscription: PushSubscription | undefined;
  requestSubscribe: () => Promise<void>;
  requestUnsubscribe: () => Promise<void>;
}
const WebNotificationContext = createContext<WebNotificationContextValue | null>(null);

export const useWebNotification = () => {
  const context = useContext(WebNotificationContext);

  if (!context) {
    throw new Error('WebNotificationContext was not provided');
  }

  return context;
};

const isSupport = 'Notification' in window;

interface Props {
  getSubscribeOptions: () => Promise<PushSubscriptionOptionsInit>;
  onSubscribed: (subscription: PushSubscription) => void;
  onSubscribeError?: (e: Error) => void;
}

interface State {
  isInit: boolean;
  permission: NotificationPermission | undefined;
  subscription: PushSubscription | undefined;
}

export const WebNotificationProvider: React.FC<Props> = ({
  children,
  getSubscribeOptions,
  onSubscribed,
  onSubscribeError,
}) => {
  const [state, setState] = useState<State>({
    isInit: false,
    permission: isSupport ? Notification.permission : undefined,
    subscription: undefined,
  });

  const updateState = useCallback(async () => {
    if (!isSupport) {
      return setState((prev) => ({ ...prev, isInit: true }));
    }

    const permission = Notification.permission;
    let subscription: PushSubscription | null = null;

    if (permission === 'granted') {
      const reg = await navigator.serviceWorker.getRegistration();

      if (reg) {
        subscription = await reg.pushManager.getSubscription();
      }
    }

    setState({ isInit: true, permission, subscription: subscription || undefined });
  }, []);

  const requestSubscribe = useCallback(async () => {
    if (!('serviceWorker' in navigator)) return;
    if (!isSupport) return;

    if (Notification.permission !== 'default') {
      const status = await Notification.requestPermission();
      if (status === 'denied') return;
    }

    const registration = await navigator.serviceWorker.ready;
    const options = await getSubscribeOptions();

    try {
      const subscription = await registration.pushManager.subscribe(options);
      onSubscribed(subscription);
      updateState();
      console.log('requestSubscribe: success!', subscription);
    } catch (e) {
      console.log('requestSubscribe: error!', e);
      onSubscribeError && onSubscribeError(e as any);
    }
  }, [getSubscribeOptions, onSubscribed, onSubscribeError, updateState]);
  const requestUnsubscribe = useCallback(async () => {
    if (!isSupport) return;
    const reg = await navigator.serviceWorker.getRegistration();
    if (!reg) {
      return updateState();
    }

    const sub = await reg.pushManager.getSubscription();
    if (!sub) {
      return updateState();
    }
    await sub.unsubscribe();

    return updateState();
  }, [updateState]);

  useEffect(() => {
    updateState();
  }, [updateState]);

  const value = useMemo(() => {
    return { isSupport, requestSubscribe, requestUnsubscribe, ...state };
  }, [state, requestSubscribe, requestUnsubscribe]);

  return (
    <WebNotificationContext.Provider value={value}>{children}</WebNotificationContext.Provider>
  );
};
