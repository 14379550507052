import React from 'react';
import { PreviewSessionCard } from '../card';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import style from './index.module.scss';

interface Item {
  catalogName: string | null | undefined;
  dosageForm: any;
  remarks: string | null | undefined;
}

interface Props {
  title: React.ReactNode;
  items: Item[];
  hideIfEmpty?: boolean;
}
export const PreviewClinicalDrugs: React.FC<Props> = ({ title, items, hideIfEmpty = true }) => {
  const isEmpty = items ? items.length === 0 : false;
  if (isEmpty && hideIfEmpty) {
    return null;
  }
  return (
    <PreviewSessionCard title={title}>
      <Table size={'small'}>
        <TableBody>
          {items.map((item, index) => {
            return (
              <TableRow key={index} className={style.row}>
                <TableCell>{item.catalogName}</TableCell>
                <TableCell>{item.dosageForm}</TableCell>
                <TableCell>{item.remarks}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </PreviewSessionCard>
  );
};
