import {
  apiPatientPrescriptionDetails,
  schemaPatientPrescriptionDetail,
} from 'services/user-patient-profile-prescription-details';
import { InferType } from 'yup';

export const schema = schemaPatientPrescriptionDetail;
export type FormModel = InferType<typeof schema>;
export const useGetQuery =
  apiPatientPrescriptionDetails.useGetPatientPrescriptionDetailFormDataQuery;
export const usePatchMutation =
  apiPatientPrescriptionDetails.usePatchPatientPrescriptionDetailMutation;
export const usePostMutation =
  apiPatientPrescriptionDetails.usePostPatientPrescriptionDetailMutation;
export const useDeleteMutation =
  apiPatientPrescriptionDetails.useDeletePatientPrescriptionDetailMutation;
