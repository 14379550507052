import React, { useCallback } from 'react';
import { GridControlsDropDownTemplateProps } from 'models/grid';
import { MenuItem } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { GridModel } from '../models';
import { useEffectError } from 'hooks';
import { apiUserPatientPrescriptions } from 'services/user-patient-profile-prescriptions';
import { useOpen } from 'AurionCR/components/hooks';
import { DialogConfirm } from 'components/dialog-confirm';
import { composeFunctions } from 'utils';
import { handlerStopAndPrevent } from 'utils/handlers';

const usePrintMutation = apiUserPatientPrescriptions.usePrintPatientPrescriptionMutation;

interface Props extends GridControlsDropDownTemplateProps<GridModel> {}
export const ControlDownload: React.FC<Props> = ({ data, handleClose, onTask }) => {
  const { tp } = useTranslate();

  const { id: userPatientProfilePrescriptionID, prescriptionFilePath: url } = data;

  const [print, resultPrint] = usePrintMutation();
  useEffectError(resultPrint.error);

  const onDownload = useCallback(async () => {
    if (!url) return null;

    await print({
      userPatientProfilePrescriptionID,
      url,
      remarks: tp('activity-patient-prescription-print-prescription'),
    });

    onTask([['getData']]);

    handleClose();
  }, [print, userPatientProfilePrescriptionID, handleClose, url, onTask, tp]);

  const disabled = !url || !data.isCompleted || resultPrint.isLoading;

  const confirmState = useOpen();

  return (
    <>
      <MenuItem
        disabled={disabled}
        onClick={composeFunctions(handlerStopAndPrevent, confirmState.handleOpen)}
      >
        {tp('download')}
      </MenuItem>
      {confirmState.isOpen && (
        <DialogConfirm
          title={tp('confirm-print')}
          message={tp('are-you-sure-print-prescription')}
          onCancel={confirmState.handleClose}
          onConfirm={composeFunctions(confirmState.handleClose, onDownload)}
        />
      )}
    </>
  );
};
