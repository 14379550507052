// mixins
import { reduce } from 'lodash-es';
import { IDataGridMadel } from '../../model';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { checkEs6AndRun, requestError, requestSuccess } from 'AurionCR/components';

export const createGridFilters = (
  filtersMap: Record<string, any>,
  mainFilter?: string,
  toolbarFilter: Record<string, any> = {},
): string => {
  if (Object.keys(toolbarFilter).length || mainFilter) {
    const mainFilters = mainFilter ? [mainFilter] : [];
    const toolbarFilters = reduce(
      toolbarFilter,
      (result: any, value: any, field: any) => {
        const filter_ = filtersMap[field];
        if (filter_) {
          if (
            Array.isArray(value) &&
            filter_.options.type === 'select' &&
            filter_.options.multiple
          ) {
            result.push(
              `(${value
                .map((value: string | number) =>
                  checkEs6AndRun(filter_.options.valueTemplate, {
                    field,
                    value,
                    filters: toolbarFilter,
                  }),
                )
                .join(' || ')})`,
            );
          } else if (
            (value || value === false) &&
            // eslint-disable-next-line
            (!Array.isArray(value) || (Array.isArray(value) && value.length))
          ) {
            const filterValue = checkEs6AndRun(filter_.options.valueTemplate, {
              field,
              value,
              filters: toolbarFilter,
            });
            filterValue && result.push(filterValue);
          }
        } else {
          console.error('Error parse filters by request', field);
        }
        return result;
      },
      [],
    );
    return [...mainFilters, ...toolbarFilters].join(' && ');
  } else {
    return '';
  }
};

export const parseData = (data: any) => {
  const { data_, mixins_ } = reduce(
    data,
    (result: any, value, key) => {
      if (value && typeof value === 'object') {
        if (value.mixin_) {
          result.mixins_.push(value);
          if (['imageUploader', 'fileUploader', 'editor'].includes(value.type_))
            result.data_[key] = '';
        }
      } else {
        result.data_[key] = value;
      }
      return result;
    },
    { data_: {}, mixins_: [] },
  );
  return { data_, mixins_ };
};
export const handleError = (error: any, type: string, onTasks: any, dispatch: any) => {
  if (onTasks)
    onTasks([
      ['onHandle', { type: type + 'Error', error: error }],
      [type + 'Data', null],
      ['loading', false],
    ]);
  dispatch(notifyRequestResult(requestError(error), 'error'));
};
export const handleDone = (
  response: any,
  type: string,
  onTasks: any,
  dispatch: any,
  payload: any[] = [],
) => {
  if (onTasks)
    onTasks([
      ['onHandle', { type: type + 'Done', response: response.data }],
      [type + 'Data', null],
      ...payload,
      ['getData'],
    ]);
  if (dispatch) dispatch(notifyRequestResult(requestSuccess(response)));
};
export const createGetProps = ({
  model,
  pagination,
  filters,
  sortable,
}: {
  model: IDataGridMadel;
  pagination: any;
  filters: any;
  sortable: any;
}) => {
  const props: any = {
    url: model.store.get.url,
    params: {
      Count: true,
      Select: model.store.get.selected,
      OrderBy: model.store.get?.orderBy ? model.store.get?.orderBy + ',' : undefined,
    },
  };
  //expand
  if (model.store.get.expand) {
    props.params['Expand'] = model.store.get.expand;
  }
  // pagination
  if (pagination.size !== 'all') {
    if (pagination.page - 1) props.params['Skip'] = (pagination.page - 1) * pagination.size;
    props.params['Take'] = pagination.size;
  }
  // sortable
  if (sortable.field && sortable.order) {
    const column: any = model.columns.filter((column) => column.field === sortable.field)[0];
    if (column) {
      if (column.sortable) {
        if (typeof column.sortable === 'string') {
          props.params['OrderBy'] = `${column.sortable} ${sortable.order}`;
        } else if (column.sortable[sortable.order]) {
          props.params['OrderBy'] = `${column.sortable[sortable.order]} ${sortable.order}`;
        } else {
          props.params['OrderBy'] = `${sortable.field} ${sortable.order}`;
        }
      } else {
        props.params['OrderBy'] = `${sortable.field} ${sortable.order}`;
      }
    } else {
      props.params['OrderBy'] = `${sortable.field} ${sortable.order}`;
      // console.error(`Error parse sortable by request: Can\`t find column by field ${sortable.field}`)
    }
  }
  if (model.store.get.orderBy) {
    props.params['OrderBy'] = [model.store.get.orderBy, props.params['OrderBy']]
      .filter((item) => item)
      .join();
  }

  const requestFilters = createGridFilters(
    model.toolbar.filtersMap,
    model.store.get.filters,
    filters,
  );

  if (requestFilters) {
    props.params['Filter'] = requestFilters;
  }
  return props;
};
