import React, { useCallback } from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { isDateLike, isFutureDate, isPastDate } from 'utils/dates';
import { transformToDateISO } from 'utils/transformers';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslate } from 'hooks/use-translate';
import { useEffectError, useFieldProps } from 'hooks';
import { AppDatePicker } from 'components/app-date-picker';
import { AppInput } from 'components/app-input';
import { ButtonCancel, ButtonSave } from 'components/app-buttons';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { isMutationFulfilled } from 'utils/rtk-query';
import { useTodoTaskNotificationCreated } from '../../../dialog-task';

const schema = yup.object({
  onHoldEndDate: yup
    .string()
    .required('rule-required')
    .test('date', 'rule-date', isDateLike)
    .test('date', 'rule-date-future', isFutureDate)
    .transform(transformToDateISO)
    .default(''),
  onHoldReason: yup.string().required('rule-required').default(''),
});

const useSubmitMutation = apiUserPatientProfile.useMakePatientOnHoldMutation;

type FormModel = yup.InferType<typeof schema>;

interface Props {
  userPatientProfileID: string;
  onCancel: () => void;
  onDone: (formData: FormModel) => void;
}

export const DialogPatientOnHold: React.FC<Props> = ({
  userPatientProfileID,
  onCancel,
  onDone,
}) => {
  const { tp } = useTranslate();
  const { control, handleSubmit, errors } = useForm({
    defaultValues: schema.cast({}),
    resolver: yupResolver(schema),
  });

  const { onSend } = useTodoTaskNotificationCreated();

  const [submit, resultSubmit] = useSubmitMutation();
  useEffectError(resultSubmit.error);
  const getFieldProps = useFieldProps({ errors });

  const onSubmit = useCallback(
    async (formData: FormModel) => {
      const result = await submit({ userPatientProfileID, ...formData });

      if (isMutationFulfilled(result)) {
        onSend({ id: result.data.taskID });
        onDone(formData);
      }
    },
    [submit, userPatientProfileID, onDone, onSend],
  );

  return (
    <Dialog open={true} fullWidth maxWidth={'xs'}>
      <DialogHeading
        title={tp('patient-on-hold')}
        onClose={onCancel}
        disabled={resultSubmit.isLoading}
        isLoading={resultSubmit.isLoading}
      />
      <DialogContent>
        <Controller
          name={'onHoldEndDate'}
          control={control}
          render={(renderProps) => {
            return (
              <AppDatePicker
                {...getFieldProps(renderProps)}
                disabled={resultSubmit.isLoading}
                shouldDisableDate={isPastDate}
              />
            );
          }}
        />
        <Controller
          name={'onHoldReason'}
          control={control}
          render={(renderProps) => {
            return <AppInput {...getFieldProps(renderProps)} disabled={resultSubmit.isLoading} />;
          }}
        />
      </DialogContent>
      <DialogActions>
        <ButtonCancel disabled={resultSubmit.isLoading} isBack={false} onClick={onCancel} />
        <ButtonSave
          isCreate={false}
          onClick={handleSubmit(onSubmit)}
          disabled={resultSubmit.isLoading}
        />
      </DialogActions>
    </Dialog>
  );
};
