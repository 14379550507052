import React, { useMemo } from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { Popover, PopoverProps, Typography } from '@material-ui/core';
import { NativeScroll } from 'components/native-scroll';
import { Skeleton } from '@material-ui/lab';

const EMPTY_OPTIONS_LENGTH = 3;
const PreviewRowSkeleton: React.FC = () => {
  return (
    <div className={style.column}>
      <Skeleton variant="text" height={21} />
      <Skeleton variant="text" height={21} />
    </div>
  );
};

const renderOptionDefault: RenderOption<Partial<Option>> = (option, i) => {
  return (
    <div key={i} className={style.column}>
      <Typography variant={'body2'} className={style.label} component={'div'}>
        {option.title}
      </Typography>
      {option.value}
    </div>
  );
};

interface Classes {
  root: string;
}

export interface Option {
  title: React.ReactNode;
  value?: React.ReactNode;
}

export interface RenderOption<O> {
  (item: O, index: number): React.ReactNode;
}
interface Props<O> extends Partial<Omit<PopoverProps, 'title'>> {
  title: React.ReactNode;
  options: O[];
  className?: string;
  classes?: Partial<Classes>;
  isLoading?: boolean;
  renderOption?: RenderOption<O>;
}

export const PreviewPopover = <O,>({
  className,
  classes,
  title,
  options,
  isLoading,
  renderOption = renderOptionDefault,
  children,
  ...rest
}: Props<O>) => {
  const rowsSkeleton = useMemo(() => {
    return new Array(EMPTY_OPTIONS_LENGTH).fill(null).map((_, i) => {
      return <PreviewRowSkeleton key={i} />;
    });
  }, []);

  return (
    <Popover
      open={true}
      elevation={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      className={clsx(style.root, classes?.root, className)}
      classes={{
        paper: style.paper,
      }}
      {...rest}
    >
      <Typography className={style.title} color={'secondary'} component={'h4'}>
        {isLoading ? <Skeleton variant={'text'} width={'70%'} /> : title}
      </Typography>
      <NativeScroll className={style.scroll}>
        {isLoading ? rowsSkeleton : options.map(renderOption)}
      </NativeScroll>
      {children}
    </Popover>
  );
};
