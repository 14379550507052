import React from 'react';
import { Dialog, DialogProps } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { PdfView } from 'components/pdf-view';

interface Props extends Partial<DialogProps> {
  onClose?: () => void;
  title: string;
  src: string;
}

export const DialogPdfPreview: React.FC<Props> = ({ children, title, onClose, src, ...rest }) => {
  return (
    <Dialog open={true} onClose={onClose} {...rest}>
      <DialogHeading title={title} onClose={onClose} />
      <PdfView title={title} pdf={src} />
    </Dialog>
  );
};
