import React, { useMemo } from 'react';
import { usePatientSubscription } from '../../hooks';
import { calcDaysLeft, convertToDate, DateValue } from 'utils/dates';
import { ShortRemark } from 'components/short-remark';
import { ChipProps, Tooltip, Typography } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import style from './index.module.scss';
import { apiClinicalMeetings } from 'services/clinical-meetings';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { TemplateLtrOnly } from 'components/templates/template-ltr-only';

const LEFT_DAYS = 14; //days
const useFetchEndOfTreatmentMeetings =
  apiClinicalMeetings.useGetClinicalMeetingEndEndTreatmentByRangeQuery;
const useFetchPatient = apiUserPatientProfile.useGetPatientForGeneralFormQuery;

interface BadgeEndOfSubscriptionViewProps extends Omit<ChipProps, 'children' | 'label'> {
  userPatientProfileID: string;
  startDate: DateValue;
  endDate: DateValue;
}
const BadgeEndOfSubscriptionView: React.FC<BadgeEndOfSubscriptionViewProps> = ({
  startDate,
  endDate,
  userPatientProfileID,
  ...rest
}) => {
  const { data: meetings } = useFetchEndOfTreatmentMeetings({
    userPatientProfileID,
    endDate,
  });
  const { data: patient } = useFetchPatient(userPatientProfileID);

  const leftDays = useMemo(() => {
    return calcDaysLeft(new Date(), convertToDate(endDate));
  }, [endDate]);

  const isHasNotEndOfTreatment = meetings ? meetings.length === 0 : false;

  const isHigh = leftDays <= LEFT_DAYS;
  const isHighest = isHigh && isHasNotEndOfTreatment;

  const { tp } = useTranslate();
  const priority = isHighest ? 'highest' : isHigh ? 'high' : 'medium';
  const isActive = patient ? patient.isActive : true;

  if (!isActive) {
    return (
      <Tooltip title={tp('subscription-end-tooltip')} arrow>
        <ShortRemark {...rest} priority={'high'} className={style.root}>
          {tp('subscription-end-no-active-program')}
        </ShortRemark>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={tp('subscription-end-tooltip')} arrow>
      <ShortRemark
        classes={{ label: style.rootLabel }}
        {...rest}
        priority={priority}
        className={style.root}
      >
        <span>
          {isHighest
            ? tp('subscription-end-does-not-have-end-of-treatment')
            : tp('subscription-end-left-days')}
        </span>

        <TemplateLtrOnly
          style={{ display: 'inline-block' }}
          pl={'1rem'}
          value={
            <Typography component={'span'} color={'inherit'} variant={'h2'}>
              {leftDays}
            </Typography>
          }
        />
      </ShortRemark>
    </Tooltip>
  );
};

interface Props extends Omit<ChipProps, 'children' | 'label'> {
  userPatientProfileID: string;
}
export const BadgeEndOfSubscription: React.FC<Props> = ({ userPatientProfileID, ...rest }) => {
  const { data: subscription } = usePatientSubscription(userPatientProfileID);

  if (!subscription) {
    return null;
  }

  return (
    <BadgeEndOfSubscriptionView
      userPatientProfileID={userPatientProfileID}
      startDate={subscription.startDate}
      endDate={subscription.endDate}
      {...rest}
    />
  );
};
