import { DefaultGridButtonExportToExcel } from 'components/data-grid/model';
import { API_USER_PATIENT_PROFILE_SESSION_ITEMS } from 'services/user-patient-profile-session-item';

export default (userPatientProfileSessionID: string, parser: any): any => ({
  id: 'grid_user-patient-profile-session-items',
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_SESSION_ITEMS.GET_ALL_DYNAMIC,
      filters: `userPatientProfileSessionID == "${userPatientProfileSessionID}"`,
      selected: 'entryValueString,entryValueBool,entryValueNumber,entryValueDate',
      parser,
    },
    create: {
      url: API_USER_PATIENT_PROFILE_SESSION_ITEMS.POST,
    },
    patch: {
      url: API_USER_PATIENT_PROFILE_SESSION_ITEMS.PATCH,
    },
    delete: {
      url: API_USER_PATIENT_PROFILE_SESSION_ITEMS.DELETE,
    },
    sortable: {
      field: 'rank',
      order: 'asc',
    },
  },
  toolbar: {
    title: '',
    controls: null,
    controlsDefault: {
      new: null,
      resetFilters: null,
      export: DefaultGridButtonExportToExcel
    },
  },
  pagination: false,
  columns: [
    {
      title: 'field-input',
      field: 'fieldInput.labelKey as fieldInputLabelKey',
      sortable: 'fieldInput.labelKey',
      tdStyle: {
        fontWeight: 'bold',
      },
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'activity-info',
      field: 'entryValueString',
      sortable: false,
      template: 'html',
      tdClasses: 'mobile-20',
    },
  ],
});
