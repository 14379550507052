const API_ENDPOINT = 'RegistrationOwners';

export const API_REGISTRATION_OWNERS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<RegistrationOwner, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<RegistrationOwner, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface RegistrationOwner extends Components.Schemas.RegistrationOwner {}
