import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'DiagnosisTypes';

export const API_DIAGNOSIS_TYPES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<IDiagnosisType, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<IDiagnosisType, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface IDiagnosisType extends FixServerObject<Components.Schemas.DiagnosisType, 'id'> {}
