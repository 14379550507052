import { DateValue } from 'utils/dates';
import { UserEmployeeProfile } from 'services/user-employee-profiles';

const API_ENDPOINT = 'UserPatientProfileTelecoms';

export const API_USER_PATIENT_PROFILE_TELECOMS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<UserPatientProfileTelecom, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<UserPatientProfileTelecom, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface UserPatientProfileTelecom extends Components.Schemas.UserPatientProfileTelecom {}
export interface UserPatientProfileTelecomGetActivitiesInput
  extends Pick<UserPatientProfileTelecom, 'userPatientProfileID'> {
  dateRange: DateValue[];
}

export interface UserPatientProfileTelecomGetActivitiesItem
  extends Pick<UserPatientProfileTelecom, 'id' | 'entryDate' | 'callDirection'> {
  employee: UserEmployeeProfile['fullName'];
}
