import { useCallback } from 'react';
import { useMountedRef } from './use-mounted-ref';
import { useDispatch } from 'react-redux';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { useOpen } from 'AurionCR/components/hooks';
import { PromiseType } from 'utils/types';
import { parseErrorData } from 'utils/service';
import { useTranslate } from 'hooks/use-translate';

interface Request {
  (...args: any[]): Promise<any>;
}

interface RequestSuccess<T extends Request> {
  type: 'success';
  payload: PromiseType<ReturnType<T>>;
}

interface RequestError {
  type: 'error';
  error: Error;
}

type Result<T extends Request> = RequestSuccess<T> | RequestError;
type UseRequestResultType<T> = T extends UseRequestResult<infer U> ? U : never;
export type UseRequestResult<T extends Request> = (...args: Parameters<T>) => Promise<Result<T>>;

export const useRequest = <T extends Request>(
  request: T,
  setLoading?: (v: boolean) => void,
): UseRequestResult<T> => {
  const mountedRef = useMountedRef();
  return useCallback(
    async (...args: Parameters<T>) => {
      setLoading && setLoading(true);
      try {
        const result = await request(...args);
        return { type: 'success' as const, payload: result };
      } catch (e: any) {
        return { type: 'error' as const, error: parseErrorData(e) };
      } finally {
        if (mountedRef.current) {
          setLoading && setLoading(false);
        }
      }
    },
    // eslint-disable-next-line
    [setLoading, mountedRef, request],
  );
};

export const isRequestSuccess = <T extends UseRequestResult<any>>(
  result: any,
): result is RequestSuccess<UseRequestResultType<T>> => {
  return result?.type === 'success';
};
export const isRequestError = (result: any): result is RequestError => {
  return result?.type === 'error';
};

export const useRequestAlertError = <T extends Request>(request: UseRequestResult<T>) => {
  const mountedRef = useMountedRef();
  const dispatch = useDispatch();
  const { t } = useTranslate();

  return useCallback(
    async (...args: Parameters<T>) => {
      const result = await request(...args);
      if (mountedRef.current && isRequestError(result)) {
        const parsedErr = parseErrorData(result.error);
        dispatch(notifyRequestResult(t(parsedErr.message), 'error'));
      }
      return result;
    },
    // eslint-disable-next-line
    [request, mountedRef, dispatch],
  );
};
export const useRequestAlertSuccess = <T extends Request>(
  request: UseRequestResult<T>,
  message?: string,
) => {
  const mountedRef = useMountedRef();
  const dispatch = useDispatch();
  const { t } = useTranslate();

  return useCallback(
    async (...args: Parameters<T>) => {
      const result = await request(...args);
      if (mountedRef.current && isRequestSuccess(result)) {
        dispatch(notifyRequestResult(message || t('success'), 'success'));
      }
      return result;
    },
    // eslint-disable-next-line
    [request, mountedRef, dispatch, message],
  );
};

type HandlerTypes = 'get' | 'delete' | 'put' | 'patch' | 'post' | 'refreshAndContinue';

interface Handler {
  (data: { type: HandlerTypes; payload: any; isEditAfter?: any }): void;
}

export const useRequestFormGrid = <T extends Request>(
  request: UseRequestResult<T>,
  type: HandlerTypes,
  handler: Handler,
  continueField?: keyof PromiseType<ReturnType<T>>['data'],
) => {
  const mountedRef = useMountedRef();

  return useCallback(
    async (...args: Parameters<T>) => {
      const result = await request(...args);
      if (mountedRef.current && isRequestSuccess(result)) {
        const payload = result.payload.data;
        handler({
          type,
          payload,
          isEditAfter: continueField && payload ? payload[continueField] : undefined,
        });
      }
      return result;
    },
    // eslint-disable-next-line
    [request, mountedRef, handler, type],
  );
};
export const isRequestItemCreated = (itemID: any): itemID is string => {
  return itemID && itemID !== 'true' && typeof itemID === 'string';
};
export const useRequestConfirm = <T extends Request>(request: UseRequestResult<T>) => {
  const { isOpen, handleOpen, handleClose } = useOpen();
  const mountedRef = useMountedRef();

  const onConfirm = useCallback(
    async (...args: Parameters<T>) => {
      const result = await request(...args);
      if (mountedRef.current && isRequestSuccess(result)) {
        handleClose();
      }
      return result;
    },
    // eslint-disable-next-line
    [request, handleClose, mountedRef],
  );

  return { isOpen, onOpen: handleOpen, onClose: handleClose, onConfirm };
};
