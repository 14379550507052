import React, { forwardRef } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import style from './index.module.scss';
import clsx from 'clsx';

interface Props extends ButtonProps {}

export const ButtonOrange = forwardRef<HTMLButtonElement, Props>(({ className, ...rest }, ref) => {
  return (
    <Button ref={ref} className={clsx(style.root, className)} variant={'contained'} {...rest} />
  );
});
