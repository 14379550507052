import { call, delay, put, race, take, takeLatest } from 'typed-redux-saga';
import {
  actionEmployeesMonitorEndPolling,
  actionEmployeesMonitorStartPolling,
  actionEmployeesMonitorUpdateRerender,
} from './slice';
import { actionEmployeesMonitorGetEmployees } from './actions';
import { apiUserEmployeeProfileWorkLogs } from 'services/user-employee-profile-work-logs';
import { apiUserEmployeeProfileSchedules } from 'services/user-employee-profile-schedules';
import { apiUserEmployeeProfileAbsences } from 'services/user-employee-profile-absence';

const POLLING_DELAY = 60_000 * 10; //milliseconds * minutes

function* sagaUpdateRenderTimer() {
  while (true) {
    yield* delay(30_000);
    yield* put(actionEmployeesMonitorUpdateRerender());
  }
}

function* fetchEmployees() {
  while (true) {
    yield* put(actionEmployeesMonitorGetEmployees());

    yield* race([
      take(actionEmployeesMonitorGetEmployees.fulfilled),
      take(actionEmployeesMonitorGetEmployees.rejected),
    ]);

    yield* delay(POLLING_DELAY);
  }
}
function* sagaWatchPolling() {
  while (true) {
    yield* take(actionEmployeesMonitorStartPolling);
    yield* race([call(fetchEmployees), take(actionEmployeesMonitorEndPolling)]);
  }
}
function* watchChangesWorkLogs() {
  yield* put(actionEmployeesMonitorGetEmployees());
}
function* watchChangesSchedules() {
  yield* put(actionEmployeesMonitorGetEmployees());
}
function* watchChangesAbsences() {
  yield* put(actionEmployeesMonitorGetEmployees());
}

export const employeesMonitorSagas = [
  sagaWatchPolling(),
  sagaUpdateRenderTimer(),
  takeLatest(
    [
      apiUserEmployeeProfileWorkLogs.endpoints.employeeProfileWorkLogPost.matchFulfilled,
      apiUserEmployeeProfileWorkLogs.endpoints.employeeProfileWorkLogPatch.matchFulfilled,
      apiUserEmployeeProfileWorkLogs.endpoints.employeeProfileWorkLogDelete.matchFulfilled,
    ],
    watchChangesWorkLogs,
  ),
  takeLatest(
    [
      apiUserEmployeeProfileSchedules.endpoints.employeeProfileSchedulesMakeFromDefault
        .matchFulfilled,
      apiUserEmployeeProfileSchedules.endpoints.employeeProfileSchedulePost.matchFulfilled,
      apiUserEmployeeProfileSchedules.endpoints.employeeProfileSchedulePatch.matchFulfilled,
      apiUserEmployeeProfileSchedules.endpoints.employeeProfileScheduleDelete.matchFulfilled,
    ],
    watchChangesSchedules,
  ),
  takeLatest(
    [
      apiUserEmployeeProfileAbsences.endpoints.createEmployeeProfileAbsence.matchFulfilled,
      apiUserEmployeeProfileAbsences.endpoints.deleteEmployeeProfileAbsence.matchFulfilled,
    ],
    watchChangesAbsences,
  ),
];
