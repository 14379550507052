import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';
import { calcPaginationState } from 'utils/service';

const selectState = (state: RootState) => state.dietDiariesReport;

export const selectDietDiaryReportStatuses = createSelector(selectState, ({ isLoading }) => {
  return { isLoading };
});
export const selectDietDiaryReportFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});
export const selectDietDiaryReportPagination = createSelector(selectState, ({ pagination }) => {
  return calcPaginationState(pagination);
});
export const selectDietDiaryReportData = createSelector(selectState, ({ data }) => {
  return data;
});

export const selectDietDiaryReportSelected = createSelector(selectDietDiaryReportData, (rows) => {
  return rows.filter((row) => row.__checked);
});
export const selectDietDiaryReportIsAnySelected = createSelector(
  selectDietDiaryReportSelected,
  (rows) => {
    return rows.length > 0;
  },
);
export const selectDietDiaryReportIsAllSelected = createSelector(
  selectDietDiaryReportIsAnySelected,
  selectDietDiaryReportData,
  selectDietDiaryReportSelected,
  (isAny, rows, selectedRows) => {
    return isAny && rows.length === selectedRows.length;
  },
);
