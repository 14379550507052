import {
  Avatar,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemSecondaryAction,
  ListItemText,
  ListProps,
} from '@material-ui/core';
import React, { useCallback } from 'react';
import { TemplateMeetingDate } from 'components/templates/template-meeting-date';
import { DateValue } from 'utils/dates';
import { Unset } from 'utils/types';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { useSelect, UseSelectProps } from '../../hooks/use-select';
import style from './index.module.scss';
import clsx from 'clsx';

export interface Meeting {
  id: string;
  meetingFromDateTime: DateValue;
  meetingToDateTime: DateValue;
}
export interface MeetingType {
  color: Unset;
  icon: Unset;
  title: Unset;
}
export interface MeetingEmployee {
  fullName: Unset;
}
export interface IMeetingData {
  meeting: Meeting;
  employee: Unset<Partial<MeetingEmployee>>;
  type: Unset<Partial<MeetingType>>;
}
interface IListItemProps extends Pick<ListItemProps, 'disableGutters' | 'className'> {}
interface MeetingListItemProps extends IListItemProps, IMeetingData {
  selected: boolean;
  disabled: boolean;
  onSelect: () => void;
  multiple: boolean;
}
export const MeetingListItem: React.FC<MeetingListItemProps> = ({
  meeting,
  type,
  selected,
  disabled,
  onSelect,
  employee,
  multiple,
  ...rest
}) => {
  const onClick = useCallback(() => {
    return onSelect();
  }, [onSelect]);

  return (
    <ListItem
      disableGutters
      disabled={disabled}
      {...rest}
      button
      selected={selected}
      onClick={onClick}
      className={clsx(style.item, rest.className)}
    >
      <ListItemIcon>
        <Avatar
          title={type?.title ?? 'type'}
          src={type?.icon ?? undefined}
          style={{ backgroundColor: type?.color ?? undefined }}
          imgProps={{
            style: {
              width: '70%',
              height: '70%',
            },
          }}
        >
          <MeetingRoomIcon />
        </Avatar>
      </ListItemIcon>
      <ListItemText
        secondary={employee?.fullName}
        primary={<TemplateMeetingDate meeting={meeting} style={{ padding: 0 }} />}
      />
      {multiple && (
        <ListItemSecondaryAction>
          <Checkbox edge="end" checked={selected} onChange={onSelect} disabled={disabled} />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export interface MeetingItem extends Meeting {
  type: Unset<Partial<MeetingType>>;
  employee: Unset<Partial<MeetingEmployee>>;
}

type Props<Multiple extends boolean> = Omit<ListProps, 'onSelect' | 'onChange'> &
  UseSelectProps<MeetingItem, Multiple> & {
    ItemsProps?: Partial<IListItemProps>;
    disabled: boolean;
  };

export const MeetingList = <Multiple extends boolean = false>(props: Props<Multiple>) => {
  const { options, value, onChange, ItemsProps, multiple, ...rest } = props;
  const { mapSelected, factoryClick } = useSelect(props);
  return (
    <List dense disablePadding {...rest} className={clsx(style.root, rest.className)}>
      {(options || []).map((meeting, index) => {
        const selected = !!mapSelected[meeting.id];
        return (
          <React.Fragment key={meeting.id}>
            <MeetingListItem
              selected={selected}
              meeting={meeting}
              employee={meeting.employee}
              type={meeting.type}
              disabled={rest.disabled}
              onSelect={factoryClick(meeting)}
              multiple={!!multiple}
              {...ItemsProps}
            />
          </React.Fragment>
        );
      })}
    </List>
  );
};
