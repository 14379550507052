import React, { forwardRef, useCallback, useMemo } from 'react';
import { ButtonOrange } from 'components/button-orange';
import AddIcon from '@material-ui/icons/Add';
import { useOpen } from 'AurionCR/components/hooks';
import {
  DialogTaskCreate,
  ResultCreate,
  useTodoTaskNotificationCreated,
} from 'components/dialog-task';
import { ButtonProps } from '@material-ui/core';
import { useMountedRef } from 'hooks';
import { useTranslate } from 'hooks/use-translate';

interface Props extends ButtonProps {
  userPatientProfileSessionID?: string;
  userPatientProfileID: string;
  onDone?: () => void;
}

export const ButtonTask = forwardRef<HTMLButtonElement, Props>(
  ({ userPatientProfileID, userPatientProfileSessionID, onDone, ...rest }, ref) => {
    const { t } = useTranslate();
    const { onSend } = useTodoTaskNotificationCreated();
    const { isOpen, handleToggle, handleClose } = useOpen();
    const initData = useMemo(
      () => ({
        userPatientProfileID,
      }),
      [userPatientProfileID],
    );

    const mountedRef = useMountedRef();
    const onCreated = useCallback(
      async (data: ResultCreate, shouldContinue: boolean) => {
        if (!shouldContinue) {
          handleClose();
        }

        const taskID = String(data.id);
        await onSend({ id: taskID });

        if (!mountedRef.current) {
          return;
        }
        onDone && onDone();
      },
      [handleClose, mountedRef, onDone, onSend],
    );

    const createPayload = useMemo(() => {
      if (!userPatientProfileSessionID) {
        return undefined;
      }
      return { userPatientProfileSessionID };
    }, [userPatientProfileSessionID]);

    return (
      <>
        <ButtonOrange ref={ref} onClick={handleToggle} startIcon={<AddIcon />} {...rest}>
          {t('add-task')}
        </ButtonOrange>

        {isOpen && (
          <DialogTaskCreate
            createPayload={createPayload}
            initData={initData}
            onClose={handleClose}
            onCreated={onCreated}
          />
        )}
      </>
    );
  },
);
