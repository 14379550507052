import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { round } from 'lodash-es';
import { fabric } from 'fabric';
import { useAppDispatch, useAppSelector } from 'store';
import { PDFDocumentProxy } from 'modules/pdfjs';
import { FormDocumentInput } from 'services/form-document-inputs';
import { pdfDocumentSubmitSetPageSize } from '../../store';
import style from './index.module.scss';
import { FabricDefaultDisabledRectProps } from '../../../pdf-document-constructor/components/pdf/pdf-canvas';

export const PdfCanvas = memo(
  ({ pdf, pageNumber = 0 }: { pdf: PDFDocumentProxy; pageNumber: number }) => {
    const dispatch = useAppDispatch();
    //state
    const {
      scale,
      eventFocusItem,
      pages: { [pageNumber]: pageContent },
    } = useAppSelector((state) => state.pdfDocumentSubmit);
    const canvasPDFRef = useRef<any>();
    const canvasFabricRef = useRef<any>();
    const [init, setInit] = useState(false);
    const [Fabric, setFabric] = useState<fabric.Canvas | null>(null);
    // methods
    const addReact = useCallback(
      (item: FormDocumentInput): fabric.Rect | null => {
        if (Fabric) {
          const { width, height, coordX: left, coordY: top, id } = item;
          const rect = new fabric.Rect({
            ...FabricDefaultRectProps,
            width,
            height,
            top,
            left,
            // @ts-ignore
            _id: id,
            // @ts-ignore
            _pageNumber: pageNumber,
          });
          rect.setControlsVisibility(FabricDefaultDisabledRectProps);
          Fabric.add(rect);
          return rect;
        } else {
          return null;
        }
      },
      [Fabric, pageNumber],
    );
    // eventFocusItem
    useEffect(() => {
      if (eventFocusItem && Fabric) {
        if (eventFocusItem.pageNumber === pageNumber) {
          Fabric.getObjects().forEach((item) => {
            // @ts-ignore
            if (item._id === eventFocusItem.id) {
              Fabric.setActiveObject(item);
              Fabric.requestRenderAll();
            }
          });
        } else {
          Fabric.discardActiveObject();
          Fabric.requestRenderAll();
        }
      }
    }, [eventFocusItem, dispatch, pageNumber, Fabric]);
    // init
    useEffect(() => {
      if (Fabric && !init) {
        setInit(true);
        // render init data
        pageContent.inputs.forEach((item) => {
          addReact(item);
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Fabric, init, setInit, addReact]);
    // render page
    useEffect(() => {
      if (canvasPDFRef.current && canvasFabricRef.current && pdf) {
        const canvasPDF = canvasPDFRef.current;
        const canvasFabric = canvasFabricRef.current;
        pdf.getPage(pageNumber + 1).then((PdfPage) => {
          const viewport = PdfPage.getViewport({ scale });
          const { width, height } = viewport;
          canvasFabric.width = canvasPDF.width = width;
          canvasFabric.height = canvasPDF.height = height;
          PdfPage.render({ canvasContext: canvasPDF.getContext('2d'), viewport: viewport });
          // create fabric
          if (!init) {
            setFabric(new fabric.Canvas(canvasFabric));
          }
          // update pdf minWidth
          dispatch(
            pdfDocumentSubmitSetPageSize({
              index: pageNumber,
              width: round(width),
              height: round(height),
            }),
          );
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pdf, canvasPDFRef, canvasFabricRef, setFabric, dispatch]);
    //render
    return (
      <>
        <canvas ref={canvasPDFRef} className={style.pdf} dir={'ltr'} />
        <canvas ref={canvasFabricRef} className={style.fabric} />
      </>
    );
  },
);

export const FabricDefaultRectProps: Partial<fabric.Rect> = {
  top: 100,
  left: 100,
  width: 100,
  height: 100,
  fill: 'transparent',

  transparentCorners: false,
  cornerColor: 'transparent',
  borderColor: 'red',
  borderScaleFactor: 5,
  cornerSize: 5,
  padding: 3,
  borderDashArray: [5, 5],
};

export default PdfCanvas;
