const API_ENDPOINT = 'PharmacyApiResponses';

export const API_PHARMACY_API_RESPONSES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  SEND: `${API_ENDPOINT}/Send`,
  RESEND: `${API_ENDPOINT}/ReSend`,
  PATCH: (data: Pick<IPharmacyApiResponse, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<IPharmacyApiResponse, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};
export interface IPharmacyApiResponse extends Components.Schemas.PharmacyApiResponse {}
