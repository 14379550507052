import React, { memo, useEffect, useState } from 'react';
import { GridConfig, SupportMeetingActivitiesEditor } from 'components/support-meeting-activities';
import { useFormGrid } from 'AurionCR/components/formV2';
import DataGrid from 'components/data-grid';
import { useCurrentUser } from 'components/hooks';
import { UserInfoWeightGrid } from 'components/user-info-weight-grid';
import style from './index.module.scss';
import { supportMeetingEmitter } from '../../emitter';

interface Props {
  supportMeetingID: string;
  disabled?: boolean;
  userPatientProfileID: string;
}
export const SupportActivities = memo<Props>(
  ({ supportMeetingID, userPatientProfileID, disabled = false }) => {
    const { appUserID } = useCurrentUser();
    const [gridConfig] = useState<any>(GridConfig({ appUserID: '', supportMeetingID, disabled }));
    const { grid, onGridHandle, editItemID, setEditItemID, onFormHandle } = useFormGrid('activity');

    useEffect(() => {
      const onActivityCreated = (payload: { meetingID: string }) => {
        if (payload.meetingID !== supportMeetingID) return;
        if (!grid.current) return;
        grid.current.onTasks([['getData']]);
      };
      supportMeetingEmitter.on('activity-created', onActivityCreated);
      return () => {
        supportMeetingEmitter.off('activity-created', onActivityCreated);
      };
    }, [supportMeetingID, grid]);

    return (
      <div className={style.root}>
        <UserInfoWeightGrid m={0} userPatientProfileID={userPatientProfileID} flexWrap={'wrap'} />
        <DataGrid ref={grid} onHandle={onGridHandle} config={gridConfig} />
        {editItemID && (
          <SupportMeetingActivitiesEditor
            onClose={() => setEditItemID(false)}
            appUserID={appUserID}
            onFormHandle={onFormHandle}
            itemID={editItemID}
            supportMeetingID={supportMeetingID}
          />
        )}
      </div>
    );
  },
);
