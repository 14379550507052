import React, { memo, FC, useMemo } from 'react';
import { IDataGridMadel } from '../../model';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Loading, useI18n } from 'AurionCR/components';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useExportToExcel } from '../../../hooks';
import { createGetProps } from '../requests';
import { format } from 'date-fns';
import { APP_FORMAT_EXCEL_DATE } from 'configs/const';

export const Controls: FC<{
  model: IDataGridMadel;
  onTasks: (tasks: any[]) => void;
  filters: any;
  pagination: any;
  sortable: any;
}> = ({ model, filters, onTasks, pagination, sortable }) => {
  const { t } = useI18n();
  const { exportToExcel, loading } = useExportToExcel();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const controls = model?.toolbar?.controls;
  const isDisableResetFilters = useMemo(() => {
    return controls?.resetFilters && model?.toolbar?.filters?.length
      ? !(model?.toolbar?.filters || []).some((value) => {
          const val = filters[value.field];
          return val && val !== value.default;
        })
      : false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls?.resetFilters, filters, model?.toolbar?.filters]);

  // handles
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onResetFilters = () => {
    const filters = (model.toolbar.filters || []).reduce((acc: any, value) => {
      acc[value.field] = value.default || '';
      return acc;
    }, {});
    onTasks([['filters', filters], ['getData']]);
    handleClose();
  };
  const onExport = () => {
    const params = { ...createGetProps({ model, pagination, filters, sortable }) };
    const title = t(model?.toolbar?.excelTitle || model?.toolbar?.title || '');
    params.params.Take = null;
    params.params.Skip = 0;
    exportToExcel({
      ...params,
      columns: model.columns,
      title: `${format(new Date(), APP_FORMAT_EXCEL_DATE)}-${title}`,
    }).catch((e) => {
      console.error('export', model, e);
    });
    handleClose();
  };

  if (!controls?.refresh && !controls?.new && !controls?.resetFilters && !controls?.export)
    return null;

  return (
    <>
      <IconButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
        className="data-grid__toolbar__controls"
      >
        <MoreVertIcon aria-controls="data-grid-controls" aria-haspopup="true" />
      </IconButton>
      <Menu
        id="data-grid-controls"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        style={{
          transform: 'translateY(40px)',
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleClose}
      >
        {controls.new && (
          <MenuItem
            onClick={() => {
              onTasks([['onHandle', { type: 'new' }]]);
              handleClose();
            }}
          >
            {t(controls.new.title || '')}
          </MenuItem>
        )}

        {controls.refresh && (
          <MenuItem
            onClick={() => {
              onTasks([['getData']]);
              handleClose();
            }}
          >
            {t(controls.refresh.title || '')}
          </MenuItem>
        )}

        {controls.resetFilters && (
          <MenuItem onClick={onResetFilters} disabled={isDisableResetFilters}>
            {t(controls.resetFilters.title || '')}
          </MenuItem>
        )}

        {controls.export && (
          <MenuItem onClick={onExport} disabled={!pagination?.total}>
            {t(controls.export.title || '')}
          </MenuItem>
        )}
      </Menu>
      {loading && <Loading />}
    </>
  );
};

export default memo(Controls);
