import React, { useCallback } from 'react';
import { TemplateVisit } from 'components/templates/template-visit';
import { useDataGridTasks } from 'components/data-grid/hooks';
import { usePermissions } from 'hooks/use-permissions';
import { Stack } from 'components/stack';
import { apiClinicalMeetings } from 'services/clinical-meetings';
import { useEffectError } from 'hooks';
import { useTranslate } from 'hooks/use-translate';

const useUpdate = apiClinicalMeetings.useUpdateClinicalMeetingWithLogMutation;

interface Props {
  meetingID: string;
  completeSessionDateTime: string | null;
}

export const ColumnClinicalMeetingVisit: React.FC<Props> = ({
  meetingID,
  completeSessionDateTime,
}) => {
  const { tp } = useTranslate();
  const [update, resultUpdate] = useUpdate();
  useEffectError(resultUpdate.error);

  const isAllowToEdit = usePermissions('isAllowToEditClinicalMeetings');
  const { onTasks } = useDataGridTasks();
  const onClick = useCallback(async () => {
    await update({
      initData: { id: meetingID, completeSessionDateTime },
      formData: {
        id: meetingID,
        completeSessionDateTime: completeSessionDateTime ? null : new Date().toISOString(),
      },
      remark: tp('updates-by-employee'),
      remarkForPatientCallStatusID: undefined,
    });
    onTasks([['getData']]);
  }, [completeSessionDateTime, meetingID, onTasks, tp, update]);

  return (
    <Stack>
      <TemplateVisit
        visitDate={completeSessionDateTime ?? null}
        style={{ color: 'green' }}
        disabled={!isAllowToEdit || resultUpdate.isLoading}
        onClick={onClick}
      />
    </Stack>
  );
};
