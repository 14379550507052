import React from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { BoxProps, Box, Typography } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
interface Props extends BoxProps {}

export const MeetingSlotInvalid: React.FC<Props> = ({ className, ...rest }) => {
  const { t } = useTranslate();
  return (
    <Box {...rest} className={clsx(style.root, className)}>
      <Typography className={style.title} variant={'body2'}>
        {t('invalid-time-slot')}
      </Typography>
    </Box>
  );
};
