import { IPatientCardInfo } from 'services/user-patient-profile';
import { DateValue } from 'utils/dates';
import { UserPatientProfileDietDiary } from 'services/user-patient-profile-diet-diaries';
import { DietDiaryType } from 'services/diet-diary-types';

export interface ListModel
  extends Pick<
    Required<UserPatientProfileDietDiary>,
    | 'id'
    | 'assignToUserEmployeeProfileID'
    | 'entryDate'
    | 'dietDiaryTypeID'
    | 'description'
    | 'replyDate'
    | 'userEmployeeProfileID'
    | 'userPatientProfileID'
    | 'reply'
    | 'isViewed'
  > {
  id: string;
  patient: IPatientCardInfo;
  files: string[];
  dietDiaryType: Pick<DietDiaryType, 'icon' | 'title' | 'color'>;
}

export interface GridModel extends ListModel {
  __checked: boolean;
}

export enum ViewOptions {
  YES = 'yes',
  NO = 'no',
  ALL = 'all',
}

export type DietDiariesReportFilters = {
  search: string;
  dateRange: DateValue[];
  assignedToEmployeeID: string;
  patientIDs: string[];
  isViewed: ViewOptions;
};

export enum BulkActions {
  ASSIGN = 1,
  EDIT,
  DELETE,
}

export interface BulkActionComponentProps {
  selected: { id: string }[];
  onClose: () => void;
  onRefresh: () => void;
}

export const QUERY_NAME = 'id';
