import React, { memo, useCallback, useMemo, useState } from 'react';
import { getRandomString } from 'AurionCR/components';
import { FormDocumentFieldInputID, FormDocumentFieldType } from 'services/form-document-inputs';
import { iFormInputField } from './index';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import style from './index.module.scss';
import clsx from 'clsx';
import { TEST_VALUE_DATE, TEST_VALUE_TEXT, TEST_VALUE_TIME } from '../../helpers';
import { PdfFormDocumentInput } from '../../../store';
import { enumToArray, fieldToLabelKey } from 'utils/other';
import { sortBy } from 'lodash-es';
import { useTranslate } from 'hooks/use-translate';
import { useMap } from 'hooks';

export const FieldInputID = memo(
  ({ value: Value, onChange: OnChange }: iFormInputField<'inputID'>) => {
    const { t } = useTranslate();
    //state
    const [fieldID] = useState(`inputID_${getRandomString()}`);
    // source

    const data = useMemo(() => {
      const list = enumToArray(FormDocumentFieldInputID).map((item) => {
        return { id: item.id, title: t(fieldToLabelKey(item.title)) };
      });
      return sortBy(list, 'title');
    }, [t]);
    const map = useMap(data, 'id');

    const value = useMemo(() => {
      return map[Value] || data[0];
    }, [Value, map, data]);
    // handlers
    const onChange = useCallback(
      (e, value) => {
        const props: Partial<PdfFormDocumentInput> = {
          _value: TEST_VALUE_TEXT,
          formDocumentFieldType: FormDocumentFieldType.text,
        };
        // if previous type is checkbox
        if (Value === FormDocumentFieldInputID.checkBox) {
          props.width = 100;
          props.height = 22;
        }
        switch (value.id) {
          case FormDocumentFieldInputID.signature: {
            props.formDocumentFieldType = FormDocumentFieldType.signature;
            props._value = '';
            break;
          }
          case FormDocumentFieldInputID.dateOfBirth:
          case FormDocumentFieldInputID.startDate:
          case FormDocumentFieldInputID.endDate:
          case FormDocumentFieldInputID.docDate: {
            props.formDocumentFieldType = FormDocumentFieldType.date;
            props._value = TEST_VALUE_DATE;
            break;
          }
          case FormDocumentFieldInputID.docTime: {
            props.formDocumentFieldType = FormDocumentFieldType.date;
            props._value = TEST_VALUE_TIME;
            break;
          }
          case FormDocumentFieldInputID.checkBox: {
            props.formDocumentFieldType = FormDocumentFieldType.checkbox;
            props._value = true;
            props.fontSize = 18;
            props.width = Math.ceil(props.fontSize * 1.2);
            props.height = Math.ceil(props.fontSize * 1.2);
            break;
          }
        }
        OnChange(
          {
            ...props,
            title: value.title,
            inputID: value.id,
          },
          [Value, value.id].includes(FormDocumentFieldInputID.checkBox),
        );
      },
      [OnChange, Value],
    );
    //render
    return (
      <div className={clsx(style.labelInput, style.fillBg)}>
        <label htmlFor={fieldID} className={style.label}>
          {t('type')}:
        </label>
        <Autocomplete
          className={style.select}
          id={fieldID}
          options={data}
          value={value}
          getOptionLabel={(option) => option.title}
          renderOption={(option) => option.title}
          onChange={onChange}
          disableClearable={true}
          renderInput={(params) => (
            <TextField {...params} className={style.input} size="small" variant="outlined" />
          )}
        />
      </div>
    );
  },
);

export default FieldInputID;
