import { ArgumentTypes, PromiseType } from 'utils/types';
import { reduce, isPlainObject, isEqual } from 'lodash-es';

const dec2hex = (dec: number) => {
  return dec.toString(16).padStart(2, '0');
};
export const getRandomString = (length = 10) => {
  const arr = new Uint8Array(length / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
};
export const toKebab = (field: string) => {
  return String(field)
    .replace(/[A-Z]/g, (substring) => {
      return `-${substring}`;
    })
    .toLowerCase()
    .replace(/^-/gi, '');
};

export const fieldToLabelKey = (field: string) => {
  return toKebab(String(field).replace(/ID/g, ''));
};

export const enumToArray = <T extends Record<string, any>>(en: T) => {
  return Object.entries(en)
    .filter(([key]) => !isFinite(key as any))
    .map(([key, val]) => ({
      id: val,
      title: key,
    })) as { id: T[keyof T]; title: string }[];
};

type AsyncFunction = (...args: any[]) => Promise<any>;

export const debounceAsync = <T extends AsyncFunction>(fn: T, wait: number) => {
  let timerID: NodeJS.Timeout | null = null;

  return function (...args: ArgumentTypes<T>) {
    if (timerID) {
      clearTimeout(timerID);
    }

    return new Promise<PromiseType<ReturnType<T>>>((resolve, reject) => {
      timerID = setTimeout(() => {
        fn(...args)
          .then(resolve)
          .catch(reject);
      }, wait);
    });
  };
};

export const getDiff = <T extends Record<string, any>>(obj1: T, obj2: T) => {
  return reduce(
    obj1,
    function (result, value, key: keyof T) {
      if (isPlainObject(value)) {
        result[key] = getDiff(value, obj2[key]);
      } else if (!isEqual(value, obj2[key])) {
        result[key] = value;
      }
      return result;
    },
    {} as T,
  );
};
