import React, { useMemo } from 'react';

interface ContextValue {
  notebookID: string;
  userPatientProfileID: string;
  supportMeetingTypeID?: string;
  excludeFutureMeetingIDs: string[];
  showDelete: boolean;
  meetingFromDateTime: string | undefined;
  meetingToDateTime: string | undefined;
}
export const NotebookFormGeneratorPropsProviderContext = React.createContext<ContextValue | null>(
  null,
);

interface Props {
  notebookID: string;
  userPatientProfileID: string;
  supportMeetingTypeID?: string;
  excludeFutureMeetingIDs?: string[];
  showDelete?: boolean;
  meetingFromDateTime: string | undefined;
  meetingToDateTime: string | undefined;
  children: React.ReactNode;
}
export const NotebookFormGeneratorPropsProvider: React.FC<Props> = ({
  userPatientProfileID,
  supportMeetingTypeID,
  excludeFutureMeetingIDs,
  notebookID,
  showDelete = true,
  children,
  meetingFromDateTime,
  meetingToDateTime,
}) => {
  const value = useMemo(
    () => ({
      userPatientProfileID,
      supportMeetingTypeID,
      notebookID,
      excludeFutureMeetingIDs: excludeFutureMeetingIDs || [],
      showDelete,
      meetingFromDateTime,
      meetingToDateTime,
    }),
    [
      userPatientProfileID,
      supportMeetingTypeID,
      excludeFutureMeetingIDs,
      notebookID,
      showDelete,
      meetingFromDateTime,
      meetingToDateTime,
    ],
  );

  return (
    <NotebookFormGeneratorPropsProviderContext.Provider value={value}>
      {children}
    </NotebookFormGeneratorPropsProviderContext.Provider>
  );
};
