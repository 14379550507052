import React, { useCallback } from 'react';
import style from './index.module.scss';
import { useValueQuery } from 'hooks/use-value-query';
import clsx from 'clsx';
import { TemplatePatientFullName } from '../template-patient-full-name';
import { ButtonBase, ButtonBaseProps } from '@material-ui/core';
import ReplyIcon from '@material-ui/icons/Reply';
import { IPatientCardInfo } from 'services/user-patient-profile';

interface Props extends ButtonBaseProps {
  userPatientProfileID: string | null | undefined;
  patient: IPatientCardInfo | undefined;
}

export const TEMPLATE_PATIENT_CARD_QUERY_NAME = 'patient';

export const TemplatePatientCard: React.FC<Props> = ({
  userPatientProfileID,
  patient,
  ...rest
}) => {
  const { onChange } = useValueQuery<string>({ name: TEMPLATE_PATIENT_CARD_QUERY_NAME });

  const onClick = useCallback(() => {
    onChange(String(userPatientProfileID));
  }, [onChange, userPatientProfileID]);

  const disabled = !userPatientProfileID;
  return (
    <ButtonBase
      className={clsx({ [style.root]: true, [style.clickable]: !disabled })}
      onClick={onClick}
      disabled={disabled}
      disableRipple={!onClick}
      {...rest}
    >
      <div>
        <TemplatePatientFullName patient={patient} className={clsx(style.fullName)}>
          {patient?.fullName}
        </TemplatePatientFullName>
      </div>
      {!!onClick && <ReplyIcon className={style.icon} />}
    </ButtonBase>
  );
};
