import React, { memo, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { sortBy } from 'lodash-es';
import { PdfFormDocument, pdfFormDocumentMerge } from '../../store';
import { PdfPage } from './pdf-page';
import { Scroll } from './scroll';
import pdfjs, { PDFDocumentProxy } from 'modules/pdfjs';
import style from './index.module.scss';
import { FormDocumentFieldInputID, FormDocumentFieldType } from 'services/form-document-inputs';
import { TEST_VALUE_DATE, TEST_VALUE_TEXT, TEST_VALUE_TIME } from '../helpers';
import { ServiceMediaPrivate } from 'services/media-private-services';

export const Pdf = memo(() => {
  const dispatch = useAppDispatch();
  //state
  const { pages, width, initData } = useAppSelector((state) => state.pdfFormDocument);
  const [pdf, setPdf] = useState<PDFDocumentProxy | undefined>();

  // handlers
  const onLoad = useCallback(async () => {
    // fill pages from init data
    if (initData) {
      const { data: blobPdf } = await ServiceMediaPrivate.getFile(initData.pdfUrl);
      const arrayBufferPdf = await blobPdf.arrayBuffer();
      const pdf = await pdfjs.getDocument(new Uint8Array(arrayBufferPdf)).promise;
      // create pages
      const pages: PdfFormDocument['pages'] = Array.from({ length: pdf.numPages || 1 }).map(
        (val, i) => ({
          index: i,
          width: 0,
          height: 0,
          inputs: [],
        }),
      );

      sortBy(initData.formDocumentInputs, ['rank']).forEach((item, i) => {
        const page = pages[item.pageNumber];
        if (page) {
          const _value =
            item.formDocumentFieldType === FormDocumentFieldType.checkbox
              ? true
              : item.formDocumentFieldType === FormDocumentFieldType.signature
              ? ''
              : item.formDocumentFieldType === FormDocumentFieldType.date
              ? item.inputID === FormDocumentFieldInputID.docTime
                ? TEST_VALUE_TIME
                : TEST_VALUE_DATE
              : TEST_VALUE_TEXT;
          page.inputs.push({
            ...item,
            _open: false,
            _valid: true,
            _value,
            _index: page.inputs.length,
          });
        }
      });

      setPdf(pdf);
      dispatch(pdfFormDocumentMerge({ pages }));
    }
  }, [setPdf, dispatch, initData]);
  // init
  useEffect(() => {
    onLoad().catch((e) => {
      console.log(e);
    });
  }, [onLoad]);
  //render
  return (
    <div className={style.pdf} style={{ minWidth: width + 10, overflow: 'hidden' }}>
      <Scroll>
        {pdf && pages.map(({ index }) => <PdfPage key={index} pdf={pdf} pageNumber={index} />)}
      </Scroll>
    </div>
  );
});

export default Pdf;
