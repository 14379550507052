import { put, select, takeEvery } from 'typed-redux-saga';
import { ActionMatchingPattern } from '@redux-saga/types';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { apiUserPatientProfileActivities } from 'services/user-patient-profile-activities';
import { selectAuthUser } from '../auth';
import { i18nAppTranslator } from 'modules/i18n';
import { apiEmailMessages } from 'services/email-messages';
function* watchDeactivateByRefund(
  action: ActionMatchingPattern<
    typeof apiUserPatientProfile.endpoints.deactivatePatientByRefund.matchFulfilled
  >,
) {
  const { meta } = action;
  const {
    arg: { originalArgs },
  } = meta;

  const currentUser = yield* select(selectAuthUser);

  if (!currentUser) {
    return null;
  }

  yield* put(
    apiUserPatientProfileActivities.endpoints.postUserPatientProfileActivity.initiate({
      userPatientProfileID: originalArgs,
      userEmployeeProfileID: String(currentUser.appUserID),
      remarks: i18nAppTranslator.tp('activity-patient-deactivate-refund'),
    }),
  );
}

function* watchSendDocumentToPatient(
  action: ActionMatchingPattern<
    typeof apiEmailMessages.endpoints.sendEmailToPatient.matchFulfilled
  >,
) {
  const { meta } = action;
  const {
    arg: { originalArgs },
  } = meta;

  const currentUser = yield* select(selectAuthUser);

  if (!currentUser) {
    return null;
  }

  yield* put(
    apiUserPatientProfileActivities.endpoints.postUserPatientProfileActivity.initiate({
      userPatientProfileID: originalArgs.userPatientProfileID,
      userEmployeeProfileID: String(currentUser.appUserID),
      remarks: i18nAppTranslator.tp('activity-patient-send-document-to-patient'),
    }),
  );
}
function* watchSendDocumentToPharmacy(
  action: ActionMatchingPattern<
    typeof apiEmailMessages.endpoints.sendEmailToPharmacy.matchFulfilled
  >,
) {
  const { meta } = action;
  const {
    arg: { originalArgs },
  } = meta;

  const currentUser = yield* select(selectAuthUser);

  if (!currentUser) {
    return null;
  }

  yield* put(
    apiUserPatientProfileActivities.endpoints.postUserPatientProfileActivity.initiate({
      userPatientProfileID: originalArgs.userPatientProfileID,
      userEmployeeProfileID: String(currentUser.appUserID),
      remarks: i18nAppTranslator.tp('activity-patient-send-document-to-pharmacy'),
    }),
  );
}
export const userPatientProfileActivitiesSagas = [
  takeEvery(
    apiEmailMessages.endpoints.sendEmailToPatient.matchFulfilled,
    watchSendDocumentToPatient,
  ),
  takeEvery(
    apiEmailMessages.endpoints.sendEmailToPharmacy.matchFulfilled,
    watchSendDocumentToPharmacy,
  ),
  takeEvery(
    apiUserPatientProfile.endpoints.deactivatePatientByRefund.matchFulfilled,
    watchDeactivateByRefund,
  ),
];
