import React, { memo, useMemo } from 'react';
import GridConfig from './grid-config';
import DataGrid from 'components/data-grid';
import { useFormGrid } from 'AurionCR/components/formV2';
import {
  ServiceUserPatientProfileSessionItems,
  UserPatientProfileSessionItem,
} from 'services/user-patient-profile-session-item';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';
import clsx from 'clsx';

interface Props {
  userPatientProfileSessionID: string;
  className?: string;
}
export const GridAllActivities = memo<Props>(({ userPatientProfileSessionID, className }) => {
  const { t } = useTranslate();
  const { grid, onGridHandle } = useFormGrid('activity-log');
  const gridConfig = useMemo<any>(() => {
    return GridConfig(userPatientProfileSessionID, (value: UserPatientProfileSessionItem[]) =>
      value.map((item) => {
        const res = ServiceUserPatientProfileSessionItems.adapterPreviewItem(item, {
          translate: t,
        });
        return { ...res, entryValueString: res.previewValue };
      }),
    );
  }, [t, userPatientProfileSessionID]);
  return (
    <div className={clsx(style.gridLog, className)}>
      <DataGrid ref={grid} onHandle={onGridHandle} config={gridConfig} />
    </div>
  );
});
