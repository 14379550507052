import React, {FC, ReactNode, Suspense, useMemo} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch
} from 'react-router-dom';
import {useDispatch} from "react-redux";
// @ts-ignore
import queryString from 'query-string';
import {initRoutes} from "../store/modules/routes";
import {useAuth} from "./auth";
import {useI18n} from "./i18";
import {debounce} from "lodash-es"

export interface IRoute {
  path: string,
  title: string,
  exact?: boolean,
  inMenu?: boolean,
  icon?: undefined | string | ReactNode,
  private?: undefined | boolean,
  permission?: undefined | string,
  inLayout?: boolean,
  component?: any,
  routes?: undefined | IRoute[]
}

export const useRouter = () => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const query = {
    ...queryString.parse(location.search),
    ...params
  };
  const search = (value?: {}) => {
    const query_ = {...query, ...value || {}};
    const search_ = Object.keys(query_).reduce((result: any[], key) => {
      if (query_[key]) result.push(`${key}=${query_[key]}`);
      return result;
    }, []).join('&');
    return (search_) ? '?' + search_ : ''
  };
  const replaceQuery_ = (value?: {}) => {
    const search_ = search(value);
    if (search_ !== location.search) {
      history.replace({pathname: location.pathname, search: search_});
    }
  };
  const replaceQuery = debounce(replaceQuery_, 150);
  return useMemo(() => {
      return {
        push: history.push,
        replace: history.replace,
        pathname: location.pathname,
        query: query,
        search,
        replaceQuery,
        match,
        location,
        history
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params, match, location, history]
  );
};

export const parseRoute = (t: any, route: IRoute, flat: IRoute[], map: any, parent?: IRoute | null) => {
  const permission_ = `${parent?.permission || ''}${route.permission && parent?.permission ? ',' : ''}${route.permission || ''}`;
  let result: IRoute = {
    exact: true,
    inMenu: true,
    private: true,
    inLayout: true,
    ...route,
    permission: permission_,
    path: `${parent ? parent.path : ''}${route.path}`
  };
  result.title = t(result.title);
  if (result.routes) result.routes = result.routes.map(value => parseRoute(t, value, flat, map, result));
  if (result.component) flat.push(result);
  map[result.path] = result;
  return result;
};
export const parser = (config: IRoute[], page404: ReactNode, t: any) => {
  let map: {} = {};
  let flat: IRoute[] = [];
  let tree: IRoute[] = [
    ...config,
    {
      path: '*',
      title: '404',
      inMenu: false,
      private: false,
      component: page404
    }
  ].map(value => parseRoute(t, value, flat, map, null));
  return {flat, tree, map};
};

export const LayoutWrapper: FC<any> = (props) => {
  const route = useRouter();
  if (props.layout && props.routes[route.pathname] && props.routes[route.pathname].inLayout) {
    return (<props.layout>{props.children}</props.layout>);
  }
  return (<>{props.children}</>);
};
export const RoutePrivateWrapper: FC = (props: any) => {
  const auth = useAuth();
  if (props.route.private) {
    if (!auth.init && auth.loading) {
      return (<h1>Loading...</h1>);
    } else if (auth.error || !auth.user) {
      return (<Redirect to={{pathname: props.loginPath, search: `?redirect=${props.location.pathname.slice(1)}`}}/>)
    } else if (auth.permission(props.route.permission)) {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <props.route.component {...props}/>
        </Suspense>
      )
    } else {
      return <props.page404/>;
    }
  }
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <props.route.component {...props}/>
    </Suspense>
  )
};
export const Routes: FC<{
  routes: IRoute[],
  layout: ReactNode,
  page404: ReactNode,
  basename?: string,
  loginPath?: string
}> = ({
        routes,
        layout,
        page404,
        basename = '/',
        loginPath = '/login'
      }) => {
  const {t} = useI18n();
  // parse props
  const {flat, tree, map} = parser(routes, page404, t);
  // set store
  const dispatch = useDispatch();
  // TODO: need check init state, now hack to waiting init components
  setTimeout(() => {
    dispatch(initRoutes(tree, flat, map, basename, loginPath));
  }, 100)
  // render
  return (
    // render routes
    <Router basename={basename}>
      {flat.length > 0 &&
      <LayoutWrapper
        layout={layout}
        routes={map}>
        <Switch>
          {flat.map(route => (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              render={props => (
                // @ts-ignore
                <RoutePrivateWrapper {...props} route={route} loginPath={loginPath} page404={page404}/>
              )}
            />
          ))}
        </Switch>
      </LayoutWrapper>
      }
    </Router>
  )
};

export default useRouter;
