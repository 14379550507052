import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import GridConfig from './grid-config';
import DataGrid from 'components/data-grid';
import { GridMethods } from 'models/grid';
import { patientEmitter } from '../../emitter';
import { ButtonAddWeight } from './components/button-add-weight';
import { Box } from '@material-ui/core';

interface Props {
  userPatientProfileID: string;
}
export const GridWeight = memo<Props>(({ userPatientProfileID }) => {
  const [gridConfig] = useState<any>(GridConfig(userPatientProfileID));
  const grid = useRef<GridMethods>(null);

  const onRefresh = useCallback(() => {
    grid.current?.onTasks([['getData']]);
  }, []);

  useEffect(() => {
    const refresh = (payload: { patientID: string }) => {
      if (payload.patientID !== userPatientProfileID) {
        return;
      }
      onRefresh();
    };

    patientEmitter.on('refresh-all', refresh);
    patientEmitter.on('refresh-weight', refresh);
    return () => {
      patientEmitter.off('refresh-all', refresh);
      patientEmitter.off('refresh-weight', refresh);
    };
  }, [userPatientProfileID, onRefresh]);

  return (
    <DataGrid
      ref={grid}
      config={gridConfig}
      renderControlsSlot={() => {
        return (
          <Box px={1}>
            <ButtonAddWeight userPatientProfileID={userPatientProfileID} onRefresh={onRefresh} />
          </Box>
        );
      }}
    />
  );
});
