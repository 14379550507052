import {useCallback, useEffect, useMemo, useState} from "react";
import {IUseForm, IUseFormHandle} from "./interfaces/userFormHook";
import {useI18n} from "../../i18";
import {useDispatch} from "react-redux";
import useRouter from "../../router";
import {useForm} from "react-hook-form";
import axios, {CancelTokenSource} from "axios";
import {checkEs6AndRun, es6Run, requestError} from "../../helpers";
import {notifyRequestResult} from "../../../store/modules/notify";
import {parseMixins, parseProps, saveMixins} from "./parsers";
import {UnpackNestedValue} from "react-hook-form/dist/types/form";
import {DeepPartial} from "react-hook-form/dist/types/utils";

// TODO: fix all mixins when form reset/set

export const useFormHook = <Model = { id: null | string }>(props: IUseForm<Model>) => {
  // props
  const {
    editID,
    fields,
    title, titleNew,
    get, delete: _delete, patch, put, post,
    formHandle
  } = useMemo(() => parseProps(props), [props]);
  const {t} = useI18n();
  const dispatch = useDispatch();
  const router = useRouter();
  const defaultValues = useMemo(() => {
    return {...fields} as UnpackNestedValue<DeepPartial<Model>>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields])
  const formUse = useForm<Model>({defaultValues});
  // inner props
  const [initData, setInitData] = useState<Model>();
  const [formLoading, setFormLoading] = useState(false);
  const formIsNew = useMemo(() => ['true', true, ''].includes(editID), [editID]);
  const formTitle = useMemo(() => {
    if (formIsNew) {
      return t(titleNew || '')
    } else if (initData && typeof title === 'function') {
      return title(initData)
    } else if (initData && typeof title === 'string') {
      return es6Run(initData, title)
    } else {
      return t('loading')
    }
    // eslint-disable-next-line
  }, [formIsNew, titleNew, title, initData, t]);
  // handlers
  const formRequestError = useCallback((error) => {
    setFormLoading(false);
    console.log(error);
    dispatch(notifyRequestResult(requestError(error), 'error'));
    // eslint-disable-next-line
  }, []);
  const formRequestHandle = useCallback<IUseFormHandle<Model>>((params) => {
    if (formHandle) formHandle(params);
  }, [formHandle]);
  // requests
  const getItem = useCallback(async (cancelToken: CancelTokenSource) => {
    if (get && get.request) {
      // update status
      setFormLoading(true);
      // request

      const select = Object.keys({...fields, [get.field || '']: get.field}).toString()
      const response = await get.request({
        url: checkEs6AndRun(get.url, editID),
        params: {
          'Filter': `${get.field}=="${editID}"`,
          'Select': select
        },
        cancelToken
      });
      // parser
      const data = (get.parser) ? get.parser(response.data) : response.data;
      // update form data
      if (data !== null) {
        setInitData(data);
        formUse.reset(data);
        setFormLoading(false);
        formRequestHandle({type: 'get', payload: data});
        return data;
      } else {
        router.replace('/not-found');
        return null;
      }
    }
    return 'ok';
    // eslint-disable-next-line
  }, [editID, fields, get, formRequestHandle]);
  const deleteItem = useCallback(async () => {
    if (_delete && _delete.request) {
      setFormLoading(true);
      const {data} = await _delete.request({url: checkEs6AndRun(_delete.url, initData)});
      setFormLoading(false);
      formRequestHandle({type: 'delete', payload: initData as Model});
      return data;
    }
    return 'ok'
    // eslint-disable-next-line
  }, [editID, initData, formRequestHandle]);
  const patchRequest = useCallback(async (data: Model) => {
    if (patch && patch.request) {
      const {data: pathData} = await patch.request({url: checkEs6AndRun(patch.url, data), data});
      return {...data, ...(typeof pathData === 'object' ? pathData : {})};
    } else {
      return data;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patch]);
  const patchItem = useCallback(async (formData, isEditAfter = false) => {
    if (patch && patch.request) {
      // update status
      setFormLoading(true);
      // parse form data
      let {data, mixins} = parseMixins({...(initData || {}), ...formData});
      // save mixins
      data = await saveMixins(data, mixins);
      // save main
      data = await patchRequest(data);
      // done
      setFormLoading(false);
      formRequestHandle({type: 'patch', payload: data, isEditAfter: isEditAfter ? data[patch.field] : undefined});
      return data;
    }
    return 'ok'
  }, [initData, patch, patchRequest, formRequestHandle]);
  const putRequest = useCallback(async (data) => {
    if (put && put.request) {
      const {data: putData} = await put.request({url: checkEs6AndRun(put.url, data), data});
      // update response
      return {...data, ...(typeof putData === 'object' ? putData : {})};
    } else {
      return data;
    }
  }, [put]);
  const putItem = useCallback(async (formData, isEditAfter = false) => {
    if (put && put.request) {
      // update status
      setFormLoading(true);
      // parse form data
      let {data, mixins} = parseMixins({...(initData || {}), ...formData});
      // save mixins
      data = await saveMixins(data, mixins);
      // save main
      data = await putRequest(data);
      // done
      setFormLoading(false);
      formRequestHandle({type: 'put', payload: data, isEditAfter: isEditAfter ? data[put.field] : undefined});
      return data;
    }
    return 'ok'
  }, [initData, put, putRequest, formRequestHandle]);
  const postItem = useCallback(async (formData, isEditAfter = false) => {
    if (post && post.request) {
      // update status
      setFormLoading(true);
      // parse form data
      let {data, mixins} = await parseMixins(formData);
      // save main
      const {data: postData} = await post.request({
        url: checkEs6AndRun(post.url, data),
        data: {...data, id: undefined}
      });

      // update response
      data = (typeof postData === 'object') ? {...data, ...postData} : {...data, id: postData};
      // save mixins
      if (mixins.length) {
        data = await saveMixins(data, mixins);

        // patch/put
        if (patch && patch.request) {
          data = await patchRequest(data);
        } else if (put && put.request) {
          data = await putRequest(data);
        }
      }
      // done
      setFormLoading(false);
      formRequestHandle({type: 'post', payload: data, isEditAfter: isEditAfter ? data[post.field] : undefined});
      return data;
    }
    return 'ok'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initData, post, patch, patchRequest, put, putRequest, formRequestHandle]);
  // main
  const onSubmit = useCallback((formData: Model) => {
    return (formIsNew) ? postItem(formData) : patch && patch.request ? patchItem(formData) : putItem(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formIsNew, postItem, patchItem, putItem, patch]);
  const onSubmitAndContinue = useCallback((formData: Model) => {
    return (formIsNew) ? postItem(formData, true) : patch && patch.request ? patchItem(formData, true) : putItem(formData, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formIsNew, postItem, patchItem, putItem, patch]);
  // init
  useEffect(() => {
    let cancelToken: any;
    if (!formIsNew) {
      getItem(cancelToken = axios.CancelToken.source()).catch(formRequestError)
    } else {
      formUse.reset({...fields} as UnpackNestedValue<DeepPartial<Model>>);
      setInitData(undefined);
    }
    return () => {
      if (cancelToken) cancelToken.cancel();
    };
    // eslint-disable-next-line
  }, [formIsNew, getItem, formUse.reset, fields]);
  // return
  return {
    initData, setInitData,
    formLoading, setFormLoading,
    formIsNew, formTitle, formUse,
    getItem, deleteItem, patchItem, putItem, postItem,
    onSubmit, onSubmitAndContinue
  };
};
