import { API_ACCOUNTS } from './models';
import { api } from 'utils/service';

export * from './models';

class Service {
  getCurrentUser() {
    return api.get<Components.Schemas.CurrentUserProfileResponse>(
      API_ACCOUNTS.GET_CURRENT_APP_USER,
    );
  }
  loginWithCode(input: Components.Schemas.LoginRequest) {
    return api.post<Components.Schemas.LoginResponse>(API_ACCOUNTS.LOGIN_WITH_CODE, input);
  }
  generatePassword(input: Components.Schemas.GeneratePasswordRequest) {
    return api.post(API_ACCOUNTS.GENERATE_PASSWORD, input);
  }
  logout() {
    return api.post(API_ACCOUNTS.LOGOUT);
  }
}

export const ServiceAccount = new Service();
