import { API_PHARMACY_MESSAGE, IPharmacyMessage } from './models';
import { api } from 'utils/service';

export * from './models';

class Service {
  async post(
    data: Pick<
      IPharmacyMessage,
      | 'pharmacyID'
      | 'userPatientProfileID'
      | 'userEmployeeProfileID'
      | 'subject'
      | 'html'
      | 'prescriptionFilePath'
    >,
  ) {
    return api.post(API_PHARMACY_MESSAGE.POST, { ...data, entryDate: new Date().toISOString() });
  }
}

export const ServicePharmacyMessages = new Service();
