import React, { memo, useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { selectActiveListItems, TMMerge } from 'pages/TaskManager/components/store';
import { ButtonMeetingAdd } from 'components/button-meeting-add';
import { ArrayType } from 'utils/types';

export const BtnAddNew = memo(() => {
  const dispatch = useAppDispatch();
  const list = useAppSelector(selectActiveListItems);
  const source = useMemo(() => list.filter((item) => !item.isPrescriptionRenewal), [list]);
  const { appUserID } = useAppSelector((state) => state.auth.user);
  const onClick = useCallback(
    (item: ArrayType<typeof source> | null) => {
      dispatch(
        TMMerge({
          editMeeting: {
            userEmployeeProfileID: appUserID,
            supportMeetingTypeID: item ? item.id : undefined,
          },
        }),
      );
    },
    //eslint-disable-next-line
    [dispatch, appUserID],
  );

  return <ButtonMeetingAdd options={source} onClick={onClick} />;
});
