import { API_SUPPORT_MEETING_ACTIVITIES } from 'services/support-meeting-activities';
import { API_REMARK_FOR_PATIENT_CALL_STATUSES } from 'services/remark-for-patient-call-status';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { API_USER_EMPLOYEE_PROFILES } from 'services/user-employee-profiles';
import { TemplateLog } from 'components/templates/template-log';
import React from 'react';

const mapLabel = {
  userEmployeeProfileID: 'employee',
};

type GridModel = {
  changes: string;
};
interface Options {
  appUserID: string;
  supportMeetingID: string;
  disabled?: boolean;
}
export const GridConfig = ({ appUserID, supportMeetingID, disabled = false }: Options): any => ({
  id: 'grind_SupportMeetingActivities',
  store: {
    get: {
      url: API_SUPPORT_MEETING_ACTIVITIES.GET_ALL_DYNAMIC,
      filters: `supportMeetingID=="${supportMeetingID}"`,
    },
    create: {
      url: API_SUPPORT_MEETING_ACTIVITIES.POST,
    },
    patch: {
      url: API_SUPPORT_MEETING_ACTIVITIES.PATCH,
    },
    delete: {
      url: API_SUPPORT_MEETING_ACTIVITIES.DELETE,
      field: 'remarks',
    },
    sortable: {
      field: 'entryDate',
      order: 'desc',
    },
  },
  controlsDropDown: !disabled ? ['delete'] : null,
  toolbar: {
    controlsDefault: { refresh: null, new: !disabled ? { title: 'add-new' } : null },
    controls: { new: !disabled ? { title: 'add-new' } : null },
    filters: [
      {
        field: 'entryDate',
        options: {
          type: 'date-multiple',
          placeholder: 'select-date',
          style: { minWidth: '21rem' },
        },
      },
      {
        field: 'userEmployeeProfileID',
        options: {
          type: 'select',
          label: 'employee',
          source: `${API_USER_EMPLOYEE_PROFILES.GET_ALL_DYNAMIC}?Select=appIdentityUserID as id,fullName as title&orderBy=firstName,lastName`,
          value: appUserID,
          style: { minWidth: '16rem' },
        },
      },
      {
        field: 'remarkForPatientCallStatusID',
        options: {
          type: 'select',
          label: 'remarks-for-patient',
          source: `${API_REMARK_FOR_PATIENT_CALL_STATUSES.GET_ALL_DYNAMIC}?Select=id,title&orderBy=rank`,
          style: { minWidth: '16rem' },
        },
      },
    ],
  },
  columns: [
    {
      title: 'entry-date',
      field: 'entryDate',
      template: {
        type: 'date',
        format: APP_FORMAT_DATE_TIME,
      },
    },
    {
      title: 'employee',
      field: 'userEmployeeProfile.fullName as userEmployeeProfileFullName',
      sortable: 'userEmployeeProfile.firstName',
    },
    {
      title: 'remarks-for-patient',
      field: 'remarkForPatientCallStatus.title as remarkForPatientCallStatusTitle',
      sortable: 'remarkForPatientCallStatus.title',
      editor: !disabled
        ? {
            type: 'select',
            field: 'remarkForPatientCallStatusID',
            source: `${API_REMARK_FOR_PATIENT_CALL_STATUSES.GET_ALL_DYNAMIC}?Select=id,title&orderBy=rank`,
          }
        : undefined,
    },
    {
      title: 'remarks',
      field: 'remarks',
    },
    {
      title: 'changes',
      field: 'changes',
      template: (data: GridModel) => <TemplateLog value={data.changes} mapLabels={mapLabel} />,
    },
  ],
});
