import React, { memo, useCallback, useMemo, useState } from 'react';
import { iEmployeeMeetingDateSelect } from './@type';
import { DialogTimeSlots } from './components';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import TodayIcon from '@material-ui/icons/Today';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';
import { convertToDate } from 'utils/dates';
import { APP_FORMAT_DATE_TIME, APP_FORMAT_TIME } from 'configs/const';

const prevent = (e: React.SyntheticEvent) => {
  e.stopPropagation();
  e.preventDefault();
};

type Props = iEmployeeMeetingDateSelect;

export const EmployeeMeetingDateSelect = memo<Props>(
  ({ value, onChange, error, disabled, userEmployeeID, preferStartDate, defaultDuration }) => {
    const { t } = useTranslate();

    // states
    const [isOpen, setIsOpen] = useState(false);
    const title = useMemo(() => {
      if (!value) return '';
      if (value.length !== 2) return '';

      return [
        format(convertToDate(value[0]), APP_FORMAT_DATE_TIME),
        format(convertToDate(value[1]), APP_FORMAT_TIME),
      ].join(' - ');
    }, [value]);

    // handlers
    const handleOpen = useCallback(
      (e) => {
        if (e) {
          prevent(e);
        }
        setIsOpen(true);
      },
      [setIsOpen],
    );
    const handleClear = useCallback(
      (e) => {
        if (e) {
          prevent(e);
        }
        onChange(null);
      },
      [onChange],
    );
    const handleChange = useCallback(
      (value) => {
        onChange(value);
        setIsOpen(false);
      },
      [onChange, setIsOpen],
    );

    return (
      <>
        <TextField
          fullWidth
          error={Boolean(error)}
          value={title}
          label={t('meeting-datetime')}
          placeholder={`${t('click-to-set')} ${t('meeting-datetime')}`}
          helperText={(error && error?.message && t(error.message)) || ''}
          className={style.field}
          disabled={disabled}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">
                <TodayIcon />
              </InputAdornment>
            ),
            endAdornment: title?.length ? (
              <InputAdornment position="end">
                <IconButton onClick={handleClear} disabled={disabled}>
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ) : undefined,
            onClick: disabled ? undefined : handleOpen,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {isOpen && (
          <DialogTimeSlots
            value={value}
            userEmployeeID={userEmployeeID}
            onChange={handleChange}
            onClose={() => setIsOpen(false)}
            preferStartDate={preferStartDate}
            defaultDuration={defaultDuration}
          />
        )}
      </>
    );
  },
);
