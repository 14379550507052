import clsx from 'clsx';
import React, { forwardRef, Ref, memo } from 'react';
import style from './index.module.scss';
import { Box, BoxProps } from '@material-ui/core';

export interface NativeScrollProps extends BoxProps {
  className?: string;
  mode?: 'hover' | 'visible';
  ref?: Ref<HTMLDivElement>;
}

const Component = (
  { className, children, mode = 'hover', ...rest }: NativeScrollProps,
  ref: Ref<HTMLDivElement>,
) => {
  return (
    // @ts-ignore
    <Box ref={ref} className={clsx(style.root, className, style[mode])} {...rest}>
      {children}
    </Box>
  );
};

export const NativeScroll = memo(forwardRef(Component)) as typeof Component;
