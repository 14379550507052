import { ChangeLogConfig } from 'modules/change-log';
import { dateFormat, isDateLike } from 'utils/dates';

import { UserPatientProfile, UserPatientProfilePatchInput } from './models';
import { PatchPartial } from 'utils/types';

type Config = ChangeLogConfig<
  PatchPartial<UserPatientProfilePatchInput, 'appIdentityUserID'>,
  UserPatientProfile
>;

export const logConfig: Record<keyof UserPatientProfilePatchInput, Config> = {
  appIdentityUserID: {
    select: 'appIdentityUserID',
    getValue: (res) => String(res.appIdentityUserID),
  },
  firstName: {
    select: 'firstName',
    getValue: (res) => String(res.firstName),
  },
  lastName: {
    select: 'lastName',
    getValue: (res) => String(res.lastName),
  },
  idNumber: {
    select: 'idNumber',
    getValue: (res) => String(res.idNumber),
  },
  mobilePhone: {
    select: 'mobilePhone',
    getValue: (res) => String(res.mobilePhone),
  },
  occupation: {
    select: 'occupation',
    getValue: (res) => String(res.occupation),
  },
  email: {
    select: 'email',
    getValue: (res) => String(res.email),
  },
  genderID: {
    select: 'new { gender.title } as gender',
    getValue: (res) => String(res.gender?.title),
  },
  cityID: {
    select: 'new { city.title } as city',
    getValue: (res) => String(res.city?.title),
  },
  weight: {
    select: 'weight',
    getValue: (res) => String(res.weight),
  },
  height: {
    select: 'height',
    getValue: (res) => String(res.height),
  },
  dateOfBirth: {
    select: 'dateOfBirth',
    getValue: (res) =>
      isDateLike(res.dateOfBirth) ? dateFormat(res.dateOfBirth) : String(res.dateOfBirth),
  },
  address: {
    select: 'address',
    getValue: (res) => String(res.address),
  },
  isTLC: {
    select: 'isTLC',
    getValue: (res) => String(res.isTLC),
  },
  isActive: {
    select: 'isActive',
    getValue: (res) => String(res.isActive),
  },
  mustFillHealthSurvey: {
    select: 'mustFillHealthSurvey',
    getValue: (res) => String(res.mustFillHealthSurvey),
  },
  maintenanceWeight: {
    select: 'maintenanceWeight',
    getValue: (res) => String(res.maintenanceWeight),
  },
  diagnosisTypeID: {
    select: 'new { diagnosisType.title } as diagnosisType',
    getValue: (res) => String(res.diagnosisType?.title),
  },
  bmiSummaryID: {
    select: 'new { bmiSummary.title } as bmiSummary',
    getValue: (res) => String(res.bmiSummary?.title),
  },
  medicalRemarks: {
    select: 'medicalRemarks',
    getValue: (res) => String(res.medicalRemarks),
  },
  userEmployeeProfileSupportPrimaryID: {
    select:
      'new { userEmployeeProfileSupportPrimary.fullName } as userEmployeeProfileSupportPrimary',
    getValue: (res) => String(res.userEmployeeProfileSupportPrimary?.fullName),
  },
  userEmployeeProfileSupportSecondaryID: {
    select:
      'new { userEmployeeProfileSupportSecondary.fullName } as userEmployeeProfileSupportSecondary',
    getValue: (res) => String(res.userEmployeeProfileSupportSecondary?.fullName),
  },
  userEmployeeProfileDietitianID: {
    select: 'new { userEmployeeProfileDietitian.fullName } as userEmployeeProfileDietitian',
    getValue: (res) => String(res.userEmployeeProfileDietitian?.fullName),
  },
  remarks: {
    select: 'remarks',
    getValue: (res) => String(res.remarks),
  },
  shortRemark: {
    select: 'shortRemark',
    getValue: (res) => String(res.shortRemark),
  },
  familyMemberFirstName: {
    select: 'familyMemberFirstName',
    getValue: (res) => String(res.familyMemberFirstName),
  },
  familyMemberLastName: {
    select: 'familyMemberLastName',
    getValue: (res) => String(res.familyMemberLastName),
  },
  familyMemberPhone: {
    select: 'familyMemberPhone',
    getValue: (res) => String(res.familyMemberPhone),
  },
  familyMemberIDNumber: {
    select: 'familyMemberIDNumber',
    getValue: (res) => String(res.familyMemberIDNumber),
  },
  familyMemberRelationship: {
    select: 'familyMemberRelationship',
    getValue: (res) => String(res.familyMemberRelationship),
  },
  onHold: {
    select: 'onHold',
    getValue: (res) => String(res.onHold),
  },
  doNotProlongTreatment: {
    select: 'doNotProlongTreatment',
    getValue: (res) => String(res.doNotProlongTreatment),
  },
  slowIncreaseWeight: {
    select: 'slowIncreaseWeight',
    getValue: (res) => String(res.slowIncreaseWeight),
  },
  changeDosageByDoctorApproval: {
    select: 'changeDosageByDoctorApproval',
    getValue: (res) => String(res.changeDosageByDoctorApproval),
  },
  onHoldEndDate: {
    select: 'onHoldEndDate',
    getValue: (res) =>
      isDateLike(res.onHoldEndDate) ? dateFormat(res.onHoldEndDate) : String(res.onHoldEndDate),
  },
  onHoldReason: {
    select: 'onHoldReason',
    getValue: (res) => String(res.onHoldReason),
  },
  passportNumber: {
    select: 'passportNumber',
    getValue: (res) => String(res.passportNumber),
  },
  nameEng: {
    select: 'nameEng',
    getValue: (res) => String(res.nameEng),
  },
  preferedPharmacyType: {
    select: 'preferedPharmacyType',
    getValue: (res) => String(res.preferedPharmacyType),
  },
  doNotAllowEndOfTreatmentLetter: {
    select: 'doNotAllowEndOfTreatmentLetter',
    getValue: (res) => String(res.doNotAllowEndOfTreatmentLetter),
  },
  extraAttention: {
    select: 'extraAttention',
    getValue: (res) => String(res.extraAttention),
  },
};
