export const PICKER_COLORS = [
  '#EB5757',
  '#FDEEEE',
  '#F6AA41',
  '#FDEACF',
  '#F8D000',
  '#FEF6CC',
  '#68C690',
  '#DFF3E7',
  '#68B7E1',
  '#DFF0F9',
  // rest
  '#DB3E00',
  '#FCCB00',
  '#008B02',
  '#006B76',
  '#1273DE',
  '#004DCF',
  '#5300EB',
  '#888888',
  '#EB9694',
  '#FAD0C3',
  '#FEF3BD',
  '#C1E1C5',
  '#BEDADC',
  '#C4DEF6',
  '#BED3F3',
  '#D4C4FB',
  '#FFFFFF',
  '#B80000',
];
