import React, { memo, useCallback } from 'react';
import { round } from 'lodash-es';
import { useAppDispatch, useAppSelector } from 'store';
import { getRandomString, useI18n } from 'AurionCR/components';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import { pdfFormDocumentMerge, pdfFormDocumentScrollToPage } from '../../store';
import { FormInput } from './form-input';
import style from './index.module.scss';
import { StandardFonts } from 'pdf-lib';
import { FormDocumentFieldInputID, FormDocumentFieldType } from 'services/form-document-inputs';
import { TEST_VALUE_TEXT } from '../helpers';

export const FormPage = memo(({ index }: { index: number }) => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  //state
  const { idPrefix } = useAppSelector((state) => state.pdfFormDocument);
  const { inputs, width, height } = useAppSelector((state) => state.pdfFormDocument.pages[index]);
  // handlers
  const onAddNew = useCallback(() => {
    dispatch(
      pdfFormDocumentMerge({
        eventAdd: {
          pageNumber: index,

          id: `${idPrefix}${getRandomString()}`,
          width: 100,
          height: 22,
          coordX: round(width / 2 - 60),
          coordY: round(height / 2 - 12),

          fontFamily: StandardFonts.TimesRoman,
          fontSize: 9,
          background: '',

          inputID: FormDocumentFieldInputID.firstName,
          title: 'first-name',
          formDocumentFieldType: FormDocumentFieldType.text,
          isRequired: true,
          maxLength: 100,

          _open: false,
          _valid: true,
          _index: inputs.length,
          _value: TEST_VALUE_TEXT,
        },
      }),
    );
  }, [index, width, height, idPrefix, inputs, dispatch]);
  const onScrollTo = useCallback(() => {
    dispatch(pdfFormDocumentScrollToPage({ pageNumber: index }));
  }, [dispatch, index]);
  //render
  return (
    <div className={style.page}>
      <div className={style.head}>
        <div className={style.title}>
          <Button
            className={style.btnFocusPage}
            variant="outlined"
            color="secondary"
            title={t('page-focus')}
            onClick={onScrollTo}
          >
            <GpsFixedIcon className={style.icon} />
          </Button>
          <div className={style.text}>
            {t('page')} {index + 1}
          </div>
        </div>
        <Button className={style.btnAdd} variant="contained" color="secondary" onClick={onAddNew}>
          <AddIcon className={style.icon} /> {t('new-form-field')}
        </Button>
      </div>
      <div className={style.inputs}>
        {inputs.map(({ id, pageNumber }, index) => (
          <FormInput key={id} pageNumber={pageNumber} index={index} />
        ))}
      </div>
    </div>
  );
});

export default FormPage;
