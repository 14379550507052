import { DefaultGridButtonExportToExcel } from 'components/data-grid/model';
import { TemplateDuration } from 'components/templates/template-duration';
import { TemplateLink } from 'components/templates/template-link';
import { APP_FORMAT_DATE_TIME_SECONDS } from 'configs/const';
import React from 'react';
import {
  API_USER_PATIENT_PROFILE_TELECOMS,
  UserPatientProfileTelecom,
} from 'services/user-patient-profile-telecoms';
import { excelTemplateDate } from 'utils/dates';
import { TemplateDate } from 'components/templates/template-date';

interface Options {
  userPatientProfileID: string;
  displayLogs: boolean;
}
export default ({ userPatientProfileID, displayLogs }: Options) => ({
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_TELECOMS.GET_ALL_DYNAMIC,
      filters: [
        `userPatientProfileID=="${userPatientProfileID}"`,
        '!String.IsNullOrEmpty(recordingUrl)',
      ]
        .filter(Boolean)
        .join('&&'),
    },
    sortable: {
      field: 'rowIndex',
      order: 'asc',
    },
  },
  toolbar: {
    title: '',
    excelTitle: 'calls',
    controls: null,
    controlsDefault: {
      new: null,
      resetFilters: null,
      export: DefaultGridButtonExportToExcel,
    },
  },
  columns: [
    {
      title: 'row-index',
      field: 'rowIndex',
    },
    {
      title: 'entry-date',
      field: 'entryDate',
      type: 'date',
      template: (data: UserPatientProfileTelecom) => (
        <TemplateDate date={data.entryDate} format={APP_FORMAT_DATE_TIME_SECONDS} />
      ),
      valueTemplate: (data: UserPatientProfileTelecom) => excelTemplateDate(data.entryDate),
      width: '15%',
    },
    ...(displayLogs
      ? [
          {
            title: 'waiting-duration-in-seconds',
            field: 'waitingDurationInSeconds',
          },
        ]
      : []),
    {
      title: 'duration',
      field: 'callDurationInSeconds',
      template: (data: UserPatientProfileTelecom) => (
        <TemplateDuration value={data.callDurationInSeconds} />
      ),
      width: '10%',
    },
    ...(displayLogs
      ? [
          {
            title: 'call-id',
            field: 'callID',
            width: '25%',
          },
        ]
      : []),
    {
      title: 'call-direction',
      field: 'callDirection',
    },
    {
      title: 'call-status',
      field: 'callStatus',
    },
    ...(displayLogs
      ? [
          {
            title: 'destination-phone-number',
            field: 'destinationPhoneNumber',
          },
          {
            title: 'employee-phone-number',
            field: 'employeePhoneNumber',
          },
          {
            title: 'recording-file',
            field: 'recordingFile',
          },
        ]
      : []),
    {
      title: 'recording-url',
      field: 'recordingUrl',
      template: (data: UserPatientProfileTelecom) => (
        <TemplateLink documentURL={data.recordingUrl} labelKey={'download-doc'} />
      ),
    },
  ],
});
