import { DefaultGridButtonExportToExcel } from 'components/data-grid/model';
import { APP_FORMAT_DATE } from 'configs/const';
import { API_USER_PATIENT_PROFILE_SESSION_ITEMS } from 'services/user-patient-profile-session-item';

export default (userPatientProfileID: string): any => ({
  id: 'grid_user-patient-profile-subscriptions',
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_SESSION_ITEMS.GET_ALL_DYNAMIC,
      filters: `fieldInput.inputType.isSideEffect&&userPatientProfileSession.userPatientProfileID=="${userPatientProfileID}"`,
    },
    create: {
      url: API_USER_PATIENT_PROFILE_SESSION_ITEMS.POST,
    },
    patch: {
      url: API_USER_PATIENT_PROFILE_SESSION_ITEMS.PATCH,
    },
    delete: {
      url: API_USER_PATIENT_PROFILE_SESSION_ITEMS.DELETE,
    },
    sortable: {
      field: 'userPatientProfileSession.entryDate',
      order: 'desc',
    },
  },
  toolbar: {
    title: '',
    controls: null,
    controlsDefault: {
      new: null,
      resetFilters: null,
      export: DefaultGridButtonExportToExcel
    },
  },
  columns: [
    {
      title: 'entry-date',
      field: 'userPatientProfileSession.entryDate as entryDate',
      sortable: 'userPatientProfileSession.entryDate',
      template: {
        type: 'date',
        format: APP_FORMAT_DATE,
      },
      width: '15%',
    },
    {
      title: 'notebook',
      field: 'userPatientProfileSession.notebook.labelKey as notebookTitle',
      sortable: 'userPatientProfileSession.notebook.labelKey',
      width: '25%',
    },
    {
      title: 'employee-name',
      field: 'userPatientProfileSession.userEmployeeProfile.fullName as employeeName',
      sortable: 'userPatientProfileSession.userEmployeeProfile.firstName',
      width: '25%',
    },
    {
      title: 'side-effects',
      field: 'entryValueString as sideEffect',
      sortable: 'entryValueString',
      width: '35%',
    },
  ],
});
