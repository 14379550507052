import React, { memo, useMemo } from 'react';
import { Dialog, DialogActions, DialogContent, LinearProgress } from '@material-ui/core';
import { IUseForm, useFormHook, Controls, validateRule } from 'AurionCR/components/formV2';
import { DialogBase } from 'AurionCR/components';
import { useDeleteDialog } from 'AurionCR/components/form';
import { DialogHeading } from 'components/dialog-title';
import { useRequestNotify } from 'components/hooks';
import { IGridEditFormProps, IGridFormProps } from 'components/models';

import { usePermissions } from 'hooks/use-permissions';
import { useTranslate } from 'hooks/use-translate';
import {
  API_USER_PATIENT_PROFILE_SESSIONS_CLINIC_DRUGS,
  UserPatientProfileSessionClinicDrug,
} from 'services/user-patient-profile-session-clinic-drugs';
import { useFieldProps } from 'hooks';
import { Controller } from 'react-hook-form';
import { AppInput } from 'components/app-input';
import { Left } from 'utils/types';
import { SelectDrugs } from 'components/select-drugs';

type FormModel = Left<UserPatientProfileSessionClinicDrug>;

export const Form: React.FC<IGridFormProps<FormModel>> = ({ isLoading, control, errors }) => {
  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });
  const { tp } = useTranslate();

  return (
    <>
      <Controller
        name={'drugID'}
        control={control}
        rules={validateRule('required')}
        render={(renderProps) => (
          <SelectDrugs {...getFieldProps(renderProps)} disabled={isLoading} disableClearable />
        )}
      />
      <Controller
        name={'dosageForm'}
        control={control}
        render={(renderProps) => (
          <AppInput {...getFieldProps(renderProps)} disabled={isLoading} label={tp('dosage')} />
        )}
      />
      <Controller
        control={control}
        name={'remarks'}
        render={(renderProps) => (
          <AppInput
            {...getFieldProps(renderProps)}
            disabled={isLoading}
            multiline
            minRows={4}
            maxRows={8}
          />
        )}
      />
    </>
  );
};

interface Props extends IGridEditFormProps {
  userPatientProfileSessionID: string;
}

export default memo<Props>(({ onClose, onFormHandle, itemID, userPatientProfileSessionID }) => {
  const { t } = useTranslate();

  const config = useMemo<IUseForm<FormModel>>(() => {
    return {
      editID: itemID,
      fields: {
        id: '',
        userPatientProfileSessionID: userPatientProfileSessionID,
        drugID: '',
        dosageForm: '',
        remarks: '',
      },
      endpoint: API_USER_PATIENT_PROFILE_SESSIONS_CLINIC_DRUGS.API_ENDPOINT,
      formHandle: onFormHandle,
    };
  }, [itemID, onFormHandle, userPatientProfileSessionID]);

  const {
    formLoading,
    setFormLoading,
    formTitle,
    formIsNew,
    formUse: { control, errors, handleSubmit, register },
    onSubmit,
    deleteItem,
  } = useFormHook(config);

  // request noty wrapper
  const { requestWrap: wrapSubmit } = useRequestNotify({
    request: onSubmit,
    setLoading: setFormLoading,
  });
  const { requestWrap: wrapDelete } = useRequestNotify({
    request: deleteItem,
    setLoading: setFormLoading,
  });
  // delete helper
  const { handleClose, handleConfirm, handleOpen, isOpenDelete } = useDeleteDialog({
    deleteMiddleware: wrapDelete,
  });
  const isAllowToDelete = usePermissions('isAllowToDeleteAnything');

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="xs">
      <DialogHeading title={' '} onClose={onClose} />
      {formLoading && <LinearProgress />}
      <DialogContent style={{ position: 'relative' }}>
        <input {...register('userPatientProfileSessionID')} type="hidden" />
        <Form isLoading={formLoading} errors={errors} control={control} />
        {isOpenDelete && isAllowToDelete && (
          <DialogBase
            title={formTitle}
            onClose={handleClose}
            onConfirm={handleConfirm}
            isLoading={formLoading}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Controls
          size="medium"
          state={!formIsNew ? 'save' : 'create'}
          loading={formLoading}
          onSubmit={handleSubmit(wrapSubmit)}
          onDelete={handleOpen}
          onCancel={onClose}
          showDelete={!formIsNew && isAllowToDelete}
          CancelButtonProps={{
            children: t('cancel'),
          }}
        />
      </DialogActions>
    </Dialog>
  );
});
