import React from 'react';

import { Dialog, DialogContent } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { ClinicalMeetingActivities } from 'components/clinical-meeting-activities';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';

const defaultRefresh = () => {

}

interface Props {
  clinicalMeetingID: string;
  onClose: () => void;
  onRefresh?: () => void;
}

export const ClinicalMeetingActivitiesDialog: React.FC<Props> = ({ clinicalMeetingID, onClose, onRefresh = defaultRefresh }) => {
  const { t } = useTranslate();

  return (
    <Dialog
      open={true}
      disableEscapeKeyDown={true}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      classes={{
        paper: style.paper,
      }}
    >
      <DialogHeading title={t('activities')} onClose={onClose} />
      <DialogContent className={style.content}>
        <ClinicalMeetingActivities
          className={style.grid}
          clinicalMeetingID={clinicalMeetingID}
          onRefresh={onRefresh}
        />
      </DialogContent>
    </Dialog>
  );
};
