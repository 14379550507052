import React from 'react';
import clsx from 'clsx';

import style from './index.module.scss';
import { Box, BoxProps, Typography } from '@material-ui/core';
import { Stack } from '../../../stack';

interface Props {
  className?: string;
  title: string;
  control?: React.ReactNode;
  children: React.ReactNode;
  ContentProps?: BoxProps;
}

export const PreviewResultLayout: React.FC<Props> = ({
  className,
  title,
  control,
  children,
  ContentProps,
}) => {
  return (
    <div className={clsx(style.root, className)}>
      <Stack>
        <Typography variant={'h5'} color={'secondary'} style={{ fontWeight: 'bold', flexGrow: 1 }}>
          {title}
        </Typography>
        {control}
      </Stack>

      <Box mt={1} {...ContentProps}>
        {children}
      </Box>
    </div>
  );
};
