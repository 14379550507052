import { FixServerObject } from 'utils/types';
import SmsIcon from '@material-ui/icons/Sms';
import CallIcon from '@material-ui/icons/Call';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SupportAgent from 'components/svg-icons/support-agent';
import PaymentIcon from '@material-ui/icons/Payment';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import PostAddRoundedIcon from '@material-ui/icons/PostAddRounded';
import PersonIcon from '@material-ui/icons/Person';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import InfoIcon from '@material-ui/icons/Info';
import { SvgIconComponent } from '@material-ui/icons';

const API_ENDPOINT = 'ToDoTaskCategories';

export const API_TODO_TASK_CATEGORIES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<Components.Schemas.ToDoTaskCategory, 'id'>) =>
    `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<Components.Schemas.ToDoTaskCategory, 'id'>) =>
    `${API_ENDPOINT}/Delete/${data.id}`,
};

export enum ActionKey {
  General = 1,
  Prescription,
  Payment,
  SideEffect,
  ClinicalAppointment,
  SupportMeeting,
  CallToPatient,
  PatientIsReady,
  Urgent,
  NextAppointmentDetails,
}
export enum TaskTag {
  PatientAppAskPrescription = 1,
  PatientAppAddingDocument = 2,
  PatientAppAddDietaryItem = 3,
  PatientOnHold = 4,
  PatientInactive = 5,
  PrescriptionRequired = 6,
}

export const CONFIG_CATEGORY_ACTION = {
  [ActionKey.General]: { Icon: SmsIcon, color: '#27AE60', name: 'general' },
  [ActionKey.Prescription]: { Icon: AssignmentIcon, color: '#2798D4', name: 'prescription' },
  [ActionKey.Payment]: { Icon: PaymentIcon, color: '#2798D4', name: 'payment' },
  [ActionKey.SideEffect]: { Icon: WarningRoundedIcon, color: '#F49511', name: 'side-effect' },
  [ActionKey.ClinicalAppointment]: {
    Icon: PostAddRoundedIcon,
    color: '#2798D4',
    name: 'clinical-appointment',
  },
  [ActionKey.SupportMeeting]: { Icon: SupportAgent, color: '#F49511', name: 'support-meeting' },
  [ActionKey.CallToPatient]: { Icon: CallIcon, color: '#27AE60', name: 'call-to-patient' },
  [ActionKey.PatientIsReady]: { Icon: PersonIcon, color: '#C3A0E0', name: 'patient-is-ready' },
  [ActionKey.Urgent]: { Icon: ArrowUpwardIcon, color: '#eb5757', name: 'urgent' },
  [ActionKey.NextAppointmentDetails]: {
    Icon: InfoIcon,
    color: '#F49511',
    name: 'next-appointment-details',
  },
};

export interface ToDoTaskCategory
  extends Omit<
    FixServerObject<Components.Schemas.ToDoTaskCategory, 'id'>,
    'actionKey' | 'taskTag'
  > {
  actionKey: ActionKey;
  taskTag: null | TaskTag;
}

export interface ToDoTaskCategoryCreateInput extends ToDoTaskCategory {
  userEmployeeProfileIDs: [];
}
export interface ToDoTaskCategoryUpdateInput extends ToDoTaskCategory {
  userEmployeeProfileIDs: [];
}
export interface TodoTaskCategoryGridFilter
  extends Pick<FixServerObject<Components.Schemas.ToDoTaskCategory, 'id'>, 'id' | 'title'> {
  actionKey: ActionKey;
}

export type ITaskCategoryList = TodoTaskCategoryGridFilter & {
  Icon: SvgIconComponent;
  color: string;
};

export interface IToDoTaskCategoryInfo extends Pick<ToDoTaskCategory, 'id' | 'defaultMessage'> {
  defaultEmployeeProfileIDs: string[];
}

export const getTaskCategoryIconURL = (actionKey: ActionKey) => {
  return `${window.location.origin}/images/task-${actionKey}.png`;
};
