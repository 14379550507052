const API_ENDPOINT = 'InputTypeParameters';

export const API_INPUT_TYPE_PARAMETERS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<InputTypeParameter, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<InputTypeParameter, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface InputTypeParameter extends Components.Schemas.InputTypeParameter {}
