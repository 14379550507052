import React, { memo, useCallback, useState } from 'react';
import clsx from 'clsx';
import Footer from './footer';
import { Controller, useForm } from 'react-hook-form';
import { FormController, Input, validateRule } from 'AurionCR/components/formV2';
import { Loading } from 'AurionCR/components';
import { Grid } from '@material-ui/core';
import { tPaymentFormExtra } from './@type';
import { differenceInDays } from 'date-fns';
import { number } from 'card-validator';
import { CleaveCardCVV, CleaveCardDate, CleaveCardNumber, CleaveNumber } from './helpers';
import style from './index.module.scss';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'hooks/use-translate';
import { AppDatePicker } from 'components/app-date-picker';
import { useFieldProps } from 'hooks';
import { ServicePayment } from 'services/payment';
import { actionUserPatientProfileSubscriptionActivitiesCrateLogCreated } from 'store/user-patient-profile-subscription-activities';
import { ServiceUserPatientProfile } from 'services/user-patient-profile';
import { AppInputLtr } from '../app-input-ltr';

interface Props {
  initData: Partial<tPaymentFormExtra>;
  onCancel: (e?: any) => void;
  onNext: (link: string, state: number) => void;
  onBack: (e?: any) => void;
}
export const State3 = memo<Props>(({ initData, onCancel, onNext, onBack }) => {
  const { t, tp } = useTranslate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { errors, control, handleSubmit, watch } = useForm<Partial<tPaymentFormExtra>>({
    defaultValues: {
      payments: 1,
      ccHolderIDNumber: initData.clientVatID,
      ccHolderName: '',
      ccNumber: '',
      ccExpired: '',
      cvv: '',
      ccPrice: undefined,

      cashPrice: undefined,

      bankTransferDate: new Date(),
      bankTransferAccount: undefined,
      bankTransferPrice: undefined,

      sum: initData.sum,
    },
  });
  const getFieldProps = useFieldProps({ errors });

  const ccPrice = watch('ccPrice');
  const cashPrice = watch('cashPrice');
  const bankTransferPrice = watch('bankTransferPrice');
  // handlers
  const onSubmit = useCallback(
    async (formData: Partial<tPaymentFormExtra>) => {
      setIsLoading(true);
      // parse
      const [ccExpiredMonth, ccExpiredYear] = formData.ccExpired
        ? formData.ccExpired.split('/')
        : [0, 0];
      const data = {
        ...initData,
        ...formData,
        ccExpiredMonth: Number(ccExpiredMonth || 0),
        ccExpiredYear: Number(ccExpiredYear || 0),
      };
      delete data.sum;
      delete data.ccExpired;
      delete data.durationMonths;
      data.ccPrice = data.ccPrice || 0;
      data.cashPrice = data.cashPrice || 0;
      data.bankTransferAccount = data.bankTransferAccount || 0;
      data.bankTransferPrice = data.bankTransferPrice || 0;

      try {
        const res = await ServicePayment.pay(data as any);
        if (initData.durationMonths && initData.durationMonths > 0) {
          ServiceUserPatientProfile.patch({
            appIdentityUserID: String(initData.userPatientProfileID),
            isActive: true,
          });
        }
        dispatch(
          actionUserPatientProfileSubscriptionActivitiesCrateLogCreated({
            data: res.data,
            translate: t,
          }),
        );
        onNext(res.data.invoiceDocUrl || '', 4);
      } catch (e) {
        onNext('', 5);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onNext, setIsLoading, dispatch, t],
  );

  // render
  return (
    <div className={clsx(style.state, style.state1)}>
      <form className={style.contentForm} onSubmit={handleSubmit(onSubmit)}>
        <div className={style.subTitle}>{t('pay-by-credit-card')}</div>
        <Grid container spacing={3}>
          <Grid item sm={3}>
            <FormController<tPaymentFormExtra>
              name={'payments'}
              label={t('payments')}
              placeholder="0"
              as={Input}
              rules="required,number"
              errors={errors}
              control={control}
              InputLabelProps={{ shrink: true }}
              type="number"
              autoFocus={true}
              disabled={isLoading}
            />
          </Grid>
          <Grid item sm={3}>
            <FormController<tPaymentFormExtra>
              name={'ccHolderIDNumber'}
              label={t('id-card')}
              as={Input}
              errors={errors}
              control={control}
              InputLabelProps={{ shrink: true }}
              rules={{
                validate: (value?: any) => {
                  if (ccPrice) {
                    if (!value) {
                      return t('required');
                    } else if (value.length < 2) {
                      return t('length-min-error');
                    }
                  }
                  return true;
                },
              }}
              disabled={isLoading}
            />
          </Grid>
          <Grid item sm={6}>
            <FormController<tPaymentFormExtra>
              name={'ccHolderName'}
              label={t('name-in-card')}
              as={Input}
              errors={errors}
              control={control}
              InputLabelProps={{ shrink: true }}
              rules={{
                validate: (value?: any) => {
                  if (ccPrice) {
                    if (!value) {
                      return t('required');
                    } else if (value.length < 2) {
                      return t('length-min-error');
                    }
                  }
                  return true;
                },
              }}
              disabled={isLoading}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <FormController<tPaymentFormExtra>
              name={'ccNumber'}
              label={t('cc-number')}
              as={Input}
              errors={errors}
              control={control}
              InputProps={{
                inputComponent: CleaveCardNumber as any,
                style: {
                  direction: 'ltr',
                },
              }}
              InputLabelProps={{ shrink: true }}
              rules={{
                validate: (value?: any) => {
                  if (ccPrice) {
                    if (!value) {
                      return t('required');
                    } else if (!number(value).isValid) {
                      return t('invalid-card-number');
                    }
                  }
                  return true;
                },
              }}
              disabled={isLoading}
            />
          </Grid>
          <Grid item sm={2}>
            <Controller
              name={'ccExpired'}
              control={control}
              rules={{
                validate: (value?: any) => {
                  if (ccPrice) {
                    if (!value) {
                      return t('required');
                    } else if (value.length < 5) {
                      return t('invalid-date');
                    } else {
                      const val = value.split('/');
                      const date = new Date(2000 + Number(val[1]), Number(val[0]) - 1);
                      if (differenceInDays(date, new Date()) < 0) return t('expired');
                    }
                  }
                  return true;
                },
              }}
              render={(renderProps) => {
                const props = getFieldProps(renderProps);
                return (
                  <AppInputLtr
                    {...props}
                    InputProps={{
                      inputComponent: CleaveCardDate as any,
                    }}
                    InputLabelProps={{ shrink: true }}
                    disabled={isLoading}
                    label={t('month-year')}
                  />
                );
              }}
            />
          </Grid>
          <Grid item sm={2}>
            <FormController<tPaymentFormExtra>
              name={'cvv'}
              label={t('cvv')}
              as={Input}
              errors={errors}
              control={control}
              InputProps={{
                inputComponent: CleaveCardCVV as any,
              }}
              InputLabelProps={{ shrink: true }}
              rules={{
                validate: (value?: any) => {
                  if (ccPrice) {
                    if (!value) {
                      return t('required');
                    } else if (value.length < 2) {
                      return t('length-min-error');
                    }
                  }
                  return true;
                },
              }}
              disabled={isLoading}
            />
          </Grid>
          <Grid item sm={2}>
            <FormController<tPaymentFormExtra>
              name={'ccPrice'}
              label={t('payment')}
              placeholder="0"
              as={Input}
              errors={errors}
              control={control}
              InputProps={{
                inputComponent: CleaveNumber as any,
              }}
              InputLabelProps={{ shrink: true }}
              disabled={isLoading}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={3}>
            <div className={clsx(style.subTitle, style.paddingTop, style.borderRight)}>
              {t('pay-by-cash')}
            </div>
          </Grid>
          <Grid item sm={9}>
            <div className={clsx(style.subTitle, style.paddingTop, style.borderRight)}>
              {t('pay-by-bank-transfer')}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={3}>
            <FormController<tPaymentFormExtra>
              name={'cashPrice'}
              label={t('payment')}
              placeholder="0"
              as={Input}
              errors={errors}
              control={control}
              InputProps={{
                inputComponent: CleaveNumber as any,
              }}
              InputLabelProps={{ shrink: true }}
              disabled={isLoading}
              className={style.borderRightControl}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name={'bankTransferDate'}
              control={control}
              rules={validateRule('required,dateLike')}
              render={(renderProps) => (
                <AppDatePicker
                  {...getFieldProps(renderProps)}
                  label={t('date')}
                  InputLabelProps={{ shrink: true }}
                  disabled={isLoading}
                />
              )}
            />
          </Grid>
          <Grid item sm={4}>
            <FormController<tPaymentFormExtra>
              name={'bankTransferAccount'}
              label={t('bank-account')}
              as={Input}
              errors={errors}
              control={control}
              type="number"
              InputLabelProps={{ shrink: true }}
              rules={{
                validate: (value?: any) => {
                  if (bankTransferPrice) {
                    if (!value) return t('required');
                    if (isNaN(Number(value))) return t('invalid-number');
                  }
                  return true;
                },
              }}
              disabled={isLoading}
            />
          </Grid>
          <Grid item sm={2}>
            <FormController<tPaymentFormExtra>
              name={'bankTransferPrice'}
              label={t('payment')}
              placeholder="0"
              as={Input}
              errors={errors}
              control={control}
              InputProps={{
                inputComponent: CleaveNumber as any,
              }}
              InputLabelProps={{ shrink: true }}
              disabled={isLoading}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} className={style.total}>
          <Grid item sm={6}>
            <FormController<tPaymentFormExtra>
              name={'sum'}
              label={t('total-amount')}
              as={Input}
              InputProps={{
                inputComponent: CleaveNumber as any,
              }}
              rules={{
                validate: (value?: any) => {
                  const sum =
                    Number(ccPrice || 0) + Number(cashPrice || 0) + Number(bankTransferPrice || 0);

                  const expected = Number(value);

                  const min = 0;
                  const isValid = sum >= min && sum <= expected;

                  return isValid
                    ? undefined
                    : tp('all-payment-rule', {
                        min,
                        max: expected,
                      });
                },
              }}
              errors={errors}
              control={control}
              disabled={true}
            />
          </Grid>
        </Grid>
      </form>
      <Footer
        onCancel={onCancel}
        onNext={handleSubmit(onSubmit)}
        onBack={() => onBack(2)}
        disabled={isLoading}
      />
      <Loading active={isLoading} />
    </div>
  );
});

export default State3;
