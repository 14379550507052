const API_ENDPOINT = 'Manufacturers';

export const API_MANUFACTURES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<Manufacturer, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<Manufacturer, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface Manufacturer extends Components.Schemas.Manufacturer {}
