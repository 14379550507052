import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { IconButton, Typography } from '@material-ui/core';
import DataGrid from 'components/data-grid';
import { GridConfigClinical } from './grid-config-clinical';
import style from './index.module.scss';
import { enumToArray } from 'utils/other';
import { ButtonSwitcher } from 'components/button-switcher';
import { Stack } from 'components/stack';
import {
  CustomHandler,
  CustomHandlersClinic,
  getMeetingRowProps,
  PATIENT_MEETING_SWITCH,
} from '../../helpers';
import {
  DialogCancelMeetingContainer,
  DialogClinicalMeeting,
} from 'components/dialog-clinical-meeting';
import { useFormGrid } from 'AurionCR/components/formV2';
import { useAppSelector } from 'store';
import { selectCMTimes } from 'pages/ClinicalMeetings/store';
import { useTranslate } from 'hooks/use-translate';
import { AddNewButton, EmptySlot } from '../empty-slot';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useOpenValue } from 'hooks';
import { patientEmitter } from '../../../../emitter';

interface Props {
  userPatientProfileID: string;
  defaultPeriod?: PATIENT_MEETING_SWITCH;
}

export const PatientGridClinicalMeetings: React.FC<Props> = ({
  userPatientProfileID,
  defaultPeriod = PATIENT_MEETING_SWITCH.ALL,
}) => {
  const { t } = useTranslate();

  const initData = useMemo(() => {
    return { userPatientProfileID };
  }, [userPatientProfileID]);

  const options = useMemo(() => {
    return enumToArray(PATIENT_MEETING_SWITCH).map((item) => ({
      ...item,
      value: item.id,
      title: t(item.id),
    }));
  }, [t]);

  const grid = useFormGrid('meeting-clinical');
  const timesClinical = useAppSelector(selectCMTimes);
  const [switcherClinical, setSwitcherClinical] = useState<PATIENT_MEETING_SWITCH>(defaultPeriod);
  const clinicalConfig = useMemo(() => {
    return GridConfigClinical({
      userPatientProfileID,
      period: switcherClinical,
      onEdit: grid.setEditItemID,
    }) as any;
  }, [userPatientProfileID, switcherClinical, grid.setEditItemID]);

  const stateOpenCancel = useOpenValue<{ clinicalMeetingSubjectID: string; id: string }>();
  const gridHandle = useCallback(
    async (_action: CustomHandlersClinic) => {
      let action = _action;

      if (action.type === CustomHandler.CANCEL_MEETING) {
        return stateOpenCancel.onOpen({
          id: action.data.id,
          clinicalMeetingSubjectID: action.data.clinicalMeetingSubjectID,
        });
      }

      return grid.onGridHandle(action as any);
    },
    [grid, stateOpenCancel],
  );
  const onCreate = useCallback(() => {
    return grid.setEditItemID(true);
  }, [grid]);
  const onRefresh = useCallback(() => {
    return grid.grid.current?.onTasks([['getData']]);
  }, [grid]);

  useEffect(() => {
    const refresh = () => {
      onRefresh();
    };

    patientEmitter.on('refresh-meetings', refresh);
    return () => {
      patientEmitter.off('refresh-meetings', refresh);
    };
  }, [onRefresh]);

  return (
    <div className={style.root}>
      <DataGrid
        ref={grid.grid}
        onHandle={gridHandle}
        rowProps={getMeetingRowProps}
        renderToolbarControlsSlot={() => (
          <div className={style.gridHeader}>
            <Typography noWrap variant={'h4'} component={'h3'} className={style.title}>
              {t('clinical-meetings')}
            </Typography>
            <Stack spacing={1}>
              <AddNewButton onClick={onCreate} />
              <ButtonSwitcher
                value={switcherClinical}
                onChange={setSwitcherClinical}
                options={options}
              />
              <IconButton onClick={onRefresh}>
                <RefreshIcon />
              </IconButton>
            </Stack>
          </div>
        )}
        EmptySlot={<EmptySlot onCreate={onCreate} />}
        config={clinicalConfig}
      />
      {grid.editItemID && (
        <DialogClinicalMeeting
          onClose={grid.onClose}
          itemID={grid.editItemID}
          times={timesClinical}
          onComplete={onRefresh}
          initData={initData}
        />
      )}
      {stateOpenCancel.openValue && (
        <DialogCancelMeetingContainer
          clinicalMeetingSubjectID={stateOpenCancel.openValue.clinicalMeetingSubjectID}
          meetingID={stateOpenCancel.openValue.id}
          onClose={stateOpenCancel.onClose}
          onDone={onRefresh}
        />
      )}
    </div>
  );
};
