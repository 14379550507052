import React, { memo, useEffect } from 'react';
import style from './components/index.module.scss';
import { useAppDispatch, useAppSelector } from 'store';
import { Loading } from 'AurionCR/components';
import { pdfDocumentSubmitInit, pdfDocumentSubmitReset } from './store';
import { Pdf } from './components/pdf';
import { Signature } from './components/signature';
import { Footer } from 'components/pdf-document-submit/components/footer';
import { Checkbox } from 'components/pdf-document-submit/components/checkbox';
import { Typography } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';

export const PdfDocumentSubmit = memo(({ id }: { id: string }) => {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();
  // state
  const { initData, loading, pages } = useAppSelector((state) => state.pdfDocumentSubmit);
  // init
  useEffect(() => {
    dispatch(pdfDocumentSubmitInit({ id }));
    return () => {
      dispatch(pdfDocumentSubmitReset());
    };
  }, [dispatch, id]);
  // render
  return (
    <div className={style.wrapper}>
      <Typography variant="h1" color="secondary" className={style.title}>
        {initData?.title || t('loading')}
      </Typography>
      {initData && (
        <>
          <Pdf />
          <Signature />
          <Checkbox />
          <Footer />
        </>
      )}
      {(loading || !initData || pages.length === 0) && <Loading />}
    </div>
  );
});

export default PdfDocumentSubmit;
export * from './store';
