import { API_USER_PATIENT_PROFILE_SESSIONS } from 'services/user-patient-profile-session';
import { APP_FORMAT_DATE } from 'configs/const';
import {
  createFilterEquals,
  createFilterValueArrayEquals,
  mergeFilters,
} from 'utils/dynamic-helpers';
import { NOTEBOOK_INDEXES } from 'services/notebook';

export default (userPatientProfileID: string): any => ({
  id: 'grid_user-patient-profile-sessions',
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_SESSIONS.GET_ALL_DYNAMIC,
      filters: mergeFilters(
        createFilterValueArrayEquals('notebook.rowIndex', [
          NOTEBOOK_INDEXES.QUESTIONNAIRE_EN,
          NOTEBOOK_INDEXES.QUESTIONNAIRE_HE,
        ]),
        createFilterEquals('userPatientProfileID', userPatientProfileID),
      ).join('&&'),
    },
    sortable: {
      field: 'entryDate',
      order: 'desc',
    },
  },
  toolbar: {
    title: '',
    controls: null,
    controlsDefault: null,
  },
  controls: null,
  columns: [
    {
      title: 'date',
      field: 'entryDate',
      template: {
        type: 'date',
        format: APP_FORMAT_DATE,
      },
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'notebook',
      field: 'notebook.labelKey as notebookLabelKey',
      sortable: 'notebook.labelKey',
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'total-answers',
      field: 'userPatientProfileSessionItems.count() as totalItems',
      sortable: 'userPatientProfileSessionItems.count()',
      tdClasses: 'mobile-no-wrap',
    },
  ],
  pagination: false,
});
