import React, { memo, useMemo } from 'react';
import { Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import Controls from 'AurionCR/components/form/controls';
import { IUseForm, useFormHook, validateRule } from 'AurionCR/components/formV2';
import { IGridEditFormProps } from 'components/models';
import {
  API_USER_PATIENT_PROFILE_SUBSCRIPTION_ACTIVITIES,
  IAppUserPatientProfileSubscriptionActivity,
} from 'services/user-patient-profile-subscription-activities';
import { useCurrentUser, useRequestNotify } from 'components/hooks';
import { useDeleteDialog } from 'AurionCR/components/form';
import { DialogBase } from 'AurionCR/components';
import { usePermissions } from 'hooks/use-permissions';
import { useTranslate } from 'hooks/use-translate';
import { SelectEmployee } from 'components/select-employee';
import { Controller } from 'react-hook-form';
import { useFieldProps } from 'hooks';
import { AppDateTimePicker } from 'components/app-date-time-picker';
import { AppInput } from 'components/app-input';

interface Props extends IGridEditFormProps {
  userPatientProfileSubscriptionID: string;
}
type Model = Omit<IAppUserPatientProfileSubscriptionActivity, 'subscriptionActivityType'>;

export const Editor = memo<Props>(
  ({ onClose, onFormHandle, itemID, userPatientProfileSubscriptionID }) => {
    const { t } = useTranslate();
    const { appUserID } = useCurrentUser();

    const config = useMemo<IUseForm<Model>>(() => {
      return {
        editID: itemID,
        fields: {
          id: '',
          userPatientProfileSubscriptionID,
          userEmployeeProfileID: appUserID,
          entryDate: new Date().toISOString(),
          remarks: '',
          isManually: true,
        },
        endpoint: API_USER_PATIENT_PROFILE_SUBSCRIPTION_ACTIVITIES.API_ENDPOINT,
        formHandle: onFormHandle,
      };
    }, [itemID, onFormHandle, userPatientProfileSubscriptionID, appUserID]);

    const {
      formLoading,
      setFormLoading,
      formIsNew,
      formUse: { control, errors, handleSubmit, register, watch },
      onSubmit,
      deleteItem,
    } = useFormHook(config);

    const { requestWrap: wrapSubmit } = useRequestNotify({
      request: onSubmit,
      setLoading: setFormLoading,
    });
    const { requestWrap: wrapDelete } = useRequestNotify({
      request: deleteItem,
      setLoading: setFormLoading,
    });

    const { handleClose, handleConfirm, handleOpen, isOpenDelete } = useDeleteDialog({
      deleteMiddleware: wrapDelete,
    });
    const isAllowToDelete = usePermissions('isAllowToDeleteAnything');
    const isReadOnly = watch('isManually') === false;

    const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

    return (
      <Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
        <DialogHeading
          isLoading={formLoading}
          title={t('activity')}
          onClose={onClose}
          disabled={formLoading}
        />
        <DialogContent>
          <input {...register('id')} type="hidden" />
          <input {...register('userPatientProfileSubscriptionID')} type="hidden" />
          <input {...register('isActive')} type="hidden" />
          <input {...register('isManually')} type="hidden" />
          <Grid container spacing={4}>
            <Grid item md={5}>
              <Controller
                name={'entryDate'}
                control={control}
                rules={validateRule('required,dateLike,datePast')}
                render={(renderProps) => (
                  <AppDateTimePicker
                    {...getFieldProps(renderProps)}
                    disableFuture={true}
                    disabled={isReadOnly || formLoading}
                  />
                )}
              />
            </Grid>
            <Grid item md={12}>
              <Controller
                name="userEmployeeProfileID"
                rules={validateRule('required')}
                control={control}
                render={(renderProps) => {
                  const props = getFieldProps(renderProps);
                  return (
                    <SelectEmployee
                      {...props}
                      label={t('employee')}
                      disabled={isReadOnly || formLoading}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'remarks'}
                control={control}
                render={(renderProps) => (
                  <AppInput
                    {...getFieldProps(renderProps)}
                    multiline
                    minRows={5}
                    disabled={isReadOnly || formLoading}
                  />
                )}
              />
            </Grid>
          </Grid>
          {isOpenDelete && isAllowToDelete && (
            <DialogBase
              title={'activity'}
              onClose={handleClose}
              onConfirm={handleConfirm}
              isLoading={formLoading}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Controls
            size="medium"
            state={!formIsNew ? 'save' : 'create'}
            showSubmit={!isReadOnly}
            loading={formLoading}
            onSubmit={handleSubmit(wrapSubmit)}
            onDelete={handleOpen}
            onCancel={onClose}
            showDelete={!formIsNew && isAllowToDelete && !isReadOnly}
            showCopy={false}
          />
        </DialogActions>
      </Dialog>
    );
  },
);
