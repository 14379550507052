export interface GridModel {
  isForm29: boolean;
  isCopy: boolean;
  documentURL: string;
}

export enum CustomHandler {
  SEND_TO_PATIENT,
  SEND_TO_PHARMACY,
}
export type CustomHandlers =
  | { type: CustomHandler.SEND_TO_PATIENT; data: GridModel }
  | { type: CustomHandler.SEND_TO_PHARMACY; data: GridModel };

export interface BaseCreateComponentProps {
  userPatientProfileID: string;
  onClose: () => void;
  onDone: () => void;
}
