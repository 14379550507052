import React, {memo} from "react";
import {TimePickerProps, TimePicker as Picker} from "@material-ui/pickers";
import {useTranslate} from "hooks/use-translate";

interface Props extends Omit<TimePickerProps, 'onChange'> {
  error?: any | { message: string };
  format?: string;
  ampm?: boolean;
  onChange: (e: {target: { value: Date | null}}) => void;
}

export const TimePicker = memo<Props>((
  {
    error,
    format = 'hh:mma',
    ...props
  }
) => {
  const {t} = useTranslate();
  return <div className="date-field-wrapper">
    <Picker
      todayLabel={t('today')}
      okLabel={t('ok')}
      cancelLabel={t('cancel')}
      {...props}
      error={Boolean(error)}
      helperText={error ? error.message || '' : ''}
      format={format}
      onChange={(date) => {
        props.onChange({target: {value: date}});
      }}
      fullWidth
    />
  </div>
});

export default TimePicker;
