import { createRef, RefObject, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FieldGetValueResult, iFieldRefProps } from '../components';
import { useForm } from 'react-hook-form';
import { NotebookFieldInput } from 'services/notebook-field-input';
import { Notebook } from 'services/notebook';
import { UserPatientProfileSession } from 'services/user-patient-profile-session';
import { generateDynamicQuery } from 'utils/service';
import { useSource } from 'AurionCR/components';
import { API_SUPPORT_MEETING_TYPES, SupportMeetingType } from 'services/support-meeting-types';
import { NotebookFormGeneratorPropsProviderContext } from '../components/props-provider';

interface NotebookFieldInputDynamic extends NotebookFieldInput {
  formID: string | null | undefined;
  formValue: any;
}
export interface NotebookDynamicExtraFields
  extends Pick<
    UserPatientProfileSession,
    | 'icd10Remarks'
    | 'sideEffectRemarks'
    | 'surgeryRemarks'
    | 'sensitivityRemarks'
    | 'sensitivityDrugRemarks'
    | 'routineDrugRemarks'
    | 'pastDrugForObesityRemarks'
  > {
  icd10IDs: string[];
  surgeryIDs: string[];
  sensitivityIDs: string[];
  sensitivityDrugIDs: string[];
  regularDrugIDs: string[];
  pastDrugIDs: string[];
  sideEffectIDs: string[];
}
export interface NotebookDynamicModel extends NotebookDynamicExtraFields {
  fieldInputs: NotebookFieldInputDynamic[];
}

export interface NotebookDynamicSubmitModel extends NotebookDynamicExtraFields {
  fieldInputs: FieldGetValueResult[];
}

const makeDynamicDefaultValues = (notebookFieldInputs: NotebookFieldInputDynamic[]) => {
  return notebookFieldInputs.reduce((acc, item) => {
    let defaultValue: string | boolean | number = item.defaultValue || '';
    const params = item.fieldInput?.inputType?.inputTypeParameter;
    if (params?.isBool) {
      defaultValue = false;
    } else if (params?.isNumber) {
      defaultValue = '';
    }
    acc[item.fieldInputID as string] =
      item.formValue !== null ? item.formValue || defaultValue : defaultValue;
    return acc;
  }, {} as Record<string, string | null | undefined | boolean | string[]>);
};

interface UseNotebookFormOptions {
  initData: NotebookDynamicModel;
  onSubmit: (data: NotebookDynamicSubmitModel) => void;
}
export const useNotebookForm = (options: UseNotebookFormOptions) => {
  const { initData, onSubmit } = options;

  const { fieldInputs } = initData;
  const length = fieldInputs.length;

  const [refs, setRefs] = useState<RefObject<iFieldRefProps>[]>([]);

  useEffect(() => {
    setRefs(new Array(length).fill(null).map(() => createRef<iFieldRefProps>()));
  }, [length]);

  const defaultValues = useMemo(() => {
    return {
      ...makeDynamicDefaultValues(initData.fieldInputs),
      icd10Remarks: initData.icd10Remarks || '',
      sideEffectRemarks: initData.sideEffectRemarks || '',
      surgeryRemarks: initData.surgeryRemarks || '',
      sensitivityRemarks: initData.sensitivityRemarks || '',
      sensitivityDrugRemarks: initData.sensitivityDrugRemarks || '',
      routineDrugRemarks: initData.routineDrugRemarks || '',
      pastDrugForObesityRemarks: initData.pastDrugForObesityRemarks || '',
      icd10IDs: initData.icd10IDs,
      surgeryIDs: initData.surgeryIDs,
      sensitivityIDs: initData.sensitivityIDs,
      sensitivityDrugIDs: initData.sensitivityDrugIDs,
      regularDrugIDs: initData.regularDrugIDs,
      pastDrugIDs: initData.pastDrugIDs,
      sideEffectIDs: initData.sideEffectIDs,
    };
  }, [initData]);

  const methods = useForm({
    defaultValues,
  });
  const { reset } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const prepareFields = useCallback(
    (data: NotebookDynamicExtraFields) => {
      const fieldInputs = refs
        .filter((ref) => ref.current)
        .map((ref) => ref.current?.getValue()) as unknown as FieldGetValueResult[];
      return {
        fieldInputs,

        icd10IDs: data.icd10IDs,
        surgeryIDs: data.surgeryIDs,
        sensitivityIDs: data.sensitivityIDs,
        regularDrugIDs: data.regularDrugIDs,
        pastDrugIDs: data.pastDrugIDs,
        sideEffectIDs: data.sideEffectIDs,
        sensitivityDrugIDs: data.sensitivityDrugIDs,

        icd10Remarks: data.icd10Remarks,
        sideEffectRemarks: data.sideEffectRemarks,
        surgeryRemarks: data.surgeryRemarks,
        sensitivityRemarks: data.sensitivityRemarks,
        sensitivityDrugRemarks: data.sensitivityDrugRemarks,
        routineDrugRemarks: data.routineDrugRemarks,
        pastDrugForObesityRemarks: data.pastDrugForObesityRemarks,
      };
    },
    [refs],
  );

  const onSubmitForm = useCallback(
    (data: NotebookDynamicExtraFields) => {
      onSubmit(prepareFields(data));
    },
    [onSubmit, prepareFields],
  );

  return { methods, refs, onSubmitForm, prepareFields };
};

export type NotebookSettings = Pick<
  Notebook,
  | 'displayIcd10Section'
  | 'displaySurgerySection'
  | 'displaySensitivitySection'
  | 'displayRoutineDrugSection'
  | 'displaySensitivityDrugSection'
  | 'displayPastDrugForObesitySection'
  | 'displayClinicDrugSection'
  | 'displaySideEffectSection'
>;
export const useNotebookSections = (settings: NotebookSettings | undefined) => {
  const isShowEffect = useMemo(() => {
    return [
      settings?.displayIcd10Section,
      settings?.displaySurgerySection,
      settings?.displaySensitivitySection,
      settings?.displayRoutineDrugSection,
      settings?.displaySensitivityDrugSection,
      settings?.displayPastDrugForObesitySection,
      settings?.displaySideEffectSection,
    ].some(Boolean);
  }, [settings]);

  const isShowClinicalDrugs = useMemo(() => {
    return Boolean(settings?.displayClinicDrugSection);
  }, [settings]);

  return { isShowEffect, isShowClinicalDrugs };
};

export const useSourceMeetingTypes = () => {
  const query = useMemo(() => {
    return generateDynamicQuery({
      select: 'title,id,nextMeetingNumberOfDays,isTLC,isSideEffect,isActive',
      filter: 'isPrescriptionRenewal==false',
      orderBy: 'rank',
    });
  }, []);
  return useSource<SupportMeetingType>(
    `${API_SUPPORT_MEETING_TYPES.GET_ALL_DYNAMIC}?${query}`,
    'id',
    true,
  );
};

export const useNotebookFormGeneratorProps = () => {
  const context = useContext(NotebookFormGeneratorPropsProviderContext);

  if (!context) {
    throw new Error('NotebookFormGeneratorPropsProviderContext is required');
  }

  return context;
};
