import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchPatient } from 'services/user-patient-profile';
import { TypedStorage } from 'modules/typed-storage';
import { DateValue } from 'utils/dates';

interface SearchedPatient extends ISearchPatient {
  date: DateValue;
}

interface State {
  searchedPatients: SearchedPatient[];
}

const LIMIT_LOCALSTORAGE_SEARCHED_PATIENTS = 25;

const localStorageSearchedPatients = new TypedStorage<SearchedPatient[]>(
  'searchedPatients',
  '0.0.2',
);
const initialState = (): State => ({
  searchedPatients: localStorageSearchedPatients.getObject() || [],
});

const slice = createSlice({
  name: 'SEARCHED_PATIENTS',
  initialState,
  reducers: {
    actionSetSearchedPatient(state, action: PayloadAction<Required<SearchedPatient>>) {
      // remove the oldest one if exist
      let newList = state.searchedPatients.filter((patient) => {
        return patient.appIdentityUserID !== action.payload.appIdentityUserID;
      });

      // add new item to the star of list
      newList = [{ ...action.payload, date: new Date() }, ...newList];

      // update state
      state.searchedPatients = newList.slice(0, LIMIT_LOCALSTORAGE_SEARCHED_PATIENTS);

      localStorageSearchedPatients.setObject(state.searchedPatients);
    },
    actionRemoveSearchedPatient(state, action: PayloadAction<string>) {
      state.searchedPatients = state.searchedPatients.filter(
        (patient) => patient.appIdentityUserID !== action.payload,
      );

      localStorageSearchedPatients.setObject(state.searchedPatients);
    },
  },
});
export const { actionSetSearchedPatient, actionRemoveSearchedPatient } = slice.actions;
export const searchedPatientsReducer = slice.reducer;
