import { FixServerObject } from 'utils/types';

const API = 'Accounts';

export const API_ACCOUNTS = {
  GET_CURRENT_APP_USER: `${API}/GetCurrentAppUser`,
  LOGIN_WITH_CODE: `${API}/LoginWithCode`,
  GENERATE_PASSWORD: `${API}/GeneratePassword`,
  LOGOUT: `${API}/Logout`,
};

export interface AppUser
  extends FixServerObject<
    Components.Schemas.CurrentUserProfileResponse,
    'appUserID' | 'name' | 'email'
  > {}
