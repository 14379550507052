import { DateValue, isDateLike, isPastDate } from 'utils/dates';
import { FixServerObject } from 'utils/types';
import { dateToDayOfWeek, DAY_OF_WEEK } from '../user-employee-profile-work-logs';
import * as yup from 'yup';
import { timeSlotToNumber } from 'utils/app-helpers';
import { transformToDateISO } from 'utils/transformers';
import { UserEmployeeProfile } from '../user-employee-profiles';
import { SupportMeetingType } from '../support-meeting-types';
import { InferType } from 'yup';

const API_ENDPOINT = 'SupportMeetings';

export const API_SUPPORT_MEETINGS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<SupportMeeting, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<SupportMeeting, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface SupportMeeting
  extends FixServerObject<
    Components.Schemas.SupportMeeting,
    | 'id'
    | 'supportMeetingTypeID'
    | 'userEmployeeProfileID'
    | 'userPatientProfileID'
    | 'meetingToDateTime'
    | 'meetingFromDateTime'
  > {}
export const RENEWAL_SUPPORT_MEETING_TYPE_ID = '976ac6e2-e68d-47f0-a288-2c921168ae31';

export class SupportMeetingRenewal
  implements
    Pick<
      Required<SupportMeeting>,
      | 'isActive'
      | 'supportMeetingTypeID'
      | 'userPatientProfileID'
      | 'userEmployeeProfileID'
      | 'includeMeetingTime'
      | 'remarks'
    >
{
  isActive = true;
  supportMeetingTypeID = RENEWAL_SUPPORT_MEETING_TYPE_ID;
  userPatientProfileID!: string;
  userEmployeeProfileID!: string;
  includeMeetingTime = false;
  remarks = '';
  entryDate = new Date().toISOString();
}

export interface SupportMeetingInput extends Partial<SupportMeeting> {
  date: DateValue;
  start: string;
  end: string;
  _IS_RENEWAL: boolean;
}

export interface SupportMeetingGetActivitiesMeetingInput {
  userPatientProfileID: string;
  dateRange: DateValue[];
}

export interface SupportMeetingGetActivitiesMeetingItem
  extends Pick<
    SupportMeeting,
    | 'id'
    | 'meetingFromDateTime'
    | 'meetingToDateTime'
    | 'includeMeetingTime'
    | 'userPatientProfileSessionID'
  > {
  employee: Required<SupportMeeting>['userEmployeeProfile']['fullName'];
  supportMeetingTypeTitle: Required<SupportMeeting>['supportMeetingType']['title'];
  activities: number;
}

export interface PatientFutureSupportMeeting
  extends Pick<
    SupportMeeting,
    | 'id'
    | 'meetingFromDateTime'
    | 'meetingToDateTime'
    | 'supportMeetingTypeID'
    | 'userEmployeeProfileID'
    | 'includeMeetingTime'
    | 'remarks'
  > {
  employee: Required<SupportMeeting>['userEmployeeProfile']['fullName'];
}

export interface NextFutureMeetingFromExistingInput
  extends Pick<
    Required<SupportMeeting>,
    | 'id'
    | 'userEmployeeProfileID'
    | 'meetingFromDateTime'
    | 'meetingToDateTime'
    | 'userPatientProfileSessionID'
  > {}
export interface NextFutureMeetingFromExistingOutput
  extends Pick<
    Required<SupportMeeting>,
    | 'id'
    | 'userEmployeeProfileID'
    | 'meetingFromDateTime'
    | 'meetingToDateTime'
    | 'userPatientProfileSessionID'
  > {}

export interface PostOrPatchSupportMeetingInput extends Omit<SupportMeeting, 'id'> {
  id: string | undefined;
}

export interface SupportMeetingExcel
  extends Pick<
    Components.Schemas.SupportMeeting,
    'id' | 'meetingFromDateTime' | 'meetingToDateTime'
  > {
  supportMeetingType: Pick<Components.Schemas.SupportMeetingType, 'title'>;
  userPatientProfile: Pick<Components.Schemas.UserPatientProfile, 'fullName'>;
  userEmployeeProfile: Pick<Components.Schemas.UserEmployeeProfile, 'fullName'>;
  activities: number;
  lastActivityType: string | null;
  remarks: string | null;
  subscription: { endDate: string } | null;
}

export const isAvailableSupportMeetingDate = (date: DateValue) => {
  if (!date) return true;

  let value = dateToDayOfWeek(date);

  return DAY_OF_WEEK[value] !== undefined;
};

export const isShouldDisableSupportMeetingDate = (date: DateValue) => {
  return [isPastDate(date), !isAvailableSupportMeetingDate(date)].some(Boolean);
};

export const schemaSupportMeetingNew = yup.object({
  supportMeetingTypeID: yup.string().nullable().required('rule-required').default(''),
  userPatientProfileID: yup.string().nullable().required('rule-required').default(''),
  userEmployeeProfileID: yup.string().nullable().required('rule-required').default(''),
  date: yup
    .string()
    .nullable()
    .required('rule-required')
    .test('date', 'rule-date', isDateLike)
    .test('date-available', 'rule-date-available', isAvailableSupportMeetingDate)
    .test('date-past', 'rule-meeting-past', (value) => !isPastDate(value))
    .transform(transformToDateISO)
    .default(new Date().toISOString()),
  start: yup
    .string()
    .nullable()
    .required('rule-required')
    .test('start-date', 'start-time-should-be-less-than-end-time', (value, context) => {
      if (!value) return true;
      const endDate = context.parent.end;
      if (!endDate) return true;
      return timeSlotToNumber(value) < timeSlotToNumber(endDate);
    })
    .default(''),
  end: yup.string().nullable().required('rule-required').default(''),
  remarks: yup.string().nullable().notRequired().default(''),
});

export type SupportMeetingForm = InferType<typeof schemaSupportMeetingNew>;

export const schemaSupportMeetingNext = schemaSupportMeetingNew
  .pick([
    'supportMeetingTypeID',
    'userPatientProfileID',
    'userEmployeeProfileID',
    'date',
    'remarks',
  ])
  .shape({
    includeMeetingTime: yup.boolean().nullable().required('rule-required'),
    start: yup
      .string()
      .when('includeMeetingTime', {
        is: true,
        then: (schema) =>
          schema
            .nullable()
            .required('rule-required')
            .test('start-date', 'start-time-should-be-less-than-end-time', (value, context) => {
              if (!value) return true;
              const endDate = context.parent.end;
              if (!endDate) return true;
              return timeSlotToNumber(value) < timeSlotToNumber(endDate);
            }),
        otherwise: (schema) => schema.nullable().notRequired(),
      })
      .default(''),
    end: yup.string().when('includeMeetingTime', {
      is: true,
      then: (schema) => schema.nullable().required('rule-required'),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
  });

export const makeSchemaSupportMeetingEdit = ({
  isPrescriptionRenewal,
}: {
  isPrescriptionRenewal: boolean;
}) => {
  if (isPrescriptionRenewal) {
    return schemaSupportMeetingNew.omit(['start', 'end']).shape({
      start: yup.string().nullable().notRequired(),
      end: yup.string().nullable().notRequired(),
    });
  }
  return schemaSupportMeetingNew;
};

export type GetSupportMeetingOutput = SupportMeeting & { labelKey: string | null };
export interface CancelSupportMeetingInput {
  id: string;
  remarks: string;
  statusID: string;
  supportMeetingTypeID: string;
  userPatientProfileID: string;
}
export interface SupportMeetingFuture
  extends Pick<SupportMeeting, 'id' | 'meetingFromDateTime' | 'meetingToDateTime'> {
  employee: Pick<UserEmployeeProfile, 'fullName'>;
  type: Pick<SupportMeetingType, 'color' | 'icon' | 'title'>;
}
