import { createSvgIcon } from '@material-ui/core';
import React from 'react';

export default createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.74101 11.1215C8.08894 13.159 9.86312 14.71 11.9998 14.71C14.1365 14.71 15.9107 13.159 16.2587 11.1215H16.3515C17.051 11.1215 17.6181 10.5544 17.6181 9.85489C17.6181 9.15532 17.051 8.58825 16.3515 8.58825H16.3208V8.11911C14.6149 7.71638 13.5673 6.81157 13.0289 6.20239C12.2498 6.89856 10.5569 8.08286 7.67894 8.57384L7.53171 8.5938C6.88683 8.65267 6.38159 9.19466 6.38159 9.85489C6.38159 10.5544 6.9487 11.1215 7.64823 11.1215H7.74101Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M7.67896 7.38414C11.1991 6.72875 12.6111 4.96699 12.6254 4.94875L13.1635 4.25973L13.5963 5.01934C13.6284 5.07422 14.4015 6.35945 16.3208 6.90844V6.3209C16.3208 3.93453 14.3863 2 11.9999 2C9.61353 2 7.67896 3.93453 7.67896 6.32094V7.38414Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M17.7097 18.4844H17.5271C16.9943 18.4844 16.5608 18.9179 16.5608 19.4507V20.8281H18.6761V19.4507C18.6761 18.9179 18.2425 18.4844 17.7097 18.4844Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M6.3816 20.8281C6.81307 20.8281 7.16285 20.4783 7.16285 20.0469C7.16285 19.6154 6.81307 19.2656 6.3816 19.2656C5.95012 19.2656 5.60034 19.6154 5.60034 20.0469C5.60034 20.4783 5.95012 20.8281 6.3816 20.8281Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M13.406 15.6985C12.9503 15.8192 12.4779 15.8819 12 15.8819C11.5222 15.8819 11.0497 15.8192 10.594 15.6985L12 17.6704L13.406 15.6985Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M4.42863 20.047C4.42863 19.1741 5.00426 18.4332 5.79582 18.1838V14.7122L4.1968 15.4624C2.8623 16.0884 2 17.4454 2 18.9195V22.0001H6.38176C5.3048 22.0001 4.42863 21.1239 4.42863 20.047Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M19.8034 15.4621L18.2043 14.7119V17.3707C19.1456 17.5946 19.8479 18.4418 19.8479 19.4506V21.9999H22.0002V18.9193C22.0002 17.4452 21.1379 16.0882 19.8034 15.4621Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M17.0321 17.3708V14.1622L16.2919 13.8149C16.1439 14.0005 11.9998 19.6888 11.9998 19.6888C11.9998 19.6888 7.85585 14.0005 7.70772 13.8149L6.96753 14.1622V18.1836C7.75909 18.433 8.33472 19.1739 8.33472 20.0468C8.33472 21.1237 7.45854 21.9999 6.38159 21.9999H15.3886V19.4506C15.3886 18.4419 16.0909 17.5946 17.0321 17.3708Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>,
  'DoctorIcon',
);
