import React, { useCallback } from 'react';
import { Button, ButtonGroup, ButtonGroupProps, ButtonProps } from '@material-ui/core';
import clsx from 'clsx';
import style from './index.module.scss';

export interface Option<T> {
  title: string;
  value: T;
  Icon?: React.ReactNode;
  ButtonProps?: Partial<ButtonProps>;
}

export interface ButtonSwitcherProps<T> extends Omit<ButtonGroupProps, 'value' | 'onChange'> {
  options: Option<T>[];
  value: T;
  onChange: (value: this['value']) => void;
  ButtonItemProps?: Partial<ButtonProps>;
}

export const ButtonSwitcher = <T,>({
  options,
  value,
  onChange,
  ButtonItemProps,
  ...rest
}: ButtonSwitcherProps<T>) => {
  const factoryClick = useCallback(
    (value: T) => {
      return () => onChange(value);
    },
    //eslint-disable-next-line
    [onChange],
  );

  return (
    <ButtonGroup variant={'outlined'} color={'secondary'} {...rest}>
      {options.map((item, i) => {
        return (
          <Button
            key={i}
            startIcon={item.Icon}
            {...ButtonItemProps}
            {...item?.ButtonProps}
            variant={value === item.value ? 'contained' : 'outlined'}
            onClick={factoryClick(item.value)}
            className={clsx(value === item.value && style.buttonActive, style.button)}
          >
            {item.title}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};
