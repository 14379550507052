import { useSource } from 'AurionCR/components';
import {
  API_USER_PATIENT_PROFILE_SUBSCRIPTIONS,
  UserPatientProfileSubscription,
} from 'services/user-patient-profile-subscriptions';
import { useCallback, useContext, useMemo } from 'react';
import { generateDynamicQuery } from 'utils/service';
import { Subscription } from 'services/subscription';
import { API_USER_PATIENT_PROFILE_SESSION_ITEMS } from 'services/user-patient-profile-session-item';
import { differenceInDays, isAfter } from 'date-fns';
import { UserPatientProfileEditGeneralInput } from 'services/user-patient-profile';

import { useTranslate } from 'hooks/use-translate';

import { convertToDate, DateValue, isDateLike } from 'utils/dates';
import { ProviderPatientDataContext, UpdateSignature } from '../components';
import { useFactoryDebounce } from 'hooks/use-factory-debounce';
import { mergeFilters } from 'utils/dynamic-helpers';

interface PatientSubscription
  extends Pick<UserPatientProfileSubscription, 'id' | 'startDate' | 'endDate'> {
  subscription: Pick<Subscription, 'labelKey' | 'durationMonths' | 'visitDietitian'>;
}

const DIFF_IN_DAYS_TO_END = 10;

export const usePatientSubscriptionStatus = (subscription: null | { endDate?: DateValue }) => {
  const { tp } = useTranslate();
  const endDate = subscription?.endDate;

  const isActive = useMemo(() => {
    if (!isDateLike(endDate)) {
      return false;
    }
    return convertToDate(endDate) > new Date();
  }, [endDate]);

  const isEnd = useMemo(() => {
    if (!endDate) return false;
    return isAfter(new Date(), convertToDate(endDate));
  }, [endDate]);

  const endsIn = useMemo(() => {
    if (!endDate) return Infinity;
    return differenceInDays(convertToDate(endDate), new Date());
  }, [endDate]);
  const isCloseToEnd = useMemo(() => {
    if (!endDate) return false;
    if (isEnd) return false;
    return endsIn <= DIFF_IN_DAYS_TO_END;
  }, [endDate, isEnd, endsIn]);

  const notifyMessage = useMemo(() => {
    if (isEnd) {
      return tp('program-is-ended');
    }
    if (isCloseToEnd) {
      return tp('program-is-close-to-end', { days: endsIn });
    }

    return '';
  }, [tp, isEnd, isCloseToEnd, endsIn]);

  return { isEnd, endsIn, isCloseToEnd, notifyMessage, isActive };
};
export const usePatientSubscription = (userPatientProfileID: string) => {
  const params = useMemo(() => {
    return generateDynamicQuery({
      filter: mergeFilters(
        `userPatientProfileID=="${userPatientProfileID}"`,
        'subscription.durationMonths>0',
      ).join('&&'),
      select: [
        'id',
        'startDate',
        'endDate',
        'new { subscription.labelKey, subscription.durationMonths, subscription.visitDietitian } as subscription',
      ].join(','),
      orderBy: 'endDate desc',
      take: 1,
    });
  }, [userPatientProfileID]);
  const { data: records, loading } = useSource<PatientSubscription>(
    `${API_USER_PATIENT_PROFILE_SUBSCRIPTIONS.GET_ALL_DYNAMIC}?${params}`,
  );

  const data = useMemo<PatientSubscription | null>(() => {
    return records[0] || null;
  }, [records]);

  const isInactiveSubscription =
    !loading && (!data?.endDate || isAfter(new Date(), convertToDate(data.endDate)));

  return { isLoading: loading, data, isInactiveSubscription };
};

export interface PatientWeight {
  id: string;
  entryDate: string;
  transactionType: string | null;
  activityType: string | null;
  weight: number;
  externalSystemValue: string;
  inputTypeID: string;
}
export const usePatientWeights = (userPatientProfileID: string) => {
  const params = useMemo(() => {
    return generateDynamicQuery({
      filter: `fieldInput.inputType.isWeight==true && userPatientProfileSession.userPatientProfileID=="${userPatientProfileID}" && entryValueNumber!=null`,
      select:
        'id,userPatientProfileSession.entryDate as entryDate,userPatientProfileSession.notebook.labelKey as transactionType,fieldInput.inputType.title as activityType,entryValueNumber as weight, externalSystemValue,fieldInput.inputTypeID',
      orderBy: 'userPatientProfileSession.entryDate desc',
    });
  }, [userPatientProfileID]);
  const {
    data: records,
    loading,
    refresh,
  } = useSource<PatientWeight>(
    `${API_USER_PATIENT_PROFILE_SESSION_ITEMS.GET_ALL_DYNAMIC}?${params}`,
  );
  const data = useMemo(() => {
    return records.filter(
      (item) =>
        item.entryDate &&
        item.transactionType &&
        item.activityType &&
        item.weight !== null &&
        item.weight !== undefined,
    );
  }, [records]);

  const startWeightEntity = useMemo<PatientWeight | null>(() => {
    return data[data.length - 1] || null;
  }, [data]);

  return { isLoading: loading, data, refresh, startWeightEntity };
};

const emptyUpdate = () => {};
export const usePatientData = () => {
  const context = useContext(ProviderPatientDataContext);

  if (!context) {
    throw new Error('ProviderPatientDataContext: is required');
  }

  const onUpdate = context.onUpdate || emptyUpdate;

  const { register } = useFactoryDebounce(onUpdate, 1000);

  const onUpdateDebounce = useCallback<typeof onUpdate>(
    (entity, description) => {
      const func = register(Object.keys(entity).join('__'));
      return func(entity, description);
    },
    [register],
  );

  return useMemo(
    () => ({
      ...context,
      onUpdateDebounce: context.onUpdate ? onUpdateDebounce : undefined,
    }),
    [context, onUpdateDebounce],
  );
};
const getSelectValue = (value: any, map: Record<string, { title?: string | null } | undefined>) => {
  if (!value) {
    return 'null';
  }

  const item = map[value];

  if (!item) {
    return 'null';
  }

  return item.title || 'empty';
};
const getInputValue = (value: any) => {
  if (value === null || value === undefined || value === '') {
    return 'null';
  }
  return String(value);
};

interface UseFactoryChangeProps {
  initData: undefined | UserPatientProfileEditGeneralInput;
  onUpdate: undefined | UpdateSignature;
  getValues: (key: string) => any;
  validate: (key: any) => Promise<boolean>;
}
export const useFactoryChangeInput = (options: UseFactoryChangeProps) => {
  const { onUpdate, getValues, initData, validate } = options;
  return useCallback(
    (field: string, name: string) => {
      return async () => {
        if (!onUpdate) return;

        let key = field as keyof UserPatientProfileEditGeneralInput;
        const value = getValues(key);

        const isValid = await validate(key);

        if (!isValid) return;

        const oldValue = initData ? initData[key] : null;

        const description = {
          oldValue: getInputValue(oldValue),
          newValue: getInputValue(value),
          name,
        };

        onUpdate({ [field]: value }, description);
      };
    },
    [initData, onUpdate, getValues, validate],
  );
};
export const useFactoryChangeSelect = (options: UseFactoryChangeProps) => {
  const { onUpdate, getValues, initData, validate } = options;
  return useCallback(
    (field: string, name: string, map: Record<string, { title?: string | null } | undefined>) => {
      return async () => {
        if (!onUpdate) return;

        let key = field as keyof UserPatientProfileEditGeneralInput;
        const value = getValues(key);
        const isValid = await validate(key);

        if (!isValid) return;

        const oldID = initData ? initData[key] : null;

        const oldValue = getSelectValue(oldID, map);
        const newValue = getSelectValue(value, map);

        const description = {
          oldValue,
          newValue,
          name,
        };

        onUpdate({ [field]: value }, description);
      };
    },
    [onUpdate, initData, getValues, validate],
  );
};
