import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import React, { memo, useCallback } from 'react';
import { useTranslate } from 'hooks/use-translate';
import { APP_FORMAT_DATE } from 'configs/const';
import { convertToDate, isDateLike } from 'utils/dates';

interface Props extends Omit<KeyboardDatePickerProps, 'onChange'> {
  onChange: (date: string | null) => void;
}
export const AppDatePicker = memo<Props>(({ onChange, ...rest }) => {
  const { t } = useTranslate();
  const onChangeWrap = useCallback(
    (date: Date | null) => {
      if (isDateLike(date)) {
        return onChange(convertToDate(date).toISOString());
      }
      onChange(date);
    },
    [onChange],
  );

  return (
    <KeyboardDatePicker
      todayLabel={t('today')}
      okLabel={t('ok')}
      cancelLabel={t('cancel')}
      format={APP_FORMAT_DATE}
      fullWidth
      {...rest}
      onChange={onChangeWrap}
      value={rest.value || null}
    />
  );
});
