import React from 'react';
import { TemplateIcon } from 'components/templates/template-icon';
import { useSourceClinicalMeetingSubjects } from 'components/hooks';
import { CircularProgress } from '@material-ui/core';

interface Props {
  meetingSubjectsID: string;
}

export const TemplateClinicalMeetingSubject: React.FC<Props> = ({ meetingSubjectsID }) => {
  const { map } = useSourceClinicalMeetingSubjects();
  const meeting = map[meetingSubjectsID];

  if (!meeting) {
    return <CircularProgress />;
  }

  return (
    <TemplateIcon
      color={meeting?.color}
      iconSrc={meeting?.icon}
      sx={{ minHeight: '4rem', minWidth: '4rem' }}
      AvatarProps={{
        title: meeting?.title,
      }}
    />
  );
};
