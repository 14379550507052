import React, {useCallback, useMemo} from "react";
import {Controller, UseFormMethods} from "react-hook-form";
import {validateRule} from "../validation";
import { composeFunctions } from "utils";

interface FormControllerProps<T> {
  name: keyof T;
  control: UseFormMethods['control'];
  as: any;
  rules?: any;
  errors?: any;
  onChange?: (e: any, value: any) => any;

  [x: string]: any;
}

export const formControllerChangeParser = (e: any, value?: any) => {
  let value_ = '';
  if (
    e.target &&
    e.target.value !== undefined &&
    e.target.value !== null &&
    e.target.type !== 'checkbox'
  ) {
    value_ = e.target.value;
  } else if (
    value !== undefined
    && value !== null
  ) {
    value_ = value;
  }
  return value_;
}

function FormControllerComponent<FormModel = {}, AsProps = {}>(
  {
    name,
    control,
    as: Element,
    rules,
    errors,
    onChange: onChangeUp,
    ...rest
  }: FormControllerProps<FormModel> & AsProps) {
  const rules_ = useMemo(() => rules ? validateRule(rules) || rules : undefined, [rules]);
  // handlers
  const onChangeProxy = useCallback((e, value) => {
    if (onChangeUp) {
      return onChangeUp(e, value);
    } else {
      return formControllerChangeParser(e, value)
    }
  }, [onChangeUp]);

  // render
  return <Controller
    name={String(name)}
    control={control}
    rules={rules_}
    render={({
               onChange,
               onBlur,
               value,
               name
             }) =>
      <Element
        {...rest}
        onBlur={composeFunctions(onBlur, rest.onBlur)}
        value={value}
        name={name}
        error={errors ? errors[name] : null}
        onChange={(e: any, value: any) => onChange(onChangeProxy(e, value))}
      />
    }
  />
}

export const FormController = React.memo(FormControllerComponent) as typeof FormControllerComponent
