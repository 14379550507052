import { DynamicService } from 'utils/service';

import {
  API_TODO_TASK_CATEGORIES,
  IToDoTaskCategoryInfo,
  TaskTag,
  ToDoTaskCategory,
  ToDoTaskCategoryCreateInput,
  ToDoTaskCategoryUpdateInput,
} from './models';

import { ServiceUserEmployeeTodoTaskCategories } from 'services/user-employee-profile-todo-task-categories';
import { apiRtk } from 'utils/rtk-query';
import * as dynamic from 'utils/dynamic-helpers';

export * from './models';

class Service extends DynamicService<ToDoTaskCategory> {
  create = async (data: ToDoTaskCategoryCreateInput) => {
    const { userEmployeeProfileIDs, ...rest } = data;

    const result = await this.post(rest);

    if (userEmployeeProfileIDs && userEmployeeProfileIDs.length) {
      await ServiceUserEmployeeTodoTaskCategories.createBulk({
        toDoTaskCategoryID: result.data.id,
        userEmployeeProfileIDs,
      });
    }

    return result;
  };
  update = async (data: ToDoTaskCategoryUpdateInput) => {
    const { userEmployeeProfileIDs, ...rest } = data;

    const result = await this.patch(rest);

    if ('userEmployeeProfileIDs' in data) {
      await ServiceUserEmployeeTodoTaskCategories.createBulk({
        toDoTaskCategoryID: rest.id,
        userEmployeeProfileIDs: userEmployeeProfileIDs || [],
      });
    }

    return result;
  };
}

export const ServiceTodoTaskCategories = new Service({
  mainField: 'id',
  getAll: API_TODO_TASK_CATEGORIES.GET_ALL_DYNAMIC,
  post: API_TODO_TASK_CATEGORIES.POST,
  delete: API_TODO_TASK_CATEGORIES.DELETE,
  patch: API_TODO_TASK_CATEGORIES.PATCH,
});

export const apiTodoTaskCategories = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getTodoTaskCategoryInfoByTag: builder.query<IToDoTaskCategoryInfo, TaskTag>({
      queryFn: async (tag, { dispatch }) => {
        try {
          const response = await ServiceTodoTaskCategories.getAllDynamic<IToDoTaskCategoryInfo>({
            select: dynamic.select(
              'id',
              'defaultMessage',
              'userEmployeeProfileToDoTaskCategories.Where(k => k.userEmployeeProfile.isActive).Select(k => k.userEmployeeProfileID) as defaultEmployeeProfileIDs',
            ),
            filter: dynamic.mergeFilters(dynamic.createFilterEquals('taskTag', tag)).join('&&'),
            take: 1,
          });

          const { data } = DynamicService.transformResponseToItem(response);

          return { data: data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});
