import React, { memo, useCallback, useRef } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { DialogHeading } from '../dialog-title';
import { useTranslate } from 'hooks/use-translate';
import { HtmlIframeView } from '../html-iframe-view';
import { ButtonCancel } from '../app-buttons';

interface Props {
  isLoading?: boolean;
  title: string;
  content: string;
  onGenerate: (content: string) => void;
  onCancel: () => void;
}
export const DialogEditHtmlContent = memo<Props>(
  ({ isLoading, title, content, onGenerate, onCancel }) => {
    const { tp } = useTranslate();
    const ref = useRef<HTMLIFrameElement>(null);

    const onSubmit = useCallback(() => {
      if (!ref.current) return;
      const window = ref.current.contentWindow;

      if (!window) return;

      const html = window.document.documentElement.outerHTML;

      onGenerate(html);
    }, [onGenerate]);

    return (
      <Dialog open={true} fullWidth={true} maxWidth={'lg'} onClose={onCancel}>
        <DialogHeading title={title} onClose={onCancel} isLoading={isLoading} />
        <DialogContent>
          <HtmlIframeView ref={ref} title={title} srcDoc={content} editable={!isLoading} />
        </DialogContent>
        <DialogActions>
          <ButtonCancel isBack={false} onClick={onCancel} />
          <Button disabled={isLoading} color={'primary'} variant={'contained'} onClick={onSubmit}>
            {tp('generate')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);
