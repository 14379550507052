import React, { useMemo } from 'react';
import { AllActivitiesGrid } from '../all-activities-grid';
import { TabItem, TabsWrapper } from 'components/tabs-wrapper';
import { PATIENT_CARD_ALL_ACTIVITIES_TABS } from 'configs/routes';
import { GridPatientActivities, GridTasks } from 'components/dialog-patient/grids';
import { useTranslate } from 'hooks/use-translate';
import { useValueQuery } from 'hooks/use-value-query';
import style from './index.module.scss';
import { Badge, Box } from '@material-ui/core';
import { NotebooksFromRapid } from '../notebooks-from-rapid';
import { GridNotes } from 'components/dialog-patient/grids/notes';
import { usePermissions } from 'hooks/use-permissions';

const QUERY_NAME = 'tab-activities-all';

interface Props {
  userPatientProfileID: string;
  notDoneTasks?: number;
}

export const TabAllActivities: React.FC<Props> = ({ userPatientProfileID, notDoneTasks }) => {
  const { t } = useTranslate();

  const { value, onChange } = useValueQuery<PATIENT_CARD_ALL_ACTIVITIES_TABS>({
    name: QUERY_NAME,
    init: PATIENT_CARD_ALL_ACTIVITIES_TABS.ACTIVITIES,
    destroyOnUnmount: true,
  });

  const isAllowToEditCommercials = usePermissions('isAllowToEditCommercials');
  const isAllowToEditPermissions = usePermissions('isAllowToEditPermissions');
  const isAllowToRenderActivities = isAllowToEditCommercials || isAllowToEditPermissions;

  const tabs = useMemo<TabItem<PATIENT_CARD_ALL_ACTIVITIES_TABS>[]>(
    () => [
      {
        value: PATIENT_CARD_ALL_ACTIVITIES_TABS.ACTIVITIES,
        title: t('activities'),
        element: <AllActivitiesGrid userPatientProfileID={userPatientProfileID} />,
      },
      {
        value: PATIENT_CARD_ALL_ACTIVITIES_TABS.TODO_TASKS,
        title: (
          <Badge badgeContent={notDoneTasks} color={'error'}>
            <Box p={'0 1rem'}>{t('todo-tasks')}</Box>
          </Badge>
        ),
        element: (
          <GridTasks
            userPatientProfileID={userPatientProfileID}
            notDoneTasks={notDoneTasks}
            shouldCheckTasksStatus
          />
        ),
      },
      {
        value: PATIENT_CARD_ALL_ACTIVITIES_TABS.NOTES,
        title: t('notes'),
        element: <GridNotes userPatientProfileID={userPatientProfileID} />,
      },
      {
        value: PATIENT_CARD_ALL_ACTIVITIES_TABS.NOTEBOOKS_FROM_RAPID,
        title: t('notebooks-from-rapid'),
        element: <NotebooksFromRapid userPatientProfileID={userPatientProfileID} />,
      },
      ...(isAllowToRenderActivities
        ? [
            {
              value: PATIENT_CARD_ALL_ACTIVITIES_TABS.PATIENT_ACTIVITIES,
              title: t('all-patient-activities'),
              element: <GridPatientActivities userPatientProfileID={userPatientProfileID} />,
              keepInMemory: false,
            },
          ]
        : []),
    ],
    [t, userPatientProfileID, notDoneTasks, isAllowToRenderActivities],
  );

  return (
    <TabsWrapper
      tabs={tabs}
      value={value}
      onChange={onChange}
      classes={{ content: style.content }}
      TabPanelProps={{
        style: {
          paddingTop: 0,
        },
      }}
    />
  );
};
