import { FixServerObject } from 'utils/types';
const API_ENDPOINT = 'ToDoTaskActivities';

export const API_TODO_TASK_ACTIVITIES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<Components.Schemas.ToDoTaskCategory, 'id'>) =>
    `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<Components.Schemas.ToDoTaskCategory, 'id'>) =>
    `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface ToDoTaskActivity
  extends FixServerObject<Components.Schemas.ToDoTaskActivity, 'id'> {}
export interface PostTodoTaskMeetingActivityInput
  extends Omit<ToDoTaskActivity, 'id' | 'entryDate' | 'userEmployeeProfileID'> {
  toDoTaskID: string;
  remarks: string;
}
