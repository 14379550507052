import React, { useEffect } from 'react';
import { apiStatic } from 'AurionCR/components';
import axios, { AxiosTransformer } from 'axios';
import { isServerDateString } from 'utils/dates';
import { api } from 'utils/service';

const instances = [apiStatic, api, axios];
export const axiosDateTransformer: AxiosTransformer | AxiosTransformer[] = (res) => {
  try {
    return JSON.parse(res, (key, value) => {
      if (typeof value === 'string' && isServerDateString(value)) {
        const [_dateString, _ms] = value.split('.');
        const _dateNumber = Date.parse(_dateString);

        if (_dateNumber) {
          const ms = _ms || '000';
          // ms has no timezone | only numbers
          if (/^[0-9]+$/gi.test(ms)) {
            return `${_dateString}.${ms}Z`;
          }
        }
      }
      return value;
    });
  } catch (e) {
    return res;
  }
};

export const AxiosInterceptorGet: React.FC = ({ children }) => {
  useEffect(() => {
    instances.forEach((instance) => {
      instance.defaults.transformResponse = [
        ...(instance.defaults.transformRequest as AxiosTransformer[]),
        axiosDateTransformer,
      ];
    });
  }, []);

  return <>{children}</>;
};
