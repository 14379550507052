import React, { FC, memo, useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ClickAwayListener } from '@material-ui/core';
import { IDataGridColumnEditorCustom } from 'components/data-grid/model';
import Footer from 'components/data-grid/components/column/editors/footer';
import { useFieldProps } from 'hooks';
import { GridOnTasks } from 'models/grid';
import { AppDatePicker } from 'components/app-date-picker';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useOpen } from 'AurionCR/components/hooks';
import { composeFunctions } from 'utils';
import { useTranslate } from 'hooks/use-translate';
import { apiUserPatientProfileSubscriptionActivities } from 'services/user-patient-profile-subscription-activities';
import { isMutationFulfilled } from 'utils/rtk-query';
import { convertToDate, isDateLike } from 'utils/dates';
import { DialogEndDateRemark, EndDateRemarkFormModel } from '../dialog-end-date-remark';
import { endOfDay, isAfter } from 'date-fns';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { useAppDispatch } from 'store';

type GridModel = {
  id: string;
  endDate: string;
  userPatientProfileID: string;
};

const useGetPatientQuery = apiUserPatientProfile.useGetPatientForGeneralFormQuery;

const useCreateLog =
  apiUserPatientProfileSubscriptionActivities.useCreateUserPatientProfileSubscriptionActivityLogChangeEndDateMutation;

const schema = yup.object({
  data: yup.string().required('rule-required').test('date', 'rule-date', isDateLike).default(''),
});

interface Props {
  row: GridModel;
  editor: IDataGridColumnEditorCustom;
  data: string;
  onClose: any;
  onTasks: GridOnTasks;
}
export const EditorPatientSubscriptionEndDate: FC<Props> = memo(({ row, onClose, onTasks }) => {
  const dispatch = useAppDispatch();
  const { tp } = useTranslate();
  const { handleSubmit, errors, control, getValues, watch } = useForm({
    defaultValues: schema.cast({ data: row.endDate }),
    resolver: yupResolver(schema),
  });

  const { data: patient } = useGetPatientQuery(row.userPatientProfileID);

  const getFieldProps = useFieldProps({ errors });

  const stateOpenReason = useOpen();

  const [createItem, resultCreateItem] = useCreateLog();

  const onSubmit = useCallback(
    async (formData: EndDateRemarkFormModel) => {
      const endDate = getValues('data');
      const res = await createItem({
        userPatientProfileSubscriptionID: String(row.id),
        endDate,
        remarks: formData.remarks,
      });

      if (!isMutationFulfilled(res)) return;

      if (formData.makeActive) {
        dispatch(
          apiUserPatientProfile.endpoints.activatePatient.initiate(row.userPatientProfileID),
        );
      }
      onTasks([['pathData', { row: { ...row, id: String(row.id) }, value: { endDate: endDate } }]]);
      onClose();
    },
    [createItem, getValues, onTasks, onClose, row, dispatch],
  );

  const isLoading = resultCreateItem.isLoading;

  const entryDate = watch('data');

  const isInactive = patient?.isActive === false;

  const initValues = useMemo(() => {
    return {
      makeActive: isInactive && isAfter(convertToDate(entryDate), endOfDay(new Date())),
    };
  }, [entryDate, isInactive]);

  const disabled = isLoading || !patient;

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className="grid-editor date">
        <form onSubmit={handleSubmit(stateOpenReason.handleOpen)}>
          <Controller
            control={control}
            name="data"
            render={(renderProps) => (
              <div className="date-field-wrapper">
                <AppDatePicker {...getFieldProps(renderProps)} label={null} disabled={disabled} />
              </div>
            )}
          />
          <Footer onClose={onClose} disabled={disabled} />
        </form>
        {stateOpenReason.isOpen && (
          <DialogEndDateRemark
            title={tp('subscription-end-change-reason-title')}
            description={tp('subscription-end-change-reason-description')}
            initValues={initValues}
            isLoading={isLoading}
            onClose={composeFunctions(stateOpenReason.handleClose, onClose)}
            onSubmit={onSubmit}
          />
        )}
      </div>
    </ClickAwayListener>
  );
});
