import * as yup from 'yup';
export interface EmailModel {
  subject: string;
  toEmails: string[];
  replyToEmail: string;
  fromDisplayName: string;
  html: string;
}

export const schemaEmailModel = yup.object({
  subject: yup.string().required('').trim().default(''),
  toEmails: yup
    .array()
    .of(yup.string().required('rule-required').email('rule-email').trim())
    .min(1, 'rule-required')
    .default([]),
  replyToEmail: yup.string().required('rule-required').email('rule-email').default(''),
  fromDisplayName: yup.string().required('rule-required').default(''),
  html: yup.string().nullable().notRequired(),
});
