import React, { useMemo } from 'react';

import { getSearchFilter } from 'utils/front-filters';
import { Divider, Popper, PopperProps, Typography } from '@material-ui/core';
import { Stack } from 'components/stack';
import style from './index.module.scss';
import { AppSelect, AppSelectProps } from 'components/app-select';
import { ListboxComponent } from 'components/app-select/components';
import { apiIcd10s } from 'services/icd10s';

const CustomPopper = React.forwardRef<HTMLDivElement, PopperProps>(({ children, ...rest }, ref) => (
  <Popper {...rest} ref={ref} style={{ ...rest.style, width: 760, maxWidth: '90vw' }}>
    {children}
  </Popper>
));

type Option = {
  id: string | null | undefined;
  code: string | null | undefined;
  titleHeb: string | null | undefined;
  description: string | null | undefined;
};

const renderOption = (option: Option) => {
  const title = [option.titleHeb, option.description].filter(Boolean).join(' ');
  return (
    <Stack
      className={style.item}
      spacing={1}
      divider={<Divider className={style.divider} orientation={'vertical'} flexItem />}
    >
      <Typography title={title} noWrap className={style.itemTitle}>
        {title}
      </Typography>
      {option.code && (
        <Typography title={option.code} noWrap className={style.itemId}>
          {option.code}
        </Typography>
      )}
    </Stack>
  );
};
const filterOptions = <T extends Option>(options: T[], state: any) => {
  return options.filter(
    getSearchFilter<T>({
      value: state.inputValue,
      fields: ['titleHeb', 'code', 'description'],
    }),
  );
};

export const getOptionLabel = (option: Option) => {
  return [option.code, option.titleHeb, option.description].filter(Boolean).join(' | ');
};

const ModifiedListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>((props, ref) => <ListboxComponent {...props} ref={ref} renderOption={renderOption as any} />);

interface Props<
  T extends Record<string, any> = { id: string; title: string; isActive?: boolean },
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
> extends Partial<AppSelectProps<T, Multiple, DisableClearable, FreeSolo>> {
  source?: T[];
  disabled?: boolean;
  loading?: boolean;
  label?: React.ReactNode;
}

export const SelectIcd10 = <
  T extends Option,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>({
  loading,
  ...rest
}: Props<T, Multiple, DisableClearable, FreeSolo>) => {
  const source = apiIcd10s.useGetSourceIcdsQuery();
  const options = useMemo(() => {
    return source.data || [];
  }, [source.data]);

  return (
    <AppSelect
      filterOptions={filterOptions}
      options={options as T[]}
      renderOption={renderOption}
      ListboxComponent={ModifiedListboxComponent}
      getOptionLabel={getOptionLabel}
      PopperComponent={CustomPopper}
      {...rest}
      disabled={rest.disabled}
      loading={source.isFetching || loading}
    />
  );
};
