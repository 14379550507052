import { useContext } from 'react';
import { GridTasksContext, GridTasksContextValue } from './components/grid-tasks-provider';

export const useDataGridTasks = <T = any>() => {
  const context = useContext<GridTasksContextValue<T> | null>(GridTasksContext);

  if (!context) {
    throw new Error('GridTasksContext is required');
  }

  return context;
};
