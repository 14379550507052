import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  LinearProgress,
} from '@material-ui/core';
import {
  apiSupportMeeting,
  makeSchemaSupportMeetingEdit,
  SupportMeetingInput,
} from 'services/support-meetings';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { SelectMeetingType } from 'components/select-meeting-type';

import CloseIcon from '@material-ui/icons/Close';
import { useSourceSupportMeetingTypes } from 'components/hooks';
import { useEffectError, useFieldProps, useMountedRef } from 'hooks';
import { usePermissions } from 'hooks/use-permissions';
import { createDefaultMeetingRange } from 'utils/app-helpers';
import { useAppDispatch } from 'store';
import { actionSupportMeetingActivitiesCrateLogCallToPatient } from 'store/support-meeting-activities';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormEdit } from '../form-edit';
import { Stack } from 'components/stack';
import { ButtonCall, ButtonTask } from 'components/dialog-patient/components';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { adapterMeeting } from 'utils/adapters';
import { ButtonCancel, ButtonSave } from 'components/app-buttons';
import { SupportActivities } from '../activities';
import { Loading } from 'AurionCR/components';
import {
  NotebookPrescriptionInput,
  useSupportMeetingNotebookCreateComplete,
  useSupportMeetingNotebookRenewalComplete,
} from 'components/notebook-prescription';
import { isMutationRejected } from 'utils/rtk-query';
import style from './index.module.scss';
import { ButtonSms } from '../button-sms';
import { PatientInfo } from '../patient-info';
import { supportMeetingEmitter } from '../../emitter';
import { isEqual, pick } from 'lodash-es';
import { composeFunctions } from 'utils';
import { useOpen } from 'AurionCR/components/hooks';
import { useTranslate } from 'hooks/use-translate';
import {
  DialogMeetingActivityRemark,
  MeetingActivityModel,
} from 'components/dialog-meeting-activity-remark';

const useQueryGet = apiSupportMeeting.useGetSupportMeetingQuery;
const useQueryGetPatientInfo = apiUserPatientProfile.useGetPatientDetailsForSupportMeetingQuery;
const useMutationPatch = apiSupportMeeting.useUpdateSupportMeetingWithLogMutation;

const defaultValues = () => ({
  ...createDefaultMeetingRange(),
});

type FormModel = Omit<SupportMeetingInput, '_IS_RENEWAL'>;

export interface DialogSupportMeetingEditProps {
  itemID: string;
  times: { id: string }[];
  onClose: () => void;
  onRefresh: () => void;
  onPreviewPatient: (userPatientProfileID: string) => void;
}

export const DialogSupportMeetingEdit = memo<DialogSupportMeetingEditProps>(
  ({ itemID, times, onClose, onRefresh, onPreviewPatient }) => {
    const mountedRef = useMountedRef();
    const dispatch = useAppDispatch();
    const { tp } = useTranslate();

    const [, resultComplete] = useSupportMeetingNotebookCreateComplete(itemID);
    const [, resultRenewal] = useSupportMeetingNotebookRenewalComplete(itemID);

    const resultMeetingGet = useQueryGet(itemID, {
      refetchOnMountOrArgChange: true,
    });

    const meeting = resultMeetingGet.data;

    const userPatientProfileSessionID = meeting?.userPatientProfileSessionID;

    const isHandled = meeting ? Boolean(userPatientProfileSessionID) : false;

    const userPatientProfileID = meeting?.userPatientProfileID || '';

    const resultPatientGet = useQueryGetPatientInfo(userPatientProfileID, {
      skip: !userPatientProfileID,
    });
    const patient = resultPatientGet.data;

    const [isRenewal, setIsRenewal] = useState(false);
    const schema = useMemo(() => {
      return makeSchemaSupportMeetingEdit({ isPrescriptionRenewal: isRenewal });
    }, [isRenewal]);

    const formMethods = useForm({
      defaultValues: schema.cast(defaultValues(), { stripUnknown: true, assert: false }),
      resolver: yupResolver(schema),
    });
    const { control, errors, watch, handleSubmit, reset, register, trigger, getValues } =
      formMethods;

    const adaptedMeeting = useMemo(() => {
      return meeting ? adapterMeeting({ ...defaultValues(), ...meeting }) : null;
    }, [meeting]);

    useEffect(() => {
      if (!adaptedMeeting) return;
      reset(
        schema.cast(adaptedMeeting, {
          stripUnknown: true,
          assert: false,
        }),
      );
      //eslint-disable-next-line
    }, [adaptedMeeting, reset]);

    const getFieldProps = useFieldProps({ errors });

    const sourceSupportMeetingTypes = useSourceSupportMeetingTypes();
    const supportMeetingTypeID = watch('supportMeetingTypeID');
    useEffect(() => {
      if (!supportMeetingTypeID) return;
      const type = sourceSupportMeetingTypes.map[supportMeetingTypeID];

      if (!type) return;

      setIsRenewal(!!type.isPrescriptionRenewal);
    }, [supportMeetingTypeID, sourceSupportMeetingTypes.map]);

    const isInitRenewal = useMemo(() => {
      if (!meeting) return false;
      return sourceSupportMeetingTypes.map[meeting.supportMeetingTypeID]?.isPrescriptionRenewal;
    }, [sourceSupportMeetingTypes.map, meeting]);

    const supportMeetingOptions = useMemo(() => {
      if (!isInitRenewal) return sourceSupportMeetingTypes.data;
      return sourceSupportMeetingTypes.data.filter((type) => {
        return type.isPrescriptionRenewal === true;
      });
    }, [sourceSupportMeetingTypes.data, isInitRenewal]);

    const isAllowToEdit = usePermissions('!isAllowToEditTaskManagerLimited');

    const isReadOnly = !isAllowToEdit;

    const [triggerPatch, resultPatch] = useMutationPatch();
    useEffectError(resultPatch.error);

    const performSubmit = useCallback(
      async (formData: FormModel, activityData?: MeetingActivityModel) => {
        if (!adaptedMeeting) {
          throw new Error('unexpected-behaviour');
        }
        const payload = { ...formData, _IS_RENEWAL: isRenewal, id: itemID };
        const result = await triggerPatch({
          initData: adaptedMeeting,
          formData: payload,
          remark: activityData?.remarks,
          remarkForPatientCallStatusID: activityData?.remarkForPatientCallStatusID,
        });

        if (isMutationRejected(result)) {
          return;
        }

        if (mountedRef.current) {
          onRefresh();
          onClose();
        }
      },
      [triggerPatch, isRenewal, itemID, onClose, mountedRef, onRefresh, adaptedMeeting],
    );
    const stateOpenReason = useOpen();
    const onSubmitForm = (formData: FormModel) => {
      if (!meeting) return;

      const oldData = pick(adaptedMeeting, ['date', 'start', 'end']);
      const newData = pick(formData, ['date', 'start', 'end']);

      if (isEqual(oldData, newData)) {
        return performSubmit(formData);
      }

      stateOpenReason.handleOpen();
    };

    const onSubmitReason = async (reasonData: MeetingActivityModel) => {
      const formData = getValues();
      const isValid = await trigger();

      if (!isValid) return;

      performSubmit(formData as FormModel, reasonData);
    };

    const onDoneNotebook = useCallback(() => {
      onRefresh();
      onClose();
    }, [onRefresh, onClose]);

    const isLoading =
      resultMeetingGet.isLoading ||
      resultComplete.isLoading ||
      resultRenewal.isLoading ||
      resultPatch.isLoading;

    const disabled = useMemo(
      () => isLoading || isHandled || isReadOnly,
      [isLoading, isReadOnly, isHandled],
    );

    const onCalled = useCallback(async () => {
      if (!itemID) return;

      await dispatch(
        actionSupportMeetingActivitiesCrateLogCallToPatient({
          supportMeetingID: itemID,
        }),
      );

      supportMeetingEmitter.emit('activity-created', { meetingID: itemID });
    }, [dispatch, itemID]);

    const onClickPreviewPatient = useMemo(() => {
      if (!userPatientProfileID) return undefined;
      if (!onPreviewPatient) return undefined;

      return () => {
        onPreviewPatient(userPatientProfileID);
      };
    }, [userPatientProfileID, onPreviewPatient]);

    return (
      <>
        <Dialog open={true} onClose={onClose} fullWidth maxWidth="xl">
          <Controller
            control={control}
            name={'supportMeetingTypeID'}
            render={(renderProps) => {
              return (
                <SelectMeetingType
                  {...getFieldProps(renderProps)}
                  disabled={disabled || isInitRenewal}
                  options={supportMeetingOptions}
                  loading={sourceSupportMeetingTypes.loading}
                  endIcon={
                    <IconButton
                      className={style.close}
                      style={{ color: 'white' }}
                      onClick={onClose}
                    >
                      <CloseIcon color={'inherit'} />
                    </IconButton>
                  }
                />
              );
            }}
          />
          {isLoading && <LinearProgress />}
          <DialogContent className={style.content}>
            <div>
              <Stack spacing={1}>
                <ButtonSms
                  supportMeetingID={itemID}
                  userPatientProfileID={userPatientProfileID}
                  patient={patient ?? {}}
                  meeting={meeting ?? null}
                />
                <ButtonCall
                  taskID={null}
                  userPatientProfileID={userPatientProfileID}
                  onDone={onCalled}
                  fullWidth
                />
                <ButtonTask userPatientProfileID={userPatientProfileID} fullWidth />
              </Stack>
              <Collapse in={!!userPatientProfileID}>
                <PatientInfo
                  userPatientProfileID={userPatientProfileID}
                  onPreviewPatient={onClickPreviewPatient}
                  mt={1}
                />
              </Collapse>
              <FormProvider {...formMethods}>
                <input hidden {...register('userPatientProfileID')} />
                <FormEdit
                  isLoading={isLoading}
                  times={times}
                  disabled={disabled}
                  isRenewal={isRenewal}
                >
                  {meeting && (
                    <NotebookPrescriptionInput
                      meeting={meeting}
                      disabled={disabled}
                      isRenewal={isRenewal}
                      notebook={{ labelKey: meeting.labelKey }}
                      onDone={onDoneNotebook}
                    />
                  )}
                </FormEdit>
              </FormProvider>
            </div>
            <Divider orientation={'vertical'} />
            <div>
              {userPatientProfileID ? (
                <SupportActivities
                  supportMeetingID={itemID}
                  userPatientProfileID={userPatientProfileID}
                  disabled={disabled}
                />
              ) : (
                <Loading />
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <ButtonCancel isBack={false} onClick={onClose} />
            <ButtonSave isCreate={false} disabled={disabled} onClick={handleSubmit(onSubmitForm)} />
          </DialogActions>
        </Dialog>
        {stateOpenReason.isOpen && (
          <DialogMeetingActivityRemark
            title={tp('support-meeting-update-reason-title')}
            description={tp('support-meeting-update-reason-description')}
            isLoading={false}
            onSubmit={composeFunctions(stateOpenReason.handleClose, onSubmitReason)}
            onClose={stateOpenReason.handleClose}
            reason={'rescheduled'}
          />
        )}
      </>
    );
  },
);
