import { ChangeLogConfig } from 'modules/change-log';
import { dateFormat } from 'utils/dates';

import { ClinicalMeeting } from './models';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { PatchPartial } from 'utils/types';

type FormData = Pick<
  ClinicalMeeting,
  | 'isActive'
  | 'clinicalMeetingTypeID'
  | 'clinicalMeetingSubjectID'
  | 'userEmployeeProfileID'
  | 'meetingFromDateTime'
  | 'meetingToDateTime'
  | 'arriveToClinicDateTime'
  | 'completeSessionDateTime'
  | 'organizerUserEmployeeProfileID'
  | 'userPatientProfileID'
  | 'approveMeeting'
  | 'remarks'
>;

type Config = ChangeLogConfig<
  PatchPartial<ClinicalMeeting, 'id'>,
  Components.Schemas.ClinicalMeeting
>;

export const logConfig: Record<keyof FormData, Config> = {
  clinicalMeetingTypeID: {
    select: 'new { clinicalMeetingType.title } as clinicalMeetingType',
    getValue: (res) => String(res.clinicalMeetingType?.title),
  },
  clinicalMeetingSubjectID: {
    select: 'new { clinicalMeetingSubject.title } as clinicalMeetingSubject',
    getValue: (res) => String(res.clinicalMeetingSubject?.title),
  },
  userEmployeeProfileID: {
    select: 'new { userEmployeeProfile.fullName } as userEmployeeProfile',
    getValue: (res) => String(res.userEmployeeProfile?.fullName),
  },
  userPatientProfileID: {
    select: 'new { userPatientProfile.fullName } as userPatientProfile',
    getValue: (res) => String(res.userPatientProfile?.fullName),
  },
  organizerUserEmployeeProfileID: {
    select: 'new { organizerUserEmployeeProfile.fullName } as organizerUserEmployeeProfile',
    getValue: (res) => String(res.organizerUserEmployeeProfile?.fullName),
  },
  remarks: {
    select: 'remarks',
    getValue: (res) => String(res.remarks ?? null),
  },
  meetingFromDateTime: {
    select: 'meetingFromDateTime',
    getValue: (res) => dateFormat(res.meetingFromDateTime, APP_FORMAT_DATE_TIME),
  },
  meetingToDateTime: {
    select: 'meetingToDateTime',
    getValue: (res) => dateFormat(res.meetingToDateTime, APP_FORMAT_DATE_TIME),
  },
  approveMeeting: {
    select: 'approveMeeting',
    getValue: (res) => String(res.approveMeeting),
  },
  isActive: {
    select: 'isActive',
    getValue: (res) => String(res.isActive),
  },
  arriveToClinicDateTime: {
    select: 'arriveToClinicDateTime',
    getValue: (res) =>
      res.arriveToClinicDateTime
        ? dateFormat(res.arriveToClinicDateTime, APP_FORMAT_DATE_TIME)
        : '',
  },
  completeSessionDateTime: {
    select: 'completeSessionDateTime',
    getValue: (res) =>
      res.completeSessionDateTime
        ? dateFormat(res.completeSessionDateTime, APP_FORMAT_DATE_TIME)
        : '',
  },
};
