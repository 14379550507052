import React, { memo } from 'react';
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';

interface Props {
  title?: string;
  message?: React.ReactNode;
  onCancel?: () => void;
  onConfirm?: () => void;
  ButtonCancelProps?: Partial<ButtonProps>;
  ButtonConfirmProps?: Partial<ButtonProps>;
  isLoading?: boolean;
}

export const DialogConfirm = memo<Props>(
  ({ title, message, isLoading, onCancel, ButtonCancelProps, onConfirm, ButtonConfirmProps }) => {
    const { t } = useTranslate();
    return (
      <Dialog open={true}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          {onCancel && (
            <Button color="primary" children={t('no')} {...ButtonCancelProps} onClick={onCancel} />
          )}
          {onConfirm && (
            <Button
              color="secondary"
              children={t('yes')}
              {...ButtonConfirmProps}
              onClick={onConfirm}
              disabled={isLoading || ButtonConfirmProps?.disabled}
            />
          )}
        </DialogActions>
      </Dialog>
    );
  },
);
