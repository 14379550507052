import {
  DialogSupportMeetingNew,
  DialogSupportMeetingEdit,
  DialogSupportMeetingNewProps,
  DialogSupportMeetingEditProps,
  DialogSupportMeetingActivityLog,
  ActivityLogModel,
  DialogCancelMeetingContainer,
} from './components';
import React, { memo } from 'react';

export {
  DialogSupportMeetingNew,
  DialogSupportMeetingEdit,
  DialogSupportMeetingActivityLog,
  DialogCancelMeetingContainer,
};

export type { ActivityLogModel };

type Props = DialogSupportMeetingNewProps | DialogSupportMeetingEditProps;

export const DialogSupportMeeting = memo<Props>((props) => {
  if (props.itemID === true) {
    return (
      <DialogSupportMeetingNew
        itemID={true}
        initData={props.initData}
        onRefresh={props.onRefresh}
        onClose={props.onClose}
        times={props.times}
      />
    );
  }

  return (
    <DialogSupportMeetingEdit
      itemID={props.itemID}
      onRefresh={props.onRefresh}
      onClose={props.onClose}
      times={props.times}
      onPreviewPatient={props.onPreviewPatient}
    />
  );
});
