import React, { memo, useCallback, useMemo } from 'react';
import { API_DRUGS } from 'services/drug';
import { useSource } from 'AurionCR/components';
import { InputAdornment, Popper, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';
import { generateDynamicQuery } from 'utils/service';

const useSourceDrugs = () => {
  const params = useMemo(
    () =>
      generateDynamicQuery({
        select: [
          'id',
          'catalogName',
          'drugNameEng',
          'defaultDosageForm',
          'defaultDosageFormTotal',
          'defaultDosageFormTotalDesc',
          'defaultDosageFormDaily',
          'defaultInstructions',
        ].join(','),
        filter: 'isActive==true',
        orderBy: 'highlight desc, catalogName',
      }),
    [],
  );
  return useSource<Components.Schemas.Drug>(`${API_DRUGS.GET_ALL_DYNAMIC}?${params}`);
};

const CustomPopper = memo((props: any) => {
  return <Popper {...props} className={clsx('MuiAutocomplete-popper')} placement="bottom-start" />;
});

interface Props {
  onTasks: (val: any[]) => void;
}
export const SelectAddNewDrug = memo<Props>(({ onTasks }) => {
  const { t } = useTranslate();
  const { data, loading } = useSourceDrugs();

  const onChange = useCallback(
    (e, value) => {
      onTasks([['onHandle', { type: 'onSelectDrug', value }]]);
    },
    [onTasks],
  );

  return (
    <Autocomplete
      className={style.root}
      options={data}
      value={null}
      getOptionLabel={(item) => String(item.catalogName)}
      getOptionSelected={(item, value) => item.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size={'small'}
          placeholder={t('add-drug')}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <AddIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      loading={loading}
      fullWidth={true}
      PopperComponent={CustomPopper}
      onChange={onChange}
    />
  );
});
