import { UserPatientProfile } from '../user-patient-profile';
import { UserEmployeeProfile } from '../user-employee-profiles';
import { Drug } from '../drug';
import { Gender } from '../genders';

const API = 'PdfGenerators';
export const API_PDF = {
  GENERATE: `${API}/GetPdf`,
};

interface PdfGimel29Patient
  extends Pick<
    UserPatientProfile,
    | 'fullName'
    | 'idNumber'
    | 'dateOfBirth'
    | 'weight'
    | 'height'
    | 'address'
    | 'mobilePhone'
    | 'medicalRemarks'
  > {
  gender: Pick<Gender, 'title'>;
}
export type Gimel29Payload = {
  doctor: UserEmployeeProfile;
  patient: PdfGimel29Patient;
  drug: Drug;
};
