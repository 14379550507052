import { GridModel } from '../grid-config';
import React, { useMemo } from 'react';

interface Props {
  data: GridModel;
}
export const TemplateRemark: React.FC<Props> = ({ data }) => {
  const remark = useMemo(() => {
    const item = data.items.find(({ isWeight }) => !isWeight);
    if (!item) return '';
    return item.value;
  }, [data.items]);

  return <div>{remark}</div>;
};
