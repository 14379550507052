import { enumToArray } from 'utils/other';
import { Box } from '@material-ui/core';
import { FilterButtonSwitcher } from 'components/app-grid';
import React from 'react';
import { useTranslate } from 'hooks/use-translate';
import { DONE_STATUS } from '../../helpers';
import { IDataGridFilter } from 'components/data-grid/model';

interface Props {
  filter: IDataGridFilter;
  onTasks: any;
  value: DONE_STATUS;
}

export const FilterDoneTasks: React.FC<Props> = (props) => {
  const { t } = useTranslate();

  const options = enumToArray(DONE_STATUS).map((item) => ({
    ...item,
    value: item.id,
    title: t(item.id),
  }));
  return (
    <Box mx={1}>
      <FilterButtonSwitcher {...props} options={options} />
    </Box>
  );
};
