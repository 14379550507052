import {IGet} from "../interfaces/requests";
import {apiStatic} from "../../../helpers";
import axios from "axios";

export const axiosGet = ({url, params, cancelToken}: IGet) => {
  return new Promise((resolve: (response: any) => void, reject: (error: any) => void) => {
    apiStatic(
      {
        method: 'get',
        url: url,
        cancelToken: cancelToken?.token,
        params
      })
      .then(resolve)
      .catch(error => {
        if (!axios.isCancel(error)) reject(error)
      })
  })
};
