import React, { memo, useMemo } from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { ButtonBase, ButtonBaseProps, Typography } from '@material-ui/core';
import { usePermissions } from 'hooks/use-permissions';
import { useTranslate } from 'hooks/use-translate';
import { getAge, isTeenager } from 'utils/dates';
import { TemplatePatientFullName } from '../template-patient-full-name';
import ReplyIcon from '@material-ui/icons/Reply';
import { IPatientCardInfo } from 'services/user-patient-profile';

export interface TemplatePatientProps extends ButtonBaseProps {
  patient: IPatientCardInfo | undefined;
}

export const TemplatePatient = memo<TemplatePatientProps>(({ className, patient, ...rest }) => {
  const { t } = useTranslate();
  const age = useMemo(() => {
    return getAge(patient?.dateOfBirth);
  }, [patient]);

  const isChild = useMemo(() => {
    return isTeenager(patient?.dateOfBirth);
  }, [patient]);

  const isAllowToViewPatients = usePermissions('isAllowToEditPatients');

  const isClickable = !!(isAllowToViewPatients && rest.onClick);

  return (
    <ButtonBase
      className={clsx(style.root, className, {
        [style.child]: isChild,
      })}
      disableRipple={!isClickable}
      {...rest}
      onClick={isClickable ? rest.onClick : undefined}
    >
      <TemplatePatientFullName patient={patient} color={'inherit'} className={clsx(style.name)}>
        {patient?.fullName}
      </TemplatePatientFullName>
      <Typography color={'inherit'} className={style.age}>
        {t('age')}: {age}
      </Typography>
      {isClickable && <ReplyIcon className={style.icon} />}
    </ButtonBase>
  );
});
