import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServiceNotebooks } from 'services/notebook';
import { NotebookFormGeneratorSettings } from './slice';

export const actionNotebookFormGeneratorGetNotebook = createAsyncThunk(
  `NOTEBOOK_FORM_GENERATOR/GetNotebook`,
  async (notebookID: string) => {
    const result = await ServiceNotebooks.getDynamic<NotebookFormGeneratorSettings>(notebookID, {
      select: [
        'id',
        'labelKey',
        'displayNextMeetingForm',
        'displayIcd10Section',
        'displaySurgerySection',
        'displaySensitivitySection',
        'displayRoutineDrugSection',
        'displayPastDrugForObesitySection',
        'displaySensitivityDrugSection',
        'displayClinicDrugSection',
        'displaySideEffectSection',
        'isRequirePrescription',
        'userEmployeeProfilePermissionNotebooks.Select(k => k.userEmployeeProfilePermissionID ) as userEmployeeProfilePermissionIDs',
        'notebookFieldInputs.OrderBy(i => i.rank).Where(i => i.isActive==true).Select(i => new  { i.id, i.isRequired, i.rank,i.defaultValue,i.fieldInputID,i.fieldInput,i.fieldInput.inputType,i.fieldInput.inputType.inputTypeDataSource,i.fieldInput.inputType.inputTypeParameter }) as notebookFieldInputs',
      ].join(','),
    });

    return result.data;
  },
);
