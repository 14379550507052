import React, { memo, useCallback, useMemo } from 'react';
import { useI18n } from 'AurionCR/components';
import { add, format, nextSunday } from 'date-fns';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useOpen } from 'AurionCR/components/hooks';
import { composeFunctions } from 'utils';
import { AppDatePicker } from 'components/app-date-picker';
import { convertToDate } from 'utils/dates';

interface Props {
  setDate: (val: Date) => void;
  disabled?: boolean;
}

export const ButtonMeetingMove = memo<Props>(({ setDate, disabled }) => {
  const { t } = useI18n();
  const { isOpen: isOpenPicker, handleOpen: onOpenPicker, handleClose: onClosePicker } = useOpen();

  const { isOpen: isOpenMenu, handleToggle: onToggleMenu, handleClose: onCloseMenu } = useOpen();

  const anchorRef = React.useRef<HTMLDivElement>(null);

  const onCloseAll = useMemo(() => {
    return composeFunctions(onClosePicker, onCloseMenu);
  }, [onClosePicker, onCloseMenu]);

  const onMoveToTomorrow = useCallback(() => {
    const now = new Date();
    setDate(add(now, { days: format(now, 'EEEEEE') === 'Fr' ? 2 : 1 }));
  }, [setDate]);

  const onMoveToNextSunday = useCallback(() => {
    setDate(nextSunday(new Date()));
    onCloseMenu();
  }, [setDate, onCloseMenu]);

  const onMoveToToday = useCallback(() => {
    setDate(new Date());
    onCloseMenu();
  }, [setDate, onCloseMenu]);

  const onChangeDate = useCallback(
    (value: string | null) => {
      if (value) {
        setDate(convertToDate(value));
      }

      onCloseAll();
    },
    [setDate, onCloseAll],
  );

  return (
    <>
      <ButtonGroup variant="contained" color="primary" ref={anchorRef}>
        <Button
          disabled={disabled}
          onClick={onMoveToTomorrow}
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          {t('move-to-tomorrow')}
        </Button>
        <Button size="small" disabled={disabled} onClick={onToggleMenu}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={isOpenMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        placement={'bottom-end'}
        transition
        style={{
          zIndex: 2,
        }}
      >
        <Paper>
          <ClickAwayListener onClickAway={onCloseAll}>
            {isOpenPicker ? (
              <div>
                <AppDatePicker
                  autoOk
                  orientation="landscape"
                  variant="static"
                  openTo="date"
                  disablePast={true}
                  value={null}
                  onChange={onChangeDate}
                />
              </div>
            ) : (
              <MenuList>
                <MenuItem onClick={onOpenPicker}>{t('move-to-another-date')}</MenuItem>
                <MenuItem onClick={onMoveToToday}>{t('move-to-today')}</MenuItem>
                <MenuItem onClick={onMoveToNextSunday}>{t('move-to-next-sunday')}</MenuItem>
              </MenuList>
            )}
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
});
