import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';

const selectPatientSmsMessagesState = (state: RootState) => state.patientSmsMessages;

export const selectPatientSmsMessageList = createSelector(
  selectPatientSmsMessagesState,
  ({ smsMessageList }) => {
    return smsMessageList;
  },
);

export const selectPatientSmsMessageCurrent = createSelector(
  selectPatientSmsMessageList,
  (list) => {
    return list.length ? list[0] : null;
  },
);
