import {
  apiPharmacyDrugQuantities,
  schemaPharmacyDrugQuantity,
} from 'services/pharmacy-drug-quantities';
import { apiDrugs } from 'services/drug';
import { InferType } from 'yup';

export const schema = schemaPharmacyDrugQuantity;
export type FormModel = InferType<typeof schema>;
export const useGetQuery = apiPharmacyDrugQuantities.useGetPharmacyDrugQuantityFormDataQuery;
export const usePatchMutation = apiPharmacyDrugQuantities.usePatchPharmacyDrugQuantityMutation;
export const usePostMutation = apiPharmacyDrugQuantities.usePostPharmacyDrugQuantityMutation;
export const useDeleteMutation = apiPharmacyDrugQuantities.useDeletePharmacyDrugQuantityMutation;
export const useSourceDosageFormOptions = (drugID: string) => {
  const { data, isLoading } = apiDrugs.useGetSourceDrugUniqueDosageFormsQuery(drugID);

  return { data, loading: isLoading };
};
