import React, { useMemo } from 'react';
import clsx from 'clsx';
import style from './index.module.scss';
import { EmployeeAvatar } from '../../../employee-avatar';

interface Props {
  userEmployeeProfileID: string;
  userPhoto: string | null | undefined;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  isActivityLow: boolean;
}
export const EmployeeMonitorItemShort: React.FC<Props> = ({
  userEmployeeProfileID,
  userPhoto,
  firstName,
  lastName,
  isActivityLow,
}) => {
  const short = useMemo(() => {
    return [firstName, lastName]
      .map((word) => (word ? word[0] : '').toUpperCase())
      .filter(Boolean)
      .join(' ');
  }, [firstName, lastName]);

  return (
    <EmployeeAvatar
      userEmployeeProfileID={userEmployeeProfileID}
      className={clsx(style.root, {
        [style.low]: isActivityLow,
        [style.isImage]: Boolean(userPhoto),
      })}
      src={userPhoto || ''}
    >
      {short}
    </EmployeeAvatar>
  );
};
