import { createAsyncThunk } from '@reduxjs/toolkit';
import { PatientSmsMessageItem } from './slice';
import { AppAsyncThunkConfig } from 'store/store';
import { ServiceUserPatientProfile } from 'services/user-patient-profile';

interface MessageForm {
  toPhoneNumber: string;
  message: string;
}
export const actionPatientSmsMessagesSendMessage = createAsyncThunk<
  void,
  { item: PatientSmsMessageItem; messageForm: MessageForm },
  AppAsyncThunkConfig
>(`PATIENT_SMS_MESSAGES/sendMessage`, async (arg, { getState }) => {
  const { item, messageForm } = arg;

  try {
    if (!item) {
      throw new Error('unexpected-behaviour');
    }

    const input = {
      userPatientProfileID: String(item.formData.userPatientProfileID),
      toPhoneNumber: messageForm.toPhoneNumber,
      message: messageForm.message,
    };
    await ServiceUserPatientProfile.sendSms(input);

    if (item.onSuccess) {
      item.onSuccess(input);
    }
  } catch (e) {
    if (item && item.onError) {
      item.onError(e);
    }
    throw e;
  }
});
