import React from 'react';
import { Button, Paper } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { useFieldProps } from 'hooks';
import { SelectEmployee } from 'components/select-employee';
import { validateTaskEmployees } from '../../helpers';
import { Stack } from '../../../stack';
import { useTranslate } from 'hooks/use-translate';

export type FormModel = { userEmployeeProfileIDs: string[] };
interface Props {
  isLoading: boolean;
  onSave: () => void;
  onCancel: () => void;
}

export const FormAssignedTo: React.FC<Props> = ({ isLoading, onSave, onCancel }) => {
  const { t } = useTranslate();
  const { errors, control } = useFormContext<FormModel>();

  const getProps = useFieldProps({ errors, disabled: isLoading });

  return (
    <Paper style={{ width: '32rem', padding: '1.6rem' }}>
      <Controller
        control={control}
        name={'userEmployeeProfileIDs'}
        rules={{
          validate: validateTaskEmployees,
        }}
        render={(renderProps) => (
          <SelectEmployee {...getProps(renderProps)} multiple limitTags={1} />
        )}
      />
      <Stack spacing={1} justifyContent={'end'}>
        <Button variant={'outlined'} color={'primary'} onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button variant={'contained'} color={'primary'} disabled={isLoading} onClick={onSave}>
          {t('save')}
        </Button>
      </Stack>
    </Paper>
  );
};
