import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actionNotebookFormGeneratorGetNotebook } from './actions';
import { Notebook } from 'services/notebook';
import { NotebookFieldInput } from 'services/notebook-field-input';
import { apiSupportMeeting, SupportMeeting } from 'services/support-meetings';

type ReplaceSupportMeeting = Pick<
  SupportMeeting,
  'id' | 'meetingFromDateTime' | 'meetingToDateTime' | 'userEmployeeProfileID'
>;

export interface NotebookFormGeneratorSettings
  extends Pick<
    Required<Notebook>,
    | 'id'
    | 'labelKey'
    | 'displayNextMeetingForm'
    | 'displayIcd10Section'
    | 'displaySurgerySection'
    | 'displaySensitivitySection'
    | 'displayRoutineDrugSection'
    | 'displayPastDrugForObesitySection'
    | 'displaySensitivityDrugSection'
    | 'displayClinicDrugSection'
    | 'displaySideEffectSection'
    | 'isRequirePrescription'
  > {
  notebookFieldInputs: NotebookFieldInput[];
  userEmployeeProfilePermissionIDs: string[];
}

type NotebookState = 'form' | 'result';

export interface NotebookFormGeneratorNextMeetingData {
  date: string;
  start?: string | undefined;
  end?: string | undefined;
  includeMeetingTime: boolean;
  supportMeetingTypeID: string;
  userEmployeeProfileID: string;
  remarks?: string | null;
}

interface State {
  notebookSettings: NotebookFormGeneratorSettings | null;

  isLoading: boolean;
  isInit: boolean;

  state: NotebookState;
  previousState: null | NotebookState;

  userPatientProfileSessionID: string | null;

  nextSupportMeeting: null | NotebookFormGeneratorNextMeetingData;

  replaceSupportMeeting: null | ReplaceSupportMeeting;

  showCloneTooltip: boolean;
}

const initialState = (): State => ({
  notebookSettings: null,

  isLoading: false,
  isInit: false,

  state: 'form',
  previousState: null,

  userPatientProfileSessionID: null,

  nextSupportMeeting: null,

  replaceSupportMeeting: null,

  showCloneTooltip: false,
});

const slice = createSlice({
  name: 'NOTEBOOK_FORM_GENERATOR',
  initialState,
  reducers: {
    actionNotebookFormGeneratorReset() {
      return initialState();
    },
    actionNotebookFormGeneratorSetState(state, action: PayloadAction<'form' | 'result'>) {
      state.previousState = state.state;
      state.state = action.payload;
    },
    actionNotebookFormGeneratorSetSession(state, action: PayloadAction<string | null>) {
      state.userPatientProfileSessionID = action.payload;
    },
    actionNotebookFormGeneratorStoreNextMeetingData(
      state,
      action: PayloadAction<NotebookFormGeneratorNextMeetingData>,
    ) {
      state.nextSupportMeeting = action.payload;
    },

    actionNotebookFormGenerationSetReplaceMeeting(
      state,
      action: PayloadAction<ReplaceSupportMeeting>,
    ) {
      state.replaceSupportMeeting = action.payload;
    },

    actionNotebookFormGenerationSetShowCloneTooltip(state, action: PayloadAction<boolean>) {
      state.showCloneTooltip = action.payload;
    },
  },
  extraReducers(build) {
    build
      .addCase(actionNotebookFormGeneratorGetNotebook.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actionNotebookFormGeneratorGetNotebook.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isInit = true;
        state.notebookSettings = action.payload;
      })
      .addCase(actionNotebookFormGeneratorGetNotebook.rejected, (state, action) => {
        state.isLoading = false;
        state.isInit = true;
      });

    build
      .addMatcher(
        apiSupportMeeting.endpoints.setNextPatientFutureSupportMeetingFromExisting.matchPending,
        (state, action) => {
          state.isLoading = true;
        },
      )
      .addMatcher(
        apiSupportMeeting.endpoints.setNextPatientFutureSupportMeetingFromExisting.matchFulfilled,
        (state) => {
          state.isLoading = false;
        },
      )
      .addMatcher(
        apiSupportMeeting.endpoints.setNextPatientFutureSupportMeetingFromExisting.matchRejected,
        (state) => {
          state.isLoading = false;
        },
      );
  },
});
export const {
  actionNotebookFormGeneratorReset,
  actionNotebookFormGeneratorSetState,
  actionNotebookFormGeneratorSetSession,
  actionNotebookFormGeneratorStoreNextMeetingData,
  actionNotebookFormGenerationSetReplaceMeeting,
  actionNotebookFormGenerationSetShowCloneTooltip,
} = slice.actions;
export const notebookFormGeneratorReducer = slice.reducer;
