import React, { useCallback } from 'react';
import { GridControlsDropDownTemplateProps } from 'models/grid';
import { MenuItem } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { GridModel } from '../models';
import { useGridMedicalPrescriptions } from '../hooks';

interface Props extends GridControlsDropDownTemplateProps<GridModel> {}
export const ControlEdit: React.FC<Props> = ({ data, handleClose }) => {
  const { t } = useTranslate();
  const disabled = !Boolean(process.env.NODE_ENV === 'development' || !data?.isCompleted);
  const { onTasks } = useGridMedicalPrescriptions();

  const onClick = useCallback(() => {
    onTasks([['onHandle', { type: 'edit', id: data.id, data }]]);
    handleClose();
  }, [handleClose, data, onTasks]);

  return (
    <MenuItem onClick={onClick} disabled={disabled}>
      {t('edit')}
    </MenuItem>
  );
};
