import React from 'react';

import { Divider } from '@material-ui/core';
import style from './index.module.scss';
import VAR from 'styles/_variabels.scss';
import { PatientGridClinicalMeetings, PatientGridSupportMeetings } from '../../components';

interface Props {
  userPatientProfileID: string;
}

export const GridMeetings: React.FC<Props> = ({ userPatientProfileID }) => {
  return (
    <div className={style.root}>
      <PatientGridClinicalMeetings userPatientProfileID={userPatientProfileID} />
      <Divider
        orientation={'vertical'}
        style={{ backgroundColor: VAR.colorSecondary, opacity: 0.1 }}
      />
      <PatientGridSupportMeetings userPatientProfileID={userPatientProfileID} />
    </div>
  );
};
