import React, { memo, MouseEvent, useCallback, useState } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import {
  iTMCalendarSupportMeeting,
  TMMerge,
  TMMoveItem,
  TMRefresh,
} from 'pages/TaskManager/components/store';
import { useAppDispatch } from 'store';
import style from './index.module.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import { NotebookPrescription } from '../notebook-prescription';
import { usePermissions } from 'hooks/use-permissions';
import { useTranslate } from 'hooks/use-translate';
import { addDays, nextSunday } from 'date-fns';
import clsx from 'clsx';
import { useMountedRef } from 'hooks';
import { useOpen } from 'AurionCR/components/hooks';
import { DialogCancelMeetingContainer } from 'components/dialog-support-meeting';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {
  DialogMeetingActivityRemark,
  MeetingActivityModel,
} from 'components/dialog-meeting-activity-remark';

interface Props {
  className: string;
  data: iTMCalendarSupportMeeting;
  isCanceled?: boolean;
}
export const MeetingDropDropMenu = memo<Props>(({ className, data, isCanceled }) => {
  const { t, tp } = useTranslate();
  const mountedRef = useMountedRef();
  const dispatch = useAppDispatch();

  // state
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [date, setDate] = useState<null | Date>(null);

  const handleCancelMoveItem = useCallback(() => {
    setDate(null);
  }, [setDate]);

  const handleMoveItem = useCallback(
    (value: MeetingActivityModel) => {
      dispatch(TMMoveItem({ date: date as Date, data, ...value }));
      setDate(null);
    },
    [data, dispatch, date],
  );

  const handleOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);
  const handleEdit = useCallback(() => {
    dispatch(TMMerge({ editMeeting: { id: data.id } }));
    handleClose();
  }, [dispatch, data, handleClose]);
  const handleSendSms = useCallback(() => {
    dispatch(
      TMMerge({
        sendSms: {
          supportMeetingID: data.id,
          userPatientProfileID: data.userPatientProfileID,
          userEmployeeProfileFullName: data.userEmployeeProfileFullName,
          toPhoneNumber: data.userPatientProfile.mobilePhone || '',
        },
      }),
    );
    handleClose();
  }, [dispatch, data, handleClose]);
  const handleDelete = useCallback(() => {
    dispatch(TMMerge({ confirmDeleteMeeting: data }));
    handleClose();
  }, [data, handleClose, dispatch]);

  const onMoveToNextSunday = useCallback(() => {
    setDate(nextSunday(new Date()));
    handleClose();
  }, [handleClose]);

  const onMoveToToday = useCallback(() => {
    setDate(new Date());
    handleClose();
  }, [handleClose]);

  const onMoveToTomorrow = useCallback(() => {
    setDate(addDays(new Date(), 1));
    handleClose();
  }, [handleClose]);

  const stateCancel = useOpen();
  const onCancelDone = useCallback(async () => {
    if (mountedRef.current) {
      handleClose();
    }

    if (mountedRef.current) {
      dispatch(TMRefresh(true));
    }
  }, [dispatch, handleClose, mountedRef]);

  const isAllowToEdit = usePermissions('isAllowToEditTaskManager');
  const isAllowToDelete = usePermissions('isAllowToDeleteAnything');
  const isAllowToCancelMeeting = usePermissions('isAllowToCancelMeeting');

  return (
    <>
      <IconButton onClick={handleOpen} className={className}>
        <MoreVertIcon />
      </IconButton>
      {Boolean(anchorEl) && (
        <Menu
          anchorEl={anchorEl}
          open={true}
          style={{ transform: 'translateY(40px)' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={handleClose}
          disableAutoFocusItem
        >
          {isAllowToEdit && (
            <MenuItem onClick={handleEdit}>
              {t(data?.userPatientProfileSessionID ? 'preview' : 'edit')}
            </MenuItem>
          )}

          <MenuItem onClick={handleSendSms}>{t('send-sms')}</MenuItem>
          <NotebookPrescription
            data={data}
            handleClose={handleClose}
            isMenuItem={true}
            disabled={false}
          />
          <MenuItem onClick={onMoveToToday}>{t('move-to-today')}</MenuItem>
          <MenuItem onClick={onMoveToTomorrow}>{t('move-to-tomorrow')}</MenuItem>
          <MenuItem onClick={onMoveToNextSunday}>{t('move-to-next-sunday')}</MenuItem>
          {isAllowToCancelMeeting && isCanceled !== undefined && (
            <MenuItem
              onClick={stateCancel.handleOpen}
              disabled={stateCancel.isOpen}
              className={clsx(style.button, style.buttonClose)}
            >
              <RemoveCircleOutlineIcon className={style.icon} />
              {t('cancel-meeting')}
            </MenuItem>
          )}
          {isAllowToDelete && (
            <MenuItem onClick={handleDelete} className={clsx(style.button, style.buttonDelete)}>
              <DeleteIcon className={style.icon} />
              {t('delete')}
            </MenuItem>
          )}
        </Menu>
      )}
      {Boolean(date) && (
        <DialogMeetingActivityRemark
          isLoading={false}
          title={tp('support-meeting-update-reason-title')}
          description={tp('support-meeting-update-reason-description')}
          reason={'rescheduled'}
          onClose={handleCancelMoveItem}
          onSubmit={handleMoveItem}
        />
      )}
      {stateCancel.isOpen && (
        <DialogCancelMeetingContainer
          meetingID={data.id}
          supportMeetingTypeID={data.supportMeetingTypeID}
          userPatientProfileID={data.userPatientProfileID}
          onClose={stateCancel.handleClose}
          onDone={onCancelDone}
        />
      )}
    </>
  );
});
