import React from 'react';

import style from './index.module.scss';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { Loading } from 'AurionCR/components';
import { useTranslate } from 'hooks/use-translate';

interface Classes {
  root: string;
  frame: string;
  empty: string;
}

interface Props {
  className?: string;
  classes?: Partial<Classes>;
  title: string;
  pdf?: string;
  isLoading?: boolean;
}

export const PdfView: React.FC<Props> = ({ className, classes, isLoading = false, title, pdf }) => {
  const { t } = useTranslate();
  return (
    <div className={clsx(style.root, className, classes?.root)}>
      {pdf && (
        <iframe
          title={title}
          src={pdf}
          className={clsx(style.frame, classes?.frame)}
          frameBorder={0}
        />
      )}
      {!pdf && !isLoading && (
        <Typography
          color={'secondary'}
          variant={'h1'}
          component={'p'}
          className={clsx(style.empty, classes?.empty)}
        >
          {t('pdf-not-generated')}
        </Typography>
      )}
      <Loading active={isLoading} />
    </div>
  );
};
