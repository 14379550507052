import React, { memo } from 'react';

import { useFieldProps } from 'hooks';
import style from './index.module.scss';
import { Box, Typography } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { InputBrowse } from 'components/input-browse';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useTranslate } from 'hooks/use-translate';
import { Left } from 'utils/types';

type Model = Left<{ excelFile: string }>;
interface Props {
  isLoading: boolean;
}

export const MigrateFroExel = memo<Props>(({ isLoading }) => {
  const { tp } = useTranslate();
  const { control, errors } = useFormContext<Model>();
  const getInputProps = useFieldProps({ errors, emptyHelperText: '' });

  return (
    <div className={style.root}>
      <Box p={'1.2rem 1.6rem 0'}>
        <Typography color={'secondary'} style={{ fontWeight: 'bold' }}>
          {tp('migrate-from-excel')}
        </Typography>
        <Controller
          control={control}
          name={'excelFile'}
          render={(renderProps) => (
            <InputBrowse {...getInputProps(renderProps)} disabled={isLoading} />
          )}
        />
      </Box>
      <Alert severity="info">
        <AlertTitle>{tp('patient-excel-info-title')}</AlertTitle>
        {tp('patient-excel-info-description')}
      </Alert>
    </div>
  );
});
