import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { useTranslate } from 'hooks/use-translate';
import { NativeScroll } from 'components/native-scroll';
import { apiClinicalMeetings } from 'services/clinical-meetings';
import { useEffectError, useFieldProps } from 'hooks';
import { dateFormat } from 'utils/dates';
import style from './index.module.scss';
import clsx from 'clsx';
import { UserPatientProfileDocument } from 'services/user-patient-profile-documents';
import { MeetingList } from 'components/meetings-list';
import * as yup from 'yup';
import { useForm, useController, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppSelector } from 'store';
import { selectLanguageID } from 'store/languages';
import { apiPdf } from 'services/pdf';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { isMutationFulfilled } from 'utils/rtk-query';
import { apiPdfContent } from 'services/pdf-content';
import { DialogEditHtmlContent } from 'components/dialog-edit-html-content';
import { useUpdatePatientDataFromContent } from '../../../../hooks/use-update-patient-data-from-content';
import { AppSelect } from '../../../../../app-select';
import { useSourceBmiSummaries, useSourceDiagnosisTypes } from '../../../../../hooks';

const schema = yup.object({
  meeting: yup
    .object({
      id: yup.string().required(),
      date: yup.string().required('rule-required').default(''),
    })
    .nullable()
    .default(null),
  diagnosisTypeID: yup.string().required('rule-required').nullable().default(null),
  bmiSummaryID: yup.string().required('rule-required').nullable().default(null),
});

type FormData = yup.InferType<typeof schema>;

const useFetchClinicalMeetingsQuery =
  apiClinicalMeetings.useGetClinicalMeetingForEndOfTreatmentConsultationQuery;

const useGenerateMutation = apiPdf.useGenerateDocumentEndTreatmentConsultationMutation;
const useGenerateContentMutation = apiPdfContent.useGenerateContentEndTreatmentConsultationMutation;

const useLazyFetchPatientData =
  apiUserPatientProfile.useLazyGetPatientDetailsForPdfSummaryOfConsultationQuery;

type StateApply = { content: string; title: string };
interface DialogCreateContentProps {
  userPatientProfileID: string;
  price: number | null;
  onClose: () => void;
  onApply: (state: StateApply) => void;
}

const DialogCreateContent: React.FC<DialogCreateContentProps> = ({
  userPatientProfileID,
  onApply,
  onClose,
  price,
}) => {
  const [updateUser, resultUpdateUser, patient] = useUpdatePatientDataFromContent({
    userPatientProfileID,
  });
  useEffectError(resultUpdateUser.error);

  const { tp, t } = useTranslate();

  const languageID = useAppSelector(selectLanguageID);

  const [generateContent, resultContent] = useGenerateContentMutation();
  useEffectError(resultContent.error);

  const { data = [], isFetching, error } = useFetchClinicalMeetingsQuery(userPatientProfileID);
  useEffectError(error);

  const { control, handleSubmit, errors, setValue } = useForm({
    defaultValues: schema.cast({}),
    resolver: yupResolver(schema),
  });
  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

  const refInit = useRef(false);
  useEffect(() => {
    if (refInit.current) return;
    if (!patient) return;

    // issue with the set form
    requestAnimationFrame(() => {
      setValue('bmiSummaryID', patient.bmiSummaryID);
      setValue('diagnosisTypeID', patient.diagnosisTypeID);
    });

    refInit.current = true;
  }, [patient, setValue]);

  const controlMeeting = useController({ control, name: 'meeting' });
  const [fetchPatientData, resultPatientData] = useLazyFetchPatientData();
  useEffectError(resultPatientData.error);

  const onSubmit = useCallback(
    async (formData: FormData) => {
      const resUpdate = await updateUser({
        diagnosisTypeID: formData.diagnosisTypeID,
        bmiSummaryID: formData.bmiSummaryID,
      });

      if (!isMutationFulfilled(resUpdate)) return;

      const resPatient = await fetchPatientData(userPatientProfileID);

      if (!resPatient.data) return;

      const res = await generateContent({
        languageID,
        payload: {
          patient: resPatient.data,
          date: formData.meeting?.date || new Date().toISOString(),
          price: price,
        },
      });

      if (isMutationFulfilled(res)) {
        onApply(res.data);
      }
    },
    [
      generateContent,
      onApply,
      userPatientProfileID,
      languageID,
      fetchPatientData,
      price,
      updateUser,
    ],
  );

  const list = useMemo(() => {
    return data.map((meeting) => {
      return {
        meetingFromDateTime: String(meeting.meetingFromDateTime),
        meetingToDateTime: String(meeting.meetingToDateTime),
        employee: meeting.employee,
        type: meeting.subject,
        id: String(meeting.id),
      };
    });
  }, [data]);

  const sourceDiagnosisTypes = useSourceDiagnosisTypes();
  const sourceBmiSummaries = useSourceBmiSummaries();

  const isLoading = isFetching || resultContent.isLoading || resultPatientData.isLoading;

  const onChange = (id: string | undefined | null) => {
    const meeting = list.find((meeting) => meeting.id === id);
    if (!meeting) return;

    controlMeeting.field.onChange({ id: meeting.id, date: meeting.meetingFromDateTime });
  };

  const onToday = () => {
    controlMeeting.field.onChange({ id: 'today', date: new Date().toISOString() });
  };

  const value = controlMeeting.field.value?.id;

  return (
    <Dialog fullWidth maxWidth={'sm'} open={true} onClose={onClose}>
      <DialogHeading
        isLoading={isLoading}
        title={t('end-treatment-consultation-dialog-title')}
        onClose={onClose}
      />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={'diagnosisTypeID'}
              render={(renderProps) => {
                const props = getFieldProps(renderProps);
                return (
                  <AppSelect
                    {...props}
                    options={sourceDiagnosisTypes.data}
                    loading={sourceDiagnosisTypes.loading}
                    disabled={isLoading || !!patient?.diagnosisTypeID}
                    disableClearable
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={'bmiSummaryID'}
              render={(renderProps) => {
                const props = getFieldProps(renderProps);
                return (
                  <AppSelect
                    {...props}
                    options={sourceBmiSummaries.data}
                    loading={sourceBmiSummaries.loading}
                    disabled={isLoading || !!patient?.bmiSummaryID}
                    disableClearable
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Typography
          component={'h3'}
          style={{ fontWeight: 'bold', marginBottom: '0.3rem', marginTop: '1.6rem' }}
          color={'secondary'}
        >
          {t('select-meeting')}
        </Typography>
        <NativeScroll>
          <ListItem
            button
            onClick={onToday}
            className={clsx(style.item)}
            disabled={isLoading}
            selected={value === 'today'}
          >
            <ListItemText
              secondary={t('today')}
              secondaryTypographyProps={{
                color: 'textSecondary',
              }}
              primary={dateFormat(new Date())}
              primaryTypographyProps={{
                style: {
                  fontWeight: 'bold',
                },
              }}
            />
          </ListItem>
          <MeetingList
            value={value}
            className={style.list}
            multiple={false}
            options={list}
            disabled={isLoading}
            onChange={onChange}
          />
        </NativeScroll>
      </DialogContent>
      <DialogActions>
        <Button
          color={'primary'}
          variant={'contained'}
          disabled={isLoading || !controlMeeting.field.value}
          onClick={handleSubmit(onSubmit)}
        >
          {tp('apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface Props {
  isLoading: boolean;
  userPatientProfileID: string;
  onClose: () => void;
  onDone: (doc: UserPatientProfileDocument) => void;
  price: number | null;
}

export const DialogEndTreatmentConsultation: React.FC<Props> = ({
  userPatientProfileID,
  onDone,
  onClose,
  isLoading: isLoadingOuter,
  price,
}) => {
  const [state, setState] = useState<StateApply>();

  const [generate, resultGenerate] = useGenerateMutation();
  useEffectError(resultGenerate.error);

  const onGenerate = useCallback(
    async (content: string) => {
      if (!state) return;

      const res = await generate({ content, title: state.title, userPatientProfileID });

      if (isMutationFulfilled(res)) {
        onDone(res.data);
      }
    },
    [state, userPatientProfileID, onDone, generate],
  );

  const isLoading = isLoadingOuter || resultGenerate.isLoading;

  if (!state) {
    return (
      <DialogCreateContent
        userPatientProfileID={userPatientProfileID}
        onClose={onClose}
        onApply={setState}
        price={price}
      />
    );
  }

  return (
    <DialogEditHtmlContent
      isLoading={isLoading}
      title={state.title}
      content={state.content}
      onGenerate={onGenerate}
      onCancel={onClose}
    />
  );
};
