import React, { forwardRef, memo, useCallback, useImperativeHandle } from 'react';
import { validateRule } from 'AurionCR/components/formV2';
import { fieldValueDefault, iField, iFieldRefProps } from './@type';
import { useTranslate } from 'hooks/use-translate';
import { Controller, useFormContext } from 'react-hook-form';
import { AppDatePicker } from 'components/app-date-picker';
import { useFieldProps } from 'hooks';
import { composeFunctions } from 'utils';
import { dateFormat } from 'utils/dates';

export const FieldDate = memo(
  forwardRef<iFieldRefProps, iField>(({ item, disabled = false, onWasChange }, ref) => {
    const { t } = useTranslate();

    const name = item.fieldInputID as string;
    const labelKey = item.fieldInput?.labelKey;

    const { getValues, control, errors } = useFormContext();
    const getValue = useCallback(() => {
      const value = getValues(name);
      return {
        ...fieldValueDefault(),
        id: item.formID || '',
        fieldInputID: item.fieldInputID as string,
        entryValueDate: value || null,
        entryValueString: value ? dateFormat(value, 'dd.MM.yyyy') : '',

        formValue: value,
        notebookFieldInputID: item.id,
      };
    }, [getValues, name, item]);
    useImperativeHandle(ref, () => ({ getValue }), [getValue]);

    const getFieldProps = useFieldProps({ errors });

    return (
      <div>
        <Controller
          name={name}
          control={control}
          rules={validateRule(`dateLike,${item?.isRequired ? 'required' : ''}`)}
          render={(renderProps) => {
            const { onBlur, ...rest } = getFieldProps(renderProps);
            return (
              <AppDatePicker
                {...rest}
                label={labelKey ? t(labelKey) : undefined}
                onBlur={composeFunctions(onBlur, onWasChange)}
                disabled={disabled}
              />
            );
          }}
        />
      </div>
    );
  }),
);
