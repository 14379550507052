import React, { memo, useCallback, useMemo, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { getRandomString, useI18n } from 'AurionCR/components';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { iFormInputField } from './index';
import style from './index.module.scss';
import clsx from 'clsx';
import { FormDocumentFieldType } from 'services/form-document-inputs';
import { PdfFormDocumentInput } from '../../../store';

const options = ['6', '7', '8', '9', '10', '11', '12', '14', '18', '24', '36'];
export const FieldFontSize = memo(
  ({
    value,
    formDocumentFieldType,
    onChange: OnChange,
  }: iFormInputField<'fontSize'> & { formDocumentFieldType: FormDocumentFieldType }) => {
    const { t } = useI18n();
    // state
    const [fieldID] = useState(`fontSize${getRandomString()}`);
    const [open, setOpen] = useState(false);
    const valueSelect = useMemo(() => {
      const index = options.indexOf(String(value));
      return index !== -1 ? options[index] : '';
    }, [value]);
    // handlers
    const onChange = useCallback(
      (e) => {
        const isCheckBox = formDocumentFieldType === FormDocumentFieldType.checkbox;
        const props: Partial<PdfFormDocumentInput> & { fontSize: number } = {
          fontSize: Number(e.target.value || 1),
        };
        if (isCheckBox) {
          props.width = Math.ceil(props.fontSize * 1.2);
          props.height = Math.ceil(props.fontSize * 1.2);
        }
        OnChange(props, isCheckBox);
      },
      [OnChange, formDocumentFieldType],
    );
    const onChangeSelect = useCallback(
      (e, value) => {
        onChange({ target: { value: Number(value) } });
        setOpen(false);
      },
      [setOpen, onChange],
    );
    const onOpen = useCallback(() => {
      setOpen((state) => !state);
    }, [setOpen]);
    // //render
    return (
      <div className={clsx(style.labelInput, style.fieldFontSize)}>
        <label htmlFor={fieldID} className={style.label}>
          {t('font-size')}:
        </label>
        <div className={clsx(style.input, style.fontSize)}>
          <Autocomplete
            className={style.select}
            open={open}
            options={options}
            value={valueSelect}
            onChange={onChangeSelect}
            disabled={true}
            renderInput={(params) => (
              <TextField {...params} className={style.input} size="small" variant="outlined" />
            )}
          />
          <TextField
            className={style.input}
            id={fieldID}
            size={'small'}
            variant="outlined"
            value={value || 6}
            onChange={onChange}
            inputProps={{ min: 1, max: 100, type: 'number' }}
            onFocus={() => setOpen(true)}
            onBlur={() => setOpen(false)}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size={'small'} onClick={onOpen}>
                    {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    );
  },
);

export default FieldFontSize;
