import React, { memo, useCallback, useMemo, useRef } from 'react';
import { DialogHeading } from 'components/dialog-title';
import { Button, ButtonProps, Dialog, DialogContent, Tooltip, Typography } from '@material-ui/core';
import { UserPatientProfile } from 'services/user-patient-profile';
import { RowField } from '../../../row-field';
import { ControllerRenderProps, useController, useFormContext } from 'react-hook-form';
import { differenceInYears } from 'date-fns';
import { convertToDate } from 'utils/dates';
import EditIcon from '@material-ui/icons/Edit';
import { useOpen } from 'AurionCR/components/hooks';
import { Left } from 'utils/types';
import { useTranslate } from 'hooks/use-translate';
import { AppInput } from 'components/app-input';
import { useFieldProps } from 'hooks';
import { AppPhoneInput } from 'components/app-phone-input';
import { composeFunctions } from 'utils';
import WarningIcon from '@material-ui/icons/Warning';
import style from './index.module.scss';
import clsx from 'clsx';
import { useFactoryChangeInput, usePatientData } from '../../hooks';
import { getPatientFieldsMaxLength } from '../../helpers';
import { PatientFormModel } from '../../models';

interface PreviewItemProps {
  label: React.ReactNode;
  children: React.ReactNode;
}
const PreviewRowItem: React.FC<PreviewItemProps> = ({ label, children }) => {
  return (
    <tr>
      <td>
        <Typography>{label}:</Typography>
      </td>
      <td>
        <Typography>{children}</Typography>
      </td>
    </tr>
  );
};

type Model = Pick<
  Left<UserPatientProfile>,
  | 'familyMemberFirstName'
  | 'familyMemberLastName'
  | 'familyMemberPhone'
  | 'familyMemberIDNumber'
  | 'familyMemberRelationship'
  | 'dateOfBirth'
>;

const TooltipTitle: React.FC = () => {
  const { t } = useTranslate();
  const { watch } = useFormContext<Model>();
  const familyMemberFirstName = watch('familyMemberFirstName');
  const familyMemberLastName = watch('familyMemberLastName');
  const familyMemberPhone = watch('familyMemberPhone');
  const familyMemberIDNumber = watch('familyMemberIDNumber');
  const familyMemberRelationship = watch('familyMemberRelationship');

  return (
    <table>
      <tbody>
        <PreviewRowItem label={t('first-name')}>{familyMemberFirstName}</PreviewRowItem>
        <PreviewRowItem label={t('last-name')}>{familyMemberLastName}</PreviewRowItem>
        <PreviewRowItem label={t('phone')}>{familyMemberPhone}</PreviewRowItem>
        <PreviewRowItem label={t('id-number')}>{familyMemberIDNumber}</PreviewRowItem>
        <PreviewRowItem label={t('relationship')}>{familyMemberRelationship}</PreviewRowItem>
      </tbody>
    </table>
  );
};

const FIELDS: (keyof Model)[] = [
  'familyMemberFirstName',
  'familyMemberLastName',
  'familyMemberPhone',
  'familyMemberIDNumber',
  'familyMemberRelationship',
];

interface Props extends ButtonProps {
  debounce: boolean;
}

export const ButtonFieldMember = memo<Props>(({ debounce, ...buttonProps }) => {
  const { control, errors, watch, getValues, trigger } = useFormContext<Model>();
  const { onUpdateDebounce, onUpdate, patient } = usePatientData();
  const { t } = useTranslate();

  const factoryChangeInput = useFactoryChangeInput({
    onUpdate: debounce ? onUpdateDebounce : onUpdate,
    initData: patient,
    getValues,
    validate: trigger,
  });

  const dateOfBirth = watch('dateOfBirth');

  const { isOpen, handleOpen, handleClose } = useOpen();

  const isDisabled = useMemo(() => {
    if (!dateOfBirth) {
      return true;
    }
    return differenceInYears(new Date(), convertToDate(dateOfBirth)) > 17;
  }, [dateOfBirth]);

  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });
  const renderInput = (renderProps: ControllerRenderProps<Model>) => {
    const { label, ...rest } = getFieldProps(renderProps);
    const maxLength = getPatientFieldsMaxLength(renderProps.name as keyof PatientFormModel);
    return (
      <RowField label={label}>
        <AppInput
          {...rest}
          inputProps={{
            maxLength,
          }}
          onBlur={composeFunctions(rest.onBlur, factoryChangeInput(rest.name, label))}
        />
      </RowField>
    );
  };

  const ref = useRef<HTMLButtonElement>(null);

  const onFocus = useCallback(() => {
    const el = ref.current;
    if (!el) return;
    el.focus();
  }, [ref]);

  const controllerFirstName = useController<Model>({
    name: 'familyMemberFirstName',
    control,
    onFocus,
  });
  const controllerLastName = useController<Model>({
    name: 'familyMemberLastName',
    control,
    onFocus,
  });
  const controllerMemberPhone = useController<Model>({
    name: 'familyMemberPhone',
    control,
    onFocus,
  });

  const controllerIdNumber = useController<Model>({
    name: 'familyMemberIDNumber',
    control,
    onFocus,
  });
  const controllerRelationship = useController<Model>({
    name: 'familyMemberRelationship',
    control,
    onFocus,
  });

  const hasError = FIELDS.some((field) => {
    return Boolean(errors[field]);
  });

  return (
    <>
      <Tooltip title={<TooltipTitle />} arrow>
        <span>
          <Button
            ref={ref}
            variant={'contained'}
            color={hasError ? undefined : 'primary'}
            size={'small'}
            startIcon={hasError ? <WarningIcon /> : <EditIcon />}
            {...buttonProps}
            className={clsx(style.button, hasError && style.buttonError)}
            onClick={handleOpen}
            disabled={isDisabled}
          >
            {t('edit-family-member')}
          </Button>
        </span>
      </Tooltip>

      <Dialog open={isOpen} fullWidth maxWidth={'md'} onClose={handleClose}>
        <DialogHeading title={t('family-member')} onClose={handleClose} />
        <DialogContent>
          {renderInput(controllerFirstName.field)}
          {renderInput(controllerLastName.field)}
          <RowField label={t('family-member-phone')}>
            <AppPhoneInput
              {...getFieldProps(controllerMemberPhone.field)}
              label={null}
              onBlur={composeFunctions(
                controllerMemberPhone.field.onBlur,
                factoryChangeInput(controllerMemberPhone.field.name, t('family-member-phone')),
              )}
            />
          </RowField>
          {renderInput(controllerIdNumber.field)}
          {renderInput(controllerRelationship.field)}
        </DialogContent>
      </Dialog>
    </>
  );
});
