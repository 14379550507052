import { FormDocumentFieldInputID } from 'services/form-document-inputs';
import React, { useMemo } from 'react';
import { useTranslate } from 'hooks/use-translate';
import { apiFormDocuments, ServiceFormDocument } from 'services/form-documents';
import { useEffectError } from 'hooks';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { DialogValidity } from 'components/dialog-validity';

const excludeFiledInputsFromChecking = [
  FormDocumentFieldInputID.signature,
  FormDocumentFieldInputID.checkBox,
];

interface Props {
  title: string;
  formDocumentID: string;
  userPatientProfileID: string;
  onSubmit: () => void;
  onClose: () => void;
}

export const DialogValidityContainer: React.FC<Props> = ({
  title,
  formDocumentID,
  userPatientProfileID,
  onSubmit,
  onClose,
}) => {
  const { t, tp } = useTranslate();

  const resultFormDocument =
    apiFormDocuments.useGetFormDocumentInputsForValidationTemplateQuery(formDocumentID);
  useEffectError(resultFormDocument.error);

  const resultPatient = apiUserPatientProfile.useGetPatientDetailsForDocumentSignQuery(
    userPatientProfileID,
    {
      refetchOnMountOrArgChange: true,
    },
  );
  useEffectError(resultPatient.error);

  const fieldsList = useMemo(() => {
    if (!resultPatient.data) {
      return [];
    }
    if (!resultFormDocument.data) {
      return [];
    }
    const userInfo = ServiceFormDocument.adapterDocumentSign(resultPatient.data);

    const list = ServiceFormDocument.prepareDocumentFieldInputs(
      resultFormDocument.data.formDocumentInputs || [],
      userInfo,
    ).filter((fieldInput) => {
      const shouldBeExcluded = excludeFiledInputsFromChecking.some(
        (inputID) => inputID === fieldInput.inputID,
      );

      return !shouldBeExcluded;
    });

    return list.map((input) => {
      const isValid =
        input.defaultValue !== null &&
        input.defaultValue !== undefined &&
        input.defaultValue !== '';

      return {
        title: input.title,
        value: input.defaultValue,
        isValid,
      };
    });
  }, [resultPatient.data, resultFormDocument.data]);

  const dialogTitle = useMemo(() => {
    if (resultFormDocument.isFetching) {
      return t('checking-document-data');
    }
    if (resultPatient.isFetching) {
      return t('checking-patient-data');
    }
    return title;
  }, [resultFormDocument.isFetching, resultPatient.isFetching, t, title]);

  const isChecking = resultFormDocument.isFetching || resultPatient.isFetching;
  const isSuccess = resultFormDocument.isSuccess || resultPatient.isSuccess;

  return (
    <DialogValidity
      title={dialogTitle}
      list={fieldsList}
      success={{
        title: t('document-verified-success-title'),
        description: tp('confirm-create-template-title-message', { title }),
      }}
      error={{
        title: t('document-verified-error-title'),
        description: t('document-verified-error-description'),
      }}
      isLoading={isChecking}
      isLoadedSuccess={isSuccess}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};
