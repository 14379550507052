import React, { useCallback } from 'react';
import { DialogCancelMeeting, FormModel } from 'components/dialog-cancel-meeting';
import { apiClinicalMeetings } from 'services/clinical-meetings';
import { useEffectError } from 'hooks';
import { isMutationFulfilled } from 'utils/rtk-query';

const useCancelMutation = apiClinicalMeetings.useCancelClinicalMeetingMutation;
interface Props {
  meetingID: string;
  clinicalMeetingSubjectID: string;
  onClose: () => void;
  onDone: () => void;
}
export const DialogCancelMeetingContainer: React.FC<Props> = ({
  meetingID,
  clinicalMeetingSubjectID,
  onClose,
  onDone,
}) => {
  const [triggerCancel, resultCancel] = useCancelMutation();
  useEffectError(resultCancel.error);

  const onSubmit = useCallback(
    async (formData: FormModel) => {
      const res = await triggerCancel({
        id: formData.meetingID,
        remarks: formData.remarks,
        statusID: formData.remarkForPatientCallStatusID,
        clinicalMeetingSubjectID: clinicalMeetingSubjectID,
      });

      if (isMutationFulfilled(res)) {
        onDone();
        onClose();
      }
    },
    [clinicalMeetingSubjectID, triggerCancel, onDone, onClose],
  );

  return (
    <DialogCancelMeeting
      meetingID={meetingID}
      isLoading={resultCancel.isLoading}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};
