import React, { memo, useCallback, useMemo, useState } from 'react';
import { getRandomString, useI18n } from 'AurionCR/components';
import { ColorPicker, createColor, Color } from 'material-ui-color';
import { iFormInputField } from './index';
import style from './index.module.scss';
import { InputAdornment, TextField } from '@material-ui/core';
import clsx from 'clsx';

export const FieldBackground = memo(
  ({ value: Value, onChange: OnChange }: iFormInputField<'background'>) => {
    const { t } = useI18n();
    // state
    const [fieldID] = useState(`inputID_${getRandomString()}`);
    const value = useMemo(() => createColor(Value || '#'), [Value]);
    // handlers
    const onChange = useCallback(
      (value: Color) => {
        OnChange({ background: value.css.backgroundColor || '' });
      },
      [OnChange],
    );
    const onChangeInput = useCallback(
      (e) => {
        OnChange({ background: e.target.value || '' });
      },
      [OnChange],
    );
    //render
    return (
      <div className={clsx(style.labelInput, style.fieldIsBackground)}>
        <div className={style.background}>
          <TextField
            id={fieldID}
            className={style.input}
            size={'small'}
            variant="outlined"
            placeholder={t('background')}
            value={Value || ''}
            onChange={onChangeInput}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ColorPicker value={value} onChange={onChange} hideTextfield />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    );
  },
);

export default FieldBackground;
