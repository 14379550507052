import {DependencyList, useCallback, useEffect} from "react";

export const useEffectDebounce = <T extends (...args: any[]) => any>(effect: T, delay: number = 0, deps: DependencyList) => {
  //eslint-disable-next-line
  const callback = useCallback(effect, deps)

  useEffect(() => {
    const handle = setTimeout(() => {
      callback();
    }, delay)

    return () => {
      clearTimeout(handle)
    }
  }, [callback, delay])
}
