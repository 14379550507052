import { DynamicService } from 'utils/service';

import {
  API_USER_PATIENT_PROFILE_SESSIONS_ROUTINE_DRUGS,
  CreateBulkUserPatientProfileSessionRoutineDrugInput,
  UserPatientProfileSessionRoutineDrug,
} from './models';

export * from './models';

class Service extends DynamicService<UserPatientProfileSessionRoutineDrug> {
  createBulk = (input: CreateBulkUserPatientProfileSessionRoutineDrugInput) => {
    return this.engine.post(API_USER_PATIENT_PROFILE_SESSIONS_ROUTINE_DRUGS.POST_BULK, {
      mainID: input.userPatientProfileSessionID,
      itemIDs: input.routineDrugIDs,
    });
  };
}

export const ServiceUserPatientProfileSessionsRoutineDrugs = new Service({
  mainField: 'id',
  getAll: API_USER_PATIENT_PROFILE_SESSIONS_ROUTINE_DRUGS.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_SESSIONS_ROUTINE_DRUGS.POST,
  delete: API_USER_PATIENT_PROFILE_SESSIONS_ROUTINE_DRUGS.DELETE,
  patch: API_USER_PATIENT_PROFILE_SESSIONS_ROUTINE_DRUGS.PATCH,
});
