import React, { memo } from 'react';
import { Avatar, AvatarProps, Badge, BadgeProps } from '@material-ui/core';
import clsx from 'clsx';
import style from './index.module.scss';

interface AvatarOnlineProps extends AvatarProps {
  isOnline: boolean;
  BadgeProps?: Partial<BadgeProps>;
}

export const AvatarOnline = memo<AvatarOnlineProps>(({ BadgeProps, isOnline, ...props }) => {
  return (
    <Badge
      badgeContent={isOnline ? 1 : 0}
      variant="dot"
      overlap="circular"
      {...BadgeProps}
      classes={{
        ...BadgeProps?.classes,
        badge: clsx(BadgeProps?.classes?.badge, style.badge),
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
        ...BadgeProps?.anchorOrigin,
      }}
    >
      <Avatar {...props} />
    </Badge>
  );
});
