import React from 'react';
import { Stack, StackProps } from 'components/stack';
import { Button, Divider, Typography } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';

interface Props extends Omit<StackProps, 'title'> {
  title?: React.ReactNode;
  onClose?: () => void;
  disabled?: boolean;
}

export const EditorTitle: React.FC<Props> = ({ onClose, title, disabled, children, ...rest }) => {
  const { t } = useTranslate();
  return (
    <Stack spacing={2} style={{ flexGrow: 1 }} alignItems={'center'} {...rest}>
      {title !== undefined && (
        <Typography variant="h1" style={{ flexGrow: 1 }}>
          {title}
        </Typography>
      )}

      {children}
      {onClose && (
        <Button
          style={{ minWidth: 120, minHeight: 30 }}
          onClick={onClose}
          variant="contained"
          color="secondary"
          size={'small'}
          disabled={Boolean(disabled)}
        >
          {t('close')}
        </Button>
      )}
      <Divider orientation={'vertical'} flexItem style={{ height: 64 }} />
    </Stack>
  );
};
