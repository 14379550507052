import React, { useCallback } from 'react';
import { GridControlsDropDownTemplateProps } from 'models/grid';
import { CustomHandler, GridModel } from '../../models';
import { MenuItem } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { useGridDocumentTasks } from '../../hooks';
import { composeFunctions } from 'utils';

interface Props extends GridControlsDropDownTemplateProps<GridModel> {}
export const ControlSendToPatient: React.FC<Props> = ({ data, handleClose }) => {
  const { tp } = useTranslate();

  const { onTasks } = useGridDocumentTasks();

  const onClick = useCallback(() => {
    onTasks([['onHandle', { type: CustomHandler.SEND_TO_PATIENT, data }]]);
  }, [onTasks, data]);

  if (data.isForm29) {
    return null;
  }

  return (
    <MenuItem onClick={composeFunctions(onClick, handleClose)} disabled={!data.documentURL}>
      {tp('send-to-patient')}
    </MenuItem>
  );
};
