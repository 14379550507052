import React, { memo, useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { format } from 'date-fns';
import { NotebookFieldInput } from 'services/notebook-field-input';
import { uniqBy } from 'lodash-es';
import { useI18n } from 'AurionCR/components';
import style from './index.module.scss';
import { APP_FORMAT_DATE } from 'configs/const';

interface TransactionItemTypeModified extends Partial<NotebookFieldInput> {
  activityType: string | null;
  weight: number;
  entryDate: string;
  transactionType: string | null;
  externalSystemValue: string | null;
}

const makeSeries = (
  selectedFilters: TransactionItemTypeModified[],
  data: TransactionItemTypeModified[],
) => {
  return selectedFilters.map((filter) => ({
    name: filter.activityType,
    data: data
      .filter((item) => item.activityType === filter.activityType)
      .map((item) => ({ x: new Date(item.entryDate).getTime(), y: item.weight, item })),
  }));
};

interface Props {
  isLoading: boolean;
  weights: TransactionItemTypeModified[];
}

export const ChartBMI = memo<Props>(({ weights: data, isLoading }) => {
  const { t } = useI18n();
  const [filters, setFilters] = useState<TransactionItemTypeModified[]>([]);

  useEffect(() => {
    const newFilters = uniqBy(data, 'activityType');
    setFilters(newFilters);
  }, [data]);

  const chartOptions = useMemo<any>(() => {
    if (!isLoading && data) {
      const series = makeSeries(filters, data);
      return {
        series,
        options: {
          chart: {
            zoom: {
              enabled: true,
              type: 'x',
              zoomedArea: {
                fill: {
                  color: '#90CAF9',
                  opacity: 0.4,
                },
                stroke: {
                  color: '#0D47A1',
                  opacity: 0.4,
                  width: 1,
                },
              },
            },
            animations: {
              animateGradually: {
                enabled: false,
                delay: 150,
              },
            },
            defaultLocale: 'en',
            locales: [
              {
                name: 'en',
                options: {
                  months: [
                    t('january'),
                    t('february'),
                    t('march'),
                    t('april'),
                    t('may'),
                    t('june'),
                    t('july'),
                    t('august'),
                    t('september'),
                    t('october'),
                    t('november'),
                    t('december'),
                  ],
                  shortMonths: [
                    t('jan'),
                    t('feb'),
                    t('mar'),
                    t('apr'),
                    t('may'),
                    t('jun'),
                    t('jul'),
                    t('aug'),
                    t('sep'),
                    t('oct'),
                    t('nov'),
                    t('dec'),
                  ],
                  days: [
                    t('sunday'),
                    t('monday'),
                    t('tuesday'),
                    t('wednesday'),
                    t('thursday'),
                    t('friday'),
                    t('saturday'),
                  ],
                  shortDays: [t('sun'), t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat')],
                  toolbar: {
                    download: 'Download SVG', // Not working
                    selection: t('selection'),
                    selectionZoom: t('selection-zoom'),
                    zoomIn: t('zoom-in'),
                    zoomOut: t('zoom-out'),
                    pan: t('panning'),
                    reset: t('reset-zoom'),
                  },
                },
              },
            ],
          },
          stroke: {
            curve: 'smooth',
          },
          markers: {
            size: 5,
          },
          xaxis: {
            type: 'datetime',
            labels: {
              formatter: (date: number) => format(new Date(date), APP_FORMAT_DATE),
            },
          },
          yaxis: {
            labels: {
              style: {
                cssClass: style.label,
              },
            },
          },
          tooltip: {
            x: {
              show: false,
              formatter: (date: number) => format(new Date(date), `${APP_FORMAT_DATE} | hh.mm`),
            },
            custom: ({ seriesIndex, dataPointIndex }: any) => {
              const item = series[seriesIndex].data[dataPointIndex].item;
              return `<div class="chart-pie-tooltip">
                          <div class="title">${item.transactionType}</div>
                          <div>${format(
                            new Date(item.entryDate),
                            `${APP_FORMAT_DATE} | hh.mm`,
                          )}</div>
                          <div class="bmi">${t('bmi')}: ${item.weight}</div>
                        </div>`;
            },
          },
          dataLabels: { enabled: false },
          legend: { horizontalAlign: 'left' },
        },
      };
    } else {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data, filters]);

  return (
    <div className={style.root}>
      {chartOptions && (
        <Chart
          series={chartOptions.series}
          options={chartOptions.options}
          type="line"
          height={270}
        />
      )}
    </div>
  );
});
