import SearchIcon from '@material-ui/icons/Search';
import { Theme, Typography, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';

interface Props {
  className?: string;
  title?: React.ReactNode | string;
}

export const DataGridEmpty: React.FC<Props> = ({ className, title }) => {
  const { t } = useTranslate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const TitleComponent = title || t('no-data');
  return (
    <div className={clsx(style.wrap, className)}>
      <div className={style.data}>
        <SearchIcon className={style.icon} />
        <Typography variant={isMobile ? 'h4' : 'h3'} component={'div'} className={style.text}>
          {TitleComponent}
        </Typography>
      </div>
    </div>
  );
};
