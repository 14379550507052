import clsx from 'clsx';
import { DataGridRow } from '../data-grid-row';
import { useDataGridLightSettings } from '../../hooks';
import { DataGridLightColumn, DataGridLightRowProps } from '../../models';
import React, { memo } from 'react';
import style from './index.module.scss';

interface Props {
  columns: DataGridLightColumn<any, any>[];
  rows: any[];
  rowProps?: DataGridLightRowProps<any>;
  rowKey?: any;
}

export const DataGridLightTable = memo<Props>(({ columns, rowProps, rows, rowKey }) => {
  const { classes } = useDataGridLightSettings();
  return (
    <table className={clsx(style.table, classes.table)}>
      <thead>
        <tr className={clsx(style.rowHead, classes.rowHead)}>
          {columns.map((column) => {
            return (
              <th
                key={column.id}
                {...column.thProps}
                className={clsx(style.cellHead, classes.cellHead, column.thProps?.className, {
                  [style.sticky]: column.stickyLeft || column.stickyRight,
                  [style.stickyLeft]: column.stickyLeft,
                  [style.stickyRight]: column.stickyRight,
                })}
              >
                {column.renderHeader({ field: column.field })}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => {
          const key = rowKey ? row[rowKey] : i;
          return <DataGridRow key={key} row={row} rowProps={rowProps} columns={columns} />;
        })}
      </tbody>
    </table>
  );
});
