import React, { memo, useCallback } from 'react';
import { INotebookPrescription } from './index';
import Wrapper from './wrapper';
import style from './index.module.scss';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { useAppDispatch } from 'store';
import { TMMerge } from '../../store';
import { useTranslate } from 'hooks/use-translate';

interface Props extends INotebookPrescription {}

export const Prescription = memo<Props>(
  ({ data, isMenuItem, isGridItem, handleClose, disabled }) => {
    const { t } = useTranslate();
    const dispatch = useAppDispatch();

    const onClick = useCallback(() => {
      dispatch(TMMerge({ dialogPrescription: data }));
      if (isMenuItem && handleClose) handleClose();
    }, [dispatch, data, isMenuItem, handleClose]);

    return (
      <Wrapper
        isMenuItem={isMenuItem}
        isGridItem={isGridItem}
        onClick={onClick}
        disabled={disabled}
      >
        <div className={style.prescription}>
          <NoteAddIcon className={style.icon} />
          {t('create-renewal')}
        </div>
      </Wrapper>
    );
  },
);

export default Prescription;
