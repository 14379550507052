import React, { useCallback, useEffect } from 'react';
import { useAuth } from 'AurionCR/components';
import { urlBase64ToUint8Array } from 'utils/file-uploader';
import { ServicePushNotifications } from 'services/push-notifications';
import { useWebNotification, WebNotificationProvider } from './web-notification-provider';
import { PopupNotification } from 'components/popup-notification';
import { useAppDispatch, useAppSelector } from 'store';
import {
  actionAuthNotificationSet,
  selectAuthNotifications,
  selectAuthStatuses,
  selectAuthUser,
} from 'store/auth';

const AskToSubscribe: React.FC = () => {
  const dispatch = useAppDispatch();
  const { doNotAsk } = useAppSelector(selectAuthNotifications);
  const { isLoading: isLoadingAuth } = useAppSelector(selectAuthStatuses);
  const user = useAppSelector(selectAuthUser);

  const setDoNotAsk = useCallback(
    (v: boolean) => {
      dispatch(
        actionAuthNotificationSet({
          notificationDoNotAsk: v,
        }),
      );
    },
    [dispatch],
  );

  const { permission, isInit, isSupport, requestSubscribe, subscription } = useWebNotification();

  const isOpen = Boolean(isSupport && isInit && permission === 'default' && user && !doNotAsk);

  const onAccept = useCallback(async () => {
    setDoNotAsk(true);
    await requestSubscribe();
  }, [requestSubscribe, setDoNotAsk]);

  const onClose = useCallback(() => {
    setDoNotAsk(true);
  }, [setDoNotAsk]);

  useEffect(() => {
    if (permission === 'granted' && isInit && !subscription && user && !isLoadingAuth) {
      requestSubscribe();
    }
  }, [permission, isInit, subscription, user, requestSubscribe, isLoadingAuth]);

  return <PopupNotification isOpen={isOpen} onClose={onClose} onAccept={onAccept} />;
};

export const AppWebNotification: React.FC = ({ children }) => {
  const auth = useAuth();
  const user = auth.user;

  const getSubscribeOptions = useCallback(async () => {
    const publicVapidKey = await ServicePushNotifications.getPublicKey();
    return { applicationServerKey: urlBase64ToUint8Array(publicVapidKey), userVisibleOnly: true };
  }, []);

  const userEmployeeProfileID = user?.appUserID;
  const onSubscribed = useCallback(
    (subscription: PushSubscription) => {
      if (!userEmployeeProfileID) return;

      ServicePushNotifications.subscribeEmployee({ userEmployeeProfileID, subscription });
    },
    [userEmployeeProfileID],
  );

  return (
    <WebNotificationProvider getSubscribeOptions={getSubscribeOptions} onSubscribed={onSubscribed}>
      {children}
      <AskToSubscribe />
    </WebNotificationProvider>
  );
};
