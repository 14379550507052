import {
  API_TODO_TASK_ACTIVITIES,
  PostTodoTaskMeetingActivityInput,
  ToDoTaskActivity,
} from './models';
import { DynamicService } from 'utils/service';
import { apiRtk, RTK_TAGS } from 'utils/rtk-query';
import { selectAuthUser } from 'store/auth';
import { RootState } from 'store';

export * from './models';

class Service extends DynamicService<ToDoTaskActivity> {}

export const ServiceTodoTaskActivities = new Service({
  mainField: 'id',
  getAll: API_TODO_TASK_ACTIVITIES.GET_ALL_DYNAMIC,
  post: API_TODO_TASK_ACTIVITIES.POST,
  delete: API_TODO_TASK_ACTIVITIES.DELETE,
  patch: API_TODO_TASK_ACTIVITIES.PATCH,
});

export const apiTodoTaskActivities = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getLatestTodoTaskActivities: build.query({
      queryFn: async (toDoTaskID: string) => {
        try {
          const {
            data: { value },
          } = await ServiceTodoTaskActivities.getAllDynamic<
            Required<Pick<ToDoTaskActivity, 'id' | 'entryDate' | 'remarks'>>
          >({
            filter: `toDoTaskID=="${toDoTaskID}"`,
            select: ['id', 'entryDate', 'remarks'].join(','),
            take: 5,
          });
          return { data: value };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (_, __, toDoTaskID) => [
        { type: RTK_TAGS.TODO_TASK_ACTIVITIES, id: `todo_task_id__${toDoTaskID}` },
      ],
    }),
    postTodoTaskMeetingActivity: build.mutation<ToDoTaskActivity, PostTodoTaskMeetingActivityInput>(
      {
        queryFn: async (input, { getState }) => {
          try {
            const currentUser = selectAuthUser(getState() as RootState);

            if (!currentUser) {
              throw new Error('apiTodoTaskActivities: current user is required');
            }

            const { data } = await ServiceTodoTaskActivities.post({
              ...input,
              entryDate: new Date().toISOString(),
              userEmployeeProfileID: String(currentUser?.appUserID),
            });
            return { data };
          } catch (e: any) {
            return { error: e };
          }
        },
      },
    ),
  }),
});
