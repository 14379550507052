import { useOpen } from 'AurionCR/components/hooks';
import { useMemo } from 'react';
import { composeFunctions } from '../utils';

interface Options {
  onAccepted: () => void;
}
export const useConfirm = (options: Options) => {
  const { isOpen, handleOpen, handleClose } = useOpen();

  const onConfirm = handleOpen;
  const onDecline = handleClose;

  const onAccept = useMemo(
    () => composeFunctions(options.onAccepted, handleClose),
    [options.onAccepted, handleClose],
  );

  return { isOpen, onDecline, onAccept, onConfirm };
};
