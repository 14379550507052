import React from 'react';

import { Dialog, DialogContent } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { GridUserPatientProfilePrescriptionActivities } from '../grid';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';

const defaultRefresh = () => {};

interface Props {
  userPatientProfilePrescriptionID: string;
  onClose: () => void;
  onRefresh?: () => void;
}

export const DialogUserPatientProfilePrescriptionActivities: React.FC<Props> = ({
  userPatientProfilePrescriptionID,
  onClose,
  onRefresh = defaultRefresh,
}) => {
  const { t } = useTranslate();

  return (
    <Dialog
      open={true}
      disableEscapeKeyDown={true}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      classes={{
        paper: style.paper,
      }}
    >
      <DialogHeading title={t('prescription-activities')} onClose={onClose} />
      <DialogContent className={style.content}>
        <GridUserPatientProfilePrescriptionActivities
          className={style.grid}
          userPatientProfilePrescriptionID={userPatientProfilePrescriptionID}
          onRefresh={onRefresh}
        />
      </DialogContent>
    </Dialog>
  );
};
