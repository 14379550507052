import { DateValue } from 'utils/dates';
import { UserEmployeeProfile } from 'services/user-employee-profiles';

const API_ENDPOINT = 'UserPatientProfileNotes';

export const API_USER_PATIENT_PROFILE_NOTES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<Notes, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<Notes, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface Notes extends Components.Schemas.UserPatientProfileNote {}

export interface UserNotesGetActivitiesInput {
  userPatientProfileID: string;
  dateRange: DateValue[];
}
export interface NotesGrid extends Notes {
  'userEmployeeProfile.firstName': '';
  'userEmployeeProfile.lastName': '';
}

export interface UserNotesGetActivitiesItem
  extends Pick<Notes, 'id' | 'entryDate' | 'description'> {
  employee: UserEmployeeProfile['fullName'];
}
