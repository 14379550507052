import React, { memo, useMemo } from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { Box, Tooltip, BoxProps } from '@material-ui/core';
import { DateValue, isDateLike } from 'utils/dates';
import WarningIcon from '@material-ui/icons/Warning';
import { APP_FORMAT_DATE } from 'configs/const';
import { useAppSelector } from 'store';
import { selectLanguageIsRtl } from 'store/languages';
import { usePatientSubscriptionStatus } from 'components/dialog-patient/hooks';
import { TemplateDate } from '../template-date';

interface Props extends BoxProps {
  subscriptionName?: string;
  endDate: DateValue;
}

export const TemplateSubscription = memo<Props>(
  ({ endDate, className, subscriptionName, ...rest }) => {
    const subscription = useMemo(() => {
      if (!isDateLike(endDate)) {
        return null;
      }
      return { endDate };
    }, [endDate]);

    const isRtl = useAppSelector(selectLanguageIsRtl);

    const { isEnd, isCloseToEnd, notifyMessage, isActive } =
      usePatientSubscriptionStatus(subscription);

    const isError = isEnd || isCloseToEnd;
    return (
      <Tooltip title={notifyMessage} arrow placement={isRtl ? 'right-start' : 'left-start'}>
        <Box
          className={clsx(style.root, isRtl && style.rootRTL, isError && style.error, className)}
          {...rest}
        >
          {!isActive && <WarningIcon className={style.icon} />}
          <div className={style.inner}>
            {subscriptionName && <div>{subscriptionName}</div>}
            <TemplateDate
              date={endDate}
              format={APP_FORMAT_DATE}
              style={{ lineHeight: 1, fontWeight: subscriptionName ? 'bold' : 'normal' }}
            />
          </div>
        </Box>
      </Tooltip>
    );
  },
);
