import React, { useCallback, useMemo } from 'react';
import { Box, Collapse, Grid, InputBase, Tooltip } from '@material-ui/core';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { useFieldProps } from 'hooks';
import { useSourceClinicalMeetingTypes, useSourceEmployees } from 'components/hooks';
import { apiUserPatientProfileSubscriptions } from 'services/user-patient-profile-subscriptions';
import { SubscriptionInfo } from '../subscription-info';
import { SelectPatients } from 'components/select-patients';
import { AppCheckbox } from 'components/app-checkbox';
import { MEETING_TYPE_KEY } from 'services/clinical-meeting-types';
import { Stack } from 'components/stack';
import { useTranslate } from 'hooks/use-translate';
import { EmployeeMeetingDateSelect } from 'components/employee-meeting-date-select';
import { SelectEmployee } from 'components/select-employee';
import { validateRule } from 'AurionCR/components/formV2';
import { PERMISSION_IDS } from 'services/user-employee-profile-permissions';
import { AppSelect } from 'components/app-select';
import { AppInput } from 'components/app-input';
import { ClinicalMeetingFormModel, ClinicalMeetingMeta } from '../../models';

import { rulesMeetingDateRange } from '../../helpers';
import { useClientMeetingDefaultDuration } from '../../hooks';

const useGetSubscriptionInfo =
  apiUserPatientProfileSubscriptions.useGetLatestPatientSubscriptionQuery;
interface Props {
  isLoading: boolean;
  isReadOnly: boolean;
  meetingTypeKey?: MEETING_TYPE_KEY;
  times: { id: string }[];
  meta: ClinicalMeetingMeta;
}

export const ClinicalMeetingFormEdit: React.FC<Props> = ({
  isLoading,
  isReadOnly,
  meetingTypeKey,
  meta,
}) => {
  const { t } = useTranslate();

  const { control, errors, watch, setValue, trigger } = useFormContext<ClinicalMeetingFormModel>();

  const getFieldProps = useFieldProps({ errors });

  const date = watch('date');
  const clinicalMeetingSubjectID = watch('clinicalMeetingSubjectID');

  const sourceMeetingTypes = useSourceClinicalMeetingTypes();
  const meetingTypesOptions = useMemo(() => {
    return meetingTypeKey
      ? sourceMeetingTypes.data.filter((item) => item.meetingTypeKey === meetingTypeKey)
      : sourceMeetingTypes.data;
  }, [sourceMeetingTypes.data, meetingTypeKey]);

  const userPatientProfileID = watch('userPatientProfileID');

  const userEmployeeProfileID = watch('userEmployeeProfileID');

  const { data: userPatientSubscription } = useGetSubscriptionInfo(userPatientProfileID, {
    skip: !userPatientProfileID,
  });

  const renderSendSms = useCallback(
    (renderProps: ControllerRenderProps<ClinicalMeetingFormModel>) => {
      return (
        <Tooltip title={t('send-sms')}>
          <Box mt={1.5}>
            <AppCheckbox
              {...getFieldProps(renderProps)}
              label={null}
              disabled={isLoading || isReadOnly}
            />
          </Box>
        </Tooltip>
      );
    },
    [getFieldProps, isLoading, isReadOnly, t],
  );

  const sourceEmployees = useSourceEmployees();
  const optionsEmployees = useMemo(() => {
    // only doctors and dietitians
    return sourceEmployees.data.filter((employee) => {
      return [PERMISSION_IDS.DIETITIAN, PERMISSION_IDS.DOCTOR].some(
        (permissionID) => employee.userEmployeeProfilePermissionID === permissionID,
      );
    });
  }, [sourceEmployees.data]);

  const rulesMeeting = useMemo(() => {
    return {
      required: {
        value: true,
        message: 'rule-required',
      },
      ...rulesMeetingDateRange,
    };
  }, []);

  const getDefaultDuration = useClientMeetingDefaultDuration(clinicalMeetingSubjectID);

  return (
    <Grid container spacing={2}>
      <Controller name="date" control={control} as={<InputBase type="hidden" />} />
      <Grid item xs={12}>
        <Controller
          control={control}
          name={'clinicalMeetingTypeID'}
          rules={validateRule('required')}
          render={(renderProps) => {
            return (
              <AppSelect
                {...getFieldProps(renderProps)}
                label={t('meeting-type')}
                disabled={isLoading || isReadOnly}
                options={meetingTypesOptions}
                loading={sourceMeetingTypes.loading}
                disableClearable
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ bgcolor: '#F6F8F8', mt: 1, px: 1, mx: -1, borderRadius: 6, overflow: 'hidden' }}>
          <Controller
            control={control}
            name={'userPatientProfileID'}
            rules={validateRule('required')}
            render={(renderProps) => {
              return (
                <SelectPatients
                  {...getFieldProps(renderProps)}
                  disabled={isLoading || isReadOnly || meta.disablePatient}
                  disableClearable
                />
              );
            }}
          />
        </Box>
      </Grid>
      {userPatientSubscription && (
        <Grid item xs={12}>
          <SubscriptionInfo
            sx={{ pt: 1.1, pb: 1.4 }}
            label={t('subscription')}
            title={userPatientSubscription.title || ''}
            start={userPatientSubscription.startDate}
            end={userPatientSubscription.endDate}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Collapse in={Boolean(userPatientProfileID)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack spacing={2} width={'100%'}>
                <Box width={'100%'}>
                  <Controller
                    control={control}
                    name={'userEmployeeProfileID'}
                    rules={validateRule('required')}
                    render={(renderProps) => {
                      return (
                        <SelectEmployee
                          {...getFieldProps(renderProps)}
                          options={optionsEmployees}
                          fullWidth={true}
                          disabled={isLoading || isReadOnly}
                          label={t('user-employee-profile')}
                          onChange={(e) => {
                            setValue('meetingDateTimeUserEmployeeProfile', null);
                            renderProps.onChange(e);
                          }}
                        />
                      );
                    }}
                  />
                </Box>
                <div>
                  <Controller
                    control={control}
                    name={'meetingDateTimeUserEmployeeProfile'}
                    rules={rulesMeeting}
                    render={(renderProps) => {
                      return (
                        <EmployeeMeetingDateSelect
                          {...getFieldProps(renderProps)}
                          disabled={isLoading || isReadOnly || !userEmployeeProfileID}
                          userEmployeeID={userEmployeeProfileID}
                          preferStartDate={date}
                          onChange={(value) => {
                            renderProps.onChange(value);
                            trigger();
                          }}
                          defaultDuration={getDefaultDuration(userEmployeeProfileID)}
                        />
                      );
                    }}
                  />
                </div>
                <Controller control={control} name={'isSendSmsEmployee'} render={renderSendSms} />
              </Stack>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={'organizerUserEmployeeProfileID'}
          rules={validateRule('required')}
          render={(renderProps) => (
            <SelectEmployee {...getFieldProps(renderProps)} disableClearable />
          )}
        />
        <Controller
          control={control}
          name={'remarks'}
          render={(renderProps) => (
            <AppInput {...getFieldProps(renderProps)} multiline minRows={2} />
          )}
        />
      </Grid>
    </Grid>
  );
};
