import { DynamicService } from 'utils/service';

import {
  API_USER_PATIENT_PROFILE_MEDICAL_INFORMATIONS,
  MedicalInformations,
  UserMedicalInformationGetActivitiesInput,
  UserMedicalInformationGetActivitiesItem,
} from './models';
import { makeFilterDateRange } from 'utils/app-helpers';

export * from './models';

class Service extends DynamicService<MedicalInformations> {
  getActivities = async (input: UserMedicalInformationGetActivitiesInput) => {
    const { userPatientProfileID, dateRange } = input;
    const {
      data: { value },
    } = await this.getAllDynamic<UserMedicalInformationGetActivitiesItem>({
      select: ['id', 'expirationDate', 'remarks', 'userEmployeeProfile.fullName as employee'].join(
        ',',
      ),
      filter: [
        `userPatientProfileID=="${userPatientProfileID}"`,
        makeFilterDateRange('expirationDate', dateRange),
      ]
        .filter(Boolean)
        .join('&&'),
      orderBy: 'expirationDate desc',
    });

    return value.map((item) => ({
      id: String(item.id),
      title: item.remarks,
      date: item.expirationDate,
      employee: '',
      download: null,
    }));
  };
}

export const ServiceUserPatientProfileMedicalInformations = new Service({
  mainField: 'id',
  getAll: API_USER_PATIENT_PROFILE_MEDICAL_INFORMATIONS.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_MEDICAL_INFORMATIONS.POST,
  delete: API_USER_PATIENT_PROFILE_MEDICAL_INFORMATIONS.DELETE,
  patch: API_USER_PATIENT_PROFILE_MEDICAL_INFORMATIONS.PATCH,
});
