import React, { memo, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { TMMoveAll } from '../../store';
import { ButtonMeetingMove } from 'components/button-meeting-move';
import { useTranslate } from 'hooks/use-translate';
import {
  DialogMeetingActivityRemark,
  MeetingActivityModel,
} from 'components/dialog-meeting-activity-remark';

export const MeetingMove = memo(() => {
  const { tp } = useTranslate();
  const dispatch = useAppDispatch();
  const [date, setDate] = useState<null | Date>(null);

  const handleCancelMoveAll = useCallback(() => {
    setDate(null);
  }, [setDate]);
  const handleMoveAll = useCallback(
    (value: MeetingActivityModel) => {
      dispatch(TMMoveAll({ ...value, date: date as Date }));
      setDate(null);
    },
    [date, setDate, dispatch],
  );

  const { view } = useAppSelector((state) => state.TaskManager);
  const { canToMove } = useAppSelector((state) => state.TaskManager[view]);

  return (
    <>
      <ButtonMeetingMove setDate={setDate} disabled={canToMove.length === 0} />
      {Boolean(date) && (
        <DialogMeetingActivityRemark
          isLoading={false}
          title={tp('support-meeting-update-reason-title')}
          description={tp('support-meeting-update-reason-description')}
          reason={'rescheduled'}
          onClose={handleCancelMoveAll}
          onSubmit={handleMoveAll}
        />
      )}
    </>
  );
});
