import React from 'react';
import { Link, LinkProps } from '@material-ui/core';

interface Props extends LinkProps {
  value: React.ReactNode;
}

export const TemplatePhoneNumber: React.FC<Props> = ({ value, ...rest }) => {
  return (
    <Link href={`tel:${value}`} underline={'hover'} color={'inherit'} {...rest}>
      {value}
    </Link>
  );
};
