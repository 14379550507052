import axios from 'axios';
import { call, delay, put, select, takeLatest } from 'typed-redux-saga';
import { apiStatic, apiStaticCanceled, requestError } from 'AurionCR/components';
import {
  PdfDocumentSubmit,
  PdfDocumentSubmitActionTypes,
  PdfDocumentSubmitInitAction,
  PdfDocumentSubmitInitData,
  PdfDocumentSubmitSaveAction,
  PdfDocumentSubmitScrollToInputAction,
} from './@type';
import { pdfDocumentSubmitMerge } from './module';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { round } from 'lodash-es';
import { ServiceUserPatientProfile, UserPatientProfile } from 'services/user-patient-profile';
import {
  FormDocumentFieldInputID,
  FormDocumentFieldType,
  FormDocumentInput,
} from 'services/form-document-inputs';
import { FormDocumentFieldInputIDTransform } from '../components/helper';
import { format } from 'date-fns';
import { API_USER_PATIENT_PROFILE_FORM_DOCUMENTS } from 'services/user-patient-profile-form-documents';
import { ServiceMediaPrivate } from 'services/media-private-services';
import { base64ToFileStream } from 'utils/file-uploader';
import { selectPdfDocumentSubmitPdfInfo, selectPdfDocumentSubmitStatuses } from './selectors';
import { UserPatientProfileSubscription } from 'services/user-patient-profile-subscriptions';

interface PatientInfo extends UserPatientProfile {
  subscription?: Pick<UserPatientProfileSubscription, 'id' | 'startDate' | 'endDate'>;
}
const getPatientIfo = async (userPatientProfileID: string, predefinedFields: string) => {
  return ServiceUserPatientProfile.getDynamic<PatientInfo>(userPatientProfileID, {
    select: [
      predefinedFields,
      'userPatientProfileSubscriptions.Where(s => s.isActive).OrderByDescending(s => s.endDate).Select(s => new { s.id, s.startDate, s.endDate }).FirstOrDefault() as subscription',
    ].join(','),
  });
};

// INIT
function* init({ payload: { id } }: PdfDocumentSubmitInitAction) {
  const { init } = yield* select(selectPdfDocumentSubmitStatuses);

  if (init) return;

  const { scale } = yield* select(selectPdfDocumentSubmitPdfInfo);

  yield* put(pdfDocumentSubmitMerge({ init: true, loading: true }));
  try {
    const {
      data: { value },
    }: {
      data: {
        value: PdfDocumentSubmitInitData[];
      };
    } = yield call(apiStaticCanceled, {
      url: API_USER_PATIENT_PROFILE_FORM_DOCUMENTS.GET_ALL_DYNAMIC,
      _cancelID: 'PdfDocumentSubmit',
      params: {
        filter: `id=="${id}"`,
        select: [
          'id',
          'userPatientProfileID',
          'expiredDate',
          'formDocument.title as title',
          'formDocument.pdfUrl as pdfUrl',
          'formDocument.isRTL as isRTL',
          'formDocument.formDocumentInputs as formDocumentInputs',
        ].join(),
      },
    });

    const initData = value[0] || null;

    if (initData) {
      const userPatientProfileFields: {
        [key: string]: FormDocumentInput[];
      } = {};
      initData.formDocumentInputs = (initData.formDocumentInputs || []).map((item) => {
        const result = {
          ...item,
          width: item.width * scale,
          height: item.height * scale,
          coordY: item.coordY * scale,
          coordX: item.coordX * scale,
        };
        if (item.inputID === FormDocumentFieldInputID.checkBox) result.defaultValue = null;
        if (
          ![FormDocumentFieldInputID.signature, FormDocumentFieldInputID.checkBox].includes(
            item.inputID,
          )
        ) {
          if (!userPatientProfileFields[item.inputID]) {
            userPatientProfileFields[item.inputID] = [];
          }
          userPatientProfileFields[item.inputID]?.push(result);
        }
        return result;
      });

      const predefinedFields = Object.keys(userPatientProfileFields)
        .reduce((acc: string[], key) => {
          if (FormDocumentFieldInputIDTransform[key]) {
            acc.push(FormDocumentFieldInputIDTransform[key]);
          }
          return acc;
        }, [])
        .join(',');

      const { data: patient } = yield* call(
        getPatientIfo,
        initData.userPatientProfileID,
        predefinedFields,
      );

      const { subscription } = patient;

      const profile = {
        ...patient,
        startDate: subscription?.startDate,
        endDate: subscription?.endDate,
      };

      Object.keys(userPatientProfileFields).forEach((key) => {
        // @ts-ignore
        const value = profile[key];

        if (value) {
          userPatientProfileFields[key].forEach((item) => {
            if (item.formDocumentFieldType === FormDocumentFieldType.date) {
              let format_ = 'dd.MM.yyyy';
              if (key === FormDocumentFieldInputID.docTime) {
                format_ = 'HH:mm';
              }
              item.defaultValue = format(new Date(value), format_);
            } else {
              item.defaultValue = value || '';
            }
          });
        }
      });
    }
    yield* put(pdfDocumentSubmitMerge({ loading: false, initData }));
  } catch (e) {
    yield* put(pdfDocumentSubmitMerge({ loading: false }));
    if (!axios.isCancel(e)) {
      yield* put(notifyRequestResult(requestError(e), 'error'));
    }
  }
}

function* scrollToInput({ payload }: PdfDocumentSubmitScrollToInputAction) {
  const { initData, pages }: PdfDocumentSubmit = yield select((state) => state.pdfDocumentSubmit);
  const input = initData?.formDocumentInputs?.find((item) => item.id === payload);
  if (input) {
    const { coordX, coordY, width, height, id, pageNumber } = input;
    let top = 0;
    for (let i = 0; i < pageNumber; i++) {
      top += pages[pageNumber].height + 15; //css padding 10 + margin: 5
    }
    yield put(
      pdfDocumentSubmitMerge({
        eventScrollTo: {
          top: round(top + coordY + height / 2),
          left: round(coordX + width / 2),
        },
        eventFocusItem: {
          pageNumber,
          id,
          input,
        },
      }),
    );
  }
}

function* save({ payload }: PdfDocumentSubmitSaveAction) {
  const { initData }: PdfDocumentSubmit = yield select((state) => state.pdfDocumentSubmit);
  if (initData) {
    yield put(pdfDocumentSubmitMerge({ loading: true }));
    try {
      const {
        data: { filePath: formDocumentPath },
      } = yield* call(ServiceMediaPrivate.uploadFile, {
        fileName: `${initData.title}-${initData.id}.pdf`,
        fileStreamString: base64ToFileStream(payload),
        filePath: 'Documents',
      });
      yield call(
        apiStatic.patch,
        API_USER_PATIENT_PROFILE_FORM_DOCUMENTS.PATCH({
          id: initData.id,
        }),
        {
          formDocumentPath,
          signedDate: new Date(),
        },
      );
      yield put(notifyRequestResult('success'));
      yield delay(1000);
      window.location.href = '/';
    } catch (e) {
      yield put(pdfDocumentSubmitMerge({ loading: false }));
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
  }
}

// EXPORTS
export const pdfDocumentSubmitSagas = [
  takeLatest(PdfDocumentSubmitActionTypes.PDF_DOCUMENTS_SUBMIT_INIT, init),
  takeLatest(PdfDocumentSubmitActionTypes.PDF_DOCUMENTS_SUBMIT_SCROLL_TO_INPUT, scrollToInput),
  takeLatest(PdfDocumentSubmitActionTypes.PDF_DOCUMENTS_SUBMIT_SAVE, save),
];
