import React, { forwardRef } from 'react';

import style from './index.module.scss';
import { Badge, ButtonBase, ButtonBaseProps } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslate } from 'hooks/use-translate';

export interface TemplateBadgeProps extends ButtonBaseProps {
  count: number;
}

export const TemplateBadge = forwardRef<HTMLButtonElement, TemplateBadgeProps>(
  ({ className, count, children, ...rest }, ref) => {
    const { t } = useTranslate();
    return (
      <ButtonBase ref={ref} className={clsx(style.root, className)} {...rest}>
        <Badge badgeContent={count} color="primary" showZero className={style.badge}>
          <span className={style.title}>
            {children === undefined ? t('display-activities') : children}
          </span>
        </Badge>
      </ButtonBase>
    );
  },
);
