import React, { useCallback } from 'react';
import { Box, Chip, FormControl, FormHelperText, FormLabel, Typography } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import style from './index.module.scss';
import { Stack } from 'components/stack';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import VAR from 'styles/_variabels.scss';
import { ArrayType } from 'utils/types';

interface OptionCardProps<T> {
  title: string;
  isCompleted: boolean;
  items: T[];
  getSelected: (item: T) => boolean;
  onSwitch: (item: T) => void;
  disabled?: boolean;
}
const OptionCard = <T extends { id: any; title: any }>({
  title,
  items,
  getSelected,
  onSwitch,
  isCompleted,
  disabled,
}: OptionCardProps<T>) => {
  const { t } = useTranslate();

  const factoryClick = (item: T) => () => onSwitch(item);

  return (
    <div className={style.prescription}>
      <div className={style.prescriptionHead}>
        <Typography variant={'body2'} color={'secondary'} className={style.prescriptionTitle}>
          {title}
        </Typography>
        <Box lineHeight={1} title={t(isCompleted ? 'completed' : 'uncompleted')}>
          {isCompleted ? (
            <DoneIcon style={{ color: VAR.colorGreen }} />
          ) : (
            <CloseIcon color={'error'} />
          )}
        </Box>
      </div>
      <div className={style.prescriptionList}>
        {items.map((drug) => {
          const isSelected = getSelected(drug);
          const handleSwitch = factoryClick(drug);
          return (
            <Chip
              key={drug.id}
              label={drug.title}
              size={'small'}
              color={isSelected ? 'primary' : 'default'}
              onClick={handleSwitch}
              onDelete={isSelected ? handleSwitch : undefined}
              classes={{ root: style.chip, label: style.chipLabel }}
              disabled={disabled}
            />
          );
        })}
      </div>
    </div>
  );
};

interface Props<V, P> {
  label?: React.ReactNode;
  error?: boolean;
  helperText?: React.ReactNode;
  value: V[];
  options: P[];
  onChange: (value: this['value']) => void;
  disabled?: boolean;
}

export const AppPrescriptionDrugsAreaInput = <
  V extends { id: string; title: string },
  P extends { id: string; title: string; isCompleted?: boolean; items: V[] },
>({
  label,
  error,
  helperText,
  value = [],
  options,
  onChange,
  disabled,
}: Props<V, P>) => {
  const getSelected = useCallback(
    (item: { id: string }) => {
      return value.some(({ id }) => id === item.id);
    },
    [value],
  );

  const onSwitch = useCallback(
    (item: ArrayType<typeof value>) => {
      const newList = value.filter(({ id }) => id !== item.id);
      const result = newList.length === value.length ? [...value, item] : newList;
      onChange(result);
    },
    [onChange, value],
  );

  return (
    <FormControl className={style.root} error={error} fullWidth component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <Stack spacing={1} direction={'column'} mt={0.5}>
        {options.map((option) => {
          return (
            <div key={option.id}>
              <OptionCard
                title={option.title}
                items={option.items}
                getSelected={getSelected}
                isCompleted={!!option.isCompleted}
                onSwitch={onSwitch}
                disabled={disabled}
              />
            </div>
          );
        })}
      </Stack>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
