import { useAppSelector } from 'store';
import { selectEmployeesMonitorEmployeesListMap } from 'store/employees-monitor';
import { useCallback } from 'react';

export const useEmployeeAvailability = () => {
  const monitorEmployeesListMap = useAppSelector(selectEmployeesMonitorEmployeesListMap);

  const checkIsAvailable = useCallback(
    (userEmployeeProfileID: string | null | undefined): userEmployeeProfileID is string => {
      const employee = userEmployeeProfileID
        ? monitorEmployeesListMap[userEmployeeProfileID]
        : null;

      if (!employee) return false;

      return employee.isMustBeActive === false;
    },
    [monitorEmployeesListMap],
  );

  return { checkIsAvailable };
};
