import { Unset } from 'utils/types';

const API_ENDPOINT = 'ClinicalMeetingActivities';

export const API_CLINICAL_MEETING_ACTIVITIES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<ClinicalMeetingActivity, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<ClinicalMeetingActivity, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export class ClinicalMeetingActivity implements Components.Schemas.ClinicalMeetingActivity {
  id!: string;
  isActive = true;
  clinicalMeetingID = '';
  userEmployeeProfileID = '';
  remarkForPatientCallStatusID = '';
  entryDate = new Date().toISOString();
  remarks = '';
  changes = '';
}

export interface ClinicalMeetingActivityPostInput
  extends Pick<ClinicalMeetingActivity, 'clinicalMeetingID'> {
  remarkForPatientCallStatusID: Unset;
  changes: Unset;
  remarks: Unset;
}
