import { DynamicService } from 'utils/service';
import { API_LABELS, AppLabel, Label } from './models';
import { mergeFilters } from 'utils/dynamic-helpers';

export * from './models';

class Service extends DynamicService<Label> {
  getAppLabels = async (languageID: string) => {
    return this.getAllDynamic<AppLabel>({
      select: ['title', 'labelKey'].join(','),
      filter: mergeFilters(
        `languageID=="${languageID}"`,
        'isActive==true',
        'labelSource.key=="crm"',
      ).join('&&'),
    });
  };
}

export const ServiceLabels = new Service({
  mainField: 'id',
  getAll: API_LABELS.GET_ALL_DYNAMIC,
  post: API_LABELS.POST,
  delete: API_LABELS.DELETE,
  patch: API_LABELS.PATCH,
});
