import Cleave from 'cleave.js/react';
import React, { memo } from 'react';

export const CleaveCardCVV = memo((props: any) => {
  const { inputRef, ...rest } = props;
  return (
    <Cleave
      htmlRef={inputRef}
      options={{
        blocks: [4],
        numericOnly: true,
      }}
      {...rest}
    />
  );
});

export const CleaveCardNumber = memo((props: any) => {
  const { inputRef, onChange, ...rest } = props;
  return (
    <Cleave
      htmlRef={inputRef}
      options={{
        creditCard: true,
      }}
      onChange={(e: any) => {
        const {
          target: { rawValue: value },
        } = e;
        e.target.value = value;
        onChange(e);
      }}
      {...rest}
    />
  );
});

export const CleaveCardDate = memo((props: any) => {
  const { inputRef, ...rest } = props;
  return (
    <Cleave
      htmlRef={inputRef}
      options={{
        date: true,
        datePattern: ['m', 'y'],
      }}
      {...rest}
      placeholder={'MM/YY'}
    />
  );
});

export const CleaveNumber = memo((props: any) => {
  const { inputRef, onChange, ...rest } = props;
  return (
    <Cleave
      htmlRef={inputRef}
      options={{
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      }}
      onChange={(e: any) => {
        const {
          target: { rawValue: value },
        } = e;
        e.target.value = value;
        onChange(e);
      }}
      {...rest}
    />
  );
});
