import { useContext } from 'react';
import { DataGridLightSettingContext } from '../components';

export const useDataGridLightSettings = () => {
  const result = useContext(DataGridLightSettingContext);

  if (!result) {
    throw Error('You should use DataGridSettingContext');
  }

  return result;
};
