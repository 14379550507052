import React from 'react';
import {
  API_USER_PATIENT_PROFILE_FORM_DOCUMENTS,
  createPatientDocumentLink,
  iUserPatientProfileFormDocument,
} from 'services/user-patient-profile-form-documents';
import { API_FORM_DOCUMENTS } from 'services/form-documents';
import TemplateDownloadPrivate from 'components/templates/template-download-private';
import { TemplateLinkCopy } from 'components/templates/template-link-copy';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { GridControlAttachToPrescription } from './grid-control-attach-to-prescription';
import { GridControlsTemplateProps } from 'models/grid';

interface Options {
  prescriptionID: string;
  userPatientProfileID: string;
}
export default (options: Options) => ({
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_FORM_DOCUMENTS.GET_ALL_DYNAMIC,
      filters: [
        'signedDate==null',
        `userPatientProfilePrescriptionID==null`,
        `userPatientProfileID=="${options.userPatientProfileID}"`,
      ].join('&&'),
      selected: [
        'new { userPatientProfile.firstName, userPatientProfile.lastName, userPatientProfile.mobilePhone } as patient',
        'userPatientProfileID',
        'formDocument.formDocumentCategoryID as formDocumentCategoryID',
      ].join(','),
    },
    delete: {
      url: API_USER_PATIENT_PROFILE_FORM_DOCUMENTS.DELETE,
    },
    sortable: {
      field: 'expiredDate',
      order: 'desc',
    },
  },
  toolbar: {
    title: '',
    filters: [
      {
        field: 'formDocumentID',
        options: {
          type: 'select',
          label: 'form-document',
          source: `${API_FORM_DOCUMENTS.GET_ALL_DYNAMIC}?Select=id,title&orderBy=title`,
          style: { minWidth: '35rem' },
        },
      },
    ],
    controls: null,
    controlsDefault: {
      new: null,
      resetFilters: null,
    },
  },
  controls: [
    {
      type: 'custom',
      template: (props: GridControlsTemplateProps<iUserPatientProfileFormDocument>) => (
        <GridControlAttachToPrescription
          prescriptionID={options.prescriptionID}
          data={props.data}
        />
      ),
    },
  ],
  controlsDropDown: null,
  columns: [
    {
      title: 'form-document-title',
      field: 'formDocument.title as title',
      sortable: 'formDocument.title',
    },
    {
      title: 'download-doc',
      field: 'formDocumentPath',
      sortable: false,
      template: (data: iUserPatientProfileFormDocument) => (
        <TemplateDownloadPrivate documentURL={data.formDocumentPath} />
      ),
    },
    {
      title: 'expired-date',
      field: 'expiredDate',
      template: {
        type: 'date',
        format: APP_FORMAT_DATE_TIME,
      },
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'signed-date',
      field: 'signedDate',
      template: {
        type: 'date',
        format: APP_FORMAT_DATE_TIME,
      },
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'patient-path',
      sortable: false,
      template: (data: iUserPatientProfileFormDocument) => (
        <TemplateLinkCopy
          href={createPatientDocumentLink(data)}
          StackProps={{ style: { maxWidth: '20rem' } }}
        />
      ),
      tdClasses: 'boolean',
    },
    {
      title: 'is-expired',
      field: '(expiredDate <= DateTime.Now) as isExpired',
      sortable: false,
      template: 'boolean',
      tdClasses: 'boolean',
    },
    {
      title: 'active',
      field: 'isActive',
      template: 'boolean',
    },
  ],
  pagination: false,
});
