import { DynamicService } from 'utils/service';

import {
  API_USER_PATIENT_PROFILE_TELECOMS,
  UserPatientProfileTelecom,
  UserPatientProfileTelecomGetActivitiesInput,
  UserPatientProfileTelecomGetActivitiesItem,
} from './models';
import { makeFilterDateRange } from 'utils/app-helpers';

export * from './models';

class Service extends DynamicService<UserPatientProfileTelecom> {
  getActivities = async (input: UserPatientProfileTelecomGetActivitiesInput) => {
    const { userPatientProfileID, dateRange } = input;
    const {
      data: { value },
    } = await this.getAllDynamic<UserPatientProfileTelecomGetActivitiesItem>({
      select: ['id', 'entryDate', 'callDirection', 'userEmployeeProfile.fullName as employee'].join(
        ',',
      ),
      filter: [
        `userPatientProfileID=="${userPatientProfileID}"`,
        'callStatus=="hangup"',
        makeFilterDateRange('entryDate', dateRange),
      ]
        .filter(Boolean)
        .join('&&'),
      orderBy: 'entryDate desc',
    });

    return value.map((item) => ({
      id: String(item.id),
      title: item.callDirection,
      date: item.entryDate,
      employee: item.employee,
      download: null,
    }));
  };
}

export const ServiceUserPatientProfileTelecoms = new Service({
  mainField: 'id',
  getAll: API_USER_PATIENT_PROFILE_TELECOMS.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_TELECOMS.POST,
  delete: API_USER_PATIENT_PROFILE_TELECOMS.DELETE,
  patch: API_USER_PATIENT_PROFILE_TELECOMS.PATCH,
});
