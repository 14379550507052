import React, { useCallback, useEffect, useMemo } from 'react';
import { LayoutContent } from 'components/layout-content';
import { Typography } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { useAppDispatch, useAppSelector } from 'store';
import {
  actionSideEffectsLoadData,
  selectSideEffectsData,
  selectSideEffectsStatuses,
} from './store';
import { createColumn, DataGridEmpty, DataGridLight } from 'components/data-grid-light';
import { GridModel } from './models';
import { NativeScroll } from 'components/native-scroll';
import { Loading } from 'AurionCR/components';
import { TemplateAge } from 'components/templates/template-age';
import { TemplateDate } from 'components/templates/template-date';
import { APP_FORMAT_DATE } from 'configs/const';
import { TemplatePatientCard } from 'components/templates/template-patient-card';
import { DialogPatient } from 'components/dialog-patient';
import { useValueQuery } from 'hooks/use-value-query';
import { Filters } from './components/filters';
import style from './index.module.scss';
import { TemplatePhoneNumber } from 'components/templates/template-phone-number';
import { ColumnSideEffects } from './components/column-side-effects';
import { useCurrentUser } from 'components/hooks';
import { PermissionProtectorScreen } from 'components/permission-protector-screen';
import { TemplateEmployeeSource } from 'components/templates/template-employee-source';

export * from './store';

const PageSideEffectsReport: React.FC = () => {
  const { isLoading } = useAppSelector(selectSideEffectsStatuses);

  const { t, tp } = useTranslate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(actionSideEffectsLoadData());
  }, [dispatch]);

  const queryPatient = useValueQuery<string>({ name: 'patient' });

  const rows = useAppSelector(selectSideEffectsData);

  const columns = useMemo(
    () => [
      createColumn<GridModel, 'entryDate'>({
        field: 'entryDate',
        renderHeader: () => t('entry-date'),
        renderColumn: ({ row }) => <TemplateDate date={row.entryDate} />,
      }),
      createColumn<GridModel, 'fullName'>({
        field: 'fullName',
        renderHeader: () => t('name'),
        renderColumn: ({ row }) => (
          <TemplatePatientCard patient={row} userPatientProfileID={row.appIdentityUserID} />
        ),
      }),
      createColumn<GridModel, 'userEmployeeProfileID'>({
        field: 'userEmployeeProfileID',
        renderHeader: () => t('employee'),
        renderColumn: ({ row }) => (
          <TemplateEmployeeSource userEmployeeProfileID={row.userEmployeeProfileID} />
        ),
      }),
      createColumn<GridModel, 'shortRemark'>({
        field: 'shortRemark',
      }),
      createColumn<GridModel, 'idNumber'>({
        field: 'idNumber',
      }),
      createColumn<GridModel, 'mobilePhone'>({
        field: 'mobilePhone',
        renderColumn: ({ row }) => <TemplatePhoneNumber value={row.mobilePhone} />,
      }),
      createColumn<GridModel, 'dateOfBirth'>({
        field: 'dateOfBirth',
        renderHeader: () => t('age'),
        renderColumn: ({ row }) => <TemplateAge value={row.dateOfBirth} />,
      }),
      createColumn<GridModel, 'subscription'>({
        field: 'subscription',
        renderHeader: () => t('subscription-start-date'),
        renderColumn: ({ row }) => (
          <TemplateDate date={row.subscription?.startDate} format={APP_FORMAT_DATE} />
        ),
      }),
      createColumn<GridModel, 'subscription'>({
        field: 'subscription',
        renderHeader: () => t('subscription-end-date'),
        renderColumn: ({ row }) => (
          <TemplateDate date={row.subscription?.endDate} format={APP_FORMAT_DATE} />
        ),
      }),
      createColumn<GridModel, 'sideEffects'>({
        field: 'sideEffects',
        renderHeader: () => t('side-effects'),
        renderColumn: ({ row }) => <ColumnSideEffects sideEffects={row.sideEffects} />,
      }),
    ],
    [t],
  );

  const onRefresh = useCallback(() => {
    dispatch(actionSideEffectsLoadData());
  }, [dispatch]);

  const isEmpty = rows.length === 0;

  return (
    <>
      <LayoutContent
        header={
          <Typography variant="h1" color="secondary">
            {t('side-effects-report')}
          </Typography>
        }
        classes={{
          main: style.content,
        }}
      >
        <Filters />
        <NativeScroll>
          <DataGridLight columns={columns} rows={rows} />
          {isEmpty && <DataGridEmpty />}
          {isLoading && <Loading />}
        </NativeScroll>
        <Typography component={'div'} className={style.total}>
          {tp('total-items', { count: rows.length })}
        </Typography>
      </LayoutContent>
      {queryPatient.value && (
        <DialogPatient
          itemID={queryPatient.value}
          onClose={queryPatient.onClear}
          onFormHandle={onRefresh}
        />
      )}
    </>
  );
};

const PageSideEffectsReportProtector: React.FC = () => {
  const { $isAdmin } = useCurrentUser();
  return (
    <PermissionProtectorScreen isSafe={$isAdmin}>
      <PageSideEffectsReport />
    </PermissionProtectorScreen>
  );
};

export default PageSideEffectsReportProtector;
