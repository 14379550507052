import { convertToDate, DateValue } from 'utils/dates';
import { format } from 'date-fns';
import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'UserEmployeeProfileWorkLogs';

export const API_USER_EMPLOYEE_PROFILE_WORK_LOGS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<UserEmployeeProfileWorkLogs, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<UserEmployeeProfileWorkLogs, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};
export enum DAY_OF_WEEK {
  sunday = 0,
  monday = 1,
  tuesday = 2,
  wednesday = 3,
  thursday = 4,
  friday = 5,
  // saturday = 6,
}

export const dateToDayOfWeek = (value: DateValue) => {
  if (!value) {
    throw new Error('getDayOfWeek');
  }
  const key = format(convertToDate(value), 'iiii').toLowerCase() as any;
  const res = DAY_OF_WEEK[key] as unknown as DAY_OF_WEEK;

  if (res !== undefined) return res;

  return Number(format(convertToDate(value), 'i'));
};

export interface UserEmployeeProfileWorkLogs
  extends FixServerObject<
    Omit<Components.Schemas.UserEmployeeProfileWorkLog, 'dayOfWeek'>,
    'fromTime' | 'toTime' | 'isInClinic' | 'id'
  > {
  dayOfWeek: DAY_OF_WEEK;
}
