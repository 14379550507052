import React, { memo } from 'react';
import { useAppSelector } from 'store';
import { Loading } from 'AurionCR/components';

export const ProgressBar = memo(() => {
  //state
  const { loading } = useAppSelector((state) => state.TaskManager);
  const { loading: calendarLoading } = useAppSelector((state) => state.TaskManager.calendar);
  const { loading: listLoading } = useAppSelector((state) => state.TaskManager.list);

  //render
  return loading || calendarLoading || listLoading ? <Loading /> : null;
});
