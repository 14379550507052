import React from 'react';
import { ButtonProps, Button } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';

interface Props extends ButtonProps {
  isCreate: boolean;
}
export const ButtonSave: React.FC<Props> = ({ isCreate, ...rest }) => {
  const { t } = useTranslate();

  return (
    <Button
      children={t(isCreate ? 'create' : 'save')}
      variant={'contained'}
      color={'primary'}
      {...rest}
    />
  );
};
