import clsx from 'clsx';
import { useDataGridLightSettings } from '../../hooks';
import { DataGridLightColumn } from '../../models';
import React, { memo, useMemo } from 'react';
import style from './index.module.scss';
import { DataGridEditorContainer } from '../data-grid-editor-container';

interface Props<T = any> {
  row: T;
  column: DataGridLightColumn<any, any>;
}

export const DataGridLightCell = memo<Props>(({ column, row }) => {
  const { classes } = useDataGridLightSettings();

  const props = useMemo(() => {
    if (typeof column.tdProps === 'function') {
      return column.tdProps(row);
    }
    return column.tdProps;
  }, [column, row]);

  const isEditable = useMemo(() => {
    if (typeof column.editable === 'function') {
      return column.editable({ row });
    }
    return !!column.editable;
  }, [column, row]);

  if (isEditable && !column.Editor) {
    throw new Error('client-error: Editor is required');
  }

  const columnContent = column.renderColumn({ field: column.field, row });

  return (
    <td
      {...props}
      className={clsx(
        style.cell,
        classes.cell,
        props?.className,
        {
          [style.clickable]: props?.onClick,
          [style.sticky]: column.stickyLeft || column.stickyRight,
          [style.stickyLeft]: column.stickyLeft,
          [style.stickyRight]: column.stickyRight,
        },
        style.td,
      )}
    >
      {isEditable && column.Editor ? (
        <DataGridEditorContainer row={row} field={column.field} Editor={column.Editor}>
          {columnContent}
        </DataGridEditorContainer>
      ) : (
        columnContent
      )}
    </td>
  );
});
