import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'NotebookFieldInputs';

export const API_NOTEBOOK_FIELD_INPUTS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<NotebookFieldInput, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<NotebookFieldInput, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface NotebookFieldInput
  extends FixServerObject<
    Components.Schemas.NotebookFieldInput,
    'id' | 'notebookID' | 'fieldInputID' | 'rank' | 'isRequired' | 'isMedicalInformation'
  > {
  entryValueDate?: string | null | Date | undefined;
}
