import React, { memo, useEffect, useMemo, useRef } from 'react';
import GridConfig from './grid-config';
import DataGrid from 'components/data-grid';
import { patientEmitter } from '../../emitter';

interface Props {
  userPatientProfileID: string;
}
export const GridSmsMessagePatients = memo<Props>(({ userPatientProfileID }) => {
  const gridConfig = useMemo<any>(() => GridConfig(userPatientProfileID), [userPatientProfileID]);

  const grid = useRef<any>();

  useEffect(() => {
    const refresh = (payload: { patientID: string }) => {
      if (payload.patientID !== userPatientProfileID) {
        return;
      }
      grid.current?.onTasks([['getData']]);
    };

    patientEmitter.on('refresh-all', refresh);
    patientEmitter.on('refresh-sms', refresh);
    return () => {
      patientEmitter.off('refresh-all', refresh);
      patientEmitter.off('refresh-sms', refresh);
    };
  }, [userPatientProfileID, grid]);

  return <DataGrid ref={grid} config={gridConfig} />;
});
