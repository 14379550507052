const API = 'VoipeServices';
export const API_VOIPE_SERVICE = {
  CALL_PATIENT: `${API}/ClickToCall`,
  CALL_LEAD: `${API}/ClickToCallForLead`,
};
export interface TelecomCallPatientInput {
  userPatientProfileID: string;
}
export interface TelecomCallLeadInput {
  phoneNumber: string;
}
