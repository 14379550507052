import { format } from 'date-fns';
import { DateValue } from './dates';

interface Meeting {
  meetingFromDateTime: DateValue;
  meetingToDateTime: DateValue;
}

export const adapterMeeting = <T extends Meeting>(data: T) => {
  return {
    ...data,
    date: data.meetingFromDateTime
      ? new Date(data.meetingFromDateTime).toISOString()
      : new Date().toISOString(),
    start: data?.meetingFromDateTime
      ? format(new Date(data.meetingFromDateTime), 'HH:mm')
      : '09:00',
    end: data?.meetingToDateTime ? format(new Date(data.meetingToDateTime), 'HH:mm') : '10:00',
  };
};
