import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { FormProvider, useForm } from 'react-hook-form';
import {
  apiUserPatientProfileSubscriptions,
  END_TREATMENT_LANGUAGE,
  getEndOfTreatmentContentKey,
} from 'services/user-patient-profile-subscriptions';
import { useEffectError } from 'hooks';
import { FormEndTreatment } from '../form-end-treatment';
import { useAppSelector } from 'store';
import { useTranslate } from 'hooks/use-translate';
import { selectLanguageID } from 'store/languages';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { dateFormat, isDateLike } from 'utils/dates';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { apiPdf } from 'services/pdf';
import { isMutationFulfilled, isMutationRejected } from 'utils/rtk-query';
import { apiPdfContent } from 'services/pdf-content';
import { DialogEditHtmlContent } from 'components/dialog-edit-html-content';

const useFetchPatientData = apiUserPatientProfile.useGetPatientDetailsForPdfEndOfTreatmentQuery;
const useGenerateContentMutation = apiPdfContent.useGenerateContentEndTreatmentMutation;
const useGenerateDocumentMutation = apiPdf.useGenerateDocumentEndTreatmentMutation;

const useUpdateSubscriptionMutation =
  apiUserPatientProfileSubscriptions.usePatchPatientSubscriptionMutation;

const schema = yup.object({
  prescribedMedications: yup
    .array()
    .of(
      yup.object({
        id: yup.string().required(),
        title: yup.string().required(),
      }),
    )
    .default([]),
  startingWeight: yup.number().required('rule-required').default(0),
  currentWeight: yup.number().required('rule-required').default(0),
  startingBMI: yup.number().required('rule-required').default(0),
  currentBMI: yup.number().required('rule-required').default(0),
  doctorRecommendation: yup.string().required('rule-required').default(''),
  language: yup.number().required('rule-required').default(END_TREATMENT_LANGUAGE.HEBREW),
  period: yup.string().required('rule-required').trim().default(''),
});

const defaultValues = schema.cast({});
type FormModel = typeof defaultValues;
type ApplyState = { content: string; title: string; formData: FormModel };

interface DialogCreateContentProps {
  userPatientProfileID: string;
  startDate: string;
  endDate: string;
  onClose: () => void;
  onApply: (state: ApplyState) => void;
}

const DialogCreateContent: React.FC<DialogCreateContentProps> = ({
  userPatientProfileID,
  startDate,
  endDate,
  onClose,
  onApply,
}) => {
  const { t } = useTranslate();
  const languageID = useAppSelector(selectLanguageID);

  const resultPatient = useFetchPatientData(
    {
      userPatientProfileID,
      start: startDate,
      end: endDate,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  useEffectError(resultPatient.error);

  const formMethods = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { reset, handleSubmit, watch } = formMethods;

  const language = watch('language');
  const contentKey = useMemo(() => {
    if (!resultPatient.data) {
      return null;
    }
    return getEndOfTreatmentContentKey({
      genderRowIndex: Number(resultPatient.data.gender.rowIndex),
      language,
    });
  }, [resultPatient.data, language]);

  const [generate, resultGenerate] = useGenerateContentMutation();
  useEffectError(resultGenerate.error);

  const onSubmit = useCallback(
    async (formData: typeof defaultValues) => {
      if (!contentKey) return;
      if (!resultPatient.data) return;

      const prescribedMedications = Array.from(
        new Set(formData.prescribedMedications.map(({ title }) => title)).keys(),
      ).join(', ');

      const res = await generate({
        contentKey,
        languageID,
        payload: {
          prescribedMedications: prescribedMedications,
          startingWeight: formData.startingWeight,
          startingBMI: formData.startingBMI,
          currentWeight: formData.currentWeight,
          currentBMI: formData.currentBMI,
          period: formData.period,
          doctorRecommendation: formData.doctorRecommendation,
          patient: {
            fullName: String(resultPatient.data.fullName),
            idNumber: String(resultPatient.data.idNumber),
          },
        },
      });

      if (isMutationRejected(res)) return;

      onApply({ content: res.data.content, title: res.data.title, formData });
    },
    [languageID, onApply, contentKey, generate, resultPatient.data],
  );

  useEffect(() => {
    if (!resultPatient.data) return;
    reset(
      schema.cast(
        {
          ...resultPatient.data,
          period: [startDate, endDate]
            .filter(isDateLike)
            .map((v) => dateFormat(v))
            .join(' - '),
        },
        { stripUnknown: true, assert: false },
      ),
    );
  }, [reset, resultPatient.data, startDate, endDate]);

  const isLoading = resultPatient.isFetching || resultGenerate.isLoading;

  return (
    <Dialog fullWidth maxWidth={'lg'} open={true} onClose={onClose}>
      <DialogHeading
        isLoading={isLoading}
        title={t('end-treatment-dialog-title')}
        onClose={onClose}
      />
      <DialogContent>
        <FormProvider {...formMethods}>
          <FormEndTreatment isLoading={isLoading} userPatientProfileID={userPatientProfileID} />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
          variant={'contained'}
          color={'primary'}
        >
          {t('apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface Props {
  userPatientProfileSubscriptionID: string;
  userPatientProfileID: string;
  startDate: string;
  endDate: string;
  onClose: () => void;
  onDone: () => void;
}

export const DialogEndTreatment: React.FC<Props> = ({
  userPatientProfileSubscriptionID,
  userPatientProfileID,
  startDate,
  endDate,
  onClose,
  onDone,
}) => {
  const [state, setState] = useState<ApplyState>();

  const [patchSubscription, resultPatchSubscription] = useUpdateSubscriptionMutation();
  useEffectError(resultPatchSubscription.error);

  const [generate, resultGenerate] = useGenerateDocumentMutation();
  useEffectError(resultGenerate.error);

  const isLoading = resultGenerate.isLoading || resultPatchSubscription.isLoading;

  const onGenerate = useCallback(
    async (content: string) => {
      if (!state) return;

      const formData = state.formData;

      const resDoc = await generate({ content, title: state.title, userPatientProfileID });

      if (!isMutationFulfilled(resDoc)) return;

      const prescribedMedications = Array.from(
        new Set(formData.prescribedMedications.map(({ title }) => title)).keys(),
      ).join(', ');

      const resSub = await patchSubscription({
        id: userPatientProfileSubscriptionID,
        endOfTreatmentDocUrl: resDoc.data.documentURL,
        prescribedMedications,
        startingWeight: formData.startingWeight,
        currentWeight: formData.currentWeight,
        startingBMI: formData.startingBMI,
        currentBMI: formData.currentBMI,
        doctorRecommendation: formData.doctorRecommendation,
      });

      if (!isMutationFulfilled(resSub)) return;

      onClose();
      onDone();
    },
    [
      state,
      generate,
      userPatientProfileID,
      onClose,
      onDone,
      patchSubscription,
      userPatientProfileSubscriptionID,
    ],
  );

  if (!state) {
    return (
      <DialogCreateContent
        userPatientProfileID={userPatientProfileID}
        startDate={startDate}
        endDate={endDate}
        onClose={onClose}
        onApply={setState}
      />
    );
  }

  return (
    <DialogEditHtmlContent
      isLoading={isLoading}
      title={state.title}
      content={state.content}
      onGenerate={onGenerate}
      onCancel={onClose}
    />
  );
};
