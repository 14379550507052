const API_ENDPOINT = 'UserEmployeeProfilePermissionSmsContents';

export const API_USER_EMPLOYEE_PROFILE_PERMISSION_SMS_CONTENTS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<Components.Schemas.UserEmployeeProfileToDoTask, 'id'>) =>
    `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<Components.Schemas.UserEmployeeProfileToDoTask, 'id'>) =>
    `${API_ENDPOINT}/Delete/${data.id}`,
  INSERT_BULK: `${API_ENDPOINT}/CreateUserEmployeeProfileToDoTaskCategoryBulk`,
};

export interface UserEmployeeProfilePermissionSmsContentBulkInput {
  smsContentID: string;
  userEmployeeProfilePermissionIDs: string[];
}
