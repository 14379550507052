import React, { useCallback, useEffect, useState } from 'react';

import { DialogPdf } from 'components/dialog-pdf';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import { RowField } from 'components/row-field';
import { Drug, ServiceDrugs } from 'services/drug';
import { HtmlEditor, HtmlEditorConfig } from 'components/html-editor';
import {
  useEffectError,
  useFetchDynamic,
  useFieldProps,
  useOpenValue,
  useRequest,
  useRequestAlertError,
} from 'hooks';
import { DialogPdfPreview } from 'components/dialog-pdf-preview';
import { ServiceUserPatientProfile } from 'services/user-patient-profile';
import { ServiceUserEmployeeProfile } from 'services/user-employee-profiles';
import { CONTENT_KEYS, ServiceDocumentTemplates } from 'services/document-templates';
import { ServicePdf } from 'services/pdf';
import { useTranslate } from 'hooks/use-translate';
import { AppInput } from 'components/app-input';
import { AppCheckbox } from 'components/app-checkbox';

const CONFIG: Partial<HtmlEditorConfig> = {
  cleanHTML: {
    replaceNBSP: true,
    removeEmptyElements: true,
    denyTags: {
      script: false,
      iframe: false,
    },
    allowTags: {
      div: true,
      p: true,
      span: true,
      a: {
        href: true,
      },
      br: true,
      h1: true,
      h2: true,
      h3: true,
      h4: true,
      h5: true,
      h6: true,
      strong: true,
      ul: true,
      ol: true,
      li: true,
      img: true,
    },
  },
  buttons: 'bold,|,ul,ol,|,align,|,fontsize,link,|,undo,redo,image',
  hidePoweredByJodit: true,
  toolbarAdaptive: false,
  toolbarSticky: false,
  allowResizeX: false,
  allowResizeY: false,
  uploader: { insertImageAsBase64URI: true },
  height: 300,
};
const defaultValues: Partial<Drug> = {
  form29PharmacyName: '',
  form29DrugName: '',
  form29Dosage: '',
  form29ActiveIngredients: '',
  form29Manufacturer: '',
  form29RequestedLabel: '',
  form29Rationals: '',
  form29Articles: '',
  form29DailyDosage: '',
  form29DeclarationNotRegisterInIsrael: false,
  form29DeclarationOffLabel: false,
  form29DeclarationRegisterInKnownCountry: false,
  form29DeclarationRegisterInUSA: false,
  form29DeclarationRegisterInCanada: false,
  form29DeclarationRegisterInAustralia: false,
  form29DeclarationRegisterInJapan: false,
  form29DeclarationRegisterInOtherCountry: false,
  form29DeclarationRegisterInOtherCountryName: '',
  form29ExpirationInMonths: 0,
};

type LoadDataOptions = {
  drugID: string;
  doctorID: string;
  patientID: string;
};

type Model = typeof defaultValues;

type GenerateResult = {
  value: string;
  valueWatermark: string;
  drug: Drug;
};

interface Props {
  onClose: () => void;
  onGenerated: (result: GenerateResult) => void;
  drugID: string;
  patientID: string;
  doctorID: string;
}

export const DialogGenerateForm29 = ({
  onClose,
  onGenerated,
  drugID,
  patientID,
  doctorID,
}: Props) => {
  const { control, errors, handleSubmit, reset } = useForm({ defaultValues });
  const { t } = useTranslate();

  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

  const loadData = useCallback(async (options: LoadDataOptions) => {
    const { drugID, doctorID, patientID } = options;

    const [{ data: drug }, { data: patient }, { data: doctor }] = await Promise.all([
      ServiceDrugs.getForm26(drugID),
      ServiceUserPatientProfile.getPatientDetailsForPdfGimel29(patientID),
      ServiceUserEmployeeProfile.get(doctorID),
    ]);

    return { data: { drug, doctor, patient } };
  }, []);

  const [triggerLoadData, { data, isLoading: isLoadingData, error }] = useFetchDynamic(loadData);
  useEffectError(error);

  const { openValue, onOpen: onOpenPreview, onClose: onClosePreview } = useOpenValue<string>();

  const [isGenerating, setIsGenerating] = useState(false);

  const makePdf = useCallback(
    async (drug: Model) => {
      if (!data) return null;

      const {
        data: { html: htmlTemplate },
      } = await ServiceDocumentTemplates.getByContentKey(CONTENT_KEYS.GIMEL_29);

      const payload = { ...data, drug: { ...data.drug, ...drug } };

      const [{ data: value }, { data: valueWatermark }] = await Promise.all([
        ServicePdf.generateGimel29WithoutWatermark({
          htmlTemplate,
          payload,
        }),
        ServicePdf.generateGimel29({ htmlTemplate, payload }),
      ]);

      return { value, valueWatermark };
    },
    [data],
  );

  const requestPreview = useCallback(
    async (drug: Model) => {
      const result = await makePdf(drug);
      if (!result) return;

      onOpenPreview(result.value);
    },
    [onOpenPreview, makePdf],
  );
  const onPreview = useRequestAlertError(useRequest(requestPreview, setIsGenerating));
  const requestGenerate = useCallback(
    async (drug: Model) => {
      if (!data) return;
      const result = await makePdf(drug);

      if (!result) return;
      onGenerated({ ...result, drug: data.drug });
      onClose();
    },
    [data, onGenerated, onClose, makePdf],
  );
  const onGenerate = useRequestAlertError(useRequest(requestGenerate, setIsGenerating));

  useEffect(() => {
    triggerLoadData({
      drugID,
      patientID,
      doctorID,
    });
  }, [drugID, patientID, doctorID, triggerLoadData]);

  useEffect(() => {
    if (data?.drug) {
      reset(data.drug);
    }
  }, [data, reset]);

  const isLoading = isLoadingData || isGenerating;

  const renderInput = (renderProps: ControllerRenderProps<Model>) => {
    const { label, ...rest } = getFieldProps(renderProps);
    return (
      <RowField label={label}>
        <AppInput {...rest} disabled={isLoading} disableClearable />
      </RowField>
    );
  };
  const renderCheckbox = (renderProps: ControllerRenderProps<Model>) => {
    const { label, ...rest } = getFieldProps(renderProps);
    return (
      <RowField label={label}>
        <AppCheckbox {...rest} disabled={isLoading} color="primary" />
      </RowField>
    );
  };
  const renderEditor = (renderProps: ControllerRenderProps<Model>) => {
    const props = getFieldProps(renderProps);
    return <HtmlEditor {...props} config={CONFIG} disabled={isLoading} />;
  };

  return (
    <>
      {openValue && (
        <DialogPdfPreview
          fullScreen
          title={t('form29-generate')}
          src={openValue}
          onClose={onClosePreview}
        />
      )}
      <DialogPdf
        fullWidth
        maxWidth={'xl'}
        onClose={onClose}
        onPreview={handleSubmit(onPreview)}
        onGenerate={handleSubmit(onGenerate)}
        title={t('form29')}
        isLoading={isLoading}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <Controller
              name={'form29PharmacyName'}
              control={control}
              render={(renderProps) => {
                const { label, ...rest } = getFieldProps(renderProps);
                return (
                  <RowField label={label}>
                    <AppInput {...rest} disabled={isLoading} disableClearable multiline />
                  </RowField>
                );
              }}
            />
            <Controller control={control} name={'form29DrugName'} render={renderInput} />
            <Controller control={control} name={'form29Dosage'} render={renderInput} />
            <Controller
              control={control}
              name={'form29DeclarationNotRegisterInIsrael'}
              render={renderCheckbox}
            />
            <Controller
              control={control}
              name={'form29DeclarationOffLabel'}
              render={renderCheckbox}
            />
            <Controller
              control={control}
              name={'form29DeclarationRegisterInKnownCountry'}
              render={renderCheckbox}
            />
            <Controller
              control={control}
              name={'form29DeclarationRegisterInWestEurope'}
              render={renderCheckbox}
            />
            <Controller
              control={control}
              name={'form29DeclarationRegisterInUSA'}
              render={renderCheckbox}
            />
            <Controller
              control={control}
              name={'form29DeclarationRegisterInCanada'}
              render={renderCheckbox}
            />
            <Controller
              control={control}
              name={'form29DeclarationRegisterInAustralia'}
              render={renderCheckbox}
            />
            <Controller
              control={control}
              name={'form29DeclarationRegisterInJapan'}
              render={renderCheckbox}
            />
            <Controller
              control={control}
              name={'form29DeclarationRegisterInOtherCountry'}
              render={renderCheckbox}
            />
            <Controller
              control={control}
              name={'form29DeclarationRegisterInOtherCountryName'}
              render={renderCheckbox}
            />
            <Controller
              control={control}
              name={'form29ExpirationInMonths'}
              render={(renderProps) => {
                const { label, ...rest } = getFieldProps(renderProps);
                return (
                  <RowField label={label}>
                    <AppInput
                      {...rest}
                      type="number"
                      disabled={isLoading}
                      disableClearable
                      multiline
                    />
                  </RowField>
                );
              }}
            />
          </Grid>
          <Grid container item spacing={4} xs={12} md={6} lg={8} style={{ padding: 0, margin: 0 }}>
            <Grid item xs={12} md={12} lg={6}>
              <Controller
                control={control}
                name={'form29ActiveIngredients'}
                render={renderEditor}
              />
              <Controller control={control} name={'form29DailyDosage'} render={renderEditor} />
              <Controller control={control} name={'form29Manufacturer'} render={renderEditor} />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Controller control={control} name={'form29Rationals'} render={renderEditor} />
              <Controller control={control} name={'form29Articles'} render={renderEditor} />
              <Controller control={control} name={'form29RequestedLabel'} render={renderEditor} />
            </Grid>
          </Grid>
        </Grid>
      </DialogPdf>
    </>
  );
};
