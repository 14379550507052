import React, { useMemo } from 'react';
import { Button, ButtonProps, Divider } from '@material-ui/core';
import { DateValue } from 'utils/dates';
import { useTranslate } from 'hooks/use-translate';
import { ActionKey, CONFIG_CATEGORY_ACTION } from 'services/todo-task-categories';
import { useOpen } from 'AurionCR/components/hooks';
import { DialogPreview } from '../dialog-preview';
import { Stack } from 'components/stack';
import { TemplateDate } from 'components/templates/template-date';

export interface ButtonTaskPreviewProps extends Omit<ButtonProps, 'onClick'> {
  taskID: string;
  actionKey: ActionKey;
  entryDate: DateValue;
}
export const ButtonTaskPreview: React.FC<ButtonTaskPreviewProps> = ({
  taskID,
  actionKey,
  entryDate,
  children,
  ...rest
}) => {
  const { t } = useTranslate();
  const config = useMemo(() => {
    return CONFIG_CATEGORY_ACTION[actionKey];
  }, [actionKey]);

  if (!config) {
    throw new Error('unsupported task category');
  }

  const { isOpen, handleOpen, handleClose } = useOpen();
  return (
    <>
      <Button
        variant={'outlined'}
        startIcon={<config.Icon />}
        {...rest}
        style={{ color: config.color, ...rest?.style }}
        onClick={handleOpen}
      >
        <Stack spacing={1} divider={<Divider flexItem orientation={'vertical'} />}>
          <span>{children === undefined ? t(`task-${config.name}`) : children}</span>
          <TemplateDate date={entryDate} />
        </Stack>
      </Button>
      {isOpen && <DialogPreview taskID={taskID} onClose={handleClose} />}
    </>
  );
};
