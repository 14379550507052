import { APP_FORMAT_DATE } from 'configs/const';
import { API_USER_PATIENT_PROFILE_SESSION_ITEMS } from 'services/user-patient-profile-session-item';
import * as dynamic from 'utils/dynamic-helpers';

interface Options {
  userPatientProfileID: string;
  inputTypeID: string | null;
}
export default (options: Options) => ({
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_SESSION_ITEMS.GET_ALL_DYNAMIC,
      selected:
        'userPatientProfileSessionID,userPatientProfileSession.userPatientProfileID as userPatientProfileID',
      filters: dynamic
        .mergeFilters(
          dynamic.createFilterEquals('fieldInput.inputType.isWeight', 'true'),
          dynamic.createFilterEquals(
            'userPatientProfileSession.userPatientProfileID',
            options.userPatientProfileID,
          ),
          dynamic.createFilterEquals('fieldInput.inputTypeID', options.inputTypeID),
        )
        .join('&&'),
    },
    sortable: {
      field: 'userPatientProfileSession.entryDate',
      order: 'desc',
    },
  },
  toolbar: {
    title: '',
    controls: null,
    controlsDefault: null,
  },
  columns: [
    {
      title: 'entry-date',
      field: 'userPatientProfileSession.entryDate as entryDate',
      template: {
        type: 'date',
        format: APP_FORMAT_DATE,
      },
      sortable: 'userPatientProfileSession.entryDate',
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'notebook',
      field: 'userPatientProfileSession.notebook.labelKey as notebook',
      sortable: 'userPatientProfileSession.notebook.labelKey',
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'activity-type',
      field: 'fieldInput.labelKey as entryValue',
      sortable: 'fieldInput.labelKey',
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'weight',
      field: 'entryValueNumber',
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'employee',
      field: 'userPatientProfileSession.userEmployeeProfile.fullName as employeeName',
      sortable: 'userPatientProfileSession.userEmployeeProfile.firstName',
      tdClasses: 'mobile-20',
    },
  ],
  controls: null,
  pagination: {
    size: 'all',
    visible: false,
  },
});
