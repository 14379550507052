import {
  API_USER_EMPLOYEE_PROFILE_SCHEDULES,
  UserEmployeeProfileSchedule,
  UserEmployeeProfileScheduleMakeFromDefaultInput,
} from './models';
import { DynamicService } from 'utils/service';
import { apiRtk, RTK_TAGS } from 'utils/rtk-query';
import { FixServerObject, PatchPartial } from 'utils/types';
import { addMinutes, endOfDay, endOfMonth, getDay, set, startOfDay, startOfMonth } from 'date-fns';
import { convertToDate, DateValue } from 'utils/dates';
import { makeFilterDateRange } from 'utils/app-helpers';
import {
  ServiceUserEmployeeProfileWorkLogs,
  UserEmployeeProfileWorkLogs,
} from 'services/user-employee-profile-work-logs';
import { CALENDAR_RANGE_MINUTES } from 'configs/const';

export * from './models';

class Service extends DynamicService<UserEmployeeProfileSchedule> {
  makeFromDefault = async (input: UserEmployeeProfileScheduleMakeFromDefaultInput) => {
    const { userEmployeeProfileID, date } = input;
    const dayOfWeek = getDay(convertToDate(date));
    const {
      data: { value },
    } = await ServiceUserEmployeeProfileWorkLogs.getAllDynamic<
      Pick<UserEmployeeProfileWorkLogs, 'fromTime' | 'toTime' | 'isInClinic'>
    >({
      filter: [`userEmployeeProfileID=="${userEmployeeProfileID}"`, `dayOfWeek=="${dayOfWeek}"`]
        .filter(Boolean)
        .join('&&'),
      select: ['fromTime', 'toTime', 'isInClinic'].join(','),
    });

    let items = value;

    if (value.length === 0) {
      const fromTime = set(convertToDate(date), {
        hours: 6,
        minutes: 0,
      });
      const toTime = addMinutes(fromTime, CALENDAR_RANGE_MINUTES);
      items = [
        {
          fromTime: fromTime.toISOString(),
          toTime: toTime.toISOString(),
          isInClinic: false,
        },
      ];
    }

    await Promise.all(
      items.map((item) => {
        const fromTime = set(convertToDate(date), {
          hours: convertToDate(item.fromTime).getHours(),
          minutes: convertToDate(item.fromTime).getMinutes(),
          seconds: 0,
          milliseconds: 0,
        });
        const toTime = set(convertToDate(date), {
          hours: convertToDate(item.toTime).getHours(),
          minutes: convertToDate(item.toTime).getMinutes(),
          seconds: 0,
          milliseconds: 0,
        });
        return this.post({
          fromTime: fromTime.toISOString(),
          toTime: toTime.toISOString(),
          isInClinic: item.isInClinic,
          userEmployeeProfileID,
          date: fromTime.toISOString(),
        });
      }),
    );
  };
}

export const ServiceUserEmployeeProfileSchedules = new Service({
  mainField: 'id',
  getAll: API_USER_EMPLOYEE_PROFILE_SCHEDULES.GET_ALL_DYNAMIC,
  post: API_USER_EMPLOYEE_PROFILE_SCHEDULES.POST,
  patch: API_USER_EMPLOYEE_PROFILE_SCHEDULES.PATCH,
  delete: API_USER_EMPLOYEE_PROFILE_SCHEDULES.DELETE,
});

interface ModelApiUserEmployeeProfileSchedule
  extends FixServerObject<UserEmployeeProfileSchedule, 'userEmployeeProfileID'> {}

export const apiUserEmployeeProfileSchedules = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    employeeProfileSchedulesMakeFromDefault: builder.mutation<
      void,
      UserEmployeeProfileScheduleMakeFromDefaultInput
    >({
      queryFn: async ({ userEmployeeProfileID, date }) => {
        try {
          await ServiceUserEmployeeProfileSchedules.makeFromDefault({
            userEmployeeProfileID,
            date,
          });
          return { data: undefined, error: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (_, __, arg) => [
        {
          type: RTK_TAGS.EMPLOYEE_SCHEDULES,
          id: ['employee', arg.userEmployeeProfileID].join('__'),
        },
      ],
    }),
    employeeProfileSchedulesMonthly: builder.query<
      Pick<UserEmployeeProfileSchedule, 'id' | 'fromTime' | 'toTime' | 'isInClinic' | 'date'>[],
      { userEmployeeProfileID: string; date: DateValue }
    >({
      queryFn: async ({ userEmployeeProfileID, date }) => {
        const start = startOfMonth(convertToDate(date));
        const end = endOfMonth(convertToDate(date));
        try {
          const {
            data: { value },
          } = await ServiceUserEmployeeProfileSchedules.getAllDynamic({
            filter: [
              `userEmployeeProfileID=="${userEmployeeProfileID}"`,
              makeFilterDateRange('date', [start, end]),
            ]
              .filter(Boolean)
              .join('&&'),
            select: ['id', 'fromTime', 'toTime', 'isInClinic', 'date'].join(','),
          });

          return { data: value };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (_, __, arg) => [
        {
          type: RTK_TAGS.EMPLOYEE_SCHEDULES,
          id: ['employee', arg.userEmployeeProfileID].join('__'),
        },
      ],
    }),
    employeeProfileSchedulesByRange: builder.query<
      Pick<UserEmployeeProfileSchedule, 'id' | 'fromTime' | 'toTime' | 'isInClinic' | 'date'>[],
      { userEmployeeProfileID: string; startDate: DateValue; endDate: DateValue }
    >({
      queryFn: async ({ userEmployeeProfileID, startDate, endDate }) => {
        const start = startOfDay(convertToDate(startDate));
        const end = endOfDay(convertToDate(endDate));
        try {
          const {
            data: { value },
          } = await ServiceUserEmployeeProfileSchedules.getAllDynamic({
            filter: [
              `userEmployeeProfileID=="${userEmployeeProfileID}"`,
              makeFilterDateRange('date', [start, end]),
            ]
              .filter(Boolean)
              .join('&&'),
            select: ['id', 'fromTime', 'toTime', 'isInClinic', 'date'].join(','),
          });

          return { data: value };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (_, __, arg) => [
        {
          type: RTK_TAGS.EMPLOYEE_SCHEDULES,
          id: ['employee', arg.userEmployeeProfileID].join('__'),
        },
      ],
    }),
    employeeProfileSchedulePost: builder.mutation<
      UserEmployeeProfileSchedule,
      Omit<ModelApiUserEmployeeProfileSchedule, 'id'>
    >({
      queryFn: async (args) => {
        try {
          const { data } = await ServiceUserEmployeeProfileSchedules.post(args);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (_, __, arg) => [
        {
          type: RTK_TAGS.EMPLOYEE_SCHEDULES,
          id: ['employee', arg.userEmployeeProfileID].join('__'),
        },
      ],
    }),
    employeeProfileSchedulePatch: builder.mutation<
      void,
      PatchPartial<ModelApiUserEmployeeProfileSchedule, 'id' | 'userEmployeeProfileID'>
    >({
      queryFn: async (args) => {
        try {
          await ServiceUserEmployeeProfileSchedules.patch(args);
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (_, __, arg) => [
        {
          type: RTK_TAGS.EMPLOYEE_SCHEDULES,
          id: ['employee', arg.userEmployeeProfileID].join('__'),
        },
      ],
    }),
    employeeProfileScheduleDelete: builder.mutation<
      UserEmployeeProfileSchedule,
      PatchPartial<ModelApiUserEmployeeProfileSchedule, 'id' | 'userEmployeeProfileID'>
    >({
      queryFn: async (args) => {
        try {
          const { data } = await ServiceUserEmployeeProfileSchedules.delete(args);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (_, __, arg) => [
        {
          type: RTK_TAGS.EMPLOYEE_SCHEDULES,
          id: ['employee', arg.userEmployeeProfileID].join('__'),
        },
      ],
    }),
  }),
});
