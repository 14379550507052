import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actionDietDiariesReportLoadData } from './actions';
import { DietDiariesReportFilters, GridModel, ViewOptions } from '../models';
import { PREFIX } from './helpers';
import { DynamicPagination } from 'utils/service';

interface State {
  isLoading: boolean;
  isInit: boolean;
  filters: DietDiariesReportFilters;
  data: GridModel[];
  pagination: DynamicPagination;
}

const initialState = (): State => {
  return {
    isLoading: false,
    isInit: false,
    filters: {
      search: '',
      dateRange: [],
      assignedToEmployeeID: '',
      patientIDs: [],
      isViewed: ViewOptions.ALL,
    },
    data: [],
    pagination: {
      count: 0,
      take: 50,
      page: 1,
    },
  };
};

const slice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<Partial<DietDiariesReportFilters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    toggleItem(state, action: PayloadAction<{ id: string; value: boolean }>) {
      const item = state.data.find((item) => item.id === action.payload.id);

      if (!item) return state;

      item.__checked = action.payload.value;
    },
    toggleAll(state, action: PayloadAction<boolean>) {
      state.data.forEach((item) => {
        item.__checked = action.payload;
      });
    },
    setPagination(state, action: PayloadAction<Partial<Pick<DynamicPagination, 'page' | 'take'>>>) {
      state.pagination = { ...state.pagination, ...action.payload };
    },
  },
  extraReducers(build) {
    build
      .addCase(actionDietDiariesReportLoadData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actionDietDiariesReportLoadData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.value.map((item) => {
          return { ...item, __checked: false };
        });
        state.pagination.count = action.payload.count || 0;
      })
      .addCase(actionDietDiariesReportLoadData.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});
export const dietDiariesReportActions = slice.actions;
export const dietDiariesReducer = slice.reducer;
