import React, { memo, useCallback, useMemo, useRef } from 'react';
import clsx from 'clsx';
import type { XYCoord } from 'dnd-core';
import { useAppDispatch, useAppSelector } from 'store';
import { Button, Collapse } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import SettingsIcon from '@material-ui/icons/Settings';
import { useI18n } from 'AurionCR/components';
import {
  PdfFormDocumentInput,
  pdfFormDocumentMerge,
  pdfFormDocumentPageInputModify,
  pdfFormDocumentScrollToInput,
  pdfFormDocumentSwap,
} from '../../../store';
import { FieldInputID } from './field-inputID';
import { FieldTitle } from './field-title';
import { FieldMaxLength } from './field-max-length';
import { FieldFontSize } from './field-font-size';
import { FieldIsRequired } from './field-is-required';
import { FieldBackground } from './field-background';
import { TestValue } from './test-value';
import style from './index.module.scss';
import { FormDocumentFieldInputID } from 'services/form-document-inputs';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { useDrag, useDrop } from 'react-dnd';

export interface iFormInput {
  pageNumber: number;
  index: number;
}

export interface iFormInputField<K extends keyof PdfFormDocumentInput> {
  value: PdfFormDocumentInput[K];
  onChange: (value: Partial<PdfFormDocumentInput>, triggerUpdateFabric?: boolean) => void;
}

export const FormInput = memo(({ pageNumber, index }: iFormInput) => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  //state
  const {
    id,

    width,
    height,

    _open,
    _value,

    inputID,
    formDocumentFieldType,
    isRequired,
    fontSize,
    // fontFamily,
    title,
    maxLength,
    background,
  } = useAppSelector((state) => state.pdfFormDocument.pages[pageNumber].inputs[index]);
  const { eventSelectFabricItem } = useAppSelector((state) => state.pdfFormDocument);
  // computed
  const active = useMemo(() => eventSelectFabricItem?.id === id, [eventSelectFabricItem, id]);
  const showFontProps = useMemo(
    () => ![FormDocumentFieldInputID.signature].includes(inputID),
    [inputID],
  );
  // handlers
  const onScrollTo = useCallback(() => {
    dispatch(pdfFormDocumentScrollToInput({ pageNumber, index }));
  }, [dispatch, pageNumber, index]);
  const onDelete = useCallback(() => {
    dispatch(pdfFormDocumentMerge({ eventRemove: { pageNumber, id } }));
  }, [pageNumber, id, dispatch]);
  const onSettings = useCallback(() => {
    dispatch(pdfFormDocumentPageInputModify({ id, pageNumber, _open: !_open }));
  }, [pageNumber, id, _open, dispatch]);
  const onChange = useCallback(
    (value, triggerUpdateFabric) => {
      dispatch(pdfFormDocumentPageInputModify({ ...value, id, pageNumber }));
      if (triggerUpdateFabric) {
        dispatch(pdfFormDocumentMerge({ eventUpdateFabricPage: pageNumber }));
      }
    },
    [id, pageNumber, dispatch],
  );
  // drag and drop
  const ref = useRef<HTMLDivElement>(null);
  // @ts-ignore
  const [{ isDragging }, drag] = useDrag({
    item: { type: `pdf-form-input_page-${pageNumber}`, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [, drop] = useDrop({
    accept: `pdf-form-input_page-${pageNumber}`,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
      dispatch(pdfFormDocumentSwap({ from: dragIndex, to: hoverIndex, pageNumber }));

      item.index = hoverIndex;
    },
  });
  drag(drop(ref));
  //render
  return (
    <div
      className={clsx(style.wrapper, active && style.active)}
      style={{ opacity: isDragging ? 0 : 1 }}
      ref={ref}
    >
      <div className={style.dragAndDrop} ref={drag}>
        <DragIndicatorIcon className={style.icon} />
      </div>
      <div className={style.main}>
        <Button
          className={style.btnFocusInput}
          variant="outlined"
          color="secondary"
          title={t('focus-input')}
          onClick={onScrollTo}
        >
          <GpsFixedIcon className={style.icon} />
        </Button>
        <FieldInputID value={inputID} onChange={onChange} />
        <Button
          className={clsx(style.btnSettings, _open && style.open)}
          color="secondary"
          onClick={onSettings}
        >
          <SettingsIcon className={style.icon} />
        </Button>
        <Button className={style.btnDelete} onClick={onDelete}>
          <DeleteIcon className={style.icon} />
        </Button>
      </div>
      <Collapse in={_open} timeout="auto" unmountOnExit>
        <div className={style.inner}>
          <div className={style.defaultProps}>
            <FieldTitle value={title} onChange={onChange} />
            <FieldBackground value={background} onChange={onChange} />
            <FieldIsRequired value={isRequired} onChange={onChange} />
          </div>
          {showFontProps && (
            <div className={style.fontProps}>
              <FieldFontSize
                value={fontSize}
                formDocumentFieldType={formDocumentFieldType}
                onChange={onChange}
              />
              <FieldMaxLength value={maxLength} onChange={onChange} />
            </div>
          )}
          <div className={style.testValueWrapper}>
            <TestValue
              value={_value}
              onChange={onChange}
              width={width}
              height={height}
              formDocumentFieldType={formDocumentFieldType}
            />
          </div>
        </div>
      </Collapse>
    </div>
  );
});

export default FormInput;
