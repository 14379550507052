import React, { FC, memo, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';

interface Props {
  onClose: () => void;
  disabled?: boolean;
}
const Footer: FC<Props> = ({ onClose, disabled }) => {
  const { t } = useTranslate();
  const onCancel = useCallback((e) => {
    e.stopPropagation();
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="grid-editor-footer">
      <Button color="secondary" type="submit" disabled={disabled}>
        {t('save')}
      </Button>
      <Button color="secondary" onClick={onCancel} disabled={disabled}>
        {t('cancel')}
      </Button>
    </div>
  );
};

export default memo(Footer);
