import { useCallback, useEffect, useReducer } from 'react';
import { apiUserPatientProfileDocuments } from 'services/user-patient-profile-documents';
import { useMountedRef } from 'hooks';

export interface Detail {
  id: string;
  catalogName?: string | null | undefined;
  includeForm29?: boolean | null | undefined;
}

interface DetailItem extends Detail {
  isLoading: boolean;
  url: string | null | undefined;
}
interface DetailsReducerState {
  details: DetailItem[];
}
type DetailsReducerActions =
  | { type: 'INIT'; payload: Detail[] }
  | { type: 'SET_LOADING'; payload: { detailID: string; isLoading: boolean } }
  | { type: 'SET_URL'; payload: { detailID: string; url: null | string } };
const detailsReducer = (
  state: DetailsReducerState,
  action: DetailsReducerActions,
): DetailsReducerState => {
  switch (action.type) {
    case 'INIT': {
      return {
        details: action.payload.map((detail) => ({
          ...detail,
          isLoading: false,
          url: null,
        })),
      };
    }
    case 'SET_LOADING': {
      return {
        details: state.details.map((detail) => {
          if (detail.id !== action.payload.detailID) return detail;
          return { ...detail, isLoading: action.payload.isLoading };
        }),
      };
    }
    case 'SET_URL': {
      return {
        details: state.details.map((detail) => {
          if (detail.id !== action.payload.detailID) return detail;
          return { ...detail, url: action.payload.url };
        }),
      };
    }
    default:
      return state;
  }
};

interface UseReducerDetailsOption {
  userPatientProfilePrescriptionID: string;
  details: Detail[];
}
export const useReducerDetails = (options: UseReducerDetailsOption) => {
  const { userPatientProfilePrescriptionID, details } = options;
  const [state, dispatch] = useReducer(detailsReducer, {
    details: [],
  });

  const [triggerGetUrl] =
    apiUserPatientProfileDocuments.useLazyGetUserPatientProfileDocumentForm29GimelQuery();

  const mountedRef = useMountedRef();
  const onGetDocumentUrl = useCallback(
    async (detailID: string) => {
      dispatch({ type: 'SET_LOADING', payload: { detailID, isLoading: true } });

      const result = await triggerGetUrl(
        {
          userPatientProfilePrescriptionID: userPatientProfilePrescriptionID,
          userPatientProfilePrescriptionDetailID: detailID,
        },
        true,
      );

      if (!mountedRef.current) return;

      dispatch({ type: 'SET_LOADING', payload: { detailID, isLoading: false } });

      if (result.data) {
        dispatch({ type: 'SET_URL', payload: { detailID, url: result.data.documentURL || null } });
      }
    },
    [dispatch, triggerGetUrl, userPatientProfilePrescriptionID, mountedRef],
  );

  useEffect(() => {
    if (details.length > 0) {
      dispatch({ type: 'INIT', payload: details });
    }
  }, [details]);

  return { state, dispatch, onGetDocumentUrl };
};
