import React, { useMemo } from 'react';
import { Button, Typography } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { useOpen } from 'AurionCR/components/hooks';
import { DialogPharmacyDrugQuantities } from 'components/dilaog-pharmacy-drug-quantities';
import style from './index.module.scss';

interface ValidityValueAddProps {
  drugID: string;
  pharmaDrugID: string;
  dosageFormTotalDesc: string;
}

export const ValidityValueAdd: React.FC<ValidityValueAddProps> = ({
  drugID,
  pharmaDrugID,
  dosageFormTotalDesc,
}) => {
  const { t } = useTranslate();
  const { isOpen, handleOpen, handleClose } = useOpen();
  const initData = useMemo(() => ({ dosageFormTotalDesc }), [dosageFormTotalDesc]);
  return (
    <>
      <Button variant={'contained'} color={'primary'} size={'small'} onClick={handleOpen}>
        {t('add-manually')}
      </Button>
      {isOpen && (
        <DialogPharmacyDrugQuantities
          itemID={true}
          initData={initData}
          drugID={drugID}
          pharmacyDrugID={pharmaDrugID}
          onClose={handleClose}
        />
      )}
    </>
  );
};

interface ValidityValueErrorProps {
  drugName: string;
}

export const ValidityValueError: React.FC<ValidityValueErrorProps> = ({ drugName }) => {
  const { tp } = useTranslate();
  return (
    <Typography className={style.error} title={tp('neopharm-verified-missing-drug-tooltip')}>
      {tp('neopharm-verified-missing-drug')}
    </Typography>
  );
};

interface Props {
  drugID: string;
  drugName: string;
  pharmaDrugID: string | null;
  quantity: number | null;
  dosageFormTotalDesc: string;
}
export const ValidityValue: React.FC<Props> = ({
  drugID,
  drugName,
  quantity,
  pharmaDrugID,
  dosageFormTotalDesc,
}) => {
  if (!pharmaDrugID) {
    return <ValidityValueError drugName={drugName} />;
  }
  if (!quantity) {
    return (
      <ValidityValueAdd
        drugID={drugID}
        pharmaDrugID={pharmaDrugID}
        dosageFormTotalDesc={dosageFormTotalDesc}
      />
    );
  }
  return <Typography>{quantity}</Typography>;
};
