import React from 'react';
import PaymentDialog from 'components/payment-dialog';
import { useOpen } from 'AurionCR/components/hooks';
import { composeFunctions } from 'utils';
import { BaseAction } from '../../models';
import { ButtonAction } from '../button-action';

interface Props extends BaseAction {
  userPatientProfileID: string;
}
export const ActionPayment: React.FC<Props> = ({
  userPatientProfileID,
  onDone,
  actionKey,
  isLoading,
}) => {
  const { isOpen, handleOpen, handleClose } = useOpen();

  return (
    <>
      <ButtonAction actionKey={actionKey} onClick={handleOpen} disabled={isLoading} />
      {isOpen && (
        <PaymentDialog
          userPatientProfileID={userPatientProfileID}
          onDone={composeFunctions(onDone, handleClose)}
          onCancel={handleClose}
        />
      )}
    </>
  );
};
