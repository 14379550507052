import React, { useCallback, useState } from 'react';
import { Model } from '../../helpers';
import { patientEmitter } from 'components/dialog-patient/emitter';
import { ColumnTaskAction } from 'components/dialog-task';
import { useRequest, useRequestAlertError } from 'hooks';

interface Props {
  row: Model;
  onDone: () => void;
}
export const ColumnAction: React.FC<Props> = ({ row, onDone: onDoneTask }) => {
  const userPatientProfileID = String(row.userPatientProfileID);

  const [isLoadingDone, setIsLoadingDone] = useState(false);

  const requestDone = useCallback(async () => {
    onDoneTask();
    patientEmitter.emit('refresh-tasks', { patientID: userPatientProfileID });
  }, [userPatientProfileID, onDoneTask]);

  const onDone = useRequestAlertError(useRequest(requestDone, setIsLoadingDone));

  return (
    <ColumnTaskAction
      row={row}
      isLoading={isLoadingDone}
      onDone={onDone}
      userPatientProfileID={userPatientProfileID}
    />
  );
};
