import React, { memo, useMemo } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import clsx from 'clsx';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';
import { useSourceEmployees } from '../../hooks';

interface Props extends TypographyProps {
  userEmployeeProfileID: string | null | undefined;
}
export const TemplateEmployeeSource = memo<Props>(
  ({ userEmployeeProfileID, className, ...rest }) => {
    const { t } = useTranslate();
    const { loading, map } = useSourceEmployees(false);

    const employee = userEmployeeProfileID ? map[userEmployeeProfileID] : null;

    const label = useMemo(() => {
      if (loading) {
        return t('loading');
      }

      if (!employee) return '';

      return [employee.title, employee.isActive ? null : `[${t('inactive')}]`]
        .filter(Boolean)
        .join(' ');
    }, [employee, t, loading]);

    return (
      <Typography className={clsx(style.root, className)} {...rest}>
        {label}
      </Typography>
    );
  },
);
