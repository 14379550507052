import React, { memo, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import pdfjs, { PDFDocumentProxy } from 'modules/pdfjs';
import fontkit from '@pdf-lib/fontkit';
import { sortBy } from 'lodash-es';
import { FormDocumentFieldType } from 'services/form-document-inputs';
import style from './index.module.scss';
import {
  PdfDocumentSubmit,
  pdfDocumentSubmitMerge,
  pdfDocumentSubmitSave,
  pdfDocumentSubmitScrollToInput,
} from '../../store';
import Scroll from './scroll';
import PdfPage from './pdf-page';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { hexAToRGBA } from 'components/helpers';
import { ServiceMediaPrivate } from 'services/media-private-services';

export const Pdf = memo(() => {
  const dispatch = useAppDispatch();
  //state
  const { pages, width, initData, scale, triggerSubmit } = useAppSelector(
    (state) => state.pdfDocumentSubmit,
  );
  const [pdf, setPdf] = useState<PDFDocumentProxy | undefined>();

  // handlers
  const onLoad = useCallback(async () => {
    if (initData) {
      // TODO: NEED LOAD ONLY ONE AND AFTER ADD/REBUILD ONLY ONE PAGE/ELEMENT
      // TODO: NEED LOAD ONLY ONE AND AFTER ADD/REBUILD ONLY ONE PAGE/ELEMENT
      // TODO: NEED LOAD ONLY ONE AND AFTER ADD/REBUILD ONLY ONE PAGE/ELEMENT
      // TODO: NEED LOAD ONLY ONE AND AFTER ADD/REBUILD ONLY ONE PAGE/ELEMENT
      // TODO: NEED LOAD ONLY ONE AND AFTER ADD/REBUILD ONLY ONE PAGE/ELEMENT

      const { data: blobPdf } = await ServiceMediaPrivate.getFile(initData.pdfUrl);
      const arrayBufferPdf = await blobPdf.arrayBuffer();

      const pdfDoc = await PDFDocument.load(arrayBufferPdf);

      pdfDoc.registerFontkit(fontkit);
      const OpenSans = await import(
        /* webpackChunkName: 'FontOpenSansBold' */ 'fonts/fontOpenSansBold'
      );
      const font = await pdfDoc.embedFont(OpenSans.default);
      const fontCheckBox = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);
      const checkBox = '✓';

      // create pages
      const pages: PdfDocumentSubmit['pages'] = Array.from({
        length: pdfDoc.getPageCount() || 1,
      }).map((val, i) => ({
        index: i,
        width: 0,
        height: 0,
        inputs: [],
      }));
      let focusFormInputID = '';
      // fill pages from init data
      sortBy(initData.formDocumentInputs, ['rank']).forEach((item) => {
        const page = pages[item.pageNumber];
        if (page) {
          page.inputs.push(item);
          // focus next signature
          if (
            !focusFormInputID &&
            ((item.formDocumentFieldType === FormDocumentFieldType.signature &&
              !item.defaultValue) ||
              (item.formDocumentFieldType === FormDocumentFieldType.checkbox &&
                item.defaultValue === null))
          ) {
            focusFormInputID = item.id;
          }
        }
      });

      for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
        const { index, inputs } = pages[pageIndex];
        if (inputs.length) {
          const page = pdfDoc.getPage(index);
          const { height: pageHeight } = page.getSize();
          for (let inputIndex = 0; inputIndex < inputs.length; inputIndex++) {
            const {
              defaultValue,
              coordX,
              coordY,
              formDocumentFieldType: type,
              width,
              height,
              background,
              ...rest
            } = inputs[inputIndex];
            // background
            const width_ = width / scale;
            const height_ = height / scale;
            const x = coordX / scale;
            const y = coordY / scale;
            if (background) {
              const [r, g, b, a] = hexAToRGBA(background);
              page.drawRectangle({
                x,
                y: pageHeight - height_ - y,
                width: width_,
                height: height_,
                color: rgb(r / 255, g / 255, b / 255),
                opacity: a || 1,
              });
            }
            if (defaultValue) {
              if (type === FormDocumentFieldType.checkbox) {
                const { fontSize } = rest;
                const textHeight = fontCheckBox.heightAtSize(fontSize);
                page.drawText(checkBox, {
                  x: x + (width_ - fontCheckBox.widthOfTextAtSize(checkBox, fontSize)) * 0.5,
                  y: pageHeight - y - height_ * 0.5 - textHeight * 0.3,
                  size: fontSize,
                  lineHeight: fontSize * 1.2,
                  font: fontCheckBox,
                });
              } else if (type === FormDocumentFieldType.signature) {
                const pngImage = await pdfDoc.embedPng(defaultValue as string);
                page.drawImage(pngImage, {
                  x,
                  y: pageHeight - height_ - y,
                  width: width_,
                  height: height_,
                });
              } else {
                const { fontSize /*fontFamily*/ } = rest;
                // const font = await pdfDoc.embedFont(fontFamily);
                const textHeight = font.heightAtSize(fontSize);
                const text = String(defaultValue);
                page.drawText(text, {
                  x: initData?.isRTL ? x + width_ - font.widthOfTextAtSize(text, fontSize) : x,
                  y: pageHeight - y - height_ * 0.5 - textHeight * 0.25,
                  size: fontSize,
                  lineHeight: fontSize * 1.2,
                  font,
                });
              }
            }
          }
        }
      }
      const pdfBytes = await pdfDoc.save();

      // preview
      const pdf = await pdfjs.getDocument(pdfBytes).promise;

      setPdf(pdf);
      // set focus
      if (focusFormInputID) {
        dispatch(pdfDocumentSubmitMerge({ pages }));
        setTimeout(() => {
          // @ts-ignore
          dispatch(pdfDocumentSubmitScrollToInput(focusFormInputID));
        }, 250);
      } else {
        dispatch(
          pdfDocumentSubmitMerge({
            pages,
            eventFocusItem: null,
            eventScrollTo: null,
            isReadyToSubmit: true,
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPdf, dispatch, initData]);
  // trigger Submit
  useEffect(() => {
    if (triggerSubmit && pdf) {
      pdf.getData().then((result) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          dispatch(pdfDocumentSubmitSave(String(reader.result)));
        };
        reader.readAsDataURL(new Blob([result], { type: 'application/pdf;charset=utf-8' }));
      });
      dispatch(pdfDocumentSubmitMerge({ triggerSubmit: '' }));
    }
  }, [triggerSubmit, pdf, dispatch, initData]);
  // init
  useEffect(() => {
    onLoad().catch((e) => {
      console.log(e);
    });
  }, [onLoad]);
  //render
  return (
    <div className={style.pdf} style={{ minWidth: width + 20, overflow: 'hidden' }}>
      <Scroll>
        {pdf && pages.map(({ index }) => <PdfPage key={index} pdf={pdf} pageNumber={index} />)}
      </Scroll>
    </div>
  );
});
