import React from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { Button, ButtonProps } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

interface Props extends ButtonProps {}

export const ButtonWatchAll: React.FC<Props> = ({ className, children, ...rest }) => {
  return (
    <Button
      className={clsx(style.root, className)}
      variant={'contained'}
      color={'primary'}
      {...rest}
    >
      <VisibilityIcon className={style.icon} /> {children}
    </Button>
  );
};
