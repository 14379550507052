import { convertToDate, DateValue } from 'utils/dates';

type Paths<T> = keyof T | string;

type FilterVal = string | undefined | null | boolean;
class MergeFilters {
  private value: Array<FilterVal>;
  constructor(value: Array<FilterVal>) {
    this.value = value.filter(Boolean);
  }
  join(separator: string) {
    if (this.value.length === 0) {
      return undefined;
    }
    return createStart(this.value.length, this.value.join(separator));
  }
}

const prepareValue = (value?: string) => {
  return String(value).toLowerCase().trim().replace(/"/gi, '\\"');
};
const createStart = (count: number, query: string) => {
  return count > 1 ? `(${query})` : query;
};
export const createFilterEquals = <T extends object = {}>(
  name: Paths<T> | Paths<T>[],
  value: any,
) => {
  const names = Array.isArray(name) ? name : [name];

  return value
    ? createStart(names.length, names.map((_n) => `${_n}=="${prepareValue(value)}"`).join('||'))
    : undefined;
};

export const createFilterNotEquals = <T extends object = {}>(
  name: Paths<T> | Paths<T>[],
  value: any,
) => {
  const names = Array.isArray(name) ? name : [name];

  return value
    ? createStart(names.length, names.map((_n) => `${_n}!="${prepareValue(value)}"`).join('||'))
    : undefined;
};

export const createFilterNumberEquals = <T extends object = {}>(
  name: Paths<T> | Paths<T>[],
  value: any,
) => {
  if (!Number(value)) {
    return '';
  }

  return createFilterEquals(name, value);
};

export const createFilterValueArrayEquals = <T extends object = {}>(
  name: Paths<T> | Paths<T>[],
  value: any,
  separator = '||',
) => {
  if (!Array.isArray(value)) return undefined;
  if (value.length === 0) return undefined;
  return createStart(value.length, value.map((v) => createFilterEquals(name, v)).join(separator));
};

export const createFilterEqualsSome = <T extends object = {}>(name: Paths<T>, value: string[]) => {
  if (!Array.isArray(value)) return undefined;
  let values = value.filter(Boolean);
  if (values.length === 0) return undefined;

  let valueStr = values.map((val) => `"${val}"`).join(',');
  return `(new[]{${valueStr}}).Contains(${name})`;
};
export const createFilterNotEqualsSome = <T extends object = {}>(
  name: Paths<T>,
  value: string[],
) => {
  if (!Array.isArray(value)) return undefined;
  let values = value.filter(Boolean);
  if (values.length === 0) return undefined;

  let valueStr = values.map((val) => `"${val}"`).join(',');
  return `((new[]{${valueStr}}).Contains(${name})==false)`;
};

export const createFilterContains = <T extends object = {}>(
  name: Paths<T> | Paths<T>[],
  value: any,
) => {
  const names = Array.isArray(name) ? name : [name];

  return value
    ? `(${names.map((_n) => `${_n}.ToLower().contains("${prepareValue(value)}")`).join('||')})`
    : null;
};
export const createFilterEndsWith = <T extends object = {}>(
  name: Paths<T> | Paths<T>[],
  value: any,
) => {
  const names = Array.isArray(name) ? name : [name];

  return value
    ? `(${names.map((_n) => `${_n}.ToLower().EndsWith("${prepareValue(value)}")`).join('||')})`
    : null;
};

export const createFilterContainsPartial = <T extends object = {}>(
  name: Paths<T> | Paths<T>[],
  value: any,
) => {
  if (!value) return null;

  const words = String(value).split(' ').filter(Boolean);

  if (words.length === 0) return null;

  return mergeFilters(...words.map((word) => createFilterContains(name, word))).join('&&');
};

export const orderBy = (name?: string, order?: string) => {
  if (!name) return undefined;

  return name
    .split(',')
    .filter(Boolean)
    .map((n) => [n.trim(), order].filter(Boolean).join(' '))
    .join(',');
};

export const createFilterSmartSearch = <T extends object = {}>(
  name: Paths<T> | Paths<T>[],
  value: any,
) => {
  if (!value) return null;

  const names = Array.isArray(name) ? name : [name];
  const field = names.map((_name) => `${_name}.ToLower().replace(" ",String.Empty)`).join('+');
  return createFilterContains<T>(`(${field})` as Paths<T>, String(value).replace(/ /g, ''));
};
export const createFilterDateISO = <T extends object = {}>(name: Paths<T>, value: DateValue[]) => {
  const [start, end] = value;

  if (!start || !end) {
    return;
  }

  const startDate = convertToDate(start).toISOString();
  const endDate = convertToDate(end).toISOString();

  return `(${name}>=DateTime("${startDate}")&&${name}<=DateTime("${endDate}"))`;
};
export const createFilterDateMoreISO = <T extends object = {}>(
  name: Paths<T>,
  value: DateValue,
) => {
  if (!value) {
    return;
  }

  const date = convertToDate(value).toISOString();

  return `(${name}>DateTime("${date}"))`;
};
export const createFilterDateMoreOrEqualsISO = <T extends object = {}>(
  name: Paths<T>,
  value: DateValue,
) => {
  if (!value) {
    return;
  }

  const date = convertToDate(value).toISOString();

  return `(${name}>=DateTime("${date}"))`;
};
export const createFilterDateLessISO = <T extends object = {}>(
  name: Paths<T>,
  value: DateValue,
) => {
  if (!value) {
    return;
  }

  const date = convertToDate(value).toISOString();

  return `(${name}<DateTime("${date}"))`;
};
export const createFilterDateLessOrEqualsISO = <T extends object = {}>(
  name: Paths<T>,
  value: DateValue,
) => {
  if (!value) {
    return;
  }

  const date = convertToDate(value).toISOString();

  return `(${name}<=DateTime("${date}"))`;
};
export const createFilterPhoneNumber = <T extends object = {}>(name: Paths<T>, value: any) => {
  if (!value) return null;

  const field = `${name}.replace("-", "")`;
  const value_ = String(value).replace(/\D+/g, '').slice(-7);

  if (value_.length < 3) {
    return undefined;
  }

  return createFilterContains<T>(`(${field})` as Paths<T>, value_);
};

export const mergeFilters = (...filters: FilterVal[]) => {
  return new MergeFilters(filters);
};

export const select = <T extends Record<string, any> = Record<string, any>>(
  ...args: (Paths<T> | string)[]
) => {
  return args
    .join(',')
    .replace(/  +/g, '') // remove extra spaces
    .replace(/\n/gm, '') // remove new lines
    .replace(' .', '.'); // remove spaces for methods
};
