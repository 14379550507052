import React, { forwardRef, memo, ReactEventHandler, useCallback, useRef, useState } from 'react';
import style from './index.module.scss';
import clsx from 'clsx';
import { useMergeRef } from '../../hooks/use-merge-ref';

interface Props {
  srcDoc: string | undefined;
  title: string;
  editable?: boolean;
}
const Component = forwardRef<HTMLIFrameElement, Props>((props, outerRef) => {
  const { title, editable, srcDoc } = props;
  const innerRef = useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = useState(0);
  const onLoad = useCallback<ReactEventHandler<HTMLIFrameElement>>((event) => {
    if (!innerRef.current) return;
    const object = innerRef.current;
    const doc = object.contentWindow?.document;
    if (!doc) return;

    setHeight(doc.documentElement.scrollHeight);
  }, []);

  const mergedRef = useMergeRef(outerRef, innerRef);

  return (
    <div className={style.root}>
      <iframe
        className={clsx(style.frame, {
          [style.frameReadOnly]: !editable,
        })}
        title={title || 'HtmlIframePreview'}
        ref={mergedRef}
        scrolling={'no'}
        srcDoc={srcDoc}
        onLoad={onLoad}
        style={{ height: `${height}px` }}
      />
    </div>
  );
});

export const HtmlIframeView = memo(Component) as typeof Component;
