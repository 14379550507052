import React, { useMemo } from 'react';
import { ListItemProps, ListProps } from '@material-ui/core';
import { MeetingList, MeetingItem } from 'components/meetings-list';
import { useSourceClinicalMeetingSubjects } from '../../../../../hooks';
import { PatientTodayClinicalMeeting } from 'services/clinical-meetings';

export interface ListItemMeetingProps extends Pick<ListItemProps, 'disableGutters'> {}

interface Props extends Omit<ListProps, 'onSelect' | 'onChange'> {
  selectedID: string | null;
  onSelect: (selectedID: null | string) => void;
  list: PatientTodayClinicalMeeting[];
  ItemsProps?: Partial<ListItemMeetingProps>;
  disabled: boolean;
}
export const ListFutureClinicalMeetings: React.FC<Props> = ({
  list,
  selectedID,
  disabled,
  onSelect,
  ...rest
}) => {
  const { map: typeMap } = useSourceClinicalMeetingSubjects();
  const meetingsList = useMemo<Array<MeetingItem>>(
    () =>
      list.map((meeting) => {
        return {
          ...meeting,
          type: typeMap[meeting.clinicalMeetingSubjectID],
          employee: { fullName: meeting.employee },
        };
      }),
    [list, typeMap],
  );

  return (
    <MeetingList
      value={selectedID}
      onChange={onSelect}
      options={meetingsList}
      disabled={disabled}
      {...rest}
    />
  );
};
