import React from 'react';

import { getSearchFilter } from 'utils/front-filters';
import { useSourcePatients } from 'components/hooks';
import { Divider, Typography } from '@material-ui/core';
import { Stack } from 'components/stack';
import style from './index.module.scss';
import { AppSelect, AppSelectProps } from 'components/app-select';
import { ListboxComponent } from 'components/app-select/components';
import clsx from 'clsx';

const getOptionLabel = (option: Option) => {
  return [option.firstName, option.lastName].filter(Boolean).join(' ');
};

const getOptionDisabled = () => false;

interface OptionItemProps {
  id: string;
  title?: string;
  idNumber?: string;
  isActive: boolean;
}

const OptionItem: React.FC<OptionItemProps> = ({ title, idNumber, isActive }) => {
  return (
    <Stack
      className={clsx(style.item, !isActive && style.inactive)}
      spacing={1}
      divider={<Divider className={style.divider} orientation={'vertical'} flexItem />}
    >
      <Typography noWrap className={clsx(style.itemTitle)}>
        {title}
      </Typography>
      {idNumber && (
        <Typography noWrap className={style.itemId}>
          {idNumber}
        </Typography>
      )}
    </Stack>
  );
};

type Option = {
  id: string;
  firstName: string;
  lastName: string;
  idNumber?: string;
  isActive: boolean;
};

const renderOption = (option: Option) => {
  return (
    <OptionItem
      id={option.id}
      title={getOptionLabel(option)}
      idNumber={option.idNumber}
      isActive={option.isActive}
    />
  );
};
const filterOptions = <T extends Option>(options: T[], state: any) => {
  return options.filter(
    getSearchFilter<T>({
      value: state.inputValue,
      fields: ['firstName', 'lastName', 'idNumber'],
    }),
  );
};

const ModifiedListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>((props, ref) => <ListboxComponent {...props} ref={ref} renderOption={renderOption as any} />);

interface Props<
  T extends Record<string, any> = { id: string; title: string; isActive?: boolean },
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
> extends Partial<AppSelectProps<T, Multiple, DisableClearable, FreeSolo>> {
  source?: T[];
  disabled?: boolean;
  loading?: boolean;
  label?: React.ReactNode;
}

export const SelectPatients = <
  T extends Option,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>({
  loading,
  ...rest
}: Props<T, Multiple, DisableClearable, FreeSolo>) => {
  const sourcePatients = useSourcePatients();

  return (
    <AppSelect
      filterOptions={filterOptions}
      options={sourcePatients.data as any}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      ListboxComponent={ModifiedListboxComponent}
      getOptionDisabled={getOptionDisabled}
      {...rest}
      loading={sourcePatients.loading || loading}
    />
  );
};
