import React from 'react';
import { AppInputMask, AppInputMaskedProps } from 'components/app-input-masked';
import { useAppSelector } from 'store';
import { selectLanguageIsRtl } from 'store/languages';

export const AppPhoneInput: React.FC<Omit<AppInputMaskedProps, 'MaskOptions'>> = (props) => {
  const isRtl = useAppSelector(selectLanguageIsRtl);
  return (
    <AppInputMask
      {...props}
      placeholder={'050-0000000'}
      dir={'ltr'}
      disableClearable
      inputProps={{
        style: { textAlign: isRtl ? 'right' : undefined },
      }}
      MaskOptions={{
        mask: '\\050-#000000',
        definitions: { '#': /[1-9]/ },
      }}
    />
  );
};
