import React, {memo, useCallback} from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {notifyRequestResult} from "../store/modules/notify";
import {useDispatch} from "react-redux";
import {composeFunctions} from "../../utils";

interface Props {
    text?: string;
    message?: string;
    onCopied?: () => void
    children: React.ReactNode
}
export const  CopyPathToClipboard = memo<Props>((
  {
    text = '',
    message = 'copied',
    children,
      onCopied
  }) => {
  const dispatch = useDispatch();
  const onCopy = useCallback(() => {
      dispatch(notifyRequestResult(message, 'success', {autoHideDuration: 750}))
  }, [dispatch, message])
  return (
    <CopyToClipboard
      text={text}
      onCopy={composeFunctions(onCopy, onCopied)}>
      {children}
    </CopyToClipboard>
  )
});
export default CopyPathToClipboard;
