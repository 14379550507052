import { API_NOTEBOOKS, Notebook } from 'services/notebook';
import {
  createFilterContains,
  createFilterNumberEquals,
  mergeFilters,
} from 'utils/dynamic-helpers';
import { permissionKey } from 'services/user-employee-profile-permissions';

type Model = {
  roles: string[];
};

export default (): any => ({
  id: 'grid_notebooks',
  store: {
    get: {
      url: API_NOTEBOOKS.GET_ALL_DYNAMIC,
    },
    create: {
      url: API_NOTEBOOKS.POST,
    },
    patch: {
      url: API_NOTEBOOKS.PATCH,
    },
    delete: {
      url: API_NOTEBOOKS.DELETE,
      filed: 'labelKey',
    },
    sortable: {
      field: 'rowIndex',
      order: 'asc',
    },
  },
  controls: ['edit'],
  controlsDropDown: [
    'edit',
    { type: 'delete', title: 'delete', permission: permissionKey('isAllowToDeleteAnything') },
  ],
  toolbar: {
    title: 'notebooks',
    filters: [
      {
        field: 'labelKey',
        options: {
          type: 'string',
          valueTemplate: ({ value }: any) =>
            mergeFilters(
              createFilterContains<Notebook>(['labelKey', 'externalSystemTitle'], value),
              createFilterNumberEquals<Notebook>('rowIndex', value),
            ).join('||'),
        },
      },
      {
        field: 'migatedFromOldSystem',
        options: {
          type: 'switcher',
          label: 'migrated-from-old-system',
          value: false,
        },
      },
    ],
  },
  columns: [
    {
      title: 'row-index',
      field: 'rowIndex',
      width: 20,
    },
    {
      title: 'title',
      field: 'labelKey',
      editor: {
        type: 'string',
        required: true,
      },
      tdClasses: 'mobile-20',
    },
    {
      title: 'roles',
      field:
        'userEmployeeProfilePermissionNotebooks.Select(k => k.userEmployeeProfilePermission.title) as roles',
      template: (data: Model) => {
        return data.roles.join(', ');
      },
      sortable: false,
      tdClasses: 'mobile-20',
    },
    {
      title: 'external-system-title',
      field: 'externalSystemTitle',
      tdClasses: 'mobile-20',
    },
    {
      title: 'migrated-from-old-system',
      field: 'migatedFromOldSystem',
      template: 'boolean',
    },
    {
      title: 'is-for-patient',
      field: 'isForPatient',
      template: 'boolean',
    },
    {
      title: 'is-for-clinical-meeting',
      field: 'isForClinicalMeeting',
      template: 'boolean',
    },
    {
      title: 'display-next-meeting-form',
      field: 'displayNextMeetingForm',
      template: 'boolean',
    },
    {
      title: 'display-side-effect-section',
      field: 'displaySideEffectSection',
      template: 'boolean',
    },
    {
      title: 'active',
      field: 'isActive',
      template: 'boolean',
    },
  ],
});
