import defaultReducer, { INITIAL_STATE } from 'AurionCR/store/modules/auth';
import { NOTIFICATION_LOCAL_STORAGE_KET } from 'configs/const';
import { ACTION_AUTH_TYPES, AuthActions } from './actions';

const initState = () => {
  const res = INITIAL_STATE();

  return {
    ...res,
    notificationDoNotAsk: localStorage.getItem(NOTIFICATION_LOCAL_STORAGE_KET) === 'true',
  };
};

export const appAuthReducer = (state = initState(), action: AuthActions) => {
  const result = defaultReducer(state, action);

  switch (action.type) {
    case 'AUTH_SUCCESS':
      return {
        ...result,
        user: result.user
          ? {
              ...result.user,
              userPosition: {
                ...result.user.userEmployeeProfilePermission,
              },
            }
          : result.user,
      };
    case ACTION_AUTH_TYPES.NOTIFICATION_SET: {
      localStorage.setItem(NOTIFICATION_LOCAL_STORAGE_KET, action.payload.notificationDoNotAsk);

      return {
        ...result,
        notificationDoNotAsk: localStorage.getItem(NOTIFICATION_LOCAL_STORAGE_KET) === 'true',
      };
    }
    default:
      return result;
  }
};
