import React from 'react';
import { ControlsAddPrev } from '../comtrols-add-prev';
import { SelectAddNewDrug } from '../select-add-new-drugs';

interface Props {
  onTasks: (tasks: string[][]) => void;
  userPatientProfileID: string;
  userPatientProfilePrescriptionID: string;
  onRefresh: () => void;
}

export const ControlsSlot: React.FC<Props> = ({
  onTasks,
  userPatientProfileID,
  userPatientProfilePrescriptionID,
  onRefresh,
}) => {
  return (
    <>
      <SelectAddNewDrug onTasks={onTasks} />
      <ControlsAddPrev
        userPatientProfileID={userPatientProfileID}
        userPatientProfilePrescriptionID={userPatientProfilePrescriptionID}
        onRefresh={onRefresh}
      />
    </>
  );
};
