import { MODEL_TYPE, Row } from './models';
import SmsIcon from '@material-ui/icons/Sms';
import CallIcon from '@material-ui/icons/Call';
import AssignmentIcon from '@material-ui/icons/Assignment';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DescriptionIcon from '@material-ui/icons/Description';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import SupportAgent from 'components/svg-icons/support-agent';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import { DataGridLightRowProps } from 'components/data-grid-light';
import { hexToRgb, rgba2hex } from 'components/helpers';

export const CONFIG_MODEL_TYPE = {
  [MODEL_TYPE.SMS]: { Icon: SmsIcon, color: '#27AE60' },
  [MODEL_TYPE.CALL]: { Icon: CallIcon, color: '#27AE60' },
  [MODEL_TYPE.PRESCRIPTION]: { Icon: AssignmentIcon, color: '#2798D4' },
  [MODEL_TYPE.DOCUMENT]: { Icon: InsertDriveFileIcon, color: '#2798D4' },
  [MODEL_TYPE.SIGNED_DOCUMENT]: { Icon: DescriptionIcon, color: '#2798D4' },
  [MODEL_TYPE.USER_DOCUMENT]: { Icon: AssignmentIndIcon, color: '#2798D4' },
  [MODEL_TYPE.SUBSCRIPTION]: { Icon: CardMembershipIcon, color: '#F8D000' },
  [MODEL_TYPE.SESSION]: { Icon: NewReleasesIcon, color: '#F8D000' },
  [MODEL_TYPE.MEDICAL_INFORMATION]: { Icon: LocalHospitalIcon, color: '#F8D000' },
  [MODEL_TYPE.CLINICAL_MEETING]: { Icon: EventAvailableIcon, color: '#F49511' },
  [MODEL_TYPE.SUPPORT_MEETING]: { Icon: SupportAgent, color: '#F49511' },
  [MODEL_TYPE.TASK]: { Icon: FlashOnIcon, color: '#F6AA41' },
  [MODEL_TYPE.NOTE]: { Icon: NoteAddIcon, color: '#eb5757' },
  [MODEL_TYPE.ACTIVITIES]: { Icon: LocalActivityIcon, color: '#2798D4' },
};

const OPACITY = 0.06;

export const calcBgColor = (type: MODEL_TYPE) => {
  const colorHex = CONFIG_MODEL_TYPE[type].color;
  const { r, g, b } = hexToRgb(colorHex);
  return rgba2hex(r, g, b, OPACITY);
};

export const calcRowProps: DataGridLightRowProps<Row> = (row) => {
  return {
    style: { backgroundColor: calcBgColor(row.type) },
  };
};
