import React, { useMemo } from 'react';
import { numberUS } from 'utils/numbers';
import { TemplateLtrOnly } from '../template-ltr-only';
import { BoxProps } from '@material-ui/core';

interface Props extends BoxProps {
  value: number | null | undefined;
  precision?: number;
}

export const TemplateNumber: React.FC<Props> = ({ value, precision, ...rest }) => {
  const formated = useMemo(() => numberUS(value, precision), [value, precision]);
  return <TemplateLtrOnly value={formated} {...rest} />;
};
