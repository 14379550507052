const API_ENDPOINT = 'Drugs';

export const API_DRUGS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<Drug, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<Drug, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface Drug extends Components.Schemas.Drug {}
