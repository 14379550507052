import React, { useEffect, useMemo, useRef, useState } from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { Box, BoxProps, ButtonBase } from '@material-ui/core';
import { usePatientWeights, UserBoxWeight } from 'components/dialog-patient';
import { useTranslate } from 'hooks/use-translate';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { useSourceWeightTypes } from 'components/hooks';
import { round } from 'lodash-es';
import { calcPatientWeightInfo } from 'utils/app-helpers';
import { usePatientSubscription } from '../dialog-patient/hooks';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { INPUT_TYPE_INDEXES } from 'services/input-type';
import { useOpen } from '../../AurionCR/components/hooks';
import { DialogInfo } from './components/dialog-info';

const formatWeightLost = (weight: number) => {
  const val = Math.abs(round(weight, 1));
  const sign = val === 0 ? '' : weight > 0 ? '-' : '+';
  return `${sign}${val}`;
};

interface Classes {
  root: string;
}

interface Props extends BoxProps {
  className?: string;
  classes?: Partial<Classes>;
  userPatientProfileID: string;
}

export const UserInfoWeightGrid: React.FC<Props> = ({
  className,
  classes,
  userPatientProfileID,
  ...rest
}) => {
  const { t } = useTranslate();

  const sourceSubscription = usePatientSubscription(userPatientProfileID);

  const sourceWeightTypes = useSourceWeightTypes();
  const [type, setType] = useState('');

  const refOnce = useRef(false);
  useEffect(() => {
    if (refOnce.current) return;
    const weightType = sourceWeightTypes.data.find(
      ({ rowIndex }) => rowIndex === INPUT_TYPE_INDEXES.WEIGHT_HOME,
    );
    if (!weightType) return;

    setType(weightType.id || '');
    refOnce.current = true;
  }, [sourceWeightTypes, refOnce]);

  const weightType = useMemo(() => {
    if (!type) return null;

    return sourceWeightTypes.map[type] || null;
  }, [type, sourceWeightTypes.map]);
  const onChangeType = (event: React.MouseEvent<HTMLElement>, val: string) => {
    setType(val);
  };

  const { data: patientInfo, isLoading: isLoadingPatient } =
    apiUserPatientProfile.useGetPatientDetailsFormWeightsQuery(userPatientProfileID);

  const sourceWeights = usePatientWeights(userPatientProfileID);

  const weights = useMemo(() => {
    if (!type) return sourceWeights.data;
    return sourceWeights.data.filter((item) => item.inputTypeID === type);
  }, [sourceWeights.data, type]);

  const patient = useMemo(() => {
    if (weightType?.rowIndex === INPUT_TYPE_INDEXES.WEIGHT_HOME) {
      const weightEntity = weights[weights.length - 1] || null;
      return { weight: weightEntity?.weight ?? null };
    }

    return { weight: patientInfo?.weight ?? null };
  }, [patientInfo, weightType, weights]);

  const info = useMemo(() => {
    return calcPatientWeightInfo({
      today: new Date(),
      weights: weights || [],
      patient: patient,
      subscription: sourceSubscription.data,
    });
  }, [weights, patient, sourceSubscription.data]);

  const isLoading = isLoadingPatient || sourceWeights.isLoading || sourceSubscription.isLoading;

  const { isOpen, handleClose, handleOpen } = useOpen();
  return (
    <>
      <Box className={clsx(style.root, classes?.root, className)} {...rest}>
        <ToggleButtonGroup
          className={style.switcher}
          size="small"
          value={type}
          onChange={onChangeType}
          exclusive
          color={'primary'}
        >
          {sourceWeightTypes.data.map((item) => {
            return (
              <ToggleButton
                classes={{
                  root: style.switcherButton,
                  selected: style.switcherButtonSelected,
                }}
                key={item.id}
                value={item.id}
              >
                {item.title}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
        <ButtonBase className={style.items} onClick={handleOpen} component={'div'}>
          <UserBoxWeight
            isLoading={isLoading || isLoadingPatient}
            title={t('program-info-start-weight')}
            value={info.start}
          />
          <UserBoxWeight isLoading={isLoading} title={t('last-weight')} value={info.last} />
          <UserBoxWeight
            isLoading={isLoading}
            title={t('program-info-weight-loss-amount')}
            value={formatWeightLost(info.lost)}
          />
          <UserBoxWeight
            isLoading={isLoading}
            title={t('lost-weight-for-last-month')}
            value={formatWeightLost(info.lostLastMonth)}
          />
          <UserBoxWeight
            isLoading={isLoading}
            title={t('lost-weight-for-last-week')}
            value={formatWeightLost(info.lostLastWeek)}
          />
          <UserBoxWeight
            isLoading={isLoading}
            title={t('lost-weight-for-beginning-of-the-subscription')}
            value={formatWeightLost(info.lostStartSubscription)}
          />
        </ButtonBase>
      </Box>
      {isOpen && (
        <DialogInfo
          title={weightType?.title ?? null}
          userPatientProfileID={userPatientProfileID}
          inputTypeID={type}
          onClose={handleClose}
        />
      )}
    </>
  );
};
