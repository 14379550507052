import React, { useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { add } from 'date-fns';
import { NextMeetingForm, setUpTimeForSideEffectMeetingTime } from '../next-meeting-form';
import { useSourceMeetingTypes } from '../../hooks';
import { PreviewSessionCard } from 'components/preview-session-effects';
import { useTranslate } from 'hooks/use-translate';

export type NextMeetingModel = {
  date: string;
  start: string | number;
  end: string | number;
  includeMeetingTime: boolean;
  supportMeetingTypeID: string;
};

interface Props {
  isLoading: boolean;
  isTLC: boolean | undefined;
}
export const NextMeetingNewContainer: React.FC<Props> = ({ isLoading, isTLC }) => {
  const { t } = useTranslate();
  const { watch, setValue, getValues } = useFormContext<NextMeetingModel>();
  const sourceMeetingTypes = useSourceMeetingTypes();

  const supportMeetingTypeID = watch('supportMeetingTypeID');

  const refInitType = useRef(false);
  const supportMeetingType = supportMeetingTypeID
    ? sourceMeetingTypes.map[supportMeetingTypeID]
    : null;

  useEffect(() => {
    if (refInitType.current) return;
    if (!supportMeetingType) return;

    const date = add(new Date(), {
      days: Number(supportMeetingType.nextMeetingNumberOfDays || 0),
    });
    refInitType.current = true;
    setValue('date', date);

    if (supportMeetingType.isSideEffect) {
      const { start, end } = getValues(['start', 'end']);

      const res = setUpTimeForSideEffectMeetingTime({ start, end });

      if (res) {
        setValue('start', res[0]);
        setValue('end', res[1]);
      }
    }
  }, [setValue, supportMeetingType, getValues]);

  const refInitTLC = useRef(false);
  const supportMeetingTLC = useMemo(() => {
    return sourceMeetingTypes.data.find(({ isTLC }) => isTLC);
  }, [sourceMeetingTypes.data]);

  useEffect(() => {
    if (!isTLC) return;
    if (refInitTLC.current) return;
    if (!supportMeetingTLC) return;

    refInitTLC.current = true;
    refInitType.current = false;

    setValue('supportMeetingTypeID', supportMeetingTLC.id);
  }, [setValue, isTLC, supportMeetingTLC]);

  return (
    <PreviewSessionCard title={t('create-new')}>
      <NextMeetingForm isLoading={isLoading} />
    </PreviewSessionCard>
  );
};
