import React, { useMemo } from 'react';

import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { Input, Select } from 'AurionCR/components/formV2';
import { Controller, useForm } from 'react-hook-form';
import { useFieldProps, useFormDefaultValue } from 'hooks';
import { useSourcePatientRemarkStatuses } from 'components/hooks';
import { AppCheckbox } from 'components/app-checkbox';
import { useTranslate } from 'hooks/use-translate';

export class MeetingChangeModel {
  remarkForPatientCallStatusID!: string;
  remarks = '';
  isSendSms = true;
}

export type MeetingChangeReason = 'default' | 'reassigned' | 'rescheduled';

interface Props {
  isLoading?: boolean;
  initialData?: Partial<MeetingChangeModel>;
  onSubmit: (data: MeetingChangeModel) => void;
  onClose: () => void;
  title: string;
  reason: MeetingChangeReason;
  includeSms: boolean;
}

export const DialogMeetingChanges: React.FC<Props> = ({
  title,
  initialData,
  onClose,
  onSubmit,
  reason,
  includeSms,
  isLoading,
}) => {
  const { t } = useTranslate();
  const { control, errors, setValue, watch, handleSubmit } = useForm({
    defaultValues: { ...new MeetingChangeModel(), ...initialData },
    shouldUnregister: true,
  });
  const getInputProps = useFieldProps({ errors });

  const sourcePatientRemarkStatuses = useSourcePatientRemarkStatuses();
  const sourceRemarks = useMemo(() => {
    if (reason === 'reassigned') {
      return sourcePatientRemarkStatuses.data.filter(({ isReassigned }) => isReassigned);
    }
    if (reason === 'rescheduled') {
      return sourcePatientRemarkStatuses.data.filter(({ isRescheduled }) => isRescheduled);
    }
    return sourcePatientRemarkStatuses.data;
  }, [sourcePatientRemarkStatuses, reason]);

  useFormDefaultValue(sourceRemarks[0]?.id, {
    name: 'remarkForPatientCallStatusID',
    setValue,
    watch,
  });

  return (
    <Dialog open={true} onClose={isLoading ? undefined : onClose} fullWidth maxWidth="xs">
      <DialogHeading isLoading={isLoading} title={title} onClose={onClose} disabled={isLoading} />
      <DialogContent>
        <Controller
          name={'remarkForPatientCallStatusID'}
          control={control}
          rules={{
            required: {
              value: true,
              message: 'rule-required',
            },
          }}
          render={(renderProps) => (
            <Select
              {...getInputProps(renderProps)}
              label={t('remarks-for-patient')}
              source={sourceRemarks}
              loading={sourcePatientRemarkStatuses.loading}
              disabled={isLoading}
            />
          )}
        />
        <Controller
          name={'remarks'}
          control={control}
          rules={{
            required: {
              value: true,
              message: 'rule-required',
            },
          }}
          render={(renderProps) => (
            <Input {...getInputProps(renderProps)} multiline={true} rows={5} disabled={isLoading} />
          )}
        />
        {includeSms && (
          <Controller
            name={'isSendSms'}
            control={control}
            render={(renderProps) => (
              <AppCheckbox {...getInputProps(renderProps)} disabled={isLoading} />
            )}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" disabled={isLoading}>
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
