import React, { useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import {
  actionNotebookFormGenerationSetReplaceMeeting,
  selectNotebookFormGeneratorReplaceSupportMeetingData,
} from 'store/notebook-form-generator';
import {
  DialogNextFutureSupportMeeting,
  FutureMeeting,
} from 'components/dialog-next-future-support-meeting';
import { apiSupportMeeting } from 'services/support-meetings';
import { useEffectError } from 'hooks';
import { actionSupportMeetingActivitiesCrateLogUpdated } from 'store/support-meeting-activities';
import { composeFunctions } from 'utils';
import { NotebookFormGenerator, NotebookFormGeneratorProps } from '../notebook-form-generator';
import { useCurrentUser } from 'components/hooks';

enum STATE {
  MEETING = 1,
  FORM,
}

interface Props extends NotebookFormGeneratorProps {}

export const NotebookFormGeneratorSupportMeetingBefore: React.FC<Props> = ({
  notebookID,
  userPatientProfileID,
  supportMeetingTypeID,
  onClose,
  onCreated,
  showDelete = true,
  onDone: _onDone,
}) => {
  const dispatch = useAppDispatch();
  const { appUserID } = useCurrentUser();
  const selectedMeeting = useAppSelector(selectNotebookFormGeneratorReplaceSupportMeetingData);
  const setSelectedMeeting = useCallback(
    (data: FutureMeeting) => {
      dispatch(actionNotebookFormGenerationSetReplaceMeeting(data));
    },
    [dispatch],
  );
  const [state, setState] = useState(STATE.MEETING);

  const onContinue = useCallback(() => {
    setState(STATE.FORM);
  }, []);

  const [setFromExisting, resultSetFromExisting] =
    apiSupportMeeting.useSetNextPatientFutureSupportMeetingFromExistingMutation();
  useEffectError(resultSetFromExisting.error);

  const excludeMeetingIDs = useMemo(() => {
    return [selectedMeeting && selectedMeeting.id].filter(Boolean) as string[];
  }, [selectedMeeting]);

  const onDone = useCallback(
    async (session: { id: string }) => {
      if (!selectedMeeting) {
        return _onDone(session);
      }
      const result = await setFromExisting({
        ...selectedMeeting,
        userEmployeeProfileID: appUserID,
        userPatientProfileSessionID: session.id,
      });

      if ('error' in result) return;

      dispatch(
        actionSupportMeetingActivitiesCrateLogUpdated({
          oldData: selectedMeeting,
          newData: result.data,
        }),
      );

      _onDone(session);
    },
    [selectedMeeting, _onDone, appUserID, setFromExisting, dispatch],
  );

  if (state === STATE.MEETING) {
    return (
      <DialogNextFutureSupportMeeting
        userPatientProfileID={userPatientProfileID}
        onClose={onClose}
        onContinue={onContinue}
        onContinueWithMeeting={composeFunctions(setSelectedMeeting, onContinue)}
      />
    );
  }

  return (
    <NotebookFormGenerator
      isLoading={resultSetFromExisting.isLoading}
      notebookID={notebookID}
      onClose={onClose}
      onCreated={onCreated}
      supportMeetingTypeID={supportMeetingTypeID}
      userPatientProfileID={userPatientProfileID}
      showDelete={showDelete}
      onDone={onDone}
      excludeMeetingIDs={excludeMeetingIDs}
      meetingFromDateTime={undefined}
      meetingToDateTime={undefined}
    />
  );
};
