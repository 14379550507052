import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from 'store/store';
import { selectAuthUser } from 'store/auth';
import { Translate } from 'dictionary';
import { UserPatientProfileSubscription } from 'services/user-patient-profile-subscriptions';
import {
  IAppUserPatientProfileSubscriptionActivity,
  SubscriptionActivityType,
  UserPatientProfileSubscriptionActivities,
} from 'services/user-patient-profile-subscription-activities';
import { calcHtmlTemplate } from 'utils';
import { format } from 'date-fns';
import { convertToDate } from 'utils/dates';
import { APP_FORMAT_DATE } from 'configs/const';

const actionUserPatientProfileSubscriptionActivitiesCrateLog = createAsyncThunk<
  void,
  Pick<
    IAppUserPatientProfileSubscriptionActivity,
    'userPatientProfileSubscriptionID' | 'remarks' | 'subscriptionActivityType'
  >,
  AppAsyncThunkConfig
>(`USER_PATIENT_PROFILE_SUBSCRIPTION_ACTIVITIES/createLog`, async (input, { getState }) => {
  try {
    const user = selectAuthUser(getState());

    if (!user) {
      throw new Error('current employee is required');
    }

    await UserPatientProfileSubscriptionActivities.post({
      userPatientProfileSubscriptionID: input.userPatientProfileSubscriptionID,
      remarks: input.remarks,
      entryDate: new Date().toISOString(),
      userEmployeeProfileID: user.appUserID,
      subscriptionActivityType: input.subscriptionActivityType,
    });
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionUserPatientProfileSubscriptionActivitiesCrateLogCreated = createAsyncThunk<
  void,
  {
    data: UserPatientProfileSubscription;
    translate: Translate;
  },
  AppAsyncThunkConfig
>(
  `USER_PATIENT_PROFILE_SUBSCRIPTION_ACTIVITIES/crateLogUpdated`,
  async ({ data, translate }, { dispatch }) => {
    try {
      const id = data.id;

      if (!id) {
        throw new Error('id is required');
      }

      dispatch(
        actionUserPatientProfileSubscriptionActivitiesCrateLog({
          userPatientProfileSubscriptionID: id,
          remarks: calcHtmlTemplate(translate('activity-subscription-make-payment'), {
            startDate: format(convertToDate(data.startDate), APP_FORMAT_DATE),
            endDate: format(convertToDate(data.endDate), APP_FORMAT_DATE),
          }),
          subscriptionActivityType: SubscriptionActivityType.General,
        }),
      );
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
