import React from 'react';
import { Stack } from 'components/stack';
import style from './index.module.scss';

interface Props {
  emails: string[];
}

export const GridEmailListTemplate: React.FC<Props> = ({ emails }) => {
  const items = emails.filter(Boolean);
  return (
    <Stack className={style.root} divider={<span dir={'ltr'}>,&nbsp;</span>}>
      {items.map((email, i) => (
        <a key={i} href={`mailto:${email}`} dir={'ltr'}>
          {email}
        </a>
      ))}
    </Stack>
  );
};
