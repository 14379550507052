import { apiUserPatientProfile, UserPatientProfilePatchInput } from 'services/user-patient-profile';
import { useCallback } from 'react';
import { isEqual, pick } from 'lodash-es';
import { useMutationDynamic } from '../../../hooks';

const useFetchPatientForm = apiUserPatientProfile.useGetPatientForGeneralFormQuery;
const usePatchPatientForm = apiUserPatientProfile.usePatchPatientWithLogMutation;

interface UseUpdatePatientDataProps {
  userPatientProfileID: string;
}
export const useUpdatePatientDataFromContent = ({
  userPatientProfileID,
}: UseUpdatePatientDataProps) => {
  const resultPatient = useFetchPatientForm(userPatientProfileID);
  const patient = resultPatient.data;

  const [patch] = usePatchPatientForm();

  const onUpdate = useCallback(
    async (formData: Partial<UserPatientProfilePatchInput>) => {
      if (!patient) {
        throw new Error('useUpdatePatientDataFromContent: patient is required');
      }

      const origin = pick(patient, Object.keys(formData));

      if (isEqual(origin, formData)) return { data: {} };

      await patch({
        initData: { appIdentityUserID: userPatientProfileID, ...origin },
        formData: {
          appIdentityUserID: userPatientProfileID,
          ...formData,
        },
        remark: '',
      }).unwrap();

      return { data: {} };
    },
    [userPatientProfileID, patch, patient],
  );

  const res = useMutationDynamic(onUpdate);

  return [res[0], res[1], patient] as const;
};
