import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo } from 'react';
import { validateRule } from 'AurionCR/components/formV2';
import { fieldValueDefault, iField, iFieldRefProps } from './@type';
import { Controller, useFormContext } from 'react-hook-form';
import { composeFunctions } from 'utils';
import { useFieldProps } from 'hooks';
import { AppInput } from 'components/app-input';

export const FieldText = memo(
  forwardRef<iFieldRefProps, iField>(({ item, disabled = false, onWasChange }, ref) => {
    const name = item.fieldInputID as string;
    const label = item.fieldInput?.labelKey;

    const { getValues, control, errors } = useFormContext();
    const getValue = useCallback(() => {
      const value = getValues(name);
      return {
        ...fieldValueDefault(),
        id: item.formID || '',
        fieldInputID: item.fieldInputID as string,
        entryValueString: value || null,

        formValue: value,
        notebookFieldInputID: item.id,
      };
    }, [item, getValues, name]);

    useImperativeHandle(ref, () => ({ getValue }), [getValue]);

    const rules = useMemo(() => {
      if (item?.isRequired) {
        return validateRule('required');
      }
      return undefined;
    }, [item]);

    const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

    return (
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={(renderProps) => {
          const props = getFieldProps(renderProps);
          return (
            <AppInput
              {...props}
              label={label}
              disabled={disabled}
              onBlur={composeFunctions(props.onBlur, onWasChange)}
            />
          );
        }}
      />
    );
  }),
);
