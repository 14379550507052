import {
  API_PATIENT_PUSH_NOTIFICATIONS,
  EmployeeNotifications,
  NotificationsToggleSubscription,
  PatientNotifications,
} from './models';
import { ServiceUserPatientProfileWebPushNotifications } from 'services/user-patient-profile-web-push-notifications';
import { api, DynamicService, isAxiosError } from 'utils/service';
import { ServiceUserEmployeeProfileWebPushNotifications } from 'services/user-employee-profile-web-push-notifications';

interface BaseModel {
  id: string;
  subscription: string;
}

class ServiceNotifications<Model extends BaseModel, ModelField extends keyof Model> {
  url: string;
  dynamicService: Pick<DynamicService<Model>, 'getAllDynamic' | 'delete'>;
  dynamicServiceRelationField: ModelField;
  constructor(options: {
    dynamicService: Pick<DynamicService<Model>, 'getAllDynamic' | 'delete'>;
    url: string;
    dynamicServiceRelationField: ModelField;
  }) {
    this.dynamicService = options.dynamicService;
    this.url = options.url;
    this.dynamicServiceRelationField = options.dynamicServiceRelationField;
  }
  private sendNotification = async <P>(input: { id: string; subscription: string; payload: P }) => {
    const { id, subscription, payload } = input;
    try {
      await api.post(
        this.url,
        {
          subscription: JSON.parse(subscription),
          ...payload,
        },
        { baseURL: '/' },
      );
    } catch (e: any) {
      if (!isAxiosError(e)) {
        throw e;
      }

      console.dir(e);

      // remove unsubscribed subscription
      if (e.response?.status === 410) {
        await this.dynamicService.delete({ id: id } as any);
      }
    }
  };
  sendNotifications = async <P>(input: { relationID: string; payload: P }) => {
    const { relationID, payload } = input;
    // get all subscriptions
    const {
      data: { value },
    } = await this.dynamicService.getAllDynamic({
      filter: [`${this.dynamicServiceRelationField}=="${relationID}"`].join(','),
      select: ['id', 'subscription'].join(','),
    });

    // remove duplicates
    const subscriptions = Array.from(
      new Map(value.map((item) => [item.subscription, item])).values(),
    );

    if (subscriptions.length === 0) {
      return;
    }

    await Promise.all(
      subscriptions.map((sub) => {
        return this.sendNotification({
          id: sub.id,
          subscription: sub.subscription,
          payload: payload,
        });
      }),
    );
  };
}

const ServicePatientPushNotifications = new ServiceNotifications({
  dynamicService: ServiceUserPatientProfileWebPushNotifications,
  url: API_PATIENT_PUSH_NOTIFICATIONS.PUSH_TO_PATIENT,
  dynamicServiceRelationField: 'userPatientProfileID',
});

const ServiceEmployeePushNotifications = new ServiceNotifications({
  dynamicService: ServiceUserEmployeeProfileWebPushNotifications,
  url: API_PATIENT_PUSH_NOTIFICATIONS.PUSH_TO_EMPLOYEE,
  dynamicServiceRelationField: 'userEmployeeProfileID',
});

class Service {
  getPublicKey = async () => {
    const { data } = await api.get<{ publicVapidKey: string }>(
      API_PATIENT_PUSH_NOTIFICATIONS.GET_KEYS,
      { baseURL: '/' },
    );
    return data.publicVapidKey;
  };

  subscribeEmployee = async (input: NotificationsToggleSubscription) => {
    const { userEmployeeProfileID, subscription } = input;

    const subscriptionString = JSON.stringify(subscription);

    await ServiceUserEmployeeProfileWebPushNotifications.post({
      userEmployeeProfileID,
      subscription: subscriptionString,
    });
  };
  unsubscribeEmployee = async (input: NotificationsToggleSubscription) => {
    const { subscription } = input;
    await subscription.unsubscribe();
  };
  sendToPatient = async (data: PatientNotifications) => {
    const { userPatientProfileID, ...rest } = data;
    return ServicePatientPushNotifications.sendNotifications({
      relationID: data.userPatientProfileID,
      payload: rest,
    });
  };
  sendToEmployee = async (data: EmployeeNotifications) => {
    const { toUserEmployeeProfileID, ...rest } = data;

    return ServiceEmployeePushNotifications.sendNotifications({
      relationID: data.toUserEmployeeProfileID,
      payload: rest,
    });
  };
}

export const ServicePushNotifications = new Service();
