import React, { useCallback } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import { useTranslate } from 'hooks/use-translate';
import { useCurrentUser } from 'components/hooks';
import { useEffectError } from 'hooks';
import { apiVoipe } from 'services/voipe-services';
import { Unset } from 'utils/types';
import { isMutationRejected } from 'utils/rtk-query';
import { apiTodoTaskActivities } from 'services/todo-task-activities';

const useCallMutation = apiVoipe.useVoipeCallToPatientMutation;
const usePostActivityMutation = apiTodoTaskActivities.usePostTodoTaskMeetingActivityMutation;

interface Props extends ButtonProps {
  userPatientProfileID: string;
  taskID: Unset;
  onDone?: () => void;
}

export const ButtonCall: React.FC<Props> = ({ userPatientProfileID, taskID, onDone, ...rest }) => {
  const { tp } = useTranslate();
  const { phoneExtension } = useCurrentUser();

  const [triggerCall, resultCall] = useCallMutation();
  useEffectError(resultCall.error);

  const [triggerActivity, resultActivity] = usePostActivityMutation();
  useEffectError(resultActivity.error);

  const isLoading = resultCall.isLoading;

  const onClick = useCallback(async () => {
    const result = await triggerCall({ userPatientProfileID });

    if (isMutationRejected(result)) {
      return;
    }

    if (taskID) {
      triggerActivity({ toDoTaskID: taskID, remarks: tp('activity-to-do-task-call-to-patient') });
    }

    onDone && onDone();
  }, [triggerCall, triggerActivity, userPatientProfileID, onDone, taskID, tp]);

  return (
    <Button
      variant={'contained'}
      color={'primary'}
      startIcon={<PhoneIcon />}
      disabled={!phoneExtension || isLoading}
      onClick={onClick}
      {...rest}
    >
      {tp('call-to-patient')}
    </Button>
  );
};
