import React, { useMemo } from 'react';
import { TemplateLtrOnly } from '../template-ltr-only';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import clsx from 'clsx';
import style from './index.module.scss';
import { Stack, StackProps } from 'components/stack';
import { useAppSelector } from 'store';
import { selectLanguageIsRtl } from 'store/languages';

interface Props extends StackProps {
  value: string | number | null | undefined;
}

export const TemplateDeltaWeight: React.FC<Props> = ({ value, ...rest }) => {
  const isRtl = useAppSelector(selectLanguageIsRtl);
  const isPositive = useMemo(() => {
    return parseFloat(String(value)) > 0;
  }, [value]);

  const isNegative = useMemo(() => {
    return parseFloat(String(value)) < 0;
  }, [value]);

  const valueResult = useMemo(() => {
    if (isPositive) {
      return `+${value}`;
    }
    return value;
  }, [isPositive, value]);

  return (
    <>
      <Stack
        direction={'row'}
        alignItems={'center'}
        spacing={0.5}
        {...rest}
        className={clsx(
          style.root,
          isPositive && style.rootPositive,
          isNegative && style.rootNegative,
          isRtl && style.rootRtl,
        )}
      >
        {!isPositive && !isNegative && <TrendingFlatIcon className={style.icon} />}
        {isPositive && <TrendingUpIcon className={style.icon} />}
        {isNegative && <TrendingDownIcon className={style.icon} />}
        <TemplateLtrOnly value={valueResult} />
      </Stack>
    </>
  );
};
