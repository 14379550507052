import React, { memo, useMemo } from 'react';
import GridConfig from './grid-config';
import DataGrid from 'components/data-grid';
import { useFormGrid } from 'AurionCR/components/formV2';
import Form from './components/form';

interface Props {
  userPatientProfileID: string;
}
export const GridNotes = memo<Props>(({ userPatientProfileID }) => {
  const gridConfig = useMemo<any>(
    () => GridConfig({ userPatientProfileID }),
    [userPatientProfileID],
  );
  const { grid, onGridHandle, onFormHandle, editItemID, setEditItemID } = useFormGrid(
    'user-patient-profile-notes',
  );

  return (
    <>
      <DataGrid ref={grid} config={gridConfig} onHandle={onGridHandle} />
      {editItemID && (
        <Form
          onClose={() => setEditItemID(false)}
          onFormHandle={onFormHandle}
          itemID={editItemID}
          userPatientProfileID={userPatientProfileID}
        />
      )}
    </>
  );
});
