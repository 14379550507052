import { Stack } from '../stack';
import { Divider, Typography } from '@material-ui/core';
import { dateFormat, DateValue } from 'utils/dates';
import React from 'react';
import { Unset } from 'utils/types';

type SubscriptionOption = {
  startDate: DateValue;
  endDate: DateValue;
  subscription: { labelKey: Unset };
};

export const subscriptionGetLabel = (option?: SubscriptionOption) => {
  if (!option) {
    return '';
  }
  return String(option.subscription.labelKey);
};
export const subscriptionRenderOption = (option: SubscriptionOption) => {
  return (
    <Stack width={'100%'} spacing={1} divider={<Divider flexItem orientation={'vertical'} />}>
      <Typography style={{ flexGrow: 1 }}>{option.subscription.labelKey}</Typography>
      <Typography color={'textSecondary'}>
        {dateFormat(option.startDate)} - {dateFormat(option.endDate)}
      </Typography>
    </Stack>
  );
};
