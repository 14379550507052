import React, { memo, useEffect, useMemo } from 'react';
import GridConfig from './grid-config';
import DataGrid from 'components/data-grid';
import { useFormGrid } from 'AurionCR/components/formV2';
import Form from './components/form';
import { patientEmitter } from '../../emitter';

interface Props {
  userPatientProfileID: string;
}
export const GridMedicalInformation = memo<Props>(({ userPatientProfileID }) => {
  const gridConfig = useMemo<any>(
    () => GridConfig({ userPatientProfileID }),
    [userPatientProfileID],
  );
  const { grid, onGridHandle, onFormHandle, editItemID, setEditItemID } =
    useFormGrid('medical-information');

  useEffect(() => {
    const refresh = (payload: { patientID: string }) => {
      if (payload.patientID !== userPatientProfileID) {
        return;
      }
      grid.current?.onTasks([['getData']]);
    };

    patientEmitter.on('refresh-all', refresh);
    return () => {
      patientEmitter.off('refresh-all', refresh);
    };
  }, [userPatientProfileID, grid]);

  return (
    <>
      <DataGrid ref={grid} config={gridConfig} onHandle={onGridHandle} />
      {editItemID && (
        <Form
          onClose={() => setEditItemID(false)}
          onFormHandle={onFormHandle}
          itemID={editItemID}
          userPatientProfileID={userPatientProfileID}
        />
      )}
    </>
  );
});
