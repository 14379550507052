import {
  API_EMAIL_MESSAGES,
  EmailMessage,
  EmailMessageToPatientInput,
  EmailMessageToPharmacyInput,
} from './models';
import { api } from 'utils/service';
import { apiRtk } from 'utils/rtk-query';

export * from './models';

class Service {
  sendEmail = async (data: EmailMessage) => {
    try {
      const res = await api.post<EmailMessage | { status: string; errorMessage: string }>(
        API_EMAIL_MESSAGES.SEND_MESSAGE,
        data,
      );

      if ('status' in res.data && res.data.status === 'error') {
        throw new Error(res.data.errorMessage || res.data.status);
      }
    } catch (e) {
      throw e;
    }
  };
}

export const ServiceEmailMessages = new Service();

export const apiEmailMessages = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    sendEmailToPatient: builder.mutation<void, EmailMessageToPatientInput>({
      queryFn: async (input) => {
        try {
          const { userPatientProfileID, ...rest } = input;
          await ServiceEmailMessages.sendEmail(rest);

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    sendEmailToPharmacy: builder.mutation<void, EmailMessageToPharmacyInput>({
      queryFn: async (input) => {
        try {
          const { userPatientProfileID, ...rest } = input;
          await ServiceEmailMessages.sendEmail(rest);

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
