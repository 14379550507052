import { Box, BoxProps } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { Characteristic } from '../characteristic';
import style from './index.module.scss';

export interface CharacteristicItem {
  label: string;
  value: React.ReactNode;
}

interface Classes {
  list: string;
  item: string;
  itemLabel: string;
  itemValue: string;
}

interface Props extends BoxProps {
  className?: string;
  classes?: Partial<Classes>;
  items: CharacteristicItem[];
}

export const CharacteristicList: React.FC<Props> = ({
  className,
  classes,
  items,
  children,
  ...rest
}) => {
  return (
    <Box className={clsx(style.list, classes?.list, className)} component={'dl'} {...rest}>
      {items.map(({ value, ...item }, key) => {
        return (
          <Characteristic
            key={key}
            {...item}
            classes={{
              root: clsx(style.item, classes?.item),
              label: clsx(style.itemLabel, classes?.itemLabel),
              value: clsx(style.itemValue, classes?.itemValue),
            }}
          >
            {value}
          </Characteristic>
        );
      })}
    </Box>
  );
};
