import React, { memo, useCallback } from 'react';
import { IDataGridFilter } from '../../model';
import { useI18n } from 'AurionCR/components';
import { DateMultiple } from 'AurionCR/components/form';

export default memo(
  ({ filter, value, onTasks }: { filter: IDataGridFilter; value: any; onTasks: any }) => {
    const onChange = useCallback((value) => {
      onTasks([
        ['pagination', { page: 1 }],
        [
          'filters',
          {
            [filter.field]: value,
          },
        ],
        ['getData'],
      ]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { t } = useI18n();

    return (
      <DateMultiple
        // @ts-ignore
        label={t(filter.options?.label)}
        // @ts-ignore
        placeholder={t(filter.options?.placeholder)}
        style={filter.options?.style}
        fullWidth={false}
        onChange={onChange}
        value={value}
      />
    );
  },
);
