import { useEffect, useRef } from 'react';
import { UseFormMethods, FieldName, FieldValue } from 'react-hook-form';
import { useEffectDebounce } from 'AurionCR/components/hooks';

interface Options<T> {
  setValue: UseFormMethods<T>['setValue'];
  watch: UseFormMethods<T>['watch'];
  name: FieldName<T>;
  skip?: boolean;
  emptyValue?: any;
}

export const useFormDefaultValue = <T extends Record<string, any>>(
  defaultValue: FieldValue<T> | undefined,
  options: Options<T>,
) => {
  const { name, watch, setValue, skip = false, emptyValue = undefined } = options;

  const value = watch(name);
  const setValueRef = useRef(setValue);
  useEffect(() => {
    setValueRef.current = setValue;
  }, [setValue]);

  useEffectDebounce(
    () => {
      if (value === emptyValue && defaultValue && skip === false) {
        setValueRef.current(name, defaultValue);
      }
    },
    100,
    [value, defaultValue, name, skip],
  );
};
