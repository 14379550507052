import React, { memo, Fragment } from 'react';
import { Grid, Typography } from '@material-ui/core';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';
import { format } from 'date-fns';
import { convertToDate, dateFormat, DateValue, getAge } from 'utils/dates';
import { APP_FORMAT_DATE } from 'configs/const';
import { UserPatientProfile } from 'services/user-patient-profile';
import clsx from 'clsx';

type Patient = Pick<
  Required<UserPatientProfile>,
  | 'lastName'
  | 'firstName'
  | 'idNumber'
  | 'dateOfBirth'
  | 'height'
  | 'weight'
  | 'email'
  | 'mobilePhone'
  | 'address'
  | 'city'
>;

interface ItemProps {
  label: string;
  value?: React.ReactNode;
}

export const Item: React.FC<ItemProps> = ({ label, value }) => {
  return (
    <div className={style.item}>
      <Typography color={'textPrimary'} variant={'body2'} component={'div'} className={style.label}>
        {`${label} :`}
      </Typography>
      <Typography color={'textPrimary'} variant={'body2'} component={'div'} className={style.value}>
        {value}
      </Typography>
    </div>
  );
};

interface Props {
  entryDate: DateValue;
  data: Patient | undefined;
  activityRows: { labelKey: string; entryValueString: string; id: string; isUrgent: boolean }[];
}

export const DialogUserPatientDeclarationContent = memo<Props>(
  ({ data, activityRows, entryDate }) => {
    const { tp } = useTranslate();

    return (
      <div className={style.wrap}>
        <div className={style.top}>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Item label={tp('last-name')} value={data?.lastName} />
              <Item
                label={tp('date-of-birth')}
                value={data?.dateOfBirth ? dateFormat(data.dateOfBirth) : ''}
              />
              <Item label={tp('weight')} value={data?.weight} />
              <Item label={tp('height')} value={data?.height} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Item label={tp('first-name')} value={data?.firstName} />
              <Item label={tp('age')} value={getAge(data?.dateOfBirth)} />
              <Item label={tp('city')} value={data?.city?.title} />

              <Item label={tp('address')} value={data?.address} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Item label={tp('id-number')} value={data?.idNumber} />
              <Item label={tp('email')} value={data?.email} />
              <Item label={tp('mobile-phone')} value={data?.mobilePhone} />
            </Grid>
          </Grid>
        </div>

        <div className={style.activities}>
          {activityRows.map((item) => (
            <Fragment key={item.id}>
              <Typography color={'textPrimary'} variant={'body2'} className={style.activitiesValue}>
                {item.labelKey}
              </Typography>
              <Typography
                color={'textPrimary'}
                variant={'body2'}
                className={clsx(style.activitiesResult, item.isUrgent && style.urgent)}
              >
                {item.entryValueString}
              </Typography>
            </Fragment>
          ))}
        </div>

        <div className={style.bottom}>
          <Typography variant={'body1'} color={'textPrimary'} className={style.value}>
            {tp('approve-survey-detail-title')}
          </Typography>
          <Typography variant={'body2'} color={'textPrimary'}>
            {tp('approve-survey-detail-subtitle')}
          </Typography>
          {entryDate && (
            <Item label={tp('date')} value={format(convertToDate(entryDate), APP_FORMAT_DATE)} />
          )}
        </div>
      </div>
    );
  },
);
