import {
  API_MIGRATIONS,
  MigratePatientFromExcelInput,
  PatientPdfDataSession,
  PatientPdfData,
  MigratePatientMigratePatientPdfDataInput,
  MigratePatientMakePatientPdfDataInput,
  MigrateGetPdfFilesInput,
  MigrateMeetingsFromExcelInput,
} from './models';
import { ServiceMediaPrivate } from 'services/media-private-services';
import { base64ToFileStream, isFileLike } from 'utils/file-uploader';
import pdfjsDist, { PDFDocumentProxy, TextItem } from 'modules/pdfjs';
import { findBirthDay, findEmail, findMobilePhone } from 'utils/regexp';
import { pdfTableExtractor } from 'modules/pdf-table-extract';
//@ts-ignore
import bidiFactory from 'bidi-js';
import { AxiosResponse } from 'axios';
import { apiRtk } from 'utils/rtk-query';
import { api } from 'utils/service';

const HEBREW = '[\u0590-\u05fe]';

const findGender = (text: string) => {
  if (text.includes('זכר')) {
    return 'זכר';
  }
  if (text.includes('קבה')) {
    return 'נקבה';
  }
  return undefined;
};

const getRtlFlips = (levels: number[]) => {
  //1 - rtl;
  //0 - ltr
  const flips: number[][] = [];
  for (let i = 0, j = 0; i < levels.length; i++) {
    const level = levels[i];

    //rtl
    if (level % 2 !== 0) {
      if (flips[j]) {
        flips[j] = [flips[j][0], i];
      } else {
        flips[j] = [i];
      }
    }

    // ltr
    if (level % 2 === 0) {
      if (flips[j]) {
        j++;
      }
    }
  }

  return flips.map((flip) => (flip.length !== 2 ? [flip[0], flip[0]] : flip));
};

interface TablePage {
  page: number;
  tables: string[][];
}

const parseTablePage = (page: TablePage) => {
  // Make Table Column Matrix
  const table = page.tables;
  // Header Indexes
  let columnIndexes = (table[0] || [])
    .map((val, index) => {
      return { index, isEmpty: !Boolean(val) };
    })
    .filter(({ isEmpty }) => !isEmpty)
    .map(({ index }) => index);

  let structuredTables: string[][] = [];

  for (let i = 0; i < table.length; i++) {
    const row = table[i];
    structuredTables[i] = [];
    columnIndexes.forEach((headerIndex) => {
      structuredTables[i].push(row[headerIndex]);
    });
  }

  return structuredTables;
};
enum MAP_ACTIONS_INDEXES {
  balance,
  paid,
  charged,
  // program,
  employee,
  description,
  code,
  session,
  treatNumber,
  date,
}
const parseTableActions = async (pages: TablePage[]) => {
  let flagSecondTableStart = false;
  let flagSecondTableEnd = false;

  const pagesThatContainsTable2 = pages.map((page) => {
    const tables = page.tables.filter((row) => {
      const isBreak = row.every((val) => val === '');
      if (isBreak && !flagSecondTableStart) {
        flagSecondTableStart = true;
        return false;
      }
      if (isBreak && flagSecondTableStart) {
        flagSecondTableEnd = true;
        return false;
      }
      return flagSecondTableStart && !flagSecondTableEnd;
    });
    return { ...page, tables: tables };
  });

  let table = pagesThatContainsTable2
    .map((page) => {
      return parseTablePage(page);
    })
    .flat();

  table = table.filter((row) => {
    return new RegExp('\\d{2}\\/\\d{2}\\/\\d{4}').test(row[MAP_ACTIONS_INDEXES.date] || '');
  });

  table = table.map((row) => {
    return row.map((col) => replaceLtrSymbolAndKeepOrdering(col));
  });

  return table.map((row) => {
    return row.reduce((acc, item, i) => {
      const key = (MAP_ACTIONS_INDEXES[i] || `unknown-${1}`) as keyof PatientPdfDataSession;
      acc[key] = item;
      return acc;
    }, {} as PatientPdfDataSession);
  });
};

const parsePdfActions = async (doc: PDFDocumentProxy) => {
  const extractedAsTables = await pdfTableExtractor(doc);

  return parseTableActions(extractedAsTables.pageTables);
};
const parsePdfMainInfo = async (doc: PDFDocumentProxy) => {
  const page = await doc.getPage(1);

  const patientFileRow = 'תיק המטופל/ת:';
  const endTableWord = 'יתרה'; // not includes to result

  let isEndTable = false;

  let textContent = await page.getTextContent();

  const items = textContent.items;

  let tableItems: TextItem[] = [];

  for (let i = 0; i < items.length; i++) {
    const item = items[i];

    if (!('str' in item)) {
      continue;
    }

    if (!isEndTable && item.str.startsWith(endTableWord)) {
      isEndTable = true;
      break;
    }

    if (!isEndTable) {
      tableItems.push(item);
    }
  }

  const map = new Map<number, TextItem[]>();

  for (let i = 0, row = 1; i < tableItems.length; i++) {
    const item = tableItems[i];

    const isEmpty = item.str === '';

    if (isEmpty) {
      row++;
      continue;
    }

    let items = map.get(row) || [];
    items.push(item);

    map.set(row, items);
  }

  const rows = Array.from(map.values());
  const rowWithPatient = rows
    .find((row) => row.some((item) => item.str.includes(patientFileRow)))
    ?.map((item) => item.str)
    .join(' ');

  const patient = rowWithPatient?.split(':')[1];

  const lastRow = (rows[rows.length - 1] || []).map((item) => item.str).join(' ');

  const idNumber = patient?.split(',')[1]?.trim();
  return {
    fullName: patient?.split(',')[0]?.trim(),
    idNumber: String(idNumber).replace(/\D/g, ''),
    mobilePhone: findMobilePhone(lastRow),
    birthDay: findBirthDay(lastRow),
    email: findEmail(lastRow),
    gender: findGender(lastRow),
  };
};

export * from './models';

class Service {
  getPdfFiles = async (input: MigrateGetPdfFilesInput) => {
    const { bulk } = input;

    return api.get<string[]>(API_MIGRATIONS.GET_FILES, {
      params: { bulk },
    });
  };
  migratePatientFromExcel = async (input: MigratePatientFromExcelInput) => {
    const { userPatientProfileID } = input;
    if (!isFileLike(input.fileExcel)) {
      throw new Error('is-not-a-file');
    }
    const fileStreamString = base64ToFileStream(input.fileExcel.value);

    const {
      data: { filePath },
    } = await ServiceMediaPrivate.uploadFile({
      fileName: input.fileExcel.name,
      fileStreamString,
    });

    const result = await api.post(API_MIGRATIONS.MIGRATE_PATIENT_FROM_EXCEL, {
      userPatientProfileID,
      url: filePath,
    });

    return {
      ...result,
      data: { userPatientProfileID },
    };
  };

  migrateMeetingsFromExcel = async (input: MigrateMeetingsFromExcelInput) => {
    const { file } = input;
    if (!isFileLike(file)) {
      throw new Error('is-not-a-file');
    }
    const fileStreamString = base64ToFileStream(file.value);

    const {
      data: { filePath },
    } = await ServiceMediaPrivate.uploadFile({
      fileName: ['migration-meeting', new Date().toISOString(), file.name].join('__'),
      fileStreamString,
    });

    return api.post(API_MIGRATIONS.MIGRATE_MEETINGS_FROM_EXCEL, {
      url: filePath,
    });
  };

  makePatientPdfData = async (input: MigratePatientMakePatientPdfDataInput) => {
    const { file } = input;

    const pdfDocumentProxy = await pdfjsDist.getDocument(file).promise;

    const [main, sessions] = await Promise.all([
      parsePdfMainInfo(pdfDocumentProxy),
      parsePdfActions(pdfDocumentProxy),
    ]);

    return { data: { ...main, sessions } as PatientPdfData };
  };
  migratePatientFromPdf = async (input: MigratePatientMigratePatientPdfDataInput) => {
    const { data, file } = input;
    let url = '';

    if (isFileLike(file)) {
      const {
        data: { filePath },
      } = await ServiceMediaPrivate.uploadFile({
        fileStreamString: base64ToFileStream(file.value),
        fileName: file.name,
      });
      url = filePath;
    } else {
      url = file;
    }

    return api.post<Components.Schemas.MigrateFromPdfRequest, AxiosResponse<string>>(
      API_MIGRATIONS.MIGRATE_PATIENT_FROM_PDF,
      {
        url,
        ...data,
      },
    );
  };
}

export const ServiceMigrations = new Service();

const replaceLtrSymbolAndKeepOrdering = (didiText: string, symbol: string = 'ª') => {
  const bidi = bidiFactory();

  let rows = didiText.split('\n');

  for (let i = 0; i < rows.length; i++) {
    let row = rows[i];
    if (!row.includes(symbol)) continue;

    let newStr = row.split('');

    for (let j = 0; j < newStr.length; j++) {
      const prevLetter = newStr[j - 1];
      const letter = newStr[j];
      const nextLetter = newStr[j + 1];

      if (letter !== symbol) {
        continue;
      }

      const embeddingLevels = bidi.getEmbeddingLevels(newStr.join(''), 'ltr');
      const flips = getRtlFlips(embeddingLevels.levels);

      // in the middle
      if (new RegExp(HEBREW, 'gi').test(prevLetter) || new RegExp(HEBREW, 'gi').test(nextLetter)) {
        const prevFlip = flips.find((flip) => j - 1 >= flip[0] && j - 1 <= flip[1]);
        const prevStr = prevFlip ? newStr.slice(prevFlip[0], prevFlip[1] + 1) : [];
        const nextFlip = flips.find((flip) => j + 1 >= flip[0] && j + 1 <= flip[1]);
        const nextStr = nextFlip ? newStr.slice(nextFlip[0], nextFlip[1] + 1) : [];

        const restPrev = newStr.slice(0, prevFlip ? prevFlip[0] : j - 1);
        const restNext = newStr.slice(nextFlip ? nextFlip[1] + 1 : j + 1);

        newStr = [...restPrev, ...nextStr, 'נ', ...prevStr, ...restNext];
      }
    }

    rows[i] = newStr.join('');
  }

  return rows.join('\n');
};

export const apiMigrations = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    migrationAttachDocuments: builder.mutation<void, { userPatientMigrationLogID: string }>({
      query: ({ userPatientMigrationLogID }) => ({
        url: API_MIGRATIONS.ATTACH_DOCUMENTS,
        params: { id: userPatientMigrationLogID },
      }),
    }),
    migrationSetIgnore: builder.mutation<void, { userPatientMigrationLogID: string }>({
      query: ({ userPatientMigrationLogID }) => ({
        url: API_MIGRATIONS.SET_IGNORE,
        params: { id: userPatientMigrationLogID },
      }),
    }),
    migrateMeetingsFromExcel: builder.mutation({
      queryFn: (input: MigrateMeetingsFromExcelInput) => {
        return ServiceMigrations.migrateMeetingsFromExcel(input);
      },
    }),
  }),
});
