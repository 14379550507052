import { DynamicService } from 'utils/service';
import { API_ICD10S, Icd10s } from './models';
import { apiRtk, RTK_TAGS, transformResponseDynamic } from 'utils/rtk-query';

export * from './models';

class Service extends DynamicService<Icd10s> {}

export const ServiceIcd10s = new Service({
  mainField: 'id',
  getAll: API_ICD10S.GET_ALL_DYNAMIC,
  post: API_ICD10S.POST,
  delete: API_ICD10S.DELETE,
  patch: API_ICD10S.PATCH,
});

export const apiIcd10s = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getSourceIcds: builder.query({
      queryFn: async (arg: void) => {
        const { data } = await ServiceIcd10s.getAllDynamic<
          Pick<Icd10s, 'id' | 'code' | 'description' | 'titleHeb'>
        >({
          select: ['id', 'code', 'description', 'titleHeb'].join(','),
          filter: 'isActive==true',
          orderBy: 'description asc',
        });

        return { data: transformResponseDynamic(data) };
      },
      providesTags: () => [{ type: RTK_TAGS.ICD10S, id: 'source' }],
    }),
  }),
});
