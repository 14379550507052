import { DynamicService } from 'utils/service';

import {
  API_USER_EMPLOYEE_PROFILE_PERMISSION_NOTEBOOKS,
  UserEmployeeProfilePermissionNotebookInput,
} from './models';
export * from './models';

class Service extends DynamicService<Components.Schemas.UserEmployeeProfilePermissionNotebook> {
  createBulk = async (data: UserEmployeeProfilePermissionNotebookInput) => {
    return this.engine.post(API_USER_EMPLOYEE_PROFILE_PERMISSION_NOTEBOOKS.INSERT_BULK, {
      mainID: data.notebookID,
      itemIDs: data.userEmployeeProfilePermissionIDs,
    });
  };
}

export const ServiceUserEmployeePermissionNotebooks = new Service({
  mainField: 'id',
  getAll: API_USER_EMPLOYEE_PROFILE_PERMISSION_NOTEBOOKS.GET_ALL_DYNAMIC,
  post: API_USER_EMPLOYEE_PROFILE_PERMISSION_NOTEBOOKS.POST,
  delete: API_USER_EMPLOYEE_PROFILE_PERMISSION_NOTEBOOKS.DELETE,
  patch: API_USER_EMPLOYEE_PROFILE_PERMISSION_NOTEBOOKS.PATCH,
});
