import React from 'react';
import { Button } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import style from './index.module.scss';
import { TooltipError } from 'components/tooltips';
import clsx from 'clsx';
import { Stack } from 'components/stack';
import AddIcon from '@material-ui/icons/Add';

interface Props {
  isShowCloneTooltip: boolean;
  onClone?: () => void;
  onCreate: () => void;
  disabled: boolean;
}
export const ControlSlot: React.FC<Props> = ({
  onClone,
  onCreate,
  disabled,
  isShowCloneTooltip,
}) => {
  const { t, tp } = useTranslate();

  return (
    <Stack px={1} spacing={1}>
      {onClone && (
        <TooltipError
          open={isShowCloneTooltip}
          title={tp('rule-clinical-drugs-copy')}
          arrow
          placement={'top'}
          classes={{
            popper: style.tooltip,
          }}
        >
          <Button
            className={clsx(style.btnCopy, isShowCloneTooltip && style.blink)}
            startIcon={<FileCopyIcon />}
            onClick={onClone}
            disabled={disabled}
          >
            {t('clone-from-previous')}
          </Button>
        </TooltipError>
      )}
      <Button
        color={'primary'}
        variant={'contained'}
        startIcon={<AddIcon />}
        disabled={disabled}
        onClick={onCreate}
      >
        {tp('add-new')}
      </Button>
    </Stack>
  );
};
