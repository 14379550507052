import React, { FC, memo } from 'react';
import { IDataGridFilter } from '../../model';
import { FormControlLabel, Switch } from '@material-ui/core';
import { debounce } from 'lodash-es';
import { useI18n } from 'AurionCR/components';

const Filter: FC<{
  filter: IDataGridFilter;
  value: string;
  onTasks: any;
}> = ({ filter, value, onTasks }) => {
  const { t } = useI18n();
  const debounceOnChange = React.useCallback(
    debounce((value: boolean) => {
      onTasks([['pagination', { page: 1 }], ['filters', { [filter.field]: value }], ['getData']]);
    }, 400),
    [],
  );

  return (
    <FormControlLabel
      control={
        <Switch
          color={'primary'}
          checked={Boolean(value)}
          onChange={(e, checked) => debounceOnChange(checked)}
        />
      }
      // @ts-ignore
      label={t(filter.options?.label)}
    />
  );
};

export default memo(Filter);
