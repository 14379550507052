import { Box, BoxProps } from '@material-ui/core';
import React from 'react';

interface Props extends BoxProps {
  ratioWidth: number;
  ratioHeight: number;
  children: React.ReactNode;
}

export const AspectRatio: React.FC<Props> = ({ children, ratioWidth, ratioHeight, ...rest }) => {
  const ratio = (ratioHeight / ratioWidth) * 100 + '%';
  return (
    <Box position={'relative'} pb={ratio} {...rest}>
      <Box position={'absolute'} top={0} left={0} right={0} bottom={0}>
        {children}
      </Box>
    </Box>
  );
};
