import React, { useMemo } from 'react';

import { Popover, PopoverProps } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { createColumn, DataGridLight } from 'components/data-grid-light';
import { ButtonDownload29Gimel } from '../button-download-29-gimel';
import { Skeleton } from '@material-ui/lab';
const renderSkeleton = () => {
  return <Skeleton />;
};

interface RowModel {
  id: string;
  catalogName?: string | null | undefined;
  dosageFormTotalDesc?: string | null | undefined;
  dosageFormDaily?: string | null | undefined;
  instructions?: string | null | undefined;
  includeForm29?: boolean | undefined;
}
interface Props extends PopoverProps {
  userPatientProfilePrescriptionID: string;
  rows: RowModel[];
  isLoading: boolean;
  count?: number;
}

export const PrescriptionDetailsPopup: React.FC<Props> = ({
  rows,
  userPatientProfilePrescriptionID,
  isLoading,
  count = 2,
  ...rest
}) => {
  const { t } = useTranslate();
  const columns = useMemo(
    () =>
      [
        createColumn<RowModel, 'catalogName'>({
          id: 'catalogName',
          field: 'catalogName',
          renderHeader: () => t('drug-name'),
        }),
        createColumn<RowModel, 'dosageFormTotalDesc'>({
          id: 'dosageFormTotal',
          field: 'dosageFormTotalDesc',
          renderHeader: () => t('dosage-form-total'),
        }),
        createColumn<RowModel, 'dosageFormDaily'>({
          id: 'dosageFormDaily',
          field: 'dosageFormDaily',
        }),
        createColumn<RowModel, 'instructions'>({
          id: 'instructions',
          field: 'instructions',
        }),
        createColumn<RowModel, any>({
          id: 'controls',
          field: 'CONTROLS',
          renderHeader: () => null,
          renderColumn: ({ row }) => (
            <ButtonDownload29Gimel
              userPatientProfilePrescriptionID={userPatientProfilePrescriptionID}
              userPatientProfilePrescriptionDetailID={row.id}
              includeForm29={!!row.includeForm29}
            />
          ),
        }),
      ].map((column) => {
        if (!isLoading) {
          return column;
        }
        return { ...column, renderColumn: renderSkeleton };
      }),
    [userPatientProfilePrescriptionID, t, isLoading],
  );

  const innerRows = useMemo(() => {
    if (!isLoading) return rows;

    return new Array(count).fill(null).map((_, i) => {
      return { id: String(i) };
    });
  }, [isLoading, rows, count]);

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...rest}
    >
      <DataGridLight columns={columns} rows={innerRows} />
    </Popover>
  );
};
