import React, { useMemo } from 'react';
import { MODEL_TYPE, Row } from '../../models';

import { Stack, StackProps } from 'components/stack';
import { CONFIG_MODEL_TYPE } from '../../helpers';
import { Box, ButtonBase, Divider, Typography } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { useOpen } from 'AurionCR/components/hooks';
import { DialogClinicalMeeting } from 'components/dialog-clinical-meeting';
import { useAppSelector } from 'store';
import { selectCMTimes } from 'pages/ClinicalMeetings/store';
import { TemplateFullSessionInfo } from 'components/templates/template-full-info';
import { isToday } from 'date-fns';
import { convertToDate } from 'utils/dates';
import { ShortRemark } from 'components/short-remark';

interface Props extends StackProps {
  type: MODEL_TYPE;
  row: Row;
  onRefresh: () => void;
}

const ColumnTypeDefault: React.FC<Props> = ({ row, type, children, ...rest }) => {
  const { t } = useTranslate();
  const { Icon, color } = useMemo(() => {
    return CONFIG_MODEL_TYPE[type];
  }, [type]);

  return (
    <Stack direction={'row'} spacing={1} {...rest}>
      <Icon style={{ color: color }} />
      <Typography>{t(type)}</Typography>
      {children}
    </Stack>
  );
};

interface ColumnTypeClinicalProps extends Props {
  clinicalMeetingID: string;
}
const ColumnTypeClinicalMeeting: React.FC<ColumnTypeClinicalProps> = ({
  clinicalMeetingID,
  ...rest
}) => {
  const times = useAppSelector(selectCMTimes);
  const { isOpen, handleOpen, handleClose } = useOpen();

  return (
    <>
      <ColumnTypeDefault
        component={ButtonBase}
        direction={'row'}
        onClick={handleOpen}
        spacing={1}
        {...rest}
      />
      {isOpen && (
        <DialogClinicalMeeting
          itemID={clinicalMeetingID}
          onClose={handleClose}
          onComplete={rest.onRefresh}
          times={times}
        />
      )}
    </>
  );
};

interface ColumnTypeSessionProps extends Props {
  sessionID: string;
}
const ColumnTypeSession: React.FC<ColumnTypeSessionProps> = ({ sessionID, ...rest }) => {
  return (
    <ColumnTypeDefault {...rest} style={{ cursor: 'pointer' }} alignItems={'center'}>
      <Box width={'100%'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
        <TemplateFullSessionInfo
          userPatientProfileSessionID={sessionID}
          IconButtonProps={{ size: 'small' }}
        />
      </Box>
    </ColumnTypeDefault>
  );
};

export const ColumnVariant: React.FC<Props> = ({ row, type, onRefresh }) => {
  const sessionID = useMemo(() => {
    switch (row.type) {
      case MODEL_TYPE.SESSION: {
        return row.id;
      }
      case MODEL_TYPE.SUPPORT_MEETING: {
        return row.userPatientProfileSessionID;
      }
    }
  }, [row]);

  if (sessionID) {
    return <ColumnTypeSession sessionID={sessionID} row={row} type={type} onRefresh={onRefresh} />;
  }

  if (row.type === MODEL_TYPE.CLINICAL_MEETING) {
    return (
      <ColumnTypeClinicalMeeting
        clinicalMeetingID={row.id}
        type={type}
        row={row}
        onRefresh={onRefresh}
      />
    );
  }

  return <ColumnTypeDefault row={row} type={type} onRefresh={onRefresh} />;
};

export const ColumnType: React.FC<Props> = (props) => {
  const { t } = useTranslate();
  const isCurrentDate = isToday(convertToDate(props.row.date));
  return (
    <Stack spacing={1} divider={<Divider flexItem orientation={'vertical'} />}>
      <div style={{ flexGrow: 1 }}>
        <ColumnVariant {...props} />
      </div>
      {isCurrentDate && <ShortRemark>{t('today')}</ShortRemark>}
    </Stack>
  );
};
