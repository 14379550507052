import React from 'react';
import { NextMeetingForm } from '../next-meeting-form';
import { PreviewSessionCard } from 'components/preview-session-effects';
import { useTranslate } from 'hooks/use-translate';
interface Props {
  isLoading: boolean;
}
export const NextMeetingEditContainer: React.FC<Props> = ({ isLoading }) => {
  const { t } = useTranslate();
  return (
    <PreviewSessionCard title={t('edit-existing')}>
      <NextMeetingForm isLoading={isLoading} />
    </PreviewSessionCard>
  );
};
