import React from 'react';
import { Box, Button, ButtonProps } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import AddIcon from '@material-ui/icons/Add';

export const AddNewButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslate();
  return (
    <Button variant={'contained'} color={'primary'} startIcon={<AddIcon />} {...props}>
      {t('add-new')}
    </Button>
  );
};

interface EmptySlotProps {
  onCreate: () => void;
}

export const EmptySlot: React.FC<EmptySlotProps> = ({ onCreate }) => {
  return (
    <Box flexGrow={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <AddNewButton onClick={onCreate} />
    </Box>
  );
};
