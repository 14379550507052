import React, { memo } from 'react';
import { AvatarOnline } from 'components/avatar-online';
import { AvatarProps } from '@material-ui/core';
import { useAppSelector } from 'store';
import { selectEmployeesMonitorEmployeesListMap } from 'store/employees-monitor';

interface Props extends Omit<AvatarProps, 'src'> {
  src?: string | null | undefined;
  userEmployeeProfileID: string;
}
export const EmployeeAvatar = memo<Props>(({ userEmployeeProfileID, src, ...rest }) => {
  const map = useAppSelector(selectEmployeesMonitorEmployeesListMap);

  const isOnline = map[userEmployeeProfileID] && map[userEmployeeProfileID].__isOnline;

  return <AvatarOnline isOnline={Boolean(isOnline)} {...rest} src={src ?? undefined} />;
});
