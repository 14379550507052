import React, { memo, useEffect } from 'react';
import { useAppDispatch } from 'store';
import { actionLanguagesInit } from 'store/languages';

export const AppTranslateProvider = memo(({ children }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(actionLanguagesInit());
  }, [dispatch]);

  return <>{children}</>;
});
