import React, { useCallback } from 'react';
import { GridModel } from '../../models';
import { IDataGridLightEditorProps } from 'components/data-grid-light';

interface Props extends IDataGridLightEditorProps<GridModel, 'userEmployeeProfileID'> {}
export const EditorReplyEmployee: React.FC<Props> = ({ row, field, onClose, onSubmit }) => {
  const handleSubmit = useCallback<typeof onSubmit>(
    (formData) => {
      onSubmit({
        ...formData,
        isViewed: true,
        replyDate: new Date().toISOString(),
      });
    },
    [onSubmit],
  );
  return <EditorReplyEmployee row={row} field={field} onClose={onClose} onSubmit={handleSubmit} />;
};
