import React, { useCallback, useState } from 'react';
import { ButtonSendSms } from 'components/dialog-patient/components';
import { SupportMeeting } from 'services/support-meetings';
import { UserPatientProfile } from 'services/user-patient-profile';
import { useCurrentUser } from 'components/hooks';
import { dateFormat } from 'utils/dates';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { useTranslate } from 'hooks/use-translate';
import { ActivityLogModel, DialogSupportMeetingActivityLog } from '../dialog-activity-log';
import { useEffectError, useMountedRef } from 'hooks';
import { apiSupportMeetingActivities } from 'services/support-meeting-activities';
import { isMutationFulfilled } from 'utils/rtk-query';
import { supportMeetingEmitter } from '../../emitter';

const useMutation = apiSupportMeetingActivities.usePostSupportMeetingActivityMutation;

interface Props {
  userPatientProfileID: string;
  supportMeetingID: string;
  patient: Pick<UserPatientProfile, 'mobilePhone' | 'firstName' | 'lastName'> | null;
  meeting: Pick<SupportMeeting, 'meetingFromDateTime' | 'meetingToDateTime'> | null;
}
export const ButtonSms: React.FC<Props> = ({
  userPatientProfileID,
  patient,
  meeting,
  supportMeetingID,
}) => {
  const mountedRef = useMountedRef();
  const { t } = useTranslate();
  const [remarks, setRemarks] = useState('');
  const employee = useCurrentUser();

  const onSendDone = useCallback(
    (value: { message?: string | null }) => {
      const employeeFullName = [employee.firstName, employee.lastName].filter(Boolean).join(' ');
      setRemarks(
        [
          t('sent-sms-meeting'),
          dateFormat(new Date(), APP_FORMAT_DATE_TIME),
          `"${value.message}"`,
          t('from-employee'),
          employeeFullName,
        ]
          .filter(Boolean)
          .join(' '),
      );
    },
    [t, employee],
  );
  const onCancel = useCallback(() => {
    setRemarks('');
  }, []);

  const [performCreate, resultCreate] = useMutation();
  useEffectError(resultCreate.error);
  const onConfirm = useCallback(
    async (data: ActivityLogModel) => {
      const result = await performCreate({
        remarks: data.remarks,
        remarkForPatientCallStatusID: data.remarkForPatientCallStatusID,
        supportMeetingID,
      });

      if (!mountedRef.current) return;
      if (isMutationFulfilled(result)) {
        onCancel();
        supportMeetingEmitter.emit('activity-created', { meetingID: supportMeetingID });
      }
    },
    [performCreate, supportMeetingID, mountedRef, onCancel],
  );

  return (
    <>
      <ButtonSendSms
        userPatientProfileID={userPatientProfileID}
        toPhoneNumber={patient?.mobilePhone}
        patient={patient ?? {}}
        meeting={meeting ?? null}
        fullWidth
        onDone={onSendDone}
      />
      {remarks && (
        <DialogSupportMeetingActivityLog
          isLoading={resultCreate.isLoading}
          title={t('sent-sms-meeting')}
          remarks={remarks}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};
