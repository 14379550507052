import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { useOpen } from 'AurionCR/components/hooks';
import { DialogEndTreatmentConsultationContainer } from '../dialog-treatment-consultation-container';

interface Props extends ButtonProps {
  userPatientProfileSubscriptionID: string;
  userPatientProfileID: string;
  price: number;

  onDone: () => void;
}

export const ButtonTreatmentConsultation: React.FC<Props> = ({
  userPatientProfileSubscriptionID,
  userPatientProfileID,
  onDone,
  price,
  ...rest
}) => {
  const { isOpen, handleOpen, handleClose } = useOpen();
  const { t } = useTranslate();

  return (
    <>
      <Button
        color={'primary'}
        variant={'contained'}
        {...rest}
        disabled={rest.disabled}
        startIcon={rest.startIcon}
        onClick={handleOpen}
      >
        {t('end-treatment-consultation')}
      </Button>
      {isOpen && (
        <DialogEndTreatmentConsultationContainer
          userPatientProfileID={userPatientProfileID}
          userPatientProfileSubscriptionID={userPatientProfileSubscriptionID}
          onDone={onDone}
          onClose={handleClose}
          price={price}
        />
      )}
    </>
  );
};
