import React, { useMemo } from 'react';
import { apiUserPatientPrescriptions } from 'services/user-patient-profile-prescriptions';
import { DialogValidity, ListItem } from 'components/dialog-validity';
import { useTranslate } from 'hooks/use-translate';
import { ValidityValue } from './validity-value';
import { Stack } from 'components/stack';
import { Typography } from '@material-ui/core';
import {
  apiUserPatientProfile,
  schemaPatientForPrescriptionForm,
} from 'services/user-patient-profile';
import { useSourceCities } from 'components/hooks';
import { ValidityCity } from './validity-city';
import { useAsyncMemo } from 'hooks/use-async-memo';
import { InferType, ValidationError } from 'yup';
import { ValidityIdNumber } from './validity-id-number';

const schema = schemaPatientForPrescriptionForm.pick(['cityID', 'idNumber']);
type ValidationData = InferType<typeof schema>;

const factoryHasError = (name: keyof ValidationData) => {
  return (e: ValidationError) => {
    return e.inner.some((inner) => inner.path === name);
  };
};

const useGetPatientDetails = apiUserPatientProfile.useGetPatientForGeneralFormQuery;
const useGetPatientPrescriptionDetails =
  apiUserPatientPrescriptions.useGetMedicalPrescriptionDetailsForNeopharmQuery;

interface Props {
  userPatientProfileID: string | null | undefined;
  prescriptionID: string;
  onContinue: () => void;
  onClose: () => void;
}
export const DialogNeopharmValidity: React.FC<Props> = ({
  userPatientProfileID,
  prescriptionID,
  onContinue,
  onClose,
}) => {
  const { tp } = useTranslate();

  const resultPatient = useGetPatientDetails(userPatientProfileID || '', {
    skip: !userPatientProfileID,
  });
  const resultPrescription = useGetPatientPrescriptionDetails(prescriptionID, {
    refetchOnMountOrArgChange: true,
  });

  const drugsList = useMemo<ListItem[]>(() => {
    const drugs = (resultPrescription.data || []).map((detail) => {
      const quantity = detail.quantities.find(
        (q) => q.dosageFormTotalDesc === detail.dosageFormTotalDesc,
      );

      return {
        title: (
          <Stack direction={'column'}>
            <Typography color={'secondary'} variant={'body2'} style={{ fontWeight: 'bold' }}>
              {detail.drugName}
            </Typography>
            <Typography color={'textSecondary'} variant={'body2'}>
              {detail.dosageFormTotalDesc}
            </Typography>
          </Stack>
        ),
        value: (
          <ValidityValue
            drugID={detail.drugID}
            drugName={detail.drugName}
            pharmaDrugID={detail.pharmacyDrug?.id ?? null}
            quantity={quantity?.quantity ?? null}
            dosageFormTotalDesc={detail.dosageFormTotalDesc}
          />
        ),
        isValid: Boolean(quantity),
      };
    });

    return drugs;
  }, [resultPrescription.data]);

  const patient = resultPatient.data;
  const cityID = patient?.cityID;
  const sourceCities = useSourceCities();
  const city = cityID ? sourceCities.map[cityID] : null;

  const validity = useAsyncMemo<null | Record<keyof ValidationData, boolean>>(async () => {
    if (!resultPatient.data) {
      return null;
    }
    try {
      await schema.validate(resultPatient.data, { abortEarly: false });

      return { cityID: true, idNumber: true };
    } catch (e: any) {
      if (!(e instanceof ValidationError)) {
        return null;
      }

      return {
        cityID: !factoryHasError('cityID')(e),
        idNumber: !factoryHasError('idNumber')(e),
      };
    }
  }, [resultPatient.data]);

  const list = useMemo(() => {
    return [
      ...drugsList,
      {
        title: (
          <Stack direction={'column'}>
            <Typography color={'secondary'} variant={'body2'} style={{ fontWeight: 'bold' }}>
              {tp('city')}
            </Typography>
            <Typography color={'textSecondary'} variant={'body2'} title={tp('address')}>
              {patient?.address}
            </Typography>
          </Stack>
        ),
        value: (
          <ValidityCity
            userPatientProfileID={userPatientProfileID}
            isValid={!!validity?.cityID}
            value={city?.title}
          />
        ),
        isValid: !!validity?.cityID,
      },
      {
        title: (
          <Stack direction={'column'}>
            <Typography color={'secondary'} variant={'body2'} style={{ fontWeight: 'bold' }}>
              {tp('id-number')}
            </Typography>
          </Stack>
        ),
        value: (
          <ValidityIdNumber
            userPatientProfileID={userPatientProfileID}
            isValid={!!validity?.idNumber}
            value={patient?.idNumber}
          />
        ),
        isValid: !!validity?.idNumber,
      },
    ];
  }, [drugsList, city, tp, validity, userPatientProfileID, patient]);

  const isFetching = resultPatient.isFetching || resultPrescription.isFetching;
  const isSuccess = resultPatient.isSuccess && resultPatient.isSuccess;

  return (
    <DialogValidity
      title={tp('neopharm-verification')}
      list={list}
      success={{
        title: tp('neopharm-verified-success-title'),
        description: tp('neopharm-verified-success-description'),
      }}
      error={{
        title: tp('neopharm-verified-error-title'),
        description: tp('neopharm-verified-error-description'),
      }}
      isLoading={isFetching}
      isLoadedSuccess={isSuccess}
      onSubmit={onContinue}
      ButtonSubmitProps={{
        children: tp('send'),
      }}
      onClose={onClose}
    />
  );
};
