import React from 'react';
import { API_CLINICAL_MEETINGS, ClinicalMeeting } from 'services/clinical-meetings';
import { TemplateMeetingDate } from 'components/templates/template-meeting-date';
import { TemplateEmployee } from 'components/templates/template-employee';
import { UserEmployeeProfile } from 'services/user-employee-profiles';
import { permissionKey } from 'services/user-employee-profile-permissions';
import { calcFilterSwitcher, PATIENT_MEETING_SWITCH } from '../../helpers';
import { APP_FORMAT_DATE, APP_FORMAT_DATE_TIME } from 'configs/const';
import style from './index.module.scss';
import { Box } from '@material-ui/core';
import { ColumnClinicalMeetingActivities } from '../column-clinical-meeting-activities';
import { ClinicalControlClose } from '../clinical-control-close';
import { format } from 'date-fns';
import { convertToDate } from 'utils/dates';
import { TemplateClinicalMeetingSubject } from 'components/templates/template-clinical-meeting-subject';
import { TemplateClinicalMeetingType } from 'components/templates/template-clinical-meeting-type';
import { ColumnClinicalMeetingVisit } from '../column-clinical-meeting-visit';
import { ControlNotShowingUp } from './components/control-not-showing-up';
import { EditorClinicalMeetingApprove } from '../editor-clinical-meeting-approve';

interface GridData
  extends Pick<
    ClinicalMeeting,
    | 'clinicalMeetingSubjectID'
    | 'clinicalMeetingTypeID'
    | 'meetingFromDateTime'
    | 'meetingToDateTime'
    | 'userEmployeeProfileID'
    | 'completeSessionDateTime'
    | 'approveMeeting'
    | 'isActive'
    | 'id'
  > {
  employee: Pick<UserEmployeeProfile, 'firstName' | 'lastName'>;
  activitiesCount: number;
  isCanceled: boolean;
  isNotShowingUpMeeting: boolean;
}

interface Options {
  userPatientProfileID: string;
  period: PATIENT_MEETING_SWITCH;
  onEdit: (itemID: string) => void;
}
export const GridConfigClinical = ({ userPatientProfileID, period, onEdit }: Options) => ({
  store: {
    get: {
      url: API_CLINICAL_MEETINGS.GET_ALL_DYNAMIC,
      selected: [
        'isActive',
        'meetingToDateTime',
        'clinicalMeetingSubject.isCanceledMeeting as isCanceled',
        'clinicalMeetingSubject.isNotShowingUpMeeting as isNotShowingUpMeeting',
        `new { userEmployeeProfile.firstName, userEmployeeProfile.lastName } as employee`,
        'clinicalMeetingActivities.Count() as activitiesCount',
      ].join(','),
      filters: [`(userPatientProfileID=="${userPatientProfileID}")`, calcFilterSwitcher(period)]
        .filter(Boolean)
        .join('&&'),
    },
    patch: {
      url: API_CLINICAL_MEETINGS.PATCH,
    },
    delete: {
      url: API_CLINICAL_MEETINGS.DELETE,
      title: (data: GridData) =>
        data.meetingFromDateTime
          ? format(convertToDate(data.meetingFromDateTime), APP_FORMAT_DATE_TIME)
          : '',
    },
    sortable: {
      field: 'meetingFromDateTime',
      order: 'desc',
    },
  },
  controlsDropDown: [
    {
      template: ControlNotShowingUp,
      type: 'custom',
      permission: permissionKey('isAllowToEditClinicalMeetings'),
    },
    {
      template: ClinicalControlClose,
      type: 'custom',
      permission: permissionKey('isAllowToCancelMeeting'),
    },
    {
      type: 'delete',
      title: 'delete',
      permission: permissionKey('isAllowToDeleteAnything'),
    },
  ],
  toolbar: {
    title: '',
    controls: null,
    controlsDefault: null,
  },
  columns: [
    {
      title: '',
      field: 'clinicalMeetingSubjectID',
      sortable: 'clinicalMeetingSubject.title',
      template: (data: GridData) => (
        <TemplateClinicalMeetingSubject meetingSubjectsID={data.clinicalMeetingSubjectID} />
      ),
      tdClasses: 'data-grid-td-width-40',
    },
    {
      title: '',
      field: 'clinicalMeetingTypeID',
      sortable: 'clinicalMeetingType.title',
      template: (data: GridData) => (
        <TemplateClinicalMeetingType meetingTypeID={data.clinicalMeetingTypeID} />
      ),
      tdClasses: 'data-grid-td-width-50',
    },
    {
      title: 'date-time',
      field: 'meetingFromDateTime',
      template: (data: GridData) => (
        <Box display={'flex'} height={'100%'}>
          <TemplateMeetingDate
            meeting={data}
            dateFormat={APP_FORMAT_DATE}
            onClick={() => {
              onEdit(data.id);
            }}
            className={style.activeColumn}
          />
          <Box alignSelf={'center'}>
            <ColumnClinicalMeetingActivities meetingID={data.id} count={data.activitiesCount} />
          </Box>
        </Box>
      ),
    },
    {
      title: 'employee',
      field: 'userEmployeeProfileID',
      sortable: 'userEmployeeProfile.firstName',
      template: (data: GridData) => <TemplateEmployee employee={data.employee} />,
    },
    {
      title: 'approve-meeting',
      field: 'approveMeeting',
      template: 'boolean',
      editor: {
        type: 'custom',
        component: EditorClinicalMeetingApprove,
      },
    },
    {
      title: 'visit',
      field: 'completeSessionDateTime',
      template: (data: GridData) => (
        <ColumnClinicalMeetingVisit
          meetingID={data.id}
          completeSessionDateTime={data.completeSessionDateTime ?? null}
        />
      ),
    },
  ],
});
