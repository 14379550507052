import React, { useCallback, useMemo } from 'react';
import { useEffectError } from 'hooks';
import { apiUserPatientPrescriptions } from 'services/user-patient-profile-prescriptions';
import { Button, Link } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { getFileNameFromUrl } from 'utils/file-uploader';
import { useDataGridTasks } from 'components/data-grid/hooks';
import { composeFunctions } from 'utils';
import { useOpen } from 'AurionCR/components/hooks';
import { handlerStopAndPrevent } from 'utils/handlers';
import { DialogConfirm } from 'components/dialog-confirm';

const usePrintMutation = apiUserPatientPrescriptions.usePrintPatientPrescriptionMutation;

interface PrintProps {
  userPatientProfilePrescriptionID: string;
  documentURL: string;
  remarks: string;
}
const Print: React.FC<PrintProps> = ({
  documentURL,
  remarks,
  userPatientProfilePrescriptionID,
}) => {
  const { tp } = useTranslate();
  const { onTasks } = useDataGridTasks();
  const [print, resultPrint] = usePrintMutation();
  useEffectError(resultPrint.error);

  const fileName = useMemo(() => {
    return getFileNameFromUrl(documentURL);
  }, [documentURL]);

  const onDownload = useCallback(async () => {
    await print({ userPatientProfilePrescriptionID, url: documentURL, remarks });

    onTasks([['getData']]);
  }, [print, documentURL, userPatientProfilePrescriptionID, onTasks, remarks]);

  const confirmState = useOpen();

  return (
    <>
      <Button
        color={'primary'}
        component={Link}
        disabled={resultPrint.isLoading}
        title={fileName}
        href={`#download-${fileName}`}
        onClick={composeFunctions(handlerStopAndPrevent, confirmState.handleOpen)}
      >
        {`[${tp('download-document')}]`}
      </Button>
      {confirmState.isOpen && (
        <DialogConfirm
          title={tp('confirm-print')}
          message={tp('are-you-sure-print-prescription')}
          onCancel={confirmState.handleClose}
          onConfirm={composeFunctions(confirmState.handleClose, onDownload)}
        />
      )}
    </>
  );
};

interface TemplatePrintProps {
  userPatientProfilePrescriptionID: string;
  documentURL: string | null | undefined;
  remarks: string;
}
export const BaseTemplatePrint: React.FC<TemplatePrintProps> = ({
  documentURL,
  userPatientProfilePrescriptionID,
  remarks,
}) => {
  if (!documentURL) return null;

  return (
    <Print
      userPatientProfilePrescriptionID={userPatientProfilePrescriptionID}
      documentURL={documentURL}
      remarks={remarks}
    />
  );
};
