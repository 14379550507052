import React, { memo, useMemo } from 'react';
import { useAuth, UserNav } from 'AurionCR/components';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import style from './index.module.scss';

export const ProfileNav = memo(() => {
  const { user, permission } = useAuth();
  const menu = useMemo(() => {
    if (user && permission('isAllowToEditProfile')) {
      return [{ title: 'profile', path: '/profile' }];
    } else {
      return [];
    }
  }, [user, permission]);

  return (
    <div className={style.root}>
      <UserNav
        menu={menu}
        collapsed={false}
        visibleTitle={false}
        fieldImagePath="userPhoto"
        endIcon={<KeyboardArrowDownIcon fontSize="large" />}
      />
    </div>
  );
});
