import React from 'react';
import style from './index.module.scss';
import {
  DraftEmployee,
  DraftGeneral,
  DraftPatient,
  DraftPayment,
  DraftPrescription,
  DraftPrescriptionDetails,
  DraftQuestionnaire,
  DraftSubscription,
} from './components';
import { NativeScroll } from 'components/native-scroll';

interface Props {
  prescriptionID: string;
  prescription?: DraftPrescription;
  patientID: string | null | undefined;
  patient: DraftPatient | undefined;
  doctorID: string | null | undefined;
  doctor: DraftEmployee | null | undefined;
  subscription: null | undefined | DraftSubscription;
  isActive: boolean;
  onRefresh: () => void;
}

export const TabDraft: React.FC<Props> = ({
  prescriptionID,
  prescription,
  patientID,
  patient,
  doctor,
  subscription,
  onRefresh,
}) => {
  return (
    <NativeScroll className={style.scroll}>
      <div className={style.root}>
        <DraftGeneral
          className={style.general}
          prescriptionID={prescriptionID}
          patientID={patientID}
          prescription={prescription}
          doctor={doctor}
          onRefresh={onRefresh}
        />
        <DraftPayment
          className={style.payment}
          prescriptionID={prescriptionID}
          patientID={patientID}
          subscription={subscription}
        />
        <DraftQuestionnaire
          className={style.questionnaire}
          patientID={patientID}
          patient={patient}
        />
        <DraftPrescriptionDetails
          className={style.details}
          prescriptionID={prescriptionID}
          patientID={patientID}
        />
      </div>
    </NativeScroll>
  );
};
