const API_ENDPOINT = 'Cities';

export const API_CITIES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<Components.Schemas.City, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<Components.Schemas.City, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export enum CityIDs {
  NA = '777e8cb0-25c5-449f-83ec-a38a736842a0',
}
