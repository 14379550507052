import React, { FC, memo, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ClickAwayListener } from '@material-ui/core';
import { validateDateLike, validateDatePast } from 'AurionCR/components/formV2';
import { IDataGridColumnEditorCustom } from 'components/data-grid/model';
import Footer from 'components/data-grid/components/column/editors/footer';
import { useFieldProps, useMutationDynamic } from 'hooks';
import { GridOnTasks } from 'models/grid';
import { ServiceUserPatientProfileSessions } from 'services/user-patient-profile-session';
import { AppDatePicker } from 'components/app-date-picker';
import { usePatientWeights } from '../../../hooks';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object({
  data: yup
    .string()
    .required('rule-required')
    .test((value, context) => {
      const result = validateDatePast(value);

      if (!result) {
        return true;
      }

      return context.createError({ message: result });
    })
    .test((value, context) => {
      const result = validateDateLike(value);

      if (!result) {
        return true;
      }

      return context.createError({ message: result });
    })
    .default(''),
});

interface Row
  extends Pick<Components.Schemas.UserPatientProfileSessionItem, 'userPatientProfileSessionID'> {
  entryDate: Components.Schemas.UserPatientProfileSession['entryDate'];
  userPatientProfileID: string;
}

type FormModel = {
  data: string;
};

interface Props {
  row: Row;
  editor: IDataGridColumnEditorCustom;
  data: string;
  onClose: any;
  onTasks: GridOnTasks;
}
export const EditorEntryDate: FC<Props> = memo(({ row, onClose, onTasks }) => {
  const { handleSubmit, errors, control } = useForm({
    defaultValues: schema.cast({ data: row.entryDate }),
    resolver: yupResolver(schema),
  });

  const { refresh } = usePatientWeights(row.userPatientProfileID);

  const requestSubmit = useCallback(
    async (formData: FormModel) => {
      if (!row.userPatientProfileSessionID) {
        return;
      }

      await ServiceUserPatientProfileSessions.patch({
        id: row.userPatientProfileSessionID,
        entryDate: formData.data,
      });

      onTasks([['getData']]);
      onClose();
      refresh();
    },
    [onTasks, row, onClose, refresh],
  );
  const [onSubmit, { isLoading }] = useMutationDynamic(requestSubmit);

  const getFieldProps = useFieldProps({ errors });

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className="grid-editor date">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="data"
            render={(renderProps) => (
              <div className="date-field-wrapper">
                <AppDatePicker
                  {...getFieldProps(renderProps)}
                  label={null}
                  disabled={isLoading}
                  disableFuture={true}
                />
              </div>
            )}
          />
          <Footer onClose={onClose} disabled={isLoading} />
        </form>
      </div>
    </ClickAwayListener>
  );
});
