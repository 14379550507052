import React, { memo, useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { TMMerge, TMNotebookPath } from '../../store';
import { NotebookFormGenerator } from 'components/notebook-form-generator';
import { convertToDate } from 'utils/dates';

export const DialogNotebookFormGenerator = memo(() => {
  const dispatch = useAppDispatch();
  const { dialogNotebookFormGenerator } = useAppSelector((state) => state.TaskManager);
  const onDone = useCallback(
    (data: { id: string }) => {
      dispatch(
        TMNotebookPath({
          id: String(dialogNotebookFormGenerator?.id),
          userPatientProfileSessionID: data.id,
        }),
      );
    },
    [dialogNotebookFormGenerator, dispatch],
  );
  const handleClose = useCallback(() => {
    dispatch(TMMerge({ dialogNotebookFormGenerator: null }));
  }, [dispatch]);

  const excludeMeetingIDs = useMemo(() => {
    return [dialogNotebookFormGenerator?.id].filter(Boolean) as string[];
  }, [dialogNotebookFormGenerator]);

  const meetingTimes = useMemo(() => {
    if (!dialogNotebookFormGenerator) {
      return { meetingFromDateTime: undefined, meetingToDateTime: undefined };
    }
    return {
      meetingFromDateTime: convertToDate(
        dialogNotebookFormGenerator.meetingFromDateTime,
      ).toISOString(),
      meetingToDateTime: convertToDate(dialogNotebookFormGenerator.meetingToDateTime).toISOString(),
    };
  }, [dialogNotebookFormGenerator]);

  if (!dialogNotebookFormGenerator) return null;

  return (
    <NotebookFormGenerator
      notebookID={dialogNotebookFormGenerator.notebookID}
      userPatientProfileID={dialogNotebookFormGenerator.userPatientProfileID as string}
      supportMeetingTypeID={dialogNotebookFormGenerator.supportMeetingTypeID as string}
      excludeMeetingIDs={excludeMeetingIDs}
      showDelete={false}
      onClose={handleClose}
      onDone={onDone}
      meetingFromDateTime={meetingTimes.meetingFromDateTime}
      meetingToDateTime={meetingTimes.meetingToDateTime}
    />
  );
});

export default DialogNotebookFormGenerator;
