import React, { memo, useCallback, useMemo } from 'react';
import GridConfig from './grid-config';
import DataGrid from 'components/data-grid';
import { useFormGrid } from 'AurionCR/components/formV2';
import { DialogDietDiary } from 'components/dialog-diet-diary';

interface Props {
  userPatientProfileID: string;
}
export const GridDietDiaries = memo<Props>(({ userPatientProfileID }) => {
  const { grid, onGridHandle, editItemID, onClose } = useFormGrid('diet-diaries');

  const gridConfig = useMemo<any>(
    () => GridConfig({ userPatientProfileID }),
    [userPatientProfileID],
  );

  const onRefresh = useCallback(() => {
    grid.current?.onTasks([['getData']]);
  }, [grid]);

  return (
    <>
      <DataGrid ref={grid} config={gridConfig} onHandle={onGridHandle} />
      {editItemID && (
        <DialogDietDiary
          onClose={onClose}
          onRefresh={onRefresh}
          itemID={editItemID}
          userPatientProfileID={userPatientProfileID}
        />
      )}
    </>
  );
});
