import React, { FC, memo, useCallback } from 'react';
import { IDataGridColumnEditorDate } from '../../../model';
import { Controller, useForm } from 'react-hook-form';
import { ClickAwayListener } from '@material-ui/core';
import Footer from './footer';
import { DatePicker, validateRule } from 'AurionCR/components/formV2';
import { APP_FORMAT_DATE } from 'configs/const';
import { convertToDate, isDateLike } from 'utils/dates';

type FormModal = {
  data: string | Date | null;
};
const EditorDate: FC<{
  editor: IDataGridColumnEditorDate;
  data: string;
  onClose: any;
  onSubmit: any;
}> = ({ editor, data, onClose, onSubmit }) => {
  const { handleSubmit, errors, control } = useForm({ defaultValues: { data: data || '' } });

  const { format = APP_FORMAT_DATE } = editor;
  const submit = useCallback(
    (formData: FormModal) => {
      const value = isDateLike(formData.data)
        ? convertToDate(formData.data).toISOString()
        : formData.data;

      onSubmit({ data: value });
    },
    [onSubmit],
  );

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className="grid-editor date">
        <form onSubmit={handleSubmit(submit)}>
          <Controller
            control={control}
            name="data"
            render={({ value, onChange }) => {
              return (
                <DatePicker
                  format={format}
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  error={errors['data']}
                />
              );
            }}
            rules={editor.required ? validateRule('required,dateLike') : validateRule('dateLike')}
          />
          <Footer onClose={onClose} />
        </form>
      </div>
    </ClickAwayListener>
  );
};

export default memo(EditorDate);
