import React, { useEffect } from 'react';
import { apiStatic, getCookies } from 'AurionCR/components';
import axios from 'axios';
import { api } from 'utils/service';

interface AxiosInterceptorsTokenProps {}

export const AxiosInterceptorsToken: React.FC<AxiosInterceptorsTokenProps> = ({ children }) => {
  useEffect(() => {
    const ids = [api, apiStatic, axios].map((instance) => {
      const id = instance.interceptors.request.use((config) => {
        const token = getCookies(window.authCookieName_ || '');
        if (token) {
          config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
      });
      return { id, instance };
    });

    return () => {
      ids.forEach(({ instance, id }) => {
        instance.interceptors.request.eject(id);
      });
    };
  }, []);

  return <>{children}</>;
};
