import React, { useCallback, useMemo } from 'react';

import { getSearchFilter } from 'utils/front-filters';
import { useSourceEmployees, useSourcePermissions } from 'components/hooks';
import { Chip, ChipProps, Divider, Typography } from '@material-ui/core';
import { Stack } from 'components/stack';
import { AppSelect, AppSelectProps } from 'components/app-select';
import { EmployeeAvatar } from 'components/employee-avatar';
import { useEmployeeAvailability } from 'hooks/use-employee-availability';
import { AutocompleteGetTagProps } from '@material-ui/lab';

interface EmployeeChipProps extends ChipProps {
  userPhoto: string | null | undefined;
  userEmployeeProfileID: string;
}
export const EmployeeChip: React.FC<EmployeeChipProps> = ({
  userPhoto,
  userEmployeeProfileID,
  ...rest
}) => {
  return (
    <Chip
      size={'small'}
      color={'primary'}
      variant={'outlined'}
      avatar={
        <EmployeeAvatar
          userEmployeeProfileID={userEmployeeProfileID}
          src={userPhoto || undefined}
        />
      }
      {...rest}
    />
  );
};

type Option = {
  id: string;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  title?: string | null | undefined;
  userPhoto?: string | null | undefined;
  userEmployeeProfilePermissionID?: string | null | undefined;
  isActive?: boolean;
};

type RenderOptions = Omit<Option, 'userEmployeeProfilePermissionID'> & {
  permissionTitle?: string | null | undefined;
};

const renderTags = (options: RenderOptions[], getTagProps: AutocompleteGetTagProps) =>
  options.map((option, index) => (
    <EmployeeChip
      userPhoto={option.userPhoto}
      userEmployeeProfileID={option.id as string}
      label={option.title}
      {...getTagProps({ index })}
    />
  ));

const renderOption = (option: RenderOptions) => {
  const title = option.title || [option.firstName, option.lastName].filter(Boolean).join(' ');

  return (
    <Stack
      flex
      direction={'row'}
      spacing={1}
      divider={<Divider orientation={'vertical'} flexItem />}
      width={'100%'}
      alignItems={'center'}
    >
      <Stack style={{ flexBasis: '70%' }} direction={'row'} spacing={1} alignItems={'center'}>
        <EmployeeAvatar
          userEmployeeProfileID={option.id}
          style={{ width: 26, height: 26 }}
          src={option.userPhoto}
        />
        <Typography noWrap>{title}</Typography>
      </Stack>

      {option.permissionTitle && (
        <Typography
          style={{ flexBasis: '30%' }}
          color={'textSecondary'}
          title={option.permissionTitle || ''}
          noWrap
        >
          {option.permissionTitle}
        </Typography>
      )}
    </Stack>
  );
};
const filterOptions = <T extends RenderOptions>(options: T[], state: any) => {
  return options.filter(
    getSearchFilter<T>({
      value: state.inputValue,
      fields: ['firstName', 'lastName', 'permissionTitle'],
    }),
  );
};

interface Props<
  T extends Record<string, any> = { id: string; title: string; isActive?: boolean },
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
> extends Partial<AppSelectProps<T, Multiple, DisableClearable, FreeSolo>> {
  options?: T[];
  disabled?: boolean;
  loading?: boolean;
  label?: React.ReactNode;
  isActiveOnly?: boolean;
}

export const SelectEmployee = <
  T extends Option,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>({
  loading,
  isActiveOnly = true,
  ...rest
}: Props<T, Multiple, DisableClearable, FreeSolo>) => {
  const { checkIsAvailable } = useEmployeeAvailability();
  const sourcePermissions = useSourcePermissions();
  const source = useSourceEmployees(isActiveOnly);

  const employeeOptions = useMemo(() => {
    return (rest.options || source.data)
      .map((item) => {
        const permissionID = item.userEmployeeProfilePermissionID;
        const permission = permissionID ? sourcePermissions.map[permissionID] : null;

        return {
          ...item,
          permissionTitle: permission?.title,
        };
      })
      .sort((a, b) => {
        if (checkIsAvailable(a.id)) {
          return -1;
        }
        if (checkIsAvailable(b.id)) {
          return 1;
        }
        return 0;
      });
  }, [source, rest.options, sourcePermissions.map, checkIsAvailable]);

  const getOptionLabel = useCallback((option: Option) => {
    return option.title || '';
  }, []);

  return (
    <AppSelect
      filterOptions={filterOptions}
      renderOption={renderOption}
      renderTags={renderTags}
      getOptionLabel={getOptionLabel}
      {...rest}
      options={employeeOptions as T[]}
      loading={rest.options === undefined ? source.loading : loading}
    />
  );
};
