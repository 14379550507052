import { addMinutes, format, startOfDay } from 'date-fns';
import { iTimeLine } from './@type';

export const createTimeline = (hoursFrom: number, hoursTo: number, minutes = 10) => {
  const result: iTimeLine[] = [];

  const minutesSlice = minutes / 60;

  let date = addMinutes(startOfDay(new Date()), (hoursFrom - minutesSlice) * 60);

  for (let i = hoursFrom; i <= hoursTo; i += minutesSlice) {
    const id = format((date = addMinutes(date, minutesSlice * 60)), 'HH:mm');
    result.push({ id, decimal: i });
  }

  return result;
};

export const convertTimeStringFromNumber = (value: number) => {
  return (value < 10 ? '0' + value : value) + ':00';
};
