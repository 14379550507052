import React, { useMemo } from 'react';
import { ButtonRedirect } from 'components/app-buttons';
import { APP_ROUTES } from 'configs/routes';
import { useLocation } from 'react-router-dom';
import { useTranslate } from 'hooks/use-translate';
import { apiUserEmployeeProfiles } from 'services/user-employee-profiles';
import { useCurrentUser } from '../../../hooks';
import { Badge } from '@material-ui/core';
import style from './index.module.scss';
import clsx from 'clsx';

const useFetchCountQuery = apiUserEmployeeProfiles.useGetMyTasksCountQuery;

export const MyTasks: React.FC = () => {
  const { tp } = useTranslate();
  const { pathname } = useLocation();
  const { appUserID } = useCurrentUser();

  const result = useFetchCountQuery(appUserID, {
    // ms // s // m
    pollingInterval: 1000 * 60 * 2,
  });

  const rows = useMemo(() => {
    return result.data || [];
  }, [result.data]);

  const isUrgent = useMemo(() => {
    return rows.some((task) => task.isUrgent);
  }, [rows]);

  return (
    <Badge badgeContent={rows.length} color={'error'}>
      <ButtonRedirect
        className={clsx(style.root, isUrgent && style.rootUrgent)}
        to={{ ...APP_ROUTES.MY_TASKS().config, state: { prev: pathname } }}
      >
        {tp('my-tasks')}
      </ButtonRedirect>
    </Badge>
  );
};
