import React, {FC, memo, useMemo} from "react";
import {IDataGridColumnEditorSelect} from "../../../model";
import {Controller, useForm} from "react-hook-form";
import {ClickAwayListener} from "@material-ui/core";
import Footer from "./footer";
import {validateRule, Select} from "AurionCR/components/formV2";

const EditorSelect: FC<{
  editor: IDataGridColumnEditorSelect,
  data: string | number,
  onClose: any,
  onSubmit: any
}> = ({editor, data, onClose, onSubmit}) => {
  const {handleSubmit, errors, control} = useForm({defaultValues: {data: data || ''}}) as any;
  const settings = useMemo(() => ({
    placeholder: '',
    clearable: !editor.required,
    multiple: false,
    ...editor
  }), [editor]);
  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className="grid-editor string">
        <form
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            as={
              <Select
                // @ts-ignore
                settings={settings}
                error={errors.data}
              />
            }
            name="data"
            control={control}
            rules={editor.required ? validateRule('required') : {}}
          />
          <Footer onClose={onClose}/>
        </form>
      </div>
    </ClickAwayListener>
  )
};

export default memo(EditorSelect)
