import React from 'react';

import style from './index.module.scss';

import clsx from 'clsx';

interface Props {
  className?: string;
  value?: string;
}

export const ColorPreview: React.FC<Props> = ({ className, value }) => {
  return (
    <div className={clsx(style.root, className)}>
      <span className={style.color} style={{ backgroundColor: value }} />
    </div>
  );
};
