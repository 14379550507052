export enum LABELS_KEY {
  EXPERT = 'expert',
  PHARMA = 'pharma',
  CRM = 'crm',
  WEBSITES = 'websites',
}

const _API = 'Labels';

export const API_LABELS = {
  API: _API,
  GET_ALL_DYNAMIC: `${_API}/GetAllDynamic`,
  POST: `${_API}/Create`,
  PATCH: ({ id }: Pick<Label, 'id'>) => `${_API}/Patch/${id}`,
  DELETE: ({ id }: Pick<Label, 'id'>) => `${_API}/Delete/${id}`,
};

export class Label {
  id!: string;
  title: string = '';
  labelKey: string = '';
  longDescription: string = '';
  languageID!: string;
  labelSourceID!: string;
  isActive: boolean = true;
}

export type AppLabel = Pick<Label, 'title' | 'labelKey'>;
