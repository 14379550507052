const API_ENDPOINT = 'FormDocumentDrugs';

export const API_FORM_DOCUMENT_DRUGS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<Components.Schemas.UserEmployeeProfileToDoTaskCategory, 'id'>) =>
    `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<Components.Schemas.UserEmployeeProfileToDoTaskCategory, 'id'>) =>
    `${API_ENDPOINT}/Delete/${data.id}`,
  INSERT_BULK: `${API_ENDPOINT}/CreateFormDocumentDrugBulk`,
};

export interface FormDocumentDrugsBulkInput {
  formDocumentID: string;
  drugIDs: string[];
}
