import React from "react";

export type ErrorFileUploaderTypes = 'INCORRECT_TYPE' | 'ERROR_LOAD' | 'MAX_FILE_SIZE' | 'UNKNOWN'

export class ErrorFileUploader extends Error {
  public type: ErrorFileUploaderTypes
  constructor (type: ErrorFileUploaderTypes, message: string) {
    super(message)
    this.type = type
  }
}

export interface FileUploaderRenderProps {
  fileName?: string
  value: string | null
  error: ErrorFileUploader | null
  open: () => void
  remove: () => void
  renderProps: FileUploaderProps['renderProps']
}

export interface FileUploaderProps {
  className?: string
  apiPath: string;
  apiSet?: string;
  apiRemove?: string;
  filePath?: string;

  fileNameFromFieldModel?: string;

  name?: string;
  value: any;
  onChange: (e: any) => void;
  addDataToPost?: boolean;
  fileAccept?: string; // can be multiple pdf,doc
  fileMaxSize?: number; // MB
  isImage?: boolean

  render?: (props: FileUploaderRenderProps) => React.ReactNode,
  renderProps?: any
}
