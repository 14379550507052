import { ActionKey } from 'services/todo-task-categories';
import { FixServerObject } from 'utils/types';

export interface Model
  extends Pick<
    FixServerObject<Components.Schemas.ToDoTask, 'id'>,
    | 'id'
    | 'entryDate'
    | 'done'
    | 'userPatientProfileID'
    | 'sentByUserEmployeeProfileID'
    | 'rowIndex'
    | 'closeDate'
    | 'userPatientProfileSessionID'
  > {
  assignedTo: { id: string; fullName: string }[];
  actionKey: ActionKey;
  category: string;
  activitiesTotal: number;
  createdDate: string;
  sentByUserEmployeeProfileID: string;
}

export enum ASSIGNED {
  ALL = 'all',
  ASSIGN_TO_ME = 'to-me',
  ASSIGNED_BY_ME = 'by-me',
}

interface CalcAssignedOptions {
  appUserID: string;
  assigned: ASSIGNED;
}
export const calcAssigned = ({ assigned, appUserID }: CalcAssignedOptions) => {
  switch (assigned) {
    case ASSIGNED.ALL:
      return null;
    case ASSIGNED.ASSIGN_TO_ME:
      return `userEmployeeProfileToDoTasks.any(k => k.userEmployeeProfileID=="${appUserID}")`;
    case ASSIGNED.ASSIGNED_BY_ME:
      return `sentByUserEmployeeProfileID=="${appUserID}"`;
  }
};

export enum DONE_STATUS {
  NOT_DONE = 'not-done',
  DONE = 'done',
  ALL = 'all',
}

interface CalcDoneStatusOptions {
  doneStatus: DONE_STATUS;
}
export const calcDoneStatus = ({ doneStatus }: CalcDoneStatusOptions) => {
  switch (doneStatus) {
    case DONE_STATUS.NOT_DONE:
      return 'done=="false"';
    case DONE_STATUS.DONE:
      return 'done=="true"';
    case DONE_STATUS.ALL:
      return '';
  }
};

export const ASSIGN_BUTTON_SWITCHER = 'assignButtonSwitcher';
export const STATUS_DONE_BUTTON_SWITCHER = 'statusDoneButtonSwitcher';
