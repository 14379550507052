import React, { useMemo } from 'react';
import { formatWeight } from 'utils/numbers';
import { TemplateLtrOnly } from '../template-ltr-only';
import { BoxProps } from '@material-ui/core';

interface Props extends BoxProps {
  value: number | null | undefined;
}

export const TemplateWeight: React.FC<Props> = ({ value, ...rest }) => {
  const formated = useMemo(() => formatWeight(value), [value]);
  return <TemplateLtrOnly value={formated} {...rest} />;
};
