import React, { FC, memo, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ClickAwayListener } from '@material-ui/core';
import { validateDateLike } from 'AurionCR/components/formV2';
import { IDataGridColumnEditorCustom } from 'components/data-grid/model';
import Footer from 'components/data-grid/components/column/editors/footer';
import { useFieldProps } from 'hooks';
import { GridOnTasks } from 'models/grid';
import { AppDatePicker } from 'components/app-date-picker';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GridModel } from '../../models';
import { apiUserPatientProfileSubscriptionActivities } from 'services/user-patient-profile-subscription-activities';
import { isMutationFulfilled } from 'utils/rtk-query';

const useCreateLog =
  apiUserPatientProfileSubscriptionActivities.useCreateUserPatientProfileSubscriptionActivityLogChangeStartDateMutation;

const schema = yup.object({
  data: yup
    .string()
    .required('rule-required')
    .test((value, context) => {
      const result = validateDateLike(value);

      if (!result) {
        return true;
      }

      return context.createError({ message: result });
    })
    .default(''),
});

interface Props {
  row: GridModel;
  editor: IDataGridColumnEditorCustom;
  data: string;
  onClose: any;
  onTasks: GridOnTasks;
}
export const EditorPatientSubscriptionStartDate: FC<Props> = memo(({ row, onClose, onTasks }) => {
  const { handleSubmit, errors, control, getValues } = useForm({
    defaultValues: schema.cast({ data: row.startDate }),
    resolver: yupResolver(schema),
  });

  const getFieldProps = useFieldProps({ errors });

  const [createItem, resultCreateItem] = useCreateLog();

  const onSubmit = useCallback(async () => {
    const startDate = getValues('data');
    const res = await createItem({
      userPatientProfileSubscriptionID: String(row.id),
      startDate,
    });

    if (isMutationFulfilled(res)) {
      onTasks([['pathData', { row: { ...row, id: String(row.id) }, value: { startDate } }]]);
      onClose();
    }
  }, [createItem, getValues, onTasks, onClose, row]);

  const isLoading = resultCreateItem.isLoading;

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className="grid-editor date">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="data"
            render={(renderProps) => (
              <div className="date-field-wrapper">
                <AppDatePicker {...getFieldProps(renderProps)} label={null} disabled={isLoading} />
              </div>
            )}
          />
          <Footer onClose={onClose} disabled={isLoading} />
        </form>
      </div>
    </ClickAwayListener>
  );
});
