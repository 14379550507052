import clsx from 'clsx';
import { DataGridLightRenderHeaderProps } from '../../models';
import React, { useMemo } from 'react';
import { fieldToLabelKey } from 'utils/other';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';

interface Props<T = any, K extends keyof T = any> extends DataGridLightRenderHeaderProps<T, K> {
  className?: string;
}

const Header = <T, K extends keyof T>(props: Props<T, K>) => {
  const { field } = props;
  const { t } = useTranslate();
  const value = useMemo(() => {
    return fieldToLabelKey(String(field));
  }, [field]);
  return <div className={clsx(style.wrap)}>{t(value)}</div>;
};

export const renderHeader = <T, K extends keyof T>(props: DataGridLightRenderHeaderProps<T, K>) => {
  //@ts-ignore
  return <Header {...props} />;
};
