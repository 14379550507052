import React, { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { debounce } from 'lodash-es';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { CircularProgress, FormControlLabel, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { useDrag, useDrop } from 'react-dnd';
import { Checkbox, FormController, Input, Select } from 'AurionCR/components/formV2';
import { Loading, useI18n } from 'AurionCR/components';
import { onChangeDebounceHelper } from 'components/helpers';
import { ExtendedNotebookFieldInputs, NOTEBOOKS_STATE } from '../../store/module';
import { usePermissions } from 'hooks/use-permissions';
import { RowFormLayout } from 'pages/Settings/Notebooks/Notebooks/form/components/row-form-layout';

export type TypeNotebookFieldInput = {
  isMedicalInformation: boolean;
  isRequired: boolean;
  inputTypeID: string;
  labelKey: string;
  defaultValue: string;
};
const disableDraggable = {
  draggable: true,
  onDragStart: (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  },
};
export const NotebookFieldInput = memo(
  ({
    id,
    isRequired,
    isMedicalInformation,
    fieldInputID,
    fieldInput,
    defaultValue,

    isLoading,
    isLoadingPatch,

    index,
  }: ExtendedNotebookFieldInputs & { index: number }) => {
    // states
    const { t } = useI18n();
    const dispatch = useDispatch();
    // states
    const {
      notebooks: { inputTypes },
    }: {
      notebooks: NOTEBOOKS_STATE;
    } = useSelector((state: any) => state);
    const [isDisabledDefaultValue, setIsDisabledDefaultValue] = useState(() => {
      const inputType = inputTypes.find(({ id }) => id === fieldInput?.inputTypeID);
      return !(
        inputType &&
        (inputType?.inputTypeParameter?.isText || inputType?.inputTypeParameter?.isLongText)
      );
    });
    const { control, errors, handleSubmit, setValue } = useForm({
      defaultValues: {
        isMedicalInformation: isMedicalInformation,
        isRequired: isRequired,
        inputTypeID: fieldInput?.inputTypeID,
        labelKey: fieldInput?.labelKey,
        defaultValue: defaultValue || '',
      },
    });
    // handlers
    // -- NotebookFieldInput
    const onSubmitDebounceNotebookFieldInput = useCallback(
      debounce(
        handleSubmit((formData) => {
          dispatch({
            type: 'NOTEBOOKS_PATCH_NOTEBOOK_FIELD_INPUT',
            payload: {
              id,
              isMedicalInformation: formData.isMedicalInformation,
              isRequired: formData.isRequired,
              defaultValue: formData.defaultValue || null,
            },
          });
        }),
        300,
      ),
      [handleSubmit, id],
    );
    // -- FieldInput
    const onSubmitDebounceFieldInput = useCallback(
      debounce(
        handleSubmit((formData) => {
          dispatch({
            type: 'NOTEBOOKS_PATCH_FIELD_INPUT',
            payload: {
              id: fieldInputID,
              notebookFieldInputID: id,
              inputTypeID: formData.inputTypeID,
              labelKey: formData.labelKey,
            },
          });
        }),
        300,
      ),
      [handleSubmit, fieldInputID, id],
    );
    const onChangeInputType = useCallback(
      (e, value) => {
        const isDisabled = !(
          value &&
          (value.inputTypeParameter?.isText || value.inputTypeParameter?.isLongText)
        );
        setIsDisabledDefaultValue(isDisabled);
        if (isDisabled) {
          setValue('defaultValue', '');
          onSubmitDebounceNotebookFieldInput();
        }
        onSubmitDebounceFieldInput();
        return e.target.value;
      },
      [
        setValue,
        setIsDisabledDefaultValue,
        onSubmitDebounceFieldInput,
        onSubmitDebounceNotebookFieldInput,
      ],
    );

    // double check to data valid (store <=> form state)
    // useEffect(() => {
    //   if (!isLoadingPatch) {
    //     setValue('isRequired', isRequired);
    //     setValue('inputTypeID', fieldInput?.inputTypeID);
    //     setValue('labelKey', fieldInput?.labelKey);
    //     setValue('defaultValue', defaultValue || '');
    //   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isLoadingPatch]);
    // DRAG and drop
    const [{ isDragging }, drag] = useDrag({
      item: { type: 'notebook-field-inputs', id, originalIndex: index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      begin: () => {
        dispatch({
          type: 'NOTEBOOKS_MERGE',
          payload: { isDrag: true },
        });
      },
      end: (dropResult, monitor) => {
        const { id: droppedID, originalIndex } = monitor.getItem();
        const didDrop = monitor.didDrop();
        dispatch({
          type: 'NOTEBOOKS_MERGE',
          payload: { isDrag: false },
        });
        if (!didDrop) {
          dispatch({
            type: 'NOTEBOOKS_DND_NOTEBOOK_FIELD_INPUT',
            payload: { droppedID, originalIndex },
          });
        } else {
          dispatch({ type: 'NOTEBOOKS_NORMALIZE' });
        }
      },
    });
    const [, drop] = useDrop({
      accept: 'notebook-field-inputs',
      canDrop: () => false,
      hover({ id: droppedID }: any) {
        if (droppedID !== id) {
          dispatch({
            type: 'NOTEBOOKS_DND_NOTEBOOK_FIELD_INPUT',
            payload: { id, droppedID },
          });
        }
      },
    });

    const isAllowToDelete = usePermissions('isAllowToDeleteAnything');
    // render
    return (
      <div
        ref={(node) => drag(drop(node))}
        className={clsx('notebook-field-inputs__input', isDragging && 'is-dragging')}
      >
        <div className="drag-indicator">
          <DragIndicatorIcon />
        </div>
        {/*form*/}
        <RowFormLayout {...disableDraggable}>
          <FormController<TypeNotebookFieldInput>
            name="labelKey"
            placeholder={t('parameter')}
            as={Input}
            rules="required,minLength"
            errors={errors}
            control={control}
            disabled={isLoading}
            onChange={onChangeDebounceHelper(onSubmitDebounceFieldInput)}
          />
          <FormController<TypeNotebookFieldInput>
            name={'inputTypeID'}
            placeholder={t('field-type')}
            as={Select}
            rules="required"
            errors={errors}
            control={control}
            disabled={isLoading}
            onChange={onChangeInputType}
            source={inputTypes}
          />
          <FormController<TypeNotebookFieldInput>
            name={'defaultValue'}
            placeholder={t('default-value')}
            as={Input}
            errors={errors}
            control={control}
            disabled={isLoading || isDisabledDefaultValue}
            onChange={onChangeDebounceHelper(onSubmitDebounceNotebookFieldInput)}
          />
          <FormControlLabel
            className="is-required"
            control={
              <FormController<TypeNotebookFieldInput>
                name="isRequired"
                control={control}
                disabled={isLoading}
                as={Checkbox}
                onChange={onChangeDebounceHelper(onSubmitDebounceNotebookFieldInput)}
                color="primary"
              />
            }
            label={t('required')}
          />
          <FormControlLabel
            control={
              <FormController<TypeNotebookFieldInput>
                name="isMedicalInformation"
                control={control}
                disabled={isLoading}
                as={Checkbox}
                onChange={onChangeDebounceHelper(onSubmitDebounceNotebookFieldInput)}
                color="primary"
              />
            }
            label={t('medical-information')}
          />
        </RowFormLayout>
        {/*controls*/}
        <IconButton
          color="primary"
          className="btn-delete"
          disabled={isLoading || !isAllowToDelete}
          {...disableDraggable}
          onClick={() => {
            dispatch({ type: 'NOTEBOOKS_MERGE', payload: { isDeleteNotebookFieldInputID: id } });
            dispatch({ type: 'NOTEBOOKS_DELETE_NOTEBOOK_FIELD_INPUT' });
          }}
        >
          <DeleteIcon fontSize={'inherit'} style={{ fontSize: '2rem' }} />
        </IconButton>
        {/*loaders*/}
        <CircularProgress
          size={20}
          className={clsx('patch-progress', isLoadingPatch && 'is-show')}
          {...disableDraggable}
        />
        <Loading active={isLoading} size={30} />
      </div>
    );
  },
);
