import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'FormDocumentCategories';

export const API_FORM_DOCUMENT_CATEGORIES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<iFormDocumentCategory, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<iFormDocumentCategory, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface iFormDocumentCategory
  extends Omit<
    FixServerObject<Components.Schemas.FormDocumentCategory, 'id'>,
    'formDocumentCategoryType'
  > {
  formDocumentCategoryType: FormDocumentCategoryType;
}

export enum FormDocumentCategoryType {
  GENERAL = 1,
  DRUG,
  CONTRACT,
}
