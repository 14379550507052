const API_ENDPOINT = 'Icd10s';

export const API_ICD10S = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<Icd10s, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<Icd10s, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface Icd10s extends Components.Schemas.Icd10 {}
