import React, { memo, useMemo } from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { Button, ButtonProps } from '@material-ui/core';
import { convertToDate, isDateLike } from 'utils/dates';
import { format } from 'date-fns';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

interface Props extends ButtonProps {
  visitDate: string | null;
  stringFormat?: string;
}

export const TemplateVisit = memo<Props>(
  ({ visitDate, stringFormat = 'HH:mm', className, ...rest }) => {
    const viewDate = useMemo(() => {
      return isDateLike(visitDate) ? format(convertToDate(visitDate), stringFormat) : null;
    }, [visitDate, stringFormat]);

    return (
      <Button className={clsx(style.root, className)} {...rest}>
        <div className={style.inner}>
          {viewDate ? (
            <CheckCircleIcon color="inherit" />
          ) : (
            <RadioButtonUncheckedIcon color="inherit" />
          )}
          {viewDate}
        </div>
      </Button>
    );
  },
);
