import React from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import * as yup from 'yup';
import { DialogHeading } from 'components/dialog-title';
import { ButtonCancel, ButtonSave } from 'components/app-buttons';
import { useTranslate } from 'hooks/use-translate';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldProps } from 'hooks';
import { AppInput } from 'components/app-input';
import { Alert } from '@material-ui/lab';

const schema = yup.object({
  remarks: yup
    .string()
    .required('rule-required')
    .min(2, 'rule-min-length')
    .max(2000, 'rule-max-length')
    .trim()
    .default(''),
});

const defaultValues = schema.cast({});

interface Props {
  title: string;
  description: string;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (formData: typeof defaultValues) => void;
}
export const DialogRemark: React.FC<Props> = ({
  isLoading,
  title,
  description,
  onClose,
  onSubmit,
}) => {
  const { tp } = useTranslate();
  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { errors, control, handleSubmit } = methods;
  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });
  return (
    <Dialog open={true} fullWidth maxWidth={'sm'}>
      <DialogHeading title={title} isLoading={isLoading} onClose={onClose} />
      <DialogContent>
        <Alert severity="info">{description}</Alert>
        <Controller
          control={control}
          name={'remarks'}
          render={(renderProps) => {
            return (
              <AppInput
                {...getFieldProps(renderProps)}
                multiline
                minRows={5}
                disabled={isLoading}
              />
            );
          }}
        />
      </DialogContent>
      <DialogActions>
        <ButtonCancel isBack={false} onClick={onClose} />
        <ButtonSave disabled={isLoading} isCreate={false} onClick={handleSubmit(onSubmit)}>
          {tp('submit')}
        </ButtonSave>
      </DialogActions>
    </Dialog>
  );
};
