const API_ENDPOINT = 'RemarkForPatientCallStatuses';

export const API_REMARK_FOR_PATIENT_CALL_STATUSES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<RemarkForPatientCallStatus, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<RemarkForPatientCallStatus, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface RemarkForPatientCallStatus extends Components.Schemas.RemarkForPatientCallStatus {}

export enum REMARK_REASON {
  RESCHEDULED,
  REASSIGNED,
  AUTO,
}
