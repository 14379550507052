import SelectFieldDetails from 'components/app-grid/select-field-details';
import { DefaultGridButtonExportToExcel } from 'components/data-grid/model';
import TemplateDownloadPrivate from 'components/templates/template-download-private';
import { TemplateEmployee } from 'components/templates/template-employee';
import React from 'react';
import { permissionKey } from 'services/user-employee-profile-permissions';
import { API_USER_EMPLOYEE_PROFILES } from 'services/user-employee-profiles';
import {
  API_USER_PATIENT_PROFILE_UPLOADED_FILES,
  UploadedFiles,
  UploadedFilesGrid,
} from 'services/user-patient-profile-uploaded-files';
import { excelTemplateDate } from 'utils/dates';
import {
  createFilterContains,
  createFilterEquals,
  createFilterSmartSearch,
  mergeFilters,
} from 'utils/dynamic-helpers';
import { TemplateDate } from 'components/templates/template-date';

interface Options {
  userPatientProfileID: string;
}
export default (options: Options): any => ({
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_UPLOADED_FILES.GET_ALL_DYNAMIC,
      filters: createFilterEquals('userPatientProfileID', options.userPatientProfileID),
    },
    create: {
      url: API_USER_PATIENT_PROFILE_UPLOADED_FILES.POST,
    },
    patch: {
      url: API_USER_PATIENT_PROFILE_UPLOADED_FILES.PATCH,
    },
    delete: {
      url: API_USER_PATIENT_PROFILE_UPLOADED_FILES.DELETE,
    },
    sortable: {
      field: 'createdDate',
      order: 'desc',
    },
  },
  controlsDropDown: [
    { type: 'delete', title: 'delete', permission: permissionKey('isAllowToDeleteAnything') },
  ],
  toolbar: {
    title: '',
    excelTitle: 'files',
    filters: [
      {
        field: 'text',
        options: {
          type: 'string',
          label: 'text',
          valueTemplate: ({ value }: any) =>
            mergeFilters(
              createFilterContains<UploadedFilesGrid>(['title'], value),
              createFilterSmartSearch<UploadedFilesGrid>(
                ['userEmployeeProfile.firstName', 'userEmployeeProfile.lastName'],
                value,
              ),
            ).join('||'),
        },
      },
      {
        field: 'userEmployeeProfileID',
        options: {
          style: { minWidth: '30rem' },
          type: 'component',
          template: SelectFieldDetails,
          label: 'employee',
          source: `${API_USER_EMPLOYEE_PROFILES.GET_ALL_DYNAMIC}?Select=appIdentityUserID as id,fullName as title,isActive&orderBy=isActive desc,firstName,lastName`,
          option: {
            label: 'title',
            value: 'id',
          },
        },
      },
    ],
    controls: null,
    controlsDefault: {
      resetFilters: null,
      export: DefaultGridButtonExportToExcel,
    },
  },
  columns: [
    {
      title: 'date',
      field: 'createdDate',
      template: (data: UploadedFilesGrid) => <TemplateDate date={data.createdDate} />,
      tdClasses: 'mobile-no-wrap',
      valueTemplate: (data: UploadedFilesGrid) => excelTemplateDate(data.createdDate),
    },
    {
      title: 'employee',
      field: 'userEmployeeProfile.fullName as userEmployeeProfileID',
      template: (data: UploadedFiles) => (
        <TemplateEmployee employee={data.userEmployeeProfileID || ''} />
      ),
    },
    {
      title: 'title',
      field: 'title',
    },
    {
      title: 'download-doc',
      field: 'fileURL',
      sortable: false,
      template: (data: UploadedFiles) => <TemplateDownloadPrivate documentURL={data.fileURL} />,
    },
    {
      title: 'image',
      field: 'isImage',
      template: 'boolean',
    },
    {
      title: 'uploaded-by-patient',
      field: 'uploadedByPatient',
      template: 'boolean',
    },
    {
      title: 'active',
      field: 'isActive',
      template: 'boolean',
    },
  ],
});
