import { createFilterEquals, mergeFilters } from 'utils/dynamic-helpers';
import {
  API_SUPPORT_MEETINGS,
  RENEWAL_SUPPORT_MEETING_TYPE_ID,
  SupportMeetingGetActivitiesMeetingInput,
  SupportMeetingGetActivitiesMeetingItem,
  SupportMeetingRenewal,
  SupportMeeting,
  SupportMeetingInput,
  PatientFutureSupportMeeting,
  NextFutureMeetingFromExistingInput,
  NextFutureMeetingFromExistingOutput,
  PostOrPatchSupportMeetingInput,
  SupportMeetingExcel,
  GetSupportMeetingOutput,
  CancelSupportMeetingInput,
  SupportMeetingFuture,
} from './models';
import { DynamicService } from 'utils/service';
import { DateValue, convertToDate } from 'utils/dates';
import { addMinutes, differenceInMinutes, format } from 'date-fns';
import { makeFilterDateRange, setDateToNearestTimeSlot } from 'utils/app-helpers';
import { apiRtk, RTK_TAGS } from 'utils/rtk-query';
import { apiSupportMeetingTypes } from '../support-meeting-types';
import { PatchPartial, Unset } from 'utils/types';
import { apiSupportMeetingActivities } from '../support-meeting-activities';
import * as dynamic from 'utils/dynamic-helpers';
import { makeChangeLog, schemaChangeLogs } from 'modules/change-log';
import { logConfigPatch, logConfigUpdate } from './log';
import { i18nAppTranslator } from 'modules/i18n';

export * from './models';

class Service extends DynamicService<SupportMeeting> {
  createRenewal = async (data: SupportMeetingRenewal) => {
    const normalizedData = Service.normalizeRenewal(data);
    return this.post(normalizedData);
  };
  validateRenewal = async (
    data: Pick<SupportMeetingRenewal, 'userEmployeeProfileID' | 'userPatientProfileID'>,
  ) => {
    const result = await this.getAllDynamic({
      select: 'id',
      take: 1,
      filter: [
        createFilterEquals('supportMeetingTypeID', RENEWAL_SUPPORT_MEETING_TYPE_ID),
        createFilterEquals('userEmployeeProfileID', data.userEmployeeProfileID),
        createFilterEquals('userPatientProfileID', data.userPatientProfileID),
      ]
        .filter(Boolean)
        .join('&&'),
    });

    const isValid = result.data.value.length === 0;

    return { ...result, data: isValid };
  };

  static normalizeDateRegular(date: DateValue, options: { timeSlot: string }) {
    const { timeSlot } = options;
    return new Date(`${format(convertToDate(date), 'yyyy-MM-dd')}T${timeSlot}`).toISOString();
  }
  static normalizeDateRenewal(date: DateValue) {
    return format(convertToDate(date), 'yyyy-MM-dd');
  }

  static normalizeRenewal = (data: SupportMeetingRenewal) => {
    const { entryDate, ...rest } = data;
    const date = Service.normalizeDateRenewal(entryDate);
    return { ...rest, meetingFromDateTime: date, meetingToDateTime: date };
  };

  prepareMeetingData = (data: SupportMeetingInput) => {
    let { date, start, end, _IS_RENEWAL, ...rest } = data;

    const dateNormalizer = _IS_RENEWAL
      ? Service.normalizeDateRenewal
      : Service.normalizeDateRegular;

    return {
      ...rest,
      meetingFromDateTime: dateNormalizer(date, { timeSlot: start }),
      meetingToDateTime: dateNormalizer(date, { timeSlot: end }),
    };
  };

  createMeeting = async (data: SupportMeetingInput) => {
    return this.post(this.prepareMeetingData(data));
  };
  updateMeeting = async (data: SupportMeetingInput) => {
    return this.patch(this.prepareMeetingData(data));
  };

  getExcelData = async (input: { filter: string | undefined; orderBy: string | undefined }) => {
    return this.getAllDynamic<SupportMeetingExcel>({
      filter: input.filter,
      orderBy: input.orderBy,
      select: [
        'id',
        'new {supportMeetingType.title} as supportMeetingType',
        'meetingFromDateTime',
        'meetingToDateTime',
        'new {userPatientProfile.fullName} as userPatientProfile',
        'new {userEmployeeProfile.fullName} as userEmployeeProfile',

        'supportMeetingActivities.Count() as activities',

        'remarks',

        'supportMeetingActivities.OrderByDescending(a=>a.entryDate).Select(a=>a.remarkForPatientCallStatus.title).FirstOrDefault() as lastActivityType',
        'userPatientProfile.userPatientProfileSubscriptions.OrderByDescending(z => z.endDate).Select(k => new {k.endDate}).FirstOrDefault() as subscription',
      ].join(','),
    });
  };

  getActivities = async (input: SupportMeetingGetActivitiesMeetingInput) => {
    const { userPatientProfileID, dateRange } = input;

    const {
      data: { value },
    } = await this.getAllDynamic<SupportMeetingGetActivitiesMeetingItem>({
      filter: [
        `userPatientProfileID=="${userPatientProfileID}"`,
        makeFilterDateRange('meetingFromDateTime', dateRange),
      ]
        .filter(Boolean)
        .join('&&'),
      select: [
        'id',
        'meetingFromDateTime',
        'meetingToDateTime',
        'supportMeetingType.title as supportMeetingTypeTitle',
        'userEmployeeProfile.fullName as employee',
        'userPatientProfileSessionID',
        'includeMeetingTime',
        'supportMeetingActivities.Count() as activities',
      ].join(','),
      orderBy: 'meetingFromDateTime desc',
    });

    return value.map((item) => ({
      id: String(item.id),
      title: item.supportMeetingTypeTitle,
      date: String(item.meetingFromDateTime),
      employee: item.employee,
      download: null,

      meetingFromDateTime: item.meetingToDateTime,
      meetingToDateTime: item.meetingToDateTime,
      userPatientProfileSessionID: item.userPatientProfileSessionID,

      activities: item.activities,
    }));
  };

  setNextFutureMeetingFromExisting = async (data: NextFutureMeetingFromExistingInput) => {
    const minutes = differenceInMinutes(
      convertToDate(data.meetingToDateTime),
      convertToDate(data.meetingFromDateTime),
    );

    const meetingFromDateTime = setDateToNearestTimeSlot(new Date());

    const meetingToDateTime = addMinutes(meetingFromDateTime, minutes);

    const payload = {
      meetingFromDateTime: meetingFromDateTime.toISOString(),
      meetingToDateTime: meetingToDateTime.toISOString(),
      id: data.id,
      userEmployeeProfileID: data.userEmployeeProfileID,
      userPatientProfileSessionID: data.userPatientProfileSessionID,
    };

    await this.patch(payload);

    return { data: payload };
  };
}

export const ServiceSupportMeetings = new Service({
  mainField: 'id',
  getAll: API_SUPPORT_MEETINGS.GET_ALL_DYNAMIC,
  post: API_SUPPORT_MEETINGS.POST,
  patch: API_SUPPORT_MEETINGS.PATCH,
  delete: API_SUPPORT_MEETINGS.DELETE,
});

export const apiSupportMeeting = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSupportMeeting: build.query<GetSupportMeetingOutput, string>({
      queryFn: async (id: string) => {
        try {
          const result = await ServiceSupportMeetings.getDynamic<GetSupportMeetingOutput>(id, {
            select: [
              'id',
              'supportMeetingTypeID',
              'userPatientProfileID',
              'userEmployeeProfileID',
              'userPatientProfileSessionID',
              'meetingFromDateTime',
              'meetingToDateTime',
              'includeMeetingTime',
              'remarks',
              'userPatientProfileSession.notebook.labelKey as labelKey',
            ].join(','),
          });

          return { data: result.data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getPatientFutureSupportMeetings: build.query({
      queryFn: async (userPatientProfileID: string) => {
        try {
          const {
            data: { value },
          } = await ServiceSupportMeetings.getAllDynamic<PatientFutureSupportMeeting>({
            filter: mergeFilters(
              createFilterEquals('userPatientProfileID', userPatientProfileID),
              `userPatientProfileSessionID==null`,
              'supportMeetingType.isCanceledMeeting==false',
              `meetingFromDateTime>DateTime("${new Date().toISOString()}")`,
            ).join('&&'),
            select: [
              'id',
              'meetingFromDateTime',
              'meetingToDateTime',
              'supportMeetingTypeID',
              'userEmployeeProfileID',
              'includeMeetingTime',
              'userEmployeeProfile.fullName as employee',
              'remarks',
            ].join(','),
            orderBy: 'meetingFromDateTime asc',
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (res, err, userPatientProfileID) =>
        res
          ? [
              {
                type: RTK_TAGS.SUPPORT_MEETINGS,
                id: `userPatientProfileID__${userPatientProfileID}`,
              },
              ...res.map((meeting) => ({ type: RTK_TAGS.SUPPORT_MEETINGS, id: meeting.id })),
            ]
          : [],
    }),
    getSupportMeetingsFutureUnhandled: build.query<
      Array<SupportMeetingFuture>,
      { userPatientProfileID: string }
    >({
      queryFn: async (input) => {
        try {
          const start = new Date();

          const result = await ServiceSupportMeetings.getAllDynamic<SupportMeetingFuture>({
            filter: dynamic
              .mergeFilters(
                dynamic.createFilterEquals('userPatientProfileID', input.userPatientProfileID),
                'supportMeetingType.isCanceledMeeting==false',
                `userPatientProfileSessionID==null`,
                dynamic.createFilterDateMoreOrEqualsISO('meetingFromDateTime', start),
              )
              .join('&&'),
            select: dynamic.select(
              'id',
              'meetingFromDateTime',
              'meetingToDateTime',
              'new { supportMeetingType.title, supportMeetingType.icon, supportMeetingType.color } as type',
              'new { userEmployeeProfile.fullName } as employee',
            ),
          });

          return { data: result.data.value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (res, error, arg) => [
        { type: RTK_TAGS.CLINICAL_MEETINGS, id: `patient__${arg.userPatientProfileID}` },
      ],
    }),

    setNextPatientFutureSupportMeetingFromExisting: build.mutation<
      NextFutureMeetingFromExistingOutput,
      NextFutureMeetingFromExistingInput
    >({
      queryFn: async (input) => {
        try {
          const { data } = await ServiceSupportMeetings.setNextFutureMeetingFromExisting(input);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    patchSupportMeeting: build.mutation<
      PatchPartial<SupportMeeting, 'id' | 'userPatientProfileID'>,
      PatchPartial<SupportMeeting, 'id' | 'userPatientProfileID'>
    >({
      queryFn: async (input) => {
        try {
          await ServiceSupportMeetings.patch(input);
          return { data: { ...input, id: String(input.id) } };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res) =>
        res
          ? [
              {
                type: RTK_TAGS.SUPPORT_MEETINGS,
                id: `userPatientProfileID__${res.userPatientProfileID}`,
              },
            ]
          : [],
    }),
    patchSupportMeetingWithLog: build.mutation<
      PatchPartial<SupportMeeting, 'id' | 'userPatientProfileID'>,
      {
        initData: PatchPartial<SupportMeeting, 'id' | 'userPatientProfileID'>;
        formData: PatchPartial<SupportMeeting, 'id' | 'userPatientProfileID'>;
        remark: Unset;
        remarkForPatientCallStatusID: Unset;
      }
    >({
      queryFn: async (input, { dispatch }) => {
        try {
          const fields = await makeChangeLog(logConfigPatch, {
            initData: input.initData,
            formData: input.formData,
            update: (formData) => ServiceSupportMeetings.patch({ ...formData } as SupportMeeting),
            getDefinition: (initData, params) =>
              ServiceSupportMeetings.getDynamic<SupportMeeting>(String(input.initData.id), {
                select: params.select,
              }).then((r) => r.data),
          });

          const changes = schemaChangeLogs.cast({ fields }, { stripUnknown: true, assert: false });

          dispatch(
            apiSupportMeetingActivities.endpoints.postSupportMeetingActivity.initiate({
              supportMeetingID: String(input.formData.id),
              changes: JSON.stringify(changes),
              remarks: input.remark || i18nAppTranslator.tp('updates-by-employee'),
              remarkForPatientCallStatusID: input.remarkForPatientCallStatusID,
            }),
          );

          return { data: input.formData };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res) =>
        res
          ? [
              {
                type: RTK_TAGS.SUPPORT_MEETINGS,
                id: `userPatientProfileID__${res.userPatientProfileID}`,
              },
            ]
          : [],
    }),
    postSupportMeetingRenewal: build.mutation<SupportMeeting, SupportMeetingRenewal>({
      queryFn: async (input) => {
        try {
          const { data: isValid } = await ServiceSupportMeetings.validateRenewal(input);

          if (!isValid) {
            throw new Error('prescription-renewal-exist');
          }

          const result = await ServiceSupportMeetings.createRenewal(input);

          return { data: result.data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    postOrPatchSupportMeeting: build.mutation<SupportMeeting, PostOrPatchSupportMeetingInput>({
      queryFn: async (input) => {
        try {
          if (input.id) {
            await ServiceSupportMeetings.patch(input);
            return { data: { ...input, id: String(input.id) } };
          } else {
            const { data } = await ServiceSupportMeetings.post(input);
            return { data };
          }
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res) =>
        res
          ? [
              {
                type: RTK_TAGS.SUPPORT_MEETINGS,
                id: `userPatientProfileID__${res.userPatientProfileID}`,
              },
            ]
          : [],
    }),

    createSupportMeeting: build.mutation<SupportMeeting, SupportMeetingInput>({
      queryFn: async (input) => {
        try {
          const res = await ServiceSupportMeetings.createMeeting(input);
          return { data: res.data };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res, __, input) => [
        {
          type: RTK_TAGS.SUPPORT_MEETINGS,
          id: `userPatientProfileID__${input.userPatientProfileID}`,
        },
        ...(res
          ? [
              {
                type: RTK_TAGS.SUPPORT_MEETINGS,
                id: res.id,
              },
            ]
          : []),
      ],
    }),
    deleteSupportMeeting: build.mutation<SupportMeeting, string>({
      queryFn: async (supportMeetingID) => {
        try {
          const res = await ServiceSupportMeetings.delete({ id: supportMeetingID });
          return { data: res.data };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res, __, arg) => [
        {
          type: RTK_TAGS.SUPPORT_MEETINGS,
          id: arg,
        },
        ...(res
          ? [
              {
                type: RTK_TAGS.SUPPORT_MEETINGS,
                id: `userPatientProfileID__${res.userPatientProfileID}`,
              },
            ]
          : []),
      ],
    }),

    updateSupportMeetingWithLog: build.mutation<
      void,
      {
        initData: PatchPartial<SupportMeetingInput, 'id'>;
        formData: PatchPartial<SupportMeetingInput, 'id'>;
        remark: Unset;
        remarkForPatientCallStatusID: Unset;
      }
    >({
      queryFn: async (input, { dispatch }) => {
        try {
          const fields = await makeChangeLog(logConfigUpdate, {
            initData: input.initData,
            formData: input.formData,
            update: (formData) =>
              ServiceSupportMeetings.updateMeeting({ ...formData } as SupportMeetingInput),
            getDefinition: (initData, params) =>
              ServiceSupportMeetings.getDynamic(String(input.initData.id), {
                select: params.select,
              }).then((r) => r.data),
          });

          const changes = schemaChangeLogs.cast({ fields }, { stripUnknown: true, assert: false });

          dispatch(
            apiSupportMeetingActivities.endpoints.postSupportMeetingActivity.initiate({
              supportMeetingID: String(input.formData.id),
              changes: JSON.stringify(changes),
              remarks: input.remark || i18nAppTranslator.tp('updates-by-employee'),
              remarkForPatientCallStatusID: input.remarkForPatientCallStatusID,
            }),
          );

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (_, __, arg) => [
        {
          type: RTK_TAGS.SUPPORT_MEETINGS,
          id: `userPatientProfileID__${arg.formData.userPatientProfileID}`,
        },
        {
          type: RTK_TAGS.SUPPORT_MEETINGS,
          id: arg.formData.id,
        },
      ],
    }),

    cancelSupportMeeting: build.mutation<{ id: string }, CancelSupportMeetingInput>({
      queryFn: async (input, { dispatch }) => {
        try {
          const { id, statusID, remarks } = input;
          const result = dispatch(
            apiSupportMeetingTypes.endpoints.getSupportMeetingSubjectCancelID.initiate(undefined),
          );
          result.unsubscribe();

          const supportMeetingTypeID = await result.unwrap();

          await dispatch(
            apiSupportMeeting.endpoints.patchSupportMeetingWithLog.initiate({
              initData: {
                id,
                supportMeetingTypeID: input.supportMeetingTypeID,
                userPatientProfileID: input.userPatientProfileID,
              },
              formData: {
                id,
                supportMeetingTypeID,
                userPatientProfileID: input.userPatientProfileID,
              },
              remark: remarks,
              remarkForPatientCallStatusID: statusID,
            }),
          ).unwrap();

          return { data: { id } };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res, err, input) => [
        {
          type: RTK_TAGS.SUPPORT_MEETINGS,
          id: input.id,
        },
      ],
    }),
    cancelSupportMeetingAuto: build.mutation({
      queryFn: async (id: string, { dispatch }) => {
        try {
          const { data: initData } = await ServiceSupportMeetings.getDynamic(id, {
            select: dynamic.select('id', 'supportMeetingTypeID', 'userPatientProfileID'),
          });
          const result = dispatch(
            apiSupportMeetingTypes.endpoints.getSupportMeetingSubjectCancelID.initiate(undefined),
          );
          result.unsubscribe();
          const supportMeetingTypeID = await result.unwrap();

          await dispatch(
            apiSupportMeeting.endpoints.patchSupportMeetingWithLog.initiate({
              initData: {
                id,
                supportMeetingTypeID: initData.supportMeetingTypeID,
                userPatientProfileID: initData.userPatientProfileID,
              },
              formData: {
                id,
                supportMeetingTypeID: supportMeetingTypeID,
                userPatientProfileID: initData.userPatientProfileID,
              },
              remark: i18nAppTranslator.tp('activity-support-meeting-cancel'),
              remarkForPatientCallStatusID: undefined,
            }),
          ).unwrap();

          return { data: { id } };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res, err, id) => [
        {
          type: RTK_TAGS.SUPPORT_MEETINGS,
          id,
        },
      ],
    }),
  }),
});
