import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo } from 'react';
import { FormController, Select } from 'AurionCR/components/formV2';
import { fieldValueDefault, iField, iFieldRefProps } from './@type';
import { useTranslate } from 'hooks/use-translate';
import { useFormContext } from 'react-hook-form';

export const FieldDays = memo(
  forwardRef<iFieldRefProps, iField>(({ item, disabled = false, onWasChange }, ref) => {
    const { t } = useTranslate();
    const name = item.fieldInputID as string;
    const labelKey = item.fieldInput?.labelKey;
    const { control, errors, getValues } = useFormContext();
    // states
    const daysSource = useMemo(
      () =>
        ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map(
          (val) => ({ id: t(val), title: t(val) }),
        ),
      [t],
    );

    const getValue = useCallback(() => {
      const value = getValues(name);
      return {
        ...fieldValueDefault(),
        id: item.formID || '',
        fieldInputID: item.fieldInputID as string,
        entryValueString: value.length ? value.join(',') : null,

        formValue: value,
        notebookFieldInputID: item.id,
      };
    }, [getValues, name, item]);
    // public
    useImperativeHandle(ref, () => ({ getValue }), [getValue]);

    return (
      <div>
        <FormController
          name={name}
          label={labelKey ? t(labelKey) : undefined}
          as={Select}
          rules={item?.isRequired ? 'required' : undefined}
          errors={errors}
          control={control}
          disabled={disabled}
          source={daysSource}
          onBlur={onWasChange}
          multiple
        />
      </div>
    );
  }),
);
