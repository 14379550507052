import React from 'react';
import { API_CLINICAL_MEETINGS, ClinicalMeeting } from 'services/clinical-meetings';
import { TemplateMeetingDate } from 'components/templates/template-meeting-date';
import { TemplateEmployeeSource } from 'components/templates/template-employee-source';
import { APP_FORMAT_DATE } from 'configs/const';
import { createFilterDateISO, mergeFilters } from 'utils/dynamic-helpers';
import { TemplateClinicalMeetingSubject } from 'components/templates/template-clinical-meeting-subject';
import { TemplateClinicalMeetingType } from 'components/templates/template-clinical-meeting-type';
import { endOfDay, startOfDay } from 'date-fns';
interface GridData
  extends Pick<
    ClinicalMeeting,
    | 'clinicalMeetingSubjectID'
    | 'clinicalMeetingTypeID'
    | 'meetingFromDateTime'
    | 'meetingToDateTime'
    | 'userEmployeeProfileID'
    | 'organizerUserEmployeeProfileID'
    | 'id'
  > {}

interface Options {
  date: Date;
}
export const GridConfig = ({ date }: Options) => ({
  store: {
    get: {
      url: API_CLINICAL_MEETINGS.GET_ALL_DYNAMIC,
      selected: ['meetingToDateTime'].join(','),
      filters: mergeFilters(
        'clinicalMeetingSubject.isCanceledMeeting==false',
        createFilterDateISO('meetingFromDateTime', [startOfDay(date), endOfDay(date)]),
      ).join('&&'),
    },
    sortable: {
      field: 'meetingFromDateTime',
      order: 'asc',
    },
  },
  controlsDropDown: null,
  toolbar: {
    title: '',
    controls: null,
    controlsDefault: null,
  },
  columns: [
    {
      title: '',
      field: 'clinicalMeetingSubjectID',
      sortable: 'clinicalMeetingSubject.title',
      template: (data: GridData) => (
        <TemplateClinicalMeetingSubject meetingSubjectsID={data.clinicalMeetingSubjectID} />
      ),
      tdClasses: 'data-grid-td-width-40',
    },
    {
      title: '',
      field: 'clinicalMeetingTypeID',
      sortable: 'clinicalMeetingType.title',
      template: (data: GridData) => (
        <TemplateClinicalMeetingType meetingTypeID={data.clinicalMeetingTypeID} />
      ),
      tdClasses: 'data-grid-td-width-50',
    },
    {
      title: 'date-time',
      field: 'meetingFromDateTime',
      template: (data: GridData) => (
        <TemplateMeetingDate meeting={data} dateFormat={APP_FORMAT_DATE} />
      ),
    },
    {
      title: 'organizer-user-employee-profile',
      field: 'organizerUserEmployeeProfileID',
      sortable: 'organizerUserEmployeeProfile.firstName',
      template: (data: GridData) => (
        <TemplateEmployeeSource userEmployeeProfileID={data.organizerUserEmployeeProfileID} />
      ),
    },
    {
      title: 'employee',
      field: 'userEmployeeProfileID',
      sortable: 'userEmployeeProfile.firstName',
      template: (data: GridData) => (
        <TemplateEmployeeSource userEmployeeProfileID={data.userEmployeeProfileID} />
      ),
    },
  ],
});
