import React, { memo, useMemo } from 'react';
import { Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { IUseForm, useFormHook, validateRule } from 'AurionCR/components/formV2';
import { IGridEditFormProps } from 'components/models';
import { useRequestNotify } from 'components/hooks';
import { useTranslate } from 'hooks/use-translate';
import { ToDoTaskActivity, API_TODO_TASK_ACTIVITIES } from 'services/todo-task-activities';
import { SelectEmployee } from 'components/select-employee';
import { useFieldProps } from 'hooks';
import { Controller } from 'react-hook-form';
import { AppInput } from 'components/app-input';
import { ButtonCancel, ButtonSave } from 'components/app-buttons';

type FormModel = ToDoTaskActivity;
const API_URLS = API_TODO_TASK_ACTIVITIES;

interface Props extends IGridEditFormProps {
  toDoTaskID: string;
  appUserID: string;
}
export const Editor = memo<Props>(({ onClose, onFormHandle, itemID, toDoTaskID, appUserID }) => {
  const { t } = useTranslate();
  //state
  const config = useMemo<IUseForm<FormModel>>(() => {
    return {
      editID: itemID,
      fields: {
        id: '',
        toDoTaskID,
        userEmployeeProfileID: appUserID,
        entryDate: new Date() as any,
        remarks: '',
        isActive: true,
      },
      endpoint: API_URLS.API_ENDPOINT,
      get: {
        parser: (data) => {
          const value = data?.value[0] || null;
          if (value && value.entryDate) value.entryDate = new Date(value.entryDate);
          return value;
        },
      },
      formHandle: onFormHandle,
    };
  }, [itemID, onFormHandle, toDoTaskID, appUserID]);

  const {
    formLoading,
    setFormLoading,
    formUse: { control, errors, handleSubmit, register },
    onSubmit,
  } = useFormHook(config);
  // request noty wrapper
  const { requestWrap: wrapSubmit } = useRequestNotify({
    request: onSubmit,
    setLoading: setFormLoading,
  });

  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
      <DialogHeading
        isLoading={formLoading}
        title={t('activity')}
        onClose={onClose}
        disabled={formLoading}
      />
      <DialogContent>
        <input {...register('id')} type="hidden" />
        <input {...register('toDoTaskID')} type="hidden" />
        <input {...register('isActive')} type="hidden" />
        <input {...register('entryDate')} type="hidden" />
        <Grid container spacing={4}>
          <Grid item md={7}>
            <Controller
              control={control}
              name={'userEmployeeProfileID'}
              rules={validateRule('required')}
              render={(renderProps) => (
                <SelectEmployee
                  {...getFieldProps(renderProps)}
                  label={t('employee')}
                  disableClearable
                  disabled={formLoading}
                />
              )}
            />
          </Grid>

          <Grid item md={12}>
            <Controller
              control={control}
              name={'remarks'}
              rules={validateRule('required')}
              render={(renderProps) => (
                <AppInput
                  {...getFieldProps(renderProps)}
                  disabled={formLoading}
                  multiline={true}
                  minRows={5}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonCancel isBack={true} onClick={onClose} />
        <ButtonSave isCreate={true} onClick={handleSubmit(wrapSubmit)} disabled={formLoading} />
      </DialogActions>
    </Dialog>
  );
});
