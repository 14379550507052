import { PanelResizeHandle, PanelResizeHandleProps } from 'react-resizable-panels';
import style from './index.module.scss';
import React from 'react';
import clsx from 'clsx';

interface Props extends PanelResizeHandleProps {
  direction?: 'horizontal' | 'vertical';
}
export const AppPanelResizeHandle: React.FC<Props> = ({ direction = 'vertical', ...rest }) => {
  return (
    <PanelResizeHandle
      {...rest}
      className={clsx(style.resize, rest.className, {
        [style.horizontal]: direction === 'horizontal',
        [style.vertical]: direction === 'vertical',
      })}
    >
      <div className={style.inner}>
        <span className={style.dot} />
        <span className={style.dot} />
        <span className={style.dot} />
      </div>
    </PanelResizeHandle>
  );
};
