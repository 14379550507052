import React from 'react';
import { TemplateIcon } from 'components/templates/template-icon';
import { useSourceSupportMeetingTypes } from 'components/hooks';
import { CircularProgress } from '@material-ui/core';

interface Props {
  meetingTypeID: string;
}

export const ColumnSupportMeetingType: React.FC<Props> = ({ meetingTypeID }) => {
  const { map } = useSourceSupportMeetingTypes();
  const meetingType = map[meetingTypeID];

  if (!meetingType) {
    return <CircularProgress />;
  }

  return (
    <TemplateIcon
      color={meetingType.color}
      iconSrc={meetingType.icon}
      sx={{ bgcolor: 'none', minHeight: '4rem', minWidth: '4rem' }}
      AvatarProps={{
        title: meetingType.title,
        style: { backgroundColor: meetingType.color },
      }}
    />
  );
};
