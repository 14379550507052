import React, {memo, useCallback, useMemo, useRef, useState} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  InputLabel,
  Slider,
  Tooltip,
  Typography
} from "@material-ui/core";
import {checkEs6AndRun, getRandomString, requestError} from "../../helpers";
import {useI18n} from "../../i18";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import AvatarEditor from "react-avatar-editor";
import Controls from "./controls";
import {useDispatch} from "react-redux";
import {notifyRequestResult} from "../../../store/modules/notify";
import ErrorIcon from '@material-ui/icons/Error';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import mime from 'mime-types';
import {fromBlob} from 'file-type/browser'
import {DialogAlert} from "../../dialog-alert";
import {IImageUploaderProps} from "./interfaces";

export const Label = (props: any) => {
  const {children, open, value} = props;

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      title={value}
      placement="top"
    >
      {children}
    </Tooltip>
  );
};
export const AvatarEditorDialog = memo((
  {
    value,
    width,
    height,
    borderRadius,
    onClose,
    onCreate
  }: {
    value: any,
    width: number;
    height: number;
    borderRadius: number;
    onClose: () => void;
    onCreate: (value: any) => void;
  }) => {
  const {t} = useI18n();
  const ref = useRef(null);
  const [props, setProps] = useState({
    width: Math.floor(width * 1.25),
    height: Math.floor(height * 1.25),
    scale: 110,
    rotate: 0,
    borderRadius: (borderRadius || 0) * 1.25,
    border: Math.floor(Math.max(width, height) * .25)
  });
  const onChangeProp = useCallback((value: any, name: string) => {
    setProps(state => ({...state, [name]: value}));
  }, [setProps]);
  return (
    <Dialog
      className="image-dialog"
      open={true}
      onClose={onClose}
    >
      <DialogTitle>
        {t('img-editor')}
      </DialogTitle>
      <DialogContent>
        <AvatarEditor
          ref={ref}
          image={value || ''}
          width={props.width}
          height={props.height}
          border={props.border}
          borderRadius={props.borderRadius}
          scale={props.scale / 100}
          rotate={props.rotate}
        />
        <Typography id="scale-slider" gutterBottom>
          {t('scale')}
        </Typography>
        <Slider
          aria-labelledby="scale-slider"
          aria-label={t('scale')}
          ValueLabelComponent={Label}
          defaultValue={props.scale}
          min={100}
          max={300}
          onChange={(e, value) => onChangeProp(value, 'scale')}
        />
        <Typography id="rotate-slider" gutterBottom>
          {t('rotate')}
        </Typography>
        <Slider
          aria-labelledby="rotate-slider"
          aria-label={t('rotate')}
          ValueLabelComponent={Label}
          defaultValue={props.rotate}
          min={0}
          max={360}
          onChange={(e, value) => onChangeProp(value, 'rotate')}
        />
      </DialogContent>
      <DialogActions>
        <Controls
          state={'create'}
          // @ts-ignore
          onSubmit={() => onCreate(ref.current.getImageScaledToCanvas().toDataURL())}
          onCancel={onClose}
          showCopy={false}
        />
      </DialogActions>
    </Dialog>
  )
});
export const getCroppedImg = (file: any, width: number, height: number, resultType: string) => {
  return new Promise((resolve, reject) => {
    const img_ = new Image();
    img_.src = URL.createObjectURL(file);
    img_.onload = function () {
      const imageWidth = img_.width;
      const imageHeight = img_.height;
      let ratio = 1;
      if (imageWidth > width) ratio = width / imageWidth;
      if (imageHeight > height) ratio = height / imageHeight;

      if (ratio === 1) {
        const reader_ = new FileReader();
        reader_.readAsDataURL(file);
        reader_.onload = () => resolve(reader_.result);
        reader_.onerror = reject;
      } else {
        const canvas = document.createElement('canvas');
        const width_ = imageWidth * ratio;
        const height_ = imageHeight * ratio;
        canvas.width = width_;
        canvas.height = height_;
        // @ts-ignore
        canvas.getContext('2d').drawImage(img_, 0, 0, imageWidth, imageHeight, 0, 0, width_, height_);
        resolve(canvas.toDataURL(`image/${resultType}`))
      }
    };
    img_.onerror = reject;
    return
  })
};

export const ImageUploader = memo<IImageUploaderProps>((
  {
    // form fields
    name,
    value,
    onChange,
    error,
    label,
    disabled = false,
    // api
    apiSet = 'MediaUploads/UploadFileToCloud',
    // eslint-disable-next-line
    apiRemove = 'MediaUploads/RemoveFileFromCloud?isImage=true&filePath=${data}',
    resultType = 'png',
    fileAccept = 'png,jpg',
    // config
    type = 'uploader',
    // !NOTE: if width === 99999 an height === 99999 - allow any size
    width = 260,
    styleWidth = 0,
    height = 260,
    styleHeight = 0,
    borderRadius = 0,
    styleNoImageSize = {},
  }, refImperative) => {
  const dispatch = useDispatch();
  const {t} = useI18n();
  const ref = useRef<any>(null);
  const value_ = useMemo(() => {
    return value ? (typeof value === 'string') ? value : value?.value || '' : '';
  }, [value]);
  const [isEdit, setIsEdit] = useState(false);
  const [file, setFile] = useState(null);
  const [remove, setRemove] = useState('');
  const [previewDialog, setPreviewDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const accept = useMemo<{ input: string, types: null | string[] }>(() => {
    if (fileAccept) {
      const types_ = fileAccept
        .replace(/\s/g, '')
        .split(',')
        .reduce((result: any, item: any) => {
          const type_ = mime.lookup(item);
          if (type_) result.push(type_);
          return result;
        }, []);
      if (types_.length) return {
        input: types_.join(','),
        types: types_
      }
    }
    return {
      input: 'application/octet-stream',
      types: null
    }
  }, [fileAccept]);
  // Callbacks
  // TODO: NEED TO DEBUG REMOVE (example, 1.exitFile, 2.remove, 3.change 4.remove = not apply remove from cloud);
  const onChangeData = useCallback((base64_ = '', remove_ = '', type: string | null = null) => {
    if (onChange) {
      let value: any = '';
      if (base64_ || remove_) {
        value = {
          mixin_: {
            requests: [],
            updateModel: 'filePath',
            name,
          },
          value: base64_
        };
      }
      if (remove_) value.mixin_.requests.push((data: any) => ({
        method: 'put',
        url: checkEs6AndRun(apiRemove, remove_),
        transformResponse: [() => ''],
      }));
      if (base64_) value.mixin_.requests.push((data: any) => ({
        method: 'post',
        url: apiSet,
        data: {
          fileName: `${name}_${getRandomString(20)}.${type || resultType}`,
          isImage: true,
          filePath: 'Images',
          fileStreamString: base64_.split(',')[1]
        }
      }));
      onChange({target: {value}});
    }
  }, [onChange, apiRemove, apiSet, name, resultType]);
  const onEdit = useCallback((base64?: any, type = '') => {
    let remove_ = remove;
    if (value && typeof value === 'string') {
      setRemove(value);
      remove_ = value;
    }
    onChangeData(base64 || '', remove_, type);
    setIsEdit(false);
    // @ts-ignore
    if (ref.current) ref.current.value = ''
  }, [onChangeData, setIsEdit, remove, setRemove, value]);
  const onShowError = useCallback((error, addition = '') => {
    setAlertMessage(t(error) + addition);
    if (ref) ref.current.value = '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAlertMessage, ref]);
  const onLoadFile = useCallback((file_) => {
    setFile(file_);
    if (type === 'avatar') {
      setIsEdit(true);
    } else if (type === 'uploader') {
      getCroppedImg(file_, width, height, resultType)
        .then(response => {
          onEdit(response, mime.extension(file_.type));
        })
        .catch(error => {
          console.error(error);
          dispatch(notifyRequestResult(requestError(error), 'error'));
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFile, setIsEdit, onEdit, width, height, resultType]);
  const onChangeFile = useCallback((e: any) => {
    const file_ = e.target.files[0];
    if (!file_) return
    if (!accept.types) {
      return onLoadFile(file_)
    }
    // 1. fast check to file type
    if (!accept.types.some(type => type === file_.type)) {
      return onShowError('file-incorrect-type');
      // 2. check to file size
      // TODO SVG CHECK ISSUE
    } else if (file_.type !== 'image/svg+xml') {
      // 3. slow test to file type
      fromBlob(file_)
        .then((response: any) => {
          if (accept.types?.some(type => type === response.mime)) {
            onLoadFile(file_);
          } else {
            onShowError('file-incorrect-type');
          }
        })
        .catch(_ => {
          onShowError('file-incorrect-type');
        })
    } else {
      return onLoadFile(file_)
    }
  }, [accept, onShowError, onLoadFile]);
  // UI
  const classes = useMemo(() => {
    return `image-uploader-wrapper${type === 'uploader' ? ' alternative' : ' default'}${label ? ' label' : ''}`;
  }, [type, label]);
  const Preview = useMemo(() => {
    const width_ = (width === 99999) ? styleWidth || 200 : styleWidth || width;
    const height_ = (height === 99999) ? styleHeight || 200 : styleHeight || height;
    const style_ = {minWidth: `${width_}px`, width: `${width_}px`, minHeight: `${height_}px`, height: `${height_}px`};
    if (value_) {
      return <div className="thumb" style={style_}>
        <img src={value_} alt="" style={{maxWidth: `${width_}px`, maxHeight: `${height_}px`}}/>
      </div>;
    } else {
      return <div className="no-image" style={style_}>
        <ImageSearchIcon style={styleNoImageSize}/>
      </div>
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value_, width, height]);
  const Note = useMemo(() => {
    if (type === 'uploader' && width !== 99999 && height !== 99999) {
      return <p className="note">
        <ErrorIcon color="secondary"/>
        <span>{t('note-auto-resize-image-to')}:</span>
        <span>{t('width-up-to')} {width}px</span>
        <span>{t('height-up-to')} {height}px</span>
      </p>
    } else {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, width, height]);
  const Controls = useMemo(() => {
    if (type === 'avatar') {
      return <>
        <Fab
          size="small"
          color="primary"
          aria-label="edit"
          disabled={disabled}
          // @ts-ignore
          onClick={() => ref.current ? ref.current.click() : false}
        >
          <EditIcon/>
        </Fab>
        <Fab
          size="small"
          color="secondary"
          aria-label="delete"
          disabled={disabled || !Boolean(value_)}
          onClick={() => onEdit()}
        >
          <DeleteIcon/>
        </Fab>
      </>
    } else {
      return <>
        {Note}
        <Button
          size="small"
          color="secondary"
          aria-label="edit"
          disabled={disabled}
          // @ts-ignore
          onClick={() => ref.current ? ref.current.click() : false}
          startIcon={<EditIcon/>}
        >
          {t('edit')}
        </Button>
        <Button
          size="small"
          color="secondary"
          aria-label="delete"
          disabled={disabled || !Boolean(value_)}
          onClick={() => onEdit()}
          startIcon={<DeleteIcon/>}
        >
          {t('delete')}
        </Button>
        <Button
          size="small"
          color="secondary"
          aria-label="full size"
          disabled={disabled || !Boolean(value_)}
          onClick={() => setPreviewDialog(true)}
          startIcon={<FullscreenIcon/>}
        >
          {t('full-size')}
        </Button>
      </>
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, Note, disabled, ref, value_, onEdit, setPreviewDialog]);
  // render
  return <div
    className={classes}>
    {label &&
    <InputLabel
      shrink
      htmlFor="code-input"
      error={Boolean(error)}
    >
      {label}
    </InputLabel>
    }
    <div className={`image-uploader${Boolean(error) ? ' error' : ''}`}>
      {Preview}
      <div className="controls">{Controls}</div>
      <input
        style={{height: 0, width: 0, position: 'relative', overflow: 'hidden', opacity: 0}}
        ref={ref}
        type="file"
        accept={accept.input}
        onChange={onChangeFile}
        tabIndex={-1}
      />
      {type === 'avatar' && isEdit &&
      <AvatarEditorDialog
        value={file}
        width={width}
        height={height}
        borderRadius={borderRadius}
        onClose={() => setIsEdit(false)}
        onCreate={onEdit}
      />
      }
    </div>
    {Boolean(error) && <p className="error">{error.message || ''}</p>}
    {previewDialog && <Dialog
      open={true}
      onClose={() => setPreviewDialog(false)}
      className="image-uploader-preview-dialog">
      <DialogContent>
        <img src={value_} alt={'preview'}/>
      </DialogContent>
    </Dialog>}
    {alertMessage && <DialogAlert message={alertMessage} onClose={() => setAlertMessage('')}/>}
  </div>;
})

export default ImageUploader;
