export const replaceNull = (value: any) => {
  Object.keys(value).forEach(key => {
    if (value[key] === null) value[key] = '';
  });
  return value;
};

// handlers
export interface DefaultOnGridHandleProps {
  value: { type: 'edit' | 'new', id: boolean | string }
  setEditItemID: (value: boolean | string) => void;
}

export const defaultOnGridHandle = ({value, setEditItemID}: DefaultOnGridHandleProps) => {
  switch (value.type) {
    case 'new':
      setEditItemID(true);
      break;
    case 'edit':
      setEditItemID(value.id);
      break;
  }
};

export interface DefaultOnFormHandleProps {
  value: {
    type: 'get' | 'put' | 'patch' | 'delete' | 'post' | 'refreshAndContinue',
    payload: object,
    isEditAfter?: string
  };
  grid: any;
  setEditItemID: (value: boolean | string) => void;
}

export const defaultOnFormHandle = ({value, grid, setEditItemID}: DefaultOnFormHandleProps) => {
  if (grid) {
    const {type, isEditAfter} = value;
    switch (type) {
      case "put":
      case "patch":
      case "delete":
      case "post":
        setEditItemID(false);
        grid?.current?.onTasks([['getData']])
        break;
      case "refreshAndContinue":
        grid?.current?.onTasks([['getData']])
        break;
    }
    if (isEditAfter) setEditItemID(isEditAfter);
  }
};
