import React, { memo, useMemo } from 'react';
import GridConfig from './grid-config';
import DataGrid from 'components/data-grid';
import style from './index.module.scss';
import { Typography } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';

interface Props {
  userPatientProfileID: string;
}
export const GridClinicalDrugs = memo<Props>(({ userPatientProfileID }) => {
  const { t } = useTranslate();
  const gridConfig = useMemo<any>(
    () => GridConfig({ userPatientProfileID }),
    [userPatientProfileID],
  );

  return (
    <DataGrid
      className={style.grid}
      config={gridConfig}
      ScrollProps={{
        mode: 'visible',
      }}
      renderToolbarControlsSlot={() => {
        return (
          <Typography color={'secondary'} variant={'h2'}>
            {t('clinical-drugs')}
          </Typography>
        );
      }}
    />
  );
});
