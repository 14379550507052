import React, { useMemo } from 'react';
import { TabsWrapper, TabItem } from 'components/tabs-wrapper';
import { useValueQuery } from 'hooks/use-value-query';
import { GridDocuments, GridSignedDocuments } from '../../grids';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';
import { PATIENT_CARD_DOC_TABS } from 'configs/routes';

const QUERY_NAME = 'tab-docs';

interface Props {
  userPatientProfileID: string;
  userPatientProfileSubscriptionID: string | undefined;
}

export const TabDocuments: React.FC<Props> = ({
  userPatientProfileID,
  userPatientProfileSubscriptionID,
}) => {
  const { t } = useTranslate();

  const { value, onChange } = useValueQuery<PATIENT_CARD_DOC_TABS>({
    name: QUERY_NAME,
    init: PATIENT_CARD_DOC_TABS.DOCUMENTS,
    destroyOnUnmount: true,
  });

  const tabs = useMemo<TabItem<PATIENT_CARD_DOC_TABS>[]>(
    () => [
      {
        value: PATIENT_CARD_DOC_TABS.DOCUMENTS,
        title: t('documents'),
        element: <GridDocuments userPatientProfileID={userPatientProfileID} />,
      },
      {
        value: PATIENT_CARD_DOC_TABS.SIGNED_DOCUMENTS,
        title: t('signed-documents'),
        element: (
          <GridSignedDocuments
            userPatientProfileID={userPatientProfileID}
            userPatientProfileSubscriptionID={userPatientProfileSubscriptionID}
          />
        ),
      },
    ],
    [t, userPatientProfileID, userPatientProfileSubscriptionID],
  );

  return (
    <TabsWrapper
      tabs={tabs}
      value={value}
      onChange={onChange}
      classes={{ content: style.content }}
      TabPanelProps={{
        style: {
          paddingTop: 0,
        },
      }}
    />
  );
};
