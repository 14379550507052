import React, { FC, memo, useCallback } from 'react';
import { Select } from 'AurionCR/components/form';
import { IDataGridFilter } from 'components/data-grid/model';
import style from './index.module.scss';
import { calcFilterSelectResult } from 'components/data-grid/components/filters/filter-select';
import renderUserDetailsOption from 'components/render-option';

const SelectFieldDetails: FC<{
  filter: IDataGridFilter;
  value: any;
  onTasks: any;
}> = ({ filter, value, onTasks }) => {
  const onChange = useCallback((e, value) => {
    onTasks([
      ['pagination', { page: 1 }],
      // @ts-ignore
      ['filters', { [filter.field]: calcFilterSelectResult(value, filter.options.option.value) }],
      ['getData'],
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Select
      value={value}
      onChange={onChange}
      renderOption={renderUserDetailsOption}
      className={style.root}
      settings={filter.options}
      style={filter.options?.style}
    />
  );
};

export default memo(SelectFieldDetails);
