import { Loading } from 'AurionCR/components';
import { PIPELINE_VARIABLES } from 'configs/const';
import React, { memo, useCallback, useEffect, useState } from 'react';

const isDev = process.env.NODE_ENV === 'development';

type Config = typeof PIPELINE_VARIABLES;

interface Props {
  children: React.ReactNode;
}

export const AppConfigProvider = memo<Props>(({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();

  const getConfig = useCallback(async () => {
    const result = await fetch('/config.json');
    const config: Config = await result.json();

    const configKeys = Object.keys(config) as (keyof Config)[];

    configKeys.forEach((key) => {
      const value = config[key];
      const isEmpty = /^#{.+}#$/gi.test(value);

      if (!isDev) {
        PIPELINE_VARIABLES[key] = value;
        if (isEmpty) {
          setError(new Error(`PIPELINE VARIABLE: "${value}" - was not provided`));
        }
      }
    });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getConfig();
  }, [getConfig]);

  if (error) {
    throw error;
  }

  if (isLoading) return <Loading />;

  return <>{children}</>;
});
