import { apiRtk } from 'utils/rtk-query';
import { DynamicService } from 'utils/service';
import {
  API_END_OF_TREATMENT_RECOMMENDATION_TEMPLATES,
  EndOfTreatmentRecommendationTemplate,
} from './models';
import * as dynamic from 'utils/dynamic-helpers';

export * from './models';

class Service extends DynamicService<EndOfTreatmentRecommendationTemplate> {}

export const ServiceEndOfTreatmentRecommendationTemplate = new Service({
  mainField: 'id',
  getAll: API_END_OF_TREATMENT_RECOMMENDATION_TEMPLATES.GET_ALL_DYNAMIC,
  post: API_END_OF_TREATMENT_RECOMMENDATION_TEMPLATES.POST,
  patch: API_END_OF_TREATMENT_RECOMMENDATION_TEMPLATES.PATCH,
  delete: API_END_OF_TREATMENT_RECOMMENDATION_TEMPLATES.DELETE,
});

export const apiEndOfTreatmentRecommendationTemplate = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getEndOfTreatmentRecommendationTemplatesSource: builder.query<
      Pick<EndOfTreatmentRecommendationTemplate, 'id' | 'title'>[],
      void
    >({
      queryFn: async () => {
        try {
          const { data } = await ServiceEndOfTreatmentRecommendationTemplate.getAllDynamic({
            select: dynamic.select('id', 'title'),
            orderBy: dynamic.orderBy('rank', 'desc'),
            filter: dynamic.mergeFilters('isActive==true').join('&&'),
          });

          return { data: data.value };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
