import { FixServerObject } from 'utils/types';
import { FormDocumentInput } from 'services/form-document-inputs';
import { UserPatientProfile } from 'services/user-patient-profile';
import { UserPatientProfileSubscription } from 'services/user-patient-profile-subscriptions';

const API_ENDPOINT = 'FormDocuments';

export const API_FORM_DOCUMENTS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<FormDocument, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<FormDocument, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface FormDocument
  extends FixServerObject<Omit<Components.Schemas.FormDocument, 'formDocumentInputs'>, 'pdfUrl'> {
  formDocumentInputs?: FormDocumentInput[];
}

export interface DocumentSignMap {
  firstName: string;
  lastName: string;
  fullName: string;
  idNumber: string;
  dateOfBirth: string;
  mobilePhone: string;
  email: string;
  familyMemberName: string;
  familyMemberFirstName: string;
  familyMemberLastName: string;
  familyMemberPhone: string;
  familyMemberIDNumber: string;
  familyMemberRelationship: string;
  address: string;

  // prescription
  startDate: Date | string | null | undefined;
  endDate: Date | string | null | undefined;

  // signature
  docDate: Date;
  docTime: Date;
}

export class FormDocumentPostOrPatchInput
  implements
    Pick<
      Components.Schemas.FormDocument,
      'id' | 'title' | 'formDocumentCategoryID' | 'pdfUrl' | 'isRTL' | 'isUnder18' | 'isActive'
    >
{
  id: string | null | undefined = '';
  title = '';
  formDocumentCategoryID = '';
  pdfUrl = '';
  isActive = true;
  isRTL = false;
  isUnder18 = false;
  formDocumentDrugIDs?: string[] = [];
}

export interface FormDocumentsWithRequiredDrugs
  extends Pick<FormDocument, 'id' | 'title' | 'isUnder18'> {
  drugIDs: string[];
}

export interface AdapterDocumentSignInput
  extends Pick<
    UserPatientProfile,
    | 'firstName'
    | 'lastName'
    | 'fullName'
    | 'idNumber'
    | 'dateOfBirth'
    | 'mobilePhone'
    | 'email'
    | 'address'
    | 'familyMemberName'
    | 'familyMemberFirstName'
    | 'familyMemberLastName'
    | 'familyMemberPhone'
    | 'familyMemberIDNumber'
    | 'familyMemberRelationship'
  > {
  subscription: Pick<UserPatientProfileSubscription, 'startDate' | 'endDate'>;
}
