import { Tooltip, TooltipProps } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import style from './index.module.scss';

interface Props extends TooltipProps {}
export const TooltipError: React.FC<Props> = (props) => {
  return (
    <Tooltip
      {...props}
      classes={{
        ...props.classes,
        tooltip: clsx(style.tooltip, props.classes?.tooltip),
        arrow: clsx(style.arrow, props.classes?.arrow),
      }}
    />
  );
};
