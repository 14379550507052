import React from 'react';
import { StackProps } from 'components/stack';
import { Badge, IconButton } from '@material-ui/core';
import { SupportMeetingActivitiesDialog } from 'components/support-meeting-activities';
import { useOpen } from 'AurionCR/components/hooks';

import style from './index.module.scss';

interface Props extends StackProps {
  meetingID: string;
  count: number;
}

export const ColumnSupportMeetingActivities: React.FC<Props> = ({ meetingID, count }) => {
  const { isOpen, handleOpen, handleClose } = useOpen();
  return (
    <>
      <IconButton className={style.button} onClick={handleOpen}>
        <Badge badgeContent={count} color="primary" showZero classes={{ badge: style.badge }} />
      </IconButton>
      {isOpen && (
        <SupportMeetingActivitiesDialog
          onClose={handleClose}
          supportMeetingID={meetingID}
        />
      )}
    </>
  );
};
