import React, { memo, useCallback } from 'react';
import style from './index.module.scss';
import { Button } from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { useAppDispatch, useAppSelector } from 'store';
import { useI18n } from 'AurionCR/components';
import { pdfDocumentSubmitMerge } from '../../store';
import { getRandomString } from 'utils/other';

export const Footer = memo(() => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  // state
  const { isReadyToSubmit } = useAppSelector((state) => state.pdfDocumentSubmit);
  // handlers
  const handleSubmit = useCallback(() => {
    dispatch(pdfDocumentSubmitMerge({ triggerSubmit: getRandomString() }));
  }, [dispatch]);
  // render
  return isReadyToSubmit ? (
    <div className={style.wrapper}>
      <Button
        onClick={handleSubmit}
        endIcon={<DoneAllIcon />}
        variant="contained"
        color="primary"
        disabled={!isReadyToSubmit}
      >
        {t('submit')}
      </Button>
    </div>
  ) : null;
});
