import { useMemo } from 'react';
import { CONFIG_MODEL_TYPE } from '../helpers';
import { useTranslate } from 'hooks/use-translate';
export const useModelTypeOptions = () => {
  const { t } = useTranslate();
  return useMemo(
    () =>
      Object.entries(CONFIG_MODEL_TYPE).map(([key, val]) => {
        return { id: key, Icon: val.Icon, title: t(key), color: val.color };
      }),
    [t],
  );
};
