import React, { useCallback, useMemo } from 'react';
import style from './index.module.scss';
import clsx from 'clsx';
import { useTranslate } from 'hooks/use-translate';

import {
  MedicalPrescriptionDetailsProvider,
  useMedicalPrescriptionDetailsContext,
} from 'components/medical-prescription';
import DataGrid from 'components/data-grid';
import GridConfig from './grid-config';
import { IDataGridRowProps } from 'components/data-grid/model';
import { SectionPaper } from '../../../section-papper';

interface DrugsGridProps {
  prescriptionID: string;
}

const DrugsGrid: React.FC<DrugsGridProps> = ({ prescriptionID }) => {
  const gridConfig = useMemo<any>(() => {
    return GridConfig({ userPatientProfilePrescriptionID: prescriptionID });
  }, [prescriptionID]);

  const { setRows, rowRules } = useMedicalPrescriptionDetailsContext();
  const onGridHandle = useCallback(
    (props) => {
      if (props.type !== 'getDone') {
        return;
      }
      setRows(props.data?.value || []);
    },
    [setRows],
  );

  const rowProps = useCallback<IDataGridRowProps<{ id: string }>>(
    ({ row }) => {
      const hasRule = rowRules[row.id]?.length > 0;
      return {
        className: clsx(style.row, { [style.rowRequired]: hasRule }),
      };
    },
    [rowRules],
  );

  return <DataGrid config={gridConfig} onHandle={onGridHandle} rowProps={rowProps} />;
};

interface Props {
  className: string;
  prescriptionID: string;
  patientID: string | null | undefined;
}

export const DraftPrescriptionDetails: React.FC<Props> = ({
  prescriptionID,
  className,
  patientID,
}) => {
  const { t } = useTranslate();

  return (
    <SectionPaper className={clsx(style.root, className)} title={t('drugs')}>
      {patientID && (
        <MedicalPrescriptionDetailsProvider userPatientProfileID={patientID}>
          <DrugsGrid prescriptionID={prescriptionID} />
        </MedicalPrescriptionDetailsProvider>
      )}
    </SectionPaper>
  );
};
