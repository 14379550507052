import React, { useCallback, useMemo, useRef } from 'react';
import { Stack } from 'components/stack';
import { IconButton, Popover, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useOpen } from 'AurionCR/components/hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { FormAssignedTo, FormModel } from '../form-assigned-to';
import { isEqual } from 'lodash-es';
import { apiTodoTasks } from 'services/todo-tasks';
import { useEffectError, useMountedRef } from '../../../../hooks';

import { useTranslate } from 'hooks/use-translate';

const useUpdateMutation = apiTodoTasks.useUpdateTodoTaskWithLogMutation;

interface EditorContainerProps {
  taskID: string;
  userEmployeeProfileIDs: string[];
  onRefresh: () => void;
  onClose: () => void;
}
const EditorContainer: React.FC<EditorContainerProps> = ({
  taskID,
  userEmployeeProfileIDs,
  onClose,
  onRefresh,
}) => {
  const { tp } = useTranslate();

  const [update, resultUpdate] = useUpdateMutation();

  const error = resultUpdate.error;
  useEffectError(error);

  const isLoading = resultUpdate.isLoading;

  const mountedRef = useMountedRef();

  const methods = useForm({
    defaultValues: { userEmployeeProfileIDs },
  });
  const { handleSubmit } = methods;
  const onSubmit = useCallback(
    async (data: FormModel) => {
      const oldList = [...userEmployeeProfileIDs].sort();
      const newList = [...data.userEmployeeProfileIDs].sort();

      if (isEqual(oldList, newList)) {
        return onClose();
      }

      const formData = { id: taskID, userEmployeeProfileIDs: newList };
      await update({
        initData: { userEmployeeProfileIDs, id: taskID },
        formData: formData,
        remark: tp('updates-by-employee'),
      });

      onRefresh();

      if (mountedRef.current) {
        onClose();
      }
    },
    [userEmployeeProfileIDs, onClose, update, onRefresh, mountedRef, taskID, tp],
  );

  return (
    <FormProvider {...methods}>
      <FormAssignedTo isLoading={isLoading} onSave={handleSubmit(onSubmit)} onCancel={onClose} />
    </FormProvider>
  );
};

type Row = {
  id: string;
  assignedTo?: { id: string; fullName: string }[];
};
interface Props {
  row: Row;
  onRefresh: () => void;
}
export const ColumnTaskAssignedTo: React.FC<Props> = ({ row, onRefresh }) => {
  const employees = useMemo(() => {
    return row.assignedTo || [];
  }, [row.assignedTo]);

  const ref = useRef(null);
  const disabled = useMemo(() => {
    return employees.length === 0;
  }, [employees]);

  const userEmployeeProfileIDs = useMemo(() => {
    return employees.map(({ id }) => id);
  }, [employees]);

  const { isOpen, handleOpen, handleClose } = useOpen();

  return (
    <>
      <Stack alignItems={'start'} justifyContent={'space-between'}>
        <div ref={ref}>
          {employees.map(({ fullName, id }) => {
            return (
              <Typography noWrap title={fullName} key={id}>
                {fullName}
              </Typography>
            );
          })}
        </div>
        <IconButton color={'primary'} disabled={disabled} onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Stack>
      <Popover open={isOpen} anchorEl={ref.current} onClose={handleClose}>
        <EditorContainer
          taskID={row.id}
          userEmployeeProfileIDs={userEmployeeProfileIDs}
          onClose={handleClose}
          onRefresh={onRefresh}
        />
      </Popover>
    </>
  );
};
