import React, { useMemo } from 'react';
import {
  apiUserPatientProfileSessions,
  LatestSessionWithItems,
} from 'services/user-patient-profile-session';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';
import { useEffectError, useOpenValue } from 'hooks';
import { DialogUserPatientProfileSession } from 'components/dialoog-user-patient-profile-session';
import { NativeScroll } from 'components/native-scroll';
import { PreviewSessionEffectsList } from 'components/notebook-form-generator';

const adapterSession = (item: LatestSessionWithItems) => {
  return {
    id: String(item.id),
    notebook: item.notebook.labelKey,
    employee: item.userEmployeeProfile.fullName,
    date: item.createdDate,
    items: item.previewItems,
    remarks: item.remarks,
  };
};

interface Props {
  userPatientProfileID: string;
}

export const EffectsSection: React.FC<Props> = ({ userPatientProfileID }) => {
  const { t } = useTranslate();

  const payload = {
    userPatientProfileID,
    userPatientProfileSessionID: null,
  };

  const { openValue, onOpen, onClose } = useOpenValue<string>();

  const resultIcd10s = apiUserPatientProfileSessions.useGetAllSessionWithIcd10sQuery(payload);
  useEffectError(resultIcd10s.error);
  const icd10List = useMemo(() => {
    return (resultIcd10s.data || []).map(adapterSession);
  }, [resultIcd10s.data]);

  const resultSideEffects =
    apiUserPatientProfileSessions.useGetAllSessionWithSideEffectsQuery(payload);
  useEffectError(resultSideEffects.error);
  const sideEffectList = useMemo(() => {
    return (resultSideEffects.data || []).map(adapterSession);
  }, [resultSideEffects.data]);

  const resultSurgeries = apiUserPatientProfileSessions.useGetAllSessionWithSurgeriesQuery(payload);
  useEffectError(resultSurgeries.error);
  const surgeryList = useMemo(() => {
    return (resultSurgeries.data || []).map(adapterSession);
  }, [resultSurgeries.data]);

  const resultSensitivities =
    apiUserPatientProfileSessions.useGetAllSessionWithSensitivitiesQuery(payload);
  useEffectError(resultSensitivities.error);
  const sensitivityList = useMemo(() => {
    return (resultSensitivities.data || []).map(adapterSession);
  }, [resultSensitivities.data]);

  const resultSensitivityDrugs =
    apiUserPatientProfileSessions.useGetAllSessionWithSensitivityDrugsQuery(payload);
  useEffectError(resultSensitivityDrugs.error);
  const sensitivityDrugList = useMemo(() => {
    return (resultSensitivityDrugs.data || []).map(adapterSession);
  }, [resultSensitivityDrugs.data]);

  const resultRoutineDrugs =
    apiUserPatientProfileSessions.useGetAllSessionWithRoutineDrugsQuery(payload);
  useEffectError(resultRoutineDrugs.error);
  const routineDrugList = useMemo(() => {
    return (resultRoutineDrugs.data || []).map(adapterSession);
  }, [resultRoutineDrugs.data]);

  const resultPastDrugs = apiUserPatientProfileSessions.useGetAllSessionWithPastDrugsQuery(payload);
  useEffectError(resultPastDrugs.error);
  const pastDrugList = useMemo(() => {
    return (resultPastDrugs.data || []).map(adapterSession);
  }, [resultPastDrugs.data]);

  return (
    <NativeScroll mode={'visible'}>
      <div className={style.root}>
        <PreviewSessionEffectsList title={t('icd10s')} list={icd10List} onPreview={onOpen} />
        <PreviewSessionEffectsList
          title={t('side-effects')}
          list={sideEffectList}
          onPreview={onOpen}
        />
        <PreviewSessionEffectsList title={t('surgeries')} list={surgeryList} onPreview={onOpen} />
        <PreviewSessionEffectsList
          title={t('sensitivities')}
          list={sensitivityList}
          onPreview={onOpen}
        />
        <PreviewSessionEffectsList
          title={t('routine-drugs')}
          list={routineDrugList}
          onPreview={onOpen}
        />
        <PreviewSessionEffectsList
          title={t('sensitivity-drugs')}
          list={sensitivityDrugList}
          onPreview={onOpen}
        />
        <PreviewSessionEffectsList
          title={t('past-drug-for-obesity')}
          list={pastDrugList}
          onPreview={onOpen}
        />
      </div>
      {openValue && (
        <DialogUserPatientProfileSession
          itemID={openValue}
          onClose={onClose}
          userPatientProfileID={userPatientProfileID}
          isShowDelete={false}
        />
      )}
    </NativeScroll>
  );
};
