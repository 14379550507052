import { iUserPatientProfilePrescription } from 'services/user-patient-profile-prescriptions';

export type GridModel = iUserPatientProfilePrescription & {
  id: string;
  drugs: string[];
  totalActivities: number;
  pharmacyFollowupID: string | null;
};

export enum CustomHandler {
  SEND_TO_PATIENT,
  SEND_COPY_TO_PATIENT,
  SEND_TO_PHARMACY,
}

export type CustomHandlers =
  | { type: CustomHandler.SEND_TO_PATIENT; data: GridModel }
  | { type: CustomHandler.SEND_COPY_TO_PATIENT; data: GridModel }
  | { type: CustomHandler.SEND_TO_PHARMACY; data: GridModel };
