import React, { memo, useCallback, useEffect, useMemo } from 'react';
import {
  apiClinicalMeetings,
  ClinicalMeeting,
  ClinicalMeetingCreateModel,
  ServiceClinicalMeetings,
} from 'services/clinical-meetings';
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  LinearProgress,
} from '@material-ui/core';
import { useEffectError, useFetchDynamic, useFieldProps, useMutationDynamic } from 'hooks';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ClinicalMeetingFormEdit } from '../form-edit';
import { SelectMeetingType } from 'components/select-meeting-type';
import CloseIcon from '@material-ui/icons/Close';
import { composeFunctions, setToModel } from 'utils';

import { useCurrentUser, useSourceClinicalMeetingSubjects } from 'components/hooks';
import { convertToDate, dateFormat } from 'utils/dates';
import { usePermissions } from 'hooks/use-permissions';
import { MEETING_TYPE_KEY } from 'services/clinical-meeting-types';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { validateRule } from 'AurionCR/components/formV2';
import { useOpen } from 'AurionCR/components/hooks';
import { useClinicalMeetingSendMessageToPatient } from '../../hooks';
import { useAppDispatch } from 'store';
import { useTranslate } from 'hooks/use-translate';
import { ClinicalMeetingFormModel, ClinicalMeetingMeta } from '../../models';
import { ButtonCancel, ButtonDelete, ButtonSave } from 'components/app-buttons';
import { Stack } from 'components/stack';
import { DialogConfirm } from 'components/dialog-confirm';
import style from './index.module.scss';
import { isEqual, pick } from 'lodash-es';
import {
  DialogMeetingActivityRemark,
  MeetingActivityModel,
} from 'components/dialog-meeting-activity-remark';

type ReasonData = MeetingActivityModel;
type ActivityData = ReasonData;

type PredefinedValues = Pick<ClinicalMeeting, 'clinicalMeetingSubjectID' | 'userPatientProfileID'>;
interface Props {
  itemID: string;
  onClose: () => void;
  onComplete: () => void;
  initData?: Partial<ClinicalMeetingFormModel>;
  meetingTypeKey?: MEETING_TYPE_KEY;
  times: { id: string }[];
  predefinedValues?: Partial<PredefinedValues>;
}

export const DialogClinicalMeetingEdit = memo<Props>(
  ({ itemID, initData, onComplete, onClose, meetingTypeKey, times, predefinedValues }) => {
    const { t, tp } = useTranslate();
    const { appUserID } = useCurrentUser();

    const dispatch = useAppDispatch();
    const { showSendSmsDialog } = useClinicalMeetingSendMessageToPatient();

    const defaultValues = useMemo(() => {
      const primary = {
        ...new ClinicalMeetingCreateModel(),
        organizerUserEmployeeProfileID: appUserID,
        ...initData,
        isSendSmsDoctor: true,
        isSendSmsEmployee: true,
        ...predefinedValues,
      };

      return { ...primary, clinicalMeetingSubjectID: primary.clinicalMeetingSubjectID || '' };
    }, [initData, predefinedValues, appUserID]);
    const formMethods = useForm<ClinicalMeetingFormModel>({
      defaultValues,
    });

    const meta = useMemo<ClinicalMeetingMeta>(
      () => ({
        disablePatient: !!predefinedValues?.userPatientProfileID,
        disableSubject: !!predefinedValues?.clinicalMeetingSubjectID,
      }),
      [predefinedValues],
    );

    const { reset, handleSubmit, control, errors, register, trigger, getValues } = formMethods;

    const fetchData = useCallback(
      (...args: Parameters<typeof ServiceClinicalMeetings.getDynamic>) => {
        return ServiceClinicalMeetings.getDynamic<ClinicalMeeting>(...args);
      },
      [],
    );
    const [triggerGet, { data, isLoading: isLoadingGet }] = useFetchDynamic(fetchData);

    useEffect(() => {
      triggerGet(itemID);
    }, [itemID, triggerGet]);

    useEffect(() => {
      if (!data) return;
      const parsedData: Partial<ClinicalMeetingCreateModel> = {
        ...data,
        organizerUserEmployeeProfileID: data.organizerUserEmployeeProfileID || appUserID,
        meetingDateTimeUserEmployeeProfile:
          data.meetingFromDateTime && data.meetingToDateTime
            ? [new Date(data.meetingFromDateTime), new Date(data.meetingToDateTime)]
            : null,
      };
      reset(setToModel(defaultValues, { ...parsedData, ...predefinedValues }));
      //eslint-disable-next-line
    }, [data, reset, appUserID]);

    const tryToComplete = useCallback(() => {
      onComplete();
      onClose();
    }, [onClose, onComplete]);

    const requestPatchItem = useCallback(
      async (formData: ClinicalMeetingFormModel, activityData?: ActivityData) => {
        const {
          doctorID,
          meetingDateTimeDoctor,
          isSendSmsDoctor,

          userEmployeeProfileID,
          meetingDateTimeUserEmployeeProfile,
          isSendSmsEmployee,
          date,

          ...rest
        } = formData;

        if (!data) return;

        const clinicalMeetingID = String(itemID);

        const range = meetingDateTimeUserEmployeeProfile || [];

        await dispatch(
          apiClinicalMeetings.endpoints.updateClinicalMeetingWithLog.initiate({
            initData: {
              ...data,
              userEmployeeProfileID,
              userPatientProfileID: data.userPatientProfileID,
            },
            formData: {
              ...rest,
              meetingFromDateTime: convertToDate(range[0]).toISOString(),
              meetingToDateTime: convertToDate(range[1]).toISOString(),
              userEmployeeProfileID,
              userPatientProfileID: data.userPatientProfileID,
            },
            remark: activityData?.remarks,
            remarkForPatientCallStatusID: activityData?.remarkForPatientCallStatusID,
          }),
        );

        if (isSendSmsEmployee) {
          showSendSmsDialog({ clinicalMeetingID });
        }

        tryToComplete();
      },
      [showSendSmsDialog, tryToComplete, itemID, dispatch, data],
    );
    const [patchItem, { error: errorPatch, isLoading: isLoadingPatch }] =
      useMutationDynamic(requestPatchItem);
    useEffectError(errorPatch);

    const stateOpenReason = useOpen();

    const onSubmitForm = (formData: ClinicalMeetingFormModel) => {
      if (!data) return;

      const [meetingFromDateTime, meetingToDateTime] = (
        formData.meetingDateTimeUserEmployeeProfile || []
      ).map((v) => convertToDate(v).toISOString());

      const oldData = pick(data, ['meetingFromDateTime', 'meetingToDateTime']);
      const newData = { meetingFromDateTime, meetingToDateTime };

      if (isEqual(oldData, newData)) {
        return patchItem(formData);
      }

      stateOpenReason.handleOpen();
    };

    const onSubmitReason = async (reasonData: ReasonData) => {
      const formData = getValues();
      const isValid = await trigger();

      if (!isValid) return;
      patchItem(formData, reasonData);
    };

    const getFieldProps = useFieldProps({ errors });

    const sourceMeetingSubjects = useSourceClinicalMeetingSubjects();

    const requestDeleteItem = useCallback(async () => {
      await ServiceClinicalMeetings.delete({ id: String(itemID) });
      tryToComplete();
    }, [tryToComplete, itemID]);
    const [deleteItem, resultDelete] = useMutationDynamic(requestDeleteItem);
    useEffectError(resultDelete.error);
    const stateDelete = useOpen();

    const isAllowToEdit = usePermissions('!isAllowToEditClinicalMeetingsLimited');
    const _isAllowToDelete = usePermissions('isAllowToDeleteAnything');

    const isLocked = useMemo(() => {
      return data ? ServiceClinicalMeetings.isMeetingLocked(data) : false;
    }, [data]);
    const isAllowToDelete = isAllowToEdit && _isAllowToDelete;

    const isReadOnly = !isAllowToEdit || isLocked;
    const isLoading = isLoadingGet || isLoadingPatch || resultDelete.isLoading;

    return (
      <>
        <Dialog open={true} onClose={onClose} fullWidth maxWidth={'sm'}>
          <input {...register('id')} hidden />
          <Controller
            control={control}
            name={'clinicalMeetingSubjectID'}
            rules={validateRule('required')}
            render={(renderProps) => {
              return (
                <SelectMeetingType
                  {...getFieldProps(renderProps)}
                  disabled={isReadOnly || meta.disableSubject}
                  options={sourceMeetingSubjects.data}
                  loading={sourceMeetingSubjects.loading}
                  endIcon={
                    <IconButton
                      className={style.close}
                      style={{ color: 'white' }}
                      onClick={onClose}
                    >
                      <CloseIcon color={'inherit'} />
                    </IconButton>
                  }
                />
              );
            }}
          />
          {isLoading && <LinearProgress />}
          <DialogContent style={{ position: 'relative' }}>
            <FormProvider {...formMethods}>
              <ClinicalMeetingFormEdit
                meta={meta}
                meetingTypeKey={meetingTypeKey}
                isReadOnly={isReadOnly}
                isLoading={isLoading}
                times={times}
              />
            </FormProvider>
          </DialogContent>
          <DialogActions>
            <Stack sx={{ marginRight: 'auto' }}>
              {isAllowToDelete && (
                <ButtonDelete
                  onClick={isAllowToDelete ? stateDelete.handleOpen : undefined}
                  disabled={isLoading || isReadOnly}
                />
              )}
            </Stack>

            <ButtonCancel isBack={true} disabled={isLoading} onClick={onClose} />
            <ButtonSave
              isCreate={false}
              disabled={isLoading}
              onClick={handleSubmit(onSubmitForm)}
            />
          </DialogActions>
        </Dialog>
        {stateDelete.isOpen && isAllowToDelete && (
          <DialogConfirm
            title={t('confirm-delete')}
            message={data ? dateFormat(data.meetingFromDateTime, APP_FORMAT_DATE_TIME) : 'unknown'}
            onCancel={stateDelete.handleClose}
            onConfirm={deleteItem}
          />
        )}
        {stateOpenReason.isOpen && (
          <DialogMeetingActivityRemark
            title={tp('clinical-meeting-update-reason-title')}
            description={tp('clinical-meeting-update-reason-description')}
            isLoading={false}
            onSubmit={composeFunctions(stateOpenReason.handleClose, onSubmitReason)}
            onClose={stateOpenReason.handleClose}
            reason={'rescheduled'}
          />
        )}
      </>
    );
  },
);
