import { createSvgIcon } from '@material-ui/core';
import React from 'react';

export default createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3514_14605)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5981 11.456C23.0641 12.649 24.0001 14.466 24.0001 16.5C24.0001 20.087 21.0871 23 17.5001 23C16.6831 23 15.9011 22.849 15.1801 22.573L21.5981 11.456ZM9.69507 19.865L9.33207 20.499C7.95207 22.89 4.89107 23.71 2.50107 22.33C0.110069 20.95 -0.709931 17.889 0.670069 15.499L7.54307 3.50101C8.47407 1.88801 10.1691 0.990006 11.9081 1.00001C12.7471 1.00501 13.5961 1.22101 14.3741 1.67001C16.2161 2.73301 17.1261 4.79501 16.8151 6.77801C16.7461 7.21501 16.6191 7.64701 16.4291 8.06501C15.4491 8.18901 14.5201 8.48001 13.6731 8.90801L14.4721 7.50101C15.3001 6.06701 14.8081 4.23001 13.3741 3.40201C11.9401 2.57401 10.1031 3.06701 9.27507 4.50101L5.90307 10.436L9.88107 12.732C9.31807 13.868 9.00207 15.147 9.00207 16.499C9.00207 17.694 9.24907 18.833 9.69507 19.865V19.865ZM13.4451 21.579C11.9551 20.387 11.0001 18.554 11.0001 16.5C11.0001 12.913 13.9131 10 17.5001 10C18.3371 10 19.1371 10.158 19.8721 10.447L13.4451 21.579V21.579Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3514_14605">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  'Pharmacy',
);
