import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'DietDiaryTypes';

export const API_DIET_DIARY_TYPES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<DietDiaryType, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<DietDiaryType, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface DietDiaryType
  extends FixServerObject<Components.Schemas.DietDiaryType, 'id' | 'title' | 'icon' | 'color'> {}
