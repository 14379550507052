import React, { memo, useCallback, useRef } from 'react';
import { Button, DialogActions, DialogContent } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { UserInfoWeightGrid } from 'components/user-info-weight-grid';
import { Panel, PanelGroup } from 'react-resizable-panels';
import { AppPanelResizeHandle } from 'components/app-panel-resize-handle';
import { useTranslate } from 'hooks/use-translate';
import { NotebookFieldInput } from 'services/notebook-field-input';
import { apiUserPatientProfileSessions } from 'services/user-patient-profile-session';
import { useCurrentUser } from 'components/hooks';
import { useEffectError, useMountedRef } from 'hooks';
import { isMutationFulfilled } from 'utils/rtk-query';
import { useAppDispatch, useAppSelector } from 'store';
import {
  actionNotebookFormGenerationSetShowCloneTooltip,
  actionNotebookFormGeneratorSetSession,
  actionNotebookFormGeneratorSetState,
  selectNotebookFormGeneratorIsShowCloneTooltip,
  selectNotebookFormGeneratorShouldClone,
} from 'store/notebook-form-generator';
import { NotebookFormSettings } from '../../models';
import { NotebookFormPanel, NotebookFormPanelHandler } from '../notebook-form-panel';
import { NotebookDynamicSubmitModel, useNotebookFormGeneratorProps } from '../../hooks';
import { InformationSection } from '../information-section';
import { notebookEmitter } from '../../emitter';
import { NotebookDialogTitle } from '../notebook-dialog-title';
import style from './index.module.scss';

interface Props {
  userPatientProfileSessionID: string | undefined;
  notebookSettings: NotebookFormSettings;
  notebookLabelKey: string;
  isLoading: boolean;
  onClose: () => void;
  onCreated?: () => void;
  fieldInputs: NotebookFieldInput[];
}

export const NotebookFormGeneratorStateForm = memo<Props>(
  ({
    fieldInputs,
    isLoading,
    onCreated,
    onClose,
    userPatientProfileSessionID,
    notebookSettings,
    notebookLabelKey,
  }) => {
    const mountedRef = useMountedRef();

    const { userPatientProfileID, notebookID } = useNotebookFormGeneratorProps();
    const dispatch = useAppDispatch();
    const { appUserID } = useCurrentUser();
    const { t } = useTranslate();

    const ref = useRef<NotebookFormPanelHandler>(null);

    const [postOrPatchItem, resultSession] =
      apiUserPatientProfileSessions.usePostOrPatchNotebookGeneratorMutation();
    useEffectError(resultSession.error);

    const [cloneFromPrevious, resultCloneFromPrevious] =
      apiUserPatientProfileSessions.useCloneClinicalDrugsFromPreviousNotebookMutation();
    useEffectError(resultCloneFromPrevious.error);

    const processCloneFromPrevious = useCallback(
      async (...args: Parameters<typeof cloneFromPrevious>) => {
        const result = await cloneFromPrevious(...args);

        notebookEmitter.emit('refresh-clinical-drugs');

        if (!mountedRef.current) return;
        if (isMutationFulfilled(result)) {
          dispatch(actionNotebookFormGenerationSetShowCloneTooltip(false));
        }
      },
      [cloneFromPrevious, mountedRef, dispatch],
    );

    const processPostOrPatch = useCallback(
      async (data: NotebookDynamicSubmitModel) => {
        const { fieldInputs, ...rest } = data;

        const sessionData = {
          notebookID,
          userPatientProfileID,
          userPatientProfileSessionID: userPatientProfileSessionID ?? null,
          userEmployeeProfileID: appUserID,
          ...rest,
          userPatientProfileSessionItems: fieldInputs,
        };

        if (!mountedRef.current) {
          return null;
        }

        const result = await postOrPatchItem(sessionData);

        if (mountedRef.current && isMutationFulfilled(result)) {
          dispatch(actionNotebookFormGeneratorSetSession(result.data.userPatientProfileSessionID));
          onCreated && onCreated();
        }

        return result;
      },
      [
        postOrPatchItem,
        notebookID,
        userPatientProfileID,
        userPatientProfileSessionID,
        appUserID,
        mountedRef,
        dispatch,
        onCreated,
      ],
    );

    const onSubmit = useCallback(
      async (data: NotebookDynamicSubmitModel) => {
        const result = await processPostOrPatch(data);

        if (!mountedRef.current) {
          return;
        }

        if (isMutationFulfilled(result)) {
          dispatch(actionNotebookFormGeneratorSetState('result'));
        }
      },
      [processPostOrPatch, dispatch, mountedRef],
    );

    const createAndClone = useCallback(async () => {
      try {
        if (!ref.current) return;

        const formData = await ref.current.validateAndGet();
        const resultCreate = await processPostOrPatch(formData);

        if (!isMutationFulfilled(resultCreate)) return;

        await processCloneFromPrevious({
          userPatientProfileID,
          userPatientProfileSessionID: resultCreate.data.userPatientProfileSessionID,
        });
      } catch {}
    }, [processPostOrPatch, processCloneFromPrevious, userPatientProfileID]);

    const onCopyFromPrevious = useCallback(async () => {
      if (userPatientProfileSessionID) {
        await processCloneFromPrevious({ userPatientProfileSessionID, userPatientProfileID });
      } else {
        await createAndClone();
      }
    }, [
      userPatientProfileSessionID,
      userPatientProfileID,
      processCloneFromPrevious,
      createAndClone,
    ]);

    const isShouldCloneFirst = useAppSelector(selectNotebookFormGeneratorShouldClone);
    const isShowCloneTooltip = useAppSelector(selectNotebookFormGeneratorIsShowCloneTooltip);

    const onClickSave = useCallback(() => {
      if (!ref.current) return;

      if (isShouldCloneFirst) {
        return dispatch(actionNotebookFormGenerationSetShowCloneTooltip(true));
      }

      ref.current.submit();
    }, [isShouldCloneFirst, dispatch]);

    const onTryCreate = useCallback(() => {
      return createAndClone();
    }, [createAndClone]);

    return (
      <>
        <DialogHeading
          TitleProps={{ style: { width: '100%' } }}
          isLoading={isLoading}
          title={
            <NotebookDialogTitle
              mainTitle={notebookLabelKey}
              userPatientProfileID={userPatientProfileID}
            />
          }
          onClose={onClose}
        />
        <DialogContent className={style.content}>
          <UserInfoWeightGrid userPatientProfileID={userPatientProfileID} mt={1} />
          <PanelGroup direction={'horizontal'}>
            <Panel defaultSize={50} minSize={30} maxSize={65}>
              <NotebookFormPanel
                isLoading={resultSession.isLoading || resultCloneFromPrevious.isLoading}
                ref={ref}
                showOnlyWhenReady={false}
                userPatientProfileID={userPatientProfileID}
                userPatientProfileSessionID={userPatientProfileSessionID}
                notebookFieldInputs={fieldInputs}
                notebookSettings={notebookSettings}
                onSubmit={onSubmit}
                onCopyClinicalDrugsFromPrevious={onCopyFromPrevious}
                onTryCreate={onTryCreate}
                isShowCloneTooltip={isShowCloneTooltip}
              />
            </Panel>
            <AppPanelResizeHandle
              style={{
                margin: '0 1rem',
              }}
            />
            <Panel>
              <InformationSection
                userPatientProfileSessionID={userPatientProfileSessionID ?? null}
                userPatientProfileID={userPatientProfileID}
              />
            </Panel>
          </PanelGroup>
        </DialogContent>
        <DialogActions
          style={{
            paddingTop: '1rem',
            paddingBottom: '1rem',
          }}
        >
          <Button color={'primary'} onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            disabled={resultSession.isLoading || isShowCloneTooltip}
            onClick={onClickSave}
            color={'primary'}
            variant={'contained'}
          >
            {t('save')}
          </Button>
        </DialogActions>
      </>
    );
  },
);
