import SelectFieldDetails from 'components/app-grid/select-field-details';
import { DefaultGridButtonExportToExcel } from 'components/data-grid/model';
import { TemplateEmployee } from 'components/templates/template-employee';
import { APP_FORMAT_DATE } from 'configs/const';
import { format } from 'date-fns';
import React from 'react';
import { permissionKey } from 'services/user-employee-profile-permissions';
import { API_USER_EMPLOYEE_PROFILES } from 'services/user-employee-profiles';
import {
  API_USER_PATIENT_PROFILE_MEDICAL_INFORMATIONS,
  MedicalInformations,
  MedicalInformationsGrid,
} from 'services/user-patient-profile-medical-informations';
import {
  createFilterContains,
  createFilterEquals,
  createFilterSmartSearch,
  mergeFilters,
} from 'utils/dynamic-helpers';

interface Options {
  userPatientProfileID: string;
}
export default (options: Options): any => ({
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_MEDICAL_INFORMATIONS.GET_ALL_DYNAMIC,
      filters: createFilterEquals('userPatientProfileID', options.userPatientProfileID),
      selected: 'isActive',
    },
    create: {
      url: API_USER_PATIENT_PROFILE_MEDICAL_INFORMATIONS.POST,
    },
    patch: {
      url: API_USER_PATIENT_PROFILE_MEDICAL_INFORMATIONS.PATCH,
    },
    delete: {
      url: API_USER_PATIENT_PROFILE_MEDICAL_INFORMATIONS.DELETE,
    },
    sortable: {
      field: 'createdDate',
      order: 'desc',
    },
  },
  controlsDropDown: [
    'edit',
    { type: 'delete', title: 'delete', permission: permissionKey('isAllowToDeleteAnything') },
  ],
  toolbar: {
    title: '',
    filters: [
      {
        field: 'text',
        options: {
          type: 'string',
          label: 'text',
          valueTemplate: ({ value }: any) =>
            mergeFilters(
              createFilterContains<MedicalInformationsGrid>(['remarks'], value),
              createFilterSmartSearch<MedicalInformationsGrid>(
                ['userEmployeeProfile.firstName', 'userEmployeeProfile.lastName'],
                value,
              ),
            ).join('||'),
        },
      },
      {
        field: 'userEmployeeProfileID',
        options: {
          style: { minWidth: '30rem' },
          type: 'component',
          template: SelectFieldDetails,
          label: 'employee',
          source: `${API_USER_EMPLOYEE_PROFILES.GET_ALL_DYNAMIC}?Select=appIdentityUserID as id,fullName as title,isActive&orderBy=isActive desc,firstName,lastName asc`,
          option: {
            label: 'title',
            value: 'id',
          },
        },
      },
    ],
    controls: null,
    controlsDefault: {
      export: DefaultGridButtonExportToExcel
    },
  },
  columns: [
    {
      title: 'date',
      field: 'createdDate',
      template: {
        type: 'date',
        format: APP_FORMAT_DATE,
      },
    },
    {
      title: 'employee',
      field: 'userEmployeeProfile.fullName as userEmployeeProfileID',
      sortable: 'userEmployeeProfile.firstName',
      template: (data: MedicalInformations) => (
        <TemplateEmployee employee={data.userEmployeeProfileID || ''} />
      ),
    },
    {
      title: 'remarks',
      field: 'remarks',
    },
    {
      title: 'expiration-date',
      field: 'expirationDate',
      template: (data: MedicalInformations) =>
        data.expirationDate ? format(new Date(data.expirationDate), APP_FORMAT_DATE) : '',
    },
    {
      title: 'permanent',
      field: 'isPermanent',
      template: 'boolean',
    },
    {
      title: 'active',
      field: 'isActive',
      template: 'boolean',
    },
  ],
});
