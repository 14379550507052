import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { FormControlLabel, IconButton } from '@material-ui/core';
import { Checkbox, FormController, Input, Select } from 'AurionCR/components/formV2';
import { NOTEBOOKS_STATE } from '../../store/module';
import { TypeNotebookFieldInput } from './notebook-field-input';
import { useTranslate } from 'hooks/use-translate';
import { RowFormLayout } from 'pages/Settings/Notebooks/Notebooks/form/components/row-form-layout';

export const NotebookFieldInputNew = memo(() => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  // states
  const {
    notebooks: { inputTypes, isLoading },
  }: {
    notebooks: NOTEBOOKS_STATE;
  } = useSelector((state: any) => state);
  const [isDisabledDefaultValue, setIsDisabledDefaultValue] = useState(true);
  const { control, errors, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      isMedicalInformation: false,
      isRequired: false,
      inputTypeID: '',
      labelKey: '',
      defaultValue: '',
    },
  });
  // handlers
  const onSubmit = (formData: any) => {
    dispatch({ type: 'NOTEBOOKS_CREATE_NOTEBOOK_FIELD_INPUT', payload: formData });
  };
  const onChangeInputType = useCallback(
    (e, value) => {
      const isDisabled = !(
        value &&
        (value.inputTypeParameter?.isText || value.inputTypeParameter?.isLongText)
      );
      setIsDisabledDefaultValue(isDisabled);
      if (isDisabled) setValue('defaultValue', '');
      return e;
    },
    [setValue, setIsDisabledDefaultValue],
  );
  // clean form
  useEffect(() => {
    if (!isLoading) {
      reset();
      setIsDisabledDefaultValue(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, setIsDisabledDefaultValue]);
  // render
  return (
    <div className="notebook-field-inputs__new">
      <div />
      {/*form*/}
      <RowFormLayout>
        <FormController<TypeNotebookFieldInput>
          name="labelKey"
          placeholder={t('parameter')}
          as={Input}
          rules="required,minLength"
          errors={errors}
          control={control}
          disabled={isLoading}
        />
        <FormController<TypeNotebookFieldInput>
          name={'inputTypeID'}
          placeholder={t('field-type')}
          as={Select}
          rules="required"
          errors={errors}
          control={control}
          disabled={isLoading}
          source={inputTypes}
          onChange={onChangeInputType}
        />
        <FormController<TypeNotebookFieldInput>
          name={'defaultValue'}
          placeholder={t('default-value')}
          as={Input}
          errors={errors}
          control={control}
          disabled={isLoading || isDisabledDefaultValue}
        />
        <FormControlLabel
          className="is-required"
          control={
            <FormController<TypeNotebookFieldInput>
              name="isRequired"
              control={control}
              disabled={isLoading}
              as={Checkbox}
              color="primary"
            />
          }
          label={t('required')}
        />
        <FormControlLabel
          control={
            <FormController<TypeNotebookFieldInput>
              name="isMedicalInformation"
              control={control}
              disabled={isLoading}
              as={Checkbox}
              color="primary"
            />
          }
          label={t('medical-information')}
        />
      </RowFormLayout>
      {/*controls*/}
      <IconButton
        color="primary"
        className="btn-create"
        disabled={isLoading}
        onClick={handleSubmit(onSubmit)}
      >
        <AddCircleIcon fontSize={'inherit'} style={{ fontSize: '3rem' }} />
      </IconButton>
    </div>
  );
});
