import { createSlice, isAnyOf, SerializedError } from '@reduxjs/toolkit';
import {
  actionEmployeesMonitorGetEmployees,
  actionEmployeesMonitorPingCurrentUser,
  actionEmployeesMonitorRefreshEmployees,
} from './actions';
import { EmployeeActivitiesMonitorItem } from 'services/user-employee-profiles';

interface State {
  isPolling: boolean;
  isLoading: boolean;
  isInit: boolean;
  error: Error | SerializedError | null;
  employees: EmployeeActivitiesMonitorItem[];
}
const initialState = (): State => ({
  isPolling: false,
  isInit: false,
  isLoading: false,
  error: null,
  employees: [],
});

const slice = createSlice({
  name: 'EMPLOYEES_MONITOR',
  initialState,
  reducers: {
    actionEmployeesMonitorStartPolling(state) {
      state.isPolling = true;
    },
    actionEmployeesMonitorEndPolling(state) {
      state.isPolling = false;
      state.isInit = false;
    },
    actionEmployeesMonitorUpdateRerender(state) {
      state.employees = Array.from(state.employees);
    },
  },
  extraReducers(build) {
    build.addCase(actionEmployeesMonitorPingCurrentUser.fulfilled, (state, action) => {
      const currentEmployee = state.employees.find((employee) => {
        return employee.appIdentityUserID === action.payload.appUserID;
      });

      if (currentEmployee) {
        currentEmployee.lastActivityDate = action.payload.lastActivityDate;
      }
    });
    build
      .addMatcher(
        isAnyOf(
          actionEmployeesMonitorGetEmployees.pending,
          actionEmployeesMonitorRefreshEmployees.pending,
        ),
        (state) => {
          state.isLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isAnyOf(
          actionEmployeesMonitorGetEmployees.fulfilled,
          actionEmployeesMonitorRefreshEmployees.fulfilled,
        ),
        (state, action) => {
          state.isLoading = false;
          state.employees = action.payload;
          state.isInit = true;
        },
      )
      .addMatcher(
        isAnyOf(
          actionEmployeesMonitorGetEmployees.rejected,
          actionEmployeesMonitorRefreshEmployees.rejected,
        ),
        (state, action) => {
          state.isLoading = false;
          state.error = action.error;
          state.isInit = true;
        },
      );
  },
});
export const {
  actionEmployeesMonitorStartPolling,
  actionEmployeesMonitorEndPolling,
  actionEmployeesMonitorUpdateRerender,
} = slice.actions;
export const employeesMonitorReducer = slice.reducer;
