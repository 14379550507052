import { API_TODO_TASKS } from 'services/todo-tasks';
import React from 'react';
import {
  createFilterContains,
  createFilterNumberEquals,
  createFilterSmartSearch,
  createFilterValueArrayEquals,
  mergeFilters,
} from 'utils/dynamic-helpers';
import { IDataGridCellProps } from 'components/data-grid/model';
import { ColumnAction, FilterAssign, FilterDoneTasks } from './components';
import style from './index.module.scss';
import {
  ASSIGNED,
  Model,
  calcAssigned,
  calcDoneStatus,
  DONE_STATUS,
  STATUS_DONE_BUTTON_SWITCHER,
  ASSIGN_BUTTON_SWITCHER,
} from './helpers';
import FilterSelectWithColoredIcon from 'components/app-grid/filter-select-with-colored-icon';
import { ITaskCategoryList } from 'services/todo-task-categories';
import {
  ColumnActivities,
  ColumnNotebook,
  ColumnTaskAssignedTo,
  ColumnTaskDone,
  ColumnTaskCategory,
} from 'components/dialog-task';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { permissionKey } from 'services/user-employee-profile-permissions';
import { excelTemplateDate } from 'utils/dates';
import { TemplateDate } from 'components/templates/template-date';
import { EditorEntryDate, EditorMessage } from 'components/dialog-task/components';

const calcCellProps: IDataGridCellProps<Model> = ({ row }) => {
  return { style: { backgroundColor: 'transparent' } };
};

interface Options {
  userPatientProfileID: string;
  appUserID: string;
  onDoneTask: () => void;
  onRefresh: () => void;
  taskCategoryList: ITaskCategoryList[];
  notDoneTasks?: number;
}

export default ({
  userPatientProfileID,
  onDoneTask,
  onRefresh,
  appUserID,
  taskCategoryList,
  notDoneTasks,
}: Options) => ({
  store: {
    get: {
      url: API_TODO_TASKS.GET_ALL_DYNAMIC,
      filters: [`userPatientProfileID=="${userPatientProfileID}"`].filter(Boolean).join('&&'),
      selected: [
        `userPatientProfileID`,
        'sentByUserEmployeeProfileID',
        'toDoTaskCategory.actionKey as actionKey',
      ].join(','),
    },
    delete: {
      url: API_TODO_TASKS.DELETE,
    },
    patch: {
      url: API_TODO_TASKS.PATCH,
    },
    sortable: {
      field: 'entryDate',
      order: 'desc',
    },
  },

  toolbar: {
    title: '',
    excelTitle: 'todo-tasks',
    filters: [
      {
        field: 'text',
        options: {
          type: 'string',
          label: 'text',
          valueTemplate: ({ value }: any) =>
            mergeFilters(
              createFilterContains<Components.Schemas.ToDoTask>(
                ['message', 'details', 'toDoTaskCategory.title'],
                value,
              ),
              createFilterSmartSearch<Components.Schemas.ToDoTask>(
                ['sentByUserEmployeeProfile.firstName', 'sentByUserEmployeeProfile.lastName'],
                value,
              ),
              createFilterNumberEquals<Components.Schemas.ToDoTask>('rowIndex', value),
            ).join('||'),
        },
      },
      {
        field: 'toDoTaskCategoryID',
        options: {
          template: FilterSelectWithColoredIcon,
          multiple: true,
          type: 'component',
          label: 'category',
          source: taskCategoryList,
          valueTemplate: ({ field, value }: { field: keyof Model; value: string[] }) =>
            createFilterValueArrayEquals<Model>(field, value),
          option: {
            label: 'title',
            value: 'id',
          },
        },
      },
      {
        field: 'createdDate',
        options: {
          type: 'date-multiple',
          placeholder: 'entry-date',
        },
      },
      {
        field: 'entryDate',
        options: {
          type: 'date-multiple',
          placeholder: 'execution-date',
        },
      },
      {
        field: ASSIGN_BUTTON_SWITCHER,
        options: {
          template: FilterAssign,
          valueTemplate: ({ value }: { value: ASSIGNED }) =>
            calcAssigned({ assigned: value, appUserID }),
          type: 'component',
          value: notDoneTasks ? ASSIGNED.ASSIGN_TO_ME : ASSIGNED.ALL,
        },
      },
      {
        field: STATUS_DONE_BUTTON_SWITCHER,
        options: {
          template: FilterDoneTasks,
          valueTemplate: ({ value }: { value: DONE_STATUS }) =>
            calcDoneStatus({ doneStatus: value }),
          type: 'component',
          value: DONE_STATUS.NOT_DONE,
        },
      },
    ],
    controls: { new: null },
    controlsDefault: {
      new: null,
      resetFilters: null,
      export: null,
    },
  },

  controlsDropDown: [
    { type: 'delete', title: 'delete', permission: permissionKey('isAllowToDeleteAnything') },
  ],
  controls: [
    {
      type: 'custom',
      template: ({ data }: { data: Model }) => <ColumnAction row={data} onDone={onDoneTask} />,
    },
  ],
  columns: [
    {
      title: 'row-index',
      field: 'rowIndex',
      cellProps: calcCellProps,
    },
    {
      title: 'entry-date',
      field: 'createdDate',
      template: (data: Model) => <TemplateDate date={data.createdDate} />,
      valueTemplate: (data: Model) => excelTemplateDate(data.createdDate),
      cellProps: calcCellProps,
      tdClasses: 'min-width-date-time',
    },
    {
      title: 'execution-date',
      field: 'entryDate',
      editor: {
        type: 'custom',
        component: EditorEntryDate,
      },
      template: (data: Model) => <TemplateDate date={data.entryDate} />,
      valueTemplate: (data: Model) => excelTemplateDate(data.entryDate, APP_FORMAT_DATE_TIME),
      cellProps: calcCellProps,
      tdClasses: 'min-width-date-time',
    },
    {
      title: 'activities',
      field: 'toDoTaskActivities.Count() as activitiesTotal',
      template: (data: Model) => <ColumnActivities row={data} onRefresh={onRefresh} />,
      sortable: 'toDoTaskActivities.Count()',
      cellProps: calcCellProps,
    },
    {
      title: 'category',
      field: 'toDoTaskCategory.title as category',
      sortable: 'toDoTaskCategory.title',
      template: (data: Model) => <ColumnTaskCategory row={data} />,
      cellProps: calcCellProps,
    },
    {
      title: '',
      field: 'userPatientProfileSessionID',
      sortable: false,
      template: (data: Model) => (
        <ColumnNotebook
          userPatientProfileID={data.userPatientProfileID}
          userPatientProfileSessionID={data.userPatientProfileSessionID}
        />
      ),
      cellProps: calcCellProps,
    },
    {
      title: 'assigned-by',
      field: 'sentByUserEmployeeProfile.fullName as assignedBy',
      cellProps: calcCellProps,
      sortable: 'sentByUserEmployeeProfile.firstName,sentByUserEmployeeProfile.lastName',
    },
    {
      title: 'assigned-to',
      field:
        'userEmployeeProfileToDoTasks.Select(k => new { k.userEmployeeProfileID as id, k.userEmployeeProfile.fullName }) as assignedTo',
      template: (data: Model) => <ColumnTaskAssignedTo row={data} onRefresh={onRefresh} />,
      cellProps: calcCellProps,
      valueTemplate: (data: Model) => (data.assignedTo || []).map(({ fullName }) => fullName),
      sortable: false,
      tdClasses: style.minWidthTd,
    },
    {
      title: 'message',
      field: 'message',
      editor: {
        type: 'custom',
        component: EditorMessage,
      },
      cellProps: calcCellProps,
      tdClasses: style.minWidthTd,
    },
    {
      title: 'details',
      field: 'details',
      cellProps: calcCellProps,
      tdClasses: style.minWidthTd,
    },
    {
      title: 'done',
      field: 'done',
      template: (data: Model) => <ColumnTaskDone row={data} onRefresh={onRefresh} />,
      cellProps: calcCellProps,
    },
    {
      title: 'close-date',
      field: 'closeDate',
      template: (data: Model) => <TemplateDate date={data.closeDate} />,
      cellProps: calcCellProps,
    },
    {
      title: 'close-employee',
      field: 'closedByUserEmployeeProfile.fullName as closeEmployeeFullName',
      sortable: 'closedByUserEmployeeProfile.firstName',
      cellProps: calcCellProps,
    },
  ],
});
