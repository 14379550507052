import React, { memo, useCallback, useMemo } from 'react';
import { FormControl, FormControlProps, FormLabel } from '@material-ui/core';
import JoditEditor from 'jodit-react';
import { HtmlEditorConfig, HtmlEditorConfigDefault } from './models';
import style from './index.module.scss';
import clsx from 'clsx';

export * from './models';

export interface Props extends Omit<FormControlProps, 'value' | 'onBlur' | 'onChange'> {
  label?: string;
  value?: string;
  onChange?: (v: string) => void;
  onBlur?: (v: string) => void;
  error?: boolean;
  config?: Partial<HtmlEditorConfig>;
  disabled?: boolean;
  direction?: string;
  readOnly?: boolean;
}

export const HtmlEditor = memo<Props>((props) => {
  const { value, onChange, onBlur, error, label, config, disabled, direction, readOnly, ...rest } =
    props;

  const joditConfig = useMemo(() => {
    return { ...HtmlEditorConfigDefault, ...config, disabled, direction, readonly: readOnly };
  }, [config, disabled, direction, readOnly]);

  const replaceNBSP = Boolean(joditConfig.cleanHTML?.replaceNBSP);

  const decorateNBSP = useCallback(
    (handler?: (v: string) => void) => {
      if (!handler) return handler;
      return (v: string) =>
        replaceNBSP ? handler(String(v).replace(/&nbsp;/gi, ' ')) : handler(v);
    },
    [replaceNBSP],
  );

  return (
    <FormControl error={error} {...rest} className={clsx(style.root, rest?.className)}>
      {label && <FormLabel>{label}</FormLabel>}
      <JoditEditor
        value={value || ''}
        config={joditConfig}
        onChange={decorateNBSP(onChange)}
        onBlur={decorateNBSP(onBlur)}
      />
    </FormControl>
  );
});
