import { DynamicService } from 'utils/service';
import {
  API_USER_PATIENT_PROFILE_PRESCRIPTIONS,
  CompleteMedicalPrescriptionInput,
  CreateNewInput,
  CreateNewLatestSession,
  DataForPatientEmailOutput,
  GenerateNotebookInput,
  GetMedicalPrescriptionLatestData,
  GetMedicalPrescriptionsWithDrugsItem,
  IMedicalPrescriptionWizardDataItem,
  iUserPatientProfilePrescription,
  MakeWizardSignedDocumentValidationInput,
  MakeWizardValidityItemsDraftInput,
  MakeWizardValidityItemsSignInput,
  MakeWizardValidityItemsSignOutput,
  MedicalPrescriptionDetailForNeopharm,
  MedicalPrescriptionDetailsForUserCopy,
  MedicalPrescriptionHoldInput,
  MedicalPrescriptionTitle,
  PdfDataOutput,
  PrintPatientPrescriptionInput,
  UserPrescriptionGeneratePrescriptionDocumentsInput,
  UserPrescriptionGetActivitiesInput,
  UserPrescriptionGetActivitiesItem,
} from './models';
import { DOCTOR_RAZ_ID } from 'services/user-employee-profiles';
import { ServiceDrugs } from 'services/drug';
import { ServicePatientPrescriptionDetails } from 'services/user-patient-profile-prescription-details';
import { makeFilterDateRange } from 'utils/app-helpers';
import { endOfDay, format } from 'date-fns';
import { convertToDate, dateFormat, isTeenager } from 'utils/dates';
import { ServiceMediaPrivate } from 'services/media-private-services';
import { base64ToFileStream, getFileNameFromUrl } from 'utils/file-uploader';
import { apiRtk, RTK_TAGS } from 'utils/rtk-query';
import { PRESCRIPTION_STEP_TYPE_KEY, ServicePrescriptionSteps } from 'services/prescription-steps';
import { NOTEBOOK_INDEXES, ServiceNotebooks } from 'services/notebook';
import { PatchPartial } from 'utils/types';
import { ServiceUserPatientProfileSessions } from 'services/user-patient-profile-session';
import * as dynamic from 'utils/dynamic-helpers';
import { ServiceUserPatientProfileSessionClinicDrugs } from 'services/user-patient-profile-session-clinic-drugs';
import { APP_FORMAT_DATE } from 'configs/const';
import { FormDocumentCategoryType } from '../form-document-categories';
import { apiUserPatientProfilePrescriptionActivities } from '../user-patient-profile-prescription-activities';
import { i18nAppTranslator } from 'modules/i18n';
import { createFilterDateLessISO, createFilterEquals } from 'utils/dynamic-helpers';
import { downloadBlob } from 'utils/download';
import { SELECT_PATIENT_CARD_INFO } from '../user-patient-profile';

export * from './models';
class Service extends DynamicService<iUserPatientProfilePrescription> {
  async getPdfData(userPatientProfilePrescriptionID: string) {
    const params = {
      select: [
        `new { 
          userEmployeeProfile.fullName, userEmployeeProfile.licenseNumber,
          userEmployeeProfile.signature, userEmployeeProfile.appIdentityUserID
        } as doctor`,
        `new { 
          userPatientProfile.fullName, userPatientProfile.idNumber,
          userPatientProfile.city.title as city, userPatientProfile.mobilePhone,
          userPatientProfile.appIdentityUserID,
          userPatientProfile.dateOfBirth
        } as patient`,
        'entryDate',
        `userPatientProfilePrescriptionDetails
                .Select(s => new {
                  s.drug.drugNameEng as drugName,s.dosageForm,s.dosageFormTotalDesc as dosageFormTotal,s.dosageFormDaily,s.instructions
                }) as drugs`,
        'remarks',
      ]
        .join(',')
        .replace(/  +/g, ' '),
    };

    return await ServicePatientPrescription.getDynamic<PdfDataOutput>(
      userPatientProfilePrescriptionID,
      params,
    );
  }
  getDataForPatientEmail = async (userPatientProfilePrescriptionID: string) => {
    const params = {
      select: dynamic.select(
        'id',
        'userPatientProfileID',
        'entryDate',
        `new { 
          userPatientProfile.email,
          userPatientProfile.idNumber,
          userPatientProfile.fullName,
        } as patient`,
        'prescriptionFilePath',
      ),
    };

    return await ServicePatientPrescription.getDynamic<DataForPatientEmailOutput>(
      userPatientProfilePrescriptionID,
      params,
    );
  };

  generateNotebook = async (input: GenerateNotebookInput) => {
    const { userPatientProfilePrescriptionID, userEmployeeProfileID } = input;
    const {
      data: { value },
    } = await ServiceNotebooks.getAllDynamic({
      select: ['id'].join(','),
      filter: 'isForPrescriptionOnly==true',
      take: 1,
    });

    const notebook = value[0];

    if (!value) {
      return '';
    }

    const { data: prescription } = await this.getDynamic(userPatientProfilePrescriptionID, {
      select: [
        'id',
        'userPatientProfileID',
        'userPatientProfilePrescriptionDetails.Where(d=>d.userPatientProfileSessionClinicDrugID==null).Select(d => new { d.id, d.drugID, d.dosageForm }) as userPatientProfilePrescriptionDetails',
      ].join(','),
    });

    if (!prescription.userPatientProfilePrescriptionDetails) {
      return '';
    }

    if (prescription.userPatientProfilePrescriptionDetails.length === 0) {
      return '';
    }

    const { data: session } = await ServiceUserPatientProfileSessions.post({
      userPatientProfileID: prescription.userPatientProfileID,
      userEmployeeProfileID: userEmployeeProfileID,
      notebookID: notebook.id,
    });

    await Promise.all(
      prescription.userPatientProfilePrescriptionDetails.map(async (detail) => {
        const { data } = await ServiceUserPatientProfileSessionClinicDrugs.post({
          userPatientProfileSessionID: session.id,
          drugID: detail.drugID,
          dosageForm: detail.dosageForm,
        });

        await ServicePatientPrescriptionDetails.patch({
          id: String(detail.id),
          userPatientProfileSessionClinicDrugID: data.id,
        });
      }),
    );

    return 'done';
  };

  createNew = async (payload: CreateNewInput) => {
    const { userPatientProfileID, remarks } = payload;

    const resultStep = await ServicePrescriptionSteps.getByKey(PRESCRIPTION_STEP_TYPE_KEY.DRAFT);

    const resultCreated = await this.post({
      isActive: true,
      userPatientProfileID,
      prescriptionStepID: resultStep.data.id,
      entryDate: new Date().toISOString(),
      userEmployeeProfileID: DOCTOR_RAZ_ID,
      remarks,
    });

    // load the latest session
    const resultLatestSession =
      await ServiceUserPatientProfileSessions.getDynamicLatest<CreateNewLatestSession>({
        filter: dynamic
          .mergeFilters(
            `userPatientProfileID=="${userPatientProfileID}"`,
            'userPatientProfileSessionClinicDrugs.Where(d=>d.isActive).Count() > 0',
          )
          .join('&&'),
        select: [
          'id',
          'userPatientProfileSessionClinicDrugs.Where(d=>d.isActive).Select(d=> new { d.drugID, d.dosageForm }) as userPatientProfileSessionClinicDrugs',
        ].join(','),
      });

    if (resultLatestSession.data) {
      await Promise.all(
        (resultLatestSession.data.userPatientProfileSessionClinicDrugs || []).map(
          async (detailsItem) => {
            const drugID = detailsItem.drugID || '';
            const { data } = await ServiceDrugs.get(drugID);

            return ServicePatientPrescriptionDetails.create({
              drugID,
              dosageForm: data.defaultDosageForm || '',
              instructions: data.defaultInstructions || '',
              dosageFormDaily: data.defaultDosageFormDaily || '',
              dosageFormTotal: parseInt(data.defaultDosageFormTotal || '') || 0,
              dosageFormTotalDesc: data.defaultDosageFormTotalDesc || '',
              isActive: true,
              includeForm29: false,
              userPatientProfilePrescriptionID: resultCreated.data.id,
            });
          },
        ),
      );
    }

    return resultCreated;
  };

  generatePrescriptionDocuments = async (
    input: UserPrescriptionGeneratePrescriptionDocumentsInput,
  ) => {
    const { id, pdfWithWatermark, pdfPrint, pdfWithoutWatermark, entryDate, patientFullName } =
      input;
    const [
      {
        data: { filePath: prescriptionWatermarkFilePath },
      },
      {
        data: { filePath: prescriptionFilePath },
      },
      {
        data: { filePath: prescriptionPrintFilePath },
      },
    ] = await Promise.all([
      ServiceMediaPrivate.uploadFile({
        fileStreamString: base64ToFileStream(pdfWithWatermark),
        fileName: Service.makeFileName({
          prescriptionID: id,
          entryDate,
          patientFullName,
          isWatermark: true,
          isPrint: false,
        }),
      }),
      ServiceMediaPrivate.uploadFile({
        fileStreamString: base64ToFileStream(pdfWithoutWatermark),
        fileName: Service.makeFileName({
          prescriptionID: id,
          entryDate,
          patientFullName,
          isWatermark: false,
          isPrint: false,
        }),
      }),
      ServiceMediaPrivate.uploadFile({
        fileStreamString: base64ToFileStream(pdfPrint),
        fileName: Service.makeFileName({
          prescriptionID: id,
          entryDate,
          patientFullName,
          isWatermark: false,
          isPrint: true,
        }),
      }),
    ]);

    await this.patch({
      id,
      prescriptionFilePath,
      prescriptionWatermarkFilePath,
      prescriptionPrintFilePath,
    });

    return {
      data: { id, prescriptionWatermarkFilePath, prescriptionFilePath, prescriptionPrintFilePath },
    };
  };
  getActivities = async (input: UserPrescriptionGetActivitiesInput) => {
    const { userPatientProfileID, dateRange } = input;
    const {
      data: { value },
    } = await this.getAllDynamic<UserPrescriptionGetActivitiesItem>({
      select: [
        'id',
        'entryDate',
        'userPatientProfilePrescriptionDetails.Select(s=> new  { s.drug.catalogName, s.dosageFormTotal, s.dosageFormDaily }) as drugs',
        'userEmployeeProfile.fullName as employee',
        'prescriptionFilePath',
        'userPatientProfilePrescriptionActivities.Count() as activities',
      ].join(','),
      filter: [
        'prescriptionFilePath!=null',
        `userPatientProfileID=="${userPatientProfileID}"`,
        makeFilterDateRange('entryDate', dateRange),
      ]
        .filter(Boolean)
        .join('&&'),
      orderBy: 'entryDate desc',
    });

    return value.map((item) => ({
      id: String(item.id),
      title: item.drugs
        .map((detail) => {
          return [detail.catalogName, detail.dosageFormTotal, detail.dosageFormDaily]
            .filter(Boolean)
            .join(' | ');
        })
        .sort()
        .join(', '),
      date: item.entryDate,
      employee: item.employee,
      download: item.prescriptionFilePath,
      activities: item.activities,
    }));
  };

  makeWizardValidityItemsDraft = (input: MakeWizardValidityItemsDraftInput) => {
    const { paymentDate, healthSurveyCount } = input;

    return [
      {
        labelKey: 'filled-health-survey',
        value: String(healthSurveyCount || 0),
        isValid: Boolean(healthSurveyCount),
      },
      {
        labelKey: 'payment-date',
        value: dateFormat(paymentDate, APP_FORMAT_DATE),
        isValid: Boolean(paymentDate),
      },
    ];
  };

  private makeWizardSignedDocumentListOfRequiredDocuments = (
    input: MakeWizardSignedDocumentValidationInput,
  ) => {
    const { prescriptionDrugs, systemDocuments, patient } = input;

    const isPatientTeenager = patient.dateOfBirth ? isTeenager(patient.dateOfBirth) : false;

    return systemDocuments
      .map((doc) => {
        const details = doc.drugIDs
          .map((drugID) => {
            return prescriptionDrugs.filter((detail) => detail.id === drugID);
          })
          .flat();

        return { ...doc, details };
      })
      .filter(({ details }) => details.length !== 0)
      .filter((doc) => {
        if (doc.isUnder18 && !isPatientTeenager) return false;
        if (doc.isUnder18 && isPatientTeenager) return true;

        if (!doc.isUnder18 && isPatientTeenager) return false;
        if (!doc.isUnder18 && !isPatientTeenager) return true;

        return true;
      });
  };
  makeWizardValidityItemsSigned = (
    input: MakeWizardValidityItemsSignInput,
  ): MakeWizardValidityItemsSignOutput[] => {
    const { patientSignedDocs, prescriptionDocs, ...rest } = input;

    const patientSignedDocsGeneral = patientSignedDocs.filter(
      ({ type }) => type === FormDocumentCategoryType.GENERAL,
    );
    const patientSignedDocsContract = patientSignedDocs.filter(
      ({ type }) => type === FormDocumentCategoryType.CONTRACT,
    );

    const signedPrescriptionDocs = prescriptionDocs.filter(({ signedDate }) => signedDate);

    const docsMustBeSigned = this.makeWizardSignedDocumentListOfRequiredDocuments(rest).map(
      (doc) => {
        const signed = patientSignedDocs.filter((patDoc) => patDoc.id === doc.id);

        return { ...doc, signedCount: signed.length };
      },
    );

    return [
      ...docsMustBeSigned.map((doc) => {
        return {
          type: 'doc' as const,
          docTitle: doc.title,
          drugs: doc.details.map(({ catalogName }) => catalogName).join(' | '),
          value: doc.signedCount,
          isValid: doc.signedCount > 0,
        };
      }),
      {
        type: 'regular' as const,
        labelKey: 'document-category-general',
        value: String(patientSignedDocsGeneral.length),
        isValid: patientSignedDocsGeneral.length > 0,
      },
      {
        type: 'regular' as const,
        labelKey: 'document-category-contract',
        value: String(patientSignedDocsContract.length),
        isValid: patientSignedDocsContract.length > 0,
      },
      {
        type: 'regular' as const,
        labelKey: 'signed',
        value: String(signedPrescriptionDocs.length),
      },
      {
        type: 'regular' as const,
        labelKey: 'unsigned',
        value: String(prescriptionDocs.length - signedPrescriptionDocs.length),
      },
      {
        type: 'regular' as const,
        labelKey: 'total',
        value: String(prescriptionDocs.length),
        isValid: prescriptionDocs.length === signedPrescriptionDocs.length,
      },
    ];
  };

  static makeFileName = (options: {
    prescriptionID: string;
    patientFullName: string;
    entryDate: string;
    isWatermark: boolean;
    isPrint: boolean;
  }) => {
    const { prescriptionID, entryDate, patientFullName, isWatermark, isPrint } = options;
    return [
      prescriptionID,
      format(convertToDate(entryDate), 'dd_MM_yyyy'),
      isWatermark && 'wm',
      isPrint && 'print',
      patientFullName,
      '.pdf',
    ]
      .filter(Boolean)
      .join('__');
  };

  printPrescription = async (input: PrintPatientPrescriptionInput) => {
    const filePath = input.url;

    if (!filePath) {
      throw new Error('url: is required');
    }
    const fileName = getFileNameFromUrl(filePath);
    const { data } = await ServiceMediaPrivate.getFile(filePath);

    await this.patch({ id: input.userPatientProfilePrescriptionID, isPrint: true });

    downloadBlob(data, { fileName });
  };
}

export const ServicePatientPrescription = new Service({
  mainField: 'id',
  getAll: API_USER_PATIENT_PROFILE_PRESCRIPTIONS.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_PRESCRIPTIONS.POST,
  patch: API_USER_PATIENT_PROFILE_PRESCRIPTIONS.PATCH,
  delete: API_USER_PATIENT_PROFILE_PRESCRIPTIONS.DELETE,
});

export const apiUserPatientPrescriptions = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getMedicalPrescriptionWithDetails: builder.query({
      queryFn: async (uerPatientProfilePrescriptionID: string) => {
        const { data } =
          await ServicePatientPrescription.getDynamic<GetMedicalPrescriptionLatestData>(
            uerPatientProfilePrescriptionID,
            {
              select: [
                'id',
                'entryDate',
                'userPatientProfilePrescriptionDetails.Select(d=> new { d.id, d.drug.catalogName, d.dosageFormTotalDesc, d.dosageFormDaily, d.instructions, d.includeForm29 }) as details',
              ].join(','),
            },
          );
        return { data };
      },
      keepUnusedDataFor: 25,
    }),
    getMedicalPrescriptionTitle: builder.query({
      queryFn: async (id: string) => {
        try {
          const { data } = await ServicePatientPrescription.getDynamic(id, {
            select: dynamic.select(
              'entryDate',
              'userPatientProfile.idNumber',
              'userPatientProfile.maintenanceWeight',
              SELECT_PATIENT_CARD_INFO('userPatientProfile'),
            ),
          });
          return { data: data as unknown as MedicalPrescriptionTitle };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getMedicalPrescriptionWizardData: builder.query({
      queryFn: async (id: string) => {
        return ServicePatientPrescription.getDynamic<IMedicalPrescriptionWizardDataItem>(id, {
          select: [
            'id',
            'userPatientProfileID',
            'prescriptionStepID',
            'prescriptionFilePath',
            'pharmacyFollowupID',
            'prescriptionWatermarkFilePath',
            'prescriptionPrintFilePath',
            'entryDate',
            'isCompleted',
            'isPrint',
            'isSent',
            'new { prescriptionStep.stepTypeKey } as prescriptionStep',
            'new { userPatientProfile.fullName, userPatientProfile.firstName, userPatientProfile.lastName, userPatientProfile.mobilePhone, userPatientProfile.dateOfBirth } as userPatientProfile',
            'new { userEmployeeProfile.fullName, userEmployeeProfile.userPhoto } as userEmployeeProfile',
            `userPatientProfile.userPatientProfileSessions.Where(s=>s.notebook.rowIndex==${NOTEBOOK_INDEXES.QUESTIONNAIRE_EN}||s.notebook.rowIndex==${NOTEBOOK_INDEXES.QUESTIONNAIRE_HE}).Count() as healthSurveyCount`,
            'userPatientProfile.userPatientProfileSubscriptions.Where(s=>DateTime.Now >= s.startDate && DateTime.Now <= s.endDate).Select(s => new { s.id, s.startDate, s.endDate, s.paymentDate, s.subscription.labelKey, s.subscription.durationMonths }).FirstOrDefault() as subscription',
            'userPatientProfileFormDocuments.Select(d=> new { d.signedDate }) as userPatientProfileFormDocuments',
            'userPatientProfilePrescriptionDetails.Select(d=> new { d.id, d.drug.catalogName, d.includeForm29, d.drugID }) as userPatientProfilePrescriptionDetails',
          ].join(','),
        });
      },
      providesTags: (_, __, arg) => [{ type: RTK_TAGS.PATIENT_PRESCRIPTION, id: arg }],
    }),
    getMedicalPrescriptionsWithDrugs: builder.query({
      queryFn: async (userPatientProfileID: string) => {
        try {
          const res =
            await ServicePatientPrescription.getAllDynamic<GetMedicalPrescriptionsWithDrugsItem>({
              select: [
                'id',
                'entryDate',
                'isCompleted',
                'userPatientProfilePrescriptionDetails.Select(d=> new { d.id, d.drug.drugNameEng, d.drug.catalogName }) as drugs',
              ].join(','),
              filter: dynamic
                .mergeFilters(
                  dynamic.createFilterEquals('userPatientProfileID', userPatientProfileID),
                  'isActive==true',
                  'userPatientProfilePrescriptionDetails.Count() > 0',
                )
                .join('&&'),
              orderBy: 'entryDate desc',
            });
          return { data: res.data.value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, arg) => [{ type: RTK_TAGS.PATIENT_PRESCRIPTION, id: arg }],
    }),
    getMedicalPrescriptionDetailsForUserCopy: builder.query<
      MedicalPrescriptionDetailsForUserCopy,
      string
    >({
      queryFn: async (userPatientProfilePrescriptionID) => {
        try {
          const filterDoc = dynamic
            .mergeFilters(
              dynamic.createFilterContains(
                'd.documentURL',
                `prs__${userPatientProfilePrescriptionID}`,
              ),
              dynamic.createFilterEquals('isActive', true),
            )
            .join('&&');

          const { data } =
            await ServicePatientPrescription.getDynamic<MedicalPrescriptionDetailsForUserCopy>(
              userPatientProfilePrescriptionID,
              {
                select: dynamic.select(
                  'id',
                  'userPatientProfileID',
                  'entryDate',
                  `new { 
                    userPatientProfile.email,
                    userPatientProfile.idNumber,
                    userPatientProfile.fullName,
                  } as patient`,
                  `userPatientProfile.userPatientProfileDocuments.Where(d => ${filterDoc}).Select(d => new { d.id, d.fileName, d.documentURL, d.isForm29 }) as documents`,
                  'prescriptionWatermarkFilePath as prescriptionURL',
                ),
              },
            );
          return { data: data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getMedicalPrescriptionsHoldExpired: builder.query<
      Array<Pick<iUserPatientProfilePrescription, 'id'>>,
      void
    >({
      queryFn: async () => {
        const end = endOfDay(new Date());
        try {
          const {
            data: { value },
          } = await ServicePatientPrescription.getAllDynamic<
            Pick<iUserPatientProfilePrescription, 'id'>
          >({
            select: dynamic.select('id'),
            filter: dynamic
              .mergeFilters(
                createFilterEquals('isActive', true),
                createFilterEquals('isOnHold', true),
                createFilterDateLessISO('onHoldDate', end),
              )
              .join('&&'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (res) => [
        ...(res
          ? res.map(({ id }) => {
              return { type: RTK_TAGS.PATIENT_PRESCRIPTION, id };
            })
          : []),
        { type: RTK_TAGS.PATIENT_PRESCRIPTION, id: 'HOLD' },
      ],
    }),

    getMedicalPrescriptionDetailsForNeopharm: builder.query<
      MedicalPrescriptionDetailForNeopharm[],
      string
    >({
      queryFn: async (userPatientProfilePrescriptionID) => {
        try {
          const { data } = await ServicePatientPrescription.getDynamic(
            userPatientProfilePrescriptionID,
            {
              select: dynamic.select(
                'id',
                `userPatientProfilePrescriptionDetails
                  .Select(d => new {
                      d.dosageFormTotalDesc,
                      d.drugID,
                      new {
                        drug.drugNameEng,
                        drug.pharmacyDrugs
                        .Select(pd => new {
                           pd.id,
                           pd.pharmacyDrugQuantities
                           .Select(q => new {
                                q.id,
                                q.quantity,
                                q.dosageFormTotalDesc
                         }) as pharmacyDrugQuantities
                       }) as pharmacyDrugs
                      } as drug
                   }) as userPatientProfilePrescriptionDetails`,
              ),
            },
          );

          const { userPatientProfilePrescriptionDetails = [] } = data;

          const details = (userPatientProfilePrescriptionDetails || []).map((detail) => {
            const pharmacyDrugs = detail.drug?.pharmacyDrugs || [];
            const quantities = pharmacyDrugs
              .map((pd) =>
                (pd.pharmacyDrugQuantities || []).map((pdq) => ({
                  id: String(pdq.id),
                  dosageFormTotalDesc: String(pdq.dosageFormTotalDesc),
                  quantity: Number(pdq.quantity),
                })),
              )
              .flat();
            const pharmacyDrug = pharmacyDrugs[0];

            return {
              drugID: String(detail.drugID),
              drugName: String(detail.drug?.drugNameEng),
              dosageFormTotalDesc: String(detail.dosageFormTotalDesc),
              pharmacyDrug: pharmacyDrug ? { id: String(pharmacyDrug.id) } : null,
              quantities,
            };
          });

          return { data: details };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: RTK_TAGS.PATIENT_PRESCRIPTION, id: 'neopharm-details' }],
    }),
    patchMedicalPrescription: builder.mutation<
      void,
      PatchPartial<iUserPatientProfilePrescription, 'id'>
    >({
      queryFn: async (input) => {
        try {
          await ServicePatientPrescription.patch(input);

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (_, __, arg) => [{ type: RTK_TAGS.PATIENT_PRESCRIPTION, id: arg.id }],
    }),
    holdMedicalPrescription: builder.mutation<void, MedicalPrescriptionHoldInput>({
      queryFn: async (input, { dispatch }) => {
        try {
          const tp = i18nAppTranslator.tp;
          await dispatch(
            apiUserPatientPrescriptions.endpoints.patchMedicalPrescription.initiate({
              id: input.id,
              onHoldDate: input.onHoldDate,
              isOnHold: true,
            }),
          ).unwrap();
          const promiseAuto = dispatch(
            apiUserPatientProfilePrescriptionActivities.endpoints.createUserPatientProfilePrescriptionActivity.initiate(
              {
                userPatientProfilePrescriptionID: input.id,
                remarks: tp('activity-patient-prescription-on-hold', {
                  date: dateFormat(input.onHoldDate),
                }),
              },
            ),
          );
          const promiseRemark = dispatch(
            apiUserPatientProfilePrescriptionActivities.endpoints.createUserPatientProfilePrescriptionActivity.initiate(
              {
                userPatientProfilePrescriptionID: input.id,
                remarks: input.remark,
              },
            ),
          );

          await Promise.all([promiseAuto, promiseRemark]);

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (_, __, arg) => [
        { type: RTK_TAGS.PATIENT_PRESCRIPTION, id: arg.id },
        { type: RTK_TAGS.PATIENT_PRESCRIPTION, id: 'HOLD' },
      ],
    }),
    unHoldMedicalPrescription: builder.mutation<
      void,
      PatchPartial<iUserPatientProfilePrescription, 'id'>
    >({
      queryFn: async (input, { dispatch }) => {
        try {
          const tp = i18nAppTranslator.tp;
          await dispatch(
            apiUserPatientPrescriptions.endpoints.patchMedicalPrescription.initiate({
              id: input.id,
              onHoldDate: null,
              isOnHold: false,
            }),
          ).unwrap();
          await dispatch(
            apiUserPatientProfilePrescriptionActivities.endpoints.createUserPatientProfilePrescriptionActivity.initiate(
              {
                userPatientProfilePrescriptionID: input.id,
                remarks: tp('activity-patient-prescription-un-hold'),
              },
            ),
          );

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (_, __, arg) => [{ type: RTK_TAGS.PATIENT_PRESCRIPTION, id: arg.id }],
    }),
    unHoldMedicalPrescriptionThatExpired: builder.mutation<{ id: string }[], void>({
      queryFn: async (_, { dispatch }) => {
        try {
          const end = endOfDay(new Date());
          const {
            data: { value: values },
          } = await ServicePatientPrescription.getAllDynamic({
            select: 'id',
            filter: dynamic
              .mergeFilters(
                createFilterEquals('isActive', true),
                createFilterEquals('isOnHold', true),
                createFilterDateLessISO('onHoldDate', end),
              )
              .join('&&'),
          });

          const promises = values.map(({ id }) => {
            return dispatch(
              apiUserPatientPrescriptions.endpoints.unHoldMedicalPrescription.initiate({ id }),
            ).unwrap();
          });

          await Promise.all(promises);

          return { data: values };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res, __, arg) =>
        res
          ? res.map(({ id }) => {
              return { type: RTK_TAGS.PATIENT_PRESCRIPTION, id: id };
            })
          : [],
    }),

    printPatientPrescription: builder.mutation({
      queryFn: async (input: PrintPatientPrescriptionInput, { dispatch }) => {
        try {
          dispatch(
            apiUserPatientProfilePrescriptionActivities.endpoints.createUserPatientProfilePrescriptionActivity.initiate(
              {
                userPatientProfilePrescriptionID: input.userPatientProfilePrescriptionID,
                remarks:
                  input.remarks ||
                  i18nAppTranslator.tp('activity-patient-prescription-print-prescription'),
              },
            ),
          );
          await ServicePatientPrescription.printPrescription(input);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (_, __, arg) => [
        { type: RTK_TAGS.PATIENT_PRESCRIPTION, id: arg.userPatientProfilePrescriptionID },
      ],
    }),
    completeMedicalPrescription: builder.mutation<void, CompleteMedicalPrescriptionInput>({
      queryFn: async (input, { dispatch }) => {
        try {
          await ServicePatientPrescription.patch({
            id: input.userPatientProfilePrescriptionID,
            isCompleted: true,
            completedDate: new Date().toISOString(),
          });

          await dispatch(
            apiUserPatientProfilePrescriptionActivities.endpoints.createUserPatientProfilePrescriptionActivity.initiate(
              {
                userPatientProfilePrescriptionID: input.userPatientProfilePrescriptionID,
                remarks: input.remarks,
              },
            ),
          ).unwrap();

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (_, __, arg) => [
        { type: RTK_TAGS.PATIENT_PRESCRIPTION, id: arg.userPatientProfilePrescriptionID },
      ],
    }),
  }),
});
