import update from 'immutability-helper';
import {
  PdfDocumentSubmit,
  PdfDocumentSubmitActionTypes,
  PdfDocumentSubmitPageProps,
} from './@type';
import { FormDocumentInput } from 'services/form-document-inputs';

export const pdfDocumentSubmitReset = () => {
  return { type: PdfDocumentSubmitActionTypes.PDF_DOCUMENTS_SUBMIT_RESET } as const;
};

export const pdfDocumentSubmitInit = (payload: { id: string }) => {
  return { type: PdfDocumentSubmitActionTypes.PDF_DOCUMENTS_SUBMIT_INIT, payload } as const;
};

export const pdfDocumentSubmitMerge = (payload: Partial<PdfDocumentSubmit>) => {
  return { type: PdfDocumentSubmitActionTypes.PDF_DOCUMENTS_SUBMIT_MERGE, payload } as const;
};

export const pdfDocumentSubmitSetPageSize = (payload: PdfDocumentSubmitPageProps) => {
  return {
    type: PdfDocumentSubmitActionTypes.PDF_DOCUMENTS_SUBMIT_SET_PAGE_SIZE,
    payload,
  } as const;
};

export const pdfDocumentSubmitPageInputModify = (payload: Partial<FormDocumentInput>) => {
  return {
    type: PdfDocumentSubmitActionTypes.PDF_DOCUMENTS_SUBMIT_PAGE_INPUT_MODIFY,
    payload,
  } as const;
};

export const pdfDocumentSubmitScrollToInput = (payload: string) => {
  return {
    type: PdfDocumentSubmitActionTypes.PDF_DOCUMENTS_SUBMIT_SCROLL_TO_INPUT,
    payload,
  } as const;
};

export const pdfDocumentSubmitSave = (payload: string) => {
  return { type: PdfDocumentSubmitActionTypes.PDF_DOCUMENTS_SUBMIT_SAVE, payload } as const;
};

type PdfDocumentSubmitActions = ReturnType<
  | typeof pdfDocumentSubmitReset
  | typeof pdfDocumentSubmitInit
  | typeof pdfDocumentSubmitMerge
  | typeof pdfDocumentSubmitSetPageSize
  | typeof pdfDocumentSubmitPageInputModify
  | typeof pdfDocumentSubmitScrollToInput
  | typeof pdfDocumentSubmitSave
>;

export const initialState = (): PdfDocumentSubmit => ({
  // flags
  init: false,
  loading: false,
  isReadyToSubmit: false,
  // data
  initData: null,
  // pdf
  scale: 1.3,
  width: 500,
  pages: [],
  // event
  eventScrollTo: null,
  eventFocusItem: null,
  // trigger
  triggerSubmit: '',
});

export const pdfDocumentSubmitReducer = (
  state = initialState(),
  action: PdfDocumentSubmitActions,
) => {
  switch (action.type) {
    case PdfDocumentSubmitActionTypes.PDF_DOCUMENTS_SUBMIT_RESET: {
      return initialState();
    }
    case PdfDocumentSubmitActionTypes.PDF_DOCUMENTS_SUBMIT_MERGE: {
      return update(state, { $merge: action.payload });
    }
    case PdfDocumentSubmitActionTypes.PDF_DOCUMENTS_SUBMIT_SET_PAGE_SIZE: {
      const { index, width, height } = action.payload;
      if (state.pages[index]) {
        return update(state, {
          width: { $set: Math.max(width, state.width) },
          pages: { [index]: { $merge: { width, height } } },
        });
      } else {
        return state;
      }
    }
    case PdfDocumentSubmitActionTypes.PDF_DOCUMENTS_SUBMIT_PAGE_INPUT_MODIFY: {
      const { id } = action.payload;
      const inputIndex = state.initData?.formDocumentInputs?.findIndex((item) => item.id === id);
      if (![undefined, -1].includes(inputIndex)) {
        return update(state, {
          initData: {
            formDocumentInputs: {
              [inputIndex as any]: { $merge: action.payload },
            },
          },
        });
      } else {
        return state;
      }
    }
    default:
      return state;
  }
};
