import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { Button, Checkbox, Dialog, DialogActions, Tooltip } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { Loading } from 'AurionCR/components';
import {
  DataGridLight,
  DataGridEmpty,
  createColumn,
  renderColumnBoolean,
} from 'components/data-grid-light';
import { isRequestSuccess, useMountedRef, useRequest, useRequestAlertError } from 'hooks';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import {
  ServicePatientPrescriptionDetails,
  PrescriptionDetailLatest,
} from 'services/user-patient-profile-prescription-details';
import { NativeScroll } from 'components/native-scroll';
import { ColumnStatus } from '../column-status';
import { PatchPartial } from 'utils/types';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { useTranslate } from 'hooks/use-translate';
import { TemplateDate } from 'components/templates/template-date';

interface Classes {
  root: string;
}

interface Model extends PrescriptionDetailLatest {
  _isLoading: boolean;
  _isChecked: boolean;
  _isDone: boolean;
  _error: null | Error;
}

interface Props {
  className?: string;
  classes?: Partial<Classes>;
  onClose: () => void;
  onRefresh: () => void;
  userPatientProfileID: string;
  userPatientProfilePrescriptionID: string;
}

interface ReducerState {
  isLoading: boolean;
  isInit: boolean;
  rows: Model[];
}
type ReducerAction =
  | { type: 'set-loading'; payload: boolean }
  | { type: 'set-rows'; payload: Model[] }
  | { type: 'change-checked-all'; payload: boolean }
  | { type: 'change-checked-item'; payload: { id: string; checked: boolean } }
  | { type: 'set-item-data'; payload: PatchPartial<Model, 'id'> };

const reducer = (state: ReducerState, action: ReducerAction) => {
  switch (action.type) {
    case 'set-loading':
      return { ...state, isLoading: action.payload };
    case 'set-rows':
      return { ...state, rows: action.payload, isInit: true };
    case 'change-checked-all':
      return {
        ...state,
        rows: state.rows.map((item) => ({ ...item, _isChecked: action.payload })),
      };
    case 'change-checked-item':
      const { id, checked } = action.payload;
      return {
        ...state,
        rows: state.rows.map((item) => (item.id !== id ? item : { ...item, _isChecked: checked })),
      };
    case 'set-item-data': {
      const { id, ...rest } = action.payload;
      return {
        ...state,
        rows: state.rows.map((item) => (item.id !== id ? item : { ...item, ...rest })),
      };
    }
  }
  return state;
};

export const DialogPreviousDrugs: React.FC<Props> = ({
  onClose,
  onRefresh,
  userPatientProfileID,
  userPatientProfilePrescriptionID,
}) => {
  const { t } = useTranslate();

  const [{ isLoading, isInit, rows }, dispatch] = useReducer(reducer, {
    isLoading: false,
    isInit: false,
    rows: [],
  });

  const onChangeAll = useCallback((e: React.ChangeEvent, checked: boolean) => {
    dispatch({ type: 'change-checked-all', payload: checked });
  }, []);
  const factoryOnChangeItem = useCallback((id: string) => {
    return (e: React.ChangeEvent, checked: boolean) =>
      dispatch({ type: 'change-checked-item', payload: { id, checked } });
  }, []);

  const checkedRows = useMemo(() => {
    return rows.filter(({ _isChecked }) => _isChecked);
  }, [rows]);
  const rowsToAdd = useMemo(() => {
    return checkedRows.filter(
      ({ _isLoading, _isDone, _error }) => !_isLoading && !_isDone && !_error,
    );
  }, [checkedRows]);

  const isAllSelected = rows.length > 0 && checkedRows.length === rows.length;

  const setIsLoading = useCallback((value: boolean) => {
    dispatch({ type: 'set-loading', payload: value });
  }, []);
  const setRows = useCallback((value: Model[]) => {
    dispatch({ type: 'set-rows', payload: value });
  }, []);

  const request = useRequestAlertError(
    useRequest(ServicePatientPrescriptionDetails.getLatestForPatient, setIsLoading),
  );

  useEffect(() => {
    const load = async () => {
      const result = await request(userPatientProfileID);
      if (isRequestSuccess(result)) {
        setRows(
          result.payload.data.value.map((item) => ({
            ...item,
            _isLoading: false,
            _isChecked: false,
            _isDone: false,
            _error: null,
          })),
        );
      }
    };
    load();
  }, [request, userPatientProfileID, setRows]);

  const columns = useMemo(() => {
    return [
      createColumn<Model, 'id'>({
        field: 'id',
        renderHeader: () => (
          <Tooltip title={t(isAllSelected ? 'uncheck-all' : 'check-all')}>
            <Checkbox checked={isAllSelected} onChange={onChangeAll} />
          </Tooltip>
        ),
        renderColumn: ({ row }) => (
          <ColumnStatus
            isDone={row._isDone}
            error={row._error}
            isLoading={row._isLoading}
            isChecked={row._isChecked}
            onCheck={factoryOnChangeItem(row.id)}
          />
        ),
        stickyLeft: true,
      }),
      createColumn<Model, 'prescriptionDate'>({
        field: 'prescriptionDate',
        renderColumn: (props) => (
          <TemplateDate date={props.row.prescriptionDate} format={APP_FORMAT_DATE_TIME} />
        ),
      }),
      createColumn<Model, 'catalogName'>({
        field: 'catalogName',
        renderHeader: () => t('drug-name'),
      }),
      createColumn<Model, 'dosageForm'>({
        field: 'dosageForm',
      }),
      createColumn<Model, 'dosageFormTotal'>({
        field: 'dosageFormTotal',
      }),
      createColumn<Model, 'dosageFormDaily'>({
        field: 'dosageFormDaily',
      }),
      createColumn<Model, 'instructions'>({
        field: 'instructions',
      }),
      createColumn<Model, 'includeForm29'>({
        field: 'includeForm29',
        renderColumn: renderColumnBoolean,
      }),
    ];
  }, [isAllSelected, onChangeAll, factoryOnChangeItem, t]);

  const mountedRef = useMountedRef();
  const requestAddToDrug = useCallback(
    async (data: Model) => {
      if (mountedRef.current) {
        dispatch({ type: 'set-item-data', payload: { id: data.id, _isLoading: true } });
      }

      try {
        await ServicePatientPrescriptionDetails.create({
          drugID: data.drugID,
          dosageForm: data.dosageForm,
          dosageFormDaily: data.dosageFormDaily,
          dosageFormTotal: parseInt(data.dosageFormTotal ? String(data.dosageFormTotal) : '0'),
          isActive: true,
          isRenewal: data.isRenewal,
          includeForm29: data.includeForm29,
          userPatientProfilePrescriptionID: userPatientProfilePrescriptionID,
          dosageFormTotalDesc: data.dosageFormTotalDesc,
          instructions: data.instructions,
        });
        if (mountedRef.current) {
          dispatch({
            type: 'set-item-data',
            payload: { id: data.id, _isLoading: false, _isDone: true },
          });
        }
      } catch (e: any) {
        if (mountedRef.current) {
          dispatch({
            type: 'set-item-data',
            payload: { id: data.id, _isLoading: false, _error: e },
          });
        }
      }
    },
    [dispatch, userPatientProfilePrescriptionID, mountedRef],
  );
  const onAddToDrugs = useCallback(async () => {
    for (let i = 0; rowsToAdd.length > i; i++) {
      await requestAddToDrug(rowsToAdd[i]);
    }
    onRefresh();
  }, [requestAddToDrug, onRefresh, rowsToAdd]);
  const disabledAddToDrugs = useMemo(() => {
    return [rowsToAdd.length === 0, isLoading, rows.some(({ _isLoading }) => _isLoading)].some(
      Boolean,
    );
  }, [rowsToAdd.length, isLoading, rows]);

  return (
    <Dialog fullWidth={true} maxWidth={'md'} open={true} onClose={onClose}>
      <DialogHeading title={t('drugs-from-prev')} onClose={onClose} />
      <NativeScroll style={{ flex: '1 1 auto', minHeight: '20rem', marginTop: '2rem' }}>
        {isInit && <DataGridLight columns={columns} rows={rows} />}
        {rows.length === 0 && isInit && <DataGridEmpty />}
        <Loading active={isLoading} />
      </NativeScroll>
      <DialogActions>
        <Tooltip title={t('add-to-drugs-tooltip')}>
          <Button
            startIcon={<ImportExportIcon />}
            color={'primary'}
            variant={'contained'}
            disabled={disabledAddToDrugs}
            onClick={onAddToDrugs}
          >
            {t('add-to-drugs')}
          </Button>
        </Tooltip>
        <Button color={'primary'} variant={'contained'} onClick={onClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
