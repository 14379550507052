import React, { memo, useCallback, useRef } from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { Loading } from 'AurionCR/components';
import {
  Avatar,
  ButtonBase,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useOpen } from 'AurionCR/components/hooks';
import { useMap } from 'hooks';
import { composeFunctions } from 'utils';

interface Option {
  id: string;
  color: string;
  icon: string;
  title: string;
  isActive?: boolean;
}

interface Props<T> {
  loading?: boolean;
  disabled?: boolean;
  options: T[];
  value?: string;
  onChange?: (value: string) => void;
  endIcon?: React.ReactNode;
}

const SelectMeetingTypeComponent = <T extends Option>({
  disabled,
  loading,
  options,
  value,
  onChange,
  endIcon,
}: Props<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const { isOpen, handleClose, handleOpen } = useOpen();

  const map = useMap(options, 'id');
  const selectedItem = value ? map[value] : undefined;

  const backgroundColor = selectedItem?.color || 'transparent';

  const factoryClickItem = useCallback(
    (option: T) => {
      return () => {
        onChange && onChange(option.id);
      };
    },
    // eslint-disable-next-line
    [onChange],
  );

  return (
    <>
      <div className={style.root} ref={ref} style={{ backgroundColor: backgroundColor }}>
        {loading && <Loading size={15} />}
        <ButtonBase className={style.btn} onClick={handleOpen} disabled={disabled}>
          <Avatar
            variant="rounded"
            alt={selectedItem?.title || ''}
            src={selectedItem?.icon || ''}
            className={style.icon}
          />
          <Avatar variant="rounded" className={style.iconEdit}>
            <EditIcon />
          </Avatar>
          <div className={style.title}>{selectedItem?.title}</div>
        </ButtonBase>
        {endIcon}
      </div>
      <Popper
        open={isOpen}
        anchorEl={ref.current}
        role={undefined}
        placement={'bottom-start'}
        transition
        className={style.popper}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList disablePadding>
              {options.map((item) => (
                <MenuItem
                  key={item.id}
                  className={clsx(style.menuIcon, item.id === value && style.current)}
                  disabled={item.isActive === false}
                  onClick={composeFunctions(factoryClickItem(item), handleClose)}
                >
                  <Avatar
                    variant="rounded"
                    alt={item.title || ''}
                    src={item.icon || ''}
                    className={style.icon}
                    style={{ backgroundColor: item.color || 'transparent' }}
                  />
                  <div className={style.title}>{item.title}</div>
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

export const SelectMeetingType = memo(
  SelectMeetingTypeComponent,
) as typeof SelectMeetingTypeComponent;
