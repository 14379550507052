import { DynamicService } from 'utils/service';
import { IPharmacyApiResponse, API_PHARMACY_API_RESPONSES } from './models';
import { apiRtk } from 'utils/rtk-query';

export * from './models';

class Service extends DynamicService<IPharmacyApiResponse> {
  send = async (input: Components.Schemas.NeopharmApiRequest) => {
    return this.engine.post(API_PHARMACY_API_RESPONSES.SEND, input);
  };
  resend = async (id: string) => {
    return this.engine.post(API_PHARMACY_API_RESPONSES.RESEND, { id });
  };
}

export const ServicePharmacyApiResponse = new Service({
  mainField: 'id',
  getAll: API_PHARMACY_API_RESPONSES.GET_ALL_DYNAMIC,
  post: API_PHARMACY_API_RESPONSES.POST,
  patch: API_PHARMACY_API_RESPONSES.PATCH,
  delete: API_PHARMACY_API_RESPONSES.DELETE,
});

export const apiPharmacyApiResponse = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getPharmacyApiResponseById: builder.query<IPharmacyApiResponse, string>({
      queryFn: async (id) => {
        try {
          const result = await ServicePharmacyApiResponse.getDynamic(id);

          return { data: result.data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    resendPharmacyApiResponse: builder.mutation<void, string>({
      queryFn: async (id) => {
        try {
          const result = await ServicePharmacyApiResponse.resend(id);

          return { data: result.data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
