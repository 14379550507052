import React, { useCallback, useMemo } from 'react';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import clsx from 'clsx';
import { Box, Divider, IconButton, Tooltip, Typography } from '@material-ui/core';
import { dateFormat, DateValue } from 'utils/dates';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { Stack } from 'components/stack';
import { useTranslate } from 'hooks/use-translate';
import style from './index.module.scss';
import VAR from 'styles/_variabels.scss';
import FlagIcon from '@material-ui/icons/Flag';

interface NotebookItem {
  id: string;
  employee: string | null | undefined;
  notebook: string | null | undefined;
  date: string | null | undefined;
  items: Item[] | undefined;
  remarks: string | undefined;
}

interface NotebookItemComponentProps extends Omit<NotebookItem, 'id'> {
  onPreview?: () => void;
  onSwitch?: (value: boolean, item: Item) => void;
  disabled: boolean;
}

const NotebookItemComponent = ({
  onPreview,
  date,
  employee,
  notebook,
  items = [],
  remarks,
  onSwitch,
  disabled,
}: NotebookItemComponentProps) => {
  const { t, tp } = useTranslate();
  const formatedDate = useMemo(() => {
    if (!date) {
      return null;
    }

    return dateFormat(date, APP_FORMAT_DATE_TIME);
  }, [date]);
  const tooltip = useMemo(() => {
    return [notebook, employee].filter(Boolean).join(': ');
  }, [notebook, employee]);

  const factoryClick = useCallback(
    (item: Item) => {
      return () => onSwitch && onSwitch(!item.isActive, item);
    },
    [onSwitch],
  );

  return (
    <div className={clsx(style.item, remarks && style.itemFull)}>
      <div className={style.itemContent}>
        <Box alignSelf={'center'}>
          <Tooltip placement={'top-start'} arrow title={tooltip}>
            <IconButton title={'preview'} color={'primary'} onClick={onPreview}>
              <RemoveRedEyeIcon fontSize={'small'} />
            </IconButton>
          </Tooltip>
        </Box>
        <div>
          <Typography variant={'body2'} component={'div'} style={{ fontWeight: 'bold' }}>
            {formatedDate}
          </Typography>
          <Stack
            direction={'column'}
            divider={<Divider style={{ backgroundColor: VAR.colorBorder }} />}
          >
            {items.map((item) => {
              const isDeactivated = item.isActive === false;
              return (
                <Stack
                  className={clsx({ [style.deactivated]: isDeactivated })}
                  key={item.id}
                  spacing={1}
                  alignItems={'flex-start'}
                >
                  <Typography style={{ flexGrow: 1 }}>{item.title}</Typography>
                  {onSwitch && (
                    <Tooltip
                      arrow
                      title={
                        isDeactivated
                          ? tp('deactivated-at', { date: dateFormat(item.updatedDate) })
                          : tp('deactivate')
                      }
                    >
                      <IconButton
                        onClick={factoryClick(item)}
                        size={'small'}
                        color={isDeactivated ? 'inherit' : 'primary'}
                        disabled={disabled}
                      >
                        <FlagIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              );
            })}
          </Stack>
        </div>
      </div>
      {remarks && (
        <>
          <Divider orientation={'vertical'} style={{ backgroundColor: VAR.colorBorder }} />
          <Box p={'0.5rem 0.5rem 0.5rem 1rem'}>
            <Typography color={'secondary'} variant={'body2'} style={{ fontWeight: 'bold' }}>
              {t('remarks')}:
            </Typography>
            <Typography color={'textSecondary'} variant={'body2'}>
              {remarks}
            </Typography>
          </Box>
        </>
      )}
    </div>
  );
};

interface Item {
  id: string;
  title: string | null | undefined;
  isActive?: boolean;
  updatedDate?: DateValue;
}

type Props = {
  className?: string;
  title: string;
  onPreview: (id: string) => void;
  isLoading?: boolean;
  onSwitch?: (value: boolean, item: Item) => void;
  list: NotebookItem[];
};

export const PreviewSessionEffectsList: React.FC<Props> = ({
  className,
  title,
  list,
  onPreview,
  onSwitch,
  isLoading,
}) => {
  const factoryClickPreview = useCallback(
    (id: string) => {
      return () => onPreview(id);
    },
    [onPreview],
  );

  const isEmpty = list ? list.length === 0 : false;

  if (isEmpty) {
    return null;
  }

  return (
    <div className={clsx(style.root, className)}>
      <div className={style.header}>
        <Typography color={'secondary'} className={style.title}>
          {title}
        </Typography>
      </div>
      <Stack
        direction={'column'}
        divider={
          <Divider orientation={'horizontal'} style={{ backgroundColor: VAR.colorBorder }} />
        }
      >
        {list.map((listItem, i) => {
          return (
            <NotebookItemComponent
              key={i}
              items={listItem.items}
              employee={listItem.employee}
              notebook={listItem.notebook}
              date={listItem.date}
              onPreview={factoryClickPreview(listItem.id)}
              onSwitch={onSwitch}
              remarks={listItem.remarks}
              disabled={!!isLoading}
            />
          );
        })}
      </Stack>
    </div>
  );
};
