import React, { memo } from 'react';
import { DialogHeading } from 'components/dialog-title';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { SupportMeetingActivity } from 'services/support-meeting-activities';
import { useTranslate } from 'hooks/use-translate';
import { useFieldProps } from 'hooks';
import { AppSelect } from 'components/app-select';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSourcePatientRemarkStatuses } from 'components/hooks';
import { AppInput } from 'components/app-input';
import { InferType } from 'yup';

const schema = yup.object({
  remarkForPatientCallStatusID: yup.string().required('rule-required').default(''),
  remarks: yup.string().trim().default(''),
});

export type ActivityLogModel = InferType<typeof schema>;

interface Props {
  isLoading: boolean;
  title: string;
  remarks: string;
  onCancel: () => void;
  onConfirm: (value: ActivityLogModel) => void;
}

export const DialogSupportMeetingActivityLog = memo<Props>(
  ({ title, isLoading, remarks, onCancel, onConfirm }) => {
    const { t } = useTranslate();

    const { control, errors, handleSubmit } = useForm<SupportMeetingActivity>({
      defaultValues: schema.cast({ remarks }),
      resolver: yupResolver(schema),
    });
    const getFieldProps = useFieldProps({ errors });

    const sourcePatientRemarkStatuses = useSourcePatientRemarkStatuses();

    return (
      <Dialog open={true} onClose={onCancel} fullWidth maxWidth="xs">
        <DialogHeading isLoading={isLoading} title={title} onClose={onCancel} />
        <DialogContent>
          <Controller
            control={control}
            name={'remarkForPatientCallStatusID'}
            render={(renderProps) => {
              return (
                <AppSelect
                  {...getFieldProps(renderProps)}
                  disabled={isLoading}
                  label={t('remarks-for-patient')}
                  options={sourcePatientRemarkStatuses.data}
                  loading={sourcePatientRemarkStatuses.loading}
                />
              );
            }}
          />
          <Controller
            control={control}
            name={'remarks'}
            render={(renderProps) => {
              return (
                <AppInput
                  {...getFieldProps(renderProps)}
                  multiline={true}
                  minRows={5}
                  disabled={isLoading}
                />
              );
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="secondary">
            {t('cancel')}
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            onClick={handleSubmit(onConfirm)}
          >
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);
