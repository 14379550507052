import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslate } from 'hooks/use-translate';
import { AppSelect } from 'components/app-select';
import { useSourceNotebooksAll } from 'components/hooks';
import { NotebookFormGenerator } from 'components/notebook-form-generator';
import { SupportMeeting } from 'services/support-meetings';
import { convertToDate } from 'utils/dates';

interface Props {
  meeting: Pick<
    SupportMeeting,
    | 'id'
    | 'userPatientProfileID'
    | 'supportMeetingTypeID'
    | 'meetingFromDateTime'
    | 'meetingToDateTime'
  >;
  onComplete: (session: { id: string }) => void;
  disabled: boolean;
}

export const NotebookCreateInput = memo<Props>(({ meeting, onComplete, disabled }) => {
  const { t } = useTranslate();
  const source = useSourceNotebooksAll();
  const [notebookID, setNotebookID] = useState<string>();
  const onClose = useCallback(() => {
    return setNotebookID(undefined);
  }, []);

  const excludeMeetingIDs = useMemo(() => {
    return [meeting.id];
  }, [meeting.id]);

  const meetingTimes = useMemo(() => {
    return {
      from: convertToDate(meeting.meetingFromDateTime).toISOString(),
      to: convertToDate(meeting.meetingToDateTime).toISOString(),
    };
  }, [meeting]);

  return (
    <>
      <AppSelect
        placeholder={t('notebook')}
        value={notebookID}
        onChange={setNotebookID}
        options={source.data}
        loading={source.loading}
        color={'primary'}
        disableClearable
        disabled={disabled}
      />
      {notebookID && (
        <NotebookFormGenerator
          notebookID={notebookID}
          userPatientProfileID={meeting.userPatientProfileID}
          supportMeetingTypeID={meeting.supportMeetingTypeID}
          excludeMeetingIDs={excludeMeetingIDs}
          showDelete={false}
          onClose={onClose}
          onDone={onComplete}
          meetingFromDateTime={meetingTimes.from}
          meetingToDateTime={meetingTimes.to}
        />
      )}
    </>
  );
});
