type InsertMethod = 'insert_as_html' | 'insert_clear_html' | 'insert_only_text' | 'insert_as_test';

interface CleanHTML {
  timeout: number;
  /**
   * @description Replace &nbsp; to plain space
   */
  replaceNBSP: boolean;

  fillEmptyParagraph: boolean;

  removeEmptyElements: boolean;

  replaceOldTags: false | Record<keyof HTMLElementTagNameMap, string>;

  /**
   * @description Remove onError attributes
   */
  removeOnError: boolean;

  /**
   * @description Safe href="javascript:" links
   */
  safeJavaScriptLink: boolean;

  /**
   * @description The allowTags option defines which elements will remain in the edited text when the editor saves. You can use this limit the returned HTML.
   */
  allowTags: string | false | Record<string, any>;
  denyTags: string | false | Record<string, any>;
}

interface UploaderOptions {
  insertImageAsBase64URI: boolean;
}

export interface HtmlEditorConfig {
  namespace: string;
  readonly: boolean;
  disabled: boolean;
  toolbarButtonSize: 'small' | 'tiny' | 'xsmall' | 'middle' | 'large';
  allowTabNavigation: boolean;
  inline: boolean;
  theme: 'dark' | 'default';
  triggerChangeEvent: boolean;
  direction: 'rtl' | 'ltr';
  toolbar: string | boolean | HTMLElement;
  statusbar: boolean;
  showTooltip: boolean;
  showTooltipDelay: number;
  useNativeTooltip: boolean;
  enter: 'br' | 'div' | 'p';
  enterBlock: 'div' | 'p';
  defaultMode: any;
  /**
   * @description Use split mode.
   */
  useSplitMode: boolean;
  /**
   * @description Image size defaults to a larger image.
   */
  imageDefaultWidth: number;
  /**
   * @description Do not display these buttons that are on the list.
   */
  removeButtons: string[];
  /**
   * @description Do not init these plugins.
   */
  disablePlugins: string | string[];
  /**
   * @description Init and download extra plugins.
   */
  extraPlugins: string[];
  buttons: string | string[];
  events: Record<string, (...args: any[]) => any>;

  /**
   * @description Show the paste dialog if the html is similar to what MSWord gives when copying.
   */
  askBeforePasteFromWord: boolean;
  /**
   * @description Handle pasting of HTML - similar to a fragment copied from MSWord
   */
  processPasteFromWord: boolean;

  /**
   * @description Default insert method from word, if not define, it will use defaultActionOnPaste instead
   */
  defaultActionOnPasteFromWord: null | InsertMethod;

  /**
   * @description Ask before paste HTML in WYSIWYG mode
   */
  askBeforePasteHTML: boolean;

  /**
   * @description When the user inserts a piece of HTML, the plugin will ask - How to insert it. If after that user insert the same fragment again, the previous option will be used without extra question.
   */
  memorizeChoiceWhenPasteFragment: boolean;

  /**
   * @description Handle pasted text - similar to HTML
   */
  processPasteHTML: boolean;

  /**
   * @description Default insert method
   */
  defaultActionOnPaste: InsertMethod;

  cleanHTML: Partial<CleanHTML>;

  toolbarInlineDisabledButtons: string[];
  toolbarInline: boolean;
  toolbarInlineForSelection: boolean;
  toolbarInlineDisableFor: string[];

  /**
   * @description limit words count
   */
  limitWords: number | false;

  /**
   * @description limit chars count
   */
  limitChars: number | false;

  /**
   * @description After resize it will change buttons set for different sizes
   */
  toolbarAdaptive: boolean;

  showPlaceholder: boolean;

  placeholder: string;

  /**
   * @description Hide the link to the Jodit site at the bottom of the editor
   */
  hidePoweredByJodit: boolean;

  allowResizeX: boolean;
  allowResizeY: boolean;
  saveHeightInStorage: boolean;
  width: string | number;
  height: string | number;
  minWidth: string | number;
  minHeight: string | number;
  maxWidth: string | number;
  maxHeight: string | number;

  /**
   * @description Beautify HTML then it possible
   */
  beautifyHTML: boolean;

  beautifyHTMLCDNUrlsJS: string[];

  /**
   * @description Options specifies whether the editor is to have its spelling and grammar checked or not.
   */
  spellcheck: boolean;

  showCharsCounter: boolean;
  countHTMLChars: boolean;
  showWordsCounter: boolean;
  toolbarSticky: boolean;
  toolbarDisableStickyForMobile: boolean;
  showXPathInStatusbar: boolean;

  uploader: Partial<UploaderOptions>;
}

export const HtmlEditorConfigDefault: Partial<HtmlEditorConfig> = {
  cleanHTML: {
    replaceNBSP: true,
    removeEmptyElements: true,
    denyTags: {
      script: false,
      iframe: false,
    },
    allowTags: {
      div: true,
      p: true,
      span: true,
      a: {
        href: true,
      },
      br: true,
      h1: true,
      h2: true,
      h3: true,
      h4: true,
      h5: true,
      h6: true,
      strong: true,
      ul: true,
      ol: true,
      li: true,
    },
  },
  buttons: 'bold,|,ul,ol,|,align,|,fontsize,link,|,undo,redo',
  hidePoweredByJodit: true,
  toolbarAdaptive: false,
  toolbarSticky: false,
  allowResizeX: false,
  allowResizeY: false,

  uploader: {
    insertImageAsBase64URI: true,
  },
};
