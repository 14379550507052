import React, { useMemo } from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import DataGrid from 'components/data-grid';
import GridConfig from './grid-config';
import { useTranslate } from 'hooks/use-translate';
import style from './index.module.scss';
import { ButtonCancel } from '../../../app-buttons';

interface Props {
  title: string | null;
  userPatientProfileID: string;
  inputTypeID: string | null;
  onClose: () => void;
}
export const DialogInfo: React.FC<Props> = ({
  title,
  inputTypeID,
  userPatientProfileID,
  onClose,
}) => {
  const { tp } = useTranslate();
  const config = useMemo(() => {
    return GridConfig({ userPatientProfileID, inputTypeID }) as any;
  }, [inputTypeID, userPatientProfileID]);

  return (
    <Dialog open={true} fullWidth maxWidth={'lg'}>
      <DialogHeading title={title || tp('weight')} onClose={onClose} />
      <DialogContent className={style.root}>
        <DataGrid className={style.grid} config={config} />
      </DialogContent>
      <DialogActions>
        <ButtonCancel isBack={true} onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};
