export enum SUPPORT_MEETING_PATIENT_OPTIONS {
  ALL = 'all-patients',
  INACTIVE = 'inactive-patients',
  ACTIVE = 'active-patients',
}
export const makeFilterPatientsActive = (value: SUPPORT_MEETING_PATIENT_OPTIONS) => {
  switch (value) {
    case SUPPORT_MEETING_PATIENT_OPTIONS.ALL: {
      return null;
    }
    case SUPPORT_MEETING_PATIENT_OPTIONS.ACTIVE: {
      return 'userPatientProfile.isActive==true';
    }
    case SUPPORT_MEETING_PATIENT_OPTIONS.INACTIVE: {
      return 'userPatientProfile.isActive==false';
    }
  }
};
