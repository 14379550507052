import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';

const selectState = (state: RootState) => state.pdfDocumentSubmit;

export const selectPdfDocumentSubmitStatuses = createSelector(
  selectState,
  ({ init, isReadyToSubmit, loading }) => {
    return { init, loading, isReadyToSubmit };
  },
);

export const selectPdfDocumentSubmitPdfInfo = createSelector(
  selectState,
  ({ pages, scale, width }) => {
    return { pages, scale, width };
  },
);
