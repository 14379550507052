import { DynamicService } from 'utils/service';

import { API_FORM_DOCUMENT_DRUGS, FormDocumentDrugsBulkInput } from './models';

export * from './models';

class Service extends DynamicService<Components.Schemas.FormDocumentDrug> {
  createBulk = async (data: FormDocumentDrugsBulkInput) => {
    return this.engine.post(API_FORM_DOCUMENT_DRUGS.INSERT_BULK, {
      mainID: data.formDocumentID,
      itemIDs: data.drugIDs,
    });
  };
}

export const ServiceFormDocumentDrugs = new Service({
  mainField: 'id',
  getAll: API_FORM_DOCUMENT_DRUGS.GET_ALL_DYNAMIC,
  post: API_FORM_DOCUMENT_DRUGS.POST,
  delete: API_FORM_DOCUMENT_DRUGS.DELETE,
  patch: API_FORM_DOCUMENT_DRUGS.PATCH,
});
