import React from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import {
  UserBoxProgramDate,
  UserBoxProgramDuration,
  UserBoxProgramName,
} from '../../../user-info-boxes';
import { Stack } from 'components/stack';
import { GridSubscriptionsPayments } from '../../grids';

interface Props {
  className?: string;
  userPatientProfileID: string;
}

export const TabCommercial: React.FC<Props> = ({ userPatientProfileID, className }) => {
  return (
    <div className={clsx(style.root, className)}>
      <Stack spacing={1}>
        <UserBoxProgramDate userPatientProfileID={userPatientProfileID} />
        <UserBoxProgramName userPatientProfileID={userPatientProfileID} />
        <UserBoxProgramDuration userPatientProfileID={userPatientProfileID} />
      </Stack>
      <GridSubscriptionsPayments userPatientProfileID={userPatientProfileID} />
    </div>
  );
};
