import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';
import { dateToDayOfWeek } from 'services/user-employee-profile-work-logs';
import { selectAuthUser } from 'store/auth';
import { groupBy } from 'lodash-es';

const selectTMState = (state: RootState) => state.TaskManager;

export const selectTMTimes = createSelector(selectTMState, ({ times }) => {
  return times;
});
const selectTMView = createSelector(selectTMState, ({ view }) => {
  return view;
});

const selectTMCalendarState = createSelector(selectTMState, ({ calendar }) => {
  return calendar;
});

export const selectTMCalendarFilters = createSelector(selectTMCalendarState, ({ filters }) => {
  return filters;
});
const selectTMCalendarData = createSelector(selectTMCalendarState, ({ data }) => {
  return data;
});

export const selectTMListState = createSelector(selectTMState, ({ list }) => {
  return list;
});

export const selectTMLoading = createSelector(
  selectTMListState,
  selectTMCalendarState,
  (stateList, stateCalendar) => {
    return stateList.loading || stateCalendar.loading;
  },
);

export const selectTMListIsRefreshingActivities = createSelector(
  selectTMListState,
  ({ isRefreshingActivities }) => {
    return isRefreshingActivities;
  },
);

const selectMeetingTypeList = createSelector(selectTMState, ({ meetingTypes: { list } }) => {
  return list;
});
const selectMeetingTypeMap = createSelector(selectTMState, ({ meetingTypes: { map } }) => {
  return map;
});
export const selectActiveListItems = createSelector(selectMeetingTypeList, (list) => {
  return list.filter((item) => item.isActive);
});
export const selectTMListData = createSelector(
  selectTMListState,
  selectMeetingTypeMap,
  ({ data }, mapMeetingTypes) => {
    return data.map((item) => {
      const meetingType = mapMeetingTypes[item.supportMeetingTypeID];
      return { ...item, $isCanceled: meetingType ? meetingType.isCanceledMeeting : false };
    });
  },
);
export const selectTMListDataSelected = createSelector(selectTMListData, (listData) => {
  return listData.filter((item) => {
    return item.$isSelected;
  });
});

export const selectTMData = createSelector(
  selectTMView,
  selectTMCalendarData,
  selectTMListData,
  (view, calendar, list) => {
    switch (view) {
      case 'list': {
        return list;
      }
      case 'calendar': {
        return calendar;
      }
    }
  },
);

export const selectTMSendSms = createSelector(selectTMState, ({ sendSms }) => {
  return sendSms;
});
export const selectTMSendSmsMeeting = createSelector(
  selectTMSendSms,
  selectTMData,
  (sendSms, data) => {
    if (!sendSms) {
      return null;
    }
    if (!sendSms.supportMeetingID) {
      return null;
    }
    return data.find((item) => item.id === sendSms.supportMeetingID);
  },
);

export const selectTMEmployees = createSelector(
  selectAuthUser,
  selectTMState,
  (user, { employees }) => {
    return [...employees.list].sort((employeeA, employeeB) => {
      if (user?.appUserID === employeeA.appIdentityUserID) {
        return -1;
      }
      if (user?.appUserID === employeeB.appIdentityUserID) {
        return 1;
      }
      return 0;
    });
  },
);

export const selectTMCalendarColumns = createSelector(
  selectTMEmployees,
  selectTMCalendarFilters,
  (employees, { date }) => {
    return employees.filter(({ workLogs }) => {
      return workLogs.some((workLog) => workLog.dayOfWeek === dateToDayOfWeek(date));
    });
  },
);

const selectTMPatientClinicalMeetings = createSelector(selectTMState, ({ clinicalMeetings }) => {
  return clinicalMeetings;
});

export const selectTMPatientClinicalMeetingsMap = createSelector(
  selectTMPatientClinicalMeetings,
  (list) => {
    return groupBy(list, 'userPatientProfileID');
  },
);
