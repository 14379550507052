import { apiSupportMeeting } from 'services/support-meetings';

export const useSupportMeetingNotebookRenewalComplete = (meetingID: string) => {
  return apiSupportMeeting.useDeleteSupportMeetingMutation({
    fixedCacheKey: `notebook__support__meeting__${meetingID}`,
  });
};
export const useSupportMeetingNotebookCreateComplete = (meetingID: string) => {
  return apiSupportMeeting.usePatchSupportMeetingMutation({
    fixedCacheKey: `notebook__support__meeting__${meetingID}`,
  });
};
