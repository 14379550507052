import { KeyboardDateTimePicker, KeyboardDateTimePickerProps } from '@material-ui/pickers';
import React, { memo } from 'react';
import { useTranslate } from 'hooks/use-translate';
import { APP_FORMAT_DATE_TIME } from 'configs/const';

interface Props extends KeyboardDateTimePickerProps {}
export const AppDateTimePicker = memo<Props>((props) => {
  const { t } = useTranslate();
  return (
    <KeyboardDateTimePicker
      todayLabel={t('today')}
      okLabel={t('ok')}
      cancelLabel={t('cancel')}
      format={APP_FORMAT_DATE_TIME}
      fullWidth
      {...props}
    />
  );
});
