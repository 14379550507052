import React from 'react';

import style from './index.module.scss';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { Checkbox, CircularProgress, Tooltip } from '@material-ui/core';
import { requestError } from 'AurionCR/components';
import { Alert } from '@material-ui/lab';
import { useTranslate } from 'hooks/use-translate';

interface Props {
  isChecked: boolean;
  onCheck: (e: React.ChangeEvent, checked: boolean) => void;
  isLoading: boolean;
  error: null | Error;
  isDone: boolean;
}

export const ColumnStatus: React.FC<Props> = ({ isChecked, onCheck, isDone, isLoading, error }) => {
  const { t } = useTranslate();
  if (isDone) {
    return (
      <Tooltip title={t('done')}>
        <div className={style.icon}>
          <DoneIcon color={'secondary'} />
        </div>
      </Tooltip>
    );
  }
  if (error) {
    return (
      <Tooltip
        classes={{
          tooltip: style.tooltipError,
        }}
        title={<Alert severity={'error'}>{t(requestError(error))}</Alert>}
      >
        <div className={style.icon}>
          <ErrorOutlineIcon color={'error'} />
        </div>
      </Tooltip>
    );
  }
  if (isLoading) {
    return (
      <div className={style.icon}>
        <CircularProgress size={20} />
      </div>
    );
  }
  return <Checkbox checked={isChecked} onChange={onCheck} />;
};
