import { DateValue } from 'utils/dates';
import { UserEmployeeProfile } from 'services/user-employee-profiles';
import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'UserPatientProfileUploadedFiles';

export const API_USER_PATIENT_PROFILE_UPLOADED_FILES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<UploadedFiles, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<UploadedFiles, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface UploadedFiles
  extends FixServerObject<
    Components.Schemas.UserPatientProfileUploadedFile,
    'id' | 'title' | 'fileURL'
  > {
  createdDate?: string;
}

export interface UploadedFilesGrid extends UploadedFiles {
  title: string;
  'userEmployeeProfile.firstName': string;
  'userEmployeeProfile.lastName': string;
}

export interface UploadedFilesGetActivitiesInput {
  userPatientProfileID: string;
  dateRange: DateValue[];
}

export interface UploadedFilesGetActivitiesItem
  extends Pick<UploadedFiles, 'id' | 'createdDate' | 'title' | 'fileURL'> {
  employee: UserEmployeeProfile['fullName'];
}
