import { ValueFileUploader } from 'utils/file-uploader';

const API = 'Migrations';

export const API_MIGRATIONS = {
  API,
  GET_FILES: `${API}/GetPdfFiles`,
  MIGRATE_PATIENT_FROM_EXCEL: `${API}/MigrateFromExcel`,
  MIGRATE_PATIENT_FROM_PDF: `${API}/MigrateFromPdf`,
  ATTACH_DOCUMENTS: `${API}/AttachDocuments`,
  SET_IGNORE: `${API}/SetIgnore`,
  MIGRATE_MEETINGS_FROM_EXCEL: `${API}/MigrateMeetingsFromExcel`,
};

export interface MigrateGetPdfFilesInput {
  bulk: number;
}

export interface MigratePatientFromExcelInput {
  fileExcel: ValueFileUploader;
  userPatientProfileID: string;
}

export interface MigratePatientMakePatientPdfDataInput {
  file: Uint8Array;
}

export interface MigrateMeetingsFromExcelInput {
  file: ValueFileUploader;
}

export interface MigratePatientMigratePatientPdfDataInput {
  file: ValueFileUploader;
  data: PatientPdfData;
}

export interface PatientPdfDataSession {
  balance: string;
  paid: string;
  charged: string;
  program: string;
  employee: string;
  description: string;
  code: string;
  session: string;
  treatNumber: string;
  date: string;
}
export interface PatientPdfData {
  fullName: string;
  idNumber: string;
  mobilePhone: string;
  birthDay: string;
  email: string;
  gender: string;
  sessions: PatientPdfDataSession[];
}
