import React, { useMemo } from 'react';
import SupportAgent from 'components/svg-icons/support-agent';
import { Badge } from '@material-ui/core';
import { useCurrentUser } from 'components/hooks';
import { DOCTOR_RAZ_ID } from 'services/user-employee-profiles';
import { useSource } from 'AurionCR/components';
import { API_SUPPORT_MEETINGS } from 'services/support-meetings';
import { generateDynamicQuery } from 'utils/service';

const useSourceRenewal = () => {
  const { appUserID } = useCurrentUser();
  const queryParams = useMemo(() => {
    return generateDynamicQuery({
      filter: `supportMeetingType.isPrescriptionRenewal==true&&userPatientProfileSessionID==null&&userEmployeeProfileID=="${appUserID}"`,
      select: ['id', 'userPatientProfileSessionID'].join(','),
    });
  }, [appUserID]);
  return useSource<{ id: string }>(`${API_SUPPORT_MEETINGS.GET_ALL_DYNAMIC}?${queryParams}`);
};

const BadgeRenewal: React.FC = () => {
  const { data } = useSourceRenewal();
  const count = data.length;
  return (
    <Badge badgeContent={count} color={'error'}>
      <SupportAgent />
    </Badge>
  );
};

export const IconTaskManager: React.FC = () => {
  const { appUserID } = useCurrentUser();
  const isDoctor = appUserID === DOCTOR_RAZ_ID;
  return isDoctor ? <BadgeRenewal /> : <SupportAgent />;
};
