import { DynamicService } from 'utils/service';

import {
  API_USER_PATIENT_PROFILE_SESSIONS_CLINIC_DRUGS,
  UserPatientProfileSessionClinicDrug,
} from './models';
import { apiRtk } from 'utils/rtk-query';

export * from './models';

class Service extends DynamicService<UserPatientProfileSessionClinicDrug> {}

export const ServiceUserPatientProfileSessionClinicDrugs = new Service({
  mainField: 'id',
  getAll: API_USER_PATIENT_PROFILE_SESSIONS_CLINIC_DRUGS.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_SESSIONS_CLINIC_DRUGS.POST,
  delete: API_USER_PATIENT_PROFILE_SESSIONS_CLINIC_DRUGS.DELETE,
  patch: API_USER_PATIENT_PROFILE_SESSIONS_CLINIC_DRUGS.PATCH,
});

export const apiUserPatientProfileSessionClinicDrugs = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    postUserPatientProfileSessionClinicDrugs: builder.mutation({
      queryFn: async (input: Omit<UserPatientProfileSessionClinicDrug, 'id'>) => {
        try {
          const result = await ServiceUserPatientProfileSessionClinicDrugs.post(input);
          return { data: result.data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
