import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'ClinicalMeetingTypes';

export const API_CLINICAL_MEETING_TYPES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<ClinicalMeetingType, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<ClinicalMeetingType, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export enum MEETING_TYPE_KEY {
  CLINIC = 'clinic',
  HOME = 'home',
}

export interface ClinicalMeetingType
  extends FixServerObject<
    Components.Schemas.ClinicalMeetingType,
    'id' | 'title' | 'icon' | 'color'
  > {
  meetingTypeKey: MEETING_TYPE_KEY;
}
