import {
  PatientDocumentCreate,
  UserPatientProfileDocument,
  API_USER_PATIENT_PROFILE_DOCUMENTS,
  PatientDocumentGetActivitiesItem,
  PatientDocumentGetActivitiesInput,
  GetUserPatientProfileDocumentsFromOldSystemItem,
  GetUserPatientProfileDocumentForm29GimelInput,
  GetUserPatientProfileDocumentForm29GimelItem,
} from './models';
import { getFileNameWithoutExt, mimeToExtension } from 'utils/file-uploader';
import { FileCloudServicePrivate } from 'services/media-private-services';
import { DynamicService } from 'utils/service';
import { makeFilterDateRange } from 'utils/app-helpers';
import { apiRtk } from 'utils/rtk-query';
import { createFilterEndsWith, createFilterEquals, mergeFilters } from 'utils/dynamic-helpers';
import { apiDocumentTypes, DOCUMENT_TYPES_INDEXES } from 'services/document-types';
import { ServicePatientPrescriptionDetails } from 'services/user-patient-profile-prescription-details';
export * from './models';

class Service extends DynamicService<UserPatientProfileDocument> {
  postWithFiles = async (data: PatientDocumentCreate) => {
    const [dataToSave] = await FileCloudServicePrivate.postDataWithFiles(data, {
      documentURL: (file) => {
        const fileName = `patients/${data.userPatientProfileID}/documents/${getFileNameWithoutExt(
          file.name,
        )}.${mimeToExtension(file.type)}`;
        return {
          isImage: false,
          fileName: fileName,
        };
      },
      documentForPrintURL: (file) => {
        const fileName = `patients/${data.userPatientProfileID}/documents/${getFileNameWithoutExt(
          file.name,
        )}__print.${mimeToExtension(file.type)}`;
        return {
          isImage: false,
          fileName: fileName,
        };
      },
    });
    return this.post(dataToSave);
  };
  getActivities = async (input: PatientDocumentGetActivitiesInput) => {
    const { userPatientProfileID, dateRange } = input;
    const {
      data: { value },
    } = await this.getAllDynamic<PatientDocumentGetActivitiesItem>({
      select: [
        'id',
        'createdDate',
        'fileName',
        'documentURL',
        'userEmployeeProfile.fullName as employee',
      ].join(','),
      filter: [
        `userPatientProfileID=="${userPatientProfileID}"`,
        makeFilterDateRange('createdDate', dateRange),
      ]
        .filter(Boolean)
        .join('&&'),
      orderBy: 'createdDate desc',
    });
    return value.map((item) => ({
      id: String(item.id),
      title: item.fileName,
      date: item.createdDate,
      employee: item.employee,
      download: item.documentURL,
    }));
  };
}

export const ServicePatientDocuments = new Service({
  mainField: 'id',
  getAll: API_USER_PATIENT_PROFILE_DOCUMENTS.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_DOCUMENTS.POST,
  patch: API_USER_PATIENT_PROFILE_DOCUMENTS.PATCH,
  delete: API_USER_PATIENT_PROFILE_DOCUMENTS.DELETE,
});

export const apiUserPatientProfileDocuments = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getUserPatientProfileDocumentMigration: builder.query({
      queryFn: async (userPatientProfileID: string) => {
        const result = await ServicePatientDocuments.getAllDynamic<
          Pick<UserPatientProfileDocument, 'id' | 'documentURL'>
        >({
          select: 'id,documentURL',
          filter: mergeFilters(
            createFilterEquals('userPatientProfileID', userPatientProfileID),
            createFilterEquals('documentType.rowIndex', DOCUMENT_TYPES_INDEXES.MIGRATION),
          ).join('&&'),
          take: 1,
        });
        const data = result.data.value[0];

        if (!data) {
          throw new Error('migration-file-does-not-exist');
        }

        return { data };
      },
    }),
    getUserPatientProfileDocumentsFromOldSystem: builder.query({
      queryFn: async (userPatientProfileID: string) => {
        const result =
          await ServicePatientDocuments.getAllDynamic<GetUserPatientProfileDocumentsFromOldSystemItem>(
            {
              select: [
                'id',
                'documentURL',
                'createdDate',
                'new { documentType.title } as documentType',
              ].join(','),
              filter: mergeFilters(
                createFilterEquals('userPatientProfileID', userPatientProfileID),
                createFilterEquals('rowIndex', DOCUMENT_TYPES_INDEXES.PATIENT_FILE),
              ).join('&&'),
            },
          );
        return { data: result.data.value };
      },
    }),
    getUserPatientProfileDocumentForm29Gimel: builder.query({
      queryFn: async (input: GetUserPatientProfileDocumentForm29GimelInput) => {
        const filePathName = ServicePatientPrescriptionDetails.makeForm29GimelFilePathName({
          ...input,
          isCopy: true,
        });

        const result =
          await ServicePatientDocuments.getAllDynamic<GetUserPatientProfileDocumentForm29GimelItem>(
            {
              select: ['id', 'documentURL', 'fileName'].join(','),
              filter: mergeFilters(createFilterEndsWith('documentURL', `${filePathName}.pdf`)).join(
                '&&',
              ),
              take: 1,
            },
          );

        const data = result.data.value[0];

        if (!data) {
          throw new Error('document-form-29-gimel-was-not-found');
        }

        return { data };
      },
    }),

    postWithFilesUserPatientProfileDocument: builder.mutation<
      UserPatientProfileDocument,
      PatientDocumentCreate
    >({
      queryFn: async (input: PatientDocumentCreate) => {
        try {
          const { data } = await ServicePatientDocuments.postWithFiles(input);

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    createUserPatientProfileDocumentGimel29: builder.mutation<
      UserPatientProfileDocument,
      Omit<PatientDocumentCreate, 'documentTypeID'>
    >({
      queryFn: async (input, { dispatch }) => {
        try {
          const typeRequest = dispatch(
            apiDocumentTypes.endpoints.getDocumentTypeByIndex.initiate(
              DOCUMENT_TYPES_INDEXES.GIMEL_29,
            ),
          );
          typeRequest.unsubscribe();

          const resultType = await typeRequest.unwrap();

          if (!resultType) {
            throw Error('Unexpected-behaviour: Can not find correct document type');
          }

          const requestPost = dispatch(
            apiUserPatientProfileDocuments.endpoints.postWithFilesUserPatientProfileDocument.initiate(
              {
                ...input,
                documentTypeID: resultType.id,
              },
            ),
          );

          const result = (await requestPost.unwrap()) as UserPatientProfileDocument;

          return { data: result };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    createUserPatientProfileDocumentReport: builder.mutation<
      UserPatientProfileDocument,
      Omit<PatientDocumentCreate, 'documentTypeID'>
    >({
      queryFn: async (input, { dispatch }) => {
        try {
          const typeRequest = dispatch(
            apiDocumentTypes.endpoints.getDocumentTypeByIndex.initiate(
              DOCUMENT_TYPES_INDEXES.REPORT,
            ),
          );
          typeRequest.unsubscribe();

          const resultType = await typeRequest.unwrap();

          if (!resultType) {
            throw Error('Unexpected-behaviour: Can not find correct document type');
          }

          const requestPost = dispatch(
            apiUserPatientProfileDocuments.endpoints.postWithFilesUserPatientProfileDocument.initiate(
              {
                ...input,
                documentTypeID: resultType.id,
              },
            ),
          );

          const result = (await requestPost.unwrap()) as UserPatientProfileDocument;

          return { data: result };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
