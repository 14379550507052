import { createFilterEquals, mergeFilters } from 'utils/dynamic-helpers';
import React from 'react';
import {
  API_USER_PATIENT_PROFILE_SESSIONS_CLINIC_DRUGS,
  UserPatientProfileSessionClinicDrug,
} from 'services/user-patient-profile-session-clinic-drugs';
import { TemplateFullSessionInfo } from 'components/templates/template-full-info';
import { TemplateDate } from 'components/templates/template-date';

export type GridModel = Pick<
  UserPatientProfileSessionClinicDrug,
  'createdDate' | 'userPatientProfileSessionID'
> & {
  isAutoGenerated: boolean;
};

interface Options {
  userPatientProfileID: string;
}
export default (options: Options): any => ({
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_SESSIONS_CLINIC_DRUGS.GET_ALL_DYNAMIC,
      filters: mergeFilters(
        createFilterEquals(
          'userPatientProfileSession.userPatientProfileID',
          options.userPatientProfileID,
        ),
      ).join('&&'),
      selected: [
        'userPatientProfileSessionID',
        'userPatientProfileSession.notebook.isForPrescriptionOnly as isAutoGenerated',
      ].join(','),
    },
    create: {
      url: API_USER_PATIENT_PROFILE_SESSIONS_CLINIC_DRUGS.POST,
    },
    sortable: {
      field: 'createdDate',
      order: 'desc',
    },
  },
  controlsDropDown: null,
  toolbar: {
    title: '',
    filters: null,
    controls: null,
    controlsDefault: null,
  },
  columns: [
    {
      title: '',
      field: 'userPatientProfileSessionID',
      template: (data: GridModel) => (
        <TemplateFullSessionInfo
          userPatientProfileSessionID={data.userPatientProfileSessionID as string}
        />
      ),
      sortable: false,
    },
    {
      title: 'entry-date',
      field: 'createdDate',
      template: (data: GridModel) => <TemplateDate date={data.createdDate} />,
      sortable: false,
    },
    {
      title: 'notebook',
      field: 'userPatientProfileSession.notebook.labelKey as notebookLabelKey',
      sortable: 'userPatientProfileSession.notebook.labelKey',
    },
    {
      title: 'clinic-drug',
      field: 'clinicDrug.catalogName as catalogName',
      sortable: 'clinicDrug.catalogName',
    },
    {
      title: 'dosage',
      field: 'dosageForm',
    },
    {
      title: 'remarks',
      field: 'remarks',
    },
  ],
  pagination: false,
});
