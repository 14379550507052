import React from 'react';
import { DateValue } from 'utils/dates';
import { TemplateDate } from 'components/templates/template-date';

interface Props {
  date: DateValue;
}
export const ColumnDate: React.FC<Props> = ({ date }) => {
  return <TemplateDate date={date} />;
};
