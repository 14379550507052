import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'UserPatientProfileSessionSensitivities';

export const API_USER_PATIENT_PROFILE_SESSIONS_SENSITIVITIES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  POST_BULK: `${API_ENDPOINT}/CreateUserPatientProfileSessionSensitivityBulk`,
  PATCH: (data: Pick<UserPatientProfileSessionSensitivity, 'id'>) =>
    `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<UserPatientProfileSessionSensitivity, 'id'>) =>
    `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface UserPatientProfileSessionSensitivity
  extends FixServerObject<Components.Schemas.UserPatientProfileSessionSensitivity, 'id'> {
  createdDate?: string;
}

export interface CreateBulkUserPatientProfileSessionSensitivityInput {
  userPatientProfileSessionID: string;
  sensitivityIDs: string[];
}
