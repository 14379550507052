import React, { memo } from 'react';
import { InputAdornment } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useTranslate } from 'hooks/use-translate';
import { useOpen } from 'AurionCR/components/hooks';
import { DialogUserPatientProfileSession } from 'components/dialoog-user-patient-profile-session';
import { AppInput } from 'components/app-input';

interface Props {
  userPatientProfileSessionID: string;
  userPatientProfileID: string;
  children?: React.ReactNode;
}

export const NotebookPreviewInput = memo<Props>(
  ({ userPatientProfileSessionID, userPatientProfileID, children }) => {
    const { t } = useTranslate();
    const { isOpen, handleOpen, handleClose } = useOpen();

    return (
      <>
        <AppInput
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position={'start'}>
                <VisibilityIcon color={'primary'} />
              </InputAdornment>
            ),
          }}
          value={children === undefined ? t('session') : children}
          onClick={handleOpen}
          color={'primary'}
          disableClearable
        />
        {isOpen && (
          <DialogUserPatientProfileSession
            itemID={userPatientProfileSessionID}
            userPatientProfileID={userPatientProfileID}
            onClose={handleClose}
            isShowDelete={false}
          />
        )}
      </>
    );
  },
);
