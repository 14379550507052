import { Avatar } from '@material-ui/core';
import React from 'react';

interface Props {
  color: string | null | undefined;
  icon: string | null | undefined;
}
export const GridColorTemplate: React.FC<Props> = ({ color, icon }) => {
  return (
    <Avatar variant="rounded" alt="icon" style={{ backgroundColor: color ? color : 'white' }}>
      <Avatar src={icon || ''} />
    </Avatar>
  );
};
