import React, { memo, useCallback, useContext, useMemo, useState } from 'react';
import {
  iUserPatientProfilePrescriptionDetails,
  PrescriptionDetailRulesGimel29Result,
  ServicePatientPrescriptionDetails,
} from 'services/user-patient-profile-prescription-details';
import { createFilterEquals, mergeFilters } from 'utils/dynamic-helpers';
import { useFetchDynamic } from 'hooks';
import { convertToDate } from 'utils/dates';
import { composeFunctions } from 'utils';
import { apiUserPatientProfile } from 'services/user-patient-profile';

interface Detail
  extends Pick<
    iUserPatientProfilePrescriptionDetails,
    | 'id'
    | 'drugID'
    | 'dosageFormTotal'
    | 'includeForm29'
    | 'userPatientProfilePrescription'
    | 'userPatientProfilePrescriptionID'
  > {}

export interface MedicalPrescriptionDetail
  extends Pick<
    iUserPatientProfilePrescriptionDetails,
    'id' | 'drugID' | 'drug' | 'includeForm29' | 'dosageFormTotal'
  > {
  entryDate: string;
  dateOfBirth: string;
}

interface Context {
  rows: MedicalPrescriptionDetail[];
  rowRules: Record<string, PrescriptionDetailRulesGimel29Result>;
  setRows: (rows: MedicalPrescriptionDetail[]) => void;
  isLoading: boolean;
}
export const MedicalPrescriptionDetailsContext = React.createContext<null | Context>(null);

export const useMedicalPrescriptionDetailsContext = () => {
  const context = useContext(MedicalPrescriptionDetailsContext);

  if (!context) {
    throw new Error('MedicalPrescriptionDetailsContext: is required');
  }

  return context;
};
export const useMedicalPrescriptionDetailsWithRules = () => {
  const { rows, rowRules } = useMedicalPrescriptionDetailsContext();

  return useMemo(
    () =>
      rows.filter((row) => {
        const rules = rowRules[row.id];

        return rules ? rules.length > 0 : false;
      }),
    [rows, rowRules],
  );
};

interface Props {
  userPatientProfileID: string;
  children: React.ReactNode;
}
export const MedicalPrescriptionDetailsProvider = memo<Props>(
  ({ children, userPatientProfileID }) => {
    const { data: patientData, isFetching: isLoadingPatientData } =
      apiUserPatientProfile.useGetPatientDetailsPrescriptionRulesQuery(userPatientProfileID);

    const fetchData = useCallback(
      (props: { entryDate: string }) => {
        const { entryDate } = props;
        const valueEntryDate = convertToDate(entryDate).toISOString();

        return ServicePatientPrescriptionDetails.getAllDynamic<Detail>({
          filter: mergeFilters(
            createFilterEquals(
              'userPatientProfilePrescription.userPatientProfileID',
              userPatientProfileID,
            ),
            `(DateTime(userPatientProfilePrescription.entryDate) <= DateTime("${valueEntryDate}"))`,
          ).join('&&'),
          select: [
            'id',
            'drugID',
            'dosageFormTotal',
            'includeForm29',
            'userPatientProfilePrescriptionID',
            'new { userPatientProfilePrescription.entryDate } as userPatientProfilePrescription',
          ].join(','),
          orderBy: 'userPatientProfilePrescription.entryDate desc',
        });
      },
      [userPatientProfileID],
    );

    const [triggerGet, { data: resultGet, isLoading: isLoadingDetails }] =
      useFetchDynamic(fetchData);
    const data = resultGet?.value || [];

    const requestGetData = useCallback(
      (rows: MedicalPrescriptionDetail[]) => {
        const entryDate = rows[0]?.entryDate;

        if (entryDate) {
          triggerGet({ entryDate });
        }
      },
      [triggerGet],
    );

    const [rows, setRows] = useState<MedicalPrescriptionDetail[]>([]);

    const rowRules = useMemo(() => {
      if (!patientData) return {};
      return rows.reduce((acc, row) => {
        acc[row.id] = ServicePatientPrescriptionDetails.rulesGimel29({
          prescription: { entryDate: row.entryDate },
          patient: {
            dateOfBirth: patientData.dateOfBirth || '',
            weight: patientData.weight || 0,
            height: patientData.height || 0,
          },
          detail: { ...row, includeForm29: Boolean(row.includeForm29) },
          drug: {
            ruleForm29AlwaysRequiredForTheFirstTime: Boolean(
              row.drug?.ruleForm29AlwaysRequiredForTheFirstTime,
            ),
            ruleForm29RenewAfterNumberOfMonths:
              row.drug?.ruleForm29RenewAfterNumberOfMonths ?? null,
            ruleForm29NumberOfPrescriptions: row.drug?.ruleForm29NumberOfPrescriptions ?? null,
            ruleForm29PrescriptionDosageFormTotalUnits:
              row.drug?.ruleForm29PrescriptionDosageFormTotalUnits ?? null,
            ruleForm29AccumulateDosageFormTotalUnits:
              row.drug?.ruleForm29AccumulateDosageFormTotalUnits ?? null,
            ruleForm29MinAge: row.drug?.ruleForm29MinAge ?? null,
            ruleForm29BMILess30: Boolean(row.drug?.ruleForm29BMILess30),
          },
          otherDetails: data.map((item) => ({
            ...item,
            entryDate: item.userPatientProfilePrescription?.entryDate || '',
          })),
        });
        return acc;
      }, {} as Record<string, PrescriptionDetailRulesGimel29Result>);
    }, [rows, data, patientData]);

    const value = useMemo(() => {
      return {
        rows,
        rowRules,
        setRows: composeFunctions(setRows, requestGetData),
        isLoading: isLoadingPatientData || isLoadingDetails,
      };
    }, [rows, rowRules, requestGetData, isLoadingPatientData, isLoadingDetails]);

    return (
      <MedicalPrescriptionDetailsContext.Provider value={value}>
        {children}
      </MedicalPrescriptionDetailsContext.Provider>
    );
  },
);
