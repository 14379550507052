import { FormDocumentInput } from 'services/form-document-inputs';
import { FormDocument } from 'services/form-documents';

export interface PdfDocumentSubmit {
  // flags
  init: boolean;
  loading: boolean;
  isReadyToSubmit: boolean;
  // data
  initData: PdfDocumentSubmitInitData | null;
  // pdf
  scale: number;
  width: number;
  pages: PdfDocumentSubmitPage[];
  // events
  eventScrollTo: { top: number; left: number; pageNumber?: number } | null;
  eventFocusItem: PdfDocumentSubmitFocus | null;
  // triggers
  triggerSubmit: string;
}

export interface PdfDocumentSubmitInitData extends FormDocument {
  id: string;
  userPatientProfileID: string;
  expiredDate: string | Date;
}

export interface PdfDocumentSubmitFocus {
  id: FormDocumentInput['id'];
  pageNumber: FormDocumentInput['pageNumber'];
  input: FormDocumentInput;
}

export interface PdfDocumentSubmitPageProps {
  index: number;
  width: number;
  height: number;
}

export interface PdfDocumentSubmitPage extends PdfDocumentSubmitPageProps {
  inputs: FormDocumentInput[];
}

export enum PdfDocumentSubmitActionTypes {
  PDF_DOCUMENTS_SUBMIT_RESET = 'PDF_DOCUMENTS_SUBMIT_RESET',
  PDF_DOCUMENTS_SUBMIT_INIT = 'PDF_DOCUMENTS_SUBMIT_INIT',
  PDF_DOCUMENTS_SUBMIT_MERGE = 'PDF_DOCUMENTS_SUBMIT_MERGE',
  PDF_DOCUMENTS_SUBMIT_SET_PAGE_SIZE = 'PDF_DOCUMENTS_SUBMIT_SET_PAGE_SIZE',
  PDF_DOCUMENTS_SUBMIT_PAGE_INPUT_MODIFY = 'PDF_DOCUMENTS_SUBMIT_PAGE_INPUT_MODIFY',
  PDF_DOCUMENTS_SUBMIT_SCROLL_TO_INPUT = 'PDF_DOCUMENTS_SUBMIT_SCROLL_TO_INPUT',
  PDF_DOCUMENTS_SUBMIT_SAVE = 'PDF_DOCUMENTS_SUBMIT_SAVE',
}

export interface PdfDocumentSubmitInitAction {
  type: PdfDocumentSubmitActionTypes.PDF_DOCUMENTS_SUBMIT_INIT;
  payload: { id: string };
}

export interface PdfDocumentSubmitScrollToInputAction {
  type: PdfDocumentSubmitActionTypes.PDF_DOCUMENTS_SUBMIT_SCROLL_TO_INPUT;
  payload: string;
}

export interface PdfDocumentSubmitSaveAction {
  type: PdfDocumentSubmitActionTypes.PDF_DOCUMENTS_SUBMIT_SAVE;
  payload: string;
}
