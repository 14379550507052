import { DynamicService } from 'utils/service';

import {
  API_USER_PATIENT_PROFILE_UPLOADED_FILES,
  UploadedFiles,
  UploadedFilesGetActivitiesInput,
  UploadedFilesGetActivitiesItem,
} from './models';
import { makeFilterDateRange } from 'utils/app-helpers';

export * from './models';

class Service extends DynamicService<UploadedFiles> {
  getActivities = async (input: UploadedFilesGetActivitiesInput) => {
    const { userPatientProfileID, dateRange } = input;
    const {
      data: { value },
    } = await this.getAllDynamic<UploadedFilesGetActivitiesItem>({
      select: [
        'id',
        'createdDate',
        'title',
        'fileURL',
        'userEmployeeProfile.fullName as employee',
      ].join(','),
      filter: [
        `userPatientProfileID=="${userPatientProfileID}"`,
        makeFilterDateRange('createdDate', dateRange),
      ]
        .filter(Boolean)
        .join('&&'),
      orderBy: 'createdDate desc',
    });

    return value.map((item) => ({
      id: String(item.id),
      title: item.title,
      date: item.createdDate,
      employee: item.employee,
      download: item.fileURL,
    }));
  };
}

export const ServiceUserPatientProfileUploadedFiles = new Service({
  mainField: 'id',
  getAll: API_USER_PATIENT_PROFILE_UPLOADED_FILES.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_UPLOADED_FILES.POST,
  delete: API_USER_PATIENT_PROFILE_UPLOADED_FILES.DELETE,
  patch: API_USER_PATIENT_PROFILE_UPLOADED_FILES.PATCH,
});
