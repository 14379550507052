import React, { memo, useCallback, useMemo } from 'react';
import { Button, Typography } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { useTransition, animated } from '@react-spring/web';
import { NativeScroll } from 'components/native-scroll';
import classes from './index.module.scss';
import { convertToDate } from 'utils/dates';
import { Stack } from 'components/stack';
import { Loading } from 'AurionCR/components';
import { useAppDispatch, useAppSelector } from 'store';
import RefreshIcon from '@material-ui/icons/Refresh';
import {
  actionEmployeesMonitorRefreshEmployees,
  selectEmployeesMonitorEmployeesList,
  selectEmployeesMonitorStatuses,
} from 'store/employees-monitor';
import { EmployeeMonitorItemEmpty } from '../employee-monitor-item-empty';
import { EmployeeMonitorItem } from '../employee-monitor-item';
import { useCurrentUser } from '../../../hooks';

const ITEM_HEIGHT = 68;
const ITEM_SPACE = 8;

export const EmployeeActivitiesMonitor = memo(() => {
  const { t, tp } = useTranslate();
  const dispatch = useAppDispatch();
  const employeeList = useAppSelector(selectEmployeesMonitorEmployeesList);
  const { isLoading, isInit } = useAppSelector(selectEmployeesMonitorStatuses);

  const rows = employeeList;

  let height = 0;

  const sortedRows = useMemo(
    () =>
      [...rows].sort((a, b) => {
        const numA = a.lastActivityDate ? convertToDate(a.lastActivityDate).getTime() : 0;
        const numB = b.lastActivityDate ? convertToDate(b.lastActivityDate).getTime() : 0;

        return numB - numA;
      }),
    [rows],
  );

  const transitions = useTransition(
    sortedRows.map((data) => ({
      ...data,
      y: (height += ITEM_HEIGHT) - ITEM_HEIGHT,
      height: ITEM_HEIGHT - ITEM_SPACE,
    })),
    {
      key: (item: { id: string }) => item.id,
      from: { height: 0, opacity: 0 },
      leave: { height: 0, opacity: 0 },
      enter: ({ y, height }) => ({ y, height, opacity: 1 }),
      update: ({ y, height }) => ({ y, height }),
    },
  );

  const { $isAdmin } = useCurrentUser();

  const onRefresh = useCallback(() => {
    dispatch(actionEmployeesMonitorRefreshEmployees());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant={'h4'} component={'h3'} noWrap>
          {t('employee-activities-monitor')}
        </Typography>
        {$isAdmin && (
          <Button
            variant={'contained'}
            startIcon={<RefreshIcon />}
            color={'primary'}
            disabled={isLoading}
            onClick={onRefresh}
          >
            {tp('refresh')}
          </Button>
        )}
      </Stack>
      <NativeScroll>
        {isLoading && !isInit && <Loading />}
        <div className={classes.list} style={{ height: height }}>
          {transitions((style, item, t, index) => {
            return (
              <animated.div
                className={classes.card}
                style={{ zIndex: rows.length - index, ...style }}
              >
                <EmployeeMonitorItem
                  appIdentityUserID={String(item.appIdentityUserID)}
                  userPhoto={item.userPhoto}
                  firstName={item.firstName}
                  lastName={item.lastName}
                  lastActivityDate={item.lastActivityDate}
                  height={item.height}
                  mobilePhone={item.mobilePhone}
                  isActivityLow={item.isMustBeActive}
                  timeSlot={item.currentSlot}
                />
              </animated.div>
            );
          })}
        </div>
        {isInit && sortedRows.length === 0 && <EmployeeMonitorItemEmpty />}
      </NativeScroll>
    </div>
  );
});
