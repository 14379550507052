import React, { memo, useCallback, useEffect, useState } from 'react';
import GridConfig from './grid-config';
import { DialogUserPatientProfileSession } from 'components/dialoog-user-patient-profile-session';
import DataGrid from 'components/data-grid';
import { useFormGrid } from 'AurionCR/components/formV2';
import { usePermissions } from 'hooks/use-permissions';
import { patientEmitter } from '../../emitter';

interface Props {
  userPatientProfileID: string;
}
export const GridActivitiesQuestionnaire = memo<Props>(({ userPatientProfileID }) => {
  const [gridConfig] = useState<any>(GridConfig(userPatientProfileID));
  const { grid, onGridHandle, editItemID, setEditItemID } = useFormGrid('questionnaire');
  const isAllowToDelete = usePermissions('isAllowToDeleteAnything');

  const onRefresh = useCallback(() => {
    patientEmitter.emit('refresh-all', { patientID: userPatientProfileID });
  }, [userPatientProfileID]);

  useEffect(() => {
    const refresh = (payload: { patientID: string }) => {
      if (payload.patientID !== userPatientProfileID) {
        return;
      }
      grid.current?.onTasks([['getData']]);
    };

    patientEmitter.on('refresh-all', refresh);
    return () => {
      patientEmitter.off('refresh-all', refresh);
    };
  }, [userPatientProfileID, grid]);

  return (
    <>
      <DataGrid ref={grid} onHandle={onGridHandle} config={gridConfig} />
      {editItemID && typeof editItemID === 'string' && (
        <DialogUserPatientProfileSession
          onClose={() => setEditItemID(false)}
          itemID={editItemID}
          userPatientProfileID={userPatientProfileID}
          onRefresh={onRefresh}
          isShowDelete={isAllowToDelete}
        />
      )}
    </>
  );
});
