import { API_USER_PATIENT_PROFILE_PRESCRIPTION_DETAILS } from 'services/user-patient-profile-prescription-details';
import { createFilterEquals } from 'utils/dynamic-helpers';
import { ColumnMedicalPrescriptionDetailRule } from 'components/medical-prescription';
import { GridControls } from './grid-controls';
import React from 'react';

interface Options {
  userPatientProfilePrescriptionID: string;
}
export default ({ userPatientProfilePrescriptionID }: Options) => ({
  id: 'GridConfigDrugs',
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_PRESCRIPTION_DETAILS.GET_ALL_DYNAMIC,
      filters: createFilterEquals(
        'userPatientProfilePrescriptionID',
        userPatientProfilePrescriptionID,
      ),
      selected: [
        'id as userPatientProfilePrescriptionDetailID',
        'drugID',
        'userPatientProfilePrescriptionID',
        'new { drug.ruleForm29AlwaysRequiredForTheFirstTime, drug.ruleForm29RenewAfterNumberOfMonths, drug.ruleForm29NumberOfPrescriptions, drug.ruleForm29PrescriptionDosageFormTotalUnits, drug.ruleForm29AccumulateDosageFormTotalUnits, drug.ruleForm29MinAge } as drug',
        'drug.drugRegNum as drugRegNum',
        'userPatientProfilePrescription.userPatientProfile.dateOfBirth',
        'userPatientProfilePrescription.userPatientProfileID',
        'userPatientProfilePrescription.userEmployeeProfileID',
        'userPatientProfilePrescription.entryDate',
      ].join(','),
    },
  },
  toolbar: {
    title: null,
    controls: false,
    controlsDefault: false,
  },
  columns: [
    {
      title: 'drug',
      field: 'drug.catalogName as catalogName',
      sortable: 'drug.catalogName',
    },
    {
      title: 'dosage-form',
      field: 'dosageForm',
    },
    {
      title: 'dosage-form-total',
      field: 'dosageFormTotalDesc',
    },
    {
      title: 'dosage-form-daily',
      field: 'dosageFormDaily',
    },
    {
      title: 'instructions',
      field: 'instructions',
    },
    {
      title: 'form-29-rules',
      field: 'id',
      template: (data: any) => <ColumnMedicalPrescriptionDetailRule row={data} />,
      sortable: false,
    },
    {
      title: 'include-form29',
      field: 'includeForm29',
      template: 'boolean',
    },
  ],
  controls: [{ type: 'custom', template: GridControls }],
  pagination: {
    visible: false,
    size: 'all',
  },
});
