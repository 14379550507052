import React, { useCallback, useMemo } from 'react';
import style from './index.module.scss';
import VAR from 'styles/_variabels.scss';
import clsx from 'clsx';
import { Box, Divider, Typography } from '@material-ui/core';
import { Stack } from 'components/stack';
import { useTranslate } from 'hooks/use-translate';
import { Skeleton } from '@material-ui/lab';
import { PreviewSessionCard } from '../card';

interface SkeletonListProps {
  count: number;
}
const SkeletonList: React.FC<SkeletonListProps> = ({ count }) => {
  const list = useMemo(() => Array.from({ length: count }).fill(''), [count]);
  return (
    <div className={style.skeleton}>
      {list.map((_, i) => {
        return <Skeleton className={style.skeletonChip} key={i} width={'100%'} />;
      })}
    </div>
  );
};

interface Item {
  title: string | null | undefined;
}
interface Props {
  className?: string;
  title: string;
  date?: string | null | undefined;
  employee?: string | null | undefined;
  notebook?: string | null | undefined;
  items: Item[] | undefined;
  isLoading?: boolean;
  onPreview?: () => void;
  remarks: string | undefined;
}

const PreviewSessionEffectsComponent: React.FC<Props> = ({
  className,
  employee,
  notebook,
  title,
  date,
  items = [],
  isLoading,
  onPreview,
  remarks,
}) => {
  const { t } = useTranslate();

  const isEmpty = items ? items.length === 0 : false;

  const renderListItem = useCallback((item: Item, i: number) => {
    return (
      <Typography variant={'body2'} component={'div'} key={i}>
        {item.title}
      </Typography>
    );
  }, []);

  return (
    <PreviewSessionCard
      title={title}
      date={date}
      employee={employee}
      notebook={notebook}
      onPreview={onPreview}
      className={className}
    >
      {!isLoading && isEmpty && (
        <div className={style.empty}>
          <Typography color={'textPrimary'}>{t('empty')}</Typography>
        </div>
      )}
      {isLoading ? (
        <SkeletonList count={3} />
      ) : (
        <div className={clsx(style.content, remarks && style.contentFull)}>
          <Stack
            direction={'column'}
            style={{ padding: '0.5rem 0.5rem 0.5rem 1.2rem' }}
            spacing={0.5}
          >
            {items.map(renderListItem)}
          </Stack>
          {remarks && (
            <>
              <Divider orientation={'vertical'} style={{ backgroundColor: VAR.colorBorder }} />
              <Box p={'0.5rem 0.5rem 0.5rem 1rem'}>
                <Typography color={'secondary'} variant={'body2'} style={{ fontWeight: 'bold' }}>
                  {t('remarks')}:
                </Typography>
                <Typography color={'textSecondary'} variant={'body2'}>
                  {remarks}
                </Typography>
              </Box>
            </>
          )}
        </div>
      )}
    </PreviewSessionCard>
  );
};

interface PreviewSessionEffectsProps extends Props {
  hideIfEmpty?: boolean;
}
export const PreviewSessionEffects: React.FC<PreviewSessionEffectsProps> = ({
  hideIfEmpty = true,
  items,
  isLoading,
  ...rest
}) => {
  const isEmpty = items ? items.length === 0 : false;

  if (isEmpty && !isLoading && hideIfEmpty) {
    return null;
  }

  return <PreviewSessionEffectsComponent isLoading={isLoading} items={items} {...rest} />;
};
