import React, { useCallback, useState } from 'react';
import { DialogConfirm } from '../components/dialog-confirm';

type ConfirmProps = {
  onConfirm: () => void;
  onDecline: () => void;
  confirmMessage: string;
};

export const withConfirmBefore = <P extends Record<string, any>>(Component: React.FC<P>) => {
  const WithConfirmBefore = (props: P & ConfirmProps) => {
    const { onConfirm: _onConfirm, onDecline: _onDecline, confirmMessage, ...rest } = props;
    const [answer, setAnswer] = useState<null | boolean>(null);

    const onConfirm = useCallback(() => {
      setAnswer(true);
      _onConfirm();
    }, [_onConfirm]);

    const onDecline = useCallback(() => {
      setAnswer(false);
      _onDecline();
    }, [_onDecline]);

    if (!answer) {
      return <DialogConfirm message={confirmMessage} onConfirm={onConfirm} onCancel={onDecline} />;
    }

    return <Component {...(rest as unknown as P)} />;
  };

  return WithConfirmBefore;
};
