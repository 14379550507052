const API = 'MediaPrivateServices';

export const API_MEDIA_PRIVATE = {
  UPLOAD_FILE: `${API}/UploadFileToCloud`,
  DOWNLOAD_FILE: `${API}/DownloadFileFromCloud`,
  REMOVE_FILE: (data: string) => `${API}/RemoveFileFromCloud?filePath=${encodeURIComponent(data)}`,
};

export interface CloudFileViewModel {
  fileName: string;
  fileStreamString: string;
  filePath: string;
  isImage: boolean;
}
