import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { patientEmitter } from '../../emitter';
import { useTranslate } from 'hooks/use-translate';
import { useCurrentUser } from '../../../hooks';
import { calcPaginationSkip, calcPaginationState } from 'utils/service';
import {
  apiUserPatientProfileSessions,
  SessionForNotebookGeneratorPreview,
} from 'services/user-patient-profile-session';
import { useEffectError, useOpenValue } from 'hooks';
import { NativeScroll } from 'components/native-scroll';
import { SessionInformationCard } from 'components/session-information-list';
import { Loading } from 'AurionCR/components';
import { DataGridEmpty } from 'components/data-grid/components/data-grid-empty';
import { Pagination } from '@material-ui/lab';
import { AppSwitch } from 'components/app-switch';
import { Stack } from 'components/stack';
import { CreateNotebookForm } from '../../components';
import { Button, IconButton } from '@material-ui/core';
import { DialogUserPatientProfileSession } from 'components/dialoog-user-patient-profile-session';
import { usePermissions } from 'hooks/use-permissions';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RefreshIcon from '@material-ui/icons/Refresh';
import style from './index.module.scss';
import clsx from 'clsx';

const useFetchQuery = apiUserPatientProfileSessions.useGetSessionsFullInformationQuery;

const TAKE = 10;

interface Props {
  userPatientProfileID: string;
}
export const GridAllActivities = memo<Props>(({ userPatientProfileID }) => {
  const isAllowToDelete = usePermissions('isAllowToDeleteAnything');
  const openView = useOpenValue<string>();
  const onRefreshWeight = useCallback(() => {
    patientEmitter.emit('refresh-weight', { patientID: userPatientProfileID });
    patientEmitter.emit('refresh-notebooks', { patientID: userPatientProfileID });
  }, [userPatientProfileID]);

  const { t, tp } = useTranslate();
  const { appUserID } = useCurrentUser();
  const ref = useRef<HTMLDivElement>(null);
  const [relativeToMe, setRelativeToMe] = useState(false);
  const [page, setPage] = useState(1);
  const skip = useMemo(() => {
    return calcPaginationSkip({ take: TAKE, page });
  }, [page]);

  const scrollTop = useCallback(() => {
    const el = ref.current;
    if (!el) return;

    el.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const result = useFetchQuery({
    userEmployeeProfileID: relativeToMe ? appUserID : null,
    userPatientProfileID,
    take: TAKE,
    skip,
  });
  useEffectError(result.error);

  const { value = [], count = 0 } = result.data || {};
  const { pages } = useMemo(() => {
    return calcPaginationState({ take: TAKE, page, count });
  }, [page, count]);

  const isEmpty = result.isSuccess && value.length === 0;

  const onChangeRelative = useCallback(
    (_: any, value: boolean) => {
      setRelativeToMe(value);
      setPage(1);
      scrollTop();
    },
    [scrollTop],
  );
  const onChangePage = useCallback(
    (_: any, page: number) => {
      setPage(page);
      scrollTop();
    },
    [scrollTop],
  );

  const onRefresh = useCallback(() => {
    patientEmitter.emit('refresh-all', { patientID: userPatientProfileID });
    result.refetch();
  }, [userPatientProfileID, result]);

  const factoryClick = useCallback(
    (row: SessionForNotebookGeneratorPreview) => {
      if (row.notebook.isForPrescriptionOnly) {
        return undefined;
      }
      return () => openView.onOpen(row.id);
    },
    [openView],
  );

  return (
    <>
      <div className={style.root}>
        <Stack alignItems={'center'} justifyContent={'space-between'} width={'100%'} mb={1}>
          <div>
            <CreateNotebookForm
              userPatientProfileID={userPatientProfileID}
              onRefresh={onRefresh}
              onUpdateWeightDate={onRefreshWeight}
            />
          </div>
          <Button
            startIcon={<RefreshIcon />}
            color={'primary'}
            variant={'contained'}
            onClick={result.refetch}
            disabled={result.isFetching}
          >
            {tp('refresh')}
          </Button>
        </Stack>
        <NativeScroll ref={ref} mode={'visible'} mx={-1} sx={{ width: 'unset!important' }}>
          <div style={{ position: 'absolute', inset: 0 }}>
            <Stack spacing={2} direction={'column'} px={1}>
              {value.map((row) => {
                const onClick = factoryClick(row);
                return (
                  <SessionInformationCard
                    className={clsx({ [style.card]: true, [style.cardEditable]: onClick })}
                    key={row.id}
                    data={row}
                    onClick={onClick}
                    middleSlot={
                      onClick ? (
                        <IconButton color={'primary'}>
                          <VisibilityIcon />
                        </IconButton>
                      ) : undefined
                    }
                  />
                );
              })}
            </Stack>
            {result.isFetching && <Loading active />}
            {isEmpty && <DataGridEmpty />}
          </div>
        </NativeScroll>
        <Stack alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
          <Pagination
            color={'primary'}
            page={page}
            onChange={onChangePage}
            count={pages}
            variant="outlined"
            shape="rounded"
            disabled={result.isFetching}
          />
          <AppSwitch
            disabled={result.isFetching}
            label={t('relative-to-me')}
            value={relativeToMe}
            onChange={onChangeRelative}
          />
        </Stack>
      </div>
      {openView.openValue && (
        <DialogUserPatientProfileSession
          onClose={openView.onClose}
          itemID={openView.openValue}
          userPatientProfileID={userPatientProfileID}
          onRefresh={onRefresh}
          isShowDelete={isAllowToDelete}
        />
      )}
    </>
  );
});
