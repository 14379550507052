import React, { memo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { TMMerge } from '../../store';
import { DialogUserPatientProfileSession } from 'components/dialoog-user-patient-profile-session';

export const DialogPreviewUserPatientProfileSession = memo(() => {
  const dispatch = useAppDispatch();

  const { dialogUserPatientProfileSession } = useAppSelector((state) => state.TaskManager);

  const handleClose = useCallback(() => {
    dispatch(TMMerge({ dialogUserPatientProfileSession: null }));
  }, [dispatch]);

  return dialogUserPatientProfileSession ? (
    <DialogUserPatientProfileSession
      onClose={handleClose}
      itemID={dialogUserPatientProfileSession.userPatientProfileSessionID as string}
      userPatientProfileID={dialogUserPatientProfileSession.userPatientProfileID as string}
      isShowDelete={false}
    />
  ) : null;
});

export default DialogUserPatientProfileSession;
