import update from 'immutability-helper';
import { iTaskManager, iTMActionTypes } from './@type';
import { createTimes, iTMAction } from './helpers';
import { SUPPORT_MEETING_PATIENT_OPTIONS } from '../../helpers';

export const initialState = (): iTaskManager => ({
  // flags
  init: false,
  loading: false,
  view: 'list',
  // dialogs
  editMeeting: null,
  confirmDeleteMeeting: null,
  sendSms: null,
  dialogUserPatientProfileSession: null,
  dialogNotebookFormGenerator: null,
  dialogPrescription: null,
  // sources
  employees: { list: [], map: {} },
  meetingTypes: { list: [], map: {} },

  times: createTimes(),
  // CALENDAR
  calendar: {
    loading: false,
    // filter
    filters: {
      date: new Date(),
      supportMeetingTypeIDs: [],
      search: '',
      taskType: 'userPatientProfileSessionID==null',
      isActivePatient: SUPPORT_MEETING_PATIENT_OPTIONS.ALL,
    },
    // data
    data: [],
    dayViewItems: {},
    dayViewItemsPrescription: {},
    // dayView
    dialogDayViewItems: null,
    dialogDayViewItemsPrescription: null,
    resizeDayViewItem: null,
    startDragDayViewItem: 0,
    // canToMove
    canToMove: [],
  },
  // LIST
  list: {
    // flags
    isRefreshingActivities: false,
    loading: false,
    // order
    orderField: 'meetingFromDateTime,meetingToDateTime',
    orderDirection: '',
    // pagination
    page: 1,
    pageSize: 100,
    // filters
    filters: {
      date: [new Date(), new Date()],
      remarkForPatientCallStatusID: null,
      userEmployeeProfileID: '',
      supportMeetingTypeIDs: [],
      search: '',
      taskType: 'userPatientProfileSessionID==null',
      isActivePatient: SUPPORT_MEETING_PATIENT_OPTIONS.ALL,
    },
    // data
    data: [],
    dataCount: 0,
    // canToMove
    canToMove: [],
    // trigger update scroll
    triggerScrollUpdate: '',
  },
  itemData: null,

  clinicalMeetings: [],
});

export const TaskManager = (state = initialState(), action: iTMAction): iTaskManager => {
  switch (action.type) {
    case iTMActionTypes.TM_RESET: {
      return update(state, {
        calendar: {
          $merge: {
            dialogDayViewItems: null,
          },
        },
      });
    }
    case iTMActionTypes.TM_MERGE: {
      return update(state, { $merge: action.payload });
    }
    case iTMActionTypes.TM_VIEW_MERGE: {
      const { view, ...rest } = action.payload;
      return update(state, { [view]: { $merge: rest } });
    }
    case iTMActionTypes.TM_FILTER_MERGE: {
      const { view, ...rest } = action.payload;
      return update(state, { [view]: { filters: { $merge: rest } } });
    }
    case iTMActionTypes.TM_SELECT: {
      const { itemID, value } = action.payload;

      const itemIndex = state.list.data.findIndex((item) => item.id === itemID);

      return update(state, {
        list: {
          data: { [itemIndex]: { $isSelected: { $set: value } } },
        },
      });
    }
    case iTMActionTypes.SET_CLINICAL_MEETINGS: {
      return update(state, {
        clinicalMeetings: {
          $set: action.payload,
        },
      });
    }
    default:
      return state;
  }
};
