import React, { useContext, useMemo } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { he, enUS } from 'date-fns/locale';
import { useAppSelector } from 'store';
import { selectLanguageIsRtl } from 'store/languages';

type Locale = typeof he | typeof enUS;
type Value = {
  locale: Locale;
};
const ContextLocale = React.createContext<Value | null>(null);

export const useDateLocale = () => {
  const context = useContext(ContextLocale);
  if (!context) {
    throw new Error('useDateLocale: Context error');
  }

  return context;
};

export const PickerProvider: React.FC = ({ children }) => {
  const isRtl = useAppSelector(selectLanguageIsRtl);
  const locale = isRtl ? he : enUS;

  const value = useMemo(() => ({ locale }), [locale]);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      <ContextLocale.Provider value={value}>{children}</ContextLocale.Provider>
    </MuiPickersUtilsProvider>
  );
};
