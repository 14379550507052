export interface ITypedStorage<T extends Record<string, any>> {
  setObject(data: T): void;
  getObject(): T | null;
  removeObject(): void;
}
export interface ITypedStorageValue<T extends Record<string, any>> {
  __v: any;
  data: T;
}
export class TypedStorage<T extends Record<string, any>> implements ITypedStorage<T> {
  key: string;
  version: any;
  constructor(prefixKey: string, version = '0.0.1') {
    this.key = prefixKey;
    this.version = version;
  }
  removeObject = () => {
    localStorage.removeItem(this.key);
  };
  setObject = (data: T) => {
    if (!data) {
      return;
    }
    const value = JSON.stringify({ __v: this.version, data });
    localStorage.setItem(this.key, value);
  };
  getObject = () => {
    const value = localStorage.getItem(this.key);

    if (!value) {
      return null;
    }

    try {
      const parsedValue = JSON.parse(value) as ITypedStorageValue<T>;
      if (parsedValue.__v !== this.version) {
        this.removeObject();
        return null;
      }

      return parsedValue.data || null;
    } catch {
      this.removeObject();
      return null;
    }
  };
}
