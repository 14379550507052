import React, { memo } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { useTranslate } from 'hooks/use-translate';
import { GridTaskActivities } from '../grid-activities';
import style from './index.module.scss';

interface Props {
  toDoTaskID: string;
  onClose: () => void;
  onRefresh?: () => void;
}
export const DialogToDoActivities = memo<Props>(({ toDoTaskID, onClose, onRefresh }) => {
  const { t } = useTranslate();
  return (
    <Dialog
      open={true}
      disableEscapeKeyDown={true}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      className={style.dialog}
    >
      <DialogHeading title={t('activities')} onClose={onClose} />
      <DialogContent>
        <GridTaskActivities toDoTaskID={toDoTaskID} onRefresh={onRefresh} />
      </DialogContent>
    </Dialog>
  );
});
