import React, {FC, memo} from "react";
import { Box, BoxProps, CircularProgress } from "@material-ui/core";
import {useSelector} from "react-redux";
import {useAppSelector} from "store";
import {selectLanguagesStatuses} from "store/languages";
import {selectLabelsStatuses} from "store/labels";

interface Props extends BoxProps{
  active?: boolean;
  zIndex?: number;
  background?: string;
  size?: number;
  children?: React.ReactNode
}
export const Loading = memo<Props>((
  {
    active = true,
    zIndex = 1000,
    background = 'rgba(255,255,255,.5)',
    size = 50,
    children,
    ...rest
  }) => {
  if (!active) return null;
  return (
    <Box
      position={'absolute'}
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex={zIndex}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      {...rest}
      style={{
        background: background,
        ...rest.style
      }}
    >
      <CircularProgress size={size}/>
      {children}
    </Box>
  )
});
export const LoadingOverlayProvider: FC = ({children}) => {
  const auth = useSelector(state => (state as any).auth);
  const languagesStatuses = useAppSelector(selectLanguagesStatuses)
  const labelsStatuses = useAppSelector(selectLabelsStatuses)

  return (
    <>
      {auth.init && children}
      <Loading active={auth.loading || labelsStatuses.isLoading || languagesStatuses.isLoading}/>
    </>
  );
};
