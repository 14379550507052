import React, { memo, useCallback, useEffect } from 'react';
import { CircularProgress, Dialog, DialogContent } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { useTranslate } from 'hooks/use-translate';
import { useEffectError, useFetchDynamic } from 'hooks';
import { ServiceUserPatientProfileSessions } from 'services/user-patient-profile-session';
import {
  DialogUserPatientDeclaration,
  DialogUserPatientProfileSessionWrap,
  TagSurvey,
} from './components';
import { Stack } from '../stack';
import { DialogUserPatientProfileSessionProps } from './model';

export { TagSurvey };

interface Props extends DialogUserPatientProfileSessionProps {}

export const DialogUserPatientProfileSession = memo<Props>((props) => {
  const { t } = useTranslate();
  const itemID = props.itemID;
  const fetchSession = useCallback((id: string) => {
    return ServiceUserPatientProfileSessions.getDynamic<{
      id: string;
      isForPatient: boolean;
      entryDate: string;
    }>(id, {
      select: ['id', 'notebook.isForPatient as isForPatient'].join(','),
    });
  }, []);

  const [triggerGet, { data, isLoading, error }] = useFetchDynamic(fetchSession);
  useEffectError(error);
  useEffect(() => {
    if (itemID) {
      triggerGet(itemID);
    }
  }, [itemID, triggerGet]);

  if (isLoading) {
    return (
      <Dialog open={true} fullWidth maxWidth={'sm'} onClose={props.onClose}>
        <DialogHeading title={t('loading')} onClose={props.onClose} />
        <DialogContent>
          <Stack alignItems={'center'} justifyContent={'center'}>
            <CircularProgress />
          </Stack>
        </DialogContent>
      </Dialog>
    );
  }

  if (data?.isForPatient) {
    return <DialogUserPatientDeclaration {...props} />;
  }

  return <DialogUserPatientProfileSessionWrap {...props} />;
});
