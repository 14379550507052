import { createFilterEquals, mergeFilters } from 'utils/dynamic-helpers';
import React from 'react';
import { TemplateEmployee } from 'components/templates/template-employee';
import { API_USER_PATIENT_PROFILE_SESSIONS_CLINIC_DRUGS } from 'services/user-patient-profile-session-clinic-drugs';
import { GridModel } from './helpers';
import { ControlsDropDown } from './controls-drop-down';
import { GridControlsDropDownTemplateProps } from 'models/grid';
import { TemplateFullSessionInfo } from 'components/templates/template-full-info';
import { TemplateDate } from 'components/templates/template-date';

interface Options {
  userPatientProfileID: string;
  userPatientProfileSessionID: string | null;
}
export default (options: Options): any => ({
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_SESSIONS_CLINIC_DRUGS.GET_ALL_DYNAMIC,
      filters: mergeFilters(
        createFilterEquals(
          'userPatientProfileSession.userPatientProfileID',
          options.userPatientProfileID,
        ),
      ).join('&&'),
      selected: [
        'isActive',
        'userPatientProfileSessionID',
        'drugID',
        'userPatientProfileSession.notebookID',
        'userPatientProfileSession.notebook.isForPrescriptionOnly as isAutoGenerated',
        'new { userPatientProfileSession.userEmployeeProfile.firstName, userPatientProfileSession.userEmployeeProfile.lastName } as userEmployeeProfile',
      ].join(','),
    },
    create: {
      url: API_USER_PATIENT_PROFILE_SESSIONS_CLINIC_DRUGS.POST,
    },
    patch: {
      url: API_USER_PATIENT_PROFILE_SESSIONS_CLINIC_DRUGS.PATCH,
    },
    delete: {
      url: API_USER_PATIENT_PROFILE_SESSIONS_CLINIC_DRUGS.DELETE,
    },
    sortable: {
      field: 'createdDate',
      order: 'desc',
    },
  },
  controlsDropDown: [
    ...(options.userPatientProfileSessionID
      ? [
          {
            type: 'custom',
            template: (props: GridControlsDropDownTemplateProps<GridModel>) => (
              <ControlsDropDown
                userPatientProfileSessionID={options.userPatientProfileSessionID as string}
                {...props}
              />
            ),
          },
        ]
      : []),
    { type: 'delete', title: 'delete', permission: null },
  ],
  toolbar: {
    title: '',
    filters: null,
    controls: null,
    controlsDefault: {
      export: null,
      new: null,
    },
  },
  columns: [
    {
      title: '',
      field: 'userPatientProfileSessionID',
      template: (data: GridModel) => (
        <TemplateFullSessionInfo
          userPatientProfileSessionID={data.userPatientProfileSessionID as string}
        />
      ),
      sortable: false,
    },
    {
      title: 'entry-date',
      field: 'createdDate',
      template: (data: GridModel) => <TemplateDate date={data.createdDate} />,
      sortable: false,
    },
    {
      title: 'employee',
      field: 'id',
      sortable: false,
      template: (data: GridModel) => <TemplateEmployee employee={data.userEmployeeProfile} />,
    },
    {
      title: 'notebook',
      field: 'userPatientProfileSession.notebook.labelKey as notebookLabelKey',
      sortable: 'userPatientProfileSession.notebook.labelKey',
    },
    {
      title: 'clinic-drug',
      field: 'clinicDrug.catalogName as catalogName',
      sortable: 'clinicDrug.catalogName',
    },
    {
      title: 'dosage',
      field: 'dosageForm',
      editor: 'string',
    },
    {
      title: 'remarks',
      field: 'remarks',
      editor: 'string',
    },
  ],
  pagination: false,
});
