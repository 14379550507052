const API_ENDPOINT = 'UserEmployeeProfilePermissionNotebooks';

export const API_USER_EMPLOYEE_PROFILE_PERMISSION_NOTEBOOKS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<Components.Schemas.UserEmployeeProfileToDoTask, 'id'>) =>
    `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<Components.Schemas.UserEmployeeProfileToDoTask, 'id'>) =>
    `${API_ENDPOINT}/Delete/${data.id}`,
  INSERT_BULK: `${API_ENDPOINT}/CreateUserEmployeeProfileNotebookBulk`,
};

export interface UserEmployeeProfilePermissionNotebookInput {
  notebookID: string;
  userEmployeeProfilePermissionIDs: string[];
}
