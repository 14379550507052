import React, { memo, useMemo } from 'react';
import { UserPatientProfileEditGeneralInput } from 'services/user-patient-profile';

export type UpdateDescription = { name: string; oldValue: string; newValue: string };
export type UpdateSignature = (
  formData: Record<string, any>,
  description: UpdateDescription,
) => void;

interface ContextValue {
  patient: undefined | UserPatientProfileEditGeneralInput;
  onUpdate: undefined | UpdateSignature;
}
export const ProviderPatientDataContext = React.createContext<ContextValue | null>(null);

interface Props {
  patient: undefined | UserPatientProfileEditGeneralInput;
  onUpdate: undefined | UpdateSignature;
  children: React.ReactNode;
}
export const ProviderPatientData = memo<Props>(({ children, patient, onUpdate }) => {
  const value = useMemo(() => ({ patient, onUpdate }), [patient, onUpdate]);

  return (
    <ProviderPatientDataContext.Provider value={value}>
      {children}
    </ProviderPatientDataContext.Provider>
  );
});
