import { useTranslate } from 'hooks/use-translate';
import React, { HTMLProps, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ErrorFile,
  ErrorFileCode,
  fileToBase64,
  getFileAccept,
  validateFileSize,
  validateFileType,
  ValueFileUploader,
} from 'utils/file-uploader';
import { parseErrorData } from 'utils/service';
import { useAppDispatch } from 'store';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';

export type FileAccept =
  | '.png'
  | '.jpg'
  | '.jpeg'
  | '.doc'
  | '.docx'
  | '.svg'
  | '.xml'
  | '.xlsx'
  | '.pdf';
export type SkipValidationsContentType = 'image/svg+xml';

export interface UseFileUploaderProps<Multiple extends boolean = false> {
  accept?: FileAccept[];
  skipValidationsContentTypes?: SkipValidationsContentType[];
  maxSize?: number;
  convertFileToBase64?: (file: File) => Promise<string>;
  disabled?: boolean | undefined;
  multiple?: Multiple;
  onChange?: Multiple extends true
    ? (v: ValueFileUploader[], files: File[]) => void
    : (v: ValueFileUploader | null, file: File | null) => void;
}

export const useFileUploader = <M extends boolean = false>(props: UseFileUploaderProps<M>) => {
  const {
    accept,
    maxSize = 2,
    convertFileToBase64 = fileToBase64,
    disabled,
    skipValidationsContentTypes,
  } = props;
  const ref = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<null | ErrorFile>(null);

  const { inputType, contentTypes } = useMemo(() => getFileAccept(accept), [accept]);

  const onChangeWrap = useCallback(
    (v: ValueFileUploader[], files: File[]) => {
      if (props.multiple === true) {
        // @ts-ignore
        props.onChange && props.onChange(v, files);
      } else {
        // @ts-ignore
        props.onChange && props.onChange(v[0] || null, files[0] || null);
      }
    },
    [props],
  );

  const setFileList = useCallback(
    async (files: FileList | null) => {
      setError(null);

      if (!files) {
        return;
      }

      try {
        const result = await Promise.all(
          Array.from(files).map(async (file) => {
            await validateFileType({
              file,
              contentTypes,
              skipContentTypes: skipValidationsContentTypes || [],
            });
            await validateFileSize(file, maxSize);

            let value = await convertFileToBase64(file);

            return { name: file.name, size: file.size, type: file.type, value, file };
          }),
        );
        onChangeWrap(result, Array.from(files || []));
      } catch (e: any) {
        setError(e);
      }
    },
    [contentTypes, maxSize, onChangeWrap, convertFileToBase64, skipValidationsContentTypes],
  );
  const _onChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setError(null);
      let { files } = e.target;
      if (!files) {
        return;
      }
      setFileList(files);
    },
    [setFileList],
  );

  const inputProps = useMemo<HTMLProps<HTMLInputElement>>(() => {
    return {
      ref,
      type: 'file',
      hidden: true,
      onChange: _onChange,
      accept: inputType,
      multiple: props.multiple,
      disabled,
      onClick: () => {
        if (ref.current) {
          ref.current.value = '';
        }
      },
    };
  }, [inputType, _onChange, props.multiple, disabled]);

  const onOpen = useCallback(() => {
    ref.current?.click();
  }, []);
  const onRemove = useCallback(() => {
    if (ref.current) {
      ref.current.value = '';
      onChangeWrap([], []);
    }
  }, [onChangeWrap]);

  return { inputProps, onOpen, onRemove, error, setFileList };
};

interface ErrorOptions {
  maxSize: number;
  accept?: string[];
}
export const useFileUploaderErrorText = (error: ErrorFile | null, options: ErrorOptions) => {
  const { maxSize, accept: _accept } = options;
  const accept = String(_accept);
  const { tp, t } = useTranslate();

  return useMemo(() => {
    if (error?.code === ErrorFileCode.MAX_SIZE) {
      return tp('file-error-size', { maxSize });
    } else if (error?.code === ErrorFileCode.FILE_TYPE) {
      return tp('file-error-type', { accept });
    } else if (error) {
      return t(parseErrorData(error).message);
    }
    return null;
  }, [t, tp, accept, error, maxSize]);
};
export const useFileUploaderNotifyError = (error: ErrorFile | null, options: ErrorOptions) => {
  const message = useFileUploaderErrorText(error, options);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (message) {
      dispatch(notifyRequestResult(message, 'error'));
    }
  }, [message, dispatch]);
};
