const API_ENDPOINT = 'UserEmployeeProfileWebPushNotifications';

export const API_USER_EMPLOYEE_PROFILE_WEB_PUSH_NOTIFICATIONS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<UserEmployeeProfileWebPushNotification, 'id'>) =>
    `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<UserEmployeeProfileWebPushNotification, 'id'>) =>
    `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface UserEmployeeProfileWebPushNotification {
  id: string;
  rowIndex: number;
  isActive: boolean;
  userEmployeeProfileID: string;
  subscription: string;
}
