import { configureStore, combineReducers, ThunkAction, Action } from '@reduxjs/toolkit';
import { apiRtk } from 'utils/rtk-query';
import createSagaMiddleware from 'redux-saga';
import { all } from 'typed-redux-saga';

// stores
import notify from 'AurionCR/store/modules/notify';
import routes from 'AurionCR/store/modules/routes';
import sources from 'AurionCR/store/modules/sources';

// sagas
import sourceSaga from 'AurionCR/store/sagas/source';
// custom
import { notebooks, notebooksSagas } from 'pages/Settings/Notebooks/Notebooks';
import { TaskManager, TaskManagerSagas } from 'pages/TaskManager/components/store';
import { reducerClinicalMeetings, sagasClinicalMeetings } from 'pages/ClinicalMeetings/store';
import { pdfFormDocument, pdfFormDocumentSagas } from 'components/pdf-document-constructor';
import { pdfDocumentSubmitReducer, pdfDocumentSubmitSagas } from 'components/pdf-document-submit';
import { loosingWeightSagas, loosingWeightReducer } from 'pages/Patients/LoosingWeightReport';
import { sideEffectsSagas, sideEffectsReducer } from 'pages/Patients/SideEffectsReport';

//
import { appAuthReducer, authSaga } from './auth';
import { languagesSaga, languagesReducer } from './languages';
import { labelsSaga, labelsReducer } from './labels';
import { reducerPatientMaker } from './patient-maker';
import { employeesMonitorSagas, employeesMonitorReducer } from './employees-monitor';
import { patientSmsMessagesSagas, patientSmsMessagesReducer } from './patient-sms-messages';
import {
  notebookFormGeneratorReducer,
  notebookFormGeneratorSagas,
} from './notebook-form-generator';
import {
  clinicalMeetingActivitiesReducer,
  clinicalMeetingActivitiesSagas,
} from './clinical-meeting-activities';
import {
  supportMeetingActivitiesReducer,
  supportMeetingActivitiesSagas,
} from './support-meeting-activities';
import { userPatientProfileActivitiesSagas } from './user-patient-profile-activities';
import { searchedPatientsReducer } from './searched-patients';
import { dietDiariesReportSagas, dietDiariesReducer } from 'pages/Patients/DietDiariesReport';

const sagas = [
  ...authSaga,
  ...sourceSaga,
  ...notebooksSagas,
  ...pdfFormDocumentSagas,
  ...pdfDocumentSubmitSagas,
  ...TaskManagerSagas,
  ...sagasClinicalMeetings,
  ...languagesSaga,
  ...labelsSaga,
  ...employeesMonitorSagas,
  ...patientSmsMessagesSagas,
  ...notebookFormGeneratorSagas,
  ...clinicalMeetingActivitiesSagas,
  ...supportMeetingActivitiesSagas,
  ...userPatientProfileActivitiesSagas,
  ...loosingWeightSagas,
  ...sideEffectsSagas,
  ...dietDiariesReportSagas,
];

const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({
  auth: appAuthReducer,
  notify,
  routes,
  sources,
  languages: languagesReducer,
  labels: labelsReducer,
  notebooks,
  pdfFormDocument,
  pdfDocumentSubmit: pdfDocumentSubmitReducer,
  TaskManager,
  clinicalMeetings: reducerClinicalMeetings,
  patientMaker: reducerPatientMaker,
  employeesMonitor: employeesMonitorReducer,
  searchedPatients: searchedPatientsReducer,
  patientSmsMessages: patientSmsMessagesReducer,
  notebookFormGenerator: notebookFormGeneratorReducer,
  clinicalMeetingActivities: clinicalMeetingActivitiesReducer,
  supportMeetingActivities: supportMeetingActivitiesReducer,
  loosingWeight: loosingWeightReducer,
  sideEffects: sideEffectsReducer,
  dietDiariesReport: dietDiariesReducer,
  [apiRtk.reducerPath]: apiRtk.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
      thunk: true,
    })
      .concat(sagaMiddleware)
      .concat(apiRtk.middleware),
  devTools: process.env.NODE_ENV === 'development',
});

function* rootSaga() {
  yield all(sagas);
}

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppAsyncThunkConfig = {
  state: RootState;
  dispatch: AppDispatch;
  serializedErrorType: Error;
};
