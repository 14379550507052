import React from 'react';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import style from './index.module.scss';
import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'hooks/use-translate';

export const EmployeeMonitorItemEmpty: React.FC = () => {
  const { tp } = useTranslate();
  return (
    <div className={style.root}>
      <AccountCircleOutlinedIcon fontSize={'inherit'} className={style.icon} />
      <Typography color={'secondary'}> {tp('employee-activities-monitor-items-empty')}</Typography>
    </div>
  );
};
