import React, { memo, useMemo } from 'react';
import { BoxProps, Tooltip, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { convertToDate } from 'utils/dates';
import { APP_FORMAT_DATE, APP_FORMAT_DATE_TIME } from 'configs/const';
import style from './index.module.scss';
import clsx from 'clsx';
import { useTranslate } from 'hooks/use-translate';
import { usePatientSubscriptionStatus } from 'components/dialog-patient/hooks';
import { Stack } from 'components/stack';

interface Props extends BoxProps {
  label: string;
  title: string | undefined;
  start: string | undefined;
  end: string | undefined;
}

export const SubscriptionInfo = memo<Props>(({ label, title, start, end, ...rest }) => {
  const { t } = useTranslate();
  const startDate = useMemo(() => {
    const value = convertToDate(start);
    return {
      date: format(value, APP_FORMAT_DATE),
      dateTime: format(value, APP_FORMAT_DATE_TIME),
    };
  }, [start]);
  const endDate = useMemo(() => {
    const value = convertToDate(end);
    return {
      date: format(value, APP_FORMAT_DATE),
      dateTime: format(value, APP_FORMAT_DATE_TIME),
    };
  }, [end]);

  const subscription = useMemo(() => {
    return { endDate: end };
  }, [end]);

  const { isEnd, isCloseToEnd, notifyMessage } = usePatientSubscriptionStatus(subscription);

  const isError = isEnd || isCloseToEnd;
  return (
    <Stack direction={'column'} {...rest} className={clsx(style.root, rest?.className)}>
      <Typography color={'secondary'} variant={'caption'}>
        {label}
      </Typography>
      <Typography>{title}</Typography>
      <div className={style.labelBox}>
        <Typography className={style.labelLabel} color={'secondary'} variant={'caption'}>
          {t('start-treatment-date')}
        </Typography>
        <Typography component={'time'} dateTime={startDate.dateTime}>
          {startDate.date}
        </Typography>
      </div>
      <Tooltip title={notifyMessage} arrow>
        <div className={clsx(style.labelBox, isError && style.error)}>
          <Typography className={style.labelLabel} color={'secondary'} variant={'caption'}>
            {t('end-date')}
          </Typography>
          <Typography component={'time'} dateTime={endDate.dateTime}>
            {endDate.date}
          </Typography>
        </div>
      </Tooltip>
    </Stack>
  );
});
