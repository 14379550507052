import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actionPatientSmsMessagesSendMessage } from './actions';

type BaseTemplatePayload<T> = {
  patient?: Pick<Components.Schemas.UserPatientProfile, 'firstName' | 'lastName'>;
  currentEmployee?: Pick<Components.Schemas.UserEmployeeProfile, 'firstName' | 'lastName'>;
} & T;

type TemplatePayloadMeeting = {
  meetingEmployee: Pick<Components.Schemas.UserEmployeeProfile, 'firstName' | 'lastName'>;

  meetingFromTime: string;
  meetingFromDate: string;

  meetingToTime: string;
  meetingToDate: string;
};

type TemplatePayloadSignDocument = {
  signDocumentUrl: string;
  signDocumentDuration: string;
  signDocumentExpiredDate: string;
};

type PatientSmsMessageTemplatePayload = BaseTemplatePayload<
  TemplatePayloadMeeting | TemplatePayloadSignDocument | {}
>;

export interface PatientSmsMessageItem {
  uid: string;

  formData: Components.Schemas.SMSMessagePatientRequest;
  templatePayload: PatientSmsMessageTemplatePayload;

  _isLoading: boolean;

  onSuccess?: (result: Components.Schemas.SMSMessagePatientRequest) => void;
  onError?: (error: any) => void;
}

interface State {
  smsMessageList: PatientSmsMessageItem[];
}
const initialState = (): State => ({
  smsMessageList: [],
});

const slice = createSlice({
  name: 'PATIENT_SMS_MESSAGES',
  initialState,
  reducers: {
    actionPatientMessageAddItem(
      state,
      action: PayloadAction<
        Pick<
          PatientSmsMessageItem,
          'uid' | 'formData' | 'templatePayload' | 'onSuccess' | 'onError'
        >
      >,
    ) {
      state.smsMessageList.push({
        ...action.payload,
        _isLoading: false,
      });
    },
    actionPatientMessageRemoveItem(
      state,
      action: PayloadAction<Pick<PatientSmsMessageItem, 'uid'>>,
    ) {
      state.smsMessageList = state.smsMessageList.filter((item) => {
        return item.uid !== action.payload.uid;
      });
    },
  },
  extraReducers(build) {
    build
      .addCase(actionPatientSmsMessagesSendMessage.pending, (state, action) => {
        state.smsMessageList = state.smsMessageList.map((item) => {
          return item.uid === action.meta.arg.item.uid ? { ...item, _isLoading: true } : item;
        });
      })
      .addCase(actionPatientSmsMessagesSendMessage.fulfilled, (state, action) => {
        state.smsMessageList = state.smsMessageList.filter((item) => {
          return item.uid !== action.meta.arg.item.uid;
        });
      })
      .addCase(actionPatientSmsMessagesSendMessage.rejected, (state, action) => {
        state.smsMessageList = state.smsMessageList.map((item) => {
          return item.uid === action.meta.arg.item.uid ? { ...item, _isLoading: false } : item;
        });
      });
  },
});
export const { actionPatientMessageAddItem, actionPatientMessageRemoveItem } = slice.actions;
export const patientSmsMessagesReducer = slice.reducer;
