export type ArrayType<T> = T extends Array<infer U> ? U : never;
export type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;
export type PromiseType<T> = T extends Promise<infer U> ? U : never;
export type PatchPartial<T, K extends keyof T> = Partial<T> & Pick<T, K>;
export type ConditionPartial<T, C, Rest> = T extends C ? Partial<Rest> : Rest;
export type Unset<T = string> = T | undefined | null;

export type FixServerObject<T, K extends keyof T> = T &
  Required<{ [P in K]: Exclude<T[P], null | undefined> }>;
export type PickServerObject<T, K extends keyof T> = Pick<FixServerObject<T, K>, K>;

export type Nullable<T extends Record<any, any>> = {
  [P in keyof T]: T[P] | null;
};
export type PartialAndNullable<T> = Nullable<Partial<T>>;

export type Primitive = string | null | number | undefined | symbol | boolean;

export type Left<T, Allowed extends unknown = Primitive> = {
  [P in keyof T]: Extract<T[P], Allowed>;
};

export const isSettledRejected = (
  input: PromiseSettledResult<unknown>,
): input is PromiseRejectedResult => input.status === 'rejected';

export const isSettledFulfilled = <T>(
  input: PromiseSettledResult<T>,
): input is PromiseFulfilledResult<T> => input.status === 'fulfilled';
