import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';
import { ArrayType } from 'utils/types';
import { calcPaginationState } from 'utils/service';
import { ICMDayViewItem } from 'pages/ClinicalMeetings/store/@type';
import { differenceInMinutes, format } from 'date-fns';
import { MEETING_TYPE_KEY } from 'services/clinical-meeting-types';
import { selectAuthUser } from 'store/auth';
import { ServiceClinicalMeetings } from 'services/clinical-meetings';
import { CALENDAR_RANGE_MINUTES } from 'configs/const';

export const selectCMState = (state: RootState) => state.clinicalMeetings;

export const selectCMStatuses = createSelector(selectCMState, ({ view, loading, init }) => {
  return { view, loading, init };
});
export const selectCMView = createSelector(selectCMStatuses, ({ view }) => {
  return view;
});

export const selectCMMeetingTypes = createSelector(selectCMState, ({ meetingTypes }) => {
  return meetingTypes;
});
export const selectCMMeetingTypesMap = createSelector(selectCMMeetingTypes, (list) => {
  return list.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {} as Record<string, ArrayType<typeof list>>);
});

export const selectCMMeetingSubjects = createSelector(selectCMState, ({ meetingSubjects }) => {
  return meetingSubjects;
});
export const selectCMMeetingSubjectsMap = createSelector(selectCMMeetingSubjects, (list) => {
  return list.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {} as Record<string, ArrayType<typeof list>>);
});

export const selectCMEmployees = createSelector(
  selectAuthUser,
  selectCMState,
  (user, { employees }) => {
    return [...employees].sort((employeeA, employeeB) => {
      if (user?.appUserID === employeeA.appIdentityUserID) {
        return -1;
      }
      if (user?.appUserID === employeeB.appIdentityUserID) {
        return 1;
      }
      return 0;
    });
  },
);
export const selectCMListState = createSelector(selectCMState, ({ list }) => {
  return list;
});
export const selectCMListData = createSelector(
  selectCMListState,
  selectCMMeetingSubjectsMap,
  (list, map) => {
    return list.data.map((item) => {
      const subject = map[item.clinicalMeetingSubjectID];
      return {
        ...item,
        $isCanceled: !!subject.isCanceledMeeting,
        $isNotShowingUpMeeting: !!subject.isNotShowingUpMeeting,
      };
    });
  },
);

export const selectCMListIsRefreshingActivities = createSelector(
  selectCMListState,
  ({ isRefreshingActivities }) => {
    return isRefreshingActivities;
  },
);
export const selectCMListStatuses = createSelector(selectCMListState, ({ loading }) => {
  return { loading };
});
export const selectCMListFilters = createSelector(selectCMListState, ({ filters }) => {
  return filters;
});
const selectCMListMeetingTypeKey = createSelector(
  selectCMListFilters,
  selectCMMeetingTypesMap,
  ({ clinicalMeetingTypeID }, mapTypes) => {
    const meetingType = mapTypes[clinicalMeetingTypeID];
    if (!meetingType) return undefined;
    return meetingType.meetingTypeKey;
  },
);

export const selectCMListPagination = createSelector(
  selectCMListState,
  ({ pageSize, page, dataCount }) => {
    const result = {
      take: pageSize,
      page,
      count: dataCount,
    };
    return calcPaginationState(result);
  },
);
export const selectCMListOrder = createSelector(
  selectCMListState,
  ({ orderField, orderDirection }) => {
    return { field: orderField, order: orderDirection };
  },
);

export const selectCMCalendarState = createSelector(selectCMState, ({ calendar }) => {
  return calendar;
});
export const selectCMCalendarStatuses = createSelector(selectCMCalendarState, ({ loading }) => {
  return { loading };
});
export const selectCMCalendarFilters = createSelector(selectCMCalendarState, ({ filters }) => {
  return filters;
});
const selectCMCalendarMeetingTypeKey = createSelector(
  selectCMCalendarFilters,
  selectCMMeetingTypesMap,
  ({ clinicalMeetingTypeID }, mapTypes) => {
    const meetingType = mapTypes[clinicalMeetingTypeID];
    if (!meetingType) return undefined;
    return meetingType.meetingTypeKey;
  },
);

export const selectCMCalendarEmployeeWorkLogs = createSelector(
  selectCMState,
  ({ employeesWorkLogs }) => {
    return employeesWorkLogs;
  },
);

export const selectCMCalendarEmployee = createSelector(
  selectCMEmployees,
  selectCMCalendarEmployeeWorkLogs,
  selectCMCalendarMeetingTypeKey,
  (employees, workLogs, meetingTypeKey) => {
    return employees.filter(({ appIdentityUserID }) => {
      const employeeWorkLogs = workLogs[appIdentityUserID] || [];

      if (employeeWorkLogs.length === 0) {
        return false;
      }

      if (meetingTypeKey === MEETING_TYPE_KEY.HOME) {
        return employeeWorkLogs.some((workLog) => workLog.isInClinic === false);
      }
      if (meetingTypeKey === MEETING_TYPE_KEY.CLINIC) {
        return employeeWorkLogs.some((workLog) => workLog.isInClinic === true);
      }

      return true;
    });
  },
);
export const selectCMCalendarData = createSelector(selectCMCalendarState, ({ data }) => {
  return data;
});

export const selectCMTimes = createSelector(selectCMState, ({ times }) => {
  return times;
});

export const selectCMCanToMoveItems = createSelector(
  selectCMView,
  selectCMListState,
  selectCMCalendarState,
  (view, listState, calendarState) => {
    switch (view) {
      case 'calendar':
        return calendarState.data.filter(
          (meeting) => !ServiceClinicalMeetings.isMeetingLocked(meeting),
        );
      case 'list':
        return listState.data.filter(
          (meeting) => !ServiceClinicalMeetings.isMeetingLocked(meeting),
        );
    }
  },
);
export const selectCMCanToMoveItemsMap = createSelector(selectCMCanToMoveItems, (list) => {
  return list.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {} as Record<string, ArrayType<typeof list>>);
});

export const selectCMCalendarColumns = createSelector(selectCMCalendarEmployee, (employees) => {
  return employees;
});

export const selectCMCalendarDayViewMap = createSelector(selectCMCalendarData, (calendarData) => {
  const parsed = calendarData.map((item) => {
    const cellID = `${format(new Date(item.meetingFromDateTime), 'HH:mm')}_${
      item.userEmployeeProfileID
    }`;
    const rows = Math.trunc(
      differenceInMinutes(new Date(item.meetingToDateTime), new Date(item.meetingFromDateTime)) /
        CALENDAR_RANGE_MINUTES,
    );

    return { id: item.id, cellID, rows, maxRows: 1, data: item };
  });
  return parsed.reduce((acc, item) => {
    if (!acc[item.cellID]) {
      acc[item.cellID] = [];
    }
    acc[item.cellID].push(item);
    return acc;
  }, {} as Record<string, ICMDayViewItem[]>);
});

export const selectCMMeetings = createSelector(
  selectCMView,
  selectCMCalendarData,
  selectCMListData,
  (view, calendarData, listData) => {
    switch (view) {
      case 'calendar': {
        return calendarData;
      }
      case 'list': {
        return listData;
      }
    }
  },
);
export const selectCMMeetingTypeKey = createSelector(
  selectCMView,
  selectCMCalendarMeetingTypeKey,
  selectCMListMeetingTypeKey,
  (view, calendarMeetingTypeKey, listMeetingTypeKey) => {
    switch (view) {
      case 'calendar':
        return calendarMeetingTypeKey;
      case 'list':
        return listMeetingTypeKey;
    }
  },
);

const selectCMSendSmsMeetingID = createSelector(selectCMState, ({ smsMeetingID }) => {
  return smsMeetingID;
});

export const selectCMSendSmsMeeting = createSelector(
  selectCMSendSmsMeetingID,
  selectCMMeetings,
  (selectCMSendSmsMeetingID, data) => {
    if (!selectCMSendSmsMeetingID) return null;
    return data.find((meeting) => meeting.id === selectCMSendSmsMeetingID);
  },
);

export const selectCMLoading = createSelector(
  selectCMState,
  selectCMListState,
  selectCMCalendarState,
  (generalState, listState, calendarState) => {
    return generalState.loading || listState.loading || calendarState.loading;
  },
);
