import { put, call, takeLatest } from 'typed-redux-saga';
import * as actions from 'AurionCR/store/modules/auth';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { requestError } from 'AurionCR/components/helpers';
import { updateTokenAll } from 'AurionCR/components';
import { actionAuthNotificationSet } from 'store/auth/actions';
import { ServiceAccount } from 'services/account';

export const ERROR_AS_SUCCESS_GENERATE = 'already-generated';

function* errorHandler(error: any) {
  const errorMessage = requestError(error);
  yield* put(notifyRequestResult(errorMessage, 'error'));
  return errorMessage;
}

function* getUser() {
  yield put(actions.authStart());
  try {
    const { data } = yield* call(ServiceAccount.getCurrentUser);
    yield* put(actions.authSuccess(data));

    // force logout
    if (data.isActive === false || data.forceReLogin === true) {
      yield* call(logout);
    }
  } catch (error) {
    // TODO: if Error Axios try update Token
    yield* put(actions.checkLoggedFail());
    return error;
  }
}
function* authUser(action: any) {
  yield* put(actions.authStart());
  try {
    const { ...data } = action.data;
    const response = yield* call(ServiceAccount.loginWithCode, data);

    if (response.status === 200) {
      const jwt = response.data.jwt;
      const expires = response.data.expires;

      const { token, refreshToken = '' } = jwt || {};
      try {
        updateTokenAll({
          token: token ?? undefined,
          refreshToken: refreshToken ?? undefined,
          expires,
        });
        yield* call(getUser);
      } catch (error) {
        const errorMessage = yield* call(errorHandler, error);
        yield* put(actions.authFail(errorMessage));
      }
    } else {
      const errorMessage = yield* call(errorHandler, response);
      yield* put(actions.authFail(errorMessage));
    }
  } catch (error) {
    const errorMessage = yield* call(errorHandler, error);
    yield* put(actions.authFail(errorMessage));
  }
}
// @ts-ignore
function* authCode(action: any) {
  yield* put(actions.authStart());
  try {
    yield* call(ServiceAccount.generatePassword, action.data);
    yield* put(actions.authCodeSuccess());
  } catch (error) {
    if (requestError(error) === ERROR_AS_SUCCESS_GENERATE) {
      yield* put(actions.authFail(requestError(error)));
    } else {
      // @ts-ignore
      const errorMessage = yield* call(errorHandler, error);
      yield* put(actions.authFail(errorMessage));
    }
  }
}

function* logout() {
  yield* put(actions.authStart());
  try {
    yield* call(ServiceAccount.logout);
    yield* put(actionAuthNotificationSet({ notificationDoNotAsk: false }));
    yield* put(actions.logoutSucceed());
    yield* call(updateTokenAll);
  } catch (error) {
    const errorMessage = requestError(error);
    yield* put(notifyRequestResult(errorMessage, 'error'));
    yield* put(actions.authFail(errorMessage));
  }
}

export const authSaga = [
  takeLatest('AUTH_GET_USER', getUser),
  takeLatest('AUTH_USER', authUser),
  takeLatest('AUTH_CODE', authCode),
  takeLatest('AUTH_INITIATE_LOGOUT', logout),
];
