import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';
const selectState = (state: RootState) => state.languages;

export const selectLanguagesStatuses = createSelector(
  selectState,
  ({ isInit, isLoading, error }) => {
    return { isInit, isLoading, error };
  },
);
export const selectLanguagesLanguageID = createSelector(selectState, ({ languageID }) => {
  return languageID;
});
export const selectLanguageID = createSelector(selectLanguagesLanguageID, (languageID) => {
  return languageID as string;
});
export const selectLanguages = createSelector(selectState, ({ data }) => {
  return data;
});

export const selectLanguage = createSelector(
  selectLanguageID,
  selectLanguages,
  (languageID, languages) => {
    return languages.find((lang) => lang.id === languageID);
  },
);

export const selectLanguageIsRtl = createSelector(selectLanguage, (language) => {
  return language ? language.direction === 2 : true;
});
