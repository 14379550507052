import React from 'react';
import { useOpen } from 'AurionCR/components/hooks';
import { Button } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { useTranslate } from 'hooks/use-translate';
import { MedicalPrescriptionDialog } from 'components/medical-prescription';

interface Props {
  userPatientProfileID: string;
}
export const ButtonCreatePrescription: React.FC<Props> = ({ userPatientProfileID }) => {
  const { t } = useTranslate();
  const { isOpen, handleOpen: onOpen, handleClose: onClose } = useOpen();

  return (
    <>
      <Button startIcon={<ListAltIcon />} onClick={onOpen} variant={'contained'} color={'primary'}>
        {t('create-prescription')}
      </Button>
      {isOpen && (
        <MedicalPrescriptionDialog
          userPatientProfilePrescriptionID={true}
          userPatientProfileID={userPatientProfileID}
          onClose={onClose}
          onComplete={onClose}
          onDeleted={onClose}
        />
      )}
    </>
  );
};
