import React, { memo, useCallback, useRef, useState } from 'react';
import { INotebookPrescription } from './index';
import Wrapper from './wrapper';
import style from './index.module.scss';
import { Loading } from 'AurionCR/components';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Menu, MenuItem } from '@material-ui/core';
import { TMMerge } from 'pages/TaskManager/components/store';
import { useAppDispatch, useAppSelector } from 'store';
import { useSourceNotebooksForSupportMeetings } from 'components/hooks';
import { useTranslate } from 'hooks/use-translate';
import { selectLanguageIsRtl } from 'store/languages';

export const SessionCreate = memo(
  ({ data, isMenuItem, isGridItem, handleClose, disabled }: INotebookPrescription) => {
    const { t } = useTranslate();
    const isRtl = useAppSelector(selectLanguageIsRtl);
    const dispatch = useAppDispatch();
    // state
    const source = useSourceNotebooksForSupportMeetings();
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<any>(null);
    // handlers
    const handleCloseSubMenu = useCallback(() => {
      setIsOpen(false);
    }, [setIsOpen]);
    const onClick = useCallback(() => {
      setIsOpen(true);
    }, [setIsOpen]);
    const handleSelectNotebook = useCallback(
      (notebookID) => {
        handleCloseSubMenu();
        if (isMenuItem && handleClose) handleClose();
        dispatch(TMMerge({ dialogNotebookFormGenerator: { ...data, notebookID } }));
      },
      [dispatch, data, handleCloseSubMenu, isMenuItem, handleClose],
    );

    const ArrowIcon =
      !isMenuItem && !isOpen ? (
        <ArrowDropDownIcon className={style.icon} />
      ) : !isMenuItem && isOpen ? (
        <ArrowDropUpIcon className={style.icon} />
      ) : isRtl ? (
        <ArrowLeftIcon className={style.icon} />
      ) : (
        <ArrowRightIcon className={style.icon} />
      );
    // render
    return (
      <>
        <Wrapper
          disabled={source.loading || source.data.length === 0 || disabled}
          isMenuItem={isMenuItem}
          isGridItem={isGridItem}
          onClick={onClick}
          ref={ref}
        >
          <div className={style.sessionCreate}>
            <div>{t('notebook')}</div>
            {ArrowIcon}
          </div>
        </Wrapper>
        {isOpen && (
          <Menu
            anchorEl={ref.current}
            open={true}
            onClose={handleCloseSubMenu}
            getContentAnchorEl={null}
            classes={{
              paper: isMenuItem ? '' : style.menu,
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: (isMenuItem && isRtl) || (!isMenuItem && !isRtl) ? 'left' : 'right',
            }}
          >
            {source.loading ? (
              <MenuItem onClick={handleCloseSubMenu}>
                <Loading size={15} />
              </MenuItem>
            ) : (
              source.data.map(({ id, title }) => (
                <MenuItem key={id} onClick={() => handleSelectNotebook(id)}>
                  {title}
                </MenuItem>
              ))
            )}
          </Menu>
        )}
      </>
    );
  },
);

export default SessionCreate;
