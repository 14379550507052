import {IDataGridMadel} from "../../model";
import {apiStatic, checkEs6AndRun, requestError} from "AurionCR/components";
import {notifyRequestResult} from "AurionCR/store/modules/notify";

export const requestSwapPath = (url: string, method: 'patch' | 'put', data: any) => {
  return apiStatic.request({url: checkEs6AndRun(url, data), method, data})
};

export const requestSwap = (
  {
    model,
    onTasks,
    dispatch,
    payload
  }: {
    model: IDataGridMadel,
    sortable: any;
    filters: any;
    onTasks: any;
    dispatch: any;
    payload: { data: any[], startIndex: number, endIndex: number }
  }) => {

  const {startIndex, endIndex, data} = payload
  const dataToSave = []
  const fieldName = model.store.swap?.field as string

  const min = Math.min(startIndex, endIndex)
  const max = Math.max(startIndex, endIndex)

  const copyData = [...data]
  const deleted = copyData.splice(startIndex, 1)
  copyData.splice(endIndex, 0, ...deleted)
  const dataSwap = copyData

  for (let i = min; i <= max; i++) {
    if (dataSwap[i] !== undefined && data[i] !== undefined) {
      const value = data[i][fieldName]
      dataToSave.push({...dataSwap[i], [fieldName]: value})
    }
  }

  onTasks([
    ['loading', true]
  ]);

  const ID_ = model.store.mainField;

  Promise
    .all(dataToSave.map((item) => {
      // @ts-ignore
      return requestSwapPath(model.store.patch?.url, model.store.patch?.method, {
        [ID_]: item[ID_],
        [fieldName]: item[fieldName]
      })
    }))
    .then(() => {
      onTasks([
        ['getData'],
      ])
    })
    .catch((error) => {
      onTasks([
        ['onHandle', {type: 'getError', error: error}],
        ['loading', false]
      ]);
      dispatch(notifyRequestResult(requestError(error), 'error'));
    })
};
