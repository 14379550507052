import React, { useMemo } from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { Box, Divider, Typography } from '@material-ui/core';
import { ChartBMI, ChartWeights } from '../../components';
import { usePatientWeights } from '../../hooks';
import { GridWeight } from '../../grids';
import VAR from 'styles/_variabels.scss';
import { calcBmi } from 'utils/app-helpers';
import { NativeScroll } from 'components/native-scroll';
import { useTranslate } from 'hooks/use-translate';
import { UserInfoWeightGrid } from 'components/user-info-weight-grid';
import { BmiChart } from 'components/leads-form/bmi-chart';
import { apiUserPatientProfile } from 'services/user-patient-profile';

interface Props {
  className?: string;
  userPatientProfileID: string;
}

export const TabWeights: React.FC<Props> = ({ userPatientProfileID, className }) => {
  const { data: patient } =
    apiUserPatientProfile.useGetPatientForGeneralFormQuery(userPatientProfileID);
  const { t } = useTranslate();
  const { data: weights, isLoading: isLoadingWeights } = usePatientWeights(userPatientProfileID);

  const height = patient?.height || 0;
  const weight = patient?.height || 0;

  const bmiWeight = useMemo(() => {
    return weights.map((item) => ({
      id: item.id,
      activityType: item.activityType,
      entryDate: item.entryDate,
      externalSystemValue: item.externalSystemValue,
      transactionType: item.transactionType,
      weight: calcBmi(item.weight, height),
    }));
  }, [weights, height]);

  const initBmi = useMemo(() => {
    return calcBmi(weight, height);
  }, [weight, height]);

  return (
    <div className={style.main}>
      <NativeScroll>
        <div className={clsx(style.root, className)}>
          <UserInfoWeightGrid userPatientProfileID={userPatientProfileID} />
          <div className={style.wrap}>
            <GridWeight userPatientProfileID={userPatientProfileID} />
            <Divider
              orientation={'vertical'}
              style={{
                backgroundColor: VAR.colorSecondary,
                opacity: 0.1,
                margin: '0 0.5rem 0 2rem',
              }}
            />

            {weights.length > 0 && (
              <Box display={'flex'} flexDirection={'column'}>
                <div>
                  <Typography variant="h1" color="secondary" className={style.weightHeader}>
                    {t('weight')}
                  </Typography>
                  <ChartWeights isLoading={isLoadingWeights} weights={weights} />
                </div>
                <Divider
                  orientation={'horizontal'}
                  style={{
                    backgroundColor: VAR.colorGreyLines,
                  }}
                />
                <div className={style.bmi}>
                  <Typography variant="h1" color="secondary" className={style.bmiHeader}>
                    {t('bmi')}
                  </Typography>
                  <ChartBMI isLoading={isLoadingWeights} weights={bmiWeight} />
                </div>
              </Box>
            )}
            {!isLoadingWeights && weights.length === 0 && (
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <BmiChart bmi={initBmi} />
              </Box>
            )}
          </div>
        </div>
      </NativeScroll>
    </div>
  );
};
