import React from 'react';
import { Stack } from 'components/stack';
import { Typography } from '@material-ui/core';
import { NotebookPatientInfo } from '../notebook-patient-info';

interface Props {
  userPatientProfileID: string;
  mainTitle: string;
}
export const NotebookDialogTitle: React.FC<Props> = ({ userPatientProfileID, mainTitle }) => {
  return (
    <Stack spacing={1} alignItems={'center'}>
      <Typography
        component={'h1'}
        variant={'h3'}
        color={'secondary'}
        style={{ flexGrow: 1, fontWeight: 'bold' }}
      >
        {mainTitle}
      </Typography>
      <div>
        <NotebookPatientInfo userPatientProfileID={userPatientProfileID} />
      </div>
    </Stack>
  );
};
