import React, { useMemo } from 'react';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import style from './index.module.scss';
import clsx from 'clsx';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { Stack } from 'components/stack';
import { format } from 'date-fns';
import { convertToDate } from 'utils/dates';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { useTranslate } from 'hooks/use-translate';

interface Props {
  className?: string;
  title: React.ReactNode;
  date?: string | null | undefined;
  employee?: string | null | undefined;
  notebook?: string | null | undefined;
  onPreview?: () => void;
  children: React.ReactNode;
}

export const PreviewSessionCard: React.FC<Props> = ({
  className,
  employee,
  notebook,
  title,
  date,
  onPreview,
  children,
}) => {
  const { t } = useTranslate();
  const formatedDate = useMemo(() => {
    if (!date) {
      return null;
    }

    return format(convertToDate(date), APP_FORMAT_DATE_TIME);
  }, [date]);

  const tooltip = useMemo(() => {
    return [notebook, employee].filter(Boolean).join(': ');
  }, [notebook, employee]);

  return (
    <div className={clsx(style.root, className)}>
      <Tooltip placement={'top-start'} arrow title={tooltip}>
        <div className={style.header}>
          <Typography color={'secondary'} className={style.title}>
            {title}
          </Typography>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            {formatedDate && (
              <Typography title={t('created-date')} variant={'body2'} style={{ lineHeight: 1 }}>
                {formatedDate}
              </Typography>
            )}
            {onPreview && (
              <IconButton title={'preview'} color={'primary'} size={'small'} onClick={onPreview}>
                <RemoveRedEyeIcon fontSize={'small'} />
              </IconButton>
            )}
          </Stack>
        </div>
      </Tooltip>
      {children}
    </div>
  );
};
