import { apiRtk, RTK_TAGS, transformResponseDynamic } from 'utils/rtk-query';
import { convertToDate, DateValue } from 'utils/dates';
import { endOfDay, endOfMonth, isSameDay, startOfDay, startOfMonth } from 'date-fns';
import {
  API_USER_EMPLOYEE_PROFILE_ABSENCES,
  CreateForAllEmployee,
  CreateForAllInput,
  UserEmployeeProfileAbsence,
} from './models';
import { PatchPartial } from 'utils/types';
import { DynamicService } from 'utils/service';
import { createFilterDateISO, mergeFilters } from 'utils/dynamic-helpers';
import { ServiceUserEmployeeProfile } from '../user-employee-profiles';

class Service extends DynamicService<UserEmployeeProfileAbsence> {
  createForAll = async (input: CreateForAllInput) => {
    const { eventDate, description } = input;
    let {
      data: { value: employees },
    } = await ServiceUserEmployeeProfile.getAllDynamic<CreateForAllEmployee>({
      filter: mergeFilters('isActive==true').join('&&'),
      select:
        'appIdentityUserID,userEmployeeProfileAbsences.Select(a=> new { a.eventDate }) as absences',
    });

    // skip employees that has absence at this day
    employees = employees.filter((employee) => {
      const isHasAbsence = employee.absences.some((absence) =>
        isSameDay(convertToDate(absence.eventDate), convertToDate(eventDate)),
      );
      return !isHasAbsence;
    });

    return Promise.all(
      employees.map(async (employee) => {
        const userEmployeeProfileID = String(employee.appIdentityUserID);
        await this.post({ userEmployeeProfileID, eventDate, description });
        return { userEmployeeProfileID };
      }),
    );
  };
}

export const ServiceUserEmployeeProfileAbsences = new Service({
  mainField: 'id',
  getAll: API_USER_EMPLOYEE_PROFILE_ABSENCES.GET_ALL_DYNAMIC,
  post: API_USER_EMPLOYEE_PROFILE_ABSENCES.CREATE,
  patch: API_USER_EMPLOYEE_PROFILE_ABSENCES.PATCH,
  delete: API_USER_EMPLOYEE_PROFILE_ABSENCES.DELETE,
});

export * from './models';

export const apiUserEmployeeProfileAbsences = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getEmployeeProfileAbsencesByRange: builder.query<
      Pick<UserEmployeeProfileAbsence, 'id' | 'eventDate'>[],
      { userEmployeeProfileID: string; startDate: DateValue; endDate: DateValue }
    >({
      query: ({ userEmployeeProfileID, startDate, endDate }) => {
        const start = startOfDay(convertToDate(startDate));
        const end = endOfDay(convertToDate(endDate));

        return {
          url: API_USER_EMPLOYEE_PROFILE_ABSENCES.GET_ALL_DYNAMIC,
          params: {
            filter: [
              `userEmployeeProfileID=="${userEmployeeProfileID}"`,
              createFilterDateISO('eventDate', [start, end]),
            ]
              .filter(Boolean)
              .join('&&'),
            select: 'id,eventDate',
          },
        };
      },
      transformResponse: transformResponseDynamic,
      providesTags: (_, __, arg) => [
        {
          type: RTK_TAGS.EMPLOYEE_ABSENCES,
          id: ['employee', arg.userEmployeeProfileID].join('__'),
        },
      ],
    }),
    getEmployeeProfileAbsencesMonthly: builder.query<
      Pick<UserEmployeeProfileAbsence, 'id' | 'eventDate' | 'description'>[],
      { userEmployeeProfileID: string; date: DateValue }
    >({
      query: ({ userEmployeeProfileID, date }) => {
        const start = startOfMonth(convertToDate(date));
        const end = endOfMonth(convertToDate(date));

        return {
          url: API_USER_EMPLOYEE_PROFILE_ABSENCES.GET_ALL_DYNAMIC,
          params: {
            filter: [
              `userEmployeeProfileID=="${userEmployeeProfileID}"`,
              createFilterDateISO('eventDate', [start, end]),
            ]
              .filter(Boolean)
              .join('&&'),
            select: 'id,eventDate,description',
          },
        };
      },
      transformResponse: transformResponseDynamic,
      providesTags: (_, __, arg) => [
        {
          type: RTK_TAGS.EMPLOYEE_ABSENCES,
          id: ['employee', arg.userEmployeeProfileID].join('__'),
        },
      ],
    }),

    createEmployeeProfileAbsence: builder.mutation<
      UserEmployeeProfileAbsence,
      PatchPartial<UserEmployeeProfileAbsence, 'userEmployeeProfileID' | 'eventDate'>
    >({
      query: (arg) => {
        return { url: API_USER_EMPLOYEE_PROFILE_ABSENCES.CREATE, method: 'POST', data: arg };
      },
      invalidatesTags: (_, __, arg) => [
        {
          type: RTK_TAGS.EMPLOYEE_ABSENCES,
          id: ['employee', arg.userEmployeeProfileID].join('__'),
        },
      ],
    }),
    deleteEmployeeProfileAbsence: builder.mutation<
      UserEmployeeProfileAbsence,
      PatchPartial<UserEmployeeProfileAbsence, 'id' | 'userEmployeeProfileID'>
    >({
      query: (arg) => {
        return { url: API_USER_EMPLOYEE_PROFILE_ABSENCES.DELETE(arg), method: 'DELETE' };
      },
      invalidatesTags: (_, __, arg) => [
        {
          type: RTK_TAGS.EMPLOYEE_ABSENCES,
          id: ['employee', arg.userEmployeeProfileID].join('__'),
        },
      ],
    }),

    createEmployeeProfileAbsencesForAll: builder.mutation<
      { userEmployeeProfileID: string }[],
      CreateForAllInput
    >({
      queryFn: async (input) => {
        try {
          const res = await ServiceUserEmployeeProfileAbsences.createForAll(input);
          return { data: res };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res) =>
        res
          ? res.map((employee) => ({
              type: RTK_TAGS.EMPLOYEE_ABSENCES,
              id: ['employee', employee.userEmployeeProfileID].join('__'),
            }))
          : [],
    }),
  }),
});
