import { useFieldProps } from 'hooks';
import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';

import style from './index.module.scss';
import { IDataGridLightEditorProps, useDataGridLightEditorForm } from 'components/data-grid-light';
import { composeFunctions } from 'utils';
import { SelectEmployee } from 'components/select-employee';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete/useAutocomplete';
import { Unset } from 'utils/types';

type Option = {
  id: string;
  isActive?: boolean;
  title?: Unset;
};

interface Props extends IDataGridLightEditorProps<any, any> {}

export const EditorEmployee: React.FC<Props> = ({ row, field, onClose, onSubmit }) => {
  const { control, handleSubmit, errors } = useDataGridLightEditorForm({
    row,
    field,
    onClose,
  });
  const getFieldProps = useFieldProps({ errors, emptyHelperText: ' ' });

  const submit = handleSubmit(onSubmit);

  const filterOptions = useCallback((list: Array<Option>, state: FilterOptionsState<Option>) => {
    return list.filter(
      (item) => item.isActive && item.title?.toLowerCase().includes(state.inputValue.toLowerCase()),
    );
  }, []);

  return (
    <div className={style.root}>
      <Controller
        name={String(field)}
        control={control}
        render={(renderProps) => {
          const props = getFieldProps(renderProps);
          return (
            <SelectEmployee
              {...props}
              isActiveOnly={false}
              filterOptions={filterOptions}
              label={null}
              onChange={composeFunctions(props.onChange, submit, onClose)}
              helperText={props.helperText === ' ' ? null : props.helperText}
            />
          );
        }}
      />
    </div>
  );
};
