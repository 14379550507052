import { useContext } from 'react';
import { DataGridTasksContext, DataGridTasksContextValue } from '../components/provider-tasks';
import { DataGridLightDefaultTasks, DataGridLightTask } from '../models';

export const useDataGridLightTasks = <
  M,
  Task extends DataGridLightTask<any> = DataGridLightDefaultTasks<M>,
>() => {
  const context = useContext(DataGridTasksContext);
  if (!context) {
    throw Error('You should use DataGridTasksContext');
  }
  return context as DataGridTasksContextValue<Task>;
};
