import React, { memo, useCallback, useMemo, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { iEmployeeMeetingDateSelect } from '../../@type';
import { useMeetingDurationSource } from '../../hooks';
import { CALENDAR_MEETING_END_HOURS, CALENDAR_MEETING_START_HOURS } from 'configs/const';
import { createTimeline } from '../../helpers';
import {
  addDays,
  addMinutes,
  eachDayOfInterval,
  endOfWeek,
  isSameDay,
  startOfWeek,
} from 'date-fns';
import style from './index.module.scss';
import { DialogHeading } from 'components/dialog-title';
import { useTranslate } from 'hooks/use-translate';
import {
  apiUserEmployeeProfileWorkLogs,
  dateToDayOfWeek,
} from 'services/user-employee-profile-work-logs';
import { useEffectError } from 'hooks';
import { apiUserEmployeeProfileSchedules } from 'services/user-employee-profile-schedules';
import { apiUserEmployeeProfileAbsences } from 'services/user-employee-profile-absence';
import { convertToDate, isPastDate } from 'utils/dates';
import { isAcrossTimeSlots, numberToTimeSlot, validateTimeSlot } from 'utils/app-helpers';
import { apiClinicalMeetings } from 'services/clinical-meetings';
import { AppSelect } from 'components/app-select';
import { AppDatePicker } from 'components/app-date-picker';
import { Legend } from '../legend';
import { SlotsView } from '../slots-view';
import { TabsWrapper, TabItem } from 'components/tabs-wrapper';
import { MeetingsView } from '../meetings-view';

enum TABS {
  SLOTS = 'slots',
  MEETINGS = 'meetings',
}

interface WorkDay {
  date: Date;
  slots: { isInClinic: boolean; fromTime: string; toTime: string }[];
}

type Props = iEmployeeMeetingDateSelect & {
  onClose: (e?: any) => void;
  onChange: (e: [string | Date, string | Date]) => void;
};
export const DialogTimeSlots = memo<Props>(
  ({
    value,
    onChange,

    userEmployeeID,

    onClose,
    preferStartDate,
    defaultDuration,
  }) => {
    const { t } = useTranslate();
    const [currentDate, setCurrentDate] = useState(
      new Date((value && value[0]) || (preferStartDate && new Date(preferStartDate)) || new Date()),
    );

    const { startDate, endDate } = useMemo(() => {
      const startDate = startOfWeek(currentDate);
      const endDate = addDays(endOfWeek(currentDate), -1);
      return { startDate, endDate };
    }, [currentDate]);

    const employeeWorkLogsResult =
      apiUserEmployeeProfileWorkLogs.useEmployeeProfileWorkLogsQuery(userEmployeeID);
    useEffectError(employeeWorkLogsResult.error);

    const employeeSchedulesResult =
      apiUserEmployeeProfileSchedules.useEmployeeProfileSchedulesByRangeQuery({
        userEmployeeProfileID: userEmployeeID,
        startDate,
        endDate,
      });
    useEffectError(employeeSchedulesResult.error);

    const employeeAbsencesResult =
      apiUserEmployeeProfileAbsences.useGetEmployeeProfileAbsencesByRangeQuery({
        userEmployeeProfileID: userEmployeeID,
        startDate,
        endDate,
      });
    useEffectError(employeeAbsencesResult.error);

    const employeeMeetingsResult = apiClinicalMeetings.useGetEmployeeClinicalMeetingsByRangeQuery(
      {
        userEmployeeProfileID: userEmployeeID,
        startDate,
        endDate,
      },
      { refetchOnMountOrArgChange: true },
    );
    useEffectError(employeeMeetingsResult.error);

    const workTimes = useMemo<WorkDay[]>(() => {
      return eachDayOfInterval({ start: startDate, end: endDate }).map((date) => {
        const isAbsence = (employeeAbsencesResult.data || []).some((absence) =>
          isSameDay(convertToDate(absence.eventDate), date),
        );

        if (isAbsence) {
          return { date, slots: [] };
        }

        const schedules = (employeeSchedulesResult.data || []).filter((schedule) =>
          isSameDay(convertToDate(schedule.date), date),
        );

        if (schedules.length > 0) {
          return { date, slots: schedules };
        }

        const datOfWeek = dateToDayOfWeek(date);

        return {
          date,
          slots: (employeeWorkLogsResult.data || []).filter(
            (workLog) => workLog.dayOfWeek === datOfWeek,
          ),
        };
      });
    }, [
      employeeWorkLogsResult.data,
      employeeSchedulesResult.data,
      employeeAbsencesResult.data,
      startDate,
      endDate,
    ]);

    const { duration, setDuration, options } = useMeetingDurationSource(defaultDuration);

    const isLoading =
      employeeWorkLogsResult.isFetching ||
      employeeSchedulesResult.isFetching ||
      employeeAbsencesResult.isFetching ||
      employeeMeetingsResult.isFetching;

    const [timeRange, setTimeRange] = useState([
      CALENDAR_MEETING_START_HOURS,
      CALENDAR_MEETING_END_HOURS,
    ]);

    const days = useMemo(() => {
      const meetingDuration = Number(duration) / 60;

      const timelines = createTimeline(timeRange[0], timeRange[1], 10);

      return workTimes.map((workTime) => ({
        id: workTime.date.toISOString(),
        date: workTime.date,
        timeline: timelines.map((timeLine) => {
          const targetSlot = {
            fromTime: numberToTimeSlot(timeLine.decimal),
            toTime: numberToTimeSlot(timeLine.decimal + meetingDuration),
          };

          const slot = workTime.slots.find((slot) => validateTimeSlot(targetSlot, [slot]));
          const meeting = (employeeMeetingsResult.data || [])
            .filter((meeting) =>
              isSameDay(convertToDate(meeting.meetingFromDateTime), workTime.date),
            )
            .find((meeting) => {
              return isAcrossTimeSlots(targetSlot, {
                fromTime: String(meeting.meetingFromDateTime),
                toTime: String(meeting.meetingToDateTime),
              });
            });

          return {
            id: timeLine.id,
            decimal: timeLine.decimal,
            isPast: isPastDate(workTime.date),
            isReserved: Boolean(meeting),
            isAvailable: Boolean(slot),
            isInClinic: Boolean(slot?.isInClinic),
          };
        }),
      }));
    }, [workTimes, timeRange, duration, employeeMeetingsResult.data]);

    const handleChangeDate = useCallback(
      (value: string | null) => {
        setCurrentDate(convertToDate(value));
      },
      [setCurrentDate],
    );

    const handleSelectTime = useCallback(
      (date: Date) => {
        onChange([date, addMinutes(date, Number(duration))]);
      },
      [duration, onChange],
    );

    const [tab, setTab] = useState(TABS.SLOTS);
    const tabs = useMemo<TabItem<TABS>[]>(
      () => [
        {
          value: TABS.SLOTS,
          title: t('slots'),
          keepInMemory: false,
          element: (
            <SlotsView
              timeRange={timeRange}
              onChangeTimeRange={setTimeRange}
              days={days}
              currentDate={currentDate}
              onSelectCurrentDate={setCurrentDate}
              onChose={handleSelectTime}
            />
          ),
        },
        {
          value: TABS.MEETINGS,
          title: t('meetings'),
          element: <MeetingsView date={currentDate} />,
        },
      ],
      [t, timeRange, days, currentDate, handleSelectTime],
    );

    return (
      <Dialog
        open={true}
        onClose={onClose}
        maxWidth={'xl'}
        fullWidth
        classes={{
          paper: style.dialogPaper,
        }}
      >
        <DialogHeading isLoading={isLoading} title={t('slots-available')} onClose={onClose} />
        <DialogContent className={style.dialogContent}>
          <div className={style.wrapper}>
            <div>
              <AppDatePicker
                autoOk
                disableToolbar
                orientation="landscape"
                variant="static"
                value={currentDate}
                onChange={handleChangeDate}
              />
              <AppSelect
                label={t('meeting-duration')}
                value={duration}
                options={options}
                onChange={setDuration}
                fullWidth
                disableClearable
              />
              <Legend />
            </div>
            <TabsWrapper
              classes={{
                root: style.tabs,
                content: style.tabsContent,
              }}
              tabs={tabs}
              value={tab}
              onChange={setTab}
            />
          </div>
        </DialogContent>
        <DialogActions className={style.dialogActions}>
          <Button onClick={onClose} variant={'outlined'}>
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);
