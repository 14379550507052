import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'UserPatientProfileSessionSensitivityDrugs';

export const API_USER_PATIENT_PROFILE_SESSIONS_SENSITIVITY_DRUGS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  POST_BULK: `${API_ENDPOINT}/CreateUserPatientProfileSessionSensitivityDrugBulk`,
  PATCH: (data: Pick<UserPatientProfileSessionSensitivityDrug, 'id'>) =>
    `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<UserPatientProfileSessionSensitivityDrug, 'id'>) =>
    `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface UserPatientProfileSessionSensitivityDrug
  extends FixServerObject<Components.Schemas.UserPatientProfileSessionSensitivityDrug, 'id'> {
  createdDate?: string;
}

export interface CreateBulkUserPatientProfileSessionSensitivityDrugInput {
  userPatientProfileSessionID: string;
  sensitivityDrugIDs: string[];
}
