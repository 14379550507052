import React, { useCallback, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { useFieldProps } from 'hooks';
import { SelectPatients } from 'components/select-patients';
import { isShouldDisableSupportMeetingDate, SupportMeetingInput } from 'services/support-meetings';
import { composeFunctions } from 'utils';
import { Left } from 'utils/types';
import { SelectEmployee } from 'components/select-employee';
import { AppDatePicker } from 'components/app-date-picker';
import { AppInput } from 'components/app-input';
import { AppSelect } from 'components/app-select';
import { useSourceProfilesSupport } from '../../../hooks';
import { DateLabel } from '../date-label';

interface Props {
  isLoading: boolean;
  isRenewal: boolean;
  times: { id: string }[];
}

type FormModel = Left<SupportMeetingInput>;

export const Form: React.FC<Props> = ({ isLoading, times, isRenewal }) => {
  const { control, errors, clearErrors } = useFormContext<FormModel>();
  const getFieldProps = useFieldProps({ errors });

  const clearTimeErrors = useCallback(() => {
    clearErrors(['start', 'end', 'userEmployeeProfileID']);
  }, [clearErrors]);

  const timesOptions = useMemo(() => {
    return times.map(({ id }) => ({ id, title: id }));
  }, [times]);

  const sourceManagers = useSourceProfilesSupport();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={'userPatientProfileID'}
          render={(renderProps) => {
            return (
              <SelectPatients
                {...getFieldProps(renderProps)}
                disabled={isLoading}
                disableClearable
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={'userEmployeeProfileID'}
          render={(renderProps) => {
            return (
              <SelectEmployee
                {...getFieldProps(renderProps)}
                options={sourceManagers.data}
                loading={sourceManagers.loading}
                disabled={isLoading}
                fullWidth
                disableClearable
              />
            );
          }}
        />
      </Grid>
      <Grid item md={6}>
        <Controller
          control={control}
          name={'date'}
          render={(renderProps) => {
            const { label, ...rest } = getFieldProps(renderProps);
            return (
              <AppDatePicker
                {...rest}
                label={<DateLabel label={label} value={rest.value} />}
                disabled={isLoading}
                shouldDisableDate={isShouldDisableSupportMeetingDate}
              />
            );
          }}
        />
      </Grid>
      <Grid item md={3} style={{ display: isRenewal ? 'none' : undefined }}>
        <Controller
          control={control}
          name={'start'}
          render={(renderProps) => {
            const props = getFieldProps(renderProps);
            return (
              <AppSelect
                {...props}
                onChange={composeFunctions(clearTimeErrors, props.onChange)}
                options={timesOptions}
                disabled={isLoading}
                disableClearable
              />
            );
          }}
        />
      </Grid>
      <Grid item md={3} style={{ display: isRenewal ? 'none' : undefined }}>
        <Controller
          control={control}
          name={'end'}
          render={(renderProps) => {
            const props = getFieldProps(renderProps);
            return (
              <AppSelect
                {...props}
                onChange={composeFunctions(clearTimeErrors, props.onChange)}
                options={timesOptions}
                disabled={isLoading}
                disableClearable
              />
            );
          }}
        />
      </Grid>
      <Grid item md={12}>
        <Controller
          control={control}
          name={'remarks'}
          render={(renderProps) => {
            return (
              <AppInput
                {...getFieldProps(renderProps)}
                disabled={isLoading}
                disableClearable
                multiline
                minRows={5}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};
