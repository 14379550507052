import clsx from 'clsx';
import { DataGridLightCell } from '../data-grid-cell';
import { useDataGridLightSettings } from '../../hooks';
import { DataGridLightColumn, DataGridLightRowProps } from '../../models';
import React, { memo, useMemo } from 'react';

interface Props {
  row: any;
  rowProps?: DataGridLightRowProps<any>;
  columns: DataGridLightColumn<any, any>[];
}

export const DataGridRow = memo<Props>(({ row, columns, rowProps }) => {
  const RowProps = useMemo(() => {
    return typeof rowProps === 'function' ? rowProps(row) : rowProps;
  }, [row, rowProps]);

  const { classes } = useDataGridLightSettings();

  return (
    <tr {...RowProps} className={clsx(classes.row, RowProps?.className)}>
      {columns.map((column) => {
        return <DataGridLightCell key={column.id} column={column} row={row} />;
      })}
    </tr>
  );
});
