import React from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { Box, BoxProps } from '@material-ui/core';
import { Stack } from 'components/stack';

interface Classes {
  root: string;
  label: string;
  value: string;
}

interface Props {
  className?: string;
  classes?: Partial<Classes>;
  label: React.ReactNode;
  LabelProps?: Partial<BoxProps>;
}

export const RowField: React.FC<Props> = ({ className, label, LabelProps, classes, children }) => {
  return (
    <Stack className={clsx(style.root, classes?.root, className)} alignItems={''}>
      <Box
        component={'span'}
        minWidth={'30%'}
        maxWidth={'30%'}
        {...LabelProps}
        className={clsx(style.label, classes?.label, LabelProps?.className)}
      >
        {label}
      </Box>
      <div className={clsx(style.value, classes?.value)}>{children}</div>
    </Stack>
  );
};
