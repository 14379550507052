import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'SupportMeetingTypes';

export const API_SUPPORT_MEETING_TYPES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<SupportMeetingType, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<SupportMeetingType, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface SupportMeetingType
  extends FixServerObject<
    Components.Schemas.SupportMeetingType,
    'id' | 'title' | 'icon' | 'color'
  > {}

export const SUPPORT_MEETING_TYPE_IDs = {
  SIDE_EFFECT: 'c3f02930-01f5-4732-b209-5c1d5e06b824',
  OBSERVE: '3a926c2d-eecf-4c76-bc50-1477b54e0faa',
};
