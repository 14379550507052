import React, { HTMLAttributes, memo, useCallback } from 'react';
import style from './index.module.scss';
import clsx from 'clsx';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useDrag } from 'react-dnd';
import { MeetingLock } from '../meeting-lock';
import { useOpen } from 'AurionCR/components/hooks';
import { Box } from '@material-ui/core';
import { CalendarViewItem } from '../../@types';

interface Options {
  rows: number;
  cellHeight: number;
  index: number;
  rowHeight: number;
}

export const calcMeetingCardHeight = (options: Options) => {
  const { rows, cellHeight, index, rowHeight } = options;
  return rows * (Number(cellHeight) - 1) + (rows - 1) - index * rowHeight;
};

interface Classes {
  root: string;
  locked: string;
  disabled: string;
  resizing: string;
  disabledByDrag: string;
  dragging: string;

  lock: string;

  resize: string;
  resizeIcon: string;
}

export interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  id: string;
  meetingFromDateTime: string;
  userEmployeeProfileID: string;
  userPatientProfileID: string;
  disabled?: boolean;
  disabledDrag?: boolean;
  disabledResize?: boolean;
  isLocked: boolean;
  rows: number;
  startDragDayViewItem: number;
  onEdit: () => void;
  onResize: (startY: number) => void;
  onChangeStartDragDayViewItem: (value: this['startDragDayViewItem']) => void;
  children: React.ReactNode;
  classes?: Partial<Classes>;
  height: number;
}

export const MeetingWrapper = memo<Props>(
  ({
    id,
    meetingFromDateTime,
    userEmployeeProfileID,
    userPatientProfileID,
    rows,
    startDragDayViewItem,
    children,
    disabled = false,
    disabledDrag = false,
    disabledResize = false,
    isLocked,
    onEdit,
    onResize,
    onChangeStartDragDayViewItem,
    classes,
    height,
    ...rest
  }) => {
    const {
      isOpen: isHoverResize,
      handleOpen: handleMouseEnterResize,
      handleClose: handleMouseLeaveResize,
    } = useOpen();

    const handleClick = useCallback<React.MouseEventHandler>(
      (e) => {
        if (e.detail === 2) onEdit();
      },
      [onEdit],
    );
    const handleResize = useCallback<React.MouseEventHandler>(
      (e) => {
        onResize(e.clientY);
      },
      [onResize],
    );

    const item: CalendarViewItem = {
      type: 'DailyViewDnDAccept',
      id,
      meetingFromDateTime,
      userEmployeeProfileID,
      userPatientProfileID,
      rows,
    };

    const canDrag = !isLocked && !disabled && !disabledDrag;
    const canResize = !isLocked && !disabled && !disabledResize;

    const [{ isDragging }, drag] = useDrag({
      item,
      canDrag,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      begin: () => {
        onChangeStartDragDayViewItem(rows);
      },
      end: () => {
        onChangeStartDragDayViewItem(0);
      },
    });

    return (
      <div
        {...rest}
        ref={drag}
        className={clsx(
          [style.root, classes?.root],
          isLocked && [style.locked, classes?.locked],
          disabled && [style.disabled, classes?.disabled],
          isHoverResize && [style.resizing, classes?.resizing],
          startDragDayViewItem && [style.disabledByDrag, classes?.disabledByDrag],
          isDragging && [style.dragging, classes?.dragging],
        )}
        style={{
          height: `${height}px`,
        }}
        draggable={canDrag}
        onClick={disabled ? undefined : handleClick}
      >
        {children}
        {isLocked && <MeetingLock className={clsx(style.lock, classes?.lock)} />}
        {canResize && (
          <Box
            className={clsx(style.resize, classes?.resize)}
            onMouseDown={handleResize}
            onMouseEnter={handleMouseEnterResize}
            onMouseLeave={handleMouseLeaveResize}
          >
            <MoreHorizIcon className={clsx(style.resizeIcon, classes?.resizeIcon)} />
          </Box>
        )}
      </div>
    );
  },
);
