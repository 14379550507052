import { DefaultGridButtonExportToExcel } from 'components/data-grid/model';
import React from 'react';
import {
  API_USER_PATIENT_PROFILE_ACTIVITIES,
  UserPatientProfileActivity,
} from 'services/user-patient-profile-activities';
import { excelTemplateDate } from 'utils/dates';
import { createFilterContains, createFilterSmartSearch, mergeFilters } from 'utils/dynamic-helpers';
import { TemplateDate } from 'components/templates/template-date';
import { TemplateLog } from 'components/templates/template-log';

type GridModel = Pick<UserPatientProfileActivity, 'entryDate' | 'changes'>;
export default (userPatientProfileID: string): any => ({
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_ACTIVITIES.GET_ALL_DYNAMIC,
      filters: `userPatientProfileID=="${userPatientProfileID}"`,
    },
    sortable: {
      field: 'entryDate',
      order: 'desc',
    },
  },
  toolbar: {
    title: '',
    excelTitle: 'all-patient-activities',
    controls: null,
    controlsDefault: {
      new: null,
      resetFilters: null,
      export: DefaultGridButtonExportToExcel,
    },
    filters: [
      {
        field: 'text',
        options: {
          type: 'string',
          label: '',
          valueTemplate: ({ value }: any) =>
            mergeFilters(
              createFilterContains('remarks', value),
              createFilterSmartSearch(
                ['userEmployeeProfile.firstName', 'userEmployeeProfile.lastName'],
                value,
              ),
            ).join('||'),
        },
      },
    ],
  },
  controls: null,
  columns: [
    {
      title: 'date',
      field: 'entryDate',
      template: (data: GridModel) => <TemplateDate date={data.entryDate} />,
      tdClasses: 'mobile-no-wrap',
      valueTemplate: (data: GridModel) => excelTemplateDate(data.entryDate),
    },
    {
      title: 'employee',
      field: 'userEmployeeProfile.fullName as employeeFullName',
      sortable: 'userEmployeeProfile.firstName',
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'remarks',
      field: 'remarks',
      tdStyle: { width: '50%' },
    },
    {
      title: 'changes',
      field: 'changes',
      tdStyle: { width: '50%' },
      template: (data: GridModel) => <TemplateLog value={data.changes} />,
    },
  ],
});
