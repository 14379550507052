import { CALENDAR_RANGE_MINUTES } from 'configs/const';
import { useMemo, useState } from 'react';
import { useTranslate } from 'hooks/use-translate';

const minutes = [0, 10, 20, 30, 40, 50, 60, 90];

export const useMeetingDurationSource = (initDuration: number = CALENDAR_RANGE_MINUTES) => {
  const { t } = useTranslate();

  const optionsWithZero = useMemo(() => {
    return minutes.map((time) => {
      const title = [time, t('minutes')].join(' ');

      return { id: String(time), title };
    });
  }, [t]);
  const options = useMemo(() => {
    return optionsWithZero.filter((item) => item.id !== '0');
  }, [optionsWithZero]);

  const [duration, setDuration] = useState<string>(() => {
    const option = options.find((op) => op.id === String(initDuration));

    return option ? option.id : options[0]?.id;
  });

  return {
    duration,
    setDuration,
    options,
    optionsWithZero,
  };
};
