import React, { useMemo } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { useEffectError, useFetchDynamic } from 'hooks';
import { ServiceNotebooks } from 'services/notebook';
import AddIcon from '@material-ui/icons/Add';
import { useTranslate } from 'hooks/use-translate';
import { useOpen } from 'AurionCR/components/hooks';
import { composeFunctions } from 'utils';
import { NotebookFormGenerator, withPrescriptionTask } from 'components/notebook-form-generator';

const NotebookForm = withPrescriptionTask(NotebookFormGenerator);

interface Props {
  userPatientProfileID: string;
  onRefresh: () => void;
}

export const ButtonAddWeight: React.FC<Props> = ({ userPatientProfileID, onRefresh }) => {
  const { t } = useTranslate();
  const [triggerGet, { error, isLoading, data }] = useFetchDynamic(
    ServiceNotebooks.getNotebookAddWeight,
  );
  useEffectError(error);

  const { isOpen, handleOpen, handleClose } = useOpen();

  const onCloseAndRefresh = useMemo(
    () => composeFunctions(handleClose, onRefresh),
    [handleClose, onRefresh],
  );

  return (
    <>
      <Button
        variant={'outlined'}
        color={'primary'}
        onClick={composeFunctions(triggerGet, handleOpen)}
        startIcon={isLoading ? <CircularProgress size={18} /> : <AddIcon />}
        disabled={isLoading}
      >
        {t('add')}
      </Button>
      {isOpen && data && (
        <NotebookForm
          notebookID={data.id}
          userPatientProfileID={userPatientProfileID}
          onCreated={onRefresh}
          onDone={onCloseAndRefresh}
          showDelete={false}
          onClose={handleClose}
          meetingFromDateTime={undefined}
          meetingToDateTime={undefined}
        />
      )}
    </>
  );
};
