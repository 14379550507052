import { EventEmitter, EventEmitterHandlers } from 'modules/event-emitter';

export interface PatientEvents {
  'refresh-all': { patientID: string };
  'refresh-weight': { patientID: string };
  'refresh-documents': { patientID: string };
  'refresh-sms': { patientID: string };
  'refresh-tasks': { patientID: string };
  'refresh-notebooks': { patientID: string };
  'refresh-general-form': { patientID: string };
  'refresh-meetings': { patientID: string };
}

export type PatientEventsHandlers = EventEmitterHandlers<PatientEvents>;

export const patientEmitter = new EventEmitter<PatientEvents>();
