import React, { useCallback, useEffect, useState } from 'react';
import { Switch } from '@material-ui/core';
import { apiTodoTasks } from 'services/todo-tasks';
import { useEffectError, useMountedRef } from 'hooks';
import { useCurrentUser } from 'components/hooks';
import { isMutationFulfilled } from 'utils/rtk-query';
import { useOpen } from 'AurionCR/components/hooks';
import { Editor } from '../grid-activities';
import { UseFormGrid } from 'AurionCR/components/formV2';
import { ServicePushNotifications } from 'services/push-notifications';
import {
  APP_ROUTES,
  PATIENT_CARD_ALL_ACTIVITIES_TABS,
  PATIENT_CARD_MAIN_TABS,
} from 'configs/routes';
import { getTaskCategoryIconURL } from 'services/todo-task-categories';
import { useTranslate } from 'hooks/use-translate';

const useLazyTaskQuery = apiTodoTasks.useLazyGetTodoTaskDetailsForNotificationQuery;

type Row = {
  id: string;
  sentByUserEmployeeProfileID: string;
  done?: boolean;
};
interface Props {
  row: Row;
  onRefresh: () => void;
}
export const ColumnTaskDone: React.FC<Props> = ({ row, onRefresh }) => {
  const { tp } = useTranslate();
  const { appUserID, name } = useCurrentUser();
  const [innerValue, setInnerValue] = useState(row.done);

  const [triggerUpdate, { isLoading, error }] = apiTodoTasks.useUpdateTodoTaskDoneMutation();
  useEffectError(error);

  const mountedRef = useMountedRef();
  const onChange = useCallback(
    async (checked: boolean) => {
      setInnerValue(checked);

      const result = await triggerUpdate({
        id: row.id,
        done: checked,
        employeeProfileID: appUserID,
      });

      if (!mountedRef.current) return;

      if (isMutationFulfilled(result)) {
        onRefresh();
      }
    },
    [onRefresh, row, appUserID, triggerUpdate, mountedRef],
  );

  const { isOpen, handleOpen, handleClose } = useOpen();

  const onClick = useCallback(
    (e: any, checked: boolean) => {
      if (!checked) return onChange(checked);
      return handleOpen();
    },
    [onChange, handleOpen],
  );

  useEffect(() => {
    setInnerValue(row.done);
  }, [row.done]);

  const [getDetails] = useLazyTaskQuery();
  const onDoneNotification = useCallback(
    async (activity: { remarks: string }) => {
      const { id, sentByUserEmployeeProfileID } = row;
      const resultDetails = await getDetails(id);

      const details = resultDetails.data;

      if (!details) {
        throw new Error('todo-task: Unexpected behaviour');
      }

      const redirect = APP_ROUTES.PATIENT_LIST({
        patient: String(details.userPatientProfileID),
        'main-tab': PATIENT_CARD_MAIN_TABS.ALL_ACTIVITIES,
        'tab-activities-all': PATIENT_CARD_ALL_ACTIVITIES_TABS.TODO_TASKS,
        task: id,
        source: 'push-noty',
      }).link;

      await ServicePushNotifications.sendToEmployee({
        type: 'TASK',
        taskID: id,
        userPatientProfileID: String(details.userPatientProfileID),
        fromUserEmployeeProfileID: appUserID,
        toUserEmployeeProfileID: sentByUserEmployeeProfileID,
        title: tp('todo-task-title-close', {
          rowIndex: Number(details.rowIndex),
          patient: details.patient,
          category: details.category,
        }),
        message: tp('todo-task-message-close', {
          employee: { fullName: String(name) },
          remarks: activity.remarks,
        }),
        redirect,
        icon: getTaskCategoryIconURL(details.category.actionKey),
      });
    },
    [row, getDetails, appUserID, name, tp],
  );

  const onFormHandle = useCallback<UseFormGrid['onFormHandle']>(
    (action) => {
      if (action.type === 'post') {
        onChange(true);
        onDoneNotification(action.payload as { remarks: string });
      }
      handleClose();
    },
    [onChange, handleClose, onDoneNotification],
  );

  return (
    <>
      <Switch checked={innerValue} onChange={onClick} color={'primary'} disabled={isLoading} />
      {isOpen && (
        <Editor
          toDoTaskID={row.id}
          appUserID={appUserID}
          itemID={true}
          onClose={handleClose}
          onFormHandle={onFormHandle}
        />
      )}
    </>
  );
};
