import React from 'react';

import { SUBSCRIPTION_INDEXES } from 'services/subscription';
import { ButtonTreatmentConsultation } from '../button-treatment-consultation';
import { ButtonTreatmentOther } from '../button-treatment-other';
import { ButtonProps } from '@material-ui/core';

interface Props extends ButtonProps {
  userPatientProfileID: string;
  userPatientProfileSubscriptionID: string;
  subscriptionRowIndex: SUBSCRIPTION_INDEXES;
  startDate: string;
  endDate: string;
  price: number;
  onDone: () => void;
}

export const ButtonEndTreatment: React.FC<Props> = ({
  userPatientProfileID,
  userPatientProfileSubscriptionID,
  subscriptionRowIndex,
  startDate,
  endDate,
  onDone,
  ...rest
}) => {
  if (subscriptionRowIndex === SUBSCRIPTION_INDEXES.CONSULTATION) {
    return (
      <ButtonTreatmentConsultation
        userPatientProfileID={userPatientProfileID}
        userPatientProfileSubscriptionID={userPatientProfileSubscriptionID}
        onDone={onDone}
        {...rest}
      />
    );
  }

  return (
    <ButtonTreatmentOther
      userPatientProfileID={userPatientProfileID}
      userPatientProfileSubscriptionID={userPatientProfileSubscriptionID}
      startDate={startDate}
      endDate={endDate}
      onDone={onDone}
      {...rest}
    />
  );
};
