import React, { memo } from 'react';
import { iTMCalendarSupportMeeting } from '../../store';
import SessionPreview from './session-preview';
import SessionCreate from './session-create';
import Prescription from './prescription';
import { usePermissions } from 'hooks/use-permissions';

export interface INotebookPrescription {
  data: Partial<Omit<iTMCalendarSupportMeeting, 'userPatientProfile'>>;
  handleClose?: () => void;
  isMenuItem?: boolean;
  isGridItem?: boolean;
  disabled: boolean;
}

export const NotebookPrescription = memo<INotebookPrescription>((props) => {
  const isSession = Boolean(props.data.userPatientProfileSessionID);
  const isPrescriptionRenewal = Boolean(props.data.isPrescriptionRenewal);
  const disabled = props.disabled;

  const isAllowToCreateAndRenewPrescriptions = usePermissions(
    'isAllowToCreateAndRenewPrescriptions',
  );

  if (isSession) {
    return <SessionPreview {...props} disabled={disabled} />;
  }

  if (isPrescriptionRenewal) {
    if (isAllowToCreateAndRenewPrescriptions) {
      return <Prescription {...props} disabled={disabled} />;
    }
    if (!isAllowToCreateAndRenewPrescriptions && props.isGridItem) {
      return <Prescription {...props} disabled />;
    }

    return null;
  }

  return <SessionCreate {...props} disabled={disabled} />;
});

export * from './dialog-preview-user-patient-profile-session';
export * from './dialog-notebook-form-generator';
export * from './dialog-prescription';
export default NotebookPrescription;
