import React, { memo, useMemo, useRef } from 'react';
import {
  BaseRowActivities,
  MODEL_TYPE,
  Row,
  RowClinicalMeeting,
  RowSubscription,
  RowSupportMeeting,
  RowTask,
} from '../../models';
import { TemplateBadge } from 'components/templates/template-badge';
import { ClinicalMeetingActivitiesDialog } from 'components/clinical-meeting-activities';
import { SupportMeetingActivitiesDialog } from 'components/support-meeting-activities';
import FullscreenIcon from '@material-ui/icons/Fullscreen';

import style from './index.module.scss';
import { useOpen } from 'AurionCR/components/hooks';
import { PreviewPopover } from 'components/preview-popover';
import { useEffectError } from 'hooks';
import { format } from 'date-fns';
import { convertToDate, DateValue } from 'utils/dates';
import { APP_FORMAT_DATE, APP_FORMAT_DATE_TIME } from 'configs/const';
import { Button } from '@material-ui/core';
import { apiClinicalMeetingActivities } from 'services/clinical-meeting-activities';
import { Stack } from 'components/stack';
import { useTranslate } from 'hooks/use-translate';
import { apiSupportMeetingActivities } from 'services/support-meeting-activities';
import { DialogUserPatientProfileSubscriptionActivities } from 'components/user-patient-subscription-activities';
import { apiTodoTaskActivities } from 'services/todo-task-activities';
import { DialogToDoActivities } from 'components/dialog-task';
import { DialogUserPatientProfilePrescriptionActivities } from 'components/user-patient-prescription-activities';
import { apiUserPatientProfilePrescriptionActivities } from 'services/user-patient-profile-prescription-activities';

interface Activity {
  entryDate: DateValue;
  remarks?: string | null | undefined;
}

interface ColumnActivityProps {
  row: BaseRowActivities;
  activities: Activity[];
  isLoading: boolean;
  onClickMore: () => void;

  isOpenPreview: boolean;
  onClosePreview: () => void;
  onOpenPreview: () => void;
}

const ColumnActivity: React.FC<ColumnActivityProps> = ({
  row,
  isLoading,
  activities,
  onClickMore,
  isOpenPreview,
  onOpenPreview,
  onClosePreview,
}) => {
  const { t } = useTranslate();
  const ref = useRef<HTMLButtonElement>(null);

  const options = useMemo(() => {
    return activities.map((option) => {
      return {
        title: format(convertToDate(option.entryDate), APP_FORMAT_DATE),
        value: option.remarks,
      };
    });
  }, [activities]);

  const previewTitle = useMemo(() => {
    return [row.date && format(convertToDate(row.date), APP_FORMAT_DATE_TIME), row.title]
      .filter(Boolean)
      .join(' | ');
  }, [row]);

  return (
    <TemplateBadge
      ref={ref}
      count={row.activities}
      className={style.templateBadge}
      onMouseEnter={row.activities > 0 ? onOpenPreview : undefined}
      onMouseLeave={onClosePreview}
      onClick={onClickMore}
    >
      {row.title}
      <PreviewPopover
        open={isOpenPreview}
        anchorEl={ref.current}
        title={previewTitle}
        isLoading={isLoading}
        options={options}
        onClose={onClosePreview}
      >
        <Stack p={1} justifyContent={'flex-end'}>
          <Button
            startIcon={<FullscreenIcon />}
            size={'small'}
            variant={'contained'}
            color={'primary'}
            onClick={onClickMore}
          >
            {t('show-all')}
          </Button>
        </Stack>
      </PreviewPopover>
    </TemplateBadge>
  );
};

interface ColumnTitleClinicalMeetingProps {
  row: RowClinicalMeeting;
}
const ColumnTitleClinicalMeeting: React.FC<ColumnTitleClinicalMeetingProps> = ({ row }) => {
  const statePreview = useOpen();
  const stateDetails = useOpen();

  const {
    data = [],
    isLoading,
    error,
  } = apiClinicalMeetingActivities.useGetLatestClinicalMeetingActivitiesQuery(row.id, {
    skip: !statePreview.isOpen,
  });
  useEffectError(error);

  return (
    <>
      <ColumnActivity
        row={row}
        isOpenPreview={statePreview.isOpen}
        onOpenPreview={statePreview.handleOpen}
        onClosePreview={statePreview.handleClose}
        isLoading={isLoading}
        activities={data}
        onClickMore={stateDetails.handleOpen}
      />
      {stateDetails.isOpen && (
        <ClinicalMeetingActivitiesDialog
          clinicalMeetingID={row.id}
          onClose={stateDetails.handleClose}
        />
      )}
    </>
  );
};

interface ColumnTitleSupportMeetingProps {
  row: RowSupportMeeting;
}
const ColumnTitleSupportMeeting: React.FC<ColumnTitleSupportMeetingProps> = ({ row }) => {
  const statePreview = useOpen();
  const stateDetails = useOpen();

  const {
    data = [],
    isLoading,
    error,
  } = apiSupportMeetingActivities.useGetLatestSupportMeetingActivitiesQuery(row.id, {
    skip: !statePreview.isOpen,
  });
  useEffectError(error);

  return (
    <>
      <ColumnActivity
        row={row}
        isOpenPreview={statePreview.isOpen}
        onOpenPreview={statePreview.handleOpen}
        onClosePreview={statePreview.handleClose}
        isLoading={isLoading}
        activities={data}
        onClickMore={stateDetails.handleOpen}
      />
      {stateDetails.isOpen && (
        <SupportMeetingActivitiesDialog
          supportMeetingID={row.id}
          onClose={stateDetails.handleClose}
        />
      )}
    </>
  );
};

interface ColumnTitleSubscriptionProps {
  row: RowSubscription;
}
const ColumnTitleSubscription: React.FC<ColumnTitleSubscriptionProps> = ({ row }) => {
  const { isOpen, handleOpen, handleClose } = useOpen();
  return (
    <>
      <TemplateBadge count={row.activities} onClick={handleOpen} />
      {isOpen && (
        <DialogUserPatientProfileSubscriptionActivities
          userPatientProfileSubscriptionID={row.id}
          onClose={handleClose}
        />
      )}
    </>
  );
};

interface ColumnTitleTaskProps {
  row: RowTask;
}
const ColumnTitleTask: React.FC<ColumnTitleTaskProps> = ({ row }) => {
  const statePreview = useOpen();
  const stateDetails = useOpen();

  const {
    data = [],
    isLoading,
    error,
  } = apiTodoTaskActivities.useGetLatestTodoTaskActivitiesQuery(row.id, {
    skip: !statePreview.isOpen,
  });
  useEffectError(error);

  return (
    <>
      <ColumnActivity
        row={row}
        isOpenPreview={statePreview.isOpen}
        onOpenPreview={statePreview.handleOpen}
        onClosePreview={statePreview.handleClose}
        isLoading={isLoading}
        activities={data}
        onClickMore={stateDetails.handleOpen}
      />
      {stateDetails.isOpen && (
        <DialogToDoActivities toDoTaskID={row.id} onClose={stateDetails.handleClose} />
      )}
    </>
  );
};

interface ColumnPrescriptionProps {
  row: RowTask;
}
const ColumnPrescription: React.FC<ColumnPrescriptionProps> = ({ row }) => {
  const statePreview = useOpen();
  const stateDetails = useOpen();

  const {
    data = [],
    isLoading,
    error,
  } = apiUserPatientProfilePrescriptionActivities.useGetLatestPrescriptionActivitiesQuery(row.id, {
    skip: !statePreview.isOpen,
  });
  useEffectError(error);

  return (
    <>
      <ColumnActivity
        row={row}
        isOpenPreview={statePreview.isOpen}
        onOpenPreview={statePreview.handleOpen}
        onClosePreview={statePreview.handleClose}
        isLoading={isLoading}
        activities={data}
        onClickMore={stateDetails.handleOpen}
      />
      {stateDetails.isOpen && (
        <DialogUserPatientProfilePrescriptionActivities
          userPatientProfilePrescriptionID={row.id}
          onClose={stateDetails.handleClose}
        />
      )}
    </>
  );
};

const ColumnDefaultTitle = memo<{ row: Row }>(({ row }) => {
  return <>{row.title}</>;
});

type Component = React.FC<{ row: Row }>;
const COLUMNS_MAP: Record<MODEL_TYPE, Component> = {
  [MODEL_TYPE.SUBSCRIPTION]: ColumnTitleSubscription as Component,
  [MODEL_TYPE.CLINICAL_MEETING]: ColumnTitleClinicalMeeting as Component,
  [MODEL_TYPE.SUPPORT_MEETING]: ColumnTitleSupportMeeting as Component,
  [MODEL_TYPE.PRESCRIPTION]: ColumnPrescription as Component,
  [MODEL_TYPE.TASK]: ColumnTitleTask as Component,
  [MODEL_TYPE.SMS]: ColumnDefaultTitle,
  [MODEL_TYPE.CALL]: ColumnDefaultTitle,
  [MODEL_TYPE.MEDICAL_INFORMATION]: ColumnDefaultTitle,
  [MODEL_TYPE.DOCUMENT]: ColumnDefaultTitle,
  [MODEL_TYPE.SIGNED_DOCUMENT]: ColumnDefaultTitle,
  [MODEL_TYPE.USER_DOCUMENT]: ColumnDefaultTitle,
  [MODEL_TYPE.SESSION]: ColumnDefaultTitle,
  [MODEL_TYPE.NOTE]: ColumnDefaultTitle,
  [MODEL_TYPE.ACTIVITIES]: ColumnDefaultTitle,
};

interface Props {
  row: Row;
}

export const ColumnTitle: React.FC<Props> = ({ row }) => {
  const Component = COLUMNS_MAP[row.type] || ColumnDefaultTitle;

  return (
    <div className={style.root}>
      <Component row={row} />
    </div>
  );
};
