import { createFilterEquals } from 'utils/dynamic-helpers';
import { api, DynamicResult, DynamicService, transformDynamicToItem } from 'utils/service';
import { API_DRUGS, Drug } from './models';
import { apiRtk, RTK_TAGS, transformResponseDynamic } from 'utils/rtk-query';

export * from './models';

class Service extends DynamicService<Drug> {
  async get(drugID: string) {
    const params = {
      filter: createFilterEquals('id', drugID),
      select:
        'defaultDosageForm,defaultDosageFormTotal,defaultDosageFormDaily,defaultInstructions,defaultDosageFormTotalDesc',
    };
    const result = await api.get<
      DynamicResult<
        Pick<
          Drug,
          | 'defaultDosageForm'
          | 'defaultDosageFormTotal'
          | 'defaultDosageFormDaily'
          | 'defaultInstructions'
          | 'defaultDosageFormTotalDesc'
        >,
        typeof params
      >
    >(API_DRUGS.GET_ALL_DYNAMIC, {
      params,
    });

    return { ...result, data: transformDynamicToItem(result.data) };
  }
  async getForm26(drugID: Required<Drug>['id']) {
    const params = {
      filter: createFilterEquals('id', drugID),
    };
    const result = await api.get<DynamicResult<Drug, typeof params>>(API_DRUGS.GET_ALL_DYNAMIC, {
      params,
    });

    return { ...result, data: transformDynamicToItem(result.data) };
  }
}

export const ServiceDrugs = new Service({
  mainField: 'id',
  getAll: API_DRUGS.GET_ALL_DYNAMIC,
  post: API_DRUGS.POST,
  patch: API_DRUGS.PATCH,
  delete: API_DRUGS.DELETE,
});

export const apiDrugs = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getSourceDrugs: builder.query({
      queryFn: async (arg: void) => {
        const { data } = await ServiceDrugs.getAllDynamic<
          Pick<Drug, 'id' | 'catalogName' | 'drugName' | 'drugNameEng' | 'highlight'>
        >({
          select: ['id', 'catalogName', 'drugName', 'drugNameEng', 'highlight'].join(','),
          filter: 'isActive==true',
          orderBy: 'highlight desc, catalogName',
        });

        return { data: transformResponseDynamic(data) };
      },
      providesTags: (_, __, arg) => [{ type: RTK_TAGS.DRUGS, id: 'source' }],
    }),
    getSourceDrugUniqueDosageForms: builder.query<{ id: string; title: string }[], string>({
      queryFn: async (drugID: string) => {
        const { data } = await ServiceDrugs.getDynamic<{ list: string[] }>(drugID, {
          select: [
            'userPatientProfilePrescriptionDetails.GroupBy(x => x.dosageFormTotalDesc).Select(x => x.First().dosageFormTotalDesc) as list',
          ].join(','),
        });

        return { data: data.list.map((id) => ({ id, title: id })) };
      },
    }),
  }),
});
