import { convertToDate, isDateLike } from './dates';

export const transformToPatientIdNumber = <T extends string | null | undefined>(value: T) => {
  const max = 8;
  if (!value) {
    return value;
  }

  return String(value).toLowerCase().replace(/\D+/g, '').padStart(max, '0');
};
export const transformToDateISO = (value: any) => {
  return isDateLike(value) ? convertToDate(value).toISOString() : null;
};

export const transformStringToInteger = (value: string | null | undefined) => {
  const match = String(value ?? '0').match(/\d+/);
  return match ? parseInt(match[0]) : 0;
};

export const transformToOptimizedString = <T extends string | null | undefined>(value: T) => {
  if (!value) return value;
  if (typeof value !== 'string') return value;

  return String(value).replace(/ +/gi, ' ').trim();
};
