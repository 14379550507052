import React, { memo, useCallback } from 'react';
import {
  MedicalPrescriptionEvents,
  MedicalPrescriptionDialog,
} from 'components/medical-prescription';
import { composeFunctions } from 'utils';

interface Props {
  userPatientProfilePrescriptionID: string | true;
  userPatientProfileID: string;
  onClose: () => void;
  onRefresh: () => void;
  onDocumentsCreated: () => void;
}
export const EditorProxy = memo<Props>(
  ({
    userPatientProfileID,
    userPatientProfilePrescriptionID,
    onDocumentsCreated,
    onClose,
    onRefresh,
  }) => {
    const onEvent = useCallback(
      (event: MedicalPrescriptionEvents) => {
        switch (event.type) {
          case 'documents-created': {
            onRefresh();
            onDocumentsCreated();
            return;
          }
        }
      },
      [onDocumentsCreated, onRefresh],
    );

    return (
      <MedicalPrescriptionDialog
        userPatientProfilePrescriptionID={userPatientProfilePrescriptionID}
        userPatientProfileID={userPatientProfileID}
        onClose={onClose}
        onComplete={composeFunctions(onRefresh, onClose)}
        onCreated={onRefresh}
        onDeleted={composeFunctions(onRefresh, onClose)}
        onEventsNew={onEvent}
        onEventsPrev={onEvent}
      />
    );
  },
);
