import React, { memo } from 'react';
import { Button } from '@material-ui/core';
import { useI18n } from 'AurionCR/components';
import style from './index.module.scss';

export const Footer = memo(
  ({
    onCancel,
    onNext,
    onBack,
    disabled = false,
  }: {
    onCancel: (e?: any) => void;
    onNext?: (e?: any) => void;
    onBack?: (e?: any) => void;
    disabled?: boolean;
  }) => {
    const { t } = useI18n();
    // render
    return (
      <div className={style.footer}>
        <div className={style.leftSide}>
          {Boolean(onBack) && (
            <Button className={style.btnBack} color="primary" onClick={onBack} disabled={disabled}>
              {t('back')}
            </Button>
          )}
        </div>
        <div className={style.rightSide}>
          {Boolean(onCancel) && (
            <Button
              className={style.btnCancel}
              color="primary"
              onClick={onCancel}
              disabled={disabled}
            >
              {t('cancel')}
            </Button>
          )}
          {Boolean(onNext) && (
            <Button
              className={style.btnNext}
              color="primary"
              variant="contained"
              size="large"
              onClick={onNext}
              disabled={disabled}
            >
              {t('next')}
            </Button>
          )}
        </div>
      </div>
    );
  },
);

export default Footer;
