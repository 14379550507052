import update from 'immutability-helper';
import {
  PdfFormDocument,
  PdfFormDocumentAction,
  PdfFormDocumentActionTypes,
  PdfFormDocumentInitAction,
  PdfFormDocumentMergeAction,
  PdfFormDocumentPageInputAddAction,
  PdfFormDocumentPageInputModifyAction,
  PdfFormDocumentPageInputRemoveAction,
  PdfFormDocumentResetAction,
  PdfFormDocumentSaveAction,
  PdfFormDocumentScrollToInputAction,
  PdfFormDocumentScrollToPageAction,
  PdfFormDocumentSetPageSizeAction,
  PdfFormDocumentSwapAction,
} from './@type';
import { findIndex } from 'lodash-es';

export const initialState = (): PdfFormDocument => ({
  // flags
  init: false,
  loading: false,
  idPrefix: 'temp_',
  // data
  initData: null,
  // pdf
  scale: 1.3,
  width: 500,
  pages: [],
  // event
  eventRemove: null,
  eventAdd: null,
  eventScrollTo: null,
  eventSelectFabricItem: null,
  eventUpdateFabricPage: null,
  // removed helper
  removed: [],
});

export const pdfFormDocument = (
  state = initialState(),
  action: PdfFormDocumentAction,
): PdfFormDocument => {
  switch (action.type) {
    case PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_RESET: {
      return initialState();
    }
    case PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_MERGE: {
      return update(state, { $merge: action.payload });
    }
    case PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_SET_PAGE_SIZE: {
      const { index, width, height } = action.payload;
      if (state.pages[index]) {
        return update(state, {
          width: { $set: Math.max(width, state.width) },
          pages: { [index]: { $merge: { width, height } } },
        });
      } else {
        return state;
      }
    }
    case PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_PAGE_INPUT_ADD: {
      const { pageNumber } = action.payload;
      if (state.pages[pageNumber]) {
        return update(state, {
          pages: {
            [pageNumber]: { inputs: { $push: [action.payload] } },
          },
          eventAdd: { $set: null },
        });
      } else {
        return state;
      }
    }
    case PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_PAGE_INPUT_REMOVE: {
      const { pageNumber, id } = action.payload;
      const page = state.pages[pageNumber];
      if (page) {
        const index = findIndex(page.inputs, { id });
        if (index !== -1) {
          let state_ = update(state, {
            pages: {
              [pageNumber]: { inputs: { $splice: [[index, 1]] } },
            },
            eventRemove: { $set: null },
          });
          if (id.indexOf(state.idPrefix) === -1) {
            state_ = update(state_, { removed: { $push: [id] } });
          }
          return state_;
        } else {
          return state;
        }
      } else {
        return state;
      }
    }
    case PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_PAGE_INPUT_MODIFY: {
      const { pageNumber, id } = action.payload;
      const page = state.pages[pageNumber];
      if (page) {
        const index = findIndex(page.inputs, { id });
        if (index !== -1) {
          return update(state, {
            pages: {
              [pageNumber]: { inputs: { [index]: { $merge: action.payload } } },
            },
          });
        } else {
          return state;
        }
      } else {
        return state;
      }
    }
    case PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_SWAP: {
      const { from, to, pageNumber } = action.payload;
      const item = state.pages[pageNumber]?.inputs[from];
      if (item) {
        return update(state, {
          pages: {
            [pageNumber]: {
              inputs: {
                $splice: [
                  [from, 1],
                  [to, 0, item],
                ],
              },
            },
          },
        });
      } else {
        return state;
      }
    }

    default:
      return state;
  }
};

export const pdfFormDocumentReset = (): PdfFormDocumentResetAction => {
  return { type: PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_RESET };
};

export const pdfFormDocumentInit = (
  payload: PdfFormDocumentInitAction['payload'],
): PdfFormDocumentInitAction => {
  return { type: PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_INIT, payload };
};

export const pdfFormDocumentSave = (
  payload: PdfFormDocumentSaveAction['payload'],
): PdfFormDocumentSaveAction => {
  return { type: PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_SAVE, payload };
};

export const pdfFormDocumentMerge = (
  payload: Partial<PdfFormDocument>,
): PdfFormDocumentMergeAction => {
  return { type: PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_MERGE, payload };
};

export const pdfFormDocumentSetPageSize = (
  payload: PdfFormDocumentSetPageSizeAction['payload'],
): PdfFormDocumentSetPageSizeAction => {
  return { type: PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_SET_PAGE_SIZE, payload };
};

export const pdfFormDocumentPageInputAdd = (
  payload: PdfFormDocumentPageInputAddAction['payload'],
): PdfFormDocumentPageInputAddAction => {
  return { type: PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_PAGE_INPUT_ADD, payload };
};

export const pdfFormDocumentPageInputRemove = (
  payload: PdfFormDocumentPageInputRemoveAction['payload'],
): PdfFormDocumentPageInputRemoveAction => {
  return { type: PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_PAGE_INPUT_REMOVE, payload };
};

export const pdfFormDocumentPageInputModify = (
  payload: PdfFormDocumentPageInputModifyAction['payload'],
): PdfFormDocumentPageInputModifyAction => {
  return { type: PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_PAGE_INPUT_MODIFY, payload };
};

export const pdfFormDocumentScrollToPage = (
  payload: PdfFormDocumentScrollToPageAction['payload'],
): PdfFormDocumentScrollToPageAction => {
  return { type: PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_SCROLL_TO_PAGE, payload };
};

export const pdfFormDocumentScrollToInput = (
  payload: PdfFormDocumentScrollToInputAction['payload'],
): PdfFormDocumentScrollToInputAction => {
  return { type: PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_SCROLL_TO_INPUT, payload };
};

export const pdfFormDocumentSwap = (
  payload: PdfFormDocumentSwapAction['payload'],
): PdfFormDocumentSwapAction => {
  return { type: PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_SWAP, payload };
};
