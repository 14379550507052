import React, { useCallback } from 'react';
import { CircularProgress, Divider, Typography } from '@material-ui/core';
import style from './index.module.scss';

import {
  usePrescriptionCompletenessStepDraft,
  usePrescriptionCompletenessStepSignDoc,
} from '../../hooks';
import { TemplateBooleanIcon } from 'components/templates/template-boolean-icon';

interface Option {
  title: string;
  value?: string;
  isValid?: boolean;
}
interface Props {
  title: string;
  isValid: boolean;
  isLoading: boolean;
  options: Option[];
}
export const WizardValidity: React.FC<Props> = ({ title, options, isValid, isLoading }) => {
  const renderIcon = useCallback(
    (isValid: boolean) => {
      return isLoading ? (
        <CircularProgress size={16} />
      ) : (
        <TemplateBooleanIcon value={isValid} fontSize={'small'} />
      );
    },
    [isLoading],
  );

  return (
    <table className={style.table}>
      <thead>
        <tr>
          <td>
            <Typography variant={'h3'} color={'inherit'}>
              {title}
            </Typography>
          </td>
          <td />
          <td style={{ width: '2.5rem' }}>{renderIcon(isValid)}</td>
        </tr>
        <tr>
          <td colSpan={3} style={{ height: '1px', paddingBottom: '0.6rem', paddingTop: '0.2rem' }}>
            <Divider
              orientation={'horizontal'}
              style={{ backgroundColor: '#fff', margin: '0 -0.8rem' }}
            />
          </td>
        </tr>
      </thead>
      <tbody>
        {options.map((option, i) => {
          return (
            <tr key={i}>
              <td>
                <Typography variant={'body2'}>{option.title}</Typography>
              </td>
              <td>{option.value && <Typography variant={'body2'}>{option.value}</Typography>}</td>
              <td>{option.isValid !== undefined && renderIcon(option.isValid)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

interface WizardValidityDraftProps {
  prescriptionID: string;
  title: string;
}
export const WizardValidityDraft: React.FC<WizardValidityDraftProps> = ({
  prescriptionID,
  title,
}) => {
  const { isLoading, isValid, items } = usePrescriptionCompletenessStepDraft(prescriptionID);
  return <WizardValidity isLoading={isLoading} title={title} isValid={isValid} options={items} />;
};

interface WizardValiditySignedProps {
  prescriptionID: string;
  title: string;
}
export const WizardValiditySigned: React.FC<WizardValiditySignedProps> = ({
  prescriptionID,
  title,
}) => {
  const { isLoading, isValid, items } = usePrescriptionCompletenessStepSignDoc(prescriptionID);

  return <WizardValidity isLoading={isLoading} title={title} isValid={isValid} options={items} />;
};
