import React, { useCallback } from 'react';
import { useCurrentUser } from 'components/hooks';
import { IDataGridColumnEditorCustom } from 'components/data-grid/model';
import { GridModel } from '../../models';
import { useDataGridTasks } from 'components/data-grid/hooks';
import EditorString from 'components/data-grid/components/column/editors/editor-string';

interface IEditor extends Omit<IDataGridColumnEditorCustom, 'field'> {
  field: keyof GridModel;
}
interface Props {
  row: GridModel;
  editor: IEditor;
  onClose: () => void;
}
export const EditorReply: React.FC<Props> = ({ row, editor, onClose }) => {
  const { onTasks } = useDataGridTasks();
  const { appUserID } = useCurrentUser();

  const onHandleSubmit = useCallback(
    (formData: { data: string }) => {
      onTasks([
        [
          'pathData',
          {
            row: { ...row },
            value: {
              isViewed: true,
              reply: formData.data,
              replyDate: new Date().toISOString(),
              userEmployeeProfileID: appUserID,
            },
          },
        ],
      ]);
      onClose();
    },
    [onTasks, onClose, row, appUserID],
  );
  return (
    <EditorString
      // @ts-ignore
      row={row}
      // @ts-ignore
      editor={editor}
      data={row.reply}
      onSubmit={onHandleSubmit}
      onClose={onClose}
    />
  );
};
