import { API_MEDIA_PRIVATE, CloudFileViewModel } from './models';
import { getRandomString } from 'utils/other';
import { PatchPartial } from 'utils/types';
import { blobToBase64, fileToBase64, getFileNameFromUrl } from 'utils/file-uploader';
import { apiRtk } from 'utils/rtk-query';
import { FileCloud, FileCloudModel } from 'modules/file-cloud';
import { api } from 'utils/service';
import { downloadBlob } from 'utils/download';

export * from './models';

class Service {
  downloadFile = async (filePath: string) => {
    const fileName = getFileNameFromUrl(filePath);
    const { data: blob } = await ServiceMediaPrivate.getFile(filePath);

    return downloadBlob(blob, { fileName });
  };
  async getFile(filePath: string) {
    const result = await api.get<Blob>(API_MEDIA_PRIVATE.DOWNLOAD_FILE, {
      params: {
        fileName: filePath,
      },
      responseType: 'blob',
    });

    if (result.status === 204) {
      throw new Error('content-was-not-found');
    }

    return result;
  }
  async removeFile(filePath: string) {
    return api.put(API_MEDIA_PRIVATE.REMOVE_FILE(filePath));
  }
  async uploadFile(data: PatchPartial<CloudFileViewModel, 'fileStreamString'>) {
    const fileName = data.fileName ? data.fileName : getRandomString(20);
    return api.post<CloudFileViewModel>(API_MEDIA_PRIVATE.UPLOAD_FILE, { ...data, fileName });
  }
}

export const ServiceMediaPrivate = new Service();

export const FileCloudServicePrivate = new FileCloud(
  async (data) => {
    const result = await api.post<FileCloudModel>(API_MEDIA_PRIVATE.UPLOAD_FILE, data);

    return result.data;
  },
  async (data) => {
    return data.filePath
      ? api.put(API_MEDIA_PRIVATE.REMOVE_FILE(data.filePath))
      : Promise.resolve();
  },
);

export const apiMediaPrivate = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getMediaPrivateAsBase64List: builder.query<string[], string[]>({
      queryFn: async (urls, { dispatch }) => {
        try {
          const promises: Promise<string>[] = urls.map(async (url) => {
            const res = dispatch(apiMediaPrivate.endpoints.getMediaPrivateAsBase64.initiate(url));
            res.unsubscribe();
            return await res.unwrap();
          });

          const base64s = await Promise.all(promises);

          return { data: base64s };
        } catch (error: any) {
          return { error };
        }
      },
      keepUnusedDataFor: 60 * 30, // seconds * minutes
    }),
    getMediaPrivateAsBase64: builder.query<string, string>({
      queryFn: async (url: string) => {
        try {
          const { data } = await ServiceMediaPrivate.getFile(url);

          return { data: await fileToBase64(data) };
        } catch (error: any) {
          return { error };
        }
      },
      keepUnusedDataFor: 60 * 30, // seconds * minutes
    }),
    getMediaPrivateBlob: builder.query<Blob, string>({
      queryFn: async (url: string) => {
        return ServiceMediaPrivate.getFile(url);
      },
    }),
    getMediaPrivateBase64: builder.query<string, { url: string; type?: string }>({
      queryFn: async ({ url, type = 'application/pdf' }) => {
        const { data } = await ServiceMediaPrivate.getFile(url);
        const blob = new Blob([data], { type });

        const base64 = await blobToBase64(blob);
        return { data: base64 };
      },
    }),
  }),
});
