import React, { memo } from 'react';
import { EditorContent } from 'components/layout-content';
import style from './index.module.scss';
import { Loading } from 'AurionCR/components';
import { PdfView } from 'components/pdf-view';
import { AppInput } from 'components/app-input';
import { apiMediaPrivate } from 'services/media-private-services';
import { useTranslate } from 'hooks/use-translate';
import { apiUserPatientProfileDocuments } from 'services/user-patient-profile-documents';

interface Props {
  userPatientProfileID: string;
}
export const NotebooksFromRapid = memo<Props>(({ userPatientProfileID }) => {
  const { t } = useTranslate();

  const { data, error, isLoading } =
    apiUserPatientProfileDocuments.useGetUserPatientProfileDocumentMigrationQuery(
      userPatientProfileID,
    );

  const documentUrl = data?.documentURL;

  const { data: docBase64, isFetching } = apiMediaPrivate.useGetMediaPrivateBase64Query(
    { url: documentUrl || '' },
    { skip: !documentUrl },
  );

  return (
    <EditorContent
      InnerProps={{
        minHeight: '100%',
        className: style.root,
      }}
    >
      <AppInput
        error={Boolean(error)}
        helperText={error ? t(error.message || 'error') : ''}
        value={documentUrl}
        disableClearable
        InputProps={{ readOnly: true }}
      />
      <PdfView title={documentUrl || ''} isLoading={isFetching} pdf={docBase64} />
      <Loading active={isLoading} />
    </EditorContent>
  );
});
