import React, { useMemo } from 'react';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { Chip, TextField } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';

interface Props<V extends string | number, DisableClearable extends boolean | undefined = undefined>
  extends Omit<
    AutocompleteProps<string, true, DisableClearable, true>,
    | 'value'
    | 'onChange'
    | 'multiple'
    | 'freeSolo'
    | 'renderTags'
    | 'renderInput'
    | 'selectOnFocus'
    | 'clearOnBlur'
    | 'options'
    | 'handleHomeEndKeys'
  > {
  value?: V[];
  onChange: (event: any) => void;
  label?: string;
  placeholder?: string;
  error?: Error | undefined;
  options?: V[];
}

export const SelectEmails = <
  V extends string,
  DisableClearable extends boolean | undefined = undefined,
>({
  label,
  value,
  onChange,
  placeholder,
  error,
  ...rest
}: Props<V, DisableClearable>) => {
  const options = useMemo(() => {
    const values = [...(rest.options || []), ...(value || [])].map(String);
    return Array.from(new Set(values).values());
  }, [rest.options, value]);
  const { t } = useTranslate();

  return (
    <Autocomplete
      {...rest}
      value={value}
      onChange={(event, value) => {
        onChange({ ...event, target: { ...event.target, value: value as V[] } });
      }}
      multiple
      options={options}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          error={Boolean(error)}
          helperText={error ? t(error.message) : ''}
          {...params}
          label={label}
          placeholder={placeholder}
        />
      )}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
    />
  );
};
