import React, { memo, useCallback, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { IGridEditFormProps } from 'components/models';
import {
  getPartPatientSchema,
  schemaUserPatientProfile,
  ServiceUserPatientProfile,
  UserPatientProfileCreateInput,
} from 'services/user-patient-profile';
import { Form, ProviderPatientData } from './components';
import { useForm, FormProvider } from 'react-hook-form';
import { useRequest, useRequestAlertError, useRequestFormGrid } from 'hooks';
import { useTranslate } from 'hooks/use-translate';
import { MigrateFroExel } from './components/migrate-from-excel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ServiceUserPatientProfileActivities } from 'services/user-patient-profile-activities';
import { useCurrentUser } from 'components/hooks';
import { isMutationFulfilled } from 'utils/rtk-query';

const createSchema = schemaUserPatientProfile.shape({
  excelFile: yup.mixed().nullable().notRequired().default(''),
});

export const DialogNewPatient = memo<IGridEditFormProps>(({ onClose, onFormHandle }) => {
  const { t, tp } = useTranslate();
  const { appUserID } = useCurrentUser();
  const showDietitian = false;
  const schema = useMemo(() => {
    return createSchema.concat(getPartPatientSchema({ userPatientProfileID: null, showDietitian }));
  }, [showDietitian]);
  const [isLoading, setIsLoading] = useState(false);

  const formMethods = useForm({
    defaultValues: schema.cast({}),
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const { handleSubmit } = formMethods;

  const onPatientLog = useCallback(
    async ({ startWeight, id }) => {
      const remarks = tp('activity-patient-update-start-weight', {
        labelKey: tp('add-new'),
        startWeight,
      });
      await ServiceUserPatientProfileActivities.post({
        userEmployeeProfileID: appUserID,
        userPatientProfileID: id,
        entryDate: new Date(),
        remarks,
      });
    },
    [tp, appUserID],
  );
  const onRequest = useCallback(
    async (data: UserPatientProfileCreateInput) => {
      const result = await ServiceUserPatientProfile.createNew(data);
      if (isMutationFulfilled(result)) {
        await onPatientLog({ startWeight: data.weight, id: result.data.appIdentityUserID });
      }
      return result;
    },
    [onPatientLog],
  );

  const _onSubmit = useRequestAlertError(useRequest(onRequest, setIsLoading));

  const onCreate = useRequestFormGrid(_onSubmit, 'post', onFormHandle);
  const onCreateAndContinue = useRequestFormGrid(
    _onSubmit,
    'refreshAndContinue',
    onFormHandle,
    'appIdentityUserID',
  );

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="lg">
      <DialogHeading title={t('creating-new-patient')} onClose={onClose} />
      {isLoading && <LinearProgress />}
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color={'secondary'} variant={'h4'}>
              {t('patient-general')}
            </Typography>
          </Grid>
          <ProviderPatientData patient={undefined} onUpdate={undefined}>
            <FormProvider {...formMethods}>
              <Grid item xs={12}>
                <Form showDietitian={showDietitian} isLoading={isLoading} isNew={true} />
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ marginBottom: '1.6rem' }} />
              </Grid>
              <Grid item xs={12} md={6}>
                <MigrateFroExel isLoading={isLoading} />
              </Grid>
            </FormProvider>
          </ProviderPatientData>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={'primary'} variant={'text'}>
          {t('back')}
        </Button>
        <Button
          disabled={isLoading}
          onClick={handleSubmit(onCreateAndContinue)}
          color={'primary'}
          variant={'contained'}
        >
          {t('create-and-continue')}
        </Button>
        <Button
          disabled={isLoading}
          onClick={handleSubmit(onCreate)}
          color={'primary'}
          variant={'contained'}
        >
          {t('create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
