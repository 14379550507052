import { useEffect } from 'react';
import { useAppDispatch } from 'store';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { requestError } from 'AurionCR/components';
import { AxiosError } from 'axios';
import { SerializedError } from '@reduxjs/toolkit';

export const useEffectError = (error: AxiosError | SerializedError | Error | null | undefined) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (error) {
      dispatch(notifyRequestResult(requestError(error, error?.message), 'error'));
    }
  }, [error, dispatch]);
};
