import React, { forwardRef } from 'react';

import { SvgIconProps } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import variables from 'styles/_variabels.scss';
import CloseIcon from '@material-ui/icons/Close';

interface Props extends SvgIconProps {
  value: boolean;
}

export const TemplateBooleanIcon = forwardRef<SVGSVGElement, Props>(({ value, ...rest }, ref) => {
  return value ? (
    <CheckIcon ref={ref} {...rest} style={{ color: variables.colorGreen, ...rest?.style }} />
  ) : (
    <CloseIcon ref={ref} {...rest} style={{ color: variables.colorRed, ...rest?.style }} />
  );
});
