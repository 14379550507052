import React, { memo } from 'react';
import { iUserPatientProfilePrescriptionDetails } from 'services/user-patient-profile-prescription-details';
import { Controller, useFormContext } from 'react-hook-form';
import { useFieldProps } from 'hooks';
import { AppInput } from 'components/app-input';
import { Left } from 'utils/types';

type FormModel = Left<iUserPatientProfilePrescriptionDetails>;

interface Props {
  isLoading: boolean;
}
export const Form = memo<Props>(({ isLoading }) => {
  const { register, errors, control } = useFormContext<FormModel>();
  const getFiledProps = useFieldProps({ errors, emptyHelperText: '' });

  return (
    <>
      <input {...register('userPatientProfilePrescriptionID')} type="hidden" />
      <input {...register('dosageFormTotal')} type="hidden" />
      <input {...register('drugID')} type="hidden" />

      <Controller
        name={'dosageForm'}
        control={control}
        render={(renderProps) => (
          <AppInput
            {...getFiledProps(renderProps)}
            disabled={isLoading}
            autoFocus={true}
            disableClearable
          />
        )}
      />
      <Controller
        name={'dosageFormTotalDesc'}
        control={control}
        render={(renderProps) => (
          <AppInput
            {...getFiledProps(renderProps)}
            disabled={isLoading}
            autoFocus={true}
            disableClearable
          />
        )}
      />
      <Controller
        name={'dosageFormDaily'}
        control={control}
        render={(renderProps) => <AppInput {...getFiledProps(renderProps)} disabled={isLoading} />}
      />
      <Controller
        name={'instructions'}
        control={control}
        render={(renderProps) => (
          <AppInput {...getFiledProps(renderProps)} disabled={isLoading} multiline minRows={3} />
        )}
      />
    </>
  );
});
