import { DynamicService } from 'utils/service';

import {
  API_USER_EMPLOYEE_PROFILE_TODO_TASK_CATEGORIES,
  UserEmployeeProfileTodoTaskCategoryBulkInput,
} from './models';

export * from './models';

class Service extends DynamicService<Components.Schemas.UserEmployeeProfileToDoTaskCategory> {
  createBulk = async (data: UserEmployeeProfileTodoTaskCategoryBulkInput) => {
    return this.engine.post(API_USER_EMPLOYEE_PROFILE_TODO_TASK_CATEGORIES.INSERT_BULK, {
      mainID: data.toDoTaskCategoryID,
      itemIDs: data.userEmployeeProfileIDs,
    });
  };
}

export const ServiceUserEmployeeTodoTaskCategories = new Service({
  mainField: 'id',
  getAll: API_USER_EMPLOYEE_PROFILE_TODO_TASK_CATEGORIES.GET_ALL_DYNAMIC,
  post: API_USER_EMPLOYEE_PROFILE_TODO_TASK_CATEGORIES.POST,
  delete: API_USER_EMPLOYEE_PROFILE_TODO_TASK_CATEGORIES.DELETE,
  patch: API_USER_EMPLOYEE_PROFILE_TODO_TASK_CATEGORIES.PATCH,
});
