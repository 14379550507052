import { Paper, Popper, PopperProps } from '@material-ui/core';
import { NativeScroll } from 'components/native-scroll';
import React, { forwardRef } from 'react';

export const SelectPopper: React.FC<PopperProps> = (props) => {
  return (
    <Popper {...props} placement="bottom-start">
      {props.children}
    </Popper>
  );
};
export const SelectPaper: React.FC<React.HTMLAttributes<HTMLElement>> = (props) => {
  return <Paper {...props} />;
};

export const SelectListBox = forwardRef<any, React.HTMLAttributes<HTMLElement>>(
  ({ children, ...rest }, ref) => {
    return (
      <NativeScroll ref={ref} {...rest} role={'listbox'}>
        {children}
      </NativeScroll>
    );
  },
);
