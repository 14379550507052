import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'Languages';

export const API_LANGUAGES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<iLanguage, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<iLanguage, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export enum Directions {
  LTR = 1,
  RTL,
}

export interface iLanguage
  extends Omit<FixServerObject<Components.Schemas.Language, 'id'>, 'direction'> {
  direction?: Directions | number;
}

export interface AppLanguage extends Pick<iLanguage, 'id' | 'culture' | 'title' | 'direction'> {}
