import React from 'react';
import { Box, BoxProps } from '@material-ui/core';

interface Props extends BoxProps {
  value: React.ReactNode;
}

export const TemplateLtrOnly: React.FC<Props> = ({ value, ...rest }) => {
  return (
    <Box {...rest} dir={'ltr'} sx={{ ...rest?.style, textAlign: 'left' }}>
      {value}
    </Box>
  );
};
