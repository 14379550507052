import { isNumber } from 'lodash-es';
interface Options {
  x: number;
  y0: number;
  y1: number;
  x0: number;
  x1: number;
}

export const interpolation = (options: Options) => {
  const { x, y1, y0, x0, x1 } = options;
  return y0 + ((y1 - y0) / (x1 - x0)) * (x - x0);
};
export const numberUS = (num: null | string | number | undefined, precision?: number) => {
  if (!isNumber(num)) {
    return '';
  }

  const value = precision ? num.toFixed(precision) : num;

  return Number(value).toLocaleString('en-US', { maximumSignificantDigits: 20 });
};

export const formatWeight = (weight: null | string | number | undefined) => {
  if (!weight) return '0.00';
  return parseFloat(String(weight)).toFixed(2);
};
