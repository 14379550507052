import { actionLanguagesInit } from './actions';
import { languagesStorage } from './helpers';
import { actionLanguagesSetLanguageID } from './slice';
import { call, put, takeEvery } from 'typed-redux-saga';
import { actionLabelsFetch } from '../labels';
import i18n from 'modules/i18n';

function* watchInitSuccess(action: ReturnType<typeof actionLanguagesInit.fulfilled>) {
  const { languageID } = action.payload;
  yield* call(languagesStorage.setObject, { languageID });
  yield* put(actionLabelsFetch(languageID));
  yield* call(i18n.changeLanguage, languageID);
}
function* watchSetLanguage(action: ReturnType<typeof actionLanguagesSetLanguageID>) {
  const languageID = action.payload;
  yield* call(languagesStorage.setObject, { languageID });

  yield* put(actionLabelsFetch(languageID));
  yield* call(i18n.changeLanguage, languageID);
}
export const languagesSaga = [
  takeEvery(actionLanguagesInit.fulfilled, watchInitSuccess),
  takeEvery(actionLanguagesSetLanguageID, watchSetLanguage),
];
