import React, { memo, useEffect, useState } from 'react';
import GridConfig from './grid-config';
import DataGrid from 'components/data-grid';
import { useFormGrid } from 'AurionCR/components/formV2';
import { patientEmitter } from '../../emitter';
import style from './index.module.scss';

interface Props {
  userPatientProfileID: string;
}
export const GridActivitiesMedicalInformation = memo<Props>(({ userPatientProfileID }) => {
  const [gridConfig] = useState<any>(GridConfig(userPatientProfileID));
  const { grid, onGridHandle } = useFormGrid('activities-medical-information');

  useEffect(() => {
    const refresh = (payload: { patientID: string }) => {
      if (payload.patientID !== userPatientProfileID) {
        return;
      }
      grid.current?.onTasks([['getData']]);
    };

    patientEmitter.on('refresh-all', refresh);
    return () => {
      patientEmitter.off('refresh-all', refresh);
    };
  }, [userPatientProfileID, grid]);

  return <DataGrid className={style.root} ref={grid} config={gridConfig} onHandle={onGridHandle} />;
});
