import React, { useCallback } from 'react';
import { BaseAction } from '../../models';
import { ButtonAction } from '../button-action';
import { useHistory } from 'react-router-dom';
import { APP_ROUTES } from 'configs/routes';

interface Props extends BaseAction {}
export const ActionNextClinicalAppointment: React.FC<Props> = ({
  onDone: _onDone,
  actionKey,
  isLoading,
}) => {
  const { push } = useHistory();
  const onDone = useCallback(() => {
    _onDone();
    push(APP_ROUTES.CLINICAL_MEETINGS().config);
  }, [_onDone, push]);
  return <ButtonAction actionKey={actionKey} onClick={onDone} disabled={isLoading} />;
};
