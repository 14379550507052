import { Validate } from 'react-hook-form';

export const validateTaskEmployees: Validate = (v) => {
  const MIN_EMPLOYEES = 1;
  if (!v) {
    return 'required';
  }
  if (Array.isArray(v) && v.length < MIN_EMPLOYEES) {
    return 'required';
  }
  return undefined;
};
