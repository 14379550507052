import React, { memo } from 'react';
import { InputAdornment } from '@material-ui/core';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { useTranslate } from 'hooks/use-translate';
import { useOpen } from 'AurionCR/components/hooks';
import { AppInput } from 'components/app-input';
import { MedicalPrescriptionDialog } from 'components/medical-prescription';

interface Props {
  supportMeetingID: string;
  userPatientProfileID: string;
  onComplete: () => void;
  disabled: boolean;
}

export const NotebookRenewalInput = memo<Props>(
  ({ userPatientProfileID, onComplete, disabled }) => {
    const { t } = useTranslate();
    const { isOpen, handleOpen, handleClose } = useOpen();

    return (
      <>
        <AppInput
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position={'start'}>
                <NoteAddIcon color={'primary'} />
              </InputAdornment>
            ),
          }}
          value={t('create-renewal')}
          onClick={handleOpen}
          color={'primary'}
          disableClearable
          disabled={disabled}
        />
        {isOpen && (
          <MedicalPrescriptionDialog
            userPatientProfilePrescriptionID={true}
            userPatientProfileID={userPatientProfileID}
            onClose={handleClose}
            onComplete={onComplete}
          />
        )}
      </>
    );
  },
);
