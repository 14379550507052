import React from 'react';

import { Button, TextField, TextFieldProps } from '@material-ui/core';
import { FileAccept, useFileUploader } from 'hooks';
import { getFileInfo, ValueFileUploader } from 'utils/file-uploader';
import { Stack } from 'components/stack';
import { useTranslate } from 'hooks/use-translate';

const defAccepts: FileAccept[] = ['.xlsx'];

interface Props {
  accepts?: FileAccept[];
  value: ValueFileUploader | null;
  onChange: (value: this['value']) => void;
}

export const InputBrowse: React.FC<Props & Omit<TextFieldProps, 'value' | 'onChange'>> = ({
  accepts = defAccepts,
  value,
  onChange,
  ...rest
}) => {
  const { t } = useTranslate();
  const { inputProps, onOpen, onRemove, error } = useFileUploader({ accept: accepts, onChange });
  const { fileName } = getFileInfo(value);
  const helperText = rest.helperText || error ? error?.message : null;

  return (
    <>
      <input {...inputProps} />
      <Stack spacing={0} direction={'row'} alignItems={'flex-start'}>
        <TextField
          value={fileName || ''}
          fullWidth
          {...rest}
          InputProps={{
            ...rest.InputProps,
            readOnly: true,
          }}
          error={rest.error || Boolean(error)}
          helperText={helperText}
        />
        {!value && (
          <Button
            style={{ marginTop: '1rem' }}
            disabled={rest.disabled}
            onClick={onOpen}
            color={'primary'}
            variant={'outlined'}
          >
            {t('browse')}
          </Button>
        )}
        {value && (
          <Button
            style={{ marginTop: '1rem' }}
            disabled={rest.disabled}
            onClick={onRemove}
            variant={'outlined'}
            color={'secondary'}
          >
            {t('remove')}
          </Button>
        )}
      </Stack>
    </>
  );
};
