import { DynamicService } from 'utils/service';
import * as DOMPurify from 'dompurify';
import * as dynamic from 'utils/dynamic-helpers';

import {
  API_USER_PATIENT_PROFILE_SESSION_ITEMS,
  UserPatientProfileSessionItem,
  UserPatientProfileSessionItemWeight,
} from './models';
import { format } from 'date-fns';
import { APP_FORMAT_DATE } from 'configs/const';
import { apiRtk } from 'utils/rtk-query';
export * from './models';

class Service extends DynamicService<UserPatientProfileSessionItem> {
  clearEntryValueString = (entryValueString: any) => {
    return String(entryValueString || '').replace(/\s+/g, ' ');
  };
  makeInternalSystemValue(data: any) {
    if (data === undefined || data === null) {
      return null;
    }
    return JSON.stringify({ value: data });
  }
  parseInternalSystemValue(data: any) {
    if (!data) {
      return null;
    }
    if (typeof data !== 'string') {
      return null;
    }
    return JSON.parse(data)?.value;
  }
  adapterPreviewItem = <
    T extends Pick<
      UserPatientProfileSessionItem,
      'entryValueString' | 'entryValueBool' | 'entryValueNumber' | 'entryValueDate'
    >,
  >(
    data: T,
    options: { translate: (key: string) => string },
  ) => {
    const { translate } = options;
    const { entryValueString, entryValueBool, entryValueNumber, entryValueDate } = data;
    let activityInfo =
      entryValueBool ||
      entryValueString ||
      entryValueNumber ||
      (entryValueDate && format(new Date(entryValueDate), APP_FORMAT_DATE)) ||
      '';
    if (entryValueBool !== null) activityInfo = translate(String(activityInfo));
    if (String(activityInfo).indexOf('\n') === 0) activityInfo = String(activityInfo).slice(1);

    const previewValue = String(activityInfo).replace('\n', '<br/>');

    return {
      ...data,
      previewValue: DOMPurify.sanitize(previewValue, { USE_PROFILES: { html: true } }),
    };
  };
  postOrPatch = async (data: Partial<UserPatientProfileSessionItem>) => {
    const dataToSave = {
      id: data.id ?? undefined,
      userPatientProfileSessionID: data.userPatientProfileSessionID,
      fieldInputID: data.fieldInputID,
      entryValueString: data.entryValueString,
      entryValueBool: data.entryValueBool,
      entryValueNumber: data.entryValueNumber,
      entryValueDate: data.entryValueDate,
      internalSystemValue: data.internalSystemValue,
      externalSystemValue: data.externalSystemValue,
      rank: data.rank,
    };

    if (dataToSave.id) {
      const result = await this.patch(dataToSave);

      return { ...result, data: dataToSave };
    }

    return this.post({ ...dataToSave, id: undefined } as unknown as UserPatientProfileSessionItem);
  };
}

export const ServiceUserPatientProfileSessionItems = new Service({
  mainField: 'id',
  getAll: API_USER_PATIENT_PROFILE_SESSION_ITEMS.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_SESSION_ITEMS.POST,
  delete: API_USER_PATIENT_PROFILE_SESSION_ITEMS.DELETE,
  patch: API_USER_PATIENT_PROFILE_SESSION_ITEMS.PATCH,
});

export const apiUserPatientProfileSessionItems = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getSessionItemWeights: builder.query<UserPatientProfileSessionItemWeight[], string>({
      queryFn: async (userPatientProfileID) => {
        try {
          const { data } =
            await ServiceUserPatientProfileSessionItems.getAllDynamic<UserPatientProfileSessionItemWeight>(
              {
                filter: dynamic
                  .mergeFilters(
                    dynamic.createFilterEquals(
                      'userPatientProfileSession.userPatientProfileID',
                      userPatientProfileID,
                    ),
                    dynamic.createFilterEquals('fieldInput.inputType.isWeight', true),
                  )
                  .join('&&'),
                select: dynamic.select(
                  'id',
                  'entryValueNumber as weight',
                  'userPatientProfileSession.entryDate as entryDate',
                  'userPatientProfileSession.userEmployeeProfileID as userEmployeeProfileID',
                  'new { userPatientProfileSession.userEmployeeProfile.firstName, userPatientProfileSession.userEmployeeProfile.lastName, userPatientProfileSession.userEmployeeProfile.signature } as userEmployeeProfile',
                ),
                orderBy: dynamic.orderBy('userPatientProfileSession.entryDate', 'desc'),
              },
            );
          return { data: data.value };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
