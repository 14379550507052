import React, { useCallback } from 'react';
import { useTranslate } from 'hooks/use-translate';
import { GridModel } from '../../models';
import { useOpen } from 'AurionCR/components/hooks';
import { DialogConfirm } from 'components/dialog-confirm';
import { apiUserPatientProfileDietDiaries } from 'services/user-patient-profile-diet-diaries';
import { useEffectError } from 'hooks';
import { isMutationFulfilled } from 'utils/rtk-query';
import { actionDietDiariesReportLoadData } from '../../store';
import { useAppDispatch } from 'store';
import { ButtonDelete } from 'components/app-buttons';

const useDeleteMutation =
  apiUserPatientProfileDietDiaries.useDeleteUserPatientProfileDietDiaryMutation;

interface Props {
  row: GridModel;
}
export const ControlDelete: React.FC<Props> = ({ row }) => {
  const dispatch = useAppDispatch();

  const { tp } = useTranslate();
  const { isOpen, handleOpen, handleClose } = useOpen();
  const onRefresh = useCallback(() => {
    dispatch(actionDietDiariesReportLoadData());
  }, [dispatch]);

  const [performDelete, resultDelete] = useDeleteMutation();
  useEffectError(resultDelete.error);

  const onConfirm = async () => {
    const res = await performDelete({ id: row.id });

    if (isMutationFulfilled(res)) {
      onRefresh();
      handleClose();
    }
  };

  const isLoading = resultDelete.isLoading;
  return (
    <>
      <ButtonDelete onClick={handleOpen} disabled={isLoading} />
      {isOpen && (
        <DialogConfirm
          isLoading={isLoading}
          onConfirm={onConfirm}
          onCancel={handleClose}
          message={tp('confirm-delete')}
        />
      )}
    </>
  );
};
