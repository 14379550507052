import React from 'react';
import { Panel, PanelGroup } from 'react-resizable-panels';
import { AppPanelResizeHandle } from 'components/app-panel-resize-handle';
interface Slots {
  left: React.ReactNode;
  right?: React.ReactNode;
  bottom?: React.ReactNode;
}
interface Props {
  slots: Slots;
}
export const FormPanelLayout: React.FC<Props> = ({ children, slots }) => {
  const { left, right, bottom } = slots || {};
  return (
    <PanelGroup direction={'vertical'}>
      <Panel>
        <PanelGroup direction={'horizontal'}>
          <Panel>{left}</Panel>
          {right && (
            <>
              <AppPanelResizeHandle
                style={{
                  margin: '0 1rem',
                }}
                direction={'vertical'}
              />
              <Panel>{right}</Panel>
            </>
          )}
        </PanelGroup>
      </Panel>
      {bottom && (
        <>
          <AppPanelResizeHandle direction={'horizontal'} />
          <Panel>{bottom}</Panel>
        </>
      )}
    </PanelGroup>
  );
};
