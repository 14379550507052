import { DynamicService } from 'utils/service';
import {
  SMSContents,
  API_SMS_CONTENTS,
  SMS_CONTENT_KEYS,
  SMSContentsTestSendInput,
  SMSContentsCreateUpdateInput,
} from './models';
import { convertToDate, DateValue } from 'utils/dates';
import { calcHtmlTemplate } from 'utils';
import { format } from 'date-fns';
import { APP_FORMAT_DATE } from 'configs/const';
import { ServiceUserEmployeePermissionSmsContents } from 'services/user-employee-profile-permisson-sms-contents';
import { apiRtk } from 'utils/rtk-query';

export * from './models';

interface BaseInput {
  languageID: string;
  contentKey: SMS_CONTENT_KEYS;
}

interface NotifyPatientMeetingInput extends BaseInput {
  date: DateValue;
  employeeNames: string[];
}

class Service extends DynamicService<SMSContents> {
  getContentByKey = async (input: BaseInput) => {
    const {
      data: { value },
    } = await this.getAllDynamic({
      take: 1,
      filter: [`languageID=="${input.languageID}"`, `smsKey=="${input.contentKey}"`].join('&&'),
    });
    const result = value[0];

    if (!result) {
      throw new Error(`SmsContent: "${input.contentKey}" is not exist for the selected language`);
    }

    return result;
  };
  makePatientMeetingMessage = async (input: NotifyPatientMeetingInput) => {
    const { contentKey, languageID, date, employeeNames } = input;
    const { message } = await this.getContentByKey({ contentKey, languageID });

    return {
      message: calcHtmlTemplate(message || '', {
        date: format(convertToDate(date), APP_FORMAT_DATE),
        day: format(convertToDate(date), 'iiii'),
        fromTime: format(convertToDate(date), 'HH:mm'),
        employeeNames: employeeNames.join(', '),
      }),
    };
  };
  testSend = async (input: SMSContentsTestSendInput) => {
    return this.engine.get(API_SMS_CONTENTS.SEND, { params: input });
  };

  create = async (input: SMSContentsCreateUpdateInput) => {
    const { userEmployeeProfilePermissionIDs, ...rest } = input;
    const result = await this.post(rest);
    await ServiceUserEmployeePermissionSmsContents.createBulk({
      smsContentID: result.data.id,
      userEmployeeProfilePermissionIDs,
    });

    return result;
  };
  update = async (input: SMSContentsCreateUpdateInput) => {
    const { userEmployeeProfilePermissionIDs, ...rest } = input;
    await Promise.all([
      this.patch(rest),
      ServiceUserEmployeePermissionSmsContents.createBulk({
        smsContentID: rest.id,
        userEmployeeProfilePermissionIDs,
      }),
    ]);

    return { data: input };
  };
}

export const ServiceSmsContents = new Service({
  mainField: 'id',
  getAll: API_SMS_CONTENTS.GET_ALL_DYNAMIC,
  post: API_SMS_CONTENTS.POST,
  patch: API_SMS_CONTENTS.PATCH,
  delete: API_SMS_CONTENTS.DELETE,
});

export const apiSmsContents = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getSmsContentBySmsKey: builder.query({
      queryFn: async (arg: BaseInput) => {
        const data = await ServiceSmsContents.getContentByKey(arg);
        return { data };
      },
    }),
  }),
});
