import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'UserPatientProfileSessionPastDrugForObesities';

export const API_USER_PATIENT_PROFILE_SESSIONS_PAST_DRUG_FOR_OBESITIES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  POST_BULK: `${API_ENDPOINT}/CreateUserPatientProfileSessionPastDrugForObesityBulk`,
  PATCH: (data: Pick<UserPatientProfileSessionPastDrugForObesity, 'id'>) =>
    `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<UserPatientProfileSessionPastDrugForObesity, 'id'>) =>
    `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface UserPatientProfileSessionPastDrugForObesity
  extends FixServerObject<Components.Schemas.UserPatientProfileSessionPastDrugForObesity, 'id'> {
  createdDate?: string;
}

export interface CreateBulkUserPatientProfileSessionPastDrugForObesityInput {
  userPatientProfileSessionID: string;
  pastDrugIDs: string[];
}
