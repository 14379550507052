import React, {
  forwardRef,
  memo,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { IDataGrid, IDataGridRowProps } from './model';
import Pagination from './components/pagination';
import Toolbar from './components/toolbar';
import DeleteDialog from './components/delete-dialog';
import Filters from './components/filters';
import Table from './components/table';
import {
  createGetProps,
  requestCreate,
  requestDelete,
  requestGet,
  requestPath,
  requestSwap,
} from './components/requests';
import { parser } from './parser';
import { reduce } from 'lodash-es';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Loading, useAuth } from 'AurionCR/components';
import Controls from './components/toolbar/controns';
import ControlsDefault from './components/toolbar/contronsDefault';
import { DataGridEmpty } from './components/data-grid-empty';
import { BoxProps } from '@material-ui/core';

import './_grid.scss';
import { useTranslate } from 'hooks/use-translate';
import { NativeScrollProps } from 'components/native-scroll';
import { GridTasksProvider } from './components/grid-tasks-provider';

export interface IGridControlsSlotProps {
  onTasks: any;
  model: any;
  filters: any;
  sortable: any;
  pagination: any;
  data: undefined | any[];
}
export interface IDataGridProps {
  config: IDataGrid;
  visible?: boolean;
  preview?: boolean;
  onHandle?: (payload?: any) => void;
  onFilter?: (payload?: any) => void;
  className?: string;
  rowControls?: any;
  renderToolbarControlsSlot?: (props: IGridControlsSlotProps) => React.ReactNode;
  disableFilterMobiles?: boolean;
  isHideSwap?: boolean;
  renderControlsSlot?: (props: IGridControlsSlotProps) => React.ReactNode;
  MiddleSlot?: React.ComponentType<{ onTasks: any; filters: any }>;
  PaginationSlot?: React.ComponentType<{ pagination: any; model: any; onTasks: any }>;
  rowProps?: IDataGridRowProps;
  FiltersBoxProps?: Partial<BoxProps>;
  EmptySlot?: React.ReactNode;
  ScrollProps?: Partial<NativeScrollProps>;
}

export const DataGrid = memo(
  forwardRef(
    (
      {
        config,
        visible,
        preview,
        onHandle,
        onFilter,
        rowControls,
        renderToolbarControlsSlot,
        renderControlsSlot,
        MiddleSlot,
        PaginationSlot,
        isHideSwap,
        rowProps,
        FiltersBoxProps,
        EmptySlot,
        ScrollProps,
        disableFilterMobiles = false,
        ...props
      }: IDataGridProps,
      ref: Ref<any>,
    ) => {
      const auth = useAuth();
      const { t } = useTranslate();
      const dispatch = useDispatch();
      const [model, setModel] = useState<any>();
      const [data, setData] = useState<any>();
      const [loading, setLoading] = useState(false);
      const [pagination, setPagination] = useState<any>({ page: 0, size: 0, total: 0 });
      const [sortable, setSortable] = useState<any>({ field: '', order: '' });
      const [filters, setFilters] = useState<any>({});
      const [triggerLoadData, setTriggerLoadData] = useState<any>(0);
      const [triggerPathData, setTriggerPathData] = useState<any>(null);
      const [triggerCreateData, setTriggerCreateData] = useState<any>(null);
      const [triggerDeleteData, setTriggerDeleteData] = useState<any>(null);
      const [triggerDeleteDialog, setTriggerDeleteDialog] = useState<any>(null);
      const [triggerSwap, setTriggerSwap] = useState<any>(null);
      const [cancelToken] = useState(axios.CancelToken.source());

      const reducer: any = useCallback(
        ({ type, payload }: { type: string; payload?: any }) => {
          switch (type) {
            case 'loading':
              return setLoading(payload);
            case 'data':
              return setData(payload);
            // PAGINATION
            case 'pagination':
              return setPagination((state: any) => {
                if (payload.total !== undefined) {
                  const size = typeof state.size === 'number' ? state.size : 1;

                  return {
                    ...state,
                    ...payload,
                    total: Math.ceil(payload.total / size),
                    total_: payload.total,
                  };
                }
                return { ...state, ...payload };
              });
            // SORTABLE
            case 'sortable':
              return setSortable((state: any) => ({ ...state, ...payload }));
            case 'sortableTrigger':
              return setSortable((state: any) => {
                if (state.field !== payload) {
                  return { field: payload, order: 'desc' };
                } else if (state.order === 'desc') {
                  return { field: payload, order: 'asc' };
                } else if (model?.store?.swap) {
                  return {
                    field: model?.store?.sortable?.field || '',
                    order: model?.store?.sortable?.order || '',
                  };
                }
                return { field: '', order: '' };
              });
            // FILTERS
            case 'filters':
              return setFilters((state: any) => {
                return reduce(
                  { ...state, ...payload },
                  (result: any, value: any, key: any) => {
                    if (value !== undefined && value !== '') result[key] = value;
                    return result;
                  },
                  {},
                );
              });
            // TRIGGER
            case 'getData':
              return setTriggerLoadData((state: any) => state + 1);
            case 'pathData':
              return setTriggerPathData(payload);
            case 'createData':
              return setTriggerCreateData(payload);
            case 'swapData':
              return setTriggerSwap(payload);
            case 'deleteData':
              return setTriggerDeleteDialog(payload);
            case 'deleteDataRequest':
              return setTriggerDeleteData(payload);
            case 'onHandle':
              if (onHandle) onHandle({ grid: model.id, ...payload });
              break;
          }
        },
        [onHandle, model],
      );

      const onTasks = useCallback(
        (tasks: any[]) => {
          tasks.forEach((task) => {
            reducer({ type: task[0], payload: task[1] });
          });
        },
        [reducer],
      );

      useImperativeHandle(ref, () => ({
        onTasks: onTasks,
        get: (type: string) => {
          switch (type) {
            case 'model':
              return model;
            case 'data':
              return data;
            case 'loading':
              return loading;
            case 'pagination':
              return pagination;
            case 'sortable':
              return sortable;
            case 'filters':
              return filters;
          }
        },
        getParamsGet: () => {
          return createGetProps({ model, pagination, filters, sortable });
        },
      }));

      useEffect(() => {
        if (onFilter) onFilter({ ...filters });
      }, [filters, onFilter]);

      // init
      useEffect(
        () => {
          const result: { model: IDataGrid; filters: any } = parser({ config, auth });
          setData(undefined);
          setLoading(false);
          setPagination({ page: 0, size: 0, total: 0 });
          setSortable({ field: '', order: '' });
          setFilters({});
          onTasks([
            [
              'pagination',
              // @ts-ignore
              { page: result.model.pagination.page, size: result.model.pagination.size, total: 0 },
            ],
            // @ts-ignore
            ['sortable', { ...result.model.store.sortable }],
            ['filters', result.filters],
          ]);

          setModel(result.model);
          if (result.model && result.model.store.autoLoad) reducer({ type: 'getData' });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [config],
      );
      // trigger GetData
      useEffect(
        () => {
          if (model)
            requestGet({
              model,
              pagination,
              filters,
              sortable,
              onTasks,
              dispatch,
              cancelToken,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [triggerLoadData],
      );
      // trigger PathData
      useEffect(
        () => {
          if (model && triggerPathData) {
            const { method, url } = model.store.patch;
            if (url) {
              requestPath({
                url,
                method,
                onTasks,
                dispatch,
                data: triggerPathData,
              });
            } else {
              console.error('Please set grid path url', model);
            }
          }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [triggerPathData],
      );
      // trigger createData
      useEffect(
        () => {
          if (model && triggerCreateData) {
            const { url } = model.store.create;
            if (url) {
              requestCreate({
                url,
                mainField: model.store.mainField,
                onTasks,
                dispatch,
                data: triggerCreateData,
              });
            } else {
              console.error('Please set grid create url', model);
            }
          }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [triggerCreateData],
      );
      // trigger swapData
      useEffect(
        () => {
          if (model && triggerSwap) {
            requestSwap({
              model,
              filters,
              sortable,
              onTasks,
              dispatch,
              payload: triggerSwap,
            });
          }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [triggerSwap],
      );
      // trigger deleteData
      useEffect(
        () => {
          if (model && triggerDeleteData) {
            const { url } = model.store.delete;
            if (url) {
              requestDelete({
                url,
                pagination,
                onTasks,
                dispatch,
                data: triggerDeleteData,
              });
            } else {
              console.error('Please set grid delete url', model);
            }
          }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [triggerDeleteData],
      );
      // destroy
      useEffect(() => {
        return () => {
          if (cancelToken) cancelToken.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      const hasFilters = useMemo(() => {
        return !!Object.keys(filters).length;
      }, [filters]);
      const hasSort = useMemo(() => {
        return !!sortable.field && model?.store?.swap?.field !== sortable.field;
      }, [sortable.field, model]);
      const _isHideSwap = useMemo(() => {
        return isHideSwap !== undefined ? isHideSwap : hasFilters || hasSort;
      }, [isHideSwap, hasFilters, hasSort]);

      const EmptyPart = EmptySlot || (
        <DataGridEmpty title={hasFilters && t('not-found')} className={'wrapper'} />
      );
      const TablePart =
        !data?.length && !loading ? (
          EmptyPart
        ) : (
          <Table
            ScrollProps={ScrollProps}
            rowProps={rowProps}
            isHideSwap={_isHideSwap}
            model={model}
            data={data}
            sortable={sortable}
            rowControls={rowControls}
            preview={preview}
            pagination={pagination}
          />
        );

      const gridClasses = useMemo(() => {
        const classes = {
          [props.className || '']: !!props.className,
          'is-filtered': Object.keys(filters).length !== 0,
        };
        return Object.entries(classes)
          .filter(([key, value]) => value)
          .map(([key, value]) => key)
          .join(' ');
      }, [filters, props.className]);

      const PaginationPart = !!PaginationSlot ? (
        <PaginationSlot pagination={pagination} model={model} onTasks={onTasks} />
      ) : (
        <Pagination pagination={pagination} model={model} onTasks={onTasks} />
      );

      const controlsProps = useMemo(
        () => ({
          onTasks,
          model,
          filters,
          sortable,
          pagination,
          data,
        }),
        [onTasks, model, filters, sortable, pagination, data],
      );

      return (
        <GridTasksProvider onTasks={onTasks}>
          {(visible === undefined || visible) && (
            <div className={`data-grid ${gridClasses}`}>
              <Toolbar model={model} />
              <div className="data-grid__toolbar">
                {renderToolbarControlsSlot && renderToolbarControlsSlot(controlsProps)}
                <Filters
                  disableMobiles={disableFilterMobiles}
                  model={model}
                  filters={filters}
                  onTasks={onTasks}
                  {...FiltersBoxProps}
                />
                <div className={'data-grid__toolbar-button'}>
                  {renderControlsSlot && renderControlsSlot(controlsProps)}
                  <ControlsDefault
                    model={model}
                    onTasks={onTasks}
                    filters={filters}
                    pagination={pagination}
                    sortable={sortable}
                  />
                  <Controls
                    model={model}
                    onTasks={onTasks}
                    filters={filters}
                    pagination={pagination}
                    sortable={sortable}
                  />
                </div>
              </div>
              <div className="data-grid-wrapper">
                {TablePart}
                {!!MiddleSlot && <MiddleSlot onTasks={onTasks} filters={filters} />}
                {PaginationPart}
                <Loading active={loading} />
              </div>
            </div>
          )}
          {triggerDeleteDialog && (
            <DeleteDialog
              title={model.store.delete?.title}
              field={model.store.delete?.field}
              data={triggerDeleteDialog}
              onTasks={onTasks}
            />
          )}
        </GridTasksProvider>
      );
    },
  ),
);

export default DataGrid;

export {} from './hooks';
