import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseErrorData } from 'utils/service';
import {
  apiRemarkForPatientCallStatuses,
  REMARK_REASON,
} from 'services/remark-for-patient-call-status';
import { AppAsyncThunkConfig } from 'store/store';
import { selectAuthUser } from 'store/auth';
import { dateFormat } from 'utils/dates';
import { APP_FORMAT_DATE, APP_FORMAT_TIME } from 'configs/const';
import { omit, pick } from 'lodash-es';
import { ServiceSupportMeetings, SupportMeeting } from 'services/support-meetings';
import {
  ServiceSupportMeetingActivities,
  SupportMeetingActivity,
} from 'services/support-meeting-activities';
import { i18nAppTranslator } from 'modules/i18n';
import { fieldToLabelKey, getDiff } from 'utils/other';
import * as dynamic from 'utils/dynamic-helpers';

const comparePrimitiveFields: Array<keyof SupportMeeting> = ['includeMeetingTime', 'remarks'];
const compareRelationFields: Array<keyof SupportMeeting> = [
  'supportMeetingTypeID',
  'userPatientProfileID',
  'userEmployeeProfileID',
  // needs to make sure that these fields are exist
  'meetingFromDateTime',
  'meetingToDateTime',
];

const getUpdateParams = (key: keyof SupportMeeting, info: Partial<MeetingInformation>) => {
  if (comparePrimitiveFields.includes(key)) {
    return { name: key, value: info.hasOwnProperty(key) ? String((info as any)[key]) : 'UNKNOWN' };
  }
  if (key === 'supportMeetingTypeID') {
    return {
      name: i18nAppTranslator.t('meeting-type'),
      value: String(info.supportMeetingType?.title) || 'UNKNOWN',
    };
  }
  if (key === 'userPatientProfileID') {
    return {
      name: i18nAppTranslator.t(fieldToLabelKey(key)),
      value: String(info.userPatientProfile?.fullName) || 'UNKNOWN',
    };
  }

  return { name: i18nAppTranslator.t(fieldToLabelKey(key)), value: 'UNKNOWN' };
};

interface MeetingInformation
  extends Pick<SupportMeeting, 'meetingFromDateTime' | 'meetingToDateTime' | 'remarks'> {
  supportMeetingType: Pick<Components.Schemas.SupportMeetingType, 'title'>;
  userPatientProfile: Pick<Components.Schemas.UserPatientProfile, 'fullName'>;
  userEmployeeProfile: Pick<Components.Schemas.UserEmployeeProfile, 'fullName'> & { role: string };
}

const actionSupportMeetingActivitiesGetMeetingAfterChanges = createAsyncThunk<
  MeetingInformation,
  string,
  AppAsyncThunkConfig
>(`SUPPORT_MEETING_ACTIVITIES/getMeetingAfterChanges`, async (meetingID) => {
  try {
    const { data } = await ServiceSupportMeetings.getDynamic<MeetingInformation>(meetingID, {
      select: dynamic.select(
        'new { supportMeetingType.title } as supportMeetingType',
        'new { userPatientProfile.fullName } as userPatientProfile',
        'new { userEmployeeProfile.fullName, userEmployeeProfile.userCrmProfilePermission.title as role } as userEmployeeProfile',
        'meetingFromDateTime',
        'meetingToDateTime',
      ),
    });

    return data;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

const actionSupportMeetingActivitiesCrateLog = createAsyncThunk<
  void,
  Pick<SupportMeetingActivity, 'supportMeetingID' | 'remarks'> & { reason: REMARK_REASON },
  AppAsyncThunkConfig
>(`SUPPORT_MEETING_ACTIVITIES/createLog`, async (input, { getState, dispatch }) => {
  try {
    const { reason, ...rest } = input;

    const res = dispatch(
      apiRemarkForPatientCallStatuses.endpoints.getRemarkByReason.initiate(reason),
    );

    const remark = await res.unwrap();
    res.unsubscribe();

    const user = selectAuthUser(getState());

    const remarkForPatientCallStatusID = remark?.id;

    if (!user) {
      throw new Error('current employee is required');
    }

    if (!remarkForPatientCallStatusID) {
      throw new Error('remark status is required');
    }

    await ServiceSupportMeetingActivities.post({
      ...rest,
      remarkForPatientCallStatusID,
      entryDate: new Date().toISOString(),
      userEmployeeProfileID: user.appUserID,
    });
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionSupportMeetingActivitiesCrateLogCreated = createAsyncThunk<
  void,
  { supportMeetingID: string },
  AppAsyncThunkConfig
>(`SUPPORT_MEETING_ACTIVITIES/createLogCreated`, async ({ supportMeetingID }, { dispatch }) => {
  try {
    const meetingInfo = await dispatch(
      actionSupportMeetingActivitiesGetMeetingAfterChanges(supportMeetingID),
    ).unwrap();

    const remarks = i18nAppTranslator.tp('activity-support-meeting-created', {
      patient: { fullName: meetingInfo.userPatientProfile.fullName || '' },
      employee: {
        fullName: meetingInfo.userEmployeeProfile.fullName || '',
        role: meetingInfo.userEmployeeProfile.role,
      },
      date: dateFormat(meetingInfo.meetingFromDateTime, APP_FORMAT_DATE),
      time: dateFormat(meetingInfo.meetingFromDateTime, APP_FORMAT_TIME),
    });

    await dispatch(
      actionSupportMeetingActivitiesCrateLog({
        supportMeetingID,
        remarks,
        reason: REMARK_REASON.AUTO,
      }),
    );
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionSupportMeetingActivitiesCrateLogReassigned = createAsyncThunk<
  void,
  { supportMeetingID: string; meeting: MeetingInformation },
  AppAsyncThunkConfig
>(
  `SUPPORT_MEETING_ACTIVITIES/crateLogReassigned`,
  async ({ supportMeetingID, meeting }, { dispatch }) => {
    try {
      const remarks = i18nAppTranslator.tp('activity-support-meeting-reassigned', {
        employee: {
          fullName: meeting.userEmployeeProfile.fullName || '',
          role: meeting.userEmployeeProfile.role,
        },
      });

      await dispatch(
        actionSupportMeetingActivitiesCrateLog({
          supportMeetingID,
          remarks,
          reason: REMARK_REASON.REASSIGNED,
        }),
      );
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);

export const actionSupportMeetingActivitiesCrateLogRescheduled = createAsyncThunk<
  void,
  { supportMeetingID: string; meeting: MeetingInformation },
  AppAsyncThunkConfig
>(
  `SUPPORT_MEETING_ACTIVITIES/crateLogRescheduled`,
  async ({ supportMeetingID, meeting }, { dispatch }) => {
    try {
      const remarks = i18nAppTranslator.tp('activity-support-meeting-rescheduled', {
        date: dateFormat(meeting.meetingFromDateTime, APP_FORMAT_DATE),
        time: dateFormat(meeting.meetingFromDateTime, APP_FORMAT_TIME),
      });

      await dispatch(
        actionSupportMeetingActivitiesCrateLog({
          supportMeetingID,
          remarks,
          reason: REMARK_REASON.RESCHEDULED,
        }),
      );
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);

export const actionSupportMeetingActivitiesCrateLogUpdated = createAsyncThunk<
  void,
  { oldData: Partial<SupportMeeting>; newData: Partial<SupportMeeting> },
  AppAsyncThunkConfig
>(
  `SUPPORT_MEETING_ACTIVITIES/crateLogUpdated`,
  async ({ oldData, newData }, { getState, dispatch }) => {
    try {
      const supportMeetingID = newData.id || oldData.id;

      if (!supportMeetingID) {
        throw new Error('supportMeetingID is required');
      }

      let dif = getDiff(newData, oldData);

      let compared = pick(dif, [...comparePrimitiveFields, ...compareRelationFields]);

      const isContainsDifInRelations = compareRelationFields.some((fieldName) =>
        compared.hasOwnProperty(fieldName),
      );

      let promises: Array<Promise<any>> = [];

      let changesInfo = dif;

      if (isContainsDifInRelations) {
        const info = await dispatch(
          actionSupportMeetingActivitiesGetMeetingAfterChanges(supportMeetingID),
        ).unwrap();

        changesInfo = { ...changesInfo, ...info };

        if (compared.hasOwnProperty('userEmployeeProfileID')) {
          promises.push(
            dispatch(
              actionSupportMeetingActivitiesCrateLogReassigned({ supportMeetingID, meeting: info }),
            ),
          );
          compared = omit(compared, 'userEmployeeProfileID');
        }
        if (
          compared.hasOwnProperty('meetingFromDateTime') ||
          compared.hasOwnProperty('meetingToDateTime')
        ) {
          promises.push(
            dispatch(
              actionSupportMeetingActivitiesCrateLogRescheduled({
                supportMeetingID,
                meeting: info,
              }),
            ),
          );
          compared = omit(compared, 'meetingFromDateTime', 'meetingToDateTime');
        }

        const restKeys = Object.keys(compared) as Array<keyof typeof compared>;

        restKeys.forEach((key) => {
          const remarks = i18nAppTranslator.tp(
            'activity-support-meeting-updated-general',
            getUpdateParams(key, changesInfo as MeetingInformation),
          );

          promises.push(
            dispatch(
              actionSupportMeetingActivitiesCrateLog({
                supportMeetingID,
                remarks,
                reason: REMARK_REASON.AUTO,
              }),
            ),
          );
        });

        await Promise.all(promises);
      }
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);

export const actionSupportMeetingActivitiesCrateLogSendSms = createAsyncThunk<
  void,
  { supportMeetingID: string; message: string },
  AppAsyncThunkConfig
>(
  `SUPPORT_MEETING_ACTIVITIES/crateLogSendSms`,
  async ({ supportMeetingID, message }, { dispatch }) => {
    try {
      const remarks = i18nAppTranslator.tp('activity-support-meeting-send-sms', {
        message,
      });

      await dispatch(
        actionSupportMeetingActivitiesCrateLog({
          supportMeetingID,
          remarks,
          reason: REMARK_REASON.AUTO,
        }),
      );
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);

export const actionSupportMeetingActivitiesCrateLogCallToPatient = createAsyncThunk<
  void,
  { supportMeetingID: string },
  AppAsyncThunkConfig
>(
  `SUPPORT_MEETING_ACTIVITIES/crateLogCallToPatient`,
  async ({ supportMeetingID }, { dispatch }) => {
    try {
      const remarks = i18nAppTranslator.tp('activity-support-meeting-call-to-patient');

      await dispatch(
        actionSupportMeetingActivitiesCrateLog({
          supportMeetingID,
          remarks,
          reason: REMARK_REASON.AUTO,
        }),
      );
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
