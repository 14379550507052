import { NotebookFieldInput } from 'services/notebook-field-input';
import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'Notebooks';

export const API_NOTEBOOKS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<Notebook, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<Notebook, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface Notebook
  extends FixServerObject<
    Omit<Components.Schemas.Notebook, 'userEmployeeProfilePermissionID'>,
    'id'
  > {
  notebookFieldInputs?: NotebookFieldInput[];
}

export interface NotebookWithPermissions extends Notebook {
  userEmployeeProfilePermissionIDs: string[];
}
export enum NOTEBOOK_INDEXES {
  FIRST_MEETING_CONSULTATION = 226,
  QUESTIONNAIRE_EN = 235,
  QUESTIONNAIRE_HE = 234,
}
