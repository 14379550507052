import React, { memo, useEffect, useMemo, useState } from 'react';
import GridConfig from './grid-config';
import DataGrid from 'components/data-grid';
import { useFormGrid } from 'AurionCR/components/formV2';
import { patientEmitter } from '../../emitter';
import { AppSwitch } from 'components/app-switch';
import { Stack } from 'components/stack';
import { useTranslate } from 'hooks/use-translate';

interface ControlsProps {
  displayLogs: boolean;
  onChangeDisplayLogs: (value: this['displayLogs']) => void;
}
const Controls: React.FC<ControlsProps> = ({ displayLogs, onChangeDisplayLogs }) => {
  const { t } = useTranslate();

  return (
    <Stack mx={1}>
      <AppSwitch
        label={t('display-logs')}
        value={displayLogs}
        onChange={(e, v) => onChangeDisplayLogs(v)}
      />
    </Stack>
  );
};

interface Props {
  userPatientProfileID: string;
}
export const GridCalls = memo<Props>(({ userPatientProfileID }) => {
  const [displayLogs, setDisplayLogs] = useState(false);
  const gridConfig = useMemo<any>(() => {
    return GridConfig({ userPatientProfileID, displayLogs });
  }, [userPatientProfileID, displayLogs]);
  const { grid, onGridHandle } = useFormGrid('call');

  useEffect(() => {
    const refresh = (payload: { patientID: string }) => {
      if (payload.patientID !== userPatientProfileID) {
        return;
      }
      grid.current?.onTasks([['getData']]);
    };

    patientEmitter.on('refresh-all', refresh);
    return () => {
      patientEmitter.off('refresh-all', refresh);
    };
  }, [userPatientProfileID, grid]);

  return (
    <DataGrid
      ref={grid}
      config={gridConfig}
      onHandle={onGridHandle}
      renderControlsSlot={() => (
        <Controls displayLogs={displayLogs} onChangeDisplayLogs={setDisplayLogs} />
      )}
    />
  );
});
