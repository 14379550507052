import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actionLoosingWeightLoadData } from './actions';
import { addWeeks, endOfDay, startOfDay } from 'date-fns';
import { LoosingWeightFilters, LoosingWeightItem } from '../models';

const loosingWeightInitFilters = (): LoosingWeightFilters => {
  const today = new Date();

  const serverStartDate = addWeeks(startOfDay(today), -4).toISOString();
  const serverEndDate = endOfDay(today).toISOString();

  return {
    serverStartDate,
    serverEndDate,

    startDate: serverStartDate,
    endDate: serverEndDate,

    age: [18, 80],
    deltaStart: 0,
    deltaEnd: 10,
    typeID: null,
    isActive: true,
  };
};

interface State {
  isLoading: boolean;
  isInit: boolean;
  filters: LoosingWeightFilters;
  weights: LoosingWeightItem[];
}

const initialState = (): State => {
  return {
    isLoading: false,
    isInit: false,
    filters: loosingWeightInitFilters(),
    weights: [],
  };
};

const slice = createSlice({
  name: 'LOOSING_WEIGHT',
  initialState,
  reducers: {
    actionLoosingWeightSetFilters(state, action: PayloadAction<Partial<LoosingWeightFilters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers(build) {
    build
      .addCase(actionLoosingWeightLoadData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actionLoosingWeightLoadData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.weights = action.payload;
      })
      .addCase(actionLoosingWeightLoadData.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});
export const { actionLoosingWeightSetFilters } = slice.actions;
export const loosingWeightReducer = slice.reducer;
