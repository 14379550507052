import { UserEmployeeProfile } from 'services/user-employee-profiles';
import { iUserPatientProfilePrescriptionDetails } from 'services/user-patient-profile-prescription-details';
import { DateValue } from 'utils/dates';
import { FixServerObject } from 'utils/types';
import { PrescriptionStep } from 'services/prescription-steps';
import { UserPatientProfileSubscription } from 'services/user-patient-profile-subscriptions';
import { Subscription } from 'services/subscription';
import { Drug } from 'services/drug';
import { UserPatientProfileSessionClinicDrug } from 'services/user-patient-profile-session-clinic-drugs';
import { IPatientCardInfo, UserPatientProfile } from '../user-patient-profile';
import { FormDocumentCategoryType } from '../form-document-categories';

const API_ENDPOINT = 'UserPatientProfilePrescriptions';

export const API_USER_PATIENT_PROFILE_PRESCRIPTIONS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<iUserPatientProfilePrescription, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<iUserPatientProfilePrescription, 'id'>) =>
    `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface iUserPatientProfilePrescription
  extends FixServerObject<
    Components.Schemas.UserPatientProfilePrescription,
    'id' | 'userPatientProfileID'
  > {}

export interface UserPrescriptionGetActivitiesInput {
  userPatientProfileID: string;
  dateRange: DateValue[];
}
export interface UserPrescriptionGeneratePrescriptionDocumentsInput {
  id: string;
  pdfWithWatermark: string;
  pdfWithoutWatermark: string;
  pdfPrint: string;
  entryDate: string;
  patientFullName: string;
}
export interface UserPrescriptionGetActivitiesItem
  extends Pick<iUserPatientProfilePrescription, 'id' | 'entryDate' | 'prescriptionFilePath'> {
  employee: UserEmployeeProfile['fullName'];
  drugs: (Pick<iUserPatientProfilePrescriptionDetails, 'dosageFormTotal' | 'dosageFormDaily'> &
    Pick<Components.Schemas.Drug, 'catalogName'>)[];
  activities: number;
}

export interface IMedicalPrescriptionWizardDataItem
  extends Pick<
    Components.Schemas.UserPatientProfilePrescription,
    | 'id'
    | 'entryDate'
    | 'isCompleted'
    | 'isPrint'
    | 'isSent'
    | 'userPatientProfileID'
    | 'userPatientProfile'
    | 'userEmployeeProfileID'
    | 'userEmployeeProfile'
    | 'prescriptionStepID'
    | 'pharmacyFollowupID'
    | 'prescriptionStep'
    | 'prescriptionFilePath'
    | 'prescriptionPrintFilePath'
    | 'prescriptionWatermarkFilePath'
    | 'userPatientProfileFormDocuments'
  > {
  prescriptionStep: Pick<PrescriptionStep, 'stepTypeKey'>;
  userPatientProfile: Pick<
    Components.Schemas.UserPatientProfile,
    'fullName' | 'firstName' | 'lastName' | 'mobilePhone' | 'dateOfBirth'
  >;
  subscription:
    | (Pick<UserPatientProfileSubscription, 'id' | 'startDate' | 'endDate' | 'paymentDate'> &
        Pick<Subscription, 'durationMonths' | 'labelKey'>)
    | null;
  userEmployeeProfile: Pick<Components.Schemas.UserEmployeeProfile, 'fullName' | 'userPhoto'>;
  healthSurveyCount: number;
  userPatientProfileFormDocuments: Pick<
    Components.Schemas.UserPatientProfileFormDocument,
    'signedDate'
  >[];
  userPatientProfilePrescriptionDetails: (Pick<
    iUserPatientProfilePrescriptionDetails,
    'id' | 'includeForm29' | 'drugID'
  > &
    Pick<Drug, 'catalogName'>)[];
}

export interface GetMedicalPrescriptionLatestData
  extends Pick<iUserPatientProfilePrescription, 'id' | 'entryDate'> {
  details: (Pick<Drug, 'catalogName'> &
    Pick<
      iUserPatientProfilePrescriptionDetails,
      'dosageFormTotalDesc' | 'dosageFormDaily' | 'instructions' | 'id' | 'includeForm29'
    >)[];
}

export interface CreateNewInput {
  userPatientProfileID: string;
  remarks: string;
}

export interface CreateNewLatestSession {
  userPatientProfileSessionClinicDrugs: Required<
    Pick<UserPatientProfileSessionClinicDrug, 'drugID' | 'dosageForm'>
  >[];
}

export type GenerateNotebookInput = {
  userEmployeeProfileID: string;
  userPatientProfilePrescriptionID: string;
};

export interface MedicalPrescriptionTitle extends IPatientCardInfo {
  entryDate: string | undefined;
  idNumber: string | undefined;
  maintenanceWeight: number | null | undefined;
}

export interface GetMedicalPrescriptionsWithDrugsItem
  extends Pick<iUserPatientProfilePrescription, 'id' | 'entryDate' | 'isCompleted'> {
  drugs: Pick<Drug, 'id' | 'drugNameEng' | 'catalogName'>[];
}

export type PdfDataOutput = {
  doctor: Pick<
    Required<UserEmployeeProfile>,
    'fullName' | 'licenseNumber' | 'signature' | 'appIdentityUserID'
  >;
  patient: Pick<
    Required<UserPatientProfile>,
    'fullName' | 'idNumber' | 'city' | 'mobilePhone' | 'appIdentityUserID' | 'dateOfBirth'
  >;
  entryDate: string;
  drugs: (Pick<Required<Drug>, 'drugName'> &
    Pick<
      Required<iUserPatientProfilePrescriptionDetails>,
      'dosageForm' | 'dosageFormTotal' | 'dosageFormDaily' | 'instructions'
    >)[];
  remarks: string;
};

export type DataForPatientEmailOutput = {
  patient: Pick<Required<UserPatientProfile>, 'idNumber' | 'fullName' | 'email'>;
} & Pick<
  iUserPatientProfilePrescription,
  'id' | 'prescriptionFilePath' | 'userPatientProfileID' | 'entryDate'
>;

export type PrintPatientPrescriptionInput = {
  userPatientProfilePrescriptionID: string;
  url: string;
  remarks: string;
};

export type MakeWizardSignedDocumentValidationInput = {
  prescriptionDrugs: { id: string; catalogName: string }[];
  patient: { dateOfBirth: DateValue };
  systemDocuments: { id: string; drugIDs: string[]; title: string; isUnder18: boolean }[];
};

export interface MakeWizardValidityItemsDraftInput {
  paymentDate: string | null | undefined;
  healthSurveyCount: number | null | undefined;
}

export interface MakeWizardValidityItemsSignInput extends MakeWizardSignedDocumentValidationInput {
  prescriptionDocs: { signedDate: DateValue }[];
  patientSignedDocs: { id: string; type: FormDocumentCategoryType }[];
}

export type MakeWizardValidityItemsSignOutputDoc = {
  type: 'doc';
  docTitle: string;
  value: number;
  isValid: boolean;
  drugs: string;
};
export type MakeWizardValidityItemsSignOutputRegular = {
  type: 'regular';
  labelKey: string;
  value?: string;
  isValid?: boolean;
};

export type MakeWizardValidityItemsSignOutput =
  | MakeWizardValidityItemsSignOutputDoc
  | MakeWizardValidityItemsSignOutputRegular;

export interface MedicalPrescriptionDetailForNeopharm {
  drugID: string;
  drugName: string;
  dosageFormTotalDesc: string;
  pharmacyDrug: { id: string } | null;
  quantities: Array<{
    id: string;
    dosageFormTotalDesc: string;
    quantity: number;
  }>;
}

export interface MedicalPrescriptionDetailsForUserCopy {
  userPatientProfileID: string;
  entryDate: DateValue;
  patient: {
    fullName: string;
    email: string;
    idNumber: string;
  };
  documents: { id: string; documentURL: string; fileName: string; isForm29: boolean }[];
  prescriptionURL: string;
}

export interface CompleteMedicalPrescriptionInput {
  userPatientProfilePrescriptionID: string;
  remarks: string;
}

export interface MedicalPrescriptionHoldInput {
  id: string;
  onHoldDate: string;
  remark: string;
}
