import React from 'react';
import style from './index.module.scss';
import {
  GridOtherUnsignedDocuments,
  GridSignedDocuments,
  SignedDocumentsCategories,
} from './components';

interface Props {
  prescriptionID: string;
  userPatientProfileID: string | null | undefined;
  userPatientProfileSubscriptionID: string | null | undefined;
  isActive: boolean;
  onRefresh: () => void;
}

export const TabSigned: React.FC<Props> = ({
  prescriptionID,
  userPatientProfileID,
  onRefresh,
  userPatientProfileSubscriptionID,
}) => {
  return (
    <div className={style.root}>
      <SignedDocumentsCategories
        className={style.categories}
        prescriptionID={prescriptionID}
        userPatientProfileID={userPatientProfileID}
        userPatientProfileSubscriptionID={userPatientProfileSubscriptionID}
      />
      <GridSignedDocuments
        className={style.docs}
        prescriptionID={prescriptionID}
        userPatientProfileID={userPatientProfileID}
        onRefresh={onRefresh}
      />
      <GridOtherUnsignedDocuments
        className={style.other}
        prescriptionID={prescriptionID}
        userPatientProfileID={userPatientProfileID}
        onRefresh={onRefresh}
      />
    </div>
  );
};
