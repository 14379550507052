import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Chip, ChipProps, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import style from './index.module.scss';

interface Props extends ChipProps {
  value: boolean;
  title: string;
}

export const ActivitiesStatus: React.FC<Props> = ({ value, title }) => {
  return (
    <Tooltip title={title}>
      <Chip
        size={'medium'}
        avatar={value ? <CheckIcon /> : <CloseIcon />}
        label={title}
        classes={{
          root: clsx(value && style.rootActive),
          avatar: clsx(value && style.avatarActive),
        }}
      />
    </Tooltip>
  );
};
