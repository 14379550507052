import React, { useCallback } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { apiUserPatientPrescriptions } from 'services/user-patient-profile-prescriptions';
import { useEffectError } from 'hooks';
import { useTranslate } from 'hooks/use-translate';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { useOpen } from 'AurionCR/components/hooks';
import { DialogRemark } from 'components/dialog-remark';
import { isMutationFulfilled } from 'utils/rtk-query';

const useCompleteMutation = apiUserPatientPrescriptions.useCompleteMedicalPrescriptionMutation;

interface Options {
  prescriptionID: string;
  prescriptionStepID: string;
  onDone: () => void;
  shouldAskReason: boolean;
}

interface Props extends ButtonProps, Options {}
export const ButtonPrescriptionComplete: React.FC<Props> = ({
  prescriptionID,
  prescriptionStepID,
  onDone,
  shouldAskReason,
  ...rest
}) => {
  const { tp } = useTranslate();

  const [complete, resultComplete] = useCompleteMutation();
  useEffectError(resultComplete.error);
  const { isOpen, handleOpen, handleClose } = useOpen();

  const onSubmit = useCallback(
    async (formData: { remarks: string }) => {
      const result = await complete({
        userPatientProfilePrescriptionID: prescriptionID,
        remarks: formData.remarks,
      });

      if (isMutationFulfilled(result)) {
        handleClose();
        onDone();
      }
    },
    [complete, prescriptionID, onDone, handleClose],
  );

  const onSubmitAuto = useCallback(() => {
    return onSubmit({ remarks: tp('activity-patient-prescription-complete-auto') });
  }, [onSubmit, tp]);

  return (
    <>
      <Button
        variant={'contained'}
        color={'primary'}
        startIcon={<DoneAllIcon />}
        onClick={shouldAskReason ? handleOpen : onSubmitAuto}
        size={'small'}
        {...rest}
        disabled={resultComplete.isLoading || resultComplete.isSuccess || rest.disabled}
      >
        {tp('complete')}
      </Button>
      {isOpen && (
        <DialogRemark
          title={tp('prescription-complete-title')}
          description={tp('prescription-complete-description')}
          isLoading={resultComplete.isLoading}
          onClose={handleClose}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};
