import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { validateEmail, validateRule } from 'AurionCR/components/formV2';
import { SelectEmails } from '../select-emails';
import { HtmlEditor, HtmlEditorConfigDefault, HtmlEditorConfig } from 'components/html-editor';
import { EmailModel } from '../../models';
import { useFieldProps } from 'hooks';
import { Left } from 'utils/types';
import { useTranslate } from 'hooks/use-translate';
import { AppInput } from 'components/app-input';
import { useAppSelector } from 'store';
import { selectLanguageIsRtl } from 'store/languages';

const editorConfig: Partial<HtmlEditorConfig> = {
  ...HtmlEditorConfigDefault,
  buttons: 'source,|,table,|,bold,|,ul,ol,|,align,|,fontsize,link,|,undo,redo',
  cleanHTML: {
    ...HtmlEditorConfigDefault.cleanHTML,
    allowTags: {
      div: true,
      p: true,
      span: true,
      a: true,
      br: true,
      h1: true,
      h2: true,
      h3: true,
      h4: true,
      h5: true,
      h6: true,
      strong: true,
      ul: true,
      ol: true,
      li: true,
      table: true,
      thead: true,
      tbody: true,
      tr: true,
      td: true,
      th: true,
    },
  },
  height: 300,
};

interface Props {
  isLoading: boolean;
}

type FormModel = Left<EmailModel>;

export const SendMessageForm: React.FC<Props> = ({ isLoading }) => {
  const { t } = useTranslate();
  const { control, errors } = useFormContext<FormModel>();

  const isRtl = useAppSelector(selectLanguageIsRtl);

  const config = useMemo(() => {
    const direction = isRtl ? 'rtl' : 'ltr';
    return { ...editorConfig, direction } as const;
  }, [isRtl]);

  const getFieldProps = useFieldProps({ errors });

  const validateToEmails = useMemo(() => {
    return {
      validate: {
        required: (val: string[]) => {
          if (Array.from(val) && val.length === 0) {
            return 'rule-required';
          }
        },
        email: (val: string[]) => {
          const isAllEmails = val.every((emailValue) => validateEmail(emailValue) === undefined);

          if (isAllEmails) {
            return undefined;
          }

          return 'rule-email';
        },
      },
    };
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={'toEmails'}
          rules={validateToEmails}
          render={(renderProps) => (
            <SelectEmails
              {...getFieldProps(renderProps)}
              placeholder={t('enter-email')}
              disabled={isLoading}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={'fromDisplayName'}
          rules={validateRule('required')}
          render={(renderProps) => (
            <AppInput {...getFieldProps(renderProps)} disabled={isLoading} />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={'replyToEmail'}
          rules={validateRule('required,email')}
          render={(renderProps) => (
            <AppInput {...getFieldProps(renderProps)} disabled={isLoading} />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={'subject'}
          rules={validateRule('required')}
          render={(renderProps) => (
            <AppInput {...getFieldProps(renderProps)} disabled={isLoading} />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={'html'}
          render={(renderProps) => (
            <HtmlEditor {...getFieldProps(renderProps)} disabled={isLoading} config={config} />
          )}
        />
      </Grid>
    </Grid>
  );
};
