import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'UserPatientProfileSessionClinicDrugs';

export const API_USER_PATIENT_PROFILE_SESSIONS_CLINIC_DRUGS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<UserPatientProfileSessionClinicDrug, 'id'>) =>
    `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<UserPatientProfileSessionClinicDrug, 'id'>) =>
    `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface UserPatientProfileSessionClinicDrug
  extends FixServerObject<Components.Schemas.UserPatientProfileSessionClinicDrug, 'id'> {
  createdDate?: string;
}
