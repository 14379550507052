import React, { memo, useCallback, useMemo } from 'react';
import {
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  LinearProgress,
} from '@material-ui/core';
import {
  apiSupportMeeting,
  schemaSupportMeetingNew,
  SupportMeetingInput,
} from 'services/support-meetings';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { SelectMeetingType } from 'components/select-meeting-type';
import style from './index.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import { useSourceSupportMeetingTypes } from 'components/hooks';
import { useEffectError, useFieldProps, useFormDefaultValue, useMountedRef } from 'hooks';
import { usePermissions } from 'hooks/use-permissions';
import { Form } from '../form';
import { createDefaultMeetingRange } from 'utils/app-helpers';
import { useAppDispatch } from 'store';
import { actionSupportMeetingActivitiesCrateLogCreated } from 'store/support-meeting-activities';
import { yupResolver } from '@hookform/resolvers/yup';
import { isMutationFulfilled } from 'utils/rtk-query';
import { ButtonCancel, ButtonSave } from 'components/app-buttons';
import { PatientInfo } from '../patient-info';

const useMutationPost = apiSupportMeeting.useCreateSupportMeetingMutation;

const defaultValues = () => ({
  ...createDefaultMeetingRange(),
});

type FormModel = Omit<SupportMeetingInput, '_IS_RENEWAL'>;

export interface DialogSupportMeetingNewProps {
  itemID: true;
  initData?: Partial<FormModel>;
  times: { id: string }[];
  onClose: () => void;
  onRefresh: () => void;
}

export const DialogSupportMeetingNew = memo<DialogSupportMeetingNewProps>(
  ({ initData, onRefresh, onClose, times }) => {
    const dispatch = useAppDispatch();
    const formMethods = useForm({
      defaultValues: schemaSupportMeetingNew.cast(
        { ...defaultValues(), ...initData },
        { stripUnknown: true, assert: false },
      ),
      resolver: yupResolver(schemaSupportMeetingNew),
    });

    const { control, errors, setValue, watch, handleSubmit } = formMethods;
    const getFieldProps = useFieldProps({ errors });

    const [triggerPost, resultPost] = useMutationPost();
    useEffectError(resultPost.error);

    const sourceSupportMeetingTypes = useSourceSupportMeetingTypes();

    const isAllowToEdit = usePermissions('!isAllowToEditTaskManagerLimited');
    const isReadOnly = !isAllowToEdit;

    const isLoading = resultPost.isLoading;

    const disabled = useMemo(
      () => isLoading || Boolean(initData?.userPatientProfileSessionID) || isReadOnly,
      [isLoading, initData, isReadOnly],
    );

    const supportMeetingTypeID = watch('supportMeetingTypeID');

    const isRenewal = useMemo(() => {
      if (!supportMeetingTypeID) return false;
      const type = sourceSupportMeetingTypes.map[supportMeetingTypeID];
      if (!type) return false;

      return !!type.isPrescriptionRenewal;
    }, [sourceSupportMeetingTypes.map, supportMeetingTypeID]);

    const mountedRef = useMountedRef();
    const onSubmit = useCallback(
      async (formData: FormModel) => {
        const result = await triggerPost({
          ...formData,
          _IS_RENEWAL: isRenewal,
        });

        if (isMutationFulfilled(result)) {
          onRefresh();
          const supportMeetingID = result.data.id;

          await dispatch(actionSupportMeetingActivitiesCrateLogCreated({ supportMeetingID }));
        }

        if (mountedRef.current) {
          onClose();
        }
      },
      [dispatch, triggerPost, onRefresh, mountedRef, onClose, isRenewal],
    );

    useFormDefaultValue(sourceSupportMeetingTypes.data[0]?.id, {
      name: 'supportMeetingTypeID',
      setValue,
      watch,
      emptyValue: '',
    });

    const usePatientProfileID = watch('userPatientProfileID');

    return (
      <Dialog open={true} onClose={onClose} fullWidth maxWidth="xs">
        <Controller
          control={control}
          name={'supportMeetingTypeID'}
          render={(renderProps) => {
            return (
              <SelectMeetingType
                {...getFieldProps(renderProps)}
                disabled={disabled}
                options={sourceSupportMeetingTypes.data}
                loading={sourceSupportMeetingTypes.loading}
                endIcon={
                  <IconButton className={style.close} style={{ color: 'white' }} onClick={onClose}>
                    <CloseIcon color={'inherit'} />
                  </IconButton>
                }
              />
            );
          }}
        />

        {isLoading && <LinearProgress />}
        <FormProvider {...formMethods}>
          <DialogContent>
            <Collapse in={!!usePatientProfileID}>
              <PatientInfo userPatientProfileID={usePatientProfileID} />
            </Collapse>
            <Form isRenewal={isRenewal} isLoading={isLoading} times={times} />
          </DialogContent>
        </FormProvider>
        <DialogActions>
          <ButtonCancel isBack={false} onClick={onClose} />
          <ButtonSave isCreate={true} onClick={handleSubmit(onSubmit)} disabled={isLoading} />
        </DialogActions>
      </Dialog>
    );
  },
);
