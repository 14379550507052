import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { convertToDate, DateValue, isPastDate } from 'utils/dates';
import { addMinutes, differenceInMinutes } from 'date-fns';

export const rulesMeetingDateRange: RegisterOptions = {
  validate: {
    validatePastDate: (value: DateValue[]) => {
      if (!value) return undefined;
      let start = value[0];
      if (!start) return undefined;

      if (!isPastDate(start)) return undefined;
      return 'rule-meeting-past';
    },
  },
};

export enum STRATEGY_TO_DOCTOR {
  BEFORE = 'before',
  AFTER = 'after',
}

interface Options {
  meetingTime: [DateValue, DateValue];
  strategy: STRATEGY_TO_DOCTOR;
  delay: number; // delay in minutes
  duration: number | undefined; // duration in minutes;
}
export const calcMeetingTime = (options: Options): [Date, Date] => {
  const { meetingTime, strategy, delay, duration } = options;
  const fromTime = addMinutes(convertToDate(meetingTime[0]), delay);
  const toTime = addMinutes(convertToDate(meetingTime[1]), delay);

  const diff = duration ?? Math.abs(differenceInMinutes(fromTime, toTime));

  switch (strategy) {
    case STRATEGY_TO_DOCTOR.BEFORE: {
      return [addMinutes(fromTime, -diff), fromTime];
    }
    case STRATEGY_TO_DOCTOR.AFTER: {
      return [toTime, addMinutes(toTime, diff)];
    }
  }
};
