import { createAsyncThunk } from '@reduxjs/toolkit';
import { DynamicResult, parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from 'store/store';
import * as dynamic from 'utils/dynamic-helpers';
import { selectDietDiaryReportFilters, selectDietDiaryReportPagination } from './selectors';
import { ListModel, ViewOptions } from '../models';
import { SELECT_PATIENT_CARD_INFO } from 'services/user-patient-profile';
import { PREFIX } from './helpers';
import { ServiceUserPatientProfileDietDiaries } from 'services/user-patient-profile-diet-diaries';

export const actionDietDiariesReportLoadData = createAsyncThunk<
  DynamicResult<ListModel>,
  void,
  AppAsyncThunkConfig
>(`${PREFIX}/loadData`, async (_, { getState }) => {
  try {
    const state = getState();
    const { search, dateRange, patientIDs, assignedToEmployeeID, isViewed } =
      selectDietDiaryReportFilters(state);

    const { skip, take } = selectDietDiaryReportPagination(state);

    const {
      data: { value, count },
    } = await ServiceUserPatientProfileDietDiaries.getAllDynamic<ListModel>({
      select: dynamic.select(
        'id',
        'assignToUserEmployeeProfileID',
        'entryDate',
        'dietDiaryTypeID',
        'new { dietDiaryType.title, dietDiaryType.color, dietDiaryType.icon } as dietDiaryType',
        'description',
        'replyDate',
        'userEmployeeProfileID',
        'userPatientProfileID',
        'reply',
        'isViewed',
        `new {${SELECT_PATIENT_CARD_INFO('userPatientProfile')}} as patient`,
        'userPatientProfileDietDiaryImages.Select(i => i.fileURL) as files',
      ),
      filter: dynamic
        .mergeFilters(
          dynamic.createFilterEquals('assignToUserEmployeeProfileID', assignedToEmployeeID),
          dynamic.createFilterDateISO('entryDate', dateRange),
          dynamic.createFilterEqualsSome('userPatientProfileID', patientIDs),
          dynamic
            .mergeFilters(
              dynamic.createFilterSmartSearch(
                ['userPatientProfile.firstName', 'userPatientProfile.lastName'],
                search,
              ),
              dynamic.createFilterContains(['description'], search),
            )
            .join('||'),
          isViewed === ViewOptions.YES && 'isViewed==true',
          isViewed === ViewOptions.NO && 'isViewed==false',
        )
        .join('&&'),
      orderBy: dynamic.orderBy('entryDate desc'),
      count: true,
      take,
      skip,
    });

    return { value, count };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
