import React, { useMemo } from 'react';
import { Link, LinkProps } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';

interface Props extends LinkProps {
  documentURL: string | null | undefined;
  labelKey?: string;
}
export const TemplateLink: React.FC<Props> = ({ documentURL, labelKey, children, ...rest }) => {
  const { t } = useTranslate();
  const Child = useMemo(() => {
    if (labelKey) {
      return <>[{t(labelKey)}]</>;
    }
    if (children !== undefined) {
      return <>{children}</>;
    }
    return <>[{t('download-document')}]</>;
  }, [labelKey, children, t]);

  if (!documentURL) {
    return null;
  }

  return (
    <Link href={documentURL || '#'} target={'_blank'} {...rest}>
      {Child}
    </Link>
  );
};
