import React, { memo, useCallback, useEffect, useState } from 'react';
import GridConfig from './grid-config';
import DataGrid from 'components/data-grid';
import { useFormGrid } from 'AurionCR/components/formV2';
import { AddNewDocument } from 'components/add-new-document';
import { patientEmitter } from '../../emitter';
import { Box } from '@material-ui/core';

interface Props {
  userPatientProfileID: string;
  userPatientProfileSubscriptionID: string | undefined;
}
export const GridSignedDocuments = memo<Props>(
  ({ userPatientProfileID, userPatientProfileSubscriptionID }) => {
    const [gridConfig] = useState<any>(GridConfig(userPatientProfileID));
    const { grid, onGridHandle } = useFormGrid('document');

    const onRefresh = useCallback(() => {
      grid.current?.onTasks([['getData']]);
    }, [grid]);

    const renderControlsSlot = useCallback(() => {
      return (
        <Box mx={1}>
          <AddNewDocument
            userPatientProfileID={userPatientProfileID}
            userPatientProfileSubscriptionID={userPatientProfileSubscriptionID}
            onDone={onRefresh}
          />
        </Box>
      );
    }, [userPatientProfileID, userPatientProfileSubscriptionID, onRefresh]);

    useEffect(() => {
      const refresh = (payload: { patientID: string }) => {
        if (payload.patientID !== userPatientProfileID) {
          return;
        }
        onRefresh();
      };

      patientEmitter.on('refresh-all', refresh);
      patientEmitter.on('refresh-documents', refresh);
      return () => {
        patientEmitter.off('refresh-all', refresh);
        patientEmitter.off('refresh-documents', refresh);
      };
    }, [userPatientProfileID, onRefresh]);

    return (
      <DataGrid
        ref={grid}
        onHandle={onGridHandle}
        config={gridConfig}
        renderControlsSlot={renderControlsSlot}
      />
    );
  },
);
