import { END_TREATMENT_LANGUAGE } from './models';
import { CONTENT_KEYS } from '../document-templates';

interface GetContentKeyOptions {
  language: END_TREATMENT_LANGUAGE;
  genderRowIndex: number;
}
type Res =
  | CONTENT_KEYS.TREATMENT_ENG
  | CONTENT_KEYS.TREATMENT_HEB_WOMAN
  | CONTENT_KEYS.TREATMENT_HEB_MAN;

export const getEndOfTreatmentContentKey = (options: GetContentKeyOptions): Res => {
  const { language, genderRowIndex } = options;

  if (language === END_TREATMENT_LANGUAGE.ENGLISH) {
    return CONTENT_KEYS.TREATMENT_ENG;
  }

  if (genderRowIndex === 2) {
    return CONTENT_KEYS.TREATMENT_HEB_WOMAN;
  }

  return CONTENT_KEYS.TREATMENT_HEB_MAN;
};
