import React, { memo, useCallback, useState } from 'react';
import { TextField } from '@material-ui/core';
import { getRandomString, useI18n } from 'AurionCR/components';
import { iFormInputField } from './index';
import style from './index.module.scss';

export const FieldTitle = memo(({ value, onChange: OnChange }: iFormInputField<'title'>) => {
  const { t } = useI18n();
  // state
  const [fieldID] = useState(`title_${getRandomString()}`);
  // handlers
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      OnChange({ title: e.target.value });
    },
    [OnChange],
  );
  //render
  return (
    <div className={style.labelInput}>
      <label htmlFor={fieldID} className={style.label}>
        {t('title')}:
      </label>
      <TextField
        id={fieldID}
        className={style.input}
        size={'small'}
        variant="outlined"
        value={value}
        onChange={onChange}
      />
    </div>
  );
});

export default FieldTitle;
