import { FormDocumentInput } from 'services/form-document-inputs';
import { FormDocument } from 'services/form-documents';

export interface PdfFormDocumentInput extends FormDocumentInput {
  _open: boolean;
  _valid: boolean;
  _index: number;
  _value: string | boolean;
}

export interface PdfFormDocument {
  // flags
  init: boolean;
  loading: boolean;
  idPrefix: string;
  // data
  initData: FormDocument | null;
  // pdf
  scale: number;
  width: number;
  pages: PdfFormDocumentPage[];
  // events
  eventRemove: PdfFormDocumentInputMainField | null;
  eventAdd: PdfFormDocumentInput | null;
  eventScrollTo: { top: number; left: number; pageNumber?: number } | null;
  eventSelectFabricItem: PdfFormDocumentInputMainField | null;
  eventUpdateFabricPage: number | null;
  // removed helper
  removed: PdfFormDocumentInput['id'][];
}

export interface PdfFormDocumentInputMainField {
  id: PdfFormDocumentInput['id'];
  pageNumber: PdfFormDocumentInput['pageNumber'];
}

export interface PdfFormDocumentPageProps {
  index: number;
  width: number;
  height: number;
}

export interface PdfFormDocumentPage extends PdfFormDocumentPageProps {
  inputs: PdfFormDocumentInput[];
}

export enum PdfFormDocumentActionTypes {
  PDF_FORM_DOCUMENTS_RESET = 'PDF_FORM_DOCUMENTS_RESET',
  PDF_FORM_DOCUMENTS_INIT = 'PDF_FORM_DOCUMENTS_INIT',
  PDF_FORM_DOCUMENTS_SAVE = 'PDF_FORM_DOCUMENTS_SAVE',
  PDF_FORM_DOCUMENTS_MERGE = 'PDF_FORM_DOCUMENTS_MERGE',
  PDF_FORM_DOCUMENTS_SET_PAGE_SIZE = 'PDF_FORM_DOCUMENTS_SET_PAGE_SIZE',
  PDF_FORM_DOCUMENTS_PAGE_INPUT_ADD = 'PDF_FORM_DOCUMENTS_PAGE_INPUT_ADD',
  PDF_FORM_DOCUMENTS_PAGE_INPUT_REMOVE = 'PDF_FORM_DOCUMENTS_PAGE_INPUT_REMOVE',
  PDF_FORM_DOCUMENTS_PAGE_INPUT_MODIFY = 'PDF_FORM_DOCUMENTS_PAGE_INPUT_MODIFY',
  PDF_FORM_DOCUMENTS_SCROLL_TO_PAGE = 'PDF_FORM_DOCUMENTS_SCROLL_TO_PAGE',
  PDF_FORM_DOCUMENTS_SCROLL_TO_INPUT = 'PDF_FORM_DOCUMENTS_SCROLL_TO_INPUT',
  PDF_FORM_DOCUMENTS_SWAP = 'PDF_FORM_DOCUMENTS_SWAP',
}

export interface PdfFormDocumentResetAction {
  type: PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_RESET;
}

export interface PdfFormDocumentInitAction {
  type: PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_INIT;
  payload: string;
}

export interface PdfFormDocumentSaveAction {
  type: PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_SAVE;
  payload?: (status: boolean) => void;
}

export interface PdfFormDocumentMergeAction {
  type: PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_MERGE;
  payload: Partial<PdfFormDocument>;
}

export interface PdfFormDocumentSetPageSizeAction {
  type: PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_SET_PAGE_SIZE;
  payload: PdfFormDocumentPageProps;
}

export interface PdfFormDocumentPageInputAddAction {
  type: PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_PAGE_INPUT_ADD;
  payload: PdfFormDocumentInput;
}

export interface PdfFormDocumentPageInputRemoveAction {
  type: PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_PAGE_INPUT_REMOVE;
  payload: PdfFormDocumentInputMainField;
}

export interface PdfFormDocumentPageInputModifyAction {
  type: PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_PAGE_INPUT_MODIFY;
  payload: Partial<PdfFormDocumentInput> & PdfFormDocumentInputMainField;
}

export interface PdfFormDocumentScrollToPageAction {
  type: PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_SCROLL_TO_PAGE;
  payload: { pageNumber: PdfFormDocumentInput['pageNumber'] };
}

export interface PdfFormDocumentScrollToInputAction {
  type: PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_SCROLL_TO_INPUT;
  payload: { pageNumber: PdfFormDocumentInput['pageNumber']; index: number };
}

export interface PdfFormDocumentSwapAction {
  type: PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_SWAP;
  payload: { from: number; to: number; pageNumber: number };
}

export type PdfFormDocumentAction =
  | PdfFormDocumentResetAction
  | PdfFormDocumentInitAction
  | PdfFormDocumentSaveAction
  | PdfFormDocumentMergeAction
  | PdfFormDocumentSetPageSizeAction
  | PdfFormDocumentPageInputAddAction
  | PdfFormDocumentPageInputRemoveAction
  | PdfFormDocumentPageInputModifyAction
  | PdfFormDocumentScrollToPageAction
  | PdfFormDocumentScrollToInputAction
  | PdfFormDocumentSwapAction;
