import React, { memo, useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { SmsSenderModel, SmsSender, SmsSenderTemplatesPayload } from 'components/sms-sender';
import {
  selectTMSendSms,
  selectTMSendSmsMeeting,
  TMMerge,
} from 'pages/TaskManager/components/store';
import { apiSupportMeetingActivities } from 'services/support-meeting-activities';
import { API_USER_PATIENT_PROFILE } from 'services/user-patient-profile';
import { format } from 'date-fns';
import { APP_FORMAT_DATE, APP_FORMAT_DATE_TIME, APP_FORMAT_TIME } from 'configs/const';
import { useTranslate } from 'hooks/use-translate';
import { useCurrentUser } from 'components/hooks';
import { convertToDate, dateFormat } from 'utils/dates';
import {
  ActivityLogModel,
  DialogSupportMeetingActivityLog,
} from 'components/dialog-support-meeting';
import { useEffectError, useMountedRef } from 'hooks';
import { isMutationFulfilled } from 'utils/rtk-query';

const useMutation = apiSupportMeetingActivities.usePostSupportMeetingActivityMutation;

export const SendSmsDialog = memo(() => {
  const { t } = useTranslate();
  const mountedRef = useMountedRef();
  const dispatch = useAppDispatch();
  // state
  const sendSms = useAppSelector(selectTMSendSms);
  const sendSmsMeeting = useAppSelector(selectTMSendSmsMeeting);

  const currentUser = useCurrentUser();

  const [message, setMessage] = useState('');
  const [openSmsDialog, setOpenSmsDialog] = useState(true);
  const [initData] = useState<SmsSenderModel>({
    userPatientProfileID: sendSms?.userPatientProfileID || '',
    toPhoneNumber: sendSms?.toPhoneNumber || '',
  });

  const templatePayload = useMemo<Omit<SmsSenderTemplatesPayload, 'lead'>>(() => {
    const meetingFromDateTime = sendSmsMeeting?.meetingFromDateTime
      ? convertToDate(sendSmsMeeting?.meetingFromDateTime)
      : null;
    const meetingToDateTime = sendSmsMeeting?.meetingToDateTime
      ? convertToDate(sendSmsMeeting?.meetingToDateTime)
      : null;
    return {
      meetingFromDate: meetingFromDateTime ? format(meetingFromDateTime, APP_FORMAT_DATE) : '',
      meetingFromTime: meetingFromDateTime ? format(meetingFromDateTime, APP_FORMAT_TIME) : '',
      meetingToDate: meetingToDateTime ? format(meetingToDateTime, APP_FORMAT_DATE) : '',
      meetingToTime: meetingToDateTime ? format(meetingToDateTime, APP_FORMAT_TIME) : '',
      currentEmployee: currentUser,
      patient: sendSmsMeeting?.userPatientProfile || {},
    };
  }, [sendSmsMeeting, currentUser]);

  // handle
  const onClose = useCallback(() => {
    setMessage('');
    dispatch(TMMerge({ sendSms: null }));
  }, [setMessage, dispatch]);

  const [performCreate, resultCreate] = useMutation();
  useEffectError(resultCreate.error);
  const onConfirm = useCallback(
    async (data: ActivityLogModel) => {
      const result = await performCreate({
        remarks: data.remarks,
        remarkForPatientCallStatusID: data.remarkForPatientCallStatusID,
        supportMeetingID: sendSms?.supportMeetingID || '',
      });

      if (!mountedRef.current) return;
      if (isMutationFulfilled(result)) {
        onClose();
      }
    },
    [performCreate, sendSms, onClose, mountedRef],
  );

  const onDone = useCallback(
    (formData) => {
      setMessage(formData.message || '');
      setOpenSmsDialog(false);
    },
    [setMessage, setOpenSmsDialog],
  );

  const remarks = useMemo(() => {
    return [
      t('sent-sms-meeting'),
      dateFormat(new Date(), APP_FORMAT_DATE_TIME),
      `"${message}"`,
      t('from-employee'),
      sendSms?.userEmployeeProfileFullName,
    ]
      .filter(Boolean)
      .join(' ');
  }, [t, message, sendSms]);

  return (
    <>
      {openSmsDialog && (
        <SmsSender
          initData={initData}
          templatePayload={templatePayload}
          onClose={onClose}
          onFormHandle={onDone}
          url={API_USER_PATIENT_PROFILE.SEND_SMS}
        />
      )}
      {message.length > 0 && (
        <DialogSupportMeetingActivityLog
          isLoading={resultCreate.isLoading}
          title={t('sent-sms-meeting')}
          remarks={remarks}
          onCancel={onClose}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
});
