const API_ENDPOINT = 'InputTypes';

export const API_INPUT_TYPES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<InputType, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<InputType, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface InputType extends Components.Schemas.InputType {}

export enum INPUT_TYPE_INDEXES {
  WEIGHT_CLINICAL = 7,
  WEIGHT_HOME = 8,
}
