import { API_USER_PATIENT_PROFILE_DIET_DIARY_IMAGES } from './models';
import { DynamicService } from 'utils/service';

class Service extends DynamicService<Components.Schemas.UserPatientProfileDietDiaryImage> {
  async createBulk(input: { id: string; filePaths: string[] }) {
    return this.engine.post(API_USER_PATIENT_PROFILE_DIET_DIARY_IMAGES.POST_BULK, {
      mainID: input.id,
      itemIDs: input.filePaths,
    });
  }
}

export const ServiceUserPatientProfileDietDiaryImages = new Service({
  mainField: 'id',
  getAll: API_USER_PATIENT_PROFILE_DIET_DIARY_IMAGES.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_DIET_DIARY_IMAGES.POST,
  patch: API_USER_PATIENT_PROFILE_DIET_DIARY_IMAGES.PATCH,
  delete: API_USER_PATIENT_PROFILE_DIET_DIARY_IMAGES.DELETE,
});

export * from './models';
