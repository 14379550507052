import {
  apiClinicalMeetings,
  AttacheNotebookToClinicalMeetingInput,
  ClinicalMeeting,
} from 'services/clinical-meetings';
import { useCallback } from 'react';

const useAttachMutation = apiClinicalMeetings.useAttachNotebookToClinicalMeetingMutation;
const usePatchMutation = apiClinicalMeetings.usePatchClinicalMeetingMutation;

export const useAttachNotebookToClinicalMeeting = () => {
  const [patchItem, resultPatch] = usePatchMutation();
  const [attachItem, resultAttach] = useAttachMutation();

  const handleStart = useCallback(
    (meeting: Pick<ClinicalMeeting, 'id'>) => {
      return patchItem({ id: meeting.id, completeSessionDateTime: new Date().toISOString() });
    },
    [patchItem],
  );

  const handleCancel = useCallback(
    (meeting: Pick<ClinicalMeeting, 'id'>) => {
      return patchItem({ id: meeting.id, completeSessionDateTime: null });
    },
    [patchItem],
  );

  const handleDone = useCallback(
    (input: AttacheNotebookToClinicalMeetingInput) => {
      return attachItem({
        id: input.id,
        userPatientProfileSessionID: input.userPatientProfileSessionID,
      });
    },
    [attachItem],
  );

  return [
    { handleStart, handleCancel, handleDone },
    {
      isLoading: resultPatch.isLoading || resultAttach.isLoading,
      error: resultPatch.error || resultAttach.error,
    },
  ] as const;
};
