import { GridEditorInputWeight } from 'components/app-grid/editors/input-weight';
import { DefaultGridButtonExportToExcel } from 'components/data-grid/model';
import { APP_FORMAT_DATE } from 'configs/const';
import { API_INPUT_TYPES } from 'services/input-type';
import { permissionKey } from 'services/user-employee-profile-permissions';
import { API_USER_PATIENT_PROFILE_SESSION_ITEMS } from 'services/user-patient-profile-session-item';
import { generateDynamicQuery } from 'utils/service';
import { EditorEntryDate } from './components/editor-entry-date';
import { TemplateRemark } from './components/template-remark';
import React from 'react';
import style from './index.module.scss';

export interface GridModel {
  items: { isWeight: boolean; value: string }[];
  isForWeightOnly: boolean;
}

export default (userPatientProfileID: string): any => ({
  id: 'grid_user-patient-profile-subscriptions',
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_SESSION_ITEMS.GET_ALL_DYNAMIC,
      selected: [
        'userPatientProfileSession.notebook.isForWeightOnly',
        'userPatientProfileSessionID',
        'userPatientProfileSession.userPatientProfileID as userPatientProfileID',
        'userPatientProfileSession.userPatientProfileSessionItems.Select(i => new { i.fieldInput.inputType.isWeight, i.entryValueString as value }) as items',
      ].join(','),
      filters: `fieldInput.inputType.isWeight==true&&userPatientProfileSession.userPatientProfileID=="${userPatientProfileID}"`,
    },
    create: {
      url: API_USER_PATIENT_PROFILE_SESSION_ITEMS.POST,
    },
    patch: {
      url: API_USER_PATIENT_PROFILE_SESSION_ITEMS.PATCH,
    },
    delete: {
      url: API_USER_PATIENT_PROFILE_SESSION_ITEMS.DELETE,
    },
    sortable: {
      field: 'userPatientProfileSession.entryDate',
      order: 'desc',
    },
  },
  toolbar: {
    title: '',
    filters: [
      {
        field: 'fieldInput.inputTypeID',
        options: {
          type: 'select',
          label: 'activity-type',
          source: `${API_INPUT_TYPES.GET_ALL_DYNAMIC}?${generateDynamicQuery({
            select: 'id,title',
            filter: ['isWeight==true', 'isActive==true'].join('&&'),
          })}`,
          style: { minWidth: '25rem' },
        },
      },
    ],
    controls: null,
    controlsDefault: {
      new: null,
      resetFilters: null,
      export: DefaultGridButtonExportToExcel,
    },
  },
  columns: [
    {
      title: 'entry-date',
      field: 'userPatientProfileSession.entryDate as entryDate',
      template: {
        type: 'date',
        format: APP_FORMAT_DATE,
      },
      editor: {
        type: 'custom',
        field: 'id',
        component: EditorEntryDate,
      },
      sortable: 'userPatientProfileSession.entryDate',
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'notebook',
      field: 'userPatientProfileSession.notebook.labelKey as notebook',
      sortable: 'userPatientProfileSession.notebook.labelKey',
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'activity-type',
      field: 'fieldInput.labelKey as entryValue',
      sortable: 'fieldInput.labelKey',
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'weight',
      field: 'entryValueNumber',
      editor: {
        type: 'custom',
        component: GridEditorInputWeight,
        required: true,
      },
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'employee',
      field: 'userPatientProfileSession.userEmployeeProfile.fullName as employeeName',
      sortable: 'userPatientProfileSession.userEmployeeProfile.firstName',
      tdClasses: 'mobile-20',
    },
    {
      title: 'remark',
      field: 'userPatientProfileSessionID',
      template: (data: GridModel) => (data.isForWeightOnly ? <TemplateRemark data={data} /> : ''),
      tdClasses: style.cellRemark,
    },
  ],
  controls: [
    { type: 'delete', title: 'delete', permission: permissionKey('isAllowToDeleteAnything') },
  ],
  pagination: {
    size: 50,
  },
});
