import React, { forwardRef } from 'react';
import { IconButton } from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

interface SwapProps {
  pagination: any;
  rowData: any;
  index: number;
}

export const Swap = forwardRef<any, SwapProps>(({ pagination, rowData, index }, ref) => {
  return (
    <td className="data-grid-col-controls swap">
      <div className={'swap-wrap'}>
        <IconButton ref={ref}>
          <DragIndicatorIcon />
        </IconButton>
      </div>
    </td>
  );
});

export default Swap;
