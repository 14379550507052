import React from 'react';
import {
  DialogTitle,
  DialogTitleProps,
  IconButton,
  IconButtonProps,
  LinearProgress,
  Typography,
  TypographyProps,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import style from './index.module.scss';
import clsx from 'clsx';

interface Classes {
  root: string;
  title: string;
  close: string;
}

interface Props extends Omit<DialogTitleProps, 'title' | 'disableTypography' | 'classes'> {
  classes?: Partial<Classes>;
  title?: React.ReactNode;
  TitleProps?: Partial<TypographyProps<any>>;
  IconButtonProps?: Partial<IconButtonProps>;
  disabled?: boolean;
  isLoading?: boolean;
  onClose?: () => void;
}

export const DialogHeading: React.FC<Props> = ({
  children,
  TitleProps,
  onClose,
  title,
  disabled,
  IconButtonProps,
  isLoading,
  classes,
  ...rest
}) => {
  return (
    <DialogTitle
      {...rest}
      disableTypography
      className={clsx(style.root, rest?.className, classes?.root)}
    >
      {title && (
        <Typography
          variant={'h4'}
          component={'h2'}
          color={'secondary'}
          {...TitleProps}
          className={clsx(style.title, TitleProps?.className, classes?.title)}
        >
          {title}
        </Typography>
      )}
      {onClose && (
        <IconButton
          {...IconButtonProps}
          onClick={onClose}
          disabled={disabled}
          className={clsx(style.close, IconButtonProps?.className, classes?.close)}
        >
          <CloseIcon color={'inherit'} />
        </IconButton>
      )}
      {children}
      {isLoading && (
        <LinearProgress style={{ position: 'absolute', left: 0, bottom: 0, right: 0 }} />
      )}
    </DialogTitle>
  );
};
