import React, { useCallback, useEffect } from 'react';
import {
  DialogSendMessage,
  SendMessageForm,
  schemaEmailModel,
} from 'components/dialog-send-message';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { useEffectError } from 'hooks';
import { useTranslate } from 'hooks/use-translate';
import { makeFullName } from 'utils/app-helpers';
import * as yup from 'yup';
import { apiEmailMessages } from 'services/email-messages';
import { InferType } from 'yup';
import { isMutationFulfilled } from 'utils/rtk-query';
import { useAppDispatch } from 'store';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { apiUserPatientProfileActivities } from 'services/user-patient-profile-activities';
import { useCurrentUser } from 'components/hooks';

const schema = schemaEmailModel.shape({
  fileURLs: yup.array(yup.string().required('rule-required')).min(1, 'rule-required').default([]),
});

type Model = InferType<typeof schema>;
const useFetchPatientDetails = apiUserPatientProfile.useGetPatientForGeneralFormQuery;
const useSendMessageMutation = apiEmailMessages.useSendEmailToPatientMutation;

interface Props {
  userPatientProfileID: string;
  onClose: () => void;
  fileURLs: string[];
}
export const DialogSendFilesToPatient: React.FC<Props> = ({
  userPatientProfileID,
  onClose,
  fileURLs,
}) => {
  const { appUserID } = useCurrentUser();
  const dispatch = useAppDispatch();
  const { tp } = useTranslate();
  const formMethods = useForm({
    defaultValues: schema.cast({
      fileURLs,
    }),
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset, getValues, register } = formMethods;

  const resultPatient = useFetchPatientDetails(userPatientProfileID);
  useEffectError(resultPatient.error);
  useEffect(() => {
    if (!resultPatient.data) return;
    const fullName = makeFullName(resultPatient.data);
    const patient = { fullName };

    reset(
      schema.cast({
        ...getValues(),
        fromDisplayName: tp('email-display-name'),
        replyToEmail: tp('email-reply-to'),
        toEmails: [resultPatient.data.email].filter(Boolean),
        subject: tp('email-to-patient-document-subject', {
          patient,
        }),
        html: tp('email-to-patient-document-html', {
          patient,
        }),
      }),
    );
  }, [resultPatient.data, reset, tp, getValues]);

  const [triggerSend, resultSend] = useSendMessageMutation();
  useEffectError(resultSend.error);

  const onSubmit = useCallback(
    async (data: Model) => {
      const resultSend = await triggerSend({
        toEmails: data.toEmails,
        subject: data.subject,
        fromDisplayName: data.fromDisplayName,
        replyToEmail: data.replyToEmail,
        html: data.html,
        attachments: data.fileURLs,
        userPatientProfileID,
      });

      if (isMutationFulfilled(resultSend)) {
        onClose();
        dispatch(
          apiUserPatientProfileActivities.endpoints.postUserPatientProfileActivity.initiate({
            userPatientProfileID,
            userEmployeeProfileID: appUserID,
            remarks: tp('activity-patient-send-document-to-patient'),
          }),
        );
        dispatch(notifyRequestResult(tp('email-send-success')));
      }
    },
    [triggerSend, onClose, tp, dispatch, userPatientProfileID, appUserID],
  );
  const isLoading = resultPatient.isFetching || resultSend.isLoading;

  return (
    <DialogSendMessage isLoading={isLoading} onClose={onClose} onSubmit={handleSubmit(onSubmit)}>
      <input hidden {...register('fileURLs')} />
      <FormProvider {...formMethods}>
        <SendMessageForm isLoading={isLoading} />
      </FormProvider>
    </DialogSendMessage>
  );
};
