import React, { memo } from 'react';
import { Box, Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { Alert, AlertProps } from '@material-ui/lab';

interface Props {
  title?: string;
  message: React.ReactNode;
  severity?: AlertProps['severity'];
  onClose: () => void;
}

export const DialogAlert = memo<Props>(({ title, severity = 'info', message, onClose }) => {
  const { tp } = useTranslate();
  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth={'xs'}>
      <DialogTitle>{title || tp('alert')}</DialogTitle>
      <Box mt={1.6} mx={1.6}>
        <Alert severity={severity}>{message}</Alert>
      </Box>
      <DialogActions>
        <Button color="secondary" children={tp('ok')} onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
});
