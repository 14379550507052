import clsx from 'clsx';
import { DataGridLightRenderColumnProps } from '../../models';
import React, { memo } from 'react';
import style from './index.module.scss';

interface Props extends DataGridLightRenderColumnProps<any, any> {}
const Column = memo<Props>(({ row, field }) => {
  const value = row[field];
  return <div className={clsx(style.wrap)}>{value}</div>;
});

export const renderColumn = (props: DataGridLightRenderColumnProps<any, any>) => {
  return <Column {...props} />;
};
