import { Box, BoxProps, Typography, TypographyProps } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

interface Classes {
  root: string;
  label: string;
  value: string;
}

interface Props extends BoxProps {
  className?: string;
  classes?: Partial<Classes>;
  label: string;
  LabelProps?: Partial<TypographyProps<any>>;
  ValueProps?: Partial<TypographyProps<any>>;
}

export const Characteristic: React.FC<Props> = ({
  className,
  classes,
  label,
  children,
  LabelProps,
  ValueProps,
  ...rest
}) => {
  return (
    <Box className={clsx(classes?.root, className)} {...rest}>
      <Typography
        color={'textSecondary'}
        className={clsx(classes?.label)}
        component={'dt'}
        title={label}
        variant={'body2'}
        {...LabelProps}
      >
        {label}
      </Typography>
      <Typography
        className={clsx(classes?.value)}
        component={'dd'}
        title={label}
        variant={'body1'}
        {...ValueProps}
      >
        {children}
      </Typography>
    </Box>
  );
};
