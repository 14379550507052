import React, { useMemo } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { apiUserPatientProfileSessions } from 'services/user-patient-profile-session';
import { useTranslate } from 'hooks/use-translate';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { useOpen } from 'AurionCR/components/hooks';
import clsx from 'clsx';
import { DialogUserPatientDeclaration } from '../dialog-user-patient-profile-declaration';
import style from './index.module.scss';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useFetch = apiUserPatientProfileSessions.useGetSessionPatientLatestSurveyQuery;

interface Props {
  userPatientProfileID: string;
}
export const TagSurvey: React.FC<Props> = ({ userPatientProfileID }) => {
  const { data } = useFetch(userPatientProfileID);
  const { isOpen, handleOpen, handleClose } = useOpen();

  const { tp } = useTranslate();

  const isNotViewed = useMemo(() => {
    if (!data) {
      return false;
    }

    return !data.doctorViewed;
  }, [data]);
  const userPatientProfileSessionID = data ? data.id : null;

  if (!data) return null;

  return (
    <>
      <Tooltip title={isNotViewed ? tp('last-patient-survey-must-view-by-doctor') : ''} arrow>
        <Button
          className={clsx(style.root, isNotViewed && style.error)}
          color={'primary'}
          variant={'contained'}
          size={'small'}
          startIcon={<PlaylistAddCheckIcon />}
          onClick={handleOpen}
          endIcon={isNotViewed ? <VisibilityOffIcon /> : <VisibilityIcon />}
        >
          {tp('last-patient-survey')}
        </Button>
      </Tooltip>

      {isOpen && userPatientProfileSessionID && (
        <DialogUserPatientDeclaration
          userPatientProfileID={userPatientProfileID}
          itemID={userPatientProfileSessionID}
          onClose={handleClose}
        />
      )}
    </>
  );
};
