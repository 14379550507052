import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { IDataGridColumn } from '../../model';
import EditorString from './editors/editor-string';
import EditorCheckbox from './editors/editor-checkbox';
import EditorNumber from './editors/editor-number';
import EditorSelect from './editors/editor-select';
import EditorDate from './editors/editor-date';
import { format, isValid } from 'date-fns';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { checkEs6AndRun, es6Run, ImageTooltip, regExpFieldAS } from 'AurionCR/components';
import clsx from 'clsx';
import { useDataGridTasks } from '../../hooks';

export const templateParser = (column: IDataGridColumn, data: any) => {
  const result: any = { type: 'string', template: '' };
  if (typeof column.template === 'string') {
    result.type = column.template;
    const data_ = es6Run(data, column.field);
    switch (column.template) {
      case 'string':
      case 'number':
        result.template = data_;
        break;
      case 'email':
        result.template = <a href={`mailto:${data_}`}>{data_}</a>;
        break;
      case 'link':
        result.template = (
          <a href={data_} target="_blank" rel="noopener noreferrer">
            {data_}
          </a>
        );
        break;
      case 'html':
        result.template = (
          <div className="html-wrapper" dangerouslySetInnerHTML={{ __html: data_ || '' }} />
        );
        break;
      case 'date': {
        const date_ = new Date(data_);
        result.template = data_ && isValid(date_) ? format(date_, 'dd MMMM yyyy') : '';
        break;
      }
      case 'boolean':
        result.template = (
          <CheckCircleIcon
            color="secondary"
            className={`template-boolean ${data_ ? 'active' : ''}`}
          />
        );
        break;
      case 'image':
        result.template = <ImageTooltip src={data_} config={{ placement: 'right' }} />;
        break;
      default:
        result.template = checkEs6AndRun(column.template, data);
        break;
    }
  } else if (typeof column.template === 'object' && column.template.type) {
    const template_ = column.template;
    result.type = template_.type;
    switch (template_.type) {
      case 'date':
        const date_ = new Date(data[column.field]);
        result.template =
          data[column.field] && isValid(date_) ? format(date_, template_.format) : '';
        break;
      case 'image':
        let src_ = template_.src;
        let title_ = template_.title;
        // @ts-ignore
        if (regExpFieldAS.test(src_)) src_ = src_.match(regExpFieldAS)[0].slice(4);
        // @ts-ignore
        if (regExpFieldAS.test(title_)) title_ = title_.match(regExpFieldAS)[0].slice(4);
        result.template = (
          <ImageTooltip
            config={{ placement: 'right' }}
            src={es6Run(data, src_)}
            title={es6Run(data, title_)}
          />
        );
        break;
    }
  } else if (typeof column.template === 'function') {
    result.template = column.template(data);
    result.type = 'function';
  }
  return result;
};
export const editorParser = (
  column: IDataGridColumn,
  row: any,
  data: any,
  onTasks: () => void,
  onSubmit: (value: any) => void,
  onClose: () => void,
) => {
  if (column.editor) {
    // @ts-ignore
    switch (column.editor.type) {
      case 'string':
        return (
          <EditorString
            row={row}
            // @ts-ignore
            editor={column.editor}
            data={data}
            onSubmit={onSubmit}
            onClose={onClose}
          />
        );
      case 'number':
        return (
          <EditorNumber
            row={row}
            // @ts-ignore
            editor={column.editor}
            data={data}
            onSubmit={onSubmit}
            onClose={onClose}
          />
        );
      case 'boolean':
        // @ts-ignore
        return <EditorCheckbox row={row} data={data} onSubmit={onSubmit} onClose={onClose} />;
      case 'select':
        return (
          <EditorSelect
            row={row}
            // @ts-ignore
            editor={column.editor}
            data={data}
            onSubmit={onSubmit}
            onClose={onClose}
          />
        );
      case 'date':
        return (
          <EditorDate
            row={row}
            // @ts-ignore
            editor={column.editor}
            data={data}
            onSubmit={onSubmit}
            onClose={onClose}
          />
        );
      case 'custom':
        // @ts-ignore
        const Custom = column.editor?.component;
        if (Custom) {
          // @ts-ignore
          return (
            <Custom
              row={row}
              editor={column.editor}
              data={data}
              onTasks={onTasks}
              onSubmit={onSubmit}
              onClose={onClose}
            />
          );
        } else {
          return null;
        }
    }
  }
  return null;
};

interface ColumnProps {
  column: IDataGridColumn;
  data: any;
  preview?: boolean;
}

const Column: FC<ColumnProps> = ({ column, data, preview }) => {
  const { onTasks } = useDataGridTasks();
  // state
  const [edit, setEdit] = useState(false);
  // editor handlers
  const onSubmit = useCallback(
    (value) => {
      // @ts-ignore
      const { field } = column.editor;
      if (data[field] !== value.data) {
        onTasks([['pathData', { row: { ...data }, value: { [field]: value.data } }]]);
      }
      setEdit(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );
  const onClose = useCallback(() => {
    setEdit(false);
  }, []);
  // components
  const col = useMemo(() => templateParser(column, data), [column, data]);
  const editor = useMemo(
    () =>
      preview
        ? null
        : // @ts-ignore
          editorParser(column, data, data[column.editor?.field], onTasks, onSubmit, onClose),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [column, data, onClose],
  );
  // state handlers
  const onTdClick = useCallback(() => {
    setEdit(Boolean(editor));
  }, [editor, setEdit]);
  const cellProps = useMemo(() => {
    if (column.cellProps) {
      return column.cellProps({ row: data, field: column.field });
    }
  }, [column, data]);

  return (
    <td
      onClick={onTdClick}
      {...cellProps}
      style={{ ...column.tdStyle, ...cellProps?.style }}
      className={clsx(
        column.tdClasses,
        col.type,
        editor && 'is-edit-allow',
        edit && 'is-edit',
        cellProps?.className,
      )}
    >
      {col.template}
      {edit && editor}
    </td>
  );
};

export default memo(Column);
