import { lazy } from 'react';
import { IRoute } from 'AurionCR/components/router';
import SettingsIcon from '@material-ui/icons/Settings';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import Pharmacy from 'components/svg-icons/pharmacy';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import BuildIcon from '@material-ui/icons/Build';
import { createTypedRoute } from 'modules/typed-routes';
import { IconTaskManager, IconDashboard, IconEmployees } from 'components/router-icons';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import { IS_DEV_BUILD } from './const';

const DevPage = IS_DEV_BUILD
  ? lazy(() => import(/* webpackChunkName: "development" */ 'pages/__dev-test'))
  : null;

export enum PATIENT_CARD_MAIN_TABS {
  GENERAL = 'general',
  APPOINTMENTS = 'appointments',
  WEIGHTS = 'weights',
  MEDICAL_INFORMATION = 'medical-information',
  COMMERCIAL = 'commercial',
  DOCUMENTS = 'documents',
  MESSAGES = 'messages',
  ALL_ACTIVITIES = 'all-activities',
}
export enum PATIENT_CARD_MESSAGE_TABS {
  SMS = 'sms',
  CALLS = 'calls',
  TODO_TASKS = 'todo-tasks',
}
export enum PATIENT_CARD_ALL_ACTIVITIES_TABS {
  ACTIVITIES = 'activities',
  TODO_TASKS = 'todo-tasks',
  NOTES = 'notes',
  NOTEBOOKS_FROM_RAPID = 'notebooks-from-rapid',
  PATIENT_ACTIVITIES = 'patient-activities',
}

export enum PATIENT_CARD_DOC_TABS {
  DOCUMENTS = 'docs',
  SIGNED_DOCUMENTS = 'signed-docs',
}

type PATIENT_SOURCE = 'push-noty';

export const APP_ROUTES = {
  PATIENT_LIST: createTypedRoute<{
    patient?: string;
    'main-tab'?: PATIENT_CARD_MAIN_TABS;
    'tab-activities-all'?: PATIENT_CARD_ALL_ACTIVITIES_TABS;
    'tab-docs'?: PATIENT_CARD_DOC_TABS;
    task?: string;
    source?: PATIENT_SOURCE;
  }>('/patients/list'),
  PATIENT_WINDOW: createTypedRoute<{ id: string }>('/patients/patient'),
  EMPLOYEES: createTypedRoute<{ employee?: string }>('/employees/employees-list'),
  PATIENT_REPORTS: createTypedRoute('/patients/reports'),
  PATIENT_PRESCRIPTIONS_HOLD_EXPIRED: createTypedRoute('/patients/prescription-on-hold-report'),
  DASHBOARD: createTypedRoute('/'),
  MY_TASKS: createTypedRoute('/my-tasks'),
  LEADS: createTypedRoute<{ lead?: string } | void>('/commercials/leads'),
  CLINICAL_MEETINGS: createTypedRoute('/clinical-meetings'),
  TASK_MANAGERS: createTypedRoute<{ 'task-manager-meeting-id'?: string } | void>('/task-manager'),
};

interface IRouteApp extends IRoute {
  permission?: string;
  routes?: undefined | IRouteApp[];
}

const routes: IRouteApp[] = [
  {
    path: APP_ROUTES.DASHBOARD.path,
    title: 'dashboard',
    icon: IconDashboard,
    component: lazy(() => import(/* webpackChunkName: 'Dashboard' */ 'pages/DashboardReports')),
    permission: 'isAllowToViewDashboard',
  },
  {
    path: '/patients',
    title: 'patients',
    icon: PermContactCalendarIcon,
    routes: [
      {
        path: '/list',
        title: 'patients-list',
        permission: 'isAllowToEditPatients',
        component: lazy(
          () => import(/* webpackChunkName: 'PatientsList' */ 'pages/Patients/PatientsList'),
        ),
      },
      {
        path: '/reports',
        title: 'active-patients-report',
        permission: 'isAllowToEditPatients',
        component: lazy(
          () => import(/* webpackChunkName: 'PatientsReports' */ 'pages/Patients/PatientsReports'),
        ),
      },
      {
        path: '/tags-report',
        title: 'tags-patients-report',
        permission: 'isAllowToEditPatients',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'TagsPatientsReport' */ 'pages/Patients/TagsPatientsReport'
            ),
        ),
      },
      {
        path: '/loosing-weight-report',
        title: 'loosing-weight-report',
        permission: 'isAllowToEditPatients',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'LoosingWeightReport' */ 'pages/Patients/LoosingWeightReport'
            ),
        ),
      },
      {
        path: '/side-effects-report',
        title: 'side-effects-report',
        //eslint-disable-next-line
        permission: '${data.isAllowToEditPatients || data.rolePatientManager}',
        component: lazy(
          () =>
            import(/* webpackChunkName: 'SideEffectsReport' */ 'pages/Patients/SideEffectsReport'),
        ),
      },
      {
        path: '/training-report',
        title: 'training-report',
        permission: 'isAllowToEditCommercials',
        component: lazy(
          () => import(/* webpackChunkName: 'TrainingReports' */ 'pages/Patients/TrainingReports'),
        ),
      },
      {
        path: '/prescription-on-hold-report',
        title: 'prescription-on-hold-report',
        permission: 'isAllowToEditCommercials',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'PrescriptionOnHoldReports' */ 'pages/Patients/PrescriptionOnHoldReports'
            ),
        ),
      },

      {
        path: '/diet-diaries-report',
        title: 'diet-diaries-report',
        permission: 'isAllowToEditPatients',
        component: lazy(
          () =>
            import(/* webpackChunkName: 'DietDiariesReport' */ 'pages/Patients/DietDiariesReport'),
        ),
      },
      {
        path: '/patient',
        title: 'patient',
        permission: 'isAllowToEditPatients',
        component: lazy(() => import(/* webpackChunkName: 'Patient' */ 'pages/Patient')),
        inMenu: false,
      },
    ],
  },
  {
    path: APP_ROUTES.TASK_MANAGERS.path,
    title: 'task-manager',
    icon: IconTaskManager,
    component: lazy(() => import(/* webpackChunkName: 'TaskManager' */ 'pages/TaskManager')),
  },
  {
    path: APP_ROUTES.CLINICAL_MEETINGS.path,
    title: 'clinical-meetings',
    icon: EventAvailableIcon,
    component: lazy(
      () => import(/* webpackChunkName: 'ClinicalMeetings' */ 'pages/ClinicalMeetings'),
    ),
  },
  {
    path: '/drugs',
    title: 'drugs',
    icon: Pharmacy,
    permission: 'isAllowToViewDrugs',
    routes: [
      {
        path: '/drugs-list',
        title: 'drugs-list',
        component: lazy(() => import(/* webpackChunkName: 'Drugs' */ 'pages/Drugs/DrugsList')),
      },
      {
        path: '/dosage-forms',
        title: 'dosage-forms',
        component: lazy(
          () => import(/* webpackChunkName: 'Dosage_Forms' */ 'pages/Drugs/DosageForms'),
        ),
      },
      {
        path: '/ingredients',
        title: 'ingredients',
        component: lazy(
          () => import(/* webpackChunkName: 'Ingredients' */ 'pages/Drugs/Ingredients'),
        ),
      },
      {
        path: '/manufacturers',
        title: 'manufacturers',
        component: lazy(
          () => import(/* webpackChunkName: 'Manufactures' */ 'pages/Drugs/Manufactures'),
        ),
      },
      {
        path: '/registration-owners',
        title: 'registration-owners',
        component: lazy(
          () =>
            import(/* webpackChunkName: 'Registration_Owners' */ 'pages/Drugs/RegistrationOwners'),
        ),
      },
    ],
  },
  {
    path: '/pharmacies',
    title: 'pharmacies',
    icon: HomeWorkIcon,
    permission: 'isAllowToViewPharmacies',
    routes: [
      {
        path: '/pharmacies',
        title: 'pharmacies',
        component: lazy(
          () => import(/* webpackChunkName: 'Pharmacies' */ 'pages/Pharmacies/Pharmacies'),
        ),
      },
      {
        path: '/pharmacy-messages',
        title: 'pharmacy-messages',
        component: lazy(
          () =>
            import(/* webpackChunkName: 'PharmacyMessages' */ 'pages/Pharmacies/PharmacyMessages'),
        ),
      },
      {
        path: '/drugs-catalog',
        title: 'drugs-catalog',
        component: lazy(
          () => import(/* webpackChunkName: 'DrugsCatalog' */ 'pages/Pharmacies/DrugsCatalog'),
        ),
      },
      {
        path: '/neopharm-api-log',
        title: 'neopharm-api-log',
        component: lazy(
          () => import(/* webpackChunkName: 'NeopharmApiLog' */ 'pages/Pharmacies/NeopharmApiLog'),
        ),
      },
    ],
  },
  {
    path: '/employees',
    title: 'employees',
    icon: IconEmployees,
    permission: 'isAllowToEditEmployeeUsers',
    routes: [
      {
        path: '/employees-list',
        title: 'employees-list',
        component: lazy(
          () => import(/* webpackChunkName: 'Employees' */ 'pages/Employees/EmployeesList'),
        ),
      },
      {
        path: '/permissions',
        title: 'permissions',
        permission: 'isAllowToEditPermissions',
        component: lazy(
          () => import(/* webpackChunkName: 'Permissions' */ 'pages/Employees/Permissions'),
        ),
      },
      {
        path: '/transactions',
        title: 'transactions',
        component: lazy(() => import(/* webpackChunkName: 'ZTemp' */ 'pages/ZTemp')),
      },
    ],
  },
  {
    path: '/commercials',
    title: 'commercials',
    icon: MonetizationOnIcon,
    permission: 'isAllowToEditCommercials',
    routes: [
      {
        path: '/leads',
        title: 'leads',
        component: lazy(() => import(/* webpackChunkName: 'Leads' */ 'pages/Leads')),
      },
      {
        path: '/user-subscriptions',
        title: 'user-subscriptions',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'UserSubscriptions' */ 'pages/Commercials/UserSubscriptions'
            ),
        ),
      },
      {
        path: '/subscription-duration-tracker',
        title: 'subscription-duration-tracker',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'SubscriptionDurationTracker' */ 'pages/Commercials/SubscriptionDurationTracker'
            ),
        ),
      },
      {
        path: '/commission-report',
        title: 'commission-report',
        permission: 'isAllowToEditPermissions',
        component: lazy(
          () =>
            import(/* webpackChunkName: 'CommissionReport' */ 'pages/Commercials/CommissionReport'),
        ),
      },
      {
        path: '/subscriptions-list',
        title: 'subscriptions-list',
        component: lazy(
          () => import(/* webpackChunkName: 'Subscriptions' */ 'pages/Commercials/Subscriptions'),
        ),
      },
    ],
  },
  {
    path: '/settings',
    title: 'settings',
    icon: SettingsIcon,
    permission: 'isAllowToEditSetting',
    routes: [
      {
        path: '/labels',
        title: 'labels',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'redirectToLevelThree' */ 'components/layout/redirectToLevelThree'
            ),
        ),
        permission: 'isAllowToEditLabels',
        routes: [
          {
            path: '/labels',
            title: 'labels',
            component: lazy(
              () => import(/* webpackChunkName: 'Labels' */ 'pages/Settings/Labels/Labels'),
            ),
          },
          {
            path: '/labels-crm',
            title: 'labels-crm',
            component: lazy(
              () => import(/* webpackChunkName: 'Labels_Crm' */ 'pages/Settings/Labels/LabelsCrm'),
            ),
          },
          {
            path: '/labels-app',
            title: 'labels-app',
            component: lazy(
              () => import(/* webpackChunkName: 'Labels_App' */ 'pages/Settings/Labels/LabelsApp'),
            ),
          },
        ],
      },
      {
        path: '/notebooks',
        title: 'notebooks',
        permission: 'isAllowToEditNotebooks',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'redirectToLevelThree' */ 'components/layout/redirectToLevelThree'
            ),
        ),
        routes: [
          {
            path: '/notebooks',
            title: 'notebooks',
            component: lazy(
              () =>
                import(/* webpackChunkName: 'Notebooks' */ 'pages/Settings/Notebooks/Notebooks'),
            ),
          },
          {
            path: '/notebook-field-inputs',
            title: 'notebook-field-inputs',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'NotebookFieldInputs' */ 'pages/Settings/Notebooks/NotebookFieldInputs'
                ),
            ),
          },
          {
            path: '/field-inputs',
            title: 'field-inputs',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'FieldInputs' */ 'pages/Settings/Notebooks/FieldInputs'
                ),
            ),
          },
          {
            path: '/input-types',
            title: 'input-types',
            component: lazy(
              () =>
                import(/* webpackChunkName: 'InputTypes' */ 'pages/Settings/Notebooks/InputTypes'),
            ),
          },
          {
            path: '/input-type-data-sources',
            title: 'input-type-data-sources',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'InputTypeDataSources' */ 'pages/Settings/Notebooks/InputTypeDataSources'
                ),
            ),
          },
          {
            path: '/input-type-parameters',
            title: 'input-type-parameters',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'InputTypeParameters' */ 'pages/Settings/Notebooks/InputTypeParameters'
                ),
            ),
          },
        ],
      },
      {
        path: '/clinical-parameters',
        title: 'clinical-parameters',
        permission: 'isAllowToEditClinicalParameters',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'redirectToLevelThree' */ 'components/layout/redirectToLevelThree'
            ),
        ),
        routes: [
          {
            path: '/icd10s',
            title: 'icd10s',
            component: lazy(
              () =>
                import(/* webpackChunkName: 'Icd10s' */ 'pages/Settings/ClinicalParameters/Icd10s'),
            ),
          },
          {
            path: '/side-effects',
            title: 'side-effects',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'SideEffects' */ 'pages/Settings/ClinicalParameters/SideEffects'
                ),
            ),
          },
          {
            path: '/satiations',
            title: 'satiations',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'Satiation' */ 'pages/Settings/ClinicalParameters/Satiation'
                ),
            ),
          },
          {
            path: '/drug-taking-times',
            title: 'drug-taking-times',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'DrugTakingTimes' */ 'pages/Settings/ClinicalParameters/DrugTakingTimes'
                ),
            ),
          },
          {
            path: '/treatment-tools',
            title: 'treatment-tools',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'TreatmentTools' */ 'pages/Settings/ClinicalParameters/TreatmentTools'
                ),
            ),
          },
        ],
      },
      {
        path: '/general-settings',
        title: 'general-settings',
        permission: 'isAllowToEditGeneralSettings',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'redirectToLevelThree' */ 'components/layout/redirectToLevelThree'
            ),
        ),
        routes: [
          {
            path: '/cities',
            title: 'cities',
            component: lazy(
              () =>
                import(/* webpackChunkName: 'Cities' */ 'pages/Settings/GeneralSettings/Cities'),
            ),
          },
          {
            path: '/prescription-steps',
            title: 'prescription-steps',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'PrescriptionSteps' */ 'pages/Settings/GeneralSettings/PrescriptionSteps'
                ),
            ),
          },
          {
            path: '/form-document-categories',
            title: 'form-document-categories',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'PrescriptionSteps' */ 'pages/Settings/GeneralSettings/FormDocumentCategories'
                ),
            ),
          },
          {
            path: '/hmos',
            title: 'hmos',
            component: lazy(
              () => import(/* webpackChunkName: 'Hmos' */ 'pages/Settings/GeneralSettings/Hmos'),
            ),
          },
          {
            path: '/genders',
            title: 'genders',
            component: lazy(
              () =>
                import(/* webpackChunkName: 'Genders' */ 'pages/Settings/GeneralSettings/Genders'),
            ),
          },
          {
            path: '/external-channels',
            title: 'external-channels',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'ExternalChannels' */ 'pages/Settings/GeneralSettings/ExternalChannels'
                ),
            ),
          },
          {
            path: '/speak-languages',
            title: 'speak-languages',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'Speak_Languages' */ 'pages/Settings/GeneralSettings/Speak_Languages'
                ),
            ),
          },
          {
            path: '/todo-categories',
            title: 'todo-categories',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'TodoTaskCategories' */ 'pages/Settings/GeneralSettings/TodoTaskCategories'
                ),
            ),
          },
          {
            path: '/todo-subjects',
            title: 'todo-subjects',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'TodoTaskSubjects' */ 'pages/Settings/GeneralSettings/TodoTaskSubjects'
                ),
            ),
          },
          {
            path: '/document-types',
            title: 'document-types',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'Document_Types' */ 'pages/Settings/GeneralSettings/Document_Types'
                ),
            ),
          },
          {
            path: '/tags',
            title: 'tags',
            component: lazy(
              () => import(/* webpackChunkName: 'Tags' */ 'pages/Settings/GeneralSettings/Tags'),
            ),
          },
          {
            path: '/languages',
            title: 'languages',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'Languages' */ 'pages/Settings/GeneralSettings/Languages'
                ),
            ),
          },
          {
            path: '/site-parameters',
            title: 'site-parameters',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'SiteParameters' */ 'pages/Settings/GeneralSettings/SiteParameters'
                ),
            ),
          },
          {
            path: '/blood-tests-results',
            title: 'blood-tests-results',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'BloodTestResults' */ 'pages/Settings/GeneralSettings/BloodTestResults'
                ),
            ),
          },
          {
            path: '/consultant-codes',
            title: 'consultant-codes',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'ConsultantCodes' */ 'pages/Settings/GeneralSettings/ConsultantCodes'
                ),
            ),
          },
          {
            path: '/medical-conditions',
            title: 'medical-conditions',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'MedicalConditions' */ 'pages/Settings/GeneralSettings/MedicalConditions'
                ),
            ),
          },
          {
            path: '/surgeries',
            title: 'surgeries',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'Surgeries' */ 'pages/Settings/GeneralSettings/Surgeries'
                ),
            ),
          },
          {
            path: '/sport-activities',
            title: 'sport-activities',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'SportActivities' */ 'pages/Settings/GeneralSettings/SportActivities'
                ),
            ),
          },
          {
            path: '/eating-habits',
            title: 'eating-habits',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'EatingHabits' */ 'pages/Settings/GeneralSettings/EatingHabits'
                ),
            ),
          },
          {
            path: '/drinking-habits',
            title: 'drinking-habits',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'DrinkingHabits' */ 'pages/Settings/GeneralSettings/DrinkingHabits'
                ),
            ),
          },
          {
            path: '/smoking-habits',
            title: 'smoking-habits',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'SmokingHabits' */ 'pages/Settings/GeneralSettings/SmokingHabits'
                ),
            ),
          },
          {
            path: '/yes-no-answer',
            title: 'yes-no-answer',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'YesNoAnswer' */ 'pages/Settings/GeneralSettings/YesNoAnswer'
                ),
            ),
          },
          {
            path: '/diagnosis-types',
            title: 'diagnosis-types',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'DiagnosisTypes' */ 'pages/Settings/GeneralSettings/DiagnosisTypes'
                ),
            ),
          },
          {
            path: '/bmi-summaries',
            title: 'bmi-summaries',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'BmiSummaries' */ 'pages/Settings/GeneralSettings/BmiSummaries'
                ),
            ),
          },
          {
            path: '/diet-diary-types',
            title: 'diet-diary-types',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'BmiSummaries' */ 'pages/Settings/GeneralSettings/DietDiaryTypes'
                ),
            ),
          },
          {
            path: '/recommendation-templates',
            title: 'recommendation-templates',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RecommendationTemplates' */ 'pages/Settings/GeneralSettings/RecommendationTemplates'
                ),
            ),
          },
        ],
      },
      {
        path: '/meeting-parameters',
        title: 'meeting-parameters',
        permission: 'isAllowToEditMeetingParameters',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'redirectToLevelThree' */ 'components/layout/redirectToLevelThree'
            ),
        ),
        routes: [
          {
            path: '/remark-for-patient-call-statuses',
            title: 'remark-for-patient-call-statuses',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RemarkForPatientCallStatuses' */ 'pages/Settings/Meetings/RemarkForPatientCallStatuses'
                ),
            ),
          },
          {
            path: '/support-meeting-types',
            title: 'support-meeting-types',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'SupportMeetingTypes' */ 'pages/Settings/Meetings/SupportMeetingTypes'
                ),
            ),
          },
          {
            path: '/subscription-types',
            title: 'subscription-types',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'SubscriptionTypes' */ 'pages/Settings/Meetings/SubscriptionTypes'
                ),
            ),
          },
          {
            path: '/clinical-meeting-subjects',
            title: 'clinical-meeting-subjects',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'ClinicalMeetingSubjects' */ 'pages/Settings/Meetings/ClinicalMeetingSubjects'
                ),
            ),
          },
          {
            path: '/clinical-meeting-types',
            title: 'clinical-meeting-types',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'ClinicalMeetingTypes' */ 'pages/Settings/Meetings/ClinicalMeetingTypes'
                ),
            ),
          },
          {
            path: '/lead-activity-types',
            title: 'lead-activity-types',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'LeadActivityTypes' */ 'pages/Settings/Meetings/LeadActivityTypes'
                ),
            ),
          },
        ],
      },
      {
        path: '/document-templates',
        title: 'document-templates',
        permission: 'isAllowToViewDocTemplates',
        component: lazy(
          () => import(/* webpackChunkName: 'DocumentTemplates' */ 'pages/DocumentTemplates/Index'),
        ),
      },
      {
        path: '/form-document-generator',
        title: 'form-document-generator',
        permission: 'isAllowToViewFormDocumentGenerator',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'FormDocumentGenerator' */ 'pages/DocumentTemplates/FormDocumentGenerator'
            ),
        ),
      },
      {
        path: '/sms-templates',
        title: 'sms-templates',
        component: lazy(
          () => import(/* webpackChunkName: 'SMS_Templates' */ 'pages/Settings/SMSTemplate'),
        ),
      },
    ],
  },
  {
    path: '/tools',
    title: 'tools',
    icon: BuildIcon,
    permission: 'isAllowToEditSetting',
    routes: [
      ...(IS_DEV_BUILD
        ? [
            {
              path: '/test-dev',
              title: 'test-dev',
              component: DevPage,
            },
          ]
        : []),
      {
        path: '/create-patient',
        title: 'create-patient',
        component: lazy(
          () => import(/* webpackChunkName: 'CreatePatient' */ 'pages/Tools/CreatePatient'),
        ),
      },
      {
        path: '/migrate-patients',
        title: 'migrate-patients',
        component: lazy(
          () => import(/* webpackChunkName: 'CreatePatients' */ 'pages/Tools/CreatePatients'),
        ),
      },
      {
        path: '/migration-logs',
        title: 'migration-logs',
        component: lazy(
          () => import(/* webpackChunkName: 'MigrationLogs' */ 'pages/Tools/MigrationLogs'),
        ),
      },
    ],
  },
  {
    path: '/profile',
    title: 'profile',
    permission: 'isAllowToEditProfile',
    inMenu: false,
    component: lazy(() => import(/* webpackChunkName: 'Profile' */ 'pages/Profile')),
  },
  {
    path: '/login',
    title: 'login',
    inMenu: false,
    private: false,
    inLayout: false,
    component: lazy(() => import(/* webpackChunkName: 'Login' */ 'pages/Login')),
  },
  {
    path: APP_ROUTES.MY_TASKS.path,
    title: 'login',
    inMenu: false,
    component: lazy(() => import(/* webpackChunkName: 'MyTasks' */ 'pages/MyTasks')),
  },
  {
    path: '/singdoc',
    title: 'singdoc',
    component: lazy(() => import(/* webpackChunkName: 'SingDoc' */ 'pages/SingDoc')),
    inMenu: false,
    inLayout: false,
  },
];

export default routes;
