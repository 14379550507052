import { GridControlsDropDownTemplateProps } from 'models/grid';
import React, { useCallback } from 'react';
import { ListItemIcon, MenuItem } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import VAR from 'styles/_variabels.scss';
import { GridModel } from '../helpers';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { apiUserPatientProfileSessionClinicDrugs } from 'services/user-patient-profile-session-clinic-drugs';
import { useEffectError } from 'hooks';
import { isMutationRejected } from 'utils/rtk-query';
import { useAppDispatch } from 'store';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';

interface Props extends GridControlsDropDownTemplateProps<GridModel> {
  userPatientProfileSessionID: string;
}

export const ControlsDropDown: React.FC<Props> = ({
  userPatientProfileSessionID,
  onTask,
  data,
  handleClose,
}) => {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();

  const [triggerClone, { error, isLoading }] =
    apiUserPatientProfileSessionClinicDrugs.usePostUserPatientProfileSessionClinicDrugsMutation();
  useEffectError(error);

  const onClick = useCallback(async () => {
    const result = await triggerClone({
      userPatientProfileSessionID,
      drugID: data.drugID,
      dosageForm: data.dosageForm,
    });

    if (isMutationRejected(result)) {
      return;
    }

    onTask([['getData']]);
    handleClose();

    dispatch(notifyRequestResult(t('success-cloned'), 'success'));
  }, [data, userPatientProfileSessionID, triggerClone, onTask, handleClose, dispatch, t]);

  if (data.userPatientProfileSessionID === userPatientProfileSessionID) {
    return null;
  }

  return (
    <>
      <MenuItem onClick={onClick} style={{ color: VAR.colorPrimary }} disabled={isLoading}>
        <ListItemIcon style={{ minWidth: '3rem' }}>
          <FileCopyIcon fontSize={'small'} style={{ color: VAR.colorPrimary }} />
        </ListItemIcon>
        {t('clone')}
      </MenuItem>
    </>
  );
};
