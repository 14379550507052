import React, { CSSProperties } from 'react';
import { useAppSelector } from '../store';
import { selectLanguageIsRtl } from '../store/languages';

export const withRtlRotate = <P extends { style?: CSSProperties }>(Component: React.FC<P>) => {
  const WithRtlRotate = (props: P) => {
    const isRtl = useAppSelector(selectLanguageIsRtl);

    return (
      <Component
        {...props}
        style={{ ...props.style, transform: `rotate(${isRtl ? 180 : 0}deg)` }}
      />
    );
  };

  return WithRtlRotate;
};
