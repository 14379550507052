import React, { memo } from 'react';
import { BaseCreateComponentProps } from '../../models';
import { DialogEndTreatmentConsultation } from '../../../../components/end-treatment/components/dialog-treatment-consultation';

export const DialogCreateDocSummaryOfConsultation = memo<BaseCreateComponentProps>(
  ({ userPatientProfileID, onClose, onDone }) => {
    return (
      <DialogEndTreatmentConsultation
        isLoading={false}
        userPatientProfileID={userPatientProfileID}
        onClose={onClose}
        onDone={onDone}
        price={null}
      />
    );
  },
);
