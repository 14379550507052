import React, { useCallback } from 'react';

import { MenuItem } from '@material-ui/core';
import { Loading } from 'AurionCR/components';
import { useOpen } from 'AurionCR/components/hooks';
import { DialogGenerateForm29 } from 'components/dialog-generate-form29';
import { isRequestSuccess, useMutationDynamic, useEffectError } from 'hooks';
import { ServicePatientPrescriptionDetails } from 'services/user-patient-profile-prescription-details';
import { getBase64FileSize } from 'utils/file-uploader';
import style from './index.module.scss';
import clsx from 'clsx';
import { apiDocumentTypes, DOCUMENT_TYPES_INDEXES } from 'services/document-types';
import { useTranslate } from 'hooks/use-translate';
import { isMutationFulfilled } from 'utils/rtk-query';
const useLoadDocType = apiDocumentTypes.useLazyGetDocumentTypeByIndexQuery;

interface Props {
  userPatientProfilePrescriptionDetailID: string;
  userPatientProfilePrescriptionID: string;
  userPatientProfileID: string;
  userEmployeeProfileID: string;
  drugID: string;
  onRefresh: () => void;
}

export const MenuGenerateGimel29: React.FC<Props> = ({
  drugID,
  userPatientProfilePrescriptionID,
  userPatientProfilePrescriptionDetailID,

  userPatientProfileID,
  userEmployeeProfileID,
  onRefresh,
}) => {
  const [triggerLoadDocType, resultLoadDocType] = useLoadDocType();
  useEffectError(resultLoadDocType.error);

  const { t } = useTranslate();
  const { isOpen: isOpenDialog, handleOpen: onOpenDialog, handleClose: onCloseDialog } = useOpen();
  const [requestCreate, { isLoading, error }] = useMutationDynamic(
    ServicePatientPrescriptionDetails.createDocumentGimel29,
  );
  useEffectError(error);

  const onGenerate = useCallback(
    async (input: {
      valueWatermark: string;
      drug: Pick<Components.Schemas.Drug, 'catalogName'>;
    }) => {
      const fileName = [t('form-29'), input.drug.catalogName, t('copy')].join(' - ');

      const resDocType = await triggerLoadDocType(DOCUMENT_TYPES_INDEXES.GIMEL_29);

      if (!isMutationFulfilled(resDocType)) {
        return;
      }

      const result = await requestCreate({
        userPatientProfilePrescriptionDetailID,
        userPatientProfilePrescriptionID,

        isForm29: true,
        isCopy: true,
        userPatientProfileID: userPatientProfileID,
        userEmployeeProfileID: userEmployeeProfileID,
        documentTypeID: resDocType.data.id,
        fileName: fileName,
        documentURL: {
          value: input.valueWatermark,
          type: 'application/pdf',
          name: `${fileName}.pdf`,
          size: getBase64FileSize(input.valueWatermark),
        },
        documentForPrintURL: null,
      });

      if (isRequestSuccess(result)) {
        onRefresh();
      }
    },
    [
      userPatientProfilePrescriptionDetailID,
      userPatientProfilePrescriptionID,
      userPatientProfileID,
      requestCreate,
      t,
      userEmployeeProfileID,
      onRefresh,
      triggerLoadDocType,
    ],
  );

  return (
    <>
      <MenuItem className={clsx(style.root)} onClick={onOpenDialog} disabled={isLoading}>
        {t('generate-form-29')}
        <Loading active={isLoading} size={12} />
      </MenuItem>
      {isOpenDialog && (
        <DialogGenerateForm29
          onClose={onCloseDialog}
          onGenerated={onGenerate}
          drugID={drugID}
          patientID={userPatientProfileID}
          doctorID={userEmployeeProfileID}
        />
      )}
    </>
  );
};
