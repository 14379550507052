import React, { memo, useMemo, useState } from 'react';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { getNotebookFormComponent } from 'components/notebook-form-generator';
import { useSourceNotebooksAll } from 'components/hooks';
import { useTranslate } from 'hooks/use-translate';
import { UserPatientProfileSession } from 'services/user-patient-profile-session';
import { AppSelect } from 'components/app-select';
import { Stack } from 'components/stack';
import { useOpen } from 'AurionCR/components/hooks';
import { composeFunctions } from 'utils';
import { withPrescriptionTask } from '../../../notebook-form-generator/components/with-prescription-task';

interface Props {
  userPatientProfileID: string;
  onRefresh: (session: Pick<UserPatientProfileSession, 'id'> | undefined) => void;
  onUpdateWeightDate: () => void;
  showDelete?: boolean;
}
export const CreateNotebookForm = memo<Props>(
  ({ userPatientProfileID, onRefresh, onUpdateWeightDate, showDelete = true }) => {
    const { t } = useTranslate();
    const [notebookID, setNotebookID] = useState('');
    const { isOpen, handleClose, handleOpen } = useOpen();

    const sourceNotebooks = useSourceNotebooksAll();

    const notebook = useMemo(() => {
      return sourceNotebooks.data.find((notebook) => notebook.id === notebookID);
    }, [sourceNotebooks, notebookID]);

    const FormGenerator = useMemo(() => {
      return notebook ? withPrescriptionTask(getNotebookFormComponent(notebook)) : null;
    }, [notebook]);

    const onCloseNotebookForm = useMemo(
      () => composeFunctions(handleClose, onRefresh),
      [handleClose, onRefresh],
    );

    return (
      <>
        <Stack spacing={1} alignItems={'center'}>
          <AppSelect
            placeholder={t('notebook')}
            value={notebookID || null}
            onChange={(v, option) => setNotebookID(v)}
            options={sourceNotebooks.data}
            loading={sourceNotebooks.loading}
            size={'small'}
            RenderInputProps={{
              variant: 'outlined',
              style: { minWidth: '22rem', margin: 0 },
            }}
          />
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            disabled={!notebookID}
            onClick={handleOpen}
            style={{ minWidth: '12rem' }}
          >
            {t('add-new')}
          </Button>
        </Stack>
        {isOpen && FormGenerator && (
          <FormGenerator
            notebookID={notebookID}
            userPatientProfileID={userPatientProfileID}
            showDelete={showDelete}
            onClose={onCloseNotebookForm}
            onDone={onCloseNotebookForm}
            onCreated={onUpdateWeightDate}
            meetingFromDateTime={undefined}
            meetingToDateTime={undefined}
          />
        )}
      </>
    );
  },
);
