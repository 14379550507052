import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actionSideEffectsLoadData } from './actions';
import { addWeeks, endOfWeek, startOfDay } from 'date-fns';
import { GridFilters, WithSideEffectItem } from '../models';

const today = new Date();
export const sideEffectsInitFilters: GridFilters = {
  dates: [addWeeks(startOfDay(today), -2).getTime(), endOfWeek(today).getTime()],
  age: [18, 80],
  sideEffectIDs: [],
};

interface State {
  isLoading: boolean;
  isInit: boolean;
  filters: GridFilters;
  sideEffects: WithSideEffectItem[];
}

const initialState = (): State => {
  return {
    isLoading: false,
    isInit: false,
    filters: sideEffectsInitFilters,
    sideEffects: [],
  };
};

const slice = createSlice({
  name: 'SIDE_EFFECTS',
  initialState,
  reducers: {
    actionSideEffectsSetFilters(state, action: PayloadAction<GridFilters>) {
      state.filters = action.payload;
    },
  },
  extraReducers(build) {
    build
      .addCase(actionSideEffectsLoadData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actionSideEffectsLoadData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sideEffects = action.payload;
      })
      .addCase(actionSideEffectsLoadData.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});
export const { actionSideEffectsSetFilters } = slice.actions;
export const sideEffectsReducer = slice.reducer;
