import React, { FC, memo, useCallback, useEffect } from 'react';
import { IDataGridFilter } from '../../model';
import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useI18n } from 'AurionCR/components';
import { Input } from 'AurionCR/components/form';
import { Controller, useForm } from 'react-hook-form';
import { useFieldProps } from 'hooks';
import { getHandlerKayPress } from 'utils/handlers';

const FilterString: FC<{
  filter: IDataGridFilter;
  value: string;
  onTasks: any;
}> = ({ filter, value = '', onTasks }) => {
  const { t } = useI18n();

  const { control, errors, setValue, reset, getValues } = useForm({
    defaultValues: { value: value },
  });
  const getFieldProps = useFieldProps({ errors });

  const onChange = useCallback(() => {
    const formValue = getValues('value');
    if (value !== formValue) {
      onTasks([
        ['filters', { [filter.field]: formValue }],
        ['pagination', { page: 1 }],
        ['getData'],
      ]);
    }
  }, [filter.field, onTasks, value, getValues]);

  const onClear = useCallback(() => {
    setValue('value', '');
    onTasks([['filters', { [filter.field]: '' }], ['pagination', { page: 1 }], ['getData']]);
  }, [filter.field, onTasks, setValue]);

  useEffect(() => {
    const formValue = getValues('value');

    if (value !== formValue) {
      reset({ value });
    }
  }, [value, getValues, reset]);

  return (
    <Controller
      control={control}
      name={'value'}
      render={(renderProps) => (
        <Input
          {...getFieldProps(renderProps)}
          onKeyDown={getHandlerKayPress('Enter', onChange)}
          onBlur={onChange}
          onClear={onClear}
          // @ts-ignore
          label={t(filter.options?.label || 'search')}
          placeholder={t(filter.options?.placeholder || 'search')}
          style={filter.options?.style}
          helperText={''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
};

export default memo(FilterString);
