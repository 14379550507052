import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { useForm, UseFormOptions } from 'react-hook-form';
import { useDataGridLightSettings } from './use-data-grid-light-settings';

export interface UseDataGridEditorFormProps<Model extends Record<string, any> = any>
  extends UseFormOptions {
  row: Model;
  field: keyof Model;
  onClose: () => void;
}
export const useDataGridLightEditorForm = <M extends Record<string, any>>(
  props: UseDataGridEditorFormProps<M>,
) => {
  const { schema } = useDataGridLightSettings();

  const { row, field, onClose, resolver: outerResolver, ...rest } = props;

  const defaultResolver = useMemo(() => {
    return schema ? yupResolver(schema.pick([field as string])) : undefined;
  }, [field, schema]);

  const defaultValues = { [field]: row[field] };
  const formMethods = useForm({
    defaultValues,
    resolver: outerResolver || defaultResolver,
    ...rest,
  });

  return { ...formMethods };
};
