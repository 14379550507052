import React, { useCallback } from 'react';
import { MenuItem, Typography } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { GridControlsDropDownTemplateProps } from 'models/grid';
import style from './index.module.scss';
import clsx from 'clsx';
import { CustomHandler } from '../../helpers';
import { Stack } from 'components/stack';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { useDataGridTasks } from 'components/data-grid/hooks';

type Model = {
  id: string;
  isCanceled: boolean;
  supportMeetingTypeID: string;
};

interface Props extends GridControlsDropDownTemplateProps<Model> {}
export const SupportControlClose: React.FC<Props> = ({ data, handleClose }) => {
  const { t } = useTranslate();
  const isClosed = data.isCanceled;

  const { onTasks } = useDataGridTasks<Model>();

  const onClick = useCallback(() => {
    onTasks([['onHandle', { type: CustomHandler.CANCEL_MEETING, data }]]);
    handleClose();
  }, [onTasks, data, handleClose]);

  return (
    <MenuItem
      disabled={isClosed}
      className={clsx(style.root, { [style.close]: !isClosed })}
      onClick={onClick}
    >
      <Stack spacing={1}>
        <RemoveCircleOutlineIcon fontSize={'small'} />
        <Typography>{t('cancel-meeting')}</Typography>
      </Stack>
    </MenuItem>
  );
};
