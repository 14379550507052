import { useEffect, useMemo } from 'react';
import { ServiceUserPatientProfileSessions } from 'services/user-patient-profile-session';
import { useEffectError, useFetchDynamic } from 'hooks';

type FetchModel = {
  notebookMigatedFromOldSystem: string;
};
const fetchData = async (id: string) => {
  return ServiceUserPatientProfileSessions.getDynamic<FetchModel>(id, {
    select: ['notebook.migatedFromOldSystem as notebookMigatedFromOldSystem'].join(','),
  });
};

export const useTitle = (userPatientProfileSessionID: string) => {
  const [triggerFetch, { data, error }] = useFetchDynamic(fetchData);
  useEffectError(error);

  const isCanDelete = useMemo(() => {
    return data ? !data.notebookMigatedFromOldSystem : false;
  }, [data]);

  useEffect(() => {
    triggerFetch(userPatientProfileSessionID);
  }, [triggerFetch, userPatientProfileSessionID]);

  return useMemo(() => ({ isCanDelete }), [isCanDelete]);
};

export default useTitle;
