import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface Options<T> {
  name: string;
  init?: T;
  destroyOnUnmount?: boolean;
  keepState?: boolean;
}
export const useValueQuery = <T extends string | number | boolean | null>(options: Options<T>) => {
  const { name, init, destroyOnUnmount, keepState = true } = options;

  const { push, replace } = useHistory();
  const { search, state } = useLocation();
  const makeSearch = useCallback(
    (value: T) => {
      const searchParams = new URLSearchParams(window.location.search);
      if (value === null || value === undefined) {
        searchParams.delete(name);
      } else if (searchParams.has(name)) {
        searchParams.set(name, String(value));
      } else {
        searchParams.append(name, String(value));
      }
      return searchParams;
    },
    //eslint-disable-next-line
    [name],
  );

  const onChange = useCallback(
    (value: T) => {
      return push({ search: makeSearch(value).toString(), state: keepState ? state : undefined });
    },
    //eslint-disable-next-line
    [makeSearch, push, keepState, state],
  );
  const onClear = useCallback(() => {
    onChange(undefined as any);
  }, [onChange]);

  const value = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get(name) as T;
    //eslint-disable-next-line
  }, [name, search]);

  const refOnce = useRef(false);
  useEffect(() => {
    if (init && !value && !refOnce.current) {
      refOnce.current = true;
      replace({ search: makeSearch(init).toString(), state: keepState ? state : undefined });
    }
    //eslint-disable-next-line
  }, [value, init, makeSearch, keepState, state]);

  useEffect(() => {
    return () => {
      if (destroyOnUnmount) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete(name);
        replace({
          search: searchParams.toString(),
          state: keepState ? state : undefined,
        });
      }
    };
    //eslint-disable-next-line
  }, []);

  return { value, onChange, onClear };
};
