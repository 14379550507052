import React, { forwardRef, useCallback, useRef } from 'react';
import { FormControl, FormHelperText, FormLabel } from '@material-ui/core';
import style from './index.module.scss';
import { ColorPicker, ColorPreview } from './components';
import { useOpen } from 'AurionCR/components/hooks';
import { PICKER_COLORS } from './models';

export * from './components';

interface Props {
  name?: string;
  label?: string;
  value?: string;
  onChange?: (v: string) => void;
  options?: string[];
  error?: boolean;
  helperText?: React.ReactNode;
  disabled?: boolean;
}

export const FieldColor = forwardRef<HTMLInputElement, Props>(
  ({ label, name, value, onChange, options = PICKER_COLORS, error, helperText }) => {
    const { isOpen, handleOpen, handleClose } = useOpen();
    const ref = useRef<HTMLDivElement>(null);

    const handleChange = useCallback(
      (color: string) => {
        onChange && onChange(color);
        handleClose();
      },
      [onChange, handleClose],
    );

    return (
      <FormControl error={Boolean(error)} className={style.root}>
        <input name={name} value={value} hidden />
        {label && <FormLabel className={style.label}>{label}</FormLabel>}
        <div ref={ref} onClick={handleOpen} className={style.inner}>
          <ColorPreview value={value} />
        </div>
        <ColorPicker
          options={options}
          onChange={handleChange}
          open={isOpen}
          anchorEl={ref.current}
          onClose={handleClose}
        />
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  },
);
