import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { Box, CircularProgress, Divider, Typography } from '@material-ui/core';
import {
  UserBoxClinicalMeeting,
  UserBoxProgramDate,
  UserBoxProgramDuration,
  UserBoxProgramName,
  UserBoxSupportMeeting,
} from '../../../user-info-boxes';
import { Stack } from 'components/stack';
import {
  ChartBMI,
  ChartWeights,
  Form,
  ProviderPatientData,
  UpdateDescription,
} from '../../components';
import { usePatientWeights } from '../../hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { calcBmi, isTheSameValue } from 'utils/app-helpers';
import VAR from 'styles/_variabels.scss';
import { NativeScroll } from 'components/native-scroll';
import { BmiChart } from 'components/leads-form/bmi-chart';
import { useTranslate } from 'hooks/use-translate';
import {
  apiUserPatientProfile,
  getPartPatientSchema,
  schemaUserPatientProfile,
} from 'services/user-patient-profile';
import { useAppDispatch, useAppSelector } from 'store';
import { selectLanguageIsRtl } from 'store/languages';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffectError } from 'hooks';
import { RTK_TAGS } from 'utils/rtk-query';

const usePatch = apiUserPatientProfile.usePatchPatientWithLogMutation;

interface Classes {
  root: string;
}

interface Props {
  className?: string;
  classes?: Partial<Classes>;
  userPatientProfileID: string;
  isLoading: boolean;
  showDietitian: boolean;
}

export const TabGeneral: React.FC<Props> = ({
  userPatientProfileID,
  showDietitian,
  isLoading,
  className,
  classes,
}) => {
  const { tp } = useTranslate();
  const dispatch = useAppDispatch();

  const [triggerPatch, resultPatch] = usePatch();
  useEffectError(resultPatch.error);

  const { data: initData } =
    apiUserPatientProfile.useGetPatientForGeneralFormQuery(userPatientProfileID);

  const schema = useMemo(() => {
    return schemaUserPatientProfile.concat(
      getPartPatientSchema({ userPatientProfileID, showDietitian }),
    );
  }, [userPatientProfileID, showDietitian]);

  const formMethods = useForm({
    defaultValues: schema.cast({ ...initData }, { stripUnknown: true, assert: false }),
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  const { watch, trigger, reset } = formMethods;

  const requestUpdate = useCallback(
    async (formData: Record<string, any>, description: UpdateDescription) => {
      if (isTheSameValue(description.newValue, description.oldValue)) return;
      if (!initData) return;

      const payload = { ...formData, appIdentityUserID: userPatientProfileID };
      await triggerPatch({ initData, formData: payload, remark: tp('updates-by-employee') });

      dispatch(
        apiUserPatientProfile.util.invalidateTags([
          { type: RTK_TAGS.PATIENT, id: userPatientProfileID },
        ]),
      );
    },
    [dispatch, userPatientProfileID, triggerPatch, tp, initData],
  );

  const refOnce = useRef(false);
  useEffect(() => {
    if (!initData) return;
    if (refOnce.current) return;

    refOnce.current = true;
    reset(schema.cast(initData, { stripUnknown: true, assert: false }));

    requestAnimationFrame(() => {
      // show the invalid fields
      trigger();
    });
  }, [initData, reset, schema, trigger]);

  const { t } = useTranslate();
  const isRtl = useAppSelector(selectLanguageIsRtl);
  const { data: weights, isLoading: isLoadingWeights } = usePatientWeights(userPatientProfileID);
  const height = watch('height') || 0;
  const weight = watch('weight') || 0;

  const bmiWeight = useMemo(() => {
    return weights.map((item) => ({
      id: item.id,
      activityType: item.activityType,
      entryDate: item.entryDate,
      externalSystemValue: item.externalSystemValue,
      transactionType: item.transactionType,
      weight: calcBmi(item.weight, height),
    }));
  }, [weights, height]);

  const initBmi = useMemo(() => {
    return calcBmi(weight, height);
  }, [weight, height]);

  return (
    <ProviderPatientData patient={initData} onUpdate={requestUpdate}>
      <div className={style.main}>
        <NativeScroll>
          <div className={clsx(style.root, classes?.root, className)}>
            <Stack spacing={1}>
              <UserBoxProgramDate userPatientProfileID={userPatientProfileID} checkSubscription />
              <UserBoxProgramName userPatientProfileID={userPatientProfileID} />
              <UserBoxProgramDuration userPatientProfileID={userPatientProfileID} />
              <UserBoxSupportMeeting userPatientProfileID={userPatientProfileID} order={'last'} />
              <UserBoxSupportMeeting userPatientProfileID={userPatientProfileID} order={'next'} />
              <UserBoxClinicalMeeting userPatientProfileID={userPatientProfileID} order={'last'} />
              <UserBoxClinicalMeeting userPatientProfileID={userPatientProfileID} order={'next'} />
            </Stack>
            <div>
              <FormProvider {...formMethods}>
                <Form isNew={false} isLoading={isLoading} showDietitian={showDietitian} />
              </FormProvider>
            </div>
            {weights.length > 0 && (
              <Box display={'flex'} height={'100%'}>
                <Box width={'50%'}>
                  <Typography variant="h1" color="secondary" style={{ margin: '0 1rem 0 1.5rem' }}>
                    {t('weight')}
                  </Typography>
                  <ChartWeights isLoading={isLoadingWeights} weights={weights} />
                </Box>
                <Divider
                  orientation={'vertical'}
                  style={{
                    backgroundColor: VAR.colorGreyLines,
                    marginLeft: isRtl ? '3rem' : '1.3rem',
                  }}
                />
                <Box width={'50%'}>
                  <Typography
                    variant="h1"
                    color="secondary"
                    style={{ textTransform: 'uppercase', margin: '0 1rem 0 1.5rem' }}
                  >
                    {t('bmi')}
                  </Typography>
                  <ChartBMI isLoading={isLoadingWeights} weights={bmiWeight} />
                </Box>
              </Box>
            )}
            {!isLoadingWeights && weights.length === 0 && <BmiChart bmi={initBmi} />}
          </div>
        </NativeScroll>
      </div>
      {resultPatch.isLoading && (
        <div className="auto-save-indicator">
          <CircularProgress size={20} />
        </div>
      )}
    </ProviderPatientData>
  );
};
