import { createFilterEquals, mergeFilters } from 'utils/dynamic-helpers';
import { api, DynamicResult, transformDynamicToItem } from 'utils/service';
import { API_DOCUMENT_TEMPLATES, CONTENT_KEYS, DocumentTemplate } from './models';
import { apiRtk } from 'utils/rtk-query';

export * from './models';
export * from './helperts';

class Service {
  async getByContentKey(contentKey: CONTENT_KEYS) {
    const params = {
      filter: mergeFilters(
        createFilterEquals('contentKey', contentKey),
        createFilterEquals('isActive', true),
      ).join('&&'),
    };

    const result = await api.get<DynamicResult<DocumentTemplate, typeof params>>(
      API_DOCUMENT_TEMPLATES.GET_ALL_DYNAMIC,
      { params },
    );

    return { ...result, data: transformDynamicToItem(result.data) };
  }
}

export const ServiceDocumentTemplates = new Service();

export const apiDocumentTemplates = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getDocumentTemplateByContentKey: builder.query<DocumentTemplate, CONTENT_KEYS>({
      queryFn: async (contentKey) => {
        try {
          const result = await ServiceDocumentTemplates.getByContentKey(contentKey);

          return { data: result.data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
