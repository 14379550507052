export const API_PATIENT_PUSH_NOTIFICATIONS = {
  GET_KEYS: '/notify/get-keys',
  PUSH_TO_PATIENT: '/notify/patient',
  PUSH_TO_EMPLOYEE: '/notify/employee',
};

export interface PatientSendMessageEvent {
  userPatientProfileID: string;
  title: string;
  message: string;
}

export interface FillHealthSurveyInput {
  userPatientProfileID: string;
}

export interface NotificationsToggleSubscription {
  userEmployeeProfileID: string;
  subscription: PushSubscription;
}

interface PatientNotificationHealthSurvey {
  type: 'HEALTH_SURVEY';
  userPatientProfileID: string;
  title: string;
  message: string;
}

export type PatientNotifications = PatientNotificationHealthSurvey;

interface EmployeeNotificationPrescription {
  type: 'TASK';
  taskID: string;
  userPatientProfileID: string;
  fromUserEmployeeProfileID: string;
  toUserEmployeeProfileID: string;
  title: string;
  message: string;

  redirect?: string;
  icon?: string;
}

export type EmployeeNotifications = EmployeeNotificationPrescription;
