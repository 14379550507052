import React, { FC, memo, ReactNode, useCallback, useMemo } from 'react';
import { IDataGridMadel } from '../model';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { useTranslate } from 'hooks/use-translate';
import clsx from 'clsx';
import { useDataGridTasks } from '../hooks';

interface Props {
  model: IDataGridMadel;
  sortable: any;
  rowControls?: ReactNode;
  preview?: boolean;
  isHideSwap: boolean;
}
const Head: FC<Props> = ({ isHideSwap, model, sortable, rowControls, preview }) => {
  const { onTasks } = useDataGridTasks();
  const { t } = useTranslate();
  const onClick = useCallback(
    (column) => {
      if (column.sortable)
        onTasks([['sortableTrigger', column.field], ['pagination', { page: 1 }], ['getData']]);
    },
    [onTasks],
  );
  // eslint-disable-next-line
  const isSwap = useMemo(
    () => model && !preview && model.store.swap && model.store.swap.field && !isHideSwap,
    [preview, model, isHideSwap],
  );
  // eslint-disable-next-line
  const isRowControls = useMemo(
    () =>
      model &&
      !preview &&
      ((model.controls && model.controls.length > 0) ||
        (model.controlsDropDown && model.controlsDropDown.length > 0) ||
        rowControls),
    [preview, model, rowControls],
  );
  const renderHeadContent = useCallback(
    (title: string | React.ReactNode) => {
      if (typeof title === 'string') {
        return t(title);
      }
      return title;
    },
    [t],
  );

  if (!model) return null;
  return (
    <>
      <colgroup>
        {isSwap && <col />}
        {model.columns.map((column) => (
          <col key={column.key} width={column.width} />
        ))}
        {isRowControls && <col />}
      </colgroup>
      <thead>
        <tr>
          {isSwap && (
            <th
              className="data-grid-col-controls swap"
              style={{
                zIndex: 8,
              }}
            >
              {renderHeadContent(model.store.swap?.title || null)}
            </th>
          )}

          {model.columns.map((column) => {
            const columnSortableField = column.field.split(' as ')[0];
            return (
              <th
                key={column.key}
                className={clsx(
                  column.thClasses,
                  column.sortable && 'sortable',
                  columnSortableField === sortable.field && 'active',
                )}
                onClick={() => onClick(column)}
                style={column.thStyles}
              >
                {renderHeadContent(column.title)}
                {columnSortableField === sortable.field && sortable.order === 'desc' && (
                  <ArrowDropDownIcon />
                )}
                {columnSortableField === sortable.field && sortable.order === 'asc' && (
                  <ArrowDropUpIcon />
                )}
              </th>
            );
          })}
          {isRowControls && (
            <th
              className="data-grid-col-controls"
              style={{
                zIndex: 8,
              }}
            >
              &nbsp;
            </th>
          )}
        </tr>
      </thead>
    </>
  );
};

export default memo(Head);
