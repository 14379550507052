import React from 'react';
import style from './index.module.scss';
import { Paper, PaperProps, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Stack } from 'components/stack';
interface Props extends Omit<PaperProps, 'title'> {
  title: React.ReactNode;
  action?: React.ReactNode;
}

export const SectionPaper: React.FC<Props> = ({ title, action, children, ...rest }) => {
  return (
    <Paper
      component={'section'}
      variant="outlined"
      square
      className={clsx(style.root, rest.className)}
    >
      <Stack spacing={1} alignItems={'center'} mb={1} minHeight={'2.2rem'}>
        <Typography variant={'h4'} component={'h3'} color={'secondary'} style={{ flexGrow: 1 }}>
          {title}
        </Typography>
        {action}
      </Stack>
      {children}
    </Paper>
  );
};
