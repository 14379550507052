import React from 'react';
import { AppInputMask, AppInputMaskedProps } from 'components/app-input-masked';
export const AppWeightInput: React.FC<
  Omit<AppInputMaskedProps, 'MaskOptions'> & { minValue?: number }
> = ({ value: _value, minValue = 0, ...props }) => {
  const value = String(_value || minValue);

  return (
    <AppInputMask
      {...props}
      value={value}
      placeholder={'00.00'}
      disableClearable
      MaskOptions={{
        mask: Number,
        definitions: undefined,

        // other options are optional with defaults below
        scale: 2, // digits after point, 0 for integers
        signed: true, // disallow negative
        thousandsSeparator: '', // any single char
        padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
        normalizeZeros: true, // appends or removes zeros at ends
        radix: '.', // fractional delimiter
        mapToRadix: ['.', ','], // symbols to process as radix

        // additional number interval options (e.g.)
        min: minValue,
        max: 300,
      }}
    />
  );
};
