import React, { useCallback, useEffect, useRef } from 'react';
import { IconButton, Paper, Popover, Typography } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import style from './index.module.scss';
import { Stack } from 'components/stack';
import { useOpen } from 'AurionCR/components/hooks';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffectError, useFieldProps } from 'hooks';
import { ButtonCancel, ButtonSave } from 'components/app-buttons';
import {
  apiUserPatientProfile,
  schemaPatientForPrescriptionForm,
} from 'services/user-patient-profile';
import EditIcon from '@material-ui/icons/Edit';
import { InferType } from 'yup';
import { isMutationFulfilled } from 'utils/rtk-query';
import { AppIdNumberInput } from 'components/app-id-number-input';

const schema = schemaPatientForPrescriptionForm.pick(['idNumber']);
const useGetPatientDetails = apiUserPatientProfile.useGetPatientForGeneralFormQuery;
const usePatchMutation = apiUserPatientProfile.usePatchPatientWithLogMutation;
type Model = InferType<typeof schema>;

interface FormProps {
  userPatientProfileID: string | null | undefined;
  onClose: () => void;
}
const Form: React.FC<FormProps> = ({ userPatientProfileID, onClose }) => {
  const { tp } = useTranslate();
  const { control, errors, reset, handleSubmit } = useForm({
    defaultValues: schema.cast({}),
    resolver: yupResolver(schema),
  });
  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

  const { data: patient, isFetching } = useGetPatientDetails(userPatientProfileID || '', {
    skip: !userPatientProfileID,
  });
  useEffect(() => {
    if (!patient) return;
    reset(schema.cast(patient, { stripUnknown: true, assert: false }));
  }, [reset, patient]);

  const [patch, resultPatch] = usePatchMutation();
  useEffectError(resultPatch.error);

  const onSubmit = useCallback(
    async (formData: Model) => {
      if (!userPatientProfileID) return;
      if (!patient) return;

      const payload = { appIdentityUserID: userPatientProfileID, ...formData };

      const res = await patch({
        initData: patient,
        formData: payload,
        remark: tp('updates-by-employee'),
      });

      if (isMutationFulfilled(res)) {
        onClose();
      }
    },
    [userPatientProfileID, patient, patch, tp, onClose],
  );

  return (
    <Paper className={style.form}>
      <Controller
        name={'idNumber'}
        control={control}
        render={(renderProps) => (
          <AppIdNumberInput
            {...getFieldProps(renderProps)}
            disabled={isFetching}
            disableClearable
          />
        )}
      />
      <Stack justifyContent={'flex-end'} spacing={1}>
        <ButtonCancel isBack={false} onClick={onClose} />
        <ButtonSave disabled={isFetching} isCreate={false} onClick={handleSubmit(onSubmit)} />
      </Stack>
    </Paper>
  );
};

interface ValidityErrorProps {
  userPatientProfileID: string | null | undefined;
}
export const ValidityError: React.FC<ValidityErrorProps> = ({ userPatientProfileID }) => {
  const { tp } = useTranslate();
  const { isOpen, handleOpen, handleClose } = useOpen();
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div className={style.error} ref={ref}>
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <Typography
          style={{ flexGrow: 1 }}
          className={style.errorTitle}
          title={tp('neopharm-verified-id-number-tooltip')}
        >
          {tp('neopharm-verified-id-number')}
        </Typography>
        <IconButton color={'primary'} size={'small'} onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Stack>
      <Popover
        anchorEl={ref.current}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Form userPatientProfileID={userPatientProfileID} onClose={handleClose} />
      </Popover>
    </div>
  );
};

interface Props {
  userPatientProfileID: string | null | undefined;
  isValid: boolean;
  value: string | null | undefined;
}
export const ValidityIdNumber: React.FC<Props> = ({ userPatientProfileID, isValid, value }) => {
  if (!isValid) {
    return <ValidityError userPatientProfileID={userPatientProfileID} />;
  }
  return <Typography style={{ textAlign: 'center' }}>{value}</Typography>;
};
