const API_ENDPOINT = 'EmailMessages';

export const API_EMAIL_MESSAGES = {
  SEND_MESSAGE: `${API_ENDPOINT}/SendEmail`,
};

export interface EmailMessage extends Components.Schemas.EmailRequest {}
export interface EmailMessageInput
  extends Required<Omit<Components.Schemas.EmailRequest, 'html' | 'tag' | 'attachments'>> {
  html?: string | null;
  tag?: string | null;
  attachments?: string[] | null;
}

export interface EmailMessageToPatientInput extends EmailMessageInput {
  userPatientProfileID: string;
}
export interface EmailMessageToPharmacyInput extends EmailMessageInput {
  userPatientProfileID: string;
}
