import React, { memo, useState } from 'react';
import Form from './form';
import { useFormGrid } from 'AurionCR/components/formV2';
import DataGrid from 'components/data-grid';
import GridConfig from './grid-config';

export default memo(() => {
  const [gridConfig] = useState<any>(GridConfig());
  const { grid, onGridHandle, onFormHandle, editItemID, setEditItemID } = useFormGrid('notebook');
  // render
  return (
    <>
      <DataGrid ref={grid} onHandle={onGridHandle} config={gridConfig} />
      {editItemID && (
        <Form
          onClose={() => setEditItemID(false)}
          onFormHandle={onFormHandle}
          itemID={editItemID}
        />
      )}
    </>
  );
});

export * from './store/saga';
export * from './store/module';
