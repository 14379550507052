import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Box, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { Form } from './form';
import { usePermissions } from 'hooks/use-permissions';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffectError, useMountedRef } from 'hooks';
import { adapterRtkItemID, isMutationFulfilled } from 'utils/rtk-query';
import {
  FormModel,
  schema,
  useDeleteMutation,
  useGetQuery,
  usePatchMutation,
  usePostMutation,
} from './helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import { useConfirm } from 'hooks/use-confirm';
import { useTranslate } from 'hooks/use-translate';
import { ButtonCancel, ButtonDelete, ButtonSave } from 'components/app-buttons';
import { DialogConfirm } from 'components/dialog-confirm';

interface Props {
  itemID: true | string;
  onClose: () => void;
  initData: Partial<FormModel>;
  onRefresh: () => void;
}

export const Editor = memo<Props>(({ onClose, itemID, initData, onRefresh }) => {
  const { t } = useTranslate();
  const mountedRef = useMountedRef();

  const entityID = adapterRtkItemID(itemID);
  const formMethods = useForm({
    defaultValues: schema.cast(
      { ...initData, dosageFormTotal: initData.dosageFormTotal || 0 },
      { stripUnknown: true, assert: false },
    ),
    resolver: yupResolver(schema),
  });
  const { reset, handleSubmit } = formMethods;

  const resultGet = useGetQuery(entityID || '', {
    skip: !entityID,
    refetchOnMountOrArgChange: true,
  });

  useEffectError(resultGet.error);
  useEffect(() => {
    if (!resultGet.data) return;

    reset(
      schema.cast(
        {
          ...initData,
          ...resultGet.data,
          dosageFormTotal: initData.dosageFormTotal || resultGet.data.dosageFormTotal || 0,
        },
        { stripUnknown: true, assert: false },
      ),
    );
  }, [reset, resultGet.data, initData]);

  const [triggerPost, resultPost] = usePostMutation();
  useEffectError(resultPost.error);

  const [triggerPatch, resultPatch] = usePatchMutation();
  useEffectError(resultPatch.error);

  const [triggerDelete, resultDelete] = useDeleteMutation();
  useEffectError(resultDelete.error);

  const onDelete = useCallback(async () => {
    if (!entityID) return;
    const res = await triggerDelete({ id: entityID });

    if (isMutationFulfilled(res)) onRefresh();
    if (!mountedRef.current) return;
    if (isMutationFulfilled(res)) onClose();
  }, [triggerDelete, entityID, onClose, onRefresh, mountedRef]);

  const stateConfirm = useConfirm({ onAccepted: onDelete });

  const isLoading =
    resultGet.isFetching || resultPost.isLoading || resultPatch.isLoading || resultDelete.isLoading;

  const onSubmit = useCallback(
    async (data: FormModel) => {
      const res = entityID
        ? await triggerPatch({ ...data, id: entityID })
        : await triggerPost({ ...data, dosageFormTotal: data.dosageFormTotal || 0 });

      if (isMutationFulfilled(res)) onRefresh();
      if (!mountedRef.current) return;
      if (isMutationFulfilled(res)) onClose();
    },
    [entityID, triggerPatch, triggerPost, onClose, onRefresh, mountedRef],
  );

  const isAllowToDeleteAnything = usePermissions('isAllowToDeleteAnything');

  const isAllowToDelete = !!(isAllowToDeleteAnything && entityID);

  const formTitle = useMemo(() => {
    if (resultGet.data) {
      return [resultGet.data.drugRegNum, resultGet.data.drugCatalogName]
        .filter(Boolean)
        .join(' - ');
    }
    if (!entityID) {
      return t('create-new');
    }
  }, [t, entityID, resultGet.data]);

  return (
    <>
      <Dialog open={true} onClose={onClose} fullWidth maxWidth={'xs'}>
        <DialogHeading isLoading={isLoading} title={formTitle} onClose={onClose} />
        <DialogContent>
          <FormProvider {...formMethods}>
            <Form isLoading={isLoading} />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          {isAllowToDelete && (
            <Box sx={{ mr: 'auto' }}>
              <ButtonDelete onClick={stateConfirm.onConfirm} disabled={isLoading} />
            </Box>
          )}
          <ButtonCancel isBack={!!entityID} disabled={isLoading} onClick={onClose} />
          <ButtonSave isCreate={!entityID} disabled={isLoading} onClick={handleSubmit(onSubmit)} />
        </DialogActions>
      </Dialog>
      {stateConfirm.isOpen && (
        <DialogConfirm
          title={t('confirm-delete')}
          onConfirm={stateConfirm.onAccept}
          onCancel={stateConfirm.onDecline}
        />
      )}
    </>
  );
});
