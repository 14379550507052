import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseErrorData } from 'utils/service';
import { selectPatientMakerBulk, selectPatientMakerDataSelected } from './selectors';
import { AppAsyncThunkConfig } from 'store/store';
import { actionPatientMakerItemSetStatus, PATIENT_ITEM_STATUS } from './slice';
import { ServiceMigrations } from 'services/migrations';
import { ServiceMediaPrivate } from 'services/media-private-services';

export const actionPatientMakerGetData = createAsyncThunk<string[], void, AppAsyncThunkConfig>(
  `PATIENT_MAKER/getData`,
  async (_, { getState }) => {
    try {
      const bulk = selectPatientMakerBulk(getState());
      const { data } = await ServiceMigrations.getPdfFiles({ bulk });
      return data;
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);

export const actionPatientMakerRun = createAsyncThunk<void, void, AppAsyncThunkConfig>(
  `PATIENT_MAKER/run`,
  async (_, { getState, dispatch }) => {
    try {
      const selectedItems = selectPatientMakerDataSelected(getState());

      await Promise.all(
        selectedItems.map(async (item) => {
          const {
            itemID,
            item: { url },
          } = item;
          try {
            // load source
            dispatch(
              actionPatientMakerItemSetStatus({ itemID, status: PATIENT_ITEM_STATUS.FETCHING }),
            );

            const { data } = await ServiceMediaPrivate.getFile(url);

            // generate the patient data
            dispatch(
              actionPatientMakerItemSetStatus({ itemID, status: PATIENT_ITEM_STATUS.GENERATING }),
            );

            const arrayBuffer = await data.arrayBuffer();
            const { data: patientData } = await ServiceMigrations.makePatientPdfData({
              file: new Uint8Array(arrayBuffer),
            });

            // migrate patient from pdf
            dispatch(
              actionPatientMakerItemSetStatus({ itemID, status: PATIENT_ITEM_STATUS.MIGRATING }),
            );
            const { data: patientID } = await ServiceMigrations.migratePatientFromPdf({
              file: url,
              data: patientData,
            });

            // end migrate patient from pdf
            dispatch(
              actionPatientMakerItemSetStatus({
                itemID,
                status: PATIENT_ITEM_STATUS.SUCCESS,
                patientID,
              }),
            );
          } catch (error: any) {
            dispatch(
              actionPatientMakerItemSetStatus({ itemID, status: PATIENT_ITEM_STATUS.ERROR, error }),
            );
          }
        }),
      );
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
