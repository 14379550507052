import { DynamicService } from 'utils/service';
import { API_CLINICAL_MEETING_TYPES, ClinicalMeetingType, MEETING_TYPE_KEY } from './models';
import { apiRtk } from 'utils/rtk-query';
import * as dynamic from 'utils/dynamic-helpers';

export * from './models';

class Service extends DynamicService<ClinicalMeetingType> {
  getByType = async (type: MEETING_TYPE_KEY) => {
    const response = await this.getAllDynamic({
      take: 1,
      filter: dynamic.mergeFilters(dynamic.createFilterEquals('meetingTypeKey', type)).join('&&'),
    });

    return DynamicService.transformResponseToItem(response);
  };
}

export const ServiceClinicalMeetingTypes = new Service({
  getAll: API_CLINICAL_MEETING_TYPES.GET_ALL_DYNAMIC,
  post: API_CLINICAL_MEETING_TYPES.POST,
  patch: API_CLINICAL_MEETING_TYPES.PATCH,
  delete: API_CLINICAL_MEETING_TYPES.DELETE,
});

export const apiClinicalMeetingTypes = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getClinicalMeetingTypeByType: builder.query<ClinicalMeetingType, MEETING_TYPE_KEY>({
      queryFn: async (type) => {
        try {
          const { data } = await ServiceClinicalMeetingTypes.getByType(type);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      keepUnusedDataFor: 10 * 60, // 10 minutes
    }),
  }),
});
