import React, { memo, useCallback } from 'react';
import { DialogHeading } from 'components/dialog-title';
import { Button, DialogActions, DialogContent } from '@material-ui/core';
import { DeleteActivity, useTitle } from '../../activity';
import { usePermissions } from 'hooks/use-permissions';
import { useTranslate } from 'hooks/use-translate';
import { PreviewResultLayout } from '../preview-result-layout';
import { PreviewSessionsBlock } from 'components/dialoog-user-patient-profile-session/components';
import { NotebookFormGeneratorStateResultMeeting } from '../notebook-form-generator-state-result-meeting';
import { ResultTopControls } from '../result-top-controls';
import { useNotebookFormGeneratorProps } from '../../hooks';
import style from './index.module.scss';
import { NotebookDialogTitle } from '../notebook-dialog-title';

interface Props {
  userPatientProfileSessionID: string;
  notebookLabelKey: string;
  isTLC: boolean;
  onClose: (e?: any) => void;
  onEdit: () => void;
  onDone: () => void;
  displayNextMeetingForm: boolean;
}
export const NotebookFormGeneratorStateResult = memo<Props>(
  ({
    userPatientProfileSessionID,
    onClose,
    onDone,
    onEdit,
    displayNextMeetingForm,
    notebookLabelKey,
    isTLC,
  }) => {
    const { userPatientProfileID, showDelete } = useNotebookFormGeneratorProps();
    const { t } = useTranslate();

    const { isCanDelete } = useTitle(userPatientProfileSessionID);

    const onCloseDeleteDialog = useCallback(
      (bool) => {
        if (bool) onClose();
      },
      [onClose],
    );

    const isAllowToSetupNextCall = usePermissions('isAllowToSetupNextCall');
    const isShowFormNextMeeting = Boolean(isAllowToSetupNextCall && displayNextMeetingForm);

    if (isShowFormNextMeeting) {
      return (
        <NotebookFormGeneratorStateResultMeeting
          userPatientProfileSessionID={userPatientProfileSessionID}
          onClose={onClose}
          onDone={onDone}
          onEdit={onEdit}
          notebookLabelKey={notebookLabelKey}
          isTLC={isTLC}
        />
      );
    }

    return (
      <>
        <DialogHeading
          TitleProps={{ style: { width: '100%' } }}
          title={
            <NotebookDialogTitle
              mainTitle={notebookLabelKey}
              userPatientProfileID={userPatientProfileID}
            />
          }
        >
          <ResultTopControls
            userPatientProfileID={userPatientProfileID}
            userPatientProfileSessionID={userPatientProfileSessionID}
          />
        </DialogHeading>
        <DialogContent>
          <PreviewResultLayout title={t('preview')}>
            <PreviewSessionsBlock userPatientProfileSessionID={userPatientProfileSessionID} />
          </PreviewResultLayout>
        </DialogContent>
        <DialogActions className={style.footer}>
          <DeleteActivity
            isCanDelete={isCanDelete && showDelete}
            formTitle={notebookLabelKey}
            userPatientProfileSessionID={userPatientProfileSessionID}
            onClose={onCloseDeleteDialog}
          />
          <div className={style.controls}>
            {showDelete && (
              <Button onClick={onClose} color={'primary'}>
                {t('cancel')}
              </Button>
            )}
            <Button onClick={onEdit} color="primary" variant="contained">
              {t('edit')}
            </Button>
            <Button onClick={onDone} color="primary" variant="contained">
              {t('done')}
            </Button>
          </div>
        </DialogActions>
      </>
    );
  },
);
