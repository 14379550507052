import React, { memo, MouseEvent, useState } from 'react';
import { Button, IconButton, Menu, MenuItem } from '@material-ui/core';
import { IDataGridControl } from '../model';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTranslate } from 'hooks/use-translate';
import { useDataGridTasks } from '../hooks';

const ControlsDropDown = ({
  id,
  data,
  controls,
}: {
  id: any;
  data: any;
  controls: null | string[] | IDataGridControl[];
}) => {
  const { onTasks } = useDataGridTasks();
  const { t } = useTranslate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // handlers
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // render
  return (
    <>
      <IconButton onClick={handleClick} className="data-grid-row-controls-drop-down">
        <MoreVertIcon aria-controls="row-controls" aria-haspopup="true" />
      </IconButton>
      <Menu
        id="row-controls"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        style={{
          transform: 'translateY(40px)',
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleClose}
      >
        {controls &&
          controls.map((control: any, index: number) => {
            switch (control.type) {
              case 'edit':
                return (
                  <MenuItem
                    key={control.type}
                    disabled={control.disabled}
                    className="btn-edit"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onTasks([['onHandle', { type: 'edit', id: id, data }]]);
                      handleClose();
                    }}
                  >
                    {t(control.title)}
                  </MenuItem>
                );
              case 'delete':
                return (
                  <MenuItem
                    key={control.type}
                    disabled={control.disabled}
                    className="btn-delete"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onTasks([['deleteData', data]]);
                      handleClose();
                    }}
                  >
                    {t(control.title)}
                  </MenuItem>
                );
              case 'custom':
                return (
                  <control.template
                    key={`${control.type}-${index}`}
                    onTask={onTasks}
                    control={control}
                    handleClose={handleClose}
                    id={id}
                    data={data}
                  />
                );
              default:
                return null;
            }
          })}
      </Menu>
    </>
  );
};

const ControlsDefault = ({
  id,
  data,
  controls,
}: {
  id: any;
  data: any;
  controls: null | string[] | IDataGridControl[];
}) => {
  const { onTasks } = useDataGridTasks();
  const { t } = useTranslate();
  return (
    <ul className="data-grid-row-controls">
      {controls &&
        controls.map((control: any) => {
          switch (control.type) {
            case 'edit':
              return (
                <li key={control.type}>
                  <Button
                    className="edit"
                    onClick={() => onTasks([['onHandle', { type: 'edit', id: id, data }]])}
                    disabled={control.disabled}
                    color={'primary'}
                    size={'small'}
                  >
                    {t(control.title)}
                  </Button>
                </li>
              );
            case 'delete':
              return (
                <li key={control.type}>
                  <Button
                    className="delete"
                    onClick={() => onTasks([['deleteData', data]])}
                    disabled={control.disabled}
                    color={'primary'}
                    size={'small'}
                  >
                    {t(control.title)}
                  </Button>
                </li>
              );
            case 'custom':
              return (
                <li key={control.type}>
                  <control.template onTasks={onTasks} control={control} id={id} data={data} />
                </li>
              );
            default:
              return null;
          }
        })}
    </ul>
  );
};

interface ControlsProps {
  controls: null | string[] | IDataGridControl[];
  controlsDropDown: null | string[] | IDataGridControl[];
  data: any;
  id: any;
  rowControls?: any;
}
const Controls = memo<ControlsProps>(({ controls, controlsDropDown, data, id, rowControls }) => {
  if (
    !rowControls &&
    (!controls || !controls.length) &&
    (!controlsDropDown || !controlsDropDown.length)
  )
    return null;

  return (
    <td className="data-grid-col-controls">
      <div className={'data-grid-col-controls__inner'}>
        {Boolean(rowControls) && rowControls(data)}
        {controls && controls.length > 0 && (
          <ControlsDefault id={id} data={data} controls={controls} />
        )}
        {controlsDropDown && controlsDropDown.length > 0 && (
          <ControlsDropDown id={id} data={data} controls={controlsDropDown} />
        )}
      </div>
    </td>
  );
});

export default Controls;
