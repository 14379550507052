import React, { useMemo } from 'react';
import { TabsWrapper, TabItem } from 'components/tabs-wrapper';
import { useValueQuery } from 'hooks/use-value-query';
import {
  GridActivitiesQuestionnaire,
  GridAllActivities,
  GridDietDiaries,
  GridFiles,
  GridMedicationsPrescriptions,
} from '../../grids';

import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';
import { ActivitiesMedicalInformation } from '../activities-medical-information';

enum TABS {
  ALL_ACTIVITIES = 'activities',
  QUESTIONNAIRE = 'questionnaire',
  MEDICAL_INFORMATION = 'info',
  ACTIVITIES_MEDICAL_INFORMATION = 'activities-info',
  MEDICAL_PRESCRIPTION = 'pres',
  DIET_DIARIES = 'diet-diaries',
  FILES = 'files',
}
const QUERY_NAME = 'tab-med-info';

interface Props {
  userPatientProfileID: string;
}

export const TabMedicalInformation: React.FC<Props> = ({ userPatientProfileID }) => {
  const { t } = useTranslate();
  const { value, onChange } = useValueQuery<TABS>({
    name: QUERY_NAME,
    init: TABS.ACTIVITIES_MEDICAL_INFORMATION,
    destroyOnUnmount: true,
  });
  const tabs = useMemo<TabItem<TABS>[]>(
    () => [
      {
        value: TABS.ACTIVITIES_MEDICAL_INFORMATION,
        title: t('medical-information'),
        element: <ActivitiesMedicalInformation userPatientProfileID={userPatientProfileID} />,
      },
      {
        value: TABS.ALL_ACTIVITIES,
        title: t('all-notebooks-activities'),
        element: <GridAllActivities userPatientProfileID={userPatientProfileID} />,
      },
      {
        value: TABS.QUESTIONNAIRE,
        title: t('questionnaire'),
        element: <GridActivitiesQuestionnaire userPatientProfileID={userPatientProfileID} />,
      },

      {
        value: TABS.MEDICAL_PRESCRIPTION,
        title: t('medications-prescriptions'),
        element: <GridMedicationsPrescriptions userPatientProfileID={userPatientProfileID} />,
      },
      {
        value: TABS.DIET_DIARIES,
        title: t('diet-diaries'),
        element: <GridDietDiaries userPatientProfileID={userPatientProfileID} />,
      },
      {
        value: TABS.FILES,
        title: t('files'),
        element: <GridFiles userPatientProfileID={userPatientProfileID} />,
      },
    ],
    [t, userPatientProfileID],
  );

  return (
    <TabsWrapper
      tabs={tabs}
      value={value}
      onChange={onChange}
      classes={{ content: style.content }}
      TabPanelProps={{
        style: {
          paddingTop: 0,
        },
      }}
    />
  );
};
