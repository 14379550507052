import React, {memo, useMemo} from "react";
import {KeyboardDateTimePicker, KeyboardDatePickerProps, KeyboardDatePicker} from "@material-ui/pickers";
import {isValid} from "date-fns";
import { useI18n } from '../../i18';

interface Props extends Omit<KeyboardDatePickerProps, 'onChange' | 'value' | 'error'>{
  onChange?: (event: { target: { value: Date | null}}) => void
  value?: any;
  error?: { message?: string };
  format?: string;
  showTodayButton?: boolean;
  ampm?: boolean;
}
export const DatePicker = memo<Props>((
  {
    value,
    error,
    format = 'dd/MM/yyyy HH:mm',
    showTodayButton = true,
    ampm = false,
    ...props
  }
) => {
  const { t } = useI18n();
  const value_ = useMemo(() => {
    if (value) {
      if (typeof value === 'string' && isValid(new Date(value))) {
        return new Date(value);
      } else if (value instanceof Date) {
        return value;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [value]);
  const isTimeAvailable = useMemo(() => {
    return /[hHm]/g.test(format);
  }, [format]);
  return <div className="date-field-wrapper">
    {isTimeAvailable &&
    <KeyboardDateTimePicker
      todayLabel={t('today')}
      okLabel={t('ok')}
      cancelLabel={t('cancel')}
      {...props}
      error={Boolean(error)}
      helperText={(error && error.message) ? t(error.message) || '' : props.helperText}
      showTodayButton={showTodayButton}
      ampm={ampm}
      value={value_}
      format={format}
      onChange={(value) => {
        props.onChange && props.onChange ({target: {value}});
      }}
      fullWidth
    />
    }
    {!isTimeAvailable &&
    <KeyboardDatePicker
      todayLabel={t('today')}
      okLabel={t('ok')}
      cancelLabel={t('cancel')}
      {...props}
      error={Boolean(error)}
      helperText={(error && error.message) ? t(error.message) || '' : props.helperText}
      showTodayButton={showTodayButton}
      value={value_}
      format={format}
      onChange={(value) => {
        props.onChange && props.onChange({ target: { value }});
      }}
      fullWidth
    />
    }
  </div>

});

export default DatePicker;
