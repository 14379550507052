import { ChangeLogConfig } from 'modules/change-log';
import { dateFormat } from 'utils/dates';

import { SupportMeeting, SupportMeetingForm, SupportMeetingInput } from './models';
import { APP_FORMAT_DATE, APP_FORMAT_DATE_TIME } from 'configs/const';
import { PatchPartial } from 'utils/types';

type ConfigUpdate = ChangeLogConfig<PatchPartial<SupportMeetingInput, 'id'>, SupportMeeting>;

export const logConfigUpdate: Record<
  keyof Omit<SupportMeetingForm, 'userPatientProfileID'>,
  ConfigUpdate
> = {
  supportMeetingTypeID: {
    select: 'new { supportMeetingType.title } as supportMeetingType',
    getValue: (res) => String(res.supportMeetingType?.title),
  },
  userEmployeeProfileID: {
    select: 'new { userEmployeeProfile.fullName } as userEmployeeProfile',
    getValue: (res) => String(res.userEmployeeProfile?.fullName),
  },
  date: {
    select: 'meetingFromDateTime',
    getValue: (_, { data }) => dateFormat(data.date, APP_FORMAT_DATE),
  },
  start: {
    select: 'meetingFromDateTime',
    getValue: (_, { data }) => data.start ?? '',
  },
  end: {
    select: 'meetingToDateTime',
    getValue: (_, { data }) => data.end ?? '',
  },
  remarks: {
    select: 'remarks',
    getValue: (res) => String(res.remarks ?? null),
  },
};

type ConfigPatch = ChangeLogConfig<
  PatchPartial<SupportMeeting, 'id' | 'userPatientProfileID'>,
  SupportMeeting
>;

export const logConfigPatch: Record<
  keyof Pick<
    SupportMeeting,
    | 'userEmployeeProfileID'
    | 'supportMeetingTypeID'
    | 'remarks'
    | 'meetingFromDateTime'
    | 'meetingToDateTime'
  >,
  ConfigPatch
> = {
  supportMeetingTypeID: {
    select: 'new { supportMeetingType.title } as supportMeetingType',
    getValue: (res) => String(res.supportMeetingType?.title),
  },
  userEmployeeProfileID: {
    select: 'new { userEmployeeProfile.fullName } as userEmployeeProfile',
    getValue: (res) => String(res.userEmployeeProfile?.fullName),
  },
  remarks: {
    select: 'remarks',
    getValue: (res) => String(res.remarks ?? null),
  },
  meetingFromDateTime: {
    select: 'meetingFromDateTime',
    getValue: (res) => dateFormat(res.meetingFromDateTime, APP_FORMAT_DATE_TIME),
  },
  meetingToDateTime: {
    select: 'meetingToDateTime',
    getValue: (res) => dateFormat(res.meetingToDateTime, APP_FORMAT_DATE_TIME),
  },
};
