import { FixServerObject } from 'utils/types';
import { DateValue, isDateLike } from 'utils/dates';
import { ActionKey } from '../todo-task-categories';
import * as yup from 'yup';
import { transformToDateISO } from 'utils/transformers';
import { InferType } from 'yup';

const API_ENDPOINT = 'ToDoTasks';

export const API_TODO_TASKS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<Components.Schemas.ToDoTask, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<Components.Schemas.ToDoTask, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export const schemaTodoTaskCreate = yup.object({
  toDoTaskCategoryID: yup.string().required('rule-required').default(''),
  userPatientProfileID: yup.string().required('rule-required').default(''),
  sentByUserEmployeeProfileID: yup.string().required('rule-required').default(''),
  entryDate: yup
    .string()
    .required('rule-required')
    .test('date', 'rule-date', isDateLike)
    .transform(transformToDateISO)
    .default(''),
  message: yup.string().required('rule-required').nullable().default(''),
  userEmployeeProfileIDs: yup
    .array()
    .of(yup.string().required('rule-required'))
    .min(0, 'rule-min')
    .default([]),
  userPatientProfileSessionID: yup.string().notRequired().nullable().default(null),
});
export const schemaTodoTaskUpdate = schemaTodoTaskCreate.omit([
  'userPatientProfileID',
  'sentByUserEmployeeProfileID',
]);
export interface TodoTask extends FixServerObject<Components.Schemas.ToDoTask, 'id'> {}

export interface ToDoTaskCreateInput extends InferType<typeof schemaTodoTaskCreate> {
  userEmployeeProfileIDs: string[];
}
export interface ToDoTaskUpdateInput extends InferType<typeof schemaTodoTaskUpdate> {
  id: string;
}
export interface ToDoTaskDoneInput extends Pick<TodoTask, 'id' | 'done'> {
  employeeProfileID: string;
}

export interface ToDoTaskGetActivitiesInput {
  userPatientProfileID: string;
  dateRange: DateValue[];
}

export interface ToDoTaskGetActivitiesItem
  extends Pick<TodoTask, 'id' | 'message' | 'details' | 'entryDate' | 'done'> {
  employee: Required<TodoTask>['sentByUserEmployeeProfile']['fullName'];
  activities: number;
}

type TEmployee = { appIdentityUserID: string; fullName: string; userPhoto: string | null };
export interface ToDoTaskDetailsForPreview
  extends Pick<TodoTask, 'id' | 'entryDate' | 'closeDate' | 'done' | 'message'> {
  createdDate: string;
  sendBy: TEmployee;
  closeEmployee: null | TEmployee;
  assignedTo: TEmployee[];
}

export interface ToDoTaskDetailsForNotification
  extends Pick<
    TodoTask,
    | 'id'
    | 'rowIndex'
    | 'message'
    | 'userPatientProfileID'
    | 'sentByUserEmployeeProfileID'
    | 'entryDate'
  > {
  patient: { fullName: string };
  category: { title: string; actionKey: ActionKey };
  sendBy: { fullName: string };
  userEmployeeProfileIDs: string[];
}

export interface TodoTaskPatientLatest extends Pick<TodoTask, 'id'> {
  createdDate: string;
  actionKey: ActionKey;
}
