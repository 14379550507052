import { useTranslation } from 'react-i18next';
import {useMemo} from "react";
import {AppTranslator} from "modules/i18n";
export const useI18n = () => {
  const { t } = useTranslation();

  const translator = useMemo(() => {
    return new AppTranslator({ t })
  }, [t])

  return { t: translator.t, tp: translator.tp }
};

