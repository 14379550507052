import { Box } from '@material-ui/core';
import React, { memo } from 'react';
import { useI18n } from 'AurionCR/components';
import style from './index.module.scss';

export interface IUserDetailsItem {
  id: string;
  title: string;
  fullName?: string;
  isActive: boolean;
}

const InactiveLabel = memo(() => {
  const { t } = useI18n();
  return <Box className={style.root}>{t('inactive')}</Box>;
});

const renderUserDetailsOption = (item: IUserDetailsItem) => (
  <Box display={'flex'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
    <Box paddingRight={1}>{item.title || item.fullName}</Box>
    {!item.isActive && <InactiveLabel />}
  </Box>
);

export default renderUserDetailsOption;
