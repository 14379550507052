import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { debounce, round } from 'lodash-es';
import { fabric } from 'fabric';
import { useAppDispatch, useAppSelector } from 'store';
import { PDFDocumentProxy } from 'modules/pdfjs';
import { IEvent } from 'fabric/fabric-impl';
import { FormDocumentFieldType, FormDocumentInput } from 'services/form-document-inputs';
import {
  pdfFormDocumentMerge,
  pdfFormDocumentPageInputAdd,
  pdfFormDocumentPageInputModify,
  pdfFormDocumentPageInputRemove,
  pdfFormDocumentScrollToInput,
  pdfFormDocumentSetPageSize,
} from '../../store';
import style from './index.module.scss';

export const FabricDefaultRectProps: Partial<fabric.Rect> = {
  top: 100,
  left: 100,
  width: 100,
  height: 100,
  fill: 'rgba(255,255,0,.35)',
  stroke: '#000',
  strokeWidth: 1,
  strokeUniform: true,

  lockRotation: true,
  lockScalingFlip: true,
  transparentCorners: false,
  cornerColor: 'red',
  cornerStrokeColor: 'red',
  borderColor: 'red',
  cornerSize: 7,
  padding: 5,
  borderDashArray: [3, 3],
};

export const FabricDefaultDisabledRectProps = {
  mt: false,
  mb: false,
  ml: false,
  mr: false,
  bl: false,
  br: false,
  tl: false,
  tr: false,
  mtr: false,
};

export const PdfCanvas = memo(
  ({ pdf, pageNumber = 0 }: { pdf: PDFDocumentProxy; pageNumber: number }) => {
    const dispatch = useAppDispatch();
    //state
    const {
      scale,
      eventAdd,
      eventRemove,
      eventSelectFabricItem,
      eventUpdateFabricPage,
      pages: { [pageNumber]: pageContent },
    } = useAppSelector((state) => state.pdfFormDocument);
    const canvasPDFRef = useRef<any>();
    const canvasFabricRef = useRef<any>();
    const [init, setInit] = useState(false);
    const [Fabric, setFabric] = useState<fabric.Canvas | null>(null);
    // methods
    const addReact = useCallback(
      (item: FormDocumentInput): fabric.Rect | null => {
        if (Fabric) {
          const { width, height, coordX: left, coordY: top, id, formDocumentFieldType } = item;
          const rect = new fabric.Rect({
            ...FabricDefaultRectProps,
            width,
            height,
            top,
            left,
            // @ts-ignore
            _id: id,
            // @ts-ignore
            _pageNumber: pageNumber,
          });
          if (formDocumentFieldType === FormDocumentFieldType.checkbox) {
            rect.setControlsVisibility(FabricDefaultDisabledRectProps);
          } else {
            rect.setControlVisible('mtr', false);
          }
          Fabric.add(rect);
          return rect;
        } else {
          return null;
        }
      },
      [Fabric, pageNumber],
    );
    // handlers
    const onFabricModify = useCallback(
      debounce(({ target }: IEvent) => {
        if (target) {
          // @ts-ignore
          let { _id: id, _pageNumber: pageNumber } = target;
          const { top, left, width, height } = target.getBoundingRect(true);
          dispatch(
            pdfFormDocumentPageInputModify({
              id,
              pageNumber,
              width: width - 1,
              height: height - 1,
              coordY: top,
              coordX: left,
            }),
          );
        }
      }, 300),
      [dispatch],
    );
    const onFabricSelect = useCallback(
      (e) => {
        // check if mouse event
        if (e?.e) {
          const target = e ? e?.selected[0] : null;
          if (target) {
            let { _id: id, _pageNumber: pageNumber } = target;
            dispatch(pdfFormDocumentMerge({ eventSelectFabricItem: { id, pageNumber } }));
          }
        }
      },
      [dispatch],
    );
    const onFabricUnSelect = useCallback(
      (e) => {
        // check if mouse event
        if (e?.e) {
          dispatch(pdfFormDocumentMerge({ eventSelectFabricItem: null }));
        }
      },
      [dispatch],
    );
    // eventAdd
    useEffect(() => {
      if (eventAdd && eventAdd.pageNumber === pageNumber && Fabric) {
        addReact(eventAdd);
        dispatch(pdfFormDocumentPageInputAdd(eventAdd));
        dispatch(pdfFormDocumentScrollToInput({ pageNumber, index: eventAdd._index }));
      }
    }, [eventAdd, dispatch, pageNumber, Fabric, addReact]);
    // eventRemove
    useEffect(() => {
      if (eventRemove && eventRemove.pageNumber === pageNumber && Fabric) {
        Fabric.getObjects().forEach((item) => {
          // @ts-ignore
          if (item._id === eventRemove.id) {
            Fabric.remove(item);
            dispatch(pdfFormDocumentPageInputRemove(eventRemove));
          }
        });
      }
    }, [eventRemove, dispatch, pageNumber, Fabric]);
    // eventSelectFabricItem
    useEffect(() => {
      if (eventSelectFabricItem && Fabric) {
        if (eventSelectFabricItem.pageNumber === pageNumber) {
          Fabric.getObjects().forEach((item) => {
            // @ts-ignore
            if (item._id === eventSelectFabricItem.id) {
              Fabric.setActiveObject(item);
              Fabric.requestRenderAll();
            }
          });
        } else {
          Fabric.discardActiveObject();
          Fabric.requestRenderAll();
        }
      }
    }, [eventSelectFabricItem, dispatch, pageNumber, Fabric]);
    useEffect(() => {
      if (Fabric && init && eventUpdateFabricPage === pageNumber) {
        dispatch(pdfFormDocumentMerge({ eventUpdateFabricPage: null }));
        Fabric.clear();
        pageContent.inputs.forEach((item) => {
          addReact(item);
        });
      }
    }, [pageContent, addReact, Fabric, init, dispatch, eventUpdateFabricPage, pageNumber]);
    // init
    useEffect(() => {
      if (Fabric && !init) {
        setInit(true);
        // events
        Fabric.on('object:modified', onFabricModify);
        Fabric.on('object:moving', onFabricModify);
        Fabric.on('selection:created', onFabricSelect);
        Fabric.on('selection:updated', onFabricSelect);
        Fabric.on('selection:cleared', onFabricUnSelect);
        // render init data
        pageContent.inputs.forEach((item) => {
          addReact(item);
        });
      }
      return () => {
        if (Fabric && init) {
          Fabric.off('object:modified', onFabricModify);
          Fabric.off('object:moving', onFabricModify);
          Fabric.off('selection:created', onFabricSelect);
          Fabric.off('selection:updated', onFabricSelect);
          Fabric.off('selection:cleared', onFabricUnSelect);
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Fabric, onFabricModify, onFabricSelect, init, setInit, addReact]);
    // render page
    useEffect(() => {
      if (canvasPDFRef.current && canvasFabricRef.current && pdf) {
        const canvasPDF = canvasPDFRef.current;
        const canvasFabric = canvasFabricRef.current;
        pdf.getPage(pageNumber + 1).then((PdfPage) => {
          const viewport = PdfPage.getViewport({ scale });
          const { width, height } = viewport;
          canvasFabric.width = canvasPDF.width = width;
          canvasFabric.height = canvasPDF.height = height;
          PdfPage.render({ canvasContext: canvasPDF.getContext('2d'), viewport: viewport });
          // create fabric
          setFabric(new fabric.Canvas(canvasFabric));
          // update pdf minWidth
          dispatch(
            pdfFormDocumentSetPageSize({
              index: pageNumber,
              width: round(width),
              height: round(height),
            }),
          );
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pdf, canvasPDFRef, canvasFabricRef, setFabric, dispatch]);
    //render
    return (
      <>
        <canvas ref={canvasPDFRef} className={style.pdf} dir={'ltr'} />
        <canvas ref={canvasFabricRef} className={style.fabric} />
      </>
    );
  },
);

export default PdfCanvas;
