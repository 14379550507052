import React, { memo, useCallback, useMemo, useState } from 'react';
import GridConfig from './grid-config';
import DataGrid from 'components/data-grid';
import { SectionPaper } from '../../../section-papper';
import { useTranslate } from 'hooks/use-translate';
import { Typography } from '@material-ui/core';
import { Stack } from 'components/stack';
import { ButtonFillHealthSurvey } from 'components/dialog-patient';
import { UserPatientProfile } from 'services/user-patient-profile';

interface NoQuestionnaireProps {
  patientID: string;
  patient: Pick<UserPatientProfile, 'firstName' | 'lastName' | 'mobilePhone'>;
}
const NoQuestionnaire: React.FC<NoQuestionnaireProps> = ({ patientID, patient }) => {
  const { t } = useTranslate();

  return (
    <Stack
      justifyContent={'center'}
      alignItems={'center'}
      flexGrow={1}
      textAlign={'center'}
      direction={'column'}
      spacing={1}
    >
      <Typography variant={'h2'} color={'primary'}>
        {t('there-is-no-health-survey')}
      </Typography>
      <ButtonFillHealthSurvey
        userPatientProfileID={patientID}
        toPhoneNumber={patient.mobilePhone}
        patient={patient}
      />
    </Stack>
  );
};

interface Props {
  className: string;
  patientID: string | null | undefined;
  patient: Pick<UserPatientProfile, 'firstName' | 'lastName' | 'mobilePhone'> | undefined;
}
export const DraftQuestionnaire = memo<Props>(({ patientID, className, patient }) => {
  const { t } = useTranslate();
  const [count, setCount] = useState<null | number>(null);

  const gridConfig = useMemo<any>(() => (patientID ? GridConfig(patientID) : null), [patientID]);

  const onHandle = useCallback((action) => {
    if (action.type === 'getDone') {
      setCount(action.data?.count || action.data?.value?.length || 0);
    }
  }, []);

  const isEmpty = count === 0;

  return (
    <SectionPaper title={t('questionnaire')} className={className}>
      {gridConfig ? <DataGrid config={gridConfig} onHandle={onHandle} visible={!isEmpty} /> : null}
      {isEmpty && patientID && patient && (
        <NoQuestionnaire patientID={patientID} patient={patient} />
      )}
    </SectionPaper>
  );
});
