import { Action } from 'redux';
import { put } from 'typed-redux-saga';
import { parseErrorData } from 'utils/service';
import i18n from '../modules/i18n';
import { isLabelLike } from './regexp';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';

interface ThunkAction<E extends { message: string }> extends Action<string> {
  error: E;
}
export function* workerErrorNotifyThunk<T extends ThunkAction<Error>>(data: T) {
  if (data.error) {
    yield* notifyErrorSaga(data.error);
  }
}

interface SagaAction<E extends { message: string }> extends Action<any> {
  payload: { error: E };
}
export function* workerErrorNotifySaga<T extends SagaAction<Error>>(action: T) {
  if (action.payload.error) {
    yield* notifyErrorSaga(action.payload.error);
  }
}

export function* notifyErrorSaga(e: { message: string }) {
  const error = parseErrorData(e);
  let message = error.message;
  if (isLabelLike(message)) {
    message = i18n.t(message);
  }

  yield* put(notifyRequestResult(message, 'error'));
}
