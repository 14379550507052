import { API_USER_PATIENT_PROFILE_SUBSCRIPTION_ACTIVITIES } from 'services/user-patient-profile-subscription-activities';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { API_USER_EMPLOYEE_PROFILES } from 'services/user-employee-profiles';
import { permissionKey } from 'services/user-employee-profile-permissions';
import { ControlDelete } from '../control-delete';
import { ControlEdit } from '../control-edit';

interface Props {
  userPatientProfileSubscriptionID: string;
}

export const GridConfig = ({ userPatientProfileSubscriptionID }: Props) => ({
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_SUBSCRIPTION_ACTIVITIES.GET_ALL_DYNAMIC,
      filters: `userPatientProfileSubscriptionID=="${userPatientProfileSubscriptionID}"`,
      selected: 'isManually',
    },
    create: {
      url: API_USER_PATIENT_PROFILE_SUBSCRIPTION_ACTIVITIES.POST,
    },
    patch: {
      url: API_USER_PATIENT_PROFILE_SUBSCRIPTION_ACTIVITIES.PATCH,
    },
    delete: {
      url: API_USER_PATIENT_PROFILE_SUBSCRIPTION_ACTIVITIES.DELETE,
      field: 'remarks',
    },
    sortable: {
      field: 'entryDate',
      order: 'desc',
    },
  },
  controlsDropDown: [
    {
      type: 'custom',
      template: ControlEdit,
    },
    {
      type: 'custom',
      template: ControlDelete,
      permission: permissionKey('isAllowToDeleteAnything'),
    },
  ],
  toolbar: {
    controlsDefault: { refresh: null, new: { title: 'add-new' } },
    controls: { new: { title: 'add-new' } },
    filters: [
      {
        field: 'entryDate',
        options: {
          type: 'date-multiple',
          placeholder: 'select-date',
          style: { minWidth: '21rem' },
        },
      },
      {
        field: 'userEmployeeProfileID',
        options: {
          type: 'select',
          label: 'employee',
          source: `${API_USER_EMPLOYEE_PROFILES.GET_ALL_DYNAMIC}?Select=appIdentityUserID as id,fullName as title&orderBy=firstName,lastName`,
          style: { minWidth: '16rem' },
        },
      },
    ],
  },
  columns: [
    {
      title: 'entry-date',
      field: 'entryDate',
      template: {
        type: 'date',
        format: APP_FORMAT_DATE_TIME,
      },
    },
    {
      title: 'employee',
      field: 'userEmployeeProfile.fullName as userEmployeeProfileFullName',
      sortable: 'userEmployeeProfile.firstName',
    },
    {
      title: 'remarks',
      field: 'remarks',
    },
  ],
});
