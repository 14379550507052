import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';
import { PERMISSION_IDS } from 'services/user-employee-profile-permissions';
import { selectAuthPermissions } from '../auth';

const selectState = (state: RootState) => state.notebookFormGenerator;

export const selectNotebookFormGeneratorStatuses = createSelector(
  selectState,
  ({ isLoading, isInit, state }) => {
    return { isLoading, isInit, state };
  },
);
export const selectNotebookFormGeneratorNextSupportMeetingData = createSelector(
  selectState,
  ({ nextSupportMeeting }) => {
    return nextSupportMeeting;
  },
);

export const selectNotebookFormGeneratorReplaceSupportMeetingData = createSelector(
  selectState,
  ({ replaceSupportMeeting }) => {
    return replaceSupportMeeting;
  },
);

export const selectNotebookFormGeneratorNotebookSettings = createSelector(
  selectState,
  ({ notebookSettings }) => {
    return notebookSettings;
  },
);
export const selectNotebookFormGeneratorIsAllowAddPrescription = createSelector(
  selectNotebookFormGeneratorNotebookSettings,
  (notebookSettings) => {
    return notebookSettings
      ? notebookSettings.userEmployeeProfilePermissionIDs.some(
          (userEmployeeProfilePermissionID) =>
            userEmployeeProfilePermissionID === PERMISSION_IDS.DOCTOR,
        )
      : false;
  },
);
export const selectNotebookFormGeneratorFieldInputs = createSelector(
  selectNotebookFormGeneratorNotebookSettings,
  (notebookSettings) => {
    if (!notebookSettings) return [];
    return notebookSettings.notebookFieldInputs;
  },
);

export const selectNotebookFormGeneratorSession = createSelector(
  selectState,
  ({ userPatientProfileSessionID }) => {
    if (!userPatientProfileSessionID) return;
    return { id: userPatientProfileSessionID };
  },
);

export const selectNotebookFormGeneratorShouldClone = createSelector(
  selectNotebookFormGeneratorSession,
  selectAuthPermissions,
  selectNotebookFormGeneratorNotebookSettings,
  (userPatientProfileSessionID, permissions, settings) => {
    if (userPatientProfileSessionID) return false;

    const res = [permissions?.rolePatientManager, settings?.displayClinicDrugSection].every(
      Boolean,
    );

    return res;
  },
);
export const selectNotebookFormGeneratorIsShowCloneTooltip = createSelector(
  selectState,
  selectNotebookFormGeneratorShouldClone,
  ({ showCloneTooltip }, isShouldClone) => {
    return [isShouldClone, showCloneTooltip].every(Boolean);
  },
);
