import { DynamicService } from 'utils/service';

import {
  API_USER_EMPLOYEE_PROFILE_WEB_PUSH_NOTIFICATIONS,
  UserEmployeeProfileWebPushNotification,
} from './models';

export * from './models';

class Service extends DynamicService<UserEmployeeProfileWebPushNotification> {}

export const ServiceUserEmployeeProfileWebPushNotifications = new Service({
  mainField: 'id',
  getAll: API_USER_EMPLOYEE_PROFILE_WEB_PUSH_NOTIFICATIONS.GET_ALL_DYNAMIC,
  post: API_USER_EMPLOYEE_PROFILE_WEB_PUSH_NOTIFICATIONS.POST,
  delete: API_USER_EMPLOYEE_PROFILE_WEB_PUSH_NOTIFICATIONS.DELETE,
  patch: API_USER_EMPLOYEE_PROFILE_WEB_PUSH_NOTIFICATIONS.PATCH,
});
