import React, { useCallback } from 'react';
import { useCurrentUser } from 'components/hooks';
import { GridModel } from '../../models';
import { EditorString } from 'components/data-grid-light-editors/editor-string';
import { IDataGridLightEditorProps } from 'components/data-grid-light';

interface Props extends IDataGridLightEditorProps<GridModel, 'reply'> {}
export const EditorReply: React.FC<Props> = ({ row, field, onClose, onSubmit }) => {
  const { appUserID } = useCurrentUser();

  const handleSubmit = useCallback<typeof onSubmit>(
    (formData) => {
      onSubmit({
        ...formData,
        isViewed: true,
        replyDate: new Date().toISOString(),
        userEmployeeProfileID: appUserID,
      });
    },
    [onSubmit, appUserID],
  );
  return <EditorString row={row} field={field} onClose={onClose} onSubmit={handleSubmit} />;
};
