import React, { memo, useCallback, useMemo } from 'react';
import GridConfig, { GridModel } from './grid-config';
import DataGrid from 'components/data-grid';
import style from './index.module.scss';
import { IDataGridRowProps } from '../../../data-grid/model';
import clsx from 'clsx';

interface Props {
  userPatientProfileID: string;
}
export const GridPreviewClinicalDrugs = memo<Props>(({ userPatientProfileID }) => {
  const gridConfig = useMemo<any>(
    () => GridConfig({ userPatientProfileID }),
    [userPatientProfileID],
  );
  const rowProps = useCallback<IDataGridRowProps<GridModel>>((option) => {
    return {
      className: clsx({
        [style.isAuto]: option.row.isAutoGenerated,
      }),
    };
  }, []);

  return <DataGrid className={style.grid} config={gridConfig} rowProps={rowProps} />;
});
