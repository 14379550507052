import { DefaultGridButtonExportToExcel } from 'components/data-grid/model';
import TemplateDownloadPrivate from 'components/templates/template-download-private';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import React from 'react';
import { permissionKey } from 'services/user-employee-profile-permissions';
import {
  API_USER_PATIENT_PROFILE_DOCUMENTS,
  UserPatientProfileDocument,
} from 'services/user-patient-profile-documents';
import { excelTemplateDate } from 'utils/dates';
import { ControlSendToPatient } from './components/control-send-to-patient';
import { ControlSendToPharmacy } from './components/control-send-to-pharmacy';
import { ControlSend29CopyToPatient } from './components/control-send-29-copy-to-patient';
import { TemplateDate } from 'components/templates/template-date';

export default (userPatientProfileID: string): any => ({
  id: 'grid_user-patient-profile-documents',
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_DOCUMENTS.GET_ALL_DYNAMIC,
      filters: `userPatientProfileID=="${userPatientProfileID}"`,
      selected: 'isCopy',
    },
    create: {
      url: API_USER_PATIENT_PROFILE_DOCUMENTS.POST,
    },
    patch: {
      url: API_USER_PATIENT_PROFILE_DOCUMENTS.PATCH,
    },
    delete: {
      url: API_USER_PATIENT_PROFILE_DOCUMENTS.DELETE,
    },
    sortable: {
      field: 'createdDate',
      order: 'desc',
    },
  },
  controlsDropDown: [
    {
      type: 'custom',
      template: ControlSendToPatient,
      permission: permissionKey('isAllowToSendDocumentToPatient'),
    },
    {
      type: 'custom',
      template: ControlSend29CopyToPatient,
      permission: permissionKey('isAllowToSendPrescriptionCopyToPatient'),
    },
    {
      type: 'custom',
      template: ControlSendToPharmacy,
      permission: permissionKey('isAllowToSendPrescriptionToPharmacy'),
    },
    {
      type: 'delete',
      title: 'delete',
      permission: permissionKey('isAllowToCreateAndRenewPrescriptions'),
    },
  ],
  toolbar: {
    title: '',
    excelTitle: 'documents',
    controls: null,
    controlsDefault: {
      new: false,
      resetFilters: null,
      export: DefaultGridButtonExportToExcel,
    },
  },
  columns: [
    {
      title: 'date',
      field: 'createdDate',
      tdClasses: 'mobile-no-wrap',
      template: (data: UserPatientProfileDocument) => <TemplateDate date={data.createdDate} />,
      valueTemplate: (data: UserPatientProfileDocument) =>
        excelTemplateDate(data.createdDate, APP_FORMAT_DATE_TIME),
    },
    {
      title: 'document-type',
      field: 'documentType.title as documentType',
      sortable: 'documentType.title',
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'file-name',
      field: 'fileName',
    },
    {
      title: 'employee-name',
      field: 'userEmployeeProfile.fullName as employeeName',
      sortable: 'userEmployeeProfile.firstName',
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'generate-29-gimel',
      field: 'isForm29',
      template: 'boolean',
    },
    {
      title: 'download-doc',
      field: 'documentURL',
      sortable: false,
      template: (data: UserPatientProfileDocument) => (
        <TemplateDownloadPrivate documentURL={data.documentURL} />
      ),
    },
    {
      title: 'download-doc-for-print',
      field: 'documentForPrintURL',
      sortable: false,
      template: (data: UserPatientProfileDocument) => (
        <TemplateDownloadPrivate documentURL={data.documentForPrintURL} />
      ),
    },
  ],
});
