import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Button, Divider } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { dateFormat } from 'utils/dates';
import { SectionPaper } from '../../../section-papper';
import { MedicalPrescriptionLastDate } from 'components/medical-prescription';
import { RowField } from 'components/row-field';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffectError } from 'hooks';
import { usePatientSubscription } from 'components/dialog-patient/hooks';
import { apiUserPatientProfile, getPartPatientSchema } from 'services/user-patient-profile';
import { Stack } from 'components/stack';
import { ButtonFieldMember, ProviderPatientData } from 'components/dialog-patient';
import { getAge } from 'utils/dates';
import { yupResolver } from '@hookform/resolvers/yup';
import { GeneralForm } from '../general-form';
import { schemaPatientForm } from '../../../../helpers';
import { InferType } from 'yup';
import style from './index.module.scss';

export type DraftPatient = Pick<
  Components.Schemas.UserPatientProfile,
  'fullName' | 'firstName' | 'lastName' | 'mobilePhone'
>;

export type DraftPrescription = Pick<
  Components.Schemas.UserPatientProfilePrescription,
  'entryDate' | 'remarks'
>;

export type DraftEmployee = Pick<Components.Schemas.UserEmployeeProfile, 'fullName' | 'userPhoto'>;

const useFetchQuery = apiUserPatientProfile.useGetPatientForGeneralFormQuery;
const usePatchMutation = apiUserPatientProfile.usePatchPatientWithLogMutation;

interface Props {
  className: string;
  prescriptionID: string;
  prescription: DraftPrescription | undefined;
  patientID: string | null | undefined;
  doctor: DraftEmployee | null | undefined;
  onRefresh: () => void;
}

export const DraftGeneral: React.FC<Props> = ({
  patientID,
  prescription,
  doctor,
  className,
  onRefresh,
}) => {
  const { data: subscription } = usePatientSubscription(patientID || '');
  const showDietitian = Boolean(subscription && subscription.subscription.visitDietitian);

  const schema = useMemo(() => {
    return schemaPatientForm.concat(
      getPartPatientSchema({ userPatientProfileID: patientID ?? null, showDietitian }),
    );
  }, [patientID, showDietitian]);

  const { data: patient } = useFetchQuery(patientID || '', {
    skip: !patientID,
  });

  const { t, tp } = useTranslate();
  const methods = useForm({
    defaultValues: schema.cast({ ...patient }, { stripUnknown: true, assert: false }),
    resolver: yupResolver(schema),
  });
  const {
    reset,
    handleSubmit,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    if (patient) {
      reset(schema.cast(patient, { stripUnknown: true, assert: false }));
    }
  }, [reset, patient, schema]);

  const [patch, { error, isLoading }] = usePatchMutation();
  useEffectError(error);
  const onSubmit = useCallback(
    async (data: InferType<typeof schemaPatientForm>) => {
      if (!patientID) return;
      if (!patient) return;

      await patch({
        initData: patient,
        formData: {
          ...data,
          appIdentityUserID: patientID,
        },
        remark: tp('updates-by-employee'),
      });

      onRefresh();
    },
    [patientID, patch, onRefresh, tp, patient],
  );

  const rowFieldClasses = useMemo(() => {
    return { root: style.row, label: style.rowLabel, value: style.rowValue };
  }, []);

  const age = getAge(patient?.dateOfBirth);

  return (
    <ProviderPatientData patient={patient} onUpdate={undefined}>
      <FormProvider {...methods}>
        <SectionPaper
          title={t('general')}
          action={
            <Button
              variant={'contained'}
              color={'primary'}
              size={'small'}
              disabled={!isDirty || isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {t('save')}
            </Button>
          }
          className={className}
        >
          <div className={style.data}>
            <RowField classes={rowFieldClasses} label={t('entry-date')}>
              {dateFormat(prescription?.entryDate)}
            </RowField>
            <RowField classes={rowFieldClasses} label={t('doctor')}>
              {doctor?.fullName}
            </RowField>
            <RowField classes={rowFieldClasses} label={t('age')}>
              <Stack spacing={1} alignItems={'center'}>
                <div style={{ flexGrow: 1 }}>{age || 0}</div>
                {age !== null && age < 18 && (
                  <div>
                    <ButtonFieldMember size={'small'} debounce={false} />
                  </div>
                )}
              </Stack>
            </RowField>
            <RowField classes={rowFieldClasses} label={t('prescription-remarks')}>
              {prescription?.remarks}
            </RowField>
          </div>
          <Divider className={style.divider} />
          <GeneralForm isLoading={isLoading} showDietitian={showDietitian} schema={schema} />
          <Box mt={'auto'} pt={1}>
            {patientID && (
              <MedicalPrescriptionLastDate
                userPatientProfileID={patientID}
                userPatientProfilePrescriptionID={null}
              />
            )}
          </Box>
        </SectionPaper>
      </FormProvider>
    </ProviderPatientData>
  );
};
