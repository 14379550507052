import { useMemo } from 'react';
import { useTranslate } from './use-translate';
import { getPatientColor } from 'services/user-patient-profile';

export enum PATIENT_TAGS {
  InActive = 'inActive',
  OnHold = 'OnHold',
  TLC = 'tlc',
  DoNotProlongTreatment = 'DoNotProlongTreatment',
  SlowIncreaseWeight = 'SlowIncreaseWeight',
  ChangeDosageByDoctorApproval = 'ChangeDosageByDoctorApproval',
  Teenager = 'age',
  ExtraAttention = 'ExtraAttention',
}

export const usePatientTags = () => {
  const { t } = useTranslate();
  const data = useMemo(() => {
    return [
      {
        id: PATIENT_TAGS.InActive,
        title: t('ia'),
        color: getPatientColor({ isActive: false }),
      },
      {
        id: PATIENT_TAGS.OnHold,
        title: t('oh'),
        color: getPatientColor({ onHold: true }),
      },
      { id: PATIENT_TAGS.TLC, title: t('tlc'), color: getPatientColor({ isTLC: true }) },
      {
        id: PATIENT_TAGS.DoNotProlongTreatment,
        title: t('dnpt'),
        color: getPatientColor({ doNotProlongTreatment: true }),
      },
      {
        id: PATIENT_TAGS.SlowIncreaseWeight,
        title: t('siw'),
        color: getPatientColor({ slowIncreaseWeight: true }),
      },
      {
        id: PATIENT_TAGS.ChangeDosageByDoctorApproval,
        title: t('cdbda'),
        color: getPatientColor({ changeDosageByDoctorApproval: true }),
      },
      {
        id: PATIENT_TAGS.Teenager,
        title: t('t'),
        color: getPatientColor({ isTeenager: true }),
      },
      {
        id: PATIENT_TAGS.ExtraAttention,
        title: t('alrt'),
        color: getPatientColor({ extraAttention: true }),
      },
    ];
  }, [t]);

  return { data };
};
