import React, { memo, useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash-es';
import { ClickAwayListener, Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import MenuIconOpen from '@material-ui/icons/MenuOpen';
import { useAuth } from 'AurionCR/components';
import { ReactComponent as LogoIcon } from 'images/logo_icon.svg';
import { ReactComponent as LogoTitle } from 'images/logo_title.svg';
import { MainNav, OnMenuOpen } from './navigations';
import { ContentLayout } from './content-layout';
import { MenuLanguages } from './navigations/menu-languages';

import './_style.scss';
import { PoweredBy } from 'components/layout/navigations/powered-by';
import { useTranslate } from 'hooks/use-translate';

const Layout = memo(({ children }) => {
  const { t } = useTranslate();
  const { user } = useAuth();
  const appUserID = user ? user.appUserID : null;

  // state
  const [isInit, setIsInit] = useState(false);
  const [open, setOpen] = useState(true);
  // handlers
  const onMenu = useCallback(
    (bool: boolean) => {
      setOpen(bool);
      if (appUserID) localStorage.setItem(`menu_user-${appUserID}`, JSON.stringify({ open: bool }));
    },
    [setOpen, appUserID],
  );
  const onMenuOpen: OnMenuOpen = ({ ref, route, level }) => {
    if (route.routes) {
      if (!open) {
        debounce(() => ref?.current?.setOpen(true), 50)();
      }
      setOpen(true);
    }
    if (!route.routes || level === 2) {
      setOpen(false);
    }
  };
  // init
  useEffect(() => {
    if (appUserID) {
      const store_ = JSON.parse(localStorage.getItem(`menu_user-${appUserID}`) || '{}');
      setOpen(store_ && store_.open !== undefined ? store_.open : true);
      setIsInit(true);
    }
  }, [setOpen, appUserID]);
  // render
  return (
    <div className="root-content">
      {isInit && (
        <ClickAwayListener onClickAway={() => onMenu(false)}>
          <Drawer className={`root-drawer drawer ${open ? 'hover' : ''}`} variant="permanent">
            <div className="burger">
              <span className="icon" onClick={() => onMenu(!open)}>
                {open ? <MenuIconOpen /> : <MenuIcon />}
              </span>
              <div className="logo-box">
                <div className="logo">
                  <LogoIcon />
                </div>
                <div className="description">
                  <LogoTitle />
                  <div className="slogan">{t('login-slogan')}</div>
                </div>
              </div>
            </div>
            <MainNav collapsed={!open} onMenuOpen={onMenuOpen} accordion={true} />
            <PoweredBy>
              <MenuLanguages />
            </PoweredBy>
          </Drawer>
        </ClickAwayListener>
      )}
      <ContentLayout>{children}</ContentLayout>
    </div>
  );
});

export default Layout;
