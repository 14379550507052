import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { GridModel } from '../../models';
import { useOpen } from 'AurionCR/components/hooks';
import { DialogDietDiary } from 'components/dialog-diet-diary';
import { actionDietDiariesReportLoadData } from '../../store';
import { useAppDispatch } from 'store';

interface Props {
  row: GridModel;
}

export const ControlEdit: React.FC<Props> = ({ row }) => {
  const dispatch = useAppDispatch();
  const { tp } = useTranslate();
  const { isOpen, handleOpen, handleClose } = useOpen();
  const onRefresh = useCallback(() => {
    dispatch(actionDietDiariesReportLoadData());
  }, [dispatch]);

  return (
    <>
      <Button color={'primary'} onClick={handleOpen}>
        {tp('edit')}
      </Button>
      {isOpen && (
        <DialogDietDiary
          userPatientProfileID={String(row.userPatientProfileID)}
          itemID={row.id}
          onClose={handleClose}
          onRefresh={onRefresh}
        />
      )}
    </>
  );
};
