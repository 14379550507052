import React from 'react';
import LockIcon from '@material-ui/icons/Lock';

import clsx from 'clsx';
import { IconButton, IconButtonProps, Tooltip } from '@material-ui/core';
import { useI18n } from 'AurionCR/components';

interface Classes {
  root: string;
}

interface Props extends IconButtonProps {
  iconFontSize?: 'small' | 'inherit' | 'default' | 'large' | 'medium';
  className?: string;
  classes?: Partial<Classes>;
}

export const MeetingLock: React.FC<Props> = ({
  className,
  classes,
  iconFontSize = 'medium',
  ...rest
}) => {
  const { t } = useI18n();
  return (
    <Tooltip title={t('meeting-lock')} placement={'top'}>
      <IconButton className={clsx(classes?.root, className)} color={'secondary'} {...rest}>
        <LockIcon fontSize={iconFontSize} />
      </IconButton>
    </Tooltip>
  );
};
