import React, { memo, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import Footer from './footer';
import { Controller, useForm } from 'react-hook-form';
import { FormController, Input, validateRule } from 'AurionCR/components/formV2';
import { Grid } from '@material-ui/core';
import { tPaymentFormExtra } from './@type';
import { add } from 'date-fns';
import { CleaveNumber } from './helpers';
import style from './index.module.scss';
import { APP_FORMAT_DATE } from 'configs/const';
import { AppDatePicker } from 'components/app-date-picker';
import { useFieldProps } from 'hooks';
import { useTranslate } from 'hooks/use-translate';
import { useSourceSubscriptions } from '../hooks';

export const State2 = memo(
  ({
    initData,
    onCancel,
    onNext,
    onBack,
  }: {
    initData: Partial<tPaymentFormExtra>;
    onCancel: (e?: any) => void;
    onNext: (e?: any) => void;
    onBack: (e?: any) => void;
  }) => {
    const { t } = useTranslate();
    const { errors, control, handleSubmit, watch, setValue } = useForm<tPaymentFormExtra>({
      defaultValues: initData,
    });
    const getFieldProps = useFieldProps({ errors });

    const sourceSubscriptions = useSourceSubscriptions();
    const mapSubscriptions = sourceSubscriptions.map;
    const subscription = initData.subscriptionID ? mapSubscriptions[initData.subscriptionID] : null;

    const startDate = watch('startDate');
    useEffect(() => {
      if (startDate) {
        setValue('endDate', add(new Date(startDate), { months: initData.durationMonths }));
      } else {
        setValue('endDate', '');
      }
    }, [setValue, startDate, initData]);
    // handlers
    const onSubmit = useCallback(
      (formData: any) => {
        onNext(formData);
      },
      [onNext],
    );
    // render
    return (
      <div className={clsx(style.state, style.state1)}>
        <form className={style.contentForm} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <FormController<tPaymentFormExtra>
                name={'description'}
                label={t('description')}
                as={Input}
                rules="required,minLength"
                errors={errors}
                control={control}
                InputLabelProps={{ shrink: true }}
                autoFocus={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={4}>
              <Controller
                name={'startDate'}
                control={control}
                rules={validateRule('required,dateLike')}
                render={(renderProps) => (
                  <AppDatePicker
                    {...getFieldProps(renderProps)}
                    label={t('from-date')}
                    InputLabelProps={{ shrink: true }}
                    placeholder={APP_FORMAT_DATE}
                  />
                )}
              />
            </Grid>
            <Grid item sm={4}>
              <Controller
                name={'endDate'}
                control={control}
                rules={validateRule('dateLike')}
                render={(renderProps) => (
                  <AppDatePicker
                    {...getFieldProps(renderProps)}
                    label={t('to-date')}
                    InputLabelProps={{ shrink: true }}
                    placeholder={APP_FORMAT_DATE}
                    disabled={true}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <FormController<tPaymentFormExtra>
                name={'clientName'}
                label={t('name-in-the-invoice')}
                as={Input}
                rules="required,minLength"
                errors={errors}
                control={control}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item sm={4}>
              <FormController<tPaymentFormExtra>
                name={'clientVatID'}
                label={t('vat-id')}
                as={Input}
                rules="required,minLength"
                errors={errors}
                control={control}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <FormController<tPaymentFormExtra>
                name={'email'}
                label={t('email')}
                as={Input}
                rules="required,email"
                errors={errors}
                control={control}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item sm={4}>
              <FormController<tPaymentFormExtra>
                name={'sum'}
                label={t('sum')}
                as={Input}
                rules={{
                  required: t('required'),
                  min: { value: subscription?.isExternal ? 0 : 1, message: t('required') },
                }}
                errors={errors}
                control={control}
                InputProps={{
                  inputComponent: CleaveNumber as any,
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <FormController<tPaymentFormExtra>
                name={'remarks'}
                label={t('remarks')}
                as={Input}
                errors={errors}
                control={control}
                multiline
                minRows={3}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </form>
        <Footer onCancel={onCancel} onNext={handleSubmit(onSubmit)} onBack={() => onBack(1)} />
      </div>
    );
  },
);

export default State2;
