import { apiRtk } from 'utils/rtk-query';
import { DynamicService } from 'utils/service';
import {
  API_FORM_DOCUMENTS,
  FormDocument,
  DocumentSignMap,
  AdapterDocumentSignInput,
  FormDocumentPostOrPatchInput,
  FormDocumentsWithRequiredDrugs,
} from './models';
import { format } from 'date-fns';
import { FormDocumentFieldType, FormDocumentInput } from 'services/form-document-inputs';
import { convertToDate } from 'utils/dates';
import { ServiceFormDocumentDrugs } from '../form-document-drugs';

export * from './models';

class Service extends DynamicService<FormDocument> {
  adapterDocumentSign = (userInfo: AdapterDocumentSignInput): DocumentSignMap => {
    const { subscription, ...patient } = userInfo;

    return {
      firstName: patient.firstName || '',
      lastName: patient.lastName || '',
      fullName: [patient.firstName, patient.lastName].filter(Boolean).join(' '),
      idNumber: patient.idNumber || '',
      dateOfBirth: patient.dateOfBirth || '',
      mobilePhone: patient.mobilePhone || '',
      email: patient.email || '',
      address: patient.address || '',

      familyMemberName:
        patient.familyMemberName ||
        [patient.familyMemberFirstName, patient.familyMemberLastName].filter(Boolean).join(' '),
      familyMemberFirstName: patient.familyMemberFirstName || '',
      familyMemberLastName: patient.familyMemberLastName || '',
      familyMemberPhone: patient.familyMemberPhone || '',
      familyMemberIDNumber: patient.familyMemberIDNumber || '',
      familyMemberRelationship: patient.familyMemberRelationship || '',

      startDate: subscription?.startDate,
      endDate: subscription?.endDate,

      docDate: new Date(),
      docTime: new Date(),
    };
  };
  prepareDocumentFieldInputs = (fieldInputs: FormDocumentInput[], documentMap: DocumentSignMap) => {
    return fieldInputs.map((fieldInput) => {
      const key = fieldInput.inputID as keyof DocumentSignMap;
      return {
        ...fieldInput,
        defaultValue: this.setFieldInputDefaultValue({
          fieldInput,
          value: documentMap[key],
        }),
      };
    });
  };
  private setFieldInputDefaultValue = (options: { fieldInput: FormDocumentInput; value: any }) => {
    const { fieldInput, value } = options;

    const inputType = fieldInput.formDocumentFieldType;

    if (inputType === FormDocumentFieldType.checkbox) {
      return value === undefined ? null : value;
    }

    if (inputType === FormDocumentFieldType.date) {
      let _format = 'dd.MM.yyyy';

      if (fieldInput.inputID === 'docTime') {
        _format = 'HH:mm';
      }

      return value ? format(convertToDate(value), _format) : null;
    }

    return value;
  };

  postWithDrugs = async (input: FormDocumentPostOrPatchInput) => {
    const { formDocumentDrugIDs, ...rest } = input;

    const result = await this.post(rest);
    if (Array.isArray(formDocumentDrugIDs)) {
      await ServiceFormDocumentDrugs.createBulk({
        formDocumentID: String(result.data.id),
        drugIDs: formDocumentDrugIDs,
      });
    }

    return result;
  };
  patchWithDrugs = async (input: FormDocumentPostOrPatchInput) => {
    const { formDocumentDrugIDs, ...rest } = input;

    const result = await this.patch(rest);
    if (Array.isArray(formDocumentDrugIDs)) {
      await ServiceFormDocumentDrugs.createBulk({
        formDocumentID: String(rest.id),
        drugIDs: formDocumentDrugIDs,
      });
    }

    return result;
  };
}

export const ServiceFormDocument = new Service({
  mainField: 'id',
  getAll: API_FORM_DOCUMENTS.GET_ALL_DYNAMIC,
  post: API_FORM_DOCUMENTS.POST,
  patch: API_FORM_DOCUMENTS.PATCH,
  delete: API_FORM_DOCUMENTS.DELETE,
});

export const apiFormDocuments = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getFormDocumentInputsForValidationTemplate: builder.query({
      queryFn: async (formDocumentID: string) => {
        try {
          const result = await ServiceFormDocument.getDynamic<
            Pick<FormDocument, 'formDocumentInputs'>
          >(formDocumentID, {
            select: [
              'id',
              'formDocumentInputs.OrderBy(i => i.rank).Where(i => i.isActive) as formDocumentInputs',
            ].join(','),
          });

          return { data: result.data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getFormDocumentsWithRequiredDrugs: builder.query({
      queryFn: async (arg: void) => {
        try {
          const result = await ServiceFormDocument.getAllDynamic<FormDocumentsWithRequiredDrugs>({
            select: [
              'id',
              'title',
              'isUnder18',
              'formDocumentDrugs.Select(d => d.drugID) as drugIDs',
            ].join(','),
            filter: 'formDocumentDrugs.Count() > 0 && isActive==true',
          });

          return { data: result.data.value };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
