import React, { memo, useCallback, useState } from 'react';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { Button, CircularProgress, Dialog, DialogContent } from '@material-ui/core';
import { useAppSelector } from 'store';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { FormDocumentFieldType } from 'services/form-document-inputs';
import { hexAToRGBA } from 'components/helpers';
import fontkit from '@pdf-lib/fontkit';
import { DialogHeading } from 'components/dialog-title';
import style from './index.module.scss';
import { ServiceMediaPrivate } from 'services/media-private-services';
import { useTranslate } from 'hooks/use-translate';

export const PdfPreview = memo(({ isRTL, disabled }: { isRTL: boolean; disabled: boolean }) => {
  const { t } = useTranslate();
  // store
  const { pages, scale, initData } = useAppSelector((state) => state.pdfFormDocument);
  const [loading, setLoading] = useState(false);
  const [pdfSource, setPdfSource] = useState('');
  // handlers
  const createPDF = useCallback(async () => {
    if (initData?.pdfUrl) {
      const { data: blobPdf } = await ServiceMediaPrivate.getFile(initData.pdfUrl);
      const bufferPdf = await blobPdf.arrayBuffer();
      const pdfDoc = await PDFDocument.load(bufferPdf);

      pdfDoc.registerFontkit(fontkit);
      const OpenSans = await import(
        /* webpackChunkName: 'FontOpenSansBold' */ 'fonts/fontOpenSansBold'
      );

      const font = await pdfDoc.embedFont(OpenSans.default);
      const fontCheckBox = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);
      const checkBox = '✓';

      for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
        const { index, inputs } = pages[pageIndex];
        if (inputs.length) {
          const page = pdfDoc.getPage(index);
          const { height: pageHeight } = page.getSize();
          for (let inputIndex = 0; inputIndex < inputs.length; inputIndex++) {
            const {
              _value,
              coordX,
              coordY,
              formDocumentFieldType: type,
              width,
              height,
              background,
              ...rest
            } = inputs[inputIndex];
            // background
            const width_ = width / scale;
            const height_ = height / scale;
            const x = coordX / scale;
            const y = coordY / scale;
            if (background) {
              const [r, g, b, a] = hexAToRGBA(background);
              page.drawRectangle({
                x,
                y: pageHeight - height_ - y,
                width: width_,
                height: height_,
                color: rgb(r / 255, g / 255, b / 255),
                opacity: a || 1,
              });
            }
            if (type === FormDocumentFieldType.checkbox) {
              const { fontSize } = rest;
              const textHeight = fontCheckBox.heightAtSize(fontSize);
              page.drawText(checkBox, {
                x: x + (width_ - fontCheckBox.widthOfTextAtSize(checkBox, fontSize)) * 0.5,
                y: pageHeight - y - height_ * 0.5 - textHeight * 0.3,
                size: fontSize,
                lineHeight: fontSize * 1.2,
                font: fontCheckBox,
              });
            } else if (type === FormDocumentFieldType.signature) {
              if (_value) {
                const pngImage = await pdfDoc.embedPng(_value as string);
                page.drawImage(pngImage, {
                  x,
                  y: pageHeight - height_ - y,
                  width: width_,
                  height: height_,
                });
              }
            } else {
              const { fontSize /*fontFamily*/ } = rest;
              // const font = await pdfDoc.embedFont(fontFamily);
              const textHeight = font.heightAtSize(fontSize);
              const text = String(_value);
              page.drawText(text, {
                x: isRTL ? x + width_ - font.widthOfTextAtSize(text, fontSize) : x,
                y: pageHeight - y - height_ * 0.5 - textHeight * 0.25,
                size: fontSize,
                lineHeight: fontSize * 1.2,
                font,
              });
            }
          }
        }
      }

      const pdfBytes = await pdfDoc.save();

      const bytes = new Uint8Array(pdfBytes);
      const blob = new Blob([bytes], { type: 'application/pdf' });
      const docUrl = URL.createObjectURL(blob);

      setPdfSource(docUrl);
    }
  }, [setPdfSource, pages, scale, initData, isRTL]);
  const onPreview = useCallback(() => {
    setLoading(true);
    createPDF()
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  }, [createPDF]);
  const onClose = useCallback(() => {
    setPdfSource('');
  }, [setPdfSource]);
  //render
  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={onPreview}
        startIcon={<PictureAsPdfIcon />}
        endIcon={loading ? <CircularProgress size={14} /> : null}
        disabled={loading || disabled}
        size={'small'}
      >
        {t('preview')}
      </Button>

      {pdfSource && (
        <Dialog
          open={true}
          onClose={onClose}
          fullWidth
          maxWidth={'lg'}
          className={style.previewDialog}
        >
          <DialogHeading title={t('preview')} onClose={onClose} />
          <DialogContent>
            <iframe title={'pdf'} src={pdfSource} width={'100%'} height={'100%'} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
});

export default PdfPreview;
