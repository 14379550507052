import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'UserPatientProfileSessionRoutineDrugs';

export const API_USER_PATIENT_PROFILE_SESSIONS_ROUTINE_DRUGS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  POST_BULK: `${API_ENDPOINT}/CreateUserPatientProfileSessionRoutineDrugBulk`,
  PATCH: (data: Pick<UserPatientProfileSessionRoutineDrug, 'id'>) =>
    `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<UserPatientProfileSessionRoutineDrug, 'id'>) =>
    `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface UserPatientProfileSessionRoutineDrug
  extends FixServerObject<Components.Schemas.UserPatientProfileSessionRoutineDrug, 'id'> {
  createdDate?: string;
  updatedDate?: string;
}

export interface CreateBulkUserPatientProfileSessionRoutineDrugInput {
  userPatientProfileSessionID: string;
  routineDrugIDs: string[];
}
