import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { requestError } from 'AurionCR/components';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import {
  ExtendedNotebookFieldInputs,
  isLoadingPatchNotebookFieldInput,
  NOTEBOOKS_STATE,
} from './module';
import { API_INPUT_TYPES, InputType } from 'services/input-type';
import { API_NOTEBOOK_FIELD_INPUTS } from 'services/notebook-field-input';
import { API_FIELD_INPUTS } from 'services/field-input';
import { API_INPUT_TYPE_PARAMETERS, InputTypeParameter } from 'services/input-type-parameter';
import { api } from 'utils/service';

// INIT
function* getInputTypesParameters() {
  const { inputTypesParameters }: NOTEBOOKS_STATE = yield select((state: any) => state.notebooks);
  if (!Object.keys(inputTypesParameters).length) {
    const {
      data: { value },
    }: {
      data: {
        value: InputTypeParameter[];
      };
    } = yield call(api.get, API_INPUT_TYPE_PARAMETERS.GET_ALL_DYNAMIC, {
      params: {
        select: 'id,isNumber,isText,isLongText,isBool,isDate,isSelect,isMultiSelect,isDays',
        filter: 'isActive==true',
      },
    });
    yield put({
      type: 'NOTEBOOKS_MERGE',
      payload: {
        inputTypesParameters: value.reduce((acc: NOTEBOOKS_STATE['inputTypesParameters'], item) => {
          acc[String(item.id)] = item;
          return acc;
        }, {}),
      },
    });
  }
}

function* getInputTypes() {
  yield call(getInputTypesParameters);
  const { inputTypesParameters }: NOTEBOOKS_STATE = yield select((state: any) => state.notebooks);
  const {
    data: { value },
  }: {
    data: {
      value: InputType[];
    };
  } = yield call(api.get, API_INPUT_TYPES.GET_ALL_DYNAMIC, {
    params: {
      select: 'id,title,inputTypeParameterID',
      filter: 'isActive==true',
      orderBy: 'title',
    },
  });
  yield put({
    type: 'NOTEBOOKS_MERGE',
    payload: {
      inputTypes: value.map((item) => {
        item.inputTypeParameter = inputTypesParameters[String(item?.inputTypeParameterID)] || null;
        return item;
      }),
    },
  });
}

function* getNotebookFieldInputs(notebookID: string) {
  const {
    data: { value: notebookFieldInputs },
  }: {
    data: {
      value: ExtendedNotebookFieldInputs[];
    };
  } = yield call(api.get, API_NOTEBOOK_FIELD_INPUTS.GET_ALL_DYNAMIC, {
    params: {
      filter: `notebookID=="${notebookID}"`,
      select: 'id,rank,isRequired,isMedicalInformation,fieldInputID,fieldInput,defaultValue',
      orderBy: 'rank',
    },
  });
  yield put({
    type: 'NOTEBOOKS_MERGE',
    payload: {
      notebookFieldInputs: notebookFieldInputs.map((item) => ({
        ...item,
        isLoading: false,
        isLoadingPatch: false,
      })),
    },
  });
}

function* init({ payload: { notebookID } }: { payload: { notebookID: string }; type: string }) {
  yield put({ type: 'NOTEBOOKS_RESET' });
  try {
    yield all([call(getInputTypes), call(getNotebookFieldInputs, notebookID)]);
  } catch (e) {
    yield put(notifyRequestResult(requestError(e), 'error'));
  }
  yield put({ type: 'NOTEBOOKS_MERGE', payload: { notebookID, isLoading: false, isInit: true } });
}

// PATCH
function* patchNotebookFieldInput({
  payload: { id, ...rest },
}: {
  payload: { id: string } & any;
  type: string;
}) {
  const { notebookFieldInputs }: NOTEBOOKS_STATE = yield select((state: any) => state.notebooks);
  const notebookFieldInput = notebookFieldInputs.find((item) => item.id === id);
  if (notebookFieldInput) {
    yield put(isLoadingPatchNotebookFieldInput(id, true));
    try {
      yield call(api.patch, API_NOTEBOOK_FIELD_INPUTS.PATCH({ id }), rest);
      yield put({
        type: 'NOTEBOOKS_MERGE_NOTEBOOK_FIELD_INPUT',
        payload: { id, isLoadingPatch: false, ...rest },
      });
    } catch (e) {
      yield put(isLoadingPatchNotebookFieldInput(id, false));
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
  } else {
    yield put(notifyRequestResult('not find notebookFieldInput', 'error'));
    console.error('not find notebookFieldInput');
  }
}

function* patchFieldInput({
  payload: { id, notebookFieldInputID, ...rest },
}: {
  payload: { id: string; notebookFieldInputID: string } & any;
  type: string;
}) {
  const { notebookFieldInputs }: NOTEBOOKS_STATE = yield select((state: any) => state.notebooks);
  const notebookFieldInput = notebookFieldInputs.find((item) => item.id === notebookFieldInputID);
  if (notebookFieldInput) {
    yield put(isLoadingPatchNotebookFieldInput(notebookFieldInputID, true));
    try {
      yield call(api.patch, API_FIELD_INPUTS.PATCH({ id }), rest);
      yield put({
        type: 'NOTEBOOKS_MERGE_FIELD_INPUT',
        payload: { notebookFieldInputID, ...rest },
      });
      yield put(isLoadingPatchNotebookFieldInput(notebookFieldInputID, false));
    } catch (e) {
      yield put(isLoadingPatchNotebookFieldInput(notebookFieldInputID, false));
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
  } else {
    yield put(notifyRequestResult('not find notebookFieldInput', 'error'));
    console.error('not find notebookFieldInput');
  }
}

// NORMALIZE
function* normalize() {
  const { notebookFieldInputs }: NOTEBOOKS_STATE = yield select((state: any) => state.notebooks);
  yield all(
    notebookFieldInputs.reduce((acc: any[], item, index) => {
      if (index + 1 !== item.rank) {
        acc.push(
          call(patchNotebookFieldInput, {
            payload: { id: item.id, rank: index + 1 },
            type: 'NOTEBOOKS_PATCH_NOTEBOOK_FIELD_INPUT',
          }),
        );
      }
      return acc;
    }, []),
  );
}

// CREATE
function* createNotebookFieldInput({
  payload: { isRequired, isMedicalInformation, inputTypeID, labelKey, defaultValue },
}: {
  payload: {
    isRequired: boolean;
    isMedicalInformation: boolean;
    inputTypeID: string;
    labelKey: string;
    defaultValue: string;
  };
  type: string;
}) {
  const { notebookFieldInputs, notebookID }: NOTEBOOKS_STATE = yield select(
    (state: any) => state.notebooks,
  );

  try {
    yield put({ type: 'NOTEBOOKS_MERGE', payload: { isLoading: true } });
    const { data: fieldInput } = yield call(api.post, API_FIELD_INPUTS.POST, {
      inputTypeID,
      labelKey,
    });

    const { data: notebookFieldInput } = yield call(api.post, API_NOTEBOOK_FIELD_INPUTS.POST, {
      notebookID,
      isRequired,
      isMedicalInformation,
      defaultValue: defaultValue || null,
      fieldInputID: fieldInput.id,
      rank: notebookFieldInputs.length
        ? (notebookFieldInputs[notebookFieldInputs.length - 1]?.rank || 0) + 1
        : 1,
    });
    yield put({
      type: 'NOTEBOOKS_ADD_NOTEBOOK_FIELD_INPUT',
      payload: { ...notebookFieldInput, fieldInput, isLoading: false, isLoadingPatch: false },
    });
    yield put({ type: 'NOTEBOOKS_MERGE', payload: { isLoading: false } });
  } catch (e) {
    yield put({ type: 'NOTEBOOKS_MERGE', payload: { isLoading: false } });
    yield put(notifyRequestResult(requestError(e), 'error'));
  }
}

// DELETE
function* deleteNotebookFieldInput() {
  const { isDeleteNotebookFieldInputID, notebookFieldInputs }: NOTEBOOKS_STATE = yield select(
    (state: any) => state.notebooks,
  );
  const notebookFieldInput = notebookFieldInputs.find(
    (item) => item.id === isDeleteNotebookFieldInputID,
  );
  if (notebookFieldInput) {
    yield put({
      type: 'NOTEBOOKS_MERGE',
      payload: { isLoading: true, isDeleteNotebookFieldInputID: '' },
    });
    try {
      yield call(api.delete, API_NOTEBOOK_FIELD_INPUTS.DELETE({ id: notebookFieldInput?.id }));
      yield put({
        type: 'NOTEBOOKS_REMOVE_NOTEBOOK_FIELD_INPUT',
        payload: { id: notebookFieldInput?.id },
      });
      yield put({ type: 'NOTEBOOKS_MERGE', payload: { isLoading: false } });
    } catch (e) {
      yield put({ type: 'NOTEBOOKS_MERGE', payload: { isLoading: false } });
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
  } else {
    yield put({ type: 'NOTEBOOKS_MERGE', payload: { isDeleteNotebookFieldInputID: '' } });
    yield put(notifyRequestResult('not find notebookFieldInput', 'error'));
    console.error('not find notebookFieldInput');
  }
}

// EXPORTS
export const notebooksSagas = [
  takeLatest('NOTEBOOKS_INIT', init),
  takeLatest('NOTEBOOKS_CREATE_NOTEBOOK_FIELD_INPUT', createNotebookFieldInput),
  takeLatest('NOTEBOOKS_DELETE_NOTEBOOK_FIELD_INPUT', deleteNotebookFieldInput),
  takeLatest('NOTEBOOKS_PATCH_NOTEBOOK_FIELD_INPUT', patchNotebookFieldInput),
  takeLatest('NOTEBOOKS_PATCH_FIELD_INPUT', patchFieldInput),
  takeLatest('NOTEBOOKS_NORMALIZE', normalize),
];
