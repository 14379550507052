import React from 'react';
import { MenuButton } from 'components/menu-button';
import { IconButton } from '@material-ui/core';
import { BulkActions } from '../../models';
import { useTranslate } from 'hooks/use-translate';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useCurrentUser } from '../../../../../components/hooks';

interface Props {
  disabled: boolean;
  onChange: (value: BulkActions) => void;
}
export const MenuActions: React.FC<Props> = ({ onChange, disabled }) => {
  const { $isAdmin } = useCurrentUser();
  const { tp } = useTranslate();
  const factoryClick = (value: BulkActions) => {
    return () => onChange(value);
  };
  const menuItems = [
    {
      id: BulkActions.ASSIGN,
      title: tp('assign-to-employee'),
      onClick: factoryClick(BulkActions.ASSIGN),
    },
    { id: BulkActions.EDIT, title: tp('edit'), onClick: factoryClick(BulkActions.EDIT) },
    ...($isAdmin
      ? [{ id: BulkActions.DELETE, title: tp('delete'), onClick: factoryClick(BulkActions.DELETE) }]
      : []),
  ];

  return (
    <MenuButton items={menuItems}>
      <IconButton disabled={disabled}>
        <MoreVertIcon />
      </IconButton>
    </MenuButton>
  );
};
