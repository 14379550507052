import React from 'react';
import { GridModel } from '../../models';
import { TemplatePrivateImages } from 'components/templates/template-private-images';

interface Props {
  row: GridModel;
}
export const ColumnImages: React.FC<Props> = ({ row }) => {
  return <TemplatePrivateImages files={row.fileURLs} />;
};
