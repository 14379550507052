import { DynamicParams, DynamicService } from 'utils/service';
import { API_DOCUMENT_TYPES, DOCUMENT_TYPES_INDEXES, DocumentType } from './models';
import { apiRtk } from 'utils/rtk-query';

export * from './models';

class Service extends DynamicService<DocumentType> {
  getDynamicByIndex = async <Model = DocumentType, P extends DynamicParams = DynamicParams>(
    rowIndex: DOCUMENT_TYPES_INDEXES,
    params?: P,
  ) => {
    const result = await this.getAllDynamic<Model>({
      ...params,
      filter: [`rowIndex==${rowIndex}`, params?.filter].filter(Boolean).join('&&'),
      take: 1,
    });
    const data = result.data.value[0] as Model;

    if (!data) {
      throw new Error('record-not-found');
    }

    return { ...result, data };
  };
}

export const ServiceDocumentTypes = new Service({
  getAll: API_DOCUMENT_TYPES.GET_ALL_DYNAMIC,
  post: API_DOCUMENT_TYPES.POST,
  patch: API_DOCUMENT_TYPES.PATCH,
  delete: API_DOCUMENT_TYPES.DELETE,
});

export const apiDocumentTypes = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getDocumentTypeByIndex: builder.query({
      queryFn: async (input: DOCUMENT_TYPES_INDEXES, { dispatch }) => {
        try {
          const { data } = await ServiceDocumentTypes.getDynamicByIndex<{ id: string }>(input, {
            select: 'id',
          });

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
