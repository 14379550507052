import React, { memo, PropsWithChildren, useMemo } from 'react';
import { DataGridLightTask } from '../../models';

export interface DataGridTasksContextValue<Task = any> {
  onTask: (task: Task) => void;
}
export const DataGridTasksContext = React.createContext<DataGridTasksContextValue | null>(null);

interface Props<Task> {
  onTask?: (task: Task) => void;
}
const Component = <M, Task extends DataGridLightTask<any, M>>({
  children,
  onTask,
}: PropsWithChildren<Props<Task>>) => {
  const value = useMemo(() => {
    return {
      onTask: onTask || (() => {}),
    };
  }, [onTask]);
  return <DataGridTasksContext.Provider value={value}>{children}</DataGridTasksContext.Provider>;
};

export const DataGridTasksProvider = memo(Component) as typeof Component;
