import React, { useCallback, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { Button, Popover } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useTranslate } from 'hooks/use-translate';
import { addDays, format, isSameDay } from 'date-fns';
import { useOpen } from 'AurionCR/components/hooks';

import style from './index.module.scss';
import { useDateLocale } from 'contexts/picker-provider';
import { AppDatePicker } from 'components/app-date-picker';
import { useAppSelector } from 'store';
import { selectLanguageIsRtl } from 'store/languages';
import { convertToDate, isDateLike } from 'utils/dates';

interface Props {
  value: Date;
  loading: boolean;
  onChange: (value: Date | null) => void;
}

export const DayViewControlCalendar: React.FC<Props> = ({ value, loading, onChange }) => {
  const { t } = useTranslate();
  const isRtl = useAppSelector(selectLanguageIsRtl);

  const { locale } = useDateLocale();
  const { isOpen, handleClose, handleToggle } = useOpen();
  const ref = useRef<HTMLButtonElement>(null);

  const onNext = useCallback(() => {
    const newValue = value || new Date();
    return onChange(addDays(newValue, 1));
  }, [onChange, value]);

  const onPrev = useCallback(() => {
    const newValue = value || new Date();
    return onChange(addDays(newValue, -1));
  }, [onChange, value]);

  const onChangeWrapper = useCallback(
    (_value: string | null) => {
      const date = isDateLike(_value) ? convertToDate(_value) : null;
      handleClose();
      if (!date) return onChange(date);
      if (!value) return onChange(date);
      if (isSameDay(date, value)) return;
      return onChange(date);
    },
    [onChange, value, handleClose],
  );

  const dateFormatted = useMemo(
    () => (
      <span>
        <strong style={{ whiteSpace: 'nowrap', fontSize: '1.1rem', display: 'block' }}>
          {format(value, 'EE, dd', { locale })}
        </strong>
        {format(value, 'MMMM', { locale })}
      </span>
    ),
    [value, locale],
  );

  return (
    <>
      <Button
        ref={ref}
        onClick={handleToggle}
        disabled={loading}
        fullWidth
        classes={{
          root: style.mainRoot,
          label: style.mainLabel,
          endIcon: style.mainEndIcon,
        }}
        endIcon={<EventIcon fontSize={'inherit'} style={{ fontSize: '1.6rem', opacity: 0.5 }} />}
      >
        {dateFormatted}
      </Button>
      <Popover
        open={isOpen}
        onClose={handleClose}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={style.head}>
          <Button
            className={clsx(style.btn, style.btnPrev)}
            onClick={onPrev}
            startIcon={
              isRtl ? (
                <ChevronRightIcon fontSize={'inherit'} style={{ fontSize: '2.4rem' }} />
              ) : (
                <ChevronLeftIcon fontSize={'inherit'} style={{ fontSize: '2.4rem' }} />
              )
            }
          >
            {t('previous-day')}
          </Button>
          <Button
            className={clsx(style.btn, style.btnNext)}
            onClick={onNext}
            endIcon={
              isRtl ? (
                <ChevronLeftIcon fontSize={'inherit'} style={{ fontSize: '2.4rem' }} />
              ) : (
                <ChevronRightIcon fontSize={'inherit'} style={{ fontSize: '2.4rem' }} />
              )
            }
          >
            {t('next-day')}
          </Button>
        </div>
        <AppDatePicker
          autoOk
          disableToolbar
          orientation="landscape"
          variant="static"
          value={value}
          onChange={onChangeWrapper}
        />
      </Popover>
    </>
  );
};
