import React from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import {
  UserBoxClinicalMeeting,
  UserBoxProgramDuration,
  UserBoxSupportMeeting,
} from '../../../user-info-boxes';
import { Stack } from 'components/stack';
import { GridMeetings } from '../../grids';

interface Props {
  className?: string;
  userPatientProfileID: string;
}

export const TabAppointments: React.FC<Props> = ({ userPatientProfileID, className }) => {
  return (
    <div className={clsx(style.root, className)}>
      <Stack spacing={1}>
        <UserBoxProgramDuration userPatientProfileID={userPatientProfileID} />
        <UserBoxSupportMeeting userPatientProfileID={userPatientProfileID} order={'last'} />
        <UserBoxSupportMeeting userPatientProfileID={userPatientProfileID} order={'next'} />
        <UserBoxClinicalMeeting userPatientProfileID={userPatientProfileID} order={'last'} />
        <UserBoxClinicalMeeting userPatientProfileID={userPatientProfileID} order={'next'} />
      </Stack>
      <GridMeetings userPatientProfileID={userPatientProfileID} />
    </div>
  );
};
