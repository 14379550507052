import { DynamicService } from 'utils/service';
import {
  API_REMARK_FOR_PATIENT_CALL_STATUSES,
  REMARK_REASON,
  RemarkForPatientCallStatus,
} from './models';
import { apiRtk } from 'utils/rtk-query';

export * from './models';

class Service extends DynamicService<RemarkForPatientCallStatus> {
  getRemarkByReason = async (reason: REMARK_REASON) => {
    const {
      data: { value },
    } = await this.getAllDynamic<Pick<RemarkForPatientCallStatus, 'id'>>({
      select: ['id'].join(','),
      filter: this.makeFilterByReason(reason),
      take: 1,
    });

    return { data: value[0] || null };
  };

  private makeFilterByReason(reason: REMARK_REASON) {
    switch (reason) {
      case REMARK_REASON.RESCHEDULED:
        return 'isRescheduled==true';
      case REMARK_REASON.REASSIGNED:
        return 'isReassigned==true';
      case REMARK_REASON.AUTO:
        return 'isAuto==true';
    }
  }
}

export const ServiceRemarkForPatientCallStatuses = new Service({
  mainField: 'id',
  getAll: API_REMARK_FOR_PATIENT_CALL_STATUSES.GET_ALL_DYNAMIC,
  post: API_REMARK_FOR_PATIENT_CALL_STATUSES.POST,
  patch: API_REMARK_FOR_PATIENT_CALL_STATUSES.PATCH,
  delete: API_REMARK_FOR_PATIENT_CALL_STATUSES.DELETE,
});

export const apiRemarkForPatientCallStatuses = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getRemarkByReason: builder.query<Pick<RemarkForPatientCallStatus, 'id'>, REMARK_REASON>({
      queryFn: async (reason) => {
        try {
          const { data } = await ServiceRemarkForPatientCallStatuses.getRemarkByReason(reason);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
