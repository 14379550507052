import { UserPatientProfile } from './models';
import vars from 'styles/_variabels.scss';
import { PIPELINE_VARIABLES } from 'configs/const';
import { mergeFilters, createFilterEquals } from 'utils/dynamic-helpers';

export const makeFilterPhone = (phone: string) => {
  const phoneValue = (phone || '').trim();

  return mergeFilters(
    createFilterEquals('mobilePhone', phoneValue),
    createFilterEquals('mobilePhone', phoneValue.replace(/\D/g, '')),
  ).join('||');
};

export const createBackdoorLink = (data: {
  userPatientProfileID: string;
  secret: string;
  redirect?: string;
}) => {
  const search = data.redirect ? `redirect=${data.redirect}` : null;
  return [
    `${PIPELINE_VARIABLES.PATIENT_APP_URL}/__backdoor/${data.userPatientProfileID}/${data.secret}`,
    search,
  ]
    .filter(Boolean)
    .join('?');
};

export const getPatientColor = (
  patient: Pick<
    UserPatientProfile,
    | 'isActive'
    | 'isTLC'
    | 'extraAttention'
    | 'onHold'
    | 'slowIncreaseWeight'
    | 'doNotProlongTreatment'
    | 'changeDosageByDoctorApproval'
  > & { isTeenager?: boolean | null },
) => {
  if (patient.isActive === false) {
    return vars.colorRed;
  }
  if (patient.extraAttention === true) {
    return vars.colorRed;
  }
  if (patient.onHold === true) {
    return vars.colorPrimary;
  }
  if (patient.isTLC === true) {
    return vars.colorOrange;
  }
  if (patient.doNotProlongTreatment) {
    return vars.colorSecondary;
  }
  if (patient.slowIncreaseWeight === true) {
    return vars.colorPurple;
  }
  if (patient.changeDosageByDoctorApproval) {
    return vars.colorYellow;
  }
  if (patient.isTeenager === true) {
    return vars.colorGreen;
  }

  return undefined;
};
