import React, { memo, useMemo } from 'react';
import { Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { IUseForm, useFormHook, validateRule } from 'AurionCR/components/formV2';
import { IGridEditFormProps } from 'components/models';
import {
  API_SUPPORT_MEETING_ACTIVITIES,
  SupportMeetingActivity,
} from 'services/support-meeting-activities';
import { useRequestNotify, useSourcePatientRemarkStatuses } from 'components/hooks';
import { SelectEmployee } from 'components/select-employee';
import { Controller } from 'react-hook-form';
import { useTranslate } from 'hooks/use-translate';
import { useFieldProps } from 'hooks';
import { AppSelect } from 'components/app-select';
import { AppInput } from 'components/app-input';
import { ButtonCancel, ButtonSave } from '../app-buttons';

export const SupportMeetingActivitiesEditor = memo(
  ({
    onClose,
    onFormHandle,
    itemID,
    supportMeetingID,
    appUserID,
  }: IGridEditFormProps & { supportMeetingID: string; appUserID: string }) => {
    const { t } = useTranslate();

    const config = useMemo<IUseForm<SupportMeetingActivity>>(() => {
      return {
        editID: itemID,
        fields: {
          id: '',
          supportMeetingID,
          userEmployeeProfileID: appUserID,
          remarkForPatientCallStatusID: '',
          entryDate: new Date() as any,
          remarks: '',
          isActive: true,
        },
        endpoint: API_SUPPORT_MEETING_ACTIVITIES.API_ENDPOINT,
        get: {
          parser: (data) => {
            const value = data?.value[0] || null;
            if (value && value.entryDate) value.entryDate = new Date(value.entryDate);
            return value;
          },
        },
        formHandle: onFormHandle,
      };
    }, [itemID, onFormHandle, supportMeetingID, appUserID]);
    const {
      formLoading,
      setFormLoading,
      formUse: { control, errors, handleSubmit, register },
      onSubmit,
    } = useFormHook(config);
    // request noty wrapper
    const { requestWrap: wrapSubmit } = useRequestNotify({
      request: onSubmit,
      setLoading: setFormLoading,
    });

    const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

    const sourceRemarks = useSourcePatientRemarkStatuses();
    return (
      <Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
        <DialogHeading
          isLoading={formLoading}
          title={t('activity')}
          onClose={onClose}
          disabled={formLoading}
        />
        <DialogContent>
          <input {...register('id')} type="hidden" />
          <input {...register('supportMeetingID')} type="hidden" />
          <input {...register('isActive')} type="hidden" />
          <input {...register('entryDate')} type="hidden" />
          <Grid container spacing={4}>
            <Grid item md={7}>
              <Controller
                name={'remarkForPatientCallStatusID'}
                control={control}
                rules={validateRule('required')}
                render={(renderProps) => (
                  <AppSelect
                    {...getFieldProps(renderProps)}
                    label={t('remarks-for-patient')}
                    options={sourceRemarks.data}
                    loading={sourceRemarks.loading}
                  />
                )}
              />
            </Grid>
            <Grid item md={12}>
              <Controller
                name="userEmployeeProfileID"
                rules={validateRule('required')}
                control={control}
                render={(renderProps) => {
                  const props = getFieldProps(renderProps);
                  return <SelectEmployee {...props} label={t('employee')} disabled={formLoading} />;
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'remarks'}
                control={control}
                render={(renderProps) => (
                  <AppInput {...getFieldProps(renderProps)} multiline minRows={5} />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonCancel isBack={true} onClick={onClose} />
          <ButtonSave isCreate={true} disabled={formLoading} onClick={handleSubmit(wrapSubmit)} />
        </DialogActions>
      </Dialog>
    );
  },
);
