import {
  CircularProgress,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { UIEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Loading } from 'AurionCR/components';
import { DataGridEmpty } from 'components/data-grid-light';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch } from 'react-redux';
import { ISearchPatient, ServiceUserPatientProfile } from 'services/user-patient-profile';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import style from './index.module.scss';
import { getHandlerKayPress, handlerStopAndPrevent } from 'utils/handlers';
import { useTranslate } from 'hooks/use-translate';
import { useAppSelector } from 'store';
import {
  actionRemoveSearchedPatient,
  selectSearchedPatientIDs,
  selectSearchedPatients,
} from 'store/searched-patients';
import { AppInput } from 'components/app-input';
import { NativeScroll } from 'components/native-scroll';
import { useFetchDynamic } from '../../../../hooks';
import { Controller, useForm } from 'react-hook-form';
import { composeFunctions } from 'utils';
import { debounce, isEqual } from 'lodash-es';
import clsx from 'clsx';
import CopyPathToClipboard from 'AurionCR/components/copy-path-to-clipboard';
import { APP_ROUTES } from '../../../../configs/routes';

interface Row extends ISearchPatient {
  isSearched?: boolean;
}

interface PatientsListTableProps {
  rows: Row[];
  onLoadMore: () => void;
  onSelect: (row: Row) => void;
  onDelete: (row: Row) => void;
}
const PatientsListTable: React.FC<PatientsListTableProps> = ({
  rows,
  onLoadMore,
  onDelete,
  onSelect,
}) => {
  const { t } = useTranslate();
  const onScroll = useCallback<UIEventHandler<HTMLDivElement>>(
    (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      if (Math.round(scrollTop) + clientHeight >= scrollHeight) {
        onLoadMore();
      }
    },
    [onLoadMore],
  );

  return (
    <NativeScroll style={{ height: '40rem' }} onScroll={onScroll}>
      <Table>
        <TableHead>
          <TableRow className={style.tableHead}>
            <TableCell>{t('name')}</TableCell>
            <TableCell className={style.remark}>{t('short-remark')}</TableCell>
            <TableCell>{t('id-number')}</TableCell>
            <TableCell>{t('mobile-phone')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            const isActive = row.isActive;
            const onHold = row.onHold;
            const handleSelect = () => onSelect(row);
            const handleDelete = (e: React.MouseEvent) => {
              onDelete(row);
            };

            return (
              <TableRow
                key={row.appIdentityUserID}
                onClick={handleSelect}
                className={clsx(style.tableBody, {
                  [style.inactive]: !isActive,
                  [style.hold]: onHold,
                })}
              >
                <TableCell size={'small'} scope="row">
                  {row.fullName}
                </TableCell>
                <TableCell size={'small'} className={style.remark}>
                  {row.shortRemark}
                </TableCell>
                <TableCell size={'small'} style={{ whiteSpace: 'nowrap' }}>
                  {row.idNumber}
                </TableCell>
                <TableCell size={'small'} style={{ whiteSpace: 'nowrap' }}>
                  {row.mobilePhone}
                </TableCell>
                <TableCell
                  size={'small'}
                  style={{ width: '4.2rem' }}
                  onClick={handlerStopAndPrevent}
                >
                  <CopyPathToClipboard
                    text={APP_ROUTES.PATIENT_LIST({ patient: row.appIdentityUserID }).href}
                  >
                    <IconButton color={'primary'} size={'small'}>
                      <FileCopyIcon fontSize={'small'} style={{ fontSize: '1.6rem' }} />
                    </IconButton>
                  </CopyPathToClipboard>
                </TableCell>
                <TableCell
                  size={'small'}
                  style={{ width: '4.2rem' }}
                  onClick={handlerStopAndPrevent}
                >
                  {row.isSearched && (
                    <IconButton onClick={handleDelete} size={'small'}>
                      <CloseIcon fontSize={'small'} style={{ fontSize: '1.6rem' }} />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </NativeScroll>
  );
};

const TAKE_PATIENTS = 25;

interface Props {
  onClose: () => void;
  onSelectItem: (itemID: string) => void;
}

const SearchGrid: React.FC<Props> = ({ onClose, onSelectItem }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const searchedPatients = useAppSelector(selectSearchedPatients);
  const searchedPatientIDs = useAppSelector(selectSearchedPatientIDs);

  const [state, setState] = useState({
    search: '',
    take: TAKE_PATIENTS,
  });

  const { search, take } = state;

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const { control, setValue, getValues, register } = useForm({
    defaultValues: {
      search,
      take,
    },
  });
  register('take');

  const [triggerGetData, { isLoading, data }] = useFetchDynamic(
    ServiceUserPatientProfile.getPatientsForSearch,
  );
  const { value: patients = [], count = 0 } = data || {};

  // init
  useEffect(() => {
    triggerGetData({ search, take, excludeIDs: searchedPatientIDs });
    //eslint-disable-next-line
  }, []);

  const ROWS = useMemo(() => {
    return search ? patients : [...searchedPatients, ...patients];
  }, [searchedPatients, patients, search]);

  const fetchPatients = useCallback(
    async (isLoadingMore = false) => {
      let formValues = { ...getValues() };

      if (isEqual(formValues, state)) return;

      if (formValues.search !== state.search) {
        formValues.take = TAKE_PATIENTS;
      }

      setState(formValues);

      setIsLoadingMore(isLoadingMore);

      await triggerGetData({ ...formValues, excludeIDs: searchedPatientIDs });

      setIsLoadingMore(false);
    },
    [triggerGetData, state, searchedPatientIDs, getValues],
  );

  const onLoadMore = useCallback(() => {
    const next = take + TAKE_PATIENTS;
    const min = Math.max(count, TAKE_PATIENTS);

    setValue('take', Math.min(min, next));
    return fetchPatients(true);
  }, [count, take, setValue, fetchPatients]);

  const refFetch = useRef(fetchPatients);
  useEffect(() => {
    refFetch.current = fetchPatients;
  }, [fetchPatients]);
  const onApplySearch = useMemo(() => debounce(() => refFetch.current(false), 300), [refFetch]);

  const onSelect = useCallback(
    (row: ISearchPatient) => {
      onSelectItem(row.appIdentityUserID);
    },
    [onSelectItem],
  );
  const onDelete = useCallback(
    (row: ISearchPatient) => {
      dispatch(actionRemoveSearchedPatient(row.appIdentityUserID));
    },
    [dispatch],
  );

  const inNoData = isLoading && patients.length === 0;

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={style.root}>
        <div className={style.header}>
          <Typography variant={'h4'} color={'secondary'}>
            {t('search')}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon color={'inherit'} />
          </IconButton>
        </div>
        <Controller
          control={control}
          name={'search'}
          render={({ value, onChange, onBlur }) => {
            return (
              <AppInput
                className={style.filter}
                value={value}
                placeholder={t('search-by-keywords')}
                onChange={onChange}
                onKeyDown={getHandlerKayPress('Enter', onApplySearch)}
                onBlur={composeFunctions(onBlur, onApplySearch)}
                onClear={onApplySearch}
                autoFocus
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: isLoadingMore && (
                    <InputAdornment position="end">
                      <CircularProgress size={18} />
                    </InputAdornment>
                  ),
                }}
              />
            );
          }}
        />

        {!inNoData && (
          <>
            <PatientsListTable
              rows={ROWS}
              onLoadMore={onLoadMore}
              onSelect={onSelect}
              onDelete={onDelete}
            />
          </>
        )}
        {inNoData && <DataGridEmpty />}
        <Loading active={isLoading && !isLoadingMore} />
      </div>
    </ClickAwayListener>
  );
};

export default React.memo(SearchGrid);
