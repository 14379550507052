import { DynamicService } from 'utils/service';
import { API_SUPPORT_MEETING_TYPES, SupportMeetingType } from './models';
import { apiRtk, transformResponseDynamicItem } from 'utils/rtk-query';
import { mergeFilters } from 'utils/dynamic-helpers';
import { createFilterEquals } from 'utils/dynamic-helpers';

export * from './models';
class Service extends DynamicService<SupportMeetingType> {
  getCancelID = async () => {
    const { data } = await this.getAllDynamic<{ id: string }>({
      take: 1,
      select: 'id',
      filter: mergeFilters(
        createFilterEquals('isActive', true),
        createFilterEquals('isCanceledMeeting', true),
      ).join('&&'),
    });

    const res = transformResponseDynamicItem(data);

    return res.id;
  };
}

const ServiceSupportMeetingTypes = new Service({
  getAll: API_SUPPORT_MEETING_TYPES.GET_ALL_DYNAMIC,
  post: API_SUPPORT_MEETING_TYPES.POST,
  patch: API_SUPPORT_MEETING_TYPES.PATCH,
  delete: API_SUPPORT_MEETING_TYPES.DELETE,
});

export const apiSupportMeetingTypes = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getSupportMeetingSubjectCancelID: builder.query({
      queryFn: async () => {
        try {
          const id = await ServiceSupportMeetingTypes.getCancelID();
          return { data: id };
        } catch (e: any) {
          return { error: e };
        }
      },
      keepUnusedDataFor: 10 * 60, // 10 minutes
    }),
  }),
});
