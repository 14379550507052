import { useCallback, useEffect, useRef } from 'react';
import { debounce } from 'lodash-es';

export const useFactoryDebounce = <T extends (...args: any[]) => void>(fun: T, delay: number) => {
  const refFun = useRef(fun);
  useEffect(() => {
    refFun.current = fun;
  }, [fun]);

  const refDebounce = useRef<Record<string, (...args: Parameters<T>) => void>>({});

  const register = useCallback(
    (key: string) => {
      if (refDebounce.current[key]) {
        return refDebounce.current[key];
      }
      refDebounce.current[key] = debounce((...args: any) => refFun.current(...args), delay);

      return refDebounce.current[key];
    },
    [delay],
  );

  return { register };
};
