import React from 'react';
import { Stack } from 'components/stack';
import { Box, Typography } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { TimeSlot } from '../day';

interface LegendItemProps {
  title: string;
}
const LegendItem: React.FC<LegendItemProps> = ({ children, title }) => {
  return (
    <Stack spacing={1} alignItems={'center'}>
      <div>{children}</div>
      <Typography color={'secondary'}>{title}</Typography>
    </Stack>
  );
};

export const Legend: React.FC = () => {
  const { t } = useTranslate();
  return (
    <div>
      <Typography variant={'h3'} color={'secondary'}>
        {t('legend')}
      </Typography>
      <Box mt={1} ml={-0.5}>
        <LegendItem title={t('legend-meeting-clinical')}>
          <TimeSlot
            isAllowMultiple={true}
            isAvailable={true}
            isReserved={false}
            isInClinic={true}
            isPast={false}
          >
            00:00
          </TimeSlot>
        </LegendItem>
        <LegendItem title={t('legend-meeting-online')}>
          <TimeSlot
            isAllowMultiple={true}
            isAvailable={true}
            isReserved={false}
            isInClinic={false}
            isPast={false}
          >
            00:00
          </TimeSlot>
        </LegendItem>
        <LegendItem title={t('legend-meeting-reserved')}>
          <TimeSlot
            isAllowMultiple={true}
            isAvailable={true}
            isReserved={true}
            isInClinic={false}
            isPast={false}
          >
            00:00
          </TimeSlot>
        </LegendItem>
        <LegendItem title={t('legend-meeting-unavailable')}>
          <TimeSlot
            isAllowMultiple={true}
            isAvailable={false}
            isReserved={false}
            isInClinic={false}
            isPast={false}
          >
            00:00
          </TimeSlot>
        </LegendItem>
        <LegendItem title={t('legend-meeting-disabled')}>
          <TimeSlot
            isAllowMultiple={true}
            isAvailable={true}
            isReserved={false}
            isInClinic={true}
            isPast={true}
          >
            00:00
          </TimeSlot>
          <TimeSlot
            isAllowMultiple={true}
            isAvailable={true}
            isReserved={false}
            isInClinic={false}
            isPast={true}
          >
            00:00
          </TimeSlot>
          <TimeSlot
            isAllowMultiple={true}
            isAvailable={true}
            isReserved={true}
            isInClinic={false}
            isPast={true}
          >
            00:00
          </TimeSlot>
        </LegendItem>
      </Box>
    </div>
  );
};
