import { FixServerObject } from 'utils/types';
import { DateValue } from 'utils/dates';

const API_ENDPOINT = 'UserEmployeeProfileSchedules';

export const API_USER_EMPLOYEE_PROFILE_SCHEDULES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<UserEmployeeProfileSchedule, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<UserEmployeeProfileSchedule, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface UserEmployeeProfileSchedule
  extends FixServerObject<
    Components.Schemas.UserEmployeeProfileSchedule,
    'id' | 'date' | 'fromTime' | 'toTime' | 'isInClinic'
  > {}

export interface UserEmployeeProfileScheduleMakeFromDefaultInput {
  date: DateValue;
  userEmployeeProfileID: string;
}
