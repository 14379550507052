import { UserEmployeeProfile } from '../user-employee-profiles';

const API_ENDPOINT = 'UserPatientProfileSessionItems';

export const API_USER_PATIENT_PROFILE_SESSION_ITEMS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<UserPatientProfileSessionItem, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<UserPatientProfileSessionItem, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface UserPatientProfileSessionItem
  extends Omit<Components.Schemas.UserPatientProfileSessionItem, 'entryValueDate'> {
  entryValueDate?: string | null | Date;
}

export interface UserPatientProfileSessionItemWeight {
  id: string;
  weight: number;
  entryDate: string;
  userEmployeeProfileID: string;
  userEmployeeProfile: Pick<UserEmployeeProfile, 'firstName' | 'lastName'>;
}
