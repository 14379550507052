import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading, useI18n } from 'AurionCR/components';
import { NotebookFieldInput } from './notebook-field-input';
import { NOTEBOOKS_STATE } from '../../store/module';
import { Box, Typography } from '@material-ui/core';
import { NotebookFieldInputNew } from './notebook-field-input-new';
import { useDrop } from 'react-dnd';
import clsx from 'clsx';
import { RowFormLayout } from 'pages/Settings/Notebooks/Notebooks/form/components/row-form-layout';

export const NotebookFieldInputs = memo(({ notebookID }: { notebookID: string }) => {
  const { t } = useI18n();
  const dispatch = useDispatch();
  // states
  const {
    notebooks: { isLoading, isInit, notebookFieldInputs, isDrag },
  }: {
    notebooks: NOTEBOOKS_STATE;
  } = useSelector((state: any) => state);
  // init
  useEffect(() => {
    dispatch({ type: 'NOTEBOOKS_INIT', payload: { notebookID } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notebookID]);
  // DRAG AND DROP
  const [, drop] = useDrop({ accept: 'notebook-field-inputs' });
  // render
  return (
    <div className="notebook-field-inputs">
      <Box p={'2rem 8.5rem 0 3rem'} position={'relative'}>
        <RowFormLayout>
          <>
            <Typography
              component={'div'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}
              color={'secondary'}
            >
              {t('parameter')}
            </Typography>
            <div>{t('field-type')}</div>
            <div>{t('default-value')}</div>
          </>
        </RowFormLayout>
      </Box>
      <div ref={drop} className={clsx('notebook-field-inputs__wrapper', isDrag && 'is-drag')}>
        {isInit &&
          notebookFieldInputs.map((item, index) => (
            <NotebookFieldInput key={item.id} index={index} {...item} />
          ))}
      </div>
      <NotebookFieldInputNew />
      <Loading active={isLoading} />
    </div>
  );
});
