import React, { useCallback } from 'react';
import { useTranslate } from 'hooks/use-translate';
import { useAppDispatch } from 'store';
import { apiUserPatientProfilePrescriptionActivities } from 'services/user-patient-profile-prescription-activities';
import TemplateDownloadPrivate from 'components/templates/template-download-private';

interface PrintProps {
  userPatientProfilePrescriptionID: string;
  documentURL: string;
}
const Component: React.FC<PrintProps> = ({ documentURL, userPatientProfilePrescriptionID }) => {
  const dispatch = useAppDispatch();
  const { tp } = useTranslate();

  const onSuccess = useCallback(async () => {
    dispatch(
      apiUserPatientProfilePrescriptionActivities.endpoints.createUserPatientProfilePrescriptionActivity.initiate(
        {
          userPatientProfilePrescriptionID,
          remarks: tp('activity-patient-prescription-download-for-patient'),
        },
      ),
    );
  }, [userPatientProfilePrescriptionID, dispatch, tp]);

  return <TemplateDownloadPrivate documentURL={documentURL} onSuccess={onSuccess} />;
};

interface TemplatePrintProps {
  userPatientProfilePrescriptionID: string;
  documentURL: string | null | undefined;
}
export const TemplateDownloadForPatient: React.FC<TemplatePrintProps> = ({
  documentURL,
  userPatientProfilePrescriptionID,
}) => {
  if (!documentURL) return null;

  return (
    <Component
      userPatientProfilePrescriptionID={userPatientProfilePrescriptionID}
      documentURL={documentURL}
    />
  );
};
