import React, { memo, useCallback, useRef } from 'react';

import style from './index.module.scss';
import {
  Avatar,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useOpen } from 'AurionCR/components/hooks';
import clsx from 'clsx';
import { useTranslate } from 'hooks/use-translate';

interface Option {
  id: string;
  title: string;
  color: string;
  icon: string;
}

interface Props<T extends Option> extends Omit<ButtonGroupProps, 'onClick'> {
  options: T[];
  onClick: (value: T | null) => void;
}

const ButtonMeetingAddComponent = <T extends Option>({
  options,
  onClick,
  className,
  ...rest
}: Props<T>) => {
  const { t } = useTranslate();

  const anchorRef = useRef<HTMLDivElement>(null);
  const { isOpen, handleToggle, handleClose } = useOpen();

  const factoryClickItem = useCallback(
    (option: T | null) => {
      return () => {
        onClick(option);
        handleClose();
      };
    },
    //eslint-disable-next-line
    [onClick, handleClose],
  );
  return (
    <>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        {...rest}
        className={clsx(style.root, className)}
      >
        <Button startIcon={<AddIcon />} onClick={factoryClickItem(null)}>
          {t('add-new')}
        </Button>
        <Button size="small" onClick={handleToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        placement={'bottom-end'}
        transition
        className={style.popper}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList>
              {options.map((item) => (
                <MenuItem key={item.id} onClick={factoryClickItem(item)}>
                  <Avatar
                    alt={item.title || ''}
                    src={item.icon || ''}
                    className={style.avatar}
                    style={{
                      backgroundColor: item.color || 'white',
                    }}
                  />
                  {item.title}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

export const ButtonMeetingAdd = memo(ButtonMeetingAddComponent) as typeof ButtonMeetingAddComponent;
