import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslate } from 'hooks/use-translate';
import { ButtonCancel, ButtonSave } from 'components/app-buttons';
import { useFieldProps } from 'hooks';
import { AppSelect } from 'components/app-select';
import { FormModel, schemaMeetingCancel } from './models';
import { useSourcePatientRemarkStatuses } from '../hooks';
import { AppInput } from '../app-input';

const schema = schemaMeetingCancel;

interface Props {
  meetingID: string;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (formData: FormModel) => void;
}
export const DialogCancelMeeting: React.FC<Props> = ({
  meetingID,
  isLoading,
  onClose,
  onSubmit,
}) => {
  const { tp } = useTranslate();
  const methods = useForm({
    defaultValues: schema.cast({ meetingID }),
    resolver: yupResolver(schema),
  });
  const { control, errors, register, handleSubmit, watch, setValue } = methods;

  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });
  const sourceStatuses = useSourcePatientRemarkStatuses();

  const remarkForPatientCallStatusID = watch('remarkForPatientCallStatusID');

  const defaultStatus = useMemo(() => {
    return sourceStatuses.data.find((status) => status.isCanceledMeeting);
  }, [sourceStatuses.data]);

  useEffect(() => {
    if (remarkForPatientCallStatusID) return;
    if (!defaultStatus) return;

    requestAnimationFrame(() => {
      setValue('remarkForPatientCallStatusID', defaultStatus.id);
    });
  }, [remarkForPatientCallStatusID, defaultStatus, setValue]);

  return (
    <Dialog open={true} fullWidth maxWidth={'sm'} onClose={onClose}>
      <DialogHeading
        isLoading={isLoading}
        title={tp('cancel-meeting-reason-title')}
        onClose={onClose}
      />
      <DialogContent>
        <input {...register('meetingID')} hidden />
        <Grid container>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={'remarkForPatientCallStatusID'}
              render={(renderProps) => {
                return (
                  <AppSelect
                    {...getFieldProps(renderProps)}
                    options={sourceStatuses.data}
                    loading={sourceStatuses.loading}
                    disabled={isLoading}
                    disableClearable
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={'remarks'}
              render={(renderProps) => {
                return (
                  <AppInput
                    {...getFieldProps(renderProps)}
                    multiline
                    minRows={3}
                    disabled={isLoading}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonCancel isBack={false} disabled={isLoading} onClick={onClose} />
        <ButtonSave isCreate={false} disabled={isLoading} onClick={handleSubmit(onSubmit)}>
          {tp('submit')}
        </ButtonSave>
      </DialogActions>
    </Dialog>
  );
};

export * from './models';
