import { DefaultGridButtonExportToExcel } from 'components/data-grid/model';
import { APP_FORMAT_DATE } from 'configs/const';
import {
  API_USER_PATIENT_PROFILE_SMS_MESSAGES,
  UserPatientProfileSmsMessage,
} from 'services/user-patient-profile-sms-messages';
import { excelTemplateDate } from 'utils/dates';

export default (userPatientProfileID: string): any => ({
  id: 'sms-message-patients',
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_SMS_MESSAGES.GET_ALL_DYNAMIC,
      filters: `userPatientProfileID == "${userPatientProfileID}"`,
    },
    sortable: {
      field: 'entryDate',
      order: 'desc',
    },
  },

  toolbar: {
    title: '',
    excelTitle: 'sms-messages',
    filters: [],
    controls: null,
    controlsDefault: {
      new: null,
      resetFilters: null,
      export: DefaultGridButtonExportToExcel,
    },
  },

  columns: [
    {
      title: 'entry-date',
      field: 'entryDate',
      template: {
        type: 'date',
        format: APP_FORMAT_DATE,
      },
      valueTemplate: (data: UserPatientProfileSmsMessage) => excelTemplateDate(data.entryDate),
    },
    {
      title: 'phone-number',
      field: 'toPhoneNumber',
    },
    {
      title: 'message',
      field: 'message',
    },
    {
      title: 'sent-by-employee',
      field: 'userEmployeeProfile.fullName as userEmployeeProfile',
      sortable: 'userEmployeeProfile',
    },
  ],
});
