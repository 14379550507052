import React from 'react';
import { BulkActionComponentProps } from '../../models';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { useTranslate } from 'hooks/use-translate';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectEmployee } from 'components/select-employee';
import { useEffectError, useFieldProps } from 'hooks';
import { ButtonSave } from 'components/app-buttons';
import { InferType } from 'yup';
import { apiUserPatientProfileDietDiaries } from 'services/user-patient-profile-diet-diaries';
import { isMutationFulfilled } from 'utils/rtk-query';

const endpoint = apiUserPatientProfileDietDiaries.endpoints.updateUserPatientProfileDietDiaryBulk;
const useUpdateMutation = endpoint.useMutation;

const schema = yup.object({
  assignToUserEmployeeProfileID: yup.string().required('rule-required').default(''),
});

type FormModel = InferType<typeof schema>;

export const BulkActionAssign: React.FC<BulkActionComponentProps> = ({
  selected,
  onClose,
  onRefresh,
}) => {
  const { tp } = useTranslate();
  const { control, errors, handleSubmit } = useForm({
    defaultValues: schema.cast({}),
    resolver: yupResolver(schema),
  });
  const getFieldProps = useFieldProps({ errors });

  const [performUpdate, resultUpdate] = useUpdateMutation();
  useEffectError(resultUpdate.error);

  const disabled = resultUpdate.isLoading;

  const onSubmit = async (formData: FormModel) => {
    const res = await performUpdate({ ids: selected.map(({ id }) => id), data: formData });

    if (isMutationFulfilled(res)) {
      onRefresh();
      onClose();
    }
  };

  return (
    <Dialog open={true} fullWidth={true} maxWidth={'sm'} onClose={disabled ? undefined : onClose}>
      <DialogHeading
        title={tp('assign-to-employee')}
        onClose={disabled ? undefined : onClose}
        disabled={disabled}
      />
      <DialogContent>
        <Controller
          control={control}
          name={'assignToUserEmployeeProfileID'}
          render={(renderProps) => {
            const props = getFieldProps(renderProps, { withLabel: false });
            return <SelectEmployee {...props} label={tp('employee')} disabled={disabled} />;
          }}
        />
      </DialogContent>
      <DialogActions>
        <ButtonSave isCreate={false} onClick={handleSubmit(onSubmit)} disabled={disabled} />
      </DialogActions>
    </Dialog>
  );
};
