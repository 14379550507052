import React, { useMemo } from 'react';
import { MODEL_TYPE, Row } from '../../models';
import { Button, Tooltip } from '@material-ui/core';
import TemplateDownloadPrivate from 'components/templates/template-download-private';
import { ActivitiesStatus } from '../activities-status';
import { DialogUserPatientProfileSession } from 'components/dialoog-user-patient-profile-session';
import { useOpen } from 'AurionCR/components/hooks';
import { useTranslate } from 'hooks/use-translate';
import { SupportMeetingActivitiesDialog } from 'components/support-meeting-activities/support-meeting-activities-dialog';
import { convertToDate } from 'utils/dates';
import { format } from 'date-fns';
import { APP_FORMAT_TIME } from 'configs/const';
import { DialogUserPatientProfileSubscriptionActivities } from 'components/user-patient-subscription-activities';

interface ColumnSessionProps {
  sessionID: string;
  userPatientProfileID: string;
  title?: string;
}
const ColumnSession: React.FC<ColumnSessionProps> = ({
  sessionID,
  title,
  userPatientProfileID,
}) => {
  const { t } = useTranslate();
  const { isOpen, handleClose, handleOpen } = useOpen();
  return (
    <>
      <Tooltip title={title || false}>
        <Button onClick={handleOpen} variant={'outlined'} color={'primary'}>
          {t('view-more')}
        </Button>
      </Tooltip>

      {isOpen && (
        <DialogUserPatientProfileSession
          itemID={sessionID}
          onClose={handleClose}
          userPatientProfileID={userPatientProfileID}
          isShowDelete={false}
        />
      )}
    </>
  );
};

interface ColumnClinicalMeetingProps {
  approveMeeting: boolean;
  completeSessionDateTime: string | null;
  arriveToClinicDateTime: string | null;
}
const ColumnClinicalMeeting: React.FC<ColumnClinicalMeetingProps> = ({
  approveMeeting,
  arriveToClinicDateTime,
  completeSessionDateTime,
}) => {
  const { t } = useTranslate();
  const title = useMemo(() => {
    if (!approveMeeting) {
      return t('not-approved');
    }
    if (completeSessionDateTime) {
      return [t('finished'), format(convertToDate(completeSessionDateTime), APP_FORMAT_TIME)]
        .filter(Boolean)
        .join(' ');
    }
    if (arriveToClinicDateTime) {
      return [t('in-clinic'), format(convertToDate(arriveToClinicDateTime), APP_FORMAT_TIME)]
        .filter(Boolean)
        .join(' ');
    }
    return t('approve-meeting');
  }, [approveMeeting, t, arriveToClinicDateTime, completeSessionDateTime]);
  return <ActivitiesStatus value={approveMeeting} title={title} />;
};

interface ColumnSupportMeetingActivitiesProps {
  supportMeetingID: string;
}
const ColumnSupportMeetingActivities: React.FC<ColumnSupportMeetingActivitiesProps> = ({
  supportMeetingID,
}) => {
  const { t } = useTranslate();
  const { isOpen, handleOpen, handleClose } = useOpen();

  return (
    <>
      <Tooltip title={t('view-more-activities')}>
        <Button onClick={handleOpen} variant={'outlined'} color={'primary'}>
          {t('view-more')}
        </Button>
      </Tooltip>

      {isOpen && (
        <SupportMeetingActivitiesDialog supportMeetingID={supportMeetingID} onClose={handleClose} />
      )}
    </>
  );
};

interface ColumnSubscriptionActivitiesProps {
  userPatientProfileSubscriptionID: string;
}
const ColumnSubscriptionActivities: React.FC<ColumnSubscriptionActivitiesProps> = ({
  userPatientProfileSubscriptionID,
}) => {
  const { t } = useTranslate();
  const { isOpen, handleOpen, handleClose } = useOpen();

  return (
    <>
      <Tooltip title={t('view-more-activities')}>
        <Button onClick={handleOpen} variant={'outlined'} color={'primary'}>
          {t('view-more')}
        </Button>
      </Tooltip>

      {isOpen && (
        <DialogUserPatientProfileSubscriptionActivities
          userPatientProfileSubscriptionID={userPatientProfileSubscriptionID}
          onClose={handleClose}
        />
      )}
    </>
  );
};

interface ColumnDownloadProps {
  row: Row;
  userPatientProfileID: string;
}
export const ColumnDownload: React.FC<ColumnDownloadProps> = ({ row, userPatientProfileID }) => {
  const { t } = useTranslate();
  if (row.type === MODEL_TYPE.TASK) {
    return <ActivitiesStatus value={row.done} title={t('done')} />;
  }
  if (row.type === MODEL_TYPE.SESSION) {
    return <ColumnSession sessionID={row.id} userPatientProfileID={userPatientProfileID} />;
  }
  if (row.type === MODEL_TYPE.CLINICAL_MEETING) {
    return (
      <ColumnClinicalMeeting
        approveMeeting={row.approveMeeting}
        completeSessionDateTime={row.completeSessionDateTime}
        arriveToClinicDateTime={row.arriveToClinicDateTime}
      />
    );
  }
  if (row.type === MODEL_TYPE.SUPPORT_MEETING) {
    return row.userPatientProfileSessionID ? (
      <ColumnSession
        title={t('view-more-notebooks')}
        sessionID={row.userPatientProfileSessionID}
        userPatientProfileID={userPatientProfileID}
      />
    ) : (
      <ColumnSupportMeetingActivities supportMeetingID={row.id} />
    );
  }
  if (row.type === MODEL_TYPE.SUBSCRIPTION) {
    return <ColumnSubscriptionActivities userPatientProfileSubscriptionID={row.id} />;
  }
  return <TemplateDownloadPrivate documentURL={row.download} />;
};
