import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import { TextFieldProps } from '@material-ui/core';
import { AppInput } from 'components/app-input';
import { useTranslate } from 'hooks/use-translate';
import React, { forwardRef, memo, useCallback, useMemo } from 'react';
import { SelectListBox, SelectPaper, SelectPopper } from './components';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  FilterOptionsState,
  Value as AutocompleteValue,
} from '@material-ui/lab';
import { useOpen } from 'AurionCR/components/hooks';

export const defaultGetOptionLabel = <T extends { title?: string }>(option: T) => {
  return option['title'] || '';
};

export const defaultGetOptionValue = <T extends { id?: string }>(option: T) => {
  return option['id'] || '';
};

const defOptions: any[] = [];

export const defaultGetOptionDisabled = <T extends Record<string, any> = { isActive?: boolean }>(
  option: T,
) => {
  return option?.isActive === false;
};

export interface AppSelectProps<
  T extends Record<string, any> = { id: string; title: string; isActive?: boolean },
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'value' | 'options' | 'onChange' | 'renderInput'
  > {
  getOptionValue?: (option: T) => string;
  readOnly?: boolean;
  label?: React.ReactNode;
  options?: T[];
  value?: Multiple extends true ? any[] : any;
  onChange?: (v: Multiple extends true ? any[] : any, option: T) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  required?: boolean;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  RenderInputProps?: Partial<Omit<TextFieldProps, 'value' | 'onChange'>>;
}

const AppSelectComponent = <
  T extends Record<string, any> = { id: string; title: string; isActive: boolean },
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>(
  {
    onChange,
    value,
    options = defOptions,
    error,
    helperText,
    loading = false,
    label,
    required,
    placeholder,
    RenderInputProps,
    getOptionDisabled = defaultGetOptionDisabled,
    limitTags = -1,
    readOnly,
    getOptionLabel = defaultGetOptionLabel,
    getOptionValue = defaultGetOptionValue,
    ...rest
  }: AppSelectProps<T, Multiple, DisableClearable, FreeSolo>,
  ref: any,
) => {
  const { isOpen, handleOpen, handleClose } = useOpen({ init: false });
  const InputProps = useMemo(() => {
    return {
      required,
      label,
      placeholder,
      error,
      helperText,
      disableClearable: true,
      ...RenderInputProps,
      inputRef: ref,
      inputProps: {
        autoComplete: 'nope',
        ...RenderInputProps?.inputProps,
      },
    };
  }, [required, label, placeholder, error, helperText, RenderInputProps, ref]);

  const _renderInput = useCallback(
    (p: AutocompleteRenderInputParams) => {
      return (
        <AppInput
          {...InputProps}
          {...p}
          InputLabelProps={{
            ...p.InputLabelProps,
            ...InputProps.InputLabelProps,
          }}
          inputProps={{
            ...InputProps.inputProps,
            ...p.inputProps,
          }}
          InputProps={{
            readOnly: readOnly,
            ...InputProps?.InputProps,
            ...p.InputProps,
            endAdornment: (
              <>
                {p.InputProps.endAdornment}
                {InputProps?.InputProps?.endAdornment}
              </>
            ),
          }}
        />
      );
    },
    [InputProps, readOnly],
  );

  const _renderOption = useCallback(
    (option: T) => {
      return getOptionLabel(option);
    },
    // eslint-disable-next-line
    [getOptionLabel],
  );

  const filterOptions = rest.filterOptions;
  const _filterOptions = useCallback(
    (options: Array<T>, state: FilterOptionsState<T>) => {
      const filtered = filterOptions
        ? filterOptions(options, state)
        : options.filter((item: T) =>
            state.getOptionLabel(item).toLowerCase().includes(state.inputValue.toLowerCase()),
          );
      return filtered;
    },
    // eslint-disable-next-line
    [filterOptions],
  );

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<{}>,
      option: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
    ) => {
      if (Array.isArray(option)) {
        const value = option
          .map((optionItem) => getOptionValue(optionItem as T))
          .filter((v) => !!v);
        onChange && onChange(value, option as any);
      } else {
        const value = (option && getOptionValue(option as T)) || '';
        onChange && onChange(value as any, option as any);
      }
    },
    // eslint-disable-next-line
    [onChange, getOptionValue],
  );

  const selectedValue = useMemo(() => {
    const ops = options || [];
    if (Array.isArray(value)) {
      const values = value || [];
      return ops.filter((option) => values.some((v: any) => getOptionValue(option) === v));
    } else if (value) {
      return ops.find((option) => getOptionValue(option) === value) || '';
    } else {
      return rest.multiple ? [] : '';
    }
  }, [value, getOptionValue, rest.multiple, options]) as AutocompleteValue<
    T,
    Multiple,
    DisableClearable,
    FreeSolo
  >;

  const { t } = useTranslate();
  const noOptionsText = t('no-options');
  return (
    <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
      value={selectedValue}
      noOptionsText={noOptionsText}
      getOptionDisabled={getOptionDisabled}
      PopperComponent={SelectPopper}
      ListboxComponent={SelectListBox}
      open={isOpen}
      onOpen={readOnly ? undefined : handleOpen}
      onClose={handleClose}
      {...rest}
      getOptionLabel={getOptionLabel}
      disableClearable={(readOnly ? true : rest.disableClearable) as any}
      renderInput={rest.renderInput || _renderInput}
      renderOption={rest.renderOption || _renderOption}
      options={options}
      onChange={handleChange}
      PaperComponent={SelectPaper}
      loading={loading}
      popupIcon={
        readOnly ? null : (
          <ArrowDropDownRoundedIcon color={'inherit'} style={{ color: 'rgba(0,0,0,0.3)' }} />
        )
      }
      limitTags={limitTags}
      filterOptions={_filterOptions}
    />
  );
};

export const AppSelect = memo(forwardRef(AppSelectComponent)) as typeof AppSelectComponent;
