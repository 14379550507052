import { createDefaultMeetingTimes } from 'utils/app-helpers';

export const DailyViewDnDAccept = 'DailyViewDnDAccept';
export const createTimes = () => {
  return createDefaultMeetingTimes();
};
export enum CLINICAL_MEETING_PATIENT_OPTIONS {
  ALL = 'all-patients',
  INACTIVE = 'inactive-patients',
  ACTIVE = 'active-patients',
}
export const makeFilterPatientsActive = (value: CLINICAL_MEETING_PATIENT_OPTIONS) => {
  switch (value) {
    case CLINICAL_MEETING_PATIENT_OPTIONS.ALL: {
      return null;
    }
    case CLINICAL_MEETING_PATIENT_OPTIONS.ACTIVE: {
      return 'userPatientProfile.isActive==true';
    }
    case CLINICAL_MEETING_PATIENT_OPTIONS.INACTIVE: {
      return 'userPatientProfile.isActive==false';
    }
  }
};
