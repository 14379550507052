import React, {FC, memo} from "react";
import {IDataGridMadel} from "../../model";
import {Typography} from "@material-ui/core";
import {useI18n} from "AurionCR/components";

const Toolbar: FC<{
  model: IDataGridMadel,
}> = ({model}) => {
  const {t} = useI18n();

  if (!model?.toolbar?.visible) return null;
  return model.toolbar.title
    ? <div className="toolbar-head">
      <Typography variant="h4" className="title" color="secondary">{t(model.toolbar.title)}</Typography>
    </div>
    : null;
};

export default memo(Toolbar);
