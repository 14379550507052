import React, { memo, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { IDataGridMadel, IDataGridRowProps } from '../model';
import Head from './head';
import { GridDndProvider } from './dnd-provider';
import { NativeScroll, NativeScrollProps } from 'components/native-scroll';
import clsx from 'clsx';
import { throttle } from 'lodash-es';
import { Box } from '@material-ui/core';

interface Props {
  rowProps?: IDataGridRowProps;
  ScrollProps?: Partial<NativeScrollProps>;
  model: IDataGridMadel;
  data: any[];
  sortable: any;
  rowControls?: ReactNode;
  preview?: boolean;
  pagination: any;
  isHideSwap: boolean;
}

export default memo<Props>(
  ({
    model,
    ScrollProps,
    data,
    sortable,
    rowControls,
    preview,
    pagination,
    isHideSwap,
    rowProps,
  }) => {
    const elScroll = useRef<HTMLDivElement>(null);
    const [scroll, setScroll] = useState({ x: 0, y: 0, '-x': 0 });

    const onScroll = useMemo(() => {
      return throttle(() => {
        const element = elScroll.current;

        if (!element) return;

        const x_ = Math.ceil(Math.abs(element.scrollLeft));
        const x__ = element.clientWidth - element.scrollWidth + x_;
        const y = element.scrollTop;
        const payload = { x: x_, '-x': x__, y };
        setScroll(payload);
      }, 300);
    }, []);

    useEffect(() => {
      const element = elScroll.current;

      if (!element) return;

      const resizeObserver = new ResizeObserver(() => {
        onScroll();
      });

      resizeObserver.observe(element);

      return () => {
        resizeObserver.unobserve(element);
      };
    }, [onScroll]);

    return (
      <Box
        position={'relative'}
        width={'100%'}
        height={'100%'}
        minHeight={'20rem'}
        flex={'1 1 auto'}
      >
        <NativeScroll
          {...ScrollProps}
          className={clsx('data-grid-scroll', ScrollProps?.className)}
          style={{ position: 'absolute', ...ScrollProps?.style }}
          ref={elScroll}
          onScroll={onScroll}
        >
          <table
            className={clsx({
              'is-scroll-x': scroll.x,
              'is-scroll--x': scroll['-x'],
              'is-scroll-y': scroll.y,
            })}
          >
            <Head
              isHideSwap={isHideSwap}
              model={model}
              sortable={sortable}
              rowControls={rowControls}
              preview={preview}
            />
            <GridDndProvider
              isHideSwap={isHideSwap}
              model={model}
              data={data}
              rowControls={rowControls}
              preview={preview}
              pagination={pagination}
              rowProps={rowProps}
            />
          </table>
        </NativeScroll>
      </Box>
    );
  },
);
