import React, { useMemo } from 'react';
import { useTranslate } from 'hooks/use-translate';
import {
  Button,
  ButtonProps,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { Stack } from 'components/stack';
import { Alert, AlertTitle } from '@material-ui/lab';
import { TableListValidity } from './components';
import style from './index.module.scss';

export interface ListItem {
  title: React.ReactNode;
  info?: string;
  value: any;
  isValid: boolean;
}

interface Dictionary {
  title: string;
  description: string;
}

interface Props {
  title: string;
  list: ListItem[];
  success: Dictionary;
  error: Dictionary;
  isLoading: boolean;
  isLoadedSuccess: boolean;
  ButtonSubmitProps?: Partial<ButtonProps>;
  onSubmit: () => void;
  onClose: () => void;
}

export const DialogValidity: React.FC<Props> = ({
  title,
  list,
  isLoading,
  isLoadedSuccess,
  onSubmit,
  onClose,
  success,
  error,
  ButtonSubmitProps,
}) => {
  const { t } = useTranslate();

  const isListValid = useMemo(() => {
    if (!isLoadedSuccess) {
      return false;
    }
    return list.every((field) => field.isValid);
  }, [list, isLoadedSuccess]);

  const dictionary = isListValid ? success : error;

  return (
    <Dialog open={true} fullWidth={true} maxWidth={'sm'} onClose={onClose}>
      <DialogTitle disableTypography={true}>
        <Stack width={'100%'} direction={'row'} alignItems={'center'} spacing={1}>
          <Typography color={'secondary'} variant={'h4'} component={'h2'} style={{ flexGrow: 1 }}>
            {title}
          </Typography>

          {isLoading && <CircularProgress size={16} />}
        </Stack>
      </DialogTitle>
      <div className={style.content}>
        <Collapse in={!isLoading}>
          <Alert severity={isListValid ? 'success' : 'error'} className={style.alert}>
            <AlertTitle>{dictionary.title}</AlertTitle>
            {dictionary.description}
          </Alert>
        </Collapse>
        <TableListValidity isLoading={isLoading} items={list} />
      </div>
      <DialogActions>
        <Button color={'primary'} onClick={onClose}>
          {t('close')}
        </Button>
        <Button
          variant={'contained'}
          color={'primary'}
          {...ButtonSubmitProps}
          disabled={isLoading || !isListValid || ButtonSubmitProps?.disabled}
          onClick={onSubmit}
        >
          {ButtonSubmitProps?.children ?? t('create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
