import React, { useCallback } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { useEffectError } from 'hooks';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import { apiUserPatientProfile, createBackdoorLink } from 'services/user-patient-profile';
import { isMutationFulfilled } from 'utils/rtk-query';

const MINUTES = 60 * 24 * 7;
interface Props extends ButtonProps {
  userPatientProfileID: string;
}

export const ButtonBackDoor: React.FC<Props> = ({ userPatientProfileID, ...rest }) => {
  const { t } = useTranslate();

  const [makeBackDoor, { isLoading, error }] =
    apiUserPatientProfile.useMakePatientBackDoorMutation();
  useEffectError(error);

  const onMake = useCallback(async () => {
    const res = await makeBackDoor({ userPatientProfileID, minutes: MINUTES });

    if (isMutationFulfilled(res) && res.data) {
      window.open(
        createBackdoorLink({
          userPatientProfileID: res.data.userPatientProfileID,
          secret: res.data.secret,
        }),
        '_blank',
      );
    }
  }, [userPatientProfileID, makeBackDoor]);

  return (
    <Button
      variant={'contained'}
      color={'primary'}
      startIcon={<PhoneIphoneIcon />}
      disabled={isLoading}
      onClick={onMake}
      {...rest}
    >
      {t('back-door')}
    </Button>
  );
};
