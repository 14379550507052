import React, { FC, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffectError, useFieldProps } from '../../../../hooks';
import { ClickAwayListener } from '@material-ui/core';
import Footer from 'components/data-grid/components/column/editors/footer';
import { apiTodoTasks, schemaTodoTaskUpdate, TodoTask } from 'services/todo-tasks';
import { InferType } from 'yup';
import { useTranslate } from 'hooks/use-translate';
import { isMutationFulfilled } from 'utils/rtk-query';
import { useDataGridTasks } from 'components/data-grid/hooks';
import { AppInput } from 'components/app-input';

const schema = schemaTodoTaskUpdate.pick(['message']);
type FormData = InferType<typeof schema>;

const useUpdateMutation = apiTodoTasks.useUpdateTodoTaskWithLogMutation;
type Row = Pick<TodoTask, 'message' | 'id'>;
interface Props {
  row: Row;
  onClose: () => void;
}

export const EditorMessage: FC<Props> = ({ row, onClose }) => {
  const { onTasks } = useDataGridTasks<Row>();
  const { tp } = useTranslate();
  const { handleSubmit, errors, control } = useForm({
    defaultValues: schema.cast(row, { stripUnknown: true, assert: false }),
    resolver: yupResolver(schema),
  });

  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

  const [update, resultUpdate] = useUpdateMutation();
  useEffectError(resultUpdate.error);

  const onSubmit = useCallback(
    async (formData: FormData) => {
      const res = await update({
        initData: row,
        formData: { ...formData, id: row.id },
        remark: tp('updates-by-employee'),
      });

      if (isMutationFulfilled(res)) {
        onTasks([['getData']]);
        onClose();
      }
    },
    [row, update, tp, onTasks, onClose],
  );

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className="grid-editor string">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="message"
            control={control}
            render={(renderProps) => {
              return (
                <AppInput
                  {...getFieldProps(renderProps)}
                  autoFocus
                  fullWidth
                  label={null}
                  disabled={resultUpdate.isLoading}
                />
              );
            }}
          />

          <Footer onClose={onClose} disabled={resultUpdate.isLoading} />
        </form>
      </div>
    </ClickAwayListener>
  );
};
