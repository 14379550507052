import { NotebookFormGeneratorProps } from '../notebook-form-generator';
import React, { useCallback } from 'react';
import { UserPatientProfileSession } from 'services/user-patient-profile-session';
import { isMutationFulfilled, isMutationRejected } from 'utils/rtk-query';
import { useCurrentUser } from '../../../hooks';
import { useEffectError } from 'hooks';
import { apiClinicalMeetings } from 'services/clinical-meetings';
import { useAttachNotebookToClinicalMeeting } from 'hooks/use-attach-notebook-to-clinical-meeting';

const useCreateNewMeeting = apiClinicalMeetings.useCreatePatientTodayClinicalMeetingMutation;

type Session = Pick<UserPatientProfileSession, 'id'>;

interface Props
  extends Pick<
    Required<NotebookFormGeneratorProps>,
    'onDone' | 'notebookID' | 'userPatientProfileID'
  > {
  isLoading?: boolean;
}

export const withOutOfSchedule = <P extends Props>(Component: React.FC<P>) => {
  const ComponentWithOutOfSchedule = ((props: P) => {
    const { userPatientProfileID, onDone: _onDone } = props;

    const { appUserID } = useCurrentUser();
    const [triggerNewMeeting, resultNewMeeting] = useCreateNewMeeting();
    useEffectError(resultNewMeeting.error);

    const [{ handleDone }, resultAttach] = useAttachNotebookToClinicalMeeting();

    const onDone = useCallback(
      async (session: Session) => {
        const res = await triggerNewMeeting({ userPatientProfileID });

        if (isMutationRejected(res)) {
          return res;
        }

        const resMeeting = await handleDone({
          id: res.data.id,
          userPatientProfileSessionID: session.id,
          userEmployeeProfileID: String(appUserID),
        });

        if (isMutationFulfilled(resMeeting)) {
          _onDone(session);
        }
      },
      [appUserID, triggerNewMeeting, handleDone, userPatientProfileID, _onDone],
    );

    const isLoading = resultAttach.isLoading || props.isLoading;
    return <Component {...props} isLoading={isLoading} onDone={onDone} />;
  }) as React.FC<P>;

  return ComponentWithOutOfSchedule;
};
