import React, { useMemo } from 'react';
import { ButtonRedirect } from 'components/app-buttons';
import { APP_ROUTES } from 'configs/routes';
import { useLocation } from 'react-router-dom';
import { useTranslate } from 'hooks/use-translate';
import { Badge } from '@material-ui/core';
import style from './index.module.scss';
import clsx from 'clsx';
import { apiUserPatientPrescriptions } from 'services/user-patient-profile-prescriptions';

const useFetchQuery = apiUserPatientPrescriptions.useGetMedicalPrescriptionsHoldExpiredQuery;

export const OnHoldPrescriptions: React.FC = () => {
  const { tp } = useTranslate();
  const { pathname } = useLocation();

  const result = useFetchQuery(undefined, {
    // ms // s // m
    pollingInterval: 1000 * 60 * 10,
  });

  const rows = useMemo(() => {
    return result.data || [];
  }, [result.data]);

  return (
    <Badge badgeContent={rows.length} color={'error'}>
      <ButtonRedirect
        className={clsx(style.root)}
        to={{
          ...APP_ROUTES.PATIENT_PRESCRIPTIONS_HOLD_EXPIRED().config,
          state: { prev: pathname },
        }}
      >
        {tp('prescriptions-on-hold-expired')}
      </ButtonRedirect>
    </Badge>
  );
};
