import React, { memo, useMemo } from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { Box, BoxProps } from '@material-ui/core';
import { formatMeetingDate } from 'utils/formates';
import { DateValue } from 'utils/dates';
import { useAppSelector } from 'store';
import { selectLanguageIsRtl } from 'store/languages';

interface Meeting {
  meetingFromDateTime: DateValue;
  meetingToDateTime: DateValue;
}

interface Props extends BoxProps {
  meeting: Meeting;
  dateFormat?: string;
  timeFormat?: string;
}

export const TemplateMeetingDate = memo<Props>(
  ({ className, meeting, dateFormat, timeFormat, ...rest }) => {
    const isRtl = useAppSelector(selectLanguageIsRtl);
    const viewDate = useMemo(() => {
      return formatMeetingDate(meeting, {
        isHtml: true,
        includeMeetingTime: true,
        dateFormat,
        timeFormat,
      });
    }, [meeting, dateFormat, timeFormat]);

    return (
      <Box
        className={clsx(style.root, className)}
        {...rest}
        style={{ textAlign: isRtl ? 'right' : 'left', ...rest?.style }}
        dangerouslySetInnerHTML={{ __html: viewDate || '' }}
      />
    );
  },
);
