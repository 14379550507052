import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BaseCreateComponentProps } from '../../models';
import { useEffectError, useFetchDynamic, useFieldProps } from 'hooks';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import {
  ServiceUserPatientProfileSubscriptions,
  UserPatientProfileSubscription,
} from 'services/user-patient-profile-subscriptions';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import { convertToDate, dateFormat } from 'utils/dates';
import { ServiceClinicalMeetings } from 'services/clinical-meetings';
import { PERMISSION_IDS } from 'services/user-employee-profile-permissions';
import { apiPdf } from 'services/pdf';
import { useAppSelector } from 'store';
import { selectLanguageID } from 'store/languages';
import { apiPdfContent } from 'services/pdf-content';
import { isMutationFulfilled } from 'utils/rtk-query';
import { DialogEditHtmlContent } from 'components/dialog-edit-html-content';
import { Button, Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { Controller, useController, useForm } from 'react-hook-form';
import { AppSelect } from 'components/app-select';
import { AppPrescriptionDrugsAreaInput } from 'components/app-prescription-drugs-area-input';
import { AppChipAreaInput } from 'components/app-chip-area-input';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslate } from 'hooks/use-translate';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { apiUserPatientPrescriptions } from 'services/user-patient-profile-prescriptions';
import { useSourceBmiSummaries, useSourceDiagnosisTypes } from '../../../../../hooks';
import { useUpdatePatientDataFromContent } from '../../../../hooks/use-update-patient-data-from-content';

const useFetchPrescriptionsQuery =
  apiUserPatientPrescriptions.useGetMedicalPrescriptionsWithDrugsQuery;

const fetchLatestSubscription = (userPatientProfileID: string) => {
  return ServiceUserPatientProfileSubscriptions.getDynamicUserLatest<
    Pick<UserPatientProfileSubscription, 'id'>
  >(userPatientProfileID, {
    select: 'id',
  });
};

const schema = yup.object({
  drugs: yup
    .array()
    .of(
      yup.object({
        id: yup.string(),
        title: yup.string(),
      }),
    )
    .min(1, 'rule-required')
    .default([]),

  diagnosisTypeID: yup.string().required('rule-required').default(null),
  bmiSummaryID: yup.string().required('rule-required').default(null),
});
const defaultValues = schema.cast({}, { assert: false });
type FormModel = typeof defaultValues;

const useGenerateDocumentMutation = apiPdf.useGenerateDocumentEndTreatmentDetailsMutation;
const useGenerateContentMutation = apiPdfContent.useGenerateContentEndTreatmentDetailsMutation;
const useFetchPatientDetailsLazyQuery =
  apiUserPatientProfile.useLazyGetPatientDetailsForPdfEndOfTreatmentDetailsQuery;

type ApplyState = { content: string; title: string };
interface DialogCreateContentProps {
  userPatientProfileID: string;
  onClose: () => void;
  onApply: (state: ApplyState) => void;
}
export const DialogCreateContent = memo<DialogCreateContentProps>(
  ({ userPatientProfileID, onClose, onApply }) => {
    const [updateUser, resultUpdateUser, patient] = useUpdatePatientDataFromContent({
      userPatientProfileID,
    });
    useEffectError(resultUpdateUser.error);

    const { tp } = useTranslate();
    const languageID = useAppSelector(selectLanguageID);

    const sourcePrescriptions = useFetchPrescriptionsQuery(userPatientProfileID);
    useEffectError(sourcePrescriptions.error);

    const sourceDiagnosisTypes = useSourceDiagnosisTypes();
    const sourceBmiSummaries = useSourceBmiSummaries();

    const prescriptionOptions = useMemo(() => {
      return (sourcePrescriptions.data || []).map((prescription) => {
        return {
          id: String(prescription.id),
          title: dateFormat(prescription.entryDate, APP_FORMAT_DATE_TIME),
          isCompleted: !!prescription.isCompleted,
          entryDate: convertToDate(prescription.entryDate),
          items: prescription.drugs.map((drug) => {
            return { id: String(drug.id), title: String(drug.drugNameEng) };
          }),
        };
      });
    }, [sourcePrescriptions.data]);

    const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
    const { errors, control, handleSubmit, setValue } = methods;
    const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

    const refInit = useRef(false);
    useEffect(() => {
      if (refInit.current) return;
      if (!patient) return;

      // issue with the set form
      requestAnimationFrame(() => {
        setValue('bmiSummaryID', patient.bmiSummaryID);
        setValue('diagnosisTypeID', patient.diagnosisTypeID);
      });

      refInit.current = true;
    }, [patient, setValue]);

    const [generate, resultContent] = useGenerateContentMutation();
    useEffectError(resultContent.error);

    const [triggerFetchDetails, resultDetails] = useFetchPatientDetailsLazyQuery();
    useEffectError(resultDetails.error);

    const [triggerSubscription, resultSubscription] = useFetchDynamic(fetchLatestSubscription);
    useEffectError(resultSubscription.error);

    const onGenerate = useCallback(
      async (formData: FormModel) => {
        const resUpdate = await updateUser({
          diagnosisTypeID: formData.diagnosisTypeID,
          bmiSummaryID: formData.bmiSummaryID,
        });

        let drugs = formData.drugs
          .filter((d) => d.title)
          .map((d) => ({ drugName: String(d.title) }));

        if (!isMutationFulfilled(resUpdate)) return;

        const resSubscription = await triggerSubscription(userPatientProfileID);

        if (!resSubscription.data) return;

        const resDetails = await triggerFetchDetails({
          userPatientProfileID,
          userPatientProfileSubscriptionID: String(resSubscription.data.id),
        });

        if (!resDetails.data) return;

        const startDate = startOfDay(convertToDate(resDetails.data.subscription.startDate));
        const endDate = endOfDay(addDays(convertToDate(resDetails.data.subscription.endDate), 14));

        const [
          {
            data: { value: meetings },
          },
        ] = await Promise.all([
          ServiceClinicalMeetings.getAllDynamic<
            Pick<
              Components.Schemas.ClinicalMeeting,
              'meetingFromDateTime' | 'meetingToDateTime' | 'userEmployeeProfile'
            >
          >({
            filter: [
              `userPatientProfileID=="${userPatientProfileID}"`,
              `clinicalMeetingSubject.isCanceledMeeting==false`,
              `meetingFromDateTime >= DateTime.parse("${startDate.toISOString()}")`,
              `meetingToDateTime <= DateTime.parse("${endDate.toISOString()}")`,
              `(userEmployeeProfile.userEmployeeProfilePermissionID=="${PERMISSION_IDS.DOCTOR}" || userEmployeeProfile.userEmployeeProfilePermissionID=="${PERMISSION_IDS.DIETITIAN}")`,
            ].join('&&'),
            select: [
              'meetingFromDateTime',
              'meetingToDateTime',
              'new { userEmployeeProfile.userEmployeeProfilePermissionID } as userEmployeeProfile',
            ].join(','),
          }),
        ]);

        const res = await generate({
          languageID,
          payload: {
            meetings,
            drugs,
            subscription: {
              visitDietitian: resDetails.data.subscription?.visitDietitian,
              listPrice: resDetails.data.subscription?.listPrice,
              title: resDetails.data.subscription?.labelKey,
              price: resDetails.data.subscription?.price,
              durationMonths: resDetails.data.subscription?.durationMonths,
              startDate: resDetails.data.subscription?.startDate,
            },
            patient: resDetails.data,
          },
        });

        if (isMutationFulfilled(res)) {
          onApply(res.data);
        }
      },
      [
        userPatientProfileID,
        languageID,
        onApply,
        generate,
        triggerFetchDetails,
        triggerSubscription,
        updateUser,
      ],
    );

    const controlDrugs = useController({ control, name: 'drugs' });

    const isLoading =
      resultDetails.isLoading || resultSubscription.isLoading || resultContent.isLoading;

    return (
      <Dialog open={true} onClose={onClose} fullWidth maxWidth={'lg'}>
        <DialogHeading
          title={tp('create-letter-to-med-insurance')}
          isLoading={isLoading}
          onClose={onClose}
        />
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid md={7} item>
              <Controller
                control={control}
                name={'diagnosisTypeID'}
                render={(renderProps) => {
                  const props = getFieldProps(renderProps);
                  return (
                    <AppSelect
                      {...props}
                      options={sourceDiagnosisTypes.data}
                      loading={sourceDiagnosisTypes.loading}
                      disabled={isLoading || !!patient?.diagnosisTypeID}
                      disableClearable
                    />
                  );
                }}
              />
            </Grid>
            <Grid md={5} item>
              <Controller
                control={control}
                name={'bmiSummaryID'}
                render={(renderProps) => {
                  const props = getFieldProps(renderProps);
                  return (
                    <AppSelect
                      {...props}
                      options={sourceBmiSummaries.data}
                      loading={sourceBmiSummaries.loading}
                      disabled={isLoading || !!patient?.bmiSummaryID}
                      disableClearable
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid md={7} item>
              <AppPrescriptionDrugsAreaInput
                {...getFieldProps(controlDrugs.field)}
                label={tp('prescriptions')}
                options={prescriptionOptions}
                disabled={isLoading}
              />
            </Grid>
            <Grid md={5} item>
              <AppChipAreaInput {...getFieldProps(controlDrugs.field)} disabled={isLoading} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color={'primary'} onClick={onClose}>
            {tp('cancel')}
          </Button>
          <Button
            color={'primary'}
            variant={'contained'}
            disabled={isLoading}
            onClick={handleSubmit(onGenerate)}
          >
            {tp('apply')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

export const DialogCreateDocDetailsOfTreatment = memo<BaseCreateComponentProps>(
  ({ userPatientProfileID, onClose, onDone }) => {
    const [state, setState] = useState<ApplyState>();

    const [generate, resultGenerate] = useGenerateDocumentMutation();
    useEffectError(resultGenerate.error);

    const onGenerate = useCallback(
      async (content: string) => {
        if (!state) return;
        const res = await generate({ content, title: state.title, userPatientProfileID });

        if (isMutationFulfilled(res)) {
          onDone();
        }
      },
      [userPatientProfileID, onDone, generate, state],
    );

    const isLoading = resultGenerate.isLoading;

    if (!state) {
      return (
        <DialogCreateContent
          userPatientProfileID={userPatientProfileID}
          onClose={onClose}
          onApply={setState}
        />
      );
    }

    return (
      <DialogEditHtmlContent
        isLoading={isLoading}
        title={state.title}
        content={state.content}
        onGenerate={onGenerate}
        onCancel={onClose}
      />
    );
  },
);
