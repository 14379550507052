export const FormDocumentFieldInputIDTransform: Record<string, string> = {
  firstName: 'firstName',
  lastName: 'lastName',
  fullName: 'fullName',
  idNumber: 'idNumber',
  dateOfBirth: 'dateOfBirth',
  mobilePhone: 'mobilePhone',
  email: 'email',
  familyMemberName: 'familyMemberFirstName + " " + familyMemberLastName as familyMemberName',
  familyMemberFirstName: 'familyMemberFirstName',
  familyMemberLastName: 'familyMemberLastName',
  familyMemberPhone: 'familyMemberPhone',
  familyMemberIDNumber: 'familyMemberIDNumber',
  familyMemberRelationship: 'familyMemberRelationship',
  address: 'address',
  // signature
  startDate: 'dateTime.now as startDate',
  endDate: 'dateTime.now.addMonths(3) as endDate',
  docDate: 'dateTime.now as docDate',
  docTime: 'dateTime.now as docTime',
};
