import React, { memo } from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { Avatar, AvatarProps, Box, BoxProps } from '@material-ui/core';

interface Props extends BoxProps {
  color?: string;
  iconSrc?: string;
  AvatarProps?: Partial<AvatarProps>;
}

export const TemplateIcon = memo<Props>(({ color, iconSrc, AvatarProps, className, ...rest }) => {
  return (
    <Box
      className={clsx(style.root, className)}
      {...rest}
      sx={{
        bgcolor: color,
        ...rest?.sx,
      }}
    >
      <Avatar
        src={iconSrc}
        {...AvatarProps}
        style={{ width: 28, height: 28, ...AvatarProps?.style, padding: '.4rem' }}
      />
    </Box>
  );
});
