import { DefaultGridButtonExportToExcel } from 'components/data-grid/model';
import { TemplateLink } from 'components/templates/template-link';
import { TemplateNumber } from 'components/templates/template-number';
import { ColumnActivities } from 'components/user-patient-subscription-activities';
import { APP_FORMAT_DATE } from 'configs/const';
import { GridRef } from 'models/grid';
import React from 'react';
import {
  API_USER_PATIENT_PROFILE_SUBSCRIPTIONS,
  UserPatientProfileSubscription,
} from 'services/user-patient-profile-subscriptions';
import { excelTemplateDate } from 'utils/dates';
import { ColumnTreatment, EditorPatientSubscriptionEndDate } from './components';
import { GridModel } from './models';
import { permissionKey } from 'services/user-employee-profile-permissions';

export default (userPatientProfileID: string, ref: GridRef): any => ({
  id: 'grid_user-patient-profile-subscriptions',
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_SUBSCRIPTIONS.GET_ALL_DYNAMIC,
      filters: `userPatientProfileID=="${userPatientProfileID}"`,
      selected:
        'userPatientProfileID,subscription.rowIndex as subscriptionRowIndex, new { userPatientProfile.isActive } as patient',
    },
    create: {
      url: API_USER_PATIENT_PROFILE_SUBSCRIPTIONS.POST,
    },
    patch: {
      url: API_USER_PATIENT_PROFILE_SUBSCRIPTIONS.PATCH,
    },
    delete: {
      url: API_USER_PATIENT_PROFILE_SUBSCRIPTIONS.DELETE,
    },
    sortable: {
      field: 'paymentDate',
      order: 'desc',
    },
  },
  toolbar: {
    title: '',
    excelTitle: 'commercial',
    controls: null,
    controlsDefault: {
      new: null,
      resetFilters: null,
      export: DefaultGridButtonExportToExcel,
    },
  },
  columns: [
    {
      title: 'payment-date',
      field: 'paymentDate',
      template: {
        type: 'date',
        format: APP_FORMAT_DATE,
      },
      valueTemplate: (data: UserPatientProfileSubscription) => excelTemplateDate(data.paymentDate),
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'subscription-title',
      field: 'subscription.labelKey as subscription',
      sortable: 'subscription.labelKey',
      tdClasses: 'min-width-200',
    },
    {
      title: 'start-treatment-date',
      field: 'startDate',
      template: {
        type: 'date',
        format: APP_FORMAT_DATE,
      },
      valueTemplate: (data: UserPatientProfileSubscription) => excelTemplateDate(data.startDate),
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'end-date',
      field: 'endDate',
      template: {
        type: 'date',
        format: APP_FORMAT_DATE,
      },
      valueTemplate: (data: UserPatientProfileSubscription) => excelTemplateDate(data.endDate),
      editor: {
        type: 'custom',
        component: EditorPatientSubscriptionEndDate,
        permission: permissionKey('isAllowToChangeSubscriptionDates'),
      },
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'activities',
      field: 'userPatientProfileSubscriptionActivities.Count() as activitiesCount',
      sortable: 'userPatientProfileSubscriptionActivities.Count()',
      template: (data: GridModel) => (
        <ColumnActivities
          userPatientProfileSubscriptionID={String(data.id)}
          activities={data.activitiesCount}
        />
      ),
    },
    {
      title: 'duration-months',
      field: 'subscription.durationMonths as durationMonths',
      sortable: 'subscription.durationMonths',
      tdClasses: 'mobile-15',
    },
    {
      title: 'starting-weight',
      field: 'startingWeight',
    },
    {
      title: 'current-weight',
      field: 'currentWeight',
    },
    {
      title: 'starting-bmi',
      field: 'startingBMI',
    },
    {
      title: 'current-bmi',
      field: 'currentBMI',
    },
    {
      title: 'list-price',
      field: 'subscription.listPrice as listPrice',
      sortable: 'subscription.listPrice',
      tdClasses: 'mobile-15',
    },
    {
      title: 'price',
      field: 'price',
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'refund-date',
      field: 'refundDate',
      template: {
        type: 'date',
        format: APP_FORMAT_DATE,
      },
      valueTemplate: (data: GridModel) => excelTemplateDate(data.refundDate),
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'refund-amount',
      field: 'refundAmount',
      tdClasses: 'mobile-no-wrap',
      template: (data: GridModel) => <TemplateNumber value={data.refundAmount} />,
    },
    {
      title: 'refund-remark',
      field: 'refundRemark',
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'refund-doc-url',
      field: 'refundDocUrl',
      template: (data: GridModel) => (
        <TemplateLink documentURL={data.refundDocUrl} labelKey={'refund-doc'} />
      ),
    },
    {
      title: 'invoice-doc-url',
      field: 'invoiceDocUrl',
      template: (data: GridModel) => (
        <TemplateLink documentURL={data.invoiceDocUrl} labelKey={'display-invoice'} />
      ),
    },
    {
      title: 'end-of-treatment-doc-url',
      field: 'endOfTreatmentDocUrl',
      template: (data: GridModel) => <ColumnTreatment data={data} grid={ref} />,
    },
  ],
});
