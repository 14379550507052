import React, { forwardRef } from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { Button, BoxProps } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
interface Props extends BoxProps {}

export const MeetingSlotCreate = forwardRef<HTMLButtonElement, Props>(
  ({ className, ...rest }, ref) => {
    return (
      <Button ref={ref} variant={'contained'} {...rest} className={clsx(style.root, className)}>
        <AddIcon />
      </Button>
    );
  },
);
