import React, { memo, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { pdfFormDocumentMerge } from '../../store';
import { NativeScroll } from 'components/native-scroll';

export const Scroll = memo(({ children }) => {
  const dispatch = useAppDispatch();
  //state
  const { eventScrollTo } = useAppSelector((state) => state.pdfFormDocument);
  const ref = useRef<HTMLDivElement>(null);
  // watch
  useEffect(() => {
    const scroll = () => {
      const element = ref.current;
      if (!element) return;
      if (!eventScrollTo) return;

      const top = eventScrollTo.pageNumber
        ? eventScrollTo.top
        : eventScrollTo.top - element.clientHeight / 2;

      const left = eventScrollTo.left;

      element.scrollTo({
        top,
        left,
        behavior: 'smooth',
      });
      dispatch(pdfFormDocumentMerge({ eventScrollTo: null }));
    };
    scroll();
  }, [ref, eventScrollTo, dispatch]);

  return <NativeScroll ref={ref}>{children}</NativeScroll>;
});

export default Scroll;
