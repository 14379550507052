import React, { FC, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffectError, useFieldProps } from '../../../../hooks';
import { ClickAwayListener } from '@material-ui/core';
import Footer from 'components/data-grid/components/column/editors/footer';
import { AppDatePicker } from 'components/app-date-picker';
import { apiTodoTasks, schemaTodoTaskUpdate, TodoTask } from 'services/todo-tasks';
import { useTranslate } from 'hooks/use-translate';
import { isMutationFulfilled } from 'utils/rtk-query';
import { useDataGridTasks } from 'components/data-grid/hooks';
import { useOpen } from 'AurionCR/components/hooks';
import { DialogRemark } from 'components/dialog-remark';
import { composeFunctions } from 'utils';

const schema = schemaTodoTaskUpdate.pick(['entryDate']);

const useUpdateMutation = apiTodoTasks.useUpdateTodoTaskWithLogMutation;
type Row = Pick<TodoTask, 'entryDate' | 'id'>;
interface Props {
  row: Row;
  onClose: () => void;
}

export const EditorEntryDate: FC<Props> = ({ row, onClose }) => {
  const stateDialog = useOpen();
  const { onTasks } = useDataGridTasks<Row>();
  const { tp } = useTranslate();
  const { handleSubmit, errors, control, trigger, getValues } = useForm({
    defaultValues: schema.cast(row, { stripUnknown: true, assert: false }),
    resolver: yupResolver(schema),
  });

  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

  const [update, resultUpdate] = useUpdateMutation();
  useEffectError(resultUpdate.error);

  const onSubmit = useCallback(
    async ({ remarks }: { remarks: string }) => {
      const isValid = await trigger();
      if (!isValid) return;

      const res = await update({
        initData: row,
        formData: { ...getValues(), id: row.id },
        remark: remarks,
      });

      if (isMutationFulfilled(res)) {
        onTasks([['getData']]);
        onClose();
      }
    },
    [row, update, onTasks, onClose, trigger, getValues],
  );

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className="grid-editor date">
        <form onSubmit={handleSubmit(stateDialog.handleOpen)}>
          <div className={'date-field-wrapper'}>
            <Controller
              name="entryDate"
              control={control}
              render={(renderProps) => {
                return (
                  <AppDatePicker
                    {...getFieldProps(renderProps)}
                    autoFocus
                    fullWidth
                    label={null}
                    disabled={resultUpdate.isLoading || stateDialog.isOpen}
                  />
                );
              }}
            />
          </div>

          <Footer onClose={onClose} disabled={resultUpdate.isLoading} />
        </form>
        {stateDialog.isOpen && (
          <DialogRemark
            title={tp('execution-date-change-reason-title')}
            description={tp('execution-date-change-reason-description')}
            isLoading={false}
            onClose={stateDialog.handleClose}
            onSubmit={composeFunctions(onSubmit, stateDialog.handleClose)}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};
