import React, { useMemo } from 'react';
import { Button, ButtonProps, Tooltip } from '@material-ui/core';
import { ActionKey, CONFIG_CATEGORY_ACTION } from 'services/todo-task-categories';
import { useTranslate } from 'hooks/use-translate';

interface Props extends ButtonProps {
  actionKey: ActionKey;
}

export const ButtonAction: React.FC<Props> = ({ actionKey, ...rest }) => {
  const { t } = useTranslate();

  const config = useMemo(() => {
    return CONFIG_CATEGORY_ACTION[actionKey];
  }, [actionKey]);

  const title = useMemo(() => {
    return t(`task-action-${actionKey}-tooltip`);
  }, [t, actionKey]);
  const text = useMemo(() => {
    return t(`task-action-${actionKey}`);
  }, [t, actionKey]);

  return (
    <Tooltip title={title}>
      <Button
        startIcon={config.Icon ? React.createElement(config.Icon) : null}
        variant={'outlined'}
        {...rest}
        style={{
          color: rest.disabled ? undefined : config.color,
          whiteSpace: 'nowrap',
        }}
      >
        {text}
      </Button>
    </Tooltip>
  );
};
