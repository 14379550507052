import React, { useMemo } from 'react';
import { TabsWrapper, TabItem } from 'components/tabs-wrapper';
import { useValueQuery } from 'hooks/use-value-query';
import { GridCalls, GridSmsMessagePatients } from '../../grids';
import style from './index.module.scss';
import { PATIENT_CARD_MESSAGE_TABS } from 'configs/routes';
import { useTranslate } from 'hooks/use-translate';

const QUERY_NAME = 'tab-messages';

interface Props {
  userPatientProfileID: string;
}

export const TabMessages: React.FC<Props> = ({ userPatientProfileID }) => {
  const { t } = useTranslate();
  const { value, onChange } = useValueQuery<PATIENT_CARD_MESSAGE_TABS>({
    name: QUERY_NAME,
    init: PATIENT_CARD_MESSAGE_TABS.CALLS,
    destroyOnUnmount: true,
  });
  const tabs = useMemo<TabItem<PATIENT_CARD_MESSAGE_TABS>[]>(
    () => [
      {
        value: PATIENT_CARD_MESSAGE_TABS.CALLS,
        title: t('calls'),
        element: <GridCalls userPatientProfileID={userPatientProfileID} />,
      },
      {
        value: PATIENT_CARD_MESSAGE_TABS.SMS,
        title: t('sms-messages'),
        element: <GridSmsMessagePatients userPatientProfileID={userPatientProfileID} />,
      },
    ],
    [t, userPatientProfileID],
  );

  return (
    <TabsWrapper
      tabs={tabs}
      value={value}
      onChange={onChange}
      classes={{ content: style.content }}
      TabPanelProps={{
        style: {
          paddingTop: 0,
        },
      }}
    />
  );
};
