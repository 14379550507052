import React from 'react';
import { LatestNotebookData } from 'components/notebook-form-generator';
import { GridClinicalDrugs } from '../../grids';
import style from './index.module.scss';

interface Props {
  userPatientProfileID: string;
}
export const ActivitiesMedicalInformation: React.FC<Props> = ({ userPatientProfileID }) => {
  return (
    <div className={style.root}>
      <GridClinicalDrugs userPatientProfileID={userPatientProfileID} />
      <LatestNotebookData
        userPatientProfileID={userPatientProfileID}
        userPatientProfileSessionID={null}
      />
    </div>
  );
};
