import React, { useCallback } from 'react';
import { ControllerRenderProps, FieldErrors } from 'react-hook-form';
import { fieldToLabelKey } from 'utils/other';
import { useTranslate } from 'hooks/use-translate';

interface Props {
  errors: FieldErrors;
  emptyHelperText?: React.ReactNode;
}

interface Options {
  withLabel: boolean;
}

export const useFieldProps = <T extends Record<string, any>>(props: Props & T) => {
  const { t } = useTranslate();

  return useCallback(
    (renderProps: ControllerRenderProps<any>, options: Options = { withLabel: true }) => {
      const { errors, emptyHelperText, ...rest } = props;
      const error = errors[renderProps.name];
      return {
        ...rest,
        ...renderProps,
        label: options.withLabel ? t(fieldToLabelKey(renderProps.name)) : '',
        error,
        helperText: error && error.message ? t(error.message) : emptyHelperText,
      };
    },
    [t, props],
  );
};
