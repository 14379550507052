import React from 'react';
import { apiTodoTasks } from 'services/todo-tasks';
import { ActionKey } from 'services/todo-task-categories';
import { ButtonTaskPreview, ButtonTaskPreviewProps } from 'components/dialog-task';

const useGetQuery = apiTodoTasks.useGetTodoTaskPatientLatestQuery;
interface Props extends Omit<ButtonTaskPreviewProps, 'taskID' | 'actionKey' | 'entryDate'> {
  userPatientProfileID: string;
}
export const ButtonLatestTaskPrescription: React.FC<Props> = ({
  userPatientProfileID,
  ...rest
}) => {
  const { data } = useGetQuery(
    {
      userPatientProfileID,
      actionKey: ActionKey.Prescription,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  if (!data) {
    return null;
  }

  return (
    <ButtonTaskPreview
      taskID={data.id}
      actionKey={ActionKey.Prescription}
      entryDate={data.createdDate}
      {...rest}
    />
  );
};
