import { convertToDate, DateValue, isDateLike } from 'utils/dates';
import { format } from 'date-fns';
import { APP_FORMAT_DATE } from 'configs/const';

interface Meeting {
  meetingFromDateTime: DateValue;
  meetingToDateTime: DateValue;
}

interface MeetingDateOptions {
  isHtml: boolean;
  includeMeetingTime: boolean;
  dateFormat?: string;
  timeFormat?: string;
}

export const formatMeetingDate = <T extends Meeting>(data: T, options: MeetingDateOptions) => {
  if (!isDateLike(data.meetingFromDateTime)) return null;
  if (!isDateLike(data.meetingToDateTime)) return null;

  const {
    isHtml,
    includeMeetingTime,
    dateFormat = APP_FORMAT_DATE,
    timeFormat = 'HH:mm',
  } = options;
  let from = convertToDate(data.meetingFromDateTime);
  let to = convertToDate(data.meetingToDateTime);

  let result = '';
  if (from && to && format(from, dateFormat) === format(to, dateFormat)) {
    result += format(from, dateFormat);
    if (includeMeetingTime) {
      if (isHtml) {
        result += ` <strong>${format(from, timeFormat)}</strong>-<strong>${format(
          to,
          timeFormat,
        )}</strong>`;
      } else {
        result += ` ${format(from, timeFormat)}-${format(to, timeFormat)}`;
      }
    }
  } else {
    if (from) {
      result += `${format(from, dateFormat)}`;
      if (includeMeetingTime) {
        if (isHtml) {
          result += `<strong>${format(from, timeFormat)}</strong>`;
        } else {
          result += `${format(from, timeFormat)}`;
        }
      }
    } else {
      result += '...';
    }
    result += '-';
    if (to) {
      result += `${format(to, dateFormat)}`;
      if (includeMeetingTime) {
        if (isHtml) {
          result += `<strong>${format(to, timeFormat)}</strong>`;
        } else {
          result += `${format(to, timeFormat)}`;
        }
      }
    } else {
      result += '...';
    }
  }
  return result;
};
