import React, { useMemo, useRef } from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { Button, CircularProgress, Typography } from '@material-ui/core';
import { useSourcesPrescriptionLatest } from 'components/hooks';
import { convertToDate } from 'utils/dates';
import { format } from 'date-fns';
import { APP_FORMAT_DATE } from 'configs/const';
import { useTranslate } from 'hooks/use-translate';
import { apiUserPatientPrescriptions } from 'services/user-patient-profile-prescriptions';
import { useEffectError } from 'hooks';
import { useOpen } from 'AurionCR/components/hooks';
import { PrescriptionDetailsPopup } from '../prescription-details-popup';
import { Unset } from 'utils/types';

interface Props {
  userPatientProfileID: string;
  userPatientProfilePrescriptionID: Unset;
}

export const MedicalPrescriptionLastDate: React.FC<Props> = ({
  userPatientProfileID,
  userPatientProfilePrescriptionID: currentID,
}) => {
  const { t } = useTranslate();
  const ref = useRef<HTMLButtonElement>(null);
  const { data } = useSourcesPrescriptionLatest({
    userPatientProfileID,
    userPatientProfilePrescriptionID: currentID,
  });

  const item = data[0] || null;

  const date = useMemo(() => {
    return item ? format(convertToDate(item.entryDate), APP_FORMAT_DATE) : null;
  }, [item]);

  const { isOpen, handleOpen, handleClose } = useOpen();

  const userPatientProfilePrescriptionID = item ? item.id : '';
  const skip = !isOpen || !userPatientProfilePrescriptionID;

  const {
    data: prescription,
    isLoading,
    error,
  } = apiUserPatientPrescriptions.useGetMedicalPrescriptionWithDetailsQuery(
    userPatientProfilePrescriptionID,
    { skip },
  );

  useEffectError(error);

  const rows = prescription ? prescription.details : [];

  if (!item) return null;

  return (
    <>
      <Button
        size={'small'}
        startIcon={isLoading ? <CircularProgress size={14} /> : <HelpOutlineIcon />}
        endIcon={
          <Typography dir={'ltr'} color={'secondary'} component={'span'} variant={'body1'}>
            {date}
          </Typography>
        }
        fullWidth
        ref={ref}
        onClick={handleOpen}
        color={'primary'}
        variant={'outlined'}
      >
        {t('display-last-prescription')}
      </Button>
      <PrescriptionDetailsPopup
        isLoading={isLoading}
        open={isOpen}
        anchorEl={ref.current}
        onClose={handleClose}
        rows={rows}
        userPatientProfilePrescriptionID={userPatientProfilePrescriptionID}
      />
    </>
  );
};
