import { DynamicService } from 'utils/service';
import {
  API_USER_PATIENT_PROFILE_NOTES,
  Notes,
  UserNotesGetActivitiesInput,
  UserNotesGetActivitiesItem,
} from './models';
import { makeFilterDateRange } from 'utils/app-helpers';

export * from './models';

class Service extends DynamicService<Notes> {
  getActivities = async (input: UserNotesGetActivitiesInput) => {
    const { userPatientProfileID, dateRange } = input;
    const {
      data: { value },
    } = await this.getAllDynamic<UserNotesGetActivitiesItem>({
      select: ['id', 'entryDate', 'description', 'userEmployeeProfile.fullName as employee'].join(
        ',',
      ),
      filter: [
        `userPatientProfileID=="${userPatientProfileID}"`,
        makeFilterDateRange('entryDate', dateRange),
      ]
        .filter(Boolean)
        .join('&&'),
      orderBy: 'entryDate desc',
    });

    return value.map((item) => ({
      id: String(item.id),
      title: item.description,
      date: item.entryDate,
      employee: item.employee,
      download: null,
    }));
  };
}
export const ServiceUserPatientProfileNotes = new Service({
  mainField: 'id',
  getAll: API_USER_PATIENT_PROFILE_NOTES.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_NOTES.POST,
  patch: API_USER_PATIENT_PROFILE_NOTES.PATCH,
  delete: API_USER_PATIENT_PROFILE_NOTES.DELETE,
});
