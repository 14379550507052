import { DynamicService } from 'utils/service';
import {
  UserPatientProfileSmsMessage,
  API_USER_PATIENT_PROFILE_SMS_MESSAGES,
  UsePatientSmsMessageGetActivitiesInput,
  UsePatientSmsMessageGetActivitiesItem,
} from './models';
import { makeFilterDateRange } from 'utils/app-helpers';

export * from './models';

class Service extends DynamicService<UserPatientProfileSmsMessage> {
  getActivities = async (input: UsePatientSmsMessageGetActivitiesInput) => {
    const { userPatientProfileID, dateRange } = input;
    const {
      data: { value },
    } = await this.getAllDynamic<UsePatientSmsMessageGetActivitiesItem>({
      select: ['id', 'entryDate', 'message', 'userEmployeeProfile.fullName as employee'].join(','),
      filter: [
        `userPatientProfileID=="${userPatientProfileID}"`,
        makeFilterDateRange('entryDate', dateRange),
      ]
        .filter(Boolean)
        .join('&&'),
      orderBy: 'entryDate desc',
    });

    return value.map((item) => ({
      id: String(item.id),
      title: item.message,
      date: item.entryDate,
      employee: item.employee,
      download: null,
    }));
  };
}
export const ServiceUsePatientProfileSmsMessages = new Service({
  mainField: 'id',
  getAll: API_USER_PATIENT_PROFILE_SMS_MESSAGES.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_SMS_MESSAGES.POST,
  patch: API_USER_PATIENT_PROFILE_SMS_MESSAGES.PATCH,
  delete: API_USER_PATIENT_PROFILE_SMS_MESSAGES.DELETE,
});
