import React, { useMemo } from 'react';
import { useOpen } from 'AurionCR/components/hooks';
import { composeFunctions } from 'utils';
import { BaseAction } from '../../models';
import { ButtonAction } from '../button-action';
import { DialogClinicalMeeting } from 'components/dialog-clinical-meeting';
import { useAppSelector } from 'store';
import { selectCMTimes } from 'pages/ClinicalMeetings/store';
import { debounce } from 'lodash-es';
import { useCurrentUser } from 'components/hooks';
import { ClinicalMeetingCreateModel } from 'services/clinical-meetings';

interface Props extends BaseAction {
  userPatientProfileID: string;
}
export const ActionClinicalAppointment: React.FC<Props> = ({
  userPatientProfileID,
  onDone: _onDone,
  actionKey,
  isLoading,
}) => {
  const { appUserID } = useCurrentUser();
  const times = useAppSelector(selectCMTimes);
  const { isOpen, handleOpen, handleClose } = useOpen();

  const onDone = useMemo(
    () => debounce(composeFunctions(_onDone, handleClose), 500),
    [_onDone, handleClose],
  );
  const initData = useMemo<Partial<ClinicalMeetingCreateModel>>(() => {
    return { userPatientProfileID, userEmployeeProfileID: appUserID, currentUserID: appUserID };
  }, [userPatientProfileID, appUserID]);

  return (
    <>
      <ButtonAction actionKey={actionKey} onClick={handleOpen} disabled={isLoading} />
      {isOpen && (
        <DialogClinicalMeeting
          itemID={true}
          initData={initData}
          times={times}
          onComplete={onDone}
          onClose={handleClose}
        />
      )}
    </>
  );
};
