import clsx from 'clsx';
import React, { memo, useCallback, useRef } from 'react';
import { IDataGridLightEditorContainerProps } from '../../models';
import style from './index.module.scss';
import { useOpen } from 'AurionCR/components/hooks';
import { useTranslate } from 'hooks/use-translate';
import { Popper, Typography } from '@material-ui/core';
import { Stack } from 'components/stack';
import EditIcon from '@material-ui/icons/Edit';
import { composeFunctions } from 'utils';
import { handlerStopAndPrevent } from 'utils/handlers';
import { useDataGridLightTasks } from '../../hooks';

interface Props extends IDataGridLightEditorContainerProps<any, any> {}
export const DataGridEditorContainer = memo<Props>(({ children, row, field, Editor }) => {
  const stateLabel = useOpen();
  const stateEditor = useOpen();
  const ref = useRef<HTMLDivElement>(null);
  const { tp } = useTranslate();

  const { onTask } = useDataGridLightTasks();

  const onSubmit = useCallback(
    (data: any) => {
      onTask({ type: 'item-change', payload: { row, data } });
    },
    [onTask, row],
  );

  return (
    <div className={style.wrap}>
      {stateEditor.isOpen && (
        <Editor row={row} field={field} onClose={stateEditor.handleClose} onSubmit={onSubmit} />
      )}
      {!stateEditor.isOpen && (
        <>
          {children}
          <div
            className={clsx(style.root, stateLabel.isOpen && style.hover)}
            ref={ref}
            onMouseEnter={stateLabel.handleOpen}
            onMouseLeave={stateLabel.handleClose}
            onClick={composeFunctions(
              handlerStopAndPrevent,
              stateEditor.handleOpen,
              stateLabel.handleClose,
            )}
          >
            <Popper
              open={stateLabel.isOpen}
              anchorEl={ref.current}
              placement={'bottom-end'}
              disablePortal={true}
            >
              <Stack spacing={0.6} direction={'row'} textAlign={'right'} className={style.helper}>
                <Typography variant={'caption'}>{tp('grid-edit-cell-helper')}</Typography>
                <EditIcon fontSize={'inherit'} style={{ fontSize: 12 }} />
              </Stack>
            </Popper>
          </div>
        </>
      )}
    </div>
  );
});
