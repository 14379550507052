import { useAuth } from 'AurionCR/components';
import {
  EmployeePermissions,
  permissionKey,
  PermissionKey,
} from 'services/user-employee-profile-permissions';
import { useMemo } from 'react';

export const usePermissions = (...keys: PermissionKey<EmployeePermissions>[]) => {
  const { permission } = useAuth();

  const key = useMemo(() => {
    return permissionKey(...keys);
  }, [keys]);

  return permission(key) as boolean;
};
