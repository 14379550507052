import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslate } from 'hooks/use-translate';
import { apiUserPatientProfileSessionItems } from 'services/user-patient-profile-session-item';
import { useSourceEmployees } from 'components/hooks';
import { isEqual, keyBy } from 'lodash-es';
import { useEffectError, useFieldProps } from 'hooks';
import { SelectEmployee } from 'components/select-employee';
import { convertToDate, dateFormat } from 'utils/dates';
import { Stack } from 'components/stack';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { apiPdf } from 'services/pdf';
import { isMutationFulfilled } from 'utils/rtk-query';
import { useAppSelector } from 'store';
import { selectLanguageID } from 'store/languages';
import { makeFullName } from 'utils/app-helpers';
import { InferType } from 'yup';
import style from './index.module.scss';
import { AppDatePicker } from 'components/app-date-picker';
import { AppWeightInput } from 'components/app-weight-input';
import { AppInput } from 'components/app-input';
import { getRandomString } from 'utils/other';
import { compareDesc } from 'date-fns';
import AddIcon from '@material-ui/icons/Add';
import { formatWeight } from 'utils/numbers';
import { BaseCreateComponentProps } from '../../models';
import { apiPdfContent } from 'services/pdf-content';
import { DialogEditHtmlContent } from 'components/dialog-edit-html-content';

const schemaWeight = yup.object({
  date: yup.string().required('rule-required').default(new Date().toISOString()),
  weight: yup.string().required('rule-required').default('0.00'),
  name: yup.string().required('rule-required').default(''),
});
type WeightModel = InferType<typeof schemaWeight>;

const schema = yup.object({
  userEmployeeProfileID: yup.string().required('rule-required').default(''),
  weights: yup.array().min(1).of(schemaWeight).default([]),
});
const defaultValues = schema.cast({});
type FormModel = typeof defaultValues;

const useLazyQueryPatientDetails =
  apiUserPatientProfile.useLazyGetPatientDetailsForPdfDietitianReportQuery;
const useQueryWeights = apiUserPatientProfileSessionItems.useGetSessionItemWeightsQuery;
const useGenerateContentMutation = apiPdfContent.useGenerateContentDietitianReportMutation;
const useGenerateDocMutation = apiPdf.useGenerateDocumentDietitianReportMutation;

type WeightItem = WeightModel & { uid: string };
interface CheckboxListProps<T> {
  label?: React.ReactNode;
  value: T[];
  onChange: (value: this['value']) => void;
  options: T[];
  disabled?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
}
const CheckboxList = <T extends WeightItem>({
  label,
  value,
  options,
  onChange,
  disabled,
  error,
  helperText,
}: CheckboxListProps<T>) => {
  const isSelectedAny = value.length > 0;
  const isSelectedAll = isSelectedAny && options.length === value.length;

  const onSelectAll = useCallback(() => {
    if (isSelectedAll) {
      return onChange([]);
    }
    onChange(options);
  }, [isSelectedAll, onChange, options]);

  return (
    <FormControl error={error} className={style.list}>
      <Stack alignItems={'center'} justifyContent={'space-between'}>
        <FormControlLabel
          label={label}
          disabled={disabled}
          control={
            <Checkbox
              checked={isSelectedAny}
              indeterminate={isSelectedAny && !isSelectedAll}
              onChange={onSelectAll}
              disabled={disabled}
            />
          }
        />
        <Typography color={'textSecondary'}>
          {value.length} / {options.length}
        </Typography>
      </Stack>

      <div className={style.listGrid}>
        {options.map((option, index) => {
          const isSelected = value.some((item) => isEqual(item, option));
          const onClick = () => {
            if (isSelected) {
              return onChange(value.filter((item) => !isEqual(item, option)));
            }
            return onChange([...value, option]);
          };
          return (
            <MenuItem component={'div'} className={style.listGridItem} disabled={disabled}>
              <FormControlLabel
                disabled={disabled}
                control={<Checkbox checked={isSelected} onChange={onClick} disabled={disabled} />}
                label={
                  <Stack direction={'column'} spacing={0.3} style={{ marginLeft: '0.4rem' }}>
                    <Stack spacing={1} divider={<Divider flexItem orientation={'vertical'} />}>
                      <Typography color={'textPrimary'} variant={'body2'} style={{ lineHeight: 1 }}>
                        {dateFormat(option.date)}
                      </Typography>
                      <Typography
                        color={'textSecondary'}
                        variant={'body2'}
                        style={{ lineHeight: 1 }}
                      >
                        {option.weight}
                      </Typography>
                    </Stack>
                    <Typography color={'textSecondary'} variant={'body2'} style={{ lineHeight: 1 }}>
                      {option.name}
                    </Typography>
                  </Stack>
                }
              />
            </MenuItem>
          );
        })}
      </div>

      <Collapse in={!!helperText}>
        <FormHelperText>{helperText}</FormHelperText>
      </Collapse>
    </FormControl>
  );
};

interface FormAddManuallyProps {
  disabled: boolean;
  onAdd: (value: WeightModel) => void;
}
const FormAddManually: React.FC<FormAddManuallyProps> = ({ onAdd, disabled }) => {
  const { tp } = useTranslate();
  const { control, errors, handleSubmit, reset } = useForm({
    defaultValues: schemaWeight.cast({}),
    resolver: yupResolver(schemaWeight),
  });
  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });
  const onSubmit = useCallback(
    (formDate: WeightModel) => {
      onAdd(formDate);
      reset(schemaWeight.cast({}));
    },
    [onAdd, reset],
  );

  return (
    <Box
      sx={{
        paddingTop: '1.5rem',
      }}
    >
      <Stack spacing={1} mb={1}>
        <Typography color={'secondary'} style={{ flexGrow: 1, alignSelf: 'center' }} variant={'h5'}>
          {tp('add-manually')}
        </Typography>
        <Button
          size={'small'}
          variant={'contained'}
          color={'primary'}
          onClick={handleSubmit(onSubmit)}
          disabled={disabled}
          startIcon={<AddIcon />}
        >
          {tp('add')}
        </Button>
      </Stack>
      <Grid container spacing={1}>
        <Grid xs={6} md={4} item>
          <Controller
            control={control}
            name={'date'}
            render={(renderProps) => {
              return (
                <AppDatePicker
                  {...getFieldProps(renderProps)}
                  inputVariant={'outlined'}
                  size={'small'}
                  disabled={disabled}
                />
              );
            }}
          />
        </Grid>
        <Grid xs={6} md={3} item>
          <Controller
            control={control}
            name={'weight'}
            render={(renderProps) => {
              return (
                <AppWeightInput
                  {...getFieldProps(renderProps)}
                  size={'small'}
                  variant={'outlined'}
                  disabled={disabled}
                />
              );
            }}
          />
        </Grid>
        <Grid xs={12} md={true} item>
          <Controller
            control={control}
            name={'name'}
            render={(renderProps) => {
              return (
                <AppInput
                  {...getFieldProps(renderProps)}
                  size={'small'}
                  variant={'outlined'}
                  disabled={disabled}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

type ApplyState = { content: string; title: string };
interface DialogCreateContentProps {
  userPatientProfileID: string;
  onClose: () => void;
  onApply: (state: ApplyState) => void;
}
const DialogCreateContent: React.FC<DialogCreateContentProps> = ({
  userPatientProfileID,
  onApply,
  onClose,
}) => {
  const languageID = useAppSelector(selectLanguageID);
  const { tp } = useTranslate();

  const [generateContent, resultContent] = useGenerateContentMutation();
  useEffectError(resultContent.error);

  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { watch, errors, control, handleSubmit, setValue, getValues } = methods;
  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

  const [weights, setWeights] = useState<Array<WeightItem>>([]);
  const resultWeights = useQueryWeights(userPatientProfileID);
  useEffectError(resultWeights.error);
  useEffect(() => {
    if (!resultWeights.data) return;

    const value = resultWeights.data.map((item) => {
      return {
        date: item.entryDate,
        weight: formatWeight(item.weight),
        name: makeFullName(item.userEmployeeProfile),
        uid: item.id,
      };
    });

    setWeights(value);
  }, [resultWeights.data]);

  const [triggerLoadPatientDetails, resultPatientDetails] = useLazyQueryPatientDetails();
  useEffectError(resultPatientDetails.error);

  const sourceEmployees = useSourceEmployees();
  const employees = useMemo(() => {
    const map = keyBy(resultWeights.data || [], 'userEmployeeProfileID');

    return sourceEmployees.data
      .filter((employee) => !!map[employee.id])
      .map((employee) => ({ ...employee }));
  }, [resultWeights.data, sourceEmployees.data]);

  const onAddManually = useCallback(
    (value: WeightModel) => {
      const newItem = { ...value, uid: getRandomString() };

      setWeights((prev) => {
        return [...prev, newItem].sort((a, b) =>
          compareDesc(convertToDate(a.date), convertToDate(b.date)),
        );
      });

      setValue('weights', [...getValues('weights'), newItem]);
    },
    [setValue, getValues],
  );

  const userEmployeeProfileID = watch('userEmployeeProfileID');

  const isLoading = sourceEmployees.loading || resultWeights.isFetching || resultContent.isLoading;

  const items = watch('weights');

  const onSubmit = useCallback(
    async (data: FormModel) => {
      const dietitian = sourceEmployees.map[data.userEmployeeProfileID];

      if (!dietitian) {
        return;
      }

      const resPatient = await triggerLoadPatientDetails(userPatientProfileID);

      if (!isMutationFulfilled(resPatient)) {
        return;
      }

      const res = await generateContent({
        languageID,
        payload: {
          dietitian,
          patient: resPatient.data,
          weights: data.weights,
        },
      });

      if (isMutationFulfilled(res)) {
        onApply(res.data);
      }
    },
    [
      sourceEmployees.map,
      triggerLoadPatientDetails,
      generateContent,
      userPatientProfileID,
      onApply,
      languageID,
    ],
  );

  const isDisabledApply = isLoading || !userEmployeeProfileID || items.length === 0;

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth={'sm'}>
      <DialogHeading
        title={tp('create-dietitian-report')}
        isLoading={isLoading}
        onClose={onClose}
      />
      <DialogContent dividers className={style.dialogContent}>
        <Controller
          control={control}
          name={'userEmployeeProfileID'}
          render={(renderProps) => {
            const props = getFieldProps(renderProps);
            return (
              <SelectEmployee
                {...props}
                disabled={isLoading}
                options={employees}
                disableClearable
              />
            );
          }}
        />
        <Divider className={style.divider} />
        <Controller
          control={control}
          name={'weights'}
          render={(renderProps) => {
            const props = getFieldProps(renderProps);

            return (
              <CheckboxList
                {...props}
                disabled={isLoading}
                options={weights}
                helperText={props.helperText}
              />
            );
          }}
        />
        <Divider className={style.divider} />
        <FormAddManually onAdd={onAddManually} disabled={isLoading} />
      </DialogContent>
      <DialogActions>
        <Button color={'primary'} onClick={onClose}>
          {tp('cancel')}
        </Button>
        <Button
          color={'primary'}
          variant={'contained'}
          disabled={isDisabledApply}
          onClick={handleSubmit(onSubmit)}
        >
          {tp('apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const DialogCreateDocDietitianReport: React.FC<BaseCreateComponentProps> = ({
  userPatientProfileID,
  onDone,
  onClose,
}) => {
  const [state, setState] = useState<ApplyState>();
  const [generate, resultGenerate] = useGenerateDocMutation();
  useEffectError(resultGenerate.error);

  const isLoading = resultGenerate.isLoading;

  const onGenerate = useCallback(
    async (content: string) => {
      if (!state) return;

      const res = await generate({ content, title: state.title, userPatientProfileID });

      if (isMutationFulfilled(res)) {
        onDone();
      }
    },
    [generate, state, userPatientProfileID, onDone],
  );

  if (!state) {
    return (
      <DialogCreateContent
        userPatientProfileID={userPatientProfileID}
        onClose={onClose}
        onApply={setState}
      />
    );
  }

  return (
    <DialogEditHtmlContent
      isLoading={isLoading}
      title={state.title}
      content={state.content}
      onGenerate={onGenerate}
      onCancel={onClose}
    />
  );
};
