import { DynamicService } from 'utils/service';
import {
  PharmacyDrugQuantity,
  API_PHARMACY_DRUG_QUANTITIES,
  ValidateDosageForm,
  IPharmacyDrugQuantityForm,
} from './models';
import { apiRtk, RTK_TAGS } from 'utils/rtk-query';
import { createFilterEquals, createFilterNotEquals, mergeFilters } from 'utils/dynamic-helpers';
import { PatchPartial } from '../../utils/types';

class Service extends DynamicService<PharmacyDrugQuantity> {
  validateDosageForm = async (input: ValidateDosageForm) => {
    const {
      data: { value },
    } = await this.getAllDynamic({
      filter: mergeFilters(
        createFilterNotEquals('id', input.id),
        createFilterEquals('dosageFormTotalDesc', input.dosageFormTotalDesc),
        createFilterEquals('pharmacyDrugID', input.pharmacyDrugID),
      ).join('&&'),
      select: 'id',
      take: 1,
    });

    return { data: { isValid: value.length === 0 } };
  };
}

export const ServicePharmacyDrugQuantities = new Service({
  mainField: 'id',
  getAll: API_PHARMACY_DRUG_QUANTITIES.GET_ALL_DYNAMIC,
  post: API_PHARMACY_DRUG_QUANTITIES.POST,
  patch: API_PHARMACY_DRUG_QUANTITIES.PATCH,
  delete: API_PHARMACY_DRUG_QUANTITIES.DELETE,
});

export const apiPharmacyDrugQuantities = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getPharmacyDrugQuantityFormData: builder.query<IPharmacyDrugQuantityForm, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServicePharmacyDrugQuantities.getDynamic(id, {
            select: ['id', 'pharmacyDrugID', 'dosageFormTotalDesc', 'quantity'].join(','),
          });

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    postPharmacyDrugQuantity: builder.mutation<
      PharmacyDrugQuantity,
      Omit<PharmacyDrugQuantity, 'id'>
    >({
      queryFn: async (input) => {
        try {
          const { data } = await ServicePharmacyDrugQuantities.post(input);

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.PATIENT_PRESCRIPTION, id: 'neopharm-details' }],
    }),
    patchPharmacyDrugQuantity: builder.mutation<
      PharmacyDrugQuantity,
      PatchPartial<PharmacyDrugQuantity, 'id'>
    >({
      queryFn: async (input) => {
        try {
          await ServicePharmacyDrugQuantities.patch(input);

          return { data: input as PharmacyDrugQuantity };
        } catch (error: any) {
          return { error };
        }
      },
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiPharmacyDrugQuantities.util.updateQueryData(
            'getPharmacyDrugQuantityFormData',
            String(arg.id),
            (draft) => {
              Object.assign(draft, arg);
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: [{ type: RTK_TAGS.PATIENT_PRESCRIPTION, id: 'neopharm-details' }],
    }),
    deletePharmacyDrugQuantity: builder.mutation<
      PharmacyDrugQuantity,
      PatchPartial<PharmacyDrugQuantity, 'id'>
    >({
      queryFn: async (input) => {
        try {
          const { data } = await ServicePharmacyDrugQuantities.delete(input);

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.PATIENT_PRESCRIPTION, id: 'neopharm-details' }],
    }),
  }),
});
