import { useAuth } from 'AurionCR/components';
import { AppUser } from 'services/account';
import { useMemo } from 'react';
import { PERMISSION_IDS } from 'services/user-employee-profile-permissions';

export const useCurrentUser = () => {
  const user = useAuth().user as AppUser;
  const userEmployeeProfilePermissionID = String(user.userEmployeeProfilePermission?.id);
  const $isAdmin = userEmployeeProfilePermissionID === PERMISSION_IDS.ADMIN;
  const $isDoctor = Boolean(
    userEmployeeProfilePermissionID === PERMISSION_IDS.DOCTOR ||
      user.userEmployeeProfilePermission?.roleDoctor,
  );
  const $isDietitian = Boolean(!!user.userEmployeeProfilePermission?.roleDietitian);

  return useMemo(() => {
    const [firstName, lastName] = user.name.split(' ');
    return {
      ...user,
      firstName,
      lastName,
      $isAdmin,
      $isDoctor,
      $isDietitian,
      userEmployeeProfilePermissionID,
    };
  }, [user, $isAdmin, $isDoctor, $isDietitian, userEmployeeProfilePermissionID]);
};
