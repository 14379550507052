export enum ACTION_AUTH_TYPES {
  NOTIFICATION_SET = 'ACTION_AUTH_NOTIFICATION_SET',
  AUTH_SUCCESS = 'AUTH_SUCCESS',
}

export const actionAuthNotificationSet = (payload: { notificationDoNotAsk: boolean }) => ({
  type: ACTION_AUTH_TYPES.NOTIFICATION_SET,
  payload,
});
export const actionAuthSuccess = (payload: any) => ({
  type: ACTION_AUTH_TYPES.AUTH_SUCCESS,
  payload,
});

export type AuthActions = ReturnType<typeof actionAuthNotificationSet | typeof actionAuthSuccess>;
