import { DynamicService } from 'utils/service';

import {
  API_USER_PATIENT_PROFILE_SESSIONS_ICD10S,
  CreateBulkUserPatientProfileSessionIcd10Input,
  UserPatientProfileSessionIcd10,
} from './models';

export * from './models';

class Service extends DynamicService<UserPatientProfileSessionIcd10> {
  createBulk = (input: CreateBulkUserPatientProfileSessionIcd10Input) => {
    return this.engine.post(API_USER_PATIENT_PROFILE_SESSIONS_ICD10S.POST_BULK, {
      mainID: input.userPatientProfileSessionID,
      itemIDs: input.icd10IDs,
    });
  };
}

export const ServiceUserPatientProfileSessionsIcd10s = new Service({
  mainField: 'id',
  getAll: API_USER_PATIENT_PROFILE_SESSIONS_ICD10S.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_SESSIONS_ICD10S.POST,
  delete: API_USER_PATIENT_PROFILE_SESSIONS_ICD10S.DELETE,
  patch: API_USER_PATIENT_PROFILE_SESSIONS_ICD10S.PATCH,
});
