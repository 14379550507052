import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { FormController } from 'AurionCR/components/formV2';
import {
  apiStaticCanceled,
  checkEs6AndRun,
  checkES6Template,
  getRandomString,
  requestError,
} from 'AurionCR/components';
import { fieldValueDefault, iField, iFieldRefProps } from './@type';
import { AutocompleteProps } from '@material-ui/lab/Autocomplete/Autocomplete';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { debounce } from 'lodash-es';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { useFormContext } from 'react-hook-form';
import { mergeFilters } from 'utils/dynamic-helpers';

export interface iSelectAutocomplete
  extends Omit<AutocompleteProps<any, any, any, any>, 'renderInput' | 'options'> {
  source: {
    url: string;
    requestFilter: string | undefined;
    baseUrl?: string;
    filter: string;
    take: number;
    select?: string;
  };
  option: { label: string; value: string };
  error: any;
  InputProps?: Partial<StandardInputProps>;
  label?: React.ReactNode | string;
}

export const SelectAutocomplete = memo(
  ({
    value,
    source,
    option,
    error,
    InputProps,
    label,
    getOptionLabel,
    ...rest
  }: iSelectAutocomplete) => {
    const { t } = useTranslate();
    const dispatch = useDispatch();
    // state
    const [uid] = useState(getRandomString(16));
    const [options, setOptions] = useState<any[]>([]);
    const [, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    // handlers
    const onGetData = useCallback(
      (search?: string, value?: any) => {
        // status
        setIsLoading(true);
        // request params
        const { url, baseUrl, filter, take, select, requestFilter } = source;
        const props: any = {
          _cancelID: `${url}_${uid}`,
          url,
          params: {
            take,
            count: true,
          },
        };
        if (baseUrl) props.baseUrl = baseUrl;
        if (select) props.params.select = select;
        if (search) props.params.filter = checkEs6AndRun(filter, search);
        if (value) {
          if (typeof value === 'string') {
            props.params.filter = `${option.value} == "${value}"`;
          } else {
            props.params.filter = `${option.value} == "${value[option.value]}"`;
          }
        }

        props.params.filter =
          [props.params.filter, requestFilter && `(${requestFilter})`].filter(Boolean).join('&&') ||
          undefined;

        // request
        apiStaticCanceled(props)
          .then(({ data: { value, count } }) => {
            setOptions(value);
            setCount(count);
            setIsLoading(false);
          })
          .catch((e) => {
            if (!axios.isCancel(e)) {
              dispatch(notifyRequestResult(requestError(e), 'error'));
            }
            setIsLoading(false);
          });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [setIsLoading, setOptions, source, option],
    );
    const onInputChange = useCallback(
      debounce((e, value) => onGetData(value), 350),
      [onGetData],
    );
    // init
    useEffect(() => {
      if (!value) onGetData('');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _options = useMemo(() => {
      return options.map((option) => {
        return { ...option, title: getOptionLabel && getOptionLabel(option) };
      });
    }, [options, getOptionLabel]);
    // render
    return (
      <Autocomplete
        {...rest}
        getOptionLabel={(option) => option.title}
        options={_options}
        loading={isLoading}
        onInputChange={onInputChange}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={label}
              error={Boolean(error)}
              helperText={error ? t(error.message) || '' : ''}
              InputProps={{
                ...InputProps,
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? <CircularProgress color="inherit" size={15} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          );
        }}
      />
    );
  },
);

export const FieldSelect = memo(
  forwardRef<iFieldRefProps, iField>(({ item, disabled = false, onWasChange }, ref) => {
    const { t } = useTranslate();

    const name = item.fieldInputID as string;
    const labelKey = item.fieldInput?.labelKey;

    const { control, errors } = useFormContext();

    const selectParams = useMemo(() => {
      const params = item?.fieldInput?.inputType?.inputTypeDataSource;

      if (!params) return null;

      const url = params?.dataSourceName || '';

      if (!url) {
        return null;
      }

      const labelFields = (((params?.fieldVal as string) || '').match(/({.*?})/gm) || []).map(
        (val: string) => val.slice(1, -1),
      );

      if (labelFields.length === 0) {
        return null;
      }

      const filter = mergeFilters(
        ...labelFields.map((key) => `${key}.ToLower().contains("\${data}")`),
      ).join('||');

      const source = {
        url: `${url}?orderBy=${labelFields.join(',')}`,
        baseUrl: '/',
        filter: filter,
        requestFilter: params.filter,
        take: 10,
        select: `${params.fieldKey},${labelFields.join(',')}`,
      };
      const label = (params?.fieldVal || '').replace(/\{/gm, '${data.');
      const option = { label, value: params.fieldKey };
      return {
        source,
        label,
        option,
      };
    }, [item]);
    // handlers
    const getOptionSelected = useCallback(
      (left, right) => {
        const valueKey = selectParams?.option?.value || 'id';
        if (selectParams?.option?.value) {
          return left[valueKey] === right[valueKey];
        } else {
          return false;
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [selectParams?.option],
    );
    const getOptionLabel = useCallback(
      (value) => {
        const label = selectParams?.option?.label;

        if (!label) return 'error-label-settings';

        if (!checkES6Template(label)) {
          return value[label] || 'error-label-settings';
        }

        return checkEs6AndRun(label, value);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [selectParams?.option],
    );

    const onChange = useCallback(
      (e, value) => {
        const valueKey = selectParams?.option?.value || 'id';
        let val = value
          ? {
              [valueKey]: value[valueKey],
              title: getOptionLabel(value),
            }
          : null;
        setValue(val);
        return e;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [selectParams?.option],
    );

    const [value, setValue] = useState<any>(item.formValue || []);

    // public
    const getValue = useCallback(() => {
      const valueKey = selectParams?.option?.value || 'id';

      const externalSystemValue = value ? value[valueKey] : null;
      const entryValueString = value ? value.title : null;

      return {
        ...fieldValueDefault(),
        id: item.formID || '',
        fieldInputID: item.fieldInputID as string,
        externalSystemValue,
        entryValueString,

        formValue: value,
        notebookFieldInputID: item.id,
      };
    }, [item, selectParams, value]);

    useImperativeHandle(ref, () => ({ getValue }), [getValue]);

    // render
    return (
      <div>
        {selectParams ? (
          <FormController
            name={name}
            label={labelKey ? t(labelKey) : ''}
            as={SelectAutocomplete}
            rules={item?.isRequired ? 'required' : undefined}
            errors={errors}
            control={control}
            disabled={disabled}
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            source={selectParams.source}
            option={selectParams.option}
            defaultValue={item.formValue || null}
            onChange={onChange}
            onBlur={onWasChange}
            autoSelect={true}
          />
        ) : (
          <div style={{ color: 'red' }}>
            <div>{item.fieldInput?.labelKey || ''} - ERROR</div>
            <div style={{ fontSize: 9 }}>{JSON.stringify(item)}</div>
          </div>
        )}
      </div>
    );
  }),
);
