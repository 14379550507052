import { apiClinicalMeetings, ServiceClinicalMeetings } from 'services/clinical-meetings';
import { useEffectError } from 'hooks';
import { apiSmsContents, SMS_CONTENT_KEYS } from 'services/sms-contents';
import { useAppDispatch, useAppSelector } from 'store';
import {
  useCurrentUser,
  useSourceClinicalMeetingSubjects,
  useSourceEmployees,
  useSourcePermissions,
} from 'components/hooks';
import { useCallback } from 'react';
import { actionPatientMessageAddItem } from 'store/patient-sms-messages';
import { calcHtmlTemplate } from 'utils';
import { actionClinicalMeetingActivitiesCrateLogSendSms } from 'store/clinical-meeting-activities';
import { selectLanguageID } from 'store/languages';
import { Unset } from 'utils/types';

export const useClinicalMeetingSendMessageToPatient = () => {
  const dispatch = useAppDispatch();
  const languageID = useAppSelector(selectLanguageID);
  const currentEmployee = useCurrentUser();

  const [getMeetingInfo, { error: errorMeetingInfo }] =
    apiClinicalMeetings.useLazyGetClinicalMeetingSendSmsToPatientQuery();
  useEffectError(errorMeetingInfo);

  const [getSmsContent, { error: errorGetContent }] =
    apiSmsContents.useLazyGetSmsContentBySmsKeyQuery();
  useEffectError(errorGetContent);

  const showSendSmsDialog = useCallback(
    async (option: { clinicalMeetingID: string }) => {
      const { clinicalMeetingID } = option;

      const resultInfo = await getMeetingInfo(clinicalMeetingID);

      if (!resultInfo.data) {
        return;
      }

      const meetingData = resultInfo.data;
      const isInClinic = ServiceClinicalMeetings.isMeetingClinical(meetingData);

      const resultContent = await getSmsContent({
        languageID,
        contentKey: isInClinic ? SMS_CONTENT_KEYS.MEETING_CLINIC : SMS_CONTENT_KEYS.MEETING_ONLINE,
      });

      if (!resultContent.data) {
        return;
      }

      const templatePayload = {
        ...meetingData,
        currentEmployee,
      };
      dispatch(
        actionPatientMessageAddItem({
          uid: `clinical-meeting__${meetingData.userEmployeeProfileID}`,
          formData: {
            userPatientProfileID: meetingData.userPatientProfileID,
            toPhoneNumber: meetingData.patient.mobilePhone || '',
            message: calcHtmlTemplate(resultContent.data.message || '', templatePayload),
          },
          templatePayload,
          onSuccess: (result) => {
            dispatch(
              actionClinicalMeetingActivitiesCrateLogSendSms({
                clinicalMeetingID,
                message: result.message || '',
              }),
            );
          },
        }),
      );
    },
    [dispatch, currentEmployee, getMeetingInfo, getSmsContent, languageID],
  );

  return { showSendSmsDialog };
};
export const useClientMeetingDefaultDuration = (clinicalMeetingSubjectID: Unset) => {
  const { map: mapPermissions } = useSourcePermissions();
  const { map: mapSubjects } = useSourceClinicalMeetingSubjects();
  const { map: mapEmployees } = useSourceEmployees();

  const subject = clinicalMeetingSubjectID ? mapSubjects[clinicalMeetingSubjectID] : null;

  return useCallback(
    (userEmployeeProfileID: Unset) => {
      if (!subject) return undefined;
      if (!userEmployeeProfileID) return undefined;

      const employee = mapEmployees[userEmployeeProfileID];

      if (!employee) return undefined;

      const userEmployeeProfilePermissionID = employee.userEmployeeProfilePermissionID;

      if (!userEmployeeProfilePermissionID) return undefined;

      const permission = mapPermissions[userEmployeeProfilePermissionID];

      if (!permission) return;

      return permission.roleDoctor ? subject.defaultDuration : subject.defaultDurationNotDoctor;
    },
    [mapPermissions, mapEmployees, subject],
  );
};
