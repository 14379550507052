import React from 'react';

import style from './index.module.scss';

import clsx from 'clsx';

interface Classes {
  root: string;
}

interface Props {
  className?: string;
  classes?: Partial<Classes>;
}

export const RowFormLayout: React.FC<Props> = ({ className, classes, children }) => {
  return <div className={clsx(style.root, classes?.root, className)}>{children}</div>;
};
