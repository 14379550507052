import { FixServerObject, Left } from 'utils/types';

const API_ENDPOINT = 'SupportMeetingActivities';

export const API_SUPPORT_MEETING_ACTIVITIES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<SupportMeetingActivity, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<SupportMeetingActivity, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface SupportMeetingActivity
  extends FixServerObject<
    Left<Components.Schemas.SupportMeetingActivity>,
    'id' | 'entryDate' | 'supportMeetingID' | 'remarks'
  > {}

export interface SupportMeetingActivityPostInput
  extends Pick<
    SupportMeetingActivity,
    'supportMeetingID' | 'remarks' | 'remarkForPatientCallStatusID' | 'changes'
  > {}
