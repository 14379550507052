import React from 'react';
import { ClinicalMeetingActivitiesDialog } from 'components/clinical-meeting-activities';
import { useOpen } from 'AurionCR/components/hooks';
import { StackProps } from 'components/stack';
import { Badge, IconButton } from '@material-ui/core';

import style from './index.module.scss';

interface Props extends StackProps {
  meetingID: string;
  count: number;
}

export const ColumnClinicalMeetingActivities: React.FC<Props> = ({ meetingID, count }) => {
  const { isOpen, handleOpen, handleClose } = useOpen();

  return (
    <>
      <IconButton className={style.button} onClick={handleOpen}>
        <Badge badgeContent={count} color="primary" showZero classes={{ badge: style.badge }} />
      </IconButton>
      {isOpen && <ClinicalMeetingActivitiesDialog clinicalMeetingID={meetingID} onClose={handleClose} />}
    </>
  );
};
