import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';
export const selectAuthState = (state: RootState) => state.auth;
export const selectAuthNotifications = createSelector(
  selectAuthState,
  ({ notificationDoNotAsk }) => {
    return { doNotAsk: Boolean(notificationDoNotAsk) };
  },
);
export const selectAuthUser = createSelector(selectAuthState, ({ user }) => {
  return user as Components.Schemas.CurrentUserProfileResponse | null;
});
export const selectAuthPermissions = createSelector(selectAuthUser, (user) => {
  return user ? user.userEmployeeProfilePermission : null;
});
export const selectAuthStatuses = createSelector(selectAuthState, ({ loading, init }) => {
  return { isLoading: Boolean(loading), isInit: Boolean(init) };
});
