import React, { useCallback, useRef } from 'react';
import { Button, ButtonProps, Menu, MenuItem } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { useEffectError } from 'hooks';
import { apiUserPatientProfile, createBackdoorLink } from 'services/user-patient-profile';
import { isMutationRejected } from 'utils/rtk-query';
import { useAppDispatch, useAppSelector } from 'store';
import { actionPatientMessageAddItem } from 'store/patient-sms-messages';
import { apiSmsContents, SMS_CONTENT_KEYS } from 'services/sms-contents';
import { calcHtmlTemplate } from 'utils';
import { useCurrentUser } from 'components/hooks';
import { differenceInMinutes } from 'date-fns';
import { apiNotebooks } from 'services/notebook';
import { useOpen } from 'AurionCR/components/hooks';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { createPatientSurveyLink } from 'services/user-patient-profile-form-documents';
import { selectLanguageID } from 'store/languages';

const usePatchMutation = apiUserPatientProfile.usePatchPatientWithLogMutation;
const MINUTES = 60 * 24 * 7;

interface Props extends ButtonProps {
  userPatientProfileID: string;
  toPhoneNumber: string | undefined | null;
  patient:
    | Pick<Components.Schemas.UserPatientProfile, 'firstName' | 'lastName' | 'mustFillHealthSurvey'>
    | undefined;
  onDone?: () => void;
}

export const ButtonFillHealthSurvey: React.FC<Props> = ({
  userPatientProfileID,
  toPhoneNumber,
  patient,
  onDone,
  ...rest
}) => {
  const { t, tp } = useTranslate();
  const currentEmployee = useCurrentUser();

  const languageID = useAppSelector(selectLanguageID);
  const dispatch = useAppDispatch();

  const [triggerGetContent, resultContent] = apiSmsContents.useLazyGetSmsContentBySmsKeyQuery();
  useEffectError(resultContent.error);
  const [makeBackDoor, resultBackDoor] = apiUserPatientProfile.useMakePatientBackDoorMutation();
  useEffectError(resultBackDoor.error);
  const [patch, resultPatch] = usePatchMutation();
  useEffectError(resultPatch.error);

  const onMake = useCallback(
    async (notebookID: string) => {
      if (!patient) return;
      const [resBackDoor, resContent, resPatch] = await Promise.all([
        makeBackDoor({ userPatientProfileID, minutes: MINUTES }),
        triggerGetContent(
          {
            contentKey: SMS_CONTENT_KEYS.SEND_SMS_FILL_HEALTH_SURVEY,
            languageID,
          },
          true,
        ),
        patch({
          initData: {
            appIdentityUserID: userPatientProfileID,
            mustFillHealthSurvey: patient.mustFillHealthSurvey,
          },
          formData: { appIdentityUserID: userPatientProfileID, mustFillHealthSurvey: true },
          remark: tp('activity-patient-ask-fill-health-survey'),
        }),
      ]);

      if (isMutationRejected(resBackDoor) || isMutationRejected(resPatch)) {
        return;
      }
      if (!resContent.data) {
        return;
      }

      const inMinutes = differenceInMinutes(resBackDoor.data.expired, new Date());
      const inHours = Math.ceil(inMinutes / 60);

      const duration = `${inHours} ${t('hours')}`;

      const redirect = createPatientSurveyLink({ id: notebookID });

      const templatePayload = {
        patient,
        currentEmployee,
        url: createBackdoorLink({
          userPatientProfileID,
          secret: resBackDoor.data.secret,
          redirect,
        }),
        duration,
      };

      dispatch(
        actionPatientMessageAddItem({
          uid: 'patient-ask-fill-survey',
          formData: {
            userPatientProfileID,
            toPhoneNumber,
            message: calcHtmlTemplate(resContent.data.message || '', templatePayload),
          },
          templatePayload,
          onSuccess: onDone,
        }),
      );
    },
    [
      userPatientProfileID,
      makeBackDoor,
      patch,
      toPhoneNumber,
      patient,
      currentEmployee,
      dispatch,
      languageID,
      triggerGetContent,
      t,
      tp,
      onDone,
    ],
  );

  const ref = useRef<HTMLButtonElement>(null);

  const { data: options = [], isLoading: isLoadingOptions } =
    apiNotebooks.useGetNotebooksHealthSurveyQuery();

  const isLoading =
    resultBackDoor.isLoading ||
    resultPatch.isLoading ||
    resultContent.isLoading ||
    resultContent.isLoading ||
    isLoadingOptions;

  const { isOpen, handleClose, handleOpen } = useOpen();
  const factoryClickMenuItem = useCallback(
    (id: string) => {
      return () => {
        onMake(id);
        handleClose();
      };
    },
    [handleClose, onMake],
  );

  return (
    <>
      <Button
        ref={ref}
        variant={'contained'}
        color={'primary'}
        startIcon={<PlaylistAddCheckIcon />}
        disabled={isLoading}
        onClick={handleOpen}
        {...rest}
      >
        {t('ask-to-fill-health-survey')}
      </Button>
      <Menu
        anchorEl={ref.current}
        keepMounted
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {options.map((item) => (
          <MenuItem key={item.id} onClick={factoryClickMenuItem(item.id)}>
            {item.labelKey}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
