import { RootState } from '../index';
import { createSelector } from '@reduxjs/toolkit';
import { PATIENT_ITEM_STATUS } from 'store/patient-maker/slice';

const selectState = (state: RootState) => state.patientMaker;

export const selectPatientMakerStatuses = createSelector(
  selectState,
  ({ isLoading, error, isInit }) => ({
    isLoading,
    isInit,
    error,
  }),
);

export const selectPatientMakerBulk = createSelector(selectState, ({ bulk }) => bulk);

export const selectPatientMakerData = createSelector(selectState, ({ data }) => {
  return data;
});

export const selectPatientMakerDataSelected = createSelector(selectPatientMakerData, (data) => {
  return data.filter(({ isSelected }) => isSelected);
});

export const selectPatientMakerTotal = createSelector(selectPatientMakerData, (data) => {
  let selected = 0;
  let success = 0;
  let error = 0;
  data.forEach((item) => {
    if (item.status === PATIENT_ITEM_STATUS.SUCCESS) {
      success++;
    }
    if (item.status === PATIENT_ITEM_STATUS.ERROR) {
      error++;
    }
    if (item.isSelected) {
      selected++;
    }
  });
  return {
    selected,
    success,
    error,
  };
});

export const selectPatientMakerDataAllSelected = createSelector(
  selectPatientMakerData,
  selectPatientMakerDataSelected,
  (data, selectedData) => {
    return data.length > 0 && data.length === selectedData.length;
  },
);

export const isPatientItemLoading = (status: PATIENT_ITEM_STATUS) => {
  return [
    PATIENT_ITEM_STATUS.FETCHING,
    PATIENT_ITEM_STATUS.GENERATING,
    PATIENT_ITEM_STATUS.MIGRATING,
  ].includes(status);
};
export const selectPatientMakerIsRunning = createSelector(
  selectPatientMakerDataSelected,
  (selectedData) => {
    return selectedData.some(({ status }) => {
      return isPatientItemLoading(status);
    });
  },
);
