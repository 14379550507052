import React, { useMemo } from 'react';
import { useOpen } from 'AurionCR/components/hooks';
import { composeFunctions } from 'utils';
import { BaseAction } from '../../models';
import { ButtonAction } from '../button-action';
import { useAppSelector } from 'store';
import { debounce } from 'lodash-es';
import { useCurrentUser } from 'components/hooks';
import { DialogSupportMeetingNew } from 'components/dialog-support-meeting';
import { SupportMeetingInput } from 'services/support-meetings';
import { selectTMTimes } from 'pages/TaskManager/components/store';
import { SUPPORT_MEETING_TYPE_IDs } from 'services/support-meeting-types';

interface Props extends BaseAction {
  userPatientProfileID: string;
}
export const ActionSupportMeeting: React.FC<Props> = ({
  userPatientProfileID,
  onDone: _onDone,
  actionKey,
  isLoading,
}) => {
  const { appUserID } = useCurrentUser();
  const times = useAppSelector(selectTMTimes);
  const { isOpen, handleOpen, handleClose } = useOpen();

  const onDone = useMemo(
    () => debounce(composeFunctions(_onDone, handleClose), 500),
    [_onDone, handleClose],
  );
  const initData = useMemo<Partial<SupportMeetingInput>>(() => {
    return {
      userPatientProfileID,
      userEmployeeProfileID: appUserID,
      supportMeetingTypeID: SUPPORT_MEETING_TYPE_IDs.OBSERVE,
    };
  }, [userPatientProfileID, appUserID]);

  return (
    <>
      <ButtonAction actionKey={actionKey} onClick={handleOpen} disabled={isLoading} />
      {isOpen && (
        <DialogSupportMeetingNew
          itemID={true}
          initData={initData}
          times={times}
          onRefresh={onDone}
          onClose={handleClose}
        />
      )}
    </>
  );
};
