import React, { useCallback, useEffect, useState } from 'react';
import {
  DialogSendMessage,
  SendMessageForm,
  schemaEmailModel,
} from 'components/dialog-send-message';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { useEffectError } from 'hooks';
import { useTranslate } from 'hooks/use-translate';
import { makeFullName } from 'utils/app-helpers';
import * as yup from 'yup';
import { apiEmailMessages } from 'services/email-messages';
import { InferType } from 'yup';
import { isMutationFulfilled } from 'utils/rtk-query';
import { useAppDispatch } from 'store';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { Collapse } from '@material-ui/core';
import { useCurrentUser, useSourcePharmacies } from 'components/hooks';
import { AppSelect } from 'components/app-select';
import { Pharmacy } from 'services/pharmacies';
import { apiUserPatientProfileActivities } from 'services/user-patient-profile-activities';

const getOptionLabel = (option: Pharmacy) => {
  return option ? String(option.name) : '';
};

const schema = schemaEmailModel.shape({
  fileURLs: yup.array(yup.string().required('rule-required')).min(1, 'rule-required').default([]),
});

type Model = InferType<typeof schema>;
const useFetchPatientDetails = apiUserPatientProfile.useGetPatientForGeneralFormQuery;
const useSendMessageMutation = apiEmailMessages.useSendEmailToPharmacyMutation;

interface Props {
  userPatientProfileID: string;
  onClose: () => void;
  fileURLs: string[];
}
export const DialogSendFilesToPharmacy: React.FC<Props> = ({
  userPatientProfileID,
  onClose,
  fileURLs,
}) => {
  const { appUserID } = useCurrentUser();
  const [pharmacyID, setPharmacyID] = useState('');
  const sourcePharmacies = useSourcePharmacies();

  const dispatch = useAppDispatch();
  const { tp, t } = useTranslate();
  const formMethods = useForm({
    defaultValues: schema.cast({
      fileURLs,
    }),
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset, getValues, register, setValue } = formMethods;

  const resultPatient = useFetchPatientDetails(userPatientProfileID);
  useEffectError(resultPatient.error);

  const _pharmacy = sourcePharmacies.map[pharmacyID];

  useEffect(() => {
    if (!resultPatient.data) return;
    if (!_pharmacy) return;
    const fullName = makeFullName(resultPatient.data);
    const patient = { fullName };
    const pharmacy = { name: String(_pharmacy.name) };

    reset(
      schema.cast({
        ...getValues(),
        fromDisplayName: tp('email-display-name'),
        replyToEmail: tp('email-reply-to'),
        subject: tp('email-to-pharmacy-document-subject', {
          patient,
          pharmacy,
        }),
        html: tp('email-to-pharmacy-document-html', {
          patient,
          pharmacy,
        }),
      }),
    );
  }, [resultPatient.data, reset, tp, getValues, _pharmacy]);

  const [triggerSend, resultSend] = useSendMessageMutation();
  useEffectError(resultSend.error);

  const onSubmit = useCallback(
    async (data: Model) => {
      const resultSend = await triggerSend({
        toEmails: data.toEmails,
        subject: data.subject,
        fromDisplayName: data.fromDisplayName,
        replyToEmail: data.replyToEmail,
        html: data.html,
        attachments: data.fileURLs,
        userPatientProfileID,
      });

      if (isMutationFulfilled(resultSend)) {
        onClose();
        dispatch(
          apiUserPatientProfileActivities.endpoints.postUserPatientProfileActivity.initiate({
            userPatientProfileID,
            userEmployeeProfileID: appUserID,
            remarks: tp('activity-patient-send-document-to-pharmacy'),
          }),
        );
        dispatch(notifyRequestResult(tp('email-send-success')));
      }
    },
    [triggerSend, onClose, tp, dispatch, userPatientProfileID, appUserID],
  );
  const isLoading = resultPatient.isFetching || resultSend.isLoading;

  const onChangePharmacy = useCallback(
    (value: any, option: Pharmacy | null) => {
      setPharmacyID(value);
      setValue('toEmails', [option?.email1, option?.email2].filter(Boolean));
    },
    [setValue],
  );

  return (
    <DialogSendMessage isLoading={isLoading} onClose={onClose} onSubmit={handleSubmit(onSubmit)}>
      <input hidden {...register('fileURLs')} />
      <FormProvider {...formMethods}>
        <AppSelect
          label={t('pharmacy')}
          value={pharmacyID}
          options={sourcePharmacies.data}
          loading={sourcePharmacies.loading}
          onChange={onChangePharmacy}
          getOptionLabel={getOptionLabel}
          disableClearable={true}
        />
        <Collapse in={!!pharmacyID}>
          <div>
            <SendMessageForm isLoading={isLoading} />
          </div>
        </Collapse>
      </FormProvider>
    </DialogSendMessage>
  );
};
