import React from 'react';
import { ButtonDownload29Gimel } from 'components/medical-prescription';

interface Row {
  userPatientProfilePrescriptionID: string;
  userPatientProfilePrescriptionDetailID: string;
  includeForm29: boolean;
}
interface Props {
  data: Row;
}
export const GridControls: React.FC<Props> = ({ data }) => {
  return (
    <ButtonDownload29Gimel
      userPatientProfilePrescriptionID={data.userPatientProfilePrescriptionID}
      userPatientProfilePrescriptionDetailID={data.userPatientProfilePrescriptionDetailID}
      includeForm29={data.includeForm29}
    />
  );
};
