import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actionPatientMakerGetData } from './actions';

interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;
  data: PatientItem[];
  bulk: number;
}

export enum PATIENT_ITEM_STATUS {
  DEFAULT,
  FETCHING,
  GENERATING,
  MIGRATING,
  SUCCESS,
  ERROR,
}

interface ItemData {
  url: string;
  patientID?: string;
}

export interface PatientItem {
  isSelected: boolean;
  status: PATIENT_ITEM_STATUS;
  error: Error | null;
  item: ItemData;
  itemID: string;
}

const initState = (): State => {
  return {
    error: null,
    isLoading: false,
    isInit: false,
    data: [],
    bulk: 100,
  };
};

type ActionItemSetStatusPayload =
  | { itemID: string; status: PATIENT_ITEM_STATUS.DEFAULT }
  | { itemID: string; status: PATIENT_ITEM_STATUS.FETCHING }
  | { itemID: string; status: PATIENT_ITEM_STATUS.GENERATING }
  | { itemID: string; status: PATIENT_ITEM_STATUS.MIGRATING }
  | { itemID: string; status: PATIENT_ITEM_STATUS.SUCCESS; patientID: string }
  | { itemID: string; status: PATIENT_ITEM_STATUS.ERROR; error: Error };

const slice = createSlice({
  name: 'PATIENT_MAKER',
  initialState: initState(),
  reducers: {
    actionPatientMakerSelectAll(state, action: PayloadAction<boolean>) {
      state.data = state.data.map((item) => ({ ...item, isSelected: action.payload }));
    },
    actionPatientMakerSelect(state, action: PayloadAction<{ itemID: string; value: boolean }>) {
      const itemIndex = state.data.findIndex(({ itemID }) => itemID === action.payload.itemID);

      if (itemIndex !== -1) {
        state.data[itemIndex].isSelected = action.payload.value;
      }
    },
    actionPatientMakerItemSetStatus(state, action: PayloadAction<ActionItemSetStatusPayload>) {
      const itemIndex = state.data.findIndex(({ itemID }) => itemID === action.payload.itemID);

      if (itemIndex === -1) {
        return state;
      }

      state.data[itemIndex].status = action.payload.status;

      if (action.payload.status === PATIENT_ITEM_STATUS.ERROR) {
        state.data[itemIndex].error = action.payload.error;
      }
      if (action.payload.status === PATIENT_ITEM_STATUS.SUCCESS) {
        state.data[itemIndex].item.patientID = action.payload.patientID;
      }
    },
    actionPatientMakerSetBulk(state, action: PayloadAction<number>) {
      state.bulk = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionPatientMakerGetData.pending, (state) => {
        state.isLoading = true;
        state.isInit = true;
        state.error = null;
      })
      .addCase(actionPatientMakerGetData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.map((url) => {
          const itemID = url;
          const oldItem = state.data.find((item) => {
            return item.itemID === itemID;
          });
          if (oldItem) {
            return oldItem;
          }
          return {
            isSelected: false,
            status: PATIENT_ITEM_STATUS.DEFAULT,
            error: null,
            item: { url },
            itemID,
          };
        });
      })
      .addCase(actionPatientMakerGetData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export const {
  actionPatientMakerSelectAll,
  actionPatientMakerSelect,
  actionPatientMakerItemSetStatus,
  actionPatientMakerSetBulk,
} = slice.actions;
export const reducerPatientMaker = slice.reducer;
