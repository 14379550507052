import React, { useCallback, useEffect, useMemo } from 'react';
import { DialogSendMessage, EmailModel, SendMessageForm } from 'components/dialog-send-message';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { CircularProgress, Collapse, Divider, Typography } from '@material-ui/core';
import { Select, validateRule } from 'AurionCR/components/formV2';
import { useSourcePharmacies } from 'components/hooks';
import {
  useEffectError,
  useFetchDynamic,
  useFieldProps,
  useMountedRef,
  useMutationDynamic,
} from 'hooks';
import { ServicePatientPrescription } from 'services/user-patient-profile-prescriptions';
import { createTableString } from 'utils/pdf-helpers';
import { differenceInYears } from 'date-fns';
import { convertToDate } from 'utils/dates';
import { ServiceEmailMessages } from 'services/email-messages';
import { ServicePharmacyMessages } from 'services/pharmacy-message';
import { ServiceSiteParameters } from 'services/site-parameters';
import { CONTENT_KEYS, ServiceDocumentTemplates } from 'services/document-templates';
import { ServicePdf } from 'services/pdf';
import { useAppDispatch, useAppSelector } from 'store';
import { base64ToFileStream } from 'utils/file-uploader';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';
import { ServiceMediaPrivate } from 'services/media-private-services';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { CLINIC_EMAIL } from 'configs/const';
import { selectLanguageID, selectLanguageIsRtl } from 'store/languages';
import { apiUserPatientProfilePrescriptionActivities } from 'services/user-patient-profile-prescription-activities';
import { apiUserPatientPrescriptions } from 'services/user-patient-profile-prescriptions';

const usePathPrescriptionMutation = apiUserPatientPrescriptions.usePatchMedicalPrescriptionMutation;

interface Model extends EmailModel {
  pharmacyID: string;
}

interface Props {
  userPatientProfilePrescriptionID: string;
  onClose: () => void;
  onDone: () => void;
}

export const MedicalPrescriptionDialogEmail: React.FC<Props> = ({
  userPatientProfilePrescriptionID,
  onClose,
  onDone,
}) => {
  const isRtl = useAppSelector(selectLanguageIsRtl);
  const { t, tp } = useTranslate();

  const [patchPrescription] = usePathPrescriptionMutation();

  const dispatch = useAppDispatch();

  const defaultValues = useMemo<Model>(() => {
    return {
      toEmails: [],
      subject: '',
      replyToEmail: tp('email-reply-to'),
      fromDisplayName: tp('email-display-name'),
      html: '',
      pharmacyID: '',
    };
  }, [tp]);

  const formMethods = useForm({ defaultValues });
  const { reset } = formMethods;

  const sourcePharmacies = useSourcePharmacies();
  const pharmacyID = formMethods.watch('pharmacyID');
  const pharmacy = sourcePharmacies.map[pharmacyID];

  const languageID = useAppSelector(selectLanguageID);

  const getEmailData = useCallback(async () => {
    const [
      { data: siteParams },
      {
        data: { doctor, remarks, entryDate, drugs, patient },
      },
    ] = await Promise.all([
      ServiceSiteParameters.getMainInfo(languageID),
      ServicePatientPrescription.getPdfData(userPatientProfilePrescriptionID),
    ]);

    const data = { siteParams, remarks, doctor, entryDate, drugs, patient };
    return { data };
  }, [userPatientProfilePrescriptionID, languageID]);

  const [trigger, { data: dataEmail, isLoading: isLoadingData, error }] =
    useFetchDynamic(getEmailData);
  useEffectError(error);

  useEffect(() => {
    trigger();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pharmacy && dataEmail) {
      const dateOfBirth = convertToDate(dataEmail.patient.dateOfBirth);

      const age = String(differenceInYears(new Date(), dateOfBirth));

      const bodyContent = [
        pharmacy.defaultMessageHtml,
        '<br />',
        createTableString({
          rows: [
            { title: `<strong>${t('id-number')}</strong>`, value: dataEmail.patient.idNumber },
            { title: `<strong>${t('patient-name')}</strong>`, value: dataEmail.patient.fullName },
            {
              title: `<strong>${t('age')}</strong>`,
              value: age,
            },
            {
              title: `<strong>${t('mobile-phone')}</strong>`,
              value: dataEmail.patient.mobilePhone,
            },
          ],
          translate: t,
          isRenderHeader: false,
          styles: {
            table: { border: 'none' },
            tr: { border: 'none' },
            td: { border: 'none' },
          },
        }),
        '<br />',
        createTableString({
          rows: dataEmail.drugs,
          translate: t,
          styles: {
            table: {
              width: '100%',
              tableLayout: 'fixed',
              border: '1px solid #ddd',
              borderCollapse: 'collapse',
            },
            trHead: { backgroundColor: '#2698d4', color: 'white' },
            td: { border: '1px solid #ddd' },
            th: { border: '1px solid #ddd', textAlign: 'unset' },
          },
        }),
      ]
        .filter(Boolean)
        .join('');

      reset({
        ...defaultValues,
        toEmails: [pharmacy.email1, pharmacy.email2, CLINIC_EMAIL].filter(Boolean),
        subject: pharmacy.defaultMessageSubject,
        pharmacyID: pharmacy.id,
        html: `<div dir="${isRtl ? 'rtl' : 'ltr'}">${bodyContent}<div>`,
      });
    }
    // eslint-disable-next-line
  }, [pharmacy, dataEmail]);

  const sendMessage = useCallback(
    async (data: Model) => {
      if (!dataEmail) {
        throw new Error('can-not-create-a-message-log');
      }

      const { pharmacyID, html, ...rest } = data;
      const {
        data: { html: prescriptionHtmlTemplate },
      } = await ServiceDocumentTemplates.getByContentKey(CONTENT_KEYS.PRESCRIPTION);
      const { data: base64 } = await ServicePdf.generatePrescriptionPharmacy({
        userPatientProfilePrescriptionID,
        htmlTemplate: prescriptionHtmlTemplate,
        payload: dataEmail,
      });
      const fileName = `pharmacy_${new Date().getTime()}.pdf`;

      const {
        data: { filePath },
      } = await ServiceMediaPrivate.uploadFile({
        fileStreamString: base64ToFileStream(base64),
        fileName,
      });

      const userPatientProfileID = String(dataEmail.patient.appIdentityUserID);

      const payload = { ...rest, html: String(html) };

      const emailUniqueAttachments = Array.from(new Set([filePath]).keys());

      await Promise.all([
        ServiceEmailMessages.sendEmail({
          ...payload,
          attachments: emailUniqueAttachments,
        }),
        ServicePharmacyMessages.post({
          pharmacyID,
          subject: payload.subject,
          html: payload.html,
          userPatientProfileID,
          userEmployeeProfileID: String(dataEmail.doctor.appIdentityUserID),
          prescriptionFilePath: filePath,
        }),
      ]);

      await patchPrescription({
        id: userPatientProfilePrescriptionID,
        isSent: true,
        isCompleted: true,
        completedDate: new Date().toISOString(),
      }).unwrap();
    },
    [dataEmail, userPatientProfilePrescriptionID, patchPrescription],
  );

  const [triggerSend, { isLoading: isLoadingSend, error: errorSend }] =
    useMutationDynamic(sendMessage);
  useEffectError(errorSend);

  const mountedRef = useMountedRef();
  const onSubmit = useCallback(
    async (data: Model) => {
      const result = await triggerSend(data);

      if (result.error) {
        return;
      }

      dispatch(notifyRequestResult(t('success-send-and-complete'), 'success'));
      await dispatch(
        apiUserPatientProfilePrescriptionActivities.endpoints.createUserPatientProfilePrescriptionActivity.initiate(
          {
            userPatientProfilePrescriptionID,
            remarks: tp('activity-patient-prescription-send-to-pharmacy', {
              pharmacyName: pharmacy?.name || '',
            }),
          },
        ),
      );

      if (mountedRef.current) {
        onDone();
        onClose();
      }
    },
    [
      triggerSend,
      mountedRef,
      onDone,
      onClose,
      dispatch,
      t,
      tp,
      userPatientProfilePrescriptionID,
      pharmacy,
    ],
  );

  const getFieldProps = useFieldProps({ errors: formMethods.errors });

  const isLoading = isLoadingSend;

  const isShowMainForm = Boolean(pharmacyID && !isLoadingData);

  return (
    <FormProvider {...formMethods}>
      <DialogSendMessage
        DialogProps={{
          maxWidth: Boolean(pharmacyID) ? 'lg' : 'sm',
        }}
        onClose={onClose}
        onSubmit={formMethods.handleSubmit(onSubmit)}
        isLoading={isLoading || isLoadingData}
      >
        <Controller
          name="pharmacyID"
          rules={validateRule('required')}
          render={(renderProps) => (
            <Select
              {...getFieldProps(renderProps)}
              loading={sourcePharmacies.loading}
              source={sourcePharmacies.data}
              disableClearable
              option={{ label: 'name', value: 'id' }}
              disabled={isLoading}
            />
          )}
          control={formMethods.control}
        />
        {pharmacyID && isLoadingData && (
          <div className={style.template}>
            <Typography color={'secondary'} variant={'h3'}>
              {t('creating-template')}
              <CircularProgress size={12} />
            </Typography>
          </div>
        )}
        <Collapse in={isShowMainForm}>
          <Divider style={{ margin: '1rem -2rem' }} />
          <SendMessageForm isLoading={isLoading} />
        </Collapse>
      </DialogSendMessage>
    </FormProvider>
  );
};
