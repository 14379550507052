import React, { useMemo } from 'react';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';

const EMPTY_VALUE = '-';

interface Config {
  value: number;
  color: string;
}

interface Props {
  bmi: number;
  configs: Config[];
}

export const BmiChartTotal: React.FC<Props> = ({ bmi, configs }) => {
  const { tp } = useTranslate();
  const config = useMemo(() => {
    const min = Math.min(...configs.map(({ value }) => value));
    if (bmi < min) {
      return configs[0];
    }
    const max = Math.max(...configs.map(({ value }) => value));
    if (bmi > max) {
      return configs[configs.length - 1];
    }
    return configs.find(({ value }) => bmi < value);
  }, [configs, bmi]);

  const btnValue = useMemo(() => {
    if (bmi === 0) return EMPTY_VALUE;
    if (bmi > 200) return EMPTY_VALUE;
    if (isFinite(bmi)) {
      return bmi;
    }
    return EMPTY_VALUE;
  }, [bmi]);

  const { color } = config || {};
  return (
    <div className={style.root} style={{ color: color }}>
      {tp('bmi-result', { bmi: btnValue })}
    </div>
  );
};
