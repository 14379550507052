import React from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { Box, BoxProps } from '@material-ui/core';
import {
  GlobalSearch,
  ProfileNav,
  TopNav,
  EditorContent,
  EditorTitle,
  ButtonTask,
} from './components';
import { usePermissions } from 'hooks/use-permissions';
import { emitterLayoutContent } from './helpers';

export { EditorContent, EditorTitle, emitterLayoutContent };

interface Classes {
  root: string;
  main: string;
}

interface Props extends BoxProps {
  className?: string;
  classes?: Partial<Classes>;
  header?: React.ReactNode;
  visible?: boolean;
  MainProps?: Partial<BoxProps>;
}

export const LayoutContent: React.FC<Props> = ({
  className,
  classes,
  header,
  children,
  visible = true,
  MainProps,
  ...rest
}) => {
  const isAllowToViewPatients = usePermissions('isAllowToEditPatients');

  return (
    <Box
      className={clsx(style.root, classes?.root, className)}
      {...rest}
      style={{
        ...rest.style,
        display: !visible ? 'none' : undefined,
      }}
    >
      <div className={clsx(style.header)}>
        {header}
        <div className={style.separator} />
        <TopNav />
        {isAllowToViewPatients && <GlobalSearch />}
        <Box ml={'20px'}>
          <ButtonTask />
        </Box>
        <ProfileNav />
      </div>
      <Box {...MainProps} className={clsx(style.main, classes?.main, MainProps?.className)}>
        {children}
      </Box>
    </Box>
  );
};
