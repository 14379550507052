import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import { format, formatDistance } from 'date-fns';
import { convertToDate } from 'utils/dates';
import { APP_FORMAT_DATE_TIME, APP_FORMAT_TIME } from 'configs/const';
import style from './index.module.scss';
import clsx from 'clsx';
import { TimeSlot } from 'utils/app-helpers';
import { Stack } from 'components/stack';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useDateLocale } from 'contexts/picker-provider';
import { EmployeeAvatar } from 'components/employee-avatar';
import { APP_ROUTES } from 'configs/routes';

interface Props {
  appIdentityUserID: string;
  userPhoto: string | null | undefined;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  lastActivityDate: string | null | undefined;
  mobilePhone: string | null | undefined;
  height: number;
  isActivityLow: boolean;
  timeSlot: undefined | TimeSlot;
}
export const EmployeeMonitorItem: React.FC<Props> = ({
  userPhoto,
  firstName,
  lastName,
  lastActivityDate,
  mobilePhone,
  height,
  isActivityLow,
  timeSlot,
  appIdentityUserID,
}) => {
  const { fullName, short } = useMemo(() => {
    const fullName = [firstName, lastName].filter(Boolean).join(' ');

    const short = [firstName, lastName]
      .map((word) => (word ? word[0] : '').toUpperCase())
      .filter(Boolean)
      .join(' ');
    return { fullName, short };
  }, [firstName, lastName]);

  const activityDate = useMemo(
    () => format(convertToDate(lastActivityDate), APP_FORMAT_DATE_TIME),
    [lastActivityDate],
  );

  const { locale } = useDateLocale();
  const activityDistance = formatDistance(convertToDate(lastActivityDate), new Date(), { locale });

  const slotRange = useMemo(() => {
    return timeSlot
      ? [timeSlot.fromTime, timeSlot.toTime]
          .map((time) => format(convertToDate(time), APP_FORMAT_TIME))
          .join(' - ')
      : null;
  }, [timeSlot]);

  const isEmpty = !timeSlot;

  return (
    <ListItem
      component={'div'}
      className={clsx(style.root, {
        [style.activityLow]: isActivityLow,
        [style.activityEmpty]: isEmpty,
      })}
      style={{ height }}
    >
      <ListItemAvatar classes={{ root: style.avatar }}>
        <EmployeeAvatar userEmployeeProfileID={appIdentityUserID} src={userPhoto || ''}>
          {short}
        </EmployeeAvatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <div className={style.content}>
            <Typography variant={'h5'} className={style.name}>
              {fullName}
            </Typography>
            <Typography
              title={activityDate}
              variant={'body2'}
              color={'textSecondary'}
              className={style.timeout}
            >
              {activityDistance}
            </Typography>
          </div>
        }
        secondary={slotRange}
        secondaryTypographyProps={{
          color: 'textSecondary',
        }}
      />
      <div className={style.control}>
        <Stack direction={'row'}>
          <IconButton
            color={'primary'}
            component={Link}
            to={APP_ROUTES.EMPLOYEES({ employee: appIdentityUserID }).config}
          >
            <VisibilityIcon fontSize={'small'} />
          </IconButton>
          {mobilePhone && (
            <IconButton component={'a'} color={'primary'} href={`tel:${mobilePhone}`}>
              <CallIcon fontSize={'small'} />
            </IconButton>
          )}
        </Stack>
      </div>
    </ListItem>
  );
};
