import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'UserPatientProfileSessionSideEffects';

export const API_USER_PATIENT_PROFILE_SESSION_SIDE_EFFECTS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  POST_BULK: `${API_ENDPOINT}/CreateUserPatientProfileSessionSideEffectBulk`,
  PATCH: (data: Pick<UserPatientProfileSessionSideEffect, 'id'>) =>
    `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<UserPatientProfileSessionSideEffect, 'id'>) =>
    `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface UserPatientProfileSessionSideEffect
  extends FixServerObject<Components.Schemas.UserPatientProfileSessionSideEffect, 'id'> {
  createdDate?: string;
}

export interface CreateBulkUserPatientProfileSessionSideEffectInput {
  userPatientProfileSessionID: string;
  sideEffectIDs: string[];
}
