import { lookup } from 'mime-types';
import { getFileNameExt } from './file-uploader';
interface Options {
  fileName: string;
}

export const downloadBlob = (blob: Blob, options: Options) => {
  const blobOptions = (() => {
    const ext = getFileNameExt(options.fileName);
    const type = lookup(ext);

    if (!type) return undefined;

    return { type };
  })();

  // create file link in browser's memory
  const href = URL.createObjectURL(new Blob([blob], blobOptions));

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', options.fileName); //or any other extension
  document.body.appendChild(link);
  link.click();
  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};
