import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'UserPatientProfileSubscriptionActivities';

export const API_USER_PATIENT_PROFILE_SUBSCRIPTION_ACTIVITIES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<IAppUserPatientProfileSubscriptionActivity, 'id'>) =>
    `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<IAppUserPatientProfileSubscriptionActivity, 'id'>) =>
    `${API_ENDPOINT}/Delete/${data.id}`,
};

export enum SubscriptionActivityType {
  Duration = 1,
  General = 2,
}

export interface IAppUserPatientProfileSubscriptionActivity
  extends FixServerObject<
    Omit<Components.Schemas.UserPatientProfileSubscriptionActivity, 'subscriptionActivityType'>,
    'id' | 'userEmployeeProfileID' | 'userPatientProfileSubscriptionID'
  > {
  subscriptionActivityType: SubscriptionActivityType;
}

export interface CreateUserPatientProfileSubscriptionActivityLogChangeEndDateInput {
  userPatientProfileSubscriptionID: string;
  endDate: string;
  remarks: string;
}
export interface CreateUserPatientProfileSubscriptionActivityLogChangeStartDateInput {
  userPatientProfileSubscriptionID: string;
  startDate: string;
}
