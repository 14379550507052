import React, { useCallback } from 'react';
import { GridControlsDropDownTemplateProps } from 'models/grid';
import { MenuItem, Typography } from '@material-ui/core';
import clsx from 'clsx';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';
import CloseIcon from '@material-ui/icons/Close';
import { Stack } from 'components/stack';
import { apiClinicalMeetings } from 'services/clinical-meetings';
import { useEffectError, useMountedRef } from 'hooks';
import { isMutationFulfilled } from 'utils/rtk-query';

const useNotShowingUpMutation = apiClinicalMeetings.useSetClinicalMeetingToNotShowingUpMutation;

interface Props
  extends GridControlsDropDownTemplateProps<{
    id: string;
    isNotShowingUpMeeting: boolean;
    isCanceled: boolean;
    clinicalMeetingSubjectID: string;
  }> {}
export const ControlNotShowingUp: React.FC<Props> = ({ data, handleClose, onTask }) => {
  const { t } = useTranslate();
  const mountedRef = useMountedRef();

  const isDisabled = data.isNotShowingUpMeeting || data.isCanceled;

  const [triggerNotShowingUp, resultNotShowingUp] = useNotShowingUpMutation();
  useEffectError(resultNotShowingUp.error);
  const onClick = useCallback(async () => {
    const res = await triggerNotShowingUp({
      id: data.id,
      clinicalMeetingSubjectID: data.clinicalMeetingSubjectID,
    });

    if (isMutationFulfilled(res)) {
      onTask([['getData']]);
    }

    if (!mountedRef.current) {
      return;
    }

    if (isMutationFulfilled(res)) {
      handleClose();
    }
  }, [
    onTask,
    triggerNotShowingUp,
    data.id,
    data.clinicalMeetingSubjectID,
    mountedRef,
    handleClose,
  ]);

  return (
    <MenuItem
      disabled={isDisabled || resultNotShowingUp.isLoading}
      className={clsx(style.root)}
      onClick={onClick}
    >
      <Stack spacing={1}>
        <CloseIcon fontSize={'small'} />
        <Typography>{t('change-to-not-showing-up-meeting')}</Typography>
      </Stack>
    </MenuItem>
  );
};
