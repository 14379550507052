import React, { memo, useCallback } from 'react';
import { INotebookPrescription } from './index';
import Wrapper from './wrapper';
import style from './index.module.scss';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useAppDispatch } from 'store';
import { TMMerge } from '../../store';
import { useTranslate } from 'hooks/use-translate';

export const SessionPreview = memo(
  ({ data, isMenuItem, isGridItem, handleClose, disabled }: INotebookPrescription) => {
    const { t } = useTranslate();
    const dispatch = useAppDispatch();
    // handlers
    const onClick = useCallback(() => {
      dispatch(TMMerge({ dialogUserPatientProfileSession: data }));
      if (isMenuItem && handleClose) handleClose();
    }, [dispatch, data, isMenuItem, handleClose]);
    // render
    return (
      <>
        <Wrapper
          isMenuItem={isMenuItem}
          isGridItem={isGridItem}
          onClick={onClick}
          disabled={disabled}
        >
          <div className={style.session}>
            <VisibilityIcon className={style.icon} />
            {data.userPatientProfileSessionNotebookLabelKey || t('session')}
          </div>
        </Wrapper>
      </>
    );
  },
);

export default SessionPreview;
