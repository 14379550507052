import React, { memo, useMemo } from 'react';
import { Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { IUseForm, useFormHook, validateRule } from 'AurionCR/components/formV2';
import { IGridEditFormProps } from 'components/models';
import {
  API_USER_PATIENT_PROFILE_PRESCRIPTION_ACTIVITIES,
  IAppUserPatientProfilePrescriptionActivity,
} from 'services/user-patient-profile-prescription-activities';
import { useCurrentUser, useRequestNotify } from 'components/hooks';
import { useTranslate } from 'hooks/use-translate';
import { Controller } from 'react-hook-form';
import { useFieldProps } from 'hooks';
import { AppInput } from 'components/app-input';
import { SelectEmployee } from 'components/select-employee';
import { ButtonCancel, ButtonSave } from 'components/app-buttons';

type FormModel = IAppUserPatientProfilePrescriptionActivity;

interface Props extends IGridEditFormProps {
  userPatientProfilePrescriptionID: string;
}
export const Editor = memo<Props>(
  ({ onClose, onFormHandle, itemID, userPatientProfilePrescriptionID }) => {
    const { t } = useTranslate();
    const { appUserID } = useCurrentUser();

    const config = useMemo<IUseForm<FormModel>>(() => {
      return {
        editID: itemID,
        fields: {
          id: '',
          userPatientProfilePrescriptionID,
          userEmployeeProfileID: appUserID,
          entryDate: new Date().toISOString(),
          remarks: '',
        },
        endpoint: API_USER_PATIENT_PROFILE_PRESCRIPTION_ACTIVITIES.API_ENDPOINT,
        formHandle: onFormHandle,
      };
    }, [itemID, onFormHandle, userPatientProfilePrescriptionID, appUserID]);

    const {
      formLoading,
      setFormLoading,
      formUse: { control, errors, handleSubmit, register },
      onSubmit,
    } = useFormHook(config);

    const { requestWrap: wrapSubmit } = useRequestNotify({
      request: onSubmit,
      setLoading: setFormLoading,
    });
    const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

    return (
      <Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
        <DialogHeading
          isLoading={formLoading}
          title={t('activity')}
          onClose={onClose}
          disabled={formLoading}
        />
        <DialogContent>
          <input {...register('id')} type="hidden" />
          <input {...register('userPatientProfilePrescriptionID')} type="hidden" />
          <input {...register('isActive')} type="hidden" />
          <input {...register('entryDate')} type="hidden" />
          <Grid container spacing={4}>
            <Grid item md={12}>
              <Controller
                name="userEmployeeProfileID"
                rules={validateRule('required')}
                control={control}
                render={(renderProps) => {
                  const props = getFieldProps(renderProps);
                  return <SelectEmployee {...props} label={t('employee')} disabled={formLoading} />;
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'remarks'}
                control={control}
                render={(renderProps) => (
                  <AppInput {...getFieldProps(renderProps)} multiline minRows={5} />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonCancel isBack={true} onClick={onClose} />
          <ButtonSave isCreate={true} onClick={handleSubmit(wrapSubmit)} disabled={formLoading} />
        </DialogActions>
      </Dialog>
    );
  },
);
