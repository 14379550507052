import React, { memo, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'store';
import { PDFDocumentProxy } from 'modules/pdfjs';
import style from './index.module.scss';
import PdfCanvas from './pdf-canvas';
import clsx from 'clsx';

export const PdfPage = memo(
  ({ pdf, pageNumber = 0 }: { pdf: PDFDocumentProxy; pageNumber: number }) => {
    //state
    const { eventScrollTo } = useAppSelector((state) => state.pdfFormDocument);
    const isScrollToCurrent = useMemo(
      () => eventScrollTo?.pageNumber === pageNumber,
      [eventScrollTo, pageNumber],
    );
    //render
    return (
      <div className={style.page}>
        <div className={style.content}>
          <PdfCanvas pdf={pdf} pageNumber={pageNumber} />
          <SelectEffect show={isScrollToCurrent} />
        </div>
      </div>
    );
  },
);

const SelectEffect = memo(({ show }: { show: boolean }) => {
  // state
  const [active, setActive] = useState(show);
  // watcher
  useEffect(() => {
    let timer: any = null;
    if (show) {
      setActive(show);
    } else {
      timer = setTimeout(() => {
        setActive(false);
      }, 1320);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
    };
  }, [show, setActive]);
  // render
  return <div className={clsx(style.selectEffect, active && style.active)} />;
});

export default PdfPage;
