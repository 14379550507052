import React, { useMemo } from 'react';
import { useTranslate } from 'hooks/use-translate';
import { DialogValidity } from 'components/dialog-validity';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { calcBmi } from '../../../../utils/app-helpers';

interface Props {
  userPatientProfileID: string;
  onClose: () => void;
  onDone: () => void;
}

export const DialogValidityPatientFields: React.FC<Props> = ({
  userPatientProfileID,
  onClose,
  onDone,
}) => {
  const { tp } = useTranslate();
  const { data, isFetching, isSuccess } =
    apiUserPatientProfile.useGetPatientRequiredDetailsForGmel29Query(userPatientProfileID, {
      refetchOnMountOrArgChange: true,
    });

  const list = useMemo(() => {
    const bmi = calcBmi(data?.weight || 0, data?.height || 0);
    const isAvailableBMI = bmi >= 30;
    const isValidBMI = isAvailableBMI || !!data?.medicalRemarks;
    const gender = data?.gender;

    return [
      { title: tp('weight'), value: data?.weight, isValid: data?.weight ? data.weight > 0 : false },
      { title: tp('height'), value: data?.height, isValid: data?.height ? data.height > 0 : false },
      { title: tp('bmi'), value: bmi, isValid: isValidBMI, info: tp('bmi-less-30') },
      {
        title: tp('gender'),
        value: gender?.title,
        isValid: gender?.isMissing === false,
      },
    ];
  }, [data, tp]);

  return (
    <DialogValidity
      title={tp('patient-gimel29-verification')}
      list={list}
      success={{
        title: tp('patient-gimel29-verified-success-title'),
        description: tp('patient-gimel29-verified-success-description'),
      }}
      error={{
        title: tp('patient-gimel29-verified-error-title'),
        description: tp('patient-gimel29-verified-error-description'),
      }}
      isLoading={isFetching}
      isLoadedSuccess={isSuccess}
      onSubmit={onDone}
      onClose={onClose}
    />
  );
};
