import React, { useMemo } from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { useTranslate } from 'hooks/use-translate';
import { apiTodoTasks } from 'services/todo-tasks';
import { useEffectError } from 'hooks';
import { GridTaskActivities } from '../grid-activities';
import { dateFormat } from 'utils/dates';
import { CharacteristicList, CharacteristicItem } from '../characteristics';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { TemplateBooleanIcon } from 'components/templates/template-boolean-icon';
import { EmployeeChip } from 'components/select-employee';
import style from './index.module.scss';

const EMPTY = '--';

const renderEmployee = (
  employee: null | { appIdentityUserID: string; userPhoto: string | null; fullName: string },
  index: number,
) => {
  return (
    <React.Fragment key={index}>
      {employee ? (
        <EmployeeChip
          userEmployeeProfileID={employee.appIdentityUserID}
          userPhoto={employee.userPhoto}
          label={employee.fullName}
        />
      ) : (
        EMPTY
      )}
    </React.Fragment>
  );
};

const useGetQuery = apiTodoTasks.useGetTodoTaskDetailsForPreviewQuery;
interface Props {
  taskID: string;
  onClose: () => void;
}
export const DialogPreview: React.FC<Props> = ({ onClose, taskID }) => {
  const { t } = useTranslate();
  const { data, isFetching, error } = useGetQuery(taskID, {
    refetchOnMountOrArgChange: true,
  });
  useEffectError(error);

  const title = useMemo(() => {
    return t('preview-task');
  }, [t]);

  const items = useMemo<CharacteristicItem[]>(
    () => [
      {
        label: t('entry-date'),
        value: data ? dateFormat(data.createdDate, APP_FORMAT_DATE_TIME) : EMPTY,
      },
      {
        label: t('execution-date'),
        value: data ? dateFormat(data.entryDate, APP_FORMAT_DATE_TIME) : EMPTY,
      },
      {
        label: t('done'),
        value: <TemplateBooleanIcon value={!!data?.done} />,
      },
      {
        label: t('close-date'),
        value: data ? dateFormat(data.closeDate, APP_FORMAT_DATE_TIME) : EMPTY,
      },
      {
        label: t('close-employee'),
        value: renderEmployee(data?.closeEmployee ?? null, 0),
      },
      {
        label: t('assigned-by'),
        value: renderEmployee(data?.sendBy ?? null, 0),
      },
      {
        label: t('assigned-to'),
        value: data ? (
          <div className={style.employees}>{data.assignedTo.map(renderEmployee)}</div>
        ) : (
          EMPTY
        ),
      },
      {
        label: t('message'),
        value: data?.message,
      },
    ],
    [data, t],
  );

  return (
    <Dialog open={true} fullWidth maxWidth={'xl'} onClose={onClose}>
      <DialogHeading isLoading={isFetching} title={title} onClose={onClose} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={5}>
            <CharacteristicList items={items} />
          </Grid>
          <Grid item xs={12} md={6} lg={7} className={style.activities}>
            <GridTaskActivities toDoTaskID={taskID} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
