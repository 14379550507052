import axios from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { apiStatic, apiStaticCanceled, requestError } from 'AurionCR/components';
import { API_FORM_DOCUMENTS, FormDocument } from 'services/form-documents';
import {
  PdfFormDocument,
  PdfFormDocumentActionTypes,
  PdfFormDocumentInitAction,
  PdfFormDocumentInput,
  PdfFormDocumentSaveAction,
  PdfFormDocumentScrollToInputAction,
  PdfFormDocumentScrollToPageAction,
} from './@type';
import { pdfFormDocumentInit, pdfFormDocumentMerge, pdfFormDocumentReset } from './module';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { API_FORM_DOCUMENT_INPUTS } from 'services/form-document-inputs';
import { round } from 'lodash-es';

// INIT
function* init({ payload }: PdfFormDocumentInitAction) {
  const { init, scale }: PdfFormDocument = yield select((state) => state.pdfFormDocument);
  if (!init && payload) {
    yield put(pdfFormDocumentMerge({ init: true, loading: true }));
    try {
      const {
        data: { value },
      }: { data: { value: FormDocument[] } } = yield call(apiStaticCanceled, {
        url: API_FORM_DOCUMENTS.GET_ALL_DYNAMIC,
        _cancelID: 'PdfFormDocument',
        params: {
          filter: `id=="${payload}"`,
          select: 'id,title,pdfUrl,isRTL,formDocumentInputs',
        },
      });
      const initData = value[0] || null;
      if (initData) {
        initData.formDocumentInputs = (initData.formDocumentInputs || []).map((item) => ({
          ...item,
          width: item.width * scale,
          height: item.height * scale,
          coordY: item.coordY * scale,
          coordX: item.coordX * scale,
        }));
      }
      yield put(pdfFormDocumentMerge({ loading: false, initData }));
    } catch (e) {
      yield put(pdfFormDocumentMerge({ loading: false }));
      if (!axios.isCancel(e)) {
        yield put(notifyRequestResult(requestError(e), 'error'));
      }
    }
  }
}

function* saveInput(data: PdfFormDocumentInput, i: number) {
  const { initData, idPrefix, scale }: PdfFormDocument = yield select(
    (state) => state.pdfFormDocument,
  );
  const {
    id,
    // need re-calc props
    width,
    height,
    coordX,
    coordY,
    // remove support fields
    _open,
    _valid,
    _index,
    _value,
    // rest
    ...rest
  } = data;
  const propsScale = {
    width: round(width / scale),
    height: round(height / scale),
    coordX: round(coordX / scale),
    coordY: round(coordY / scale),
  };
  if (id.indexOf(idPrefix) !== -1) {
    yield call(apiStatic.post, API_FORM_DOCUMENT_INPUTS.POST, {
      ...rest,
      ...propsScale,
      rank: i,
      formDocumentID: initData?.id,
    });
  } else {
    yield call(apiStatic.patch, API_FORM_DOCUMENT_INPUTS.PATCH({ id }), {
      ...rest,
      ...propsScale,
      rank: i,
    });
  }
}

function* saveInputs() {
  const { pages }: PdfFormDocument = yield select((state) => state.pdfFormDocument);
  yield all(
    pages
      .reduce((acc: PdfFormDocumentInput[], { inputs }) => {
        acc.push(...inputs);
        return acc;
      }, [])
      .map((item, i) => call(saveInput, item, i)),
  );
}

function* removeInputs() {
  const { removed }: PdfFormDocument = yield select((state) => state.pdfFormDocument);
  if (removed.length) {
    yield all(removed.map((id) => call(apiStatic.delete, API_FORM_DOCUMENT_INPUTS.DELETE({ id }))));
  }
}

function* save({ payload: Callback }: PdfFormDocumentSaveAction) {
  let status = true;
  try {
    yield put(pdfFormDocumentMerge({ loading: true }));
    yield all([call(saveInputs), call(removeInputs)]);
  } catch (e) {
    status = false;
    yield put(pdfFormDocumentMerge({ loading: false }));
    yield put(notifyRequestResult(requestError(e), 'error'));
  }
  if (Callback) {
    Callback(status);
    yield put(pdfFormDocumentMerge({ loading: false }));
  } else {
    const { initData }: PdfFormDocument = yield select((state) => state.pdfFormDocument);
    yield put(pdfFormDocumentReset());
    yield put(pdfFormDocumentInit(initData?.id || ''));
  }
}

function* scrollToInput({ payload: { pageNumber, index } }: PdfFormDocumentScrollToInputAction) {
  const { pages }: PdfFormDocument = yield select((state) => state.pdfFormDocument);
  try {
    const input = pages[pageNumber].inputs[index];
    if (input) {
      const { coordX, coordY, width, height, id } = input;
      let top = 0;
      for (let i = 0; i < pageNumber; i++) {
        top += pages[pageNumber].height + 15; //css padding 10 + margin: 5
      }
      yield put(
        pdfFormDocumentMerge({
          eventScrollTo: {
            top: round(top + coordY + height / 2),
            left: round(coordX + width / 2),
          },
          eventSelectFabricItem: {
            pageNumber,
            id,
          },
        }),
      );
    }
  } catch (e) {
    console.error(e);
  }
}

function* scrollToPage({ payload: { pageNumber } }: PdfFormDocumentScrollToPageAction) {
  const { pages }: PdfFormDocument = yield select((state) => state.pdfFormDocument);
  try {
    const page = pages[pageNumber];
    if (page) {
      let top = 0;
      for (let i = 0; i < pageNumber; i++) {
        top += pages[pageNumber].height + 15; //css padding 10 + margin: 5
      }
      yield put(
        pdfFormDocumentMerge({
          eventScrollTo: {
            top: round(top),
            left: round(page.width / 2),
            pageNumber,
          },
        }),
      );
    }
  } catch (e) {
    console.error(e);
  }
}

// EXPORTS
export const pdfFormDocumentSagas = [
  takeLatest(PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_INIT, init),
  takeLatest(PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_SAVE, save),
  takeLatest(PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_SCROLL_TO_PAGE, scrollToPage),
  takeLatest(PdfFormDocumentActionTypes.PDF_FORM_DOCUMENTS_SCROLL_TO_INPUT, scrollToInput),
];
