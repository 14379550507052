import React, { memo } from 'react';
import clsx from 'clsx';
import DoneIcon from '@material-ui/icons/Done';
import { Link } from '@material-ui/core';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';

interface Props {
  onDone: (e?: any) => void;
  link: string;
}
export const StateDone = memo<Props>(({ onDone, link }) => {
  const { t } = useTranslate();

  return (
    <div className={clsx(style.state, style.done)}>
      <div className={style.contentCenter}>
        <div className={style.wrapper}>
          <DoneIcon className={style.icon} />
          <div className={style.title}>{t('payment-successful')}</div>
          <div className={style.description}>{t('thanks-fo-the-payment')}</div>
          <Link
            href={link}
            target={'_blank'}
            color="primary"
            className={style.btnLink}
            onClick={onDone}
          >
            {t('display-invoice')}
          </Link>
        </div>
      </div>
    </div>
  );
});

export default StateDone;
