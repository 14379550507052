import { convertToDate, dateFormat, DateValue, isDateLike, isServerDateString } from './dates';
import { addHours, addMinutes, addMonths, addWeeks, format, set, startOfDay } from 'date-fns';
import {
  APP_FORMAT_DATE_TIME,
  APP_FORMAT_TIME,
  CALENDAR_MEETING_END_HOURS,
  CALENDAR_MEETING_START_HOURS,
  CALENDAR_RANGE_MINUTES,
} from '../configs/const';
import { Unset } from './types';
import { isEqual } from 'lodash-es';

const returnedUSDNumber = (number: number, preffix: string) =>
  (Number.isInteger(+number.toFixed(1)) ? Math.trunc(number) : number.toFixed(1)) + preffix;

export const numberFormatter = (num: number) => {
  if (num >= 100000000) {
    return returnedUSDNumber(num / 1000000000, 'B');
  }
  if (num >= 1000000) {
    return returnedUSDNumber(num / 1000000, 'M');
  }
  if (num >= 1000) {
    return returnedUSDNumber(num / 1000, 'K');
  }
  return num;
};

export const timeSlotToNumber = (timeSlot: DateValue) => {
  if (!timeSlot) {
    return 0;
  }
  let valueTimeSlot = timeSlot;

  if (isServerDateString(valueTimeSlot)) {
    valueTimeSlot = dateFormat(valueTimeSlot, 'HH:mm');
  }
  if (timeSlot instanceof Date) {
    valueTimeSlot = dateFormat(valueTimeSlot, 'HH:mm');
  }

  return Number(String(valueTimeSlot).replace(':', ''));
};
export const numberToTimeSlot = (hours: number) => {
  let hour = Math.floor(hours);
  let minute = Math.round(Number((hours % 1).toFixed(2)) * 60);

  if (minute >= 60) {
    hour++;
    minute = 0;
  }

  return [hour, minute].map((value) => `0${value}`.slice(-2)).join(':');
};

export interface TimeSlot {
  fromTime: DateValue;
  toTime: DateValue;
}

export const isValidTimeSlot = (targetSlot: TimeSlot, availableSlot: TimeSlot) => {
  const targetStart = timeSlotToNumber(targetSlot.fromTime);
  const targetEnd = timeSlotToNumber(targetSlot.toTime);

  const availableStart = timeSlotToNumber(availableSlot.fromTime);
  const availableENd = timeSlotToNumber(availableSlot.toTime);

  return [targetStart >= availableStart, targetEnd <= availableENd].every(Boolean);
};

export const validateTimeSlot = (targetSlot: TimeSlot, availableSlots: TimeSlot[]) => {
  return availableSlots.some((availableSlot) => {
    return isValidTimeSlot(targetSlot, availableSlot);
  });
};

const isNumberBetween = (start: number, end: number, point: number) => {
  return point > start && point < end;
};
export const isAcrossTimeSlots = (slot1: TimeSlot, slot2: TimeSlot) => {
  const targetStart = timeSlotToNumber(slot1.fromTime);
  const targetEnd = timeSlotToNumber(slot1.toTime);

  const availableStart = timeSlotToNumber(slot2.fromTime);
  const availableEnd = timeSlotToNumber(slot2.toTime);

  if (isNumberBetween(targetStart, targetEnd, availableStart)) return true;
  if (isNumberBetween(targetStart, targetEnd, availableEnd)) return true;

  if (isNumberBetween(availableStart, availableEnd, targetStart)) return true;
  if (isNumberBetween(availableStart, availableEnd, targetEnd)) return true;

  return targetStart === availableStart && targetEnd === availableEnd;
};

interface RemarkChangeDateOptions {
  translate: (value: string) => string;
  start: DateValue;
  end: DateValue;
}
export const remarkChangeDate = ({ translate, start, end }: RemarkChangeDateOptions) => {
  const startDate = isDateLike(start) && convertToDate(start);
  const endDate = isDateLike(end) && convertToDate(end);

  return [
    translate('moving-to'),
    startDate && translate(format(startDate, 'EEEE').toLowerCase()),
    translate('on-date'),
    [startDate && format(startDate, APP_FORMAT_DATE_TIME), endDate && format(endDate, 'HH:mm')]
      .filter(Boolean)
      .join(' - '),
  ]
    .filter(Boolean)
    .join(' ');
};
interface RemarkChangeEmployeeOptions {
  translate: (value: string) => string;
  fullName: string;
}
export const remarkChangeEmployee = ({ translate, fullName }: RemarkChangeEmployeeOptions) => {
  return `${translate('re-assigns')} ${fullName}`;
};
/**
 * Calculate BMI
 * @param {number} weight kg.
 * @param {number} height sm.
 * @returns {Number}.
 */
export const calcBmi = (weight: number, height: number): number => {
  const result = parseFloat((weight / Math.pow(height / 100, 2)).toFixed(2));
  return isFinite(result) ? result : 0;
};

export const makeFilterDateRange = (field: string, value: DateValue[]) => {
  let values = value.filter(Boolean);

  if (values.length !== 2) {
    return undefined;
  }
  values = value.map((v) => format(convertToDate(v), '"yyyy-MM-dd HH:mm"'));

  return `DateTime(${field}) >= DateTime(${values[0]}) && DateTime(${field}) <= DateTime(${values[1]})`;
};

export const createDefaultMeetingRange = () => {
  const now = new Date();
  const minutesNow = format(now, 'mm');

  let minutesDif = CALENDAR_RANGE_MINUTES - (Number(minutesNow) % CALENDAR_RANGE_MINUTES);

  const minutes = Number(minutesNow) + minutesDif;

  const start = set(new Date(), {
    minutes: minutes,
  });

  const end = set(new Date(), {
    minutes: minutes + CALENDAR_RANGE_MINUTES,
  });

  return {
    start: format(start, APP_FORMAT_TIME),
    end: format(end, APP_FORMAT_TIME),
  };
};

interface SetDateToNearestTimeSlotOptions {
  meetingTimes?: { decimal: number }[];
}
export const setDateToNearestTimeSlot = (
  date: Date,
  options?: SetDateToNearestTimeSlotOptions,
): Date => {
  const targetDecimal = Number((date.getHours() + date.getMinutes() / 60).toFixed(5));
  const { meetingTimes = createDefaultMeetingTimes() } = options || {};

  let nearestMeetingTime = meetingTimes.find(({ decimal }) => {
    return Number(decimal.toFixed(5)) > targetDecimal;
  });

  // out of range
  if (!nearestMeetingTime) {
    // try to find the nearest time next day
    return setDateToNearestTimeSlot(addHours(date, 2), options);
  }

  const minutes = Math.round((nearestMeetingTime.decimal % 1) * 60);
  const hours = Math.floor(nearestMeetingTime.decimal);

  return set(date, { hours, minutes, seconds: 0, milliseconds: 0 });
};

export const setDateToNearestTimeSlotFlor = (
  date: Date,
  options?: SetDateToNearestTimeSlotOptions,
): Date => {
  const targetDecimal = Number((date.getHours() + date.getMinutes() / 60).toFixed(5));
  const { meetingTimes: _meetingTimes = createDefaultMeetingTimes() } = options || {};

  const meetingTimes = Array.from(_meetingTimes).reverse();

  let nearestMeetingTime = meetingTimes.find(({ decimal }) => {
    return Number(decimal.toFixed(5)) < targetDecimal;
  });

  // out of range
  if (!nearestMeetingTime) {
    // try to find the nearest time next day
    return setDateToNearestTimeSlotFlor(addHours(date, -2), options);
  }

  const minutes = Math.round((nearestMeetingTime.decimal % 1) * 60);
  const hours = Math.floor(nearestMeetingTime.decimal);

  return set(date, { hours, minutes, seconds: 0, milliseconds: 0 });
};

export const decimalToTime = (decimal: number) => {
  const minutes = Math.round((decimal % 1) * 60);
  const hours = Math.floor(decimal);

  return { hours, minutes };
};

interface CreateTimesOptions {
  hoursFrom: number;
  hoursTo: number;
  interval: number;
}
export const createTimes = (options: CreateTimesOptions) => {
  const { hoursFrom, hoursTo, interval } = options;
  const result: { id: string; decimal: number }[] = [];
  const minutesSlice = interval / 60;

  let date = addMinutes(startOfDay(new Date()), (hoursFrom - minutesSlice) * 60);

  for (let i = hoursFrom; i <= hoursTo; i += minutesSlice) {
    date = addMinutes(date, minutesSlice * 60);

    const id = format(date, APP_FORMAT_TIME);
    result.push({ id, decimal: i });
  }

  return result;
};

export const createDefaultMeetingTimes = () => {
  const hoursFrom = CALENDAR_MEETING_START_HOURS;
  const hoursTo = CALENDAR_MEETING_END_HOURS;
  const interval = CALENDAR_RANGE_MINUTES;

  return createTimes({ hoursFrom, hoursTo, interval });
};

export const numberFormat = (
  count: number | undefined | null,
  options?: Intl.NumberFormatOptions,
) => (count ? new Intl.NumberFormat('en-US', options).format(count) : count);

export const makeObjectFullKeys = <T extends Record<string, any>>(
  obj: T,
  parentKey: null | string = null,
): string[] => {
  return Object.entries(obj)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return [parentKey, key].filter(Boolean).join('.');
      }

      if (value && typeof value === 'object') {
        return makeObjectFullKeys(value, key);
      }

      return [parentKey, key].filter(Boolean).join('.');
    })
    .flat();
};

export const makeSourceAges = () => {
  return new Array(120).fill(null).map((v, index) => {
    const value = index + 1;
    return { id: value, title: value };
  });
};

export const makeFullName = <T extends { firstName?: string | null; lastName?: string | null }>(
  user: T,
) => {
  return [user.firstName, user.lastName].filter(Boolean).join(' ');
};

export const extractFullName = (fullName: Unset): [string, string] => {
  return String(fullName || '').split(/\s+/) as [string, string];
};

const factoryBetween = (from: Date, to: Date) => {
  return (item: { entryDate: DateValue }) => {
    const entryDate = convertToDate(item.entryDate);

    return entryDate.getTime() >= from.getTime() && entryDate.getTime() <= to.getTime();
  };
};

const getWeightByRangeDate = (
  weights: { entryDate: DateValue; weight: number }[],
  from: Date,
  to: Date,
) => {
  const weightsInDateRange = weights.filter(factoryBetween(from, to));
  const latest = weightsInDateRange[weightsInDateRange.length - 1];

  return latest ? latest.weight : null;
};
interface PatientWeightInfo {
  today: DateValue;
  patient: { weight: number | null };
  subscription: { startDate?: DateValue } | null;
  weights: { entryDate: DateValue; weight: number }[];
}
export const calcPatientWeightInfo = (options: PatientWeightInfo) => {
  const { today, weights, patient, subscription } = options;
  const todayDate = startOfDay(convertToDate(today));
  const start = patient.weight;
  const orderedDesc = [...weights].sort((a, b) => {
    const dateA = convertToDate(a.entryDate);
    const dateB = convertToDate(b.entryDate);
    return dateB.getTime() - dateA.getTime();
  });

  const last = orderedDesc[0] ? orderedDesc[0].weight : start;

  const lost = Number(start) - Number(last);

  const lastMonthDate = addMonths(todayDate, -1);
  const lastMonth = getWeightByRangeDate(orderedDesc, lastMonthDate, todayDate) || last;
  const lostLastMonth = Number(lastMonth) - Number(last);

  const lastWeekDate = addWeeks(todayDate, -1);
  const lastWeek = getWeightByRangeDate(orderedDesc, lastWeekDate, todayDate) || last;
  const lostLastWeek = Number(lastWeek) - Number(last);

  const startSubscriptionDate = startOfDay(convertToDate(subscription?.startDate));
  const startSubscription =
    getWeightByRangeDate(orderedDesc, startSubscriptionDate, todayDate) || last;
  const lostStartSubscription = Number(startSubscription) - Number(last);

  return { start, last, lost, lostLastMonth, lostLastWeek, lostStartSubscription };
};

interface CalcRangeSliderBehaviourOptions {
  track: number[];
  startIndex: number;
  endIndex: number;
  activeThumb: 0 | 1;
  step: number;
}
const calcRangeSliderMin = (options: CalcRangeSliderBehaviourOptions) => {
  const { track, activeThumb, startIndex, endIndex, step } = options;
  if (activeThumb === 0) {
    const newEndIndex = startIndex + step;
    return newEndIndex > track.length - 1
      ? [track[endIndex - step], track[endIndex]]
      : [track[startIndex], track[startIndex + step]];
  }
  const newStartIndex = endIndex - step;
  return newStartIndex < 0 ? [track[0], track[step]] : [track[endIndex - step], track[endIndex]];
};
const calcRangeSliderMax = (options: CalcRangeSliderBehaviourOptions) => {
  const { activeThumb, startIndex, endIndex, track, step } = options;
  if (activeThumb === 0) {
    return [track[startIndex], track[startIndex + Math.min(step, endIndex)]];
  }
  return [track[endIndex - Math.min(step, endIndex)], track[endIndex]];
};
interface CalcRangeSliderOptions {
  value: number[];
  marks: { value: number }[];
  activeThumb: 0 | 1;
  minStep?: number;
  maxStep?: number;
}
export const calcRangeSlider = (options: CalcRangeSliderOptions) => {
  const { value, marks, minStep = 0, maxStep = Infinity, activeThumb } = options;
  const track = marks.map((m) => m.value);

  const startIndex = track.findIndex((v) => v === value[0]);
  const endIndex = track.findIndex((v) => v === value[1]);

  const diff = endIndex - startIndex;

  //handle min step
  if (diff < minStep) {
    return calcRangeSliderMin({ activeThumb, track, startIndex, endIndex, step: minStep });
  }

  // handle max step
  if (diff > maxStep) {
    return calcRangeSliderMax({ activeThumb, track, startIndex, endIndex, step: maxStep });
  }

  return value;
};

export const isTheSameValue = (val1: any, val2: any) => {
  let [parsedVal1, parsedVal2] = [val1, val2].map((val) => (val === null ? '' : val));

  return isEqual(parsedVal1, parsedVal2);
};

export const isTheSamePhone = (value1: Unset, value2: Unset) => {
  if (!value1) return false;
  if (!value2) return false;
  return value1.replace(/\D/g, '') === value2.replace(/\D/g, '');
};
