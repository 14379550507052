import React, { memo } from 'react';
import { useAppSelector } from 'store';
// import { Button } from '@material-ui/core';
// import { pdfFormDocumentSave } from '../../store';
import { FormPage } from './form-page';
// import { PdfPreview } from './pdf-preview';
import style from './index.module.scss';

export const Form = memo(() => {
  // const dispatch = useAppDispatch();
  // const { t } = useI18n();
  //state
  const { pages } = useAppSelector((state) => state.pdfFormDocument);
  // handlers
  // const onSave = useCallback(() => {
  //   dispatch(pdfFormDocumentSave());
  //   // and need close form
  //   // or update all IDS in store after create
  // }, [dispatch]);
  //render
  return (
    <div className={style.formWrapper}>
      <div className={style.form}>
        <div className={style.formPages}>
          <div className={style.formShadow}>
            {pages.map(({ index }) => (
              <FormPage key={index} index={index} />
            ))}
          </div>
        </div>

        {/*<div className={style.formControls}>*/}
        {/*  <PdfPreview />*/}
        {/*  <Button className={style.btn} variant="contained" color="primary" onClick={onSave}>*/}
        {/*    {t('save')}*/}
        {/*  </Button>*/}
        {/*</div>*/}
      </div>
    </div>
  );
});

export default Form;
export * from './pdf-preview';
