import { DynamicService } from 'utils/service';
import {
  ActivityItem,
  API_USER_PATIENT_PROFILE_ACTIVITIES,
  GetActivitiesInput,
  UserPatientProfileActivity,
  UserPatientProfileActivityPostInput,
} from './models';
import { apiRtk } from 'utils/rtk-query';
import { makeFilterDateRange } from 'utils/app-helpers';

export * from './models';

class Service extends DynamicService<UserPatientProfileActivity, 'id'> {
  async getActivities(input: GetActivitiesInput) {
    const { userPatientProfileID, dateRange } = input;

    const {
      data: { value },
    } = await this.getAllDynamic<ActivityItem>({
      filter: [
        `userPatientProfileID=="${userPatientProfileID}"`,
        makeFilterDateRange('entryDate', dateRange),
      ]
        .filter(Boolean)
        .join('&&'),
      select: ['id', 'entryDate', 'remarks', 'userEmployeeProfile.fullName as employee'].join(','),
      orderBy: 'entryDate desc',
      take: 20,
    });

    return value.map((item) => ({
      id: String(item.id),
      title: item.remarks,
      date: String(item.entryDate),
      employee: item.employee,
      download: null,
    }));
  }
}

export const ServiceUserPatientProfileActivities = new Service({
  getAll: API_USER_PATIENT_PROFILE_ACTIVITIES.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_ACTIVITIES.POST,
  patch: API_USER_PATIENT_PROFILE_ACTIVITIES.PATCH,
  delete: API_USER_PATIENT_PROFILE_ACTIVITIES.DELETE,
});

export const apiUserPatientProfileActivities = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    postUserPatientProfileActivity: build.mutation({
      queryFn: async (input: UserPatientProfileActivityPostInput) => {
        try {
          const res = await ServiceUserPatientProfileActivities.post({
            ...input,
            entryDate: new Date().toISOString(),
          });
          return { data: res.data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
