import React, { useCallback, useMemo, useRef, useState } from 'react';
import { apiUserPatientProfileSessions } from 'services/user-patient-profile-session';
import { SessionInformationList } from 'components/session-information-list';
import style from './index.module.scss';
import { NativeScroll } from 'components/native-scroll';
import { Pagination } from '@material-ui/lab';
import { calcPaginationSkip, calcPaginationState } from 'utils/service';
import { useEffectError } from 'hooks';
import { Loading } from 'AurionCR/components';
import { DataGridEmpty } from 'components/data-grid/components/data-grid-empty';
import { AppSwitch } from 'components/app-switch';
import { useTranslate } from 'hooks/use-translate';
import { useCurrentUser } from '../../../hooks';

const TAKE = 10;
interface Props {
  userPatientProfileID: string;
}

export const SessionsList: React.FC<Props> = ({ userPatientProfileID }) => {
  const { t } = useTranslate();
  const { appUserID } = useCurrentUser();
  const ref = useRef<HTMLDivElement>(null);
  const [relativeToMe, setRelativeToMe] = useState(false);
  const [page, setPage] = useState(1);
  const skip = useMemo(() => {
    return calcPaginationSkip({ take: TAKE, page });
  }, [page]);

  const scrollTop = useCallback(() => {
    const el = ref.current;
    if (!el) return;

    el.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const result = apiUserPatientProfileSessions.useGetSessionsFullInformationQuery({
    userEmployeeProfileID: relativeToMe ? appUserID : null,
    userPatientProfileID,
    take: TAKE,
    skip,
  });
  useEffectError(result.error);

  const { value = [], count = 0 } = result.data || {};
  const { pages } = useMemo(() => {
    return calcPaginationState({ take: TAKE, page, count });
  }, [page, count]);

  const isEmpty = result.isSuccess && value.length === 0;

  const onChangeRelative = useCallback(
    (_: any, value: boolean) => {
      setRelativeToMe(value);
      setPage(1);
      scrollTop();
    },
    [scrollTop],
  );
  const onChangePage = useCallback(
    (_: any, page: number) => {
      setPage(page);
      scrollTop();
    },
    [scrollTop],
  );

  return (
    <div className={style.root}>
      <NativeScroll ref={ref} mode={'visible'}>
        <div style={{ position: 'absolute', inset: 0 }}>
          <SessionInformationList rows={value} p={1} />
          {result.isFetching && <Loading active />}
          {isEmpty && <DataGridEmpty />}
        </div>
      </NativeScroll>
      <div className={style.pagination}>
        <Pagination
          color={'primary'}
          page={page}
          onChange={onChangePage}
          count={pages}
          variant="outlined"
          shape="rounded"
          disabled={result.isFetching}
        />
        <AppSwitch
          disabled={result.isFetching}
          label={t('relative-to-me')}
          value={relativeToMe}
          onChange={onChangeRelative}
        />
      </div>
    </div>
  );
};
