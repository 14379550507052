import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'BMISummaries';

export const API_BMI_SUMMARIES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<IBMISummary, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<IBMISummary, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface IBMISummary extends FixServerObject<Components.Schemas.BMISummary, 'id'> {}
