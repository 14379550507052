import React from 'react';
import { IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import style from './index.module.scss';
import image from 'images/logo-neopharm.png';
import { Stack } from 'components/stack';
import LinkIcon from '@material-ui/icons/Link';
import { ButtonSendToNeopharm } from '../button-send-to-neopharm';
import { useTranslate } from 'hooks/use-translate';
import { CopyPathToClipboard } from 'AurionCR/components';

interface Props {
  userPatientProfileID: string | null | undefined;
  prescriptionID: string;
  prescriptionPrintFilePath: string | null | undefined;
  pharmacyFollowupID: string | null | undefined;
  disabled: boolean;
}
export const SendToNeopharm: React.FC<Props> = ({
  userPatientProfileID,
  prescriptionID,
  prescriptionPrintFilePath,
  pharmacyFollowupID,
  disabled,
}) => {
  const { t } = useTranslate();
  const isSend = Boolean(pharmacyFollowupID);

  return (
    <Paper variant={'outlined'} className={style.send}>
      <img src={image} alt={''} className={style.sendImage} />
      <div className={style.sendBox}>
        {isSend ? (
          <>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              spacing={3.2}
            >
              <Typography component={'div'} variant={'h6'} style={{ lineHeight: 1.1 }}>
                {t('sent-to-neopharm')}
              </Typography>

              <CopyPathToClipboard text={pharmacyFollowupID || ''}>
                <Tooltip title={t('copy-id')}>
                  <IconButton color={'primary'} size={'small'}>
                    <LinkIcon fontSize={'small'} />
                  </IconButton>
                </Tooltip>
              </CopyPathToClipboard>
            </Stack>

            <Typography variant={'body2'} component={'div'} color={'textSecondary'}>
              {t('followup-id')}: <strong>{pharmacyFollowupID}</strong>
            </Typography>
          </>
        ) : (
          <ButtonSendToNeopharm
            userPatientProfileID={userPatientProfileID}
            prescriptionID={prescriptionID}
            prescriptionPrintFilePath={prescriptionPrintFilePath}
            disabled={disabled}
          />
        )}
      </div>
    </Paper>
  );
};
