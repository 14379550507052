import { ObjectSchema } from 'yup';

export const getSchemaFiledMaxLength = <T, K extends keyof T>(schema: ObjectSchema<T>, key: K) => {
  const description = schema.fields[key].describe();

  if ('tests' in description) {
    const rule = description.tests.find(({ name }) => name === 'max');

    if (!rule) return undefined;

    const max = rule.params?.max as number;

    if (!max) {
      return undefined;
    }

    return max;
  }

  return undefined;
};
