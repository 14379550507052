import { DynamicService } from 'utils/service';

import {
  API_USER_PATIENT_PROFILE_SESSION_SIDE_EFFECTS,
  CreateBulkUserPatientProfileSessionSideEffectInput,
  UserPatientProfileSessionSideEffect,
} from './models';

export * from './models';

class Service extends DynamicService<UserPatientProfileSessionSideEffect> {
  createBulk = (input: CreateBulkUserPatientProfileSessionSideEffectInput) => {
    return this.engine.post(API_USER_PATIENT_PROFILE_SESSION_SIDE_EFFECTS.POST_BULK, {
      mainID: input.userPatientProfileSessionID,
      itemIDs: input.sideEffectIDs,
    });
  };
}

export const ServiceUserPatientProfileSessionSideEffects = new Service({
  mainField: 'id',
  getAll: API_USER_PATIENT_PROFILE_SESSION_SIDE_EFFECTS.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_SESSION_SIDE_EFFECTS.POST,
  delete: API_USER_PATIENT_PROFILE_SESSION_SIDE_EFFECTS.DELETE,
  patch: API_USER_PATIENT_PROFILE_SESSION_SIDE_EFFECTS.PATCH,
});
