import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';
import { isValidTimeSlot, validateTimeSlot } from 'utils/app-helpers';
import { ArrayType } from 'utils/types';
import { convertToDate } from 'utils/dates';
import { differenceInMinutes } from 'date-fns';

const ALLOWABLE_ERROR_TIME = 30; // minutes
const IS_ONLINE_TIME = 10; // minutes

const selectEmployeesMonitor = (state: RootState) => state.employeesMonitor;

export const selectEmployeesMonitorStatuses = createSelector(
  selectEmployeesMonitor,
  ({ isLoading, error, isPolling, isInit }) => {
    return { isLoading, error, isPolling, isInit };
  },
);
const selectEmployeesMonitorEmployees = createSelector(selectEmployeesMonitor, ({ employees }) => {
  return employees
    .filter((employee) => {
      return employee.absences.length === 0;
    })
    .map((employee) => {
      const lastActivityDate = employee.lastActivityDate;
      const isOnline = lastActivityDate
        ? differenceInMinutes(new Date(), convertToDate(lastActivityDate)) < IS_ONLINE_TIME
        : false;

      return {
        ...employee,
        targetSlots: employee.schedules.length > 0 ? employee.schedules : employee.workLogs,
        __isOnline: isOnline,
      };
    });
});

const selectEmployeesMonitorEmployeesShouldBeActive = createSelector(
  selectEmployeesMonitorEmployees,
  (employees) => {
    return employees.filter((employee) => {
      const fromTime = new Date();
      const toTime = new Date();

      const slotNow = { fromTime, toTime };

      return validateTimeSlot(slotNow, employee.targetSlots);
    });
  },
);

const selectEmployeesMonitorEmployeesShouldBeActiveMap = createSelector(
  selectEmployeesMonitorEmployeesShouldBeActive,
  (employees) => {
    return employees.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {} as Record<string, ArrayType<typeof employees>>);
  },
);

export const selectEmployeesMonitorEmployeesList = createSelector(
  selectEmployeesMonitorEmployees,
  selectEmployeesMonitorEmployeesShouldBeActiveMap,
  (employees, mapShouldBeActive) => {
    return (
      employees
        .map((employee) => {
          const mapItem = mapShouldBeActive[employee.id];

          if (!mapItem) {
            return { ...employee, isMustBeActive: false, currentSlot: undefined };
          }

          const lastActivityDate = convertToDate(employee.lastActivityDate);

          const fromTime = new Date();
          const toTime = new Date();

          const slotNow = { fromTime, toTime };

          const currentSlot = mapItem.targetSlots.find((slot) => isValidTimeSlot(slotNow, slot));

          const isMustBeActive =
            differenceInMinutes(new Date(), lastActivityDate) > ALLOWABLE_ERROR_TIME;

          return {
            ...employee,
            isMustBeActive,
            currentSlot,
            fullName: [employee.firstName, employee.lastName].filter(Boolean).join(' '),
          };
        }, [])
        // visibility in the list
        .filter((employee) => {
          if (employee.__isOnline) return true;
          if (employee.currentSlot) return true;

          return false;
        })
    );
  },
);

export const selectEmployeesMonitorEmployeesMustBeActiveCount = createSelector(
  selectEmployeesMonitorEmployeesList,
  (employees) => {
    const mustBeActiveEmployees = employees.filter(({ isMustBeActive }) => isMustBeActive);

    return mustBeActiveEmployees.length;
  },
);

export const selectEmployeesMonitorEmployeesListMap = createSelector(
  selectEmployeesMonitorEmployeesList,
  (employees) => {
    return employees.reduce((acc, item) => {
      acc[item.appIdentityUserID] = item;
      return acc;
    }, {} as Record<string, ArrayType<typeof employees>>);
  },
);
