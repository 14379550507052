import React, { memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import {
  pdfFormDocumentInit,
  pdfFormDocumentReset,
} from 'components/pdf-document-constructor/store';
import { Loading } from 'AurionCR/components';
import { Pdf } from './components/pdf';
import { Form } from './components/form';
import style from './components/index.module.scss';
// import Test from './test';

export const PDFDocumentConstructor = memo(({ id }: { id: string }) => {
  const dispatch = useAppDispatch();
  // states
  const { initData, loading, pages } = useAppSelector((state) => state.pdfFormDocument);
  // init
  useEffect(() => {
    dispatch(pdfFormDocumentInit(id));
    return () => {
      dispatch(pdfFormDocumentReset());
    };
  }, [dispatch, id]);
  //render
  return (
    <div className={style.wrapper}>
      {initData && (
        <>
          <Pdf />
          <Form />
        </>
      )}
      {(loading || !initData || pages.length === 0) && <Loading />}
    </div>
  );
});

export default PDFDocumentConstructor;
export * from './store';
export * from './components/form';
export * from './components/pdf';
