import React, { useCallback, useMemo } from 'react';
import { Button, Typography } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { SectionPaper } from '../../../section-papper';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { useOpen } from 'AurionCR/components/hooks';
import PaymentDialog from 'components/payment-dialog';
import { Stack } from 'components/stack';
import style from './index.module.scss';
import clsx from 'clsx';
import { UserPatientProfileSubscription } from 'services/user-patient-profile-subscriptions';
import { Subscription } from 'services/subscription';
import { format } from 'date-fns';
import { convertToDate } from 'utils/dates';
import { APP_FORMAT_DATE } from 'configs/const';
import { InfoTable } from '../../../info-table';

interface NoPaymentProps {
  patientID: string;
  onRefresh: () => void;
}
const formatDate = (value: string | null | undefined) => {
  if (!value) {
    return '--';
  }
  return format(convertToDate(value), APP_FORMAT_DATE);
};
const NoPayment: React.FC<NoPaymentProps> = ({ patientID, onRefresh }) => {
  const { t } = useTranslate();

  const { isOpen, handleOpen, handleClose } = useOpen();
  return (
    <Stack
      justifyContent={'center'}
      alignItems={'center'}
      flexGrow={1}
      textAlign={'center'}
      direction={'column'}
      spacing={1}
    >
      <Typography variant={'h2'} color={'primary'}>
        {t('there-is-no-payment')}
      </Typography>
      <Button
        className="btn-pay"
        variant="contained"
        color="primary"
        startIcon={<AccountBalanceWalletIcon />}
        onClick={handleOpen}
      >
        {t('pay-now')}
      </Button>
      {isOpen && (
        <PaymentDialog userPatientProfileID={patientID} onCancel={handleClose} onDone={onRefresh} />
      )}
    </Stack>
  );
};

export type DraftSubscription = Pick<
  UserPatientProfileSubscription,
  'startDate' | 'endDate' | 'paymentDate'
> &
  Pick<Subscription, 'labelKey' | 'durationMonths'>;
interface Props {
  className: string;
  prescriptionID: string;
  patientID: string | null | undefined;
  subscription: null | undefined | DraftSubscription;
}

export const DraftPayment: React.FC<Props> = ({ patientID, className, subscription }) => {
  const { t } = useTranslate();
  const isNoPayment = !subscription;

  const onRefresh = useCallback(() => {}, []);

  const options = useMemo(() => {
    return [
      {
        label: t('subscription'),
        value: subscription?.labelKey,
      },
      {
        label: t('duration-months'),
        value: subscription?.durationMonths,
      },
      {
        label: t('start-date'),
        value: formatDate(subscription?.startDate),
      },
      {
        label: t('end-date'),
        value: formatDate(subscription?.endDate),
      },
      {
        label: t('payment-date'),
        value: formatDate(subscription?.paymentDate),
      },
    ];
  }, [subscription, t]);

  return (
    <SectionPaper title={t('payment')} className={clsx(style.root, className)}>
      {subscription && <InfoTable options={options} />}
      {isNoPayment && patientID && <NoPayment patientID={patientID} onRefresh={onRefresh} />}
    </SectionPaper>
  );
};
