const API_ENDPOINT = 'FieldInputs';

export const API_FIELD_INPUTS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<FieldInput, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<FieldInput, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface FieldInput extends Components.Schemas.FieldInput {}
