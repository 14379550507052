import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'UserPatientProfileSessionSurgeries';

export const API_USER_PATIENT_PROFILE_SESSIONS_SURGERIES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  POST_BULK: `${API_ENDPOINT}/CreateUserPatientProfileSessionSurgeryBulk`,
  PATCH: (data: Pick<UserPatientProfileSessionSurgery, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<UserPatientProfileSessionSurgery, 'id'>) =>
    `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface UserPatientProfileSessionSurgery
  extends FixServerObject<Components.Schemas.UserPatientProfileSessionSurgery, 'id'> {
  createdDate?: string;
}

export interface CreateBulkUserPatientProfileSessionSurgeryInput {
  userPatientProfileSessionID: string;
  surgeryIDs: string[];
}
