const API = 'UserPatientProfileDietDiaryImages';

export const API_USER_PATIENT_PROFILE_DIET_DIARY_IMAGES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<Components.Schemas.UserPatientProfileDietDiary, 'id'>) =>
    `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<Components.Schemas.UserPatientProfileDietDiary, 'id'>) =>
    `${API}/Delete/${data.id}`,
  POST_BULK: `${API}/CreateUserPatientProfileDietDiaryImageBulk`,
};
