import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseErrorData } from 'utils/service';
import { AppLabel, ServiceLabels } from 'services/labels';
import i18n from 'modules/i18n';
import { selectLanguageID } from '../languages';
import { AppAsyncThunkConfig } from '../store';

const reducerMakeTranslation = (acc: Record<string, string>, item: AppLabel) => {
  const ket = String(item.labelKey);
  acc[ket] = String(item.title);
  return acc;
};

export const actionLabelsFetch = createAsyncThunk<void, string, AppAsyncThunkConfig>(
  `LABELS/fetch`,
  async (languageID, { getState }) => {
    // Check if resources for the specified language have already been loaded
    const isResourcesLoaded = i18n.hasResourceBundle(languageID, 'translation');

    // If resources are already loaded, return early
    if (isResourcesLoaded) {
      return;
    }

    try {
      const {
        data: { value },
      } = await ServiceLabels.getAppLabels(languageID);

      i18n.addResources(
        languageID,
        'translation',
        value.reduce(reducerMakeTranslation, {} as Record<string, string>),
      );

      const currentLanguageID = selectLanguageID(getState());

      if (currentLanguageID === languageID) {
        i18n.changeLanguage(languageID);
      }
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);

export const actionLabelsForceRefetch = createAsyncThunk<void, string, AppAsyncThunkConfig>(
  `LABELS/forceRefetch`,
  async (languageID, { getState }) => {
    try {
      const {
        data: { value },
      } = await ServiceLabels.getAppLabels(languageID);

      i18n.addResources(
        languageID,
        'translation',
        value.reduce(reducerMakeTranslation, {} as Record<string, string>),
      );

      const currentLanguageID = selectLanguageID(getState());

      if (currentLanguageID === languageID) {
        i18n.changeLanguage(languageID);
      }
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
