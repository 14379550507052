import React, { useCallback } from 'react';
import { TemplateBadge, TemplateBadgeProps } from 'components/templates/template-badge';
import { useOpen } from 'AurionCR/components/hooks';
import { DialogUserPatientProfileSubscriptionActivities } from 'components/user-patient-subscription-activities';
import { useDataGridTasks } from 'components/data-grid/hooks';

export const useTasks = () => useDataGridTasks();

interface Props extends Omit<TemplateBadgeProps, 'count' | 'onClick'> {
  userPatientProfileSubscriptionID: string;
  activities: number;
}
export const ColumnActivities: React.FC<Props> = ({
  activities,
  userPatientProfileSubscriptionID,
  ...rest
}) => {
  const { onTasks } = useTasks();
  const { isOpen, handleOpen, handleClose } = useOpen();
  const onRefresh = useCallback(() => {
    onTasks([['getData']]);
  }, [onTasks]);

  return (
    <>
      <TemplateBadge {...rest} count={activities} onClick={handleOpen} />
      {isOpen && (
        <DialogUserPatientProfileSubscriptionActivities
          userPatientProfileSubscriptionID={userPatientProfileSubscriptionID}
          onClose={handleClose}
          onRefresh={onRefresh}
        />
      )}
    </>
  );
};
