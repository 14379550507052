import React, { forwardRef, memo } from 'react';
import { AppInput, AppInputProps } from '../app-input';
import { useAppSelector } from 'store';
import { selectLanguageIsRtl } from 'store/languages';

const Input = (props: AppInputProps, ref: ForwardedRef<HTMLInputElement>) => {
  const isRTL = useAppSelector(selectLanguageIsRtl);
  return (
    <AppInput
      ref={ref}
      {...props}
      inputProps={{
        ...props.inputProps,
        dir: 'ltr',
        style: { ...props.inputProps?.style, textAlign: isRTL ? 'right' : 'left' },
      }}
    />
  );
};

export const AppInputLtr = memo(forwardRef(Input)) as typeof Input;
