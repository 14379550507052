import React, { FunctionComponent, useEffect, useRef } from 'react';
import { apiClinicalMeetings } from 'services/clinical-meetings';
import { useOpen } from 'AurionCR/components/hooks';
import { DialogAlert } from 'components/dialog-alert';
import { useTranslate } from 'hooks/use-translate';
import { usePatientSubscription } from '../../hooks';
import { convertToDate, DateValue } from 'utils/dates';
import { differenceInDays } from 'date-fns';

const MAX_DAYS_SUBSCRIPTION = 30 * 2;
const MAX_DAY_MEETING = 30 * 2;

const checkValidityOfDay = (date: DateValue, maxDays: number) => {
  const startDate = convertToDate(date);
  const difInDays = differenceInDays(new Date(), startDate);
  return difInDays < maxDays;
};

const useFetchMeetingQuery = apiClinicalMeetings.useGetLatestClinicalMeetingWithDoctorQuery;

interface Props {
  userPatientProfileID: string;
  children: React.ReactNode;
}
export const WrapAlertLastDoctorVisit: FunctionComponent<Props> = ({
  userPatientProfileID,
  children,
}) => {
  const { tp } = useTranslate();
  const refOnce = useRef(false);
  const { isOpen, handleOpen, handleClose } = useOpen();

  const { data: subscription, isInactiveSubscription } =
    usePatientSubscription(userPatientProfileID);
  const { data: meeting } = useFetchMeetingQuery(userPatientProfileID, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (refOnce.current) return;
    if (isInactiveSubscription) return;
    if (!subscription) return;
    if (!meeting) return;

    // check subscription
    if (checkValidityOfDay(subscription.startDate, MAX_DAYS_SUBSCRIPTION)) return;

    // check meeting
    if (checkValidityOfDay(meeting.meetingToDateTime, MAX_DAY_MEETING)) return;

    handleOpen();
    refOnce.current = true;
  }, [isInactiveSubscription, subscription, meeting, handleOpen]);

  return (
    <>
      {children}
      {isOpen && (
        <DialogAlert
          severity={'error'}
          onClose={handleClose}
          message={tp('alert-last-doctor-visit')}
        />
      )}
    </>
  );
};
