import React, { useCallback } from 'react';
import { IDataGridFilter } from 'components/data-grid/model';
import { ButtonSwitcher, ButtonSwitcherProps } from 'components/button-switcher';

interface Props<T> extends Omit<ButtonSwitcherProps<T>, 'onChange'> {
  filter: IDataGridFilter;
  onTasks: any;
}

export const FilterButtonSwitcher = <T,>({ filter, onTasks, ...rest }: Props<T>) => {
  const onChange = useCallback((value) => {
    onTasks([
      ['pagination', { page: 1 }],
      // @ts-ignore
      ['filters', { [filter.field]: value }],
      ['getData'],
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ButtonSwitcher {...rest} onChange={onChange} />;
};
