import React, { useCallback, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { useFieldProps } from 'hooks';
import { isShouldDisableSupportMeetingDate, SupportMeetingInput } from 'services/support-meetings';
import { composeFunctions } from 'utils';
import { Left } from 'utils/types';
import { SelectEmployee } from 'components/select-employee';
import { AppDatePicker } from 'components/app-date-picker';
import { AppInput } from 'components/app-input';
import { AppSelect } from 'components/app-select';
import { useTranslate } from 'hooks/use-translate';
import { useSourceDoctors, useSourceProfilesSupport } from 'components/hooks';
import { DateLabel } from '../date-label';

interface Props {
  disabled: boolean;
  isLoading: boolean;
  times: { id: string }[];
  isRenewal: boolean;
  children: React.ReactNode;
}

type FormModel = Left<SupportMeetingInput>;

export const FormEdit: React.FC<Props> = ({ isLoading, times, disabled, isRenewal, children }) => {
  const { t } = useTranslate();
  const { control, errors, clearErrors } = useFormContext<FormModel>();
  const getFieldProps = useFieldProps({ errors });

  const clearTimeErrors = useCallback(() => {
    clearErrors(['start', 'end', 'userEmployeeProfileID']);
  }, [clearErrors]);

  const timesOptions = useMemo(() => {
    return times.map(({ id }) => ({ id, title: id }));
  }, [times]);

  const sourceDoctors = useSourceDoctors();
  const sourceManagers = useSourceProfilesSupport();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={'userEmployeeProfileID'}
          render={(renderProps) => {
            const props = getFieldProps(renderProps);
            return (
              <SelectEmployee
                {...props}
                label={t(isRenewal ? 'doctor' : 'employee')}
                disabled={disabled}
                loading={sourceDoctors.loading || sourceManagers.loading}
                options={isRenewal ? sourceDoctors.data : sourceManagers.data}
              />
            );
          }}
        />
      </Grid>
      <Grid item md={6}>
        <Controller
          control={control}
          name={'date'}
          render={(renderProps) => {
            const props = getFieldProps(renderProps);
            const { label, value } = props;
            return (
              <AppDatePicker
                {...props}
                label={<DateLabel label={label} value={value} />}
                disabled={isLoading || disabled}
                shouldDisableDate={isShouldDisableSupportMeetingDate}
              />
            );
          }}
        />
      </Grid>

      <Grid item md={3} style={{ display: isRenewal ? 'none' : undefined }}>
        <Controller
          control={control}
          name={'start'}
          render={(renderProps) => {
            const props = getFieldProps(renderProps);
            return (
              <AppSelect
                {...props}
                onChange={composeFunctions(clearTimeErrors, props.onChange)}
                options={timesOptions}
                disabled={isLoading || disabled}
                disableClearable
              />
            );
          }}
        />
      </Grid>
      <Grid item md={3} style={{ display: isRenewal ? 'none' : undefined }}>
        <Controller
          control={control}
          name={'end'}
          render={(renderProps) => {
            const props = getFieldProps(renderProps);
            return (
              <AppSelect
                {...props}
                onChange={composeFunctions(clearTimeErrors, props.onChange)}
                options={timesOptions}
                disabled={isLoading || disabled}
                disableClearable
              />
            );
          }}
        />
      </Grid>

      <Grid xs={12} item>
        {children}
      </Grid>

      <Grid item md={12}>
        <Controller
          control={control}
          name={'remarks'}
          render={(renderProps) => {
            return (
              <AppInput
                {...getFieldProps(renderProps)}
                disabled={isLoading || disabled}
                disableClearable
                multiline
                minRows={5}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};
