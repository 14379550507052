import React, { useCallback } from 'react';
import { ButtonOrange } from 'components/button-orange';
import AddIcon from '@material-ui/icons/Add';
import { useOpen } from 'AurionCR/components/hooks';
import {
  DialogTaskCreate,
  ResultCreate,
  useTodoTaskNotificationCreated,
} from 'components/dialog-task';
import { emitterLayoutContent } from '../../helpers';
import { useTranslate } from 'hooks/use-translate';

interface Props {}

export const ButtonTask: React.FC<Props> = () => {
  const { t } = useTranslate();
  const { isOpen, handleToggle, handleClose } = useOpen();

  const { onSend } = useTodoTaskNotificationCreated();
  const onCreated = useCallback(
    async (data: ResultCreate, shouldContinue: boolean) => {
      emitterLayoutContent.emit('task-created', data);

      const taskID = String(data.id);

      if (!shouldContinue) {
        handleClose();
      }

      await onSend({ id: taskID });
    },
    [handleClose, onSend],
  );

  return (
    <>
      <ButtonOrange onClick={handleToggle} startIcon={<AddIcon />}>
        {t('add-task')}
      </ButtonOrange>

      {isOpen && <DialogTaskCreate onClose={handleClose} onCreated={onCreated} />}
    </>
  );
};
