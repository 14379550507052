import React, { memo, useMemo } from 'react';
import style from './index.module.scss';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ReactPaginate from 'react-paginate';
import { calcPaginationState } from 'utils/service';
import { useTranslate } from 'hooks/use-translate';

const paginationSizes: Array<Option> = [
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 25 },
  { value: 50 },
  { value: 100 },
  { value: 500 },
];

type Option = { value: number };

const getOptionLabel = (option: Option) => String(option.value);
const filterOptions = (options: Option[]) => options;
const renderInput = (params: AutocompleteRenderInputParams) => (
  <TextField {...params} variant="outlined" />
);

interface Props {
  page: number;
  take: number;
  count: number;

  isLoading: boolean;
  onChange: (data: Partial<{ take: number; page: number }>) => void;
}

export const AppPagination = memo<Props>(({ take, page, count, isLoading, onChange }) => {
  const { t } = useTranslate();
  const { pages } = useMemo(() => {
    return calcPaginationState({ take, page, count });
  }, [take, page, count]);

  const pageSizeValue = useMemo(() => {
    return paginationSizes.find(({ value }) => value === take);
  }, [take]);

  return (
    <div className={style.pagination}>
      {pages > 1 && (
        <ReactPaginate
          forcePage={page - 1}
          previousLabel={<KeyboardArrowLeftIcon />}
          nextLabel={<KeyboardArrowRightIcon />}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={4}
          disableInitialCallback={true}
          onPageChange={({ selected }) => {
            onChange({ page: selected + 1 });
          }}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      )}
      <div className={style.size}>
        <div className={style.title}>{t('page-size')}</div>
        <Autocomplete
          value={pageSizeValue}
          filterOptions={filterOptions}
          options={paginationSizes}
          disableClearable={true}
          size="small"
          onChange={(_, { value }) => {
            onChange({ take: Number(value) });
          }}
          renderInput={renderInput}
          disabled={isLoading}
          getOptionLabel={getOptionLabel}
        />
        <div className={style.total}>
          {t('total')}: <span>{count}</span>
        </div>
      </div>
    </div>
  );
});
