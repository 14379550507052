import React, { useCallback } from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import { apiUserPatientPrescriptions } from 'services/user-patient-profile-prescriptions';
import { useEffectError, useMutationDynamic } from 'hooks';
import { useTranslate } from 'hooks/use-translate';
import { useAppDispatch, useAppSelector } from 'store';
import { selectLanguageID } from 'store/languages';
import { ServicePharmacyApiResponse } from 'services/pharmacy-api-responses';
import SendIcon from '@material-ui/icons/Send';
import { useOpen } from 'AurionCR/components/hooks';
import { composeFunctions } from 'utils';
import { apiUserPatientProfilePrescriptionActivities } from 'services/user-patient-profile-prescription-activities';
import { apiServiceNeopharm } from 'services/service-neopharm';
import { DialogNeopharmValidity } from 'components/dialog-neopharm-validity';
import { usePermissions } from 'hooks/use-permissions';
import { CheckPharmacyType } from '../check-pharmacy-type';
import { withRtlRotate } from 'hocs/with-rtl-rotate';
import { PREFER_PHARMACY_TYPE } from 'services/user-patient-profile';

const useCollectDataMutation = apiServiceNeopharm.useNeopharmGenerateDocumentsMutation;

const SendIconRotate = withRtlRotate(SendIcon);

interface Props extends ButtonProps {
  userPatientProfileID: string | null | undefined;
  prescriptionPrintFilePath: string | null | undefined;
  prescriptionID: string;
}
export const ButtonSendToNeopharm: React.FC<Props> = ({
  prescriptionID,
  prescriptionPrintFilePath,
  userPatientProfileID,
  ...rest
}) => {
  const isAllowToSendPrescriptionToPharmacy = usePermissions('isAllowToSendPrescriptionToPharmacy');
  const dispatch = useAppDispatch();
  const { t, tp } = useTranslate();

  const languageID = useAppSelector(selectLanguageID);

  const [collectData] = useCollectDataMutation();

  const prefrormSend = useCallback(async () => {
    if (!prescriptionPrintFilePath) return;

    const userPatientProfilePrescriptionID = prescriptionID;

    const resCollect = await collectData({
      prescriptionPrintFilePath,
      userPatientProfilePrescriptionID,
      languageID,
    }).unwrap();

    await ServicePharmacyApiResponse.send({
      userPatientProfilePrescriptionID,
      prescriptionFilePath: resCollect.prescriptionFilePath,
      files: resCollect.files,
    });

    dispatch(
      apiUserPatientProfilePrescriptionActivities.endpoints.createUserPatientProfilePrescriptionActivity.initiate(
        {
          userPatientProfilePrescriptionID: prescriptionID,
          remarks: tp('activity-patient-prescription-send-to-neopharm'),
        },
      ),
    );

    const res = dispatch(
      apiUserPatientPrescriptions.endpoints.getMedicalPrescriptionWizardData.initiate(
        prescriptionID,
        { forceRefetch: true },
      ),
    );

    res.unsubscribe();
    await res.unwrap();
  }, [tp, prescriptionID, prescriptionPrintFilePath, languageID, dispatch, collectData]);

  const [triggerSend, resultSend] = useMutationDynamic(prefrormSend);
  useEffectError(resultSend.error);

  const stateValidity = useOpen();

  return (
    <>
      <CheckPharmacyType
        userPatientProfileID={String(userPatientProfileID)}
        targetType={PREFER_PHARMACY_TYPE.NEOPHARM}
        onAction={stateValidity.handleOpen}
      >
        {({ isLoading, onActionWrap }) => {
          const loading = resultSend.isLoading || isLoading;
          return (
            <Button
              variant={'contained'}
              color={'primary'}
              startIcon={loading ? <CircularProgress size={18} /> : <SendIconRotate />}
              size={'small'}
              {...rest}
              onClick={onActionWrap}
              disabled={
                rest.disabled ||
                !prescriptionPrintFilePath ||
                loading ||
                !isAllowToSendPrescriptionToPharmacy
              }
            >
              {t('send-to-neopharm')}
            </Button>
          );
        }}
      </CheckPharmacyType>
      {stateValidity.isOpen && (
        <DialogNeopharmValidity
          userPatientProfileID={userPatientProfileID}
          prescriptionID={prescriptionID}
          onClose={stateValidity.handleClose}
          onContinue={composeFunctions(stateValidity.handleClose, triggerSend)}
        />
      )}
    </>
  );
};
