import React, { memo, useCallback, useMemo, useState } from 'react';
import { BaseCreateComponentProps } from '../../models';
import { Dialog, DialogContent } from '@material-ui/core';
import { apiUserPatientProfileSubscriptions } from 'services/user-patient-profile-subscriptions';
import { DialogHeading } from 'components/dialog-title';
import { AppSelect } from 'components/app-select';
import {
  subscriptionGetLabel,
  subscriptionRenderOption,
} from 'components/select-options/subscription';
import { useTranslate } from 'hooks/use-translate';
import { DialogEndTreatment } from '../../../../components/end-treatment/components/dialog-end-treatment';

const useFetchSubscriptionsQuery =
  apiUserPatientProfileSubscriptions.useGetPatientSubscriptionsForEndOfTreatmentQuery;

export const DialogCreateDocEndOfTreatment = memo<BaseCreateComponentProps>(
  ({ userPatientProfileID, onClose, onDone }) => {
    const { tp } = useTranslate();
    const [userPatientProfileSubscriptionID, setUserPatientProfileSubscriptionID] = useState<
      string | null
    >(null);
    const onUnSelect = useCallback(() => setUserPatientProfileSubscriptionID(null), []);

    const resultSubscriptions = useFetchSubscriptionsQuery(userPatientProfileID, {
      refetchOnMountOrArgChange: true,
    });

    const selectedSubscription = useMemo(() => {
      return (resultSubscriptions.data || []).find(
        ({ id }) => id === userPatientProfileSubscriptionID,
      );
    }, [resultSubscriptions.data, userPatientProfileSubscriptionID]);

    if (!selectedSubscription) {
      return (
        <Dialog open={true} fullWidth onClose={onClose}>
          <DialogHeading
            title={tp('end-of-treatment-doc-create')}
            isLoading={resultSubscriptions.isFetching}
            onClose={onClose}
          />
          <DialogContent>
            <AppSelect
              label={tp('subscription')}
              value={userPatientProfileSubscriptionID}
              loading={resultSubscriptions.isFetching}
              options={resultSubscriptions.data}
              onChange={(v) => setUserPatientProfileSubscriptionID(v)}
              renderOption={subscriptionRenderOption}
              getOptionLabel={subscriptionGetLabel}
              disabled={resultSubscriptions.isLoading}
            />
          </DialogContent>
        </Dialog>
      );
    }

    return (
      <DialogEndTreatment
        userPatientProfileID={userPatientProfileID}
        userPatientProfileSubscriptionID={String(selectedSubscription.id)}
        startDate={selectedSubscription.startDate}
        endDate={selectedSubscription.endDate}
        onClose={onUnSelect}
        onDone={onDone}
      />
    );
  },
);
