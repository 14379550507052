import React, { useCallback, useEffect } from 'react';
import { Box, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import {
  schema,
  useGetQuery,
  FormModel,
  usePostMutation,
  usePatchMutation,
  useDeleteMutation,
} from './helpers';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { adapterRtkItemID, isMutationFulfilled } from 'utils/rtk-query';
import { useEffectError, useMountedRef } from 'hooks';
import { ButtonCancel, ButtonDelete, ButtonSave } from 'components/app-buttons';
import { Form } from './form';
import { useTranslate } from 'hooks/use-translate';
import { useConfirm } from 'hooks/use-confirm';
import { DialogConfirm } from 'components/dialog-confirm';
import { usePermissions } from 'hooks/use-permissions';
import { transformStringToInteger } from 'utils/transformers';

interface Props {
  itemID: string | true;
  initData?: Partial<FormModel>;
  drugID: string;
  pharmacyDrugID: string;
  onClose: () => void;
  onRefresh?: () => void;
}
export const DialogPharmacyDrugQuantities: React.FC<Props> = ({
  itemID,
  pharmacyDrugID,
  initData,
  drugID,
  onClose,
  onRefresh,
}) => {
  const { t } = useTranslate();
  const mountedRef = useMountedRef();

  const entityID = adapterRtkItemID(itemID);
  const formMethods = useForm({
    defaultValues: schema.cast({
      pharmacyDrugID,
      ...initData,
      quantity: transformStringToInteger(initData?.dosageFormTotalDesc),
    }),
    resolver: yupResolver(schema),
    context: { id: entityID, pharmacyDrugID },
  });
  const { reset, handleSubmit, register } = formMethods;

  const resultGet = useGetQuery(entityID || '', {
    skip: !entityID,
  });
  useEffectError(resultGet.error);
  useEffect(() => {
    if (!resultGet.data) return;

    reset(schema.cast({ ...initData, ...resultGet.data, pharmacyDrugID }));
  }, [reset, resultGet.data, pharmacyDrugID, initData]);

  const [triggerPost, resultPost] = usePostMutation();
  useEffectError(resultPost.error);

  const [triggerPatch, resultPatch] = usePatchMutation();
  useEffectError(resultPatch.error);

  const [triggerDelete, resultDelete] = useDeleteMutation();
  useEffectError(resultDelete.error);

  const onDelete = useCallback(async () => {
    if (!entityID) return;
    const res = await triggerDelete({ id: entityID });

    if (isMutationFulfilled(res) && onRefresh) onRefresh();
    if (!mountedRef.current) return;
    if (isMutationFulfilled(res)) onClose();
  }, [triggerDelete, entityID, onClose, onRefresh, mountedRef]);

  const stateConfirm = useConfirm({ onAccepted: onDelete });

  const isLoading =
    resultGet.isFetching || resultPost.isLoading || resultPatch.isLoading || resultDelete.isLoading;

  const onSubmit = useCallback(
    async (data: FormModel) => {
      const res = entityID
        ? await triggerPatch({ ...data, id: entityID })
        : await triggerPost(data);

      if (isMutationFulfilled(res) && onRefresh) onRefresh();
      if (!mountedRef.current) return;
      if (isMutationFulfilled(res)) onClose();
    },
    [entityID, triggerPatch, triggerPost, onClose, onRefresh, mountedRef],
  );

  const isAllowToDeleteAnything = usePermissions('isAllowToDeleteAnything');

  const isAllowToDelete = !!(isAllowToDeleteAnything && entityID);

  return (
    <>
      <Dialog open={true} onClose={onClose} fullWidth maxWidth={'xs'}>
        <DialogHeading title={t('drug-quantity')} isLoading={isLoading} onClose={onClose} />
        <DialogContent>
          <input {...register('pharmacyDrugID')} hidden />
          <FormProvider {...formMethods}>
            <Form drugID={drugID} disabled={isLoading} />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          {isAllowToDelete && (
            <Box sx={{ mr: 'auto' }}>
              <ButtonDelete onClick={stateConfirm.onConfirm} disabled={isLoading} />
            </Box>
          )}
          <ButtonCancel isBack={!!entityID} disabled={isLoading} onClick={onClose} />
          <ButtonSave isCreate={!entityID} disabled={isLoading} onClick={handleSubmit(onSubmit)} />
        </DialogActions>
      </Dialog>
      {stateConfirm.isOpen && (
        <DialogConfirm
          title={t('confirm-delete')}
          onConfirm={stateConfirm.onAccept}
          onCancel={stateConfirm.onDecline}
        />
      )}
    </>
  );
};
