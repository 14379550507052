import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { ButtonBase, FormControl, FormHelperText, FormLabel, Typography } from '@material-ui/core';
import { FileAccept, useFileUploader, useFileUploaderErrorText } from 'hooks/use-file-uploader';
import React, { DragEventHandler, useCallback, useMemo } from 'react';
import { cropImg, getFileInfo, ValueFileUploader } from 'utils/file-uploader';
import { AspectRatio } from 'components/aspect-ratio';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';

const convertFileToBase64 = (file: File) => {
  return cropImg(file, 800, 800);
};

interface EmptyAreaProps {
  disabled: boolean;
  onOpen: () => void;
  onSetFiles: (fileList: FileList) => void;
}
const EmptyArea: React.FC<EmptyAreaProps> = ({ disabled, onOpen, onSetFiles }) => {
  const { t } = useTranslate();
  const onDragOver = useCallback<DragEventHandler>((e) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);
  const onDrop = useCallback<DragEventHandler>(
    (e) => {
      e.stopPropagation();
      e.preventDefault();

      let dt = e.dataTransfer;
      let files = dt.files;
      onSetFiles(files);
    },
    [onSetFiles],
  );
  return (
    <div
      onDragOverCapture={onDragOver}
      onDropCapture={disabled ? undefined : onDrop}
      onClick={disabled ? undefined : onOpen}
      className={style.area}
    >
      <img className={style.areaImage} src={AREA_IMAGE} alt={'upload-file-here'} />
      <Typography variant={'h5'}>{t('drop-or-select-image-title')}</Typography>
      <Typography variant={'body1'}>{t('drop-or-select-image-description')}</Typography>
    </div>
  );
};

interface ImagePreviewProps {
  value: ValueFileUploader;
  onDelete: () => void;
  disabled: boolean;
}
const ImagePreview: React.FC<ImagePreviewProps> = ({ value, onDelete, disabled }) => {
  const { t } = useTranslate();
  const { fileUrl } = getFileInfo(value);
  return (
    <div className={style.preview} onClick={disabled ? undefined : onDelete}>
      <ButtonBase title={t('delete')} className={style.previewControl}>
        <DeleteIcon color={'inherit'} />
      </ButtonBase>
      <img className={style.previewImage} src={fileUrl} alt={'preview'} />
    </div>
  );
};

interface ImageAddNewProps {
  disabled: boolean;
  onClick: () => void;
}
const ImageAddNew: React.FC<ImageAddNewProps> = ({ disabled, onClick }) => {
  const { tp } = useTranslate();
  return (
    <div className={style.add} onClick={disabled ? undefined : onClick}>
      <ButtonBase title={tp('add')} className={style.addControl}>
        <AddIcon color={'inherit'} />
      </ButtonBase>
    </div>
  );
};

interface ListAreaProps {
  value: ValueFileUploader[];
  disabled: boolean;
  onDelete: (index: number) => void;
  onOpen: () => void;
}

const ListArea: React.FC<ListAreaProps> = ({ value, disabled, onDelete, onOpen }) => {
  const factoryDelete = useCallback(
    (index: number) => {
      return () => onDelete(index);
    },
    [onDelete],
  );
  return (
    <div className={style.list}>
      {value.map((item, index) => {
        return (
          <AspectRatio key={index} ratioHeight={1} ratioWidth={1}>
            <ImagePreview value={item} disabled={disabled} onDelete={factoryDelete(index)} />
          </AspectRatio>
        );
      })}
      <AspectRatio ratioHeight={1} ratioWidth={1}>
        <ImageAddNew disabled={disabled} onClick={onOpen} />
      </AspectRatio>
    </div>
  );
};

type OuterValue = {
  fileURL: string | null;
  file: ValueFileUploader;
};
interface Props {
  error?: boolean;
  helperText?: React.ReactNode;
  label?: React.ReactNode;
  disabled?: boolean;
  value: OuterValue[];
  onChange: (value: this['value'] | null) => void;
}

const AREA_IMAGE = process.env.PUBLIC_URL + '/images/food-uploader.png';
export const FoodImageUploader: React.FC<Props> = ({
  error: outerError,
  label,
  helperText,
  disabled,
  value: outerValue,
  onChange,
}) => {
  const accept: FileAccept[] = ['.png', '.jpeg', '.jpg'];
  const maxSize = 5;

  const value = useMemo(() => {
    return outerValue.map(({ file }) => file);
  }, [outerValue]);

  const onAdd = useCallback(
    (files: ValueFileUploader[] | null) => {
      const newValues = (files || []).map((file) => ({
        fileURL: null,
        file,
      }));
      return onChange([...outerValue, ...newValues]);
    },
    [outerValue, onChange],
  );

  const { inputProps, onOpen, setFileList, error } = useFileUploader({
    disabled,
    onChange: onAdd,
    accept,
    maxSize,
    convertFileToBase64,
    multiple: true,
  });

  const errorMessage = useFileUploaderErrorText(error, { accept, maxSize });

  const helper = helperText || errorMessage;

  const isEmpty = value.length === 0;

  const onDelete = useCallback(
    (index: number) => {
      onChange(outerValue.filter((_, i) => i !== index));
    },
    [onChange, outerValue],
  );

  return (
    <FormControl error={Boolean(outerError)} fullWidth className={style.root}>
      <input {...inputProps} />
      {label && <FormLabel>{label}</FormLabel>}
      {isEmpty ? (
        <EmptyArea disabled={!!disabled} onOpen={onOpen} onSetFiles={setFileList} />
      ) : (
        <ListArea value={value} disabled={!!disabled} onDelete={onDelete} onOpen={onOpen} />
      )}
      {helper && <FormHelperText>{helper}</FormHelperText>}
    </FormControl>
  );
};
