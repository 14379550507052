import React, { useCallback } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { Loading } from 'AurionCR/components';
import { useTranslate } from 'hooks/use-translate';
import {
  useMedicalPrescriptionDetailsContext,
  useMedicalPrescriptionDetailsWithRules,
} from '../details-context';
import { ServicePatientPrescriptionDetails } from 'services/user-patient-profile-prescription-details';
import { useEffectError, useMountedRef, useMutationDynamic } from 'hooks';
import AutorenewIcon from '@material-ui/icons/Autorenew';

interface Props {
  onClose: () => void;
  onDone: () => void;
}

export const DialogRules29: React.FC<Props> = ({ onClose, onDone }) => {
  const { t, tp } = useTranslate();

  const mountedRef = useMountedRef();

  const { setRows, rows } = useMedicalPrescriptionDetailsContext();
  const drugsWith29Rules = useMedicalPrescriptionDetailsWithRules();

  const requestSubmit = useCallback(async () => {
    const updatedRows = await Promise.all(
      drugsWith29Rules.map(async (detail) => {
        await ServicePatientPrescriptionDetails.patch({ id: detail.id, includeForm29: true });

        return { ...detail, includeForm29: true };
      }),
    );

    if (!mountedRef.current) {
      return;
    }

    setRows(
      rows.map((row) => {
        const updatedRow = updatedRows.find(({ id }) => id === row.id);

        return updatedRow || row;
      }),
    );

    requestAnimationFrame(() => {
      onDone();
    });
  }, [drugsWith29Rules, rows, mountedRef, setRows, onDone]);

  const [trigger, { error, isLoading }] = useMutationDynamic(requestSubmit);
  useEffectError(error);

  return (
    <Dialog fullWidth={true} maxWidth={'xs'} open={true} onClose={onClose}>
      <DialogHeading
        title={tp('drugs-with-rules-29-title')}
        onClose={onClose}
        disabled={isLoading}
      />
      <DialogContent>
        {tp('drugs-with-rules-29-description', { count: drugsWith29Rules.length })}
        <Loading active={isLoading} />
      </DialogContent>

      <DialogActions>
        <Box mr={'auto'}>
          <Button disabled={isLoading} color={'primary'} variant={'text'} onClick={onClose}>
            {t('close')}
          </Button>
        </Box>
        <Button
          endIcon={<AutorenewIcon />}
          disabled={isLoading}
          color={'primary'}
          variant={'contained'}
          onClick={trigger}
        >
          {t('add-automatically')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
