import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actionLanguagesFetch, actionLanguagesInit } from './actions';
import { languagesStorage } from './helpers';
import { AppLanguage } from 'services/languages';

interface State {
  isLoading: boolean;
  isInit: boolean;
  data: AppLanguage[];
  error: null | Partial<Error>;
  languageID: null | string;
}

const initialState = (): State => ({
  languageID: languagesStorage.getObject()?.languageID || null,
  isInit: false,
  isLoading: false,
  data: [],
  error: null,
});

const slice = createSlice({
  name: 'LANGUAGES',
  initialState,
  reducers: {
    actionLanguagesSetLanguageID(state, action: PayloadAction<string>) {
      state.languageID = action.payload;
    },
  },
  extraReducers(build) {
    build
      .addCase(actionLanguagesInit.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(actionLanguagesInit.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isInit = true;
        state.languageID = action.payload.languageID;
      })
      .addCase(actionLanguagesInit.rejected, (state, action) => {
        state.isLoading = false;
        state.isInit = true;
        state.error = action.error;
      });

    build.addCase(actionLanguagesFetch.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});
export const { actionLanguagesSetLanguageID } = slice.actions;

export const languagesReducer = slice.reducer;
