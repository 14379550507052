import React, { memo, useCallback } from 'react';
import { MenuItem, MenuList } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from 'store';
import style from './index.module.scss';
import { iLanguage } from 'services/languages';
import { actionLanguagesSetLanguageID, selectLanguageID, selectLanguages } from 'store/languages';

export const MenuLanguages = memo(() => {
  const dispatch = useAppDispatch();

  const languageID = useAppSelector(selectLanguageID);
  const languages = useAppSelector(selectLanguages);

  const factoryChangeLanguage = useCallback(
    (lang: iLanguage) => {
      return () => dispatch(actionLanguagesSetLanguageID(lang.id as string));
    },
    [dispatch],
  );
  return (
    <MenuList className={style.root}>
      {languages.map((language) => {
        return (
          <MenuItem
            key={language.id}
            selected={language.id === languageID}
            className={style.item}
            onClick={factoryChangeLanguage(language)}
            title={language.title || ''}
          >
            {language.culture}
          </MenuItem>
        );
      })}
    </MenuList>
  );
});
