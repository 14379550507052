import { DynamicService } from 'utils/service';
import { API_NOTEBOOKS, Notebook, NOTEBOOK_INDEXES, NotebookWithPermissions } from './models';
import { PatchPartial } from 'utils/types';
import { ServiceUserEmployeePermissionNotebooks } from 'services/user-employee-profile-permisson-notebooks';
import { apiRtk } from 'utils/rtk-query';
import {
  createFilterEquals,
  createFilterValueArrayEquals,
  mergeFilters,
} from 'utils/dynamic-helpers';

export * from './models';

class Service extends DynamicService<Notebook> {
  postWithPermissions = async (data: NotebookWithPermissions) => {
    const { userEmployeeProfilePermissionIDs, ...rest } = data;
    const result = await this.post(rest);

    await ServiceUserEmployeePermissionNotebooks.createBulk({
      notebookID: result.data.id,
      userEmployeeProfilePermissionIDs,
    });

    return result;
  };
  patchWithPermissions = async (data: PatchPartial<NotebookWithPermissions, 'id'>) => {
    const { userEmployeeProfilePermissionIDs, ...rest } = data;
    const result = await this.patch(rest);

    if (userEmployeeProfilePermissionIDs) {
      await ServiceUserEmployeePermissionNotebooks.createBulk({
        notebookID: data.id,
        userEmployeeProfilePermissionIDs,
      });
    }

    return result;
  };
  getNotebookAddWeight = async () => {
    const result = await this.getAllDynamic({
      filter: 'isForWeightOnly==true',
      take: 1,
    });

    const data = result.data.value[0];

    if (!data) {
      throw new Error('error-notebook-is-for-weight-only');
    }

    return { ...result, data };
  };
}

export const ServiceNotebooks = new Service({
  mainField: 'id',
  getAll: API_NOTEBOOKS.GET_ALL_DYNAMIC,
  post: API_NOTEBOOKS.POST,
  patch: API_NOTEBOOKS.PATCH,
  delete: API_NOTEBOOKS.DELETE,
});

export const apiNotebooks = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getNotebooksHealthSurvey: builder.query({
      queryFn: async (arg: void) => {
        const {
          data: { value },
        } = await ServiceNotebooks.getAllDynamic<Pick<Notebook, 'id' | 'labelKey'>>({
          filter: mergeFilters(
            createFilterValueArrayEquals('rowIndex', [
              NOTEBOOK_INDEXES.QUESTIONNAIRE_EN,
              NOTEBOOK_INDEXES.QUESTIONNAIRE_HE,
            ]),
            createFilterEquals('isActive', true),
          ).join('&&'),
          select: ['id', 'labelKey'].join(','),
        });
        return { data: value };
      },
    }),
    getNotebookByID: builder.query<Notebook, string>({
      queryFn: async (input) => {
        try {
          const { data } = await ServiceNotebooks.getDynamic(input);

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
