import React, { useCallback } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { apiUserPatientProfileDocuments } from 'services/user-patient-profile-documents';
import { apiMediaPrivate } from 'services/media-private-services';
import { useEffectError } from 'hooks';
import { getFileNameExt } from 'utils/file-uploader';
import GetAppIcon from '@material-ui/icons/GetApp';
import { downloadBlob } from 'utils/download';

interface Props {
  userPatientProfilePrescriptionID: string;
  userPatientProfilePrescriptionDetailID: string;
  includeForm29: boolean;
}

export const ButtonDownload29Gimel: React.FC<Props> = ({
  includeForm29,
  userPatientProfilePrescriptionID,
  userPatientProfilePrescriptionDetailID,
}) => {
  const { t } = useTranslate();
  const [triggerGetDocument, resultGetDocument] =
    apiUserPatientProfileDocuments.useLazyGetUserPatientProfileDocumentForm29GimelQuery();
  useEffectError(resultGetDocument.error);

  const [triggerGetBlob, resGetBlob] = apiMediaPrivate.useLazyGetMediaPrivateBlobQuery();
  useEffectError(resultGetDocument.error);

  const onDownload = useCallback(async () => {
    const resDoc = await triggerGetDocument(
      { userPatientProfilePrescriptionID, userPatientProfilePrescriptionDetailID },
      true,
    );

    if (!resDoc.data) return;

    const documentURL = resDoc.data.documentURL;

    if (!documentURL) return;

    const resBlob = await triggerGetBlob(documentURL);

    if (!resBlob.data) return;

    const ext = getFileNameExt(documentURL);
    const fileName = `${resDoc.data.fileName}.${ext}`;

    downloadBlob(resBlob.data, { fileName });
  }, [
    triggerGetDocument,
    userPatientProfilePrescriptionID,
    userPatientProfilePrescriptionDetailID,
    triggerGetBlob,
  ]);

  const isLoading = resultGetDocument.isLoading || resGetBlob.isLoading;

  return (
    <Button
      color={'primary'}
      startIcon={isLoading ? <CircularProgress size={16} /> : <GetAppIcon />}
      disabled={!includeForm29 || isLoading}
      onClick={onDownload}
    >
      {t('download-form-29')}
    </Button>
  );
};
