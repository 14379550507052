import React, { memo, useCallback, useState } from 'react';
import { DialogBase, requestError } from 'AurionCR/components';
import { API_USER_PATIENT_PROFILE_SESSIONS } from 'services/user-patient-profile-session';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';
import { api } from 'utils/service';

interface Props {
  isCanDelete?: boolean;
  formTitle?: React.ReactNode;
  userPatientProfileSessionID: string;
  onClose: (isRefresh?: boolean) => void;
}
export const DeleteActivity = memo<Props>(
  ({ isCanDelete = false, formTitle = ' ', userPatientProfileSessionID, onClose }) => {
    const { t } = useTranslate();
    const dispatch = useDispatch();
    // states
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const onDelete = useCallback(() => {
      setIsLoading(true);
      api
        .delete(API_USER_PATIENT_PROFILE_SESSIONS.DELETE({ id: userPatientProfileSessionID }))
        .then(() => {
          setIsLoading(false);
          setIsOpenDelete(false);
          onClose(true);
        })
        .catch((error) => {
          dispatch(notifyRequestResult(requestError(error), 'error'));
          setIsLoading(false);
          setIsOpenDelete(false);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userPatientProfileSessionID, setIsLoading, setIsOpenDelete, onClose]);
    // render
    return (
      <div className={style.btnDeleteWrapper}>
        {isCanDelete && (
          <Button
            className={style.btnDelete}
            size={'small'}
            onClick={() => setIsOpenDelete(true)}
            disabled={isLoading || isOpenDelete}
            startIcon={<DeleteIcon />}
          >
            {t('delete')}
          </Button>
        )}
        {isOpenDelete && (
          <DialogBase
            title={formTitle}
            onClose={() => setIsOpenDelete(false)}
            onConfirm={onDelete}
            isLoading={isLoading}
          />
        )}
      </div>
    );
  },
);
