import { UserEmployeeProfile } from '../user-employee-profiles';

const API_ENDPOINT = 'UserEmployeeProfileAbsences';

export const API_USER_EMPLOYEE_PROFILE_ABSENCES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  CREATE: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<UserEmployeeProfileAbsence, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<UserEmployeeProfileAbsence, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface UserEmployeeProfileAbsence extends Components.Schemas.UserEmployeeProfileAbsence {}

export type CreateForAllInput = Pick<UserEmployeeProfileAbsence, 'eventDate' | 'description'>;
export type CreateForAllEmployee = Pick<UserEmployeeProfile, 'appIdentityUserID'> & {
  absences: Pick<UserEmployeeProfileAbsence, 'eventDate'>[];
};
