const API_ENDPOINT = 'UserEmployeeProfilePermissions';

export const API_USER_EMPLOYEE_PROFILE_PERMISSIONS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<UserEmployeeProfilePermission, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<UserEmployeeProfilePermission, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface UserEmployeeProfilePermission
  extends Components.Schemas.UserEmployeeProfilePermission {}

export class EmployeePermissions implements UserEmployeeProfilePermission {
  id!: string;
  isActive: boolean = true;
  title = '';
  position = '';

  // general
  isAllowToEditPermissions = false;
  isAllowToViewDashboard = false;
  isAllowToViewDrugs = false;
  isAllowToEditDrugs = false;
  isAllowToEditPharmacies = false;
  isAllowToViewPharmacies = false;
  isAllowToEditCommercials = false;
  isAllowToEditProfile = false;
  isAllowToEditEmployeeUsers = false;
  isAllowToDeleteAnything = false;
  roleDoctor = false;
  roleDietitian = false;
  rolePatientManager = false;
  roleSecretary = false;
  roleTelemarketing = false;

  // patient
  isAllowToCreateAndRenewPrescriptions = false;
  isAllowToEditPatients = false;
  isAllowToEditPatientsLimited = false;
  isAllowToEditTaskManager = false;
  isAllowToEditTaskManagerLimited = false;
  isAllowToEditClinicalMeetings = false;
  isAllowToEditClinicalMeetingsLimited = false;
  isAllowToSetupNextCall = false;
  isAllowToSendEmailToPatient = false;
  isAllowToSendDocumentToPatient = false;
  isAllowToSendPrescriptionToPatient = false;
  isAllowToSendPrescriptionCopyToPatient = false;
  isAllowToSendPrescriptionToPharmacy = false;
  isAllowToInactiveOrOnHoldPatient = false;
  // settings
  isAllowToEditSetting = false;
  isAllowToEditLabels = false;
  isAllowToEditLeads = false;
  isAllowToEditNotebooks = false;
  isAllowToEditClinicalParameters = false;
  isAllowToEditGeneralSettings = false;
  isAllowToEditMeetingParameters = false;
  isAllowToViewDocTemplates = false;
  isAllowToViewFormDocumentGenerator = false;
  isAllowToEditPrescriptionWizard = false;
  isAllowToEditSubscriptions = false;
  isAllowToSetupMultipleMeetings = false;
  isAllowToChangeSubscriptionDates = false;
  isAllowToCancelMeeting = false;

  color = '';
}

export type PermissionKey<T extends EmployeePermissions, K extends keyof T = keyof T> =
  | K
  | (K extends string ? `!${K}` : K);
export const permissionKey = (...key: PermissionKey<EmployeePermissions>[]) => {
  return key.join(',');
};

export enum PERMISSION_IDS {
  MANAGER = '0b41a217-dada-4c99-897c-b0070f6faf79',
  TELEMARKETING = '1aad621d-5821-438c-a57c-162d6cbf40ec',
  DOCTOR = '73e8dede-febc-46d2-ba22-b2cee5467db2',
  SECRETARY = '7b678c33-252f-42c1-a88c-f51092cd4d99',
  PATIENT_MANAGER_AND_TELEMARKETING = 'a9d1447f-5033-4c92-b33f-243fd7e64936',
  ADMIN = 'b4f16283-68b0-404a-a0ef-a9fb6a9faef7',
  ACCOUNTANT = 'c52be2f8-81a8-4139-b557-c19510b572cc',
  DIETITIAN = 'e0545b24-54b0-4d72-bcb8-d690b035d3ea',
}
