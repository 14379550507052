import React from 'react';
import SendIcon from '@material-ui/icons/Send';

import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
} from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { useTranslate } from 'hooks/use-translate';
import { useAppSelector } from 'store';
import { selectLanguageIsRtl } from 'store/languages';

export * from './models';
export * from './components';

interface Props {
  title?: string;
  isLoading?: boolean;
  onClose: () => void;
  onSubmit: () => void;
  children?: React.ReactNode;
  DialogProps?: Partial<DialogProps>;
  ButtonSendProps?: Partial<ButtonProps>;
}

export const DialogSendMessage = ({
  title,
  isLoading = false,
  onClose,
  onSubmit,
  children,
  DialogProps,
  ButtonSendProps,
}: Props) => {
  const { t } = useTranslate();
  const isRtl = useAppSelector(selectLanguageIsRtl);
  return (
    <Dialog fullWidth={true} maxWidth={'lg'} open={true} onClose={onClose} {...DialogProps}>
      <DialogHeading
        isLoading={isLoading}
        title={title || t('dialog-send-message')}
        onClose={onClose}
      />
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button color={'secondary'} onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button
          color={'primary'}
          variant={'contained'}
          endIcon={<SendIcon style={{ transform: isRtl ? 'scaleX(-1)' : 'scaleX(1)' }} />}
          {...ButtonSendProps}
          onClick={onSubmit}
          disabled={isLoading || ButtonSendProps?.disabled}
        >
          {t('send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
