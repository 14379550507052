import React, { memo, useCallback, useMemo } from 'react';
import { IRoute, useRouter } from 'AurionCR/components';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { checkActive } from './main-nav';
import clsx from 'clsx';
import style from '../index.module.scss';
import { NativeScroll } from 'components/native-scroll';

const MenuItem = memo(({ path, title }: IRoute) => {
  const router = useRouter();
  const active = useMemo(() => {
    return checkActive(router?.pathname, path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.pathname, path]);
  // handlers
  const onClick = useCallback(() => {
    router.push({ pathname: path });
  }, [path, router]);
  // render
  return (
    <ListItem button onClick={onClick} className={clsx(style.link, active && style.active)}>
      <ListItemText className={style.title} color="primary" primary={title} />
    </ListItem>
  );
});
export const TabsNav = memo(({ routes }: { routes: IRoute[] }) => {
  // render
  return (
    <NativeScroll className={clsx('r-scroll-bar', style.mainTabsNav)}>
      <List component="nav" disablePadding>
        {routes.map((item) => (
          <MenuItem key={item.path} {...item} />
        ))}
      </List>
    </NativeScroll>
  );
});
