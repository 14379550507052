import React, { useCallback, useMemo, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { ReactComponent as IconPdf } from 'images/icon-pdf.svg';
import style from './index.module.scss';
import { AppCheckbox } from 'components/app-checkbox';
import { useMap } from 'hooks';
import { PdfView } from 'components/pdf-view';
import { apiMediaPrivate } from 'services/media-private-services';
import { NativeScroll } from 'components/native-scroll';
import { ButtonCancel, ButtonSave } from 'components/app-buttons';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { useTranslate } from 'hooks/use-translate';

interface DocumentPreviewProps {
  url: string | null | undefined;
  title: string | null | undefined;
}
const DocumentPreview: React.FC<DocumentPreviewProps> = ({ title, url }) => {
  const { data: docBase64, isFetching } = apiMediaPrivate.useGetMediaPrivateBase64Query(
    { url: url || '' },
    { skip: !url },
  );

  return <PdfView title={title || 'unknown'} isLoading={isFetching} pdf={docBase64} />;
};

interface DocumentItemProps {
  title: React.ReactNode;
  selected: boolean;
  onSelect: () => void;
  checked: boolean;
  onCheck: () => void;
}
const DocumentItem: React.FC<DocumentItemProps> = ({
  checked,
  selected,
  title,
  onSelect,
  onCheck,
}) => {
  const onChecked = useCallback(
    (value: boolean) => {
      onCheck();
      if (value) {
        onSelect();
      }
    },
    [onCheck, onSelect],
  );

  return (
    <ListItem button selected={selected} onClick={onSelect} className={style.item}>
      <ListItemIcon style={{ minWidth: '3.4rem' }}>
        <IconPdf style={{ width: 24, height: 24 }} />
      </ListItemIcon>
      <ListItemText primary={title} />
      <ListItemSecondaryAction>
        <AppCheckbox
          value={checked}
          onChange={onChecked}
          FormControlProps={{
            style: { margin: 0 },
          }}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

type File = {
  id: string;
  title: string;
  url: string;
};

interface Props<T extends File> {
  title?: React.ReactNode;
  value: T[];
  onChange: (value: T[]) => void;
  options: T[];

  onClose: () => void;
}
export const DialogAttachments = <T extends File>({
  title,
  value,
  onChange,
  options,
  onClose,
}: Props<T>) => {
  const { tp } = useTranslate();
  const [selectedID, setSelectedID] = useState('');
  const [checked, setChecked] = useState<T[]>(value || []);
  const mapChecked = useMap(checked, 'id');

  const factorySelect = useCallback((id: string) => {
    return () => {
      setSelectedID(id);
    };
  }, []);

  const factoryCheck = useCallback((item: T) => {
    return () => {
      setChecked((prev) => {
        const founded = prev.find(({ id }) => id === item.id);

        if (founded) {
          return prev.filter(({ id }) => id !== item.id);
        }

        return [...prev, item];
      });
    };
    //eslint-disable-next-line
  }, []);

  const selectedItem = useMemo(() => {
    return options.find((file) => file.id === selectedID);
  }, [options, selectedID]);

  const handleSubmit = useCallback(() => {
    onChange(checked);
  }, [onChange, checked]);

  return (
    <Dialog open={true} fullWidth maxWidth={'xl'} onClose={onClose}>
      <DialogHeading title={title} onClose={onClose} />
      <DialogContent>
        <div className={style.layout}>
          <div>
            <NativeScroll>
              <List>
                {options.map((value) => {
                  return (
                    <DocumentItem
                      key={value.id}
                      selected={selectedID === value.id}
                      checked={!!mapChecked[value.id]}
                      title={value.title}
                      onSelect={factorySelect(value.id)}
                      onCheck={factoryCheck(value)}
                    />
                  );
                })}
              </List>
            </NativeScroll>
          </div>
          <div>
            <DocumentPreview title={selectedItem?.title} url={selectedItem?.url} />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <ButtonCancel isBack={false} onClick={onClose} />
        <ButtonSave
          isCreate={false}
          startIcon={<AttachmentIcon />}
          disabled={checked.length === 0}
          onClick={handleSubmit}
        >
          {tp('attach')}
        </ButtonSave>
      </DialogActions>
    </Dialog>
  );
};
