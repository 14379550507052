import React from 'react';
import { Stack } from 'components/stack';
import { Divider } from '@material-ui/core';
import { differenceInDays } from 'date-fns';
import { convertToDate } from 'utils/dates';
import { useTranslate } from 'hooks/use-translate';
import VAR from 'styles/_variabels.scss';

const ALL_DAYS = 7;

interface Props {
  label: string;
  value: string | null;
}

export const DateLabel: React.FC<Props> = ({ label, value }) => {
  const { tp } = useTranslate();
  const currentDate = new Date();

  const daysDifference = differenceInDays(convertToDate(value), currentDate);

  const isOver = daysDifference > ALL_DAYS;

  return (
    <Stack divider={<Divider orientation={'vertical'} flexItem />} spacing={1}>
      <div>{label}</div>
      {isOver && <div style={{ color: VAR.colorRed }}>{tp('more-than-week')}</div>}
    </Stack>
  );
};
