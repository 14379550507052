import React, { memo, useMemo } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import clsx from 'clsx';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';
import { useSourceEmployees } from '../../hooks';

interface Employee {
  firstName?: string | null;
  lastName?: string | null;
}

interface Props extends TypographyProps {
  employee: Employee | string;
  chooseEmployee?: boolean;
}

// TODO NEEDS TO REFACTOR;

export const TemplateEmployee = memo<Props>(({ className, employee, chooseEmployee, ...rest }) => {
  const { t } = useTranslate();

  const { data, loading } = useSourceEmployees(false);

  const fullName = useMemo(() => {
    if (typeof employee === 'string') {
      return employee;
    }

    return [employee?.firstName, employee?.lastName].filter(Boolean).join(' ');
  }, [employee]);

  const label = useMemo(() => {
    if (loading) {
      return '';
    }

    if (chooseEmployee && !fullName) {
      return `[${t('choose-employee')}]`;
    }

    const isActiveField = data.find((item) => item.title === fullName)?.isActive;

    return isActiveField ? '' : ` [${t('inactive')}]`;
  }, [chooseEmployee, fullName, t, data, loading]);

  return (
    <Typography className={clsx(style.root, className)} {...rest}>
      {fullName}
      {label}
    </Typography>
  );
});
