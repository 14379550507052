import React, { memo, useMemo } from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Controls from 'AurionCR/components/form/controls';
import { IUseForm, useFormHook } from 'AurionCR/components/formV2';
import { DialogBase } from 'AurionCR/components';
import { DialogHeading } from 'components/dialog-title';
import { IGridEditFormProps } from 'components/models';
import { useRequestNotify } from 'components/hooks';
import { useDeleteDialog } from 'AurionCR/components/form';
import { API_NOTEBOOKS, ServiceNotebooks } from 'services/notebook';
import { MainFormFields } from './main-form-fields';
import { NotebookFieldInputs } from './notebook-field-inputs';
import './_style.scss';
import { usePermissions } from 'hooks/use-permissions';
import { useTranslate } from 'hooks/use-translate';

const defaultValues = {
  id: '',
  labelKey: '',
  externalSystemTitle: '',
  userEmployeeProfilePermissionIDs: [],
  migatedFromOldSystem: false,
  displayNextMeetingForm: false,
  isForPatient: false,
  isActive: true,

  displayIcd10Section: false,
  displaySideEffectSection: false,
  displayPastDrugForObesitySection: false,
  displayRoutineDrugSection: false,
  displayClinicDrugSection: false,
  displaySurgerySection: false,
  displaySensitivitySection: false,
  displaySensitivityDrugSection: false,
  displayAttachToMeeting: false,
  isForWeightOnly: false,
  isForPrescriptionOnly: false,
  isForClinicalMeeting: false,
  isForTraining: false,
  isRequirePrescription: false,
  isAllowToEditByAll: false,
};

export default memo(({ onClose, onFormHandle, itemID }: IGridEditFormProps) => {
  const { t } = useTranslate();
  const config = useMemo<IUseForm<typeof defaultValues>>(() => {
    return {
      editID: itemID,
      fields: defaultValues,
      endpoint: API_NOTEBOOKS.API_ENDPOINT,
      get: {
        request: async () => {
          const result = await ServiceNotebooks.getDynamic(String(itemID), {
            select: [
              'id',
              'labelKey',
              'externalSystemTitle',
              'migatedFromOldSystem',
              'displayNextMeetingForm',
              'isForPatient',
              'isActive',
              'displayIcd10Section',
              'displaySideEffectSection',
              'displayPastDrugForObesitySection',
              'displayRoutineDrugSection',
              'displayClinicDrugSection',
              'displaySurgerySection',
              'displaySensitivitySection',
              'displaySensitivityDrugSection',
              'displayAttachToMeeting',
              'userEmployeeProfilePermissionNotebooks.Select(k => k.userEmployeeProfilePermissionID) as userEmployeeProfilePermissionIDs',
              'isForPrescriptionOnly',
              'isForWeightOnly',
              'isForClinicalMeeting',
              'isForTraining',
              'isRequirePrescription',
              'isAllowToEditByAll',
            ].join(','),
          });
          return { data: { value: [result.data] } };
        },
      },
      post: {
        request: ({ data }) => {
          return ServiceNotebooks.postWithPermissions(data);
        },
      },
      patch: {
        request: ({ data }) => {
          return ServiceNotebooks.patchWithPermissions(data);
        },
      },
      formHandle: onFormHandle,
      title: 'labelKey',
    };
  }, [itemID, onFormHandle]);
  const {
    formLoading,
    setFormLoading,
    formTitle,
    formIsNew,
    formUse: { control, errors, handleSubmit },
    onSubmit,
    onSubmitAndContinue,
    deleteItem,
  } = useFormHook(config);
  // request noty wrapper
  const { requestWrap: wrapSubmit } = useRequestNotify({
    request: onSubmit,
    setLoading: setFormLoading,
  });
  const { requestWrap: wrapDelete } = useRequestNotify({
    request: deleteItem,
    setLoading: setFormLoading,
  });
  const { requestWrap: wrapSubmitAndContinue } = useRequestNotify({
    request: onSubmitAndContinue,
    setLoading: setFormLoading,
  });
  // delete helper
  const { handleClose, handleConfirm, handleOpen, isOpenDelete } = useDeleteDialog({
    deleteMiddleware: wrapDelete,
  });
  const isAllowToDelete = usePermissions('isAllowToDeleteAnything');
  // render
  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth={formIsNew ? 'sm' : 'lg'}
      className={'notebook-dialog'}
    >
      <DialogHeading isLoading={formLoading} title={t('notebook')} onClose={onClose} />
      <DialogContent style={{ position: 'relative' }}>
        <MainFormFields
          isLoading={formLoading}
          errors={errors}
          control={control}
          formIsNew={formIsNew}
        />
        {!formIsNew && <NotebookFieldInputs notebookID={itemID as string} />}
        {isOpenDelete && isAllowToDelete && (
          <DialogBase
            title={formTitle}
            onClose={handleClose}
            onConfirm={handleConfirm}
            isLoading={formLoading}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Controls
          size="medium"
          state={!formIsNew ? 'save' : 'create'}
          loading={formLoading}
          onSubmit={handleSubmit(wrapSubmit)}
          onDelete={isAllowToDelete ? handleOpen : undefined}
          onCancel={onClose}
          showDelete={!formIsNew && isAllowToDelete}
          onSubmitAndContinue={handleSubmit(wrapSubmitAndContinue)}
          showSubmitAndContinue={true}
        />
      </DialogActions>
    </Dialog>
  );
});
