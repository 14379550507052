import React, { useCallback } from 'react';
import { useOpen } from 'AurionCR/components/hooks';
import { IconButton } from '@material-ui/core';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import { DialogUserPatientProfileSession } from 'components/dialoog-user-patient-profile-session';
import { useTranslate } from 'hooks/use-translate';

interface Props {
  userPatientProfileID: string | null | undefined;
  userPatientProfileSessionID: string | null | undefined;
}
export const ColumnNotebook: React.FC<Props> = ({
  userPatientProfileSessionID,
  userPatientProfileID,
}) => {
  const { t } = useTranslate();
  const { isOpen, handleOpen, handleClose } = useOpen();

  const onClickSession = useCallback<React.MouseEventHandler>(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      handleOpen();
    },
    [handleOpen],
  );

  return (
    <>
      <IconButton
        title={t('notebook')}
        disabled={!userPatientProfileSessionID}
        size={'small'}
        color={'primary'}
        onClick={onClickSession}
      >
        <BookmarksIcon />
      </IconButton>
      {isOpen && userPatientProfileSessionID && userPatientProfileID && (
        <DialogUserPatientProfileSession
          itemID={userPatientProfileSessionID}
          onClose={handleClose}
          userPatientProfileID={userPatientProfileID}
          isShowDelete={false}
        />
      )}
    </>
  );
};
