import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  ButtonBase,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { getRandomString, useI18n } from 'AurionCR/components';
import SignaturePad from 'signature_pad';
import style from './index.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import UndoIcon from '@material-ui/icons/Undo';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageIcon from '@material-ui/icons/Image';
import clsx from 'clsx';

export interface SignatureProps extends SignaturePropsDefaults {
  value: string;
  onChange: (value: string) => void;
  classes?: {
    wrapper?: string;
    btn?: string;
  };
}

export interface SignaturePropsDialog extends SignaturePropsDefaults {
  value: any[];
  onClose: (e?: any) => void;
  onChange: (data: any[], value: string) => void;
}

export interface SignaturePropsDefaults {
  width?: number;
  height?: number;
  maxWidth?: number;
  maxHeight?: number;
  maxWidthBtn?: number;
  maxHeightBtn?: number;
  scale?: number;
}

export const Signature = memo(
  ({
    width = 100,
    height = 36,
    maxWidthBtn = 100,
    maxHeightBtn = 36,
    classes,
    value,
    onChange,
    ...rest
  }: SignatureProps) => {
    // states
    const [open, setOpen] = useState(false);
    const [data, setData] = useState<any[]>([]);
    // computed
    const { width_, height_ } = useMemo(() => {
      const scale_ = Math.min(1, maxWidthBtn / width, maxHeightBtn / height);
      return { width_: width * scale_, height_: height * scale_ };
    }, [width, height, maxHeightBtn, maxWidthBtn]);
    // handlers
    const onChangeCanvas = useCallback(
      (data: any[], value: string) => {
        setData(data);
        onChange(value);
      },
      [onChange, setData],
    );
    const onClose = useCallback(() => {
      setOpen(false);
    }, [setOpen]);
    const onOpen = useCallback(() => {
      setOpen(true);
    }, [setOpen]);
    // render
    return (
      <div className={clsx(style.wrapper, classes?.wrapper)}>
        <ButtonBase
          onClick={onOpen}
          className={clsx(style.btn, classes?.btn)}
          style={{ width: width_, height: height_ }}
        >
          {value ? (
            <img src={value} alt="" style={{ width: width_, height: height_ }} />
          ) : (
            <ImageIcon />
          )}
        </ButtonBase>
        {open && (
          <SignatureDialog
            {...rest}
            width={width}
            height={height}
            value={data}
            onChange={onChangeCanvas}
            onClose={onClose}
          />
        )}
      </div>
    );
  },
);

export const SignatureDialog = memo(
  ({
    width = 100,
    height = 36,
    maxWidth = 1000,
    maxHeight = 1000,
    scale = 7,
    value,
    onClose,
    onChange,
  }: SignaturePropsDialog) => {
    const { t } = useI18n();
    //state
    const canvasRef = useRef<any>(null);
    const [tik, setTik] = useState(getRandomString());
    const [signaturePad, setSignaturePad] = useState<SignaturePad | null>(null);

    // handlers
    const onSubmit = useCallback(() => {
      if (signaturePad) {
        const paths = signaturePad.toData();
        if (paths.length) {
          onChange(paths, signaturePad.toDataURL('image/png'));
        } else {
          onChange([], '');
        }
      } else {
        onChange([], '');
      }
      onClose();
    }, [signaturePad, onChange, onClose]);
    const onClear = useCallback(() => {
      if (signaturePad) {
        signaturePad.clear();
      }
    }, [signaturePad]);
    const onUndo = useCallback(() => {
      if (signaturePad) {
        const data = signaturePad.toData();
        if (data) {
          data.pop();
          signaturePad.fromData(data);
        }
      }
    }, [signaturePad]);
    // init
    useEffect(() => {
      if (!signaturePad && canvasRef?.current) {
        const canvas = canvasRef.current;

        const scale_ = Math.min(scale, maxWidth / width, maxHeight / height);
        canvas.width = width * scale_;
        canvas.height = height * scale_;

        const signaturePad = new SignaturePad(canvas);
        setSignaturePad(signaturePad);

        if (value) {
          signaturePad.fromData(value);
        }
      }
      return () => {
        if (signaturePad) {
          signaturePad.off();
        }
      };
    }, [
      value,
      tik,
      canvasRef,
      signaturePad,
      setSignaturePad,
      width,
      height,
      maxWidth,
      maxHeight,
      scale,
    ]);
    // trick to update canvasRef
    useEffect(() => {
      let timeout: any = setTimeout(() => {
        setTik(getRandomString());
      }, 10);
      return () => {
        if (timeout) {
          clearTimeout(timeout);
          timeout = undefined;
        }
      };
    }, []);
    //render
    return (
      <Dialog open={true} onClose={onClose} className={style.dialog}>
        <DialogTitle disableTypography className={style.title}>
          {t('signature')}
          <IconButton className={style.close} onClick={onClose}>
            <CloseIcon color={'inherit'} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={style.canvas}>
            <canvas ref={canvasRef} />
          </div>
          <ButtonGroup color="secondary" variant="contained" className={style.controls}>
            <Button startIcon={<UndoIcon />} onClick={onUndo}>
              {t('undo')}
            </Button>
            <Button startIcon={<DeleteIcon />} onClick={onClear}>
              {t('clear')}
            </Button>
          </ButtonGroup>
        </DialogContent>
        <DialogActions>
          <div className="form-edit-controls">
            <div />
            <div className="right-side">
              <Button onClick={onClose} color="secondary">
                {t('cancel')}
              </Button>
              <Button onClick={onSubmit} variant="contained" color="primary">
                {t('save')}
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    );
  },
);

export default Signature;
