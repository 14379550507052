import { apiRtk } from 'utils/rtk-query';
import { LeadActivityType, API_LEAD_ACTIVITY_TYPES } from './models';
import { DynamicService } from 'utils/service';
import { mergeFilters } from 'utils/dynamic-helpers';

export * from './models';

class Service extends DynamicService<LeadActivityType> {}

export const ServiceLeadActivityTypes = new Service({
  mainField: 'id',
  getAll: API_LEAD_ACTIVITY_TYPES.GET_ALL_DYNAMIC,
  post: API_LEAD_ACTIVITY_TYPES.POST,
  patch: API_LEAD_ACTIVITY_TYPES.PATCH,
  delete: API_LEAD_ACTIVITY_TYPES.DELETE,
});

export const apiLeadActivityTypes = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getLeadActivityTypeNewPatient: builder.query<LeadActivityType, void>({
      queryFn: async () => {
        try {
          const response = await ServiceLeadActivityTypes.getAllDynamic({
            filter: mergeFilters('isActive==true', 'isNewPatient==true').join('&&'),
          });

          const { data } = DynamicService.transformResponseToItem(response);

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
