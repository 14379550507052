import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { ToDoTaskCreateInput, ToDoTaskUpdateInput } from 'services/todo-tasks';
import { Controller, useFormContext } from 'react-hook-form';
import { useFieldProps } from 'hooks';
import { validateRule } from 'AurionCR/components/form';
import { useTodoCategoryOptions } from 'components/hooks';
import { SelectPatients } from 'components/select-patients';
import { composeFunctions } from 'utils';
import { Left } from 'utils/types';
import { AppSelect } from 'components/app-select';
import { AppInput } from 'components/app-input';
import { SelectEmployee } from 'components/select-employee';
import { AppDateTimePicker } from 'components/app-date-time-picker';
import { useTranslate } from 'hooks/use-translate';
import { validateTaskEmployees } from '../../helpers';
import renderColoredIconOption from 'components/select-render-components/render-colored-icon-option';

type Model = ToDoTaskCreateInput | ToDoTaskUpdateInput;

interface Props {
  isLoading: boolean;
}

type FormModel = Left<Model>;
export const Form: React.FC<Props> = ({ isLoading }) => {
  const { t } = useTranslate();
  const { errors, control, setValue, getValues } = useFormContext<FormModel>();

  const getProps = useFieldProps({ errors, disabled: isLoading });

  const sourcedTodoCategories = useTodoCategoryOptions();
  const mapCategories = sourcedTodoCategories.map;

  const onSetDefaultValues = useCallback(async () => {
    const toDoTaskCategoryID = getValues('toDoTaskCategoryID');

    if (!toDoTaskCategoryID) return;

    const category = mapCategories[toDoTaskCategoryID];

    if (!category) return;

    const defaultValues = Array.from(new Set(category.userEmployeeProfileIDs).keys());
    setValue('userEmployeeProfileIDs', defaultValues.filter(Boolean));
  }, [getValues, mapCategories, setValue]);

  const onChangeCategory = useCallback(
    async (value: string, _option: any) => {
      const option = mapCategories[value];

      if (!option) return;

      if (option.defaultMessage) {
        setValue('message', option.defaultMessage);
      }
    },
    [mapCategories, setValue],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={'toDoTaskCategoryID'}
          rules={validateRule('required')}
          render={(renderProps) => {
            const props = getProps(renderProps);
            return (
              <AppSelect
                {...props}
                renderOption={renderColoredIconOption}
                options={sourcedTodoCategories.options}
                loading={sourcedTodoCategories.loading}
                onChange={composeFunctions(props.onChange, onChangeCategory, onSetDefaultValues)}
                disableClearable
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={'userPatientProfileID'}
          rules={validateRule('required')}
          render={(renderProps) => {
            const props = getProps(renderProps);
            return <SelectPatients {...props} disableClearable />;
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={'entryDate'}
          rules={validateRule('required')}
          render={(renderProps) => (
            <AppDateTimePicker {...getProps(renderProps)} label={t('execution-date')} />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={'message'}
          rules={validateRule('required')}
          render={(renderProps) => (
            <AppInput {...getProps(renderProps)} multiline minRows={3} disableClearable />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={'userEmployeeProfileIDs'}
          rules={{
            validate: validateTaskEmployees,
          }}
          render={(renderProps) => <SelectEmployee {...getProps(renderProps)} multiple />}
        />
      </Grid>
    </Grid>
  );
};
