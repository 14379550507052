import React, { memo, useEffect } from 'react';
import { useEffectError } from 'hooks';
import { GridOnTasks } from 'models/grid';
import { apiClinicalMeetings } from 'services/clinical-meetings';
import { useTranslate } from 'hooks/use-translate';

const useUpdate = apiClinicalMeetings.useUpdateClinicalMeetingWithLogMutation;

interface Row extends Pick<Components.Schemas.ClinicalMeeting, 'id' | 'approveMeeting'> {}

interface Props {
  row: Row;
  onClose: () => void;
  onTasks: GridOnTasks;
}
export const EditorClinicalMeetingApprove: React.FC<Props> = memo(({ row, onClose, onTasks }) => {
  const [update, resultUpdate] = useUpdate();
  useEffectError(resultUpdate.error);
  const { tp } = useTranslate();

  useEffect(() => {
    const run = async () => {
      onTasks([['loading', true]]);
      onClose();
      await update({
        initData: { id: String(row.id), approveMeeting: !!row.approveMeeting },
        formData: {
          id: String(row.id),
          approveMeeting: !row.approveMeeting,
        },
        remark: tp('updates-by-employee'),
        remarkForPatientCallStatusID: undefined,
      });
      onTasks([['getData']]);
    };

    run();

    //eslint-disable-next-line
  }, [update, row, tp]);

  return null;
});
