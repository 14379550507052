import React, { memo, ReactNode } from 'react';
import { IDataGridMadel, IDataGridRowProps } from '../model';
import { GridRow } from './row';

export interface GridBodyProps {
  model: IDataGridMadel;
  data: any[];
  rowControls?: ReactNode;
  preview?: boolean;
  pagination: any;
  setSwap: (p: { startIndex?: number | null; endIndex?: number | null }) => void;
  requestSwap: (p: { startIndex: number; endIndex: number }) => void;
  isHideSwap: boolean;
  rowProps?: IDataGridRowProps;
}

const Body = memo<GridBodyProps>((props) => {
  const { model, data = [], ...rest } = props;
  if (!model) return null;
  return (
    <tbody>
      {data.map((item, index) => {
        const key = item[model.store.mainField];
        return <GridRow {...rest} key={key} uKey={key} index={index} item={item} model={model} />;
      })}
    </tbody>
  );
});

export default memo(Body);
