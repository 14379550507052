import { DynamicService } from 'utils/service';

import {
  API_USER_PATIENT_PROFILE_SESSIONS_PAST_DRUG_FOR_OBESITIES,
  CreateBulkUserPatientProfileSessionPastDrugForObesityInput,
  UserPatientProfileSessionPastDrugForObesity,
} from './models';

export * from './models';

class Service extends DynamicService<UserPatientProfileSessionPastDrugForObesity> {
  createBulk = (input: CreateBulkUserPatientProfileSessionPastDrugForObesityInput) => {
    return this.engine.post(API_USER_PATIENT_PROFILE_SESSIONS_PAST_DRUG_FOR_OBESITIES.POST_BULK, {
      mainID: input.userPatientProfileSessionID,
      itemIDs: input.pastDrugIDs,
    });
  };
}

export const ServiceUserPatientProfileSessionsPastDrugForObesities = new Service({
  mainField: 'id',
  getAll: API_USER_PATIENT_PROFILE_SESSIONS_PAST_DRUG_FOR_OBESITIES.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_SESSIONS_PAST_DRUG_FOR_OBESITIES.POST,
  delete: API_USER_PATIENT_PROFILE_SESSIONS_PAST_DRUG_FOR_OBESITIES.DELETE,
  patch: API_USER_PATIENT_PROFILE_SESSIONS_PAST_DRUG_FOR_OBESITIES.PATCH,
});
