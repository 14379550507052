import { useCallback, useEffect } from 'react';
import { useForm, FieldValues, UnpackNestedValue, UseFormOptions } from 'react-hook-form';
import { isEqual, pick } from 'lodash-es';

const isTheSame = (currentValues: any, defaultValues: any) => {
  const currentKeys = Object.keys(currentValues);

  return isEqual(currentValues, pick(defaultValues, currentKeys));
};

interface Props<TFieldValues extends FieldValues = FieldValues, TContext extends object = {}>
  extends UseFormOptions<TFieldValues, TContext> {
  onUpdate?: (value: UnpackNestedValue<TFieldValues>) => void;
}
export const useFilterForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = {},
>(
  props: Props<TFieldValues, TContext>,
) => {
  const result = useForm(props);

  const { defaultValues, onUpdate } = props;

  const { reset, getValues, trigger } = result;

  useEffect(() => {
    const currentValues = getValues();
    if (!isTheSame(currentValues, defaultValues)) {
      reset(defaultValues);
    }
  }, [defaultValues, reset, getValues]);

  const onCheckAndUpdate = useCallback(async () => {
    const currentValues = getValues();

    const isValid = await trigger();

    if (!isValid) return;

    if (!isTheSame(currentValues, defaultValues)) {
      onUpdate && onUpdate(currentValues);
    }
  }, [getValues, defaultValues, onUpdate, trigger]);

  return { ...result, onCheckAndUpdate };
};
