import { Typography } from '@material-ui/core';
import React from 'react';
import { Stack } from 'components/stack';
import { ColoredOptionIcon } from '../helpers';

const renderColoredIconOption = ({ title, Icon, color }: ColoredOptionIcon) => {
  return (
    <Stack spacing={1}>
      <Icon style={{ color: color ?? undefined }} />
      <Typography style={{ color: color ?? undefined }}>{title}</Typography>
    </Stack>
  );
};

export default renderColoredIconOption;
