import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useTranslate } from 'hooks/use-translate';
import { SectionPaper } from '../../../section-papper';
import { apiFormDocumentCategories } from 'services/form-document-categories';
import { useEffectError } from 'hooks';
import style from './index.module.scss';
import { CategoryItem } from './components';
import { Loading } from 'AurionCR/components';
import { List } from '@material-ui/core';
import { NativeScroll } from 'components/native-scroll';
import { apiUserPatientProfileDocuments } from 'services/user-patient-profile-documents';
import { apiPatientFormDocuments } from 'services/user-patient-profile-form-documents';

const ICON_DOCUMENT = process.env.PUBLIC_URL + '/images/icon-no-signed-documents-categories.png';

interface Props {
  prescriptionID: string;
  userPatientProfileID: string | null | undefined;
  userPatientProfileSubscriptionID: string | null | undefined;
  className: string;
}

export const SignedDocumentsCategories: React.FC<Props> = ({
  prescriptionID,
  userPatientProfileID,
  userPatientProfileSubscriptionID,
  className,
}) => {
  const { t } = useTranslate();
  const resultCategories = apiFormDocumentCategories.useGetFormDocumentCategoriesTabSignedQuery();
  useEffectError(resultCategories.error);

  const resultSignedDocs =
    apiPatientFormDocuments.useGetUserPatientProfileDocumentsAllAlreadySignedQuery(
      userPatientProfileID || '',
      { skip: !userPatientProfileID },
    );
  useEffectError(resultSignedDocs.error);

  const signedDocs = useMemo(() => {
    // exclude current prescription
    return (resultSignedDocs.data || []).filter(
      (doc) => doc.userPatientProfilePrescriptionID !== prescriptionID,
    );
  }, [resultSignedDocs.data, prescriptionID]);

  const resultFromOldSystem =
    apiUserPatientProfileDocuments.useGetUserPatientProfileDocumentsFromOldSystemQuery(
      userPatientProfileID || '',
      {
        skip: !userPatientProfileID,
      },
    );
  useEffectError(resultFromOldSystem.error);
  const oldDocuments = useMemo(() => {
    return (resultFromOldSystem.data || []).map((doc) => ({
      title: doc.documentType.title || 'unknown',
      date: doc.createdDate || '',
      url: doc.documentURL || '',
    }));
  }, [resultFromOldSystem.data]);

  const categories = useMemo(() => {
    return (resultCategories.data || []).map((category) => ({
      id: category.id,
      title: String(category.title),
      icon: category.icon,
      color: category.color,
      useForContract: category.useForContract,
      documents: signedDocs
        .filter((doc) => doc.formDocument?.formDocumentCategoryID === category.id)
        .map((doc) => ({
          title: doc.formDocument?.title || 'unknown',
          date: doc.signedDate || '',
          url: doc.formDocumentPath || '',
        })),
    }));
  }, [resultCategories.data, signedDocs]);

  const isLoading = resultCategories.isLoading || resultSignedDocs.isLoading;

  return (
    <SectionPaper className={clsx(style.root, className)} title={t('signed-documents-categories')}>
      <div className={style.scroll}>
        <NativeScroll>
          <List
            component={'div'}
            style={{
              padding: 0,
            }}
          >
            {categories.map((category) => {
              return (
                <CategoryItem
                  key={category.id}
                  categoryID={category.id}
                  userPatientProfileID={userPatientProfileID || ''}
                  userPatientProfilePrescriptionID={prescriptionID}
                  userPatientProfileSubscriptionID={userPatientProfileSubscriptionID ?? undefined}
                  shouldAttachPatientSubscription={!!category.useForContract}
                  title={String(category.title)}
                  icon={category.icon}
                  color={category.color}
                  items={category.documents}
                />
              );
            })}
            <CategoryItem
              categoryID={null}
              userPatientProfileID={userPatientProfileID || ''}
              userPatientProfilePrescriptionID={prescriptionID}
              title={t('documents-from-rapid')}
              icon={ICON_DOCUMENT}
              color={'#666666'}
              items={oldDocuments}
              shouldAttachPatientSubscription={false}
            />
          </List>
        </NativeScroll>
      </div>
      {isLoading && <Loading />}
    </SectionPaper>
  );
};
