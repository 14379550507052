import { API_VOIPE_SERVICE, TelecomCallLeadInput, TelecomCallPatientInput } from './models';
import { api } from 'utils/service';
import { apiRtk } from 'utils/rtk-query';

export * from './models';

class Service {
  async callPatient(input: TelecomCallPatientInput) {
    try {
      await api.get(API_VOIPE_SERVICE.CALL_PATIENT, {
        params: {
          userPatientProfileID: input.userPatientProfileID,
        },
      });
    } catch (e) {
      throw new Error('telecom-not-available');
    }
  }
  async callLead(input: TelecomCallLeadInput) {
    try {
      await api.get(API_VOIPE_SERVICE.CALL_LEAD, {
        params: {
          phoneNumber: input.phoneNumber,
        },
      });
    } catch (e) {
      throw new Error('telecom-not-available');
    }
  }
}
export const ServiceVoipe = new Service();

export const apiVoipe = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    voipeCallToPatient: builder.mutation<void, TelecomCallPatientInput>({
      queryFn: async (input) => {
        try {
          await ServiceVoipe.callPatient(input);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
