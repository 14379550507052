import React from 'react';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { API_USER_EMPLOYEE_PROFILES } from 'services/user-employee-profiles';
import { API_TODO_TASK_ACTIVITIES, ToDoTaskActivity } from 'services/todo-task-activities';
import { TemplateLog } from 'components/templates/template-log';

type GridModel = Pick<ToDoTaskActivity, 'changes'>;

const mapLabel = {
  entryDate: 'execution-date',
  userEmployeeProfileIDs: 'assigned-to',
};

interface Options {
  toDoTaskID: string;
  disabled?: boolean;
}
export const GridConfig = ({ toDoTaskID, disabled = false }: Options): any => ({
  id: 'grind_todo-task-activities',
  store: {
    get: {
      url: API_TODO_TASK_ACTIVITIES.GET_ALL_DYNAMIC,
      filters: `toDoTaskID=="${toDoTaskID}"`,
    },
    create: {
      url: API_TODO_TASK_ACTIVITIES.POST,
    },
    patch: {
      url: API_TODO_TASK_ACTIVITIES.PATCH,
    },
    delete: {
      url: API_TODO_TASK_ACTIVITIES.DELETE,
      field: 'remarks',
    },
    sortable: {
      field: 'entryDate',
      order: 'desc',
    },
  },
  controlsDropDown: !disabled ? ['delete'] : null,
  toolbar: {
    controlsDefault: { refresh: null, new: !disabled ? { title: 'add-new' } : null },
    controls: { new: !disabled ? { title: 'add-new' } : null },
    filters: [
      {
        field: 'entryDate',
        options: {
          type: 'date-multiple',
          placeholder: 'select-date',
          style: { minWidth: '21rem' },
        },
      },
      {
        field: 'userEmployeeProfileID',
        options: {
          type: 'select',
          label: 'employee',
          source: `${API_USER_EMPLOYEE_PROFILES.GET_ALL_DYNAMIC}?Select=appIdentityUserID as id,fullName as title&orderBy=firstName,lastName`,
          style: { minWidth: '16rem' },
        },
      },
    ],
  },
  columns: [
    {
      title: 'entry-date',
      field: 'entryDate',
      template: {
        type: 'date',
        format: APP_FORMAT_DATE_TIME,
      },
    },
    {
      title: 'employee',
      field: 'userEmployeeProfile.fullName as userEmployeeProfileFullName',
      sortable: 'userEmployeeProfile.firstName',
    },
    {
      title: 'remarks',
      field: 'remarks',
    },
    {
      title: 'changes',
      field: 'changes',
      template: (row: GridModel) => <TemplateLog value={row.changes} mapLabels={mapLabel} />,
    },
  ],
  pagination: {
    size: 50,
  },
});
