import React from 'react';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';
import { Box, Button, Paper, Typography } from '@material-ui/core';
import LockPersonIcon from 'components/svg-icons/lock-person';
import { APP_ROUTES } from 'configs/routes';
import { Link } from 'react-router-dom';

const Screen: React.FC = () => {
  const { t } = useTranslate();
  return (
    <div className={style.root}>
      <Paper variant={'elevation'} elevation={6} className={style.card}>
        <LockPersonIcon fontSize={'inherit'} className={style.icon} />
        <Typography variant={'h1'} component={'h3'} style={{ marginBottom: '1rem' }}>
          {t('not-enough-permissions-title')}
        </Typography>
        <Box width={'100%'} textAlign={'right'}>
          <Typography variant={'subtitle2'} color={'secondary'}>
            {t('not-enough-permissions-description')}
          </Typography>
        </Box>
      </Paper>
      <Button
        component={Link}
        fullWidth
        className={style.button}
        variant={'contained'}
        color={'primary'}
        to={APP_ROUTES.DASHBOARD().config}
      >
        {t('dashboard')}
      </Button>
    </div>
  );
};

interface Props {
  isSafe: boolean;
  children: React.ReactNode;
}

export const PermissionProtectorScreen: React.FC<Props> = ({ children, isSafe }) => {
  return isSafe ? <>{children}</> : <Screen />;
};
