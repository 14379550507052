import React from 'react';
import { useOpen } from 'AurionCR/components/hooks';
import { Button, ButtonProps } from '@material-ui/core';
import { DialogEndTreatment } from '../dialog-end-treatment';
import { useTranslate } from 'hooks/use-translate';
import { apiUserPatientProfile } from '../../../../../../services/user-patient-profile';
const useFetchPatient = apiUserPatientProfile.useGetPatientForGeneralFormQuery;

interface Props extends ButtonProps {
  userPatientProfileID: string;
  userPatientProfileSubscriptionID: string;
  startDate: string;
  endDate: string;
  onDone: () => void;
}

export const ButtonTreatmentOther: React.FC<Props> = ({
  userPatientProfileSubscriptionID,
  userPatientProfileID,
  startDate,
  endDate,
  onDone,
  ...rest
}) => {
  const { data: patient, isFetching } = useFetchPatient(userPatientProfileID);

  const doNotAllowEndOfTreatmentLetter = Boolean(patient?.doNotAllowEndOfTreatmentLetter);
  const { isOpen, handleOpen, handleClose } = useOpen();
  const { t } = useTranslate();

  return (
    <>
      <Button
        color={'primary'}
        variant={'contained'}
        {...rest}
        disabled={rest.disabled || doNotAllowEndOfTreatmentLetter || isFetching}
        onClick={handleOpen}
      >
        {t('end-treatment')}
      </Button>
      {isOpen && (
        <DialogEndTreatment
          userPatientProfileSubscriptionID={userPatientProfileSubscriptionID}
          userPatientProfileID={userPatientProfileID}
          startDate={String(startDate)}
          endDate={String(endDate)}
          onClose={handleClose}
          onDone={onDone}
        />
      )}
    </>
  );
};
