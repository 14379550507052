import React, { memo, useCallback } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useI18n } from 'AurionCR/components';
import { iFormInputField } from './index';
import style from './index.module.scss';
import clsx from 'clsx';

export const FieldIsRequired = memo(
  ({ value, onChange: OnChange }: iFormInputField<'isRequired'>) => {
    const { t } = useI18n();
    // handlers
    const onChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        OnChange({ isRequired: e.target.checked });
      },
      [OnChange],
    );
    //render
    return (
      <div className={clsx(style.labelInput, style.fieldIsRequired)}>
        <FormControlLabel
          className={style.checkBox}
          value={value}
          control={<Checkbox color="primary" checked={value} onChange={onChange} />}
          label={`${t('is-required')}:`}
          labelPlacement="start"
          disabled={true}
        />
      </div>
    );
  },
);

export default FieldIsRequired;
