import React, { memo, useCallback, useMemo, useState } from 'react';
import { ButtonBase, TextField } from '@material-ui/core';
import { getRandomString, useI18n } from 'AurionCR/components';
import { iFormInputField } from './index';
import style from './index.module.scss';
import clsx from 'clsx';
import { PdfFormDocumentInput } from 'components/pdf-document-constructor/store';
import Signature from 'components/pdf-document-constructor/components/signature';
import { FormDocumentFieldType } from 'services/form-document-inputs';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export const TestValue = memo(
  ({
    value,
    onChange: OnChange,
    width,
    height,
    formDocumentFieldType,
  }: iFormInputField<'_value'> & {
    width: PdfFormDocumentInput['width'];
    height: PdfFormDocumentInput['height'];
    formDocumentFieldType: PdfFormDocumentInput['formDocumentFieldType'];
  }) => {
    const { t } = useI18n();
    // state
    const [fieldID] = useState(`_value_${getRandomString()}`);
    // computed
    const { showText, showSignature, showCheckBox } = useMemo(() => {
      const result = { showText: false, showSignature: false, showCheckBox: false };
      if (formDocumentFieldType === FormDocumentFieldType.checkbox) {
        result.showCheckBox = true;
      } else if (formDocumentFieldType === FormDocumentFieldType.signature) {
        result.showSignature = true;
      } else {
        result.showText = true;
      }
      return result;
    }, [formDocumentFieldType]);
    // handlers
    const onChangeText = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        OnChange({ _value: e.target.value });
      },
      [OnChange],
    );
    const onChangeSignature = useCallback(
      (value: string) => {
        OnChange({ _value: value });
      },
      [OnChange],
    );
    const handleChangeCheckBox = useCallback(() => {
      OnChange({ _value: !value });
    }, [OnChange, value]);
    //render
    return (
      <div className={clsx(style.labelInput, style.testValue)}>
        <label htmlFor={fieldID} className={style.label}>
          {t('test-value')}:
        </label>
        {showText && (
          <TextField
            id={fieldID}
            className={style.input}
            size={'small'}
            variant="outlined"
            value={value}
            onChange={onChangeText}
          />
        )}
        {showSignature && (
          <Signature
            width={width}
            height={height}
            maxWidthBtn={200}
            maxHeightBtn={200}
            value={value as string}
            onChange={onChangeSignature}
          />
        )}
        {showCheckBox && (
          <ButtonBase onClick={handleChangeCheckBox}>
            {value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          </ButtonBase>
        )}
      </div>
    );
  },
);

export default TestValue;
