import { NativeScroll } from 'components/native-scroll';
import { Box } from '@material-ui/core';
import React, { forwardRef, useCallback, useImperativeHandle, useMemo } from 'react';
import { apiUserPatientProfileSessions } from 'services/user-patient-profile-session';
import { useEffectError } from 'hooks';
import { ServiceUserPatientProfileSessionItems } from 'services/user-patient-profile-session-item';
import { NotebookFieldInput } from 'services/notebook-field-input';
import { useNotebookForm, NotebookDynamicSubmitModel, useNotebookSections } from '../../hooks';
import { FormProvider } from 'react-hook-form';
import { Field } from '../general-fields';
import { GridClinicalDrugs } from '../grid-clinical-drugs';
import { Stack } from 'components/stack';
import { Skeleton } from '@material-ui/lab';
import { FormEffects } from '../from-effects';
import { NotebookFormSettings } from '../../models';
import { FormPanelLayout } from '../form-panel-layout';

interface MainSkeletonProps {
  count: number;
}
const MainSkeleton: React.FC<MainSkeletonProps> = ({ count }) => {
  const emptyList = useMemo(() => new Array(count).fill(null), [count]);
  return (
    <Stack spacing={1} direction={'column'}>
      {emptyList.map((_, i) => {
        return <Skeleton key={i} width={'100%'} height={'36px'} />;
      })}
    </Stack>
  );
};
export type NotebookFormPanelHandler = {
  submit: () => void;
  validateAndGet: () => Promise<NotebookDynamicSubmitModel>;
};

interface Props {
  showOnlyWhenReady: boolean;
  userPatientProfileID: string;
  userPatientProfileSessionID: string | null | undefined;
  notebookSettings: NotebookFormSettings | undefined;
  notebookFieldInputs: NotebookFieldInput[];
  onSubmit: (data: NotebookDynamicSubmitModel) => void;
  isLoading: boolean;
  isShowCloneTooltip: boolean;
  onCopyClinicalDrugsFromPrevious?: () => void;
  onTryCreate?: () => void;
}
export const NotebookFormPanel = forwardRef<NotebookFormPanelHandler, Props>(
  (
    {
      isLoading,
      showOnlyWhenReady,
      userPatientProfileID,
      userPatientProfileSessionID,
      notebookFieldInputs,
      notebookSettings,
      onCopyClinicalDrugsFromPrevious,
      onSubmit,
      isShowCloneTooltip,
      onTryCreate,
    },
    ref,
  ) => {
    const isNew = !userPatientProfileSessionID;
    const { data, error, isFetching, isSuccess } =
      apiUserPatientProfileSessions.useGetSessionForNotebookGeneratorQuery(
        userPatientProfileSessionID || '',
        {
          skip: isNew,
        },
      );
    useEffectError(error);

    const fieldInputs = useMemo(() => {
      const sessionItems = data?.userPatientProfileSessionItems || [];
      return notebookFieldInputs.map((notebookInput) => {
        const sessionItem = sessionItems.find(
          (ses) => notebookInput.fieldInputID === ses.fieldInputID,
        );
        const formValue = ServiceUserPatientProfileSessionItems.parseInternalSystemValue(
          sessionItem?.internalSystemValue,
        );
        return {
          ...notebookInput,
          formValue: formValue,
          formID: sessionItem?.id || '',
        };
      });
    }, [data, notebookFieldInputs]);

    const initData = useMemo(() => {
      return {
        fieldInputs,
        icd10IDs: data?.icd10IDs || [],
        sideEffectIDs: data?.sideEffectIDs || [],
        surgeryIDs: data?.surgeryIDs || [],
        sensitivityIDs: data?.sensitivityIDs || [],
        sensitivityDrugIDs: data?.sensitivityDrugIDs || [],
        regularDrugIDs: data?.regularDrugIDs || [],
        pastDrugIDs: data?.pastDrugIDs || [],

        icd10Remarks: data?.icd10Remarks || '',
        sideEffectRemarks: data?.sideEffectRemarks || '',
        surgeryRemarks: data?.surgeryRemarks || '',
        sensitivityRemarks: data?.sensitivityRemarks || '',
        sensitivityDrugRemarks: data?.sensitivityDrugRemarks || '',
        routineDrugRemarks: data?.routineDrugRemarks || '',
        pastDrugForObesityRemarks: data?.pastDrugForObesityRemarks || '',
      };
    }, [fieldInputs, data]);

    const { methods, refs, onSubmitForm, prepareFields } = useNotebookForm({
      onSubmit,
      initData,
    });
    const { handleSubmit } = methods;

    const { isShowEffect, isShowClinicalDrugs } = useNotebookSections(notebookSettings);

    const submit = handleSubmit(onSubmitForm);
    const validateAndGet = useCallback(() => {
      return new Promise<NotebookDynamicSubmitModel>((resolve, reject) => {
        handleSubmit((data) => {
          const res = prepareFields(data);
          resolve(res);
        }, reject)();
      });
    }, [handleSubmit, prepareFields]);

    useImperativeHandle(
      ref,
      () => ({
        submit,
        validateAndGet,
      }),
      [submit, validateAndGet],
    );

    const disabled = isFetching || isLoading;

    const Fields = fieldInputs.map((item, i) => (
      <Field key={item.fieldInputID} ref={refs[i]} item={item} disabled={disabled} />
    ));

    const renderMain = () => {
      if (showOnlyWhenReady && !isSuccess) {
        return <MainSkeleton count={fieldInputs.length} />;
      }
      if (fieldInputs.length) {
        return Fields;
      }

      return <MainSkeleton count={fieldInputs.length} />;
    };

    return (
      <FormProvider {...methods}>
        <FormPanelLayout
          slots={{
            left: (
              <NativeScroll>
                <Box position={'absolute'} top={0} left={0} bottom={0} right={0} pr={'2px'}>
                  {renderMain()}
                </Box>
              </NativeScroll>
            ),
            right: isShowEffect && notebookSettings && (
              <NativeScroll>
                <FormEffects notebookSettings={notebookSettings} disabled={disabled} />
              </NativeScroll>
            ),
            bottom: isShowClinicalDrugs && (
              <GridClinicalDrugs
                isLoading={isLoading}
                userPatientProfileID={userPatientProfileID}
                userPatientProfileSessionID={userPatientProfileSessionID ?? null}
                onCopyFromPrevious={onCopyClinicalDrugsFromPrevious}
                onTryCreate={onTryCreate}
                isShowCloneTooltip={isShowCloneTooltip}
              />
            ),
          }}
        />
      </FormProvider>
    );
  },
);
