import { IMask, useIMask } from 'react-imask';
import React, { useCallback, useEffect } from 'react';
import { AppInputProps } from 'components/app-input';
import { AppInputLtr } from '../app-input-ltr';

export type TMaskOptions = IMask.AnyMaskedOptions;
export type AppInputMaskedProps = Omit<AppInputProps, 'onChange'> & {
  onChange?: (value: string) => void;
  onComplete?: (value: string) => void;
  MaskOptions: TMaskOptions;
};

export const AppInputMask = ({
  MaskOptions,
  onComplete,
  onChange,
  ...rest
}: AppInputMaskedProps) => {
  const inputValue = rest.value;

  const onAccept = useCallback(
    (value: string) => {
      if (inputValue === value) return;
      onChange && onChange(value);
    },
    [onChange, inputValue],
  );

  const { ref, maskRef } = useIMask(MaskOptions, {
    onAccept,
    onComplete: onComplete,
  });

  useEffect(() => {
    const mask = maskRef.current;
    if (!mask) return;
    if (inputValue === mask.value) return;

    mask.value = String(inputValue || '');
  }, [inputValue, maskRef]);

  return <AppInputLtr {...rest} inputRef={ref} />;
};
