import * as yup from 'yup';
import { ServicePharmacyDrugQuantities } from './service';
import { AnyObject } from 'yup';

interface Context extends AnyObject {
  id: string | undefined;
  pharmacyDrugID: string;
}
export const schemaPharmacyDrugQuantity = yup.object<Context>().shape({
  dosageFormTotalDesc: yup
    .string<string, Context>()
    .nullable()
    .required('rule-required')
    .test('is-unique', 'rule-unique', async (value, context) => {
      const id = context.options.context?.id;
      const pharmacyDrugID = context.options.context?.pharmacyDrugID;

      if (!value) return Promise.resolve(true);

      const { data } = await ServicePharmacyDrugQuantities.validateDosageForm({
        id: id ?? null,
        pharmacyDrugID: pharmacyDrugID || '',
        dosageFormTotalDesc: value,
      });

      return Promise.resolve(data.isValid);
    })
    .default(''),
  quantity: yup.number().typeError('rule-number').integer('rule-integer').required('').default(0),
  pharmacyDrugID: yup.string().required('rule-required').default(''),
});
