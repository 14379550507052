import React from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { Button, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Stack } from 'components/stack';
import { useTranslate } from 'hooks/use-translate';

interface Classes {
  root: string;
}

interface Props {
  className?: string;
  classes?: Partial<Classes>;
}

export const NotFound: React.FC<Props> = ({ className, classes }) => {
  const { t } = useTranslate();
  const { goBack } = useHistory();

  return (
    <div className={clsx(style.root, classes?.root, className)}>
      <Stack spacing={2} direction={'column'} alignItems={'center'}>
        <Typography variant={'h1'}>{t('not-found')}</Typography>
        <Button color={'primary'} variant={'outlined'} onClick={goBack}>
          {t('go-back')}
        </Button>
      </Stack>
    </div>
  );
};
