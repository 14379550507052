import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import GridConfig from './grid-config';
import DataGrid from 'components/data-grid';
import { Box, Button } from '@material-ui/core';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PaymentDialog from 'components/payment-dialog';
import { patientEmitter } from '../../emitter';
import { GridMethods } from 'models/grid';
import { useTranslate } from 'hooks/use-translate';
import { useOpen } from 'AurionCR/components/hooks';
import { composeFunctions } from 'utils';

interface PaymentProps {
  userPatientProfileID: string;
  onRefresh: (e?: any) => void;
}

const Payment: React.FC<PaymentProps> = ({ userPatientProfileID, onRefresh }) => {
  const { t } = useTranslate();
  const { isOpen, handleOpen, handleClose } = useOpen();

  return (
    <>
      <Box padding={'0 1rem'}>
        <Button
          className="btn-pay"
          variant="contained"
          color="primary"
          startIcon={<AccountBalanceWalletIcon />}
          onClick={handleOpen}
        >
          {t('pay')}
        </Button>
      </Box>
      {isOpen && (
        <PaymentDialog
          userPatientProfileID={userPatientProfileID}
          onDone={composeFunctions(onRefresh, handleClose)}
          onCancel={handleClose}
        />
      )}
    </>
  );
};

interface Props {
  userPatientProfileID: string;
}
export const GridSubscriptionsPayments = memo<Props>(({ userPatientProfileID }) => {
  const grid = useRef<GridMethods>(null);
  const gridConfig = useMemo<any>(
    () => GridConfig(userPatientProfileID, grid),
    [userPatientProfileID],
  );

  const onRefresh = useCallback(() => {
    patientEmitter.emit('refresh-all', { patientID: userPatientProfileID });
    patientEmitter.emit('refresh-general-form', { patientID: userPatientProfileID });
  }, [userPatientProfileID]);

  const renderControlsSlot = useCallback(() => {
    return <Payment userPatientProfileID={userPatientProfileID} onRefresh={onRefresh} />;
  }, [onRefresh, userPatientProfileID]);

  useEffect(() => {
    const refresh = (payload: { patientID: string }) => {
      if (payload.patientID !== userPatientProfileID) {
        return;
      }
      grid.current?.onTasks([['getData']]);
    };

    patientEmitter.on('refresh-all', refresh);
    return () => {
      patientEmitter.off('refresh-all', refresh);
    };
  }, [userPatientProfileID, grid]);

  return <DataGrid ref={grid} config={gridConfig} renderControlsSlot={renderControlsSlot} />;
});
