import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { IconButton, Typography } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import DataGrid from 'components/data-grid';
import { GridConfigSupport } from './grid-config-support';

import { enumToArray } from 'utils/other';
import { ButtonSwitcher } from 'components/button-switcher';
import { Stack } from 'components/stack';
import { useFormGrid } from 'AurionCR/components/formV2';
import { useAppDispatch, useAppSelector } from 'store';
import {
  DialogCancelMeetingContainer,
  DialogSupportMeeting,
} from 'components/dialog-support-meeting';
import { selectTMTimes } from 'pages/TaskManager/components/store';
import { useTranslate } from 'hooks/use-translate';
import {
  CustomHandler,
  CustomHandlersSupport,
  getMeetingRowProps,
  PATIENT_MEETING_SWITCH,
} from '../../helpers';
import { DialogPreviewUserPatientProfileSession } from 'pages/TaskManager/components/particals';
import { actionSupportMeetingActivitiesCrateLogUpdated } from 'store/support-meeting-activities';
import { AddNewButton, EmptySlot } from '../empty-slot';
import style from './index.module.scss';
import { useOpenValue } from 'hooks';
import { patientEmitter } from '../../../../emitter';

interface Props {
  userPatientProfileID: string;
  defaultPeriod?: PATIENT_MEETING_SWITCH;
}

export const PatientGridSupportMeetings: React.FC<Props> = ({
  userPatientProfileID,
  defaultPeriod = PATIENT_MEETING_SWITCH.ALL,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslate();
  const grid = useFormGrid('meeting-support');
  const initData = useMemo(() => {
    return { userPatientProfileID };
  }, [userPatientProfileID]);

  const options = useMemo(() => {
    return enumToArray(PATIENT_MEETING_SWITCH).map((item) => ({
      ...item,
      value: item.id,
      title: t(item.id),
    }));
  }, [t]);

  const stateOpenCancel = useOpenValue<{ id: string; supportMeetingTypeID: string }>();
  const gridHandle = useCallback(
    async (_action: CustomHandlersSupport) => {
      let action = _action;

      if (action.type === CustomHandler.PATCH_START) {
        dispatch(
          actionSupportMeetingActivitiesCrateLogUpdated({
            oldData: action.data.row,
            newData: { ...action?.data?.value, id: action?.data.row.id },
          }),
        );
      }
      if (action.type === CustomHandler.CANCEL_MEETING) {
        return stateOpenCancel.onOpen({
          id: action.data.id,
          supportMeetingTypeID: action.data.supportMeetingTypeID,
        });
      }

      return grid.onGridHandle(action as any);
    },
    [grid, dispatch, stateOpenCancel],
  );

  const onRefresh = useCallback(() => {
    const onTasks = grid.grid.current ? grid.grid.current.onTasks : null;

    if (!onTasks) return;

    onTasks([['getData']]);
  }, [grid]);

  const timesSupport = useAppSelector(selectTMTimes);
  const [switcherSupport, setSwitcherSupport] = useState<PATIENT_MEETING_SWITCH>(defaultPeriod);
  const supportConfig = useMemo(() => {
    return GridConfigSupport({
      userPatientProfileID,
      period: switcherSupport,
      onEdit: grid.setEditItemID,
    }) as any;
  }, [userPatientProfileID, switcherSupport, grid.setEditItemID]);

  const onCreate = useCallback(() => {
    return grid.setEditItemID(true);
  }, [grid]);

  useEffect(() => {
    const refresh = () => {
      onRefresh();
    };

    patientEmitter.on('refresh-meetings', refresh);
    return () => {
      patientEmitter.off('refresh-meetings', refresh);
    };
  }, [onRefresh]);

  return (
    <div className={style.root}>
      <DataGrid
        ref={grid.grid}
        onHandle={gridHandle}
        rowProps={getMeetingRowProps}
        renderToolbarControlsSlot={() => (
          <div className={style.gridHeader}>
            <Typography variant={'h4'} component={'h3'} className={style.title}>
              {t('support-meetings')}
            </Typography>
            <Stack spacing={1}>
              <AddNewButton onClick={onCreate} />
              <ButtonSwitcher
                value={switcherSupport}
                onChange={setSwitcherSupport}
                options={options}
              />
              <IconButton onClick={onRefresh}>
                <RefreshIcon />
              </IconButton>
            </Stack>
          </div>
        )}
        EmptySlot={<EmptySlot onCreate={onCreate} />}
        config={supportConfig}
      />
      <DialogPreviewUserPatientProfileSession />
      {grid.editItemID && (
        <DialogSupportMeeting
          itemID={grid.editItemID}
          onClose={grid.onClose}
          times={timesSupport}
          initData={initData}
          onRefresh={onRefresh}
          onPreviewPatient={grid.onClose}
        />
      )}
      {stateOpenCancel.openValue && (
        <DialogCancelMeetingContainer
          userPatientProfileID={userPatientProfileID}
          supportMeetingTypeID={stateOpenCancel.openValue.supportMeetingTypeID}
          meetingID={stateOpenCancel.openValue.id}
          onClose={stateOpenCancel.onClose}
          onDone={onRefresh}
        />
      )}
    </div>
  );
};
