import { DataGridLightTable } from '../data-grid-table';
import { DataGridLightSettingsProvider } from '../provider-settings';
import {
  DataGridLightClasses,
  DataGridLightColumn,
  DataGridLightDefaultTasks,
  DataGridLightRowProps,
} from '../../models';
import React from 'react';
import { DataGridTasksProvider } from '../provider-tasks';

interface Props<T, Task extends DataGridLightDefaultTasks<T>> {
  className?: string;
  columns: DataGridLightColumn<T, any>[];
  rows: T[];
  rowKey?: keyof T;
  rowProps?: DataGridLightRowProps<T>;
  classes?: Partial<DataGridLightClasses>;
  onTask?: (task: Task) => void;
}

export const DataGridLight = <T, Task extends DataGridLightDefaultTasks<T>>({
  columns,
  rows,
  rowProps,
  rowKey,
  classes,
  onTask,
}: Props<T, Task>) => {
  return (
    <DataGridTasksProvider onTask={onTask}>
      <DataGridLightSettingsProvider classes={classes}>
        <DataGridLightTable columns={columns} rows={rows} rowKey={rowKey} rowProps={rowProps} />
      </DataGridLightSettingsProvider>
    </DataGridTasksProvider>
  );
};
