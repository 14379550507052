// @ts-ignore
import VAR from './_variabels.scss';
import { createTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const FontSize = '1.4rem';

const appBreakpoints = {
  values: {
    xs: Number(VAR.xs),
    sm: Number(VAR.sm),
    md: Number(VAR.md),
    lg: Number(VAR.lg),
    xl: Number(VAR.xl),
    xxl: Number(VAR.xxl),
  },
};

const breakpoints = createBreakpoints({ ...appBreakpoints });

const HStyle = {
  color: VAR.colorSecondary,
  // textTransform: 'uppercase' as const
  textTransform: 'capitalize' as const,
};

export default createTheme({
  breakpoints,
  typography: {
    fontSize: 14,
    htmlFontSize: 10,
    fontWeightRegular: 400,
    fontFamily: 'Arial, Geneva, Helvetica, sans-serif;',
    h1: {
      ...HStyle,
      fontSize: '2.4rem',
    },
    h2: {
      ...HStyle,
      fontSize: '2rem',
    },
    h3: {
      ...HStyle,
      fontSize: '1.8rem',
    },
    h4: {
      ...HStyle,
      fontSize: '1.6rem',
      fontWeight: 'bold',
    },
    h5: {
      ...HStyle,
      fontSize: '1.6rem',
      textTransform: 'capitalize',
    },
    h6: {
      ...HStyle,
      fontSize: '1.4rem',
      textTransform: 'capitalize',
    },
    body1: {
      fontSize: '1.4rem',
    },
    body2: {
      fontSize: '1.2rem',
    },
  },
  palette: {
    text: {
      primary: 'rgba(0, 0, 0, 0.87);',
    },
    primary: {
      main: VAR.colorPrimary,
      contrastText: '#fff',
    },
    secondary: {
      main: VAR.colorSecondary,
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiTabs: {
      indicator: {
        top: '0',
        bottom: 'auto',
        height: '3px',
      },
    },
    MuiTab: {
      root: {
        fontWeight: 'bold',
        textTransform: 'inherit',
        fontSize: '1.6rem',
        background: '#F6F8F8',
        padding: '0.5rem 2.5rem',
        lineHeight: '1.1',
        '&.Mui-selected': {
          background: '#fff',
        },
      },
      textColorPrimary: {
        color: VAR.colorGreyDark,
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderColor: VAR.colorBorder,
        borderWidth: 2,
      },
    },
    MuiButton: {
      root: {
        fontSize: '1.4rem',
        borderRadius: '0.4rem',
        textTransform: 'lowercase',
        lineHeight: 1.2,
        minHeight: '3.65rem',
      },
      sizeSmall: {
        padding: '0.4rem 1rem',
        lineHeight: '1.2',
        minWidth: '2rem',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '1.1rem',
        minHeight: 'auto',
      },
    },

    MuiMenuItem: {
      root: {
        minHeight: 'inherit',
      },
    },

    MuiIconButton: {
      root: {
        padding: '0.7rem',
      },
    },
    // GRID
    MuiGrid: {
      root: {
        '&.MuiGrid-spacing-xs-4': {
          width: 'auto',
          margin: '-10px',
        },
        '&.MuiGrid-spacing-xs-4 > .MuiGrid-item': {
          padding: '10px',
        },
      },
      item: {
        // width: '100%',
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
      },
      container: {
        marginTop: '0 !important',
        marginBottom: '0 !important',
      },
    },
    // DIALOG
    MuiDialogTitle: {
      root: {
        textTransform: 'none',
        padding: ' 1.5rem 2rem',
        backgroundColor: VAR.colorGreyLight,
        display: 'flex',
        [breakpoints.down('xs')]: {
          padding: ' 1rem 1.6rem',
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: '2rem',
        [breakpoints.down('xs')]: {
          padding: ' 1rem 1.6rem',
        },
      },
    },
    MuiDialog: {
      paper: {
        overflowY: 'inherit',
        borderRadius: '0.8rem',
        overflow: 'hidden',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '2rem',
        [breakpoints.down('xs')]: {
          padding: ' 1rem 1.6rem',
        },
      },
    },

    MuiFormControl: {
      root: {
        margin: '0 0 1.5rem',
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(0, .4rem)',
        fontSize: '1.2rem',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: FontSize,
        color: '#888D8F',
        '&$focused': {
          color: '#888D8F',
        },
      },
    },
    MuiInputAdornment: {
      positionStart: {
        color: VAR.colorBorder,
      },
    },
    MuiInput: {
      root: {
        fontSize: FontSize,
      },
      underline: {
        '&:before': {
          borderBottomColor: VAR.colorBorder,
        },
      },
    },
    MuiSnackbarContent: {
      message: {
        fontSize: FontSize,
      },
    },
    // @ts-ignore
    MuiPickerDTTabs: {
      tabs: {
        color: '#fff',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: VAR.colorSecondary,
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: VAR.colorSecondary,
      },
    },
    MuiPickersModal: {
      withAdditionalAction: {
        '& > .MuiButton-textPrimary': {
          color: VAR.colorSecondary,
        },
      },
    },
    // MuiSvgIcon: {
    //   root: {
    //     'html[dir=rtl] &': {
    //       transform: 'scaleX(-1)',
    //     },
    //   },
    // },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiButtonGroup: {
      disableElevation: true,
    },
  },
});
