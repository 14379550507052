import React from 'react';
import { TemplateBadge } from 'components/templates/template-badge';
import { useOpen } from 'AurionCR/components/hooks';
import { DialogToDoActivities } from '../dialog-activities';

type Model = {
  id: string;
  activitiesTotal: number;
};
interface Props {
  row: Model;
  onRefresh: () => void;
}
export const ColumnActivities: React.FC<Props> = ({ row, onRefresh }) => {
  const { isOpen, handleOpen, handleClose } = useOpen();
  return (
    <>
      <TemplateBadge count={row.activitiesTotal} onClick={handleOpen} />
      {isOpen && (
        <DialogToDoActivities toDoTaskID={row.id} onClose={handleClose} onRefresh={onRefresh} />
      )}
    </>
  );
};
