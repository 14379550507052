import {
  addDays,
  differenceInCalendarDays,
  differenceInYears,
  endOfDay,
  format,
  isValid,
  max,
  min,
  toDate as fnsToDate,
} from 'date-fns';
import { APP_FORMAT_DATE } from '../configs/const';

export type DateValue = Date | string | number | null | undefined;

export const dateFormat = (
  value: DateValue,
  _format: string = APP_FORMAT_DATE,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  },
) => {
  if (isDateLike(value)) {
    return format(convertToDate(value), _format, options);
  }
  return '--';
};

export const isServerDateString = <T>(value: T) => {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}?((\.\d+)|$)/gi.test(String(value));
};

export const isDateLike = (value: any): value is string | number | Date => {
  if (!value) return false;
  if (typeof value === 'string') {
    return isValid(new Date(value));
  }
  return isValid(fnsToDate(value));
};

export const convertToDate = (value: DateValue) => {
  if (!value) {
    return new Date();
  }
  if (typeof value === 'string') {
    return new Date(value);
  }
  return fnsToDate(value);
};

export const maxDate = (...args: DateValue[]) => {
  const values = args.filter(isDateLike).map(convertToDate);
  return max(values);
};
export const minDate = (...args: DateValue[]) => {
  const values = args.filter(isDateLike).map(convertToDate);
  return min(values);
};

export const getAge = (dateOfBirth: DateValue) => {
  return isDateLike(dateOfBirth) ? differenceInYears(new Date(), convertToDate(dateOfBirth)) : 0;
};
export const withFullName = <T extends { firstName?: string | null; lastName?: string | null }>(
  data: T,
) => {
  return { ...data, fullName: [data.firstName, data.lastName].filter(Boolean).join(' ') };
};
export const isTeenager = (dateOfBirth: DateValue) => {
  return getAge(dateOfBirth) < 18;
};

export const isPastDate = (value: DateValue) => {
  if (!isDateLike(value)) return false;
  const date = convertToDate(value);
  let now = new Date();

  const yesterday = endOfDay(addDays(now, -1));

  return yesterday.getTime() > date.getTime();
};
export const isFutureDate = (value: DateValue) => {
  if (!isDateLike(value)) return false;
  const date = convertToDate(value);
  let now = new Date();

  return now.getTime() < date.getTime();
};
export const calcDaysLeft = (from: DateValue, to: DateValue) => {
  const start = convertToDate(from);
  const end = convertToDate(to);

  return differenceInCalendarDays(end, start);
};

export const excelTemplateDate = (value: DateValue, _format = APP_FORMAT_DATE) =>
  isDateLike(value) ? dateFormat(value, _format) : '-';
