import { DynamicService } from 'utils/service';

import {
  API_USER_EMPLOYEE_PROFILE_PERMISSION_SMS_CONTENTS,
  UserEmployeeProfilePermissionSmsContentBulkInput,
} from './models';

export * from './models';

class Service extends DynamicService<Components.Schemas.UserEmployeeProfilePermissionSmsContent> {
  createBulk = async (data: UserEmployeeProfilePermissionSmsContentBulkInput) => {
    return this.engine.post(API_USER_EMPLOYEE_PROFILE_PERMISSION_SMS_CONTENTS.INSERT_BULK, {
      mainID: data.smsContentID,
      itemIDs: data.userEmployeeProfilePermissionIDs,
    });
  };
}

export const ServiceUserEmployeePermissionSmsContents = new Service({
  mainField: 'id',
  getAll: API_USER_EMPLOYEE_PROFILE_PERMISSION_SMS_CONTENTS.GET_ALL_DYNAMIC,
  post: API_USER_EMPLOYEE_PROFILE_PERMISSION_SMS_CONTENTS.POST,
  delete: API_USER_EMPLOYEE_PROFILE_PERMISSION_SMS_CONTENTS.DELETE,
  patch: API_USER_EMPLOYEE_PROFILE_PERMISSION_SMS_CONTENTS.PATCH,
});
