import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import {
  DeleteActivity,
  InformationSection,
  NotebookDialogTitle,
  NotebookFormPanel,
  NotebookFormPanelHandler,
  useTitle,
} from 'components/notebook-form-generator';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';
import { useEffectError, useFetchDynamic, useMountedRef } from 'hooks';
import { DialogHeading } from 'components/dialog-title';
import { DialogUserPatientProfileSessionProps } from '../../model';
import { useCurrentUser } from 'components/hooks';
import {
  apiUserPatientProfileSessions,
  ServiceUserPatientProfileSessions,
  UserPatientProfileSession,
} from 'services/user-patient-profile-session';
import { NotebookDynamicSubmitModel } from 'components/notebook-form-generator/hooks';
import { isMutationRejected } from 'utils/rtk-query';
import { PreviewSessionsBlock } from '../preview';
import { Panel, PanelGroup } from 'react-resizable-panels';
import { AppPanelResizeHandle } from 'components/app-panel-resize-handle';
import { Notebook } from 'services/notebook';
import { NotebookFieldInput } from 'services/notebook-field-input';
import { differenceInHours } from 'date-fns';
import { convertToDate, dateFormat } from 'utils/dates';
import { notebookEmitter } from 'components/notebook-form-generator/emitter';
import { usePermissions } from 'hooks/use-permissions';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { UserInfoWeightGrid } from '../../../user-info-weight-grid';
import { UserEmployeeProfile } from 'services/user-employee-profiles';

const MAX_HOURS_AVAILABLE_TO_EDIT = 24;

type State = 'preview' | 'edit';

interface Model
  extends Pick<
    UserPatientProfileSession,
    'id' | 'notebook' | 'notebookID' | 'entryDate' | 'userEmployeeProfileID'
  > {
  notebookID: string;
  labelKey: string;
  notebook: Pick<
    Required<Notebook>,
    | 'displayIcd10Section'
    | 'displaySurgerySection'
    | 'displaySensitivitySection'
    | 'displayRoutineDrugSection'
    | 'displayPastDrugForObesitySection'
    | 'displaySensitivityDrugSection'
    | 'displayClinicDrugSection'
    | 'displaySideEffectSection'
    | 'isForPatient'
    | 'isAllowToEditByAll'
  >;
  notebookFieldInputs: NotebookFieldInput[];
  userEmployeeProfile: Pick<UserEmployeeProfile, 'fullName'>;
}

interface Props extends DialogUserPatientProfileSessionProps {}

export const DialogUserPatientProfileSessionWrap = memo<Props>(
  ({ onClose, itemID, userPatientProfileID, onRefresh, isShowDelete = true }) => {
    const ref = useRef<NotebookFormPanelHandler>(null);
    const [state, setState] = useState<State>('preview');
    const { t } = useTranslate();
    const { appUserID } = useCurrentUser();

    const fetchSession = useCallback((id: string) => {
      return ServiceUserPatientProfileSessions.getDynamic<Model>(id, {
        select: [
          'id',
          'notebookID',
          'entryDate',
          'notebook.labelKey',
          'userEmployeeProfileID',
          'new { userEmployeeProfile.fullName } as userEmployeeProfile',
          'new { notebook.displayIcd10Section, notebook.displaySurgerySection, notebook.displaySensitivitySection, notebook.displayRoutineDrugSection, notebook.displayPastDrugForObesitySection, notebook.displayClinicDrugSection, notebook.isForPatient, notebook.displaySideEffectSection, notebook.displaySensitivityDrugSection, notebook.isAllowToEditByAll } as notebook',
          'notebook.notebookFieldInputs' +
            '.OrderBy(i=>i.rank)' +
            '.Where(i=>i.isActive==true)' +
            '.Select(i=>new { i.id,i.isRequired,i.rank,i.defaultValue,i.fieldInputID,i.fieldInput,i.fieldInput.inputType,i.fieldInput.inputType.inputTypeDataSource,i.fieldInput.inputType.inputTypeParameter }) as notebookFieldInputs',
        ].join(','),
      });
    }, []);

    const [triggerGet, { data, isLoading: isLoadingGet, error }] = useFetchDynamic(fetchSession);
    useEffectError(error);
    useEffect(() => {
      if (itemID) {
        triggerGet(itemID);
      }
    }, [state, itemID, triggerGet]);

    const notebookID = data ? data.notebookID : null;
    const notebookSettings = data ? data.notebook : undefined;
    const notebookFieldInputs = data ? data.notebookFieldInputs : [];

    const { isCanDelete } = useTitle(itemID);

    const mainTitle = useMemo(() => {
      return [
        data?.labelKey,
        data?.userEmployeeProfile.fullName,
        data ? dateFormat(data.entryDate, APP_FORMAT_DATE_TIME) : null,
      ]
        .filter(Boolean)
        .join(' | ');
    }, [data]);

    const onCloseDeleteDialog = useCallback(
      (bool) => {
        if (bool && onRefresh) onRefresh();
        onClose();
      },
      [onRefresh, onClose],
    );

    const onCloseWrapper = useCallback(() => {
      switch (state) {
        case 'edit': {
          return setState('preview');
        }
        case 'preview': {
          return onClose();
        }
      }
    }, [state, onClose]);

    const [postOrPatchItem, resultSession] =
      apiUserPatientProfileSessions.usePostOrPatchNotebookGeneratorMutation();
    useEffectError(resultSession.error);

    const [cloneFromPrevious, resultCloneFromPrevious] =
      apiUserPatientProfileSessions.useCloneClinicalDrugsFromPreviousNotebookMutation();
    useEffectError(resultCloneFromPrevious.error);

    const mountedRef = useMountedRef();

    const _onSubmit = useCallback(
      async (data: NotebookDynamicSubmitModel) => {
        const { fieldInputs, ...rest } = data;

        if (!notebookID) {
          return;
        }

        const sessionData = {
          notebookID,
          userPatientProfileID,
          userPatientProfileSessionID: itemID,
          userEmployeeProfileID: appUserID,
          ...rest,
          userPatientProfileSessionItems: fieldInputs,
        };

        const result = await postOrPatchItem(sessionData);

        if (!mountedRef.current) {
          return;
        }

        if (isMutationRejected(result)) return;

        onRefresh && onRefresh();
        setState('preview');
      },
      [postOrPatchItem, notebookID, userPatientProfileID, itemID, appUserID, mountedRef, onRefresh],
    );
    const isLoading = isLoadingGet || resultSession.isLoading;

    const isAllowToEditNotebooks = usePermissions('isAllowToEditNotebooks');
    const isAllowToEdit = useMemo(() => {
      if (!data) return false;
      if (data.notebook.isAllowToEditByAll) return true;

      if (!data.entryDate) return false;
      if (isAllowToEditNotebooks) return true;

      const diffInHours = differenceInHours(new Date(), convertToDate(data.entryDate));

      return diffInHours < MAX_HOURS_AVAILABLE_TO_EDIT && appUserID === data.userEmployeeProfileID;
    }, [data, isAllowToEditNotebooks, appUserID]);

    const onEdit = useCallback(() => {
      if (!isAllowToEdit) return;
      if (state === 'edit') {
        if (!ref.current) return;

        return ref.current.submit();
      }

      setState('edit');
    }, [state, isAllowToEdit]);

    const onCloneClinicalDrugs = useCallback(async () => {
      await cloneFromPrevious({ userPatientProfileID, userPatientProfileSessionID: itemID });
      notebookEmitter.emit('refresh-clinical-drugs');
    }, [cloneFromPrevious, userPatientProfileID, itemID]);

    const hasActions = isAllowToEdit || isShowDelete;

    return (
      <Dialog
        open={true}
        onClose={onCloseWrapper}
        fullWidth
        //@ts-ignore
        maxWidth={'xxl'}
        classes={{
          paper: style.paper,
        }}
      >
        <DialogHeading
          isLoading={isLoading}
          TitleProps={{ style: { width: '100%' } }}
          title={
            <NotebookDialogTitle
              mainTitle={mainTitle}
              userPatientProfileID={userPatientProfileID}
            />
          }
          onClose={onCloseWrapper}
        />
        <DialogContent className={style.content}>
          <UserInfoWeightGrid userPatientProfileID={userPatientProfileID} />
          <div>
            {state === 'preview' && <PreviewSessionsBlock userPatientProfileSessionID={itemID} />}
            {state === 'edit' && (
              <PanelGroup direction={'horizontal'}>
                <Panel defaultSize={50} minSize={30} maxSize={65}>
                  <NotebookFormPanel
                    ref={ref}
                    showOnlyWhenReady
                    isLoading={isLoadingGet || resultCloneFromPrevious.isLoading}
                    isShowCloneTooltip={false}
                    userPatientProfileID={userPatientProfileID}
                    userPatientProfileSessionID={itemID}
                    notebookFieldInputs={notebookFieldInputs}
                    notebookSettings={notebookSettings}
                    onSubmit={_onSubmit}
                    onCopyClinicalDrugsFromPrevious={onCloneClinicalDrugs}
                  />
                </Panel>
                <AppPanelResizeHandle
                  style={{
                    margin: '0 1rem',
                  }}
                />
                <Panel>
                  <InformationSection
                    userPatientProfileID={userPatientProfileID}
                    userPatientProfileSessionID={null}
                  />
                </Panel>
              </PanelGroup>
            )}
          </div>
        </DialogContent>
        {hasActions && (
          <DialogActions className={style.actions}>
            {isShowDelete && (
              <DeleteActivity
                isCanDelete={isCanDelete}
                formTitle={mainTitle}
                userPatientProfileSessionID={itemID as string}
                onClose={onCloseDeleteDialog}
              />
            )}
            <Button
              disabled={isLoading || !data || !isAllowToEdit}
              variant={'contained'}
              color={'primary'}
              onClick={onEdit}
            >
              {t(state === 'preview' ? 'edit' : 'save')}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  },
);
