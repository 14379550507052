import React from 'react';
import { RowField } from 'components/row-field';
import style from './index.module.scss';

interface IOption {
  label: React.ReactNode;
  value: any;
}
interface Props {
  options: IOption[];
}
export const InfoTable: React.FC<Props> = ({ options }) => {
  return (
    <div className={style.root}>
      {options.map((item, i) => {
        return (
          <RowField key={i} label={item.label} classes={{ label: style.label, value: style.value }}>
            {item.value}
          </RowField>
        );
      })}
    </div>
  );
};
