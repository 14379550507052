import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { useTranslate } from 'hooks/use-translate';
import { Alert } from '@material-ui/lab';
import { apiSupportMeeting, SupportMeeting } from 'services/support-meetings';
import { ListFutureSupportMeetings } from './components';
import { useEffectError } from 'hooks';
import { Stack } from 'components/stack';
import { NativeScroll } from 'components/native-scroll';

export type FutureMeeting = Pick<
  SupportMeeting,
  'id' | 'meetingFromDateTime' | 'meetingToDateTime' | 'userEmployeeProfileID'
>;

interface Props {
  userPatientProfileID: string;
  onClose: () => void;
  onContinue: () => void;
  onContinueWithMeeting: (meeting: FutureMeeting) => void;
}

export const DialogNextFutureSupportMeeting: React.FC<Props> = ({
  userPatientProfileID,
  onClose,
  onContinue,
  onContinueWithMeeting,
}) => {
  const { t, tp } = useTranslate();

  const [triggerMeeting, resultMeetings] =
    apiSupportMeeting.useLazyGetPatientFutureSupportMeetingsQuery();
  useEffectError(resultMeetings.error);

  const [selectedMeetingID, setSelectedMeetingID] = useState<string | null>(null);

  useEffect(() => {
    let process = true;

    const processFetch = async () => {
      const result = await triggerMeeting(userPatientProfileID);

      if (!process) return;

      if (result.isSuccess && result.data?.length === 0) {
        onContinue();
      }
    };

    processFetch();
    return () => {
      process = false;
    };
  }, [triggerMeeting, userPatientProfileID, onContinue]);

  const meetings = useMemo(() => {
    return resultMeetings.data || [];
  }, [resultMeetings.data]);

  const selectedMeeting = useMemo(() => {
    return meetings.find((meeting) => selectedMeetingID === meeting.id);
  }, [selectedMeetingID, meetings]);
  const isLoading = resultMeetings.isFetching;

  const hasFutureMeetings = meetings.length > 0;

  const onSubmit = useCallback(async () => {
    if (!selectedMeeting) {
      return;
    }
    onContinueWithMeeting(selectedMeeting);
  }, [selectedMeeting, onContinueWithMeeting]);

  return (
    <Dialog open={true} maxWidth={'xs'} fullWidth onClose={onClose}>
      <DialogHeading isLoading={isLoading} title={tp('future-support-meeting')} onClose={onClose} />
      <DialogContent>
        {hasFutureMeetings && (
          <Stack direction={'column'} spacing={1}>
            <Alert severity={'error'}>{tp('do-you-want-to-use-exist')}</Alert>
            <Typography color={'secondary'} variant={'body1'} style={{ fontWeight: 600 }}>
              {t('select-from-list')}
            </Typography>
            <NativeScroll style={{ maxHeight: '30rem' }} mode={'visible'}>
              <ListFutureSupportMeetings
                selectedID={selectedMeetingID}
                disabled={false}
                list={meetings}
                onSelect={setSelectedMeetingID}
              />
            </NativeScroll>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button color={'secondary'} variant={'contained'} disabled={isLoading} onClick={onContinue}>
          {t('no')}
        </Button>
        <Button
          color={'primary'}
          variant={'contained'}
          disabled={isLoading || !selectedMeeting}
          onClick={onSubmit}
        >
          {t('yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ListFutureSupportMeetings } from './components';
