import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'UserPatientProfileSessionIcd10s';

export const API_USER_PATIENT_PROFILE_SESSIONS_ICD10S = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  POST_BULK: `${API_ENDPOINT}/CreateUserPatientProfileSessionIcd10Bulk`,
  PATCH: (data: Pick<UserPatientProfileSessionIcd10, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<UserPatientProfileSessionIcd10, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface UserPatientProfileSessionIcd10
  extends FixServerObject<Components.Schemas.UserPatientProfileSessionIcd10, 'id'> {
  createdDate?: string;
  updatedDate?: string;
}

export interface CreateBulkUserPatientProfileSessionIcd10Input {
  userPatientProfileSessionID: string;
  icd10IDs: string[];
}
