import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TabsWrapper } from 'components/tabs-wrapper';
import { AllActivitiesGrid } from 'components/dialog-patient';
import { useTranslate } from 'hooks/use-translate';
import { composeFunctions } from 'utils';
import { SessionsList } from '../sessions-list';
import { LatestNotebookData } from '../latest-notebook-data';
import style from './index.module.scss';

enum TABS {
  MEDICAL_INFO = '0',
  SESSIONS = '1',
  ALL_ACTIVITIES = '2',
}

interface Props {
  userPatientProfileID: string;
  userPatientProfileSessionID: string | null;
}

export const InformationSection: React.FC<Props> = ({
  userPatientProfileID,
  userPatientProfileSessionID,
}) => {
  const { t } = useTranslate();
  const [activeTab, setActiveTab] = useState(TABS.ALL_ACTIVITIES);
  const [isTabDraft, setIsTabDraft] = useState(false);

  const makeTabDraft = useCallback(() => {
    setIsTabDraft(true);
  }, []);

  const tabs = useMemo(
    () => [
      {
        value: TABS.MEDICAL_INFO,
        title: t('medical-information'),
        element: (
          <LatestNotebookData
            userPatientProfileID={userPatientProfileID}
            userPatientProfileSessionID={userPatientProfileSessionID}
          />
        ),
      },
      {
        value: TABS.SESSIONS,
        title: t('all-sessions'),
        element: <SessionsList userPatientProfileID={userPatientProfileID} />,
      },
      {
        value: TABS.ALL_ACTIVITIES,
        title: t('all-activities'),
        element: (
          <AllActivitiesGrid
            userPatientProfileID={userPatientProfileID}
            style={{ paddingTop: 0 }}
          />
        ),
      },
    ],
    [t, userPatientProfileID, userPatientProfileSessionID],
  );

  useEffect(() => {
    const setDefaultTabValue = () => {
      if (isTabDraft) return;

      setActiveTab(TABS.MEDICAL_INFO);
    };
    setDefaultTabValue();
  }, [isTabDraft]);

  return (
    <TabsWrapper
      tabs={tabs}
      value={activeTab}
      onChange={composeFunctions(setActiveTab, makeTabDraft)}
      classes={{ tabs: style.tabs, tab: style.tab }}
    />
  );
};
