import React, { memo, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { selectAuthUser } from 'store/auth';
import {
  actionEmployeesMonitorEndPolling,
  actionEmployeesMonitorStartPolling,
  actionEmployeesMonitorPingCurrentUser,
} from 'store/employees-monitor';
import { throttle } from 'lodash-es';

const INTERACTION_THROTTLE_TIME = 60_000; // ms
export const EmployeesMonitorProvider = memo(({ children }) => {
  const user = useAppSelector(selectAuthUser);
  const dispatch = useAppDispatch();

  const appUserID = user?.appUserID;

  const interactionHandler = useMemo(() => {
    return throttle(() => {
      dispatch(actionEmployeesMonitorPingCurrentUser());
    }, INTERACTION_THROTTLE_TIME);
  }, [dispatch]);

  useEffect(() => {
    if (appUserID) {
      document.body.addEventListener('click', interactionHandler);
    }

    return () => {
      document.body.removeEventListener('click', interactionHandler);
    };
  }, [interactionHandler, appUserID]);

  useEffect(() => {
    if (appUserID) {
      dispatch(actionEmployeesMonitorStartPolling());
    }

    return () => {
      dispatch(actionEmployeesMonitorEndPolling());
    };
  }, [dispatch, appUserID]);

  return <>{children}</>;
});
