import { createSlice } from '@reduxjs/toolkit';

interface State {}
const initialState = (): State => ({});

const slice = createSlice({
  name: 'USER_PATIENT_PROFILE_SUBSCRIPTION_ACTIVITIES',
  initialState,
  reducers: {},
  extraReducers(build) {},
});
// export const {} = slice.actions;
export const userPatientProfileSubscriptionActivitiesReducer = slice.reducer;
