import React, { memo, useCallback, useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import State1 from './state-1';
import State2 from './state-2';
import State3 from './state-3';
import StateDone from './state-done';
import StateFail from './state-fail';
import style from './index.module.scss';
import { tPaymentFormExtra } from './@type';
import { useTranslate } from 'hooks/use-translate';

interface Props {
  userPatientProfileID: string;

  onDone: () => void;
  onCancel: () => void;
}
export const PaymentDialog = memo<Props>(
  ({ userPatientProfileID, onDone: onDoneOuter, onCancel }) => {
    const { t } = useTranslate();
    const [screen, setScreen] = useState(1);
    const [link, setLink] = useState('');
    const [state, setState] = useState<Partial<tPaymentFormExtra>>({
      // main
      userPatientProfileID,
      // state1
      subscriptionID: '',
      durationMonths: 0,
      // state2
      description: '',
      startDate: '',
      endDate: '',
      email: '',
      clientName: '',
      clientVatID: '',
      sum: 0,
      remarks: '',
    });

    const onDoneInner = useCallback(() => {
      onDoneOuter();
    }, [onDoneOuter]);

    const onNextState1 = useCallback(
      (data: Partial<tPaymentFormExtra>) => {
        setState((state) => ({ ...state, ...data }));
        setScreen(2);
      },
      [setScreen, setState],
    );
    const onNextState2 = useCallback(
      (data: Partial<tPaymentFormExtra>) => {
        setState((state) => ({ ...state, ...data }));
        setScreen(3);
      },
      [setScreen, setState],
    );
    const onNextState3 = useCallback(
      (link: string, state: number) => {
        setLink(link);
        setScreen(state);
      },
      [setScreen, setLink],
    );

    const cancelWrap = useCallback(() => {
      if (screen === 4) {
        onDoneInner();
      }
      return onCancel();
    }, [onCancel, onDoneInner, screen]);

    return (
      <Dialog
        open={true}
        onClose={cancelWrap}
        fullWidth
        disableEscapeKeyDown={true}
        className={style.dialog}
      >
        <DialogHeading title={t('pay')} onClose={cancelWrap} />
        <DialogContent className={style.content}>
          {screen === 1 && (
            <State1
              onCancel={cancelWrap}
              onNext={onNextState1}
              userPatientProfileID={userPatientProfileID}
            />
          )}
          {screen === 2 && (
            <State2
              initData={state}
              onCancel={cancelWrap}
              onNext={onNextState2}
              onBack={setScreen}
            />
          )}
          {screen === 3 && (
            <State3
              initData={state}
              onCancel={cancelWrap}
              onNext={onNextState3}
              onBack={setScreen}
            />
          )}
          {screen === 4 && <StateDone onDone={onDoneInner} link={link} />}
          {screen === 5 && <StateFail onCancel={cancelWrap} />}
        </DialogContent>
      </Dialog>
    );
  },
);

export default PaymentDialog;
