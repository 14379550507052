import React, { FC, memo, useCallback } from 'react';
import { IDataGridFilter } from '../../model';
import { Select } from 'AurionCR/components/form';

export const calcFilterSelectResult = (value: any, field: string) => {
  if (value) {
    if (Array.isArray(value) && value.length) {
      const result = value.reduce((result: any[], item: any) => {
        if (item[field]) result.push(item[field]);
        return result;
      }, []);
      return result.length ? result : '';
    } else {
      return value[field] || '';
    }
  }
  return '';
};

const FilterSelect: FC<{
  filter: IDataGridFilter;
  value: any;
  onTasks: any;
}> = ({ filter, value, onTasks }) => {
  const onChange = useCallback((e, value) => {
    onTasks([
      ['pagination', { page: 1 }],
      // @ts-ignore
      ['filters', { [filter.field]: calcFilterSelectResult(value, filter.options.option.value) }],
      ['getData'],
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Select
      // @ts-ignore
      settings={filter.options}
      style={filter.options?.style}
      value={value}
      onChange={onChange}
    />
  );
};

export default memo(FilterSelect);
