import React, { memo, useCallback, useEffect, useMemo } from 'react';
import GridConfig from './grid-config';
import DataGrid from 'components/data-grid';
import { useFormGrid } from 'AurionCR/components/formV2';
import clsx from 'clsx';
import style from './index.module.scss';
import { SectionPaper } from '../../../section-papper';
import { useTranslate } from 'hooks/use-translate';
import { signedDocumentEmitter } from '../../emitter';

interface Props {
  userPatientProfileID: string | undefined | null;
  prescriptionID: string;
  onRefresh: () => void;
  className: string;
}
export const GridOtherUnsignedDocuments = memo<Props>(
  ({ prescriptionID, userPatientProfileID, className, onRefresh }) => {
    const { t } = useTranslate();
    const gridConfig = useMemo<any>(() => {
      if (!userPatientProfileID) {
        return null;
      }
      return GridConfig({
        userPatientProfileID,
        prescriptionID,
      });
    }, [prescriptionID, userPatientProfileID]);
    const { grid, onGridHandle } = useFormGrid('document');

    const onRefreshGrid = useCallback(() => {
      grid.current?.onTasks([['getData']]);
    }, [grid]);

    const onGridHandleWrapper = useCallback(
      (data) => {
        onGridHandle(data);
        if (data.type === 'deleteDone') {
          onRefresh();
        }
      },
      [onGridHandle, onRefresh],
    );

    useEffect(() => {
      const refresh = (options: { prescriptionID: string }) => {
        if (options.prescriptionID === prescriptionID) {
          onRefreshGrid();
        }
      };

      signedDocumentEmitter.on('refresh-grid', refresh);
      return () => {
        signedDocumentEmitter.off('refresh-grid', refresh);
      };
    }, [onRefreshGrid, prescriptionID]);

    return (
      <SectionPaper className={clsx(style.root, className)} title={t('general-unsigned-docs')}>
        {gridConfig && <DataGrid ref={grid} onHandle={onGridHandleWrapper} config={gridConfig} />}
      </SectionPaper>
    );
  },
);
