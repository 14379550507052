import update from 'immutability-helper';
import { CLINICAL_MEETING_ACTION_TYPES, IClinicalMeetingsState } from './@type';
import { ClinicalMeetingActions } from './actions';
import { CLINICAL_MEETING_PATIENT_OPTIONS, createTimes } from '../helpers';

export const initialState = (): IClinicalMeetingsState => ({
  init: false,
  loading: false,
  view: 'list',

  editMeeting: null,
  confirmDeleteMeeting: null,
  smsMeetingID: null,

  employees: [],
  employeesWorkLogs: {},
  meetingTypes: [],
  meetingSubjects: [],

  times: createTimes(),

  calendar: {
    loading: false,
    // filter
    filters: {
      date: new Date(),
      clinicalMeetingTypeID: '',
      clinicalMeetingSubjectIDs: [],
      search: '',
      isActivePatient: CLINICAL_MEETING_PATIENT_OPTIONS.ALL,
    },
    // data
    data: [],
    // dayView
    dialogDayViewItems: null,
    resizeDayViewItem: null,
    startDragDayViewItem: 0,
  },
  list: {
    isRefreshingActivities: false,
    loading: false,
    orderField: 'meetingFromDateTime',
    orderDirection: 'asc',
    page: 1,
    pageSize: 100,
    filters: {
      date: [new Date(), new Date()],
      userEmployeeProfileID: '',
      clinicalMeetingTypeID: '',
      clinicalMeetingSubjectIDs: [],
      search: '',
      isActivePatient: CLINICAL_MEETING_PATIENT_OPTIONS.ALL,
    },

    data: [],
    dataCount: 0,

    triggerScrollUpdate: '',
  },
});

export const reducerClinicalMeetings = (state = initialState(), action: ClinicalMeetingActions) => {
  switch (action.type) {
    case CLINICAL_MEETING_ACTION_TYPES.RESET: {
      return update(state, {
        calendar: {
          $merge: {
            dialogDayViewItems: null,
          },
        },
      });
    }
    case CLINICAL_MEETING_ACTION_TYPES.SEND_SMS: {
      return { ...state, smsMeetingID: action.payload.meetingID };
    }
    case CLINICAL_MEETING_ACTION_TYPES.MERGE: {
      return update(state, { $merge: action.payload });
    }
    case CLINICAL_MEETING_ACTION_TYPES.SET_CALENDAR_EMPLOYEE_TIME_SLOTS: {
      return update(state, { employeesWorkLogs: { $set: action.payload } });
    }
    case CLINICAL_MEETING_ACTION_TYPES.VIEW_MERGE: {
      const { view, ...rest } = action.payload;
      return update(state, { [view]: { $merge: rest } });
    }
    case CLINICAL_MEETING_ACTION_TYPES.FILTER_MERGE: {
      const { view, ...rest } = action.payload;
      return update(state, { [view]: { filters: { $merge: rest } } });
    }
    default:
      return state;
  }
};
