import React, { memo } from 'react';
import style from './index.module.scss';
import clsx from 'clsx';
import { Box, BoxProps } from '@material-ui/core';

export const createCalendarAxiosTimeID = (duration: string) => {
  return `axios-time-${duration}`;
};

interface Classes {
  root: string;
  separator: string;
  time: string;
  title: string;
  solid: string;
}

interface Props extends BoxProps {
  classes?: Partial<Classes>;
  times: { id: string; decimal: number }[];
}
export const CalendarAxiosTime = memo<Props>(({ times, classes, ...rest }) => {
  return (
    <Box className={clsx(style.root, classes?.root)} {...rest}>
      <div className={clsx(style.time, classes?.time, style.separator, classes?.separator)} />
      {times.map(({ id, decimal }) => (
        <div
          className={clsx(style.time, classes?.time)}
          id={createCalendarAxiosTimeID(id)}
          key={id}
        >
          <div
            className={clsx(
              style.title,
              classes?.title,
              decimal % 1 === 0 && [style.solid, classes?.solid],
            )}
          >
            {id}
          </div>
        </div>
      ))}
    </Box>
  );
});
