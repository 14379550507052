import React, { useCallback, useMemo } from 'react';
import { Box, Grid, Radio } from '@material-ui/core';
import { APP_FORMAT_DATE, FORMAT_TIME_SLOT } from 'configs/const';
import { Controller, useFormContext } from 'react-hook-form';
import { useFieldProps } from 'hooks';
import { AppRadioGroup } from 'components/app-radio-group';
import { AppCheckbox } from 'components/app-checkbox';
import { add, addMinutes, differenceInMinutes, set } from 'date-fns';
import { createDefaultMeetingTimes } from 'utils/app-helpers';
import { dateFormat } from 'utils/dates';
import { AppDatePicker } from 'components/app-date-picker';
import { AppSelect } from 'components/app-select';
import { SelectEmployee } from 'components/select-employee';
import { composeFunctions } from 'utils';
import { useSourceMeetingTypes } from '../../hooks';
import { ArrayType } from 'utils/types';
import { AppInput } from 'components/app-input';
import {
  isShouldDisableSupportMeetingDate,
  schemaSupportMeetingNext,
} from 'services/support-meetings';
import { InferType } from 'yup';

const times = createDefaultMeetingTimes();

export type NextMeetingModel = InferType<typeof schemaSupportMeetingNext>;

const timeSlotToDate = (timeSlot: string | number) => {
  const [hours, minutes] = String(timeSlot).split(':');

  return set(new Date(), {
    hours: Number(hours),
    minutes: Number(minutes),
    seconds: 0,
    milliseconds: 0,
  });
};

export const setUpTimeForSideEffectMeetingTime = (input: {
  start: string | number;
  end: string | number | undefined;
}) => {
  const { start, end } = input;

  if (!end) {
    return null;
  }

  const startDate = timeSlotToDate(start);
  const endDate = timeSlotToDate(end);

  const differentInMinutes = differenceInMinutes(endDate, startDate);

  const newStartDate = set(new Date(), { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 });
  const newEndDate = addMinutes(newStartDate, differentInMinutes);

  return [
    dateFormat(newStartDate, FORMAT_TIME_SLOT),
    dateFormat(newEndDate, FORMAT_TIME_SLOT),
  ] as const;
};

interface Props {
  isLoading: boolean;
}
export const NextMeetingForm: React.FC<Props> = ({ isLoading }) => {
  const { control, errors, watch, setValue, clearErrors, register, getValues } =
    useFormContext<NextMeetingModel>();
  const getFieldProps = useFieldProps({ errors, disabled: isLoading, emptyHelperText: null });
  const sourceMeetingTypes = useSourceMeetingTypes();

  const supportMeetingOptions = useMemo(
    () =>
      sourceMeetingTypes.data.map((item) => ({
        value: item.id,
        label: item.title,
        control: <Radio color={'primary'} />,
        nextMeetingNumberOfDays: item.nextMeetingNumberOfDays,
        isSideEffect: !!item.isSideEffect,
      })),
    [sourceMeetingTypes.data],
  );

  const includeMeetingTime = watch('includeMeetingTime');

  const onChangeIncludeMeetingTime = useCallback(
    (value: boolean) => {
      if (value) return;
      clearErrors('start');
      clearErrors('end');
    },
    [clearErrors],
  );

  const onChangeMeetingType = useCallback(
    (value: string, option: ArrayType<typeof supportMeetingOptions>) => {
      if (option.nextMeetingNumberOfDays) {
        const date = add(new Date(), {
          days: option.nextMeetingNumberOfDays,
        });
        setValue('date', date);
      }

      if (option.isSideEffect) {
        const start = getValues('start');
        const end = getValues('end');

        const res = setUpTimeForSideEffectMeetingTime({ start, end });

        if (res) {
          setValue('start', res[0]);
          setValue('end', res[1]);
        }
      }
    },
    [setValue, supportMeetingOptions, getValues],
  );

  return (
    <Box px={1}>
      <input {...register('userPatientProfileID')} hidden />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name={'supportMeetingTypeID'}
            render={(renderProps) => {
              const props = getFieldProps(renderProps);
              return (
                <AppRadioGroup
                  row
                  {...props}
                  label={null}
                  options={supportMeetingOptions}
                  onChange={composeFunctions(props.onChange, onChangeMeetingType)}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name={'userEmployeeProfileID'}
            render={(renderProps) => {
              const props = getFieldProps(renderProps);
              return <SelectEmployee {...props} disableClearable />;
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name={'date'}
            render={(renderProps) => {
              const props = getFieldProps(renderProps);
              const label = [
                props.label,
                props.value && dateFormat(props.value, 'EEEE').toLowerCase(),
              ]
                .filter(Boolean)
                .join(' | ');

              return (
                <AppDatePicker
                  {...props}
                  label={label}
                  format={APP_FORMAT_DATE}
                  shouldDisableDate={isShouldDisableSupportMeetingDate}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name={'includeMeetingTime'}
            render={(renderProps) => {
              const props = getFieldProps(renderProps);
              return (
                <AppCheckbox
                  {...props}
                  color={'primary'}
                  onChange={composeFunctions(props.onChange, onChangeIncludeMeetingTime)}
                />
              );
            }}
          />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item md={6}>
            <Controller
              control={control}
              name={'start'}
              render={(renderProps) => {
                const props = getFieldProps(renderProps);
                return (
                  <AppSelect
                    {...props}
                    options={times}
                    getOptionLabel={(option) => option.id}
                    disabled={isLoading || !includeMeetingTime}
                    disableClearable
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={'end'}
              render={(renderProps) => {
                const props = getFieldProps(renderProps);
                return (
                  <AppSelect
                    {...props}
                    options={times}
                    getOptionLabel={(option) => option.id}
                    disabled={isLoading || !includeMeetingTime}
                    disableClearable
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name={'remarks'}
            render={(renderProps) => {
              const props = getFieldProps(renderProps);
              return <AppInput {...props} multiline minRows={5} />;
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
