import React, { useCallback, useMemo, useRef } from 'react';
import { Button, ButtonProps, CircularProgress, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { useSourceNotebooksAll } from 'components/hooks';
import { useOpen } from 'AurionCR/components/hooks';
import { useOpenValue } from 'hooks';
import { getNotebookFormComponent, withPrescriptionTask } from 'components/notebook-form-generator';
import BookmarksIcon from '@material-ui/icons/Bookmarks';

interface Props extends ButtonProps {
  userPatientProfileID: string;
  onDone?: () => void;
}

export const ButtonAddNotebook: React.FC<Props> = ({ userPatientProfileID, onDone, ...rest }) => {
  const sourceNotebooks = useSourceNotebooksAll();
  const { t } = useTranslate();
  const ref = useRef<HTMLButtonElement>(null);
  const { isOpen, handleOpen, handleClose } = useOpen();

  const { openValue, onOpen: onOpenDialog, onClose: onCloseDialog } = useOpenValue<string>();

  const factoryClickMenuItem = useCallback(
    (id: string) => {
      return () => {
        onOpenDialog(id);
        handleClose();
      };
    },
    [handleClose, onOpenDialog],
  );

  const notebook = useMemo(() => {
    return sourceNotebooks.data.find((note) => note.id === openValue);
  }, [sourceNotebooks.data, openValue]);

  const FormGenerator = useMemo(() => {
    return notebook ? withPrescriptionTask(getNotebookFormComponent(notebook)) : null;
  }, [notebook]);

  return (
    <>
      <Tooltip title={t('add-notebook-tooltip')}>
        <Button
          onClick={handleOpen}
          ref={ref}
          variant={'contained'}
          color={'primary'}
          startIcon={sourceNotebooks.loading ? <CircularProgress size={20} /> : <BookmarksIcon />}
          disabled={sourceNotebooks.loading || sourceNotebooks.data.length === 0}
          {...rest}
        >
          {t('add-notebook')}
        </Button>
      </Tooltip>
      <Menu
        anchorEl={ref.current}
        keepMounted
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {sourceNotebooks.data.map((item) => (
          <MenuItem key={item.id} onClick={factoryClickMenuItem(item.id)}>
            {item.title}
          </MenuItem>
        ))}
      </Menu>
      {openValue && FormGenerator && (
        <FormGenerator
          notebookID={openValue}
          userPatientProfileID={userPatientProfileID}
          onClose={onCloseDialog}
          onDone={onCloseDialog}
          onCreated={onDone}
          meetingFromDateTime={undefined}
          meetingToDateTime={undefined}
        />
      )}
    </>
  );
};
