import DataGrid from '../../../data-grid';
import { Editor } from './editor';
import React, { useCallback, useState } from 'react';
import { useCurrentUser } from 'components/hooks';
import { GridConfig } from './grid-config';
import { useFormGrid } from 'AurionCR/components/formV2';

interface Props {
  toDoTaskID: string;
  onRefresh?: () => void;
}
export const GridTaskActivities: React.FC<Props> = ({ toDoTaskID, onRefresh }) => {
  const { appUserID } = useCurrentUser();
  const [gridConfig] = useState<any>(GridConfig({ toDoTaskID }));

  const {
    grid,
    editItemID,
    onFormHandle,
    onClose: onCloseGrid,
    onGridHandle,
  } = useFormGrid('activity');

  const onFormHandleProxy = useCallback<typeof onFormHandle>(
    (action) => {
      if (['post', 'put', 'delete', 'patch'].includes(action.type)) {
        onRefresh && onRefresh();
      }
      return onFormHandle(action);
    },
    [onFormHandle, onRefresh],
  );
  return (
    <>
      <DataGrid ref={grid} onHandle={onGridHandle} config={gridConfig} />
      {editItemID && (
        <Editor
          onClose={onCloseGrid}
          appUserID={appUserID}
          onFormHandle={onFormHandleProxy}
          itemID={editItemID}
          toDoTaskID={toDoTaskID}
        />
      )}
    </>
  );
};

export { Editor };
