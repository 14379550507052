import * as yup from 'yup';
import { InferType } from 'yup';

export const schemaMeetingCancel = yup.object({
  meetingID: yup.string().required('rule-required').default(''),
  remarkForPatientCallStatusID: yup.string().required('rule-required').default(''),
  remarks: yup.string().required('rule-required').trim().default(''),
});

export type FormModel = InferType<typeof schemaMeetingCancel>;
