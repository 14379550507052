import React, {FC, memo, useMemo} from "react";
import {IDataGridMadel} from "../../model";
import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {useI18n} from "../../../../AurionCR/components";

const Size: FC<{
  model: IDataGridMadel;
  onTasks: any;
  pagination: any;
}> = ({model, onTasks, pagination}) => {
  const {t} = useI18n();
  const value = useMemo(() => {
    return model.pagination.sizes.values.filter(option => option.value === model.pagination.size)[0] || null
  }, [model]);
  if (!model) return null;
  return (
    <>
      <span className="title">{t('page-size')}</span>
      <Autocomplete
        options={model.pagination.sizes.values}
        disableClearable={true}
        size="small"
        onChange={(e: any, value: any) => onTasks([['pagination', {size: value.value, page: 1}], ['getData']])}
        defaultValue={value}
        getOptionLabel={item => item.label}
        getOptionSelected={(item, value) => item.value === value.value}
        renderInput={params => (
          <TextField {...params} variant="outlined"/>
        )}
      />
      {Boolean(pagination.total_) &&
      <span className="total">{t('total')}: <span>{pagination.total_}</span></span>
      }
    </>
  )
};

export default memo(Size);
