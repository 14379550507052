import { omit } from 'lodash-es';
import { base64ToFileStream, isFileLike, ValueFileUploaderFile } from 'utils/file-uploader';
import { isSettledFulfilled, isSettledRejected } from 'utils/types';

type EntryToUpload<T> = [keyof T, ValueFileUploaderFile, FileConfig];

type PostFieldsConfig<T> = {
  [K in keyof T]?: FileConfig;
};

type PatchFieldsConfig<T> = {
  [K in keyof T]?: FileConfig;
};

export interface FileCloudModel {
  fileName?: string | null;
  fileStreamString?: string | null;
  filePath?: string | null;
  isImage?: boolean;
}

export type FileConfig = FileCloudModel | ((file: ValueFileUploaderFile) => FileCloudModel);

export type FileCloudUploader = (
  data: FileCloudModel,
  file: ValueFileUploaderFile,
) => Promise<FileCloudModel>;

export type FileCloudRemover = (data: FileCloudModel) => Promise<any>;

export class FileCloud {
  private _uploadFile: FileCloudUploader;
  private _removeFile: FileCloudRemover;
  constructor(uploadFile: FileCloudUploader, removeFile: FileCloudRemover) {
    this._uploadFile = uploadFile;
    this._removeFile = removeFile;
  }
  private requestUploadFiles = async <T>(entriesToUpload: EntryToUpload<T>) => {
    const [key, file, config] = entriesToUpload;
    let configObject = typeof config === 'function' ? config(file) : config;

    const result = await this._uploadFile(
      {
        fileStreamString: base64ToFileStream(file.value),
        fileName: file.name,
        ...configObject,
      },
      file,
    );

    return { key, file, config, data: result };
  };
  postDataWithFiles = async <T extends Record<string, any>>(
    formData: T,
    fields: PostFieldsConfig<T>,
  ) => {
    const fileFields = Object.keys(fields);

    let entriesToUpload: [keyof T, ValueFileUploaderFile, FileConfig][] = [];

    fileFields.forEach((key) => {
      const value = formData[key];
      if (isFileLike(value)) {
        entriesToUpload.push([key, value, fields[key] as FileConfig]);
      }
    });

    // primary fields;
    const primary = omit(formData, ...entriesToUpload.map(([key]) => key));

    // upload files to the server
    const resultUpload = await Promise.allSettled(
      entriesToUpload.map((entry) => this.requestUploadFiles(entry)),
    );

    // successfully uploaded files
    const fulfilled = resultUpload.filter(isSettledFulfilled);
    // errors
    const rejected = resultUpload.filter(isSettledRejected);

    // Callback to remove all successfully uploaded files
    const transaction = async () => {
      await Promise.allSettled(
        fulfilled.map((res) => {
          return this._removeFile(res.value.data);
        }),
      );
    };

    // Check any errors
    if (rejected.length) {
      transaction();
      throw new Error(rejected[0].reason);
    }

    // make a result
    const updatedFields = Object.fromEntries(
      fulfilled.map(({ value }) => [value.key, value.data.filePath]),
    );

    return [{ ...primary, ...updatedFields } as T, transaction] as const;
  };
  patchDataWithFiles = async <T extends Record<string, any>>(
    formData: T,
    formDataOld: T | null | undefined,
    fields: PatchFieldsConfig<T>,
  ) => {
    const fileFields = Object.keys(fields) as Array<keyof T>;

    let entriesToUpload: EntryToUpload<T>[] = [];
    let entriesToDelete: [keyof T, string][] = [];

    fileFields.forEach((key) => {
      const newItem = formData[key];
      const oldItem = formDataOld && formDataOld[key];

      if (isFileLike(newItem)) {
        entriesToUpload.push([key, newItem, fields[key] as FileConfig]);
      }
      if (newItem !== undefined && oldItem && typeof oldItem === 'string' && oldItem !== newItem) {
        entriesToDelete.push([key, oldItem]);
      }
    });

    // remove files from the server
    const deletedResult = await Promise.all(
      entriesToDelete.map(async ([key, value]) => {
        try {
          await this._removeFile({ filePath: value });
        } catch (e) {
          console.warn(`PatchFiles: ${value}`);
        }
        return { key, data: '' };
      }),
    );

    // upload files to the server
    const uploadedResult = await Promise.all(
      entriesToUpload.map(async (entry) => this.requestUploadFiles(entry)),
    );

    // make a result
    const formDataResult = {
      ...formData,
      ...Object.fromEntries(deletedResult.map(({ key, data }) => [key, data])),
      ...Object.fromEntries(uploadedResult.map(({ key, data }) => [key, data.filePath])),
    };

    return [formDataResult as T] as const;
  };
}
