import React from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { NativeScroll } from 'components/native-scroll';
import { Box, BoxProps } from '@material-ui/core';

interface Props extends BoxProps {
  className?: string;
  children: React.ReactNode;
  InnerProps?: Partial<BoxProps>;
}

export const EditorContent: React.FC<Props> = ({ children, InnerProps, className, ...rest }) => {
  return (
    <NativeScroll className={clsx(style.root, className)} {...rest}>
      <Box {...InnerProps} className={clsx(style.inner, InnerProps?.className)}>
        {children}
      </Box>
    </NativeScroll>
  );
};
