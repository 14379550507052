import React, { memo } from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { Avatar, AvatarProps, Typography } from '@material-ui/core';
import { Stack, StackProps } from 'components/stack';

interface Props extends Omit<StackProps, 'title'> {
  color?: string;
  iconSrc?: string;
  title: string | null | undefined;
  AvatarProps?: Partial<AvatarProps>;
}

export const TemplateIconWithText = memo<Props>(
  ({ color, title, iconSrc, AvatarProps, className, ...rest }) => {
    return (
      <Stack className={clsx(style.root, className)} spacing={1} {...rest}>
        <Avatar
          src={iconSrc}
          {...AvatarProps}
          style={{
            width: 28,
            height: 28,
            ...AvatarProps?.style,
            padding: '.4rem',
            backgroundColor: color,
          }}
        />
        <Typography color={'textSecondary'}>{title}</Typography>
      </Stack>
    );
  },
);
