import { API_USER_PATIENT_PROFILE_PRESCRIPTION_ACTIVITIES } from 'services/user-patient-profile-prescription-activities';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { API_USER_EMPLOYEE_PROFILES } from 'services/user-employee-profiles';
import { permissionKey } from 'services/user-employee-profile-permissions';

interface Props {
  userPatientProfilePrescriptionID: string;
  isAdmin: boolean;
}

export const GridConfig = ({ userPatientProfilePrescriptionID, isAdmin }: Props) => ({
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_PRESCRIPTION_ACTIVITIES.GET_ALL_DYNAMIC,
      filters: `userPatientProfilePrescriptionID=="${userPatientProfilePrescriptionID}"`,
    },
    create: {
      url: API_USER_PATIENT_PROFILE_PRESCRIPTION_ACTIVITIES.POST,
    },
    patch: {
      url: API_USER_PATIENT_PROFILE_PRESCRIPTION_ACTIVITIES.PATCH,
    },
    delete: {
      url: API_USER_PATIENT_PROFILE_PRESCRIPTION_ACTIVITIES.DELETE,
      field: 'remarks',
    },
    sortable: {
      field: 'entryDate',
      order: 'desc',
    },
  },
  controlsDropDown: [
    { type: 'delete', title: 'delete', permission: permissionKey('isAllowToDeleteAnything') },
  ],
  toolbar: {
    controlsDefault: { refresh: null, new: { title: 'add-new' } },
    controls: { new: { title: 'add-new' } },
    filters: [
      {
        field: 'entryDate',
        options: {
          type: 'date-multiple',
          placeholder: 'select-date',
          style: { minWidth: '21rem' },
        },
      },
      {
        field: 'userEmployeeProfileID',
        options: {
          type: 'select',
          label: 'employee',
          source: `${API_USER_EMPLOYEE_PROFILES.GET_ALL_DYNAMIC}?Select=appIdentityUserID as id,fullName as title&orderBy=firstName,lastName`,
          style: { minWidth: '16rem' },
        },
      },
    ],
  },
  columns: [
    ...(isAdmin
      ? [
          {
            title: 'created-date',
            field: 'createdDate',
            template: {
              type: 'date',
              format: APP_FORMAT_DATE_TIME,
            },
          },
        ]
      : []),
    {
      title: 'entry-date',
      field: 'entryDate',
      template: {
        type: 'date',
        format: APP_FORMAT_DATE_TIME,
      },
    },
    {
      title: 'employee',
      field: 'userEmployeeProfile.fullName as userEmployeeProfileFullName',
      sortable: 'userEmployeeProfile.firstName',
    },
    {
      title: 'remarks',
      field: 'remarks',
    },
  ],
});
