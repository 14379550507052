import React from 'react';

import style from './index.module.scss';
import { Popover, PopoverProps } from '@material-ui/core';
import { TwitterPicker } from 'react-color';
import { PICKER_COLORS } from '../../models';
import { useAppSelector } from 'store';
import { selectLanguageIsRtl } from 'store/languages';

interface Props extends Omit<PopoverProps, 'onChange' | 'value' | 'options'> {
  className?: string;
  options?: string[];
  value?: string;
  onChange: (v: string) => void;
}

export const ColorPicker: React.FC<Props> = ({
  value,
  onChange,
  options = PICKER_COLORS,
  ...rest
}) => {
  const isRtl = useAppSelector(selectLanguageIsRtl);
  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: isRtl ? 'right' : 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: isRtl ? 'right' : 'left',
      }}
      {...rest}
    >
      <TwitterPicker
        colors={options}
        className={style.picker}
        color={value}
        onChangeComplete={({ hex }) => {
          onChange(hex);
        }}
        styles={{
          default: {
            input: {
              display: 'none',
            },
            hash: {
              display: 'none',
            },
          },
        }}
      />
    </Popover>
  );
};
