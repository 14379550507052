import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import { useAppDispatch, useAppSelector } from 'store';
import { useI18n } from 'AurionCR/components';
import SignaturePad from 'signature_pad';
import { Button, ButtonGroup } from '@material-ui/core';
import UndoIcon from '@material-ui/icons/Undo';
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import {
  PdfDocumentSubmitFocus,
  pdfDocumentSubmitPageInputModify,
} from 'components/pdf-document-submit/store';
import { FormDocumentFieldType } from 'services/form-document-inputs';

export const Signature = memo(() => {
  // state
  const { eventFocusItem } = useAppSelector((state) => state.pdfDocumentSubmit);
  // render
  return eventFocusItem &&
    eventFocusItem.input.formDocumentFieldType === FormDocumentFieldType.signature ? (
    <SignatureComponent data={eventFocusItem} />
  ) : (
    <div />
  );
});

export const SignatureComponent = memo(
  ({
    data: {
      input: { width, height, id },
    },
  }: {
    data: PdfDocumentSubmitFocus;
  }) => {
    const { t } = useI18n();
    const dispatch = useAppDispatch();
    //state
    const canvasRef = useRef<any>(null);
    const [disableControls, setDisableControls] = useState(true);
    const [disableBtnSave, setDisableBtnSave] = useState(true);
    const [signaturePad, setSignaturePad] = useState<SignaturePad | null>(null);

    // handlers
    const onClear = useCallback(() => {
      if (signaturePad) {
        signaturePad.clear();
      }
      setDisableControls(true);
      setDisableBtnSave(true);
    }, [signaturePad, setDisableControls, setDisableBtnSave]);
    const onSubmit = useCallback(() => {
      if (signaturePad) {
        dispatch(
          pdfDocumentSubmitPageInputModify({
            id,
            defaultValue: signaturePad.toDataURL('image/png'),
          }),
        );
        onClear();
      }
    }, [signaturePad, dispatch, id, onClear]);
    const checkSaveBtnEnable = useCallback(() => {
      let disableControls = true;
      let disableBtnSave = true;

      if (signaturePad) {
        const paths = signaturePad.toData();
        if (paths?.length) {
          disableControls = false;
          if (paths.length > 1 || paths[0]?.points?.length > 1) {
            disableBtnSave = false;
          }
        }
      }
      setDisableControls(disableControls);
      setDisableBtnSave(disableBtnSave);
    }, [signaturePad, setDisableControls, setDisableBtnSave]);
    const onUndo = useCallback(() => {
      if (signaturePad) {
        const data = signaturePad.toData();
        if (data) {
          data.pop();
          signaturePad.fromData(data);
        }
      }
      checkSaveBtnEnable();
    }, [signaturePad, checkSaveBtnEnable]);
    // init
    useEffect(() => {
      if (!signaturePad && canvasRef?.current) {
        const canvas = canvasRef.current;

        const scale_ = Math.min(7, 500 / width, 150 / height);
        canvas.width = width * scale_;
        canvas.height = height * scale_;

        const signaturePad = new SignaturePad(canvas);
        setSignaturePad(signaturePad);
      } else if (signaturePad) {
        signaturePad.on();
        signaturePad.addEventListener('endStroke', checkSaveBtnEnable);
      }
      return () => {
        if (signaturePad) {
          signaturePad.removeEventListener('endStroke', checkSaveBtnEnable);
          signaturePad.off();
        }
      };
    }, [canvasRef, signaturePad, setSignaturePad, width, height, checkSaveBtnEnable]);
    // render
    return (
      <div className={style.wrapper}>
        <div className={style.canvas}>
          <canvas ref={canvasRef} />
        </div>
        <div className={style.footer}>
          <ButtonGroup
            color="secondary"
            variant="contained"
            className={style.controls}
            disabled={disableControls}
          >
            <Button startIcon={<UndoIcon />} onClick={onUndo}>
              {t('undo')}
            </Button>
            <Button startIcon={<DeleteIcon />} onClick={onClear}>
              {t('clear')}
            </Button>
          </ButtonGroup>
          <Button
            startIcon={<AssignmentTurnedInIcon />}
            onClick={onSubmit}
            color="primary"
            variant="contained"
            disabled={disableBtnSave}
          >
            {t('sign')}
          </Button>
        </div>
      </div>
    );
  },
);
