import {useCallback, useEffect, useMemo, useRef} from "react";
import {defaultOnFormHandle, DefaultOnFormHandleProps, defaultOnGridHandle, DefaultOnGridHandleProps} from "../heplers"
import {useSearchParams} from "../../hooks";
import {GridMethods} from "../../../../models/grid";

export type UseFormGrid = ReturnType<typeof useFormGrid>

export const useFormGrid = (query: string) => {
  const {replace, query: routeQuery} = useSearchParams()
  const queryValue = routeQuery[query]
  const editItemID = useMemo<string | boolean>(() => {
    return (queryValue === 'true' ? true : queryValue || false)
  }, [queryValue])
  const setEditItemID = useCallback((v: boolean | string | null) => {
    replace({[query]: v ? String(v) : null})
  }, [query, replace])
  const onClose = useCallback(() => {
    setEditItemID(null)
  }, [setEditItemID])
  const grid = useRef<GridMethods>(null);

  // handlers
  const onGridHandle = useCallback<(value: DefaultOnGridHandleProps['value']) => void>(value =>
      defaultOnGridHandle({value, setEditItemID})
    , [setEditItemID]);
  const onFormHandle = useCallback<(value: DefaultOnFormHandleProps['value']) => void>(value =>
      defaultOnFormHandle({value, setEditItemID, grid})
    , [grid, setEditItemID]);

  useEffect(() => {
    if (query) replace({[query]: editItemID ? String(editItemID) : null});
  }, [query, editItemID, replace]);

  const onRefresh= useCallback(() => {
    return grid.current?.onTasks([['getData']])
  }, [])

  return {grid, onGridHandle, onFormHandle, editItemID, setEditItemID, onClose, onRefresh};
}
