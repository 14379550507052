import { NotebookFormGeneratorProps } from '../notebook-form-generator';
import React, { useCallback, useState } from 'react';
import { UserPatientProfileSession } from 'services/user-patient-profile-session';
import { apiNotebooks } from 'services/notebook';
import { isMutationFulfilled } from 'utils/rtk-query';
import { DialogPrescriptionTask } from './components/dialog-task';

const useLazyFetchData = apiNotebooks.useLazyGetNotebookByIDQuery;

type Session = Pick<UserPatientProfileSession, 'id'>;

interface Props
  extends Pick<
    Required<NotebookFormGeneratorProps>,
    'onDone' | 'notebookID' | 'userPatientProfileID'
  > {
  isLoading?: boolean;
}

export const withPrescriptionTask = <P extends Props>(Component: React.FC<P>) => {
  const ComponentWithPrescriptionTask = ((props: P) => {
    const [session, setSession] = useState<Session>();

    const [getNotebook, resultGetNotebook] = useLazyFetchData();

    const onDone = useCallback(
      async (session: Session) => {
        const result = await getNotebook(props.notebookID);

        if (!isMutationFulfilled(result)) {
          return props.onDone(session);
        }

        if (!result.data.isRequirePrescription) {
          return props.onDone(session);
        }

        setSession(session);
      },
      [props, getNotebook],
    );

    const onContinue = useCallback(() => {
      if (!session) return;
      props.onDone(session);
    }, [session, props]);

    if (!session) {
      return (
        <Component
          {...props}
          isLoading={props.isLoading || resultGetNotebook.isLoading}
          onDone={onDone}
        />
      );
    }

    return (
      <DialogPrescriptionTask
        userPatientProfileSessionID={session.id}
        userPatientProfileID={props.userPatientProfileID}
        onCancel={onContinue}
        onDone={onContinue}
      />
    );
  }) as React.FC<P>;
  return ComponentWithPrescriptionTask;
};
