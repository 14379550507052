import React, { memo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { FormDocumentFieldType } from 'services/form-document-inputs';
import { PdfDocumentSubmitFocus, pdfDocumentSubmitPageInputModify } from '../../store';
import { useI18n } from 'AurionCR/components';
import style from './index.module.scss';
import { Button } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SkipNextIcon from '@material-ui/icons/SkipNext';

export const Checkbox = memo(() => {
  // state
  const { eventFocusItem } = useAppSelector((state) => state.pdfDocumentSubmit);
  // render
  return eventFocusItem &&
    eventFocusItem.input.formDocumentFieldType === FormDocumentFieldType.checkbox ? (
    <CheckboxComponent id={eventFocusItem.id} />
  ) : (
    <div />
  );
});

export const CheckboxComponent = memo(({ id }: { id: PdfDocumentSubmitFocus['id'] }) => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  // handlers
  const handleChecked = useCallback(() => {
    dispatch(pdfDocumentSubmitPageInputModify({ id, defaultValue: true }));
  }, [dispatch, id]);
  const handleSkip = useCallback(() => {
    dispatch(pdfDocumentSubmitPageInputModify({ id, defaultValue: false }));
  }, [dispatch, id]);
  // render
  return (
    <div className={style.wrapper}>
      <Button
        startIcon={<CheckCircleIcon />}
        onClick={handleChecked}
        color="primary"
        variant="contained"
      >
        {t('check')}
      </Button>
      <Button endIcon={<SkipNextIcon />} onClick={handleSkip} color="secondary" variant="contained">
        {t('skip')}
      </Button>
    </div>
  );
});
