import React, { memo, useCallback, useMemo } from 'react';
import { Dialog, DialogActions, DialogContent, LinearProgress } from '@material-ui/core';
import {
  FormController,
  IUseForm,
  useFormHook,
  Controls,
  validateRule,
  formControllerChangeParser,
} from 'AurionCR/components/formV2';
import { DialogBase, useAuth } from 'AurionCR/components';
import { useDeleteDialog } from 'AurionCR/components/form';
import { DialogHeading } from 'components/dialog-title';
import { useRequestNotify } from 'components/hooks';
import { IGridEditFormProps } from 'components/models';

import {
  API_USER_PATIENT_PROFILE_UPLOADED_FILES,
  UploadedFiles,
} from 'services/user-patient-profile-uploaded-files';
import { FileUploader } from 'components/form';
import { usePermissions } from 'hooks/use-permissions';
import { API_MEDIA_PRIVATE } from 'services/media-private-services';
import { useTranslate } from 'hooks/use-translate';
import { Controller, FormProvider, useFormContext } from 'react-hook-form';
import { useFieldProps } from 'hooks';
import { AppInput } from 'components/app-input';
import { AppCheckbox } from 'components/app-checkbox';
import { getFileNameExt } from 'utils/file-uploader';
import { SelectEmployee } from 'components/select-employee';

type FormModel = UploadedFiles;

const acceptsImages = ['png', 'jpg', 'jpeg'];
const fileAccept = ['pdf', 'doc', 'docx', ...acceptsImages].join(',');

interface FormProps {
  isLoading: boolean;
}
const Form: React.FC<FormProps> = ({ isLoading }) => {
  const { t } = useTranslate();
  const { control, errors, setValue } = useFormContext<FormModel>();
  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

  const onCheckFileName = useCallback(
    (fileName: string | undefined) => {
      if (!fileName) return;
      const ext = getFileNameExt(fileName);
      if (!ext) return;

      const isImage = acceptsImages.includes(ext);

      if (!isImage) return;

      setValue('isImage', true);
    },
    [setValue],
  );

  return (
    <>
      <Controller
        control={control}
        name={'userEmployeeProfileID'}
        rules={validateRule('required')}
        render={(renderProps) => (
          <SelectEmployee
            {...getFieldProps(renderProps)}
            label={t('employee')}
            disableClearable
            disabled={isLoading}
          />
        )}
      />
      <Controller
        control={control}
        name={'title'}
        rules={validateRule('required')}
        render={(renderProps) => (
          <AppInput {...getFieldProps(renderProps)} disableClearable disabled={isLoading} />
        )}
      />
      <FormController<UploadedFiles>
        name="fileURL"
        as={FileUploader}
        apiSet={API_MEDIA_PRIVATE.UPLOAD_FILE}
        apiRemove={API_MEDIA_PRIVATE.REMOVE_FILE}
        rules="requiredMixin"
        errors={errors}
        control={control}
        disabled={isLoading}
        fileAccept={fileAccept}
        renderProps={{ label: t('upload') }}
        onChange={(e) => {
          onCheckFileName(e?.target?.value?.value);

          return formControllerChangeParser(e);
        }}
      />
      <Controller
        control={control}
        name={'isImage'}
        render={(renderProps) => (
          <AppCheckbox {...getFieldProps(renderProps)} disabled={isLoading} />
        )}
      />
    </>
  );
};

interface Props extends IGridEditFormProps {
  userPatientProfileID: string;
}

export default memo(({ onClose, onFormHandle, itemID, userPatientProfileID }: Props) => {
  const { user } = useAuth();

  const config = useMemo<IUseForm<UploadedFiles>>((): any => {
    return {
      editID: itemID,
      fields: {
        userEmployeeProfileID: user.appUserID || '',
        title: '',
        fileURL: '',
        isImage: false,
      },
      endpoint: API_USER_PATIENT_PROFILE_UPLOADED_FILES.API_ENDPOINT,
      formHandle: onFormHandle,
    };
  }, [itemID, onFormHandle, user.appUserID]);
  const { formLoading, setFormLoading, formTitle, formIsNew, formUse, onSubmit, deleteItem } =
    useFormHook(config);
  // request noty wrapper
  const { requestWrap: wrapSubmit } = useRequestNotify({
    request: (data) => {
      return onSubmit({
        ...data,
        userPatientProfileID: userPatientProfileID,
        uploadedByPatient: false,
      });
    },
    setLoading: setFormLoading,
  });
  const { requestWrap: wrapDelete } = useRequestNotify({
    request: deleteItem,
    setLoading: setFormLoading,
  });
  // delete helper
  const { handleClose, handleConfirm, handleOpen, isOpenDelete } = useDeleteDialog({
    deleteMiddleware: wrapDelete,
  });
  const isAllowToDelete = usePermissions('isAllowToDeleteAnything');

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="xs">
      <DialogHeading title={' '} onClose={onClose} />
      {formLoading && <LinearProgress />}
      <DialogContent style={{ position: 'relative' }}>
        <FormProvider {...formUse}>
          <Form isLoading={formLoading} />
        </FormProvider>

        {isOpenDelete && isAllowToDelete && (
          <DialogBase
            title={formTitle}
            onClose={handleClose}
            onConfirm={handleConfirm}
            isLoading={formLoading}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Controls
          size="medium"
          state={!formIsNew ? 'save' : 'create'}
          loading={formLoading}
          onSubmit={formUse.handleSubmit(wrapSubmit)}
          onDelete={handleOpen}
          onCancel={onClose}
          showDelete={!formIsNew && isAllowToDelete}
        />
      </DialogActions>
    </Dialog>
  );
});
