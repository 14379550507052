import { schemaUserPatientProfile } from 'services/user-patient-profile';
import { Left } from 'utils/types';
import { InferType } from 'yup';
import { getSchemaFiledMaxLength } from 'utils/schema';

const schema = schemaUserPatientProfile;
export type PatientFormModel = Left<InferType<typeof schema>>;

export const getPatientFieldsMaxLength = (key: keyof PatientFormModel): number | undefined => {
  return getSchemaFiledMaxLength(schema, key);
};

export const PATIENT_CARD_TASK_QUERY_NAME = 'task';
