import React from 'react';
import { Stack } from 'components/stack';
import { SideEffectItem } from '../../models';
import { Button, Chip, Collapse } from '@material-ui/core';
import { getOptionLabel } from 'components/select-icd10';
import { useOpen } from 'AurionCR/components/hooks';
import { useTranslate } from 'hooks/use-translate';

interface Props {
  sideEffects: SideEffectItem[];
}
export const ColumnSideEffects: React.FC<Props> = ({ sideEffects }) => {
  const { isOpen, handleToggle } = useOpen();
  const { t } = useTranslate();
  const [first, ...rest] = sideEffects;
  return (
    <Stack direction={'column'}>
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <Chip size={'small'} label={getOptionLabel(first)} />
        {!isOpen && rest.length > 0 && (
          <Button onClick={handleToggle} color={'primary'} variant={'contained'} size={'small'}>
            {`+${rest.length}`}
          </Button>
        )}
      </Stack>
      <Collapse in={isOpen}>
        <Stack direction={'column'} spacing={1} py={1}>
          {rest.map((sideEffect, index) => {
            return (
              <div key={index}>
                <Chip size={'small'} label={getOptionLabel(sideEffect)} />
              </div>
            );
          })}
        </Stack>
      </Collapse>
      {isOpen && (
        <div>
          <Button onClick={handleToggle} color={'primary'} variant={'contained'} size={'small'}>
            {t('hide')}
          </Button>
        </div>
      )}
    </Stack>
  );
};
