
import axios from "axios"
import {apiStatic, setCookies} from "./helpers";
import {api} from "../../utils/service";

const updateDefaultsHeader = (token?: string) => {
  if (token) {
    [axios, apiStatic, api].forEach(item => {
      item.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    })
  } else {
    [axios, apiStatic, api].forEach(item => {
      delete item.defaults.headers.common['Authorization'];
    })
  }
};
export const updateTokenAll = ({token = '', refreshToken = '', expires = ''} = {}) => {
  // update cookie
  setCookies(window.authCookieName_, token, expires);
  setCookies(window.authRefreshCookieName_, refreshToken, expires);
  setCookies(window.authDateCookieName_, expires, expires);
  // update header
  updateDefaultsHeader(token);
};
