import React from 'react';
import DoctorIcon from 'components/svg-icons/doctor';
import { Badge } from '@material-ui/core';
import { useAppSelector } from 'store';
import { selectEmployeesMonitorEmployeesMustBeActiveCount } from 'store/employees-monitor';

export const IconEmployees: React.FC = () => {
  const count = useAppSelector(selectEmployeesMonitorEmployeesMustBeActiveCount);
  return (
    <Badge badgeContent={count} color={'error'}>
      <DoctorIcon />
    </Badge>
  );
};
