import { DateValue } from 'utils/dates';
import {
  API_USER_EMPLOYEE_PROFILE_WORK_LOGS,
  dateToDayOfWeek,
  UserEmployeeProfileWorkLogs,
} from './models';
import { DynamicService } from 'utils/service';
import { validateTimeSlot } from 'utils/app-helpers';
import { apiRtk, RTK_TAGS } from 'utils/rtk-query';
import { FixServerObject, PatchPartial } from 'utils/types';

export * from './models';

interface ValidateEmployeeTimeSlotInput {
  userEmployeeProfileID: string;
  date: DateValue;
  fromTime: string;
  toTime: string;
  isInClinic?: boolean;
}

class Service extends DynamicService<UserEmployeeProfileWorkLogs> {
  validateEmployeeTimeSlot = async (data: ValidateEmployeeTimeSlotInput) => {
    const { userEmployeeProfileID, date, fromTime, toTime, isInClinic } = data;
    const {
      data: { value },
    } = await this.getAllDynamic<
      Pick<UserEmployeeProfileWorkLogs, 'id' | 'dayOfWeek' | 'fromTime' | 'toTime'>
    >({
      select: ['id', 'dayOfWeek', 'fromTime', 'toTime'].join(','),
      filter: [
        `userEmployeeProfileID=="${userEmployeeProfileID}"`,
        `dayOfWeek==${dateToDayOfWeek(date)}`,
        isInClinic !== undefined ? `isInClinic==${isInClinic}` : null,
      ]
        .filter(Boolean)
        .join('&&'),
    });

    return validateTimeSlot({ fromTime, toTime }, value);
  };
}

export const ServiceUserEmployeeProfileWorkLogs = new Service({
  getAll: API_USER_EMPLOYEE_PROFILE_WORK_LOGS.GET_ALL_DYNAMIC,
  post: API_USER_EMPLOYEE_PROFILE_WORK_LOGS.POST,
  patch: API_USER_EMPLOYEE_PROFILE_WORK_LOGS.PATCH,
  delete: API_USER_EMPLOYEE_PROFILE_WORK_LOGS.DELETE,
});

interface ModelApiUserEmployeeProfileWorkLogs
  extends FixServerObject<UserEmployeeProfileWorkLogs, 'userEmployeeProfileID'> {}

export const apiUserEmployeeProfileWorkLogs = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    employeeProfileWorkLogs: builder.query<
      Pick<
        UserEmployeeProfileWorkLogs,
        'id' | 'fromTime' | 'toTime' | 'isInClinic' | 'dayOfWeek'
      >[],
      string
    >({
      queryFn: async (userEmployeeProfileID) => {
        try {
          const {
            data: { value },
          } = await ServiceUserEmployeeProfileWorkLogs.getAllDynamic({
            filter: `userEmployeeProfileID=="${userEmployeeProfileID}"`,
            select: ['id', 'fromTime', 'toTime', 'isInClinic', 'dayOfWeek'].join(','),
          });

          return { data: value };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (_, __, arg) => [
        { type: RTK_TAGS.EMPLOYEE_WORK_LOGS, id: ['employee', arg].join('__') },
      ],
    }),
    employeeProfileWorkLogPatch: builder.mutation<
      void,
      PatchPartial<ModelApiUserEmployeeProfileWorkLogs, 'id' | 'userEmployeeProfileID'>
    >({
      queryFn: async (args) => {
        try {
          await ServiceUserEmployeeProfileWorkLogs.patch(args);
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (_, __, arg) => [
        {
          type: RTK_TAGS.EMPLOYEE_WORK_LOGS,
          id: ['employee', arg.userEmployeeProfileID].join('__'),
        },
      ],
    }),
    employeeProfileWorkLogPost: builder.mutation<
      UserEmployeeProfileWorkLogs,
      Omit<ModelApiUserEmployeeProfileWorkLogs, 'id'>
    >({
      queryFn: async (args) => {
        try {
          const { data } = await ServiceUserEmployeeProfileWorkLogs.post(args);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (_, __, arg) => [
        {
          type: RTK_TAGS.EMPLOYEE_WORK_LOGS,
          id: ['employee', arg.userEmployeeProfileID].join('__'),
        },
      ],
    }),
    employeeProfileWorkLogDelete: builder.mutation<
      UserEmployeeProfileWorkLogs,
      PatchPartial<ModelApiUserEmployeeProfileWorkLogs, 'id' | 'userEmployeeProfileID'>
    >({
      queryFn: async (args) => {
        try {
          const { data } = await ServiceUserEmployeeProfileWorkLogs.delete(args);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (_, __, arg) => [
        {
          type: RTK_TAGS.EMPLOYEE_WORK_LOGS,
          id: ['employee', arg.userEmployeeProfileID].join('__'),
        },
      ],
    }),
  }),
});
