import React, { memo, useCallback, useMemo, useState } from 'react';
import style from './index.module.scss';
import { format } from 'date-fns';
import { Round } from 'AurionCR/components';
import clsx from 'clsx';
import { Box, BoxProps } from '@material-ui/core';
import { convertToDate } from 'utils/dates';
import { TimeSlot } from 'utils/app-helpers';
import { useAppSelector } from 'store';
import { selectLanguageIsRtl } from 'store/languages';

interface ITime {
  id: string;
  decimal: number;
}

interface Props {
  startY: number;
  rows: number;
  cellWidth: number;
  cellHeight: number;
  meetingFromDateTime: string | Date;
  meetingToDateTime: string | Date;
  startIndex: number;
  times: ITime[];
  onCancel: () => void;
  onChange: (value: { meetingFromDateTime: Date; meetingToDateTime: Date }) => void;
  canResize: (timeSlot: TimeSlot) => boolean;
  ItemProps?: Partial<BoxProps>;
}
export const MeetingResizer = memo<Props>(
  ({
    startY,
    startIndex,
    meetingFromDateTime,
    meetingToDateTime,
    cellHeight,
    cellWidth,
    times,
    rows,
    onCancel,
    onChange,
    canResize,
    ItemProps,
  }) => {
    const isRtl = useAppSelector(selectLanguageIsRtl);
    const fromTime = useMemo(
      () => format(new Date(meetingFromDateTime), 'HH:mm'),
      [meetingFromDateTime],
    );
    const position = useMemo(() => {
      let x = startIndex;
      let y = times.findIndex(({ id }) => id === fromTime);

      x = x !== -1 ? x : 0;
      y = y !== -1 ? y : 0;
      return {
        x,
        left: x * cellWidth,
        y,
        // +1 because exist separator and border
        top: (y + 1) * cellHeight + 1,
      };
    }, [cellHeight, cellWidth, times, startIndex, fromTime]);

    const startHeight = rows * cellHeight;

    const [height, setHeight] = useState(rows);
    const [isFinished, setIsFinished] = useState(false);

    const endTime = useMemo(() => times[position.y + height].id, [times, position, height]);

    const isValid = useMemo(() => {
      return canResize({ fromTime, toTime: endTime });
    }, [fromTime, endTime, canResize]);

    const handleMouseLeave = useCallback(() => {
      if (!isFinished) onCancel();
    }, [onCancel, isFinished]);
    const handleMouseUp = useCallback(() => {
      setIsFinished(true);
      const date = new Date(meetingToDateTime);
      const currentTime = format(date, 'HH:mm');

      if (currentTime === endTime || !isValid) {
        return onCancel();
      }

      const newMeetingToDateTime = new Date(`${format(date, 'yyyy-MM-dd')}T${endTime}`);

      onChange({
        meetingFromDateTime: convertToDate(meetingFromDateTime),
        meetingToDateTime: newMeetingToDateTime,
      });
    }, [endTime, meetingFromDateTime, meetingToDateTime, onCancel, onChange, isValid]);

    const handleMouseMove = useCallback<React.MouseEventHandler>(
      (e) => {
        if (isFinished) {
          return;
        }
        setHeight(Math.max(Round((startHeight + e.clientY - startY) / cellHeight, 1), 1));
      },
      [setHeight, startY, startHeight, isFinished, cellHeight],
    );

    return (
      <div
        className={clsx(style.root, isValid ? style.valid : style.invalid)}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        {position.x !== -1 && position.y !== -1 && (
          <Box
            {...ItemProps}
            className={clsx(style.item, ItemProps?.className)}
            style={{
              ...ItemProps?.style,
              height: height * cellHeight,
              left: isRtl ? 'auto' : position.left,
              right: isRtl ? position.left : 'auto',
              top: position.top,
              width: cellWidth,
            }}
          >
            <div className={clsx(style.time, style.top)}>{times[position.y].id}</div>
            <div className={clsx(style.time, style.bottom)}>{endTime}</div>
          </Box>
        )}
      </div>
    );
  },
);
