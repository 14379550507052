import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { useTranslate } from 'hooks/use-translate';
import { NativeScroll } from 'components/native-scroll';
import { ListFutureClinicalMeetings } from '../list-future-clinical-meetings';
import { apiClinicalMeetings } from 'services/clinical-meetings';
import { Stack } from 'components/stack';
import { Alert, AlertTitle } from '@material-ui/lab';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import { useCurrentUser } from 'components/hooks';
import { DialogConfirm } from 'components/dialog-confirm';
import { useOpen } from 'AurionCR/components/hooks';
import { composeFunctions } from 'utils';
interface StateProgressProps {
  title: string;
}
const StateProgress: React.FC<StateProgressProps> = ({ title }) => {
  return (
    <Stack direction={'column'} alignItems={'center'} spacing={1}>
      <Typography variant={'h4'} color={'primary'}>
        {title}
      </Typography>
      <CircularProgress />
    </Stack>
  );
};

const useLazyFetchMeetingsQuery = apiClinicalMeetings.useLazyGetPatientTodayClinicalMeetingsQuery;

interface Props {
  userPatientProfileID: string;
  onClose: () => void;
  onContinueEmpty: () => void;
  onContinueWithMeeting: (meeting: { id: string }) => void;
}

export const DialogSelectClinicalMeetings: React.FC<Props> = ({
  userPatientProfileID,
  onClose,
  onContinueEmpty,
  onContinueWithMeeting,
}) => {
  const { userEmployeeProfilePermissionID } = useCurrentUser();
  const { t, tp } = useTranslate();

  const [triggerMeeting, resultMeetings] = useLazyFetchMeetingsQuery();

  useEffect(() => {
    let process = true;

    const performProcess = async () => {
      const result = await triggerMeeting({
        userPatientProfileID,
        userEmployeeProfilePermissionID,
      });

      if (!process) return;

      // there are no meetings
      if (result.isSuccess && result.data?.length === 0) {
        onContinueEmpty();
      }
    };

    performProcess();
    return () => {
      process = false;
    };
  }, [triggerMeeting, userPatientProfileID, onContinueEmpty, userEmployeeProfilePermissionID]);

  const meetings = useMemo(() => {
    return resultMeetings.data || [];
  }, [resultMeetings.data]);

  const isLoading = resultMeetings.isFetching;
  const isInitLoading = resultMeetings.isLoading && meetings.length === 0;

  const onSelectMeeting = useCallback(
    (meetingID: string | null) => {
      if (!meetingID) {
        return;
      }
      onContinueWithMeeting({ id: meetingID });
    },
    [onContinueWithMeeting],
  );

  const stateProgressTitle = useMemo(() => {
    if (isInitLoading) {
      return tp('future-clinical-meeting-fetching');
    }
    return null;
  }, [isInitLoading, tp]);

  const error = resultMeetings.error;
  const hasMeetings = meetings.length !== 0;

  const confirmState = useOpen();

  return (
    <>
      <Dialog open={true} maxWidth={'xs'} fullWidth onClose={onClose}>
        <DialogHeading
          isLoading={isLoading}
          title={tp('future-clinical-meeting')}
          onClose={onClose}
        />
        <DialogContent>
          <Collapse in={Boolean(error)}>
            <Alert severity="error">
              <AlertTitle>{tp('error')}</AlertTitle>
              {error?.message}
            </Alert>
          </Collapse>
          {hasMeetings && (
            <Stack direction={'column'}>
              <Typography color={'secondary'} variant={'body1'} style={{ fontWeight: 600 }}>
                {t('select-from-list')}
              </Typography>
              <NativeScroll style={{ maxHeight: '30rem' }} mode={'visible'}>
                <ListFutureClinicalMeetings
                  selectedID={null}
                  disabled={false}
                  list={meetings}
                  onSelect={onSelectMeeting}
                />
              </NativeScroll>
            </Stack>
          )}
          {!hasMeetings && stateProgressTitle && <StateProgress title={stateProgressTitle} />}
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<QueuePlayNextIcon />}
            disabled={isLoading}
            onClick={confirmState.handleOpen}
            color={'primary'}
            variant={'contained'}
          >
            {tp('out-of-schedule-meeting')}
          </Button>
        </DialogActions>
      </Dialog>
      {confirmState.isOpen && (
        <DialogConfirm
          title={tp('out-of-schedule-meeting-confirm-title')}
          message={tp('out-of-schedule-meeting-confirm-description')}
          onConfirm={composeFunctions(confirmState.handleClose, onContinueEmpty)}
          onCancel={confirmState.handleClose}
        />
      )}
    </>
  );
};
