import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { IGridEditFormProps } from 'components/models';
import { IUseForm, useFormHook } from 'AurionCR/components/formV2';
import {
  API_USER_PATIENT_PROFILE_PRESCRIPTIONS,
  apiUserPatientPrescriptions,
  iUserPatientProfilePrescription,
  ServicePatientPrescription,
} from 'services/user-patient-profile-prescriptions';
import { useCurrentUser, useRequestNotify } from 'components/hooks';
import { useDeleteDialog } from 'AurionCR/components/form';
import { DialogHeading } from 'components/dialog-title';
import { Box, Button, Dialog, DialogActions } from '@material-ui/core';
import { DialogBase, Loading } from 'AurionCR/components';
import { GridMedicalPrescriptionsDetails } from '../grid-details';
import DeleteIcon from '@material-ui/icons/Delete';
import { useAppSelector } from 'store';
import { PatchPartial } from 'utils/types';
import { ServiceSiteParameters } from 'services/site-parameters';
import { ServicePdf } from 'services/pdf';
import { ServicePatientPrescriptionDetails } from 'services/user-patient-profile-prescription-details';
import { useEffectError, useMutationDynamic } from 'hooks';
import { MedicalPrescriptionForm } from '../medical-prescription-form';
import { usePermissions } from 'hooks/use-permissions';
import style from './index.module.scss';
import clsx from 'clsx';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { useTranslate } from 'hooks/use-translate';
import {
  MedicalPrescriptionDetailsProvider,
  useMedicalPrescriptionDetailsContext,
  useMedicalPrescriptionDetailsWithRules,
} from '../details-context';
import { Stack } from 'components/stack';
import { ShortRemark } from 'components/short-remark';
import { debounce } from 'lodash-es';
import { Panel, PanelGroup } from 'react-resizable-panels';
import { useOpen } from 'AurionCR/components/hooks';
import { DialogRules29 } from '../dialog-rules-29';
import { parseErrorData } from 'utils/service';
import { InformationSection } from '../information-section';
import { AppPanelResizeHandle } from 'components/app-panel-resize-handle';
import { EffectsSection } from '../effects-section';
import { DialogValidityPatientFields } from '../dialog-validity-patient-fields';
import { selectLanguageID } from 'store/languages';
import { dateFormat } from 'utils/dates';
import { MedicalPrescriptionProgramInfoDate } from '../program-info-date';
import { TemplatePatientFullName } from 'components/templates/template-patient-full-name';

export type MedicalPrescriptionEvents =
  | { type: 'generate'; payload: { id: string } }
  | { type: 'generate-error'; payload: { id: string; error: Error } }
  | { type: 'refresh' }
  | { type: 'cancel'; payload: iUserPatientProfilePrescription }
  | { type: 'documents-created' }
  | { type: 'documents-created-error'; payload: { error: Error } };

type FormModel = PatchPartial<Required<iUserPatientProfilePrescription>, 'id'>;

export interface MedicalPrescriptionFormEditorProps extends IGridEditFormProps {
  userPatientProfileID: string;
  onEvent?: (event: MedicalPrescriptionEvents) => void;
}

const MedicalPrescriptionFormEditorComponent = memo<MedicalPrescriptionFormEditorProps>(
  ({ userPatientProfileID, onClose, onFormHandle, itemID, onEvent }) => {
    const { t, tp } = useTranslate();

    const { appUserID } = useCurrentUser();

    const { rows } = useMedicalPrescriptionDetailsContext();
    const total = rows.length;

    const hasForm29 = useMemo(() => {
      return rows.some((value) => value.includeForm29);
    }, [rows]);

    const languageID = useAppSelector(selectLanguageID);

    const config = useMemo<IUseForm<iUserPatientProfilePrescription>>(() => {
      return {
        editID: itemID,
        fields: {
          id: '',
          entryDate: new Date().toISOString(),
          userEmployeeProfileID: '',
          userPatientProfileID: '',
          prescriptionFilePath: '',
          remarks: '',
          isActive: true,
          isCompleted: false,
        },
        endpoint: API_USER_PATIENT_PROFILE_PRESCRIPTIONS.API_ENDPOINT,
        formHandle: onFormHandle,
        get: {
          parser: ({ value }) => {
            const data = value[0] || {};
            if (!data?.remarks) data.remarks = t('prescription-validity');
            data.entryDate = new Date().toISOString();
            return data;
          },
        },
      };
      //eslint-disable-next-line
    }, [itemID, onFormHandle]);
    const { formLoading, setFormLoading, formIsNew, initData, formUse, deleteItem } =
      useFormHook(config);

    const { requestWrap: wrapDelete } = useRequestNotify({
      request: deleteItem,
      setLoading: setFormLoading,
    });

    const { handleClose, handleConfirm, handleOpen, isOpenDelete } = useDeleteDialog({
      deleteMiddleware: wrapDelete,
    });

    const { error, data } = apiUserPatientPrescriptions.useGetMedicalPrescriptionTitleQuery(
      String(itemID),
      { skip: typeof itemID === 'boolean' || !itemID },
    );
    useEffectError(error);

    const requestGeneratePDF = useCallback(
      async (formData: FormModel) => {
        const id = String(itemID);
        try {
          await ServicePatientPrescription.patch(formData);

          onEvent && onEvent({ type: 'generate', payload: { id } });

          const [
            { data: siteParams },
            {
              data: { doctor, remarks, entryDate, drugs, patient },
            },
          ] = await Promise.all([
            ServiceSiteParameters.getMainInfo(languageID),
            ServicePatientPrescription.getPdfData(itemID as string),
          ]);

          const { withWatermark, withoutWatermark, forPrint } =
            await ServicePdf.generatePrescriptionPatient({
              payload: { siteParams, remarks, doctor, entryDate, drugs, patient },
            });

          await ServicePatientPrescription.generatePrescriptionDocuments({
            id: String(itemID),
            pdfWithWatermark: withWatermark,
            pdfWithoutWatermark: withoutWatermark,
            pdfPrint: forPrint,
            entryDate,
            patientFullName: String(patient.fullName),
          });

          // generate gimel 29 documents
          const backgroundTaskGimel = async () => {
            try {
              await ServicePatientPrescriptionDetails.createDocumentsGimel29(formData.id);

              onEvent && onEvent({ type: 'documents-created' });
            } catch (e: any) {
              onEvent &&
                onEvent({ type: 'documents-created-error', payload: { error: parseErrorData(e) } });
            }
          };

          // create a notebook for drugs
          const backgroundTaskNotebook = () => {
            ServicePatientPrescription.generateNotebook({
              userEmployeeProfileID: appUserID,
              userPatientProfilePrescriptionID: formData.id,
            });
          };

          backgroundTaskGimel();
          backgroundTaskNotebook();
          // background task - end
        } catch (e: any) {
          await ServicePatientPrescription.patch(formData);
          onEvent && onEvent({ type: 'generate-error', payload: { id, error: parseErrorData(e) } });
          throw e;
        }
      },
      [languageID, itemID, onEvent, appUserID],
    );

    const [triggerGeneratePDF, { error: errorGeneratePDF, isLoading: isLoadingCreatePdf }] =
      useMutationDynamic(requestGeneratePDF);
    useEffectError(errorGeneratePDF);

    const handleCancel = useCallback(
      (event?: object, reason?: string) => {
        if (!['escapeKeyDown', 'backdropClick'].includes(reason || '')) {
          onEvent &&
            onEvent({ type: 'cancel', payload: initData as iUserPatientProfilePrescription });
          onClose();
        }
      },
      [onClose, onEvent, initData],
    );
    const isAllowToDelete = usePermissions('isAllowToDeleteAnything');
    const isLoading = isLoadingCreatePdf || formLoading;

    const title = useMemo(() => {
      return [data?.fullName, data?.idNumber, dateFormat(data?.entryDate, APP_FORMAT_DATE_TIME)]
        .filter(Boolean)
        .join(' ');
    }, [data]);

    const formTitleWrap = useMemo(() => {
      const maintenanceWeight = data?.maintenanceWeight;
      return (
        <Stack spacing={1} alignItems={'center'}>
          <TemplatePatientFullName variant={'h4'} patient={data}>
            {title}
          </TemplatePatientFullName>
          <div>
            <MedicalPrescriptionProgramInfoDate userPatientProfileID={userPatientProfileID} />
          </div>
          {maintenanceWeight && (
            <div>
              <ShortRemark>
                {tp('maintenance-weight')}: {maintenanceWeight}
              </ShortRemark>
            </div>
          )}
          {hasForm29 && <ShortRemark priority={'high'}>{tp('includes-form-29')}</ShortRemark>}
        </Stack>
      );
    }, [hasForm29, title, tp, data, userPatientProfileID]);

    // auto-save
    const { handleSubmit } = formUse;
    const onSubmitDebounce = useMemo(
      () => debounce(handleSubmit(ServicePatientPrescription.patch), 300),
      [handleSubmit],
    );

    const onRefreshPrescriptions = useCallback(() => {
      onEvent && onEvent({ type: 'refresh' });
    }, [onEvent]);

    const drugsWith29Rules = useMedicalPrescriptionDetailsWithRules();

    const stateCheckPatientFields = useOpen();
    const doneCheckPatientFields = useCallback(() => {
      stateCheckPatientFields.handleClose();
      return handleSubmit(triggerGeneratePDF)();
    }, [stateCheckPatientFields, handleSubmit, triggerGeneratePDF]);
    const runCheckPatientFields = useCallback(() => {
      if (hasForm29) {
        return stateCheckPatientFields.handleOpen();
      }
      doneCheckPatientFields();
    }, [hasForm29, stateCheckPatientFields, doneCheckPatientFields]);
    const refRunCheckPatientFields = useRef(runCheckPatientFields);
    useEffect(() => {
      refRunCheckPatientFields.current = runCheckPatientFields;
    }, [runCheckPatientFields]);

    const stateCheckRules = useOpen();
    const doneCheckRules = useCallback(() => {
      stateCheckRules.handleClose();
      handleSubmit(refRunCheckPatientFields.current)();
    }, [stateCheckRules, handleSubmit, refRunCheckPatientFields]);

    const runCheckRules = useCallback(() => {
      if (drugsWith29Rules.length) {
        return stateCheckRules.handleOpen();
      }
      doneCheckRules();
    }, [drugsWith29Rules, stateCheckRules, doneCheckRules]);
    const onSubmit = runCheckRules;

    return (
      <>
        <Dialog
          open={true}
          onClose={handleCancel}
          disableEscapeKeyDown={true}
          fullWidth
          //@ts-ignore
          maxWidth="xxl"
          PaperProps={{
            className: clsx(style.paper),
          }}
        >
          <div>
            <DialogHeading isLoading={isLoading} title={formTitleWrap} onClose={handleCancel} />
          </div>
          <PanelGroup direction={'horizontal'} className={style.panel}>
            <Panel defaultSize={65} minSize={40}>
              <Box p={2} height={'100%'} display={'grid'}>
                <MedicalPrescriptionForm
                  userPatientProfileID={userPatientProfileID ?? undefined}
                  formUse={formUse}
                  formLoading={isLoading}
                  formIsNew={formIsNew}
                  onWasChanged={onSubmitDebounce}
                  slotBottom={<EffectsSection userPatientProfileID={userPatientProfileID} />}
                >
                  {initData && userPatientProfileID && (
                    <GridMedicalPrescriptionsDetails
                      userPatientProfileID={userPatientProfileID ?? undefined}
                      userPatientProfilePrescriptionID={initData.id as string}
                      onRefreshPrescriptions={onRefreshPrescriptions}
                    />
                  )}
                </MedicalPrescriptionForm>
              </Box>
            </Panel>
            <AppPanelResizeHandle />
            <Panel defaultSize={35} maxSize={60}>
              <Box p={2} pt={'1rem'} height={'100%'}>
                <InformationSection userPatientProfileID={userPatientProfileID} />
              </Box>
            </Panel>
          </PanelGroup>
          <DialogActions className="form-edit-controls">
            <div className="left-side">
              {isAllowToDelete && (
                <Button
                  className="btn-delete"
                  variant="contained"
                  color="secondary"
                  onClick={handleOpen}
                  disabled={isLoading}
                  startIcon={<DeleteIcon />}
                />
              )}
            </div>
            <div className="right-side">
              <Button
                className="btn-cancel"
                onClick={handleCancel}
                color="secondary"
                disabled={isLoading}
              >
                {t('cancel')}
              </Button>
              <Button
                className="btn-create-and-continue"
                variant="contained"
                color="primary"
                onClick={formUse.handleSubmit(onSubmit)}
                disabled={isLoading || total === 0}
              >
                {t('generate')}
              </Button>
            </div>
          </DialogActions>
          {isLoading && <Loading />}
        </Dialog>
        {isOpenDelete && isAllowToDelete && (
          <DialogBase
            title={title}
            onClose={handleClose}
            onConfirm={handleConfirm}
            isLoading={isLoading}
          />
        )}
        {stateCheckPatientFields.isOpen && (
          <DialogValidityPatientFields
            userPatientProfileID={userPatientProfileID}
            onClose={stateCheckPatientFields.handleClose}
            onDone={doneCheckPatientFields}
          />
        )}
        {stateCheckRules.isOpen && (
          <DialogRules29 onClose={stateCheckRules.handleClose} onDone={doneCheckRules} />
        )}
      </>
    );
  },
);

export const MedicalPrescriptionFormEditor = memo<MedicalPrescriptionFormEditorProps>((props) => {
  const { userPatientProfileID } = props;
  return (
    <MedicalPrescriptionDetailsProvider userPatientProfileID={userPatientProfileID}>
      <MedicalPrescriptionFormEditorComponent {...props} />
    </MedicalPrescriptionDetailsProvider>
  );
});
