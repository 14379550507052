import React from 'react';
import { IconButton, Link, LinkProps } from '@material-ui/core';
import { Stack, StackProps } from 'components/stack';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { CopyPathToClipboard } from 'AurionCR/components';
import style from './index.module.scss';
import clsx from 'clsx';
import { useTranslate } from 'hooks/use-translate';

interface Classes {
  root: string;
  link: string;
  icon: string;
}

interface Props extends Omit<LinkProps, 'classes'> {
  value?: string | null | undefined;
  classes?: Partial<Classes>;
  StackProps?: Partial<StackProps>;
}

export const TemplateLinkCopy: React.FC<Props> = ({
  value,
  StackProps,
  classes,
  href,
  ...rest
}) => {
  const { t } = useTranslate();

  return (
    <Stack
      spacing={1}
      alignItems={'center'}
      className={clsx(style.root, classes?.root, StackProps?.className)}
      {...StackProps}
    >
      <Link
        noWrap
        target={'_blank'}
        href={href}
        {...rest}
        className={clsx(style.root, rest.className, classes?.link)}
      >
        {value || href}
      </Link>
      <CopyPathToClipboard text={href || ''} message={t('copied')}>
        <IconButton disabled={!href} className={clsx(style.root, classes?.icon)}>
          <FileCopyIcon />
        </IconButton>
      </CopyPathToClipboard>
    </Stack>
  );
};
