import { Box, BoxProps, Tab, TabProps, Tabs, TabsProps } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import style from './index.module.scss';

interface TabPanelProps extends BoxProps {
  isActive: boolean;
  optimization?: boolean;
  keepInMemory?: boolean;
}

const TabPanel: React.FC<TabPanelProps> = ({
  isActive,
  optimization = true,
  children,
  className,
  keepInMemory = true,
  ...rest
}) => {
  const [isWasActive, setIsWasActive] = useState(!optimization);

  useEffect(() => {
    if (isActive) {
      setIsWasActive(true);
    }
  }, [isActive]);

  if (!isWasActive) return null;
  if (!keepInMemory && !isActive) return null;

  return (
    <Box
      className={clsx(style.panel, className, { [style.panelHidden]: !isActive })}
      hidden={!isActive}
      {...rest}
    >
      {children}
    </Box>
  );
};

export interface TabItem<T> {
  value: T;
  title: React.ReactNode;
  TabProps?: Partial<TabProps>;
  element: React.ReactNode;
  optimization?: boolean;
  keepInMemory?: boolean;
  error?: boolean;
  wrapperProps?: Partial<BoxProps>;
}

interface Classes {
  root: string;
  header: string;
  content: string;
  tab: string;
  tabs: string;
}

interface Slots {
  tabsTop: React.ReactNode;
  tabsBottom: React.ReactNode;
}

interface Props<TabValue> {
  keepInMemory?: boolean;
  className?: string;
  classes?: Partial<Classes>;
  tabs: TabItem<TabValue>[];
  value: TabValue;
  onChange: (value: this['value']) => void;
  children?: React.ReactNode;
  TabsProps?: Partial<TabsProps>;
  TabPanelProps?: Partial<BoxProps>;
  RootProps?: Partial<BoxProps>;
  slots?: Partial<Slots>;
}

export const TabsWrapper = <TabValue extends string>({
  keepInMemory,
  className,
  TabsProps,
  TabPanelProps,
  RootProps,
  classes,
  tabs,
  value,
  onChange,
  slots,
  children,
}: Props<TabValue>) => {
  return (
    <Box
      className={clsx(style.root, classes?.root, className, RootProps?.className)}
      {...RootProps}
    >
      <div className={clsx(style.header, classes?.header)}>
        {slots?.tabsTop}
        {children}
        <Tabs
          variant={'scrollable'}
          scrollButtons={'auto'}
          indicatorColor={'secondary'}
          {...TabsProps}
          value={value}
          onChange={(e, v) => onChange(v)}
          className={clsx(classes?.tabs)}
        >
          {tabs.map((tab) => {
            return (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.title}
                {...tab?.TabProps}
                className={clsx(style.tab, tab?.TabProps?.className, classes?.tab, {
                  [style.tabError]: tab.error,
                })}
              />
            );
          })}
        </Tabs>
        {slots?.tabsBottom}
      </div>
      <div className={clsx(style.content, classes?.content)}>
        {tabs.map((tab) => {
          const __keepInMemory = tab.keepInMemory === undefined ? keepInMemory : tab.keepInMemory;
          return (
            <TabPanel
              key={tab.value}
              optimization={tab.optimization}
              isActive={value === tab.value}
              keepInMemory={__keepInMemory}
              {...TabPanelProps}
            >
              {tab.element}
            </TabPanel>
          );
        })}
      </div>
    </Box>
  );
};
