import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'PrescriptionSteps';

export const API_PRESCRIPTION_STEPS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<PrescriptionStep, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<PrescriptionStep, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export enum PRESCRIPTION_STEP_TYPE_KEY {
  DRAFT = 'step-doc-draft',
  SIGNED = 'step-signed-documents',
  READY = 'step-ready',
}

export interface PrescriptionStep
  extends FixServerObject<Components.Schemas.PrescriptionStep, 'id' | 'title' | 'icon' | 'color'> {
  stepTypeKey: PRESCRIPTION_STEP_TYPE_KEY;
}
