import React, { useCallback, useMemo } from 'react';
import { useTheme } from '@material-ui/core';
import {
  PreviewClinicalDrugs,
  PreviewSessionEffects,
  PreviewSessionItems,
} from 'components/preview-session-effects';
import Masonry from 'react-responsive-masonry';
import { useTranslate } from 'hooks/use-translate';
import { apiUserPatientProfileSessions } from 'services/user-patient-profile-session';

type RenderPreviewProps = {
  title: string;
  items: { id: string; title: string }[] | undefined;
  remarks: string | null | undefined;
};

interface Props {
  userPatientProfileSessionID: string;
}
export const PreviewSessionsBlock: React.FC<Props> = ({ userPatientProfileSessionID }) => {
  const { t } = useTranslate();
  const resultPreview = apiUserPatientProfileSessions.useGetSessionForNotebookGeneratorPreviewQuery(
    userPatientProfileSessionID,
  );

  const renderPreview = useCallback(
    (renderProps: RenderPreviewProps) => {
      return (
        <PreviewSessionEffects
          notebook={resultPreview.data?.notebook.labelKey}
          employee={resultPreview.data?.userEmployeeProfile.fullName}
          date={resultPreview.data?.createdDate}
          title={renderProps.title}
          items={renderProps.items || []}
          isLoading={resultPreview.isFetching}
          remarks={renderProps.remarks ?? undefined}
        />
      );
    },
    [resultPreview.data, resultPreview.isFetching],
  );

  const sessionItems = useMemo(() => {
    if (!resultPreview.data?.sessionItems) {
      return [];
    }
    return resultPreview.data.sessionItems.map((item) => {
      return {
        label: String(item.labelKey),
        value: item.entryValueString,
      };
    });
  }, [resultPreview.data]);

  const { spacing } = useTheme();

  return (
    <Masonry columnsCount={2} gutter={`${spacing(1)}px`}>
      <PreviewSessionItems title={t('session-items')} items={sessionItems} />
      <PreviewClinicalDrugs
        title={t('clinical-drugs')}
        items={resultPreview.data?.clinicalDrugs || []}
      />
      {renderPreview({
        title: t('icd10s'),
        items: resultPreview.data?.icd10PreviewItems,
        remarks: resultPreview.data?.icd10Remarks,
      })}
      {renderPreview({
        title: t('side-effects'),
        items: resultPreview.data?.sideEffectPreviewItems,
        remarks: resultPreview.data?.sideEffectRemarks,
      })}
      {renderPreview({
        title: t('surgeries'),
        items: resultPreview.data?.surgeryPreviewItems,
        remarks: resultPreview.data?.surgeryRemarks,
      })}
      {renderPreview({
        title: t('sensitivities'),
        items: resultPreview.data?.sensitivityPreviewItems,
        remarks: resultPreview.data?.sensitivityRemarks,
      })}
      {renderPreview({
        title: t('sensitivity-drugs'),
        items: resultPreview.data?.sensitivityDrugPreviewItems,
        remarks: resultPreview.data?.sensitivityDrugRemarks,
      })}
      {renderPreview({
        title: t('routine-drugs'),
        items: resultPreview.data?.regularDrugPreviewItems,
        remarks: resultPreview.data?.routineDrugRemarks,
      })}
      {renderPreview({
        title: t('past-drugs-for-obesity'),
        items: resultPreview.data?.pastDrugPreviewItems,
        remarks: resultPreview.data?.pastDrugForObesityRemarks,
      })}
    </Masonry>
  );
};
