import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react';
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core';
import { Loading, useSource } from 'AurionCR/components';
import { validateRule } from 'AurionCR/components/formV2';
import { DialogHeading } from 'components/dialog-title';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useCurrentUser } from 'components/hooks';
import { API_SMS_CONTENTS, SMSContents } from 'services/sms-contents';
import { Autocomplete } from '@material-ui/lab';
import style from './index.module.scss';
import { useAppSelector } from 'store';
import clsx from 'clsx';
import { useTranslate } from 'hooks/use-translate';
import { useEffectError, useFieldProps, useMutationDynamic } from 'hooks';
import { AppInput } from 'components/app-input';
import { calcHtmlTemplate } from 'utils';
import { UserPatientProfile } from 'services/user-patient-profile';
import { api, generateDynamicQuery } from 'utils/service';
import { Left } from 'utils/types';
import { mergeFilters } from 'utils/dynamic-helpers';
import { selectLanguageID } from 'store/languages';

type TemplateOption = Pick<SMSContents, 'id' | 'title' | 'message' | 'smsKey'>;
const useSourceTemplates = () => {
  const { userEmployeeProfilePermission } = useCurrentUser();
  const languageID = useAppSelector(selectLanguageID);
  const userEmployeeProfilePermissionID = userEmployeeProfilePermission?.id;

  const query = useMemo(
    () =>
      generateDynamicQuery({
        select: ['id', 'title', 'message', 'smsKey'].join(','),
        orderBy: ['title asc'].join(','),
        filter: mergeFilters(
          `languageID=="${languageID}"`,
          userEmployeeProfilePermissionID &&
            `userEmployeeProfilePermissionSmsContents.any(k =>k.userEmployeeProfilePermissionID=="${userEmployeeProfilePermissionID}")`,
        ).join('&&'),
      }),
    [languageID, userEmployeeProfilePermissionID],
  );

  return useSource<TemplateOption>(`${API_SMS_CONTENTS.GET_ALL_DYNAMIC}?${query}`);
};

export interface SmsSenderModel {
  toPhoneNumber: string;
  userPatientProfileID?: string;
  leadID?: string;
  message?: string;
}

export interface SmsSenderTemplatesPayload {
  meetingFromTime: string;
  meetingFromDate: string;

  meetingToTime: string;
  meetingToDate: string;

  patient: Pick<UserPatientProfile, 'firstName' | 'lastName'>;
  lead: { firstName: string; lastName: string };
  currentEmployee: Pick<UserPatientProfile, 'firstName' | 'lastName'>;
}

interface FormSMSSenderProps {
  isLoading: boolean;
  templatesPayload?: Partial<SmsSenderTemplatesPayload> | null;
}

type FormModel = Left<SmsSenderModel>;
export const FormSMSSender: React.FC<FormSMSSenderProps> = ({ isLoading, templatesPayload }) => {
  const { control, errors, register, setValue } = useFormContext<FormModel>();
  const { t } = useTranslate();

  const sourceTemplates = useSourceTemplates();
  const getInputProps = useFieldProps({ errors, emptyHelperText: '' });

  const messageRef = useRef<HTMLInputElement>(null);
  const onSelectTemplate = useCallback(
    (_: any, value: TemplateOption | null) => {
      const message = calcHtmlTemplate(value?.message || '', templatesPayload ?? undefined);
      setValue('message', message);
      messageRef.current?.focus();
    },
    [setValue, templatesPayload],
  );

  const isDisabledTemplate = sourceTemplates.data.length === 0 || isLoading;

  return (
    <>
      {register && <input {...register('leadID')} type="hidden" />}
      {register && <input {...register('userPatientProfileID')} type="hidden" />}
      <Controller
        control={control}
        name={'toPhoneNumber'}
        rules={validateRule('required')}
        render={(renderProps) => (
          <AppInput
            {...getInputProps(renderProps)}
            label={t('mobile-phone')}
            disabled={isLoading}
          />
        )}
      />
      <div className={clsx(style.selectTemplate, isDisabledTemplate && style.disabled)}>
        <Autocomplete
          options={sourceTemplates.data}
          loading={sourceTemplates.loading}
          value={null}
          getOptionLabel={(option) => option.title || ''}
          renderOption={(option) => option.title}
          onChange={onSelectTemplate}
          disabled={isDisabledTemplate}
          clearOnBlur={true}
          clearOnEscape={true}
          renderInput={(params) => <TextField {...params} label={t('choose-a-template')} />}
        />
      </div>
      <div className={style.fieldMessage}>
        <Controller
          control={control}
          name={'message'}
          rules={validateRule('required,minLength')}
          render={(renderProps) => (
            <AppInput
              {...getInputProps(renderProps)}
              placeholder={t('type-your-message-here')}
              disabled={isLoading}
              multiline={true}
              minRows={5}
              inputRef={messageRef}
              autoFocus
            />
          )}
        />
      </div>
    </>
  );
};

type TemplatePayload = Partial<SmsSenderTemplatesPayload> | undefined | null;

interface Props {
  url: string;
  initData: SmsSenderModel;
  templatePayload: TemplatePayload;
  onClose: () => void;
  onFormHandle: (formData: SmsSenderModel) => void;
}

export interface SmsSenderHandle {
  setFormData: <K extends keyof SmsSenderModel>(key: K, value: SmsSenderModel[K]) => void;
}

export const SmsSender = forwardRef<SmsSenderHandle, Props>(
  ({ initData, onClose, onFormHandle, url, templatePayload }, ref) => {
    const { t } = useTranslate();

    const onSubmit = useCallback(
      (formData: SmsSenderModel) => {
        return api.post(url, formData).then(() => {
          onFormHandle(formData);
        });
      },
      [url, onFormHandle],
    );

    const defaultValues: SmsSenderModel = useMemo(() => {
      return {
        leadID: undefined,
        userPatientProfileID: undefined,
        message: '',
        ...initData,
      };
    }, [initData]);

    const formMethods = useForm({
      defaultValues,
    });
    const { handleSubmit, setValue } = formMethods;
    const [triggerSubmit, { error, isLoading }] = useMutationDynamic(onSubmit);
    useEffectError(error);

    useImperativeHandle(
      ref,
      () => ({
        setFormData: (key, value) => {
          setValue(key, value);
        },
      }),
      [setValue],
    );

    return (
      <Dialog open={true} onClose={onClose} disableEscapeKeyDown={true} fullWidth maxWidth={'sm'}>
        <DialogHeading title={t('sms-message')} onClose={onClose} />
        <DialogContent className={style.content}>
          <FormProvider {...formMethods}>
            <FormSMSSender isLoading={isLoading} templatesPayload={templatePayload} />
          </FormProvider>
          <Loading active={isLoading} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {t('close')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit(triggerSubmit)}>
            {t('send')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);
