import { DynamicService } from 'utils/service';
import { API_USER_EMPLOYEE_PROFILE_PERMISSIONS, UserEmployeeProfilePermission } from './models';

export * from './models';

class Service extends DynamicService<UserEmployeeProfilePermission> {}

export const ServiceUserEmployeeProfilePermission = new Service({
  mainField: 'id',
  getAll: API_USER_EMPLOYEE_PROFILE_PERMISSIONS.GET_ALL_DYNAMIC,
  post: API_USER_EMPLOYEE_PROFILE_PERMISSIONS.POST,
  delete: API_USER_EMPLOYEE_PROFILE_PERMISSIONS.DELETE,
  patch: API_USER_EMPLOYEE_PROFILE_PERMISSIONS.PATCH,
});
