import React, { useCallback, useMemo } from 'react';
import { Unset } from 'utils/types';
import { schemaChangeLog, schemaChangeLogs } from 'modules/change-log';
import { useTranslate } from 'hooks/use-translate';
import { fieldToLabelKey } from 'utils/other';
import { InferType } from 'yup';

interface Props {
  value: Unset;
  mapLabels?: Record<string, string>;
}
export const TemplateLog: React.FC<Props> = ({ value, mapLabels }) => {
  const { tp, t } = useTranslate();
  const rows = useMemo(() => {
    if (!value) return [];
    try {
      const res = schemaChangeLogs.cast(JSON.parse(value), {
        stripUnknown: true,
        assert: false,
      }).fields;
      return res;
    } catch {
      return [];
    }
  }, [value]);

  const getLabel = useCallback(
    (log: InferType<typeof schemaChangeLog>) => {
      const labelKey = (mapLabels ? mapLabels[log.field] : null) || fieldToLabelKey(log.field);
      return t(labelKey);
    },
    [t, mapLabels],
  );

  return (
    <div>
      {rows.map((row, index) => {
        return (
          <div key={index}>
            {tp('log-change', { from: row.from, to: row.to, label: getLabel(row) })}
          </div>
        );
      })}
    </div>
  );
};
