import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';
import { convertToDate } from 'utils/dates';

const selectSearchedPatientsState = (state: RootState) => state.searchedPatients;

export const selectSearchedPatients = createSelector(
  selectSearchedPatientsState,
  ({ searchedPatients }) =>
    Array.from(searchedPatients).sort((a, b) => {
      return convertToDate(b.date).getTime() - convertToDate(a.date).getTime();
    }),
);
export const selectSearchedPatientIDs = createSelector(selectSearchedPatients, (searchedPatients) =>
  searchedPatients.map(({ appIdentityUserID }) => appIdentityUserID),
);
