import React, { memo } from 'react';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@material-ui/core';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';

export const StateFail = memo(({ onCancel }: { onCancel: (e?: any) => void }) => {
  const { t } = useTranslate();
  return (
    <div className={clsx(style.state, style.fail)}>
      <div className={style.contentCenter}>
        <div className={style.wrapper}>
          <CloseIcon className={style.icon} />
          <div className={style.title}>{t('payment-failed')}</div>
          <div className={style.description}>{t('transaction-processing-error')}</div>
          <Button variant="contained" color="primary" className={style.btn} onClick={onCancel}>
            {t('close')}
          </Button>
        </div>
      </div>
    </div>
  );
});

export default StateFail;
