import { NotebookFieldInput } from 'services/notebook-field-input';

export type tNotebookFieldInput = NotebookFieldInput & {
  formValue: any;
  formID: any;
};

export interface iField {
  item: tNotebookFieldInput;
  onWasChange?: () => void;
  disabled?: boolean;
}

export interface iFieldValue {
  id: string;
  fieldInputID: string;
  externalSystemValue: string | null;

  entryValueString: string | null;
  entryValueBool: boolean | null;
  entryValueNumber: number | null;
  entryValueDate: string | null;

  rank?: number;
}

export const fieldValueDefault = (): iFieldValue => ({
  id: '',
  fieldInputID: '',
  externalSystemValue: null,
  entryValueString: null,
  entryValueBool: null,
  entryValueNumber: null,
  entryValueDate: null,

  rank: 0,
});

export interface FieldGetValueResult extends iFieldValue {
  formValue: any;
  notebookFieldInputID: string;
}

export interface iFieldRefProps {
  getValue: () => FieldGetValueResult;
}
