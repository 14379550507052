import React, { memo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { iTMCalendarSupportMeeting, TMMerge, TMDeleteMeeting } from '../../store';
import { MedicalPrescriptionDialog } from 'components/medical-prescription';
export const DialogPrescription = memo(() => {
  const { dialogPrescription } = useAppSelector((state) => state.TaskManager);

  return dialogPrescription ? <DialogWrapper data={dialogPrescription} /> : null;
});

interface Props {
  data: Partial<iTMCalendarSupportMeeting>;
}

const DialogWrapper = memo<Props>(({ data }) => {
  const userPatientProfileID = String(data.userPatientProfileID);

  const dispatch = useAppDispatch();
  const onCancel = useCallback(() => {
    dispatch(TMMerge({ dialogPrescription: null }));
  }, [dispatch]);
  const onComplete = useCallback(() => {
    dispatch(TMDeleteMeeting({ id: data.id || '' }));
  }, [data, dispatch]);

  return (
    <MedicalPrescriptionDialog
      userPatientProfilePrescriptionID={true}
      userPatientProfileID={userPatientProfileID}
      onClose={onCancel}
      onDeleted={onCancel}
      onComplete={onComplete}
    />
  );
});
