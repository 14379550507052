import React from 'react';
import { Avatar, Typography } from '@material-ui/core';
import { Stack } from 'components/stack';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';

interface Props {
  title: string | null | undefined;
  icon: string | null | undefined;
  isCurrent: boolean;
  children: React.ReactNode;
}

export const StepLabel: React.FC<Props> = ({ title, icon, isCurrent, children }) => {
  const { t } = useTranslate();
  return (
    <Stack spacing={1} alignItems={'center'} sx={{ width: '100%' }} className={style.root}>
      {isCurrent && <div title={t('prescription-current-step')} className={style.current} />}
      <Avatar src={icon || ''} style={{ width: 32, height: 32 }} />
      <Typography style={{ flexGrow: 1, textAlign: 'left' }}>{title}</Typography>
      {children}
    </Stack>
  );
};
