import React from 'react';
import { IconButton, IconButtonProps } from '@material-ui/core';
import IconDelete from '@material-ui/icons/Delete';
import style from './index.module.scss';
import clsx from 'clsx';
import { useTranslate } from 'hooks/use-translate';

interface Props extends IconButtonProps {}
export const ButtonDelete: React.FC<Props> = (props) => {
  const { t } = useTranslate();

  return (
    <IconButton
      title={t('delete')}
      children={<IconDelete color={'inherit'} />}
      {...props}
      className={clsx(style.root, props.className)}
    />
  );
};
