import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LayoutContent } from 'components/layout-content';
import { Checkbox, Typography } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { useAppDispatch, useAppSelector } from 'store';
import {
  actionDietDiariesReportLoadData,
  dietDiariesReportActions,
  selectDietDiaryReportData,
  selectDietDiaryReportStatuses,
  selectDietDiaryReportIsAnySelected,
  selectDietDiaryReportPagination,
  selectDietDiaryReportSelected,
} from './store';
import {
  createColumnNamespace,
  DataGridEmpty,
  DataGridLight,
  DataGridLightDefaultTasks,
} from 'components/data-grid-light';
import { BulkActionComponentProps, BulkActions, GridModel } from './models';
import { NativeScroll } from 'components/native-scroll';
import { Loading } from 'AurionCR/components';
import { TemplateDate } from 'components/templates/template-date';
import { APP_FORMAT_DATE } from 'configs/const';
import { DialogPatient } from 'components/dialog-patient';
import { useValueQuery } from 'hooks/use-value-query';
import { Filters } from './components/filters';
import { useCurrentUser } from 'components/hooks';
import { Stack } from 'components/stack';
import { TemplateEmployeeSource } from 'components/templates/template-employee-source';
import { TemplateIconWithText } from 'components/templates/template-icon-with-text';
import { TemplatePatientCard } from 'components/templates/template-patient-card';
import { TemplatePrivateImages } from 'components/templates/template-private-images';
import { EditorEmployee } from 'components/data-grid-light-editors/editor-employee';
import { apiUserPatientProfileDietDiaries } from 'services/user-patient-profile-diet-diaries';
import { EditorReply } from './components/editor-reply';
import { EditorReplyEmployee } from './components/editor-reply-employee';
import { AppPagination } from 'components/app-pagination';
import { MenuActions } from './components/menu-actions';
import { BulkActionAssign } from './components/bulk-action-assign';
import { BulkActionEdit } from './components/bulk-action-edit';
import { BulkActionDelete } from './components/bulk-action-delete';
import { ControlEdit } from './components/control-edit';
import { ControlDelete } from './components/control-delete';
import style from './index.module.scss';

const useUpdateMutation =
  apiUserPatientProfileDietDiaries.endpoints.updateUserPatientProfileDietDiary.useMutation;

const columnHelper = createColumnNamespace<GridModel>();

const BULK_COMPONENTS: Record<BulkActions, React.ComponentType<BulkActionComponentProps>> = {
  [BulkActions.ASSIGN]: BulkActionAssign,
  [BulkActions.EDIT]: BulkActionEdit,
  [BulkActions.DELETE]: BulkActionDelete,
};

export * from './store';

const Page: React.FC = () => {
  const { appUserID, $isAdmin } = useCurrentUser();
  const { t } = useTranslate();

  const isAnySelected = useAppSelector(selectDietDiaryReportIsAnySelected);
  const pagination = useAppSelector(selectDietDiaryReportPagination);
  const selected = useAppSelector(selectDietDiaryReportSelected);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(dietDiariesReportActions.setFilters({ assignedToEmployeeID: appUserID }));
    dispatch(actionDietDiariesReportLoadData());
  }, [dispatch, appUserID]);

  const queryPatient = useValueQuery<string>({ name: 'patient' });

  const rows = useAppSelector(selectDietDiaryReportData);
  const statuses = useAppSelector(selectDietDiaryReportStatuses);

  const [bulkAction, setBulkAction] = useState<BulkActions>();
  const onCloseBulkAction = () => setBulkAction(undefined);

  const BulkComponent = bulkAction ? BULK_COMPONENTS[bulkAction] : null;

  const columns = useMemo(
    () => [
      columnHelper({
        field: '__checked',
        renderHeader: () => <MenuActions disabled={!isAnySelected} onChange={setBulkAction} />,
        renderColumn: ({ row }) => (
          <Checkbox
            checked={row.__checked}
            onChange={(_, checked) => {
              dispatch(dietDiariesReportActions.toggleItem({ id: String(row.id), value: checked }));
            }}
          />
        ),
        stickyLeft: true,
      }),
      columnHelper({
        field: 'assignToUserEmployeeProfileID',
        renderColumn: ({ row }) => (
          <TemplateEmployeeSource userEmployeeProfileID={row.assignToUserEmployeeProfileID} />
        ),
        renderHeader: () => t('assigned-to-employee'),
        editable: true,
        Editor: EditorEmployee,
      }),
      columnHelper({
        field: 'entryDate',
        renderColumn: ({ row }) => <TemplateDate date={row.entryDate} format={APP_FORMAT_DATE} />,
      }),
      columnHelper({
        field: 'dietDiaryTypeID',
        renderColumn: ({ row }) => (
          <TemplateIconWithText
            color={row.dietDiaryType.color}
            iconSrc={row.dietDiaryType.icon}
            title={row.dietDiaryType.title}
          />
        ),
      }),
      columnHelper({
        field: 'userPatientProfileID',
        renderHeader: () => t('patient'),
        renderColumn: ({ row }) => (
          <TemplatePatientCard
            userPatientProfileID={row.userPatientProfileID}
            patient={row.patient}
          />
        ),
      }),
      columnHelper({
        field: 'description',
      }),
      columnHelper({
        field: 'files',
        renderColumn: ({ row, field }) => <TemplatePrivateImages files={row[field]} />,
      }),
      columnHelper({
        field: 'replyDate',
        renderHeader: () => t('replied-by-employee-date'),
        renderColumn: ({ row }) => <TemplateDate date={row.replyDate} format={APP_FORMAT_DATE} />,
      }),
      columnHelper({
        field: 'userEmployeeProfileID',
        renderHeader: () => t('replied-by-employee'),
        renderColumn: ({ row }) => (
          <TemplateEmployeeSource userEmployeeProfileID={row.userEmployeeProfileID} />
        ),
        editable: true,
        Editor: EditorReplyEmployee,
      }),
      columnHelper({
        field: 'reply',
        editable: true,
        Editor: EditorReply,
      }),
      columnHelper({
        field: 'CONTROLS',
        stickyRight: true,
        renderHeader: () => null,
        renderColumn: ({ row }) => (
          <Stack>
            <ControlEdit row={row} />
            {$isAdmin && <ControlDelete row={row} />}
          </Stack>
        ),
      }),
    ],
    [isAnySelected, dispatch, $isAdmin, t],
  );

  const [performUpdate, resultUpdate] = useUpdateMutation();

  const onRefresh = useCallback(() => {
    dispatch(actionDietDiariesReportLoadData());
  }, [dispatch]);

  const isEmpty = rows.length === 0;

  const onTask = useCallback(
    async (task: DataGridLightDefaultTasks<GridModel>) => {
      switch (task.type) {
        case 'item-change': {
          await performUpdate({
            id: String(task.payload.row.id),
            assignToUserEmployeeProfileID: task.payload.data.assignToUserEmployeeProfileID,
            isViewed: task.payload.data.isViewed,
            reply: task.payload.data.reply,
            replyDate: task.payload.data.replyDate,
            userEmployeeProfileID: task.payload.data.userEmployeeProfileID,
          });
          onRefresh();
        }
      }
    },
    [onRefresh, performUpdate],
  );

  const isLoading = statuses.isLoading || resultUpdate.isLoading;

  const onChangePagination = useCallback(
    (options: Partial<{ page: number; take: number }>) => {
      dispatch(dietDiariesReportActions.setPagination(options));
      dispatch(actionDietDiariesReportLoadData());
    },
    [dispatch],
  );

  return (
    <>
      <LayoutContent
        header={
          <Typography variant="h1" color="secondary">
            {t('diet-diary-report')}
          </Typography>
        }
        classes={{
          main: style.content,
        }}
      >
        <div className={style.toolbar}>
          <Filters />
        </div>

        <NativeScroll>
          <DataGridLight columns={columns} rows={rows} onTask={onTask} />
          {isEmpty && <DataGridEmpty />}
          {isLoading && <Loading />}
        </NativeScroll>
        <Stack className={style.footer} justifyContent={'space-between'}>
          <div style={{ flexGrow: 1 }}>
            <AppPagination
              page={pagination.page}
              take={pagination.take}
              count={pagination.count}
              isLoading={isLoading}
              onChange={onChangePagination}
            />
          </div>
        </Stack>
      </LayoutContent>
      {queryPatient.value && (
        <DialogPatient
          itemID={queryPatient.value}
          onClose={queryPatient.onClear}
          onFormHandle={onRefresh}
        />
      )}
      {BulkComponent && (
        <BulkComponent selected={selected} onClose={onCloseBulkAction} onRefresh={onRefresh} />
      )}
    </>
  );
};
const PageDietDiaryProtector: React.FC = () => {
  return <Page />;
};

export default PageDietDiaryProtector;
