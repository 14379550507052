import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { FormModel, useSourceDosageFormOptions } from './helpers';
import { AppSelect } from 'components/app-select';
import { useFieldProps } from 'hooks';
import { AppInput } from 'components/app-input';
import { composeFunctions } from 'utils';
import { transformStringToInteger } from 'utils/transformers';

interface Props {
  drugID: string;
  disabled: boolean;
}
export const Form: React.FC<Props> = ({ drugID, disabled }) => {
  const { control, errors, setValue } = useFormContext<FormModel>();
  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });
  const source = useSourceDosageFormOptions(drugID);

  const onChangeTotal = useCallback(
    (value: string | null) => {
      setValue('quantity', transformStringToInteger(value));
    },
    [setValue],
  );

  return (
    <Grid container>
      <Grid item>
        <Controller
          control={control}
          name={'dosageFormTotalDesc'}
          render={(renderProps) => {
            const props = getFieldProps(renderProps);
            return (
              <AppSelect
                {...props}
                onChange={composeFunctions(props.onChange, onChangeTotal)}
                options={source.data}
                loading={source.loading}
                disabled={disabled}
              />
            );
          }}
        />
      </Grid>
      <Grid item>
        <Controller
          control={control}
          name={'quantity'}
          render={(renderProps) => {
            return (
              <AppInput
                {...getFieldProps(renderProps)}
                type={'number'}
                disabled={disabled}
                disableClearable
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};
