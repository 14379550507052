import ClearIcon from '@material-ui/icons/Clear';
import { IconButton, IconButtonProps, TextField, TextFieldProps } from '@material-ui/core';
import React, { forwardRef, memo, useCallback, useRef } from 'react';
import { useMergeRef } from 'hooks/use-merge-ref';

interface Props {
  disableClearable?: boolean;
  onClear?: (e: React.MouseEvent) => void;
  ClearIconProps?: Partial<IconButtonProps>;
}

export type AppInputProps = Props & TextFieldProps;
const Input = (
  { disableClearable = false, onClear, ClearIconProps, inputRef, ...props }: AppInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const innerRef = useRef<HTMLInputElement | null>(null);
  const mergedInputRef = useMergeRef(ref, innerRef, inputRef || null);

  const _onClear = useCallback(
    (e: React.MouseEvent) => {
      const input = innerRef.current;
      if (input) {
        let lastValue = input.value;
        input.value = '';
        let event = new Event('input', { bubbles: true });
        // @ts-ignore
        let tracker = input._valueTracker;
        if (tracker) tracker.setValue(lastValue);
        input.dispatchEvent(event);
      }
      if (onClear) {
        onClear(e);
      }
    },
    [onClear],
  );

  return (
    <TextField
      inputRef={mergedInputRef}
      variant={'standard'}
      fullWidth
      {...props}
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <>
            {!disableClearable && (
              <IconButton
                {...ClearIconProps}
                disabled={props.disabled}
                style={{ visibility: !props.value ? 'hidden' : 'visible' }}
                onClick={_onClear}
                size={'small'}
              >
                <ClearIcon fontSize={'small'} />
              </IconButton>
            )}
            {props?.InputProps?.endAdornment}
          </>
        ),
      }}
    />
  );
};

export const AppInput = memo(forwardRef(Input)) as typeof Input;
