import React, { forwardRef, memo, useCallback, useImperativeHandle } from 'react';
import { fieldValueDefault, iField, iFieldRefProps } from './@type';
import { AppWeightInput } from 'components/app-weight-input';
import { Controller, useFormContext } from 'react-hook-form';
import { useFieldProps } from 'hooks';
import { composeFunctions } from 'utils';
import { useTranslate } from 'hooks/use-translate';

export const FieldWeight = memo(
  forwardRef<iFieldRefProps, iField>(({ item, disabled = false, onWasChange }, ref) => {
    const { t } = useTranslate();
    const name = item.fieldInputID as string;
    const label = item.fieldInput?.labelKey;

    const { getValues, control, errors } = useFormContext();

    const getValue = useCallback(() => {
      const value = getValues(name);

      return {
        ...fieldValueDefault(),
        id: item.formID || '',
        fieldInputID: item.fieldInputID as string,
        entryValueNumber: value || value === 0 ? Number(value) : null,
        entryValueString: value ? String(value) : '',

        formValue: value,
        notebookFieldInputID: item.id,
      };
    }, [getValues, item, name]);
    useImperativeHandle(ref, () => ({ getValue }), [getValue]);

    const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

    return (
      <Controller
        name={name}
        control={control}
        rules={{
          required: { message: t('rule-required'), value: !!item?.isRequired },
          min: { value: 2, message: t('rule-min') },
        }}
        render={(renderProps) => {
          const props = getFieldProps(renderProps);
          return (
            <AppWeightInput
              {...props}
              label={label}
              disabled={disabled}
              onBlur={composeFunctions(props.onBlur, onWasChange)}
            />
          );
        }}
      />
    );
  }),
);
