import React, { memo, useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { selectAuthUser } from 'store/auth';
import {
  actionPatientMessageRemoveItem,
  actionPatientSmsMessagesSendMessage,
  PatientSmsMessageItem,
  selectPatientSmsMessageCurrent,
} from 'store/patient-sms-messages';
import { DialogSendSmsMessage, SmsMessageModelValid } from 'components/dialog-send-sms-message';
import { useCurrentUser } from 'components/hooks';

interface StorePatientSmsMessagesProps {
  currentMessage: PatientSmsMessageItem;
}

const StorePatientSmsMessages = memo<StorePatientSmsMessagesProps>(({ currentMessage }) => {
  const dispatch = useAppDispatch();
  const currentEmployee = useCurrentUser();
  const onClose = useCallback(() => {
    dispatch(actionPatientMessageRemoveItem(currentMessage));
  }, [currentMessage, dispatch]);

  const onSubmit = useCallback(
    (messageForm: SmsMessageModelValid) => {
      dispatch(actionPatientSmsMessagesSendMessage({ item: currentMessage, messageForm }));
    },
    [currentMessage, dispatch],
  );

  const templatePayload = useMemo(() => {
    return {
      currentEmployee: currentEmployee,
      ...currentMessage.templatePayload,
    };
  }, [currentMessage.templatePayload, currentEmployee]);

  return (
    <DialogSendSmsMessage
      key={currentMessage.uid}
      isLoading={currentMessage._isLoading}
      onClose={onClose}
      initData={currentMessage.formData}
      templatePayload={templatePayload}
      onSubmit={onSubmit}
    />
  );
});

interface Props {}
export const AppPatientSmsMessagesProvider = memo<Props>(() => {
  const user = useAppSelector(selectAuthUser);
  const currentItem = useAppSelector(selectPatientSmsMessageCurrent);
  return <>{user && currentItem && <StorePatientSmsMessages currentMessage={currentItem} />}</>;
});
