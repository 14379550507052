import React, { memo, useCallback, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import SearchGrid from '../search-grid';
import { useOpen } from 'AurionCR/components/hooks';

import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';
import { composeFunctions } from 'utils';
import { DialogPatient } from 'components/dialog-patient';
const voidFunction = () => {};

export const GlobalSearch = memo(() => {
  const { t } = useTranslate();
  const statePopup = useOpen();
  const [itemID, setItemID] = useState<string | null>();

  const onClosePatient = useCallback(() => {
    setItemID(null);
  }, []);

  return (
    <div>
      <div className={style.globalSearch} onClick={statePopup.handleOpen}>
        <Divider orientation={'vertical'} />
        <Typography className={style.searchText} color="secondary">
          {t('search')}
        </Typography>
        <SearchIcon className={style.icon} />
      </div>
      {statePopup.isOpen && (
        <SearchGrid
          onClose={statePopup.handleClose}
          onSelectItem={composeFunctions(setItemID, statePopup.handleClose)}
        />
      )}
      {itemID && (
        <DialogPatient itemID={itemID} onClose={onClosePatient} onFormHandle={voidFunction} />
      )}
    </div>
  );
});

export default GlobalSearch;
