import { mergeWith, pick } from 'lodash-es';

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export const setToModel = <Model extends { [x: string]: any }>(
  model: Model,
  source: DeepPartial<Model>,
) => {
  const result = mergeWith(
    { ...model },
    source,
    (valueModel, valueSource, key, objMod, objSource) => {
      if (Array.isArray(valueModel) && Array.isArray(source)) {
        return source;
      }
    },
  );
  return pick(result, Object.keys(model)) as Model;
};

type AnyFunction = (...args: any[]) => any;
export const composeFunctions = <T extends (...args: any[]) => any>(
  cb: T,
  ...functions: (AnyFunction | undefined)[]
) => {
  return ((...args: any[]) => {
    const result = cb(...args);
    (functions.filter(Boolean) as AnyFunction[]).forEach((fn) => {
      fn(...args);
    });
    return result;
  }) as T;
};

const replaceTemplate = (value: string, replacer: (v: string) => any) => {
  return String(value).replace(/{{(.|\n)+?}}/g, (v: string) => {
    const key = v.substring(2, v.length - 2);
    return replacer(key);
  });
};

interface HtmlTemplateOptions {
  onEmpty?: (key: string) => any;
  onError?: (key: string, error: Error) => any;
}

const defaultOnEmpty = () => '';
const defaultOnError = (key: string, error: Error) => {
  console.error(error);
  return '';
};

export const calcHtmlTemplate = (
  value: string,
  payload: Record<string, any> = {},
  options: HtmlTemplateOptions = {},
) => {
  const config = Object.entries(payload).map(([argName, param]) => ({ argName, param }));
  const { onEmpty = defaultOnEmpty, onError = defaultOnError } = options;

  const argsString = config.map(({ argName }) => argName).join(',');
  const params = config.map(({ param }) => param);

  return replaceTemplate(value, (key) => {
    try {
      // eslint-disable-next-line
      const fun = new Function(argsString, `return ${key}`);
      const result = fun(...params);
      return result === undefined ? onEmpty(key) : result;
    } catch (e: any) {
      return onError(key, e);
    }
  });
};
