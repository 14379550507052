import React, { useCallback, useMemo, useRef } from 'react';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import WarningIcon from '@material-ui/icons/Warning';
import { CircularProgress, Typography } from '@material-ui/core';
import { useMedicalPrescriptionDetailsContext } from '../../details-context';
import { useTranslate } from 'hooks/use-translate';
import {
  FORM_29_RULES,
  PrescriptionDetailRulesGimel29Result,
} from 'services/user-patient-profile-prescription-details';
import { PreviewPopover } from 'components/preview-popover';
import { useOpen } from 'AurionCR/components/hooks';
import { convertToDate } from 'utils/dates';
import { format } from 'date-fns';
import { APP_FORMAT_DATE } from 'configs/const';

import style from './index.module.scss';
import { ArrayType } from 'utils/types';
import { Stack } from 'components/stack';

interface ColumnWithRulesProps {
  rules: PrescriptionDetailRulesGimel29Result;
}
const ColumnWithRules: React.FC<ColumnWithRulesProps> = ({ rules }) => {
  const { tp } = useTranslate();
  const ref = useRef<HTMLDivElement>(null);

  const getRuleItem = useCallback(
    (rule: ArrayType<PrescriptionDetailRulesGimel29Result>) => {
      switch (rule.type) {
        case FORM_29_RULES.REQUIRED:
          return {
            title: tp('form-29-rule-required'),
          };
        case FORM_29_RULES.EXPIRED: {
          return {
            title: tp('form-29-rule-expired', {
              lastDate: format(convertToDate(rule.payload), APP_FORMAT_DATE),
            }),
          };
        }
        case FORM_29_RULES.MIN_AGE:
          return {
            title: tp('form-29-rule-min-age', { age: rule.payload }),
          };
        case FORM_29_RULES.PRESCRIPTION_UNITS:
          return {
            title: tp('form-29-rule-prescription-units', {
              count: rule.payload,
            }),
          };
        case FORM_29_RULES.BMI_LESS_30:
          return {
            title: tp('form-29-rule-bmi-less-30', {
              bmi: rule.payload,
            }),
          };
        case FORM_29_RULES.NUMBER_OF_PRESCRIPTIONS:
          return {
            title: tp('form-29-rule-total-prescriptions', { count: rule.payload }),
          };
      }
    },
    [tp],
  );

  const options = useMemo(() => {
    return rules.map(getRuleItem);
  }, [rules, getRuleItem]);
  const { isOpen, handleOpen, handleClose } = useOpen();
  const invailidCount = rules.length;

  return (
    <>
      <div ref={ref} className={style.root} onMouseEnter={handleOpen} onMouseLeave={handleClose}>
        <WarningIcon color={'inherit'} style={{ color: '#f49511' }} />
      </div>
      <PreviewPopover
        open={isOpen}
        anchorEl={ref.current}
        title={tp('form-29-rules', { count: invailidCount })}
        options={options}
        renderOption={(option) => {
          return (
            <Typography variant={'body1'} color={'secondary'}>
              {option.title}
            </Typography>
          );
        }}
      />
    </>
  );
};

interface Props {
  row: { id: string };
}

export const ColumnMedicalPrescriptionDetailRule: React.FC<Props> = ({ row }) => {
  const { rowRules, isLoading } = useMedicalPrescriptionDetailsContext();
  const rules = useMemo(() => {
    return rowRules[row.id] || [];
  }, [row, rowRules]);

  const hasRules = rules.length > 0;

  if (isLoading) {
    return (
      <Stack alignItems={'center'} justifyContent={'center'}>
        <CircularProgress size={16} />
      </Stack>
    );
  }

  if (hasRules) {
    return <ColumnWithRules rules={rules} />;
  }

  return (
    <div className={style.root}>
      <DoneAllIcon color={'inherit'} style={{ color: '#27ae60' }} />
    </div>
  );
};
