const API = 'DashboardReports';

export const API_DASHBOARD_REPORTS = {
  GET_KPIS: `${API}/GetKPIs`,
  GET_SUBSCRIPTIONS_ANALYTICS: `${API}/GeSubscriptionsAnalytics`,
  GET_SUPPORT_MEETING_ANALYTICS: `${API}/GetSupportMeetingAnalytics`,
  GET_CLINIC_MEETING_ANALYTICS: `${API}/GetClinicMeetingAnalytics`,
  GET_PATIENT_GENDERS_KPI: `${API}/GetPatientGendersKPI`,
  GET_PATIENT_AGE_RANGES_KPI: `${API}/GetPatientAgeRangesKPI`,
  GET_MEETING_TYPES_KPI: `${API}/GetMeetingTypesKPI`,
  GET_PATIENTS_KPI: `${API}/GetPatientsKPI`,
};

export const MONTHS = {
  0: 'january',
  1: 'february',
  2: 'march',
  3: 'april',
  4: 'may',
  5: 'june',
  6: 'july',
  7: 'august',
  8: 'september',
  9: 'october',
  10: 'november',
  11: 'december',
};

export interface IDashboardSubscriptionsAnalytics {
  subscriptionName: string;
  total: number;
}
export interface IDashboardSupportMeetingAnalytics {
  supportMeetingTypeTitle: string;
  supportMeetingTypeColor: string;
  supportMeetingTypeIcon: string;
  total: number;
}
export interface IDashboardClinicalMeetingAnalytics {
  clinicalMeetingSubjectTitle: string;
  clinicalMeetingSubjectColor: string;
  clinicalMeetingSubjectIcon: string;
  total: number;
}

export type IDashboardGenderKeys = 'male' | 'female' | 'other';
export type IDashboardMeetingsKeys = 'online' | 'visit-clinic';

export interface IDashboardGenders {
  value: number;
  key: IDashboardGenderKeys;
}

export interface IDashboardRanges {
  value: number;
  key: string;
}

export interface IDashboardMeetings {
  value: number;
  key: IDashboardMeetingsKeys;
}

export interface IDashboardPatients {
  value: number;
  key: string;
}
