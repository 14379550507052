import React, { forwardRef, memo, Ref } from 'react';
import { Button, MenuItem } from '@material-ui/core';
import { INotebookPrescription } from './index';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';

interface Props {
  isMenuItem: INotebookPrescription['isMenuItem'];
  isGridItem: INotebookPrescription['isMenuItem'];
  onClick: (e?: any) => void;
  children?: React.ReactNode;
  disabled?: boolean;
}

export const Wrapper = memo(
  forwardRef(({ isMenuItem, isGridItem, onClick, children, disabled }: Props, ref: Ref<any>) => {
    const { t } = useTranslate();

    if (isMenuItem) {
      return (
        <MenuItem disabled={disabled} onClick={onClick} ref={ref} className={style.menuItemWrapper}>
          {children}
        </MenuItem>
      );
    }
    if (isGridItem) {
      return (
        <Button disabled={disabled} onClick={onClick} className={style.btnGrid} ref={ref}>
          {children}
        </Button>
      );
    }

    return (
      <div className={style.btnWrapper}>
        <div className={style.label}>{t('notebook')}</div>
        <Button disabled={disabled} onClick={onClick} className={style.btn} ref={ref}>
          {children}
        </Button>
      </div>
    );
  }),
);

export default Wrapper;
