import React, { useCallback, useMemo } from 'react';
import { Avatar, Box, Button, Dialog, Divider, MenuItem, Tooltip } from '@material-ui/core';
import { useValueQuery } from 'hooks/use-value-query';
import { TabItem, TabsWrapper } from 'components/tabs-wrapper';
import { DialogHeading } from 'components/dialog-title';
import InfoIcon from '@material-ui/icons/Info';
import style from './index.module.scss';
import { PRESCRIPTION_STEP_TYPE_KEY, PrescriptionStep } from 'services/prescription-steps';
import { apiUserPatientPrescriptions } from 'services/user-patient-profile-prescriptions';
import { useEffectError, useMountedRef } from 'hooks';
import { convertToDate } from 'utils/dates';
import { format } from 'date-fns';
import { APP_FORMAT_DATE } from 'configs/const';
import { useTranslate } from 'hooks/use-translate';
import {
  StepLabel,
  TabDraft,
  TabReady,
  TabSigned,
  WizardValidityDraft,
  WizardValiditySigned,
} from './components';
import { useSourcePrescriptionSteps } from 'components/hooks';
import {
  usePrescriptionCompletenessStepDraft,
  usePrescriptionCompletenessStepSignDoc,
  usePrescriptionCanComplete,
} from './hooks';
import { TemplateBooleanIcon } from 'components/templates/template-boolean-icon';
import { Stack } from 'components/stack';
import { ButtonLatestTaskPrescription } from './components/button-latest-task-prescription';

//reexport
export { WizardValidityDraft, WizardValiditySigned };
export { usePrescriptionCanComplete };

export const DIALOG_COMPLETENESS_TABS_QUERY_NAME = 'completeness-tab';

interface Props {
  prescriptionID: string;
  onClose: () => void;
  onRefresh: () => void;
}
export const DialogPrescriptionCompleteness: React.FC<Props> = ({
  onClose,
  prescriptionID,
  onRefresh,
}) => {
  const { t } = useTranslate();
  const sourcePrescriptionSteps = useSourcePrescriptionSteps();

  const { value: tab, onChange: onChangeTab } = useValueQuery<PRESCRIPTION_STEP_TYPE_KEY>({
    name: DIALOG_COMPLETENESS_TABS_QUERY_NAME,
    init: PRESCRIPTION_STEP_TYPE_KEY.SIGNED,
    destroyOnUnmount: true,
  });
  const { data, error, isFetching, refetch } =
    apiUserPatientPrescriptions.useGetMedicalPrescriptionWizardDataQuery(prescriptionID);
  useEffectError(error);

  const [triggerMakeAsCurrent, resultMakeAsCurrent] =
    apiUserPatientPrescriptions.usePatchMedicalPrescriptionMutation();
  useEffectError(resultMakeAsCurrent.error);

  const patientQuery = useValueQuery<string>({
    name: 'patient',
    destroyOnUnmount: true,
  });

  const dialogTitle = useMemo(() => {
    if (error) {
      return error.message;
    }

    if (data) {
      return (
        <>
          {format(convertToDate(data.entryDate), APP_FORMAT_DATE)}
          <MenuItem
            component={'div'}
            className={style.titlePatient}
            onClick={() => patientQuery.onChange(data.userPatientProfileID || '')}
          >
            {data.userPatientProfile.fullName}
            <InfoIcon />
          </MenuItem>
        </>
      );
    }
    return t('loading');
  }, [data, error, t, patientQuery]);

  const onCompleted = useCallback(() => {
    onRefresh();
    onClose();
  }, [onRefresh, onClose]);

  const { isValid: isValidSigned } = usePrescriptionCompletenessStepSignDoc(prescriptionID);
  const { isValid: isValidDraft } = usePrescriptionCompletenessStepDraft(prescriptionID);
  const isValidReady = isValidDraft && isValidSigned;

  const renderTabElement = useCallback(
    (step: Pick<PrescriptionStep, 'stepTypeKey' | 'id'>) => {
      const { stepTypeKey } = step;
      if (stepTypeKey === PRESCRIPTION_STEP_TYPE_KEY.DRAFT) {
        return (
          <TabDraft
            prescriptionID={prescriptionID}
            isActive={tab === stepTypeKey}
            prescription={data}
            doctor={data?.userEmployeeProfile}
            doctorID={data?.userEmployeeProfileID}
            patient={data?.userPatientProfile}
            patientID={data?.userPatientProfileID}
            subscription={data?.subscription}
            onRefresh={refetch}
          />
        );
      }
      if (stepTypeKey === PRESCRIPTION_STEP_TYPE_KEY.SIGNED) {
        return (
          <TabSigned
            prescriptionID={prescriptionID}
            isActive={tab === stepTypeKey}
            userPatientProfileID={data?.userPatientProfileID}
            userPatientProfileSubscriptionID={data?.subscription?.id}
            onRefresh={refetch}
          />
        );
      }
      if (stepTypeKey === PRESCRIPTION_STEP_TYPE_KEY.READY) {
        return (
          <TabReady
            pharmacyFollowupID={data?.pharmacyFollowupID}
            userPatientProfileID={data?.userPatientProfileID}
            prescriptionID={prescriptionID}
            prescriptionStepID={step.id}
            isActive={tab === stepTypeKey}
            prescriptionPrintFilePath={data?.prescriptionPrintFilePath}
            prescriptionFilePath={data?.prescriptionFilePath}
            prescriptionWatermarkFilePath={data?.prescriptionWatermarkFilePath}
            onCompleted={onCompleted}
            isCompleted={!!data?.isCompleted}
            isPrint={!!data?.isPrint}
            isSent={!!data?.isSent}
            details={data?.userPatientProfilePrescriptionDetails || []}
          />
        );
      }

      return <>UNKNOWN TAB</>;
    },
    [prescriptionID, tab, data, refetch, onCompleted],
  );

  const currentStep = useMemo(() => {
    return sourcePrescriptionSteps.data.find((step) => step.id === data?.prescriptionStepID);
  }, [sourcePrescriptionSteps.data, data]);

  const selectedStep = useMemo(() => {
    return sourcePrescriptionSteps.data.find((step) => step.stepTypeKey === tab);
  }, [sourcePrescriptionSteps.data, tab]);

  const renderTabLabel = useCallback(
    (step: Pick<PrescriptionStep, 'id' | 'icon' | 'title' | 'stepTypeKey'>) => {
      let isCurrent = currentStep ? currentStep.id === step.id : false;

      let isValid = false;
      let TooltipContent: React.ReactNode | string = '';

      if (step.stepTypeKey === PRESCRIPTION_STEP_TYPE_KEY.DRAFT) {
        isValid = isValidDraft;
        TooltipContent = <WizardValidityDraft prescriptionID={prescriptionID} title={step.title} />;
      }

      if (step.stepTypeKey === PRESCRIPTION_STEP_TYPE_KEY.SIGNED) {
        isValid = isValidSigned;
        TooltipContent = (
          <WizardValiditySigned title={step.title} prescriptionID={prescriptionID} />
        );
      }

      if (step.stepTypeKey === PRESCRIPTION_STEP_TYPE_KEY.READY) {
        isValid = isValidReady;
      }

      return (
        <StepLabel icon={step.icon} title={step.title} isCurrent={isCurrent}>
          <Tooltip title={TooltipContent} arrow>
            <TemplateBooleanIcon value={isValid} />
          </Tooltip>
        </StepLabel>
      );
    },
    [currentStep, isValidDraft, isValidSigned, isValidReady, prescriptionID],
  );

  const tabs = useMemo<TabItem<PRESCRIPTION_STEP_TYPE_KEY>[]>(
    () => [
      ...sourcePrescriptionSteps.data.map((item) => ({
        value: item.stepTypeKey,
        title: renderTabLabel(item),
        element: renderTabElement(item),
      })),
    ],
    [renderTabElement, sourcePrescriptionSteps.data, renderTabLabel],
  );

  const mountedRef = useMountedRef();

  const onMakeAsCurrent = useCallback(async () => {
    if (!selectedStep) {
      return;
    }

    await triggerMakeAsCurrent({ id: prescriptionID, prescriptionStepID: selectedStep.id });

    if (mountedRef.current) {
      onRefresh();
    }
  }, [triggerMakeAsCurrent, selectedStep, prescriptionID, onRefresh, mountedRef]);

  const isProgress = sourcePrescriptionSteps.loading || isFetching;

  const isSelectedAndCurrent =
    selectedStep?.id === currentStep?.id || resultMakeAsCurrent.isLoading || isFetching;

  return (
    <Dialog fullScreen open={true} fullWidth maxWidth={false} onClose={onClose}>
      <DialogHeading
        classes={{ root: style.titleRoot, title: style.titleTitle, close: style.titleClose }}
        title={dialogTitle}
        onClose={onClose}
        isLoading={isProgress}
      >
        <Stack ml={'auto'} spacing={1}>
          {data?.userPatientProfileID && (
            <ButtonLatestTaskPrescription userPatientProfileID={data?.userPatientProfileID} />
          )}
          {selectedStep && (
            <Box ml={'auto'}>
              <Button
                disabled={isSelectedAndCurrent}
                style={{ color: isSelectedAndCurrent ? undefined : selectedStep.color }}
                onClick={onMakeAsCurrent}
                startIcon={
                  <Avatar
                    src={selectedStep.icon}
                    style={{ width: 24, height: 24, opacity: isSelectedAndCurrent ? 0.1 : 1 }}
                  />
                }
              >
                {t('mark-as-current')}
              </Button>
            </Box>
          )}
        </Stack>
      </DialogHeading>
      <Divider />
      <TabsWrapper
        TabsProps={{
          orientation: 'vertical',
          TabIndicatorProps: {
            style: {
              backgroundColor: 'transparent',
            },
          },
        }}
        classes={{
          root: style.tabs,
          tab: style.tab,
          header: style.header,
          content: style.content,
        }}
        tabs={tabs}
        value={tab}
        onChange={onChangeTab}
      />
    </Dialog>
  );
};
