import React, { useCallback } from 'react';
import { useEffectError } from 'hooks';
import { apiVoipe } from 'services/voipe-services';
import { BaseAction } from '../../models';
import { ButtonAction } from '../button-action';
import { isMutationFulfilled } from 'utils/rtk-query';
import { apiTodoTaskActivities } from 'services/todo-task-activities';
import { useTranslate } from 'hooks/use-translate';

const useCallMutation = apiVoipe.useVoipeCallToPatientMutation;
const usePostActivityMutation = apiTodoTaskActivities.usePostTodoTaskMeetingActivityMutation;

interface Props extends BaseAction {
  userPatientProfileID: string;
}
export const ActionCall: React.FC<Props> = ({
  taskID,
  userPatientProfileID,
  onDone,
  actionKey,
  isLoading: isLoadingOutside,
}) => {
  const { tp } = useTranslate();
  const [triggerCall, callResult] = useCallMutation();
  useEffectError(callResult.error);
  const [triggerActivity, activityResult] = usePostActivityMutation();
  useEffectError(activityResult.error);

  const isLoading = callResult.isLoading;

  const onClick = useCallback(async () => {
    const res = await triggerCall({ userPatientProfileID });

    if (isMutationFulfilled(res)) {
      triggerActivity({ toDoTaskID: taskID, remarks: tp('activity-to-do-task-call-to-patient') });
      onDone();
    }
  }, [userPatientProfileID, triggerCall, triggerActivity, onDone, taskID, tp]);

  return (
    <ButtonAction
      actionKey={actionKey}
      onClick={onClick}
      disabled={isLoading || isLoadingOutside}
    />
  );
};
