import React from 'react';
import { CircularProgress, IconButton, Tooltip, IconButtonProps } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { apiUserPatientProfileSessions } from 'services/user-patient-profile-session';
import { useOpen } from 'AurionCR/components/hooks';
import { SessionInformationCard } from 'components/session-information-list';
import style from './index.module.scss';

interface Props {
  userPatientProfileSessionID: string;
  IconButtonProps?: Partial<IconButtonProps>;
}

export const TemplateFullSessionInfo: React.FC<Props> = ({
  userPatientProfileSessionID,
  IconButtonProps,
}) => {
  const { isOpen, handleOpen, handleClose } = useOpen();
  const { data, isLoading } =
    apiUserPatientProfileSessions.useGetSessionForNotebookGeneratorPreviewQuery(
      userPatientProfileSessionID,
      { skip: !isOpen },
    );
  return (
    <Tooltip
      title={data ? <SessionInformationCard data={data} className={style.content} /> : ''}
      onClose={handleClose}
      onOpen={handleOpen}
      classes={{ tooltip: style.tooltip }}
      interactive
      leaveDelay={250}
    >
      <IconButton {...IconButtonProps} color={isOpen ? 'secondary' : 'primary'}>
        {isLoading ? <CircularProgress size={18} /> : <VisibilityIcon />}
      </IconButton>
    </Tooltip>
  );
};
