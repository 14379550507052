import React, { useCallback } from 'react';
import { MenuItem } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { apiUserPatientProfile, createBackdoorLink } from 'services/user-patient-profile';
import { useEffectError } from 'hooks';
import { createPatientDocumentLinkInnerLink } from 'services/user-patient-profile-form-documents';
import { isMutationFulfilled } from 'utils/rtk-query';
import { GridControlsDropDownTemplateProps } from 'models/grid';

const BACKDOOR_MINUTES = 60 * 24 * 7;

interface Props
  extends GridControlsDropDownTemplateProps<{ userPatientProfileID: string; id: string }> {}

export const GridMenuSignedDocumentsBackDoor: React.FC<Props> = ({ data, handleClose }) => {
  const { t } = useTranslate();

  const [makeBackDoor, { isLoading: isLoadingBackDoor, error: errorBackdoor }] =
    apiUserPatientProfile.useMakePatientBackDoorMutation();
  useEffectError(errorBackdoor);

  const onClick = useCallback(async () => {
    const backdoorResult = await makeBackDoor({
      userPatientProfileID: data.userPatientProfileID,
      minutes: BACKDOOR_MINUTES,
    });

    if (!isMutationFulfilled(backdoorResult)) {
      return;
    }

    const redirect = createPatientDocumentLinkInnerLink({ id: String(data.id) });

    const link = createBackdoorLink({
      userPatientProfileID: data.userPatientProfileID,
      secret: backdoorResult.data.secret,
      redirect,
    });

    window.open(link, '_blank', 'noopener,noreferrer');

    handleClose();
  }, [makeBackDoor, data, handleClose]);

  return (
    <MenuItem onClick={onClick} disabled={isLoadingBackDoor}>
      {t('display-in-patient-app')}
    </MenuItem>
  );
};
