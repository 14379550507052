const API_ENDPOINT = 'SiteParameters';

export const API_SITE_PARAMETERS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<iSiteParameters, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<iSiteParameters, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface iSiteParameters extends Components.Schemas.SiteParameter {}
