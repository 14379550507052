import React from 'react';
import { BulkActionComponentProps } from '../../models';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { useTranslate } from 'hooks/use-translate';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffectError, useFieldProps } from 'hooks';
import { ButtonSave } from 'components/app-buttons';
import { InferType } from 'yup';
import {
  apiUserPatientProfileDietDiaries,
  schemaDietDiary,
} from 'services/user-patient-profile-diet-diaries';
import { AppInput } from 'components/app-input';
import { useCurrentUser } from 'components/hooks';
import { isMutationFulfilled } from 'utils/rtk-query';

const endpoint = apiUserPatientProfileDietDiaries.endpoints.updateUserPatientProfileDietDiaryBulk;
const useUpdateMutation = endpoint.useMutation;

const schema = schemaDietDiary.pick(['reply']);

type FormModel = InferType<typeof schema>;

export const BulkActionEdit: React.FC<BulkActionComponentProps> = ({
  selected,
  onClose,
  onRefresh,
}) => {
  const { appUserID } = useCurrentUser();
  const { tp } = useTranslate();
  const { control, errors, handleSubmit } = useForm({
    defaultValues: schema.cast({}),
    resolver: yupResolver(schema),
  });
  const getFieldProps = useFieldProps({ errors });

  const [performUpdate, resultUpdate] = useUpdateMutation();
  useEffectError(resultUpdate.error);

  const disabled = resultUpdate.isLoading;

  const onSubmit = async (formData: FormModel) => {
    const res = await performUpdate({
      ids: selected.map(({ id }) => id),
      data: {
        ...formData,
        userEmployeeProfileID: appUserID,
        replyDate: new Date().toISOString(),
        isViewed: true,
      },
    });

    if (isMutationFulfilled(res)) {
      onRefresh();
      onClose();
    }
  };

  return (
    <Dialog open={true} fullWidth={true} maxWidth={'sm'} onClose={disabled ? undefined : onClose}>
      <DialogHeading
        title={tp('edit')}
        onClose={disabled ? undefined : onClose}
        disabled={disabled}
      />
      <DialogContent>
        <Controller
          control={control}
          name={'reply'}
          render={(renderProps) => {
            const props = getFieldProps(renderProps);
            return <AppInput multiline minRows={2} {...props} disabled={disabled} />;
          }}
        />
      </DialogContent>
      <DialogActions>
        <ButtonSave isCreate={false} onClick={handleSubmit(onSubmit)} disabled={disabled} />
      </DialogActions>
    </Dialog>
  );
};
