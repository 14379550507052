import React, { useCallback } from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import {
  Avatar,
  Box,
  Button,
  Divider,
  ListItem,
  ListItemAvatar,
  Paper,
  Typography,
} from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { Loading } from 'AurionCR/components';
import { Stack } from 'components/stack';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { DateValue } from 'utils/dates';

type Range = [DateValue, DateValue];
interface Item {
  id: string;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  userPhoto: string | null | undefined;
  before: Range | null;
  after: Range | null;
}

type SelectValue = { itemID: string; range: Range };

interface Props {
  className?: string;
  items: Item[];
  selectedEmployeeID: string;
  isLoading: boolean;
  onSelect: (value: SelectValue) => void;
}

export const ListEmployee: React.FC<Props> = ({
  className,
  items,
  isLoading,
  selectedEmployeeID,
  onSelect,
}) => {
  const { t } = useTranslate();
  const factoryClick = useCallback(
    (itemID: string, range: Range | null) => {
      return () => {
        if (!range) return;
        onSelect({ itemID, range });
      };
    },
    [onSelect],
  );

  return (
    <Paper className={clsx(style.root, className)} variant={'outlined'}>
      <Typography color={'secondary'} variant={'body1'} className={style.title}>
        {t('available-employee-on-this-time')}
      </Typography>
      <Divider orientation={'horizontal'} />
      {!isLoading && items.length === 0 ? (
        <div className={style.noData}>
          <AccountCircleOutlinedIcon fontSize={'inherit'} className={style.noDataIcon} />
          <Typography variant={'body2'} color={'secondary'}>
            {t('empty-employee-on-this-time')}
          </Typography>
        </div>
      ) : (
        <>
          <Stack direction={'column'} divider={<Divider orientation={'horizontal'} />}>
            {items.map((item) => {
              return (
                <ListItem
                  component={'div'}
                  key={item.id}
                  className={clsx(style.item, selectedEmployeeID === item.id && style.itemSelected)}
                >
                  <ListItemAvatar className={style.itemAvatar}>
                    <Avatar src={item.userPhoto || ''} style={{ height: 28, width: 28 }} />
                  </ListItemAvatar>
                  <Box alignSelf={'center'} flexGrow={1}>
                    {item.firstName} {item.lastName}
                  </Box>
                  <Stack spacing={0.4}>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      size={'small'}
                      onClick={factoryClick(item.id, item.before)}
                      disabled={!item.before}
                    >
                      {t('set-before')}
                    </Button>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      size={'small'}
                      onClick={factoryClick(item.id, item.after)}
                      disabled={!item.after}
                    >
                      {t('set-after')}
                    </Button>
                  </Stack>
                </ListItem>
              );
            })}
          </Stack>
        </>
      )}
      {isLoading && <Loading size={25} />}
    </Paper>
  );
};
