import React from 'react';
import { PreviewSessionCard } from 'components/preview-session-effects/components/card';
import style from './index.module.scss';
import { Typography } from '@material-ui/core';
import { NativeScroll } from 'components/native-scroll';

interface Item {
  label: string;
  value: any;
}

interface Props {
  title: React.ReactNode;
  items: Item[];
  hideIfEmpty?: boolean;
}
export const PreviewSessionItems: React.FC<Props> = ({ title, items, hideIfEmpty = true }) => {
  const isEmpty = items ? items.length === 0 : false;
  if (isEmpty && hideIfEmpty) {
    return null;
  }
  return (
    <PreviewSessionCard title={title}>
      <NativeScroll
        mode={'visible'}
        style={{
          maxHeight: '20rem',
        }}
      >
        <div className={style.wrap}>
          {items.map((item, index) => {
            return (
              <div key={index}>
                <Typography variant={'body2'} color={'textSecondary'}>
                  {item.label}
                </Typography>
                <Typography variant={'body2'}>{item.value}</Typography>
              </div>
            );
          })}
        </div>
      </NativeScroll>
    </PreviewSessionCard>
  );
};
