import React, { useCallback } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import SmsIcon from '@material-ui/icons/Sms';
import { useTranslate } from 'hooks/use-translate';
import { useAppDispatch } from 'store';
import { actionPatientMessageAddItem } from 'store/patient-sms-messages';
import { APP_FORMAT_DATE, APP_FORMAT_TIME } from 'configs/const';
import { dateFormat } from 'utils/dates';

interface Props extends ButtonProps {
  userPatientProfileID: string;
  toPhoneNumber: string | undefined | null;
  patient: Pick<Components.Schemas.UserPatientProfile, 'firstName' | 'lastName'>;
  meeting: {
    meetingFromDateTime: string;
    meetingToDateTime: string;
  } | null;
  onDone?: (data: { message?: string | null }) => void;
}

export const ButtonSendSms: React.FC<Props> = ({
  userPatientProfileID,
  toPhoneNumber,
  onDone,
  patient,
  meeting,
  ...rest
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslate();
  const onClick = useCallback(() => {
    const fromDate = meeting?.meetingFromDateTime;
    const toDate = meeting?.meetingToDateTime;

    const templatePayload = {
      meetingFromDate: fromDate ? dateFormat(fromDate, APP_FORMAT_DATE) : '',
      meetingFromTime: fromDate ? dateFormat(fromDate, APP_FORMAT_TIME) : '',

      meetingToDate: toDate ? dateFormat(toDate, APP_FORMAT_DATE) : '',
      meetingToTime: toDate ? dateFormat(toDate, APP_FORMAT_TIME) : '',

      patient,
    };
    dispatch(
      actionPatientMessageAddItem({
        uid: `patient-card__${userPatientProfileID}`,
        formData: { userPatientProfileID, toPhoneNumber },
        templatePayload,
        onSuccess: onDone,
      }),
    );
  }, [dispatch, userPatientProfileID, toPhoneNumber, patient, onDone, meeting]);

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<SmsIcon />}
      {...rest}
      onClick={onClick}
      disabled={!toPhoneNumber || rest.disabled}
    >
      {t('send-sms')}
    </Button>
  );
};
