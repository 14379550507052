import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import React, {useCallback} from "react";
import {useI18n} from "./i18";

export interface DialogBaseTitleProps {
  title?: string | React.ReactNode
}
export const DeleteDialogTitle: React.FC<DialogBaseTitleProps> = ({title}) => {
  const {t} = useI18n()
  const renderTitle = typeof title === 'string' ? `"${title}"` : title;
  return <Box display={'flex'}><Box marginRight={0.5}>{t('delete')}</Box><Box>{title ? renderTitle : ''}</Box></Box>
}
export const ConfirmDialogTitle: React.FC<DialogBaseTitleProps> = ({title}) => {
  const {t} = useI18n()
  return <>{t('confirm')}</>
}

export interface DialogBaseContentProps {
  message?: string
  isLoading: boolean
}
export const DeleteDialogContent: React.FC<DialogBaseContentProps> = ({message}) => {
  return <>{message}</>
}
export const ConfirmDialogContent: React.FC<DialogBaseContentProps> = ({message}) => {
  return <>{message}</>
}

interface DialogBaseActionsProps {
  onClose: () => void
  onConfirm: () => void
  isLoading: boolean
}
export const DeleteDialogActions: React.FC<DialogBaseActionsProps> = ({isLoading, onConfirm, onClose}) => {
  const {t} = useI18n()
  return (
    <>
      <Button color="secondary" onClick={onClose} disabled={isLoading}>
        {t('cancel')}
      </Button>
      <Button color="secondary" onClick={onConfirm} disabled={isLoading}>
        {t('delete')}
      </Button>
    </>
  )
}
export const ConfirmDialogActions: React.FC<DialogBaseActionsProps> = ({isLoading, onConfirm, onClose}) => {
  const {t} = useI18n()
  return (
    <>
      <Button color="secondary" onClick={onClose} disabled={isLoading}>
        {t('cancel')}
      </Button>
      <Button color="secondary" onClick={onConfirm} disabled={isLoading}>
        {t('confirm')}
      </Button>
    </>
  )
}

export interface DialogBaseProps {
  Title?: typeof DeleteDialogTitle | typeof ConfirmDialogTitle | null
  title?: string | React.ReactNode;
  Content?: typeof DeleteDialogContent | typeof ConfirmDialogContent | null
  message?: string
  Actions?: typeof DeleteDialogActions | typeof ConfirmDialogActions | null
  onClose: () => void
  onConfirm: () => void
  isLoading: boolean
}
export const DialogBase: React.FC<DialogBaseProps> = ({Title = DeleteDialogTitle, title, Content = DeleteDialogContent, message, Actions = DeleteDialogActions, onClose, onConfirm, isLoading}) => {

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const handleConfirm = useCallback(() => {
    onConfirm()
  }, [onConfirm])

  return (
    <Dialog onClose={handleClose} aria-labelledby="dialog-delete" open={true} className={'dialog-delete'}>
      <DialogTitle>
        {!!Title && <Title title={title}/>}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!!Content && <Content isLoading={isLoading} message={message} />}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!!Actions && <Actions onClose={handleClose} onConfirm={handleConfirm} isLoading={isLoading} />}
      </DialogActions>
    </Dialog>
  );
}
