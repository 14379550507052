import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'SMSContents';

export const API_SMS_CONTENTS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  SEND: `${API_ENDPOINT}/Send`,
  PATCH: (data: Pick<SMSContents, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<SMSContents, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export enum SMS_CONTENT_KEYS {
  MEETING_ONLINE = 'meeting-online',
  MEETING_CLINIC = 'meeting-clinic',
  SEND_SMS_SIGN_DOCUMENT = 'send-sms-sign-document',
  SEND_SMS_FILL_HEALTH_SURVEY = 'send-sms-fill-health-survey',
  LEAD_ACTION_NO_ANSWER = 'Lead is not replying',
  LEAD_ACTION_CALL_LATER = 'Lead wants to think',
  LEAD_ACTION_ON_HOLD = 'Lead wants to think',
  LEAD_ACTION_NOT_RELEVANT = 'Lead wants to think',
}

export interface SMSContents extends FixServerObject<Components.Schemas.SmsContent, 'id'> {
  smsKey?: SMS_CONTENT_KEYS.MEETING_ONLINE | string;
}
export interface SMSContentsCreateUpdateInput extends SMSContents {
  userEmployeeProfilePermissionIDs: string[];
}
export interface SMSContentsTestSendInput {
  id: string;
  phoneNumber: string;
}
