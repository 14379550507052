import { PickServerObject } from 'utils/types';
import { Notebook } from 'services/notebook';
import {
  NotebookFormGeneratorClinicalMeetingBefore,
  NotebookFormGeneratorSupportMeetingBefore,
  NotebookFormGenerator,
} from './components';
import { withOutOfSchedule } from './components/with-out-of-schedule';

const NotebookFormGeneratorWithOutOfSchedule = withOutOfSchedule(NotebookFormGenerator);

export const getNotebookFormComponent = (
  notebook: PickServerObject<
    Notebook,
    'displayNextMeetingForm' | 'isForClinicalMeeting' | 'displayAttachToMeeting'
  >,
) => {
  if (!notebook.displayAttachToMeeting) {
    if (notebook.isForClinicalMeeting) {
      return NotebookFormGeneratorWithOutOfSchedule;
    }
    return NotebookFormGenerator;
  }

  if (notebook.isForClinicalMeeting) {
    return NotebookFormGeneratorClinicalMeetingBefore;
  }

  if (notebook.displayNextMeetingForm) {
    return NotebookFormGeneratorSupportMeetingBefore;
  }

  return NotebookFormGenerator;
};
