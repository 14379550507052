import React, { useCallback } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import QueueIcon from '@material-ui/icons/Queue';
import { apiPatientFormDocuments } from 'services/user-patient-profile-form-documents';
import { useEffectError } from 'hooks';
import { addHours } from 'date-fns';
import { signedDocumentEmitter } from '../../emitter';

interface Props {
  prescriptionID: string;
  data: Pick<Components.Schemas.UserPatientProfileFormDocument, 'id'>;
}
export const GridControlAttachToPrescription: React.FC<Props> = ({ prescriptionID, data }) => {
  const { t } = useTranslate();
  const [patchItem, { error, isLoading }] =
    apiPatientFormDocuments.usePatchUserPatientFormDocumentMutation();
  useEffectError(error);

  const onClick = useCallback(async () => {
    await patchItem({
      id: String(data.id),
      userPatientProfilePrescriptionID: prescriptionID,
      expiredDate: addHours(new Date(), 48).toISOString(),
    });

    signedDocumentEmitter.emit('refresh-grid', { prescriptionID });
  }, [data.id, prescriptionID, patchItem]);

  return (
    <Button
      disabled={isLoading}
      variant={'contained'}
      color={'primary'}
      startIcon={isLoading ? <CircularProgress size={14} /> : <QueueIcon />}
      onClick={onClick}
    >
      {t('attach-doc-to-prescription')}
    </Button>
  );
};
