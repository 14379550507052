import React, { FC } from 'react';
import { AuthContextProvider, LoadingOverlayProvider, Notifier, Routes } from 'AurionCR/components';

// style
import 'AurionCR/styles/_main.scss';
import 'styles/index.scss';
// store
import { Provider } from 'react-redux';
import { store } from 'store';
// layout
import Layout from 'components/layout';
// router
import routesConfig from 'configs/routes';
// notify
import { SnackbarProvider } from 'notistack';
// l18n

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AxiosInterceptors } from 'contexts/axios-interceptors';
import { ThemeProvider } from 'contexts/theme-provider';
import { AppWebNotification } from 'contexts/web-notification';
import { NotFound } from 'pages/NotFound';
import { PickerProvider } from 'contexts/picker-provider';
import { EmployeesMonitorProvider } from 'contexts/emploees-monitor-provider';
import { AppConfigProvider } from 'contexts/app-config';
import { ErrorBoundary } from 'components/error-boundary';
import { AppPatientSmsMessagesProvider } from 'contexts/app-patient-sms-messages';
import { AppTranslateProvider } from './contexts/app-translate-provider';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const App: FC = () => {
  window.authCookieName_ = 'raz-clinic_auth_token_crm';
  window.authRefreshCookieName_ = 'raz-clinic_auth_refresh_token_crm';
  window.authDateCookieName_ = 'raz-clinic_auth_expires_token_crm';

  return (
    <ErrorBoundary>
      <AppConfigProvider>
        <Provider store={store}>
          <AxiosInterceptors>
            <AuthContextProvider>
              <ThemeProvider>
                <AppWebNotification>
                  <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Notifier />
                    <AppPatientSmsMessagesProvider />
                    <LoadingOverlayProvider>
                      <PickerProvider>
                        <EmployeesMonitorProvider>
                          <AppTranslateProvider>
                            <DndProvider backend={HTML5Backend}>
                              <Routes layout={Layout} routes={routesConfig} page404={NotFound} />
                            </DndProvider>
                          </AppTranslateProvider>
                        </EmployeesMonitorProvider>
                      </PickerProvider>
                    </LoadingOverlayProvider>
                  </SnackbarProvider>
                </AppWebNotification>
              </ThemeProvider>
            </AuthContextProvider>
          </AxiosInterceptors>
        </Provider>
      </AppConfigProvider>
    </ErrorBoundary>
  );
};

export default App;
