import React, { useCallback, useEffect, useMemo } from 'react';
import { DialogSendMessage, EmailModel, SendMessageForm } from 'components/dialog-send-message';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffectError, useFetchDynamic, useMountedRef, useMutationDynamic } from 'hooks';
import {
  apiUserPatientPrescriptions,
  ServicePatientPrescription,
} from 'services/user-patient-profile-prescriptions';
import { ServiceEmailMessages } from 'services/email-messages';
import { useAppDispatch } from 'store';
import { useTranslate } from 'hooks/use-translate';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { CLINIC_EMAIL } from 'configs/const';
import { apiUserPatientProfilePrescriptionActivities } from 'services/user-patient-profile-prescription-activities';

const usePathPrescriptionMutation = apiUserPatientPrescriptions.usePatchMedicalPrescriptionMutation;

type FetchDataInput = {
  userPatientProfilePrescriptionID: string;
};
const fetchData = async (input: FetchDataInput) => {
  const { userPatientProfilePrescriptionID } = input;
  const { data } = await ServicePatientPrescription.getDataForPatientEmail(
    userPatientProfilePrescriptionID,
  );
  return { data };
};

interface Model extends EmailModel {}

interface Props {
  userPatientProfilePrescriptionID: string;
  onClose: () => void;
  onDone: () => void;
}

export const MedicalPrescriptionDialogEmailToPatient: React.FC<Props> = ({
  userPatientProfilePrescriptionID,
  onClose,
  onDone,
}) => {
  const { t, tp } = useTranslate();
  const dispatch = useAppDispatch();
  const [pathPrescription] = usePathPrescriptionMutation();

  const defaultValues = useMemo<Model>(() => {
    return {
      toEmails: [],
      subject: t('email-patient-subject'),
      replyToEmail: t('email-reply-to'),
      fromDisplayName: t('email-display-name'),
      html: t('email-patient-html'),
    };
  }, [t]);

  const formMethods = useForm({ defaultValues });
  const { reset } = formMethods;

  const [triggerGetData, { data: dataEmail, isLoading: isLoadingData, error }] =
    useFetchDynamic(fetchData);
  useEffectError(error);

  const processInit = useCallback(
    async (input: FetchDataInput) => {
      const { data } = await triggerGetData(input);

      if (!data) return;
      reset({
        ...defaultValues,
        toEmails: [data.patient.email, CLINIC_EMAIL].filter(Boolean) as string[],
      });
    },
    [triggerGetData, reset, defaultValues],
  );

  useEffect(() => {
    processInit({ userPatientProfilePrescriptionID });
    //eslint-disable-next-line
  }, [userPatientProfilePrescriptionID]);

  const sendMessage = useCallback(
    async (data: Model) => {
      if (!dataEmail) return;

      const emailUniqueAttachments = Array.from(
        new Set([dataEmail.prescriptionFilePath as string]).keys(),
      );

      await ServiceEmailMessages.sendEmail({
        ...data,
        attachments: emailUniqueAttachments,
      });

      await pathPrescription({
        id: userPatientProfilePrescriptionID,
        isSent: true,
      }).unwrap();
    },
    [dataEmail, userPatientProfilePrescriptionID, pathPrescription],
  );

  const [triggerSend, { isLoading: isLoadingSend, error: errorSend }] =
    useMutationDynamic(sendMessage);
  useEffectError(errorSend);

  const mountedRef = useMountedRef();
  const onSubmit = useCallback(
    async (data: Model) => {
      const result = await triggerSend(data);

      if (result.error) {
        return;
      }

      dispatch(notifyRequestResult(t('success-send-and-complete'), 'success'));
      await dispatch(
        apiUserPatientProfilePrescriptionActivities.endpoints.createUserPatientProfilePrescriptionActivity.initiate(
          {
            userPatientProfilePrescriptionID,
            remarks: tp('activity-patient-prescription-send-to-patient'),
          },
        ),
      );

      if (mountedRef.current) {
        onDone();
        onClose();
      }
    },
    [triggerSend, mountedRef, onDone, onClose, dispatch, t, tp, userPatientProfilePrescriptionID],
  );

  const isLoading = isLoadingSend;

  const title = useMemo(() => {
    if (isLoadingData) {
      return t('loading');
    }
    if (!dataEmail) {
      return t('unknown');
    }
    return [dataEmail.patient.fullName, dataEmail.patient.idNumber].filter(Boolean).join(' | ');
  }, [isLoadingData, t, dataEmail]);

  return (
    <FormProvider {...formMethods}>
      <DialogSendMessage
        title={title}
        DialogProps={{
          maxWidth: 'lg',
        }}
        onClose={onClose}
        onSubmit={formMethods.handleSubmit(onSubmit)}
        isLoading={isLoading || isLoadingData}
      >
        <SendMessageForm isLoading={isLoading || isLoadingData} />
      </DialogSendMessage>
    </FormProvider>
  );
};
