import React, { useCallback } from 'react';
import { Dialog } from '@material-ui/core';
import { Loading } from 'AurionCR/components';
import { useAppDispatch, useAppSelector } from 'store';
import {
  actionNotebookFormGeneratorSetState,
  selectNotebookFormGeneratorFieldInputs,
  selectNotebookFormGeneratorNotebookSettings,
  selectNotebookFormGeneratorSession,
  selectNotebookFormGeneratorStatuses,
} from 'store/notebook-form-generator';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { useEffectError } from 'hooks';
import { UserPatientProfileSession } from 'services/user-patient-profile-session';
import { NotebookFormGeneratorStateForm } from '../notebook-form-generator-state-form';
import { NotebookFormGeneratorStateResult } from '../notebook-form-generator-state-result';
import { useNotebookFormGeneratorProps } from '../../hooks';
import { useTranslate } from 'hooks/use-translate';

interface Props {
  isLoading: boolean;
  onClose: (session?: Pick<UserPatientProfileSession, 'id'>) => void;
  onCreated?: () => void;
  onDone: (session: Pick<UserPatientProfileSession, 'id'>) => void;
}
export const NotebookFormGenerationContainer: React.FC<Props> = ({
  isLoading: isLoadingOuter,
  onClose,
  onCreated,
  onDone,
}) => {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();
  const { userPatientProfileID } = useNotebookFormGeneratorProps();

  const { data: patient, error } =
    apiUserPatientProfile.useGetPatientForGeneralFormQuery(userPatientProfileID);
  useEffectError(error);

  const notebookSettings = useAppSelector(selectNotebookFormGeneratorNotebookSettings);
  const userPatientProfileSession = useAppSelector(selectNotebookFormGeneratorSession);
  const fieldInputs = useAppSelector(selectNotebookFormGeneratorFieldInputs);
  const { isLoading: _isLoading, state } = useAppSelector(selectNotebookFormGeneratorStatuses);

  const notebookLabelKey = notebookSettings?.labelKey || t('loading');

  const onEdit = useCallback(() => {
    dispatch(actionNotebookFormGeneratorSetState('form'));
  }, [dispatch]);

  const onCloseProxy = useCallback(() => {
    onClose(userPatientProfileSession);
  }, [userPatientProfileSession, onClose]);

  const onCloseForm = useCallback(() => {
    if (userPatientProfileSession) {
      return dispatch(actionNotebookFormGeneratorSetState('result'));
    }
    return onCloseProxy();
  }, [userPatientProfileSession, dispatch, onCloseProxy]);

  const onDoneProxy = useCallback(() => {
    onDone(userPatientProfileSession as Pick<UserPatientProfileSession, 'id'>);
  }, [userPatientProfileSession, onDone]);

  const isLoading = isLoadingOuter || _isLoading;

  return (
    <Dialog open={true} fullScreen={true} disableEscapeKeyDown maxWidth={false}>
      {notebookSettings && state === 'form' && (
        <NotebookFormGeneratorStateForm
          notebookLabelKey={notebookLabelKey}
          userPatientProfileSessionID={userPatientProfileSession?.id}
          fieldInputs={fieldInputs}
          notebookSettings={notebookSettings}
          isLoading={isLoading}
          onClose={onCloseForm}
          onCreated={onCreated}
        />
      )}
      {notebookSettings && userPatientProfileSession && state === 'result' && (
        <NotebookFormGeneratorStateResult
          notebookLabelKey={notebookLabelKey}
          isTLC={!!patient?.isTLC}
          userPatientProfileSessionID={userPatientProfileSession.id}
          onClose={onCloseProxy}
          onEdit={onEdit}
          displayNextMeetingForm={notebookSettings.displayNextMeetingForm}
          onDone={onDoneProxy}
        />
      )}
      {!notebookSettings && isLoading && <Loading />}
    </Dialog>
  );
};
