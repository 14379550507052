const API_ENDPOINT = 'Genders';

export const API_GENDERS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<Gender, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<Gender, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface Gender extends Components.Schemas.Gender {}
