import React, { forwardRef, memo, useMemo } from 'react';
import { iField } from './@type';
import { FieldBoolean } from './field-boolean';
import { FieldText } from './field-text';
import { FieldNumber } from './field-number';
import { FieldDate } from './field-date';
import { FieldSelect } from './field-select';
import { FieldTextArea } from './field-text-area';
import { FieldDays } from './field-days';
import { FieldMultiSelect } from './field-multi-select';
import { FieldWeight } from './field-weight';

export const Field = memo(
  forwardRef<any, iField>((props, ref) => {
    const params = useMemo(
      () => props.item.fieldInput?.inputType?.inputTypeParameter,
      [props.item],
    );
    const isWeight = !!props.item.fieldInput?.inputType?.isWeight;
    // render
    if (isWeight) {
      return <FieldWeight {...props} ref={ref} />;
    }
    if (params?.isText) {
      return <FieldText {...props} ref={ref} />;
    } else if (params?.isLongText) {
      return <FieldTextArea {...props} ref={ref} />;
    } else if (params?.isNumber) {
      return <FieldNumber {...props} ref={ref} />;
    } else if (params?.isBool) {
      return <FieldBoolean {...props} ref={ref} />;
    } else if (params?.isDate) {
      return <FieldDate {...props} ref={ref} />;
    } else if (params?.isSelect) {
      return <FieldSelect {...props} ref={ref} />;
    } else if (params?.isMultiSelect) {
      return <FieldMultiSelect {...props} ref={ref} />;
    } else if (params?.isDays) {
      return <FieldDays {...props} ref={ref} />;
    } else {
      return null;
    }
  }),
);

export * from './@type';
