import React, { memo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { Button } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { selectTMLoading, TMRefresh } from '../../store';
import RefreshIcon from '@material-ui/icons/Refresh';

export const BtnRefresh = memo(() => {
  const { tp } = useTranslate();
  const isLoading = useAppSelector(selectTMLoading);
  const dispatch = useAppDispatch();
  const onClick = useCallback(() => {
    dispatch(TMRefresh(true));
  }, [dispatch]);

  return (
    <Button
      startIcon={<RefreshIcon />}
      disabled={isLoading}
      variant={'contained'}
      color={'primary'}
      onClick={onClick}
    >
      {tp('refresh')}
    </Button>
  );
});
