import React, { HTMLProps, useMemo } from 'react';

import { dateFormat, DateValue } from 'utils/dates';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { Typography, TypographyProps } from '@material-ui/core';

interface Props extends TypographyProps<'time', HTMLProps<HTMLTimeElement>> {
  date: DateValue;
  format?: string;
}

export const TemplateDate: React.FC<Props> = ({
  date,
  format: stringFormat = APP_FORMAT_DATE_TIME,
  ...rest
}) => {
  const dateTime = useMemo(() => {
    return dateFormat(date, APP_FORMAT_DATE_TIME);
  }, [date]);
  const value = useMemo(() => {
    return dateFormat(date, stringFormat);
  }, [date, stringFormat]);

  if (!date) {
    return null;
  }

  return (
    <Typography
      variant={'body2'}
      {...rest}
      component={'time'}
      title={value}
      dateTime={dateTime}
      style={{ whiteSpace: 'nowrap', ...rest?.style }}
    >
      {value}
    </Typography>
  );
};
