import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'DocumentTypes';

export const API_DOCUMENT_TYPES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<Components.Schemas.DocumentType, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<Components.Schemas.DocumentType, 'id'>) =>
    `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface DocumentType extends FixServerObject<Components.Schemas.DocumentType, 'id'> {}

export enum DOCUMENT_TYPES_INDEXES {
  MIGRATION = 1,
  PATIENT_FILE = 5,
  GIMEL_29 = 6,
  REPORT = 7,
}
