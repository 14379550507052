import React, { useMemo, useState } from 'react';
import { TabsWrapper } from 'components/tabs-wrapper';
import { AllActivitiesGrid } from 'components/dialog-patient';
import { useTranslate } from 'hooks/use-translate';
import { GridPreviewClinicalDrugs } from '../grid-preview-clinical-drugs';
import { SessionsList } from 'components/notebook-form-generator';

enum TABS {
  CLINICAL_DRUGS = '0',
  ALL_SESSIONS = '1',
  ALL_ACTIVITIES = '2',
}

interface Props {
  userPatientProfileID: string;
}

export const InformationSection: React.FC<Props> = ({ userPatientProfileID }) => {
  const { t } = useTranslate();
  const [activeTab, setActiveTab] = useState(TABS.CLINICAL_DRUGS);

  const tabs = useMemo(
    () => [
      {
        value: TABS.CLINICAL_DRUGS,
        title: t('clinical-drugs'),
        element: <GridPreviewClinicalDrugs userPatientProfileID={userPatientProfileID} />,
      },
      {
        value: TABS.ALL_SESSIONS,
        title: t('all-sessions'),
        element: <SessionsList userPatientProfileID={userPatientProfileID} />,
      },
      {
        value: TABS.ALL_ACTIVITIES,
        title: t('all-activities'),
        element: (
          <AllActivitiesGrid
            userPatientProfileID={userPatientProfileID}
            style={{ paddingTop: 0 }}
          />
        ),
      },
    ],
    [t, userPatientProfileID],
  );

  return <TabsWrapper tabs={tabs} value={activeTab} onChange={setActiveTab} />;
};
