import React, { useCallback, useEffect } from 'react';
import {
  MedicalPrescriptionDialogNew,
  MedicalPrescriptionEvents,
  MedicalPrescriptionFormEditor,
} from './components';
import {
  iUserPatientProfilePrescription,
  ServicePatientPrescription,
} from 'services/user-patient-profile-prescriptions';
import { useOpenValue } from 'hooks';
import { composeFunctions } from 'utils';
import { useTranslate } from 'hooks/use-translate';
import { UseFormGrid } from 'AurionCR/components/formV2';
import { usePermissions } from 'hooks/use-permissions';
import { useAppDispatch } from 'store';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { requestError } from 'AurionCR/components';

export * from './components';

interface Props {
  userPatientProfilePrescriptionID: string | true;
  userPatientProfileID: string;
  onComplete?: (data: Pick<iUserPatientProfilePrescription, 'id'>) => void;
  onCreated?: (data: Pick<iUserPatientProfilePrescription, 'id'>) => void;
  onEventsNew?: (action: MedicalPrescriptionEvents) => void;
  onEventsPrev?: (action: MedicalPrescriptionEvents) => void;
  onDeleted?: () => void;
  onClose: () => void;
}
export const MedicalPrescriptionDialog: React.FC<Props> = ({
  userPatientProfilePrescriptionID,
  userPatientProfileID,
  onComplete,
  onCreated,
  onDeleted,
  onClose,
  onEventsNew,
  onEventsPrev,
}) => {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();

  const isNew = typeof userPatientProfilePrescriptionID !== 'string';

  const { openValue: openValueEditNew, onOpen: onOpenEditNew } = useOpenValue<string>(
    !isNew ? userPatientProfilePrescriptionID : undefined,
  );
  const {
    openValue: openValueEditPrev,
    onOpen: onOpenEditPrev,
    onClose: onCloseEditPrev,
  } = useOpenValue<string>();

  const onCloseAll = useCallback(() => {
    if (openValueEditNew) {
      return onComplete && onComplete({ id: openValueEditNew });
    }
    if (openValueEditPrev) {
      return onCloseEditPrev();
    }
  }, [openValueEditNew, onComplete, openValueEditPrev, onCloseEditPrev]);

  // check permissions
  const isAllowToCreateAndRenewPrescriptions = usePermissions(
    'isAllowToCreateAndRenewPrescriptions',
  );

  useEffect(() => {
    if (!isAllowToCreateAndRenewPrescriptions) {
      dispatch(notifyRequestResult(requestError(new Error(t('not-enough-permissions'))), 'error'));
      onClose();
    }
  }, [dispatch, isAllowToCreateAndRenewPrescriptions, t, onClose]);

  const onEvents = useCallback(
    (action: MedicalPrescriptionEvents) => {
      switch (action.type) {
        case 'generate': {
          onCloseAll();
          break;
        }
      }
    },
    [onCloseAll],
  );

  const onEventsNewInner = useCallback(
    async (event: MedicalPrescriptionEvents) => {
      // remove new on cancel
      if (event.type === 'cancel' && isNew) {
        await ServicePatientPrescription.delete(event.payload);
        onDeleted && onDeleted();
      }
    },
    [onDeleted, isNew],
  );

  const onFormHandle = useCallback<UseFormGrid['onFormHandle']>(
    (action) => {
      switch (action.type) {
        case 'delete': {
          onDeleted && onDeleted();
        }
      }
    },
    [onDeleted],
  );

  if (!isAllowToCreateAndRenewPrescriptions) {
    return null;
  }

  if (openValueEditNew) {
    return (
      <MedicalPrescriptionFormEditor
        userPatientProfileID={userPatientProfileID}
        itemID={openValueEditNew}
        onClose={onClose}
        onFormHandle={onFormHandle}
        onEvent={composeFunctions(onEvents, onEventsNew, onEventsNewInner)}
      />
    );
  }

  if (openValueEditPrev) {
    return (
      <MedicalPrescriptionFormEditor
        userPatientProfileID={userPatientProfileID}
        itemID={openValueEditPrev}
        onClose={onClose}
        onFormHandle={onFormHandle}
        onEvent={composeFunctions(onEvents, onEventsPrev)}
      />
    );
  }

  // create new
  return (
    <MedicalPrescriptionDialogNew
      userPatientProfileID={userPatientProfileID}
      onCancel={onClose}
      onCreated={(data) => {
        onOpenEditNew(data.id);
        onCreated && onCreated(data);
      }}
      onCompletePrevious={(data) => {
        onOpenEditPrev(data.id);
      }}
    />
  );
};
