import { ValueFileUploader } from 'utils/file-uploader';
import { FixServerObject } from 'utils/types';
import { UserPatientProfileSubscription } from 'services/user-patient-profile-subscriptions';
import { Gender } from '../genders';
import * as yup from 'yup';
import {
  REGEXP_PATIENT_ID_NUMBER,
  REGEXP_PATIENT_PASSPORT_NUMBER,
  REGEXP_PATIENT_PHONE,
} from 'utils/regexp';
import { DateValue, isDateLike, isTeenager } from 'utils/dates';
import { transformToDateISO } from 'utils/transformers';
import { CityIDs } from '../cities';
import { InferType } from 'yup';

const _API = 'UserPatientProfiles';

export const API_USER_PATIENT_PROFILE = {
  API: _API,
  GET_ALL_DYNAMIC: `${_API}/GetAllDynamic`,
  GET_ALL_LIGHT_DYNAMIC: `${_API}/GetAllLightDynamic`,
  GET: (id: string) => `${_API}/Get/${id}`,
  GET_SECRET: (appIdentityUserID: string) => `${_API}/GetSecret/${appIdentityUserID}`,
  POST: `${_API}/Create`,
  PATCH: ({ appIdentityUserID }: Pick<UserPatientProfile, 'appIdentityUserID'>) =>
    `${_API}/Patch/${appIdentityUserID}`,
  DELETE: ({ appIdentityUserID }: Pick<UserPatientProfile, 'appIdentityUserID'>) =>
    `${_API}/Delete/${appIdentityUserID}`,
  SEND_SMS: `${_API}/SendSMS`,
};

export interface UserPatientProfile
  extends FixServerObject<Components.Schemas.UserPatientProfile, 'appIdentityUserID'> {}

export enum PREFER_PHARMACY_TYPE {
  REGULAR = 'regular-pharmacy',
  NEOPHARM = 'neopharm-pharmacy',
}

export interface ISearchPatient
  extends Pick<
    UserPatientProfile,
    | 'id'
    | 'appIdentityUserID'
    | 'rowIndex'
    | 'fullName'
    | 'idNumber'
    | 'mobilePhone'
    | 'shortRemark'
    | 'isActive'
    | 'onHold'
  > {}

export class UserPatientProfileCreateInput
  implements Omit<UserPatientProfile, 'appIdentityUserID'>
{
  firstName = '';
  lastName = '';
  idNumber = '';
  dateOfBirth = '';
  genderID = '';
  height = 0;
  isTLC = false;
  cityID = '';
  address = '';
  mobilePhone = '';
  email = '';
  weight = 0;
  bmi = 0;
  remarks = '';
  userEmployeeProfileSupportPrimaryID = '';
  userEmployeeProfileSupportSecondaryID = '';
  medicalRemarks = '';
  shortRemark = '';
  isActive = true;

  // extra fields
  excelFile: ValueFileUploader = '';
}

export interface MakePatientBackDoorInput {
  userPatientProfileID: string;
  minutes: number;
}

export interface PatientVerifyPhoneNumberInput {
  phone: string;
  isNew: boolean;
}

export interface PatientDetailsForDocumentSign
  extends Pick<
    UserPatientProfile,
    | 'firstName'
    | 'lastName'
    | 'fullName'
    | 'idNumber'
    | 'dateOfBirth'
    | 'mobilePhone'
    | 'email'
    | 'address'
    | 'familyMemberName'
    | 'familyMemberFirstName'
    | 'familyMemberLastName'
    | 'familyMemberPhone'
    | 'familyMemberIDNumber'
    | 'familyMemberRelationship'
  > {
  subscription: Pick<UserPatientProfileSubscription, 'startDate' | 'endDate'>;
}

export interface PatientDetailsForPdfGimel29
  extends Pick<
    UserPatientProfile,
    | 'fullName'
    | 'idNumber'
    | 'dateOfBirth'
    | 'weight'
    | 'height'
    | 'address'
    | 'mobilePhone'
    | 'medicalRemarks'
  > {
  gender: Pick<Gender, 'title'>;
}

type CheckSecretExpirationOutputExpired = { isExpired: true };
type CheckSecretExpirationOutputNotExpired = { isExpired: false; secret: string; expiredAt: Date };
export type CheckSecretExpirationOutput =
  | CheckSecretExpirationOutputExpired
  | CheckSecretExpirationOutputNotExpired;

export type MakePatientBackDoorResult = {
  userPatientProfileID: string;
  secret: string;
  expired: Date;
};

export interface GetPatientsForSearchInput {
  search: string;
  take: number;
  excludeIDs: string[];
}

export class UserPatientProfileEditGeneralInput implements UserPatientProfile {
  firstName = '';
  lastName = '';
  idNumber = '';
  nameEng = '';
  passportNumber = '';
  email = '';
  userPhoto = '';
  dateOfBirth = '';
  genderID = '';
  height = 0;
  isTLC = false;
  extraAttention = false;
  cityID = '';
  remarks = '';
  medicalRemarks = '';
  userEmployeeProfileSupportPrimaryID = '';
  userEmployeeProfileSupportSecondaryID = '';
  userEmployeeProfileDietitianID = '';
  diagnosisTypeID = '';
  bmiSummaryID = '';
  shortRemark = '';
  weight = 0;
  leadWeight = 0;
  maintenanceWeight = 0;
  bmi = 0;
  isActive = false;
  appIdentityUserID = '';
  mobilePhone = '';
  address = '';
  occupation = '';
  id = '';
  rowIndex = 0;
  // member
  familyMemberFirstName = '';
  familyMemberLastName = '';
  familyMemberPhone = '';
  familyMemberIDNumber = '';
  familyMemberRelationship = '';
  mustFillHealthSurvey = false;
  // on hold
  onHold = false;
  onHoldEndDate = '';
  onHoldReason = '';

  doNotProlongTreatment = false;
  slowIncreaseWeight = false;

  doNotAllowEndOfTreatmentLetter = false;

  changeDosageByDoctorApproval = false;

  preferedPharmacyType: PREFER_PHARMACY_TYPE | null = null;
}

export interface ValidatePatientsFieldsUniqueInput
  extends Pick<Components.Schemas.UserPatientProfile, 'email' | 'idNumber' | 'mobilePhone'> {
  userPatientProfileID: string | null;
}

export type ValidatePatientIdNumberInput = Pick<
  Required<ValidatePatientsFieldsUniqueInput>,
  'userPatientProfileID' | 'idNumber'
>;

export const ruleIDNumber = yup
  .string()
  .nullable()
  .required('rule-required')
  .matches(REGEXP_PATIENT_ID_NUMBER, 'rule-id-number')
  .max(9, 'rule-max-length')
  .default('');

export const schemaUserPatientProfile = yup.object({
  firstName: yup
    .string()
    .nullable()
    .required('rule-required')
    .min(2, 'rule-min-length')
    .trim()
    .default(''),
  lastName: yup
    .string()
    .nullable()
    .required('rule-required')
    .min(2, 'rule-min-length')
    .trim()
    .default(''),
  idNumber: ruleIDNumber,
  dateOfBirth: yup
    .string()
    .nullable()
    .required('rule-required')
    .test('date', 'rule-date', isDateLike)
    .transform(transformToDateISO)
    .trim()
    .default(''),
  genderID: yup.string().nullable().required('rule-required').default(''),
  cityID: yup.string().nullable().required('rule-required').default(''),
  address: yup
    .string()
    .nullable()
    .required('rule-required')
    .max(500, 'rule-max-length')
    .default(''),
  isTLC: yup.boolean().default(false),
  extraAttention: yup.boolean().default(false),
  isActive: yup.boolean().default(false),
  mustFillHealthSurvey: yup.boolean().default(false),
  mobilePhone: yup
    .string()
    .nullable()
    .required('rule-required')
    .matches(REGEXP_PATIENT_PHONE, 'rule-phone')
    .max(50, 'rule-max-length')
    .trim()
    .default(''),
  email: yup.string().nullable().required('rule-required').email('rule-email').default(''),
  occupation: yup.string().nullable().notRequired().max(100, 'rule-max-length').default(''),
  weight: yup
    .number()
    .nullable()
    .notRequired()
    .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
    .required('rule-required')
    .default(0),
  maintenanceWeight: yup.number().typeError('rule-number').nullable().notRequired().default(0),
  height: yup.number().nullable().notRequired().default(null),
  diagnosisTypeID: yup.string().nullable().notRequired().default(''),
  bmiSummaryID: yup.string().nullable().notRequired().default(''),
  medicalRemarks: yup
    .string()
    .nullable()
    .notRequired()
    .trim()
    .max(4000, 'rule-max-length')
    .default(''),
  userEmployeeProfileSupportPrimaryID: yup.string().nullable().notRequired().default(''),
  userEmployeeProfileSupportSecondaryID: yup.string().nullable().notRequired().default(''),
  userEmployeeProfileDietitianID: yup.string().nullable().notRequired().default(''),
  remarks: yup.string().nullable().notRequired().trim().max(2000, 'rule-max-length').default(''),
  shortRemark: yup.string().nullable().notRequired().trim().max(50, 'rule-max-length').default(''),

  familyMemberFirstName: yup
    .string()
    .nullable()
    .max(50, 'rule-max-length')
    .when('dateOfBirth', {
      is: (dateOfBirth: string) => {
        return dateOfBirth ? isTeenager(dateOfBirth) : false;
      },
      then: (schema) => schema.required('rule-required'),
      otherwise: (schema) => schema.notRequired().nullable(),
    })
    .default(''),
  familyMemberLastName: yup
    .string()
    .nullable()
    .max(50, 'rule-max-length')
    .when('dateOfBirth', {
      is: (dateOfBirth: string) => (dateOfBirth ? isTeenager(dateOfBirth) : false),
      then: (schema) => schema.required('rule-required'),
      otherwise: (schema) => schema.notRequired().nullable(),
    })
    .default(''),
  familyMemberPhone: yup
    .string()
    .nullable()
    .max(50, 'rule-max-length')
    .when('dateOfBirth', {
      is: (dateOfBirth: string) => (dateOfBirth ? isTeenager(dateOfBirth) : false),
      then: (schema) =>
        schema.required('rule-required').matches(REGEXP_PATIENT_PHONE, 'rule-phone'),
      otherwise: (schema) => schema.notRequired().nullable(),
    })
    .default(''),
  familyMemberIDNumber: yup
    .string()
    .nullable()
    .notRequired()
    .max(50, 'rule-max-length')
    .default(''),
  familyMemberRelationship: yup
    .string()
    .nullable()
    .notRequired()
    .max(200, 'rule-max-length')
    .default(''),

  onHold: yup.boolean().notRequired().nonNullable(),
  doNotProlongTreatment: yup.boolean().notRequired().nonNullable(),
  slowIncreaseWeight: yup.boolean().notRequired().nonNullable(),
  changeDosageByDoctorApproval: yup.boolean().notRequired().nonNullable(),
  doNotAllowEndOfTreatmentLetter: yup.boolean().notRequired().nonNullable(),

  onHoldEndDate: yup
    .string()
    .nullable()
    .notRequired()
    .test('date', 'rule-date', (value) => {
      return value ? isDateLike(value) : true;
    })
    .transform(transformToDateISO),
  onHoldReason: yup
    .string()
    .nullable()
    .notRequired()
    .trim()
    .max(2000, 'rule-max-length')
    .default(''),
  passportNumber: yup
    .string()
    .nullable()
    .notRequired()
    .matches(REGEXP_PATIENT_PASSPORT_NUMBER, 'rule-passport-number')
    .max(10, 'rule-max-length')
    .default(''),
  nameEng: yup.string().nullable().notRequired().trim().default(''),
  preferedPharmacyType: yup.string().nullable().notRequired().trim().default(null),
});

export type GetPatientRequiredDetailsForGmel29Output = Required<
  Pick<UserPatientProfile, 'id' | 'weight' | 'height' | 'medicalRemarks'>
> & {
  gender: Required<Pick<Components.Schemas.Gender, 'title' | 'isMissing'>>;
};

export type GetPatientDetailsForPdfLetterForMedInsurance = Pick<
  UserPatientProfile,
  | 'firstName'
  | 'lastName'
  | 'idNumber'
  | 'mobilePhone'
  | 'dateOfBirth'
  | 'height'
  | 'weight'
  | 'medicalRemarks'
  | 'hmo'
  | 'diagnosisType'
  | 'bmiSummary'
> & {
  subscription: Pick<UserPatientProfileSubscription, 'price' | 'startDate' | 'endDate'> & {
    listPrice: number;
    labelKey: string;
    visitDietitian: boolean;
    durationMonths: number;
  };
};
export type GetPatientDetailsForPdfEndOfTreatmentDetails = Pick<
  UserPatientProfile,
  | 'firstName'
  | 'lastName'
  | 'idNumber'
  | 'mobilePhone'
  | 'dateOfBirth'
  | 'height'
  | 'weight'
  | 'medicalRemarks'
  | 'hmo'
  | 'diagnosisType'
  | 'bmiSummary'
> & {
  subscription: Pick<UserPatientProfileSubscription, 'price' | 'startDate' | 'endDate'> & {
    listPrice: number;
    labelKey: string;
    visitDietitian: boolean;
    durationMonths: number;
  };
};

export type GetPatientDetailsForPdfEndOfTreatmentOutput = Pick<
  UserPatientProfile,
  'fullName' | 'idNumber' | 'height' | 'weight'
> & {
  gender: Pick<Gender, 'rowIndex'>;
};
export type GetPatientDetailsForPdfEndOfTreatment = GetPatientDetailsForPdfEndOfTreatmentOutput & {
  startingWeight: number;
  currentWeight: number;
  startingBMI: number;
  currentBMI: number;
};

export type GetPatientDetailsForPdfSummaryOfConsultation = Pick<
  UserPatientProfile,
  | 'firstName'
  | 'lastName'
  | 'idNumber'
  | 'mobilePhone'
  | 'dateOfBirth'
  | 'height'
  | 'weight'
  | 'medicalRemarks'
  | 'hmo'
  | 'diagnosisType'
  | 'bmiSummary'
>;

export type PatientDetailsForPreviewSurvey = Pick<
  Required<UserPatientProfile>,
  | 'appIdentityUserID'
  | 'lastName'
  | 'firstName'
  | 'idNumber'
  | 'dateOfBirth'
  | 'height'
  | 'weight'
  | 'email'
  | 'mobilePhone'
  | 'address'
  | 'city'
>;

export const schemaPatientForPrescriptionForm = schemaUserPatientProfile
  .pick([
    'idNumber',
    'genderID',
    'cityID',
    'mobilePhone',
    'email',
    'address',
    'isTLC',
    'shortRemark',
    'userEmployeeProfileDietitianID',
    'userEmployeeProfileSupportPrimaryID',
    'userEmployeeProfileSupportSecondaryID',
    'familyMemberFirstName',
    'familyMemberLastName',
    'familyMemberPhone',
    'familyMemberIDNumber',
    'familyMemberRelationship',
  ])
  .shape({
    cityID: yup
      .string()
      .required('rule-required')
      .test('correct-city', 'rule-city-na', (value) => {
        if (!value) return true;
        return value !== CityIDs.NA;
      })
      .default(''),
  });

export interface MakePatientOnHoldInput {
  userPatientProfileID: string;
  onHoldEndDate: string;
  onHoldReason: string;
}
export interface MakePatientUnHoldInput {
  userPatientProfileID: string;
}

export type UserPatientProfilePatchInput = InferType<typeof schemaUserPatientProfile> & {
  appIdentityUserID: string;
};

export interface IPatientCardInfo
  extends Pick<
    Required<UserPatientProfile>,
    | 'isTLC'
    | 'extraAttention'
    | 'fullName'
    | 'isActive'
    | 'onHold'
    | 'onHoldReason'
    | 'slowIncreaseWeight'
    | 'changeDosageByDoctorApproval'
    | 'doNotProlongTreatment'
  > {
  dateOfBirth: DateValue;
}
