const API_ENDPOINT = 'LeadActivityTypes';

export const API_LEAD_ACTIVITY_TYPES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<LeadActivityType, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<LeadActivityType, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export enum AppLeadActivityTypeTag {
  NoAnswer = 1,
  CallLater,
  NotRelevant,
  OnHold,
  General,
}

export interface LeadActivityType
  extends Omit<Components.Schemas.LeadActivityType, 'leadActivityTypeTag'> {
  leadActivityTypeTag: AppLeadActivityTypeTag;
}
