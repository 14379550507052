import React, { useCallback } from 'react';
import { Box, Chip, FormControl, FormHelperText, FormLabel, Paper } from '@material-ui/core';
import style from './index.module.scss';
import { ArrayType } from 'utils/types';

interface Props<T> {
  label?: React.ReactNode;
  error?: boolean;
  helperText?: React.ReactNode;
  value?: T[];
  onChange: (value: T[]) => void;
  disabled?: boolean;
}
export const AppChipAreaInput = <T extends { id: string; title: string }>({
  value = [],
  label,
  error,
  helperText,
  onChange,
  disabled,
}: Props<T>) => {
  const factoryRemove = useCallback(
    (drug: ArrayType<typeof value>) => {
      return () => {
        onChange(value.filter((option) => drug.id !== option.id));
      };
    },
    [value, onChange],
  );

  return (
    <FormControl className={style.root} error={error} fullWidth component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <Paper component={'ul'} className={style.paper} variant="outlined" square>
        {value.map((item) => {
          return (
            <Box component={'li'} key={item.id} m={0.5} className={style.chipWrap}>
              <Chip
                size={'small'}
                color={'primary'}
                label={item.title}
                onDelete={factoryRemove(item)}
                disabled={disabled}
              />
            </Box>
          );
        })}
      </Paper>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
