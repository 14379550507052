import React, { useCallback, useMemo } from 'react';
import { useFilterForm } from 'hooks/use-filter-form';
import { useAppDispatch, useAppSelector } from 'store';
import { selectLoosingWeightsFilters, selectLoosingWeightsStatuses } from '../../store/selectors';
import { useFieldProps } from 'hooks';
import { Tooltip, Slider, Typography, Grid, Button, Box } from '@material-ui/core';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { actionLoosingWeightLoadData, actionLoosingWeightSetFilters } from '../../store';
import { calcRangeSlider } from 'utils/app-helpers';
import { useSourceWeightTypes } from 'components/hooks';
import { ButtonSwitcher } from 'components/button-switcher';
import { useTranslate } from 'hooks/use-translate';
import style from './index.module.scss';
import { AppDatePicker } from 'components/app-date-picker';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { convertToDate, DateValue } from 'utils/dates';
import { isWithinInterval } from 'date-fns';
import FilterListIcon from '@material-ui/icons/FilterList';
import { composeFunctions } from 'utils';
import { AppInputMask } from 'components/app-input-masked';
import { getHandlerKayPress } from 'utils/handlers';

type SchemaObject = {
  startDate: string;
  endDate: string;
  deltaStart: number;
  deltaEnd: number;
};

type SchemaContext = {
  serverStartDate: DateValue;
  serverEndDate: DateValue;
};
const testDateRange = (value: string, context: yup.TestContext<SchemaContext>) => {
  const { serverStartDate, serverEndDate } = context.options.context || {};

  if (!value) {
    return context.createError({ message: 'rule-required' });
  }

  const start = convertToDate(serverStartDate);
  const end = convertToDate(serverEndDate);
  const date = convertToDate(value);

  const isValid = isWithinInterval(date, { start, end });

  if (!isValid) {
    return context.createError({ message: 'rule-range' });
  }

  return true;
};

const schema = yup.object({
  startDate: yup
    .string<string, SchemaContext>()
    .required('rule-required')
    .test(testDateRange)
    .test((startDate, _context) => {
      const context = _context;
      const { endDate } = context.parent;

      const start = convertToDate(startDate);
      const end = convertToDate(endDate);

      if (start > end) {
        return context.createError({
          message: 'rule-start-date',
        });
      }
      return true;
    }),
  endDate: yup
    .string<string, SchemaContext>()
    .required('rule-required')
    .test(testDateRange)
    .test((endDate, _context) => {
      const context = _context as yup.TestContext<SchemaContext>;
      const { startDate } = context.parent;

      const start = convertToDate(startDate);
      const end = convertToDate(endDate);

      if (end < start) {
        return context.createError({
          message: 'rule-end-date',
        });
      }
      return true;
    }),

  deltaStart: yup
    .number()
    .required('rule-required')
    .test((deltaStart, _context) => {
      const context = _context as yup.TestContext<SchemaObject>;
      const { deltaEnd } = context.parent;

      const start = parseFloat(String(deltaStart));
      const end = parseFloat(String(deltaEnd));

      if (start > end) {
        return context.createError({
          message: 'rule-start-delta',
        });
      }
      return true;
    }),
  deltaEnd: yup
    .number()
    .required('rule-required')
    .test((deltaEnd, _context) => {
      const context = _context as yup.TestContext<SchemaObject>;
      const { deltaStart } = context.parent;

      const start = parseFloat(String(deltaStart));
      const end = parseFloat(String(deltaEnd));

      if (end < start) {
        return context.createError({
          message: 'rule-end-delta',
        });
      }
      return true;
    }),
});
interface ValueLabelComponentProps {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

const ValueLabelComponent: React.FC<ValueLabelComponentProps> = (props) => {
  const { children, open, value } = props;

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value}
      arrow
      PopperProps={{ disablePortal: true }}
      classes={{
        tooltipPlacementTop: style.tooltipPlacementTop,
      }}
    >
      {children}
    </Tooltip>
  );
};

export const Filters: React.FC = () => {
  const { t, tp } = useTranslate();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectLoosingWeightsFilters);
  const { isLoading } = useAppSelector(selectLoosingWeightsStatuses);

  const { serverStartDate, serverEndDate } = filters;

  const shouldDisableDate = useCallback(
    (date: Date | null) => {
      if (!date) return false;
      return !isWithinInterval(date, {
        start: convertToDate(serverStartDate),
        end: convertToDate(serverEndDate),
      });
    },
    [serverStartDate, serverEndDate],
  );
  const { control, errors, getValues, onCheckAndUpdate, handleSubmit } = useFilterForm({
    defaultValues: filters,
    resolver: yupResolver(schema),
    context: { serverStartDate, serverEndDate },
  });
  const getFieldProps = useFieldProps({ emptyHelperText: '', errors });

  const ageSliderProps = useMemo(() => {
    const marks = Array.from({ length: 100 })
      .fill(null)
      .map((_, index) => ({ value: index }));

    return { marks, min: marks[0].value, max: marks[marks.length - 1].value };
  }, []);
  const onUpdateType = useCallback(() => {
    const payload = getValues(['typeID']);
    return dispatch(actionLoosingWeightSetFilters(payload));
  }, [dispatch, getValues]);

  const sourceWeightTypes = useSourceWeightTypes();
  const weightTypeOptions = useMemo(
    () => [
      ...sourceWeightTypes.data.map((item) => ({
        value: String(item.id),
        title: String(item.title),
      })),
      { value: null, title: t('all') },
    ],
    [sourceWeightTypes.data, t],
  );

  const sourceActive = useMemo(
    () => [
      { value: true, title: t('active') },
      { value: false, title: t('inactive') },
      { value: null, title: t('all') },
    ],
    [t],
  );

  const onSubmit = useCallback(() => {
    dispatch(actionLoosingWeightSetFilters(getValues()));
    dispatch(actionLoosingWeightLoadData());
  }, [getValues, dispatch]);

  const onSubmitDates = useCallback(
    (formData: SchemaObject) => {
      return dispatch(
        actionLoosingWeightSetFilters({
          startDate: formData.startDate,
          endDate: formData.endDate,
        }),
      );
    },
    [dispatch],
  );
  const submitDates = handleSubmit(onSubmitDates);

  const onSubmitDelta = useCallback(
    (formData: SchemaObject) => {
      return dispatch(
        actionLoosingWeightSetFilters({
          deltaStart: formData.deltaStart,
          deltaEnd: formData.deltaEnd,
        }),
      );
    },
    [dispatch],
  );
  const submitDelta = handleSubmit(onSubmitDelta);

  return (
    <div className={style.root}>
      <Grid container spacing={2}>
        <Grid style={{ width: 'unset' }} xs={12} md={true} item>
          <div>
            <Typography color={'textSecondary'} component={'div'} className={style.title}>
              {t('age')}
            </Typography>
            <Controller
              name={'age'}
              control={control}
              render={(renderProps) => {
                const props = getFieldProps(renderProps);
                return (
                  <Slider
                    step={null}
                    {...props}
                    {...ageSliderProps}
                    value={Array.from(props.value)}
                    ValueLabelComponent={ValueLabelComponent}
                    valueLabelDisplay="on"
                    onChange={(event, value) => {
                      if (!Array.isArray(value)) return;

                      const oldValue = getValues('age');
                      const activeThumb = oldValue[0] === value[0] ? 1 : 0;

                      const result = calcRangeSlider({
                        value,
                        marks: ageSliderProps.marks,
                        minStep: 8,
                        maxStep: 62,
                        activeThumb,
                      });

                      props.onChange(result);
                    }}
                    onChangeCommitted={onCheckAndUpdate}
                    disabled={isLoading}
                  />
                );
              }}
            />
          </div>
        </Grid>
        <Grid style={{ width: 'unset' }} xs={12} md={'auto'} item>
          <Box mt={'1rem'}>
            <Controller
              name={'isActive'}
              control={control}
              render={(renderProps) => {
                const props = getFieldProps(renderProps);
                return <ButtonSwitcher options={sourceActive} {...props} disabled={isLoading} />;
              }}
            />
          </Box>
        </Grid>
        <Grid style={{ width: 'unset' }} xs={12} md={'auto'} item>
          <Button
            startIcon={<FilterListIcon />}
            onClick={handleSubmit(onSubmit)}
            style={{ marginTop: '1rem' }}
            variant={'contained'}
            color={'primary'}
            disabled={isLoading}
          >
            {tp('apply')}
          </Button>
        </Grid>
      </Grid>

      <div className={style.bottom}>
        <Grid container spacing={2}>
          <Grid style={{ width: 'unset' }} xs={6} md={'auto'} item>
            <Controller
              control={control}
              name={'startDate'}
              render={(renderProps) => {
                const props = getFieldProps(renderProps);
                return (
                  <AppDatePicker
                    {...props}
                    onAccept={() => submitDates()}
                    shouldDisableDate={shouldDisableDate}
                  />
                );
              }}
            />
          </Grid>
          <Grid style={{ width: 'unset' }} xs={6} md={'auto'} item>
            <Controller
              control={control}
              name={'endDate'}
              render={(renderProps) => {
                const props = getFieldProps(renderProps);
                return (
                  <AppDatePicker
                    {...props}
                    onAccept={() => submitDates()}
                    shouldDisableDate={shouldDisableDate}
                  />
                );
              }}
            />
          </Grid>
          <Grid style={{ width: 'unset' }} xs={12} md={3} lg={2} xl={1} item>
            <Controller
              name={'deltaStart'}
              control={control}
              render={(renderProps: ControllerRenderProps<any>) => {
                const props = getFieldProps(renderProps);
                return (
                  <AppInputMask
                    dir={'ltr'}
                    InputLabelProps={{ shrink: true }}
                    {...props}
                    value={String(props.value)}
                    onKeyPress={getHandlerKayPress('Enter', submitDelta)}
                    onBlur={composeFunctions(props.onBlur, submitDelta)}
                    MaskOptions={{
                      mask: Number,
                      scale: 2,
                      min: -100,
                      max: 100,
                      radix: '.', // fractional delimiter
                      mapToRadix: ['.', ','], // symbols to process as radix
                    }}
                    disableClearable
                  />
                );
              }}
            />
          </Grid>
          <Grid style={{ width: 'unset' }} xs={12} md={3} lg={2} xl={1} item>
            <Controller
              name={'deltaEnd'}
              control={control}
              render={(renderProps: ControllerRenderProps<any>) => {
                const props = getFieldProps(renderProps);
                return (
                  <AppInputMask
                    dir={'ltr'}
                    InputLabelProps={{ shrink: true }}
                    {...props}
                    value={String(props.value)}
                    onKeyPress={getHandlerKayPress('Enter', submitDelta)}
                    onBlur={composeFunctions(props.onBlur, submitDelta)}
                    MaskOptions={{
                      mask: Number,
                      scale: 2,
                      min: -100,
                      max: 100,
                      radix: '.', // fractional delimiter
                      mapToRadix: ['.', ','], // symbols to process as radix
                    }}
                    disableClearable
                  />
                );
              }}
            />
          </Grid>
          <Grid style={{ width: 'unset', display: 'flex' }} xs={12} md={true} item>
            <Box ml={'auto'} mt={'1rem'}>
              <Controller
                name={'typeID'}
                control={control}
                render={(renderProps) => {
                  const props = getFieldProps(renderProps);
                  return (
                    <ButtonSwitcher
                      options={weightTypeOptions}
                      {...props}
                      disabled={isLoading}
                      onChange={composeFunctions(props.onChange, onUpdateType)}
                    />
                  );
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
