import React from 'react';
import { ActionKey } from 'services/todo-task-categories';
import { ActionGeneral } from '../action-general';
import { ActionPrescription } from '../action-prescription';
import { ActionPayment } from '../action-payment';
import { ActionClinicalAppointment } from '../action-clinical-appointment';
import { ActionCall } from '../action-call';
import { ActionSideEffect } from '../action-side-effect';
import { ActionSupportMeeting } from '../action-support-meeting';
import { useTranslate } from 'hooks/use-translate';
import { ActionNextClinicalAppointment } from '../action-next-clinical-appointment';
import { ActivitiesStatus } from 'components/dialog-patient/components/all-activities-grid/component/activities-status';
import { Box } from '@material-ui/core';

type Row = {
  id: string;
  actionKey: ActionKey;
  done?: boolean;
};
interface Props {
  row: Row;
  isLoading: boolean;
  userPatientProfileID: string;
  onDone: () => void;
}
export const ColumnTaskAction: React.FC<Props> = ({
  row,
  isLoading,
  userPatientProfileID,
  onDone,
}) => {
  const { t } = useTranslate();
  if (row.done) {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <ActivitiesStatus value={row.done} title={t('task-done')} />
      </Box>
    );
  }

  switch (row.actionKey) {
    case ActionKey.Urgent:
    case ActionKey.General:
    case ActionKey.PatientIsReady:
      return (
        <ActionGeneral
          taskID={row.id}
          isLoading={isLoading}
          actionKey={row.actionKey}
          onDone={onDone}
        />
      );
    case ActionKey.Prescription:
      return (
        <ActionPrescription
          taskID={row.id}
          isLoading={isLoading}
          actionKey={row.actionKey}
          userPatientProfileID={userPatientProfileID}
          onDone={onDone}
        />
      );
    case ActionKey.Payment:
      return (
        <ActionPayment
          taskID={row.id}
          isLoading={isLoading}
          actionKey={row.actionKey}
          userPatientProfileID={userPatientProfileID}
          onDone={onDone}
        />
      );
    case ActionKey.ClinicalAppointment:
      return (
        <ActionClinicalAppointment
          taskID={row.id}
          isLoading={isLoading}
          actionKey={row.actionKey}
          userPatientProfileID={userPatientProfileID}
          onDone={onDone}
        />
      );
    case ActionKey.CallToPatient:
      return (
        <ActionCall
          taskID={row.id}
          isLoading={isLoading}
          actionKey={row.actionKey}
          userPatientProfileID={userPatientProfileID}
          onDone={onDone}
        />
      );
    case ActionKey.SideEffect:
      return (
        <ActionSideEffect
          taskID={row.id}
          isLoading={isLoading}
          actionKey={row.actionKey}
          userPatientProfileID={userPatientProfileID}
          onDone={onDone}
        />
      );
    case ActionKey.SupportMeeting:
      return (
        <ActionSupportMeeting
          taskID={row.id}
          isLoading={isLoading}
          actionKey={row.actionKey}
          userPatientProfileID={userPatientProfileID}
          onDone={onDone}
        />
      );

    case ActionKey.NextAppointmentDetails:
      return (
        <ActionNextClinicalAppointment
          taskID={row.id}
          isLoading={isLoading}
          actionKey={row.actionKey}
          onDone={onDone}
        />
      );
  }
};
