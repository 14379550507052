import React, { useMemo } from 'react';
import { Box, BoxProps } from '@material-ui/core';
import Chart from 'react-apexcharts';
import style from './index.module.scss';
import { BmiChartArrow } from './components';
import clsx from 'clsx';
import { useTranslate } from 'hooks/use-translate';
import { BmiChartTotal } from './components/total';

const BMI_CONFIGS = [
  { value: 27, color: '#f44336', label: 'bmi-tooltip-fail', dataLabel: '0 - 27' },
  { value: 30, color: '#f49511', label: 'bmi-tooltip-maybe', dataLabel: '27 - 30' },
  { value: 50, color: '#27ae60', label: 'bmi-tooltip-fulfilled', dataLabel: '30+' },
];

const START_ANGLE = -90;
const END_ANGLE = 90;

const colors = BMI_CONFIGS.map(({ color }) => color);
const series = BMI_CONFIGS.map(({ value }) => value);
const labels = BMI_CONFIGS.map(({ label }) => label);

interface Options {
  x: number;
  y0: number;
  y1: number;
  x0: number;
  x1: number;
}
const interpolation = (options: Options) => {
  const { x, y1, y0, x0, x1 } = options;
  return y0 + ((y1 - y0) / (x1 - x0)) * (x - x0);
};

interface Props extends BoxProps {
  className?: string;
  bmi: number;
}

export const BmiChart: React.FC<Props> = ({ bmi, className, ...rest }) => {
  const { t } = useTranslate();
  const deg = useMemo(() => {
    const max = Math.max(...series);
    const sum = series.reduce((acc, v) => acc + v, 0);
    const index = series.findIndex((val) => bmi < val);

    if (bmi < 0) {
      return START_ANGLE;
    }

    if (bmi > max) {
      return END_ANGLE;
    }

    let x = index > 0 ? series.slice(0, index).reduce((acc, val) => acc + val, 0) : bmi;

    if (index > 0) {
      const y0 = 0;
      const y1 = series[index];
      const x0 = series[index - 1];
      const x1 = series[index];

      const delta = interpolation({ x: bmi, y0, y1, x0, x1 });
      x += delta;
    }

    return interpolation({ x, y0: START_ANGLE, y1: END_ANGLE, x0: 0, x1: sum });
  }, [bmi]);

  return (
    <Box className={clsx(style.root, className)} {...rest}>
      <div className={style.chart} dir={'ltr'}>
        <BmiChartArrow deg={deg} />
        <BmiChartTotal bmi={bmi} configs={BMI_CONFIGS} />
        <Chart
          width={330}
          height={350}
          options={{
            colors: colors,
            chart: {
              type: 'donut',
            },
            stroke: {
              width: 0,
            },
            plotOptions: {
              pie: {
                startAngle: -90,
                endAngle: 90,
                offsetY: 10,
              },
            },
            legend: {
              show: false,
            },
            labels: labels,
            dataLabels: {
              formatter: function (value, { seriesIndex }) {
                return BMI_CONFIGS[seriesIndex].dataLabel;
              },
            },
            grid: {
              padding: {
                bottom: -165,
              },
            },
            tooltip: {
              y: {
                formatter: () => {
                  return '';
                },
                title: {
                  formatter: (seriesName) => {
                    return t(seriesName);
                  },
                },
              },
            },
          }}
          series={series}
          type={'donut'}
        />
      </div>
    </Box>
  );
};
