import React from 'react';
import style from './index.module.scss';

interface Classes {
  root: string;
}

interface Props {
  className?: string;
  classes?: Partial<Classes>;
  deg: number;
}

export const BmiChartArrow: React.FC<Props> = ({ deg, className, classes }) => {
  return (
    <div className={style.arrow} style={{ transform: `rotate(${deg}deg)` }}>
      <div className={style.dot} />
    </div>
  );
};
