import update from 'immutability-helper';
import { NotebookFieldInput } from 'services/notebook-field-input';
import { InputType } from 'services/input-type';
import { findIndex } from 'lodash-es';
import { InputTypeParameter } from 'services/input-type-parameter';

export interface ExtendedNotebookFieldInputs extends NotebookFieldInput {
  isLoading: boolean;
  isLoadingPatch: boolean;
}

export type NOTEBOOKS_STATE = {
  isLoading: boolean;
  isInit: boolean;
  isDrag: boolean;
  notebookID: string;
  isDeleteNotebookFieldInputID: string;
  notebookFieldInputs: ExtendedNotebookFieldInputs[];
  inputTypes: InputType[];
  inputTypesParameters: { [id: string]: InputTypeParameter };
};

export const INITIAL_STATE = (): NOTEBOOKS_STATE => ({
  isLoading: true,
  isInit: false,
  isDrag: false,
  notebookID: '',
  isDeleteNotebookFieldInputID: '',
  notebookFieldInputs: [],
  inputTypes: [],
  inputTypesParameters: {},
});

export const notebooks = (state = INITIAL_STATE(), action: { type: string; payload?: object }) => {
  const { type, payload }: any = action;
  switch (type) {
    case 'NOTEBOOKS_RESET': {
      return { ...INITIAL_STATE(), inputTypesParameters: state.inputTypesParameters || {} };
    }
    case 'NOTEBOOKS_MERGE': {
      return update(state, { $merge: payload });
    }
    case 'NOTEBOOKS_MERGE_NOTEBOOK_FIELD_INPUT': {
      const { id, ...rest } = payload;
      const i = state.notebookFieldInputs.findIndex((item) => item.id === id);
      if (i !== -1) {
        return update(state, {
          notebookFieldInputs: {
            [i]: {
              $merge: rest,
            },
          },
        });
      } else {
        return state;
      }
    }
    case 'NOTEBOOKS_REMOVE_NOTEBOOK_FIELD_INPUT': {
      const { id } = payload;
      const i = state.notebookFieldInputs.findIndex((item) => item.id === id);
      if (i !== -1) {
        return update(state, { notebookFieldInputs: { $splice: [[i, 1]] } });
      } else {
        return state;
      }
    }
    case 'NOTEBOOKS_ADD_NOTEBOOK_FIELD_INPUT': {
      return update(state, {
        notebookFieldInputs: { $push: [payload] },
      });
    }
    case 'NOTEBOOKS_DND_NOTEBOOK_FIELD_INPUT': {
      const { id, droppedID, originalIndex } = payload;
      const idIndex =
        originalIndex !== undefined ? originalIndex : findIndex(state.notebookFieldInputs, { id });
      const droppedIDIndex = findIndex(state.notebookFieldInputs, { id: droppedID });
      if (id !== -1 && droppedIDIndex !== -1) {
        return update(state, {
          notebookFieldInputs: {
            $splice: [
              [droppedIDIndex, 1],
              [idIndex, 0, state.notebookFieldInputs[droppedIDIndex]],
            ],
          },
        });
      } else {
        return state;
      }
    }
    case 'NOTEBOOKS_MERGE_FIELD_INPUT': {
      const { notebookFieldInputID, ...rest } = payload;
      const i = state.notebookFieldInputs.findIndex((item) => item.id === notebookFieldInputID);
      if (i !== -1) {
        return update(state, {
          notebookFieldInputs: {
            [i]: {
              fieldInput: {
                $merge: rest,
              },
            },
          },
        });
      } else {
        return state;
      }
    }
    default:
      return state;
  }
};

export const isLoadingPatchNotebookFieldInput = (id: string, isLoadingPatch: boolean) => ({
  type: 'NOTEBOOKS_MERGE_NOTEBOOK_FIELD_INPUT',
  payload: { id, isLoadingPatch },
});
