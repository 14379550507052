import React, { useMemo } from 'react';
import { Box, BoxProps } from '@material-ui/core';
import { DateValue, getAge } from 'utils/dates';

interface Props extends BoxProps {
  value: DateValue;
}
export const TemplateAge: React.FC<Props> = ({ value, ...rest }) => {
  const Child = useMemo(() => {
    return getAge(value);
  }, [value]);

  return <Box {...rest}>{Child}</Box>;
};
