import { createSlice } from '@reduxjs/toolkit';

interface State {}
const initialState = (): State => ({});

const slice = createSlice({
  name: 'SUPPORT_MEETING_ACTIVITIES',
  initialState,
  reducers: {},
  extraReducers(build) {},
});
// export const {} = slice.actions;
export const supportMeetingActivitiesReducer = slice.reducer;
