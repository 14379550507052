import React, { memo, useCallback, useState } from 'react';
import { Dialog, DialogContent, LinearProgress } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { GridConfig } from './support-meeting-activities-grid-config';
import { useFormGrid } from 'AurionCR/components/formV2';
import DataGrid from 'components/data-grid';
import style from './index.module.scss';
import { SupportMeetingActivitiesEditor } from './support-meeting-activities-editor';
import { useTranslate } from 'hooks/use-translate';
import { useCurrentUser } from 'components/hooks';

interface Props {
  supportMeetingID: string;
  onClose: () => void;
  onUpdateCount?: (count: number) => void;
}
export const SupportMeetingActivitiesDialog = memo<Props>(
  ({ supportMeetingID, onClose, onUpdateCount }) => {
    const { t } = useTranslate();

    const [loading, setLoading] = useState(false);
    const { appUserID } = useCurrentUser();
    const [gridConfig] = useState<any>(
      GridConfig({ appUserID: '', supportMeetingID }),
    );

    const { grid, onGridHandle, editItemID, setEditItemID, onFormHandle } = useFormGrid('activity');
    // handlers
    const onGridHandleProxy = useCallback(
      (props) => {
        if (props.type === 'getStart') {
          setLoading(true);
        } else if (props.type === 'getDone') {
          setLoading(false);
          let total = 0;
          if (grid?.current?.get) {
            total = grid.current.get('pagination')?.total_ || 0;
          }
          onUpdateCount && onUpdateCount(total);
        }
        onGridHandle(props);
      },
      [onGridHandle, grid, setLoading, onUpdateCount],
    );

    return (
      <Dialog
        open={true}
        disableEscapeKeyDown={true}
        onClose={onClose}
        fullWidth
        maxWidth="lg"
        className={style.dialog}
      >
        <DialogHeading title={t('activities')} onClose={onClose} disabled={loading} />
        {loading && <LinearProgress />}
        <DialogContent>
          <DataGrid ref={grid} onHandle={onGridHandleProxy} config={gridConfig} />
          {editItemID && (
            <SupportMeetingActivitiesEditor
              onClose={() => setEditItemID(false)}
              appUserID={appUserID}
              onFormHandle={onFormHandle}
              itemID={editItemID}
              supportMeetingID={supportMeetingID}
            />
          )}
        </DialogContent>
      </Dialog>
    );
  },
);
