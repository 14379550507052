import React, { memo } from 'react';
import { ClinicalMeeting } from 'services/clinical-meetings';
import { isRequestItemCreated } from 'hooks';
import { MEETING_TYPE_KEY } from 'services/clinical-meeting-types';
import { DialogClinicalMeetingEdit } from './components/dialog-edit';
import { DialogClinicalMeetingNew } from './components/dialog-new';
import { ClinicalMeetingFormModel } from './models';

type PredefinedValues = Pick<ClinicalMeeting, 'clinicalMeetingSubjectID' | 'userPatientProfileID'>;
interface Props {
  itemID: true | string;
  onClose: () => void;
  onComplete: () => void;
  initData?: Partial<ClinicalMeetingFormModel>;
  meetingTypeKey?: MEETING_TYPE_KEY;
  times: { id: string }[];
  predefinedValues?: Partial<PredefinedValues>;
}

export const DialogClinicalMeeting = memo<Props>(({ itemID, ...rest }) => {
  const isEdit = isRequestItemCreated(itemID);

  return isEdit ? (
    <DialogClinicalMeetingEdit itemID={itemID} {...rest} />
  ) : (
    <DialogClinicalMeetingNew {...rest} />
  );
});

export { DialogCancelMeetingContainer } from './components';
