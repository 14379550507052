import React from 'react';
import SendIcon from '@material-ui/icons/Send';

import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
} from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { useTranslate } from 'hooks/use-translate';
import { SmsMessageModel, SmsMessageModelValid } from './models';
import { FormProvider, useForm } from 'react-hook-form';
import { SendSmsMessageForm } from './components';
import { useAppSelector } from 'store';
import { selectLanguageIsRtl } from 'store/languages';

export * from './models';

interface Props<TP extends Record<string, any>> {
  title?: string;
  isLoading?: boolean;
  onClose: () => void;
  initData: Partial<SmsMessageModel>;
  onSubmit: (data: SmsMessageModelValid) => void;
  templatePayload?: TP;
  DialogProps?: Partial<DialogProps>;
  ButtonSendProps?: Partial<ButtonProps>;
}

export const DialogSendSmsMessage = <T extends Record<string, any>>({
  title,
  isLoading = false,
  initData,
  templatePayload,
  onClose,
  onSubmit,
  DialogProps,
  ButtonSendProps,
}: Props<T>) => {
  const { t } = useTranslate();
  const isRtl = useAppSelector(selectLanguageIsRtl);

  const formMethods = useForm({
    defaultValues: {
      message: '',
      toPhoneNumber: '',
      ...initData,
    },
  });

  return (
    <Dialog fullWidth={true} maxWidth={'sm'} open={true} onClose={onClose} {...DialogProps}>
      <DialogHeading
        isLoading={isLoading}
        title={title || t('dialog-send-sms-message')}
        onClose={onClose}
      />
      <DialogContent>
        <FormProvider {...formMethods}>
          <SendSmsMessageForm isLoading={isLoading} templatesPayload={templatePayload} />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button color={'secondary'} onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button
          color={'primary'}
          variant={'contained'}
          endIcon={<SendIcon style={{ transform: isRtl ? 'scaleX(-1)' : 'scaleX(1)' }} />}
          {...ButtonSendProps}
          onClick={formMethods.handleSubmit(onSubmit)}
          disabled={isLoading || ButtonSendProps?.disabled}
        >
          {t('send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
