import { DynamicService } from 'utils/service';
import { API_LANGUAGES, AppLanguage, iLanguage } from './models';

export * from './models';

class Service extends DynamicService<iLanguage> {
  getAppLanguages = async (): Promise<any> => {
    return this.getAllDynamic<AppLanguage>({
      select: ['id', 'culture', 'title', 'direction'].join(','),
      filter: 'isActive==true',
    });
  };
}

export const ServiceLanguages = new Service({
  mainField: 'id',
  getAll: API_LANGUAGES.GET_ALL_DYNAMIC,
  post: API_LANGUAGES.POST,
  delete: API_LANGUAGES.DELETE,
  patch: API_LANGUAGES.PATCH,
});
