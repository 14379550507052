import { createSlice } from '@reduxjs/toolkit';
import { actionLabelsFetch } from './actions';

interface State {
  isLoading: boolean;
}

const initialState = (): State => ({
  isLoading: false,
});

const slice = createSlice({
  name: 'LABELS',
  initialState,
  reducers: {},
  extraReducers(build) {
    build
      .addCase(actionLabelsFetch.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(actionLabelsFetch.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(actionLabelsFetch.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const labelsReducer = slice.reducer;
