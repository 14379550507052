import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  LinearProgress,
  Tooltip,
} from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { useI18n } from 'AurionCR/components';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SaveIcon from '@material-ui/icons/Save';

interface Props extends Partial<DialogProps> {
  children: React.ReactNode;
  onClose?: () => void;
  onPreview?: () => void;
  onGenerate?: () => void;
  title?: string;
  isLoading?: boolean;
}

export const DialogPdf: React.FC<Props> = ({
  children,
  title,
  onClose,
  onPreview,
  onGenerate,
  isLoading,
  ...rest
}) => {
  const { t } = useI18n();
  return (
    <Dialog open={true} onClose={onClose} {...rest}>
      <DialogHeading title={title} onClose={onClose} />
      {isLoading && <LinearProgress />}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Tooltip title={t('pdf-preview-tooltip')}>
          <Button
            color={'secondary'}
            startIcon={<VisibilityIcon />}
            variant={'contained'}
            onClick={onPreview}
            disabled={isLoading}
          >
            {t('preview')}
          </Button>
        </Tooltip>

        <Tooltip title={t('pdf-generate-tooltip')}>
          <Button
            disabled={isLoading}
            color={'primary'}
            startIcon={<SaveIcon />}
            variant={'contained'}
            onClick={onGenerate}
          >
            {t('generate')}
          </Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
