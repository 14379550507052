import { DynamicService } from 'utils/service';
import { API_PRESCRIPTION_STEPS, PRESCRIPTION_STEP_TYPE_KEY, PrescriptionStep } from './models';
import { AxiosResponse } from 'axios';
import { apiRtk } from 'utils/rtk-query';

export * from './models';

class Service extends DynamicService<PrescriptionStep> {
  getByKey = async (key: PRESCRIPTION_STEP_TYPE_KEY) => {
    const result = await this.getAllDynamic({
      filter: `stepTypeKey=="${key}"`,
      take: 1,
    });

    const data = result.data.value[0];

    if (!data) {
      throw new Error(`ServicePrescriptionSteps: can not find record with key: "${key}"`);
    }

    return { ...result, data } as AxiosResponse<PrescriptionStep>;
  };
}

export const ServicePrescriptionSteps = new Service({
  mainField: 'id',
  getAll: API_PRESCRIPTION_STEPS.GET_ALL_DYNAMIC,
  post: API_PRESCRIPTION_STEPS.POST,
  patch: API_PRESCRIPTION_STEPS.PATCH,
  delete: API_PRESCRIPTION_STEPS.DELETE,
});

export const apiPrescriptionSteps = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getPrescriptionStepByKey: builder.query({
      queryFn: async (key: PRESCRIPTION_STEP_TYPE_KEY) => {
        return ServicePrescriptionSteps.getByKey(key);
      },
    }),
  }),
});
