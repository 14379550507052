import React, { useMemo } from 'react';
import { Stack } from 'components/stack';
import { Typography } from '@material-ui/core';
import { ActionKey, CONFIG_CATEGORY_ACTION } from 'services/todo-task-categories';

interface Props {
  row: { actionKey: ActionKey; category: string };
}

export const ColumnTaskCategory: React.FC<Props> = ({ row }) => {
  const { Icon, color } = useMemo(() => {
    return CONFIG_CATEGORY_ACTION[row.actionKey];
  }, [row]);

  return (
    <Stack direction={'row'} spacing={1}>
      <Icon style={{ color: color }} />
      <Typography style={{ whiteSpace: 'nowrap', color: color, alignSelf: 'center' }}>
        {row.category}
      </Typography>
    </Stack>
  );
};
