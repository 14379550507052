import React, { memo, useCallback, useEffect, useMemo } from 'react';
import GridConfig from './grid-config';
import DataGrid from 'components/data-grid';
import { useFormGrid } from 'AurionCR/components/formV2';
import Form from './form';
import style from './index.module.scss';
import { Typography } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { IDataGridRowProps } from 'components/data-grid/model';
import { GridModel } from './helpers';
import { ControlSlot } from './control-slot';
import clsx from 'clsx';
import { notebookEmitter } from '../../emitter';

interface Props {
  userPatientProfileID: string;
  userPatientProfileSessionID: string | null;
  onCopyFromPrevious?: () => void;
  onTryCreate?: () => void;
  isShowCloneTooltip: boolean;
  isLoading: boolean;
}
export const GridClinicalDrugs = memo<Props>(
  ({
    isShowCloneTooltip,
    userPatientProfileID,
    userPatientProfileSessionID,
    onCopyFromPrevious,
    onTryCreate,
    isLoading,
  }) => {
    const { t } = useTranslate();
    const gridConfig = useMemo<any>(
      () => GridConfig({ userPatientProfileID, userPatientProfileSessionID }),
      [userPatientProfileID, userPatientProfileSessionID],
    );
    const { grid, onGridHandle, onFormHandle, editItemID, onClose, setEditItemID } =
      useFormGrid('clinical-drugs');

    const rowProps = useCallback<IDataGridRowProps<GridModel>>(
      (option) => {
        return {
          className: clsx({
            [style.isCurrent]:
              userPatientProfileSessionID === option.row.userPatientProfileSessionID,
            [style.isAuto]: option.row.isAutoGenerated,
          }),
        };
      },
      [userPatientProfileSessionID],
    );

    const onCreate = useCallback(() => {
      setEditItemID(true);
      if (!userPatientProfileSessionID && onTryCreate) {
        onTryCreate();
      }
    }, [setEditItemID, userPatientProfileSessionID, onTryCreate]);

    useEffect(() => {
      const onRefresh = () => {
        grid.current?.onTasks([['getData']]);
      };
      notebookEmitter.on('refresh-clinical-drugs', onRefresh);
      return () => {
        notebookEmitter.off('refresh-clinical-drugs', onRefresh);
      };
    }, [grid]);

    return (
      <>
        <DataGrid
          className={style.grid}
          ref={grid}
          config={gridConfig}
          onHandle={onGridHandle}
          rowProps={rowProps}
          ScrollProps={{
            mode: 'visible',
          }}
          renderToolbarControlsSlot={() => {
            return (
              <Typography color={'secondary'} variant={'h2'}>
                {t('clinical-drugs')}
              </Typography>
            );
          }}
          renderControlsSlot={() => {
            return (
              <ControlSlot
                isShowCloneTooltip={isShowCloneTooltip}
                onClone={onCopyFromPrevious}
                onCreate={onCreate}
                disabled={isLoading}
              />
            );
          }}
        />
        {editItemID && userPatientProfileSessionID && (
          <Form
            onClose={onClose}
            onFormHandle={onFormHandle}
            itemID={editItemID}
            userPatientProfileSessionID={userPatientProfileSessionID}
          />
        )}
      </>
    );
  },
);
