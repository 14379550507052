import React, { useMemo } from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import * as yup from 'yup';
import { DialogHeading } from 'components/dialog-title';
import { ButtonCancel, ButtonSave } from 'components/app-buttons';
import { useTranslate } from 'hooks/use-translate';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldProps, useFormDefaultValue } from 'hooks';
import { AppInput } from 'components/app-input';
import { Alert } from '@material-ui/lab';
import { AppSelect } from '../app-select';
import { useSourcePatientRemarkStatuses } from '../hooks';

const schema = yup.object({
  remarks: yup
    .string()
    .required('rule-required')
    .min(2, 'rule-min-length')
    .max(2000, 'rule-max-length')
    .trim()
    .default(''),
  remarkForPatientCallStatusID: yup.string().required('rule-required').default(null),
});

const defaultValues = schema.cast({}, { assert: false });

export type MeetingActivityReason = 'default' | 'reassigned' | 'rescheduled';
export type MeetingActivityModel = typeof defaultValues;

interface Props {
  title: string;
  description: string;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (formData: MeetingActivityModel) => void;
  reason?: MeetingActivityReason;
}
export const DialogMeetingActivityRemark: React.FC<Props> = ({
  isLoading,
  title,
  description,
  onClose,
  onSubmit,
  reason = 'default',
}) => {
  const { tp } = useTranslate();
  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { errors, control, handleSubmit, setValue, watch } = methods;
  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

  const sourcePatientRemarkStatuses = useSourcePatientRemarkStatuses();

  const sourceRemarks = useMemo(() => {
    if (reason === 'reassigned') {
      return sourcePatientRemarkStatuses.data.filter(({ isReassigned }) => isReassigned);
    }
    if (reason === 'rescheduled') {
      return sourcePatientRemarkStatuses.data.filter(({ isRescheduled }) => isRescheduled);
    }
    return sourcePatientRemarkStatuses.data;
  }, [sourcePatientRemarkStatuses, reason]);

  useFormDefaultValue(sourceRemarks[0]?.id, {
    name: 'remarkForPatientCallStatusID',
    setValue,
    watch,
    emptyValue: null,
  });

  return (
    <Dialog open={true} fullWidth maxWidth={'sm'}>
      <DialogHeading title={title} isLoading={isLoading} onClose={onClose} />
      <DialogContent>
        <Alert severity="info">{description}</Alert>
        <Controller
          control={control}
          name={'remarkForPatientCallStatusID'}
          render={(renderProps) => {
            return (
              <AppSelect
                {...getFieldProps(renderProps)}
                label={tp('remarks-for-patient')}
                options={sourceRemarks}
                loading={sourcePatientRemarkStatuses.loading}
                disabled={isLoading}
              />
            );
          }}
        />
        <Controller
          control={control}
          name={'remarks'}
          render={(renderProps) => {
            return (
              <AppInput
                {...getFieldProps(renderProps)}
                multiline
                minRows={5}
                disabled={isLoading}
              />
            );
          }}
        />
      </DialogContent>
      <DialogActions>
        <ButtonCancel isBack={false} onClick={onClose} />
        <ButtonSave disabled={isLoading} isCreate={false} onClick={handleSubmit(onSubmit)}>
          {tp('submit')}
        </ButtonSave>
      </DialogActions>
    </Dialog>
  );
};
