import React, {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { FormController, Select } from 'AurionCR/components/formV2';
import { checkEs6AndRun, checkES6Template } from 'AurionCR/components';
import { fieldValueDefault, iField, iFieldRefProps } from './@type';
import { useTranslate } from 'hooks/use-translate';
import { useFormContext } from 'react-hook-form';
import { mergeFilters } from 'utils/dynamic-helpers';

export const FieldMultiSelect = memo(
  forwardRef<iFieldRefProps, iField>(({ item, disabled = false, onWasChange }, ref) => {
    const { t } = useTranslate();
    const name = item.fieldInputID as string;
    const labelKey = item.fieldInput?.labelKey;

    const { control, errors } = useFormContext();

    const selectParams = useMemo(() => {
      const params = item?.fieldInput?.inputType?.inputTypeDataSource;

      if (!params) {
        return null;
      }

      const url = params?.dataSourceName || '';

      if (!url) {
        return null;
      }

      const labelFields = (((params?.fieldVal as string) || '').match(/({.*?})/gm) || []).map(
        (val: string) => val.slice(1, -1),
      );

      if (labelFields.length === 0) {
        return null;
      }

      const filter = mergeFilters(
        ...labelFields.map((key) => `${key}.ToLower().contains("\${data}")`),
      ).join('||');

      const source = {
        url: `${url}?orderBy=${labelFields.join(',')}`,
        baseUrl: '/',
        filter,
        requestFilter: params.filter,
        take: 10,
        select: `${params.fieldKey},${labelFields.join(',')}`,
      };

      const label = (params?.fieldVal || '').replace(/\{/gm, '${data.');
      const option = { label, value: params.fieldKey };
      return {
        source,
        label,
        option,
      };
    }, [item]);

    const getOptionLabel = useCallback(
      (value) => {
        const label = selectParams?.option?.label;
        if (label) {
          if (checkES6Template(label)) {
            return checkEs6AndRun(label, value);
          } else {
            return value[label] || 'error-label-settings';
          }
        } else {
          return 'error-label-settings';
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [selectParams?.option],
    );

    const getOptionValue = useCallback(
      (item: any) => {
        return item[selectParams?.option?.value || 'id'];
      },
      [selectParams],
    );

    const [value, setValue] = useState<any>(item.formValue || []);

    const getValue = useCallback(() => {
      return {
        ...fieldValueDefault(),
        id: item.formID || '',
        fieldInputID: item.fieldInputID as string,
        externalSystemValue: value.length
          ? value.map((item: any) => getOptionValue(item) || '-').join(',')
          : null,
        entryValueString: value.length ? value.map(getOptionLabel).join(',') : null,

        formValue: value,
        notebookFieldInputID: item.id,
      };
    }, [item, getOptionValue, getOptionLabel, value]);

    useImperativeHandle(ref, () => ({ getValue }), [getValue]);

    // render
    return (
      <div>
        {selectParams ? (
          <FormController
            name={name}
            label={labelKey ? t(labelKey) : undefined}
            as={Select}
            rules={item?.isRequired ? 'required' : undefined}
            errors={errors}
            control={control}
            disabled={disabled}
            source={selectParams.source}
            option={selectParams.option}
            defaultValue={item.formValue ?? undefined}
            onBlur={onWasChange}
            onChange={(e, value) => {
              setValue(value);
              return e;
            }}
            multiple
          />
        ) : (
          <div style={{ color: 'red' }}>
            <div>{item.fieldInput?.labelKey || ''} - ERROR</div>
            <div style={{ fontSize: 9 }}>{JSON.stringify(item)}</div>
          </div>
        )}
      </div>
    );
  }),
);
