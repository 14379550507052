const API_ENDPOINT = 'PharmacyDrugQuantities';

export const API_PHARMACY_DRUG_QUANTITIES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<PharmacyDrugQuantity, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<PharmacyDrugQuantity, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface PharmacyDrugQuantity extends Components.Schemas.PharmacyDrugQuantity {}
export interface IPharmacyDrugQuantityForm
  extends Pick<
    Components.Schemas.PharmacyDrugQuantity,
    'id' | 'pharmacyDrugID' | 'dosageFormTotalDesc' | 'quantity'
  > {}
export interface ValidateDosageForm {
  id: string | null | undefined;
  dosageFormTotalDesc: string;
  pharmacyDrugID: string;
}
