import { createFilterEquals } from 'utils/dynamic-helpers';
import { API_SITE_PARAMETERS, iSiteParameters } from './models';
import { api, DynamicResult, transformDynamicToItem } from 'utils/service';
import { apiRtk } from 'utils/rtk-query';

export * from './models';

export type SiteParametersMainInfo = Pick<
  iSiteParameters,
  'logo' | 'slogan' | 'address' | 'phone' | 'email'
>;

class Service {
  async getMainInfo(languageID: string) {
    const params = {
      filter: createFilterEquals('languageID', languageID),
      select: ['logo,slogan,address,phone,email'].join(','),
    };

    const result = await api.get<DynamicResult<SiteParametersMainInfo, typeof params>>(
      API_SITE_PARAMETERS.GET_ALL_DYNAMIC,
      {
        params,
      },
    );

    const mainInfo = transformDynamicToItem(result.data);

    return { ...result, data: mainInfo };
  }
}

export const ServiceSiteParameters = new Service();

export const apiSiteParams = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getSiteParamsPdfInfo: builder.query<SiteParametersMainInfo, string>({
      queryFn: async (languageID) => {
        try {
          const { data } = await ServiceSiteParameters.getMainInfo(languageID);

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
