import React, { useCallback } from 'react';
import { MedicalPrescriptionDialog } from 'components/medical-prescription';
import { BaseAction } from '../../models';
import { ButtonAction } from '../button-action';
import { useOpen } from 'AurionCR/components/hooks';
import { composeFunctions } from 'utils';
import { usePermissions } from 'hooks/use-permissions';
import { TooltipError } from 'components/tooltips';
import { apiTodoTasks } from 'services/todo-tasks';
import { useEffectError } from 'hooks';
import { useCurrentUser } from '../../../hooks';
import { isMutationFulfilled } from 'utils/rtk-query';

const useDoneTaskMutation = apiTodoTasks.useUpdateTodoTaskDoneMutation;

interface Props extends BaseAction {
  userPatientProfileID: string;
}

export const ActionPrescription: React.FC<Props> = ({
  taskID,
  isLoading: isLoadingOutside,
  userPatientProfileID,
  onDone: _onDone,
  actionKey,
}) => {
  const { appUserID } = useCurrentUser();
  const [doneItem, resultDoneItem] = useDoneTaskMutation();
  useEffectError(resultDoneItem.error);

  const { isOpen: isOpenNew, handleOpen: onOpenNew, handleClose: onCloseNew } = useOpen();
  const isAllowed = usePermissions('isAllowToCreateAndRenewPrescriptions');

  const onDone = useCallback(async () => {
    const res = await doneItem({ id: taskID, done: true, employeeProfileID: appUserID });
    if (isMutationFulfilled(res)) {
      _onDone();
    }
  }, [doneItem, taskID, appUserID, _onDone]);

  return (
    <>
      <TooltipError title={isAllowed ? '' : 'not-enough-permission'} arrow>
        <div>
          <ButtonAction
            actionKey={actionKey}
            disabled={isLoadingOutside || !isAllowed}
            onClick={onOpenNew}
          />
        </div>
      </TooltipError>

      {isOpenNew && (
        <MedicalPrescriptionDialog
          userPatientProfilePrescriptionID={true}
          userPatientProfileID={userPatientProfileID}
          onClose={onCloseNew}
          onComplete={composeFunctions(onDone, onCloseNew)}
          onDeleted={onCloseNew}
        />
      )}
    </>
  );
};
