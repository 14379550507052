import React, { memo, useCallback, useMemo } from 'react';
import DataGrid from 'components/data-grid';
import { useFormGrid } from 'AurionCR/components/formV2';
import { GridConfig } from './grid-config';
import { Editor } from './editor';
import { useCurrentUser } from '../../../hooks';

interface Props {
  userPatientProfilePrescriptionID: string;
  onRefresh: () => void;
  className?: string;
}

export const GridUserPatientProfilePrescriptionActivities = memo<Props>(
  ({ userPatientProfilePrescriptionID, onRefresh, className }) => {
    const { $isAdmin } = useCurrentUser();
    const { grid, onGridHandle, editItemID, setEditItemID, onFormHandle } =
      useFormGrid('prescription-activity');

    const gridConfig = useMemo<any>(() => {
      return GridConfig({ userPatientProfilePrescriptionID, isAdmin: $isAdmin });
    }, [userPatientProfilePrescriptionID, $isAdmin]);

    const onClose = useCallback(() => setEditItemID(null), [setEditItemID]);

    const onFormHandleProxy = useCallback<typeof onFormHandle>(
      (action) => {
        switch (action.type) {
          case 'post':
          case 'delete': {
            onRefresh();
          }
        }
        return onFormHandle(action);
      },
      [onFormHandle, onRefresh],
    );

    return (
      <>
        <DataGrid className={className} ref={grid} onHandle={onGridHandle} config={gridConfig} />
        {editItemID && (
          <Editor
            userPatientProfilePrescriptionID={userPatientProfilePrescriptionID}
            itemID={editItemID}
            onClose={onClose}
            onFormHandle={onFormHandleProxy}
          />
        )}
      </>
    );
  },
);
