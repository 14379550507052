import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DialogHeading } from 'components/dialog-title';
import { Button, DialogActions, DialogContent, Divider, Grid } from '@material-ui/core';
import { DeleteActivity, useTitle } from '../../activity';
import { add, set } from 'date-fns';
import { composeFunctions } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffectError, useMountedRef } from 'hooks';
import {
  selectNotebookFormGeneratorNextSupportMeetingData,
  actionNotebookFormGeneratorStoreNextMeetingData,
  NotebookFormGeneratorNextMeetingData,
  selectNotebookFormGeneratorNotebookSettings,
} from 'store/notebook-form-generator';
import { PreviewResultLayout } from '../preview-result-layout';
import {
  apiSupportMeeting,
  PatientFutureSupportMeeting,
  schemaSupportMeetingNext,
} from 'services/support-meetings';
import { convertToDate } from 'utils/dates';
import { useCurrentUser } from 'components/hooks';
import { isMutationFulfilled } from 'utils/rtk-query';
import { PreviewSessionsBlock } from 'components/dialoog-user-patient-profile-session/components';
import { ResultTopControls } from '../result-top-controls';
import { Loading } from 'AurionCR/components';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Stack } from 'components/stack';
import AddIcon from '@material-ui/icons/Add';
import { ListFutureSupportMeetings } from 'components/dialog-next-future-support-meeting';
import { NativeScroll } from 'components/native-scroll';
import { adapterMeeting } from 'utils/adapters';
import { NextMeetingEditContainer } from '../next-meeting-edit-container';
import { NextMeetingNewContainer } from '../next-meeting-new-container';
import { useTranslate } from 'hooks/use-translate';
import style from './index.module.scss';
import { useNotebookFormGeneratorProps } from '../../hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  actionSupportMeetingActivitiesCrateLogCreated,
  actionSupportMeetingActivitiesCrateLogUpdated,
} from 'store/support-meeting-activities';
import { NotebookDialogTitle } from '../notebook-dialog-title';

interface StepFutureMeetingsProps {
  list: PatientFutureSupportMeeting[];
  onContinue: (value: PatientFutureSupportMeeting) => void;
}
const StepFutureMeetings: React.FC<StepFutureMeetingsProps> = ({ list, onContinue }) => {
  const { t } = useTranslate();
  const [selectedID, setSelectedID] = useState<string | null>(null);
  const meeting = useMemo(() => {
    return list.find((meeting) => meeting.id === selectedID);
  }, [list, selectedID]);

  const handleContinue = useCallback(() => {
    if (!meeting) return;
    onContinue(meeting);
  }, [meeting, onContinue]);
  return (
    <Stack direction={'column'} spacing={1}>
      <Alert severity="warning" classes={{ message: style.alertMessage }}>
        <AlertTitle>{t('future-meetings-continue')}</AlertTitle>
        {t('future-meetings-continue-description')}
      </Alert>
      <NativeScroll style={{ maxHeight: '30rem' }}>
        <ListFutureSupportMeetings
          selectedID={selectedID}
          list={list}
          disabled={false}
          onSelect={setSelectedID}
          ItemsProps={{
            disableGutters: false,
          }}
        />
      </NativeScroll>
      <Stack justifyContent={'flex-end'} p={1} spacing={1}>
        <Button
          variant={'contained'}
          color={'primary'}
          disabled={!selectedID}
          onClick={handleContinue}
        >
          {t('continue')}
        </Button>
      </Stack>
    </Stack>
  );
};

enum STEP {
  FUTURE_MEETINGS = 'FUTURE_MEETINGS',
  FORM = 'FORM',
}

const splitMeetingSlotTime = (value: string = '09:00') => {
  const [hours, minutes] = value.split(':');

  return { hours: Number(hours), minutes: Number(minutes), seconds: 0, milliseconds: 0 };
};

const defaultValues: NotebookFormGeneratorNextMeetingData = {
  date: add(new Date(), { days: 7 }).toISOString(),
  start: '09:00',
  end: '09:20',
  includeMeetingTime: true,
  supportMeetingTypeID: '',
  userEmployeeProfileID: '',
  remarks: '',
};

interface Props {
  userPatientProfileSessionID: string;
  notebookLabelKey: string;
  isTLC: boolean;
  onClose: (e?: any) => void;
  onEdit: () => void;
  onDone: () => void;
}
export const NotebookFormGeneratorStateResultMeeting = memo<Props>(
  ({ userPatientProfileSessionID, onClose, onDone, onEdit, notebookLabelKey, isTLC }) => {
    const {
      userPatientProfileID,
      supportMeetingTypeID,
      showDelete,
      excludeFutureMeetingIDs,
      meetingFromDateTime,
      meetingToDateTime,
    } = useNotebookFormGeneratorProps();

    const settings = useAppSelector(selectNotebookFormGeneratorNotebookSettings);

    const [futureSupportMeeting, setFutureSupportMeeting] =
      useState<PatientFutureSupportMeeting | null>(null);
    const [step, setStep] = useState<STEP>(STEP.FORM);

    const mountedRef = useMountedRef();
    const { appUserID } = useCurrentUser();
    const { t } = useTranslate();
    const dispatch = useAppDispatch();

    const { isCanDelete } = useTitle(userPatientProfileSessionID);

    const storedDefaultValues = useAppSelector(selectNotebookFormGeneratorNextSupportMeetingData);

    const formDefaultValue = useMemo(() => {
      const { start, end } = adapterMeeting({
        meetingFromDateTime,
        meetingToDateTime,
      });
      const payload = {
        ...defaultValues,
        date: add(new Date(), { days: 7 }).toISOString(),
        supportMeetingTypeID,
        userEmployeeProfileID: appUserID,
        userPatientProfileID,
        remarks: [t('previews-notebook-name'), settings?.labelKey].filter(Boolean).join(' - '),
        start,
        end,
        ...storedDefaultValues,
      };

      return schemaSupportMeetingNext.cast(payload, { stripUnknown: true, assert: false });
    }, [
      userPatientProfileID,
      supportMeetingTypeID,
      appUserID,
      storedDefaultValues,
      settings,
      meetingFromDateTime,
      meetingToDateTime,
      t,
    ]);

    const formMethods = useForm({
      defaultValues: formDefaultValue,
      resolver: yupResolver(schemaSupportMeetingNext),
    });

    const { handleSubmit, getValues, reset } = formMethods;

    const onCloseDeleteDialog = useCallback(
      (bool) => {
        if (bool) onClose();
      },
      [onClose],
    );

    const [postOrPatchNextMeeting, resultPostNextMeeting] =
      apiSupportMeeting.usePostOrPatchSupportMeetingMutation();
    useEffectError(resultPostNextMeeting.error);

    const onSubmit = useCallback(
      async (formData: Partial<NotebookFormGeneratorNextMeetingData>) => {
        const { includeMeetingTime, supportMeetingTypeID, date, start, end, remarks } = formData;

        if (!supportMeetingTypeID) {
          return;
        }

        const result = await postOrPatchNextMeeting({
          id: futureSupportMeeting ? futureSupportMeeting.id : undefined,
          meetingFromDateTime: set(convertToDate(date), splitMeetingSlotTime(start)).toISOString(),
          meetingToDateTime: set(convertToDate(date), splitMeetingSlotTime(end)).toISOString(),

          remarks,
          supportMeetingTypeID,
          includeMeetingTime,

          userPatientProfileID,
          userEmployeeProfileID: formData.userEmployeeProfileID || appUserID,
          userPatientProfileSessionID: null,
        });

        if (!isMutationFulfilled(result)) {
          return;
        }

        if (futureSupportMeeting) {
          dispatch(
            actionSupportMeetingActivitiesCrateLogUpdated({
              oldData: futureSupportMeeting,
              newData: result.data,
            }),
          );
        } else {
          dispatch(
            actionSupportMeetingActivitiesCrateLogCreated({ supportMeetingID: result.data.id }),
          );
        }

        if (!mountedRef.current) {
          return null;
        }

        onDone();
      },
      [
        onDone,
        appUserID,
        userPatientProfileID,
        mountedRef,
        postOrPatchNextMeeting,
        futureSupportMeeting,
        dispatch,
      ],
    );

    const storeStoreSupportMeetingData = useCallback(() => {
      dispatch(actionNotebookFormGeneratorStoreNextMeetingData(getValues()));
    }, [dispatch, getValues]);

    const resultFutureMeeting =
      apiSupportMeeting.useGetPatientFutureSupportMeetingsQuery(userPatientProfileID);
    const futureMeetings = useMemo(() => {
      return (resultFutureMeeting.data || []).filter((item) => {
        const isInExclude = excludeFutureMeetingIDs.some((excludeID) => excludeID === item.id);
        return !isInExclude;
      });
    }, [resultFutureMeeting.data, excludeFutureMeetingIDs]);

    useEffect(() => {
      if (resultFutureMeeting.isSuccess && futureMeetings && futureMeetings.length > 0) {
        setStep(STEP.FUTURE_MEETINGS);
      }
    }, [resultFutureMeeting.isSuccess, futureMeetings]);

    const onCreateNew = useCallback(() => {
      setStep(STEP.FORM);
      setFutureSupportMeeting(null);
    }, []);

    const onContinue = useCallback(
      (futureMeeting: PatientFutureSupportMeeting) => {
        const meeting = { ...getValues(), ...adapterMeeting(futureMeeting) };
        reset(
          schemaSupportMeetingNext.cast(
            { ...meeting, remarks: meeting.remarks || '', userPatientProfileID },
            { stripUnknown: true, assert: false },
          ),
        );
        setFutureSupportMeeting(futureMeeting);
        setStep(STEP.FORM);
      },
      [reset, getValues, userPatientProfileID],
    );

    const isLoading =
      resultPostNextMeeting.isLoading ||
      resultFutureMeeting.isFetching ||
      resultFutureMeeting.isLoading;

    const meetingElement = futureSupportMeeting ? (
      <NextMeetingEditContainer isLoading={isLoading} />
    ) : (
      <NextMeetingNewContainer isLoading={isLoading} isTLC={isTLC} />
    );

    return (
      <>
        <DialogHeading
          TitleProps={{ style: { width: '100%' } }}
          isLoading={isLoading}
          title={
            <NotebookDialogTitle
              mainTitle={notebookLabelKey}
              userPatientProfileID={userPatientProfileID}
            />
          }
        >
          <ResultTopControls
            userPatientProfileID={userPatientProfileID}
            userPatientProfileSessionID={userPatientProfileSessionID}
          />
        </DialogHeading>
        <DialogContent style={{ display: 'flex' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7} xl={8}>
              <PreviewResultLayout title={t('preview')}>
                <PreviewSessionsBlock userPatientProfileSessionID={userPatientProfileSessionID} />
              </PreviewResultLayout>
            </Grid>
            <Divider flexItem orientation={'vertical'} />
            <Grid item xs={6} md={true} xl={true}>
              <PreviewResultLayout
                title={t('next-meeting')}
                ContentProps={{ position: 'relative' }}
                control={
                  step === STEP.FUTURE_MEETINGS && (
                    <Button
                      color={'primary'}
                      variant={'contained'}
                      startIcon={<AddIcon />}
                      disabled={isLoading}
                      onClick={onCreateNew}
                      size={'small'}
                    >
                      {t('start-new')}
                    </Button>
                  )
                }
              >
                <FormProvider {...formMethods}>
                  {isLoading && <Loading />}
                  {step === STEP.FUTURE_MEETINGS && (
                    <StepFutureMeetings list={futureMeetings || []} onContinue={onContinue} />
                  )}
                  {step === STEP.FORM && meetingElement}
                </FormProvider>
              </PreviewResultLayout>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={style.footer}>
          <DeleteActivity
            isCanDelete={isCanDelete && showDelete}
            formTitle={notebookLabelKey}
            userPatientProfileSessionID={userPatientProfileSessionID}
            onClose={onCloseDeleteDialog}
          />
          <div className={style.controls}>
            {showDelete && (
              <Button onClick={onClose} color={'primary'} disabled={isLoading}>
                {t('cancel')}
              </Button>
            )}
            <Button
              onClick={composeFunctions(onEdit, storeStoreSupportMeetingData)}
              color="primary"
              variant="contained"
              disabled={isLoading}
            >
              {t('edit')}
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              color="primary"
              variant="contained"
              disabled={isLoading || step !== STEP.FORM}
            >
              {t('save')}
            </Button>
          </div>
        </DialogActions>
      </>
    );
  },
);
