import React from 'react';
import { useTranslate } from 'hooks/use-translate';
import { BaseTemplatePrint } from '../base-template-print';

interface Props {
  userPatientProfilePrescriptionID: string;
  documentURL: string | null | undefined;
}
export const TemplateDownloadForPrint: React.FC<Props> = ({
  documentURL,
  userPatientProfilePrescriptionID,
}) => {
  const { tp } = useTranslate();
  if (!documentURL) return null;

  return (
    <BaseTemplatePrint
      userPatientProfilePrescriptionID={userPatientProfilePrescriptionID}
      documentURL={documentURL}
      remarks={tp('activity-patient-prescription-print-prescription')}
    />
  );
};
