import React, { useCallback, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { SmsMessageModel } from '../../models';
import { useTranslate } from 'hooks/use-translate';
import { useFieldProps } from 'hooks';
import { calcHtmlTemplate } from 'utils';
import { validateRule } from 'AurionCR/components/formV2';
import { AppInput } from 'components/app-input';
import { AppSelect } from 'components/app-select';
import { useSourceMessageTemplates, MessageTemplate } from 'hooks/use-source-message-templates';
import style from './index.module.scss';

interface Props<TP> {
  isLoading: boolean;
  templatesPayload: TP;
}

export const SendSmsMessageForm = <TP,>({ isLoading, templatesPayload }: Props<TP>) => {
  const { t } = useTranslate();

  const { errors, control, setValue } = useFormContext<SmsMessageModel>();

  const sourceTemplates = useSourceMessageTemplates();
  const getInputProps = useFieldProps({ errors, emptyHelperText: '' });

  const messageRef = useRef<HTMLInputElement>(null);
  const onSelectTemplate = useCallback(
    (_: any, value: MessageTemplate | null) => {
      const message = calcHtmlTemplate(value?.message || '', templatesPayload ?? undefined);
      setValue('message', message);
      messageRef.current?.focus();
    },
    [setValue, templatesPayload],
  );

  const isDisabledTemplate = sourceTemplates.data.length === 0 || isLoading;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={'toPhoneNumber'}
          rules={validateRule('required')}
          render={(renderProps) => (
            <AppInput
              {...getInputProps(renderProps)}
              label={t('mobile-phone')}
              disabled={isLoading}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <AppSelect
          className={style.template}
          label={t('choose-a-template')}
          options={sourceTemplates.data}
          loading={sourceTemplates.loading}
          disabled={isDisabledTemplate}
          clearOnBlur={true}
          clearOnEscape={true}
          onChange={onSelectTemplate}
          size={'small'}
          RenderInputProps={{
            variant: 'outlined',
            className: style.templateField,
            InputLabelProps: {
              className: style.templateLabel,
            },
            InputProps: {
              className: style.templateInput,
            },
          }}
        />
        <Controller
          control={control}
          name={'message'}
          rules={validateRule('required,minLength')}
          render={(renderProps) => (
            <AppInput
              {...getInputProps(renderProps)}
              className={style.message}
              InputProps={{
                className: style.messageInput,
              }}
              variant={'outlined'}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder={t('type-your-message-here')}
              disabled={isLoading}
              multiline={true}
              minRows={5}
              inputRef={messageRef}
              autoFocus
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
