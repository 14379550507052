import { DynamicService } from 'utils/service';

import {
  API_USER_EMPLOYEE_PROFILE_TODO_TASKS,
  UserEmployeeProfileTodoTaskBulkInput,
} from './models';

export * from './models';

class Service extends DynamicService<Components.Schemas.UserEmployeeProfileToDoTaskCategory> {
  createBulk = async (data: UserEmployeeProfileTodoTaskBulkInput) => {
    return this.engine.post(API_USER_EMPLOYEE_PROFILE_TODO_TASKS.INSERT_BULK, {
      mainID: data.toDoTaskID,
      itemIDs: data.userEmployeeProfileIDs,
    });
  };
}

export const ServiceUserEmployeeTodoTasks = new Service({
  mainField: 'id',
  getAll: API_USER_EMPLOYEE_PROFILE_TODO_TASKS.GET_ALL_DYNAMIC,
  post: API_USER_EMPLOYEE_PROFILE_TODO_TASKS.POST,
  delete: API_USER_EMPLOYEE_PROFILE_TODO_TASKS.DELETE,
  patch: API_USER_EMPLOYEE_PROFILE_TODO_TASKS.PATCH,
});
