import React, { memo, useCallback, useMemo } from 'react';
import DataGrid from 'components/data-grid';
import { useFormGrid } from 'AurionCR/components/formV2';
import { GridConfig } from './grid-config';
import { Editor } from './editor';
import { useCurrentUser } from 'components/hooks';

interface Props {
  clinicalMeetingID: string;
  onRefresh: () => void;
  className?: string;
}

export const ClinicalMeetingActivities = memo<Props>(
  ({ clinicalMeetingID, onRefresh, className }) => {
    const { grid, onGridHandle, editItemID, setEditItemID, onFormHandle } = useFormGrid('activity');

    const { appUserID } = useCurrentUser()

    const gridConfig = useMemo<any>(() => {
      return GridConfig({ appUserID: '', clinicalMeetingID });
    }, [clinicalMeetingID]);

    const onClose = useCallback(() => setEditItemID(null), [setEditItemID]);

    const onFormHandleProxy = useCallback<typeof onFormHandle>(
      (action) => {
        switch (action.type) {
          case 'post':
          case 'delete': {
            onRefresh();
          }
        }
        return onFormHandle(action);
      },
      [onFormHandle, onRefresh],
    );

    return (
      <>
        <DataGrid className={className} ref={grid} onHandle={onGridHandle} config={gridConfig} />
        {editItemID && (
          <Editor
            appUserID={appUserID}
            clinicalMeetingID={clinicalMeetingID}
            itemID={editItemID}
            onClose={onClose}
            onFormHandle={onFormHandleProxy}
          />
        )}
      </>
    );
  },
);

export { ClinicalMeetingActivitiesDialog } from './components'
