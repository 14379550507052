import { fromBlob } from 'file-type/browser';
import mime from 'mime-types';
import { string } from 'yup';

export enum ErrorFileCode {
  LOAD = 1,
  MAX_SIZE = 2,
  FILE_TYPE = 3,
}

class ErrorFileLoad extends Error {
  readonly code: ErrorFileCode.LOAD = ErrorFileCode.LOAD;
}
class ErrorFileSize extends Error {
  readonly code: ErrorFileCode.MAX_SIZE = ErrorFileCode.MAX_SIZE;
}
class ErrorFileType extends Error {
  readonly code: ErrorFileCode.FILE_TYPE = ErrorFileCode.FILE_TYPE;
}

export type ErrorFile = ErrorFileLoad | ErrorFileSize | ErrorFileType;

export type ValueFileUploaderFile = { value: string; name: string; size: number; type: string };
export type ValueFileUploader = string | ValueFileUploaderFile;

export const isFileLike = (file?: ValueFileUploader | null): file is ValueFileUploaderFile => {
  return Boolean(file && typeof file === 'object');
};
export const isValueFileUploader = (input: any): input is ValueFileUploader => {
  if (isFileLike(input)) {
    return true;
  }
  return string().required().url().isValidSync(input);
};

export const getFileAccept = (type?: string | string[]) => {
  const types = Array.isArray(type) ? type : String(type || '').split(',');

  const contentTypes = types.map((t) => mime.lookup(t));

  return {
    inputType: types.length ? types.join(', ') : 'application/octet-stream',
    contentTypes: contentTypes.filter(Boolean) as string[],
  };
};
export const getFileInfo = (v?: ValueFileUploader | null) => {
  const isFile = isFileLike(v);
  const value = isFileLike(v) ? v.value : v || '';
  const fileName = isFileLike(v) ? v.name : getFileNameFromUrl(v || '');
  const fileUrl = value || '';
  const ext = getFileNameExt(fileName);

  return {
    isFile,
    fileUrl,
    fileName,
    value,
    ext,
  };
};

export const fileToBase64 = (file: Blob) => {
  return new Promise<string>((res, rej) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      if (!e.target) {
        return rej(new ErrorFileLoad('error-file-type'));
      }
      return res(String(e.target.result));
    };
    fileReader.onerror = rej;
    fileReader.readAsDataURL(file);
  });
};
export const base64ToFileStream = (base64: string) => {
  return base64.split(',')[1];
};
export const bufferToBlobPdf = (buffer: Buffer) => {
  return new Blob([buffer], { type: 'application/pdf' });
};
export const cropImg = (file: File | Blob, width: number, height: number) => {
  return new Promise<string>((resolve, reject) => {
    const img_ = new Image();
    img_.src = URL.createObjectURL(file);
    img_.onload = () => {
      const imageWidth = img_.width;
      const imageHeight = img_.height;

      let ratio = 1;

      if (imageWidth > width) ratio = width / imageWidth;
      if (imageHeight > height) ratio = height / imageHeight;

      const canvas = document.createElement('canvas');
      const width_ = imageWidth * ratio;
      const height_ = imageHeight * ratio;
      canvas.width = width_;
      canvas.height = height_;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(img_, 0, 0, imageWidth, imageHeight, 0, 0, width_, height_);
      resolve(canvas.toDataURL());
    };
    img_.onerror = reject;
  });
};

export const getBase64FileSize = (base64: string) => {
  const length = base64.length;
  const endBytes = base64.endsWith('==') ? 2 : 1;
  return length * (3 / 4) - endBytes;
};

export const getFileNameWithoutExt = (filePath: string) => {
  // Extract the file name from the path
  const fileName = filePath.split('/').pop() || '';

  // Check if the file name contains a dot
  if (fileName.includes('.')) {
    // Remove the file extension
    return fileName.split('.').slice(0, -1).join('.');
  }

  // Return the file name as is if it doesn't contain an extension
  return fileName;
};
export const getFileNameExt = (fileName: string) => {
  return fileName.split('.').reverse()[0];
};
export const getFileNameFromUrl = (fileName: string) => {
  //eslint-disable-next-line
  return fileName.replace(/^.*[\\\/]/, '');
};

export const validateFileSize = async (file: File, maxSize: number) => {
  const isValid = file.size < maxSize * 1000000;
  if (!isValid) {
    throw new ErrorFileSize('error-file-max-size');
  }
  return isValid;
};

interface ValidateFileTypeOptions {
  file: Blob;
  contentTypes: string[];
  skipContentTypes: string[];
}
export const validateFileType = async (input: ValidateFileTypeOptions) => {
  const { file, contentTypes, skipContentTypes } = input;
  const { type } = file;
  const validType = contentTypes.length && contentTypes.some((t) => t === type);

  if (!validType) {
    throw new ErrorFileType('error-file-type');
  }

  if (skipContentTypes.some((skipType) => skipType === type)) {
    return true;
  }

  const result = await fromBlob(file);

  if (!result) {
    throw new ErrorFileType('error-file-type');
  }

  const validTypeBlob = contentTypes.length && contentTypes.some((type) => result.mime === type);

  if (!validTypeBlob) {
    throw new ErrorFileType('error-file-type');
  }

  return true;
};

export const mimeToExtension = (mimeType: string) => {
  return mime.extension(mimeType);
};

export const downloadBase64 = (base64: string, fileName = 'example') => {
  const downloadLink = document.createElement('a');
  downloadLink.href = base64;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const base64ToUint8Array = (base64: string) => {
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  return base64ToUint8Array(base64);
};

export const blobToBase64 = (blob: Blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise<string>((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
  });
};
