import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Divider, Grid } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { Controller, useController, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslate } from 'hooks/use-translate';
import { useEffectError, useFieldProps } from 'hooks';
import { AppSelect } from 'components/app-select';
import { AppDatePicker } from 'components/app-date-picker';
import {
  apiUserPatientProfileSubscriptions,
  UserPatientSubscriptionsForLetterForMedInsurance,
} from 'services/user-patient-profile-subscriptions';
import { Stack } from 'components/stack';
import { convertToDate, dateFormat, maxDate, minDate } from 'utils/dates';
import { composeFunctions } from 'utils';
import { apiUserPatientPrescriptions } from 'services/user-patient-profile-prescriptions';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { AppSwitch } from 'components/app-switch';
import { isWithinInterval } from 'date-fns';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { apiPdf } from 'services/pdf';
import { useAppSelector } from 'store';
import { selectLanguageID } from 'store/languages';
import { isMutationFulfilled } from 'utils/rtk-query';
import { AppPrescriptionDrugsAreaInput } from 'components/app-prescription-drugs-area-input';
import { AppChipAreaInput } from 'components/app-chip-area-input';
import style from './index.module.scss';
import {
  subscriptionGetLabel,
  subscriptionRenderOption,
} from 'components/select-options/subscription';
import { BaseCreateComponentProps } from '../../models';
import { apiPdfContent } from 'services/pdf-content';
import { DialogEditHtmlContent } from 'components/dialog-edit-html-content';
import { useSourceBmiSummaries, useSourceDiagnosisTypes } from '../../../../../hooks';
import { useUpdatePatientDataFromContent } from '../../../../hooks/use-update-patient-data-from-content';

type SubscriptionOption = UserPatientSubscriptionsForLetterForMedInsurance;

const schema = yup.object({
  userPatientProfileSubscriptionID: yup.string().nullable().required('rule-required').default(null),
  date: yup.string().required('rule-required').default(''),
  drugs: yup
    .array()
    .of(
      yup.object({
        id: yup.string(),
        title: yup.string(),
      }),
    )
    .default([]),

  diagnosisTypeID: yup.string().required('rule-required').default(null),
  bmiSummaryID: yup.string().required('rule-required').default(null),
});
const defaultValues = schema.cast({}, { assert: false });
type FormModel = typeof defaultValues;

type ApplyState = { title: string; content: string };

const useFetchSubscriptionsQuery =
  apiUserPatientProfileSubscriptions.useGetPatientSubscriptionsForLetterForMedInsuranceQuery;

const useFetchPrescriptionsQuery =
  apiUserPatientPrescriptions.useGetMedicalPrescriptionsWithDrugsQuery;

const useFetchPatientDetailsLazyQuery =
  apiUserPatientProfile.useLazyGetPatientDetailsForPdfLetterForMedInsuranceQuery;

const useGenerateContentMutation = apiPdfContent.useGenerateContentLatterToMedInsuranceMutation;

interface Props {
  userPatientProfileID: string;
  onApply: (state: ApplyState) => void;
  onClose: () => void;
}
const DialogCreateContent: React.FC<Props> = ({ userPatientProfileID, onApply, onClose }) => {
  const [updateUser, resultUpdateUser, patient] = useUpdatePatientDataFromContent({
    userPatientProfileID,
  });
  useEffectError(resultUpdateUser.error);

  const languageID = useAppSelector(selectLanguageID);

  const [triggerFetchDetails, resultDetails] = useFetchPatientDetailsLazyQuery();
  useEffectError(resultDetails.error);

  const { tp, t } = useTranslate();
  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { handleSubmit, errors, control, setValue, watch } = methods;
  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

  const refInit = useRef(false);
  useEffect(() => {
    if (refInit.current) return;
    if (!patient) return;

    // issue with the set form
    requestAnimationFrame(() => {
      setValue('bmiSummaryID', patient.bmiSummaryID);
      setValue('diagnosisTypeID', patient.diagnosisTypeID);
    });

    refInit.current = true;
  }, [patient, setValue]);

  const [subscriptionOnly, setSubscriptionOnly] = useState(false);
  const onChangeSubscriptionOnly = useCallback(
    (_: any, checked: boolean) => {
      setSubscriptionOnly(checked);
      setValue('drugs', []);
    },
    [setValue],
  );

  const sourceSubscriptions = useFetchSubscriptionsQuery(userPatientProfileID);
  useEffectError(sourceSubscriptions.error);

  const [generateContent, resultContent] = useGenerateContentMutation();
  useEffectError(resultContent.error);

  const userPatientProfileSubscriptionID = watch('userPatientProfileSubscriptionID');

  const subscription = useMemo(() => {
    return (sourceSubscriptions.data || []).find(
      (item) => item.id === userPatientProfileSubscriptionID,
    );
  }, [sourceSubscriptions.data, userPatientProfileSubscriptionID]);

  const sourcePrescriptions = useFetchPrescriptionsQuery(userPatientProfileID);
  useEffectError(sourcePrescriptions.error);

  const sourceDiagnosisTypes = useSourceDiagnosisTypes();
  const sourceBmiSummaries = useSourceBmiSummaries();

  const prescriptionOptions = useMemo(() => {
    return (sourcePrescriptions.data || []).map((prescription) => {
      return {
        id: String(prescription.id),
        title: dateFormat(prescription.entryDate, APP_FORMAT_DATE_TIME),
        isCompleted: !!prescription.isCompleted,
        entryDate: convertToDate(prescription.entryDate),
        items: prescription.drugs.map((drug) => {
          return { id: String(drug.id), title: String(drug.drugNameEng) };
        }),
      };
    });
  }, [sourcePrescriptions.data]);
  const prescriptionOptionsFiltered = useMemo(() => {
    if (!subscription) {
      return prescriptionOptions;
    }
    if (!subscriptionOnly) {
      return prescriptionOptions;
    }

    const startDate = minDate(subscription.startDate, subscription.endDate);
    const endDate = maxDate(subscription.startDate, subscription.endDate);
    return prescriptionOptions.filter((prescription) =>
      isWithinInterval(prescription.entryDate, {
        start: startDate,
        end: endDate,
      }),
    );
  }, [prescriptionOptions, subscription, subscriptionOnly]);

  const onSelectSubscription = useCallback(
    (_: any, option: SubscriptionOption | null) => {
      if (!option) return;
      setValue('date', option.startDate);
    },
    [setValue],
  );

  const isLoading =
    resultContent.isLoading ||
    resultDetails.isLoading ||
    sourceSubscriptions.isFetching ||
    resultUpdateUser.isLoading ||
    sourcePrescriptions.isLoading;

  const controlDrugs = useController({ control, name: 'drugs' });

  const _onSubmit = useCallback(
    async (data: FormModel) => {
      const { userPatientProfileSubscriptionID, drugs, date } = data;

      const resUpdate = await updateUser({
        diagnosisTypeID: data.diagnosisTypeID,
        bmiSummaryID: data.bmiSummaryID,
      });

      if (!isMutationFulfilled(resUpdate)) return;

      const resultDetails = await triggerFetchDetails({
        userPatientProfileSubscriptionID,
        userPatientProfileID,
      });

      if (!resultDetails.data) {
        return;
      }

      const result = await generateContent({
        languageID,
        payload: {
          patient: resultDetails.data,
          drugs: drugs.map(({ title }) => ({ drugName: String(title) || 'unknown-drug' })),
          startDate: date,
          subscription: {
            visitDietitian: resultDetails.data.subscription?.visitDietitian,
            listPrice: resultDetails.data.subscription?.listPrice,
            title: resultDetails.data.subscription?.labelKey,
            price: resultDetails.data.subscription?.price,
            durationMonths: resultDetails.data.subscription?.durationMonths,
          },
        },
      });

      if (isMutationFulfilled(result)) {
        onApply({ content: result.data.content, title: result.data.title });
      }
    },
    [triggerFetchDetails, userPatientProfileID, languageID, generateContent, onApply, updateUser],
  );

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth={'lg'}>
      <DialogHeading
        title={tp('create-letter-to-med-insurance')}
        isLoading={isLoading}
        onClose={onClose}
      />
      <DialogContent dividers className={style.dialogContent}>
        <Grid container spacing={2}>
          <Grid md={7} item>
            <Controller
              control={control}
              name={'userPatientProfileSubscriptionID'}
              render={(renderProps) => {
                const props = getFieldProps(renderProps);
                return (
                  <AppSelect
                    {...props}
                    loading={sourceSubscriptions.isFetching}
                    options={sourceSubscriptions.data || []}
                    onChange={composeFunctions(props.onChange, onSelectSubscription)}
                    renderOption={subscriptionRenderOption}
                    getOptionLabel={subscriptionGetLabel}
                    disabled={isLoading}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name={'diagnosisTypeID'}
              render={(renderProps) => {
                const props = getFieldProps(renderProps);
                return (
                  <AppSelect
                    {...props}
                    options={sourceDiagnosisTypes.data}
                    loading={sourceDiagnosisTypes.loading}
                    disabled={isLoading || !!patient?.diagnosisTypeID}
                    disableClearable
                  />
                );
              }}
            />
          </Grid>
          <Grid md={5} item>
            <Controller
              control={control}
              name={'date'}
              render={(renderProps) => {
                return (
                  <AppDatePicker
                    {...getFieldProps(renderProps)}
                    label={t('subscription-start-date')}
                    disabled={isLoading}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name={'bmiSummaryID'}
              render={(renderProps) => {
                const props = getFieldProps(renderProps);
                return (
                  <AppSelect
                    {...props}
                    options={sourceBmiSummaries.data}
                    loading={sourceBmiSummaries.loading}
                    disabled={isLoading || !!patient?.bmiSummaryID}
                    disableClearable
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Divider className={style.divider} />
        <Stack alignItems={'end'} justifyContent={'end'}>
          <AppSwitch
            value={subscriptionOnly}
            label={t('prescriptions-relative-to-subscription')}
            onChange={onChangeSubscriptionOnly}
            disabled={!userPatientProfileSubscriptionID || isLoading}
          />
        </Stack>
        <Grid container spacing={2}>
          <Grid md={7} item>
            <AppPrescriptionDrugsAreaInput
              {...getFieldProps(controlDrugs.field)}
              label={t('prescriptions')}
              options={prescriptionOptionsFiltered}
              disabled={isLoading}
            />
          </Grid>
          <Grid md={5} item>
            <AppChipAreaInput {...getFieldProps(controlDrugs.field)} disabled={isLoading} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color={'primary'} onClick={onClose}>
          {tp('cancel')}
        </Button>
        <Button
          color={'primary'}
          variant={'contained'}
          disabled={isLoading}
          onClick={handleSubmit(_onSubmit)}
        >
          {tp('apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useGenerateDocumentMutation = apiPdf.useGenerateDocumentLatterToMedInsuranceMutation;

export const DialogCreateDocLetterToMedInsurance: React.FC<BaseCreateComponentProps> = ({
  userPatientProfileID,
  onDone,
  onClose,
}) => {
  const [state, setState] = useState<ApplyState>();

  const [triggerGenerate, resultGenerate] = useGenerateDocumentMutation();
  useEffectError(resultGenerate.error);

  const isLoading = resultGenerate.isLoading;

  const onGenerate = useCallback(
    async (content: string) => {
      if (!state) return;
      const res = await triggerGenerate({ content, title: state.title, userPatientProfileID });

      if (isMutationFulfilled(res)) {
        onDone();
      }
    },
    [triggerGenerate, onDone, userPatientProfileID, state],
  );

  if (!state) {
    return (
      <DialogCreateContent
        userPatientProfileID={userPatientProfileID}
        onApply={setState}
        onClose={onClose}
      />
    );
  }

  return (
    <DialogEditHtmlContent
      isLoading={isLoading}
      title={state.title}
      content={state.content}
      onGenerate={onGenerate}
      onCancel={onClose}
    />
  );
};
