import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AppWeightInput } from 'components/app-weight-input';
import { ClickAwayListener } from '@material-ui/core';
import { validateRule } from 'AurionCR/components/formV2';
import Footer from 'components/data-grid/components/column/editors/footer';
import { IDataGridColumnEditorNumber } from 'components/data-grid/model';
import { useFieldProps } from 'hooks';

interface Props {
  editor: IDataGridColumnEditorNumber;
  data: string;
  onClose: any;
  onSubmit: any;
}

export const GridEditorInputWeight: React.FC<Props> = ({ data, onSubmit, onClose, editor }) => {
  const { handleSubmit, errors, control } = useForm({ defaultValues: { data: String(data || 0) } });
  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className="grid-editor string">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="data"
            control={control}
            rules={editor.required ? validateRule('required') : {}}
            render={(renderProps) => (
              <AppWeightInput {...getFieldProps(renderProps)} label={null} />
            )}
          />
          <Footer onClose={onClose} />
        </form>
      </div>
    </ClickAwayListener>
  );
};
