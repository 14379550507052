import React, { memo, useCallback, useMemo } from 'react';
import { requestError, useI18n } from 'AurionCR/components';
import { useAppDispatch } from 'store';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { useCurrentUser } from 'components/hooks';
import { API_SUPPORT_MEETINGS, SupportMeeting } from 'services/support-meetings';
import { API_SUPPORT_MEETING_ACTIVITIES } from 'services/support-meeting-activities';
import {
  DialogMeetingChanges,
  MeetingChangeModel,
  MeetingChangeReason,
} from 'components/calendar-view';
import { api } from 'utils/service';

export type ApproveStatus = 'start' | 'finish' | 'error';

interface MeetingData extends Pick<Required<SupportMeeting>, 'id'> {}

export interface Props {
  meetingData: MeetingData;
  remarks: string;
  onDecline: () => void;
  onApprove: (status: ApproveStatus) => void;
  reason: MeetingChangeReason;
}

export const DialogConfirmChange = memo<Props>(
  ({ meetingData, reason, remarks, onApprove, onDecline }) => {
    const { t } = useI18n();
    const dispatch = useAppDispatch();
    const { appUserID } = useCurrentUser();

    const onSubmit = useCallback(
      async (formData: MeetingChangeModel) => {
        onApprove('start');
        const { id, ...rest } = meetingData;
        try {
          await Promise.all([
            api.patch(API_SUPPORT_MEETINGS.PATCH(meetingData), rest),
            api.post(API_SUPPORT_MEETING_ACTIVITIES.POST, {
              supportMeetingID: id,
              entryDate: new Date().toISOString(),
              userEmployeeProfileID: appUserID,
              ...formData,
            }),
          ]);
          onApprove('finish');
        } catch (e) {
          dispatch(notifyRequestResult(requestError(e), 'error'));
          onApprove('error');
        }
      },
      [dispatch, onApprove, meetingData, appUserID],
    );

    const initialData = useMemo(() => {
      return { remarks };
    }, [remarks]);

    return (
      <DialogMeetingChanges
        reason={reason}
        initialData={initialData}
        title={t('approve-change')}
        onSubmit={onSubmit}
        onClose={onDecline}
        includeSms={false}
      />
    );
  },
);
