import React, { useCallback } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { apiUserPatientPrescriptions } from 'services/user-patient-profile-prescriptions';
import { useEffectError } from 'hooks';
import { useTranslate } from 'hooks/use-translate';
import PrintIcon from '@material-ui/icons/Print';
import { DialogConfirm } from '../../../dialog-confirm';
import { composeFunctions } from '../../../../utils';
import { useOpen } from '../../../../AurionCR/components/hooks';

const usePrintMutation = apiUserPatientPrescriptions.usePrintPatientPrescriptionMutation;

interface Options {
  prescriptionID: string;
  prescriptionPrintFilePath: string | null | undefined;
}

interface Props extends ButtonProps, Options {}
export const ButtonPrescriptionForPrint: React.FC<Props> = ({
  prescriptionID,
  prescriptionPrintFilePath,
  ...rest
}) => {
  const { tp } = useTranslate();
  const [print, resultPrint] = usePrintMutation();
  useEffectError(resultPrint.error);

  const onDownload = useCallback(async () => {
    if (!prescriptionPrintFilePath) {
      return;
    }
    await print({
      userPatientProfilePrescriptionID: prescriptionID,
      url: prescriptionPrintFilePath,
      remarks: tp('activity-patient-prescription-print-prescription'),
    });
  }, [prescriptionID, print, prescriptionPrintFilePath, tp]);

  const confirmState = useOpen();

  return (
    <>
      <Button
        startIcon={<PrintIcon />}
        color={'primary'}
        variant={'outlined'}
        {...rest}
        onClick={confirmState.handleOpen}
        disabled={rest.disabled || resultPrint.isLoading || !prescriptionPrintFilePath}
      >
        {tp('prescription-for-print')}
      </Button>
      {confirmState.isOpen && (
        <DialogConfirm
          title={tp('confirm-print')}
          message={tp('are-you-sure-print-prescription')}
          onCancel={confirmState.handleClose}
          onConfirm={composeFunctions(confirmState.handleClose, onDownload)}
        />
      )}
    </>
  );
};
