import React, { useCallback } from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import * as yup from 'yup';
import { isDateLike } from 'utils/dates';
import { addDays } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffectError, useFieldProps } from 'hooks';
import { AppDatePicker } from 'components/app-date-picker';
import { ButtonCancel, ButtonSave } from 'components/app-buttons';
import { useTranslate } from 'hooks/use-translate';
import { InferType } from 'yup';
import { apiUserPatientPrescriptions } from 'services/user-patient-profile-prescriptions';
import { transformToDateISO } from 'utils/transformers';
import { isMutationFulfilled } from 'utils/rtk-query';
import { AppInput } from 'components/app-input';

const useHoldMutation = apiUserPatientPrescriptions.useHoldMedicalPrescriptionMutation;

const schema = yup.object({
  onHoldDate: yup
    .string()
    .required('rule-required')
    .test('date', 'rule-date', isDateLike)
    .transform(transformToDateISO)
    .default(() => {
      return addDays(new Date(), 7).toISOString();
    }),
  remark: yup.string().required('rule-required').trim().default(''),
});

type FormModel = InferType<typeof schema>;

interface Props {
  id: string;
  onClose: () => void;
  onDone: () => void;
}
export const DialogOnHoldMedicalPrescription: React.FC<Props> = ({ id, onClose, onDone }) => {
  const { tp } = useTranslate();
  const [patchItem, resultPatch] = useHoldMutation();
  useEffectError(resultPatch.error);

  const { control, errors, handleSubmit } = useForm({
    defaultValues: schema.cast({}),
    resolver: yupResolver(schema),
  });
  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

  const onSubmit = useCallback(
    async (formData: FormModel) => {
      const { onHoldDate, remark } = formData;
      const res = await patchItem({ id, onHoldDate, remark });

      if (!isMutationFulfilled(res)) return;

      onDone();
    },
    [id, patchItem, onDone],
  );

  const isLoading = resultPatch.isLoading;

  return (
    <Dialog open={true} fullWidth maxWidth={'xs'}>
      <DialogHeading isLoading={isLoading} title={tp('on-hold')} onClose={onClose} />
      <DialogContent>
        <Controller
          control={control}
          name={'onHoldDate'}
          render={(renderProps) => {
            return <AppDatePicker {...getFieldProps(renderProps)} disabled={isLoading} />;
          }}
        />
        <Controller
          control={control}
          name={'remark'}
          render={(renderProps) => {
            return (
              <AppInput
                {...getFieldProps(renderProps)}
                disabled={isLoading}
                minRows={3}
                multiline
              />
            );
          }}
        />
      </DialogContent>
      <DialogActions>
        <ButtonCancel isBack={false} onClick={onClose} />
        <ButtonSave disabled={isLoading} onClick={handleSubmit(onSubmit)} isCreate={true}>
          {tp('submit')}
        </ButtonSave>
      </DialogActions>
    </Dialog>
  );
};
