import { fieldToLabelKey, toKebab } from 'utils/other';
import { CSSProperties } from 'react';

const toStyleString = (props?: Partial<CSSProperties>) => {
  return Object.entries(props || {})
    .map(([key, val]) => `${toKebab(key)}: ${val}`)
    .join(';');
};
const setAttribute = (element: HTMLElement, name: string, value: string | undefined) => {
  if (value) {
    element.setAttribute(name, value);
  }
};

interface Styles {
  table: Partial<CSSProperties>;
  trHead: Partial<CSSProperties>;
  th: Partial<CSSProperties>;
  tr: Partial<CSSProperties>;
  td: Partial<CSSProperties>;
}

interface CreateTableStringProps<Row> {
  rows: Row[];
  translate: (key: string) => string;
  isRenderHeader?: boolean;
  styles?: Partial<Styles>;
}

export const createTableString = <Row extends Record<string, any>>(
  props: CreateTableStringProps<Row>,
) => {
  const { rows, translate, styles, isRenderHeader = true } = props;

  if (!rows.length) {
    return '';
  }

  const columnKeys = Object.keys(rows[0]);

  const container = document.createElement('div');
  const table = document.createElement('table');

  setAttribute(table, 'style', toStyleString(styles?.table));

  if (isRenderHeader) {
    const tr = document.createElement('tr');

    setAttribute(tr, 'style', toStyleString(styles?.trHead));

    columnKeys.forEach((columnKey) => {
      const th = document.createElement('th');

      setAttribute(th, 'style', toStyleString(styles?.th));

      th.innerHTML = translate(fieldToLabelKey(columnKey));

      tr.appendChild(th);
    });

    const tHead = document.createElement('thead');

    tHead.appendChild(tr);
    table.appendChild(tHead);
  }

  rows.forEach((row) => {
    const tr = document.createElement('tr');
    setAttribute(tr, 'style', toStyleString(styles?.tr));
    columnKeys.forEach((columnKey) => {
      const td = document.createElement('td');
      setAttribute(td, 'style', toStyleString(styles?.td));
      td.innerHTML = row[columnKey] || '';
      tr.appendChild(td);
    });
    table.appendChild(tr);
  });

  container.appendChild(table);
  return container.innerHTML;
};
