import React from 'react';
import { TemplateMeetingDate } from 'components/templates/template-meeting-date';
import { TemplateEmployee } from 'components/templates/template-employee';
import { API_SUPPORT_MEETINGS, SupportMeeting } from 'services/support-meetings';
import { APP_FORMAT_DATE, APP_FORMAT_DATE_TIME } from 'configs/const';
import SessionPreview from 'pages/TaskManager/components/particals/notebook-prescription/session-preview';
import { Notebook } from 'services/notebook';
import style from './index.module.scss';
import { Box } from '@material-ui/core';
import { permissionKey } from 'services/user-employee-profile-permissions';
import { calcFilterSwitcher, PATIENT_MEETING_SWITCH } from '../../helpers';
import { ColumnSupportMeetingActivities } from '../column-support-meeting-activities';
import { ColumnSupportMeetingType } from '../column-support-meeting-type';
import { format } from 'date-fns';
import { convertToDate } from 'utils/dates';
import { SupportControlClose } from '../support-control-close';

interface GridData
  extends Pick<
    Required<SupportMeeting>,
    | 'supportMeetingTypeID'
    | 'meetingFromDateTime'
    | 'meetingToDateTime'
    | 'userEmployeeProfileID'
    | 'userPatientProfileSessionID'
    | 'id'
    | 'isActive'
  > {
  employee: Pick<Required<SupportMeeting>['userEmployeeProfile'], 'firstName' | 'lastName'>;
  userPatientProfileSessionNotebookLabelKey: Notebook['labelKey'];
  activitiesCount: number;
  isCanceled: boolean;
}

interface Options {
  userPatientProfileID: string;
  period: PATIENT_MEETING_SWITCH;
  onEdit: (itemID: string) => void;
}
export const GridConfigSupport = ({ userPatientProfileID, period, onEdit }: Options) => ({
  store: {
    get: {
      url: API_SUPPORT_MEETINGS.GET_ALL_DYNAMIC,
      selected: [
        'isActive',
        'meetingToDateTime',
        'userPatientProfileSessionID',
        'userPatientProfileID',
        'supportMeetingType.isCanceledMeeting as isCanceled',
        `new { userEmployeeProfile.firstName, userEmployeeProfile.lastName } as employee`,
        'supportMeetingActivities.Count() as activitiesCount',
      ].join(','),
      filters: [`(userPatientProfileID=="${userPatientProfileID}")`, calcFilterSwitcher(period)]
        .filter(Boolean)
        .join('&&'),
    },
    patch: {
      url: API_SUPPORT_MEETINGS.PATCH,
    },
    delete: {
      url: API_SUPPORT_MEETINGS.DELETE,
      title: (data: GridData) =>
        data.meetingFromDateTime
          ? format(convertToDate(data.meetingFromDateTime), APP_FORMAT_DATE_TIME)
          : '',
    },
    sortable: {
      field: 'meetingFromDateTime',
      order: 'desc',
    },
  },
  controlsDropDown: [
    {
      template: SupportControlClose,
      type: 'custom',
      permission: permissionKey('isAllowToCancelMeeting'),
    },
    {
      type: 'delete',
      title: 'delete',
      permission: permissionKey('isAllowToDeleteAnything'),
    },
  ],
  toolbar: {
    title: '',
    controls: null,
    controlsDefault: null,
  },
  columns: [
    {
      title: '',
      field: 'supportMeetingTypeID',
      sortable: 'supportMeetingType.title',
      template: (data: GridData) => (
        <ColumnSupportMeetingType meetingTypeID={data.supportMeetingTypeID} />
      ),
      tdClasses: 'data-grid-td-width-50',
    },
    {
      title: 'date-time',
      field: 'meetingFromDateTime',
      template: (data: GridData) => (
        <Box display={'flex'} height={'100%'}>
          <TemplateMeetingDate
            meeting={data}
            dateFormat={APP_FORMAT_DATE}
            onClick={() => {
              onEdit(data.id);
            }}
            className={style.activeColumn}
          />
          <Box alignSelf={'center'}>
            <ColumnSupportMeetingActivities meetingID={data.id} count={data.activitiesCount} />
          </Box>
        </Box>
      ),
    },
    {
      title: 'employee',
      field: 'userEmployeeProfileID',
      sortable: 'userEmployeeProfile.firstName',
      template: (data: GridData) => <TemplateEmployee employee={data.employee} />,
    },
    ...(period === PATIENT_MEETING_SWITCH.PREVIOUS
      ? [
          {
            title: 'notebook',
            field:
              'userPatientProfileSession.notebook.labelKey as userPatientProfileSessionNotebookLabelKey',
            sortable: 'userPatientProfileSession.notebook.labelKey',
            template: (data: GridData) =>
              data.userPatientProfileSessionNotebookLabelKey ? (
                <SessionPreview data={data} isGridItem disabled={data.isActive === false} />
              ) : null,
          },
        ]
      : []),
  ],
});
