import React, { useMemo } from 'react';
import { useOpen } from 'AurionCR/components/hooks';
import style from './index.module.scss';
import { useAppSelector } from 'store';
import { selectEmployeesMonitorEmployeesList } from 'store/employees-monitor';
import { orderBy } from 'lodash-es';
import { Stack } from 'components/stack';
import { EmployeeMonitorItemShort } from '../employee-monitor-item-short';
import { Avatar, Button, ClickAwayListener } from '@material-ui/core';
import clsx from 'clsx';
import { EmployeeActivitiesMonitor } from '../employee-activities-monitor';
import CloseIcon from '@material-ui/icons/Close';

const defaultClick = () => {};

export const EmployeeMonitorDrawer: React.FC = () => {
  const { isOpen, handleClose, handleToggle } = useOpen();
  const employeeList = useAppSelector(selectEmployeesMonitorEmployeesList);

  const items = useMemo(() => {
    return orderBy(employeeList, ['lastActivityDate'], 'desc').filter(
      (employee) => !employee.isMustBeActive,
    );
  }, [employeeList]);

  const left = useMemo(() => {
    return Math.max(0, employeeList.length - items.length);
  }, [employeeList.length, items.length]);

  const shouldShowEmpty = left > 0 || items.length === 0;
  return (
    <ClickAwayListener onClickAway={isOpen ? handleClose : defaultClick}>
      <div className={clsx(style.root, { [style.open]: isOpen })}>
        <Stack
          direction={'column'}
          spacing={1}
          p={1}
          className={style.preview}
          onClick={handleToggle}
        >
          {items.map((employee) => {
            return (
              <div key={employee.id}>
                <EmployeeMonitorItemShort
                  userEmployeeProfileID={String(employee.appIdentityUserID)}
                  firstName={employee.firstName}
                  lastName={employee.lastName}
                  isActivityLow={employee.isMustBeActive}
                  userPhoto={employee.userPhoto}
                />
              </div>
            );
          })}
          {shouldShowEmpty && <Avatar className={style.more}>+{left}</Avatar>}
        </Stack>

        <div className={style.wrap}>
          <Button
            color={'secondary'}
            variant={'outlined'}
            className={style.close}
            onClick={handleToggle}
          >
            <CloseIcon />
          </Button>
          <EmployeeActivitiesMonitor />
        </div>
      </div>
    </ClickAwayListener>
  );
};
