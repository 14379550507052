import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { TypedTranslate } from 'dictionary';
import { calcHtmlTemplate } from 'utils';

export class AppTranslator<T extends { t: (key: string) => string }> {
  private translate: T;
  constructor(translate: T) {
    this.translate = translate;
  }

  t = (key: string) => {
    return this.translate.t(key);
  };

  tp: TypedTranslate = (...params) => {
    return params[1]
      ? calcHtmlTemplate(this.translate.t(params[0]), params[1])
      : this.translate.t(params[0]);
  };
}

i18n.use(initReactI18next).init({
  debug: false,
  saveMissing: true,
  resources: {
    en: {
      translation: {
        // English translations
      },
    },
    // Add more languages as needed
  },
  interpolation: {
    escapeValue: false, // React already escapes values by default
  },
});

i18n.on('missingKey', (lng, namespace, key, fallbackValue) => {
  console.groupCollapsed(`Label: \x1B[31m"${key}"`, 'non found please add it to crm ');
  console.log(' key: ' + key);
  console.groupEnd();
});

export const i18nAppTranslator = new AppTranslator(i18n);

export default i18n;
