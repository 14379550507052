import { DefaultGridButtonExportToExcel } from 'components/data-grid/model';
import { APP_FORMAT_DATE } from 'configs/const';
import { API_NOTEBOOKS_HELPERS } from 'services/notebook-helpers';

export default (userPatientProfileID: string): any => ({
  store: {
    get: {
      url: API_NOTEBOOKS_HELPERS.GET_ALL_DYNAMIC(userPatientProfileID),
    },
    sortable: {
      field: 'entryDate',
      order: 'desc',
    },
  },
  toolbar: {
    title: '',
    controls: null,
    controlsDefault: {
      new: null,
      resetFilters: null,
      export: DefaultGridButtonExportToExcel,
    },
  },
  columns: [
    {
      title: 'entry-date',
      field: 'entryDate',
      template: {
        type: 'date',
        format: APP_FORMAT_DATE,
      },
      width: '15%',
    },
    {
      title: 'notebook',
      field: 'notebookTitle',
    },
    {
      title: 'employee-name',
      field: 'employeeName',
      sortable: 'employeeName',
    },
    {
      title: 'title',
      field: 'question',
      width: '25%',
    },
    {
      title: 'description',
      field: 'answer',
      width: '35%',
    },
  ],
});
