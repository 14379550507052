import React, { memo, useCallback, useEffect, useState } from 'react';
import GridConfig from './grid-config';
import DataGrid from 'components/data-grid';
import { ButtonAddNew } from './components';
import { useFormGrid } from 'AurionCR/components/formV2';
import { patientEmitter } from '../../emitter';
import { Box } from '@material-ui/core';
import { composeFunctions } from 'utils';
import { CustomHandler, CustomHandlers, GridModel } from './models';
import { DialogSendFilesToPatient } from './components/dialog-send-to-patient';
import { useOpenValue } from 'hooks';
import { DialogSendFilesToPharmacy } from './components/dialog-send-to-pharmacy';

interface Props {
  userPatientProfileID: string;
}
export const GridDocuments = memo<Props>(({ userPatientProfileID }) => {
  const [gridConfig] = useState<any>(GridConfig(userPatientProfileID));

  const { grid, onGridHandle, editItemID } = useFormGrid('document');

  const onRefresh = useCallback(() => {
    grid.current?.onTasks([['getData']]);
  }, [grid]);

  useEffect(() => {
    const refresh = (payload: { patientID: string }) => {
      if (payload.patientID !== userPatientProfileID) {
        return;
      }
      onRefresh();
    };

    patientEmitter.on('refresh-all', refresh);
    patientEmitter.on('refresh-documents', refresh);
    return () => {
      patientEmitter.off('refresh-all', refresh);
      patientEmitter.off('refresh-documents', refresh);
    };
  }, [userPatientProfileID, onRefresh]);

  const openSendToPatient = useOpenValue<GridModel>();
  const openSendToPharmacy = useOpenValue<GridModel>();
  const onCustomHandle = useCallback(
    (action: CustomHandlers) => {
      if (action.type === CustomHandler.SEND_TO_PATIENT) {
        openSendToPatient.onOpen(action.data);
      }
      if (action.type === CustomHandler.SEND_TO_PHARMACY) {
        openSendToPharmacy.onOpen(action.data);
      }
    },
    [openSendToPatient, openSendToPharmacy],
  );

  return (
    <>
      <DataGrid
        ref={grid}
        onHandle={composeFunctions(onGridHandle, onCustomHandle)}
        config={gridConfig}
        visible={!editItemID || editItemID === true}
        renderControlsSlot={() => {
          return (
            <Box px={1}>
              <ButtonAddNew userPatientProfileID={userPatientProfileID} onRefresh={onRefresh} />
            </Box>
          );
        }}
      />
      {openSendToPatient.openValue && (
        <DialogSendFilesToPatient
          userPatientProfileID={userPatientProfileID}
          onClose={openSendToPatient.onClose}
          fileURLs={[openSendToPatient.openValue.documentURL]}
        />
      )}
      {openSendToPharmacy.openValue && (
        <DialogSendFilesToPharmacy
          userPatientProfileID={userPatientProfileID}
          onClose={openSendToPharmacy.onClose}
          fileURLs={[openSendToPharmacy.openValue.documentURL]}
        />
      )}
    </>
  );
});
