import React, { useMemo } from 'react';
import { Badge } from '@material-ui/core';
import { useCurrentUser } from 'components/hooks';
import { useSource } from 'AurionCR/components';
import { generateDynamicQuery } from 'utils/service';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { API_TODO_TASKS } from 'services/todo-tasks';
import { ActionKey } from 'services/todo-task-categories';

const useSourceRenewal = () => {
  const { appUserID } = useCurrentUser();
  const queryParams = useMemo(() => {
    return generateDynamicQuery({
      filter: [
        'done==false',
        `toDoTaskCategory.actionKey==${ActionKey.Prescription}`,
        `userEmployeeProfileToDoTasks.any(k => k.userEmployeeProfileID=="${appUserID}")`,
      ].join('&&'),
      select: ['id'].join(','),
    });
  }, [appUserID]);
  return useSource<{ id: string }>(`${API_TODO_TASKS.GET_ALL_DYNAMIC}?${queryParams}`);
};

const BadgeRenewal: React.FC = () => {
  const { data } = useSourceRenewal();
  const count = data.length;
  return (
    <Badge badgeContent={count} color={'error'}>
      <DashboardIcon />
    </Badge>
  );
};

export const IconDashboard: React.FC = () => {
  const { $isDoctor } = useCurrentUser();
  return $isDoctor ? <BadgeRenewal /> : <DashboardIcon />;
};
