import React, { useCallback } from 'react';
import { GridControlsDropDownTemplateProps } from 'models/grid';
import { Box, CircularProgress, MenuItem } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { DialogOnHoldMedicalPrescription } from 'components/medical-prescription';
import { useOpen } from 'AurionCR/components/hooks';
import { composeFunctions } from 'utils';
import {
  apiUserPatientPrescriptions,
  iUserPatientProfilePrescription,
} from 'services/user-patient-profile-prescriptions';
import { useEffectError } from 'hooks';
import { useDataGridTasks } from 'components/data-grid/hooks';
import { isMutationFulfilled } from 'utils/rtk-query';

type Model = Pick<iUserPatientProfilePrescription, 'id' | 'isOnHold'>;

const useUnHoldMutation = apiUserPatientPrescriptions.useUnHoldMedicalPrescriptionMutation;

interface HoldProps {
  id: string;
  onDone: () => void;
  onClose: () => void;
}

export const ControlPrescriptionHold: React.FC<HoldProps> = ({ id, onDone, onClose }) => {
  const { tp } = useTranslate();

  const stateDialog = useOpen();

  return (
    <>
      <MenuItem onClick={stateDialog.handleOpen}>{tp('on-hold')}</MenuItem>
      <div onKeyDown={(e) => e.stopPropagation()}>
        {stateDialog.isOpen && (
          <DialogOnHoldMedicalPrescription
            id={id}
            onClose={composeFunctions(stateDialog.handleClose, onClose)}
            onDone={onDone}
          />
        )}
      </div>
    </>
  );
};
export const ControlPrescriptionUnHold: React.FC<HoldProps> = ({ id, onDone }) => {
  const { tp } = useTranslate();

  const [unHold, resultUnHold] = useUnHoldMutation();
  useEffectError(resultUnHold.error);

  const isLoading = resultUnHold.isLoading;

  const onSubmit = useCallback(async () => {
    const res = await unHold({ id });

    if (!isMutationFulfilled(res)) return;

    onDone();
  }, [id, onDone, unHold]);

  return (
    <MenuItem onClick={onSubmit} disabled={isLoading}>
      {tp('un-hold')}
      {isLoading && (
        <Box ml={1}>
          <CircularProgress size={12} />
        </Box>
      )}
    </MenuItem>
  );
};

interface Props extends GridControlsDropDownTemplateProps<Model> {}
export const ControlPrescriptionOnHold: React.FC<Props> = ({ data, handleClose }) => {
  const { id } = data;

  const { onTasks } = useDataGridTasks<Model>();

  const onDone = useCallback(() => {
    onTasks([['getData']]);
    handleClose();
  }, [onTasks, handleClose]);

  return data.isOnHold ? (
    <ControlPrescriptionUnHold id={id} onDone={onDone} onClose={handleClose} />
  ) : (
    <ControlPrescriptionHold id={id} onDone={onDone} onClose={handleClose} />
  );
};
