import React, { useCallback } from 'react';
import { GridControlsDropDownTemplateProps } from 'models/grid';
import { GridModel } from '../../models';
import { ListItemText, MenuItem } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { composeFunctions } from 'utils';
import vars from 'styles/_variabels.scss';
import EditIcon from '@material-ui/icons/Edit';

interface Props extends GridControlsDropDownTemplateProps<GridModel> {}
export const ControlEdit: React.FC<Props> = ({ id, data, onTask, handleClose }) => {
  const { tp } = useTranslate();

  const onClick = useCallback(
    () => onTask([['onHandle', { type: 'edit', id, data }]]),
    [onTask, data, id],
  );

  return (
    <MenuItem
      style={{ color: vars.colorPrimary }}
      disabled={data.isManually === false}
      onClick={composeFunctions(onClick, handleClose)}
    >
      <EditIcon fontSize="small" />
      <ListItemText> {tp('edit')}</ListItemText>
    </MenuItem>
  );
};
