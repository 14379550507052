import React, { useCallback } from 'react';

import { Button, Dialog, DialogActions, DialogContent, Tooltip } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { useForm, FormProvider } from 'react-hook-form';
import { apiSupportMeeting, SupportMeetingRenewal } from 'services/support-meetings';
import { useEffectError, useMountedRef } from 'hooks';
import { Form } from './components/form';
import { useTranslate } from 'hooks/use-translate';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { useAppDispatch } from 'store';
import { isMutationFulfilled } from 'utils/rtk-query';
import { actionSupportMeetingActivitiesCrateLogCreated } from 'store/support-meeting-activities';

const useMutationRenewal = apiSupportMeeting.usePostSupportMeetingRenewalMutation;

interface Props {
  onClose: () => void;
  onRefresh?: () => void;
}

export const DialogRenewal: React.FC<Props> = ({ onClose, onRefresh }) => {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();
  const methods = useForm({ defaultValues: new SupportMeetingRenewal() });

  const mountedRef = useMountedRef();

  const [performRenewal, resultRenewal] = useMutationRenewal();
  useEffectError(resultRenewal.error);

  const onSubmit = useCallback(
    async (data: SupportMeetingRenewal) => {
      const result = await performRenewal(data);

      if (!isMutationFulfilled(result)) return;

      dispatch(actionSupportMeetingActivitiesCrateLogCreated({ supportMeetingID: result.data.id }));

      if (!mountedRef.current) return;

      dispatch(notifyRequestResult(t('renewal-successful-created'), 'success'));

      requestAnimationFrame(() => {
        onRefresh && onRefresh();
        onClose();
      });
    },
    [onClose, onRefresh, mountedRef, dispatch, t, performRenewal],
  );

  return (
    <Dialog open={true} fullWidth maxWidth={'sm'}>
      <DialogHeading
        isLoading={resultRenewal.isLoading}
        title={t('renewal-prescription')}
        onClose={onClose}
      />
      <DialogContent>
        <input hidden {...methods.register('supportMeetingTypeID')} />
        <input hidden {...methods.register('includeMeetingTime')} />
        <input hidden {...methods.register('isActive')} />
        <FormProvider {...methods}>
          <Form isLoading={resultRenewal.isLoading} />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button variant={'outlined'} color={'primary'} onClick={onClose}>
          {t('close')}
        </Button>
        <Tooltip title={t('renewal-create-tooltip')} placement={'top'}>
          <Button
            variant={'contained'}
            color={'primary'}
            disabled={resultRenewal.isLoading}
            onClick={methods.handleSubmit(onSubmit)}
          >
            {t('create')}
          </Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
