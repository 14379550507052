export const APP_FORMAT_DATE = 'dd/MM/yyyy';
export const APP_FORMAT_EXCEL_DATE = 'yyyy-MM-dd';
export const APP_FORMAT_TIME = 'HH:mm';
export const FORMAT_TIME_SLOT = 'HH:mm';
export const APP_FORMAT_DATE_TIME = `${APP_FORMAT_DATE} ${APP_FORMAT_TIME}`;
export const APP_FORMAT_DATE_TIME_SECONDS = `${APP_FORMAT_DATE} HH:mm.ss`;

export const NOTIFICATION_LOCAL_STORAGE_KET = 'raz_notification_do_not_ask';
export const CLINIC_EMAIL = 'mail@doctor-raz.co.il';

export const CALENDAR_MEETING_START_HOURS = 6;
export const CALENDAR_MEETING_END_HOURS = 21;
export const CALENDAR_RANGE_MINUTES = 10;

export let PIPELINE_VARIABLES = {
  PATIENT_APP_URL: process.env.REACT_APP_PATIENT_ORIGIN as string,
};

export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_DEV_BUILD = process.env.REACT_APP_ENV === 'development';
