import { IDataGridRowProps } from 'components/data-grid/model';
import clsx from 'clsx';
import style from './shared.module.scss';

export enum PATIENT_MEETING_SWITCH {
  ALL = 'all-meetings',
  FUTURE = 'upcoming-meetings',
  PREVIOUS = 'past-meetings',
}
export const calcFilterSwitcher = (value: PATIENT_MEETING_SWITCH) => {
  switch (value) {
    case PATIENT_MEETING_SWITCH.FUTURE:
      return `meetingFromDateTime>DateTime.Now`;
    case PATIENT_MEETING_SWITCH.PREVIOUS:
      return `meetingFromDateTime<=DateTime.Now`;
    case PATIENT_MEETING_SWITCH.ALL:
      return undefined;
  }
};

export const getMeetingRowProps: IDataGridRowProps<{ isCanceled: boolean }> = ({ row }) => {
  return { className: clsx(row.isCanceled && style.inactive) };
};

export enum CustomHandler {
  CANCEL_MEETING = 'cancel-meeting',
  PATCH_START = 'pathStart',
}

export type CustomHandlersClinic =
  | { type: CustomHandler.CANCEL_MEETING; data: { id: string; clinicalMeetingSubjectID: string } }
  | { type: CustomHandler.PATCH_START; data: { row: { id: string }; value: Record<string, any> } };

export type CustomHandlersSupport =
  | { type: CustomHandler.CANCEL_MEETING; data: { id: string; supportMeetingTypeID: string } }
  | { type: CustomHandler.PATCH_START; data: { row: { id: string }; value: Record<string, any> } };
