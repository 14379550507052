import {
  UserEmployeeProfile,
  API_USER_EMPLOYEE_PROFILES,
  EmployeeActivitiesMonitorItem,
  GetEmployeesDailyAvailabilityDynamicInput,
  EmployeesDailyAvailability,
  GetSourceEmployeeDietitiansDailyAvailabilityInput,
  SourceEmployeeDietitiansDailyAvailabilityItem,
  DailyAvailabilitySlot,
  DOCTOR_RAZ_ID,
  MyTask,
} from './models';
import { DynamicService } from 'utils/service';
import * as dynamic from 'utils/dynamic-helpers';
import { dateToDayOfWeek } from 'services/user-employee-profile-work-logs';
import { endOfDay, startOfDay } from 'date-fns';
import { apiRtk, RTK_TAGS } from 'utils/rtk-query';
import { convertToDate } from 'utils/dates';
import {
  PERMISSION_IDS,
  UserEmployeeProfilePermission,
} from 'services/user-employee-profile-permissions';

export * from './models';

class Service extends DynamicService<UserEmployeeProfile> {
  get = async (id: string) => {
    return this.engine.get<UserEmployeeProfile>(API_USER_EMPLOYEE_PROFILES.GET({ id }));
  };
  patchEmployee = async (data: Partial<UserEmployeeProfile>) => {
    return this.patch({
      appIdentityUserID: data.appIdentityUserID,
      isActive: data.isActive,
      languageID: data.languageID,
      firstName: data.firstName,
      lastName: data.lastName,
      userPhoto: data.userPhoto,
      mobilePhone: data.mobilePhone,
    });
  };

  getPermissions = async (userEmployeeProfileID: string) => {
    const res = await this.getDynamic(userEmployeeProfileID, {
      select: ['userCrmProfilePermission'].join(''),
    });

    return { data: res.data.userCrmProfilePermission as UserEmployeeProfilePermission };
  };

  getEmployeesDailyAvailabilityDynamic = async <T>(
    input: GetEmployeesDailyAvailabilityDynamicInput,
  ) => {
    const { date, dateRange: _outerDateRange, ...rest } = input;
    const dayOfWeek = dateToDayOfWeek(date);
    const targetDate = convertToDate(date);
    const innerDateRange = [startOfDay(targetDate), endOfDay(targetDate)];

    const dateRange = (
      _outerDateRange && _outerDateRange.length === 2 ? _outerDateRange : innerDateRange
    ).map(convertToDate);

    return this.getAllDynamic<EmployeesDailyAvailability<T>>({
      ...rest,
      select: [
        rest.select,
        `userEmployeeProfileWorkLogs.Where(k => k.isActive == true && dayOfWeek==${dayOfWeek}).Select(k => new { k.id, k.fromTime, k.toTime, k.dayOfWeek, k.isInClinic }) as workLogs`,
        `userEmployeeProfileAbsences.Where(a => a.isActive == true && ${dynamic.createFilterDateISO(
          'a.eventDate',
          dateRange,
        )}).Select(a => new { a.id, a.eventDate }) as absences`,
        `userEmployeeProfileSchedules.Where(s => s.isActive == true && ${dynamic.createFilterDateISO(
          's.date',
          dateRange,
        )}).Select(s => new { s.id, s.fromTime, s.toTime, s.date, s.isInClinic }) as schedules`,
      ]
        .filter(Boolean)
        .join(','),
    });
  };

  makeDailyAvailabilitySlot = <T extends EmployeesDailyAvailability<{}>>(
    item: T,
  ): T & { _availability: DailyAvailabilitySlot[] } => {
    if (item.absences.length > 0) {
      return { ...item, _availability: [] };
    }
    if (item.schedules.length) {
      return { ...item, _availability: item.schedules };
    }
    if (item.workLogs.length) {
      return { ...item, _availability: item.workLogs };
    }

    return { ...item, _availability: [] };
  };

  getEmployeeActivitiesMonitor = async () => {
    const date = new Date();

    return this.getEmployeesDailyAvailabilityDynamic<EmployeeActivitiesMonitorItem>({
      date,
      select: [
        'id',
        'appIdentityUserID',
        'firstName',
        'lastName',
        'userPhoto',
        'lastActivityDate',
        'mobilePhone',
      ].join(','),
      filter: dynamic.mergeFilters('isActive==true', 'lastActivityDate!=null').join('&&'),
    });
  };
}

export const ServiceUserEmployeeProfile = new Service({
  mainField: 'appIdentityUserID',
  getAll: API_USER_EMPLOYEE_PROFILES.GET_ALL_DYNAMIC,
  post: API_USER_EMPLOYEE_PROFILES.POST,
  patch: API_USER_EMPLOYEE_PROFILES.PATCH,
  delete: API_USER_EMPLOYEE_PROFILES.DELETE,
});

export const apiUserEmployeeProfiles = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getSourceEmployeeDietitiansDailyAvailability: builder.query({
      queryFn: async (arg: GetSourceEmployeeDietitiansDailyAvailabilityInput) => {
        const { date } = arg;
        const {
          data: { value },
        } = await ServiceUserEmployeeProfile.getEmployeesDailyAvailabilityDynamic<
          Required<SourceEmployeeDietitiansDailyAvailabilityItem>
        >({
          date,
          filter: dynamic
            .mergeFilters(
              dynamic.createFilterEquals(
                'userEmployeeProfilePermissionID',
                PERMISSION_IDS.DIETITIAN,
              ),
              dynamic.createFilterEquals('isActive', true),
            )
            .join('&&'),
          select: dynamic.select(
            'appIdentityUserID as id',
            'firstName',
            'lastName',
            'userPhoto',
            'userEmployeeProfilePermissionID',
            `clinicalMeetings.Where(m=> ${dynamic
              .mergeFilters(
                dynamic.createFilterDateMoreOrEqualsISO(
                  'm.meetingFromDateTime',
                  startOfDay(convertToDate(date)),
                ),
                dynamic.createFilterDateLessOrEqualsISO(
                  'm.meetingToDateTime',
                  endOfDay(convertToDate(date)),
                ),

                'm.isActive==true',
                'm.clinicalMeetingSubject.isCanceledMeeting==false',
              )
              .join(
                '&&',
              )}).Select(m => new {m.meetingFromDateTime,m.meetingToDateTime}) as clinicalMeetings`,
          ),
        });

        return { data: value };
      },
    }),
    getDoctorDetailsForPdf: builder.query<
      Pick<UserEmployeeProfile, 'firstName' | 'lastName' | 'signature'>,
      void
    >({
      queryFn: async () => {
        try {
          const { data } = await ServiceUserEmployeeProfile.getDynamic(DOCTOR_RAZ_ID, {
            select: ['firstName', 'lastName', 'signature'].join(','),
          });

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getMyTasksCount: builder.query<Array<MyTask>, string>({
      queryFn: async (currentUserID) => {
        try {
          const { data } = await ServiceUserEmployeeProfile.getDynamic<{ tasks: Array<MyTask> }>(
            currentUserID,
            {
              select: dynamic.select(
                'userEmployeeProfileToDoTasks.Where(t => t.toDoTask.done==false).Select(t => new { t.toDoTask.id, t.toDoTask.toDoTaskCategory.isUrgent }) as tasks',
              ),
            },
          );
          return { data: data.tasks };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, arg, meta) => [
        { type: RTK_TAGS.TODO_TASKS, id: `userEmployeeProfileID__${arg}` },
        ...(result || []).map((task) => ({
          type: RTK_TAGS.TODO_TASKS,
          id: task.id,
        })),
      ],
    }),
  }),
});
