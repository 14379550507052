import { CLINICAL_MEETING_ACTION_TYPES, EmployeeTimeSlot, IClinicalMeetingsState } from './@type';
import { PatchPartial } from 'utils/types';
import { ClinicalMeeting } from 'services/clinical-meetings';

export const actionCMReset = () => {
  return { type: CLINICAL_MEETING_ACTION_TYPES.RESET } as const;
};
export const actionCMMerge = (payload: Partial<IClinicalMeetingsState>) => {
  return { type: CLINICAL_MEETING_ACTION_TYPES.MERGE, payload } as const;
};
export const actionCMViewMerge = (
  payload:
    | (Partial<IClinicalMeetingsState['calendar']> & { view: 'calendar' })
    | (Partial<IClinicalMeetingsState['list']> & { view: 'list' }),
) => {
  return { type: CLINICAL_MEETING_ACTION_TYPES.VIEW_MERGE, payload } as const;
};
export const actionCMFilterMerge = (
  payload:
    | (Partial<IClinicalMeetingsState['calendar']['filters']> & {
        view: 'calendar';
      })
    | (Partial<IClinicalMeetingsState['list']['filters']> & {
        view: 'list';
      }),
) => {
  return { type: CLINICAL_MEETING_ACTION_TYPES.FILTER_MERGE, payload } as const;
};

// saga
export const actionCMInit = () => {
  return { type: CLINICAL_MEETING_ACTION_TYPES.INIT } as const;
};
export const actionCMRefresh = (payload: { refreshAnyway: boolean; refreshScroll: boolean }) => {
  return { type: CLINICAL_MEETING_ACTION_TYPES.REFRESH, payload } as const;
};
export const actionCMRefreshActivities = () => {
  return { type: CLINICAL_MEETING_ACTION_TYPES.REFRESH_ACTIVITIES } as const;
};
export const actionCMDeleteMeeting = (payload: { id: string }) => {
  return { type: CLINICAL_MEETING_ACTION_TYPES.DELETE_MEETING, payload } as const;
};
export const actionCMPatchMeeting = (payload: {
  newMeeting: PatchPartial<ClinicalMeeting, 'id' | 'clinicalMeetingSubjectID'>;
  oldMeeting: PatchPartial<
    ClinicalMeeting,
    'id' | 'clinicalMeetingSubjectID' | 'userPatientProfileID'
  >;
}) => {
  return { type: CLINICAL_MEETING_ACTION_TYPES.PATCH_MEETING, payload } as const;
};
export const actionCMChangeView = (payload: 'list' | 'calendar') => {
  return { type: CLINICAL_MEETING_ACTION_TYPES.CHANGE_VIEW, payload } as const;
};

export const actionCMSendSms = (payload: { meetingID: string | null }) => {
  return { type: CLINICAL_MEETING_ACTION_TYPES.SEND_SMS, payload } as const;
};

export const actionCMCalendarSetTimeslots = (payload: {
  [appIdentityUserID: string]: EmployeeTimeSlot[];
}) => {
  return { type: CLINICAL_MEETING_ACTION_TYPES.SET_CALENDAR_EMPLOYEE_TIME_SLOTS, payload } as const;
};

export const actionCMExportToExcel = () => {
  return { type: CLINICAL_MEETING_ACTION_TYPES.EXPORT_TO_EXCEL } as const;
};

export type ClinicalMeetingActions = ReturnType<
  | typeof actionCMReset
  | typeof actionCMMerge
  | typeof actionCMViewMerge
  | typeof actionCMFilterMerge
  | typeof actionCMFilterMerge
  | typeof actionCMInit
  | typeof actionCMRefresh
  | typeof actionCMDeleteMeeting
  | typeof actionCMPatchMeeting
  | typeof actionCMChangeView
  | typeof actionCMCalendarSetTimeslots
  | typeof actionCMSendSms
  | typeof actionCMRefreshActivities
  | typeof actionCMExportToExcel
>;
