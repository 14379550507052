import { ValueFileUploaderFile } from 'utils/file-uploader';
import { DateValue } from 'utils/dates';
import { DocumentType } from 'services/document-types';

const API_ENDPOINT = 'UserPatientProfileDocuments';

export const API_USER_PATIENT_PROFILE_DOCUMENTS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<UserPatientProfileDocument, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<UserPatientProfileDocument, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface UserPatientProfileDocument extends Components.Schemas.UserPatientProfileDocument {
  createdDate: string;
}

export interface PatientDocumentCreate
  extends Pick<
    Required<UserPatientProfileDocument>,
    | 'fileName'
    | 'documentTypeID'
    | 'userPatientProfileID'
    | 'userEmployeeProfileID'
    | 'isForm29'
    | 'isCopy'
  > {
  documentURL: ValueFileUploaderFile;
  documentForPrintURL: ValueFileUploaderFile | null;
}

export interface PatientDocumentGetActivitiesInput {
  userPatientProfileID: string;
  dateRange: DateValue[];
}
export interface PatientDocumentGetActivitiesItem
  extends Pick<UserPatientProfileDocument, 'id' | 'createdDate' | 'fileName' | 'documentURL'> {
  employee: Components.Schemas.UserEmployeeProfile['fullName'];
}

export interface GetUserPatientProfileDocumentsFromOldSystemItem
  extends Pick<UserPatientProfileDocument, 'id' | 'createdDate' | 'documentURL' | 'documentType'> {
  documentType: Pick<DocumentType, 'title'>;
}

export interface GetUserPatientProfileDocumentForm29GimelInput {
  userPatientProfilePrescriptionID: string;
  userPatientProfilePrescriptionDetailID: string;
}
export interface GetUserPatientProfileDocumentForm29GimelItem
  extends Pick<UserPatientProfileDocument, 'documentURL' | 'fileName'> {}
