import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { GridSignedDocumentModel } from './models';
import { apiSmsContents, SMS_CONTENT_KEYS } from 'services/sms-contents';
import { useAppDispatch, useAppSelector } from 'store';
import { actionPatientMessageAddItem } from 'store/patient-sms-messages';
import { useCurrentUser } from 'components/hooks';
import { calcHtmlTemplate } from 'utils';
import { apiUserPatientProfile, createBackdoorLink } from 'services/user-patient-profile';
import { useEffectError } from 'hooks';
import { createPatientDocumentLinkInnerLink } from 'services/user-patient-profile-form-documents';
import { convertToDate } from 'utils/dates';
import { differenceInMinutes, format } from 'date-fns';
import { APP_FORMAT_DATE } from 'configs/const';
import { isMutationFulfilled } from 'utils/rtk-query';
import { selectLanguageID } from 'store/languages';

const BACKDOOR_MINUTES = 60 * 24 * 7;

interface Props {
  data: GridSignedDocumentModel;
}

export const ControlButtonSmsSignDoc: React.FC<Props> = ({ data }) => {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();

  const currentEmployee = useCurrentUser();

  const languageID = useAppSelector(selectLanguageID);

  const [makeBackDoor, { isLoading: isLoadingBackDoor, error: errorBackdoor }] =
    apiUserPatientProfile.useMakePatientBackDoorMutation();
  useEffectError(errorBackdoor);

  const [triggerGetContent, { isLoading: isLoadingContent, error: errorContent }] =
    apiSmsContents.useLazyGetSmsContentBySmsKeyQuery();
  useEffectError(errorContent);

  const onClick = useCallback(async () => {
    const resultContent = await triggerGetContent({
      languageID: languageID,
      contentKey: SMS_CONTENT_KEYS.SEND_SMS_SIGN_DOCUMENT,
    });

    if (!resultContent.data) {
      return;
    }

    const backdoorResult = await makeBackDoor({
      userPatientProfileID: String(data.userPatientProfileID),
      minutes: BACKDOOR_MINUTES,
    });

    if (!isMutationFulfilled(backdoorResult)) {
      return;
    }

    const redirect = createPatientDocumentLinkInnerLink({ id: String(data.id) });
    const signDocumentUrl = createBackdoorLink({
      userPatientProfileID: backdoorResult.data.userPatientProfileID,
      secret: backdoorResult.data.secret,
      redirect,
    });

    const inMinutes = differenceInMinutes(backdoorResult.data.expired, new Date());
    const inHours = Math.ceil(inMinutes / 60);

    const signDocumentDuration = `${inHours} ${t('hours')}`;

    const signDocumentExpiredDate = format(convertToDate(data.expiredDate), APP_FORMAT_DATE);

    const templatePayload = {
      currentEmployee,
      patient: data.patient,

      signDocumentUrl,
      signDocumentDuration,
      signDocumentExpiredDate,
    };

    dispatch(
      actionPatientMessageAddItem({
        uid: `send-sms-sign-doc__${data.userPatientProfileID}`,
        formData: {
          userPatientProfileID: data.userPatientProfileID,
          toPhoneNumber: data.patient.mobilePhone,
          message: calcHtmlTemplate(resultContent.data.message || '', templatePayload),
        },
        templatePayload: templatePayload,
      }),
    );
  }, [triggerGetContent, makeBackDoor, languageID, data, currentEmployee, dispatch, t]);

  return (
    <Button
      variant={'contained'}
      color={'primary'}
      onClick={onClick}
      disabled={Boolean(data.signedDate) || data.isExpired || isLoadingContent || isLoadingBackDoor}
    >
      {t('send-sign-message')}
    </Button>
  );
};
