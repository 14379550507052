import React, { memo, useMemo } from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { IUseForm, useFormHook, Controls, validateRule } from 'AurionCR/components/formV2';
import { DialogBase, useAuth } from 'AurionCR/components';
import { useDeleteDialog } from 'AurionCR/components/form';
import { DialogHeading } from 'components/dialog-title';
import { useRequestNotify } from 'components/hooks';
import { IGridEditFormProps, IGridFormProps } from 'components/models';
import {
  API_USER_PATIENT_PROFILE_MEDICAL_INFORMATIONS,
  MedicalInformations,
} from 'services/user-patient-profile-medical-informations';
import { usePermissions } from 'hooks/use-permissions';
import { useTranslate } from 'hooks/use-translate';
import { useFieldProps } from 'hooks';
import { Controller } from 'react-hook-form';
import { SelectEmployee } from 'components/select-employee';
import { AppDatePicker } from 'components/app-date-picker';
import { AppInput } from 'components/app-input';
import { AppCheckbox } from 'components/app-checkbox';

export const Form: React.FC<IGridFormProps<MedicalInformations>> = ({
  isLoading,
  control,
  errors,
}) => {
  const { t } = useTranslate();
  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });
  return (
    <>
      <Controller
        control={control}
        name={'userEmployeeProfileID'}
        rules={validateRule('required')}
        render={(renderProps) => (
          <SelectEmployee
            {...getFieldProps(renderProps)}
            label={t('employee')}
            disableClearable
            disabled={isLoading}
          />
        )}
      />
      <Controller
        control={control}
        name={'expirationDate'}
        render={(renderProps) => (
          <AppDatePicker {...getFieldProps(renderProps)} label={t('date')} disabled={isLoading} />
        )}
      />
      <Controller
        control={control}
        name={'remarks'}
        rules={validateRule('required')}
        render={(renderProps) => (
          <AppInput
            {...getFieldProps(renderProps)}
            disabled={isLoading}
            multiline
            minRows={4}
            maxRows={8}
          />
        )}
      />
      <Controller
        control={control}
        name={'isPermanent'}
        render={(renderProps) => (
          <AppCheckbox
            {...getFieldProps(renderProps)}
            disabled={isLoading}
            label={t('permanent')}
          />
        )}
      />
    </>
  );
};

interface Props extends IGridEditFormProps {
  userPatientProfileID: string;
}

export default memo<Props>(({ onClose, onFormHandle, itemID, userPatientProfileID }) => {
  const { t } = useTranslate();
  const { user } = useAuth();

  const config = useMemo<IUseForm<MedicalInformations>>((): any => {
    return {
      editID: itemID,
      fields: {
        userEmployeeProfileID: user.appUserID || '',
        remarks: '',
        expirationDate: new Date(),
        isPermanent: true,
      },
      endpoint: API_USER_PATIENT_PROFILE_MEDICAL_INFORMATIONS.API_ENDPOINT,
      formHandle: onFormHandle,
    };
  }, [itemID, onFormHandle, user.appUserID]);
  const {
    formLoading,
    setFormLoading,
    formTitle,
    formIsNew,
    formUse: { control, errors, handleSubmit },
    onSubmit,
    deleteItem,
  } = useFormHook(config);
  // request noty wrapper
  const { requestWrap: wrapSubmit } = useRequestNotify({
    request: (data) => {
      return onSubmit({ ...data, userPatientProfileID: userPatientProfileID, isPermanent: true });
    },
    setLoading: setFormLoading,
  });
  const { requestWrap: wrapDelete } = useRequestNotify({
    request: deleteItem,
    setLoading: setFormLoading,
  });
  // delete helper
  const { handleClose, handleConfirm, handleOpen, isOpenDelete } = useDeleteDialog({
    deleteMiddleware: wrapDelete,
  });
  const isAllowToDelete = usePermissions('isAllowToDeleteAnything');

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="xs">
      <DialogHeading isLoading={formLoading} title={' '} onClose={onClose} />
      <DialogContent style={{ position: 'relative' }}>
        <Form isLoading={formLoading} errors={errors} control={control} />
        {isOpenDelete && isAllowToDelete && (
          <DialogBase
            title={formTitle}
            onClose={handleClose}
            onConfirm={handleConfirm}
            isLoading={formLoading}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Controls
          size="medium"
          state={!formIsNew ? 'save' : 'create'}
          loading={formLoading}
          onSubmit={handleSubmit(wrapSubmit)}
          onDelete={handleOpen}
          onCancel={onClose}
          showDelete={!formIsNew && isAllowToDelete}
          CancelButtonProps={{
            children: t('cancel'),
          }}
        />
      </DialogActions>
    </Dialog>
  );
});
