import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import clsx from 'clsx';
import React, { useCallback, useRef, useState } from 'react';
import style from './index.module.scss';
import Slider from 'react-slick';
import { Container, IconButton, Modal, Typography } from '@material-ui/core';

interface ArrowProps {
  onClick?: () => void;
}

const ArrowNext: React.FC<ArrowProps> = (props) => {
  return (
    <IconButton className={clsx(style.arrow, style.arrowNext)} onClick={props.onClick}>
      <ArrowBackIosRoundedIcon />
    </IconButton>
  );
};
const ArrowPrev: React.FC<ArrowProps> = (props) => {
  return (
    <IconButton className={clsx(style.arrow, style.arrowPrev)} onClick={props.onClick}>
      <ArrowBackIosRoundedIcon />
    </IconButton>
  );
};

interface IImage {
  title: string;
  picture: string;
}

interface Props {
  initialIndex?: number;
  images: IImage[];
  onClose: () => void;
}
export const DialogImagesPreview: React.FC<Props> = ({ initialIndex = 0, images, onClose }) => {
  const customPaging = useCallback(
    (index: number) => {
      return (
        <span className={style.paging}>
          <img alt={''} src={images[index].picture} width={'100%'} height={'100%'} />
        </span>
      );
    },
    [images],
  );

  const refDotsTrack = useRef<HTMLUListElement>(null);

  const [indicatorPosition, setIndicatorPosition] = useState([0, 0]);

  const calcIndicatorPosition = useCallback(() => {
    const element = refDotsTrack.current;
    if (!element) {
      return;
    }

    const activeElement = element.getElementsByClassName('slick-active')[0] as HTMLLIElement;

    if (!activeElement) {
      return;
    }

    setIndicatorPosition([activeElement.offsetLeft, activeElement.offsetTop]);
  }, []);

  return (
    <Modal open={true} onClose={onClose} className={style.root}>
      <Container className={style.wrap}>
        <IconButton onClick={onClose} className={style.close}>
          <CloseRoundedIcon />
        </IconButton>
        <div className={style.container}>
          <div className={style.inner}>
            <Slider
              initialSlide={initialIndex}
              customPaging={customPaging}
              dots
              infinite
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
              dotsClass={clsx('slick-dots', style.dots)}
              afterChange={calcIndicatorPosition}
              onInit={calcIndicatorPosition}
              appendDots={(dots) => (
                <ul ref={refDotsTrack}>
                  {dots}
                  <div
                    className={style.indicator}
                    style={{
                      transform: `translate(${indicatorPosition[0]}px,${indicatorPosition[1]}px) scale(1.2, 1.4)`,
                    }}
                  />
                </ul>
              )}
              nextArrow={<ArrowNext />}
              prevArrow={<ArrowPrev />}
            >
              {images.map((image, i) => (
                <div key={i} className={style.slide}>
                  <Typography variant={'body1'} noWrap className={style.title}>
                    {image.title}
                  </Typography>
                  <img
                    alt={''}
                    className={style.img}
                    src={image.picture}
                    width={'100%'}
                    height={'100%'}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </Container>
    </Modal>
  );
};
