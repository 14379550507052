import { AutocompleteGetTagProps } from '@material-ui/lab';
import { Chip } from '@material-ui/core';
import React from 'react';
import style from './index.module.scss';
import { ColoredOptionIcon } from '../helpers';

const renderColoredIconTags = (
  value: ColoredOptionIcon[],
  getTagProps: AutocompleteGetTagProps,
) => {
  return value.map((option, index) => {
    const Icon = option.Icon;
    const color = option.color ?? undefined;
    return (
      <Chip
        avatar={<Icon fontSize={'small'} style={{ color }} />}
        variant="outlined"
        label={option.title || ''}
        {...getTagProps({ index })}
        classes={{
          root: style.chipRoot,
          avatar: style.chipAvatar,
          deleteIcon: style.chipDeleteIcon,
        }}
        style={{
          color,
          borderColor: color,
        }}
      />
    );
  });
};

export default renderColoredIconTags;
