import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import {
  Badge,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import VAR from 'styles/_variabels.scss';

import style from './index.module.scss';
import { Skeleton } from '@material-ui/lab';
import { NativeScroll } from 'components/native-scroll';
import InfoIcon from '@material-ui/icons/Info';

interface Item {
  title: React.ReactNode;
  info?: string;
  value: any;
  isValid: boolean;
}
interface Props {
  items: Item[];
  isLoading: boolean;
}

export const TableListValidity: React.FC<Props> = ({ items, isLoading }) => {
  const { t } = useTranslate();

  const renderIcon = useCallback((isValid: boolean) => {
    if (isValid) {
      return <CheckIcon fontSize={'small'} color={'inherit'} style={{ color: VAR.colorGreen }} />;
    }
    return <CloseIcon fontSize={'small'} color={'inherit'} style={{ color: VAR.colorRed }} />;
  }, []);

  const rows = useMemo(() => {
    return isLoading
      ? new Array(3).fill(null).map(() => ({
          title: 'loading',
          value: 'loading',
          isValid: false,
          info: '',
        }))
      : items;
  }, [items, isLoading]);

  return (
    <NativeScroll style={{ maxHeight: '60vh' }} mode={'visible'}>
      <Table size={'small'} className={style.table}>
        <TableHead className={style.head}>
          <TableRow>
            <TableCell className={style.headTh}>{t('field')}</TableCell>
            <TableCell className={style.headTh}>{t('value')}</TableCell>
            <TableCell className={style.headTh} align={'center'} style={{ whiteSpace: 'nowrap' }}>
              {t('is-valid')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((item, i) => {
            return (
              <TableRow
                key={i}
                className={clsx(style.row, {
                  [style.rowError]: !isLoading && !item.isValid,
                  [style.rowSuccess]: !isLoading && item.isValid,
                })}
              >
                <TableCell style={{ width: '50%' }} variant={'head'}>
                  {isLoading ? (
                    <Skeleton />
                  ) : (
                    <Badge
                      invisible={!item.info}
                      badgeContent={
                        <Tooltip title={item.info || ''}>
                          <InfoIcon color={'primary'} />
                        </Tooltip>
                      }
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <div>{item.title}</div>
                    </Badge>
                  )}
                </TableCell>

                <TableCell style={{ width: '50%' }}>
                  {isLoading ? <Skeleton /> : item.value}
                </TableCell>
                <TableCell align={'center'}>
                  {isLoading ? (
                    <Skeleton variant="rect" width={20} height={20} style={{ margin: '0 auto' }} />
                  ) : (
                    renderIcon(item.isValid)
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </NativeScroll>
  );
};
