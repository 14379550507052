import { select } from 'utils/dynamic-helpers';

const withPrefix = (prefix?: string) => {
  const wrap: typeof select = (...args) => {
    return select(...args.map((field) => (prefix ? `${prefix}.${field}` : field)));
  };
  return wrap;
};

export const SELECT_PATIENT_CARD_INFO = (prefix?: string) => {
  const selectWithPrefix = withPrefix(prefix);

  return selectWithPrefix(
    'isTLC',
    'extraAttention',
    'dateOfBirth',
    'fullName',
    'isActive',
    'onHold',
    'onHoldReason',
    'slowIncreaseWeight',
    'changeDosageByDoctorApproval',
    'doNotProlongTreatment',
  );
};
