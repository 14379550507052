import React, { memo, useCallback, useState } from 'react';
import { TextField } from '@material-ui/core';
import { getRandomString, useI18n } from 'AurionCR/components';
import { iFormInputField } from './index';
import style from './index.module.scss';
import clsx from 'clsx';

export const FieldMaxLength = memo(
  ({ value, onChange: OnChange }: iFormInputField<'maxLength'>) => {
    const { t } = useI18n();
    // state
    const [fieldID] = useState(`maxLength_${getRandomString()}`);
    // handlers
    const onChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        OnChange({ maxLength: Number(e.target.value || 0) });
      },
      [OnChange],
    );
    //render
    return (
      <div className={clsx(style.labelInput, style.fieldMaxLength)}>
        <label htmlFor={fieldID} className={style.label}>
          {t('max-length')}:
        </label>
        <TextField
          id={fieldID}
          className={clsx(style.input, style.maxLength)}
          size={'small'}
          variant="outlined"
          value={value || 0}
          onChange={onChange}
          inputProps={{ min: 0, type: 'number' }}
        />
      </div>
    );
  },
);

export default FieldMaxLength;
