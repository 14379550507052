import React, { forwardRef } from 'react';
import { ButtonProps, Button } from '@material-ui/core';
import { Link, LinkProps } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import style from './index.module.scss';
import clsx from 'clsx';

type Props<D extends React.ElementType, P = {}> = ButtonProps<D, P> & {};
const ButtonRedirectComponent = <D extends React.ElementType = typeof Link, P = LinkProps>(
  props: Props<D, P>,
  ref: ForwardedRef<HTMLButtonElement>,
) => {
  return (
    <Button
      component={Link}
      variant="outlined"
      color="secondary"
      endIcon={<OpenInNewIcon />}
      ref={ref}
      {...props}
      className={clsx(style.root, props.className)}
    />
  );
};

export const ButtonRedirect = forwardRef(ButtonRedirectComponent) as typeof ButtonRedirectComponent;
