import { DateValue } from 'utils/dates';
import { UserEmployeeProfile } from 'services/user-employee-profiles';

const API_ENDPOINT = 'UserPatientProfileSmsMessages';

export const API_USER_PATIENT_PROFILE_SMS_MESSAGES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<UserPatientProfileSmsMessage, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<UserPatientProfileSmsMessage, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface UserPatientProfileSmsMessage
  extends Components.Schemas.UserPatientProfileSmsMessage {}

export interface UsePatientSmsMessageGetActivitiesInput {
  userPatientProfileID: string;
  dateRange: DateValue[];
}
export interface UsePatientSmsMessageGetActivitiesItem
  extends Pick<UserPatientProfileSmsMessage, 'id' | 'entryDate' | 'message'> {
  employee: UserEmployeeProfile['fullName'];
}
