import { useMap } from './use-map';
import { useCallback, useMemo } from 'react';

type Value = string | null;

const onChangeDef = () => {};
export interface UseSelectProps<
  T extends Record<string, any> = { id: string },
  M extends boolean = false,
> {
  value?: M extends true ? string[] : Value;
  multiple?: M;
  options?: T[];
  onChange?: M extends true ? (v: string[]) => void : (v: Value) => void;
}

export const useSelect = <
  T extends Record<string, any> = { id: string },
  M extends boolean = false,
>(
  props: UseSelectProps<T, M>,
) => {
  const { options = [], multiple, onChange: _onChange = onChangeDef } = props;

  const values = useMemo(() => {
    if (Array.isArray(props.value)) {
      return props.value;
    } else if (props.value) {
      return [props.value];
    } else {
      return [];
    }
  }, [props.value]);
  const mapSelected = useMap(values);

  const onChangeItem = useCallback(
    (v: string) => {
      if (multiple) {
        const newValues = mapSelected[v] ? values.filter((value) => value !== v) : [...values, v];
        // @ts-ignore
        _onChange(newValues);
      } else {
        // @ts-ignore
        _onChange(v === props.value ? null : v);
      }
    },
    [_onChange, multiple, values, mapSelected, props.value],
  );

  const isSelectedAll = useMemo(() => {
    return values.length === options.length;
  }, [values.length, options.length]);
  const onSelectAll = useCallback(() => {
    if (multiple) {
      // @ts-ignore
      _onChange(isSelectedAll ? [] : options.map((item) => item.id));
    }
  }, [_onChange, multiple, options, isSelectedAll]);

  const factoryClick = useCallback(
    (option: T) => {
      return () => onChangeItem(option.id);
    },
    //eslint-disable-next-line
    [onChangeItem],
  );

  return { mapSelected, onChangeItem, onSelectAll, isSelectedAll, factoryClick };
};
