import React from "react";
import {
  Checkbox as MaterialCheckbox,
  CheckboxProps as MaterialCheckboxProps
} from "@material-ui/core";

export interface CheckboxProps extends Omit<MaterialCheckboxProps, 'value' | 'checked' | 'onBlur'> {
  value?: boolean,
  onBlur?: () => void
}

export const Checkbox: React.FC<CheckboxProps> = ({ value, onChange, onBlur, ...rest }) => {
  return <MaterialCheckbox checked={value} onChange={onChange} onBlur={onBlur} {...rest} />
}
