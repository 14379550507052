import React from 'react';
import { Grid } from '@material-ui/core';
import { validateRule } from 'AurionCR/components/formV2';
import { useFormContext, Controller } from 'react-hook-form';
import { useSourceDoctors } from 'components/hooks';
import { useFieldProps, useFormDefaultValue } from 'hooks';
import { SelectPatients } from 'components/select-patients';
import { useTranslate } from 'hooks/use-translate';
import { AppInput } from 'components/app-input';
import { AppSelect } from 'components/app-select';
import { AppDatePicker } from 'components/app-date-picker';

interface Props {
  isLoading: boolean;
}

export const Form: React.FC<Props> = ({ isLoading }) => {
  const { t } = useTranslate();
  const { control, errors, watch, setValue } = useFormContext();
  const getFieldProps = useFieldProps({ errors, emptyHelperText: '', disabled: isLoading });

  const sourceDoctors = useSourceDoctors(true);
  useFormDefaultValue(sourceDoctors.data?.[0]?.id, {
    watch,
    setValue,
    name: 'userEmployeeProfileID',
  });

  return (
    <Grid container spacing={3}>
      <Grid item md={6}>
        <Controller
          name={'entryDate'}
          control={control}
          rules={validateRule('required,dateLike')}
          render={(renderProps) => (
            <AppDatePicker {...getFieldProps(renderProps)} disabled={isLoading} />
          )}
        />
      </Grid>
      <Grid item md={6}>
        <Controller
          name={'userEmployeeProfileID'}
          control={control}
          rules={validateRule('required')}
          render={(renderProps) => {
            return (
              <AppSelect
                {...getFieldProps(renderProps)}
                label={t('doctor')}
                loading={sourceDoctors.loading}
                options={sourceDoctors.data}
                disableClearable
              />
            );
          }}
        />
      </Grid>
      <Grid item md={12}>
        <Controller
          name={'userPatientProfileID'}
          rules={validateRule('required')}
          control={control}
          render={(renderProps) => {
            return (
              <SelectPatients
                {...getFieldProps(renderProps)}
                label={t('patient')}
                disableClearable
              />
            );
          }}
        />
      </Grid>
      <Grid item md={12}>
        <Controller
          name={'remarks'}
          control={control}
          defaultValue={t('prescription-validity')}
          render={(renderProps) => {
            return <AppInput {...getFieldProps(renderProps)} multiline minRows={3} />;
          }}
        />
      </Grid>
    </Grid>
  );
};
