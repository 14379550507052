import React, { memo } from 'react';
import { useRouter } from 'AurionCR/components';
import { usePermissions } from 'hooks/use-permissions';
import { APP_ROUTES } from 'configs/routes';
import { useTranslate } from 'hooks/use-translate';
import { ButtonRedirect } from 'components/app-buttons';
import style from './index.module.scss';
import { MyTasks } from '../my-tasks';
import { OnHoldPrescriptions } from '../on-hold-prescriptions';

export const TopNav = memo(() => {
  const { t } = useTranslate();
  const router = useRouter();

  const isShowList = usePermissions('isAllowToEditPatients');
  const isShowDashboard = usePermissions('isAllowToViewDashboard');

  const isLeads = usePermissions('isAllowToEditLeads');

  return (
    <div className={style.root}>
      {router.pathname !== APP_ROUTES.PATIENT_PRESCRIPTIONS_HOLD_EXPIRED.path && (
        <OnHoldPrescriptions />
      )}
      {router.pathname !== APP_ROUTES.MY_TASKS.path && <MyTasks />}
      {router.pathname !== APP_ROUTES.DASHBOARD.path && isShowDashboard && (
        <ButtonRedirect to={APP_ROUTES.DASHBOARD().config}>{t('dashboard')}</ButtonRedirect>
      )}
      {router.pathname !== APP_ROUTES.PATIENT_LIST.path && isShowList && (
        <ButtonRedirect to={APP_ROUTES.PATIENT_LIST({}).config}>
          {t('patients-list')}
        </ButtonRedirect>
      )}

      {router.pathname !== APP_ROUTES.LEADS.path && isLeads && (
        <ButtonRedirect to={APP_ROUTES.LEADS().config}>{t('leads')}</ButtonRedirect>
      )}
    </div>
  );
});
