import { DynamicService } from 'utils/service';
import {
  SupportMeetingActivity,
  API_SUPPORT_MEETING_ACTIVITIES,
  SupportMeetingActivityPostInput,
} from './models';
import { apiRtk, RTK_TAGS } from 'utils/rtk-query';
import { selectAuthUser } from '../../store/auth';
import { RootState } from '../../store';
import { apiRemarkForPatientCallStatuses, REMARK_REASON } from '../remark-for-patient-call-status';
export * from './models';

class Service extends DynamicService<SupportMeetingActivity, 'id'> {}

export const ServiceSupportMeetingActivities = new Service({
  getAll: API_SUPPORT_MEETING_ACTIVITIES.GET_ALL_DYNAMIC,
  post: API_SUPPORT_MEETING_ACTIVITIES.POST,
  patch: API_SUPPORT_MEETING_ACTIVITIES.PATCH,
  delete: API_SUPPORT_MEETING_ACTIVITIES.DELETE,
});

export const apiSupportMeetingActivities = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getLatestSupportMeetingActivities: build.query({
      queryFn: async (supportMeetingID: string) => {
        try {
          const {
            data: { value },
          } = await ServiceSupportMeetingActivities.getAllDynamic<
            Pick<SupportMeetingActivity, 'id' | 'entryDate' | 'remarks'>
          >({
            filter: `supportMeetingID=="${supportMeetingID}"`,
            select: ['id', 'entryDate', 'remarks'].join(','),
          });
          return { data: value };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (_, __, supportMeetingID) => [
        { type: RTK_TAGS.SUPPORT_MEETING_ACTIVITIES, id: `supportMeetingID__${supportMeetingID}` },
      ],
    }),
    postSupportMeetingActivity: build.mutation<
      SupportMeetingActivity,
      SupportMeetingActivityPostInput
    >({
      queryFn: async (input, { getState, dispatch }) => {
        const currentUser = selectAuthUser(getState() as RootState);

        if (!currentUser) {
          throw new Error('PostSupportMeetingActivity: unexpected-behaviour');
        }

        let remarkForPatientCallStatusID = input.remarkForPatientCallStatusID;

        try {
          if (!remarkForPatientCallStatusID) {
            const request = dispatch(
              apiRemarkForPatientCallStatuses.endpoints.getRemarkByReason.initiate(
                REMARK_REASON.AUTO,
              ),
            );
            request.unsubscribe();
            const res = await request.unwrap();
            remarkForPatientCallStatusID = String(res.id);
          }

          return ServiceSupportMeetingActivities.post({
            entryDate: new Date().toISOString(),

            userEmployeeProfileID: currentUser.appUserID,
            ...input,
            remarkForPatientCallStatusID: remarkForPatientCallStatusID,
          });
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (_, __, supportMeeting) => [
        {
          type: RTK_TAGS.SUPPORT_MEETING_ACTIVITIES,
          id: `supportMeetingID__${supportMeeting.supportMeetingID}`,
        },
      ],
    }),
  }),
});
