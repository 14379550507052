import React, { memo, useMemo, useRef } from 'react';
import { Button } from '@material-ui/core';
import { useOpen } from 'AurionCR/components/hooks';
import { PrescriptionDetailsPopup } from 'components/medical-prescription';
import { apiUserPatientPrescriptions } from 'services/user-patient-profile-prescriptions';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useEffectError } from 'hooks';

interface Props {
  drugs: string[];
  id: string;
}
export const TemplatePrescriptionDrugs = memo<Props>(({ drugs, id }) => {
  const { isOpen, handleOpen, handleClose } = useOpen();

  const ref = useRef(null);
  const title = useMemo(() => {
    return drugs.filter(Boolean).join(' | ');
  }, [drugs]);

  const skip = !isOpen;

  const {
    data: prescription,
    isLoading,
    error,
  } = apiUserPatientPrescriptions.useGetMedicalPrescriptionWithDetailsQuery(id, {
    skip,
  });
  useEffectError(error);

  return (
    <>
      <Button
        fullWidth
        ref={ref}
        onClick={handleOpen}
        endIcon={<VisibilityIcon color={'primary'} />}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
        disabled={!drugs.length}
      >
        {title}
      </Button>
      <PrescriptionDetailsPopup
        isLoading={isLoading}
        count={drugs.length}
        userPatientProfilePrescriptionID={id}
        rows={prescription ? prescription.details : []}
        open={isOpen}
        anchorEl={ref.current}
        onClose={handleClose}
      />
    </>
  );
});
