import { useCallback } from 'react';
import { apiTodoTasks, ToDoTaskUpdateInput } from 'services/todo-tasks';
import { useCurrentUser } from '../hooks';
import { ServiceTodoTaskActivities } from 'services/todo-task-activities';
import { useMutationDynamic } from '../../hooks';
import {
  APP_ROUTES,
  PATIENT_CARD_ALL_ACTIVITIES_TABS,
  PATIENT_CARD_MAIN_TABS,
} from 'configs/routes';
import { ServicePushNotifications } from 'services/push-notifications';
import { getTaskCategoryIconURL } from 'services/todo-task-categories';
import { useTranslate } from 'hooks/use-translate';
import { dateFormat } from 'utils/dates';
import { useAppDispatch } from 'store';
import { RTK_TAGS } from 'utils/rtk-query';
import { PatchPartial } from 'utils/types';

export const useToDoTaskUpdated = () => {
  const { appUserID } = useCurrentUser();
  const dispatch = useAppDispatch();
  const onUpdated = useCallback(
    async (newData: PatchPartial<ToDoTaskUpdateInput, 'id'>) => {
      const { id, ...rest } = newData;

      await ServiceTodoTaskActivities.post({
        userEmployeeProfileID: appUserID,
        toDoTaskID: id,
        entryDate: new Date().toISOString(),
        remarks: `AUTOGENERATED: Updated => ${JSON.stringify(rest)}`,
      });

      dispatch(apiTodoTasks.util.invalidateTags([{ type: RTK_TAGS.TODO_TASKS, id: id }]));
    },
    [appUserID, dispatch],
  );

  return useMutationDynamic(onUpdated);
};

const useLazyTaskQuery = apiTodoTasks.useLazyGetTodoTaskDetailsForNotificationQuery;
export const useTodoTaskNotificationCreated = () => {
  const { tp } = useTranslate();
  const [getDetails, resDetails] = useLazyTaskQuery();
  const onSend = useCallback(
    async (options: { id: string }) => {
      const { id } = options;
      const resultDetails = await getDetails(id);

      const details = resultDetails.data;

      if (!details) {
        throw new Error('todo-task: Unexpected behaviour');
      }

      const redirect = APP_ROUTES.PATIENT_LIST({
        patient: String(details.userPatientProfileID),
        'main-tab': PATIENT_CARD_MAIN_TABS.ALL_ACTIVITIES,
        'tab-activities-all': PATIENT_CARD_ALL_ACTIVITIES_TABS.TODO_TASKS,
        task: id,
        source: 'push-noty',
      }).link;

      await Promise.all(
        details.userEmployeeProfileIDs.map((userEmployeeProfileID) => {
          return ServicePushNotifications.sendToEmployee({
            type: 'TASK',
            taskID: id,
            fromUserEmployeeProfileID: String(details.sentByUserEmployeeProfileID),
            toUserEmployeeProfileID: userEmployeeProfileID,
            userPatientProfileID: String(details.userPatientProfileID),
            title: tp('todo-task-title-create', {
              rowIndex: Number(details.rowIndex),
              patient: details.patient,
              category: details.category,
            }),
            message: tp('todo-task-message-create', {
              employee: details.sendBy,
              message: details.message || '',
              entryDate: dateFormat(details.entryDate),
            }),
            redirect,
            icon: getTaskCategoryIconURL(details.category.actionKey),
          });
        }),
      );
    },
    [tp, getDetails],
  );

  return { onSend, isLoading: resDetails.isLoading };
};
export const useTodoTaskNotificationDone = () => {
  const { tp } = useTranslate();
  const { appUserID, name } = useCurrentUser();
  const [getDetails] = useLazyTaskQuery();
  const onSend = useCallback(
    async (options: { id: string; sentByUserEmployeeProfileID: string }) => {
      const { id, sentByUserEmployeeProfileID } = options;
      const resultDetails = await getDetails(id);

      const details = resultDetails.data;

      if (!details) {
        throw new Error('todo-task: Unexpected behaviour');
      }

      const redirect = APP_ROUTES.PATIENT_LIST({
        patient: String(details.userPatientProfileID),
        'main-tab': PATIENT_CARD_MAIN_TABS.ALL_ACTIVITIES,
        'tab-activities-all': PATIENT_CARD_ALL_ACTIVITIES_TABS.TODO_TASKS,
        task: id,
        source: 'push-noty',
      }).link;

      await ServicePushNotifications.sendToEmployee({
        type: 'TASK',
        taskID: id,
        userPatientProfileID: String(details.userPatientProfileID),
        fromUserEmployeeProfileID: appUserID,
        toUserEmployeeProfileID: sentByUserEmployeeProfileID,
        title: tp('todo-task-title-done', {
          rowIndex: Number(details.rowIndex),
          patient: details.patient,
          category: details.category,
        }),
        message: tp('todo-task-message-done', { employee: { fullName: String(name) } }),
        redirect,
        icon: getTaskCategoryIconURL(details.category.actionKey),
      });
    },
    [tp, getDetails, name, appUserID],
  );

  return { onSend };
};
