import React, { useCallback } from 'react';
import { TemplateBadge, TemplateBadgeProps } from 'components/templates/template-badge';
import { useOpen } from 'AurionCR/components/hooks';
import { DialogUserPatientProfilePrescriptionActivities } from '../dialog-activities';
import { useDataGridTasks } from 'components/data-grid/hooks';

export const useTasks = () => useDataGridTasks();

interface Props extends Omit<TemplateBadgeProps, 'count' | 'onClick'> {
  userPatientProfilePrescriptionID?: string | null;
  activities: number;
}
export const ColumnActivities: React.FC<Props> = ({
  activities,
  userPatientProfilePrescriptionID,
  ...rest
}) => {
  const { onTasks } = useTasks();
  const { isOpen, handleOpen, handleClose } = useOpen();
  const onRefresh = useCallback(() => {
    onTasks([['getData']]);
  }, [onTasks]);

  return (
    <>
      <TemplateBadge
        {...rest}
        count={activities}
        onClick={handleOpen}
        disabled={!userPatientProfilePrescriptionID}
      />
      {isOpen && userPatientProfilePrescriptionID && (
        <DialogUserPatientProfilePrescriptionActivities
          userPatientProfilePrescriptionID={userPatientProfilePrescriptionID}
          onClose={handleClose}
          onRefresh={onRefresh}
        />
      )}
    </>
  );
};
