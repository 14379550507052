import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'UserPatientProfilePrescriptionActivities';

export const API_USER_PATIENT_PROFILE_PRESCRIPTION_ACTIVITIES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<IAppUserPatientProfilePrescriptionActivity, 'id'>) =>
    `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<IAppUserPatientProfilePrescriptionActivity, 'id'>) =>
    `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface IAppUserPatientProfilePrescriptionActivity
  extends FixServerObject<
    Components.Schemas.UserPatientProfilePrescriptionActivity,
    'id' | 'userEmployeeProfileID' | 'userPatientProfilePrescriptionID'
  > {}

export interface UserPatientProfilePrescriptionActivityCreateInput {
  userPatientProfilePrescriptionID: string;
  remarks: string;
}
