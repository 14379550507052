import React, { useCallback } from 'react';
import { UserPatientProfileSubscription } from 'services/user-patient-profile-subscriptions';
import { GridRef } from 'models/grid';
import TemplateDownloadPrivate from 'components/templates/template-download-private';
import { useTranslate } from 'hooks/use-translate';
import { SUBSCRIPTION_INDEXES } from 'services/subscription';
import { patientEmitter } from 'components/dialog-patient/emitter';
import { ButtonEndTreatment } from '../../../../components';
import { Stack } from 'components/stack';
import { RegenerateDoc } from '../regenerate-doc';

type Row = Pick<
  UserPatientProfileSubscription,
  'userPatientProfileID' | 'startDate' | 'endDate' | 'endOfTreatmentDocUrl' | 'id' | 'price'
> & { subscriptionRowIndex: SUBSCRIPTION_INDEXES };

interface Props {
  grid: GridRef;
  data: Row;
}

export const ColumnTreatment: React.FC<Props> = ({ data, grid }) => {
  const { userPatientProfileID, endOfTreatmentDocUrl, id } = data;
  const { t } = useTranslate();
  const isConsultation = data.subscriptionRowIndex === SUBSCRIPTION_INDEXES.CONSULTATION;

  const onRefresh = useCallback(() => {
    grid.current?.onTasks([['getData']]);
    patientEmitter.emit('refresh-documents', { patientID: String(userPatientProfileID) });
  }, [grid, userPatientProfileID]);

  if (!data.startDate || !data.endDate) {
    return null;
  }

  if (endOfTreatmentDocUrl) {
    const label = isConsultation
      ? 'download-end-of-treatment-consultation'
      : 'download-end-of-treatment-doc';

    return (
      <Stack justifyContent={'space-between'}>
        <TemplateDownloadPrivate
          documentURL={endOfTreatmentDocUrl}
          style={{ whiteSpace: 'nowrap' }}
        >
          [{t(label)}]
        </TemplateDownloadPrivate>
        <RegenerateDoc
          userPatientProfileID={String(userPatientProfileID)}
          userPatientProfileSubscriptionID={String(id)}
          subscriptionRowIndex={data.subscriptionRowIndex}
          startDate={data.startDate}
          endDate={data.endDate}
          onDone={onRefresh}
          price={data.price || 0}
        />
      </Stack>
    );
  }

  return (
    <ButtonEndTreatment
      userPatientProfileID={String(userPatientProfileID)}
      userPatientProfileSubscriptionID={String(id)}
      subscriptionRowIndex={data.subscriptionRowIndex}
      startDate={data.startDate}
      endDate={data.endDate}
      onDone={onRefresh}
      price={data.price || 0}
    />
  );
};
