import React, { useMemo } from 'react';
import { Box, BoxProps } from '@material-ui/core';
import { intervalToDuration } from 'date-fns';

const zeroPad = (num: number) => String(num).padStart(2, '0');

interface Props extends BoxProps {
  value: number | null | undefined;
}
export const TemplateDuration: React.FC<Props> = ({ value, ...rest }) => {
  const Child = useMemo(() => {
    if (!value) return null;
    const duration = intervalToDuration({ start: 0, end: value * 1000 });
    return ([duration.hours, duration.minutes, duration.seconds] as unknown as number[])
      .map(zeroPad)
      .join(':');
  }, [value]);

  if (!value) {
    return null;
  }

  return <Box {...rest}>{Child}</Box>;
};
