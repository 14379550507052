import { apiRtk } from 'utils/rtk-query';
import { apiSiteParams } from '../site-parameters';
import { ServicePatientPrescription } from '../user-patient-profile-prescriptions';
import { apiDocumentTemplates, CONTENT_KEYS } from '../document-templates';
import { ServicePdf } from '../pdf';

import { apiMediaPrivate, ServiceMediaPrivate } from '../media-private-services';
import { base64ToFileStream, getFileNameFromUrl } from 'utils/file-uploader';
import {
  CreateDocumentPrescriptionGimel29Input,
  CreateDocumentPrescriptionGimel29Output,
  CreateDocumentPrescriptionInput,
  NeopharmGenerateDocumentOutput,
  NeopharmGenerateDocumentsInput,
} from './models';
import { ServicePatientPrescriptionDetails } from '../user-patient-profile-prescription-details';

const _apiServiceNeopharm = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    // private
    _neopharmCreateDocumentPrescription: builder.mutation<string, CreateDocumentPrescriptionInput>({
      queryFn: async (input, { dispatch }) => {
        try {
          const { userPatientProfilePrescriptionID, languageID } = input;

          const reqSiteParams = dispatch(
            apiSiteParams.endpoints.getSiteParamsPdfInfo.initiate(languageID),
          );
          const reqTemplates = dispatch(
            apiDocumentTemplates.endpoints.getDocumentTemplateByContentKey.initiate(
              CONTENT_KEYS.PRESCRIPTION,
            ),
          );

          reqSiteParams.unsubscribe();
          reqTemplates.unsubscribe();

          const [
            siteParams,
            template,
            {
              data: { doctor, remarks, entryDate, drugs, patient },
            },
          ] = await Promise.all([
            reqSiteParams.unwrap(),
            reqTemplates.unwrap(),
            ServicePatientPrescription.getPdfData(userPatientProfilePrescriptionID),
          ]);

          const { data: base64 } = await ServicePdf.generatePrescriptionPharmacy({
            userPatientProfilePrescriptionID,
            htmlTemplate: template.html,
            payload: { siteParams, doctor, remarks, entryDate, drugs, patient },
          });
          const fileName = `neopharm__prescription__${userPatientProfilePrescriptionID}__${new Date().getTime()}.pdf`;

          const {
            data: { filePath },
          } = await ServiceMediaPrivate.uploadFile({
            fileStreamString: base64ToFileStream(base64),
            fileName,
          });

          return { data: filePath };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    // private
    _neopharmCreateDocumentGimel29s: builder.mutation<
      CreateDocumentPrescriptionGimel29Output[],
      CreateDocumentPrescriptionGimel29Input
    >({
      queryFn: async (input, { dispatch }) => {
        try {
          const { userPatientProfilePrescriptionID } = input;
          const reqTemplates = dispatch(
            apiDocumentTemplates.endpoints.getDocumentTemplateByContentKey.initiate(
              CONTENT_KEYS.GIMEL_29,
            ),
          );
          reqTemplates.unsubscribe();
          const [list, { html: htmlTemplate }] = await Promise.all([
            ServicePatientPrescriptionDetails.getGimel29Details(userPatientProfilePrescriptionID),
            reqTemplates.unwrap(),
          ]);

          const res = await Promise.all(
            list.map(async (detail) => {
              const res = await ServicePdf.generateGimel29WithoutWatermark({
                htmlTemplate,
                payload: detail,
              });

              return {
                base64: res.data,
                fileName: `neopharm__prescription__${userPatientProfilePrescriptionID}__detail__${
                  detail.id
                }__${new Date().getTime()}.pdf`,
              };
            }),
          );

          return { data: res };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});

export const apiServiceNeopharm = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    neopharmGenerateDocuments: builder.mutation<
      NeopharmGenerateDocumentOutput,
      NeopharmGenerateDocumentsInput
    >({
      queryFn: async (input, { dispatch }) => {
        try {
          const reqPrescriptionDoc = dispatch(
            _apiServiceNeopharm.endpoints._neopharmCreateDocumentPrescription.initiate(input),
          );
          const reqGimel29Docs = dispatch(
            _apiServiceNeopharm.endpoints._neopharmCreateDocumentGimel29s.initiate(input),
          );
          const reqDoc = dispatch(
            apiMediaPrivate.endpoints.getMediaPrivateAsBase64.initiate(
              input.prescriptionPrintFilePath,
              { forceRefetch: true },
            ),
          );
          reqDoc.unsubscribe();

          const [resPrescriptionDoc, resGimel29Docs, resDoc] = await Promise.all([
            reqPrescriptionDoc.unwrap(),
            reqGimel29Docs.unwrap(),
            reqDoc.unwrap(),
          ]);

          return {
            data: {
              prescriptionFilePath: resPrescriptionDoc,
              files: [
                {
                  fileName: getFileNameFromUrl(input.prescriptionPrintFilePath),
                  fileStreamString: base64ToFileStream(resDoc),
                  isForm29: false,
                },
                ...resGimel29Docs.map((gimel) => {
                  return {
                    fileName: gimel.fileName,
                    fileStreamString: base64ToFileStream(gimel.base64),
                    isForm29: true,
                  };
                }),
              ],
            },
          };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
