import React from 'react';
import { SUBSCRIPTION_INDEXES } from 'services/subscription';
import { IconButton, Tooltip } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import { useOpen } from 'AurionCR/components/hooks';
import { DialogEndTreatmentConsultationContainer } from '../../../../components/end-treatment/components/dialog-treatment-consultation-container';
import { DialogEndTreatment } from '../../../../components/end-treatment/components/dialog-end-treatment';
import { composeFunctions } from 'utils';
import { useTranslate } from 'hooks/use-translate';

interface Props {
  userPatientProfileSubscriptionID: string;
  userPatientProfileID: string;
  subscriptionRowIndex: SUBSCRIPTION_INDEXES;
  startDate: string;
  endDate: string;
  price: number;
  onDone: () => void;
}
export const RegenerateDoc: React.FC<Props> = ({
  subscriptionRowIndex,
  onDone,
  userPatientProfileSubscriptionID,
  userPatientProfileID,
  startDate,
  endDate,
  price,
}) => {
  const { tp } = useTranslate();
  const { isOpen, handleOpen, handleClose } = useOpen();

  const Dialog =
    subscriptionRowIndex === SUBSCRIPTION_INDEXES.CONSULTATION
      ? DialogEndTreatmentConsultationContainer
      : DialogEndTreatment;
  return (
    <>
      <Tooltip title={tp('regenerate-document')}>
        <IconButton onClick={handleOpen}>
          <CachedIcon />
        </IconButton>
      </Tooltip>
      {isOpen && (
        <Dialog
          price={price}
          userPatientProfileSubscriptionID={userPatientProfileSubscriptionID}
          userPatientProfileID={userPatientProfileID}
          startDate={startDate}
          endDate={endDate}
          onClose={handleClose}
          onDone={composeFunctions(onDone, handleClose)}
        />
      )}
    </>
  );
};
