import React from 'react';
import { ButtonProps, Button } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';

interface Props extends ButtonProps {
  isBack: boolean;
}
export const ButtonCancel: React.FC<Props> = ({ isBack, ...rest }) => {
  const { t } = useTranslate();

  return (
    <Button
      children={t(isBack ? 'back' : 'cancel')}
      variant={'text'}
      color={'secondary'}
      {...rest}
    />
  );
};
