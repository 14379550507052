import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
//@ts-ignore
import queryString from 'query-string';

export const useSearchParams = <T extends { [x: string]: string | number | null | undefined } = any>(): { query: Partial<T>, replace: (replacedQuery: T) => void } => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();

  const query = useMemo(() => {
    return {
      ...queryString.parse(location.search),
      ...params,
    };
  }, [params, location.search]);

  const replace = useCallback((replacedQuery: T) => {
    const searchParams = new URLSearchParams(window.location.search);

    Object.entries(replacedQuery).forEach(([key, value]) => {
      const hasParam = searchParams.has(key);

      if (hasParam && value !== null && value !== '') {
        searchParams.set(key, String(value));
      } else if (hasParam && (value === null || value === '')) {
        searchParams.delete(key);
      } else if (!hasParam && value !== null && value !== '') {
        searchParams.set(key, String(value));
      }
    });
    history.replace({
      pathname: window.location.pathname,
      search: `?${searchParams}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return useMemo(() => {
    return {
      query,
      replace,
    };
  }, [query, replace]);
};
