import React, { memo, useCallback, useMemo } from 'react';
import {
  apiClinicalMeetings,
  ClinicalMeeting,
  ClinicalMeetingCreateModel,
} from 'services/clinical-meetings';
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  LinearProgress,
} from '@material-ui/core';
import { useEffectError, useFieldProps, useMutationDynamic } from 'hooks';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ClinicalMeetingFormNew } from '../form-new';
import { SelectMeetingType } from 'components/select-meeting-type';
import CloseIcon from '@material-ui/icons/Close';

import { useCurrentUser, useSourceClinicalMeetingSubjects } from 'components/hooks';
import { convertToDate, DateValue } from 'utils/dates';
import { MEETING_TYPE_KEY } from 'services/clinical-meeting-types';
import { validateRule } from 'AurionCR/components/formV2';
import { useClinicalMeetingSendMessageToPatient } from '../../hooks';
import { useAppDispatch, store } from 'store';
import { actionClinicalMeetingActivitiesCrateLogCreated } from 'store/clinical-meeting-activities';
import { ClinicalMeetingFormModel, ClinicalMeetingMeta } from '../../models';
import { ButtonCancel, ButtonSave } from 'components/app-buttons';
import style from './index.module.scss';

const createClinicalMeeting = async (data: Omit<ClinicalMeeting, 'id'>) => {
  const result = await store
    .dispatch(apiClinicalMeetings.endpoints.createClinicalMeeting.initiate(data))
    .unwrap();

  return { data: result };
};

interface FactorySubmitOptions {
  userEmployeeProfileID: string;
  meetingRange: DateValue[] | null | undefined;
  isSendSms: boolean;
}

type PredefinedValues = Pick<ClinicalMeeting, 'clinicalMeetingSubjectID' | 'userPatientProfileID'>;
interface Props {
  onClose: () => void;
  onComplete: () => void;
  initData?: Partial<ClinicalMeetingFormModel>;
  meetingTypeKey?: MEETING_TYPE_KEY;
  times: { id: string }[];
  predefinedValues?: Partial<PredefinedValues>;
}

export const DialogClinicalMeetingNew = memo<Props>(
  ({ initData, onComplete, onClose, meetingTypeKey, times, predefinedValues }) => {
    const { appUserID } = useCurrentUser();

    const dispatch = useAppDispatch();
    const { showSendSmsDialog } = useClinicalMeetingSendMessageToPatient();

    const defaultValues = useMemo(() => {
      const primary = {
        ...new ClinicalMeetingCreateModel(),
        organizerUserEmployeeProfileID: appUserID,
        ...initData,
        isSendSmsDoctor: true,
        isSendSmsEmployee: true,
        ...predefinedValues,
      };

      return { ...primary, clinicalMeetingSubjectID: primary.clinicalMeetingSubjectID || '' };
    }, [initData, predefinedValues, appUserID]);
    const formMethods = useForm<ClinicalMeetingFormModel>({
      defaultValues,
    });

    const meta = useMemo<ClinicalMeetingMeta>(
      () => ({
        disablePatient: !!predefinedValues?.userPatientProfileID,
        disableSubject: !!predefinedValues?.clinicalMeetingSubjectID,
      }),
      [predefinedValues],
    );

    const { handleSubmit, control, errors, register } = formMethods;

    const tryToComplete = useCallback(() => {
      onComplete();
      onClose();
    }, [onClose, onComplete]);

    const submitExecutor = useCallback(
      async (arg: any, options: FactorySubmitOptions) => {
        if (!options.userEmployeeProfileID) {
          return null;
        }
        if (!options.meetingRange) {
          return null;
        }
        if (options.meetingRange.length !== 2) {
          return null;
        }

        const payload = {
          userEmployeeProfileID: options.userEmployeeProfileID,
          meetingFromDateTime: convertToDate(options.meetingRange[0]).toISOString(),
          meetingToDateTime: convertToDate(options.meetingRange[1]).toISOString(),
        };

        try {
          const result = await createClinicalMeeting({
            ...arg,
            ...payload,
            date: undefined,
          });

          const clinicalMeetingID = String(result.data.id);

          showSendSmsDialog({ clinicalMeetingID });

          return result.data;
        } catch (e) {
          throw e;
        }
      },
      [showSendSmsDialog],
    );

    const requestPostItem = useCallback(
      async (data: ClinicalMeetingFormModel) => {
        const {
          doctorID,
          meetingDateTimeDoctor,
          isSendSmsDoctor,

          userEmployeeProfileID,
          meetingDateTimeUserEmployeeProfile,
          isSendSmsEmployee,

          ...rest
        } = data;

        const [doctorResult, employeeResult] = await Promise.all([
          submitExecutor(rest, {
            userEmployeeProfileID: doctorID,
            meetingRange: meetingDateTimeDoctor,
            isSendSms: isSendSmsDoctor,
          }),
          submitExecutor(rest, {
            userEmployeeProfileID: userEmployeeProfileID,
            meetingRange: meetingDateTimeUserEmployeeProfile,
            isSendSms: isSendSmsEmployee,
          }),
        ]);

        if (doctorResult && doctorResult.id) {
          dispatch(
            actionClinicalMeetingActivitiesCrateLogCreated({
              clinicalMeetingID: doctorResult.id,
            }),
          );
        }

        if (employeeResult && employeeResult.id) {
          dispatch(
            actionClinicalMeetingActivitiesCrateLogCreated({
              clinicalMeetingID: employeeResult.id,
            }),
          );
        }

        tryToComplete();
      },
      [submitExecutor, tryToComplete, dispatch],
    );
    const [postItem, { error: errorPost, isLoading: isLoadingPost }] =
      useMutationDynamic(requestPostItem);
    useEffectError(errorPost);

    const onSubmit = postItem;

    const getFieldProps = useFieldProps({ errors });

    const sourceMeetingSubjects = useSourceClinicalMeetingSubjects();

    const isLoading = isLoadingPost;

    return (
      <>
        <Dialog open={true} onClose={onClose} fullWidth maxWidth={'sm'}>
          <input {...register('id')} hidden />
          <Controller
            control={control}
            name={'clinicalMeetingSubjectID'}
            rules={validateRule('required')}
            render={(renderProps) => {
              return (
                <SelectMeetingType
                  {...getFieldProps(renderProps)}
                  disabled={meta.disableSubject}
                  options={sourceMeetingSubjects.data}
                  loading={sourceMeetingSubjects.loading}
                  endIcon={
                    <IconButton
                      className={style.close}
                      style={{ color: 'white' }}
                      onClick={onClose}
                    >
                      <CloseIcon color={'inherit'} />
                    </IconButton>
                  }
                />
              );
            }}
          />
          {isLoading && <LinearProgress />}
          <DialogContent style={{ position: 'relative' }}>
            <FormProvider {...formMethods}>
              <ClinicalMeetingFormNew
                meta={meta}
                meetingTypeKey={meetingTypeKey}
                isLoading={isLoading}
                times={times}
              />
            </FormProvider>
          </DialogContent>
          <DialogActions>
            <ButtonCancel isBack={false} disabled={isLoading} onClick={onClose} />
            <ButtonSave isCreate={true} disabled={isLoading} onClick={handleSubmit(onSubmit)} />
          </DialogActions>
        </Dialog>
      </>
    );
  },
);
