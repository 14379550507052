const PATTERN_EMAIL = '[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,4}';

const PATTERN_PHONE = '\\d{3}-\\d{7}';

const PATTERN_BIRTH_DAY = '\\d{2}\\/\\d{2}\\/\\d{4}';

export const REGEXP_PATIENT_PHONE = /^05\d-\d{7}$/;
export const REGEXP_PATIENT_ID_NUMBER = /^\d{8,9}$/;
export const REGEXP_PATIENT_PASSPORT_NUMBER = /^[a-zA-Z0-9]{0,10}$/;

export const isLabelLike = (text: string | null | undefined) => {
  if (!text) return false;
  return new RegExp(/^([a-z]+-?)+[a-z]+$/, 'g').test(text);
};
export const findEmail = (text: string) => {
  const regexp = new RegExp(`${PATTERN_EMAIL}`, 'ig');
  const matches = text.match(regexp);
  return matches ? matches[0] : undefined;
};

export const findBirthDay = (text: string) => {
  const regexp = new RegExp(`${PATTERN_BIRTH_DAY}`, 'ig');
  const matches = text.match(regexp);
  return matches ? matches[0] : undefined;
};

export const findMobilePhone = (text: string) => {
  const regexp = new RegExp(`${PATTERN_PHONE}`, 'ig');
  const matches = text.match(regexp);
  return matches ? matches[0] : undefined;
};
