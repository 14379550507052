import React, { useCallback, useMemo } from 'react';

import { getSearchFilter } from 'utils/front-filters';
import { Chip, Typography } from '@material-ui/core';
import { Stack } from 'components/stack';
import style from './index.module.scss';
import { AppSelect, AppSelectProps } from 'components/app-select';
import { ListboxComponent } from 'components/app-select/components';
import { apiDrugs } from 'services/drug';
import { useTranslate } from 'hooks/use-translate';

type Option = {
  id: string | null | undefined;
  catalogName: string | null | undefined;
  drugName: string | null | undefined;
  drugNameEng: string | null | undefined;
  highlight: boolean | null | undefined;
};

interface OptionComponentProps {
  title: string | null | undefined;
  highlight: boolean | null | undefined;
}
const OptionComponent: React.FC<OptionComponentProps> = ({ title, highlight }) => {
  const { t } = useTranslate();
  return (
    <Stack className={style.item} spacing={1}>
      <Typography noWrap className={style.itemTitle} title={title ?? undefined}>
        {title}
      </Typography>
      {highlight && <Chip size="small" label={t('highlight')} color={'primary'} />}
    </Stack>
  );
};

const renderOption = (option: Option) => {
  return <OptionComponent title={option.catalogName} highlight={option.highlight} />;
};
const filterOptions = <T extends Option>(options: T[], state: any) => {
  return options.filter(
    getSearchFilter<T>({
      value: state.inputValue,
      fields: ['catalogName', 'drugNameEng', 'drugName'],
    }),
  );
};

const ModifiedListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>((props, ref) => <ListboxComponent {...props} ref={ref} renderOption={renderOption as any} />);

interface Props<
  T extends Record<string, any> = { id: string; title: string; isActive?: boolean },
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
> extends Partial<AppSelectProps<T, Multiple, DisableClearable, FreeSolo>> {
  source?: T[];
  disabled?: boolean;
  loading?: boolean;
  label?: React.ReactNode;
}

export const SelectDrugs = <
  T extends Option,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>({
  loading,
  ...rest
}: Props<T, Multiple, DisableClearable, FreeSolo>) => {
  const source = apiDrugs.useGetSourceDrugsQuery();
  const options = useMemo(() => {
    return source.data || [];
  }, [source.data]);

  const getOptionLabel = useCallback((option: Option) => {
    return option.catalogName || '';
  }, []);

  return (
    <AppSelect
      filterOptions={filterOptions}
      options={options as T[]}
      renderOption={renderOption}
      ListboxComponent={ModifiedListboxComponent}
      getOptionLabel={getOptionLabel}
      {...rest}
      disabled={rest.disabled}
      loading={source.isFetching || loading}
    />
  );
};
