const API_ENDPOINT = 'Subscriptions';

export const API_SUBSCRIPTIONS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<Subscription, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<Subscription, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export enum SUBSCRIPTION_INDEXES {
  CONSULTATION = 8,
}

export interface Subscription extends Components.Schemas.Subscription {
  rowIndex?: SUBSCRIPTION_INDEXES;
}
