import React, { useCallback } from 'react';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { useTranslate } from 'hooks/use-translate';
import { NotebookFormSettings } from '../../models';
import { useFieldProps } from 'hooks';
import { SelectIcd10 } from 'components/select-icd10';
import { SelectDrugs } from 'components/select-drugs';
import { NotebookDynamicExtraFields } from '../../hooks';
import { AppInput } from 'components/app-input';

interface Props {
  notebookSettings: NotebookFormSettings;
  disabled: boolean;
}
export const FormEffects: React.FC<Props> = ({ notebookSettings, disabled }) => {
  const { t } = useTranslate();
  const { control, errors } = useFormContext<NotebookDynamicExtraFields>();
  const getFieldProps = useFieldProps({ errors, emptyHelperText: '', disabled });

  const renderRemarks = useCallback(
    (renderProps: ControllerRenderProps<any>) => {
      return <AppInput {...getFieldProps(renderProps)} multiline={true} maxRows={3} />;
    },
    [getFieldProps],
  );

  return (
    <>
      {notebookSettings.displayIcd10Section && (
        <>
          <Controller
            name={'icd10IDs'}
            control={control}
            render={(renderProps) => {
              return (
                <SelectIcd10 {...getFieldProps(renderProps)} multiple={true} label={t('icd10s')} />
              );
            }}
          />
          <Controller name={'icd10Remarks'} control={control} render={renderRemarks} />
        </>
      )}
      {notebookSettings.displaySideEffectSection && (
        <>
          <Controller
            name={'sideEffectIDs'}
            control={control}
            render={(renderProps) => {
              return (
                <SelectIcd10
                  {...getFieldProps(renderProps)}
                  multiple={true}
                  label={t('side-effects')}
                />
              );
            }}
          />
          <Controller name={'sideEffectRemarks'} control={control} render={renderRemarks} />
        </>
      )}
      {notebookSettings?.displaySurgerySection && (
        <>
          <Controller
            name={'surgeryIDs'}
            control={control}
            render={(renderProps) => {
              return (
                <SelectIcd10
                  {...getFieldProps(renderProps)}
                  multiple={true}
                  label={t('surgeries')}
                />
              );
            }}
          />
          <Controller name={'surgeryRemarks'} control={control} render={renderRemarks} />
        </>
      )}
      {notebookSettings.displaySensitivitySection && (
        <>
          <Controller
            name={'sensitivityIDs'}
            control={control}
            render={(renderProps) => {
              return (
                <SelectIcd10
                  {...getFieldProps(renderProps)}
                  multiple={true}
                  label={t('sensitivities')}
                />
              );
            }}
          />
          <Controller name={'sensitivityRemarks'} control={control} render={renderRemarks} />
        </>
      )}
      {notebookSettings.displaySensitivityDrugSection && (
        <>
          <Controller
            name={'sensitivityDrugIDs'}
            control={control}
            render={(renderProps) => {
              return <SelectDrugs {...getFieldProps(renderProps)} multiple={true} />;
            }}
          />
          <Controller name={'sensitivityDrugRemarks'} control={control} render={renderRemarks} />
        </>
      )}
      {notebookSettings.displayRoutineDrugSection && (
        <>
          <Controller
            name={'regularDrugIDs'}
            control={control}
            render={(renderProps) => {
              return (
                <SelectDrugs
                  {...getFieldProps(renderProps)}
                  multiple={true}
                  label={t('routine-drugs')}
                />
              );
            }}
          />
          <Controller name={'routineDrugRemarks'} control={control} render={renderRemarks} />
        </>
      )}
      {notebookSettings.displayPastDrugForObesitySection && (
        <>
          <Controller
            name={'pastDrugIDs'}
            control={control}
            render={(renderProps) => {
              return (
                <SelectDrugs
                  {...getFieldProps(renderProps)}
                  multiple={true}
                  label={t('past-drug-for-obesity')}
                />
              );
            }}
          />
          <Controller name={'pastDrugForObesityRemarks'} control={control} render={renderRemarks} />
        </>
      )}
    </>
  );
};
