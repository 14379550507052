import { UserEmployeeProfileSchedule } from 'services/user-employee-profile-schedules';
import { UserEmployeeProfileAbsence } from 'services/user-employee-profile-absence';
import { UserEmployeeProfileWorkLogs } from 'services/user-employee-profile-work-logs';
import { FixServerObject } from 'utils/types';
import { DateValue } from 'utils/dates';
import { DynamicParams } from 'utils/service';

const API_ENDPOINT = 'UserEmployeeProfiles';

export const API_USER_EMPLOYEE_PROFILES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  GET: (data: Pick<UserEmployeeProfile, 'id'>) => `${API_ENDPOINT}/Get/${data.id}`,
  PATCH: (data: Pick<UserEmployeeProfile, 'appIdentityUserID'>) =>
    `${API_ENDPOINT}/Patch/${data.appIdentityUserID}`,
  DELETE: (data: Pick<UserEmployeeProfile, 'appIdentityUserID'>) =>
    `${API_ENDPOINT}/Delete/${data.appIdentityUserID}`,
};

export interface UserEmployeeProfile
  extends FixServerObject<Components.Schemas.UserEmployeeProfile, 'appIdentityUserID'> {}

export const DOCTOR_RAZ_ID = '185c3868-e97a-4c07-aec5-a15a726b01c3';

export interface EmployeeActivitiesMonitorItem
  extends FixServerObject<
    Pick<
      Components.Schemas.UserEmployeeProfile,
      | 'appIdentityUserID'
      | 'firstName'
      | 'lastName'
      | 'userPhoto'
      | 'lastActivityDate'
      | 'mobilePhone'
      | 'id'
    >,
    'appIdentityUserID' | 'id'
  > {
  workLogs: Pick<UserEmployeeProfileWorkLogs, 'fromTime' | 'toTime' | 'isInClinic'>[];
  absences: Pick<UserEmployeeProfileAbsence, 'eventDate'>[];
  schedules: Pick<UserEmployeeProfileSchedule, 'id' | 'fromTime' | 'toTime' | 'isInClinic'>[];
}

export interface GetEmployeesDailyAvailabilityDynamicInput extends DynamicParams {
  date: DateValue;
  dateRange?: [DateValue, DateValue];
}
export interface DailyAvailabilitySlot {
  fromTime: DateValue;
  toTime: DateValue;
  isInClinic: boolean;
}
export type EmployeesDailyAvailability<T> = T & {
  workLogs: Pick<
    UserEmployeeProfileWorkLogs,
    'id' | 'dayOfWeek' | 'fromTime' | 'toTime' | 'isInClinic'
  >[];
  absences: Pick<UserEmployeeProfileAbsence, 'id' | 'eventDate'>[];
  schedules: Pick<
    UserEmployeeProfileSchedule,
    'id' | 'date' | 'fromTime' | 'toTime' | 'isInClinic'
  >[];
};

export type GetSourceEmployeeDietitiansDailyAvailabilityInput = {
  date: DateValue;
};

export interface SourceEmployeeDietitiansDailyAvailabilityItem
  extends Pick<
    UserEmployeeProfile,
    'firstName' | 'lastName' | 'userPhoto' | 'userEmployeeProfilePermissionID'
  > {
  id: UserEmployeeProfile['appIdentityUserID'];
  clinicalMeetings: Array<{ meetingFromDateTime: string; meetingToDateTime: string }>;
}

export interface MyTask {
  id: string;
  isUrgent: boolean;
}
