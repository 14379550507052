import React from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { Box, Link, Typography } from '@material-ui/core';
import image from 'images/aurion-logo-candies.png';
import logo from 'images/aurion-logo-blue.svg';

interface Classes {
  root: string;
}

interface Props {
  className?: string;
  classes?: Partial<Classes>;
}

export const PoweredBy: React.FC<Props> = ({ className, classes, children }) => {
  return (
    <div className={clsx(style.root, classes?.root, className)}>
      <div>
        {children}
        <div className={style.imageBox}>
          <img src={image} alt={'aurion-digital'} style={{ width: '4.4rem' }} />
        </div>
      </div>
      <Box textAlign={'center'}>
        <Link
          underline={'none'}
          target={'_blank'}
          href={process.env.REACT_APP_URL_POWERED_BY}
          className={style.link}
        >
          <Typography
            color={'inherit'}
            component={'div'}
            variant={'caption'}
            className={style.title}
          >
            Powered By
          </Typography>
          <img
            src={logo}
            alt={'aurion-digital'}
            style={{ width: '10rem', display: 'block', margin: '0 auto' }}
          />
        </Link>
      </Box>
    </div>
  );
};
