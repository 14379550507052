import React, { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import {
  actionDietDiariesReportLoadData,
  dietDiariesReportActions,
  selectDietDiaryReportFilters,
} from '../../store';
import { useFilterForm } from 'hooks/use-filter-form';
import { FormLabel, Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { AppInput } from 'components/app-input';
import { useFieldProps } from 'hooks';
import { DateMultiple } from 'AurionCR/components/form';
import { SelectEmployee } from 'components/select-employee';
import { SelectPatients } from 'components/select-patients';
import { DietDiariesReportFilters, ViewOptions } from '../../models';
import { composeFunctions } from 'utils';
import { Stack } from 'components/stack';
import { ButtonSwitcher } from 'components/button-switcher';
import { getHandlerKayPress } from 'utils/handlers';
import { enumToArray } from 'utils/other';
import { useTranslate } from 'hooks/use-translate';

export const Filters: React.FC = () => {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();
  const defaultValues = useAppSelector(selectDietDiaryReportFilters);
  const onUpdate = useCallback(
    (formData: DietDiariesReportFilters) => {
      dispatch(dietDiariesReportActions.setFilters(formData));
      dispatch(dietDiariesReportActions.setPagination({ page: 1 }));
      dispatch(actionDietDiariesReportLoadData());
    },
    [dispatch],
  );

  const { control, errors, onCheckAndUpdate } = useFilterForm({
    defaultValues,
    onUpdate,
  });

  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

  const viewOptions = useMemo(() => {
    return enumToArray(ViewOptions).map(({ id }) => {
      return { value: id, title: t(id) };
    });
  }, [t]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
        <Controller
          control={control}
          name={'search'}
          render={(renderProps) => {
            const props = getFieldProps(renderProps);
            return <AppInput {...props} onKeyUp={getHandlerKayPress('Enter', onCheckAndUpdate)} />;
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
        <Controller
          control={control}
          name={'dataRange'}
          render={(renderProps) => {
            const props = getFieldProps(renderProps);
            return (
              <DateMultiple
                {...props}
                onChange={composeFunctions(props.onChange, onCheckAndUpdate)}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
        <Controller
          control={control}
          name={'assignedToEmployeeID'}
          render={(renderProps) => {
            const props = getFieldProps(renderProps);
            return (
              <SelectEmployee
                {...props}
                onChange={composeFunctions(props.onChange, onCheckAndUpdate)}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12} sm={true} md={true} lg={true} xl={true}>
        <Controller
          control={control}
          name={'patientIDs'}
          render={(renderProps) => {
            const props = getFieldProps(renderProps);
            return (
              <SelectPatients
                {...props}
                multiple={true}
                onChange={composeFunctions(props.onChange, onCheckAndUpdate)}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={'auto'}>
        <Controller
          control={control}
          name={'isViewed'}
          render={(renderProps) => {
            const props = getFieldProps(renderProps);
            return (
              <Stack direction={'column'} spacing={1}>
                <FormLabel>{props.label}</FormLabel>
                <ButtonSwitcher
                  options={viewOptions}
                  value={renderProps.value}
                  onChange={composeFunctions(renderProps.onChange, onCheckAndUpdate)}
                  ButtonItemProps={{
                    size: 'small',
                  }}
                />
              </Stack>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};
