import { DateValue } from 'utils/dates';
import { UserEmployeeProfile } from 'services/user-employee-profiles';

const API_ENDPOINT = 'UserPatientProfileMedicalInformations';

export const API_USER_PATIENT_PROFILE_MEDICAL_INFORMATIONS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<MedicalInformations, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<MedicalInformations, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface MedicalInformations
  extends Components.Schemas.UserPatientProfileMedicalInformation {}

export interface MedicalInformationsGrid extends MedicalInformations {
  'userEmployeeProfile.firstName': '';
  'userEmployeeProfile.lastName': '';
}

export interface UserMedicalInformationGetActivitiesInput {
  userPatientProfileID: string;
  dateRange: DateValue[];
}
export interface UserMedicalInformationGetActivitiesItem
  extends Pick<MedicalInformations, 'id' | 'expirationDate' | 'remarks'> {
  employee: UserEmployeeProfile['fullName'];
}
