import React, { useMemo } from 'react';
import {
  usePatientSubscription,
  usePatientSubscriptionStatus,
} from 'components/dialog-patient/hooks';
import { dateFormat } from 'utils/dates';
import { useTranslate } from 'hooks/use-translate';
import { Tooltip } from '@material-ui/core';
import { ShortRemark } from 'components/short-remark';
const EMPTY = '-';

interface Props {
  userPatientProfileID: string;
}
export const MedicalPrescriptionProgramInfoDate: React.FC<Props> = ({ userPatientProfileID }) => {
  const { tp } = useTranslate();
  const { data: subscription } = usePatientSubscription(userPatientProfileID);

  const endDate = useMemo(() => {
    if (!subscription) return EMPTY;
    if (!subscription.endDate) return EMPTY;

    return dateFormat(subscription.endDate);
  }, [subscription]);

  const { isEnd, isCloseToEnd, notifyMessage } = usePatientSubscriptionStatus(subscription);

  const isError = isEnd || isCloseToEnd;

  return (
    <Tooltip title={notifyMessage}>
      <ShortRemark priority={isError ? 'highest' : 'medium'}>
        {tp('program-info-end-date')} {endDate}
      </ShortRemark>
    </Tooltip>
  );
};
