import { FixServerObject } from 'utils/types';

const API_ENDPOINT = 'ClinicalMeetingSubjects';

export const API_CLINICAL_MEETING_SUBJECTS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<ClinicalMeetingSubject, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<ClinicalMeetingSubject, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface ClinicalMeetingSubject
  extends FixServerObject<
    Components.Schemas.ClinicalMeetingSubject,
    | 'id'
    | 'title'
    | 'icon'
    | 'color'
    | 'defaultDuration'
    | 'nextMeetingGapDuration'
    | 'defaultDurationNotDoctor'
  > {}

export enum CLINICAL_MEETING_SUBJECTS_IDS {
  END_OF_TREATMENT = 'a86985c9-c168-44ca-a0b0-0d515c3cbe98',
  CLOSED = 'b945514d-dc0b-4cad-8a02-fe594edfd7a8',
}

export enum CLINICAL_MEETING_SUBJECT_TYPE {
  CANCEL = 1,
  OUT_OF_SCHEDULE,
  NOT_SHOWING_UP_MEETING,
  END_OF_TREATMENT,
}
