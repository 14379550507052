import { FormDocument } from 'services/form-documents';

const API_ENDPOINT = 'FormDocumentInputs';

export const API_FORM_DOCUMENT_INPUTS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: { id: FormDocumentInput['id'] }) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: { id: FormDocumentInput['id'] }) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export enum FormDocumentFieldType {
  text,
  date,
  checkbox,
  signature,
  IDNumber,
}

export enum FormDocumentFieldInputID {
  firstName = 'firstName',
  lastName = 'lastName',
  fullName = 'patientName',
  idNumber = 'idNumber',
  dateOfBirth = 'dateOfBirth',
  mobilePhone = 'mobilePhone',
  email = 'email',
  familyMemberName = 'familyMemberName',
  familyMemberFirstName = 'familyMemberFirstName',
  familyMemberLastName = 'familyMemberLastName',
  familyMemberPhone = 'familyMemberPhone',
  familyMemberIDNumber = 'familyMemberIDNumber',
  familyMemberRelationship = 'familyMemberRelationship',
  address = 'address',
  signature = 'signature',
  startDate = 'startDate',
  endDate = 'endDate',
  docDate = 'docDate',
  docTime = 'docTime',
  checkBox = 'checkBox',
}
export interface FormDocumentInput {
  id: string;
  rowIndex?: number;
  createdDate?: string;
  updatedDate?: string;
  isActive?: boolean;

  inputID: FormDocumentFieldInputID;
  title: string;
  formDocumentFieldType: FormDocumentFieldType;
  coordX: number;
  coordY: number;
  width: number;
  height: number;
  defaultValue?: string | boolean | null;
  rank?: number;
  pageNumber: number;
  maxLength?: number;
  isRequired: boolean;
  fontFamily: string;
  fontSize: number;
  background: string;

  formDocumentID?: string;
  formDocument?: FormDocument;
}
