import React, { useCallback, useMemo } from 'react';
import { useEffectError, useOpenValue } from 'hooks';
import { useTranslate } from 'hooks/use-translate';
import {
  apiUserPatientProfileSessions,
  LatestSessionWithItems,
} from 'services/user-patient-profile-session';
import { DialogUserPatientProfileSession } from 'components/dialoog-user-patient-profile-session';
import { NativeScroll } from 'components/native-scroll';
import style from './index.module.scss';
import { PreviewSessionEffectsList } from '../preview-session-effects-list';
import { DataGridEmpty } from 'components/data-grid/components/data-grid-empty';

const adapterSession = (item: LatestSessionWithItems) => {
  return {
    id: String(item.id),
    notebook: item.notebook.labelKey,
    employee: item.userEmployeeProfile.fullName,
    date: item.createdDate,
    items: item.previewItems,
    remarks: item.remarks,
  };
};

interface Props {
  userPatientProfileID: string;
  userPatientProfileSessionID: string | null;
}

export const LatestNotebookData: React.FC<Props> = ({
  userPatientProfileSessionID,
  userPatientProfileID,
}) => {
  const { t } = useTranslate();

  const payload = {
    userPatientProfileID,
    userPatientProfileSessionID,
  };

  const { openValue, onOpen, onClose } = useOpenValue<string>();

  const resultIcd10s = apiUserPatientProfileSessions.useGetAllSessionWithIcd10sQuery(payload);
  useEffectError(resultIcd10s.error);
  const icd10List = useMemo(() => {
    return (resultIcd10s.data || []).map(adapterSession);
  }, [resultIcd10s.data]);
  const [triggerUpdateIcd10, resultUpdateIcd10] =
    apiUserPatientProfileSessions.useActivateOrDeactivateSessionIcd10Mutation();
  useEffectError(resultUpdateIcd10.error);
  const onSwitchIcd10 = useCallback(
    (value: boolean, item: { id: string }) => {
      triggerUpdateIcd10({ id: item.id, userPatientProfileID, isActive: value });
    },
    [userPatientProfileID, triggerUpdateIcd10],
  );

  const resultSideEffects =
    apiUserPatientProfileSessions.useGetAllSessionWithSideEffectsQuery(payload);
  useEffectError(resultSideEffects.error);
  const sideEffectList = useMemo(() => {
    return (resultSideEffects.data || []).map(adapterSession);
  }, [resultSideEffects.data]);

  const resultSurgeries = apiUserPatientProfileSessions.useGetAllSessionWithSurgeriesQuery(payload);
  useEffectError(resultSurgeries.error);
  const surgeryList = useMemo(() => {
    return (resultSurgeries.data || []).map(adapterSession);
  }, [resultSurgeries.data]);

  const resultSensitivities =
    apiUserPatientProfileSessions.useGetAllSessionWithSensitivitiesQuery(payload);
  useEffectError(resultSensitivities.error);
  const sensitivityList = useMemo(() => {
    return (resultSensitivities.data || []).map(adapterSession);
  }, [resultSensitivities.data]);

  const resultSensitivityDrugs =
    apiUserPatientProfileSessions.useGetAllSessionWithSensitivityDrugsQuery(payload);
  useEffectError(resultSensitivityDrugs.error);
  const sensitivityDrugList = useMemo(() => {
    return (resultSensitivityDrugs.data || []).map(adapterSession);
  }, [resultSensitivityDrugs.data]);

  const resultRoutineDrugs =
    apiUserPatientProfileSessions.useGetAllSessionWithRoutineDrugsQuery(payload);
  useEffectError(resultRoutineDrugs.error);
  const [triggerUpdateRoutineDrug, resultUpdateRoutineDrug] =
    apiUserPatientProfileSessions.useActivateOrDeactivateSessionRoutineDrugMutation();
  useEffectError(resultUpdateRoutineDrug.error);
  const routineDrugList = useMemo(() => {
    return (resultRoutineDrugs.data || []).map(adapterSession);
  }, [resultRoutineDrugs.data]);
  const onSwitchRoutineDrugs = useCallback(
    (value: boolean, item: { id: string }) => {
      triggerUpdateRoutineDrug({ id: item.id, userPatientProfileID, isActive: value });
    },
    [userPatientProfileID, triggerUpdateRoutineDrug],
  );

  const resultPastDrugs = apiUserPatientProfileSessions.useGetAllSessionWithPastDrugsQuery(payload);
  useEffectError(resultPastDrugs.error);
  const pastDrugList = useMemo(() => {
    return (resultPastDrugs.data || []).map(adapterSession);
  }, [resultPastDrugs.data]);

  const isLoading =
    resultIcd10s.isLoading ||
    resultSideEffects.isLoading ||
    resultSurgeries.isLoading ||
    resultSensitivities.isLoading ||
    resultSensitivityDrugs.isLoading ||
    resultRoutineDrugs.isLoading ||
    resultPastDrugs.isLoading;

  const isEmpty = useMemo(() => {
    if (isLoading) return false;
    return [
      resultIcd10s.data?.length === 0,
      resultSideEffects.data?.length === 0,
      !resultSurgeries.data,
      !resultSensitivities.data,
      !resultSensitivityDrugs.data,
      !resultRoutineDrugs.data,
      !resultPastDrugs.data,
    ].every(Boolean);
  }, [
    isLoading,
    resultIcd10s.data,
    resultSideEffects.data,
    resultSurgeries.data,
    resultSensitivities.data,
    resultSensitivityDrugs.data,
    resultRoutineDrugs.data,
    resultPastDrugs.data,
  ]);

  const onPreviewItem = useCallback(
    (id: string) => {
      onOpen(id);
    },
    [onOpen],
  );
  return (
    <>
      <NativeScroll mode={'visible'}>
        <div className={style.root}>
          {isEmpty && <DataGridEmpty />}
          <PreviewSessionEffectsList
            title={t('surgeries')}
            list={surgeryList}
            onPreview={onPreviewItem}
          />
          <PreviewSessionEffectsList
            title={t('sensitivities')}
            list={sensitivityList}
            onPreview={onPreviewItem}
          />
          <PreviewSessionEffectsList
            title={t('routine-drugs')}
            list={routineDrugList}
            onPreview={onPreviewItem}
            isLoading={resultUpdateRoutineDrug.isLoading || resultRoutineDrugs.isFetching}
            onSwitch={onSwitchRoutineDrugs}
          />
          <PreviewSessionEffectsList
            title={t('sensitivity-drugs')}
            list={sensitivityDrugList}
            onPreview={onPreviewItem}
          />
          <PreviewSessionEffectsList
            title={t('past-drug-for-obesity')}
            list={pastDrugList}
            onPreview={onPreviewItem}
          />
          <PreviewSessionEffectsList
            title={t('icd10s')}
            list={icd10List}
            onPreview={onPreviewItem}
            isLoading={resultUpdateIcd10.isLoading || resultIcd10s.isFetching}
            onSwitch={onSwitchIcd10}
          />
          <PreviewSessionEffectsList
            title={t('side-effects')}
            list={sideEffectList}
            onPreview={onPreviewItem}
          />
        </div>
      </NativeScroll>
      {openValue && (
        <DialogUserPatientProfileSession
          itemID={openValue}
          onClose={onClose}
          userPatientProfileID={userPatientProfileID}
        />
      )}
    </>
  );
};
