import React, { useCallback } from 'react';
import { GridControlsDropDownTemplateProps } from 'models/grid';
import { ListItemIcon, MenuItem } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { apiUserPatientProfileActivities } from 'services/user-patient-profile-activities';
import { useCurrentUser } from 'components/hooks';
import { dateFormat } from 'utils/dates';
import VAR from 'styles/_variabels.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import { useOpen } from 'AurionCR/components/hooks';
import { DialogConfirm } from 'components/dialog-confirm';
import { composeFunctions } from 'utils';

const useCreateUserActivityMutation =
  apiUserPatientProfileActivities.usePostUserPatientProfileActivityMutation;

type Model = Pick<
  Components.Schemas.UserPatientProfilePrescription,
  'id' | 'entryDate' | 'userPatientProfileID'
>;

interface Props extends GridControlsDropDownTemplateProps<Model> {}
export const ControlDelete: React.FC<Props> = ({ data, onTask, handleClose }) => {
  const { tp } = useTranslate();

  const [performCreate] = useCreateUserActivityMutation();

  const { $isAdmin, appUserID } = useCurrentUser();

  const onDelete = useCallback(() => {
    onTask([['deleteDataRequest', data]]);
    performCreate({
      userPatientProfileID: String(data.userPatientProfileID),
      userEmployeeProfileID: appUserID,
      remarks: tp('activity-patient-prescription-deleted', {
        prescription: { entryDate: dateFormat(data.entryDate) },
      }),
    });
    handleClose();
  }, [onTask, data, appUserID, performCreate, tp, handleClose]);

  const stateOpen = useOpen();

  return (
    <>
      <MenuItem onClick={stateOpen.handleOpen} disabled={!$isAdmin} style={{ color: VAR.colorRed }}>
        <ListItemIcon style={{ minWidth: '3rem' }}>
          <DeleteIcon fontSize={'small'} style={{ color: VAR.colorRed }} />
        </ListItemIcon>
        {tp('delete')}
      </MenuItem>
      {stateOpen.isOpen && (
        <DialogConfirm
          title={tp('confirm-delete')}
          onCancel={stateOpen.handleClose}
          onConfirm={composeFunctions(onDelete, stateOpen.handleClose)}
        />
      )}
    </>
  );
};
