const API_ENDPOINT = 'EndOfTreatmentRecommendationTemplates';

export const API_END_OF_TREATMENT_RECOMMENDATION_TEMPLATES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<EndOfTreatmentRecommendationTemplate, 'id'>) =>
    `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<EndOfTreatmentRecommendationTemplate, 'id'>) =>
    `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface EndOfTreatmentRecommendationTemplate
  extends Components.Schemas.EndOfTreatmentRecommendationTemplate {}
