import React, { useCallback, useMemo } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, Divider } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from '../components';
import { apiTodoTasks, ToDoTaskCreateInput } from 'services/todo-tasks';
import { useEffectError } from 'hooks';
import { useCurrentUser, useSourceTodoCategories } from 'components/hooks';
import { ActionKey } from 'services/todo-task-categories';
import { useTranslate } from 'hooks/use-translate';
import { EmployeeActivitiesMonitor } from 'components/employee-activities-monitor';
import style from './index.module.scss';
import { isMutationFulfilled } from 'utils/rtk-query';

const useCreateMutation = apiTodoTasks.useCreateTodoTaskWithLogMutation;

type Model = ToDoTaskCreateInput;
type CreatePayload = { userPatientProfileSessionID: string };

export interface ResultCreate extends Components.Schemas.ToDoTask {
  userEmployeeProfileIDs: string[];
  actionKey: ActionKey;
}

interface Props {
  onClose?: () => void;
  onCreated?: (data: ResultCreate, shouldContinue: boolean) => void;
  initData?: Partial<Model>;
  createPayload?: Partial<CreatePayload>;
}

export const DialogTaskCreate: React.FC<Props> = ({
  initData,
  onClose,
  onCreated,
  createPayload,
}) => {
  const { appUserID } = useCurrentUser();
  const { t, tp } = useTranslate();
  const defaultValues = useMemo(() => {
    return {
      toDoTaskCategoryID: '',
      userPatientProfileID: '',
      message: '',
      done: false,
      entryDate: new Date().toISOString(),
      userEmployeeProfileIDs: [],
      ...initData,
    };
  }, [initData]);

  const methods = useForm<Model>({
    defaultValues,
  });

  const [triggerCreate, { error, isLoading }] = useCreateMutation();
  useEffectError(error);

  const sourcedTodoCategories = useSourceTodoCategories();

  const onCreate = useCallback(
    async (data: Model, shouldContinue: boolean) => {
      const category = data.toDoTaskCategoryID
        ? sourcedTodoCategories.map[data.toDoTaskCategoryID]
        : null;

      const payload = {
        ...createPayload,
        ...data,
        sentByUserEmployeeProfileID: appUserID,
        userPatientProfileID: data.userPatientProfileID,
      };

      const result = await triggerCreate(payload);

      if (isMutationFulfilled(result) && onCreated) {
        onCreated(
          {
            ...result.data,
            ...createPayload,
            userEmployeeProfileIDs: data.userEmployeeProfileIDs,
            actionKey: category ? category.actionKey : ActionKey.General,
          },
          shouldContinue,
        );
      }
    },
    [triggerCreate, appUserID, onCreated, createPayload, sourcedTodoCategories.map],
  );

  return (
    <Dialog open={true} fullWidth maxWidth={'lg'} onClose={onClose}>
      <DialogHeading title={tp('create-task')} onClose={onClose} />
      <FormProvider {...methods}>
        <DialogContent>
          <div className={style.content}>
            <Form isLoading={isLoading} />
            <Divider orientation={'vertical'} />
            <EmployeeActivitiesMonitor />
          </div>
        </DialogContent>
      </FormProvider>
      <DialogActions>
        {methods.formState.isSubmitted && (
          <Box sx={{ mr: 'auto' }}>
            <Button
              onClick={() => methods.reset(defaultValues)}
              color={'primary'}
              variant={'outlined'}
            >
              {t('reset-form')}
            </Button>
          </Box>
        )}

        <Button onClick={onClose} color={'primary'} variant={'text'}>
          {t('close')}
        </Button>
        <Button
          disabled={isLoading}
          color={'primary'}
          variant={'contained'}
          onClick={methods.handleSubmit((data) => onCreate(data, false))}
        >
          {t('create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
