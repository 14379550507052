import React, { memo, useCallback, useMemo } from 'react';
import { ServiceMediaPrivate } from 'services/media-private-services';
import { getFileNameFromUrl } from 'utils/file-uploader';
import { Button, ButtonProps, Link } from '@material-ui/core';
import { useEffectError, useMutationDynamic } from 'hooks';
import { useTranslate } from 'hooks/use-translate';

interface DownloadPrivateProps extends Omit<ButtonProps<typeof Link>, 'onClick' | 'component'> {
  documentURL: string;
  labelKey?: string;
  onSuccess?: () => void;
}
const DownloadPrivate = memo<DownloadPrivateProps>(
  ({ documentURL, labelKey, onSuccess, ...rest }) => {
    const { t } = useTranslate();
    const fileName = useMemo(() => {
      return getFileNameFromUrl(documentURL);
    }, [documentURL]);

    const [request, { isLoading, error }] = useMutationDynamic(ServiceMediaPrivate.downloadFile);
    useEffectError(error);

    const onDownload = useCallback<React.MouseEventHandler>(
      async (event) => {
        event.preventDefault();

        const res = await request(documentURL);

        if (res.isSuccess && onSuccess) {
          onSuccess();
        }
      },
      [request, documentURL, onSuccess],
    );

    const child = useMemo(() => {
      if (labelKey) {
        return t(labelKey);
      }
      if (rest.children === undefined) {
        return `[${t('download-document')}]`;
      }
      return rest.children;
    }, [labelKey, rest.children, t]);

    return (
      <Button
        color={'primary'}
        component={Link}
        disabled={isLoading}
        title={fileName}
        href={`#download-${fileName}`}
        {...rest}
        onClick={onDownload}
      >
        {child}
      </Button>
    );
  },
);

interface Props extends Omit<ButtonProps<typeof Link>, 'onClick' | 'component'> {
  documentURL: string | undefined | null;
  labelKey?: string;
  onSuccess?: () => void;
}
const TemplateDownloadPrivate = memo<Props>(({ documentURL, ...rest }) => {
  if (!documentURL) {
    return null;
  }

  return <DownloadPrivate documentURL={documentURL} {...rest} />;
});

export default TemplateDownloadPrivate;
