import React from 'react';
import { useAppSelector } from 'store';
import { selectNotebookFormGeneratorIsAllowAddPrescription } from 'store/notebook-form-generator';
import { Stack } from 'components/stack';
import { ButtonCreatePrescription } from '../button-create-prescription';
import { ButtonTask } from '../../../dialog-patient';

interface TopControlsProps {
  userPatientProfileID: string;
  userPatientProfileSessionID: string;
}
export const ResultTopControls: React.FC<TopControlsProps> = ({
  userPatientProfileID,
  userPatientProfileSessionID,
}) => {
  const isAllowAddPrescription = useAppSelector(selectNotebookFormGeneratorIsAllowAddPrescription);
  return (
    <Stack spacing={1} style={{ flexShrink: 0 }}>
      {isAllowAddPrescription && (
        <ButtonCreatePrescription userPatientProfileID={userPatientProfileID} />
      )}

      <ButtonTask
        userPatientProfileSessionID={userPatientProfileSessionID}
        userPatientProfileID={userPatientProfileID}
      />
    </Stack>
  );
};
