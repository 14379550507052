import { API_PAYMENTS } from './models';
import { api } from 'utils/service';

export * from './models';

class Service {
  pay = async (input: Components.Schemas.PaymantRequest) => {
    return api.post<Components.Schemas.UserPatientProfileSubscription>(API_PAYMENTS.PAY, input);
  };

  refund = async (input: Required<Components.Schemas.RefundRequest>) => {
    return api.post(API_PAYMENTS.REFUND, input);
  };
}

export const ServicePayment = new Service();
