import React, { useCallback } from 'react';
import { ServiceUserPatientProfileSubscriptions } from 'services/user-patient-profile-subscriptions';
import { useEffectError, useMutationDynamic } from 'hooks';
import { DialogEndTreatmentConsultation } from '../dialog-treatment-consultation';
import { UserPatientProfileDocument } from 'services/user-patient-profile-documents';

interface Props {
  userPatientProfileSubscriptionID: string;
  userPatientProfileID: string;
  price: number;

  onDone: () => void;
  onClose: () => void;
}

export const DialogEndTreatmentConsultationContainer: React.FC<Props> = ({
  userPatientProfileSubscriptionID,
  userPatientProfileID,
  onDone,
  onClose,
  price,
}) => {
  const generate = useCallback(
    async (input: UserPatientProfileDocument) => {
      await ServiceUserPatientProfileSubscriptions.patch({
        id: userPatientProfileSubscriptionID,
        endOfTreatmentDocUrl: input.documentURL,
      });
      onDone();
    },
    [userPatientProfileSubscriptionID, onDone],
  );

  const [onGenerate, { error, isLoading }] = useMutationDynamic(generate);
  useEffectError(error);

  return (
    <DialogEndTreatmentConsultation
      isLoading={isLoading}
      userPatientProfileID={userPatientProfileID}
      onDone={onGenerate}
      onClose={onClose}
      price={price}
    />
  );
};
