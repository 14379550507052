import React, { useCallback, useMemo } from 'react';
import { useFilterForm } from 'hooks/use-filter-form';
import { useAppDispatch, useAppSelector } from 'store';
import { useFieldProps } from 'hooks';
import { Tooltip, Slider, Typography, Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import {
  actionSideEffectsLoadData,
  actionSideEffectsSetFilters,
  selectSideEffectsStatuses,
  selectSideEffectsFilters,
} from '../../store';
import { calcRangeSlider } from 'utils/app-helpers';
import { useTranslate } from 'hooks/use-translate';
import { GridFilters } from '../../models';
import style from './index.module.scss';
import { SelectIcd10 } from 'components/select-icd10';
import { composeFunctions } from 'utils';
import * as yup from 'yup';
import { DateRangePicker } from 'AurionCR/components/form/date/date-range-picker';
import { yupResolver } from '@hookform/resolvers/yup';
import { convertToDate, isDateLike } from 'utils/dates';
import { differenceInDays } from 'date-fns';

const schema = yup.object({
  dates: yup
    .array()
    .of(yup.string().test('date', 'rule-date', isDateLike))
    .test('date-range', 'rule-date-range-limit', (value = []) => {
      const dif = differenceInDays(convertToDate(value[0]), convertToDate(value[1]));

      return Math.abs(dif) <= 30;
    })
    .required('rule-required'),
});

interface ValueLabelComponentProps {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

const ValueLabelComponent: React.FC<ValueLabelComponentProps> = (props) => {
  const { children, open, value } = props;

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value}
      arrow
      PopperProps={{ disablePortal: true }}
      classes={{
        tooltipPlacementTop: style.tooltipPlacementTop,
      }}
    >
      {children}
    </Tooltip>
  );
};

export const Filters: React.FC = () => {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectSideEffectsFilters);
  const { isLoading } = useAppSelector(selectSideEffectsStatuses);

  const onUpdateAndLoad = useCallback(
    (formData: GridFilters) => {
      dispatch(actionSideEffectsSetFilters(formData));
      dispatch(actionSideEffectsLoadData());
    },
    [dispatch],
  );

  const { control, errors, getValues, onCheckAndUpdate } = useFilterForm({
    defaultValues: filters,
    onUpdate: onUpdateAndLoad,
    resolver: yupResolver(schema),
  });

  const getFieldProps = useFieldProps({ emptyHelperText: '', errors });
  const ageSliderProps = useMemo(() => {
    const marks = Array.from({ length: 100 })
      .fill(null)
      .map((_, index) => ({ value: index }));

    return { marks, min: marks[0].value, max: marks[marks.length - 1].value };
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item md={'auto'} xs={12}>
        <Controller
          name={'dates'}
          control={control}
          render={(renderProps) => {
            const props = getFieldProps(renderProps);
            return (
              <DateRangePicker
                {...props}
                style={{
                  minWidth: '16rem',
                }}
                disabled={isLoading}
                onChange={composeFunctions(props.onChange, onCheckAndUpdate)}
              />
            );
          }}
        />
      </Grid>
      <Grid item lg={true} md={true} xs={12}>
        <div>
          <Typography color={'textSecondary'} component={'div'} className={style.title}>
            {t('age')}
          </Typography>
          <Controller
            name={'age'}
            control={control}
            render={(renderProps) => {
              const props = getFieldProps(renderProps);
              return (
                <Slider
                  step={null}
                  {...props}
                  {...ageSliderProps}
                  value={Array.from(props.value)}
                  ValueLabelComponent={ValueLabelComponent}
                  valueLabelDisplay="on"
                  onChange={(event, value) => {
                    if (!Array.isArray(value)) return;

                    const oldValue = getValues('age');
                    const activeThumb = oldValue[0] === value[0] ? 1 : 0;

                    const result = calcRangeSlider({
                      value,
                      marks: ageSliderProps.marks,
                      minStep: 8,
                      maxStep: 62,
                      activeThumb,
                    });

                    props.onChange(result);
                  }}
                  onChangeCommitted={onCheckAndUpdate}
                  disabled={isLoading}
                />
              );
            }}
          />
        </div>
      </Grid>
      <Grid item lg={true} xs={12}>
        <Controller
          name={'sideEffectIDs'}
          control={control}
          render={(renderProps) => {
            const props = getFieldProps(renderProps);
            return (
              <SelectIcd10
                {...props}
                multiple
                onChange={composeFunctions(props.onChange, onCheckAndUpdate)}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};
