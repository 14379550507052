import { useMountedRef } from './use-mounted-ref';
import { useCallback, useMemo, useState } from 'react';

enum STATUS {
  IS_LOADING,
  DEFAULT,
}

type Request<M = any> = (...args: any[]) => Promise<{ data: M }>;
type RequestModel<T> = T extends Request<infer U> ? U : unknown;

export const useFetchDynamic = <R extends Request, M extends RequestModel<R>>(request: R) => {
  const [status, setStatus] = useState(STATUS.DEFAULT);
  const [error, setError] = useState<Error | null>(null);
  const [lastTimestamp, setLastTimestamp] = useState<number | null>(null);

  const [data, setData] = useState<M>();

  const mountedRef = useMountedRef();
  const trigger = useCallback(
    async (...args: Parameters<R>) => {
      setError(null);
      setStatus(STATUS.IS_LOADING);
      try {
        const result = await request(...args);

        setData(result.data);
        return { data: result.data as M, error: undefined };
      } catch (e: any) {
        if (mountedRef.current) {
          setError(e);
        }
        return { error: e as Error, data: undefined };
      } finally {
        if (mountedRef.current) {
          setStatus(STATUS.DEFAULT);
          setLastTimestamp(new Date().getTime());
        }
      }
    },
    // eslint-disable-next-line
    [mountedRef, request],
  );

  const value = useMemo(() => {
    return {
      data,
      setData,
      error,
      status,
      isLoading: status === STATUS.IS_LOADING,
      lastTimestamp,
    };
  }, [data, error, status, lastTimestamp]);
  return [trigger, value] as const;
};

type MutationResult<T> = T extends Mutation<infer U> ? U : unknown;
interface Mutation<Res = any> {
  (...args: any[]): Promise<{ data: Res }> | Promise<void>;
}
export const useMutationDynamic = <Mut extends Mutation>(mutation: Mut) => {
  const [status, setStatus] = useState(STATUS.DEFAULT);
  const [error, setError] = useState<Error | null>(null);
  const [lastTimestamp, setLastTimestamp] = useState<number | null>(null);

  const [data, setData] = useState<MutationResult<Mut>>();

  const mountedRef = useMountedRef();
  const trigger = useCallback(
    async (...args: Parameters<Mut>) => {
      setError(null);
      setStatus(STATUS.IS_LOADING);
      try {
        const result = await mutation(...args);
        const data = (result ? result.data : undefined) as MutationResult<Mut>;

        if (mountedRef.current) {
          setData(data);
        }

        return {
          ...result,
          data: data,
          error: undefined,
          isSuccess: true,
          isError: false,
        } as const;
      } catch (e: any) {
        if (mountedRef.current) {
          setError(e);
        }
        return { error: e as Error, data: undefined, isSuccess: false, isError: true } as const;
      } finally {
        if (mountedRef.current) {
          setStatus(STATUS.DEFAULT);
          setLastTimestamp(new Date().getTime());
        }
      }
    },
    // eslint-disable-next-line
    [mountedRef, mutation],
  );

  const value = useMemo(() => {
    return {
      data,
      setData,
      error,
      status,
      isLoading: status === STATUS.IS_LOADING,
      lastTimestamp,
    };
  }, [data, error, status, lastTimestamp]);
  return [trigger, value] as const;
};
