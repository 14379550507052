import { createAsyncThunk } from '@reduxjs/toolkit';
import { api, parseErrorData } from 'utils/service';
import { ServiceUserEmployeeProfile } from 'services/user-employee-profiles';
import { authSuccess } from 'AurionCR/store/modules/auth';
import { selectAuthUser } from '../auth';
import { AppAsyncThunkConfig } from '../store';
import { convertToDate } from 'utils/dates';
import { differenceInMinutes } from 'date-fns';

export const actionEmployeesMonitorGetEmployees = createAsyncThunk(
  `EMPLOYEES_MONITOR/GetEmployees`,
  async () => {
    try {
      const {
        data: { value },
      } = await ServiceUserEmployeeProfile.getEmployeeActivitiesMonitor();
      return value;
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);

export const actionEmployeesMonitorRefreshEmployees = createAsyncThunk(
  `EMPLOYEES_MONITOR/RefreshEmployees`,
  async () => {
    try {
      const {
        data: { value },
      } = await ServiceUserEmployeeProfile.getEmployeeActivitiesMonitor();
      return value;
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);

export const actionEmployeesMonitorPingCurrentUser = createAsyncThunk<
  Components.Schemas.CurrentUserProfileResponse,
  void,
  AppAsyncThunkConfig
>(`EMPLOYEES_MONITOR/PingCurrentUser`, async (_, { dispatch, getState }) => {
  try {
    const user = selectAuthUser(getState());

    if (!user) {
      throw new Error('current user is not defined');
    }

    const lastDate = convertToDate(user.lastActivityDate);
    const diff = differenceInMinutes(new Date(), lastDate);

    if (diff < 1) {
      return user;
    }

    const { data } = await api.get<Components.Schemas.CurrentUserProfileResponse>(
      'Accounts/GetCurrentAppUser',
    );
    dispatch(authSuccess(data));

    return data;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
