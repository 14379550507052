import { DynamicService } from 'utils/service';
import {
  API_CLINICAL_MEETING_SUBJECTS,
  CLINICAL_MEETING_SUBJECT_TYPE,
  ClinicalMeetingSubject,
} from './models';
import { apiRtk, transformResponseDynamicItem } from 'utils/rtk-query';
import { createFilterEquals, mergeFilters } from 'utils/dynamic-helpers';

export * from './models';

class Service extends DynamicService<ClinicalMeetingSubject> {
  private getCancelID = async () => {
    const { data } = await this.getAllDynamic<{ id: string }>({
      take: 1,
      select: 'id',
      filter: mergeFilters(
        createFilterEquals('isActive', true),
        createFilterEquals('isCanceledMeeting', true),
      ).join('&&'),
    });

    const res = transformResponseDynamicItem(data);

    return res.id;
  };
  private getEndOfTreatmentMeetingID = async () => {
    const { data } = await this.getAllDynamic<{ id: string }>({
      take: 1,
      select: 'id',
      filter: mergeFilters(
        createFilterEquals('isActive', true),
        createFilterEquals('isEndOfTreatmentMeeting', true),
      ).join('&&'),
    });

    const res = transformResponseDynamicItem(data);

    return res.id;
  };
  private getOutOfScheduleID = async () => {
    const { data } = await this.getAllDynamic<{ id: string }>({
      take: 1,
      select: 'id',
      filter: mergeFilters(
        createFilterEquals('isActive', true),
        createFilterEquals('isOutOfScheduleMeeting', true),
      ).join('&&'),
    });

    const res = transformResponseDynamicItem(data);

    return res.id;
  };
  private getNotShowingUpMeetingID = async () => {
    const { data } = await this.getAllDynamic<{ id: string }>({
      take: 1,
      select: 'id',
      filter: mergeFilters(
        createFilterEquals('isActive', true),
        createFilterEquals('isNotShowingUpMeeting', true),
      ).join('&&'),
    });

    const res = transformResponseDynamicItem(data);

    return res.id;
  };

  getIdByType = async (type: CLINICAL_MEETING_SUBJECT_TYPE) => {
    switch (type) {
      case CLINICAL_MEETING_SUBJECT_TYPE.CANCEL: {
        return this.getCancelID();
      }
      case CLINICAL_MEETING_SUBJECT_TYPE.OUT_OF_SCHEDULE: {
        return this.getOutOfScheduleID();
      }
      case CLINICAL_MEETING_SUBJECT_TYPE.NOT_SHOWING_UP_MEETING: {
        return this.getNotShowingUpMeetingID();
      }
      case CLINICAL_MEETING_SUBJECT_TYPE.END_OF_TREATMENT: {
        return this.getEndOfTreatmentMeetingID();
      }
    }
  };
}

export const ServiceClinicalMeetingSubjects = new Service({
  getAll: API_CLINICAL_MEETING_SUBJECTS.GET_ALL_DYNAMIC,
  post: API_CLINICAL_MEETING_SUBJECTS.POST,
  patch: API_CLINICAL_MEETING_SUBJECTS.PATCH,
  delete: API_CLINICAL_MEETING_SUBJECTS.DELETE,
});

export const apiClinicalMeetingSubjects = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getClinicalMeetingSubjectIDByType: builder.query<string, CLINICAL_MEETING_SUBJECT_TYPE>({
      queryFn: async (type) => {
        try {
          const id = await ServiceClinicalMeetingSubjects.getIdByType(type);
          return { data: id };
        } catch (e: any) {
          return { error: e };
        }
      },
      keepUnusedDataFor: 10 * 60, // 10 minutes
    }),
  }),
});
