import React, { memo, useCallback, useEffect, useMemo } from 'react';
import GridConfig from './grid-config';
import DataGrid from 'components/data-grid';
import { patientEmitter } from '../../emitter';
import {
  API_TODO_TASK_CATEGORIES,
  CONFIG_CATEGORY_ACTION,
  TodoTaskCategoryGridFilter,
} from 'services/todo-task-categories';
import { IDataGridRowProps } from 'components/data-grid/model';
import { useFormGrid } from 'AurionCR/components/formV2';
import { hexToRgb } from 'components/helpers';
import clsx from 'clsx';
import style from './index.module.scss';
import { useCurrentUser } from 'components/hooks';
import { Model } from './helpers';
import { useSource } from 'AurionCR/components';

interface Props {
  userPatientProfileID: string;
  notDoneTasks?: number;
  shouldCheckTasksStatus?: boolean;
}

export const GridTasks = memo<Props>(
  ({ userPatientProfileID, notDoneTasks, shouldCheckTasksStatus }) => {
    const { appUserID } = useCurrentUser();
    const { grid, editItemID, onGridHandle, setEditItemID } = useFormGrid('task');
    const onRefresh = useCallback(() => {
      grid.current?.onTasks([['getData']]);
    }, [grid]);
    const onDoneTask = useCallback(() => {
      setEditItemID(null);
    }, [setEditItemID]);

    const categories = useSource<TodoTaskCategoryGridFilter>(
      `${API_TODO_TASK_CATEGORIES.GET_ALL_DYNAMIC}?Select=id,title,actionKey&orderBy=title`,
    );

    const onGridHandleWrap = useCallback(
      async (action: any) => {
        return onGridHandle(action);
      },
      [onGridHandle],
    );

    const taskCategoryList = useMemo(() => {
      return categories.data.map((item) => {
        return {
          ...item,
          ...CONFIG_CATEGORY_ACTION[item.actionKey],
        };
      });
    }, [categories.data]);

    const gridConfig = useMemo<any>(() => {
      if (
        !taskCategoryList.length ||
        (shouldCheckTasksStatus && typeof notDoneTasks === 'undefined')
      ) {
        return null;
      }

      return GridConfig({
        userPatientProfileID,
        onDoneTask,
        appUserID,
        taskCategoryList,
        notDoneTasks,
        onRefresh,
      });
    }, [
      userPatientProfileID,
      onDoneTask,
      appUserID,
      taskCategoryList,
      notDoneTasks,
      onRefresh,
      shouldCheckTasksStatus,
    ]);

    useEffect(() => {
      const refresh = (payload: { patientID: string }) => {
        if (payload.patientID !== userPatientProfileID) {
          return;
        }
        grid.current?.onTasks([['getData']]);
      };

      patientEmitter.on('refresh-all', refresh);
      patientEmitter.on('refresh-tasks', refresh);
      return () => {
        patientEmitter.off('refresh-all', refresh);
        patientEmitter.off('refresh-tasks', refresh);
      };
    }, [userPatientProfileID, grid]);

    const calcRowProps = useCallback<IDataGridRowProps<Model>>(
      ({ row }) => {
        const config = CONFIG_CATEGORY_ACTION[row.actionKey];
        const { r, g, b } = hexToRgb(config.color);

        const isSelected = row.id === editItemID;
        const opacity = isSelected ? '0.4' : '0.06';

        return {
          className: clsx(row.id === editItemID && style.selected),
          style: { backgroundColor: `rgba(${r}, ${g}, ${b}, ${opacity})` },
        };
      },
      [editItemID],
    );

    return (
      <div className={style.root}>
        {gridConfig && (
          <DataGrid
            ref={grid}
            config={gridConfig}
            rowProps={calcRowProps}
            onHandle={onGridHandleWrap}
            FiltersBoxProps={{ width: '100%' }}
          />
        )}
      </div>
    );
  },
);
