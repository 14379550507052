import { UserPatientProfileSession } from 'services/user-patient-profile-session';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store';
import {
  actionNotebookFormGeneratorGetNotebook,
  actionNotebookFormGeneratorReset,
} from 'store/notebook-form-generator';
import { NotebookFormGeneratorPropsProvider } from '../props-provider';
import { NotebookFormGenerationContainer } from '../notebook-form-generation-container';

export interface NotebookFormGeneratorProps {
  isLoading?: boolean;
  notebookID: string;
  userPatientProfileID: string;
  supportMeetingTypeID?: string;
  excludeMeetingIDs?: string[];
  onClose: (session?: Pick<UserPatientProfileSession, 'id'>) => void;
  onCreated?: () => void;
  onDone: (session: Pick<UserPatientProfileSession, 'id'>) => void;
  showDelete?: boolean;
  meetingFromDateTime: string | undefined;
  meetingToDateTime: string | undefined;
}
export const NotebookFormGenerator: React.FC<NotebookFormGeneratorProps> = ({
  isLoading,
  notebookID,
  userPatientProfileID,
  supportMeetingTypeID,
  onClose,
  onDone,
  onCreated,
  showDelete,
  excludeMeetingIDs,
  meetingFromDateTime,
  meetingToDateTime,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(actionNotebookFormGeneratorGetNotebook(notebookID));
    return () => {
      dispatch(actionNotebookFormGeneratorReset());
    };
  }, [notebookID, dispatch]);

  return (
    <NotebookFormGeneratorPropsProvider
      notebookID={notebookID}
      userPatientProfileID={userPatientProfileID}
      supportMeetingTypeID={supportMeetingTypeID}
      excludeFutureMeetingIDs={excludeMeetingIDs}
      showDelete={showDelete}
      meetingFromDateTime={meetingFromDateTime}
      meetingToDateTime={meetingToDateTime}
    >
      <NotebookFormGenerationContainer
        isLoading={!!isLoading}
        onClose={onClose}
        onCreated={onCreated}
        onDone={onDone}
      />
    </NotebookFormGeneratorPropsProvider>
  );
};
