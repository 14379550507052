import { ArrayType, ConditionPartial, PatchPartial } from 'utils/types';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
export const baseURL = `${process.env.REACT_APP_API_SERVER || ''}/api/`;

export const api = axios.create({ baseURL: baseURL });

export interface DynamicParams {
  select?: string;
  filter?: string;
  orderBy?: string;
  take?: number;
  skip?: number;
  count?: boolean;
}

export interface DynamicPagination {
  take: number;
  count: number;
  page: number;
}

export const calcPaginationSkip = ({ page, take }: Omit<DynamicPagination, 'count'>) => {
  return take * (page - 1);
};
export const calcPaginationState = ({ take, page, count }: DynamicPagination) => {
  const skip = calcPaginationSkip({ take, page });
  const pages = Math.ceil(count / take);
  const isLastPage = pages === page;
  const isFirstPage = page === 1;
  return {
    take,
    page,
    count,
    pages,
    skip,
    isFirstPage,
    isLastPage,
  };
};

export interface DynamicOrder<F extends string = string> {
  field: F;
  order: 'desc' | 'asc' | null;
}

export type DynamicResult<T extends any, P extends DynamicParams = {}> = P extends {
  count: boolean;
}
  ? { value: T[]; count: number }
  : { value: T[]; count?: undefined };
export type DynamicResultModel<M> = M extends DynamicResult<infer U> ? U : unknown;

export const transformDynamicToItem = <T extends { value: any[] } = { value: any[] }>(
  result: T,
) => {
  const item = result.value[0];
  if (!item) {
    throw new Error('record-not-found');
  }
  return item as ArrayType<T['value']>;
};

interface DynamicModel {
  id: string;
}

interface DynamicServiceOptions<M> {
  engine?: AxiosInstance;
  getAll: string;
  post: string;
  patch: (data: PatchPartial<M, keyof M>) => string;
  delete: (data: PatchPartial<M, keyof M>) => string;
}

export class DynamicService<M = DynamicModel, K extends keyof M = keyof M> {
  public engine: AxiosInstance = api;
  public urlGetAll: string;
  public urlPost: string;
  public urlPatch: (data: PatchPartial<M, keyof M>) => string;
  public urlDelete: (data: PatchPartial<M, keyof M>) => string;
  public mainField: string;

  constructor(
    options: DynamicServiceOptions<M> & ConditionPartial<M, DynamicModel, { mainField: K }>,
  ) {
    const { getAll, patch, post, engine = api, mainField } = options;
    this.mainField = String(mainField || 'id');

    this.engine = engine;

    if (!this.engine) {
      throw new Error('Dynamic Service: engine is not set');
    }

    this.urlGetAll = getAll;
    this.urlPatch = patch;
    this.urlPost = post;
    this.urlDelete = options.delete;

    this.getAllDynamic = this.getAllDynamic.bind(this);
    this.getDynamic = this.getDynamic.bind(this);
    this.patch = this.patch.bind(this);
    this.post = this.post.bind(this);
    this.delete = this.delete.bind(this);
  }

  getAllDynamic = <Model = M, Params extends DynamicParams = DynamicParams>(params?: Params) => {
    return this.engine.get<DynamicResult<Model, Params>>(this.urlGetAll, { params });
  };

  getDynamic = async <Model = M, Params extends DynamicParams = DynamicParams>(
    id: string,
    params?: Params,
  ) => {
    const result = await this.getAllDynamic<Model>({
      ...params,
      filter: [`${this.mainField}=="${id}"`, params?.filter].filter(Boolean).join('&&'),
      take: 1,
    });

    return DynamicService.transformResponseToItem<AxiosResponse<DynamicResult<Model>>>(result);
  };

  async patch(data: Partial<M>) {
    return this.engine.patch<string>(this.urlPatch(data as any), {
      ...data,
      [this.mainField]: undefined,
    });
  }

  async post<Data = Omit<M, K>, Return = M>(data: Data) {
    return this.engine.post<Return>(this.urlPost, { ...data, [this.mainField]: undefined });
  }

  async delete(data: Partial<M>) {
    return this.engine.delete<M>(this.urlDelete(data as any));
  }

  static transformResponseToItem<R extends AxiosResponse<DynamicResult<any>>>(response: R) {
    const data = response.data.value[0];
    if (!data) {
      throw new Error('record-not-found');
    }
    return { ...response, data } as AxiosResponse<DynamicResultModel<R['data']>>;
  }
  static transformResponseToItemMaybe<R extends AxiosResponse<DynamicResult<any>>>(response: R) {
    const data = response.data.value[0];
    return { ...response, data } as AxiosResponse<DynamicResultModel<R['data']> | null>;
  }
}

export const generateDynamicQuery = <T extends DynamicParams>(params: T) => {
  return Object.entries(params)
    .filter(([_, val]) => val !== undefined)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&');
};

export const isAxiosError = (error: any): error is AxiosError => {
  return 'isAxiosError' in error;
};
export const parseErrorData = <T = string>(error: AxiosError<T> | Partial<Error>) => {
  if (!error) {
    return new Error('error');
  }
  if (isAxiosError(error)) {
    const errorData = error.response?.data;

    if (!errorData) {
      return new Error('error');
    }

    if (typeof errorData === 'string') {
      return new Error(errorData);
    }
    return { message: 'error', ...errorData };
  }
  return new Error(error.message);
};

export const normalizeDataBeforeSave = <T extends Record<string, any>>(data: T) => {
  return Object.keys(data).reduce((acc, key) => {
    const value = data[key];
    // @ts-ignore
    acc[key] = String(key).endsWith('ID') && value === '' ? null : value;
    return acc;
  }, {} as T);
};

export const getDynamicRank = async <T extends Record<string, any> = { rank: number }>(
  url: string,
  field: keyof T = 'rank',
) => {
  try {
    const params = {
      select: field,
      take: 1,
      orderBy: `${field} desc`,
    };
    const {
      data: { value },
    } = await api.get<DynamicResult<T>>(url, { params });
    const item = value[0];
    return Number((item ? item[field] : 0) || 0) + 1;
  } catch (e) {
    return 1;
  }
};
