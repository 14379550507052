import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ClickAwayListener } from '@material-ui/core';
import { validateRule } from 'AurionCR/components/formV2';
import { IDataGridColumnEditorSelect } from 'components/data-grid/model';
import { SelectEmployee } from 'components/select-employee';
import { useFieldProps } from 'hooks';
import Footer from 'components/data-grid/components/column/editors/footer';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete/useAutocomplete';
import { Unset } from 'utils/types';

type Option = {
  id: string;
  isActive?: boolean;
  title?: Unset;
};
interface Props {
  editor: IDataGridColumnEditorSelect;
  data: Unset<string | number>;
  onClose: any;
  onSubmit: any;
}

export const EditorEmployee: React.FC<Props> = ({ editor, data, onClose, onSubmit }) => {
  const { handleSubmit, errors, control } = useForm({ defaultValues: { data: data || '' } });
  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

  const filterOptions = useCallback((list: Array<Option>, state: FilterOptionsState<Option>) => {
    return list.filter(
      (item) => item.isActive && item.title?.toLowerCase().includes(state.inputValue.toLowerCase()),
    );
  }, []);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className="grid-editor" style={{ width: '35rem' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="data"
            control={control}
            rules={editor.required ? validateRule('required') : {}}
            render={(renderProps) => {
              return (
                <SelectEmployee
                  {...getFieldProps(renderProps)}
                  isActiveOnly={false}
                  filterOptions={filterOptions}
                  label={null}
                />
              );
            }}
          />
          <Footer onClose={onClose} />
        </form>
      </div>
    </ClickAwayListener>
  );
};
