import React, { memo, useMemo } from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { Tooltip, Typography, TypographyProps } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { fieldToLabelKey } from 'utils/other';
import { getPatientColor, IPatientCardInfo } from 'services/user-patient-profile';
import { getAge, isTeenager } from 'utils/dates';
import { PATIENT_TAGS, usePatientTags } from 'hooks/use-patient-tags';

interface LabelProps {
  title: string;
  color: string | undefined;
  tooltip?: string;
}
const Label: React.FC<LabelProps> = ({ color, title, tooltip }) => {
  return (
    <Tooltip title={tooltip ?? ''} arrow>
      <span className={style.label} style={{ backgroundColor: color }}>
        {title}
      </span>
    </Tooltip>
  );
};

export interface TemplatePatientProps extends TypographyProps {
  patient: IPatientCardInfo | undefined;
  children: React.ReactNode;
  className?: string;
}

export const TemplatePatientFullName = memo<TemplatePatientProps>(
  ({ children, patient, className, ...rest }) => {
    const {
      dateOfBirth,
      isTLC,
      isActive,
      onHold,
      onHoldReason,
      doNotProlongTreatment,
      slowIncreaseWeight,
    } = patient || {};

    const { teenager, age } = useMemo(() => {
      return { teenager: dateOfBirth ? isTeenager(dateOfBirth) : null, age: getAge(dateOfBirth) };
    }, [dateOfBirth]);
    const { tp, t } = useTranslate();
    const { data: tags } = usePatientTags();

    const labels = useMemo(() => {
      return tags
        .filter((tag) => {
          switch (tag.id) {
            case PATIENT_TAGS.InActive: {
              return !patient?.isActive;
            }
            case PATIENT_TAGS.OnHold: {
              return patient?.onHold;
            }
            case PATIENT_TAGS.TLC: {
              return patient?.isTLC;
            }
            case PATIENT_TAGS.DoNotProlongTreatment: {
              return patient?.doNotProlongTreatment;
            }
            case PATIENT_TAGS.SlowIncreaseWeight: {
              return patient?.slowIncreaseWeight;
            }
            case PATIENT_TAGS.ChangeDosageByDoctorApproval: {
              return patient?.changeDosageByDoctorApproval;
            }
            case PATIENT_TAGS.Teenager: {
              return teenager;
            }
            case PATIENT_TAGS.ExtraAttention: {
              return patient?.extraAttention;
            }
          }

          return false;
        })
        .map((tag) => {
          switch (tag.id) {
            case PATIENT_TAGS.OnHold: {
              return {
                ...tag,
                tooltip: tp('tooltip-on-hold', { reason: onHoldReason || 'unknown' }),
              };
            }
            case PATIENT_TAGS.Teenager: {
              return {
                ...tag,
                tooltip: tp('tooltip-teenager', { age }),
              };
            }
            default: {
              const labelKey = fieldToLabelKey(tag.id);

              return {
                ...tag,
                tooltip: t(`tooltip-${labelKey}`),
              };
            }
          }
        });
    }, [tp, t, patient, teenager, age, onHoldReason, tags]);

    const borderColor = getPatientColor({
      isTLC,
      isActive,
      onHold,
      doNotProlongTreatment,
      slowIncreaseWeight,
      isTeenager: teenager,
    });

    const strike = !!onHold || !isActive;

    return (
      <div
        className={clsx(style.root, className)}
        style={{ '--data-color': borderColor } as React.CSSProperties}
      >
        <div className={style.icons}>
          <div className={style.iconsInner}>
            {labels.map(({ id, color, title, tooltip }) => {
              return <Label key={id} title={title} color={color} tooltip={tooltip} />;
            })}
          </div>
        </div>
        <Typography
          color={'inherit'}
          {...rest}
          className={clsx({ [style.border]: labels.length, [style.strike]: strike })}
        >
          {children}
        </Typography>
      </div>
    );
  },
);
