import React, { useMemo } from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { Stack, StackProps } from 'components/stack';
import { Tooltip, Typography, TypographyProps } from '@material-ui/core';
import { usePatientSubscription } from 'components/dialog-patient/hooks';
import { format, isAfter, isBefore } from 'date-fns';
import { convertToDate } from 'utils/dates';
import { APP_FORMAT_DATE } from 'configs/const';
import { Loading } from 'AurionCR/components';
import { useSourceAppointment, useSourceSupportMeeting } from 'components/hooks';
import { useTranslate } from 'hooks/use-translate';
import InfoIcon from '@material-ui/icons/Info';

const formatMeetingValue = <T extends { meetingDate: string; employeeName?: string }>(item: T) => {
  return [format(convertToDate(item.meetingDate), APP_FORMAT_DATE), item.employeeName]
    .filter(Boolean)
    .join(' ');
};

const formatMeeting = <T extends { meetingDate: string; employeeName?: string }>(
  data: T[],
  order: 'next' | 'last',
) => {
  const sortedDataAsc = Array.from(data).sort((a, b) => {
    return convertToDate(a.meetingDate).getTime() - convertToDate(b.meetingDate).getTime();
  });

  const EMPTY = '-';

  if (order === 'last') {
    const meeting = sortedDataAsc
      .reverse()
      .find(({ meetingDate }) => isBefore(convertToDate(meetingDate), new Date()));
    return meeting ? formatMeetingValue(meeting) : EMPTY;
  }

  if (order === 'next') {
    const meeting = sortedDataAsc.find(({ meetingDate }) =>
      isAfter(convertToDate(meetingDate), new Date()),
    );
    return meeting ? formatMeetingValue(meeting) : EMPTY;
  }
};

interface UserInfoBoxTitleProps extends TypographyProps {
  className?: string;
}
const UserInfoBoxTitle: React.FC<UserInfoBoxTitleProps> = ({ className, ...rest }) => {
  return <Typography variant={'body2'} className={clsx(style.title, className)} {...rest} />;
};

interface UserInfoBoxValueProps extends TypographyProps {
  className?: string;
}
const UserInfoBoxValue: React.FC<UserInfoBoxValueProps> = ({ className, ...rest }) => {
  return (
    <Typography
      variant={'body2'}
      color={'primary'}
      className={clsx(style.value, className)}
      {...rest}
    />
  );
};

interface UserInfoBoxProps extends StackProps {
  className?: string;
  isLoading?: boolean;
}
const UserInfoBox: React.FC<UserInfoBoxProps> = ({ className, isLoading, children, ...rest }) => {
  return (
    <Stack className={clsx(style.root, className)} {...rest}>
      {children}
      <Loading active={isLoading} size={15} />
    </Stack>
  );
};

interface UserBoxProgramDateProps extends UserInfoBoxProps {
  userPatientProfileID: string;
  checkSubscription?: boolean;
}
export const UserBoxProgramDate: React.FC<UserBoxProgramDateProps> = ({
  userPatientProfileID,
  checkSubscription,
  ...rest
}) => {
  const { tp } = useTranslate();

  const {
    data: subscription,
    isLoading,
    isInactiveSubscription,
  } = usePatientSubscription(userPatientProfileID);

  const { startDate, endDate } = useMemo(() => {
    const EMPTY = '-';
    return {
      startDate:
        subscription && subscription.startDate
          ? format(convertToDate(subscription.startDate), APP_FORMAT_DATE)
          : EMPTY,
      endDate:
        subscription && subscription.endDate
          ? format(convertToDate(subscription.endDate), APP_FORMAT_DATE)
          : EMPTY,
    };
  }, [subscription]);

  const highlightInactiveSubscription = checkSubscription && isInactiveSubscription;
  return (
    <UserInfoBox
      isLoading={isLoading}
      {...rest}
      className={clsx(style.dates, highlightInactiveSubscription && style.datesError)}
    >
      {highlightInactiveSubscription && (
        <Tooltip title={tp('patient-subscription-is-outdated-tooltip')}>
          <InfoIcon color={'error'} fontSize={'small'} className={style.datesIcon} />
        </Tooltip>
      )}
      <UserInfoBoxTitle>{tp('program-info-start-date')}</UserInfoBoxTitle>
      <UserInfoBoxValue>{startDate}</UserInfoBoxValue>
      <UserInfoBoxTitle color={highlightInactiveSubscription ? 'error' : 'inherit'}>
        {tp('program-info-end-date')}
      </UserInfoBoxTitle>
      <UserInfoBoxValue color={highlightInactiveSubscription ? 'error' : 'primary'}>
        {endDate}
      </UserInfoBoxValue>
    </UserInfoBox>
  );
};

interface UserBoxProgramNameProps extends UserInfoBoxProps {
  userPatientProfileID: string;
}
export const UserBoxProgramName: React.FC<UserBoxProgramNameProps> = ({
  userPatientProfileID,
  ...rest
}) => {
  const { t } = useTranslate();

  const { data: subscription, isLoading } = usePatientSubscription(userPatientProfileID);

  const title = useMemo(() => {
    const EMPTY = '-';
    return subscription?.subscription.labelKey ? t(subscription?.subscription.labelKey) : EMPTY;
  }, [subscription, t]);

  return (
    <UserInfoBox direction={'column'} isLoading={isLoading} {...rest}>
      <UserInfoBoxTitle>{t('program-info-name')}</UserInfoBoxTitle>
      <UserInfoBoxValue>{title}</UserInfoBoxValue>
    </UserInfoBox>
  );
};

interface UserBoxProgramDurationProps extends UserInfoBoxProps {
  userPatientProfileID: string;
}
export const UserBoxProgramDuration: React.FC<UserBoxProgramDurationProps> = ({
  userPatientProfileID,
  ...rest
}) => {
  const { t } = useTranslate();

  const { data: subscription, isLoading } = usePatientSubscription(userPatientProfileID);

  const duration = useMemo(() => {
    const EMPTY = '-';
    return subscription?.subscription.durationMonths || EMPTY;
  }, [subscription]);

  return (
    <UserInfoBox
      isLoading={isLoading}
      spacing={1}
      alignItems={'center'}
      justifyContent={'center'}
      {...rest}
    >
      <UserInfoBoxTitle>{t('program-info-duration')}</UserInfoBoxTitle>
      <UserInfoBoxValue variant={'h1'}>{duration}</UserInfoBoxValue>
    </UserInfoBox>
  );
};

interface UserBoxSupportMeetingProps extends UserInfoBoxProps {
  userPatientProfileID: string;
  order: 'last' | 'next';
}
export const UserBoxSupportMeeting: React.FC<UserBoxSupportMeetingProps> = ({
  userPatientProfileID,
  order,
  ...rest
}) => {
  const { t } = useTranslate();

  const { data, loading: isLoading } = useSourceSupportMeeting(userPatientProfileID);

  const value = useMemo(() => {
    return formatMeeting(data, order);
  }, [data, order]);

  const titleLabelKey = useMemo<string>(() => {
    switch (order) {
      case 'last':
        return 'last-support-meeting';
      case 'next':
        return 'next-support-meeting';
    }
  }, [order]);

  return (
    <UserInfoBox isLoading={isLoading} direction={'column'} {...rest}>
      <UserInfoBoxTitle>{t(titleLabelKey)}</UserInfoBoxTitle>
      <UserInfoBoxValue>{value}</UserInfoBoxValue>
    </UserInfoBox>
  );
};

interface UserBoxClinicalMeetingProps extends UserInfoBoxProps {
  userPatientProfileID: string;
  order: 'last' | 'next';
}
export const UserBoxClinicalMeeting: React.FC<UserBoxClinicalMeetingProps> = ({
  userPatientProfileID,
  order,
  ...rest
}) => {
  const { t } = useTranslate();

  const { data, loading: isLoading } = useSourceAppointment(userPatientProfileID);

  const value = useMemo(() => {
    return formatMeeting(data, order);
  }, [data, order]);

  const titleLabelKey = useMemo<string>(() => {
    switch (order) {
      case 'last':
        return 'last-appointment';
      case 'next':
        return 'next-appointment';
    }
  }, [order]);

  return (
    <UserInfoBox isLoading={isLoading} direction={'column'} {...rest}>
      <UserInfoBoxTitle>{t(titleLabelKey)}</UserInfoBoxTitle>
      <UserInfoBoxValue>{value}</UserInfoBoxValue>
    </UserInfoBox>
  );
};

interface UserBoxWeightProps extends UserInfoBoxProps {
  value: string | number | null | undefined;
  title: string;
  isLoading: boolean;
}
export const UserBoxWeight: React.FC<UserBoxWeightProps> = ({
  isLoading,
  title,
  value,
  children,
  ...rest
}) => {
  const { t } = useTranslate();
  const _value = value ?? '-';

  return (
    <UserInfoBox spacing={1} isLoading={isLoading} {...rest}>
      <UserInfoBoxTitle className={style.titleFirst}>{title}</UserInfoBoxTitle>
      <UserInfoBoxValue variant={'h1'} style={{ direction: 'ltr' }}>
        {_value}
      </UserInfoBoxValue>
      <UserInfoBoxTitle>{t('unit-kg')}</UserInfoBoxTitle>
      {children}
    </UserInfoBox>
  );
};
